import api from '@pray/shared/utils/api';

export async function getArtistLocales({ artistId = '' }) {
  if (!artistId) throw Error('Artist ID is required');

  const params = {};
  const url = `/web/studio/artists/${artistId}/locales`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
