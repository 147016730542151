import { z } from 'zod';

import api from '@pray/shared/utils/api';

const disconnectSocialPlatformSchema = z.object({
  artistId: z.string().min(1, 'Artist ID is required'),
  platformId: z.string().min(1, 'Platform ID is required'),
});

type DisconnectSocialPlatformProps = z.infer<typeof disconnectSocialPlatformSchema>;

export async function disconnectSocialPlatform(props: DisconnectSocialPlatformProps) {
  const { artistId, platformId } = disconnectSocialPlatformSchema.parse(props);

  const url = `/web/studio/artists/${artistId}/socials/${platformId}`;

  const response = await api.executeDeleteRequest(url);

  return response.data;
}
