import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

function mapArtistImpactMetrics(data) {
  return {
    totalReach: data.total_reach,
    reachByLocation: data.reach_by_locations,
  };
}

export default function useAudienceEngagement({ artistId }) {
  async function fetchArtistImpactMetrics() {
    const response = await studioService.home.getArtistImpactMetrics({ artistId });
    return mapArtistImpactMetrics(response.data);
  }

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistImpactMetrics(artistId),
    queryFn: fetchArtistImpactMetrics,
  });

  return query;
}
