import React from 'react';
import { create } from 'zustand';

import ToastMessage from '@pray/shared/components/ui/ToastMessage';

export const useToastMessage = create((set) => ({
  timeout: 3000,
  success: '',
  error: '',

  show: ({ success = '', error = '', timeout = 3000 }) => {
    set(() => ({ success, error, timeout }));
  },

  close: () => {
    set(() => ({ success: '', error: '', timeout: 3000 }));
  },
}));

export default function StudioToastMessage() {
  const { success, error, timeout, close } = useToastMessage();
  const message = success || error;

  if (!message) return null;

  const icon = success ? <SuccessIcon /> : <ErrorIcon />;

  return (
    <ToastMessage timeout={timeout} onTimeout={() => close()}>
      {icon} {message}
    </ToastMessage>
  );
}

function SuccessIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM10.5 17.1L4.90002 11.5L7 9.39999L10.4 12.8L16.8 6.39999L18.9 8.5L10.5 17.1Z"
        fill="#52B27F"
      />
    </svg>
  );
}

function ErrorIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 23C5.9 23 1 18.1 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12C23 18.1 18.1 23 12 23ZM13.5 5H10.5V13H13.5V5ZM12 15C10.9 15 10 15.9 10 17C10 18.1 10.9 19 12 19C13.1 19 14 18.1 14 17C14 15.9 13.1 15 12 15Z"
        fill="#FA6061"
      />
    </svg>
  );
}
