import api from '@pray/shared/utils/api';

export async function getAnnouncementFromWorkflowAI({ artistId, contentId, locale }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Announcement ID is required');
  if (!locale) throw Error('Locale is required');

  const url = `web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/announcement`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
