import { useQuery, UseQueryResult } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapVoices, Voice } from '@pray/shared/services/studioService/voices';

type UseAvailableVoicesProps = {
  artistId: string;
};

export function useAvailableVoices({ artistId }: UseAvailableVoicesProps): UseQueryResult<Voice[]> {
  async function fetchArtistVoices() {
    const response = await studioService.voices.getArtistVoices({ artistId });

    return mapVoices(response.data);
  }

  async function fetchTemplateVoices() {
    const response = await studioService.voices.getTemplateVoices();

    return mapVoices(response.data);
  }

  async function fetchAvailableVoices() {
    const [artistVoices, templateVoices] = await Promise.all([fetchArtistVoices(), fetchTemplateVoices()]);

    return [...artistVoices, ...templateVoices];
  }

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistVoices(artistId),
    queryFn: fetchAvailableVoices,
  });

  return query;
}
