import api from '@pray/shared/utils/api';

type SaveBookVersionProps = {
  artistId: string;
  bookId: string;
};

export const saveBookVersion = async ({ artistId, bookId }: SaveBookVersionProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/save`;
  await api.executePostRequest(url);
};
