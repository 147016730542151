import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export async function deleteArtist({ artistId = '' }) {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data;
}

export function useDeleteArtist() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteArtist,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artists(),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteArtist: mutateAsync,
    ...rest,
  };
}
