import { useQuery, useMutation } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export function useContentArticle(artistId, contentId, locale) {
  async function fetchContentArticle() {
    const { data } = await studioService.contentArticle.getArticle({
      artistId,
      contentId,
      locale,
    });
    return data;
  }

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.artistContentArticle(artistId, contentId, locale),
    queryFn: fetchContentArticle,
  });

  return query;
}

export function useGenerateContentArticle() {
  async function generateContentArticle({ artistId, contentId, locale }) {
    const { data } = await studioService.contentArticle.generateArticle({
      artistId,
      contentId,
      locale,
    });

    return data;
  }

  const mutation = useMutation({
    mutationFn: generateContentArticle,
  });

  return {
    generateContentArticle: mutation.mutateAsync,
    ...mutation,
  };
}

export function useUpdateContentArticle() {
  async function updateContentArticle({ artistId, contentId, locale, article }) {
    const { data } = await studioService.contentArticle.updateArticle({
      artistId,
      contentId,
      locale,
      article,
    });
    return data;
  }

  const mutation = useMutation({
    mutationFn: updateContentArticle,
  });

  return {
    updateContentArticle: mutation.mutateAsync,
    ...mutation,
  };
}
