import { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Label from '@pray/shared/components/ui/Label';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';

import { SocialAccount } from './types';
import DeleteContentModal from '../../../components/DeleteContentModal/DeleteContentModal';

type SocialAccountsTableProps = {
  data: SocialAccount[];
  isLoading: boolean;
  onConnect: () => void;
  onDisconnect: (platformId: string) => void;
};

export default function SocialAccountsTable({
  data: socialAccounts,
  isLoading,
  onConnect,
  onDisconnect,
}: SocialAccountsTableProps) {
  const [platformToDisconnect, setPlatformToDisconnect] = useState<string | null>(null);

  return (
    <div className="mt-8">
      <Table>
        <Thead>
          <Tr>
            <Th>Social Accounts</Th>
            <Th>Status</Th>
            <Th>Profile</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && <SkeletonLoadingRows />}

          {socialAccounts.map((socialAccount) => (
            <Tr key={socialAccount.id}>
              <Td>
                <div className="flex items-center gap-3">
                  <socialAccount.icon className="size-6" />
                  <Text variant={typography.heading_sm}>{socialAccount.name}</Text>
                </div>
              </Td>
              <Td>
                <Label status={socialAccount.isConnected ? 'published' : 'draft'} className="uppercase">
                  {socialAccount.isConnected ? 'connected' : 'disconnected'}
                </Label>
              </Td>
              <Td>
                <Text color={colors.text_tertiary} className="font-medium">
                  {socialAccount.profile || '--'}
                </Text>
              </Td>
              <Td className="flex justify-end">
                {!socialAccount.isConnected && (
                  <Button variant={buttons.variant.primary} size={buttons.size.xsmall} onClick={onConnect}>
                    Connect
                  </Button>
                )}
                {socialAccount.isConnected && (
                  <Button
                    variant={buttons.variant.secondary}
                    size={buttons.size.xsmall}
                    onClick={() => setPlatformToDisconnect(socialAccount.id)}
                  >
                    Disconnect
                  </Button>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <DeleteContentModal
        isOpen={!!platformToDisconnect}
        title="Disconnect Social Account"
        message="Are you sure you want to disconnect this social account?"
        deleteButtonLabel="Disconnect"
        onClose={() => setPlatformToDisconnect(null)}
        onDelete={async () => {
          await onDisconnect(platformToDisconnect);
          setPlatformToDisconnect(null);
        }}
      />
    </div>
  );
}

function SkeletonLoadingRows() {
  return (
    <>
      {Array.from({ length: 4 }).map((_, index) => (
        <Tr key={index}>
          <Td>
            <div className="flex items-center gap-2">
              <Loading isLight className="size-4" />
              <Loading isLight className="h-4 w-36" />
            </div>
          </Td>
          <Td>
            <Loading isLight className="h-4 w-24" />
          </Td>
          <Td>
            <Loading isLight className="h-4 w-24" />
          </Td>
          <Td>
            <Loading isLight className="h-4 w-24" />
          </Td>
        </Tr>
      ))}
    </>
  );
}
