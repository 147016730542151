import { useEffect, useState } from 'react';

import Select from '@pray/shared/components/ui/Select/Select';
import Text from '@pray/shared/components/ui/Text';
import logger from '@pray/shared/utils/logger';

import Flags from './assets';

import styles from './LanguageSelect.module.scss';

import { Tooltip } from '@/components/ui/tooltip';

export default function LanguageSelect({
  form,
  items = [],
  onLanguageChange = () => null,
  disabled = false,
  tooltipTextOnDisabled = '',
}) {
  const [selectedLanguage, setSelectedLanguage] = useState(form.values.locale);

  const [showTooltip, setShowTooltip] = useState(false);

  // Add this handler for mouse enter
  const handleMouseEnter = () => {
    if (disabled) setShowTooltip(true);
  };

  // Add this handler for mouse leave
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const flagIcons = {
    ar: Flags.AR,
    en: Flags.EN,
    es: Flags.ES,
    fr: Flags.FR,
    hi: Flags.HI,
    it: Flags.IT,
    ja: Flags.JA,
    ko: Flags.KO,
    pt: Flags.PT,
    sw: Flags.SW,
    tl: Flags.TL,
    zh: Flags.ZH,
  };

  // Update local state when the external form state changes
  useEffect(() => {
    setSelectedLanguage(form.values.locale);
  }, [form.values.locale]);

  if (!items.length) return null;

  const handleLanguageChange = (event) => {
    const { value } = event.target;
    setSelectedLanguage(value);
    form.setValue('locale', value);
    onLanguageChange(value);
  };

  const renderItemLabel = (item) => {
    if (!item) return null;

    return (
      <>
        {item.language}
        {item.is_primary && <span className={styles.primary}>Primary</span>}
      </>
    );
  };

  const getFlagIcon = (item) => {
    if (item.icon_url) return <img src={item.icon_url} alt={item.locale} className="mx-auto my-0 block w-5" />;

    const icon = flagIcons[item.locale];

    if (!icon) {
      logger.debug(`Flag icon for locale "${item?.locale}" not found.`);
      return null;
    }

    return <img src={icon} alt={item?.locale} className="mx-auto my-0 block w-5" />;
  };

  return (
    <div className="relative mx-3" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Select
        label="Language"
        name="locale"
        disabled={disabled}
        value={selectedLanguage}
        error={form.errors.locale}
        items={items}
        groups={['Translated', 'Not Translated']}
        getGroupIndex={(item) => (item?.is_localized ? 0 : 1)}
        getItemLabel={(item) => item?.language}
        getItemValue={(item) => item?.locale}
        getLeftIcon={(item) => getFlagIcon(item)}
        renderItemLabel={renderItemLabel}
        onChange={handleLanguageChange}
      />
      {showTooltip && <NotAvailableTooltipButton onClose={() => setShowTooltip(false)} text={tooltipTextOnDisabled} />}
    </div>
  );
}

function NotAvailableTooltipButton({ text = '', onClose = () => null }) {
  return (
    <Tooltip position={{ useRight: true }} onClose={onClose} className="hover:bg-white">
      <div className="px-4 py-2 text-sm">
        <Text>{text}</Text>
      </div>
    </Tooltip>
  );
}
