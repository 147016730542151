import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useSaveTranscriptVersion(artistId, contentId, locale) {
  const queryClient = useQueryClient();

  async function saveTranscriptVersion({ transcript }) {
    await studioService.workflowAI.saveTranscriptVersion({
      artistId,
      contentId,
      locale,
      transcript,
    });
  }

  const mutation = useMutation({
    mutationFn: saveTranscriptVersion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.transcripts(artistId, contentId, locale) });
      queryClient.invalidateQueries({ queryKey: queryKeys.artistContentDetails(artistId, contentId) });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveTranscriptVersion: mutateAsync,
    ...rest,
  };
}
