import { useRef, useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import Text from '@pray/shared/components/ui/Text';
import useClickAway from '@pray/shared/hooks/useClickAway';

import CalendarFilter from 'components/pages/StudioPage/components/EventsCalendar/Filter/CalendarFilter';
import FilterButton from 'components/pages/StudioPage/components/EventsCalendar/Filter/FilterButton';
import { ChevronLeft, ChevronRight, ChevronDown } from 'images/icons';
import { cn } from 'styles/utils';

import { viewTypes } from '../constants';

export default function CalendarHeader({
  title,
  viewType,
  onTodayClick,
  onPrevClick,
  onNextClick,
  onViewTypeChange,
  filterState,
}) {
  const [isViewTypeOpen, setIsViewTypeOpen] = useState(false);

  const filterRef = useRef(null);

  useClickAway(filterRef, () => filterState.setIsFilterOpened(false));

  function handleViewTypeChange(type) {
    setIsViewTypeOpen(false);
    onViewTypeChange(type);
  }

  return (
    <div className="z-[110] flex items-center justify-between">
      {/* left */}
      <div className="flex items-center gap-4">
        <div>
          <Button
            variant={buttons.variant.secondary}
            size={buttons.size.small}
            className="!border-[#8C8F96]"
            onClick={onTodayClick}
          >
            Today
          </Button>
        </div>
        <div className="flex gap-2">
          <IconButton icon={ChevronLeft} data-testid="prev-btn" data-tapeventname="Previous" onClick={onPrevClick} />
          <IconButton icon={ChevronRight} data-testid="next-btn" data-tapeventname="Next" onClick={onNextClick} />
        </div>
        <Text variant={typography.h1}>{title}</Text>
      </div>

      {/* right */}
      <div className="flex items-center gap-3">
        <div className="relative" ref={filterRef}>
          <FilterButton
            title={filterState.filterTitle}
            isOpened={filterState.isFilterOpened}
            onClick={() => filterState.setIsFilterOpened(!filterState.isFilterOpened)}
          />

          {filterState.isFilterOpened && (
            <div className="absolute right-[380px]">
              <CalendarFilter
                fetchedSeries={filterState.fetchedSeries}
                selectedTypes={filterState.selectedTypes}
                selectedStatuses={filterState.selectedStatuses}
                selectedSeries={filterState.selectedSeries}
                setSelectedTypes={filterState.setSelectedTypes}
                setSelectedStatuses={filterState.setSelectedStatuses}
                setSelectedSeries={filterState.setSelectedSeries}
                filterTypes={filterState.filterTypes}
                filterStatuses={filterState.filterStatuses}
                filterSeries={filterState.filterSeries}
              />
            </div>
          )}
        </div>

        <DropdownMenu
          hasSeparator={false}
          isOpen={isViewTypeOpen}
          onClose={() => setIsViewTypeOpen(false)}
          component={
            <Button
              className={cn(
                'flex w-[110px] items-center justify-between gap-2 rounded-full capitalize',
                isViewTypeOpen ? 'bg-[#0b0c0d] text-white' : 'bg-[#F2F3F4] hover:bg-[#dedfe3]'
              )}
              onClick={() => setIsViewTypeOpen(!isViewTypeOpen)}
            >
              <Text variant={typography.heading_md}>{viewType}</Text>
              <ChevronDown className={cn(isViewTypeOpen && 'stroke-white')} />
            </Button>
          }
        >
          <DropdownMenu.Item className="w-24" title="Month" onClick={() => handleViewTypeChange(viewTypes.month)} />
          <DropdownMenu.Item className="w-24" title="Week" onClick={() => handleViewTypeChange(viewTypes.week)} />
          <DropdownMenu.Item className="w-24" title="Day" onClick={() => handleViewTypeChange(viewTypes.day)} />
        </DropdownMenu>
      </div>
    </div>
  );
}
