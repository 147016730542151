import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';

import styles from './EpisodeInformation.module.scss';

export default function EpisodeInformation({ currentContent, isMinimized = false, ...props }) {
  const episodeInfoClasses = [styles.episodeInfo];
  if (isMinimized) episodeInfoClasses.push(styles.episodeInfoMinimized);
  const episodeInfoStyles = episodeInfoClasses.join(' ');

  return (
    <div className={episodeInfoStyles} {...props}>
      <div>
        <Text className={styles.title} data-contenttitle>
          {currentContent.title}
        </Text>
      </div>
      <div>
        <Text color={colors.text_secondary} className={styles.contentSeriesTitle}>
          {currentContent.artist_name} • {currentContent.content_series_title}
        </Text>
      </div>
    </div>
  );
}
