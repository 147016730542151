import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useTestimonials({ artistId, type, startDate, endDate }) {
  const [data, setData] = useState({
    total_testimonials: 0,
    total_profile_testimonials: 0,
    total_daily_testimonials: 0,
    total_positive_testimonials: 0,
    total_neutral_testimonials: 0,
    total_negative_testimonials: 0,
    daily_testimonials: {
      offset: 0,
      data: [],
    },
    profile_testimonials: {
      offset: 0,
      data: [],
    },
  });

  const fetchTestimonials = async (offset) => {
    const response = await studioService.testimonials.getAllTestimonials({
      artistId,
      type,
      startDate,
      endDate,
      offset,
    });

    if (response.data.total_testimonials !== undefined) {
      setData(response.data);
    }

    if (type === 'daily') return response.data.daily_testimonials;

    return response.data.profile_testimonials;
  };

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!(artistId && startDate && endDate),
    queryKey: queryKeys.artistTestimonials(artistId, { type, startDate, endDate }),
    queryFn: ({ pageParam: offset }) => fetchTestimonials(offset),
    getPreviousPageParam: (response) => response.offset,
    getNextPageParam: (response) => response.offset,
    placeholderData: keepPreviousData,
  });

  return { ...query, summary: data };
}
