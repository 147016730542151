import React from 'react';

import Text from '@pray/shared/components/ui/Text';

const statusStyles = {
  pending: {
    color: '#360A76',
    backgroundColor: '#D2C7F9',
  },
  paid: {
    color: '#04341A',
    backgroundColor: '#B6E2CB',
  },
};
export function PayoutStatus({ status }) {
  return (
    <Text
      className="rounded-full px-2 py-1 text-[11px] font-bold uppercase"
      style={{
        color: statusStyles[status]?.color,
        backgroundColor: statusStyles[status]?.backgroundColor,
      }}
    >
      {status}
    </Text>
  );
}
