import React from 'react';

import Text from '../Text/Text';

import styles from './Label.module.scss';

// Map of statuses to their respective classes
const STATUSES = {
  DRAFT: ['draft'],
  SCHEDULED: ['scheduled'],
  PUBLISHED: ['published', 'sent'],
  UNPUBLISHED: ['unpublished'],
};

/**
 * ```
 * A label component used for displaying text.
 *
 * @component
 * @param {Object} props - The properties for the Label component.
 * @param {string} props.children - The text content of the label.
 * @param {string} [props.className] - The className of the label.
 * @param {'draft' | 'scheduled' | 'published' | 'unpublished'} [props.status] - The status of the label.
 * @returns {JSX.Element} The rendered Label component.
 * ```
 */
export default function Label({ children, status, className, ...props }) {
  // default classes
  const classes = [styles.label, className];

  // status classes
  if (STATUSES.DRAFT.includes(status?.toLowerCase())) classes.push(styles.draft);
  if (STATUSES.PUBLISHED.includes(status?.toLowerCase())) classes.push(styles.published);
  if (STATUSES.SCHEDULED.includes(status?.toLowerCase())) classes.push(styles.scheduled);
  if (STATUSES.UNPUBLISHED.includes(status?.toLowerCase())) classes.push(styles.unpublished);

  // final classes
  const classNames = classes.filter((item) => item).join(' ');

  return (
    <Text className={classNames} {...props}>
      {children}
    </Text>
  );
}
