import api from '@pray/shared/utils/api';

export const getDailySeriesById = async ({
  artistId = null,
  dailySeriesId = null,
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
  options = {},
} = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series ID is required');

  const params = {};

  if (offset) {
    params.offset = offset;
  }

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
};
