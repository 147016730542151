import { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';

import ActionButton from '../ActionButton/ActionButton';

export default function ButtonCluster({ children, ...props }) {
  const [isButtonMenuOpen, setIsButtonMenuOpen] = useState(false);

  function toggleOpenButtonMenu(event) {
    event.stopPropagation();
    setIsButtonMenuOpen(!isButtonMenuOpen);
  }

  return (
    <div className="flex items-center" role="none" onClick={() => setIsButtonMenuOpen(false)}>
      <ActionButton
        className="h-10 rounded-r-none disabled:!rounded-r-none disabled:!border-none disabled:!opacity-80"
        {...props}
      />
      <DropdownMenu
        hasSeparator={false}
        isOpen={isButtonMenuOpen}
        onClose={toggleOpenButtonMenu}
        component={
          <Button
            variant={buttons.variant.primary}
            className="size-10 rounded-l-none !border-l-[#56585E] p-3 disabled:!border-l-gray-200 disabled:!opacity-80"
            disabled={props.disabled}
            onClick={toggleOpenButtonMenu}
          >
            <ChevronDown fill={props.disabled ? 'black' : '#c1c1c1'} />
          </Button>
        }
      >
        {children}
      </DropdownMenu>
    </div>
  );
}
