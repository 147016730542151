import api from '@pray/shared/utils/api';

export async function createDailyItems({ artistId, dailySeriesId, payload }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series Id is required');
  if (!payload) throw Error('Payload is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}/daily-items`;
  const response = await api.executePostRequest(url, payload);

  return response?.data?.data;
}
