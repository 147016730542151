import React from 'react';

import { typography, colors } from '@pray/shared/components/foundations';
import Label from '@pray/shared/components/ui/Label';
import Text from '@pray/shared/components/ui/Text';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import TabItem from 'components/pages/StudioPage/components/TabItem/TabItem';
import useAppRoutes from 'hooks/useAppRoutes';

import EmailDetailsPreview from './EmailDetailsPreview';
import { emailStatus, statusToLabelMap } from '../constants';

export function EmailDetailsItems({ email, activeItem = '' }) {
  const appRoutes = useAppRoutes();

  if (!email) return null;

  return (
    <div className="flex flex-col gap-6 pt-5">
      <BackLinkButton label="All Emails" to={appRoutes.artistEmails()} />

      <div className="flex flex-col gap-2 px-4">
        <EmailDetailsPreview data={email} />
        <Text variant={typography.headline_small} color={colors.text_primary}>
          {email.name}
        </Text>
        <div className="inline-block">
          <Label status={statusToLabelMap[email.status || emailStatus.draft]}>{email.status?.toUpperCase()}</Label>
        </div>
      </div>

      <div className="flex flex-col">
        <TabItem
          className="!px-3"
          text="Overview"
          active={activeItem === 'overview'}
          route={appRoutes.artistEmailsOverview(email.id)}
        />
        <TabItem
          className="!px-3"
          text="Analytics"
          active={activeItem === 'analytics'}
          route={appRoutes.artistEmailsAnalytics(email.id)}
        />
      </div>
    </div>
  );
}
