import api from '@pray/shared/utils/api';
import type { ListResponse } from '@pray/shared/utils/api/types/response';

type GetMappingsProps = {
  artistId: string;
  headers: string[];
  signal?: AbortSignal;
};

/* eslint-disable camelcase */
export type GetMappingsResponse = ListResponse<{
  header: string;
  guess: string;
  presentation_name: string;
}>;
/* eslint-enable camelcase */

export async function getMappings({ artistId, headers, signal }: GetMappingsProps) {
  if (!artistId) throw Error('Artist ID is required');
  if (!headers?.length) {
    throw new Error('CSV Headers are empty');
  }

  const url = `/web/studio/artists/${artistId}/leads-lists-mapping`;
  const query = { headers: headers.join(',') };

  const response = await api.executeGetRequest<GetMappingsResponse>(url, query, { signal });

  return response.data;
}
