import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { eventPropsToString } from '@pray/shared/components/events/eventUtils';
import MagicLogin from '@pray/shared/components/MagicLogin/MagicLogin';
import SharedComponents from '@pray/shared/components/SharedComponents';
import Text from '@pray/shared/components/ui/Text';
import UploaderModal from '@pray/shared/components/ui/UploaderModal/UploaderModal';
import { useAuth } from '@pray/shared/hooks/useAuth';
import { navigate } from '@pray/shared/utils/navigation';
import { parseQueryString } from '@pray/shared/utils/parseFromQuery';

import ContentTab from 'components/pages/StudioPage/tabs/ContentTab/ContentTab';
import ProtectedRoute from 'components/router/ProtectedRoute';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';
import { Permissions } from 'utils/auth';

import AppBar from './components/AppBar/AppBar';
import SideBar from './components/SideBar/SideBar';
import StudioToastMessage from './components/StudioToastMessage';
import AnalyticsTab from './tabs/AnalyticsTab/AnalyticsTab';
import AnnouncementsTab from './tabs/AnnouncementsTab/AnnouncementsTab';
import BannerTab from './tabs/BannerTab/BannerTab';
import CalendarTab from './tabs/CalendarTab/CalendarTab';
import ChatsTab from './tabs/ChatsTab/ChatsTab';
import ContentPage from './tabs/ContentTab/ContentPage/ContentPage';
import SocialClipsPublishPage from './tabs/ContentTab/SocialClipsPage/Publish/SocialClipsPublishPage';
import TranscriptEditForm from './tabs/ContentTab/TranscriptEditForm/TranscriptEditForm';
import DailiesFeedTab from './tabs/DailiesFeedTab/DailiesFeedTab';
import DailyBuilderTab from './tabs/DailyBuilderTab/DailyBuilderTab';
import DashboardTab from './tabs/DashboardTab/DashboardTab';
import DonationsTab from './tabs/DonationsTab/DonationsTab';
import EmailsTab from './tabs/EmailsTab/EmailsTab';
import FollowersTab from './tabs/FollowersTab/FollowersTab';
import LeadListsTab from './tabs/LeadListsTab/LeadListsTab';
import EditBookTranscriptPage from './tabs/LibraryTab/BookDetails/BookTranscript/EditBookTranscript/EditBookTranscriptPage';
import LibraryTab from './tabs/LibraryTab/LibraryTab';
import MinistryMatchTab from './tabs/MinistryMatchTab/MinistryMatchTab';
import PlaylistsTab from './tabs/PlaylistsTab/PlaylistsTab';
import ProfileTab from './tabs/ProfileTab/ProfileTab';
import RssFeedTab from './tabs/RssFeedTab/RssFeedTab';
import SegmentsTab from './tabs/SegmentsTab/SegmentsTab';
import SettingsTab from './tabs/SettingsTab/SettingsTab';
import TagsTab from './tabs/TagsTab/TagsTab';
import TeamTab from './tabs/TeamTab/TeamTab';

import styles from './StudioPage.module.scss';

export default function StudioPage() {
  const appRoutes = useAppRoutes();
  const { user, permissions } = useAuth();
  const { selectedArtist } = useStudioContext();
  const { email, code } = parseQueryString();

  const isMagicLoginFlow = email && code;
  const isUserRegistered = !!user?.is_registered;
  const shouldRedirect = !isUserRegistered && !isMagicLoginFlow;

  useEffect(() => {
    document.body.classList.add('theme-light');
    document.body.classList.remove('theme-dark');
  }, []);

  useEffect(() => {
    if (shouldRedirect) {
      navigate(appRoutes.index(), { replace: true });
    }
  }, [shouldRedirect]);

  // if user is not registered and it's not the magic login flow, then go to / page
  if (shouldRedirect) {
    return null;
  }

  if (isMagicLoginFlow) {
    return <LoginPage />;
  }

  const eventProps = {};

  if (selectedArtist.id) {
    eventProps['data-eventprops'] = eventPropsToString({
      artist_id: selectedArtist.id,
      artist_name: selectedArtist.name,
    });
  }

  return (
    <div className={styles.page} {...eventProps}>
      <AppBar />

      <StudioToastMessage />

      <div className={styles.container}>
        <SideBar />

        <Routes>
          <Route path="/leaders/:artistId/dashboard" element={<DashboardTab />} />

          <Route path="/leaders/profile" element={<ProfileTab />} />

          <Route path="/leaders/:artistId/profile" element={<ProfileTab />} />

          <Route path="/leaders/:artistId/banner" element={<BannerTab />} />

          <Route path="/leaders/:artistId/calendar" element={<CalendarTab />} />

          <Route path="/leaders/:artistId/episodes/*" element={<ContentTab />} />
          <Route path="/leaders/:artistId/episodes/:contentId/details" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/summary" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/transcript" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/transcript/voices" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/announcement" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/email" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/article" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/study-guide" element={<ContentPage />} />
          <Route path="/leaders/:artistId/episodes/:contentId/transcript/edit" element={<TranscriptEditForm />} />
          <Route path="/leaders/:artistId/episodes/:contentId/social-clips" element={<ContentPage />} />
          <Route
            path="/leaders/:artistId/episodes/:contentId/social-clips/:socialClipId/publish"
            element={<SocialClipsPublishPage />}
          />

          <Route path="/leaders/:artistId/series/*" element={<PlaylistsTab />} />

          <Route path="/leaders/:artistId/rss-feed/*" element={<RssFeedTab />} />

          <Route
            path="/leaders/:artistId/daily-series/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <DailiesFeedTab />
              </ProtectedRoute>
            }
          />

          <Route path="/leaders/:artistId/donations/*" element={<DonationsTab />} />

          <Route path="/leaders/:artistId/announcements/*" element={<AnnouncementsTab />} />

          <Route path="/leaders/:artistId/chats" element={<ChatsTab />} />
          <Route path="/leaders/:artistId/chats/:userId" element={<ChatsTab />} />

          <Route path="/leaders/:artistId/emails/*" element={<EmailsTab />} />

          <Route
            path="/leaders/:artistId/library/:bookId/transcript/edit/voices"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <EditBookTranscriptPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leaders/:artistId/library/:bookId/transcript/edit"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <EditBookTranscriptPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/leaders/:artistId/library/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <LibraryTab />
              </ProtectedRoute>
            }
          />

          <Route
            path="/dailies/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.VIEW_DAILY_BUILDER)}>
                {selectedArtist.id && <DailyBuilderTab />}
              </ProtectedRoute>
            }
          />

          <Route
            path="/tags/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.VIEW_TAGS)}>
                <TagsTab />
              </ProtectedRoute>
            }
          />

          <Route path="/leaders/:artistId/followers" element={<FollowersTab />} />

          <Route
            path="/leaders/:artistId/segments"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <SegmentsTab />
              </ProtectedRoute>
            }
          />

          <Route path="/settings/*" element={<SettingsTab />} />

          <Route path="/leaders/:artistId/team" element={<TeamTab />} />

          <Route
            path="/leaders/:artistId/lists/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <LeadListsTab />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ministry-match/*"
            element={
              <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
                <MinistryMatchTab />
              </ProtectedRoute>
            }
          />

          <Route path="/analytics/*" element={<AnalyticsTab />} />

          <Route path="*" element={<Navigate to={appRoutes.index()} replace />} />
        </Routes>

        <UploaderModal />
        <SharedComponents />
      </div>
    </div>
  );
}

function LoginPage() {
  const { user } = useAuth();
  const appRoutes = useAppRoutes();
  const { authenticate } = useStudioContext();
  const isUserRegistered = !!user?.is_registered;

  const handleMessageModalClosed = () => {
    const nextRoute = isUserRegistered ? appRoutes.initial() : appRoutes.index();
    navigate(nextRoute, { replace: true });
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center gap-4">
      <Text className="text-9xl font-bold text-gray-100">Loading...</Text>
      <MagicLogin handleSuccessfulLogin={authenticate} onMessageModalClosed={handleMessageModalClosed} />
    </div>
  );
}
