import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeadClient(leadClientId) {
  async function fetchLeadClient() {
    const response = await studioService.leadClients.getLeadClientById({
      leadClientId,
    });

    return response.data;
  }

  const query = useQuery({
    enabled: !!leadClientId,
    queryKey: queryKeys.leadClient(leadClientId),
    queryFn: fetchLeadClient,
  });

  return query;
}
