import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Label from '@pray/shared/components/ui/Label';
import { Table, Thead, Tr, Th, Tbody, Td } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';
import { navigate } from '@pray/shared/utils/navigation';

import { PLACEHOLDER_IMAGE } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

export default function FundListTable({ data = [], onToggleEnabled }) {
  const appRoutes = useAppRoutes();
  const location = useLocation();

  const fundToggles = useMemo(() => {
    return data?.reduce((acc, fund) => ({ ...acc, [fund.id]: fund.isEnabled }), {}) || {};
  }, [data]);

  const [toggles, setToggles] = useState(fundToggles);

  useEffect(() => {
    setToggles(fundToggles);
  }, [fundToggles]);

  async function handleToggleEnabled(fund) {
    try {
      setToggles({ ...toggles, [fund.id]: !toggles[fund.id] });
      await onToggleEnabled(fund);
    } catch {
      setToggles({ ...toggles, [fund.id]: !toggles[fund.id] });
    }
  }

  function handleFundClick(fund) {
    navigate(appRoutes.artistDonationsFundsEdit(fund.id), location);
  }

  return (
    <div className="mt-6">
      <Table rowCount={data.length}>
        <Thead>
          <Tr>
            <Th field="fund_active" width="120">
              On/Off
            </Th>
            <Th field="fund_name">Fund</Th>
            <Th field="fund_type">Type</Th>
            <Th field="total_donation_amount">Total Revenue Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((fund) => (
            <Tr key={fund.id}>
              <Td>
                <div className="px-3">
                  <ToggleSwitch checked={toggles[fund.id]} onChange={() => handleToggleEnabled(fund)} />
                </div>
              </Td>
              <Td className="cursor-pointer" onClick={() => handleFundClick(fund)}>
                <div className="flex gap-4">
                  <img
                    alt=""
                    className="size-[50px] min-w-[50px] rounded-md object-cover"
                    src={fund.imageUrl || PLACEHOLDER_IMAGE}
                    onError={(event) => {
                      event.target.src = PLACEHOLDER_IMAGE;
                    }}
                  />
                  <div className="flex max-w-[192px] flex-col justify-center gap-1.5">
                    <Text variant="subhead_small" className="line-clamp-2 font-medium">
                      {fund.name}
                    </Text>
                  </div>
                </div>
              </Td>
              <Td className="cursor-pointer" onClick={() => handleFundClick(fund)}>
                {fund.isDefault && <Label status="published">DEFAULT</Label>}
                {!fund.isDefault && <Label status="scheduled">CAUSE</Label>}
              </Td>
              <Td className="cursor-pointer" onClick={() => handleFundClick(fund)}>
                {fund.totalDonationAmount}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}
