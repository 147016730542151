import React from 'react';
import { Geographies, Geography } from 'react-simple-maps';

import { GEO_URL, US_STATES } from '@pray/shared/constants';

const COLORS = {
  LOADING: '#F2F3F4',
  FILL: '#FCF2DE',
  STROKE: '#79551066',
  HOVER: '#E3AF4A',
};

export function States({ audience = [], onMouseEnter = null, isLoading = false }) {
  function handleMouseEnter(event, geo) {
    if (!onMouseEnter) return;

    const stateName = geo.properties.name;
    const stateAbbr = Object.keys(US_STATES).find((state) => US_STATES[state].name === stateName);
    const stateAudience = audience.find((audience) => audience.state === stateAbbr);

    onMouseEnter({
      state: stateAbbr,
      listens: stateAudience?.count || 0,
      left: event.clientX,
      top: event.clientY + 24,
    });
  }

  const defaultStyles = {
    strokeWidth: 1,
    outline: 'none',
  };

  return (
    <Geographies geography={GEO_URL}>
      {({ geographies }) =>
        geographies.map((geo) => (
          <Geography
            key={geo.rsmKey}
            geography={geo}
            style={{
              default: {
                ...defaultStyles,
                fill: isLoading ? COLORS.LOADING : COLORS.FILL,
                stroke: isLoading ? COLORS.LOADING : COLORS.STROKE,
              },
              hover: {
                ...defaultStyles,
                fill: isLoading ? COLORS.LOADING : COLORS.HOVER,
                stroke: isLoading ? COLORS.LOADING : COLORS.STROKE,
              },
              pressed: {
                ...defaultStyles,
                fill: isLoading ? COLORS.LOADING : COLORS.HOVER,
                stroke: isLoading ? COLORS.LOADING : COLORS.STROKE,
              },
            }}
            onMouseMove={(event) => handleMouseEnter(event, geo)}
          />
        ))
      }
    </Geographies>
  );
}
