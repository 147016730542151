import api from '@pray/shared/utils/api';

export async function deleteContentSeries({ artistId, contentSeriesId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data.data;
}
