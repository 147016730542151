// eslint-disable-next-line no-shadow
export enum BookProcessStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  NOT_STARTED = null,
}

export type Book = {
  id: string;
  fileName: string;
  title: string;
  description: string;
  thumbnail: string;
  isPublished: boolean;
  textExtractionStatus?: BookProcessStatus;
  textExtractionUpdatedAt?: string;
  audiobookGenerationStatus?: BookProcessStatus;
  audiobookGenerationUpdatedAt?: string;
  lastSavedAt?: string;
  createdAt: string;
  publishedAt: string | null;
};

export type BookStatus = {
  textExtraction: BookProcessStatus;
  textExtractionUpdatedAt: string;
  audiobookGeneration: BookProcessStatus;
  audiobookGenerationUpdatedAt: string;
};
