import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';

import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import { useStudioContext } from 'context/StudioContext';
import useEmailTemplates from 'hooks/emailTemplates/useEmailTemplates';
import { Template } from 'images/icons';

import { templateTypes } from '../../constants';
import TemplateCard from '../TemplateCard';

import emptyTemplateCover from './assets/template-0.png';

const templateActionModes = {
  selection: 'selection',
  management: 'management',
};

export function TemplateGrid({
  isTemplateSelector = false,
  templateType,
  onApply,
  onCreate,
  onEdit,
  onClone,
  onPreview,
}) {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const templates = useEmailTemplates(artistId, templateType);
  const actionMode = isTemplateSelector ? templateActionModes.selection : templateActionModes.management;

  if (templates.isLoading) return null;

  const templateItems = templates.data?.pages?.flatMap((page) => page.data) || [];

  if (!templateItems.length) {
    return (
      <div className="mx-auto flex w-80 flex-col items-center justify-center gap-4">
        <EmptyState
          icon={<Template />}
          title="Nothing to show"
          subtitle="A description that contains a link to explain how to create a template"
        />
        <Button variant={buttons.variant.secondary} onClick={() => null}>
          Start from scratch
        </Button>
      </div>
    );
  }

  function addActionsForFirstTemplate(actions, template) {
    if (actionMode === templateActionModes.selection) {
      actions.primary = { label: 'Apply', onClick: () => onApply(template) };
    }

    if (actionMode === templateActionModes.management) {
      actions.primary = { label: 'Create', onClick: () => onCreate(template) };
    }
  }

  function addActionsForOtherTemplates(actions, template) {
    if (actionMode === templateActionModes.selection) {
      actions.primary = { label: 'Apply', onClick: () => onApply(template) };
    }

    if (actionMode === templateActionModes.management) {
      actions.primary = { label: 'Clone', onClick: () => onClone(template) };

      if (templateType === templateTypes.userTemplates) {
        actions.primary = { label: 'Edit', onClick: () => onEdit(template) };
      }
    }

    actions.secondary = { label: 'Preview', onClick: () => onPreview(template) };
  }

  return (
    <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 lg:gap-6 xl:grid-cols-5 xl:gap-8">
      {templateItems.map((template, index) => {
        const actions = {};

        if (index === 0) {
          template.previewImgUrl = emptyTemplateCover;
          addActionsForFirstTemplate(actions, template);
        } else {
          template.previewImgUrl = '';
          addActionsForOtherTemplates(actions, template);
        }

        return <TemplateCard key={template.id} data={template} actions={actions} />;
      })}

      <InfiniteScroll fetchData={templates.fetchNextPage} />
    </div>
  );
}
