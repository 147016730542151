import React, { useState } from 'react';

import { colors, typography } from '../../foundations';

import styles from './Text.module.scss';

/**
 * Custom props for the Text component
 * @typedef CustomProps
 * @property {keyof typeof typography|string} [variant] Typography variant to apply text styling (e.g. 'body', 'heading1'). See `typography` for all options
 * @property {keyof typeof colors|string} [color] Theme color to apply to the text (e.g. 'primary', 'text_primary'). See `colors` for all options
 * @property {React.ReactNode} [children] Content to render as text
 * @property {string} [className] Custom CSS classes to apply additional styling
 * @property {boolean} [nowrap] When true, prevents text from wrapping to multiple lines
 * @property {boolean} [block] When true, displays text as a block element instead of inline
 * @property {number} [wordLimit] Maximum number of words to display before truncating with "Read more"
 * @property {object} [style] Custom inline CSS styles to apply to the text
 */

/**
 * Props for the Text component
 * @typedef {CustomProps & React.ComponentPropsWithoutRef<'span'>} TextProps
 */

/**
 * A versatile text component that supports multiple typography variants, colors and display options
 *
 * | Property | Type | Description |
 * | --- | --- | --- |
 * | variant | string | Typography variant to apply text styling (e.g. 'body', 'heading1'). See `typography` for all options |
 * | color | string | Theme color to apply to the text (e.g. 'primary', 'text_primary'). See `colors` for all options |
 * | children | React.ReactNode | Content to render as text |
 * | className | string | Custom CSS classes to apply additional styling |
 * | nowrap | boolean | When true, prevents text from wrapping to multiple lines |
 * | block | boolean | When true, displays text as a block element instead of inline |
 * | wordLimit | number | Maximum number of words to display before truncating with "Read more" |
 * | style | object | Custom inline CSS styles to apply to the text |
 *
 * ```jsx
 * import Text from '@pray/shared/components/ui/Text';
 * import { typography, colors } from '@pray/shared/components/foundations';
 *
 * // Text with typography variant
 * <Text variant={typography.h1}>Heading 1</Text>
 *
 * // Text with color
 * <Text color={colors.text_primary}>Primary</Text>
 * ```
 */
export default function Text({
  variant = '',
  color = '',
  children = null,
  className = '',
  nowrap = false,
  block = false,
  wordLimit = null,
  ...props
}) {
  const typographyVariant = typography[variant] || variant || '';

  const variantStyle = typographyVariant ?? '';
  const colorStyle = colors[color] ?? '';
  const wrapStyle = nowrap ? styles.noWrap : '';
  const blockStyle = block ? styles.block : '';

  const classes = ['typography', variantStyle, colorStyle, wrapStyle, blockStyle, className]
    .filter((item) => item)
    .join(' ');

  return (
    <span className={classes} {...props}>
      {wordLimit ? (
        <ReadMore id="announcement-details-body" amountOfWords={wordLimit}>
          {children}
        </ReadMore>
      ) : (
        children
      )}
    </span>
  );
}

export const ReadMore = ({ id, children, amountOfWords = 36 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const splittedText = children.split(' ');
  const itCanOverflow = splittedText.length > amountOfWords;
  const beginText = itCanOverflow ? splittedText.slice(0, amountOfWords - 1).join(' ') : children;
  const endText = splittedText.slice(amountOfWords - 1).join(' ');

  const handleKeyboard = (e) => {
    if (e.code === 'Space' || e.code === 'Enter') {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <p id={id}>
      {beginText}
      {itCanOverflow && (
        <>
          {!isExpanded && <span>...</span>}
          <span className={`${!isExpanded && 'hidden'}`} aria-hidden={!isExpanded}>
            &nbsp;{endText}
          </span>
          <span
            className="ml-2 cursor-pointer font-bold"
            role="button"
            tabIndex={0}
            aria-expanded={isExpanded}
            aria-controls={id}
            onKeyDown={handleKeyboard}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Read Less' : 'Read More'}
          </span>
        </>
      )}
    </p>
  );
};
