import api from '@pray/shared/utils/api';

export const getPrayRadioTvContentSeries = async ({ artistId = null, params = {}, options = {} } = {}) => {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/content-series-pray-radio-tv`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
};
