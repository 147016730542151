import { _mockDeleteEpisode } from './getEpisodes';
import type { DeleteEpisodeResponse } from './types';

export type DeleteEpisodeServiceParams = {
  artistId: string;
  courseId: string;
  episodeId: string;
};

/**
 * Deletes an episode from a course
 * @param params - The parameters for deleting an episode
 * @param params.artistId - The ID of the artist containing the episode
 * @param params.courseId - The ID of the course containing the episode
 * @param params.episodeId - The ID of the episode to delete
 * @returns A promise that resolves to the delete episode response data
 */
export async function deleteEpisode({
  artistId,
  courseId,
  episodeId,
}: DeleteEpisodeServiceParams): Promise<DeleteEpisodeResponse> {
  if (!episodeId) throw new Error('Episode ID is required');
  if (!artistId) throw new Error('Artist ID is required');
  if (!courseId) throw new Error('Course ID is required');

  await new Promise((resolve) => setTimeout(resolve, 500));

  _mockDeleteEpisode({ artistId, courseId, episodeId });

  const response: DeleteEpisodeResponse = {
    /* eslint-disable camelcase */
    responded_at: new Date().toISOString(),
    object: 'object',
    data: {
      id: episodeId,
      message: 'Episode successfully deleted',
    },
    /* eslint-enable camelcase */
  };

  return response;

  // Uncomment when server implementation is ready
  // const url = `/studio/artists/${artistId}/courses/${courseId}/episodes/${episodeId}`;
  // const response = await api.executeDeleteRequest<DeleteEpisodeResponse>(url);
  // return response.data;
}
