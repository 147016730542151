import { DateTime } from 'luxon';

import { typography, colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { cn } from 'styles/utils';

export default function WeekDayCell({ date, highlightedDay, onDayClick }) {
  const isHighlighted = date.get('day') === highlightedDay;
  const isToday = date.hasSame(DateTime.now(), 'day');

  return (
    <th className="p-2.5">
      <div
        role="none"
        className="group flex cursor-pointer flex-col items-center justify-center"
        onClick={() => onDayClick(date)}
      >
        <Text
          variant={typography.subhead_medium}
          color={isToday ? colors.text_primary : colors.text_secondary}
          className={isToday ? '!font-bold' : '!font-medium'}
        >
          {date.get('weekdayShort')}
        </Text>

        <div className="flex justify-center p-1">
          <Text
            variant={typography.footnote}
            className={cn(
              'flex size-6 items-center justify-center rounded-full font-medium',
              isHighlighted ? 'bg-[#F2F3F4]' : 'group-hover:bg-[#F2F3F4]',
              isToday && 'bg-black !text-white'
            )}
          >
            {date.get('day')}
          </Text>
        </div>
      </div>
    </th>
  );
}
