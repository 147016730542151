import React from 'react';

function CloseIcon(props) {
  const { color, ...rest } = props;
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.3741 6.95952C7.98357 6.56899 7.35041 6.56899 6.95989 6.95952C6.56936 7.35004 6.56936 7.98321 6.95989 8.37373L14.5861 16L6.95989 23.6262C6.56936 24.0167 6.56936 24.6499 6.95989 25.0404C7.35041 25.4309 7.98357 25.4309 8.3741 25.0404L16.0003 17.4142L23.6266 25.0404C24.0171 25.4309 24.6502 25.4309 25.0408 25.0404C25.4313 24.6499 25.4313 24.0167 25.0408 23.6262L17.4145 16L25.0408 8.37373C25.4313 7.98321 25.4313 7.35004 25.0408 6.95952C24.6502 6.56899 24.0171 6.56899 23.6266 6.95952L16.0003 14.5857L8.3741 6.95952Z"
        fill={color}
      />
    </svg>
  );
}

export default CloseIcon;
