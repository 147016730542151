import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';

import BookDetailsPage from './BookDetails/BookDetailsPage';
import BooksListPage from './BooksList/BooksListPage';

export default function LibraryTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Library Tab">
      <Routes>
        <Route index element={<BooksListPage />} />
        <Route path="/:bookId/*" element={<BookDetailsPage />} />
      </Routes>
    </MainLayout>
  );
}
