export default function ErrorIcon(props) {
  const { fill = '#AA0E0F', className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 12C4.25 7.44365 7.94365 3.75 12.5 3.75C17.0563 3.75 20.75 7.44365 20.75 12C20.75 16.5563 17.0563 20.25 12.5 20.25C7.94365 20.25 4.25 16.5563 4.25 12ZM12.5 2.25C7.11522 2.25 2.75 6.61522 2.75 12C2.75 17.3848 7.11522 21.75 12.5 21.75C17.8848 21.75 22.25 17.3848 22.25 12C22.25 6.61522 17.8848 2.25 12.5 2.25ZM13.25 16.5V15H11.75V16.5H13.25ZM13.25 7.25V6.75H11.75V7.25V7.5V8V13V13.75H13.25V13V8V7.5V7.25Z"
        fill={fill}
      />
    </svg>
  );
}
