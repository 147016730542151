import api from '@pray/shared/utils/api';

export async function updateStudyGuide({ artistId = null, contentId = null, locale = null, studyGuide = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');
  if (!studyGuide) throw Error('Study guide content is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/study-guide`;
  const { data } = await api.executePutRequest(url, { studyGuide });

  return data;
}
