import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';

import RevenueStatusAlert from './RevenueStatusAlert';
import RevenueStatusItem from './RevenueStatusItem';
import { getRevenueStatusConfig, isPaymentsPaused, isPayoutsPaused, RevenueStatuses } from '../../constants';

export default function RevenueAccountStatus({ status, onViewAccount, isLoadingViewAccount }) {
  const config = getRevenueStatusConfig(status);
  const { button } = config || {};

  return (
    <div className="flex flex-col gap-4">
      <Text variant={typography.heading_lg}>Account status</Text>

      {status && status !== RevenueStatuses.ENABLED && (
        <RevenueStatusAlert status={status} onAction={onViewAccount} isLoadingViewAccount={isLoadingViewAccount} />
      )}

      <div className="mt-2 flex flex-col gap-2">
        <RevenueStatusItem
          title="Payments"
          info="New revenue being collected by Pray.com for your account "
          isPaused={isPaymentsPaused(status)}
        />
        <RevenueStatusItem
          title="Deposits"
          info="Collected revenue automatically sent to your bank account"
          isPaused={isPayoutsPaused(status)}
        />
      </div>

      {status === RevenueStatuses.ENABLED && (
        <ActionButton
          text={button?.title}
          className="w-fit"
          variant={button.variant}
          onClick={onViewAccount}
          isLoading={isLoadingViewAccount}
          disabled={isLoadingViewAccount}
        />
      )}
    </div>
  );
}
