import React from 'react';
import { useParams } from 'react-router-dom';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import usePayoutById from 'hooks/donations/usePayoutById';
import useAppRoutes from 'hooks/useAppRoutes';

import { PayoutDetailsHeader } from './PayoutDetailsHeader';
import { PayoutStats } from './PayoutStats';
import { PayoutTransactions } from './PayoutTransactions';

export function PayoutDetailsPage() {
  const appRoutes = useAppRoutes();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const { id: payoutId } = useParams();
  const { data: payout, isLoading } = usePayoutById({ artistId, payoutId });

  return (
    <TabPage data-viewname={VIEW_EVENT_NAMES.DONATIONS.BANK_TRANSFER_DETAILS}>
      <BackLinkButton to={appRoutes.artistDonationsBankPayouts()} label="Bank Transfer" />

      <div className="ml-3 flex max-w-7xl flex-col gap-6 pt-8">
        {(() => {
          if (isLoading) return null;

          return (
            <>
              <PayoutDetailsHeader date={payout?.date} status={payout?.status} />
              <PayoutStats payout={payout} />
            </>
          );
        })()}

        <PayoutTransactions artistId={artistId} payoutId={payoutId} />
      </div>
    </TabPage>
  );
}
