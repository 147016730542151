import { useContext } from 'react';

import { PrayContext } from '@pray/shared/context/PrayContext';

export default function usePrayContext() {
  const {
    authState = {},
    setCurrentUser = () => {},
    disableGoogleAnalytics = () => {},
    playContent = () => {},
    shareContent = () => {},
  } = useContext(PrayContext) ?? {};

  return {
    authState,
    setCurrentUser,
    isUserRegistered: authState?.currentUser?.is_registered,
    isUserSubscribed: authState?.currentUser?.is_subscribed,
    disableGoogleAnalytics,
    playContent,
    shareContent,
  };
}
