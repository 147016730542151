import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export function PayoutStatsCard({ title, children }) {
  return (
    <div className="flex flex-col justify-between gap-3 rounded-xl border bg-white p-6">
      <Text className="text-sm font-medium" color={colors.text_secondary}>
        {title}
      </Text>
      {children}
    </div>
  );
}
