import React from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import { Currency } from 'images/icons';

export default function RevenueAccountSetup({ isLoadingSetup, onSetupAccount }) {
  return (
    <div className="mt-40 flex flex-col items-center gap-6">
      <div className="flex flex-col items-center justify-center gap-4">
        <div className="flex items-center rounded-full bg-[#E3AF4A33] p-4" style={{ color: '#E3AF4A' }}>
          <Currency />
        </div>
        <div className="flex flex-col items-center gap-2">
          <Text variant={typography.h2}>Start receiving revenue from Pray.com</Text>
          <Text className="!font-medium" variant={typography.headline_small} color={colors.text_tertiary}>
            Enter your bank information to receive deposits.
          </Text>
        </div>
      </div>
      <ActionButton
        text="Set up account"
        variant={buttons.variant.primary}
        onClick={onSetupAccount}
        isLoading={isLoadingSetup}
        disabled={isLoadingSetup}
      />
    </div>
  );
}
