import React from 'react';

import Text from '@pray/shared/components/ui/Text';

import { PayoutStatsCard } from './PayoutStatsCard';
import { PayoutBankDetails } from '../Payouts/PayoutBankDetails';

export function PayoutStats({ payout }) {
  return (
    <div className="grid grid-cols-1 gap-6 py-4 md:grid-cols-2 lg:grid-cols-4">
      <PayoutStatsCard title="Transferred to">
        <Text className="text-3xl font-bold">
          <PayoutBankDetails bank={payout?.bank} showLogo />
        </Text>
      </PayoutStatsCard>

      <PayoutStatsCard title="Gross">
        <Text className="text-3xl font-bold">{payout?.grossAmount}</Text>
      </PayoutStatsCard>

      <PayoutStatsCard title="Fees & Refunds">
        <Text className="text-3xl font-bold">{payout?.feeAmount}</Text>
      </PayoutStatsCard>

      <PayoutStatsCard title="Net Total">
        <Text className="text-3xl font-bold">{payout?.netAmount}</Text>
      </PayoutStatsCard>
    </div>
  );
}
