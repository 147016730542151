import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import Text from '@pray/shared/components/ui/Text';
import studioService from '@pray/shared/services/studioService';
import { formatDateUsingShorterMonth } from '@pray/shared/utils/datetime';
import { navigate } from '@pray/shared/utils/navigation';

import MainLayout from 'components/layouts/MainLayout';
import ButtonCluster from 'components/pages/StudioPage/components/ButtonCluster';
import useSideBar from 'components/pages/StudioPage/components/SideBar/useSideBar';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useEmailCampaign from 'hooks/emailCampaigns/useEmailCampaign';
import useSaveEmailCampaign from 'hooks/emailCampaigns/useSaveEmailCampaign';
import useAppRoutes from 'hooks/useAppRoutes';

import { DetailItem } from './DetailItem';
import ConfirmDeactivationModal from '../components/Dialogs/ConfirmDeactivationModal';
import DeleteEmailModal from '../components/Dialogs/DeleteEmailModal';
import SaveEmailAsTemplateModal from '../components/Dialogs/SaveEmailAsTemplateModal';
import SendTestEmailModal from '../components/Dialogs/SendTestEmailModal';
import { audience, emailStatus } from '../constants';
import { EmailDetailsItems } from '../EmailAnalyticsPage/EmailDetailsItems';

export default function EmailOverviewPage() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const toast = useToastMessage();
  const { id: emailId } = useParams();
  const appRoutes = useAppRoutes();
  const location = useLocation();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const { saveEmailCampaign } = useSaveEmailCampaign();

  const emailResponse = useEmailCampaign(artistId, emailId);
  const email = useMemo(() => emailMapper(emailResponse.data), [emailResponse.data]);

  const [isDeleteEmailModalOpen, setIsDeleteEmailModalOpen] = useState(false);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isConfirmDeactivationModalOpen, setIsConfirmDeactivationModalOpen] = useState(false);
  const [isChangingAutomationStatus, setIsChangingAutomationStatus] = useState(false);

  useEffect(() => {
    setRenderDetails(() => <EmailDetailsItems email={email} activeItem="overview" />);
  }, [location, email]);

  async function handleSendTestEmail(emails) {
    try {
      setIsSendingTestEmail(true);
      await studioService.emailCampaigns.sendTestEmail({ artistId, campaignId: emailId, emails });
      toast.show({ success: 'Your test email has been sent' });
    } catch {
      toast.show({ error: 'There was an error sending your test email' });
    } finally {
      setIsSendingTestEmail(false);
      setIsSendTestModalOpen(false);
    }
  }

  async function handleMarkAsDraft() {
    try {
      const { id: campaignId, ...data } = email;
      await saveEmailCampaign({ artistId, campaignId, ...data, status: emailStatus.draft });

      toast.show({ success: 'Email has been marked as draft' });
      navigate(appRoutes.artistEmails());
    } catch {
      toast.show({ error: 'There was an error marking the email as draft' });
    }
  }

  async function handleChangeAutomationStatus(status) {
    try {
      setIsChangingAutomationStatus(true);
      const { id: campaignId, ...data } = email;
      await saveEmailCampaign({ artistId, campaignId, ...data, status });

      toast.show({ success: `Email automation has been ${status === emailStatus.active ? 'resumed' : 'stopped'}` });
    } catch {
      toast.show({ error: 'There was an error while updating the automation status' });
    } finally {
      setIsConfirmDeactivationModalOpen(false);
      setIsChangingAutomationStatus(false);
    }
  }

  return (
    <MainLayout>
      <TabPage
        data-viewname={VIEW_EVENT_NAMES.EMAILS.OVERVIEW}
        title="Overview"
        topRightButtons={
          <OverviewActionButtons
            status={email?.status}
            onViewPublished={null}
            onMarkAsDraft={handleMarkAsDraft}
            onEdit={() => navigate(appRoutes.artistEmailsEdit(emailId))}
            onDelete={() => setIsDeleteEmailModalOpen(true)}
            onSendTest={() => setIsSendTestModalOpen(true)}
            onSaveAsTemplate={() => setIsSaveAsTemplateModalOpen(true)}
            onStopAutomation={() => setIsConfirmDeactivationModalOpen(true)}
            onResumeAutomation={() => handleChangeAutomationStatus(emailStatus.active)}
          />
        }
      >
        <div className="mt-8 flex gap-16">
          <div className="flex h-[calc(100vh-275px)] w-[732px] items-center rounded-xl bg-[#F2F3F4]">
            <iframe
              id="preview-iframe"
              srcDoc={email?.compiledHtmlCss}
              title="Email Preview"
              className="no-scrollbar size-full overflow-x-hidden border-none"
            />
          </div>
          <div className="flex flex-col gap-6">
            <Text variant={typography.heading_xl}>Details</Text>
            <DetailItem title="Subject line" value={email?.subjectText} />
            <DetailItem title="Preview Text" value={email?.previewText} />
            <DetailItem
              title="Publish Date"
              value={email?.formattedDate ? `Scheduled on ${email?.formattedDate}` : '-'}
            />
            <DetailItem title="Type" value={email?.automationType ? 'Email Automation' : 'Email'} />
            <DetailItem title="Segment" value={audience} />
          </div>
        </div>
      </TabPage>

      {isSendTestModalOpen && (
        <SendTestEmailModal
          onCancel={() => setIsSendTestModalOpen(false)}
          onSend={handleSendTestEmail}
          isLoading={isSendingTestEmail}
        />
      )}

      {isDeleteEmailModalOpen && (
        <DeleteEmailModal
          artistId={artistId}
          campaignId={email?.id}
          onDelete={() => navigate(appRoutes.artistEmails())}
          onCancel={() => setIsDeleteEmailModalOpen(false)}
        />
      )}

      {isSaveAsTemplateModalOpen && (
        <SaveEmailAsTemplateModal
          artistId={artistId}
          email={email}
          onSaved={() => setIsSaveAsTemplateModalOpen(false)}
          onCancel={() => setIsSaveAsTemplateModalOpen(false)}
        />
      )}

      {isConfirmDeactivationModalOpen && (
        <ConfirmDeactivationModal
          isLoading={isChangingAutomationStatus}
          onCancel={() => setIsConfirmDeactivationModalOpen(false)}
          onSend={() => handleChangeAutomationStatus(emailStatus.stopped)}
        />
      )}
    </MainLayout>
  );
}

function OverviewActionButtons({
  status,
  onViewPublished,
  onMarkAsDraft,
  onEdit,
  onDelete,
  onSendTest,
  onSaveAsTemplate,
  onStopAutomation,
  onResumeAutomation,
}) {
  return (
    <>
      {status === emailStatus.sent && (
        <Button className="hidden" variant="primary" onClick={onViewPublished}>
          View Published
        </Button>
      )}

      {status === emailStatus.scheduled && (
        <>
          <Button variant="secondary" onClick={onDelete}>
            Delete
          </Button>
          <ButtonCluster text="Edit Email" onClick={onEdit}>
            <DropdownMenu.Item className="w-40" title="Send Test" onClick={onSendTest} />
            <DropdownMenu.Item className="w-40" title="Save as Template" onClick={onSaveAsTemplate} />
            {status !== emailStatus.draft && (
              <DropdownMenu.Item className="w-40" title="Mark as Draft" onClick={onMarkAsDraft} />
            )}
          </ButtonCluster>
        </>
      )}

      {[emailStatus.active, emailStatus.stopped].includes(status) && (
        <ButtonCluster text="Edit Email" onClick={onEdit}>
          <DropdownMenu.Item className="w-40" title="Send Test Email" onClick={onSendTest} />
          {status === emailStatus.active && (
            <DropdownMenu.Item className="w-40" title="Stop Automation" onClick={onStopAutomation} />
          )}
          {status === emailStatus.stopped && (
            <DropdownMenu.Item className="w-40" title="Resume Automation" onClick={onResumeAutomation} />
          )}
        </ButtonCluster>
      )}
    </>
  );
}

function emailMapper(email) {
  if (!email) return null;

  const scheduledDate = email?.scheduledAt;
  const formattedDate = formatDateUsingShorterMonth(scheduledDate, true);

  return { ...email, formattedDate };
}
