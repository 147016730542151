import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

export default function DistributionChecklist({ distributionStatus }) {
  return (
    <div className="max-w-[640px]">
      <Text variant="h2">Distribution Checklist</Text>
      <Text className="mt-1">
        To generate your secure RSS Feed that you can link to the platforms your listeners use, you’ll need to make sure
        you meet the minimum requirements.
      </Text>

      <div className="mt-9 flex flex-col gap-4 rounded-md bg-[#F3F3F2] p-4">
        <CheckListItem
          text="Fill out your Series title and description"
          checked={distributionStatus.hasTitleAndDescription}
        />
        <CheckListItem
          text="Upload a high-quality thumbnail for your Series"
          checked={distributionStatus.hasThumbnail}
        />
        <CheckListItem
          text="Make sure your Series visibility is set to ‘Public’"
          checked={distributionStatus.isVisible}
        />
        <CheckListItem
          text="Have at least one published episode in your Series"
          checked={distributionStatus.hasPublishedEpisodes}
        />
      </div>
    </div>
  );
}

const CheckListItem = ({ checked = false, text = '' }) => {
  const textColor = checked ? '' : 'text-[#00000060]';
  return (
    <div className="flex items-center gap-2">
      {checked ? <CheckedIcon /> : <UncheckedIcon />}
      <Text className={`font-medium ${textColor}`}>{text}</Text>
    </div>
  );
};

const CheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#188149" />
    <path
      d="M15.6719 6.39062L8.45312 13.6094L4.84375 10"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UncheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5.2253 5.2253C5.52571 4.9249 6.01276 4.9249 6.31316 5.2253L10 8.91214L13.6868 5.2253C13.9872 4.9249 14.4743 4.9249 14.7747 5.2253C15.0751 5.52571 15.0751 6.01276 14.7747 6.31316L11.0879 10L14.7747 13.6868C15.0751 13.9872 15.0751 14.4743 14.7747 14.7747C14.4743 15.0751 13.9872 15.0751 13.6868 14.7747L10 11.0879L6.31316 14.7747C6.01276 15.0751 5.52571 15.0751 5.2253 14.7747C4.9249 14.4743 4.9249 13.9872 5.2253 13.6868L8.91214 10L5.2253 6.31316C4.9249 6.01276 4.9249 5.52571 5.2253 5.2253Z"
      fill="black"
      fillOpacity="0.4"
    />
  </svg>
);
