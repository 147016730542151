import { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { ACCEPTED_AUDIO_FORMATS, MAX_FILE_SIZE, MAX_FILES } from '../constants';

export function useSampleDropzone({ uploadedSamples, onAcceptedFiles, onRejectedFiles }) {
  const dropzoneConfig = useMemo(
    () => ({
      accept: {
        'audio/*': ACCEPTED_AUDIO_FORMATS,
      },
      maxSize: MAX_FILE_SIZE,
      maxFiles: MAX_FILES,
      onDrop: (acceptedFiles, rejectedFiles) => {
        const remainingSlots = MAX_FILES - uploadedSamples.length;
        const acceptedToProcess = acceptedFiles.slice(0, remainingSlots);
        const rejectedToProcess = rejectedFiles.slice(0, Math.max(0, remainingSlots - acceptedFiles.length));

        if (acceptedToProcess.length > 0) {
          onAcceptedFiles(acceptedToProcess);
        }
        if (rejectedToProcess.length > 0) {
          onRejectedFiles(rejectedToProcess);
        }
      },
    }),
    [uploadedSamples.length, onAcceptedFiles, onRejectedFiles]
  );

  return useDropzone(dropzoneConfig);
}
