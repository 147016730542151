import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapSocialClip } from '@pray/shared/services/studioService/socialClips';

export function useSocialClip(artistId, contentId, socialClipId) {
  async function fetchSocialClip() {
    const { data: socialClip } = await studioService.socialClips.getSocialClip({
      artistId,
      contentId,
      socialClipId,
    });

    return mapSocialClip(socialClip);
  }

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!socialClipId,
    queryKey: queryKeys.artistContentSocialClipById(artistId, contentId, socialClipId),
    queryFn: fetchSocialClip,
  });

  return query;
}
