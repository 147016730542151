import api from '@pray/shared/utils/api';

export const getContentSeriesById = async ({
  artistId = null,
  contentSeriesId = null,
  search = '',
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
  options = {},
} = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');

  const params = {};

  if (search) {
    params.search = search;
  } else if (offset) {
    params.offset = offset;
  }

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
};
