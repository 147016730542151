import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Função utilitária para combinar classes CSS condicionalmente.
 *
 * @param {...(string | Record<string, boolean>)} inputs - Classes ou objetos condicionalmente ativos.
 * @returns {string} - String final com classes mescladas.
 */
export function cn(...inputs) {
  return twMerge(clsx(...inputs));
}
