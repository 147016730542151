import React from 'react';

import DetailsContent, { DetailsContentProps } from '../Content/DetailsContent/DetailsContent';

export default function DetailsTab({ form }: DetailsContentProps) {
  return (
    <div className="flex flex-col">
      <DetailsContent form={form} />
    </div>
  );
}
