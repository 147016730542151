import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';

export default function BooksListLoading({ rowCount = 4 }) {
  const rows = Array.from({ length: rowCount }, (_, i) => ({ key: `loading-row-${i}` }));

  return (
    <Table sortable={false} rowCount={rowCount}>
      <Thead>
        <Tr>
          <Th className="p-1">Name</Th>
          <Th className="max-w-md p-1">Date</Th>
          <Th className="max-w-md p-1">Audio Book</Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row) => (
          <Tr key={row.key}>
            <Td className="w-1/4 !bg-transparent p-1">
              <div className="flex flex-row gap-4">
                <Loading height={92} isLight width={60} borderRadius="6px" />
                <div className="flex flex-col justify-center gap-2">
                  <Loading height={16} isLight width={76} borderRadius="2px" className="m-0" />
                  <Loading height={16} isLight width={182} borderRadius="2px" className="m-0" />
                </div>
              </div>
            </Td>
            <Td className="w-[10%] !bg-transparent p-1">
              <div className="flex flex-col justify-center gap-2">
                <Loading height={16} isLight width={96} borderRadius="2px" className="m-0" />
                <Loading height={16} isLight width={60} borderRadius="2px" className="m-0" />
              </div>
            </Td>
            <Td className="w-[15%] !bg-transparent p-1">
              <Loading height={24} isLight width={80} borderRadius="2px" />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
