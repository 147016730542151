import React, { useMemo } from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Tbody, Td, Tr } from '@pray/shared/components/ui/Table';

const SKELETON_DIMENSIONS = {
  avatar: { width: 40, height: 40, borderRadius: '100%' },
  title: { width: 76 },
  subtitle: { width: 182 },
  button: { width: 80, height: 24 },
  action: { width: 24, height: 24 },
};

const ROWS_COUNT = 5;

export default React.memo(function VoicesSkeletonTable() {
  const skeletonRows = useMemo(() => {
    return Array.from({ length: ROWS_COUNT }, (_, i) => `skeleton-row-${i}`);
  }, []);

  return (
    <Table className="mt-8">
      <Tbody>
        {skeletonRows.map((uniqueId) => (
          <Tr key={uniqueId}>
            <Td width="8%">
              <Loading isLoading isLight {...SKELETON_DIMENSIONS.avatar} margin="0" />
            </Td>
            <Td>
              <div className="flex flex-col gap-2">
                <Loading isLoading isLight {...SKELETON_DIMENSIONS.title} margin="0" />
                <Loading isLoading isLight {...SKELETON_DIMENSIONS.subtitle} margin="0" />
              </div>
            </Td>
            <Td width="10%">
              <Loading isLoading isLight {...SKELETON_DIMENSIONS.button} margin="0" />
            </Td>
            <Td width="2%" className="!pr-0">
              <Loading isLoading isLight {...SKELETON_DIMENSIONS.action} margin="0" />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
});
