import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { Plus, VoiceDropzoneIcon } from 'images/icons';
import { cn } from 'styles/utils';

const DROPZONE_COLORS = {
  border: '#DEDFE3',
  hover: '#F2F3F4',
  iconBg: '#E3AF4A33',
  plusIcon: '#E3AF4A',
};

export default function SamplesDropzone({ getRootProps, getInputProps }) {
  return (
    <div
      {...getRootProps()}
      role="button"
      tabIndex={0}
      className={cn('flex cursor-pointer rounded-2xl border border-dashed p-1', `border-[${DROPZONE_COLORS.border}]`)}
    >
      <div
        className={cn(
          'flex w-full items-center justify-between gap-4 rounded-2xl p-1 px-4 py-6',
          `hover:bg-[${DROPZONE_COLORS.hover}]`
        )}
      >
        <input {...getInputProps()} accept="audio/*" />
        <div className="flex flex-1 items-center gap-4">
          <div className={cn('flex items-center justify-center rounded-full p-2.5', `bg-[${DROPZONE_COLORS.iconBg}]`)}>
            <VoiceDropzoneIcon />
          </div>
          <div className="flex flex-col">
            <Text className="!font-bold" variant={typography.heading_md} color={colors.text_featured}>
              Add voice samples
            </Text>
            <Text className="!font-medium" variant={typography.body_md} color={colors.text_tertiary}>
              Upload by clicking or dragging files here
            </Text>
          </div>
        </div>

        <Plus color={DROPZONE_COLORS.plusIcon} width={24} height={24} />
      </div>
    </div>
  );
}
