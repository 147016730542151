import Text from '@pray/shared/components/ui/Text/Text';

import { variations } from './constants';

const PeriodicTableGroupCard = ({ value, label, isSelected, onSelect, variation = 0 }) => (
  <div
    className={`w-full cursor-pointer rounded-lg border-2 ${isSelected ? 'border-[#12022F]' : 'border-transparent'}`}
    onClick={onSelect}
    role="button"
    tabIndex={-1}
    onKeyDown={() => null}
  >
    <div className="h-2 rounded-t-lg border-t-0" style={{ backgroundColor: variations[variation].border }} />
    <div
      className="flex flex-col rounded-b-lg px-3 py-2 text-center"
      style={{ backgroundColor: variations[variation].background }}
    >
      <Text className="text-[32px] font-bold leading-tight" style={{ color: variations[variation].color }}>
        {value}
      </Text>
      <Text className="text-[12px] font-bold leading-snug text-[#56585E]">{label}</Text>
    </div>
  </div>
);

export default PeriodicTableGroupCard;
