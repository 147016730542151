import React from 'react';

import styles from './Spinner.module.css';

export default function Spinner({ color = 'primary', size = '', className = ' ' }) {
  const borderColor = `border-${color}`;
  const spinnerStyles = [styles.loading, borderColor, className];

  if (color === 'purple') spinnerStyles.push(styles.purple);
  if (color === 'dark') spinnerStyles.push(styles.dark);
  if (color === 'gold') spinnerStyles.push(styles.gold);

  if (size === 'small') spinnerStyles.push(styles.small);
  if (size === 'medium') spinnerStyles.push(styles.medium);
  if (size === 'large') spinnerStyles.push(styles.large);

  return <div data-spinner className={spinnerStyles.join(' ')} />;
}
