import React, { useContext } from 'react';

import { TableContext } from './Table';
import Text from '../Text/Text';

export const Td = ({ children, ...props }: React.HTMLAttributes<HTMLTableCellElement>) => {
  const { styles } = useContext(TableContext);

  let contents = children;

  if (typeof contents === 'string') {
    contents = <Text>{contents}</Text>;
  }

  return (
    <td {...props}>
      <div className={styles.container}>
        <div className={styles.contents}>{contents}</div>
      </div>
    </td>
  );
};
