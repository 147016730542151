import React, { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { useAuth } from '@pray/shared/hooks/useAuth';

import FooterPortal from 'components/layouts/FooterPortal';
import MainLayout from 'components/layouts/MainLayout';
import { PRAY_ARTIST_NAME } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import { Permissions } from 'utils/auth';

import FeaturedProfileImages from './FeaturedProfileImages/FeaturedProfileImages';
import LeaderAnalytics from './LeaderAnalytics/LeaderAnalytics';
import LeaderLocation from './LeaderLocation/LeaderLocation';
import LeaderVerification from './LeaderVerification/LeaderVerification';
import LocationSearchToggle from './LocationSearchToggle/LocationSearchToggle';
import ProfileBasicInfo from './ProfileBasicInfo/ProfileBasicInfo';
import ProfileImage from './ProfileImage/ProfileImage';
import useLeaderProfile from './useLeaderProfile';
import ActionButton from '../../components/ActionButton/ActionButton';
import DeleteContentModal from '../../components/DeleteContentModal/DeleteContentModal';
import TabPage from '../../components/TabPage/TabPage';

export default function ProfileTab() {
  const { permissions } = useAuth();
  const { selectedArtist } = useStudioContext();

  const {
    form,
    profileUrl,
    isHandleEditable,
    isGivingUrlEditable,
    isPublishing,
    isDeleteModalOpen,
    validationValues,
    handleSaveProfile,
    handleDeleteArtist,
    setIsDeleteModalOpen,
  } = useLeaderProfile();

  function renderPublishButton() {
    let text = isPublishing ? 'Publishing' : 'Publish';
    if (selectedArtist.id) text = isPublishing ? 'Saving Changes' : 'Save Changes';

    return (
      <ActionButton
        text={text}
        isLoading={isPublishing}
        onClick={handleSaveProfile}
        disabled={!form.changed}
        data-testid="profile-publish-button"
      />
    );
  }

  function renderTopRightButtons() {
    return (
      <div className="flex space-x-4">
        <div className="sm-hide md-show">{renderPublishButton()}</div>

        {!!selectedArtist.handle && (
          <>
            <Button variant={buttons.variant.secondary} href={profileUrl} target="_blank">
              View Profile
            </Button>

            {permissions.has(Permissions.STUDIO_ADMIN) && selectedArtist.name !== PRAY_ARTIST_NAME && (
              <LeaderOptionsDropdown isDisabled={isPublishing} onDelete={() => setIsDeleteModalOpen(true)} forAdmin />
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <MainLayout data-viewname="PRAY Studio - Profile Tab">
      <TabPage title="Profile" topRightButtons={renderTopRightButtons()}>
        <div className="flex flex-col gap-9">
          {permissions.has(Permissions.STUDIO_ADMIN) && <ProfileImage form={form} />}

          <ProfileBasicInfo
            form={form}
            profileUrl={profileUrl}
            artistHandle={selectedArtist.handle}
            validationValues={validationValues}
            isHandleEditable={isHandleEditable}
            isGivingUrlVisible={permissions.has(Permissions.STUDIO_ADMIN)}
            isGivingUrlEditable={isGivingUrlEditable}
          />

          <LeaderLocation form={form} validationValues={validationValues} />

          {permissions.has(Permissions.STUDIO_ADMIN) && <LocationSearchToggle form={form} forAdmin />}

          {permissions.has(Permissions.UPLOAD_EXTRA_IMAGE_SIZES) && <FeaturedProfileImages form={form} forAdmin />}

          {permissions.has(Permissions.STUDIO_ADMIN) && form.values.id && <LeaderAnalytics form={form} forAdmin />}

          {permissions.has(Permissions.STUDIO_ADMIN) && form.values.id && <LeaderVerification form={form} forAdmin />}
        </div>
        <FooterPortal>{renderPublishButton()}</FooterPortal>
      </TabPage>

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title="Delete this Leader profile?"
        message={<DeleteLeaderConfirmation form={form} />}
        isDeleteButtonEnabled={form.values.handle === form.values.confirmation_handle}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeleteArtist();
          setIsDeleteModalOpen(false);
        }}
      />
    </MainLayout>
  );
}

function LeaderOptionsDropdown({ isDisabled = false, onDelete = null, forAdmin = false }) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  function toggleOpenMoreMenu() {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  }

  return (
    <DropdownMenu
      isOpen={isMoreMenuOpen}
      onClose={toggleOpenMoreMenu}
      component={
        <Button disabled={isDisabled} onClick={toggleOpenMoreMenu}>
          •••
        </Button>
      }
      forAdmin={forAdmin}
    >
      <DropdownMenu.Item
        title="Delete"
        onClick={() => {
          onDelete();
          toggleOpenMoreMenu();
        }}
      />
    </DropdownMenu>
  );
}

function DeleteLeaderConfirmation({ form }) {
  return (
    <div className="flex flex-col space-y-3">
      <Text>
        Are you absolutely sure you want to delete this leader and all associated data, including all Content and
        Series? This process cannot be reverted.
      </Text>

      <Text>
        To confirm, please type the leader handle below and click <strong>DELETE</strong>.
      </Text>

      <Text className="font-semibold">Leader handle: {form.values.handle}</Text>

      <TextInput
        placeholder="Type the leader handle here"
        name="confirmation_handle"
        value={form.values.confirmation_handle}
        onChange={form.handleChange}
      />
    </div>
  );
}
