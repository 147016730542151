import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import Button from '@pray/shared/components/ui/Button/Button';
import InputField, { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';
import { isValidUrl } from '@pray/shared/utils/validation';

import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import ActionButton from '../../../components/ActionButton/ActionButton';
import PodcastPreview from '../../../components/PodcastPreview/PodcastPreview';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './DailiesRssFeedForm.module.scss';

const initialFormData = {
  url: '',
};

let keyUpTimeout;

export default function DailiesRssFeedForm() {
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { selectedArtist } = useStudioContext();
  const [rssFeedData, setRSSFeedData] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLoadingRSSFeedData, setIsLoadingRSSFeedData] = useState(false);

  const form = useForm(initialFormData);

  const validateUrl = (url) => {
    const errors = {};

    // Daily Series RSS URL
    if (!url || !isValidUrl(url, { scheme: 'https' })) {
      errors.url = 'Invalid URL';
    }

    form.setErrors(errors);

    const isError = Object.keys(errors).length === 0;
    return isError;
  };

  const loadRSSFeedData = async (url) => {
    clearTimeout(keyUpTimeout);

    if (!validateUrl(url)) {
      setRSSFeedData(null);
      return;
    }

    try {
      setIsLoadingRSSFeedData(true);
      const rssFeedData = await studioService.rssFeed.validateRssFeedUrl(url);
      setRSSFeedData({ url, ...rssFeedData });
    } catch (err) {
      setRSSFeedData(null);
      form.setError('url', 'Failed to validate RSS Feed URL');
    } finally {
      setIsLoadingRSSFeedData(false);
    }
  };

  const handlePublish = async () => {
    const { url } = rssFeedData;
    if (!validateUrl(url)) return;

    setIsPublishing(true);

    try {
      const artistId = selectedArtist.id;
      await studioService.dailySeries.createDailySeriesWithRSSFeed(artistId, rssFeedData);
      navigate(appRoutes.dailies(), { replace: true });
    } catch (err) {
      logger.debug('Failed to publish daily series RSS', err);
      form.setError('url', 'Failed to publish Daily Series RSS Feed URL');
    } finally {
      setIsPublishing(false);
    }
  };

  const handleRSSUrlBlur = () => {
    loadRSSFeedData(form.values.url);
  };

  const handleRSSPreviewKeyUp = () => {
    clearTimeout(keyUpTimeout);
    keyUpTimeout = setTimeout(() => loadRSSFeedData(form.values.url), 2000);
  };

  const handleRSSUrlPaste = (event) => {
    const url = event.clipboardData.getData('text');
    loadRSSFeedData(url);
  };

  const renderTopRightButtons = () => {
    const text = isPublishing ? 'Publishing' : 'Publish';

    return (
      <div className="flex space-x-4">
        <Button variant={buttons.variant.secondary} onClick={() => navigate(appRoutes.dailies(), { replace: true })}>
          Cancel
        </Button>
        <ActionButton isLoading={isPublishing} text={text} onClick={handlePublish} disabled={!rssFeedData} />
      </div>
    );
  };

  return (
    <div data-viewname="PRAY Studio - Daily Series With RSS Tab">
      <Metadata title="PRAY Studio for Leaders - Content Creators" />

      <TabPage title="New RSS Feed" topRightButtons={renderTopRightButtons()}>
        <Section className="max-w-4xl">
          <div className={styles.formField}>
            <Text className={styles.label}>Daily Series RSS URL</Text>
            <Text className={styles.description}>
              Add the RSS URL to publish content on Pray.com. We will automatically gather the title, image and
              description. Your content will go live once you hit &quot;Publish&quot;. You will not be able to edit this
              URL after publishing your content.
            </Text>

            <TextInput
              placeholder="Daily Series RSS URL"
              name="url"
              value={form.values.url}
              errorMessage={form.errors.url}
              loading={isLoadingRSSFeedData}
              onBlur={handleRSSUrlBlur}
              onKeyUp={handleRSSPreviewKeyUp}
              onPaste={handleRSSUrlPaste}
              onChange={form.handleChange}
            />

            <InputHelperText>
              Note: RSS feed containing http:// links are not allowed, please use RSS feeds with secure https:// links.
            </InputHelperText>
          </div>

          {rssFeedData?.title && (
            <PodcastPreview
              title={rssFeedData.title}
              subtitle={rssFeedData.description}
              image={rssFeedData.image_url}
            />
          )}
        </Section>
      </TabPage>
    </div>
  );
}
