import api from '@pray/shared/utils/api';

export async function sendTestEmail({ artistId = undefined, campaignId = undefined, emails = undefined }) {
  if (!campaignId) throw new Error('Campaign Id is required');
  if (!artistId) throw new Error('Artist Id is required');

  const data = {
    emails,
  };

  const url = `/web/studio/artists/${artistId}/campaigns/emails/${campaignId}/test-email`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
