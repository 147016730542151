import React from 'react';

import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { VoiceSuccess, VoiceWarning } from 'images/icons';

import { SampleUploadStatus } from '../../constants';

export default function SampleStatusIcon({ status }) {
  switch (status) {
    case SampleUploadStatus.UPLOADING:
      return <Spinner color="black" />;
    case SampleUploadStatus.SUCCESS:
      return <VoiceSuccess />;
    case SampleUploadStatus.ERROR:
      return <VoiceWarning />;
    default:
      return null;
  }
}
