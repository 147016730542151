import api from '@pray/shared/utils/api';

export async function updateLeadCampaign({
  id: campaignId,
  name,
  title,
  subtitle,
  disclaimer,
  buttonText,
  imageUrl,
  type,
  targetCount,
  autopopulatePhoneNumber,
  scheduledStartDate,
  scheduledEndDate,
  dailyTargetLimit,
}) {
  if (!campaignId) throw Error('Campaign ID is required');

  const data = {};

  if (name !== undefined) data.name = name;
  if (title !== undefined) data.title = title;
  if (subtitle !== undefined) data.subtitle = subtitle;
  if (disclaimer !== undefined) data.disclaimer = disclaimer;
  if (buttonText !== undefined) data.button_text = buttonText;
  if (imageUrl !== undefined) data.image_url = imageUrl;
  if (type !== undefined) data.type = type;
  if (targetCount !== undefined) data.target_count = targetCount;
  if (autopopulatePhoneNumber !== undefined) data.auto_populate_phone_number = autopopulatePhoneNumber;
  if (scheduledStartDate !== undefined) data.scheduled_start_date = scheduledStartDate;
  if (scheduledEndDate !== undefined) data.scheduled_end_date = scheduledEndDate;
  if (dailyTargetLimit !== undefined) data.daily_target_limit = dailyTargetLimit;

  const url = `/web/studio/lead-campaigns/${campaignId}`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
