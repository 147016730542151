import { useEffect, useState } from 'react';

import { navigate } from '@pray/shared/utils/navigation';

import { useStudioContext } from '@/context/StudioContext';
import { useSocialPosts } from '@/hooks/socialPosts/useSocialPosts';
import useAppRoutes from '@/hooks/useAppRoutes';

import { useRevenueSettings } from '../../tabs/SettingsTab/RevenueSettings/useRevenueSettings';

export function useUserNotifications() {
  const appRoutes = useAppRoutes();
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  // Social Posts Notifications
  const { data: failedSocialPosts, isLoading } = useSocialPosts({ artistId, status: 'failed' });
  const isShowSocialPostsNotification = !!failedSocialPosts?.length;

  // Revenue Settings Notifications
  const { isShowUpdateAccountModal, onViewAccount, onCloseUpdateAccountModal } = useRevenueSettings();

  function addNotifications(newNotifications) {
    setNotifications([
      ...notifications,
      ...newNotifications.filter((n) => !notifications.find(({ id }) => id === n.id)),
    ]);
  }

  function removeNotification(id) {
    setNotifications(notifications.filter((n) => n.id !== id));
  }

  useEffect(() => {
    if (isShowUpdateAccountModal) {
      addNotifications([
        {
          id: 'giving-account-status',
          title: 'Revenue Account Update Required',
          message: 'Please update your revenue account settings to continue using all features.',
          icon: <AttentionIcon />,
        },
      ]);
    }
  }, [isShowUpdateAccountModal]);

  useEffect(() => {
    if (isShowSocialPostsNotification) {
      addNotifications(
        failedSocialPosts.map((post) => ({
          id: `social-post-failed-${post.id}`,
          title: 'Social Post Failed',
          message: `${post.title} | ${post.failedReason}`,
          icon: <AttentionIcon />,
          data: post,
        }))
      );
    }
  }, [isShowSocialPostsNotification]);

  function onNotificationClicked(notification) {
    setIsOpen(false);
    removeNotification(notification.id);

    // Revenue Account Update
    if (notification.id === 'giving-account-status') {
      onViewAccount();
      onCloseUpdateAccountModal();
    }

    // Social Post Failed
    if (notification.id.includes('social-post-failed')) {
      const { contentId } = notification.data;
      navigate(appRoutes.artistContentSocialClips(contentId));
    }
  }

  return {
    isOpen,
    isLoading,
    notifications,
    setIsOpen,
    onNotificationClicked,
  };
}

function AttentionIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#FEF0F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 20C11.75 15.4437 15.4437 11.75 20 11.75C24.5563 11.75 28.25 15.4437 28.25 20C28.25 24.5563 24.5563 28.25 20 28.25C15.4437 28.25 11.75 24.5563 11.75 20ZM20 10.25C14.6152 10.25 10.25 14.6152 10.25 20C10.25 25.3848 14.6152 29.75 20 29.75C25.3848 29.75 29.75 25.3848 29.75 20C29.75 14.6152 25.3848 10.25 20 10.25ZM20.75 24.5V23H19.25V24.5H20.75ZM20.75 15.25V14.75H19.25V15.25V15.5V16V21V21.75H20.75V21V16V15.5V15.25Z"
        fill="#AA0E0F"
      />
    </svg>
  );
}
