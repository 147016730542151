import React, { useMemo, useState } from 'react';

import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Download } from '@pray/shared/components/ui/Icons/Download';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { LOCALES } from '@pray/shared/constants';
import { useAuth } from '@pray/shared/hooks/useAuth';
import { downloadFileFromBlob } from '@pray/shared/utils/download';
import { getFormattedFileName } from '@pray/shared/utils/file';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';

import useRetriggerTranslations from '@/hooks/translations/retrigger';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';
import useContentDetails from 'hooks/useContentDetails';
import { ReTranslate, Trash } from 'images/icons';
import { Permissions } from 'utils/auth';

import ContentDetailsBanners from './ContentDetailsBanners';
import ButtonCluster from '../../../components/ButtonCluster';
import DeleteContentModal from '../../../components/DeleteContentModal/DeleteContentModal';
import Section from '../../../components/Section/Section';
import { useToastMessage } from '../../../components/StudioToastMessage';
import TabPage from '../../../components/TabPage/TabPage';
import ContentDetailsForm from '../ContentDetails/ContentDetailsForm/ContentDetailsForm';
import ContentPreview from '../ContentDetails/ContentPreview/ContentPreview';

export default function ContentDetails({
  artist,
  content,
  locale,
  isLoadingContent,
  workflowAIDetails,
  fetchContentDetails,
  onTranslationStarted,
}) {
  const toast = useToastMessage();
  const appRoutes = useAppRoutes();
  const { permissions } = useAuth();
  const isAdmin = permissions.has(Permissions.STUDIO_ADMIN);

  /**
   * Internal states
   */
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRemoveImageModalOpen, setIsRemoveImageModalOpen] = useState(false);

  /**
   * Variables and utilities functions
   */
  const isPrimary = locale && locale === LOCALES.DEFAULT;
  const isVideo = !!content?.primary_video_url;

  const onContentSaved = async () => {
    toast.show({ success: 'Episode saved successfully' });
    await fetchContentDetails();
  };

  const onContentImageRemoved = async () => {
    await fetchContentDetails();
  };

  const onContentDeleted = () => {
    if (isPrimary) {
      navigate(appRoutes.artistContent());
    } else {
      form.setValue('locale', LOCALES.DEFAULT);
    }
  };

  const { form, isSaving, handleSubmit, handleDeleteContent, handleRemoveContentImage } = useContentDetails({
    data: content,
    isVideo,
    onContentSaved,
    onContentDeleted,
    onContentImageRemoved,
  });

  const { retriggerTranslations, isPending: triggerTranslationPending } = useRetriggerTranslations(
    artist?.id,
    content?.id
  );

  const isLocalized = useMemo(() => {
    return content?.translations?.find((item) => item.locale === form.values.locale)?.is_localized;
  }, [locale, content?.translations]);

  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      downloadFileFromBlob(blob, filename);
    } catch (error) {
      toast.show({ error: 'Failed to download audio' });
      logger.error('Error downloading file:', error);
    }
  };

  const handleRetriggerTranslations = async () => {
    const params = {
      sourceContentIds: [content?.id],
    };

    if (!isPrimary) {
      params.targetLocaleCodes = [locale];
    }

    const response = await retriggerTranslations(params);
    if (response.object === 'duplicate') {
      const errorMessage =
        response?.resource?.message ||
        'There is already a translation triggered for this locale, wait 15 minutes before retrying';

      toast.show({ error: errorMessage });
    } else {
      toast.show({ success: 'The re-translation will start shortly' });
    }
  };

  const renderTopRightButtons = () => {
    if (!content) return null;

    return (
      <div className="sm-hide md-show">
        <ButtonCluster
          text={isSaving ? 'Saving' : 'Save'}
          isLoading={isSaving || triggerTranslationPending}
          disabled={triggerTranslationPending || isSaving || content.is_processing || (!isPrimary && !isLocalized)}
          onClick={handleSubmit}
        >
          <DropdownMenu.Item
            className="w-48"
            title={isVideo ? 'Download Video' : 'Download Audio'}
            leftComponent={<Download />}
            onClick={async () => {
              toast.show({ success: 'The download will start shortly' });
              const filename = getFormattedFileName(content.title, isVideo ? 'mp4' : 'mp3', locale || 'en');
              await handleDownload(content.download_url, filename);
            }}
          />

          {isAdmin && (
            <DropdownMenu.Item
              className="w-48"
              title="Re-translate"
              disabled={isVideo || triggerTranslationPending}
              leftComponent={triggerTranslationPending ? <Spinner /> : <ReTranslate />}
              onClick={async () => {
                await handleRetriggerTranslations();
              }}
            />
          )}

          <DropdownMenu.Item
            className="w-48"
            title="Delete"
            leftComponent={<Trash />}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </ButtonCluster>
      </div>
    );
  };

  let isShowDetailsForm = true;
  if (content.is_video) {
    isShowDetailsForm = !isLoadingContent && isLocalized && !content.is_processing;
  } else {
    isShowDetailsForm =
      !isLoadingContent && isLocalized && !content.is_processing && content.transcript_status !== 'processing';
  }

  const isShowContentPreview = isPrimary || isLocalized;

  return (
    <TabPage title="Details" data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS} topRightButtons={renderTopRightButtons()}>
      <Section className="flex flex-row gap-10">
        <ContentDetailsBanners
          title={form.values.title}
          locale={form.values.locale}
          artistId={artist?.id}
          content={content}
          isLoading={isLoadingContent}
          isLocalized={isLocalized}
          translations={content?.translations}
          workflowAIDetails={workflowAIDetails}
          onTranslationStarted={onTranslationStarted}
        />

        {isShowDetailsForm && (
          <ContentDetailsForm
            form={form}
            content={content}
            artist={artist}
            isVideo={isVideo}
            isPrimary={isPrimary}
            onRemoveContentImage={() => setIsRemoveImageModalOpen(true)}
          />
        )}

        {isShowContentPreview && <ContentPreview content={content} locale={locale} />}
      </Section>

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title="Delete this content?"
        message="You are about to delete this content and its associated data. This action cannot be undone. Are you sure you want to delete this content?"
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeleteContent();
          setIsDeleteModalOpen(false);
        }}
      />

      <DeleteContentModal
        isOpen={isRemoveImageModalOpen}
        title="Delete the Content thumbnail?"
        message="You are about to delete this content thumbnail. This action cannot be undone. Are you sure you want to delete this image?"
        onClose={() => setIsRemoveImageModalOpen(false)}
        onDelete={async () => {
          await handleRemoveContentImage();
          setIsRemoveImageModalOpen(false);
        }}
      />
    </TabPage>
  );
}
