import React from 'react';

export default function LinkIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 24C4 12.9713 12.9713 4 24 4C35.0287 4 44 12.9713 44 24C44 35.0287 35.0287 44 24 44C12.9713 44 4 35.0287 4 24ZM22.643 25.3966C23.1949 25.115 23.414 24.4392 23.1324 23.8873C22.6944 23.0287 22.8528 21.9739 23.5456 21.2811L25.9256 18.9011C26.8019 18.0248 28.2226 18.0248 29.0989 18.9011C29.9752 19.7774 29.9752 21.1981 29.0989 22.0744C28.6608 22.5125 28.6608 23.2229 29.0989 23.661C29.5371 24.0992 30.2474 24.0992 30.6856 23.661C32.4381 21.9085 32.4381 19.067 30.6856 17.3144C28.933 15.5619 26.0915 15.5619 24.339 17.3144L21.959 19.6944C20.5735 21.0799 20.2568 23.1884 21.1337 24.9071C21.4153 25.4591 22.091 25.6782 22.643 25.3966ZM25.357 22.6034C24.8051 22.885 24.586 23.5608 24.8676 24.1127C25.3056 24.9713 25.1472 26.0261 24.4544 26.7189L22.0744 29.0989C21.1981 29.9752 19.7774 29.9752 18.9011 29.0989C18.0248 28.2226 18.0248 26.8019 18.9011 25.9256C19.3392 25.4875 19.3392 24.7771 18.9011 24.339C18.4629 23.9008 17.7526 23.9008 17.3144 24.339C15.5619 26.0915 15.5619 28.933 17.3144 30.6856C19.067 32.4381 21.9085 32.4381 23.661 30.6856L26.041 28.3056C27.4265 26.9201 27.7432 24.8116 26.8663 23.0929C26.5847 22.5409 25.909 22.3218 25.357 22.6034Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
