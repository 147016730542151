import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

import styles from './ContentStatus.module.scss';

export default function ContentStatus({ status }) {
  return (
    <div>
      <Text className={`${styles.contentStatus} ${styles[status]}`}>{status}</Text>
    </div>
  );
}
