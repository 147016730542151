import React from 'react';

import Dropzone, { DropzoneInfo } from '@pray/shared/components/ui/Dropzone/Dropzone';

import { useToastMessage } from '../../../components/StudioToastMessage/StudioToastMessage';
import uploadIcon from '../assets/upload-icon.svg';
import { ACCEPTED_FILE_EXTENSIONS_STRING, ACCEPTED_FILE_TYPES } from '../constants';

import { cn } from '@/styles/utils';

type BooksListUploaderProps = {
  dropzoneRef: React.RefObject<typeof Dropzone>;
  isShowDropzone: boolean;
  onAddBook: (files: File[]) => void;
  onDragLeave: () => void;
};

export default function BooksListUploader({
  dropzoneRef,
  isShowDropzone,
  onAddBook,
  onDragLeave,
}: BooksListUploaderProps) {
  const toast = useToastMessage();

  const handleAcceptedFiles = (files: File[]) => {
    if (files.length) {
      onAddBook(files);
    }
  };

  const handleRejectedFiles = (files: File[]) => {
    if (files.length) {
      toast.show({
        error: `Unsupported file type. Please upload a ${ACCEPTED_FILE_EXTENSIONS_STRING.replace('and', 'or')} file.`,
      });
    }
  };

  const getDropzoneMessage = (isDragActive: boolean) => {
    let title = '';
    let subtitle = '';

    if (isShowDropzone) {
      title = isDragActive ? 'Drop your Books to upload' : 'Drag & Drop your Books here';
      subtitle = `You can upload or drag files in the following formats: ${ACCEPTED_FILE_EXTENSIONS_STRING}.`;
    }

    return <DropzoneInfo title={title} subtitle={subtitle} customIcon={uploadIcon} />;
  };

  return (
    <div
      className={cn('absolute inset-0', !isShowDropzone && 'hidden')}
      onDragLeave={onDragLeave}
      onDropCapture={onDragLeave}
    >
      <Dropzone
        ref={dropzoneRef}
        options={{ accept: ACCEPTED_FILE_TYPES }}
        getDropzoneMessage={getDropzoneMessage}
        onAcceptedFiles={handleAcceptedFiles}
        onRejectedFiles={handleRejectedFiles}
      />
    </div>
  );
}
