import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { leadListsMapper } from '@pray/shared/services/studioService/leadLists/mappers';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';

export default function useLeadLists({ artistId, filters, enableAutoRefetch = true, refetchInterval = 30000 }) {
  async function fetchLeadLists({ pageParam }) {
    const { search } = filters;
    const response = await studioService.leadLists.getLeadLists({
      artistId,
      lastItemIdentifier: pageParam,
      search,
      sortDirection: 'desc',
    });

    const mapped = leadListsMapper(response);
    return mapped;
  }

  const query = useInfiniteQuery({
    initialPageParam: null,
    enabled: !!artistId,
    queryKey: queryKeys.leadLists(artistId, filters),
    queryFn: fetchLeadLists,
    getNextPageParam: (lastPage) => lastPage.lastItemIdentifier,
    placeholderData: keepPreviousData,
    refetchInterval: (query) => {
      if (!enableAutoRefetch) return false;

      const pages = query.state.data?.pages || [];
      const lists = pages.flatMap((page) => page.lists) || [];

      return lists.some((list) => list.status === LeadListStatus.PROCESSING) ? refetchInterval : false;
    },
  });

  return query;
}
