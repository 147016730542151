import { useEffect } from 'react';

export function usePreventNativePickers() {
  const preventDefault = (event) => event.preventDefault();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  useEffect(() => {
    const datePickers = document.querySelectorAll('input[type="date"]');
    const timePickers = document.querySelectorAll('input[type="time"]');
    const dateTimePickers = [...datePickers, ...timePickers];

    if (dateTimePickers.length > 0) {
      dateTimePickers.forEach((input) => {
        input.addEventListener('click', preventDefault);
        input.addEventListener('focus', preventDefault);
        input.addEventListener('keydown', handleKeyDown);
      });
    }

    return () => {
      if (dateTimePickers.length > 0) {
        dateTimePickers.forEach((input) => {
          input.removeEventListener('click', preventDefault);
          input.removeEventListener('focus', preventDefault);
          input.removeEventListener('keydown', handleKeyDown);
        });
      }
    };
  }, []);
}
