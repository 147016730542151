import React from 'react';

export function Bookmarked({ fill = 'white', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75029 5.50025C4.75021 3.98139 5.98143 2.75 7.50034 2.75L16.5 2.75C18.0188 2.75 19.25 3.98122 19.25 5.5V19.3788C19.25 20.9026 17.614 21.8667 16.281 21.1284L12.2422 18.8915C12.0915 18.808 11.9084 18.808 11.7577 18.8915L7.71897 21.1284C6.38596 21.8666 4.74996 20.9026 4.75 19.3788C4.75004 17.9371 4.7501 16.4955 4.75017 15.0539C4.75032 11.8694 4.75047 8.68481 4.75029 5.50025Z"
        fill={fill}
      />
    </svg>
  );
}
