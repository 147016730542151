import api from '@pray/shared/utils/api';

export async function updateArticle({ artistId = null, contentId = null, locale = null, article }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');
  if (!article) throw Error('Article is required');

  const data = {
    article,
  };

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/article`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
