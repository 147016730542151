import { useLocation, useNavigate } from 'react-router-dom';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { LOCALES } from '@pray/shared/constants';
import { useAuth } from '@pray/shared/hooks/useAuth';
import useForm from '@pray/shared/hooks/useForm';

import useAppRoutes from 'hooks/useAppRoutes';
import {
  AiAnnouncement,
  AiArticle,
  AiEmailCampaign,
  AiStudyGuide,
  Edit,
  SummaryAI,
  TranscriptAI,
  VideoCamera,
} from 'images/icons';
import { Permissions } from 'utils/auth';

import { useActiveTabStore } from './ContentPage';
import { extractContentDetailsVariables, getBackRoutesFromContentPage } from './utilities';
import BackLinkButton from '../../../components/BackLinkButton/BackLinkButton';
import LanguageSelect from '../../../components/LanguageSelect/LanguageSelect';
import SideBarContentDetails from '../../../components/SideBar/SideBarContentDetails';
import TabItem from '../../../components/TabItem/TabItem';
import WorkflowAIStatus from '../ContentDetails/WorkflowAIStatus/WorkflowAIStatus';

export default function ContentSidebar({ contentId, content, isFetchingContentData, localeCode, activeTab }) {
  /**
   * Hooks
   */
  const { permissions } = useAuth();
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const activeTabStore = useActiveTabStore();

  /**
   * Utils
   */
  const isLocaleSupported = Object.values(LOCALES).find((locale) => locale === localeCode);
  const isDefaultLocale = localeCode === LOCALES.DEFAULT;

  const getCustomSpinner = () => {
    return <Spinner color="dark" size="small" className="border-2 border-gray-400" />;
  };

  /**
   * Language Form definition
   */
  const languageForm = useForm({
    locale: isLocaleSupported ? localeCode : LOCALES.EN,
  });

  /**
   * Variables definition
   */
  const backRoute = getBackRoutesFromContentPage(appRoutes.artistContent());
  const { contentExists, isVideo, isLoadingDetails, isWorkflowAIProcessing, isProcessingTranscript, transcriptStatus } =
    extractContentDetailsVariables(content);

  /**
   * Loading state
   */
  if (isFetchingContentData) {
    return (
      <div className="flex h-full flex-col gap-4 pt-5 ">
        <div className="w-auto">
          <BackLinkButton label="Episodes" to={backRoute} />
        </div>

        <div className="flex flex-col gap-2 px-5">
          <Loading height={80} isLight width={80} />
          <Loading height={40} isLight width={150} />

          <Loading height={40} isLight />
          <Loading height={40} isLight />
          <Loading height={40} isLight />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col gap-4 pt-5">
      <div className="mx-2 w-auto">
        <BackLinkButton label="Episodes" to={backRoute} />
      </div>

      <SideBarContentDetails label="Episode" />

      <LanguageSelect
        form={languageForm}
        items={content?.translations}
        disabled={transcriptStatus !== 'done' && !isVideo}
        tooltipTextOnDisabled="You can't select languages while your audio is still processing."
        onLanguageChange={(value) => {
          navigate(
            {
              pathname: location.pathname,
              search: `?locale=${value}`,
              hash: location.hash,
            },
            { replace: true }
          );
          activeTabStore.setActiveTab('details');
        }}
      />

      <div className="grow [&_a]:px-3">
        <TabItem
          icon={isLoadingDetails ? getCustomSpinner : Edit}
          text="Details"
          route={`${appRoutes.artistContentDetails(contentId)}?locale=${languageForm.values.locale}`}
          active={activeTab === 'details'}
          onClick={() => activeTabStore.setActiveTab('details')}
        />

        <TabItem
          icon={isWorkflowAIProcessing ? getCustomSpinner : SummaryAI}
          text="Summary"
          route={`${appRoutes.artistContentSummary(contentId)}?locale=${languageForm.values.locale}`}
          active={activeTab === 'summary'}
          disabled={!contentExists || isProcessingTranscript || transcriptStatus === 'failed'}
          onClick={() => activeTabStore.setActiveTab('summary')}
        />

        <TabItem
          icon={isWorkflowAIProcessing ? getCustomSpinner : TranscriptAI}
          text="Transcript"
          route={`${appRoutes.artistContentTranscript(contentId)}?locale=${languageForm.values.locale}`}
          active={activeTab === 'transcript'}
          disabled={!contentExists || isProcessingTranscript || transcriptStatus === 'failed'}
          onClick={() => activeTabStore.setActiveTab('transcript')}
        />

        <TabItem
          icon={isWorkflowAIProcessing ? getCustomSpinner : AiAnnouncement}
          text="Announcement"
          route={`${appRoutes.artistContentAnnouncement(contentId)}?locale=${languageForm.values.locale}`}
          active={activeTab === 'announcement'}
          disabled={!contentExists || isProcessingTranscript || transcriptStatus === 'failed'}
          onClick={() => activeTabStore.setActiveTab('announcement')}
        />

        <TabItem
          icon={isWorkflowAIProcessing ? getCustomSpinner : AiEmailCampaign}
          text="Email"
          route={`${appRoutes.artistContentEmail(contentId)}?locale=${languageForm.values.locale}`}
          active={activeTab === 'email'}
          disabled={!contentExists || isProcessingTranscript || transcriptStatus === 'failed'}
          onClick={() => activeTabStore.setActiveTab('email')}
        />
        {isVideo && isDefaultLocale && (
          <TabItem
            icon={VideoCamera}
            text={
              <>
                Social Clips
                <Text
                  variant={typography.body_sm}
                  className="ml-2 rounded bg-[#ECF9ED] px-1.5 py-0.5 !font-bold text-[#1CAB5F]"
                >
                  NEW
                </Text>
              </>
            }
            route={`${appRoutes.artistContentSocialClips(contentId)}?locale=${languageForm.values.locale}`}
            active={activeTab === 'social-clips'}
            disabled={!contentExists}
            onClick={() => activeTabStore.setActiveTab('social-clips')}
          />
        )}
        {permissions.has(Permissions.STUDIO_ADMIN) && (
          <TabItem
            icon={isWorkflowAIProcessing ? getCustomSpinner : AiArticle}
            text="Article"
            route={`${appRoutes.artistContentArticle(contentId)}?locale=${languageForm.values.locale}`}
            active={activeTab === 'article'}
            disabled={!contentExists}
            onClick={() => activeTabStore.setActiveTab('article')}
          />
        )}
        {permissions.has(Permissions.STUDIO_ADMIN) && (
          <TabItem
            icon={isWorkflowAIProcessing ? getCustomSpinner : AiStudyGuide}
            text="Study Guide"
            route={`${appRoutes.artistContentStudyGuide(contentId)}?locale=${languageForm.values.locale}`}
            active={activeTab === 'study-guide'}
            disabled={!contentExists}
            onClick={() => activeTabStore.setActiveTab('study-guide')}
          />
        )}
      </div>

      <WorkflowAIStatus />
    </div>
  );
}
