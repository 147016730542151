import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import s3Service from '@pray/shared/services/s3Service';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useDonationFund from 'hooks/donationFunds/useDonationFund';
import useSaveDonationFund from 'hooks/donationFunds/useSaveDonationFund';

export default function useCreateEditFundPage() {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const { saveDonationFund } = useSaveDonationFund(artistId);

  const [isSaving, setIsSaving] = useState(false);

  const form = useForm({
    defaultValues: {
      id: null,
      name: '',
      description: '',
      imageUrl: '',
      imageFile: null,
      isEnabled: true,
      isDefault: false,
      defaultAmountMonthly: '',
      defaultAmountYearly: '',
      amountMonthly2: '',
      amountYearly2: '',
      includeInAppPromotions: false,
    },
  });

  const { id: paramFundId } = useParams();
  const fundId = paramFundId ?? form.getValues('id');
  const { data: fund, isLoading } = useDonationFund(artistId, fundId);

  useEffect(() => {
    if (!fund) return;

    form.reset({
      id: fund.id,
      name: fund.name || '',
      description: fund.description || '',
      imageUrl: fund.imageUrl || '',
      isEnabled: !!fund.isEnabled,
      isDefault: !!fund.isDefault,
      defaultAmountMonthly: String(fund.defaultAmountMonthly || ''),
      defaultAmountYearly: String(fund.defaultAmountYearly || ''),
      amountMonthly2: String(fund.amountMonthly2 || ''),
      amountYearly2: String(fund.amountYearly2 || ''),
      includeInAppPromotions: !!fund.includeInAppPromotions,
    });
  }, [fund]);

  const formData = form.watch();

  async function handleUploadImage(fundId, imageFile) {
    if (imageFile) {
      try {
        const { url: imageUrl } = await s3Service.signAndUpload({
          file: imageFile,
          type: 'fundImage',
          signParams: {
            fund_id: fundId || null,
          },
        });

        return imageUrl;
      } catch (error) {
        logger.error('Failed uploading fund image', error);
        form.setError('imageUrl', { message: error.message });
      }
    }

    return formData.imageUrl;
  }

  async function handleSaveFund() {
    const formData = form.getValues();

    if (!formData.imageUrl && !formData.imageFile) {
      form.setError('imageUrl', { message: 'Fund image is required' });
      return;
    }

    try {
      const data = {
        fundId: formData.id,
        name: formData.name,
        description: formData.description,
        imageUrl: formData.imageUrl,
        isEnabled: formData.isEnabled,
        defaultAmountMonthly: Number(formData.defaultAmountMonthly),
        defaultAmountYearly: Number(formData.defaultAmountYearly),
        includeInAppPromotions: formData.includeInAppPromotions,
      };

      data.amountMonthly2 = formData.amountMonthly2 ? Number(formData.amountMonthly2) : null;
      data.amountYearly2 = formData.amountYearly2 ? Number(formData.amountYearly2) : null;

      setIsSaving(true);

      if (!data.fundId) {
        const fund = await saveDonationFund(data);
        data.fundId = fund.id;
      }

      data.imageUrl = await handleUploadImage(data.fundId, formData.imageFile);

      await saveDonationFund(data);
      toast.show({ success: `Your fund "${formData.name}" has been saved` });

      if (!formData.id) {
        navigate(-1);
      }
    } catch (error) {
      logger.error('Failed saving fund', error);

      if (error.cause) {
        form.setError(error.cause, { message: error.message });
      }

      throw error;
    } finally {
      setIsSaving(false);
    }
  }

  return {
    form,
    formData,
    isSaving,
    isLoading,
    handleSaveFund,
  };
}
