import api from '@pray/shared/utils/api';

export async function triggerContentTranslation({ artistId = null, contentId = null, locale = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');

  const data = {};

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
