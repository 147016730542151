import React from 'react';

const SkipForwardIcon = (props) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.71669 6.3999C7.4338 6.3999 7.16248 6.51228 6.96245 6.71232C6.76241 6.91236 6.65003 7.18367 6.65003 7.46657C6.65002 7.46796 6.65002 7.46935 6.65003 7.47074V15.9999V24.5291C6.65002 24.5305 6.65002 24.5318 6.65003 24.5332C6.65003 24.8161 6.76241 25.0874 6.96245 25.2875C7.16248 25.4875 7.4338 25.5999 7.71669 25.5999C7.94393 25.5995 8.16509 25.5265 8.34794 25.3916H8.35003C8.35988 25.3841 8.3696 25.3765 8.37919 25.3687L20.0271 16.8978C20.1774 16.8011 20.301 16.6683 20.3865 16.5114C20.4721 16.3545 20.5168 16.1786 20.5167 15.9999C20.5166 15.8248 20.4734 15.6525 20.3909 15.4981C20.3084 15.3437 20.1892 15.2119 20.0438 15.1145L8.35003 6.60824C8.1666 6.4729 7.94464 6.39989 7.71669 6.3999ZM24.25 6.3999C23.3668 6.3999 22.65 7.1167 22.65 7.9999V23.9999C22.65 24.8831 23.3668 25.5999 24.25 25.5999C25.1332 25.5999 25.85 24.8831 25.85 23.9999V7.9999C25.85 7.1167 25.1332 6.3999 24.25 6.3999Z"
        fill="white"
      />
    </svg>
  );
};

export default SkipForwardIcon;
