import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useForm from '@pray/shared/hooks/useForm';
import logger from '@pray/shared/utils/logger';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { useStudioContext } from 'context/StudioContext';
import useGetDailySeriesCoverArts from 'hooks/dailySeries/useGetDailySeriesCoverArts';
import useSaveDailySeriesCoverArt from 'hooks/dailySeries/useSaveDailySeriesCoverArt';

const initialValues = {
  square_image_url: '',
  portrait_image_url: '',
  landscape_image_url: '',
  // Store the image files in the form state
  image_landscape_file: null,
  image_portrait_file: null,
  image_square_file: null,
};

export default function DailyCoverArt() {
  const toast = useToastMessage();

  // Fetch daily series cover arts
  const { id: dailySeriesId } = useParams();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { isLoading, data: coverArtData } = useGetDailySeriesCoverArts(artistId, dailySeriesId);
  const { saveCoverArts, isSaving } = useSaveDailySeriesCoverArt(artistId, dailySeriesId);

  const form = useForm(initialValues);

  useEffect(() => {
    if (!coverArtData) return;
    form.setValues({
      ...form.values,
      square_image_url: coverArtData.square_image_url || '',
      portrait_image_url: coverArtData.portrait_image_url || '',
      landscape_image_url: coverArtData.landscape_image_url || '',
    });
  }, [coverArtData]);

  const handleSaveCoverArts = async () => {
    try {
      const payload = {
        imageSquareInput: form.values.image_square_file || form.values.square_image_url,
        imagePortraitInput: form.values.image_portrait_file || form.values.portrait_image_url,
        imageLandscapeInput: form.values.image_landscape_file || form.values.landscape_image_url,
      };
      await saveCoverArts(payload);
      toast.show({ success: 'Series updated successfully' });
    } catch (err) {
      logger.error(err);
      toast.show({ error: 'A problem happened when saving cover arts. Please try again later.' });
    }
  };

  const renderTopRightButtons = () => {
    return (
      <div className="hidden md:block">
        <div className="flex flex-1 space-x-3">
          <ActionButton
            isLoading={isSaving}
            disabled={isSaving}
            text={isSaving ? 'Saving' : 'Save'}
            onClick={handleSaveCoverArts}
          />
        </div>
      </div>
    );
  };

  return (
    <TabPage title={!isLoading ? 'Cover Art' : ''} topRightButtons={renderTopRightButtons()}>
      <div className="flex-col gap-10">
        <div className="mt-8">
          <MediaUpload
            imageUrl={form.values.square_image_url}
            title="Square"
            labelPlacement="right"
            description="Use a picture that’s 1080x1080 pixels and 4MB or less. Use a PNG or JPEG."
            uploadButtonLabel={form.values.square_image_url ? 'Change Image' : 'Upload Image'}
            error={form.errors.square_image_url}
            cropProps={{ aspect: 1 }}
            onFileChange={(file) => form.setValue('image_square_file', file)}
            onError={(error) => form.setError('square_image_url', error)}
          />
        </div>

        <div className="mt-8">
          <MediaUpload
            imageUrl={form.values.portrait_image_url}
            title="Portrait"
            labelPlacement="right"
            description="Use a picture that’s 1640x2380 pixels and 4MB or less. Use a PNG or JPEG."
            uploadButtonLabel={form.values.portrait_image_url ? 'Change Image' : 'Upload Image'}
            error={form.errors.portrait_image_url}
            cropProps={{ aspect: 3 / 4 }}
            onFileChange={(file) => form.setValue('image_portrait_file', file)}
            onError={(error) => form.setError('portrait_image_url', error)}
          />
        </div>

        <div className="mt-8">
          <MediaUpload
            imageUrl={form.values.landscape_image_url}
            title="Landscape"
            labelPlacement="right"
            description="Use a picture that’s 1640x920 pixels and 4MB or less. Use a PNG or JPEG."
            uploadButtonLabel={form.values.landscape_image_url ? 'Change Image' : 'Upload Image'}
            error={form.errors.landscape_image_url}
            cropProps={{ aspect: 7 / 4 }}
            onFileChange={(file) => form.setValue('image_landscape_file', file)}
            onError={(error) => form.setError('landscape_image_url', error)}
          />
        </div>
      </div>
    </TabPage>
  );
}
