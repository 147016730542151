import React, { useState } from 'react';

import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { Tabs } from 'components/pages/StudioPage/components/Tabs/Tabs';
import TestimonialCard from 'components/pages/StudioPage/components/TestimonialCard/TestimonialCard';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useDeleteTestimonial from 'hooks/testimonials/useDeleteTestimonial';
import useTestimonials from 'hooks/testimonials/useTestimonials';
import { Insights } from 'images/icons';

import ReportHeader from '../../../components/ReportHeader/ReportHeader';

export default function TestimonialsReport({ form }) {
  const [testimonialToDelete, setTestimonialToDelete] = useState(null);
  const [testimonialsType, setTestimonialsType] = useState(null);

  const { startDate, endDate } = form.values;
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const { deleteTestimonial } = useDeleteTestimonial();

  const {
    summary: data,
    data: paginatedData,
    isLoading,
    isFetching,
    hasPreviousPage,
    hasNextPage,
    fetchNextPage,
  } = useTestimonials({
    type: testimonialsType,
    artistId,
    startDate,
    endDate,
  });

  const isEmpty = !isFetching && !hasPreviousPage && !hasNextPage;

  const testimonials = paginatedData?.pages.flatMap((page) => page.data) || [];

  const totalTestimonialsWithSentiment =
    data.total_positive_testimonials + data.total_neutral_testimonials + data.total_negative_testimonials;

  let percentagePositive = 0;
  let percentageNeutral = 0;
  let percentageNegative = 0;

  if (totalTestimonialsWithSentiment > 0) {
    percentagePositive = Math.round((data.total_positive_testimonials / totalTestimonialsWithSentiment) * 100);
    percentageNeutral = Math.round((data.total_neutral_testimonials / totalTestimonialsWithSentiment) * 100);
    percentageNegative = Math.round((data.total_negative_testimonials / totalTestimonialsWithSentiment) * 100);
  }

  const activeTab = testimonialsType === 'daily' ? 1 : 0;

  const tabs = [
    {
      title: <TabTitle text="Profile" count={data.total_profile_testimonials || 0} selected={activeTab === 0} />,
    },
    {
      title: <TabTitle text="Dailies" count={data.total_daily_testimonials || 0} selected={activeTab === 1} />,
    },
  ];

  const handleActiveTabChange = (index) => {
    setTestimonialsType(index === 0 ? 'profile' : 'daily');
  };

  return (
    <TabPage data-viewname={VIEW_EVENT_NAMES.TESTIMONIALS}>
      <div className="sticky top-0 z-20 -my-6 flex max-w-7xl flex-col gap-6 bg-white pt-8">
        <ReportHeader form={form} title="Testimonials" />
      </div>

      <div className="flex max-w-7xl flex-col gap-6 py-6">
        <div className="sticky top-20 z-10 bg-white py-3">
          <Tabs tabStyle="pills" tabs={tabs} activeTab={activeTab} onChange={handleActiveTabChange} />
        </div>
        <div className="flex gap-6">
          <div className="mb-3 flex flex-1 flex-col gap-3">
            {(() => {
              if (isLoading) {
                return (
                  <div className="flex h-60 items-center justify-center">
                    <Spinner color="black" size="large" />
                  </div>
                );
              }

              if (isEmpty) {
                return (
                  <EmptyState
                    icon={<Insights />}
                    title="No testimonials yet"
                    subtitle="Your testimonials will be displayed here"
                  />
                );
              }

              return (
                <>
                  {testimonials.map((testimonial) => (
                    <TestimonialCard
                      key={testimonial.id}
                      userProfileImage={testimonial.author_profile_image}
                      userName={testimonial.author_name}
                      testimonialDate={testimonial.created_at}
                      sentiment={testimonial.sentiment}
                      testimonialTitle={testimonial.title}
                      testimonialBody={testimonial.body}
                      onDelete={() => setTestimonialToDelete(testimonial)}
                    />
                  ))}

                  <InfiniteScroll key={testimonialsType || 'profile'} fetchData={fetchNextPage} />
                </>
              );
            })()}

            <DeleteContentModal
              isOpen={!!testimonialToDelete}
              title="Delete this testimonial?"
              message="You are about to delete this testimonial. This action cannot be undone. Are you sure you want to delete it?"
              onClose={() => setTestimonialToDelete(null)}
              onDelete={async () => {
                const testimonialId = testimonialToDelete.id;
                await deleteTestimonial({ artistId, testimonialId });
                setTestimonialToDelete(null);
              }}
            />
          </div>

          {!isLoading && !!data.total_testimonials && (
            <div>
              <div className="sticky top-36 z-10 flex w-64 flex-col gap-6 rounded-lg border border-[#CBCDD2] p-6">
                <div className="flex flex-col gap-6">
                  <Text variant="headline_small" className="font-medium">
                    Testimonials
                  </Text>

                  <div className="flex flex-col gap-1">
                    <Text className="text-[36px] font-bold leading-9 opacity-80">{data.total_testimonials}</Text>
                    <Text variant="subhead_small" className="font-medium text-[#71747B]">
                      For selected range
                    </Text>
                  </div>
                </div>

                <div className="border-t border-[#CBCDD2]" />

                <Text variant="headline_small" className="font-medium">
                  Sentiment Breakdown
                </Text>

                <div className="flex flex-col gap-3">
                  <ProgressBar
                    color="#52B27F"
                    label="Positive"
                    value={data.total_positive_testimonials}
                    percentage={percentagePositive}
                  />
                  <ProgressBar
                    color="#71747B"
                    label="Neutral"
                    value={data.total_neutral_testimonials}
                    percentage={percentageNeutral}
                  />
                  <ProgressBar
                    color="#EA4345"
                    label="Negative"
                    value={data.total_negative_testimonials}
                    percentage={percentageNegative}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </TabPage>
  );
}

const TabTitle = ({ text, count, selected }) => {
  return (
    <div className="flex gap-1">
      <Text variant="subhead_small" className={`font-medium ${selected ? 'text-white' : 'text-black'}`}>
        {text}
      </Text>
      <Text variant="subhead_small" className={`font-medium ${selected ? 'text-white' : 'text-[#71747B]'}`}>
        ({count})
      </Text>
    </div>
  );
};

const ProgressBar = ({ color, label, value, percentage }) => {
  return (
    <div className="flex flex-col gap-1" data-testid={`ProgressBar-${label}`}>
      <div className="relative h-1.5 rounded-lg bg-[#E8E9ED]">
        <div
          className="absolute left-0 top-0 h-1.5 rounded-lg"
          style={{ width: `${percentage}%`, backgroundColor: color }}
        />
      </div>

      <div className="flex flex-row items-center justify-between">
        <Text variant="subhead_small" className="font-medium text-[#71747B]">
          {label}
        </Text>
        <div className="flex gap-1">
          <Text variant="subhead_small" className="font-medium">
            {value}
          </Text>
          <Text variant="subhead_small" className="font-medium text-[#71747B]">
            ({percentage}%)
          </Text>
        </div>
      </div>
    </div>
  );
};
