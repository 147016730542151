import React from 'react';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import artistAvatarPlaceholder from '@pray/shared/images/anonymous.svg';

import AppBar from 'components/pages/StudioPage/components/AppBar/AppBar';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import { ChevronLeft, Send } from 'images/icons';

import { TemplatePreviewDesktop } from './DesktopPreview/TemplatePreviewDesktop';
import { TemplatePreviewDesktopContent } from './DesktopPreview/TemplatePreviewDesktopContent';
import { TemplatePreviewDesktopHeadline } from './DesktopPreview/TemplatePreviewDesktopHeadline';
import { TemplatePreviewMobile } from './MobilePreview/TemplatePreviewMobile';
import { TemplatePreviewMobileContainer } from './MobilePreview/TemplatePreviewMobileContainer';

export default function TemplatePreviewModal({ subjectText, html, onBack, onSend = null }) {
  const { selectedArtist } = useStudioContext();
  const artistName = selectedArtist?.name;
  const artistProfileImageUrl = selectedArtist?.profile_image_url || artistAvatarPlaceholder;

  const previewHtml = `
    <main style="overflow-x:hidden;">
      ${html}
    </main>
`;

  return (
    <div data-viewname={VIEW_EVENT_NAMES.EMAILS.PREVIEW} className="fixed right-0 top-0 z-[100] size-full bg-white">
      <AppBar />

      <header className="flex h-[72px] items-center justify-between border-b border-[#DEDFE3] pl-8 pr-4">
        <div className="flex items-center">
          <Button variant="secondary" className="flex items-center gap-2 pl-2 pr-3" onClick={() => onBack()}>
            <ChevronLeft />
            Back
          </Button>
        </div>

        <Text variant="h1" className="flex-1 text-center">
          Preview
        </Text>

        {onSend && (
          <div className="flex items-center">
            <Button variant="primary" className="flex items-center gap-2 pl-2 pr-3" onClick={onSend}>
              <Send />
              Send Test
            </Button>
          </div>
        )}
      </header>

      <main className="flex h-screen flex-row gap-10 bg-gray-100 p-8">
        <TemplatePreviewDesktop>
          <TemplatePreviewDesktopHeadline
            profileImageUrl={artistProfileImageUrl}
            from={artistName}
            subject={subjectText}
          />
          <TemplatePreviewDesktopContent>
            <iframe srcDoc={html} title="Email Preview" className="size-full border-none" />
          </TemplatePreviewDesktopContent>
        </TemplatePreviewDesktop>

        <TemplatePreviewMobile>
          <TemplatePreviewMobileContainer>
            <div className="relative flex grow flex-col items-center justify-center">
              <iframe srcDoc={previewHtml} title="Email Preview" className="flex size-full grow" />
            </div>
          </TemplatePreviewMobileContainer>
        </TemplatePreviewMobile>
      </main>
    </div>
  );
}
