import api from '@pray/shared/utils/api';

import { GetAllContentSeriesResponse } from './types';

// TODO: refactor executeGetRequest options type to easy export that
type GetAllContentSeriesOptions = Parameters<typeof api.executeGetRequest>[2];

type GetAllContentSeriesProps = {
  artistId: string;
  search?: string;
  offset?: number;
  limit?: number;
  sortColumn?: string;
  sortDirection?: string;
  includeRssSeries?: boolean;
  options?: GetAllContentSeriesOptions;
};

// TODO: use shared params type
/* eslint-disable camelcase */
type GetAllContentSeriesParams = {
  search?: string;
  offset?: number;
  limit?: number;
  sort_column?: string;
  sort_direction?: string;
  include_rss_series?: boolean;
};
/* eslint-enable camelcase */

export const getAllContentSeries = async ({
  artistId = null,
  search = '',
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
  includeRssSeries = true,
  options = {},
}: GetAllContentSeriesProps) => {
  if (!artistId) throw Error('Artist ID is required');

  const params: GetAllContentSeriesParams = {};

  if (search) {
    params.search = search;
  } else if (offset) {
    params.offset = offset;
  }

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  // If includeRssSeries is false, we will filter out any rss series.
  if (includeRssSeries) {
    params.include_rss_series = includeRssSeries;
  }

  const url = `/web/studio/artists/${artistId}/content-series`;
  const response = await api.executeGetRequest<GetAllContentSeriesResponse>(url, params, options);

  return response.data;
};
