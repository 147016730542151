import api from '@pray/shared/utils/api';

export async function updatePromoUnit({
  promoUnitId,
  artistId,
  header = undefined,
  description = undefined,
  imageUrl = undefined,
  ctaText = undefined,
  ctaUrl = undefined,
  isPublished = undefined,
}) {
  if (!promoUnitId) throw Error('Promo Unit ID is required');
  if (!artistId) throw Error('Artist ID is required');

  const data = {};

  if (header) data.header = header;
  if (description) data.description = description;
  if (imageUrl) data.image_url = imageUrl;
  if (ctaText) data.cta_text = ctaText;
  if (ctaUrl) data.cta_url = ctaUrl;
  if (isPublished !== undefined) data.is_published = isPublished;

  if (Object.keys(data).length === 0) return;

  const url = `/web/studio/artists/${artistId}/artist-promo-unit/${promoUnitId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
