import { useEffect, useState } from 'react';

import { useAuth } from '@pray/shared/hooks/useAuth';
import useForm from '@pray/shared/hooks/useForm';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import { useCreateArtist, useDeleteArtist, useUpdateArtist } from '@pray/shared/services/studioService/artist';
import logger from '@pray/shared/utils/logger';
import storage from '@pray/shared/utils/storage';
import { isValidUrl } from '@pray/shared/utils/validation';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { WEB_BASE_URI } from 'config.js';
import { LOCAL_STORAGE } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';
import { Permissions } from 'utils/auth';

const initialArtistData = {
  id: '',
  name: '',
  handle: '',
  description: '',
  giving_external_url: '',
  profile_image_url: '',
  profile_vertical_image_url: '',
  profile_horizontal_image_url: '',
  facecoin_image_url: '',
};

const handleMinLength = 3;
const handleMaxLength = 100;
const profileDescriptionMaxLength = 1000;
const profileNameMinLength = 2;
const profileNameMaxLength = 100;
const ministryNameMinLength = 2;
const ministryNameMaxLength = 100;

export default function useLeaderProfile() {
  const { authenticate, selectedArtist } = useStudioContext();
  const { permissions } = useAuth();
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();

  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { createArtist } = useCreateArtist();
  const { updateArtist } = useUpdateArtist();
  const { deleteArtist } = useDeleteArtist();

  const isExternalGiving = selectedArtist.giving_preference === 'external';
  const isGivingUrlEditable = isExternalGiving && permissions.has(Permissions.STUDIO_ADMIN);
  const isHandleEditable = !selectedArtist.handle || permissions.has(Permissions.UPDATE_ARTIST_HANDLE);

  function getInitialData() {
    const data = selectedArtist.id ? selectedArtist : initialArtistData;

    return {
      id: data.id || '',
      name: data.name || '',
      handle: data.handle || '',
      description: data.description || '',
      profile_image_url: data.profile_image_url || '',
      profile_vertical_image_url: data.profile_vertical_image_url || '',
      profile_horizontal_image_url: data.profile_horizontal_image_url || '',
      facecoin_image_url: data.facecoin_image_url || '',
      giving_external_url: data.giving_external_url || '',
      is_studio_analytics_enabled: data.is_studio_analytics_enabled || false,
      is_location_search_enabled: data.is_location_search_enabled || false,
      is_verified: data.is_verified || false,
      google_place_id: data.google_place_id || '',
      location_name: data.location_name || '',
      location_address: data.location_address || '',
      latitude: data.latitude || '',
      longitude: data.longitude || '',
    };
  }

  const form = useForm(getInitialData());

  const profileUrl = `${WEB_BASE_URI}/${selectedArtist.handle}`;

  useEffect(() => {
    if (Object.keys(form.errors).length) {
      validateFormData();
    }
  }, [form.values]);

  function validateFormData() {
    const errors = {};

    // Profile name
    if (
      form.values.name.trim().length < profileNameMinLength ||
      form.values.name.trim().length > profileNameMaxLength
    ) {
      errors.name = 'Invalid name';
    }

    // Profile URL handle
    const handleRegex = new RegExp(`^[a-z0-9]{${handleMinLength},${handleMaxLength}}$`, 'i');
    if (!handleRegex.test(form.values.handle.trim())) {
      errors.handle = 'Invalid URL';
    }

    // Profile description
    if (form.values.description.trim() && form.values.description.trim().length > profileDescriptionMaxLength) {
      errors.description = 'Invalid description';
    }

    // Leader giving url
    if (form.values.giving_external_url && !isValidUrl(form.values.giving_external_url, { scheme: 'https' })) {
      errors.giving_external_url = 'Invalid URL';
    }

    // Ministry name
    if (
      form.values.location_name.trim().length < ministryNameMinLength ||
      form.values.location_name.trim().length > ministryNameMaxLength
    ) {
      errors.location_name = 'Invalid ministry name';
    }

    form.setErrors(errors);

    return Object.keys(errors).length === 0;
  }

  async function uploadArtistImage(artistId, imageFieldName) {
    try {
      const { url: imageUrl } = await s3Service.signAndUpload({
        file: form.values[imageFieldName],
        type: 'artist',
        signParams: {
          artist_id: artistId || null,
        },
      });
      return imageUrl;
    } catch (err) {
      form.setError(imageFieldName, err);
    }
    return null;
  }

  async function handleSaveProfile() {
    if (!validateFormData()) return;

    const artistProfile = {
      name: form.values.name,
      handle: form.values.handle,
      description: form.values.description,
      profileImageUrl: form.values.profile_image_url,
      profileVerticalImageUrl: form.values.profile_vertical_image_url,
      profileHorizontalImageUrl: form.values.profile_horizontal_image_url,
      facecoinImageUrl: form.values.facecoin_image_url,
      givingExternalUrl: form.values.giving_external_url,
      isStudioAnalyticsEnabled: form.values.is_studio_analytics_enabled,
      isLocationSearchEnabled: form.values.is_location_search_enabled,
      isVerified: form.values.is_verified,
      googlePlaceId: form.values.google_place_id,
      locationName: form.values.location_name,
    };

    const { id: artistId, handle: artistHandle } = selectedArtist;
    const isNewArtistProfile = !artistId;

    try {
      setIsPublishing(true);

      // validate artist handle
      const { handle } = form.values;
      if (artistHandle !== handle) {
        try {
          await studioService.artist.validateArtistHandle({ handle });
        } catch (err) {
          form.setError('handle', err);
          return;
        }
      }

      // upload profile image
      if (permissions.has(Permissions.STUDIO_ADMIN) && form.values.profile_image_file) {
        const imageUrl = await uploadArtistImage(artistId, 'profile_image_file');
        if (!imageUrl) return;
        artistProfile.profileImageUrl = imageUrl;
      }

      // upload artist's extra image sizes
      if (permissions.has(Permissions.UPLOAD_EXTRA_IMAGE_SIZES)) {
        // upload vertical profile image
        if (form.values.profile_vertical_image_file) {
          const imageUrl = await uploadArtistImage(artistId, 'profile_vertical_image_file');
          if (!imageUrl) return;
          artistProfile.profileVerticalImageUrl = imageUrl;
        }

        // upload horizontal profile image
        if (form.values.profile_horizontal_image_file) {
          const imageUrl = await uploadArtistImage(artistId, 'profile_horizontal_image_file');
          if (!imageUrl) return;
          artistProfile.profileHorizontalImageUrl = imageUrl;
        }

        // upload facecoin image
        if (form.values.facecoin_image_file) {
          const imageUrl = await uploadArtistImage(artistId, 'facecoin_image_file');
          if (!imageUrl) return;
          artistProfile.facecoinImageUrl = imageUrl;
        }
      }

      // create/update artist profile
      if (isNewArtistProfile) {
        await createArtist(artistProfile);
        await authenticate();
      } else {
        await updateArtist({ artistId, ...artistProfile });
      }

      form.setErrors({});
      form.setChanged(false);

      toast.show({ success: 'Leader profile has been published' });
    } catch (err) {
      logger.debug('Failed to publish leader profile', err);
      toast.show({ error: 'Failed to save profile.' });
    } finally {
      setIsPublishing(false);
    }
  }

  async function handleDeleteArtist() {
    try {
      const artistId = form.values.id;

      // delete selected artist
      await deleteArtist({ artistId });

      // remove artist from local storage
      storage.removeFromStorage(LOCAL_STORAGE.SELECTED_ARTIST_ID);

      // navigate to the index page
      window.location.href = appRoutes.index();
    } catch (err) {
      logger.debug('Failed to delete leader profile', err);
      toast.show({ error: 'Failed to delete leader profile.' });
    }
  }

  return {
    form,
    isHandleEditable,
    isGivingUrlEditable,
    isDeleteModalOpen,
    isPublishing,
    profileUrl,
    validationValues: {
      handleMinLength,
      handleMaxLength,
      profileDescriptionMaxLength,
      profileNameMinLength,
      profileNameMaxLength,
      ministryNameMinLength,
      ministryNameMaxLength,
    },
    handleDeleteArtist,
    handleSaveProfile,
    setIsDeleteModalOpen,
  };
}
