import api from '@pray/shared/utils/api';

export async function addTeamMembers({ artistId, emails = [], roleName = 'editor' }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!emails?.length) throw Error('At least one member e-mail is required');
  if (!roleName) throw Error('Role name is required');

  const data = {
    emails,
    role_name: roleName,
  };

  const url = `/web/studio/artists/${artistId}/team-members`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
