import React, { useEffect, useRef, useState } from 'react';

import { Play } from '@pray/shared/components/ui/Icons/Play';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { MagicWand, Pause } from 'images/icons';

import styles from './PhonePreview.module.scss';

export default function PhonePreview({
  previewImage = null,
  previewAudio = null,
  previewVideo = null,
  body = '',
  reference = '',
  isLoading = false,
  children = null,
  loadingText = 'Your daily preview will appear here',
}) {
  const audioRef = useRef();
  const videoRef = useRef();
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);

  // Load and track audio progress
  useEffect(() => {
    if (!previewAudio) return () => {};

    const handleTimeUpdate = () => {
      if (audioRef.current?.currentTime) {
        setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
        return;
      }

      setProgress(0);
    };

    const handleEnded = () => {
      setProgress(0);
      setPlaying(false);
    };

    audioRef.current = new Audio(previewAudio);
    audioRef.current?.load();
    audioRef.current?.addEventListener('timeupdate', handleTimeUpdate);
    audioRef.current?.addEventListener('ended', handleEnded);

    return () => {
      audioRef.current?.pause();
      audioRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      audioRef.current?.removeEventListener('ended', handleEnded);
    };
  }, [previewAudio]);

  // Update video progress
  useEffect(() => {
    if (!videoRef.current || !previewVideo) return () => {};

    const handleTimeUpdate = () => {
      if (videoRef.current?.currentTime) {
        setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
        return;
      }

      setProgress(0);
    };

    const handleEnded = () => {
      setProgress(0);
      setPlaying(false);
    };

    videoRef.current?.addEventListener('timeupdate', handleTimeUpdate);

    videoRef.current?.addEventListener('ended', handleEnded);

    return () => {
      videoRef.current?.pause();
      videoRef.current?.removeEventListener('timeupdate', handleTimeUpdate);
      videoRef.current?.removeEventListener('ended', handleEnded);
    };
  }, [previewVideo]);

  const toggleAudio = () => {
    if (!audioRef.current || !previewAudio) return;

    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setPlaying(!playing);
  };

  const toggleVideo = () => {
    if (!videoRef.current || !previewVideo) return;
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setPlaying(!playing);
  };

  const getPlaceHolderPreview = () => {
    return (
      <div className={styles.phoneScreen}>
        <div className={styles.previewPlaceholder}>
          {isLoading ? <Spinner /> : <MagicWand />}

          <Text className={styles.text}>{loadingText}</Text>
        </div>
      </div>
    );
  };

  const getPreviewDaily = () => {
    return (
      <div className={styles.phoneScreen}>
        <img src={previewImage} alt="preview" />

        <div className={styles.textView}>
          <Text className={styles.body}>{body}</Text>
          <Text className={styles.reference}>{reference}</Text>
        </div>

        <div className={styles.bottomRow}>
          <button type="button" onClick={toggleAudio}>
            {playing ? <Pause /> : <Play fill="#fff" />}
          </button>

          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: progress }} />
          </div>
        </div>
      </div>
    );
  };

  const getPreviewVideo = () => {
    return (
      <div className={styles.phoneScreen}>
        <video ref={videoRef} src={previewVideo} className={styles.videoPreview}>
          <track kind="captions" />
        </video>

        <div className={styles.bottomRow}>
          <button type="button" onClick={toggleVideo}>
            {playing ? <Pause /> : <Play fill="#fff" />}
          </button>

          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: progress }} />
          </div>
        </div>
      </div>
    );
  };

  const getChildren = () => {
    return (
      <div className={styles.childScreen}>
        <div className="mt-10">{children}</div>
      </div>
    );
  };

  const renderPhoneContent = () => {
    if (children) return getChildren();
    if (previewVideo) return getPreviewVideo();
    if (previewImage) return getPreviewDaily();
    return getPlaceHolderPreview();
  };

  return (
    <div className={styles.phoneBorder}>
      <div className={styles.phoneIsland} />
      {renderPhoneContent()}
    </div>
  );
}
