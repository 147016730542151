import React from 'react';

const MinimizeIcon = (props) => {
  return (
    <svg viewBox="0 0 18 10" width="18" height="10" {...props}>
      <path
        d="M0.292893 0.292893C-0.097631 0.683417 -0.0976311 1.31658 0.292893 1.70711L8.29289 9.70711C8.68342 10.0976 9.31658 10.0976 9.7071 9.70711L17.7071 1.70711C18.0976 1.31659 18.0976 0.68342 17.7071 0.292896C17.3166 -0.0976282 16.6834 -0.0976283 16.2929 0.292896L9 7.58579L1.70711 0.292893C1.31658 -0.097631 0.683418 -0.0976311 0.292893 0.292893Z"
        fill="white"
      />
    </svg>
  );
};

export default MinimizeIcon;
