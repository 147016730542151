import React from 'react';

import { cn } from '@pray/shared/utils/styles';

import { colors, typography } from '../../foundations';
import Text from '../Text';
import { ACTIVE_BORDER_CLASS, INACTIVE_BORDER_CLASS, INDICATOR_SIZE } from './constants';

type RoundedNumberProps = {
  /** The number to display */
  number: number;
  /** Whether this number is for the active step */
  isActive: boolean;
  /** Size class for the number indicator */
  size?: string;
  /** Active border class */
  activeBorderClass?: string;
  /** Inactive border class */
  inactiveBorderClass?: string;
};

/**
 * RoundedNumber component
 * Displays a number inside a rounded border, with different styling based on active state
 */
export default function RoundedNumber({
  number,
  isActive,
  size = INDICATOR_SIZE,
  activeBorderClass = ACTIVE_BORDER_CLASS,
  inactiveBorderClass = INACTIVE_BORDER_CLASS,
}: RoundedNumberProps) {
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded-full border-2',
        size,
        isActive ? activeBorderClass : inactiveBorderClass
      )}
    >
      <Text variant={typography.heading_sm} color={isActive ? colors.text_primary : colors.text_tertiary}>
        {number}
      </Text>
    </div>
  );
}
