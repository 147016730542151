import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

type UploadBookParams = {
  artistId: string;
  fileUrl: string;
};

export function useUploadBook() {
  async function uploadBook({ artistId, fileUrl }: UploadBookParams) {
    await studioService.books.uploadBook({
      artistId,
      fileUrl,
    });
  }

  const mutation = useMutation({
    mutationFn: uploadBook,
  });

  const { mutateAsync } = mutation;

  return {
    uploadBook: mutateAsync,
  };
}
