import React from 'react';

export function ChevronLeft(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5202 2.97982C10.7155 3.17508 10.7155 3.49167 10.5202 3.68693L6.20711 8.00004L10.5202 12.3132C10.7155 12.5084 10.7155 12.825 10.5202 13.0203C10.325 13.2155 10.0084 13.2155 9.81311 13.0203L5.14645 8.35359C4.95118 8.15833 4.95118 7.84175 5.14645 7.64649L9.81311 2.97982C10.0084 2.78456 10.325 2.78456 10.5202 2.97982Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
