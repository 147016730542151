import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export async function updateContentTranscriptMultiVoices({
  artistId = null,
  contentId = null,
  locale = null,
  targetLocale = null,
  multiVoicesTranscript = null,
  useExistingMultiVoicesTranscript = null,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');
  if (!targetLocale) throw Error('Target locale is required');

  if (!multiVoicesTranscript && !useExistingMultiVoicesTranscript) {
    throw Error('Multi-voices transcript or use existing multi-voices transcript is required');
  }

  const data = {
    target_locale: targetLocale,
  };

  if (multiVoicesTranscript) {
    data.multi_voices_transcript = multiVoicesTranscript;
  }

  if (useExistingMultiVoicesTranscript) {
    data.use_existing_multi_voices_transcript = useExistingMultiVoicesTranscript;
  }

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/transcripts/multi-voices`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}

export function useUpdateContentTranscriptMultiVoices() {
  const queryClient = useQueryClient();

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: updateContentTranscriptMultiVoices,
    onSuccess: (_, { artistId, contentId, locale }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistContentDetailsLocaleTranscriptMultiVoices(artistId, contentId, locale),
      });
    },
  });

  return { updateContentTranscriptMultiVoices: mutateAsync, ...rest };
}
