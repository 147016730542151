import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

/* eslint-disable camelcase */
/**
 * Enum representing the possible statuses of a course episode regeneration process
 */
export enum RegenerationStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
}

export type CourseEpisode = {
  id: string;
  title: string;
  content: string;
};

export type GetEpisodesResponse = ObjectResponse<CourseEpisode[]>;

export type RegenerateEpisodeResponse = ObjectResponse<{
  id: string;
  generation_id: string;
  message: string;
  status: RegenerationStatus;
}>;

export type CreateEpisodeResponse = ObjectResponse<{
  id: string;
  course_id: string;
  title: string;
  transcript: string;
}>;

export type UpdateEpisodeResponse = ObjectResponse<{
  id: string;
  course_id: string;
  title: string;
  transcript: string;
}>;

export type DeleteEpisodeResponse = ObjectResponse<{
  id: string;
  message: string;
}>;
/* eslint-enable camelcase */
