import api from '@pray/shared/utils/api';

export async function resendTeamMemberEmail({ artistId, teamMemberId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!teamMemberId) throw Error('Team Member ID is required');

  const data = {};

  const url = `/web/studio/artists/${artistId}/team-members/${teamMemberId}/resend-email`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
