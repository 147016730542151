import { isOnBrowser } from '.';

export const parseFromQuery = (attribute) => {
  if (typeof window === 'undefined' || !window.location) return null;

  const parsedParams = parseQueryString(window.location.search);
  return parsedParams[attribute];
};

export const removeQueryParamsFromUrl = ({ only = [], except = [] } = {}) => {
  if (!isOnBrowser() || !window.location || !window.location.search) return;

  const params = parseQueryString();

  if (only.length) {
    Object.keys(params)
      .filter((key) => only.includes(key))
      .forEach((key) => delete params[key]);
  } else {
    Object.keys(params)
      .filter((key) => !except.includes(key))
      .forEach((key) => delete params[key]);
  }

  const uri = window.location.toString();
  const queryString = Object.keys(params).length ? stringifyQueryString(params) : '';
  const cleanUri = uri.split('?')[0] + (queryString ? `?${queryString}` : '');

  window.history.replaceState(window.history.state, document.title, cleanUri);
};

export function parseQueryString(queryString) {
  const query = {};

  if (typeof window === 'undefined' || !window.location) return query;

  const qs = queryString ?? window.location.search;
  const pairs = (qs[0] === '?' ? qs.substr(1) : qs).split('&');

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i].split('=');

    if (pair[1] !== 'null' && pair[1] !== '' && pair[1] !== ',') {
      const key = decodeURIComponent(pair[0]);
      const val = decodeURIComponent(pair[1] || '');
      if (key) query[key] = val;
    }
  }

  return query;
}

export function stringifyQueryString(queryParams) {
  if (!queryParams) queryParams = parseQueryString();

  const queryString = Object.keys(queryParams)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');

  return queryString;
}
