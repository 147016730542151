import { DateTime } from 'luxon';
import React, { useMemo, useState, useEffect } from 'react';
import slugify from 'slugify';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import Text from '@pray/shared/components/ui/Text/Text';
import TimePicker from '@pray/shared/components/ui/TimePicker';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { CONTENT_VISIBILITY } from '@pray/shared/constants';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import { capitalize } from '@pray/shared/utils';

import { layout } from 'components/layouts/constants';
import { useStudioContext } from 'context/StudioContext';
import { cn } from 'styles/utils';
import { isSafari } from 'utils/navigation';

import styles from './ContentVisibilityModal.module.scss';

export default function ContentVisibilityModal({ data, anchorEl, onClose, onSuccess, onError }) {
  const [isSaving, setIsSaving] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const initialValues = useMemo(() => {
    let visibility = CONTENT_VISIBILITY.PUBLIC;
    if (!data?.is_published) {
      visibility = CONTENT_VISIBILITY.PRIVATE;
    } else if (DateTime.now() < DateTime.fromISO(data?.published_at)) {
      visibility = CONTENT_VISIBILITY.SCHEDULE;
    }

    // If schedule date exists but is in the past, initialise the calendar with current date
    // If published_at date doesn't exists and browser used is Safari, initialise with tomorrow's date
    // If published_at date doesn't exists for other browsers, initialise the calendar with null
    let initialScheduledDate = null;
    if (!data?.published_at && isSafari()) {
      // If published_at is not set and browser used is Safari, initiate for tomorrow date
      initialScheduledDate = new Date();
      initialScheduledDate.setDate(initialScheduledDate.getDate() + 1);
    } else if (data?.published_at) {
      initialScheduledDate =
        new Date().getTime() < new Date(data.published_at).getTime() ? new Date(data.published_at) : new Date();
    }

    return {
      visibility,
      scheduled_at_date: initialScheduledDate ? DateTime.fromJSDate(initialScheduledDate).toFormat('yyyy-MM-dd') : null,
      scheduled_at_time: initialScheduledDate ? DateTime.fromJSDate(initialScheduledDate).toFormat('HH:mm') : null,
      automation_emails: false,
      automation_announcements: false,
    };
  }, [data]);

  useEffect(() => {
    const mainArea = document.getElementById(layout.main);
    mainArea.classList.add('overflow-hidden');
    document.body.classList.add('overflow-hidden');

    return () => {
      mainArea.classList.remove('overflow-hidden');
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const form = useForm(initialValues);

  const validateFormData = () => {
    const errors = {};

    const { scheduled_at_date: scheduledDate, scheduled_at_time: scheduledTime, visibility } = form.values;

    // Check if the scheduled date and time are valid if the content is scheduled
    if (!(scheduledDate && scheduledTime) && visibility === CONTENT_VISIBILITY.SCHEDULE) {
      if (!scheduledDate) {
        errors.scheduled_at_date = 'Invalid date';
      }

      if (!scheduledTime) {
        errors.scheduled_at_time = 'Invalid time';
      }
    }

    // Check if the scheduled date time is in the past if the user selected the "schedule" option
    if (scheduledDate && scheduledTime && form.values.visibility === CONTENT_VISIBILITY.SCHEDULE) {
      const scheduledDateTime = DateTime.fromFormat(`${scheduledDate} ${scheduledTime}`, 'yyyy-MM-dd HH:mm');

      if (scheduledDateTime < DateTime.now()) {
        errors.scheduled_at_date = 'Past date';
        errors.scheduled_at_time = 'Past time';
      }
    }

    form.setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFormData()) return;

    setIsSaving(true);

    const {
      scheduled_at_date: scheduledDate,
      scheduled_at_time: scheduledTime,
      visibility,
      automation_emails: automationEmails,
      automation_announcements: automationAnnouncements,
    } = form.values;

    const isPublished = visibility !== CONTENT_VISIBILITY.PRIVATE;
    // When making the content private, published_at should be null
    let publishedAt =
      isPublished && scheduledDate && scheduledTime
        ? DateTime.fromFormat(`${scheduledDate} ${scheduledTime}`, 'yyyy-MM-dd HH:mm').toUTC()
        : null;

    // If the content is published and doesn't have a published_at date, we need to set it to now
    if (isPublished && !publishedAt) {
      publishedAt = DateTime.now().toUTC();
    }

    // If we are updating to the "Public" visibility, we need to set the published_at date to now
    // if the published_at date is in the future
    if (
      visibility === CONTENT_VISIBILITY.PUBLIC &&
      publishedAt &&
      // @ts-ignore - Luxon doesn't have a `isBefore` method
      DateTime.now() < DateTime.fromISO(publishedAt)
    ) {
      publishedAt = DateTime.now().toUTC();
    }

    try {
      const localizedTranslations = data.translations.filter((item) => item.is_localized).map((item) => item.locale);

      await studioService.content.updateArtistContent({
        artistId,
        contentId: data.id,
        isPublished,
        publishedAt,
        isEmailAutoGenerated: automationEmails,
        isAnnouncementAutoGenerated: automationAnnouncements,
        locales: localizedTranslations,
      });

      if (onSuccess) await onSuccess();
    } catch (error) {
      const message = error?.response?.data?.data?.[0]?.info ?? 'Failed to save visibility.';
      onError(capitalize(message));
    }
    setIsSaving(false);
  };

  function getButtonLabel() {
    if (form.values.visibility === CONTENT_VISIBILITY.PRIVATE) return 'Make Private';
    if (form.values.visibility === CONTENT_VISIBILITY.PUBLIC) return 'Publish';
    if (form.values.visibility === CONTENT_VISIBILITY.SCHEDULE) return 'Schedule';
    return 'Save';
  }

  const modalTop = useMemo(() => {
    const { top } = anchorEl.getBoundingClientRect();
    const modalHeight = 480;
    const topMargin = 180;
    const bottomMargin = 220;
    return top + modalHeight > window.innerHeight ? `${top - topMargin}px` : `${top + bottomMargin}px`;
  }, [anchorEl]);

  return (
    <div className="fixed inset-0 left-[250px] z-[100] -translate-y-1/2" style={{ top: modalTop }}>
      <div className={styles.modal}>
        <Text className="text-lg font-bold">Edit your episode visibility</Text>

        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-3">
            <VisibilityOption
              name="visibility"
              label="Public"
              value={CONTENT_VISIBILITY.PUBLIC}
              checked={form.values.visibility === CONTENT_VISIBILITY.PUBLIC}
              onChange={(event) => form.setValue('visibility', event.target.value)}
            />

            <VisibilityOption
              name="visibility"
              label="Private"
              value={CONTENT_VISIBILITY.PRIVATE}
              checked={form.values.visibility === CONTENT_VISIBILITY.PRIVATE}
              onChange={(event) => form.setValue('visibility', event.target.value)}
            />

            <VisibilityOption
              name="visibility"
              label="Schedule"
              value={CONTENT_VISIBILITY.SCHEDULE}
              checked={form.values.visibility === CONTENT_VISIBILITY.SCHEDULE}
              onChange={(event) => form.setValue('visibility', event.target.value)}
            >
              {form.values.visibility === CONTENT_VISIBILITY.SCHEDULE && <AutomationSchedule form={form} />}
            </VisibilityOption>
          </div>

          <div className="mt-4 flex justify-end gap-4">
            <Button disabled={isSaving} variant={buttons.variant.secondary} onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={isSaving} variant={buttons.variant.primary} onClick={handleSubmit}>
              {isSaving ? <Spinner /> : getButtonLabel()}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

function VisibilityOption({ name, value, checked, label, children = null, onChange }) {
  const id = `${name}-${slugify(value)}`;

  return (
    <div className={cn('flex gap-4 rounded-md border border-[#D4D4D4] p-4', checked && 'border-black')}>
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className={styles.option}
      />

      <div className="-mt-0.5 flex w-full flex-col">
        <label htmlFor={id}>
          <Text className="text-base font-bold">{label}</Text>
        </label>
        {children}
      </div>
    </div>
  );
}

function AutomationSchedule({ form }) {
  return (
    <div className="flex flex-col gap-4">
      <Text className="text-sm font-bold text-[#71747B]">Schedule as Public</Text>
      <div className="flex flex-row gap-3">
        <DatePicker
          label="Date"
          value={form.values.scheduled_at_date}
          minDate={DateTime.local().startOf('day').toJSDate()}
          onChange={(date) => form.setValue('scheduled_at_date', date)}
          errorMessage={form.errors.scheduled_at_date}
        />
        <TimePicker
          label="Time"
          value={form.values.scheduled_at_time}
          onChange={(time) => form.setValue('scheduled_at_time', time)}
          errorMessage={form.errors.scheduled_at_time}
        />
      </div>

      <Text variant={typography.heading_md} className="font-medium">
        AI Automation
      </Text>

      <div className="flex gap-3">
        <Checkbox
          checked={form.values.automation_emails}
          id="automation_emails"
          onChange={(value) => form.setValue('automation_emails', value.target.checked)}
        />
        <label htmlFor="automation_emails" className="flex flex-col">
          <Text variant={typography.subhead_small} className="font-medium">
            Emails
          </Text>
          <Text variant={typography.footnote} color={colors.text_secondary} className="font-medium">
            Automatically create and schedule emails
          </Text>
        </label>
      </div>

      <div className="flex gap-3">
        <Checkbox
          checked={form.values.automation_announcements}
          id="automation_announcements"
          onChange={(value) => form.setValue('automation_announcements', value.target.checked)}
        />
        <label htmlFor="automation_announcements" className="flex flex-col">
          <Text variant={typography.subhead_small} className="font-medium">
            Announcement
          </Text>
          <Text variant={typography.footnote} color={colors.text_secondary} className="font-medium">
            Automatically create and schedule announcements
          </Text>
        </label>
      </div>
    </div>
  );
}
