import imageCompression from 'browser-image-compression';
import React, { useEffect, useRef, useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { ErrorIcon } from '@pray/shared/components/ui/TextInput/TextInput';
import logger from '@pray/shared/utils/logger';

import ImageCropModal from './ImageCropModal/ImageCropModal';

import placeholderUser from './assets/placeholder-user.png';

import styles from './MediaUpload.module.scss';

// We support two versions of the label placement for the form input.
// - top
// In the version 1 of the form input the label is stacked above the image and upload button.
// - right
// In the version 2 of the form input the label and upload button are stacked to the right of the image.
// NOTE: By default, if no layout parameter is provided we will use top
export default function MediaUpload({
  placeholderImage = placeholderUser,
  display = 'default',
  labelPlacement = 'top',
  isCircleImage = false,
  isLoading = false,
  imageUrl = '',
  title = '',
  description = '',
  error = '',
  uploadButtonLabel,
  uploadButtonVariant = buttons.variant.secondary,
  secondaryButtonLabel = null,
  cropProps = null,
  onValidateImage = null,
  onSecondaryButtonClick = null,
  minifyImage = null,
  maxSizeMB = null,
  supportedFileTypes = [],
  onFileChange,
  required = false,
  onError,
}) {
  const fileInputRef = useRef(null);
  const [image, setImage] = useState(placeholderImage);
  const [loading, setLoading] = useState(isLoading);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  useEffect(() => {
    const newImage = imageUrl || placeholderImage;
    if (newImage !== image) {
      if (newImage instanceof File) {
        setImage(URL.createObjectURL(newImage));
      } else {
        setImage(newImage);
      }
    }
  }, [imageUrl]);

  const handleImageError = (event) => {
    event.target.src = placeholderImage;
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const clearFileInputValue = () => {
    fileInputRef.current.value = '';
  };

  const handleSelectedFile = async (file) => {
    if (maxSizeMB && file.size > maxSizeMB * 1024 * 1024) {
      onError(`File size exceeds ${maxSizeMB}MB. Please choose a smaller file.`);
      clearFileInputValue();
      return;
    }

    const fileType = file.name.split('.').pop();
    if (supportedFileTypes?.length && !supportedFileTypes.includes(fileType)) {
      const supportedExtensions = supportedFileTypes.join(', ');
      onError(
        `Unsupported file type. Please choose a file with one of the following extensions: ${supportedExtensions}.`
      );
      clearFileInputValue();
      return;
    }

    setLoading(true); // Start loading

    const URL = window.URL ?? window.webkitURL;
    const img = new Image();

    if (minifyImage) {
      const options = {
        maxSizeMB: minifyImage.maxSizeMB,
        maxWidthOrHeight: minifyImage.maxWidthOrHeight,
      };

      try {
        setImage(null);
        const compressedFile = await imageCompression(file, options);
        img.src = URL.createObjectURL(compressedFile);
      } catch (err) {
        logger.error('Error compressing image', err);
        img.src = URL.createObjectURL(file);
      }
    } else {
      img.src = URL.createObjectURL(file);
    }

    img.onload = () => {
      setLoading(false); // Stop loading when the image has loaded

      let isValidImage = false;
      try {
        isValidImage = !onValidateImage || onValidateImage(img);
      } catch (err) {
        onError(err.message || 'Invalid image. Please try again.');
        clearFileInputValue();
        return;
      }

      if (!isValidImage) {
        onError('Invalid image. Please try again.');
        clearFileInputValue();
        return;
      }

      setImage(img.src);
      if (error) {
        clearFileInputValue();
        onError(null);
      }

      if (onFileChange) {
        onFileChange(file);
        clearFileInputValue();
      }
    };

    img.onerror = () => {
      setLoading(false);
      clearFileInputValue();
      onError('Failed loading image. Please try again.');
    };
  };

  const handleCropImage = (event) => {
    if (!event.target.files?.length) return;
    setIsCropModalOpen(true);
  };

  const handleFileChange = (event) => {
    const [file] = event.target.files;
    handleSelectedFile(file);
  };

  const imageStyles = (labelPlacement) => {
    const classNames = [styles.image, isCircleImage && styles.circle];

    if (labelPlacement === 'right') {
      classNames.push(styles.layoutV2);
    }

    return classNames.filter(Boolean).join(' ');
  };

  const file = fileInputRef.current?.files?.[0];
  const fileUrl = file ? URL.createObjectURL(file) : '';

  const renderTitle = () => {
    if (!title) return null;

    if (required) {
      return (
        <Text className="block text-base font-bold">
          {title} <span className="text-[#aa0e0f]">*</span>
        </Text>
      );
    }

    return <Text className="block text-base font-bold">{title}</Text>;
  };

  return (
    <div className={`flex flex-col gap-4 ${display === 'inline' ? 'w-full' : ''}`}>
      {labelPlacement === 'top' && (
        <div>
          {renderTitle()}
          {!!description && <Text className="text-sm text-[#3A3C40]">{description}</Text>}
        </div>
      )}
      <div className="flex items-center gap-4">
        <label>
          <input
            ref={fileInputRef}
            type="file"
            name="file"
            accept=".jpg,.jpeg,.png"
            disabled={loading}
            className="hidden"
            onChange={cropProps ? handleCropImage : handleFileChange}
          />

          {loading && (
            <div className={imageStyles(labelPlacement)}>
              <Loading isLight isLoading height={64} width={64} />
            </div>
          )}
          {!loading && (
            <img src={image} alt="preview" className={imageStyles(labelPlacement)} onError={handleImageError} />
          )}
        </label>

        <div className={display === 'inline' ? 'flex w-full justify-between' : 'space-x-3'}>
          {labelPlacement === 'right' && (
            <div className={display === 'default' ? 'mb-3 ml-3' : ''}>
              {renderTitle()}
              {!!description && <Text className="text-sm text-[#3A3C40]">{description}</Text>}
            </div>
          )}
          <Button variant={uploadButtonVariant} className="bg-white" onClick={handleButtonClick}>
            {uploadButtonLabel}
          </Button>
          {secondaryButtonLabel && (
            <Button variant={buttons.variant.secondary} onClick={onSecondaryButtonClick}>
              {secondaryButtonLabel}
            </Button>
          )}
        </div>
      </div>

      {error && (
        <Text className={styles.error} variant={typography.body_sm} color={colors.attention}>
          <ErrorIcon />
          {error}
        </Text>
      )}

      {isCropModalOpen && (
        <ImageCropModal
          {...cropProps}
          file={file}
          imageUrl={fileUrl}
          onConfirm={(file) => {
            handleSelectedFile(file);
            setIsCropModalOpen(false);
          }}
          onClose={() => {
            clearFileInputValue();
            setIsCropModalOpen(false);
          }}
        />
      )}
    </div>
  );
}
