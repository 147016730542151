import api from '@pray/shared/utils/api';

export async function deleteArtistContent({ artistId, contentIds = [] }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentIds?.length) throw Error('Content IDs are required');

  const data = {
    content_ids: contentIds,
  };

  const url = `/web/studio/artists/${artistId}/content`;
  const response = await api.executeDeleteRequest(url, data);

  return response.data?.data;
}
