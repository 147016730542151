import React from 'react';

export function ChevronRight(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        {...props}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81311 13.0202C5.61785 12.8249 5.61785 12.5083 5.81311 12.3131L10.1262 7.99996L5.81311 3.68685C5.61785 3.49158 5.61785 3.175 5.81311 2.97974C6.00837 2.78448 6.32496 2.78448 6.52022 2.97974L11.1869 7.64641C11.3821 7.84167 11.3821 8.15825 11.1869 8.35351L6.52022 13.0202C6.32496 13.2154 6.00837 13.2154 5.81311 13.0202Z"
        fill="white"
      />
    </svg>
  );
}
