import React from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Thead, Tr, Th } from '@pray/shared/components/ui/Table';

export default function ContentTableSkeleton({ rows = 5 }) {
  return (
    <Table sortable={false} rowCount={rows} disabled>
      <Thead>
        <Tr>
          <Th field="col" className="p-1">
            <Loading height={20} isLight width={20} borderRadius="6px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
          <Th field="col" className="max-w-md p-1">
            <Loading height={14} isLight width={72} borderRadius="2px" />
          </Th>
        </Tr>
        {[...Array(rows)].map((_, i) => (
          <Tr key={i}>
            <Th field="col" width="3%" className="p-1">
              <Loading height={20} isLight width={20} borderRadius="6px" />
            </Th>
            <Th field="col" width="25%" className="max-w-md p-1">
              <div className="flex flex-row gap-2">
                <Loading height={40} isLight width={70} borderRadius="6px" />
                <div className="flex flex-col justify-center gap-1">
                  <Loading height={18} isLight width={101} borderRadius="2px" className="m-0" />
                  <Loading height={14} isLight width={72} borderRadius="2px" className="m-0" />
                </div>
              </div>
            </Th>
            <Th field="col" width="7%" className="p-1">
              <Loading height={14} isLight width={56} borderRadius="2px" />
            </Th>
            <Th field="col" width="14%" className="p-1">
              <Loading height={14} isLight width={56} borderRadius="2px" />
            </Th>
            <Th field="col" width="13%" className="p-1">
              <div className="flex flex-col gap-1.5">
                <Loading height={18} isLight width={108} borderRadius="2px" className="m-0" />
                <Loading height={14} isLight width={72} borderRadius="2px" className="m-0" />
              </div>
            </Th>
            <Th field="col" width="21%" className="p-1">
              <Loading height={14} isLight width={72} borderRadius="2px" />
            </Th>
            <Th field="col" width="20%" className="p-1">
              <div className="flex flex-row gap-1">
                <Loading height={24} isLight width={52} borderRadius="100px" />
                <Loading height={24} isLight width={52} borderRadius="100px" />
                <Loading height={24} isLight width={52} borderRadius="100px" />
              </div>
            </Th>
          </Tr>
        ))}
      </Thead>
    </Table>
  );
}
