import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { fundMapper } from '@pray/shared/services/studioService/donationFunds';

export default function useDonationFunds(artistId) {
  async function fetchDonationFunds(offset) {
    const response = await studioService.donationFunds.getAllDonationFunds({
      artistId,
      offset,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    response.data = response.data.map(fundMapper);

    return response;
  }

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistFunds(artistId),
    queryFn: ({ pageParam: offset }) => fetchDonationFunds(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
    placeholderData: keepPreviousData,
  });

  return query;
}
