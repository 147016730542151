import { useNavigate } from 'react-router-dom';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text';
import logger from '@pray/shared/utils/logger';

import { useToastMessage } from '../../../components/StudioToastMessage/StudioToastMessage';

import { useGenerateAudiobook } from '@/hooks/books/useGenerateAudiobook';
import useAppRoutes from '@/hooks/useAppRoutes';
import { useArtistVoices } from '@/hooks/voices/useArtistVoices';

type GenerateAudioModalProps = {
  isOpen: boolean;
  artistId: string;
  bookId: string;
  onClose: () => void;
};

export default function GenerateAudioModal({ isOpen, artistId, bookId, onClose }: GenerateAudioModalProps) {
  const { data: voices, isLoading: isLoadingVoices } = useArtistVoices({ artistId });
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const toast = useToastMessage();
  const mainVoiceId = voices?.find((voice) => voice.isMain)?.id;
  const isMainVoiceSelected = !!mainVoiceId;

  const { generateAudiobook } = useGenerateAudiobook();

  const handleSelectMainVoice = () => {
    navigate(appRoutes.artistLibraryBookTranscriptEditVoices(bookId));
    onClose();
  };

  const handleTranscriptReview = () => {
    navigate(appRoutes.artistLibraryBookTranscriptEdit(bookId));
    onClose();
  };

  const handleGenerateAudio = async () => {
    try {
      await generateAudiobook({ artistId, bookId, voiceId: mainVoiceId });
      onClose();
    } catch (error) {
      logger.error('Failed to generate audio book', error);
      toast.show({ error: 'Failed to generate audio book' });
    }
  };

  if (!isOpen) return null;

  if (isLoadingVoices) return <LoadingModal onClose={onClose} />;

  if (!isMainVoiceSelected) {
    return <SelectMainVoiceModal onClose={onClose} onSelectMainVoice={handleSelectMainVoice} />;
  }

  return (
    <ReviewTranscriptModal
      onClose={onClose}
      handleTranscriptReview={handleTranscriptReview}
      handleGenerateAudio={handleGenerateAudio}
    />
  );
}

function LoadingModal({ onClose }) {
  return (
    <Modal onBackdropClick={onClose} isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <Loading isLight width={200} height={20} />
          <Loading isLight width={400} height={40} />
        </div>
        <div className="flex w-full justify-end gap-4">
          <Loading isLight width={100} height={20} />
          <Loading isLight width={100} height={20} />
        </div>
      </div>
    </Modal>
  );
}

function SelectMainVoiceModal({ onClose, onSelectMainVoice }) {
  return (
    <Modal onBackdropClick={onClose} isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <Text variant={typography.heading_2xl}>Please assign a speaker</Text>
          <Text variant={typography.body_lg}>
            Please assign a speaker to your transcript to generate an audio book.
          </Text>
        </div>
        <div className="flex w-full justify-end gap-4">
          <Button variant={buttons.variant.secondary} size={buttons.size.medium} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={buttons.variant.primary}
            color={colors.pray_primary}
            size={buttons.size.medium}
            onClick={onSelectMainVoice}
          >
            Assign speaker
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function ReviewTranscriptModal({ onClose, handleTranscriptReview, handleGenerateAudio }) {
  return (
    <Modal onBackdropClick={onClose} isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <Text variant={typography.heading_2xl}>Review Transcript?</Text>
          <Text variant={typography.body_lg}>
            Would you like to review the transcript of your book before generating an audio book?
          </Text>
        </div>
        <div className="flex w-full justify-end gap-4">
          <Button variant={buttons.variant.secondary} size={buttons.size.medium} onClick={handleTranscriptReview}>
            Yes
          </Button>
          <Button
            variant={buttons.variant.primary}
            color={colors.pray_primary}
            size={buttons.size.medium}
            onClick={handleGenerateAudio}
          >
            No, generate audio
          </Button>
        </div>
      </div>
    </Modal>
  );
}
