import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import { Microphone } from '@pray/shared/components/ui/Icons/Microphone';
import Text from '@pray/shared/components/ui/Text';

export default React.memo(function MainVoice() {
  return (
    <div className="flex items-center gap-1 whitespace-nowrap rounded-full bg-[#B6E2CB] px-2 py-1">
      <Microphone fill="#04341A" />
      <Text variant={typography.body_sm} className="!font-semibold uppercase">
        Primary Voice
      </Text>
    </div>
  );
});
