import React, { forwardRef } from 'react';

import { cn } from '@pray/shared/utils/styles';

import { typography } from '../../foundations';
import InputLoader from '../InputLoader/InputLoader';
import Text from '../Text';
import { ErrorIcon } from '../TextInput';

import styles from './TextArea.module.scss';

/**
 * Custom props for the TextArea component
 * @typedef CustomProps
 * @property {React.Ref<HTMLTextAreaElement>} [ref] - Ref forwarded to textarea element
 * @property {string} [label] - Label text shown above textarea
 * @property {React.ReactNode} [infoElement] - Info icon/tooltip element shown next to label
 * @property {string} [description] - Description text shown below textarea
 * @property {string} [helperText] - Helper text shown below textarea (e.g. character count)
 * @property {string} [errorMessage] - Error message shown below textarea
 * @property {string} [className] - Additional CSS class to apply to container
 * @property {boolean} [loading] - Shows loading spinner in place of right icon
 * @property {React.ReactNode} [rightIcon] - Icon element shown on right side
 * @property {boolean} [required] - Whether field is required
 * @property {boolean} [disabled] - Whether field is disabled
 * @property {string} [placeholder] - Placeholder text
 * @property {number} [rows] - Number of visible text lines
 */

/**
 * Props for the TextArea component
 * @typedef {CustomProps & React.ComponentPropsWithoutRef<'textarea'>} TextAreaProps
 */

/**
 * A textarea component for multi-line text input
 *
 * | Property | Type | Description |
 * | --- | --- | --- |
 * | ref | React.Ref<HTMLTextAreaElement> | Reference to the textarea element |
 * | label | string | Label text displayed above the textarea |
 * | infoElement | React.ReactNode | Optional info icon/element displayed next to label |
 * | description | string | Description text displayed below the textarea |
 * | helperText | string | Helper text displayed below the textarea (e.g. character count) |
 * | errorMessage | string | Error message displayed below the textarea when validation fails |
 * | className | string | Additional CSS classes to apply to the textarea |
 * | loading | boolean | Whether to show a loading spinner |
 * | rightIcon | React.ReactNode | Icon element to display on the right side |
 * | required | boolean | Whether the textarea is required |
 * | disabled | boolean | Whether the textarea is disabled |
 * | placeholder | string | Placeholder text shown when textarea is empty |
 * | rows | number | Number of visible text lines |
 *
 * ```jsx
 * // Basic usage
 * <TextArea label="Comments" placeholder="Enter your comments" />
 *
 * // With validation error
 * <TextArea
 *   label="Description"
 *   errorMessage="Description is required"
 *   required
 * />
 *
 * // With helper text and description
 * <TextArea
 *   label="Bio"
 *   description="Tell us about yourself"
 *   helperText="0/500 characters"
 * />
 *
 * // With info tooltip and right icon
 * <TextArea
 *   label="Notes"
 *   infoElement={<Popover title="Info"><InfoIcon /></Popover>}
 *   rightIcon={<Search />}
 * />
 * ```
 */
const TextArea = forwardRef((/** @type {TextAreaProps} */ props, ref) => {
  const {
    description,
    errorMessage,
    helperText,
    infoElement,
    label,
    loading,
    required,
    rightIcon,
    className,
    ...textareaProps
  } = props;

  return (
    <div className={styles.container}>
      <label className={cn(styles.inputBase, props.disabled && styles.disabled, className)}>
        <div className={styles.inputWrap}>
          <InputLabel {...{ infoElement, ...props }} />
          <textarea ref={ref} {...textareaProps} />
        </div>
        <div className={styles.rightIcon}>{loading ? <InputLoader /> : rightIcon}</div>
      </label>
      <InputFooter {...props} />
    </div>
  );
});

function InputLabel(props) {
  const { label, required, infoElement } = props;

  return (
    <div className={styles.labelRow}>
      <Text className={styles.label}>{label}</Text>
      {required && <Text className={styles.required}>*</Text>}
      {infoElement}
    </div>
  );
}

function InputFooter(props) {
  const { errorMessage, description, helperText } = props;

  if (!description && !errorMessage && !helperText) {
    return null;
  }

  return (
    <div className={styles.footer}>
      <div className={styles.footerCol}>
        {!!description && (
          <Text className={styles.description} variant={typography.body_sm}>
            {description}
          </Text>
        )}
        {!!errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
      {!!helperText && (
        <Text className={styles.info} variant={typography.body_sm}>
          {helperText}
        </Text>
      )}
    </div>
  );
}

export function ErrorMessage({ message }) {
  return (
    <Text className={styles.errorMessage} variant={typography.body_sm}>
      <ErrorIcon />
      {message}
    </Text>
  );
}

TextArea.displayName = 'TextArea';

export default TextArea;
