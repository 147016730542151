import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

type GetChapterVersionsProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

/* eslint-disable camelcase */
export type ChapterVersionsResponse = {
  published: VersionDiff[];
  draft: VersionDiff[];
  most_recent_transcript: {
    type: 'published' | 'draft';
    transcript?: string;
  };
};

type VersionDiff = {
  transcript: string;
  updated_at: string;
  changes: Changes | null;
  diff: Diff[] | null;
};

type Changes = {
  transcript: {
    new: string;
    old: string;
  };
};

type Diff = {
  count: number;
  value: string;
  added?: boolean;
  removed?: boolean;
};
/* eslint-enable camelcase */

export const getChapterVersions = async ({ artistId, bookId, chapterId }: GetChapterVersionsProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!chapterId) throw Error('Chapter ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters/${chapterId}/versions`;

  const response = await api.executeGetRequest<ObjectResponse<ChapterVersionsResponse>>(url);

  return response.data;
};
