import { create } from 'zustand';

const useSideBar = create((set) => ({
  isFullClosed: false,
  isCollapsed: false,
  renderDetails: null,

  setIsFullClosed: (isFullClosed) => set({ isFullClosed }),
  setIsCollapsed: (isCollapsed) => set({ isCollapsed }),
  toggleCollapsed: () => set((state) => ({ isCollapsed: !state.isCollapsed })),
  setRenderDetails: (renderDetails) => set({ renderDetails }),
  resetRenderDetails: () => set({ renderDetails: null }),
  resetMobileSideBar: () => set({ isCollapsed: true, renderDetails: null }),
}));

export default useSideBar;
