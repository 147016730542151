import React, { useMemo } from 'react';

import { chartTypes } from '@pray/shared/services/studioService/periodicTable';

import useSegmentDetails from 'hooks/periodicTable/useSegmentDetails';

import { DonutChart } from '../../../../../../components/Charts/DonutChart';
import { ChartWidget } from '../ChartWidget';

export default function HouseholdIncomeChart({ artistId, segmentCode, segmentName }) {
  const { isLoading, data } = useSegmentDetails({ artistId, segmentCode, chartType: chartTypes.householdIncome });

  const description = useMemo(() => {
    if (!data?.household_income?.length) return null;

    const largestSlice = data.household_income.reduce((prev, current) => {
      return +prev.value > +current.value ? prev : current;
    });

    return (
      <>
        <span className="text-[#0B0C0D]">{largestSlice.value}%</span> of members from {segmentName} earn{' '}
        <span className="text-[#0B0C0D]">{largestSlice.label}</span> a year
      </>
    );
  }, [data?.household_income]);

  if (isLoading || !!data?.household_income?.length) {
    return (
      <ChartWidget
        isLoading={isLoading}
        title="Household Income"
        description={description}
        info="This chart shows the breakdown of Household Income of PRAY.COM members from the periodic table segment."
      >
        <DonutChart isLoading={isLoading} data={data?.household_income} />
      </ChartWidget>
    );
  }
}
