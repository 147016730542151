import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import Text from '@pray/shared/components/ui/Text/Text';
import CloseIcon from '@pray/shared/icons/close';

import Drawer from 'components/pages/StudioPage/components/Drawer/Drawer';

import minutesInPrayer from './assets/minutes-in-prayer.png';
import segmentElement from './assets/segment-element.png';
import viewership from './assets/viewership.png';

export default function PeriodicTableDrawer({ isOpen = false, onClose = null }) {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <div className="h-full bg-white">
        <div className="flex h-14 items-center justify-between border border-[#DEDFE3] px-4">
          <Text variant="headline_medium">Periodic Table</Text>
          <IconButton data-tapeventname="Close" onClick={onClose}>
            <CloseIcon fill="#a6a8ad" />
          </IconButton>
        </div>
        <main className="h-full overflow-y-auto pb-16 pt-3">
          <div className="flex flex-col gap-4 px-7 py-3">
            <Text className="text-[28px] font-bold">Periodic Table of People</Text>
            <Text>
              We built the periodic table of people to help you easily segment PRAY.COM members interacting with your
              content to display a breakdown in a Periodic Table view to help you better understand your audience and
              create a more meaningful impact on their lives.
            </Text>
            <Text>This help article will help you understand how to use the Periodic Table of People.</Text>
          </div>
          <div className="flex flex-col gap-4 px-7 py-3">
            <Text className="text-[20px] font-bold">Who are all the people in the Periodic Table of People?</Text>
            <Text>
              All the people who show up on the Periodic Table of People are PRAY.COM members who have listened,
              watched, or shared your content, along with other data points that PRAY.COM is tracking to build your
              personal Periodic Table of People.
            </Text>
          </div>
          <div className="flex flex-col gap-4 px-7 py-3">
            <Text className="text-[24px] font-bold">Viewership</Text>
            <img src={viewership} alt="Viewership" className="my-5" />
            <Text>
              The viewership blocks at the top of the Periodic Table of People segment your audience into different
              buckets. The Viewership blocks are colors that bundle the segments in the Periodic Table of People to help
              you identify which segments correspond to your viewership. The highest viewership will always order from
              left to right.
            </Text>
          </div>
          <div className="flex flex-col gap-4 px-7 py-6">
            <Text className="text-[20px] font-bold">What is viewership?</Text>
            <Text>
              Viewership is the percentage of PRAY.COM members listening and watching the content you have distributed
              on PRAY.COM.
            </Text>
          </div>
          <div className="flex flex-col gap-4 px-7 py-3">
            <Text className="text-[24px] font-bold">Minutes in Prayer</Text>
            <img src={minutesInPrayer} alt="Minutes in prayer" className="my-5" />
            <Text>
              The Minutes in Prayer Bar displays the total number of minutes users spend on PRAY.COM, ordered from
              highest to lowest (left to right). The segments on the bar are color-coded according to the Minutes In
              Prayer to make it easier to identify which users have spent the most time on PRAY.COM.
            </Text>
          </div>
          <div className="flex flex-col gap-4 px-7 py-3">
            <Text className="text-[24px] font-bold">Segment Element</Text>
            <img src={segmentElement} alt="Segment element" className="mx-auto my-5 w-36" />
            <ul className="flex flex-col gap-3">
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Element Color:</strong> Each element will have a corresponding color to the viewership blocks
                  located at the top of the Period Table of People. We have grouped elements by color and rank to help
                  quickly inform you which segments have the highest viewership.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Rank:</strong> The number located on the top right of the segment element displays the rank of
                  the segment ordered by viewership. The element with the number 1 means those segments of users have
                  the highest viewership rank amongst all the other elements.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Symbol:</strong> Symbol is the abbreviation of the segment name for the element. For example,
                  EUB is an abbreviation of the Elite Urban Boomers segment.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Name:</strong> Elements will show the name of the segment that PRAY.COM has classified those
                  users to be part of the element.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Minutes in Prayer:</strong> The number of Minutes in Prayer displays the total number of
                  minutes the users in the element have spent on PRAY.COM.
                  <ul className="pl-6">
                    <li className="list-disc">
                      The Minutes in Prayer have a corresponding color to the Minutes in Prayer Bar located at the top
                      of the Periodic Table of People to help you easily identify which segments have spent the most
                      time on PRAY.COM.
                    </li>
                  </ul>
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Listens:</strong> Listens are the percent of users within the element segment listening to
                  your content on PRAY.COM.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Shares:</strong> Shares are the percent of users within the element segment listening to your
                  content on PRAY.COM.
                </Text>
              </li>
              <li className="list-disc">
                <Text className="align-text-top">
                  <strong>Follows:</strong> Follows are the percent of users within the element segment listening to
                  your content on PRAY.COM.
                </Text>
              </li>
            </ul>
          </div>
        </main>
      </div>
    </Drawer>
  );
}
