import usePrayContext from 'hooks/usePrayContext';
import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { Bookmark } from '@pray/shared/components/ui/Icons/Bookmark';
import { Bookmarked } from '@pray/shared/components/ui/Icons/Bookmarked';
import { Close } from '@pray/shared/components/ui/Icons/Close';
import { ContentList } from '@pray/shared/components/ui/Icons/ContentList';
import { CreatorProfile } from '@pray/shared/components/ui/Icons/CreatorProfile';
import { Gift } from '@pray/shared/components/ui/Icons/Gift';
import { Share } from '@pray/shared/components/ui/Icons/Share';
import { Verified } from '@pray/shared/components/ui/Icons/Verified';
import Text from '@pray/shared/components/ui/Text/Text';
import Modal from '@pray/shared/components/v1/Modal/Modal';
import { isOnBrowser } from '@pray/shared/utils';

import styles from './OptionsModal.module.scss';

export const Option = ({ Icon, label, iconColor, ...props }) => {
  return (
    <Button full className={styles.option} {...props}>
      <div className={styles.iconWrap}>
        <Icon fill={iconColor} />
      </div>
      <Text>{label}</Text>
    </Button>
  );
};

export default function OptionsModal({ isOpen, currentContent, isContentSaved, onSave, onShare, onNavigate, onClose }) {
  const { isUserSubscribed } = usePrayContext();

  if (!isOpen) return null;

  const givingUrl = currentContent.artist_giving_url;

  let givingUrlTarget = '_blank';

  if (isOnBrowser() && givingUrl) {
    const { hostname: currentHostname } = window.location;
    const { hostname: givingHostname } = new URL(givingUrl);

    const currentBaseDomain = currentHostname.split('.').slice(-2).join('.');
    const givingBaseDomain = givingHostname.split('.').slice(-2).join('.');

    if (currentBaseDomain === givingBaseDomain) {
      givingUrlTarget = '_self';
    }
  }

  return (
    <Modal className={styles.modal} isShowCloseButton={false} onClose={onClose}>
      <Button onClick={onClose} className={styles.topCloseButton}>
        <Close />
      </Button>
      <div className={styles.container}>
        <div className={styles.coverWrap}>
          <img src={currentContent.image_url} alt={currentContent.title} />
        </div>
        <div className={styles.contentWrap}>
          <Text block className={styles.title}>
            {currentContent.title}
          </Text>
          <Text block className={styles.subtitle}>
            {currentContent.artist_name} • {currentContent.content_series_title}
          </Text>
        </div>
      </div>

      <div className={styles.options}>
        {!isUserSubscribed && (
          <Option
            Icon={Verified}
            label="Subscribe"
            iconColor={colors.$pray_featured}
            to="/subscribe/"
            onClick={onNavigate}
          />
        )}

        <Option
          Icon={isContentSaved ? Bookmarked : Bookmark}
          label={isContentSaved ? 'Saved' : 'Save'}
          onClick={onSave}
        />

        <Option Icon={Share} label="Share" onClick={onShare} />

        {givingUrl && (
          <Option Icon={Gift} label="Give" href={givingUrl} target={givingUrlTarget} onClick={onNavigate} />
        )}

        {currentContent.artist_id && (
          <Option
            Icon={CreatorProfile}
            label={`View ${currentContent.artist_name}`}
            to={`/artists/${currentContent.artist_id}`}
            onClick={onNavigate}
          />
        )}

        {currentContent.content_series_handle && (
          <Option
            Icon={ContentList}
            label="View all episodes"
            to={`/series/${currentContent.content_series_handle}`}
            onClick={onNavigate}
          />
        )}
      </div>

      <div className={styles.closeButtonWrap}>
        <Button full onClick={onClose}>
          <Text variant={typography.button_medium}>Close</Text>
        </Button>
      </div>
    </Modal>
  );
}
