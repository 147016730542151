import { Calendar, Download, Upload } from 'lucide-react';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Label from '@pray/shared/components/ui/Label';
import Text from '@pray/shared/components/ui/Text';
import { ErrorMessage } from '@pray/shared/components/ui/TextInput';
import { formatSeconds } from '@pray/shared/utils/datetime';
import { downloadFileFromUrl } from '@pray/shared/utils/download';

import { VIEW_EVENT_NAMES } from '@/constants';
import useAppRoutes from '@/hooks/useAppRoutes';

import { socialClipStatus } from './constants';
import { SocialClipVideo } from './SocialClipVideo';

export function SocialClipsList({ socialClips }) {
  return (
    <div data-viewname={VIEW_EVENT_NAMES.SOCIAL_CLIPS.LIST} className="flex flex-col gap-12">
      {socialClips.map((socialClip) => (
        <SocialClip data={socialClip} key={socialClip.id} />
      ))}
    </div>
  );
}

function SocialClip({ data: socialClip }) {
  return (
    <div className="h-[510px]">
      <div className="flex h-full gap-8">
        <div className="min-w-[265px]">
          <SocialClipVideo videoUrl={socialClip.url} />
        </div>
        <div className="flex flex-col gap-6">
          <SocialClipStatus data={socialClip} />
          <SocialClipHeader title={socialClip.title} />
          <SocialClipTranscript
            transcript={socialClip.transcript}
            timeRangeStart={socialClip.timeRangeStart}
            timeRangeEnd={socialClip.timeRangeEnd}
          />
          <SocialClipActions data={socialClip} />
        </div>
      </div>
    </div>
  );
}

function SocialClipStatus({ data: socialClip }) {
  if (socialClip.isFailed) {
    return (
      <div className="flex flex-col gap-2">
        <div>
          <Label className="shrink-0 bg-[var(--color-red-100)] uppercase text-[var(--color-red-700)]">
            Scheduled Failed
          </Label>
        </div>
        <SocialClipError message={socialClip.failedReason} />
      </div>
    );
  }

  if (socialClip.isPublished) {
    const publishDate = DateTime.fromISO(socialClip.publishedAt);
    const isScheduled = socialClip.status === socialClipStatus.scheduled;
    const status = isScheduled ? 'scheduled' : 'published';
    const label = isScheduled ? `Scheduled ${publishDate.toFormat('dd LLL yyyy-hh:mm a')}` : 'PUBLISHED';

    return (
      <div>
        <Label status={status} className="uppercase">
          {label}
        </Label>
      </div>
    );
  }

  return null;
}

function SocialClipError({ message }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const maxLength = 60;
  const shouldTruncate = message?.length > maxLength;
  const errorMessage = shouldTruncate ? `${message.slice(0, maxLength)}...` : message;

  if (shouldTruncate) {
    return (
      <>
        <ErrorMessage
          message={
            <span>
              {errorMessage}
              <Button
                className="ml-1 p-0 text-xs normal-case text-inherit underline"
                onClick={() => setIsDialogOpen(true)}
              >
                See details
              </Button>
            </span>
          }
        />

        {isDialogOpen && (
          <Dialog onEscapeKeyDown={() => setIsDialogOpen(false)} onBackdropClick={() => setIsDialogOpen(false)}>
            <div className="flex flex-col gap-6">
              <Text variant={typography.heading_2xl}>Failed to share social clip</Text>
              <div className="max-h-72 overflow-y-auto pr-2">
                <Text
                  color={colors.text_secondary}
                  dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br /><br />') }}
                />
              </div>
              <div className="flex flex-row justify-end gap-4">
                <Button variant={buttons.variant.secondary} onClick={() => setIsDialogOpen(false)}>
                  Close
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }

  return <ErrorMessage message={errorMessage} />;
}

function SocialClipHeader({ title }) {
  return (
    <div className="flex flex-col gap-1">
      <Text variant={typography.heading_sm} color={colors.text_tertiary}>
        Clip Title
      </Text>
      <Text variant={typography.heading_xl}>{title}</Text>
    </div>
  );
}

function SocialClipTranscript({ timeRangeStart, timeRangeEnd, transcript }) {
  return (
    <>
      <div className="flex items-center gap-1">
        <CcIcon />
        <Text variant={typography.heading_sm} color={colors.text_secondary}>
          Transcript
        </Text>
      </div>
      <div className="-mt-4 flex-1 overflow-y-auto rounded-2xl border p-5">
        <div className="flex h-full flex-col gap-2">
          <Text variant={typography.body_lg} className="!font-medium">
            [{formatSeconds(timeRangeStart)} - {formatSeconds(timeRangeEnd)}]
          </Text>
          <Text variant={typography.body_lg} color={colors.text_secondary} className="pb-2 !font-medium">
            {transcript}
          </Text>
        </div>
      </div>
    </>
  );
}

function SocialClipActions({ data: socialClip }) {
  const appRoutes = useAppRoutes();
  const { contentId } = useParams();

  return (
    <div className="flex justify-end gap-4">
      <Button
        variant={buttons.variant.secondary}
        className="flex items-center gap-2"
        onClick={() => downloadFileFromUrl(socialClip.url)}
      >
        <Download className="size-5" />
        Download
      </Button>
      {socialClip.status === socialClipStatus.draft && (
        <Button
          variant={buttons.variant.primary}
          className="flex items-center gap-2"
          to={appRoutes.artistContentSocialClipsPublish(contentId, socialClip.id)}
        >
          <Upload className="size-5" />
          Publish
        </Button>
      )}
      {socialClip.status === socialClipStatus.scheduled && (
        <Button
          variant={buttons.variant.primary}
          className="flex items-center gap-2"
          to={appRoutes.artistContentSocialClipsPublish(contentId, socialClip.id)}
        >
          <Calendar className="size-5" />
          Edit Schedule
        </Button>
      )}
    </div>
  );
}

function CcIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.75" y="5.49316" width="20.5" height="14.5" rx="3.25" stroke="#56585E" strokeWidth="1.5" />
      <path
        d="M8.50893 16.2432C6.60714 16.2432 5.375 14.871 5.375 12.7478C5.375 10.6432 6.65179 9.24316 8.5625 9.24316C10.1071 9.24316 11.2589 10.1796 11.4821 11.6352H10.2768C10.0536 10.8471 9.40179 10.3836 8.53571 10.3836C7.33036 10.3836 6.57143 11.2922 6.57143 12.7385C6.57143 14.1756 7.33929 15.1028 8.53571 15.1028C9.41964 15.1028 10.0982 14.6206 10.3125 13.8697H11.5C11.25 15.2975 10.0536 16.2432 8.50893 16.2432Z"
        fill="#56585E"
      />
      <path
        d="M15.6339 16.2432C13.7321 16.2432 12.5 14.871 12.5 12.7478C12.5 10.6432 13.7768 9.24316 15.6875 9.24316C17.2321 9.24316 18.3839 10.1796 18.6071 11.6352H17.4018C17.1786 10.8471 16.5268 10.3836 15.6607 10.3836C14.4554 10.3836 13.6964 11.2922 13.6964 12.7385C13.6964 14.1756 14.4643 15.1028 15.6607 15.1028C16.5446 15.1028 17.2232 14.6206 17.4375 13.8697H18.625C18.375 15.2975 17.1786 16.2432 15.6339 16.2432Z"
        fill="#56585E"
      />
    </svg>
  );
}
