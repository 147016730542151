import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import RevenueAccountInput from './Status/RevenueAccountInput';
import RevenueAccountStatus from './Status/RevenueAccountStatus';

export default function RevenueAccountDetails({ status, onViewAccount, isLoadingViewAccount }) {
  return (
    <div className="mt-8 flex max-w-lg flex-col gap-6">
      <Text variant={typography.heading_xl}>Bank Account</Text>

      <RevenueAccountStatus status={status} onViewAccount={onViewAccount} isLoadingViewAccount={isLoadingViewAccount} />

      <RevenueAccountInput />
    </div>
  );
}
