import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';

import CreateEditEmailPage from './CreateEditEmailPage/CreateEditEmailPage';
import EmailAnalyticsPage from './EmailAnalyticsPage/EmailAnalyticsPage';
import EmailOverviewPage from './EmailOverviewPage/EmailOverviewPage';
import EmailsListPage from './EmailsListPage/EmailsListPage';

export default function EmailsTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Emails">
      <Routes>
        <Route index element={<EmailsListPage />} />
        <Route path="create" element={<CreateEditEmailPage />} />
        <Route path=":id/edit" element={<CreateEditEmailPage />} />
        <Route path=":id/overview" element={<EmailOverviewPage />} />
        <Route path=":id/analytics" element={<EmailAnalyticsPage />} />
      </Routes>
    </MainLayout>
  );
}
