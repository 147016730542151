import React from 'react';
import { UseFormReturn } from 'react-hook-form';

import Loading from '@pray/shared/components/Loading';
import { cn } from '@pray/shared/utils/styles';

import EditorContainer, { SidebarTab } from '../EditorContainer';
import RichTextEditor from '../RichTextEditor/RichTextEditor';

type TranscriptEditorProps<FormValuesType> = {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  headerActions?: React.ReactNode;
  sidebarTabs?: SidebarTab[];
  defaultSidebarTab?: string;
  content: string;
  useMarkdown?: boolean;
  resetChangeHistory?: boolean;
  form?: UseFormReturn<FormValuesType>;
  className?: string;
  editorClassName?: string;
  onBack: () => void;
  onChange: (content: string) => void;
  onSidebarTabChange?: (tabId: string) => void;
};

export default function TranscriptEditor<FormValuesType>({
  title,
  subtitle,
  isLoading = false,
  headerActions,
  sidebarTabs = [],
  defaultSidebarTab,
  content,
  useMarkdown = false,
  resetChangeHistory,
  form,
  className,
  editorClassName,
  onBack,
  onChange,
  onSidebarTabChange,
}: TranscriptEditorProps<FormValuesType>) {
  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="mt-8 flex justify-center">
          <div className="flex flex-col gap-4">
            <Loading isLight height={50} width={700} />
            <Loading isLight height={200} width={700} />
            <Loading isLight height={200} width={700} />
          </div>
        </div>
      );
    }

    return (
      <RichTextEditor
        useMarkdown={useMarkdown}
        content={content}
        resetChangeHistory={resetChangeHistory}
        className={cn(
          'min-h-[500px] [&>div]:outline-none [&_h1]:my-4 [&_h1]:text-2xl [&_h1]:font-bold [&_h2]:my-4 [&_h2]:text-xl [&_h2]:font-bold [&_h3]:my-4 [&_h3]:text-lg [&_h3]:font-bold [&_p]:my-4',
          editorClassName
        )}
        onChange={onChange}
      />
    );
  };

  return (
    <EditorContainer
      title={title}
      subtitle={subtitle}
      isLoading={isLoading}
      headerActions={headerActions}
      sidebarTabs={sidebarTabs}
      defaultSidebarTab={defaultSidebarTab}
      form={form}
      className={className}
      onBack={onBack}
      onSidebarTabChange={onSidebarTabChange}
    >
      {renderContent()}
    </EditorContainer>
  );
}
