import api from '@pray/shared/utils/api';

export const getLeaderFollowerMetadata = async ({ artistId = '' }) => {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/followers/metadata`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
