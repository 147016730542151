// get the first route in the navigation history that matches one of the route patterns
import { getBackRoute } from 'utils/navigation';

export function getBackRoutesFromContentPage(defaultBackRoute) {
  const routePatterns = [
    '/leaders/:artistId/episodes/:type', // episodes list
    '/leaders/:artistId/series/:seriesId', // series details
  ];
  return getBackRoute(routePatterns, defaultBackRoute);
}

export function extractContentDetailsVariables(content) {
  const isVideo = content?.is_video || !!content?.primary_video_url;
  const contentId = content?.id || content?.content_id;
  const contentExists = !!content?.id;
  const workflowAIDetailsContent = content?.workflow_ai_metadata;
  const isLoadingDetails = content?.is_processing || workflowAIDetailsContent?.translation_status === 'in_progress';

  const isLoadingTranscript = workflowAIDetailsContent && !workflowAIDetailsContent.transcript;
  const isProcessingTranscript = content?.transcript_status === 'processing';

  const isLoadingSummaryAI =
    workflowAIDetailsContent &&
    !(
      workflowAIDetailsContent?.summary &&
      workflowAIDetailsContent?.short_summary &&
      workflowAIDetailsContent?.chapters
    );

  const isWorkflowAIProcessing =
    (content?.is_workflow_ai_processing || isProcessingTranscript) && content?.transcript_status !== 'failed';

  return {
    isVideo,
    contentId,
    contentExists,
    isLoadingDetails,
    isLoadingSummaryAI,
    isLoadingTranscript,
    isProcessingTranscript,
    isWorkflowAIProcessing,
    transcriptStatus: content?.transcript_status,
  };
}
