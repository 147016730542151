export default function Drawer({ isOpen = false, onClose = null, children = null }) {
  const drawerClasses = 'fixed right-0 top-0 z-[100] h-full w-[472px] duration-300 ease-in-out';
  const backdropClasses = 'fixed inset-0';
  const bgColor = isOpen ? 'bg-black/40' : '';
  const zPos = isOpen ? 'z-[100]' : '-z-10';
  const xPos = isOpen ? 'translate-x-0' : 'translate-x-full';

  return (
    <>
      <div className={`${backdropClasses} ${zPos} ${bgColor}`} role="none" onClick={onClose} />
      <div className={`${drawerClasses} ${xPos}`}>{children}</div>
    </>
  );
}
