import { useCallback, useMemo, useRef, useState } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Table, Tbody, Td, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import useClickAway from '@pray/shared/hooks/useClickAway';

import { VerticalEllipsis } from 'images/icons';

import MainVoice from './MainVoice';
import VoiceOptionsMenu from './VoiceOptionsMenu';
import VoicePlaybackButton from '../VoicePlaybackButton';

export default function VoicesTable({
  voices,
  isPlayingId,
  handleEditClick,
  handleDeleteClick,
  handleSetAsMainVoiceClick,
  handlePlayClick,
  handlePauseClick,
}) {
  const [openVoiceId, setOpenVoiceId] = useState(null);
  const optionsRef = useRef(null);

  useClickAway(optionsRef, () => setOpenVoiceId(null));

  const handleOptionClick = useCallback((voiceId) => {
    setOpenVoiceId((openVoiceId) => (openVoiceId === voiceId ? null : voiceId));
  }, []);

  const voiceRows = useMemo(() => {
    return voices.map((voice) => (
      <VoiceTableRow
        key={voice.id}
        voice={voice}
        isPlaying={isPlayingId === voice.id}
        onPlay={handlePlayClick}
        onPause={handlePauseClick}
        onOptionClick={handleOptionClick}
        openVoiceId={openVoiceId}
        optionsRef={optionsRef}
        handleEditClick={handleEditClick}
        handleDeleteClick={handleDeleteClick}
        handleSetAsMainVoiceClick={handleSetAsMainVoiceClick}
      />
    ));
  }, [
    voices,
    isPlayingId,
    openVoiceId,
    handlePlayClick,
    handlePauseClick,
    handleOptionClick,
    handleEditClick,
    handleDeleteClick,
    handleSetAsMainVoiceClick,
  ]);

  return (
    <Table className="mt-8">
      <Tbody>{voiceRows}</Tbody>
    </Table>
  );
}

function VoiceTableRow({
  voice,
  isPlaying,
  onPlay,
  onPause,
  onOptionClick,
  openVoiceId,
  optionsRef,
  handleEditClick,
  handleDeleteClick,
  handleSetAsMainVoiceClick,
}) {
  return (
    <Tr key={voice.id} className="cursor-pointer">
      <Td width="8%">
        <VoicePlaybackButton voice={voice} isPlaying={isPlaying} onPause={onPause} onPlay={onPlay} />
      </Td>
      <Td>
        <Text variant={typography.body_lg}>{voice.name}</Text>
      </Td>
      <Td width="10%">{voice.isMain && <MainVoice />}</Td>
      <Td width="2%" className="relative !pr-0">
        <Button onClick={() => onOptionClick(voice.id)}>
          <VerticalEllipsis />
        </Button>

        {openVoiceId === voice.id && (
          <VoiceOptionsMenu
            optionsRef={optionsRef}
            isMainVoice={voice.isMain}
            handleEditClick={() => {
              handleEditClick(voice.id);
              onOptionClick(null);
            }}
            handleDeleteClick={() => {
              handleDeleteClick(voice.id);
              onOptionClick(null);
            }}
            handleSetAsMainVoiceClick={() => {
              handleSetAsMainVoiceClick(voice.id);
              onOptionClick(null);
            }}
          />
        )}
      </Td>
    </Tr>
  );
}
