import api from '@pray/shared/utils/api';

export async function updateLocalizedContent({
  artistId = '',
  contentId = '',
  locale = '',
  primaryImageUrl = undefined,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale code is required');

  const data = {};

  if (primaryImageUrl !== undefined) data.primary_image_url = primaryImageUrl;

  if (Object.keys(data).length === 0) return null;

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
