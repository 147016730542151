import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

import type { CourseEpisode } from '../../components/pages/StudioPage/tabs/PlaylistsTab/Courses/CourseEdit/components/Content/EpisodesContent/types';

type UseDeleteEpisodeProps = {
  artistId: string;
  courseId: string;
};

type DeleteEpisodeHookParams = {
  episodeId: string;
};

export default function useDeleteEpisode({ artistId, courseId }: UseDeleteEpisodeProps) {
  const queryClient = useQueryClient();

  const deleteEpisode = async ({ episodeId }: DeleteEpisodeHookParams) => {
    if (!episodeId) throw new Error('Episode ID is required');

    const response = await studioService.courses.deleteEpisode({
      artistId,
      courseId,
      episodeId,
    });

    return response.data;
  };

  const mutation = useMutation({
    mutationFn: deleteEpisode,
    onMutate: async ({ episodeId }) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.artistCourseEpisodes(artistId, courseId),
      });

      const previousEpisodes = queryClient.getQueryData(queryKeys.artistCourseEpisodes(artistId, courseId));

      queryClient.setQueryData(
        queryKeys.artistCourseEpisodes(artistId, courseId),
        (old: CourseEpisode[] | undefined) => {
          if (!old) return old;

          return old.filter((episode) => episode.id !== episodeId);
        }
      );

      return { previousEpisodes };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousEpisodes) {
        queryClient.setQueryData(queryKeys.artistCourseEpisodes(artistId, courseId), context.previousEpisodes);
      }
    },
    onSettled: (_data, _error, { episodeId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistCourseEpisodes(artistId, courseId),
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.artistCourseEpisode(artistId, courseId, episodeId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteEpisode: mutateAsync,
    ...rest,
  };
}
