import React, { useCallback, useEffect, useState } from 'react';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Tooltip from '@pray/shared/components/ui/Tooltip/Tooltip';
import shareService from '@pray/shared/services/shareService';
import { isMobileBrowser, copyToClipboard } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';

import EmailIcon from './components/EmailIcon';
import FacebookIcon from './components/FacebookIcon';
import LinkIcon from './components/LinkIcon';
import TwitterIcon from './components/TwitterIcon';

import styles from './ShareButtons.module.scss';

export default function ShareButtons({
  title = '',
  message = '',
  shareObject = null,
  objectType = '',
  objectName = '',
  showShareButton = false,
  className = '',
}) {
  const useNativeShare = typeof window.navigator.share !== 'undefined' && isMobileBrowser();

  const [sharingLinks, setSharingLinks] = useState({
    external_share_link: null,
    facebook_share_link: null,
    link_share_link: null,
    twitter_share_link: null,
  });

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const {
    external_share_link: externalShareLink,
    facebook_share_link: facebookShareLink,
    link_share_link: linkShareLink,
    twitter_share_link: twitterShareLink,
  } = sharingLinks;

  const getSharableLink = async () => {
    if (!objectType || !shareObject) return;

    const response = await shareService.getSharingLinks({
      id: shareObject.id,
      type: objectType,
    });

    setSharingLinks(response.data);
  };

  const handleNativeShare = async (url) => {
    if (!url) return;

    if (useNativeShare) {
      try {
        const text = `${message} ${url}`;
        await navigator.share({ text, title, url });
      } catch (err) {
        logger.debug(err);
      }
    }
  };

  useEffect(() => {
    getSharableLink();
  }, [objectType, shareObject]);

  const getNativeShare = useCallback(() => {
    if (sharingLinks) handleNativeShare(linkShareLink);
  }, [sharingLinks]);

  const handleCopy = useCallback(
    (event) => {
      event.preventDefault();

      if (linkShareLink) {
        copyToClipboard(linkShareLink, () => {
          setIsLinkCopied(true);
        });
      }
    },
    [linkShareLink]
  );

  const handleEmailOpen = useCallback(() => {
    // Use native email client to share web content until sharing via email is enabled on backend
    const contentType = objectName;
    const subject = `Sharing Pray.com's ${contentType} with you`;
    const body = `I hope you enjoy Pray.com's ${contentType}!%0D%0ADownload the Pray app for original daily devotionals and more.%0D%0A%0D%0A${externalShareLink}`;
    window.location.href = `mailto:%20?subject=${subject}&body=${body}`;
  }, [objectName, externalShareLink]);

  return (
    <div className={[styles.social, className].join(' ')}>
      {!showShareButton && (
        <>
          <Button
            data-tapeventname="Facebook"
            disabled={!facebookShareLink}
            className={styles.shareButton}
            href={`https://www.facebook.com/sharer/sharer.php?u=${facebookShareLink}&quote=${message}`}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon fill={facebookShareLink ? colors.$text_primary : colors.$text_secondary} />
          </Button>

          <Button
            data-tapeventname="Twitter"
            disabled={!twitterShareLink}
            className={styles.shareButton}
            href={`https://twitter.com/intent/tweet?text=${message}&url=${twitterShareLink}`}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon fill={twitterShareLink ? colors.$text_primary : colors.$text_secondary} />
          </Button>

          <Button
            data-tapeventname="Email"
            disabled={!externalShareLink}
            onClick={handleEmailOpen}
            className={styles.shareButton}
          >
            <EmailIcon fill={externalShareLink ? colors.$text_primary : colors.$text_secondary} />
          </Button>

          <Button
            data-tapeventname="Copy Link"
            disabled={!linkShareLink}
            onClick={handleCopy}
            className={styles.shareButton}
          >
            {isLinkCopied && (
              <Tooltip timeout={2000} onTimeout={() => setIsLinkCopied(false)}>
                Link copied!
              </Tooltip>
            )}
            <LinkIcon fill={linkShareLink ? colors.$text_primary : colors.$text_secondary} />
          </Button>
        </>
      )}

      {showShareButton && (
        <Button full variant={buttons.variant.primary} onClick={getNativeShare}>
          Share
        </Button>
      )}
    </div>
  );
}
