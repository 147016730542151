import { useMemo } from 'react';

import logger from '@pray/shared/utils/logger';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useDonationFunds from 'hooks/donationFunds/useDonationFunds';
import useSaveDonationFund from 'hooks/donationFunds/useSaveDonationFund';

export default function useFundListPage() {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const fundsQuery = useDonationFunds(artistId);
  const { saveDonationFund } = useSaveDonationFund(artistId);

  const funds = useMemo(() => fundsQuery.data?.pages?.flatMap((page) => page.data) || [], [fundsQuery.data]);

  async function handleToggleEnabled(fund) {
    try {
      await saveDonationFund({
        fundId: fund.id,
        name: fund.name,
        description: fund.description,
        isEnabled: !fund.isEnabled,
        defaultAmountMonthly: fund.defaultAmountMonthly,
        defaultAmountYearly: fund.defaultAmountYearly,
        amountMonthly2: fund.amountMonthly2,
        amountYearly2: fund.amountYearly2,
      });

      toast.show({ success: 'Fund updated successfully' });
    } catch (error) {
      logger.error('Failed to update fund', error);
      toast.show({ error: 'Failed to update fund' });
      throw error;
    }
  }

  return {
    funds,
    fundsQuery,
    handleToggleEnabled,
  };
}
