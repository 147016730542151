import React from 'react';

export function Share() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6754 0.262846C11.2931 -0.0876153 10.7063 -0.0876153 10.324 0.262846L6.32396 3.92951C5.91684 4.3027 5.88933 4.93527 6.26253 5.34239C6.63572 5.74951 7.26829 5.77701 7.6754 5.40382L9.99968 3.27324V17C9.99968 17.5523 10.4474 18 10.9997 18C11.552 18 11.9997 17.5523 11.9997 17V3.27324L14.324 5.40382C14.7311 5.77701 15.3636 5.74951 15.7368 5.34239C16.11 4.93527 16.0825 4.3027 15.6754 3.92951L11.6754 0.262846Z"
        fill="white"
      />
      <path
        d="M4.33301 7.33333H6.33301V9.33333H4.33301C3.22844 9.33333 2.33301 10.2288 2.33301 11.3333V22C2.33301 23.1046 3.22844 24 4.33301 24H17.6663C18.7709 24 19.6663 23.1046 19.6663 22V11.3333C19.6663 10.2288 18.7709 9.33333 17.6663 9.33333H15.6663V7.33333H17.6663C19.8755 7.33333 21.6663 9.1242 21.6663 11.3333V22C21.6663 24.2091 19.8755 26 17.6663 26H4.33301C2.12387 26 0.333008 24.2091 0.333008 22V11.3333C0.333008 9.1242 2.12387 7.33333 4.33301 7.33333Z"
        fill="white"
      />
    </svg>
  );
}
