export * from './createEmailCampaign';
export * from './updateEmailCampaign';
export * from './getAllEmailCampaigns';
export * from './getEmailCampaign';
export * from './deleteEmailCampaign';
export * from './sendTestEmail';
export * from './getEmailCampaignStatus';
export * from './getEmailCampaignFromWorkflowAI';

export function emailCampaignMapper(emailCampaign) {
  return {
    id: emailCampaign.id,
    name: emailCampaign.name,
    status: emailCampaign.status,
    automationType: emailCampaign.CampaignAutomation?.handle ?? null,
    subjectText: emailCampaign.subject_text,
    previewText: emailCampaign.preview_text,
    scheduledAt: emailCampaign.scheduled_at,
    rawHtml: emailCampaign.raw_html,
    rawCss: emailCampaign.raw_css,
    compiledHtmlCss: emailCampaign.compiled_html_css,
    previewImgUrl: emailCampaign.preview_img_url,
    htmlCssUpdatedAt: emailCampaign.html_css_updated_at,
    sendCount: emailCampaign.send_count || 0,
    openCount: emailCampaign.open_count || 0,
    clickCount: emailCampaign.click_count || 0,
    unsubscribeCount: emailCampaign.unsubscribe_count || 0,
    spamReportCount: emailCampaign.spam_report_count || 0,
    bounceCount: emailCampaign.bounce_count || 0,
  };
}
