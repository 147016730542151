import api from '@pray/shared/utils/api';

export async function getAnnouncementMetrics({ artistId, announcementId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!announcementId) throw Error('Announcement ID is required');

  const url = `/web/studio/artists/${artistId}/announcements/${announcementId}/metrics`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
