import React from 'react';

import styles from './PhonePreview.module.scss';

export default function BasePhonePreview({ isScrollable, children }) {
  const phoneScreenClass = [styles.phoneScreen, isScrollable ? styles.scrollable : '']
    .filter((className) => className)
    .join(' ');

  return (
    <div className={styles.phoneBorder}>
      <div className={styles.phoneIsland} />
      <div className={phoneScreenClass}>{children}</div>
    </div>
  );
}
