import { useMutation, useQueryClient } from '@tanstack/react-query';

import { LOCALES } from '@pray/shared/constants';
import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useTriggerTranslationSynthesis(artistId, contentId, locale) {
  const queryClient = useQueryClient();

  async function triggerTranslationSynthesis() {
    // If locale is english we shouldn't trigger translation synthesis (only for non-english locales)
    if (locale === LOCALES.DEFAULT) return;

    await studioService.workflowAI.triggerTranslationSynthesis({
      artistId,
      contentId,
      locale,
    });
  }

  const mutation = useMutation({
    mutationFn: triggerTranslationSynthesis,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistContentDetails(artistId, contentId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    triggerTranslationSynthesis: mutateAsync,
    ...rest,
  };
}
