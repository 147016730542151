import { useLocation } from 'react-router-dom';

import Link from '@pray/shared/components/ui/Link/Link';
import Text from '@pray/shared/components/ui/Text/Text';

import { variations } from './constants';

import styles from './PeriodicTable.module.scss';

const PeriodicTableElementCard = ({
  to,
  rank,
  code,
  name,
  highlight,
  table,
  group,
  selectedGroup,
  gridArea,
  variation = 0,
  className = '',
  onClick = () => null,
}) => {
  const location = useLocation();

  let selectedGroupClass = '';

  if (selectedGroup === group) {
    selectedGroupClass = styles.isSelected;
  } else if (selectedGroup) {
    selectedGroupClass = styles.isDisabled;
  }

  return (
    <Link
      to={to}
      state={{ from: location.pathname }}
      className={`flex flex-col ${styles.groupElement} ${selectedGroupClass} ${className}`}
      style={{ gridArea }}
      data-group={group}
      onClick={onClick}
      onKeyDown={onclick}
      tabIndex={-1}
    >
      <div className="h-1.5 rounded-t-lg border-t-0" style={{ backgroundColor: variations[variation].border }} />
      <div className="relative h-full rounded-b-lg p-2" style={{ backgroundColor: variations[variation].background }}>
        <Text
          className="absolute right-2 top-1 w-5 text-right text-[10px] font-bold"
          style={{ color: variations[variation].color }}
        >
          {rank}
        </Text>

        <Text className="block text-[24px] font-bold leading-tight" style={{ color: variations[variation].color }}>
          {code}
        </Text>

        <Text className="block h-6 text-[9px] leading-3 text-[#0B0C0D]">{name}</Text>

        <section className="my-1 rounded py-1 text-center" style={{ backgroundColor: highlight.color }}>
          <Text className="block text-[8px] font-bold">{Number(highlight.value).toLocaleString('en-US')}</Text>
          <Text className="block text-[8px] font-medium">{highlight.label}</Text>
        </section>

        <table className="w-full rounded bg-white">
          <caption className="my-1 text-left text-[6px] font-medium text-[#0B0C0D]">{table.caption}</caption>
          <thead className="rounded border-b border-b-[#E4DEF0] text-center">
            <tr className="h-3 rounded">
              <th className="text-[6px] font-bold">
                <Text>{table.records[0].label}</Text>
              </th>
              <th className="text-[6px] font-bold">
                <Text>{table.records[1].label}</Text>
              </th>
              <th className="text-[6px] font-bold">
                <Text>{table.records[2].label}</Text>
              </th>
            </tr>
          </thead>
          <tbody className="rounded text-center">
            <tr className="h-3 rounded">
              <td className="text-[6px]">
                <Text>{table.records[0].value}</Text>
              </td>
              <td className="text-[6px]">
                <Text>{table.records[1].value}</Text>
              </td>
              <td className="text-[6px]">
                <Text>{table.records[2].value}</Text>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Link>
  );
};

export default PeriodicTableElementCard;
