import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export async function updateGivingAccount({
  artistId = '',
  accountId = '',
  payoutInterval = '',
  statementDescriptor = '',
} = {}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!accountId) throw new Error('Giving Account ID is required');
  if (!payoutInterval) throw new Error('Payout Interval is required');

  const params = {
    payout_interval: payoutInterval,
  };

  if (statementDescriptor !== undefined) params.statement_descriptor = statementDescriptor;

  const url = `/web/studio/artists/${artistId}/giving-accounts/${accountId}`;
  const response = await api.executePutRequest(url, params);

  return response.data;
}

export function useUpdateGivingAccount() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateGivingAccount,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        exact: true,
        queryKey: queryKeys.artist(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    updateGivingAccount: mutateAsync,
    ...rest,
  };
}
