import React from 'react';

const JumpForwardIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5296 1.36322C12.8225 1.07033 13.2974 1.07033 13.5903 1.36322L16.2421 4.01505C16.535 4.30794 16.535 4.78282 16.2421 5.07571L13.5903 7.72758C13.2974 8.02048 12.8225 8.02048 12.5296 7.72758C12.2367 7.43469 12.2367 6.95982 12.5296 6.66692L13.9424 5.25411C11.4388 4.58557 8.6596 5.23418 6.69713 7.19665C3.76798 10.1258 3.76795 14.8751 6.69702 17.8041C9.62608 20.7332 14.3753 20.7332 17.3045 17.804C19.9037 15.2048 20.1969 11.1707 18.1822 8.24866C17.9471 7.90765 18.0329 7.4406 18.3739 7.20548C18.715 6.97035 19.182 7.05619 19.4171 7.39721C21.8352 10.9043 21.4855 15.7443 18.3652 18.8647C14.8502 22.3796 9.15123 22.3797 5.63636 18.8648C2.12149 15.3499 2.12156 9.6509 5.63647 6.13599C7.85054 3.92192 10.9294 3.10323 13.783 3.6772L12.5296 2.42388C12.2368 2.13099 12.2368 1.65612 12.5296 1.36322Z"
        fill="#A6A8AD"
      />
      <path
        d="M9.86994 14.8366V10.9551H9.15244C8.93015 10.9551 8.74994 10.7749 8.74994 10.5526C8.74994 10.3303 8.93015 10.1501 9.15244 10.1501H10.7309V14.8366C10.7309 15.0744 10.5382 15.2671 10.3004 15.2671C10.0627 15.2671 9.86994 15.0744 9.86994 14.8366Z"
        fill="#A6A8AD"
      />
      <path
        d="M15.2361 13.4891C15.2361 14.5951 14.4592 15.3511 13.3182 15.3511C12.4078 15.3511 11.7604 14.8555 11.5867 14.0705C11.5337 13.8308 11.7392 13.6291 11.9847 13.6291C12.2301 13.6291 12.4136 13.8373 12.5091 14.0634C12.6416 14.377 12.9329 14.5531 13.3392 14.5531C13.9412 14.5531 14.3261 14.1611 14.3261 13.5381C14.3261 12.9431 13.9692 12.5371 13.3532 12.5371C12.9612 12.5371 12.6042 12.7331 12.4222 12.9991L11.6312 12.8311L12.2612 10.1501H14.5152C14.7432 10.1501 14.9282 10.335 14.9282 10.5631C14.9282 10.7912 14.7432 10.9761 14.5152 10.9761H12.9052L12.6322 12.0401C12.8772 11.8791 13.1852 11.7881 13.5352 11.7881C14.5572 11.7881 15.2361 12.4811 15.2361 13.4891Z"
        fill="#A6A8AD"
      />
    </svg>
  );
};

export default JumpForwardIcon;
