import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type UpdateBookParams = {
  artistId: string;
  bookId: string;
  title: string;
  description: string;
  imageUrl?: string;
};

export function useUpdateBook() {
  const queryClient = useQueryClient();

  async function updateBook({ artistId, bookId, title, description, imageUrl }: UpdateBookParams): Promise<void> {
    await studioService.books.updateBook({
      artistId,
      bookId,
      title,
      description,
      imageUrl,
    });
  }

  function onSuccess(_, variables) {
    queryClient.invalidateQueries({ queryKey: queryKeys.artistBooks(variables.artistId) });
    queryClient.invalidateQueries({
      queryKey: queryKeys.artistBookDetails(variables.artistId, variables.bookId),
    });
  }

  const { mutateAsync } = useMutation({
    mutationFn: updateBook,
    onSuccess,
  });

  return { updateBook: mutateAsync };
}
