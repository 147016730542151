import React from 'react';

import FilterHeader from './Header/FilterHeader';
import FilterSections from './Sections/FilterSections';

export default function CalendarFilter({
  fetchedSeries,
  selectedTypes,
  selectedStatuses,
  selectedSeries,
  setSelectedTypes,
  setSelectedStatuses,
  setSelectedSeries,
  filterTypes,
  filterStatuses,
  filterSeries,
}) {
  return (
    <div
      role="dialog"
      aria-modal="true"
      className="absolute z-10 mt-2 h-[532px] w-[380px] rounded-xl bg-white shadow-[0_4px_16px_0_rgba(0,0,0,0.16)]"
    >
      {/* Header Section */}
      <FilterHeader
        selectedTypes={selectedTypes}
        selectedStatuses={selectedStatuses}
        selectedSeries={selectedSeries}
        setSelectedTypes={setSelectedTypes}
        setSelectedStatuses={setSelectedStatuses}
        setSelectedSeries={setSelectedSeries}
      />

      {/* Border */}
      <hr className="w-full border-b border-[#DEDFE3]" />

      {/* Options */}
      <FilterSections
        fetchedSeries={fetchedSeries}
        filterTypes={filterTypes}
        filterStatuses={filterStatuses}
        filterSeries={filterSeries}
        setSelectedTypes={setSelectedTypes}
        setSelectedStatuses={setSelectedStatuses}
        setSelectedSeries={setSelectedSeries}
        selectedTypes={selectedTypes}
        selectedStatuses={selectedStatuses}
        selectedSeries={selectedSeries}
      />
    </div>
  );
}
