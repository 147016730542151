import { DateTime } from 'luxon';

import { capitalize } from '@pray/shared/utils';

import useAppRoutes from 'hooks/useAppRoutes';

import DayCalendarEvent from './DayCalendarEvent';
import { CameraIcon, ChurchIcon, EnvelopeIcon, ImageIcon, PaperIcon, PlayIcon } from './event-icons';
import MonthCalendarEvent from './MonthCalendarEvent';
import WeekCalendarEvent from './WeekCalendarEvent';
import { eventTypeColors, eventTypes, viewTypes } from '../constants';

/**
 * Represents a calendar event.
 *
 * @param {Object} props - The event properties.
 * @param {string} props.viewType - The type of calendar view.
 * @param {string} props.title - The title of the event.
 * @param {string} props.status - The status of the event.
 * @param {string} props.startDate - The start date and time of the event.
 * @param {string} props.endDate - The end date and time of the event.
 * @param {import('react').ReactNode} props.icon - The icon of the event.
 * @param {import('luxon').DateTime} props.date - The date and time of the event.
 * @param {number} props.width - The width of the event container.
 * @param {string} props.color - The color of the event.
 * @param {string} props.backgroundColor - The background color of the event.
 * @param {string} props.onHover - The background color of the event when hovering.
 * @param {boolean} props.isAllDay - Indicates if the event is an all-day event.
 * @param {boolean} props.isCompact - Indicates if the event should be displayed in a compact format.
 * @param {boolean} props.isDisplayTime - Indicates if the event should display the time.
 * @param {boolean} props.isEventStart - Indicates if the event is the start of an event.
 * @param {boolean} props.isEventEnd - Indicates if the event is the end of an event.
 * @param {boolean} props.isSaturday - Indicates if the event is on a Saturday.
 * @param {Function} props.to - The route to navigate when the event is clicked.
 * @returns {import('react').ReactNode} The rendered calendar event component.
 */
function CalendarEvent(props) {
  const { viewType, date, isAllDay, isDisplayTime = true } = props;

  const eventProps = {
    ...props,
    time: date.toFormat('h:mm a'),
    isDisplayTime: isDisplayTime && !isAllDay,
  };

  let eventCard = null;

  if (viewType === viewTypes.month || isAllDay) {
    eventCard = <MonthCalendarEvent {...eventProps} />;
  } else if (viewType === viewTypes.week) {
    eventCard = <WeekCalendarEvent {...eventProps} />;
  } else {
    eventCard = <DayCalendarEvent {...eventProps} />;
  }

  const event = props;
  const startTime = DateTime.fromISO(event.startDate).toFormat('h:mm a');
  const endTime = DateTime.fromISO(event.endDate).toFormat('h:mm a');
  const eventTime = event.endDate && startTime !== endTime ? `${startTime} - ${endTime}` : startTime;
  const cardTitle = `${eventTime}\n${event.title}\n[${capitalize(event.status)}]`;

  return <div title={cardTitle}>{eventCard}</div>;
}

export function EpisodeEvent(props) {
  const appRoutes = useAppRoutes();
  const { payload, isVideo = false } = props;
  const contentId = payload.content_id;
  const { primary, secondary, onHover } = eventTypeColors[eventTypes.episode];
  const icon = isVideo ? <CameraIcon /> : <PlayIcon />;

  const getEventRoute = () => {
    return appRoutes.artistContentDetails(contentId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}

export function AnouncementEvent(props) {
  const appRoutes = useAppRoutes();
  const { status, payload } = props;
  const postId = payload.post_id;
  const { primary, secondary, onHover } = eventTypeColors[eventTypes.announcement];
  const icon = <PaperIcon />;

  const getEventRoute = () => {
    if (status === 'draft') return appRoutes.artistAnnouncementEdit(postId);
    return appRoutes.artistAnnouncementDetailsOverview(postId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}

export function EmailEvent(props) {
  const appRoutes = useAppRoutes();
  const { status, payload } = props;
  const emailCampaignId = payload.email_campaign_id;
  const { primary, secondary, onHover } = eventTypeColors[eventTypes.email];
  const icon = <EnvelopeIcon />;

  const getEventRoute = () => {
    if (status === 'draft') return appRoutes.artistEmailsEdit(emailCampaignId);
    return appRoutes.artistEmailsOverview(emailCampaignId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}

export function DailyEvent(props) {
  const appRoutes = useAppRoutes();
  const { payload } = props;
  const dailySeriesId = payload.daily_series_id;
  const dailyItemId = payload.daily_item_id;
  const { primary, secondary, onHover } = eventTypeColors[eventTypes.daily];
  const icon = <ImageIcon />;

  const getEventRoute = () => {
    return appRoutes.dailySeriesIdDailyItemsId(dailySeriesId, dailyItemId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}

export function MinistryMatchEvent(props) {
  const appRoutes = useAppRoutes();
  const { payload } = props;
  const leadCampaignId = payload.lead_campaign_id;
  const { primary, secondary, onHover } = eventTypeColors[eventTypes.ministryMatch];
  const icon = <ChurchIcon />;

  const getEventRoute = () => {
    return appRoutes.ministryMatchCampaignDetails(leadCampaignId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}

export function SocialPostEvent(props) {
  const appRoutes = useAppRoutes();
  const { payload } = props;
  const contentId = payload.content_id;

  const { primary, secondary, onHover } = eventTypeColors[eventTypes.socialPost];
  const icon = <CameraIcon />;

  const getEventRoute = () => {
    return appRoutes.artistContentSocialClips(contentId);
  };

  return (
    <CalendarEvent
      {...props}
      icon={icon}
      color={primary}
      backgroundColor={secondary}
      onHover={onHover}
      to={getEventRoute()}
    />
  );
}
