import React from 'react';

import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

import PlaceSearchInput from 'components/pages/StudioPage/components/PlaceSearchInput/PlaceSearchInput';
import Section from 'components/pages/StudioPage/components/Section/Section';

import OrganizationPlaceDetails from '../OrganizationPlaceDetails/OrganizationPlaceDetails';
import styles from '../ProfileTab.module.scss';

export default function LeaderLocation({ form, validationValues }) {
  const handleRemovePlace = () => {
    form.setValues({
      ...form.values,
      google_place_id: '',
      location_address: '',
      latitude: '',
      longitude: '',
    });
  };

  const getPlaceDetails = (place) => {
    const placeData = {
      placeId: place.place_id,
      placeName: place.name,
      placeAddress: place.formatted_address,
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };

    return placeData;
  };

  const handlePlaceChanged = (place) => {
    const { placeId, placeName, placeAddress, latitude, longitude } = getPlaceDetails(place);

    const updatedFormValues = {
      ...form.values,
      google_place_id: placeId,
      location_address: placeAddress,
      latitude,
      longitude,
    };

    // do not replace the location name if one is already set
    if (!form.values.location_name) updatedFormValues.location_name = placeName;

    form.setValues(updatedFormValues);
  };

  return (
    <Section title="Leader Location" className="max-w-4xl">
      {!form.values.google_place_id && (
        <Text variant="subhead_small" className="block text-[#3A3C40]">
          Search by typing in the name or address of your location
        </Text>
      )}

      <OrganizationPlaceDetails address={form.values.location_address} onRemoveClick={handleRemovePlace} />

      {!form.values.google_place_id && (
        <div className="mt-4 border border-gray-300">
          <PlaceSearchInput
            latitude={form.values.latitude}
            longitude={form.values.longitude}
            onPlaceChanged={handlePlaceChanged}
            onMapClick={handlePlaceChanged}
          />
        </div>
      )}

      <div className={styles.formField}>
        <Text className={styles.label}>Ministry Name</Text>
        <Text className={styles.description}>
          The name of your ministry or name associated to your ministry location
        </Text>

        <TextInput
          label="Ministry Name"
          name="location_name"
          value={form.values.location_name}
          errorMessage={form.errors.location_name}
          onChange={form.handleChange}
        />

        <InputHelperText>
          <span>
            Your ministry name must contain {validationValues.ministryNameMinLength}-
            {validationValues.ministryNameMaxLength} letters.
          </span>
          <span>
            {form.values.location_name.length}/{validationValues.ministryNameMaxLength}
          </span>
        </InputHelperText>
      </div>
    </Section>
  );
}
