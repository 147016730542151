import { useState, useEffect } from 'react';

import { useStudioContext } from '@/context/StudioContext';
import { useCreateSocialClips } from '@/hooks/socialClips/useCreateSocialClips';
import { useSocialClips } from '@/hooks/socialClips/useSocialClips';

import { socialClipGenerationStatus } from './constants';

const states = {
  empty: 'empty',
  loading: 'loading',
  invalidFormat: 'invalidFormat',
  generating: 'generating',
  error: 'error',
  list: 'list',
};

export function useSocialClipsPage(content) {
  const [state, setState] = useState(states.loading);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const contentId = content?.id;

  const { data, isLoading, refetch } = useSocialClips(artistId, contentId);
  const { createSocialClips } = useCreateSocialClips();

  async function handleGenerateSocialClips() {
    try {
      setState(states.generating);
      await createSocialClips({ artistId, contentId });
      refetch();
    } catch (error) {
      setState(states.error);
    }
  }

  useEffect(() => {
    if (!content.allowedSocialClips) {
      setState(states.invalidFormat);
      return;
    }

    if (isLoading) {
      setState(states.loading);
      return;
    }

    if (data?.status === socialClipGenerationStatus.started) {
      setState(states.generating);
      return;
    }

    if (data?.status === socialClipGenerationStatus.completed) {
      setState(states.list);
      return;
    }

    if (data?.status === socialClipGenerationStatus.failed) {
      setState(states.error);
      return;
    }

    setState(states.empty);
  }, [contentId, isLoading, data]);

  return {
    state,
    states,
    data,
    handleGenerateSocialClips,
  };
}
