import { create } from 'zustand';

export const TASKS_TO_BE_PROCESSED = {
  TRANSCRIPT: 'transcript',
  SUMMARY: 'summary',
  SHORT_SUMMARY: 'short_summary',
  ARTICLE: 'article',
  STUDY_GUIDE: 'study_guide',
  CHAPTERS: 'chapters',
  ANNOUNCEMENT: 'announcement',
  EMAIL_CAMPAIGN: 'email_campaign',
};

const useWorkflowAIStatus = create((set) => ({
  isLoading: false,
  tasksDone: new Set(),
  setWorkflowStatusLoading: (loading) => set({ isLoading: loading }),
  addTaskDone: (task) =>
    set((state) => {
      const newTasksDone = new Set(state.tasksDone);
      newTasksDone.add(task);
      return { tasksDone: newTasksDone };
    }),
  clearTasksDone: () => set({ tasksDone: new Set() }),
}));

export default useWorkflowAIStatus;
