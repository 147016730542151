import React from 'react';
import { create } from 'zustand';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import ShareButtons from '@pray/shared/components/ShareButtons/ShareButtons';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import { EVENTS } from '@pray/shared/constants';
import shareService from '@pray/shared/services/shareService';
import { isMobileBrowser } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';

import styles from './NewShareModal.module.scss';

export const useShareModal = create((set) => ({
  options: null,
  openShareModal: (options) => set(() => ({ options })),
  closeShareModal: () => set({ options: null }),
}));

export default function NewShareModal({ image, objectName, objectType, shareObject, close }) {
  const useNativeShare = isMobileBrowser() && typeof window.navigator.share !== 'undefined';

  const handleNativeShare = async () => {
    try {
      let url = shareObject.external_share_link;

      if (!url) {
        const response = await shareService.getSharingLinks({
          id: shareObject.id,
          type: objectType,
        });

        url = response.data.external_share_link;
      }

      await navigator.share({ url });
    } catch (err) {
      logger.debug('Failed handling native sharing', err);
    }
  };

  if (useNativeShare) {
    handleNativeShare();
    close();
    return null;
  }

  return (
    <Modal data-viewname={EVENTS.PRAY_EVENTS.VIEW_EVENT_NAMES.SHARE_MODAL} className={styles.modal} onClose={close}>
      <Text block variant={typography.hero}>
        Share {objectName || 'Content'}
      </Text>

      <img className={styles.image} src={image} alt="" />

      <ShareButtons objectName={objectName} objectType={objectType} shareObject={shareObject} />

      <Button color={colors.text_secondary} size={buttons.size.large} onClick={close}>
        Close
      </Button>
    </Modal>
  );
}
