import Loading from '@pray/shared/components/Loading/Loading';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';

export const ChartWidget = ({ isLoading = false, title = '', description = null, info = '', children }) => {
  return (
    <div className="flex flex-1 flex-col gap-1 rounded-xl border border-[#CBCDD2] p-6">
      {title && (
        <Loading isLight {...{ isLoading, margin: '2px 0', height: '24px', width: '200px' }}>
          <div className="flex items-center gap-2">
            <Text variant="h2">{title}</Text>
            <Popover title={title} description={info}>
              <Info className="size-5 text-[#56585E]" />
            </Popover>
          </div>
        </Loading>
      )}

      {description && (
        <Loading isLight {...{ isLoading, margin: '2px 0', height: '18px', width: '300px' }}>
          <div className="flex items-center gap-1">
            <Text variant="subhead_small" className="font-medium text-[#56585E]">
              {description}
            </Text>
          </div>
        </Loading>
      )}

      {children}
    </div>
  );
};
