import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import emailPreview from '../assets/email-preview.svg';

export default function EmailPreviewFrame({ html }) {
  return (
    <section className="sticky left-8 top-32 max-w-80 space-y-4">
      <Text variant={typography.headline_small} className="font-medium">
        Preview
      </Text>

      <TemplatePreview html={html} />
    </section>
  );
}

function TemplatePreview({ html }) {
  if (!html) {
    return (
      <div className="p-[3px] rounded-[9px] shadow-[0_0_7px_lightgray]">
        <img src={emailPreview} alt="Preview" />
      </div>
    );
  }

  const previewHtml = `
    <main style="position:relative;">
      <div style="position:absolute;left:0;right:0;top:0;bottom:0;z-index:100;"></div>
      ${html}
    </main>
  `;

  return (
    <div className="relative flex h-[470px] w-[316px] items-center justify-center overflow-hidden rounded-md border bg-white shadow-md">
      <iframe
        id="preview-iframe"
        srcDoc={previewHtml}
        title="Email Preview"
        className="no-scrollbar absolute h-[900px] w-[600px] scale-[0.51] overflow-x-hidden rounded-md border-none"
      />
    </div>
  );
}
