import api from '@pray/shared/utils/api';

export async function createRssFeed(artistId, url) {
  if (!artistId) throw Error('Artist ID is required');
  if (!url) throw Error('URL is required');

  const data = {
    url,
  };

  const response = await api.executePostRequest(`/web/studio/artists/${artistId}/rss-feeds`, data);

  return response.data.data;
}
