import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';

import { Paused, Enabled } from 'images/icons';

const COLORS = {
  PAUSED: '#540304',
  ENABLED: '#04341A',
  BG_PAUSED: '#FCBEBE',
  BG_ENABLED: '#D8F9E9',
};

export default function RevenueStatusItem({ isPaused, title, info }) {
  const statusColor = isPaused ? COLORS.PAUSED : COLORS.ENABLED;
  const statusBgColor = isPaused ? COLORS.BG_PAUSED : COLORS.BG_ENABLED;
  const StatusIcon = isPaused ? Paused : Enabled;
  const dimension = 18;

  return (
    <div className="flex justify-between rounded-xl border border-[#D3D3D3] p-4">
      <div className="flex items-center justify-center gap-1">
        <Text className="text-base font-bold text-[#5A5A5A]">{title}</Text>
        <Popover description={info}>
          <Info width={dimension} height={dimension} style={{ color: COLORS.TITLE }} />
        </Popover>
      </div>
      <div
        className="flex items-center justify-center gap-1 rounded-full px-2 py-1"
        style={{ backgroundColor: statusBgColor }}
      >
        <StatusIcon width={dimension} height={dimension} style={{ color: statusColor }} />
        <Text variant={typography.body_md} className="!font-bold" style={{ color: statusColor }}>
          {isPaused ? 'Paused' : 'Enabled'}
        </Text>
      </div>
    </div>
  );
}
