import React, { useEffect, useState } from 'react';
import { Geographies } from 'react-simple-maps';

import { GEO_URL, US_STATES } from '@pray/shared/constants';

import { getStateCode } from '../USAudienceMap';

export function LeaderAudienceArcs({ audience = [], artistState, activeState }) {
  const [opacity, setOpacity] = useState(0);

  // Function to calculate projected arc path
  const calculateArcPath = (from, to, projection) => {
    const fromProjected = projection(from);
    const toProjected = projection(to);
    const midX = (fromProjected[0] + toProjected[0]) / 2;
    const midY = (fromProjected[1] + toProjected[1]) / 2 - 300; // Move the midpoint up for a curved arc
    return `M${fromProjected[0]},${fromProjected[1]} Q${midX},${midY} ${toProjected[0]},${toProjected[1]}`;
  };

  useEffect(() => {
    setOpacity(0);

    setTimeout(() => {
      setOpacity(1);
    }, 500);
  }, [audience]);

  return (
    <>
      {audience.map((aud, index) => {
        const stateCode = getStateCode(aud.state);
        const audienceStateCoords = US_STATES[stateCode]?.coords;
        const artistStateCoords = US_STATES[artistState]?.coords;

        let arcColor = '';

        if (!activeState) {
          arcColor = '#33333350';
        } else if (activeState.state === aud.state) {
          arcColor = '#000000';
        } else if (activeState.state !== aud.state) {
          arcColor = '#33333325';
        }

        return (
          <Geographies key={`geo-${aud.state}`} geography={GEO_URL}>
            {({ projection }) => (
              <path
                className="audience-arc"
                d={calculateArcPath(artistStateCoords, audienceStateCoords, projection)}
                fill="none"
                stroke={arcColor}
                strokeWidth={2}
                strokeLinecap="round"
                style={{
                  opacity,
                  transition: 'opacity 0.5s ease',
                  transitionDelay: `${index * 0.1}s`,
                }}
              />
            )}
          </Geographies>
        );
      })}
    </>
  );
}
