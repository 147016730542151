import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Select from '@pray/shared/components/ui/Select/Select';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';
import useForm from '@pray/shared/hooks/useForm';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';
import useGetDailySeriesCoverArts from 'hooks/dailySeries/useGetDailySeriesCoverArts';
import useSaveDailySeriesCoverArt from 'hooks/dailySeries/useSaveDailySeriesCoverArt';
import { AddPlus, CrossedClose, Time } from 'images/icons';

import ActionButton from '../../../components/ActionButton/ActionButton';
import { useToastMessage } from '../../../components/StudioToastMessage';
import TabPage from '../../../components/TabPage/TabPage';
import TimePickerComponent from '../../../components/TimePicker/TimePicker';

const initialValues = {
  start_time: '09:00',
  end_time: '21:00',
  priority: null,
};

export default function PopupsForm() {
  const toast = useToastMessage();

  // Internal state
  const [isPopupActive, setIsPopupActive] = useState(true);
  const [canAddTime, setCanAddTime] = useState(false);
  const [canAddPriority, setCanAddPriority] = useState(false);

  // Fetch daily series cover arts
  const { id: dailySeriesId } = useParams();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { isLoading, data: popupsData, refetch } = useGetDailySeriesCoverArts(artistId, dailySeriesId);
  const { saveCoverArts, isSaving } = useSaveDailySeriesCoverArt(artistId, dailySeriesId);

  const form = useForm(initialValues);

  useEffect(() => {
    if (!popupsData) return;
    form.setValues({
      ...form.values,
      start_time: '09:00',
      end_time: '21:00',
      priority: popupsData.priority,
    });
  }, [popupsData]);

  const handleSavePopups = async () => {
    try {
      toast.show({ success: 'Series popups rules updated successfully' });
    } catch (err) {
      logger.error(err);
      toast.show({ error: 'A problem happened when saving popups rules. Please try again later.' });
    }
  };

  const togglePopupActive = () => {
    setIsPopupActive(!isPopupActive);
    setCanAddTime(false);
    setCanAddPriority(false);
  };

  const handleAddStartTime = () => {
    const startDateTime = DateTime.now().set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
    const endDateTime = DateTime.now().set({ hour: 21, minute: 0, second: 0, millisecond: 0 });

    form.setValues({
      ...form.values,
      start_time: startDateTime.toFormat('HH:mm'),
      end_time: endDateTime.toFormat('HH:mm'),
    });

    setCanAddTime(true);
  };

  const handleAddPriority = () => {
    setCanAddPriority(true);
    form.setValue('priority', 1);
  };

  const renderTopRightButtons = () => {
    return (
      <div>
        <div className="hidden md:block">
          <div className="flex flex-1 space-x-3">
            <ActionButton
              isLoading={isSaving}
              disabled={isSaving}
              text={isSaving ? 'Saving' : 'Save'}
              onClick={handleSavePopups}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <TabPage title="PopUps" topRightButtons={renderTopRightButtons()}>
      <div className="flex-col gap-10">
        <div className="rounded-md border-2 p-6">
          {/* Setup form */}
          <div className="mb-4 flex items-center justify-between">
            <div className="flex-col">
              <span className="block font-bold">Display PopUps</span>
              <span className="block text-gray-500">Please describe how the popup behave in the platform</span>
            </div>

            <ToggleSwitch checked={isPopupActive} onChange={togglePopupActive} />
          </div>

          {/* Popups form */}
          {canAddTime && (
            <div className="flex py-2">
              <div className="mr-2 w-52">
                <TimePickerComponent
                  label="Start Time"
                  name="start_time"
                  indicatorIcon={<Time />}
                  value={form.values.start_time}
                  error={form.errors.start_time}
                  onChange={form.handleChange}
                />
              </div>

              <div className="mr-5 w-52">
                <TimePickerComponent
                  label="End Time"
                  name="end_time"
                  indicatorIcon={<Time />}
                  value={form.values.end_time}
                  error={form.errors.end_time}
                  onChange={form.handleChange}
                />
              </div>

              <button type="button" onClick={() => setCanAddTime(false)}>
                <CrossedClose />
              </button>
            </div>
          )}

          {/* Priority */}
          {canAddPriority && (
            <div className="flex py-2">
              <div className="mr-5">
                <Select
                  label="Priority"
                  name="priority"
                  value={form.values.priority}
                  error={form.errors.priority}
                  items={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({ value: item, label: item }))}
                  onChange={form.handleChange}
                  getItemLabel={(item) => item?.label}
                  getItemValue={(item) => item?.value}
                />
              </div>

              <button type="button" onClick={() => setCanAddPriority(false)}>
                <CrossedClose />
              </button>
            </div>
          )}

          {/* Buttons */}
          {isPopupActive && (
            <div className="mt-4 flex">
              {!canAddTime && (
                <Button
                  className="mr-4 flex"
                  onClick={handleAddStartTime}
                  variant={buttons.variant.secondary}
                  color={colors.pray_primary}
                >
                  <AddPlus />
                  <span className="ml-2">Add Start/End time</span>
                </Button>
              )}

              {!canAddPriority && (
                <Button
                  className="flex"
                  onClick={handleAddPriority}
                  variant={buttons.variant.secondary}
                  color={colors.pray_primary}
                >
                  <AddPlus />
                  <span className="ml-2">Add Priority</span>
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </TabPage>
  );
}
