import api from '@pray/shared/utils/api';

export async function getLeadCampaignById({ leadCampaignId }) {
  if (!leadCampaignId) throw Error('Campaign ID is required');

  const url = `/web/studio/lead-campaigns/${leadCampaignId}`;
  const params = {};
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
