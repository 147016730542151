import React from 'react';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { AiFeather, Close } from 'images/icons';

import SolomonChart from './SolomonChart';
import SolomonResponseActions from './SolomonResponseActions';
import { ResponseType } from '../useSolomon';

import Markdown from '@/components/pages/StudioPage/components/Markdown/Markdown';

export default function SolomonResponse({ response, onCopy, onDownload, onRefresh, onClose }) {
  const isText = response.type === ResponseType.TEXT_ONLY;

  return (
    <div className="flex flex-col gap-6">
      <div id="response-wrapper" className="flex flex-col gap-6">
        <div className="flex items-start gap-2">
          <AiFeather />
          <Text className="text-base !font-medium">{response.data.title}</Text>
        </div>

        <div className="flex gap-10 px-6">
          {!isText && (
            <>
              <SolomonChart response={response} />
              <div className="w-px self-stretch bg-[#DEDFE3]" />
            </>
          )}

          <div className="max-h-[400px] overflow-y-auto">
            <Markdown className="font-satoshi text-base">{response.data.text_data}</Markdown>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between">
        <SolomonResponseActions isText={isText} onCopy={onCopy} onDownload={onDownload} onRefresh={onRefresh} />
        <Button className="p-0" onClick={onClose}>
          <Close />
        </Button>
      </div>
    </div>
  );
}
