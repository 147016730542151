import api from '@pray/shared/utils/api';

export async function getEmailTemplate({ artistId = '', templateId = '' }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!templateId) throw new Error('Template ID is required');

  const url = `/web/studio/artists/${artistId}/email-templates/${templateId}`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
