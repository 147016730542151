import React from 'react';
import { useMatch } from 'react-router-dom';

import Loading from '@pray/shared/components/Loading/Loading';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useGetAnnouncementMetrics from 'hooks/announcements/useGetAnnouncementMetrics';
import { ArrowPointer, OpenEyes } from 'images/icons';

import TabPage from '../../../components/TabPage/TabPage';

export default function AnnouncementAnalytics() {
  const routeMatch = useMatch('/leaders/:artistId/announcements/:announcementId/:action');
  const { announcementId, artistId } = routeMatch.params;
  const { data, isLoading } = useGetAnnouncementMetrics(artistId, announcementId);

  return (
    <TabPage title="Analytics" data-viewname={VIEW_EVENT_NAMES.ANNOUNCEMENTS.ANALYTICS}>
      <div className="mt-6 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <AnalyticsItem
          title="Views"
          value={data?.total_impressions || 0}
          label="unique"
          icon={<OpenEyes className="mr-1 h-6" />}
          isLoading={isLoading}
        />

        <AnalyticsItem
          title="Clicks"
          value={data?.total_clicks || 0}
          label="total"
          icon={<ArrowPointer className="mr-1 h-6" />}
          isLoading={isLoading}
        />
      </div>
    </TabPage>
  );
}

function AnalyticsItem({ title, value, label, icon, isLoading }) {
  return (
    <div className="rounded-md border px-8 pb-10 pt-6">
      <div className="mb-2 flex items-center text-left">
        {icon}
        <span className="text-md font-bold">{title}</span>
      </div>

      <div className="text-left">
        {isLoading && <Loading isLight height="80px" />}
        {!isLoading && (
          <>
            <span className="mr-1 text-5xl font-bold">{value}</span>
            <span className="text-sm text-gray-500">{label}</span>
          </>
        )}
      </div>
    </div>
  );
}
