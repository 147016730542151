import { useEffect, useState } from 'react';

import { navigate } from '@pray/shared/utils/navigation';

import { useStudioContext } from 'context/StudioContext';

import PrayModuleDialog from '../../../components/PrayModuleDialog/PrayModuleDialog';

import coverImage from './assets/donation-module.png';

export default function DonationModuleDialog() {
  const { selectedArtist } = useStudioContext();
  const [isFundDialogOpen, setIsFundDialogOpen] = useState(false);

  useEffect(() => {
    setIsFundDialogOpen(!selectedArtist?.giving_account);
  }, [selectedArtist?.giving_account]);

  if (!isFundDialogOpen) return null;

  return (
    <PrayModuleDialog
      image={coverImage}
      title="Welcome to PRAY Revenue"
      message="To enable Revenue, please contact one of our account executives to help you get started!"
      onDone={() => navigate(-1)}
    />
  );
}
