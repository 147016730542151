import api from '@pray/shared/utils/api';

export async function updateDailySeriesCoverArts({
  artistId,
  dailySeriesId,
  imageSquareUrl,
  imagePortraitUrl,
  imageLandscapeUrl,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series Id is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}/cover-arts`;
  const response = await api.executePutRequest(url, {
    square_image_url: imageSquareUrl,
    portrait_image_url: imagePortraitUrl,
    landscape_image_url: imageLandscapeUrl,
  });

  return response?.data?.data;
}
