import type { ObjectResponse, PaginatedListResponse } from '@pray/shared/utils/api/types/response';

// eslint-disable-next-line no-shadow
export enum LeadListStatus {
  COMPLETE = 'complete',
  FAILED = 'failed',
  PROCESSING = 'processing',
}

/* eslint-disable camelcase */
export type LeadListMappings = {
  column_name: string;
  presentation_name: string;
};
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export type LeadListResponseContacts = {
  id: string;
  email: string;
  first_name?: string;
  last_name?: string;
  phone_number?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state_province_region?: string;
  postal_code?: number;
  country?: string;
  is_email_valid?: boolean;
};
/* eslint-enable camelcase */

export type LeadListResponse = PaginatedListResponse<LeadListResponseContacts>;

/* eslint-disable camelcase */
type GetLeadListsResponseList = {
  id: string;
  list_name: string;
  status: LeadListStatus;
  count: number;
  created_at: string;
};
/* eslint-enable camelcase */

export type GetLeadListsResponse = PaginatedListResponse<GetLeadListsResponseList>;

export type UpdateLeadListMetadata = {
  listName?: string;
};

/* eslint-disable camelcase */
export type LeadListMappingItem = {
  column_name: string;
  index: number;
};
/* eslint-enable camelcase */

export type CreateLeadListParams = {
  name: string;
  mapping: LeadListMappingItem[];
  s3Url: string;
  initializationVector: string;
  secretKey: string;
  artistId: string;
};

export type CreateLeadListResponse = ObjectResponse<{ id: string }>;
