import { useEffect, useState } from 'react';

export const breakpoints = {
  small: 768,
  medium: 1024,
  large: 1200,
};

export default function useHasMinWidth(minWidth = 0) {
  const [mediaQuery, setMediaQuery] = useState({
    matches: typeof window !== 'undefined' && window.innerWidth > minWidth,
  });

  useEffect(() => {
    const mediaQueryMatcher = window.matchMedia(`(min-width: ${minWidth}px)`);
    mediaQueryMatcher.addEventListener('change', setMediaQuery);
    return () => mediaQueryMatcher.removeEventListener('change', setMediaQuery);
  }, []);

  return mediaQuery.matches;
}
