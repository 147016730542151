import React from 'react';

export function Search(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 3.25C6.71979 3.25 3.25 6.71979 3.25 11C3.25 15.2802 6.71979 18.75 11 18.75C12.87 18.75 14.5853 18.0877 15.9242 16.9848L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L16.9848 15.9242C18.0877 14.5853 18.75 12.87 18.75 11C18.75 6.71979 15.2802 3.25 11 3.25ZM4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
        fill="#3C3C3D"
        fillRule="evenodd"
        clipRule="evenodd"
        {...props}
      />
    </svg>
  );
}
