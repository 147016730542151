import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import queryKeys from '@pray/shared/queryKeys';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

export default function useSaveLeadCampaign() {
  const queryClient = useQueryClient();

  function validateCampaign(campaignData, form) {
    const errors = {};

    if (!campaignData.name.trim()) errors.name = 'Invalid name';
    if (!campaignData.title.trim()) errors.title = 'Invalid title';
    if (!campaignData.subtitle.trim()) errors.subtitle = 'Invalid description';
    if (!campaignData.buttonText.trim()) errors.button_text = 'Invalid button text';
    if (!campaignData.imageUrl && !campaignData.imageFile) errors.image_url = 'Invalid image';
    if (!campaignData.type) errors.type = 'Invalid type';
    if (!campaignData.targetCount) errors.target_count = 'Invalid target count';
    if (!campaignData.leadClientId) errors.lead_client_id = 'Invalid organization';
    if (!form.values.leads_strategy) errors.leads_strategy = 'Invalid leads strategy';

    const startDate = campaignData.scheduledStartDate ? DateTime.fromISO(campaignData.scheduledStartDate) : null;
    const endDate = campaignData.scheduledEndDate ? DateTime.fromISO(campaignData.scheduledEndDate) : null;

    if (!startDate) {
      errors.scheduled_start_date = 'Invalid start date';
    } else if (endDate && startDate >= endDate) {
      errors.scheduled_end_date = 'Invalid date range';
    }

    if (
      form.values.leads_strategy === 'daily_target' &&
      (!campaignData.dailyTargetLimit || Number.isNaN(campaignData.dailyTargetLimit))
    ) {
      errors.daily_target_limit = 'Daily target limit must be a number and it is required';
    }

    return errors;
  }

  async function uploadCampaignImage(form, campaignId) {
    if (!form.values.image_file) return null;

    let imageUrl = null;

    try {
      const response = await s3Service.signAndUpload({
        file: form.values.image_file,
        type: 'leadCampaign',
        signParams: {
          lead_campaign_id: campaignId || null,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      form.setError('image_url', err);
    }

    return imageUrl;
  }

  async function createCampaign(form, campaignData) {
    const { data } = await studioService.leadCampaigns.createLeadCampaign(campaignData);

    Object.assign(campaignData, data);

    const imageUrl = await uploadCampaignImage(form, campaignData.id);

    await updateCampaign(form, { id: campaignData.id, imageUrl });
  }

  async function updateCampaign(form, campaignData) {
    const imageUrl = await uploadCampaignImage(form, campaignData.id);

    if (imageUrl) campaignData.imageUrl = imageUrl;

    await studioService.leadCampaigns.updateLeadCampaign(campaignData);
  }

  async function saveLeadCampaign(form) {
    // save or create campaign based on id field
    const campaignData = {
      id: form.values.id,
      name: form.values.name,
      title: form.values.title,
      subtitle: form.values.subtitle,
      disclaimer: form.values.disclaimer,
      buttonText: form.values.button_text,
      type: form.values.type,
      targetCount: +form.values.target_count,
      autopopulatePhoneNumber: form.values.auto_populate_phone_number,
      leadClientId: form.values.lead_client_id,
      imageUrl: form.values.image_url,
      imageFile: form.values.image_file,
      dailyTargetLimit: form.values.daily_target_limit !== undefined && +form.values.daily_target_limit,
    };

    if (form.values.scheduled_start_date) {
      campaignData.scheduledStartDate = DateTime.fromISO(form.values.scheduled_start_date, { zone: 'utc' }).toISO();
    }

    if (form.values.scheduled_end_date) {
      campaignData.scheduledEndDate = DateTime.fromISO(form.values.scheduled_end_date, { zone: 'utc' }).toISO();
    }

    if (form.values.leads_strategy !== 'daily_target') {
      campaignData.dailyTargetLimit = null;
    }

    // validate campaign required fields
    const errors = validateCampaign(campaignData, form);

    form.setErrors(errors);

    if (Object.keys(errors).length) {
      throw new Error('Invalid campaign data', { cause: errors });
    }

    if (!campaignData.id) {
      await createCampaign(form, campaignData);
    } else {
      await updateCampaign(form, campaignData);
    }

    return campaignData;
  }

  const mutation = useMutation({
    mutationFn: saveLeadCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadCampaigns(),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveLeadCampaign: mutateAsync,
    ...rest,
  };
}
