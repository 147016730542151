import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Loading from '@pray/shared/components/Loading';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll';
import { useAuth } from '@pray/shared/hooks/useAuth';

import { CONTENT_SERIES_HANDLER, VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';
import { Playlist as PlaylistIcon } from 'images/icons';
import { Permissions } from 'utils/auth';

import { SERIES_TABS } from '../constants';
import { PlaylistItem } from './PlaylistItem/PlaylistItem';
import { usePlaylistsPage } from './usePlaylistsPage';
import ActionButton from '../../../components/ActionButton/ActionButton';
import EmptyState from '../../../components/EmptyState/EmptyState';
import TabPage from '../../../components/TabPage/TabPage';
import { Tabs } from '../../../components/Tabs/Tabs';
import { PlaylistsPageProps } from '../types';

export default function PlaylistsPage({ activeTab }: PlaylistsPageProps) {
  const appRoutes = useAppRoutes();
  const { permissions } = useAuth();

  const {
    hasMoreData,
    isAddMenuOpen,
    isEmpty,
    isLoading,
    playlists,
    playlistTabs,
    restrictedPlaylists,
    fetchPlaylistsData,
    handleOnDragEnd,
    setIsAddMenuOpen,
  } = usePlaylistsPage(activeTab);

  const renderTopRightButtons = () => (
    <DropdownMenu
      isOpen={isAddMenuOpen}
      onClose={() => setIsAddMenuOpen((state) => !state)}
      component={<ActionButton text="Add Series" onClick={() => setIsAddMenuOpen((state) => !state)} />}
    >
      <DropdownMenu.Item
        to={appRoutes.artistPlaylistCreate()}
        title="Custom Series"
        description="Add from previously uploaded files"
      />
      <DropdownMenu.Item
        to={appRoutes.artistRssFeedCreate()}
        title="RSS Feed"
        description="Add from an existing RSS feed"
      />
    </DropdownMenu>
  );

  const activeTabIndex = Object.values(SERIES_TABS).indexOf(activeTab);

  return (
    <TabPage title="Series" data-viewname={VIEW_EVENT_NAMES.SERIES.LIST} topRightButtons={renderTopRightButtons()}>
      {permissions.has(Permissions.STUDIO_ADMIN) && playlistTabs && (
        <Tabs tabs={playlistTabs} activeTab={activeTabIndex} tabStyle="pills" />
      )}

      <div className="transition-all duration-300 ease-in-out">
        {isEmpty && (
          <EmptyState icon={<PlaylistIcon />} title="No series available" subtitle="Your series will show up here" />
        )}

        <div className="pt-6">
          {activeTab === SERIES_TABS.SERIES && (
            <div className="transition-opacity duration-300 ease-in-out">
              <div className="animate-fade-in opacity-0">
                {restrictedPlaylists.map((playlist) => (
                  <PlaylistItem key={playlist.id} playlist={playlist} isDragDisabled isDailyItem={false} />
                ))}
              </div>
            </div>
          )}

          {isLoading && <LoadingSeries activeTab={activeTab} />}

          {playlists.length > 0 && (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="playlists">
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    className="animate-fade-in opacity-0 transition-opacity duration-300 ease-in-out"
                    {...provided.droppableProps}
                  >
                    {playlists.map((playlist, index) => (
                      <DraggablePlaylist
                        key={playlist.id}
                        index={index}
                        playlist={playlist}
                        isDailyItem={activeTab === SERIES_TABS.DAILES}
                      />
                    ))}
                    {provided.placeholder}
                    {hasMoreData && <InfiniteScroll fetchData={fetchPlaylistsData} />}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>
    </TabPage>
  );
}

const DraggablePlaylist = ({ index, playlist, isDailyItem }) => {
  const isDragDisabled = !!(
    playlist.handle === CONTENT_SERIES_HANDLER.RADIO || playlist.handle === CONTENT_SERIES_HANDLER.TV
  );

  return (
    <Draggable draggableId={playlist.id} index={index} isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <PlaylistItem
            playlist={playlist}
            orderNumber={index + 1}
            isDailyItem={isDailyItem}
            isDragging={snapshot.isDragging}
            isDragDisabled={isDragDisabled}
            {...provided.dragHandleProps}
          />
        </div>
      )}
    </Draggable>
  );
};

const LoadingSeries = ({ activeTab }) => {
  const length = 5;

  if (activeTab === SERIES_TABS.SERIES) {
    return Array.from({ length }).map((_, index) => (
      <div key={`series-${index}`} className="flex items-center gap-4 pl-10">
        <Loading isLight className="!size-[92px]" />
        <div className="flex flex-col gap-3">
          <Loading isLight className="!m-0 !w-72" />
          <Loading isLight className="!m-0 !w-96" />
          <Loading isLight className="!m-0 !h-6 !w-24 rounded-full" />
        </div>
      </div>
    ));
  }

  return Array.from({ length }).map((_, index) => (
    <div key={`dailies-${index}`} className="flex items-center gap-4 pl-10">
      <Loading isLight className="!h-[138px] !w-[92px]" />
      <div className="flex flex-col gap-3">
        <Loading isLight className="!m-0 !w-72" />
        <Loading isLight className="!m-0 !w-96" />
        <Loading isLight className="!m-0 !h-6 !w-24 rounded-full" />
      </div>
    </div>
  ));
};
