import pako from 'pako';

import s3Service from '@pray/shared/services/s3Service';
import { appendDateToFileName } from '@pray/shared/utils/dateToFilename';
import base64ToUint8Array from '@pray/shared/utils/encoding/base64ToIntArray';
import { encryptFile } from '@pray/shared/utils/encryption/encryptFile';
import { encrypt as encryptRsa, importKey } from '@pray/shared/utils/encryption/rsa';

export const useUploadEncryptedFile = ({ artistId }: { artistId: string }) => {
  async function encryptAndUploadFile<T extends File>(file: T, filename: string) {
    // Encrypt the file
    const encryptedFile = await encryptFile(file);

    // Compress the cipher
    const gzippedCiphertext = pako.gzip(encryptedFile.ciphertext);
    const { url, rsaPublicKey: rsaPublicKeyBase64 } = await s3Service.signAndUpload({
      type: 'leadsList',
      file: new Blob([gzippedCiphertext], { type: 'application/gzip' }),
      fileName: appendDateToFileName(filename),
      signParams: {
        artist_id: artistId,
      },
    });

    // Decode the RSA public key
    const rsaPublicKey = base64ToUint8Array(rsaPublicKeyBase64);
    const rsaPublicKeyCryptoKey = await importKey(rsaPublicKey);

    // Encrypt the AES key, iv, and url using RSA concurrently
    const [rsaEncryptedAesKey, rsaEncryptedAesIv, rsaEncryptedAesUrl] = await Promise.all([
      encryptRsa(rsaPublicKeyCryptoKey, encryptedFile.key),
      encryptRsa(rsaPublicKeyCryptoKey, encryptedFile.iv),
      encryptRsa(rsaPublicKeyCryptoKey, new TextEncoder().encode(url)),
    ]);

    return {
      rsaEncryptedAesKey,
      rsaEncryptedAesIv,
      rsaEncryptedAesUrl,
    };
  }

  return { encryptAndUploadFile };
};
