import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { colors, typography } from '@pray/shared/components/foundations';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Select from '@pray/shared/components/ui/Select/Select';
import SelectionCard from '@pray/shared/components/ui/SelectionCard';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput/TextInput';
import TimePicker from '@pray/shared/components/ui/TimePicker';
import useDebounce from '@pray/shared/hooks/useDebounce';
import studioService from '@pray/shared/services/studioService';

import Alert from 'components/pages/StudioPage/components/Alert/Alert';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import AnnouncementPhonePreview from 'components/pages/StudioPage/components/PhonePreview/AnnouncementPhonePreview';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { useStudioContext } from 'context/StudioContext';
import useParseResourceUrl from 'hooks/announcements/useParseResourceUrl';
import useLeaderContentSeries from 'hooks/contentSeries/useLeaderContentSeries';
import useLeaderSegments from 'hooks/segments/useLeaderSegments';
import { dateIsInFuture } from 'utils/dates';

import { publishTypes } from '../constants';

const callToActionOptions = [
  'Apply Now',
  'Book Now',
  'Contact Us',
  'Learn More',
  'Sign Up',
  'Subscribe',
  'Listen Now',
  'Give',
];

const announcementResourceTypes = [
  { name: "Don't show resource", value: '' },
  { name: 'External Link', value: 'external_url' },
  { name: 'Content on Pray', value: 'content' },
];

function AnnouncementForm() {
  const [contentList, setContentList] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentIsScheduled, setSelectedContentIsScheduled] = useState(null);
  const [selectedContentIsNotPublished, setSelectedContentIsNotPublished] = useState(null);
  const [selectedContentSeriesIsNotPublished, setSelectedContentSeriesIsNotPublished] = useState(null);
  const [isFetchingContentList, setIsFetchingContentList] = useState(false);

  const [offset, setOffset] = useState(0);
  const [hasMoreContent, setHasMoreContent] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const ITEMS_PER_PAGE = 25;

  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const leaderSegments = useLeaderSegments(artistId);
  const fetchedSegments = leaderSegments.data ?? [];
  const segments = [{ name: 'Followers', code: 'FOLLOWERS' }, ...fetchedSegments];

  const leaderContentSeriesList = useLeaderContentSeries(artistId);
  const fetchedContentSeriesList = leaderContentSeriesList.data?.pages.flatMap((page) => page.data) || [];
  const contentSeriesList = fetchedContentSeriesList.map((series) => ({
    name: series.title,
    value: series.id,
    image: series.image_url,
    is_visible: series.is_visible,
  }));

  const form = useFormContext();
  const formData = form.watch();
  const { errors } = form.formState;

  // NOTE: Parse the resource URL if one is provided
  const { parseResourceUrl, isLoading: isParsingResourceUrl } = useParseResourceUrl();
  const watchResourceUrl = form.watch('resource_url');
  const debouncedResourceUrl = useDebounce(watchResourceUrl, 750);

  async function handleResourceUrlParsing() {
    // NOTE: We are only setting the resource title and image if they are not already set
    if (!debouncedResourceUrl || (formData.resource_title && formData.resource_image_url)) return;

    const metadata = await parseResourceUrl({ resourceUrl: debouncedResourceUrl });

    form.setValue('resource_title', formData.resource_title || metadata.data?.headline);
    form.setValue('resource_image_url', formData.resource_image_url || metadata.data?.image);
  }

  useEffect(() => {
    handleResourceUrlParsing();
  }, [debouncedResourceUrl]);

  const watchResourceType = form.watch('resource_type');

  useEffect(() => {
    if (watchResourceType !== 'content') {
      setSelectedContentSeriesIsNotPublished(false);
    }
    if (watchResourceType === 'external_url' && !formData.resource_button_text) {
      form.setValue('resource_button_text', callToActionOptions[0]);
    }
  }, [watchResourceType]);

  // NOTE: Fetch content list based on selected content series
  const watchContentSeriesId = form.watch('content_series_id');
  const debouncedContentSeriesId = useDebounce(watchContentSeriesId, 750);

  // Fetch content list based on selected content series
  async function fetchLeaderContentForContentSeries(artistId, parentContentSeriesId) {
    const contentSeriesFound = contentSeriesList?.find((series) => series.value === watchContentSeriesId);
    setSelectedContentSeriesIsNotPublished(contentSeriesFound?.is_visible === false);

    // Reset content selection if the content series is changed
    if (watchContentSeriesId && !contentSeriesFound) {
      form.setValue('content_series_id', '');
      form.setValue('content_id', '');
    }

    // Avoid loading series episodes when the series is private
    if (!contentSeriesFound?.is_visible) return;

    // NOTE: Show loading indicator when fetching content list only on the first page
    if (offset === 0) {
      setIsFetchingContentList(true);
    }

    // Fetch content list based on selected content series
    const leaderContentForContentSeries = await studioService.content.getArtistContent({
      artistId,
      contentSeriesId: parentContentSeriesId,
      includeContentFromRSS: true,
      limit: ITEMS_PER_PAGE,
      offset,
    });

    // Map content list to the expected format
    const newContentItems = (leaderContentForContentSeries.data ?? []).map((content) => ({
      name: content.title,
      value: content.id,
      image: content.thumbnail_image_url,
      isPublished: content.is_published,
      publishedAt: content.published_at,
    }));

    // Check if we have more content to load
    setHasMoreContent(newContentItems.length === ITEMS_PER_PAGE);

    if (offset === 0) {
      // First page - reset the list and add the default option
      const updatedList = [{ name: "Don't select any episode", value: '' }, ...newContentItems];
      setContentList(updatedList);
      setOffset(newContentItems.length);
    } else {
      // Subsequent pages - append to existing list
      setContentList((prev) => [...prev, ...newContentItems]);
    }

    if (offset === 0) {
      setIsFetchingContentList(false);
    }

    // Reset content selection if the content series is changed
    if (!newContentItems.find((content) => content.value === formData.content_id)) {
      form.setValue('content_id', '');
    }
  }

  // NOTE: Fetch content list based on selected content series
  useEffect(() => {
    if (debouncedContentSeriesId) {
      setOffset(0); // Reset offset when content series changes
      setHasMoreContent(true);
      setContentList([]);
      fetchLeaderContentForContentSeries(artistId, debouncedContentSeriesId);
    } else {
      // Reset offset, content list, and has more content when content series is cleared
      setOffset(0);
      setContentList([]);
      setHasMoreContent(false);
    }
  }, [debouncedContentSeriesId]);

  // Populate and validated the selected content based on the content list and selected content ID
  const watchContentId = form.watch('content_id');

  useEffect(() => {
    const contentFound = contentList.find((content) => content.value === formData.content_id);

    setSelectedContent(contentFound);
    setSelectedContentIsNotPublished(contentFound?.isPublished === false);
    setSelectedContentIsScheduled(dateIsInFuture(contentFound?.publishedAt));
  }, [contentList, watchContentId]);

  // Validate if the selected content is not published or scheduled in the future
  useEffect(() => {
    if (selectedContentIsNotPublished) {
      form.setError('nonRegisteredContentIdInput', {
        type: 'manual',
        message:
          'This episode is currently private and not yet published. You’re not able to publish this announcement until the episode is public.',
      });
    } else if (selectedContentIsScheduled) {
      form.setError('nonRegisteredContentIdInput', {
        type: 'manual',
        message: `This content is already scheduled for ${DateTime.fromISO(
          selectedContent?.publishedAt
        ).toLocaleString()}. You can only publish the announcement after the content is public.`,
      });
    } else if (selectedContentSeriesIsNotPublished) {
      form.setError('nonRegisteredContentIdInput', {
        type: 'manual',
        message:
          'This series is currently private and not yet published. You’re not able to publish this announcement until the series is public.',
      });
    } else {
      form.clearErrors('nonRegisteredContentIdInput');
    }
  }, [selectedContent, selectedContentIsScheduled, selectedContentIsNotPublished, selectedContentSeriesIsNotPublished]);

  const titleMaxLength = 50;
  const bodyMaxLength = 5000;
  const resourceTitleMaxLength = 140;

  // Handle loading more content
  const handleLoadMore = async () => {
    if (!isLoadingMore && hasMoreContent) {
      try {
        setIsLoadingMore(true);
        const nextOffset = offset + ITEMS_PER_PAGE;
        setOffset(nextOffset);
        await fetchLeaderContentForContentSeries(artistId, formData.content_series_id);
      } finally {
        setIsLoadingMore(false);
      }
    }
  };

  return (
    <form className="mt-10 flex" noValidate>
      <div className="grow">
        <TextInput
          required
          label="Announcement Name"
          name="title"
          placeholder="Eg. New Book"
          errorMessage={errors.title?.message.toString()}
          {...form.register('title', {
            required: 'Missing announcement title',
            maxLength: { value: titleMaxLength, message: 'Announcement title is too long' },
          })}
        />
        <Section title="Send To" subtitle="Who are you sending this announcement to?" className="mt-11">
          <Select
            required
            label="Segments"
            placeholder="Select your segment"
            value={formData.segment_code}
            items={segments}
            getItemLabel={(item) => item?.name}
            getItemValue={(item) => item?.code}
            inputElement={TextInput}
            errorMessage={errors.segment_code?.message.toString()}
            {...form.register('segment_code', {
              required: 'Missing segment',
            })}
          />
        </Section>
        <Section title="Announcement Details" className="mt-11">
          <TextInput
            required
            rows={5}
            label="Details"
            placeholder="What do you want to say"
            name="transcript"
            helperText={`${formData.body?.length ?? 0}/${bodyMaxLength}`}
            errorMessage={errors.body?.message.toString()}
            {...form.register('body', {
              required: 'Missing announcement body',
              maxLength: { value: bodyMaxLength, message: 'Announcement body is too long' },
            })}
          />
        </Section>
        <Section title="Add a link to your announcement" className="mt-11">
          <Select
            label="Resource"
            placeholder="Select the type of resource"
            value={formData.resource_type}
            items={announcementResourceTypes}
            getItemLabel={(item) => item?.name}
            getItemValue={(item) => item?.value}
            inputElement={TextInput}
            {...form.register('resource_type')}
          />

          {formData.resource_type === 'content' && (
            <Select
              label="Series"
              placeholder="Select a series"
              value={formData.content_series_id}
              items={contentSeriesList}
              getItemLabel={(item) => item?.name}
              getItemValue={(item) => item?.value}
              getLeftImage={(item) => item?.image}
              inputElement={TextInput}
              onInfiniteScroll={leaderContentSeriesList && leaderContentSeriesList.fetchNextPage}
              {...form.register('content_series_id')}
            />
          )}

          {formData.resource_type === 'content' && formData.content_series_id && (
            <Select
              label="Episode"
              placeholder="Select an episode"
              loading={isFetchingContentList}
              loadingMore={isLoadingMore}
              disabled={isFetchingContentList}
              value={formData.content_id}
              items={contentList}
              getItemLabel={(item) => item?.name}
              getItemValue={(item) => item?.value}
              getLeftImage={(item) => item?.image}
              inputElement={TextInput}
              onInfiniteScroll={handleLoadMore}
              hasMore={hasMoreContent}
              {...form.register('content_id')}
            />
          )}

          {formData.resource_type === 'external_url' && (
            <TextInput
              label="Website URL"
              name="website_url"
              placeholder="E.g. https://www.pray.com"
              description="Enter the website URL field for your announcement. Your link will preview in the right side of the screen."
              errorMessage={errors.resource_url?.message.toString()}
              loading={isParsingResourceUrl}
              {...form.register('resource_url', {
                pattern: {
                  value: /^https:\/\/[^ "]+$/,
                  message: 'Invalid URL, please ensure your link starts with https://',
                },
              })}
            />
          )}

          {formData.resource_type === 'external_url' && formData.resource_url && (
            <div className="rounded-md border border-[#DEDFE3] bg-[#F2F3F4] p-4">
              <div className="flex items-center justify-between">
                <MediaUpload
                  required={formData.resource_type === 'external_url'}
                  display="inline"
                  labelPlacement="right"
                  imageUrl={formData.resource_image_url}
                  title="Image"
                  isLoading={isParsingResourceUrl}
                  description="Recommended image size: 1080 x 1080 pixels"
                  uploadButtonLabel="Upload Image"
                  error={errors.resource_image_url?.message.toString()}
                  cropProps={{ aspect: 1 }}
                  onFileChange={(file) => form.setValue('resource_image_url', file)}
                  onError={() => form.setValue('resource_image_url', '')}
                  {...form.register('resource_image_url', {
                    required: 'Missing resource image',
                  })}
                />
              </div>
              <div className="mt-6">
                <TextInput
                  required={!!formData.resource_url}
                  label="Headline"
                  name="resource_title"
                  placeholder="Eg. New Episode"
                  helperText={`${formData.resource_title?.length ?? 0}/${resourceTitleMaxLength}`}
                  errorMessage={errors.resource_title?.message.toString()}
                  {...form.register('resource_title', {
                    required: 'Missing resource title',
                    maxLength: { value: resourceTitleMaxLength, message: 'Resource title is too long' },
                  })}
                />
              </div>
              <div className="mt-6">
                <Select
                  label="Call to action"
                  placeholder="Select an option"
                  value={formData.resource_button_text}
                  items={callToActionOptions}
                  getItemLabel={(item) => item || 'Select an option'}
                  getItemValue={(item) => item || null}
                  inputElement={TextInput}
                  {...form.register('resource_button_text')}
                />
              </div>
            </div>
          )}

          {selectedContentIsNotPublished && (
            <div className="mt-2">
              <Alert
                className="w-full border border-[#F7C1B5] bg-[#FDF1EF]"
                icon={<Info color="#6B2D0D" />}
                title="Episode Not Yet Released"
                message="This episode is currently private and not yet published. You’re not able to publish this announcement until the episode is public."
              />
            </div>
          )}

          {selectedContentIsScheduled && (
            <div className="mt-2">
              <Alert
                className="w-full border border-[#F7C1B5] bg-[#FDF1EF]"
                icon={<Info color="#6B2D0D" />}
                title="Episode Scheduled"
                message={`This content is already scheduled for ${DateTime.fromISO(
                  selectedContent?.publishedAt
                ).toFormat('EEE, LLL dd, yyyy t')}. You can only publish the announcement after the content is public.`}
              />
            </div>
          )}

          {formData.resource_type === 'content' && selectedContentSeriesIsNotPublished && (
            <div className="mt-2">
              <Alert
                className="w-full border border-[#F7C1B5] bg-[#FDF1EF]"
                icon={<Info color="#6B2D0D" />}
                title="Series Not Yet Released"
                message="This series is currently private and not yet published. You’re not able to publish this announcement until the series is public."
              />
            </div>
          )}
        </Section>

        <Section title="Publish" subtitle="How do you want to send the announcement?" className="mt-11">
          <div>
            <div className="mb-4 flex flex-col items-stretch gap-3 lg:flex-row">
              <SelectionCard
                type="radio"
                value={publishTypes.NOW}
                className="w-full"
                title="Send Now"
                text="Send your announcement immediately"
                {...form.register('publishType')}
                onClick={(event) => form.setValue('publishType', event.target.value)}
              />
              <SelectionCard
                type="radio"
                value={publishTypes.SCHEDULED}
                className="w-full"
                title="Schedule Time"
                text="Schedule for a specific date and time in the future"
                {...form.register('publishType')}
                onClick={(event) => form.setValue('publishType', event.target.value)}
              />
            </div>

            {formData.publishType === publishTypes.SCHEDULED && (
              <>
                <div className="flex flex-row gap-3">
                  <DatePicker
                    label="Publish Date"
                    value={formData.scheduled_at_date}
                    minDate={DateTime.local().startOf('day').toJSDate()}
                    onChange={(date) => form.setValue('scheduled_at_date', date)}
                    errorMessage={errors.scheduled_at_date?.message.toString()}
                  />
                  <TimePicker
                    label="Time"
                    value={formData.scheduled_at_time}
                    onChange={(time) => form.setValue('scheduled_at_time', time)}
                    errorMessage={errors.scheduled_at_time?.message.toString()}
                  />
                </div>
                <Text variant={typography.heading_sm} color={colors.text_tertiary} className="mt-3">
                  Publish date and time are based on you current timezone ({DateTime.local().offsetNameShort})
                </Text>
              </>
            )}
          </div>
        </Section>
      </div>
      <div className="ml-16">
        <div className="sticky top-28">
          <Text variant={typography.headline_small} className="mb-4 font-medium">
            Preview
          </Text>
          <AnnouncementPhonePreview
            resourceType={formData.resource_type}
            resourceContentId={formData.content_id}
            resourceContentSeriesId={formData.content_series_id}
            resourceTitle={formData.resource_title}
            resourceImageUrl={formData.resource_image_url}
            resourceButtonText={formData.resource_button_text}
            body={formData.body}
          />
        </div>
      </div>
    </form>
  );
}

export default AnnouncementForm;
