import React, { createContext, useEffect, useState, ReactNode, ForwardedRef } from 'react';

import styles from './Table.module.scss';

type SelectionDetails = {
  values: Record<string, boolean>;
  selected: number[];
  unselected: number[];
  isAllSelected: boolean;
  isSomeSelected: boolean;
};

type Selection = {
  all: boolean;
  some: boolean;
  [key: number]: boolean;
};

type TableContextValue = {
  styles: { [key: string]: string };
  selectable: boolean;
  sortable: boolean;
  sortBy: string;
  selected: Selection;
  rowActions: ((index: number) => ReactNode[]) | null;
  updateSorting: (field: string) => void;
  handleSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type TableProps = {
  selectable?: boolean;
  sortable?: boolean;
  sortBy?: string;
  rowCount?: number;
  width?: string;
  className?: string;
  children?: ReactNode;
  rowActions?: ((index: number) => ReactNode[]) | null;
  onSortChange?: ((sortBy: string) => void) | null;
  onSelectionChange?: ((details: SelectionDetails) => void) | null;
};

export const TableContext = createContext<TableContextValue | null>(null);

export const Table = React.forwardRef((props: TableProps, ref: ForwardedRef<HTMLTableElement>) => {
  const {
    selectable = false,
    sortable = false,
    sortBy = '',
    rowCount = 0,
    width = '100%',
    className = '',
    rowActions = null,
    onSortChange = null,
    onSelectionChange = null,
    ...tableProps
  } = props;

  const initialSelection = (): Selection => {
    const selection: Selection = { all: false, some: false };
    for (let i = 0; i < rowCount; i++) {
      selection[i] = false;
    }
    return selection;
  };

  const [sorting, setSorting] = useState<string>(sortBy);
  const [selected, setSelected] = useState<Selection>(initialSelection());

  const updateSorting = (field: string): void => {
    const [sortField, sortOrder] = sorting.split(' ');
    const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
    const newSortBy = `${field} ${order}`;
    setSorting(newSortBy);
    onSortChange?.(newSortBy);
  };

  const handleSelectionChanges = (selection: Selection = selected): void => {
    // initialize all checkbox selection state
    for (let i = 0; i < rowCount; i++) {
      selection[i] = !!selection[i];
    }

    // check if all items are selected
    selection.all = Object.entries(selection).every(([key, val]) => /all|some/.test(key) || val);

    // check if there is at least one selected item
    selection.some = Object.entries(selection).some(([key, val]) => !/all|some/.test(key) && val);

    // update selection state
    setSelected(selection);

    // notify parent component about selection change
    onSelectionChange?.(getSelectionDetails(selection));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    // select/deselect individual items
    const selection: Selection = {
      ...selected,
      [value]: !selected[value],
    };

    // select/deselect all items
    if (value === 'all') {
      for (let i = 0; i < rowCount; i++) {
        selection[i] = selection.all;
      }
    }

    // handle changes
    handleSelectionChanges(selection);
  };

  const getSelectionDetails = (selection: Selection): SelectionDetails => {
    const { all, some, ...values } = selection;

    const selectionDetails: SelectionDetails = {
      values,
      selected: [],
      unselected: [],
      isAllSelected: all,
      isSomeSelected: some,
    };

    Object.entries(values).forEach(([key, val]) => {
      const array = val ? selectionDetails.selected : selectionDetails.unselected;
      array.push(+key);
    });

    return selectionDetails;
  };

  useEffect(() => handleSelectionChanges(), [rowCount]);

  const classes = [styles.table, className].filter(Boolean).join(' ');

  return (
    <TableContext.Provider
      value={{
        styles,
        selectable,
        sortable,
        sortBy: sorting,
        selected,
        rowActions,
        updateSorting,
        handleSelect,
      }}
    >
      <table ref={ref} className={classes} width={width} {...tableProps} />
    </TableContext.Provider>
  );
});
