import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import type { UpdateLeadListMetadata } from '@pray/shared/services/studioService/leadLists/types';

export const useUpdateLeadListMetadata = ({ artistId, listId }) => {
  const queryClient = useQueryClient();

  async function updateLeadListMetadata(metadata: UpdateLeadListMetadata) {
    const response = await studioService.leadLists.updateLeadList({
      artistId,
      listId,
      metadata,
    });

    return response;
  }

  const mutation = useMutation({
    mutationFn: updateLeadListMetadata,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadListMetadata(artistId, listId),
      });
    },
  });

  return {
    updateLeadListMetadata: mutation.mutateAsync,
    ...mutation,
  };
};
