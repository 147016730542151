const BRANCH = process.env.GATSBY_CIRCLE_BRANCH || process.env.REACT_APP_BRANCH;

// let ENV = 'development';
// let ENV = 'local';
let ENV = 'master';
ENV = BRANCH || process.env.GATSBY_ENV || process.env.REACT_APP_ENV || ENV;
exports.ENV = ENV;

// --------------------------------------------------------------------------------------------
// Amplitude
// --------------------------------------------------------------------------------------------
let amplitudeKey = '7ca06da5bfe6f27a1476000996f3976b';
if (ENV !== 'master') amplitudeKey = '6dd21fb20998eaf79faa3127f044ef1c';
exports.AMPLITUDE_KEY = amplitudeKey;

// --------------------------------------------------------------------------------------------
// Braze
// --------------------------------------------------------------------------------------------
let brazeKey = '9daa669b-32d8-46ff-a3d1-28cd99944750';
if (ENV !== 'master') brazeKey = '98302c51-dd6a-455f-9196-11fc06926b7f';
exports.BRAZE_KEY = brazeKey;

let brazeSDKEndpoint = 'sdk.iad-06.braze.com';
if (ENV !== 'master') brazeSDKEndpoint = 'sdk.iad-06.braze.com';
exports.BRAZE_SDK_ENDPOINT = brazeSDKEndpoint;

// --------------------------------------------------------------------------------------------
// Branch
// --------------------------------------------------------------------------------------------
let branchKey = 'key_live_pkFEvDVs2m1ekLDOtnbwKhadDvePet44';
if (ENV !== 'master') branchKey = 'key_test_foCszDGB7e7pcVDTqkfEIhlkszkOoSin';
exports.BRANCH_KEY = branchKey;

// --------------------------------------------------------------------------------------------
// Facebook Pixel
// --------------------------------------------------------------------------------------------
const facebookPixelId = '120473298572141';
exports.FACEBOOK_PIXEL_ID = facebookPixelId;

// --------------------------------------------------------------------------------------------
// Google Analytics
// --------------------------------------------------------------------------------------------
let googleAnalyticsKey = 'G-F4CZ9W1XY7';
if (ENV !== 'master') googleAnalyticsKey = 'G-NHMGS90PL5';
exports.GOOGLE_ANALYTICS_KEY = googleAnalyticsKey;

// --------------------------------------------------------------------------------------------
// Google Recaptcha v3
// --------------------------------------------------------------------------------------------
let googleRecaptchaSiteKey = '6Leo5dclAAAAAP2knqbHvUmqphcxUn_JlaemqrTy';
if (ENV !== 'master') googleRecaptchaSiteKey = '6Lcf6tclAAAAADhZCE6GMSVKpOMnaKRI28UpLZml';
exports.GOOGLE_RECAPTCHA_SITE_KEY = googleRecaptchaSiteKey;

// --------------------------------------------------------------------------------------------
// Google Tag Manager
// --------------------------------------------------------------------------------------------
let googleTagManagerKey = 'GTM-5L5Q887';
if (ENV !== 'master') googleTagManagerKey = 'GTM-NHHSHTD';
exports.GOOGLE_TAG_MANAGER_KEY = googleTagManagerKey;

// --------------------------------------------------------------------------------------------
// Google Maps
// --------------------------------------------------------------------------------------------
let googleMapsApiKey = 'AIzaSyCPIxTPgzpQByZrwI2WiYJye3wN7IEZmFw';
if (ENV !== 'master') googleMapsApiKey = 'AIzaSyCPIxTPgzpQByZrwI2WiYJye3wN7IEZmFw';
exports.GOOGLE_MAPS_API_KEY = googleMapsApiKey;

// --------------------------------------------------------------------------------------------
// Stripe
// --------------------------------------------------------------------------------------------
let stripeApiKey = 'pk_live_P43ApllnbBivVhS2pEOa1tTV';
if (ENV !== 'master') stripeApiKey = 'pk_test_ZVtqNEBpvrfGxKRmAzf6APnI';
exports.STRIPE_API_KEY = stripeApiKey;

// --------------------------------------------------------------------------------------------
// Sendbird
// --------------------------------------------------------------------------------------------
let sendbirdAppId = '6A829553-9402-4189-B2A1-6CC521E9CDC6';
if (ENV !== 'master') sendbirdAppId = '56E9D42A-80BC-4052-97E3-8FEAC306E727';
if (ENV === 'staging') sendbirdAppId = 'FF5F25CD-C614-4BDC-9ABC-025A5FC36EFC';
exports.SENDBIRD_APP_ID = sendbirdAppId;

// --------------------------------------------------------------------------------------------
// Backend server
// --------------------------------------------------------------------------------------------
let serverBaseURI = 'https://api.pray.com';
if (ENV === 'local') serverBaseURI = 'http://localhost:8081';
if (ENV === 'development') serverBaseURI = 'https://dev.pray.com';
if (ENV === 'staging') serverBaseURI = 'https://stage.pray.com';
if (ENV === 'qa-1') serverBaseURI = 'https://dev.pray.com';
if (ENV === 'qa-2') serverBaseURI = 'https://api.pray.com';
exports.SERVER_BASE_URI = serverBaseURI;

// --------------------------------------------------------------------------------------------
// Server JWT
// --------------------------------------------------------------------------------------------
const serverJWTForGatsbyBuild =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiODAzYzhhMzAtZjA0ZC0xMWU5LWFlYWQtMzFkOGQwZDVkNjQ0IiwiYmlydGhkYXkiOm51bGwsImFnZSI6bnVsbCwiZ2VuZGVyIjpudWxsLCJzdHJlZXRfYWRkcmVzcyI6bnVsbCwiY2l0eSI6bnVsbCwic3RhdGUiOm51bGwsInppcGNvZGUiOm51bGwsImNvdW50cnkiOm51bGwsInByb2ZpbGVfaW1hZ2UiOm51bGwsImJhY2tncm91bmRfcHJvZmlsZV9pbWFnZSI6bnVsbCwic21zIjp0cnVlLCJtYXJrZXRpbmdfc21zIjp0cnVlLCJkZXN0cm95ZWQiOmZhbHNlLCJoYXNfYWNjZXB0ZWRfdG9zX3BwIjpmYWxzZSwicGFzc3dvcmQiOm51bGwsIl9pZCI6MjY3NzkyOCwidHlwZSI6ImJhc2ljIiwidXBkYXRlZF9hdCI6IjIwMTktMTAtMTZUMTk6NDU6MjYuMDAwWiIsImNyZWF0ZWRfYXQiOiIyMDE5LTEwLTE2VDE5OjQ1OjI2LjAwMFoifSwiaWF0IjoxNTcxMjU1MTI2LCJleHAiOjk1NzEyNTU3MjZ9.BfpihbYkqL2riV67pr2BEewMHULi_mXv4-RipNEoJOU';
exports.SERVER_JWT = serverJWTForGatsbyBuild;

// --------------------------------------------------------------------------------------------
// Web base URI
// --------------------------------------------------------------------------------------------
let webBaseURI = 'https://www.pray.com';
if (ENV === 'local') webBaseURI = 'http://localhost:8000';
if (ENV === 'development') webBaseURI = 'https://development-www.pray.com';
if (ENV === 'staging') webBaseURI = 'https://stage-www.pray.com';
if (ENV === 'qa-1') webBaseURI = 'https://qa-1-www.pray.com';
if (ENV === 'qa-2') webBaseURI = 'https://qa-2-www.pray.com';
exports.WEB_BASE_URI = webBaseURI;

// --------------------------------------------------------------------------------------------
// Common Datadog Properties for All Pray Web Apps
// --------------------------------------------------------------------------------------------
exports.DATADOG_SITE = 'datadoghq.com';
exports.DATADOG_ENV = ENV;

// --------------------------------------------------------------------------------------------
// Firebase
// --------------------------------------------------------------------------------------------
exports.FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDAbCHUYUkbnDIZYYd7LEs_PlbDD6GjIyg',
  authDomain: 'fir-pray.firebaseapp.com',
  databaseURL: 'https://fir-pray.firebaseio.com',
  projectId: 'firebase-pray',
  storageBucket: 'firebase-pray.appspot.com',
  messagingSenderId: '917201567722',
  appId: '1:917201567722:web:fe9fd362d5c46eca36bc93',
  measurementId: 'G-8YXGTXYCLE',
};

// --------------------------------------------------------------------------------------------
// Giving Tips
// --------------------------------------------------------------------------------------------
exports.TIP_MODEL_DEFAULTS = {
  PERCENTAGE: [
    { DISPLAY: '30%', MULTIPLIER: 0.3 },
    { DISPLAY: '19%', MULTIPLIER: 0.19 },
    { DISPLAY: '15%', MULTIPLIER: 0.15 },
  ],
  FLAT: [
    { DISPLAY: '$3.00', VALUE: 300 },
    { DISPLAY: '$2.00', VALUE: 200 },
    { DISPLAY: '$1.00', VALUE: 100 },
  ],
};

// --------------------------------------------------------------------------------------------
// Hubspot
// --------------------------------------------------------------------------------------------
exports.HUBSPOT_FORM_SCRIPT = '//js.hsforms.net/forms/shell.js';

exports.HUBSPOT_CONTACT_FORM = {
  region: 'na1',
  portalId: '2592247',
  formId: '456917eb-a535-4da9-91af-aac3b3f14799',
  inlineMessage:
    'Thank you for being interested in partnerships with us, someone from Pray will reach out to you shortly.',
};
