import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';

import ContentItem from 'components/pages/StudioPage/components/ContentItem/ContentItem';

export const TableChart = ({ isLoading = false, data = [] }) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th field="number" width="50">
            <Loading isLight {...{ isLoading, margin: '0 14px 0 0', height: '22px', width: '32px' }}>
              #
            </Loading>
          </Th>
          <Th field="title">
            <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '48px' }}>
              Track
            </Loading>
          </Th>
          <Th field="listen_count">
            <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '102px' }}>
              Listen Count
            </Loading>
          </Th>
          <Th field="listen_minutes">
            <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '115px' }}>
              Listen Minutes
            </Loading>
          </Th>
          <Th field="favorite_listens">
            <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '121px' }}>
              Favorite Listens
            </Loading>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item) => (
          <Tr key={item.id}>
            <Td className="w-8">
              <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '24px' }}>
                {item.number}
              </Loading>
            </Td>
            <Td className="w-96">
              {isLoading ? (
                <LoadingContentItem />
              ) : (
                <ContentItem image={item.image_url} title={item.title} description={item.series_title} />
              )}
            </Td>
            <Td>
              <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '60px' }}>
                {item.listen_count?.toLocaleString()}
              </Loading>
            </Td>
            <Td>
              <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '60px' }}>
                {item.listen_minutes?.toLocaleString()}
              </Loading>
            </Td>
            <Td>
              <Loading isLight {...{ isLoading, margin: '0', height: '22px', width: '60px' }}>
                {item.favorite_listens?.toLocaleString()}
              </Loading>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const LoadingContentItem = () => (
  <div className="flex items-center gap-2">
    <Loading isLight {...{ margin: '0', height: '42px', width: '42px' }} />
    <div className="flex flex-col gap-2">
      <Loading isLight {...{ margin: '0', height: '16px', width: '148px' }} />
      <Loading isLight {...{ margin: '0', height: '12px', width: '68px' }} />
    </div>
  </div>
);
