import { formatDateUsingFullMonth } from '@pray/shared/utils/datetime';
import { navigate } from '@pray/shared/utils/navigation';

export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  let seconds = Math.floor(time) - minutes * 60;
  if (seconds < 10) seconds = `0${seconds}`;
  return `${minutes}:${seconds}`;
};

export const formatDecimal = (amount, digits = 2) => {
  const value = amount / 100;
  if (Number.isNaN(value)) return '';
  return value.toFixed(digits);
};

export const formatNumber = (amount, digits = 0) => {
  if (Number.isNaN(amount)) return '';
  const numberFormat = Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return numberFormat.format(amount);
};

export const formatCurrency = (amount, digits = 2) => {
  if (amount === null || amount === undefined || Number.isNaN(amount)) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(amount / 100);
};

export const formatDuration = (time = 0) => {
  const hrs = Math.floor(time / 3600);
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  const hh = `${hrs > 0 ? `${hrs}:` : ''}`;
  const mm = `${mins > 9 ? `${mins}:` : `0${mins}:`}`;
  const ss = `${secs > 9 ? `${secs}` : `0${secs}`}`;

  return hh + mm + ss;
};

export const formatNumberCount = (count) => {
  if (!count || count < 1e3) return count;
  if (count >= 1e3 && count < 1e6) return `${+(count / 1e3).toFixed(1)}K`;
  if (count >= 1e6 && count < 1e9) return `${+(count / 1e6).toFixed(1)}M`;
  if (count >= 1e9 && count < 1e12) return `${+(count / 1e9).toFixed(1)}B`;
  return null;
};

export const calculateSubscriptionChargeDate = (product) => {
  const today = new Date();
  const chargeDate = today;
  const currentYear = today.getFullYear();

  if (product.free_trial_interval === 'day') {
    chargeDate.setDate(chargeDate.getDate() + product.free_trial_interval_count);
  } else if (product.free_trial_interval === 'month') {
    chargeDate.setMonth(chargeDate.getMonth() + product.free_trial_interval_count);
  } else if (product.free_trial_interval === 'year') {
    chargeDate.setFullYear(chargeDate.getFullYear() + product.free_trial_interval_count);
  }

  const displayChargeYear = chargeDate.getFullYear() !== currentYear;

  return formatDateUsingFullMonth(chargeDate.getTime(), displayChargeYear);
};

export const calculateMonthlyRate = (amount) => {
  return Math.round(amount / 12);
};

export const isMobileBrowser = () => {
  if (typeof navigator === 'undefined') return false;

  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    navigator.userAgent.match(/Opera Mini/i) ||
    navigator.userAgent.match(/IEMobile/i) ||
    navigator.userAgent.match(/WPDesktop/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/BlackBerry/i)
  );
};

/**
 * Utility function that takes any string and generates a unique,
 * deterministic hash based on it.
 */
export const hashCode = (str) => {
  const ret = str.split('').reduce((a, b) => {
    // eslint-disable-next-line no-param-reassign, no-bitwise
    a = (a << 5) - a + b.charCodeAt(0);
    // eslint-disable-next-line no-bitwise
    return a & a;
  }, 0);

  return Math.abs(ret);
};

export const randNum = () => Math.floor(Math.random() * 100);

// Helper method to check if we are on a browser or in server-side static
// assets generation(build time)
export const isOnBrowser = () => typeof window !== 'undefined';

export const cacheImage = (url) => {
  const img = new Image();
  img.src = url;
};

// Gatsby's `navigate` function only runs on the client and is not
// smart enough to check for the SSR case, and ends up breaking the
// build if used on the render method. This function is a thin wrapper
// that adds this check.
// More context on this issue here: https://github.com/gatsbyjs/gatsby/issues/10421#issuecomment-777703554
export const safeNavigate = (url, options) => {
  if (isOnBrowser()) {
    return navigate(url, options);
  }
  return null;
};

export const isShareDeepLink = (url) => {
  return url.startsWith('pray://page/shareItem') || url.startsWith('pray://action/share');
};

export const isSharePageLink = (url) => {
  return url.startsWith('pray://page/shareItem');
};

export const isActionShareLink = (url) => {
  return url.startsWith('pray://action/share');
};

const fallbackCopyTextToClipboard = (text, successCallback, errorCallback) => {
  const input = document.createElement('input');
  input.value = text;
  input.readOnly = true;
  input.style.opacity = '0';

  document.body.appendChild(input);
  input.select();

  const copy = document.execCommand('copy');
  if (copy) {
    window.getSelection().removeAllRanges();
    if (successCallback) {
      successCallback();
    }
  } else if (errorCallback) {
    errorCallback();
  }

  document.body.removeChild(input);
};

export const copyToClipboard = (text, successCallback, errorCallback) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, successCallback, errorCallback);
    return;
  }

  navigator.clipboard.writeText(text).then(successCallback, errorCallback);
};

export const truncate = (string = '', length) => {
  return string.substring(0, length) + (string.length > length ? '...' : '');
};

export const combineStyles = (baseStyles, customStyles) => {
  const styles = { ...baseStyles };

  Object.keys(customStyles).forEach((key) => {
    if (styles[key]) {
      styles[key] = `${styles[key]} ${customStyles[key]}`;
    }
  });

  return styles;
};

export const scrollPageTop = (scrollTop = 0) => {
  if (!isOnBrowser()) return;

  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({
      top: scrollTop,
      behavior: 'smooth',
    });
  } else {
    document.body.scrollTop = scrollTop; // For Safari
    document.documentElement.scrollTop = scrollTop; // For Chrome, Firefox, IE and Opera
  }
};

export const randomId = () => {
  const dateString = Date.now().toString(36);
  const randValue = Math.random().toString(36).substring(2);
  return (dateString + randValue).substring(5);
};

export const capitalize = (text) => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.substring(1);
};

export const removeDuplicatesByProperty = (array = [], property = 'id') => {
  return array.filter((item, index) => {
    return index === array.findIndex((object) => item[property] === object[property]);
  });
};

export const nthNumber = (number) => {
  if (number > 3 && number < 21) return `${number}th`;

  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
};

// Based on backend name to first and last name split
export const getFirstAndLastName = (name) => {
  const [firstName, lastName] = name ? name.split(' ').map((n) => n.trim()) : [];
  return { firstName, lastName };
};
