import { useQueryClient } from '@tanstack/react-query';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from '@pray/shared/hooks/useAuth';
import studioService from '@pray/shared/services/studioService';
import { useArtist, useArtists } from '@pray/shared/services/studioService/artist';
import storage from '@pray/shared/utils/storage';

import { LOCAL_STORAGE } from 'constants.js';

export const StudioContext = createContext(null);

const initialArtistData = { id: null };

export default function StudioContextProvider({ children }) {
  const queryClient = useQueryClient();
  const { setDomainId, setUserAndPermissions } = useAuth();
  const [selectedArtist, _setSelectedArtist] = useState(initialArtistData);
  const setSelectedArtist = (artist) => _setSelectedArtist(artist ?? initialArtistData);

  const storedArtistId = storage.getFromStorage(LOCAL_STORAGE.SELECTED_ARTIST_ID);
  const activeArtistId = selectedArtist?.id || storedArtistId;

  const artistsQuery = useArtists();
  const artistQuery = useArtist(activeArtistId);

  const artists = artistsQuery.data?.data || [];
  const artist = artistQuery.data?.data;
  const isArtistLoading = artistsQuery.isLoading || artistQuery.isLoading;
  const isArtistError = artistQuery.isError;

  function setArtist(artist) {
    setDomainId(artist.id);
    setSelectedArtist({ ...artist, isResolved: true });
    storage.upsertToStorage(LOCAL_STORAGE.SELECTED_ARTIST_ID, artist.id);
  }

  useEffect(() => {
    if (isArtistLoading) return;

    if (artist) {
      setArtist(artist);
    } else if (artists.length) {
      setArtist(artists[0]);
    } else {
      setSelectedArtist({ ...initialArtistData, isResolved: true });
    }

    if (isArtistError) {
      storage.removeFromStorage(LOCAL_STORAGE.SELECTED_ARTIST_ID);
    }
  }, [artist, isArtistLoading, isArtistError]);

  const authenticate = async () => {
    const { user, permissions } = await studioService.auth.authenticate();
    user.is_permissions_resolved = true;
    setUserAndPermissions(user, permissions);
  };

  const resetContextData = () => {
    setSelectedArtist(initialArtistData);
    queryClient.clear();
  };

  return (
    <StudioContext.Provider
      value={{
        artists,
        selectedArtist,
        authenticate,
        setSelectedArtist,
        resetContextData,
      }}
    >
      {children}
    </StudioContext.Provider>
  );
}

export const useStudioContext = () => {
  const {
    //
    authenticate,
    artists,
    selectedArtist,
    setSelectedArtist,
    resetContextData,
  } = useContext(StudioContext);

  return {
    authenticate,
    artists,
    selectedArtist,
    setSelectedArtist,
    resetContextData,
  };
};
