export const pageStates = {
  loading: 'loading',
  ready: 'ready',
};

export const publishOptions = {
  now: 'now',
  scheduled: 'scheduled',
};

export const socialNetworks = {
  instagram: 'instagram',
  facebook: 'facebook',
  youtube: 'youtube',
  twitter: 'twitter',
};

export const socialClipGenerationStatus = {
  started: 'started',
  notStarted: 'not_started',
  completed: 'completed',
  failed: 'failed',
};

export const socialClipStatus = {
  draft: 'draft',
  scheduled: 'scheduled',
  published: 'published',
  failed: 'failed',
};

export const maxTitleLength = 255;
export const pollInterval = 30 * 1000; // 30 seconds
