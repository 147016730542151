import api from '@pray/shared/utils/api';

type DeleteLeadListProps = {
  artistId: string;
  listId: string;
};

export async function deleteLeadList({ artistId, listId }: DeleteLeadListProps) {
  const url = `/web/studio/artists/${artistId}/leads-lists/${listId}`;
  const response = await api.executeDeleteRequest<undefined>(url);

  return response.data;
}
