import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderContentById(artistId, contentId) {
  const fetchLeaderContentById = async () => {
    const response = await studioService.content.getArtistContentDetails({
      artistId,
      contentId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!(artistId && contentId),
    queryKey: queryKeys.artistContent(artistId, contentId),
    queryFn: fetchLeaderContentById,
  });

  return query;
}
