import React, { useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

export default function EditVoiceNameDialog({ voiceName, isLoading, onCancel, onConfirm }) {
  const [name, setName] = useState(voiceName);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Dialog width="500px">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-8">
          <Text variant={typography.heading_2xl}>Edit voice name</Text>
          <TextInput value={name} label="Voice Name" onChange={handleNameChange} />
        </div>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant={buttons.variant.primary}
            onClick={() => onConfirm(name)}
            loading={isLoading}
            disabled={!name || name === voiceName}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
