import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailCampaignMapper } from '@pray/shared/services/studioService/emailCampaigns';

export default function useGetEmailCampaignFromWorkflowAI(artistId, contentId, locale) {
  const fetchData = async () => {
    try {
      const response = await studioService.emailCampaigns.getEmailCampaignFromWorkflowAI({
        artistId,
        contentId,
        locale,
      });

      return emailCampaignMapper(response.data);
    } catch (err) {
      return null;
    }
  };

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.artistEmailCampaignFromWorkflowAI(artistId, contentId, locale),
    queryFn: fetchData,
    refetchInterval: 7000,
    refetchOnWindowFocus: true,
  });

  return query;
}
