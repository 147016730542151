export * from './cloneEmailTemplates';
export * from './createEmailTemplates';
export * from './updateEmailTemplate';
export * from './getAllEmailTemlates';
export * from './getEmailTemplate';
export * from './deleteEmailTemplate';
export * from './sendTestEmail';

export function emailTemplateMapper(emailTemplate) {
  return {
    id: emailTemplate.id,
    name: emailTemplate.name,
    rawHtml: emailTemplate.raw_html,
    rawCss: emailTemplate.raw_css,
    compiledHtmlCss: emailTemplate.compiled_html_css,
    previewImgUrl: emailTemplate.preview_img_url,
  };
}
