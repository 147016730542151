import React from 'react';

export function Microphone(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        {...props}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43774 6.75C8.43774 4.78259 10.0328 3.1875 12.0002 3.1875C13.9677 3.1875 15.5627 4.78259 15.5627 6.75V7.49819L15.5627 7.5L15.5627 7.50181V10.4982L15.5627 10.5L15.5627 10.5018V11.25C15.5627 13.2174 13.9677 14.8125 12.0002 14.8125C10.0328 14.8125 8.43774 13.2174 8.43774 11.25V6.75ZM9.56274 6.75C9.56274 5.40391 10.6542 4.3125 12.0002 4.3125C13.3463 4.3125 14.4377 5.40391 14.4377 6.75V6.9375H13.5002C13.1896 6.9375 12.9377 7.18934 12.9377 7.5C12.9377 7.81066 13.1896 8.0625 13.5002 8.0625H14.4377V9.9375H13.5002C13.1896 9.9375 12.9377 10.1893 12.9377 10.5C12.9377 10.8107 13.1896 11.0625 13.5002 11.0625H14.4377V11.25C14.4377 12.5961 13.3463 13.6875 12.0002 13.6875C10.6542 13.6875 9.56274 12.5961 9.56274 11.25V11.0625H10.5002C10.8109 11.0625 11.0627 10.8107 11.0627 10.5C11.0627 10.1893 10.8109 9.9375 10.5002 9.9375H9.56274V8.0625H10.5002C10.8109 8.0625 11.0627 7.81066 11.0627 7.5C11.0627 7.18934 10.8109 6.9375 10.5002 6.9375H9.56274V6.75Z"
        fill="currentColor"
      />
      <path
        d="M6.56274 10.5C6.56274 10.1893 6.3109 9.9375 6.00024 9.9375C5.68958 9.9375 5.43774 10.1893 5.43774 10.5V12C5.43774 15.4295 8.08119 18.2524 11.4377 18.5386V20.25C11.4377 20.5607 11.6896 20.8125 12.0002 20.8125C12.3109 20.8125 12.5627 20.5607 12.5627 20.25V18.5386C15.9193 18.2524 18.5627 15.4295 18.5627 12V10.5C18.5627 10.1893 18.3109 9.9375 18.0002 9.9375C17.6896 9.9375 17.4377 10.1893 17.4377 10.5V12C17.4377 14.9976 14.9978 17.4375 12.0002 17.4375C9.00265 17.4375 6.56274 14.9976 6.56274 12V10.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
