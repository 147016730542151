import { useRef, useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useClickAway from '@pray/shared/hooks/useClickAway';
import { cn } from '@pray/shared/utils/styles';

export default function SocialAccountDropdown({ items = [], disabled = false, onSelect, onRemove, onConnect }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useClickAway(dropdownRef, () => setIsOpen(false));

  function handleSelect(account) {
    onSelect?.(account);
  }

  function handleRemove(account) {
    onRemove?.(account);
  }

  function handlePlaceholderClick() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <label role="none" className="flex items-center justify-between [&_*]:cursor-pointer">
        <SelectPlaceholder items={items} disabled={disabled} onClick={handlePlaceholderClick} onRemove={handleRemove} />
        <SelectDropdownIcon isOpen={isOpen} />
      </label>

      {isOpen && (
        <div className="absolute z-20 mt-2 w-full space-y-2 rounded-lg bg-white p-3 shadow-2xl">
          {items.map((account) => (
            <div
              role="none"
              key={account.id}
              className="flex cursor-pointer items-center justify-between rounded-lg px-3 py-2 hover:bg-[#F2F3F4]"
              onClick={() => handleSelect(account)}
            >
              <SelectItem account={account} />

              {!account.isConnected && (
                <Button variant={buttons.variant.secondary} size={buttons.size.xsmall} onClick={onConnect}>
                  Connect
                </Button>
              )}

              {account.isConnected && (
                <div className="flex items-center justify-center rounded bg-white">
                  <Checkbox checked={account.isSelected} onChange={() => handleSelect(account)} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function SelectPlaceholder({ items, disabled, onClick, onRemove }) {
  const selectedItems = items.filter((item) => item.isSelected);

  return (
    <div className="relative flex-1">
      <div
        role="none"
        className={cn('absolute inset-1 p-2 z-10 right-10 flex items-center', {
          'bg-white': !disabled && selectedItems.length,
        })}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (disabled) return;
          onClick();
        }}
      >
        {selectedItems.length === 1 && <SelectItem account={selectedItems[0]} />}
        {selectedItems.length >= 2 && <MultiSelection items={selectedItems} onRemove={onRemove} />}
      </div>
      <TextInput
        label={!disabled && 'Social accounts'}
        placeholder="Select a social account to publish your Social Clip"
        disabled={disabled}
        className={cn({ 'opacity-0': selectedItems.length })}
        onFocus={onClick}
      />
    </div>
  );
}

function SelectItem({ account }) {
  function getAccountIcon() {
    const NetworkIcon = account.icon;

    if (!account.isConnected) {
      return <NetworkIcon />;
    }

    return (
      <div className="relative">
        <img src={account.profileImageUrl} alt="Profile" className="size-10 rounded-full" />
        <NetworkIcon className="absolute -right-2 bottom-0 size-6" />
      </div>
    );
  }

  return (
    <div className="flex items-center gap-3">
      <div className="flex size-10 items-center justify-center rounded-full bg-gray-200">{getAccountIcon()}</div>
      <div className="flex flex-col">
        <Text variant={typography.heading_md}>{account.title}</Text>
        {account.description && (
          <Text variant={typography.body_sm} color={colors.text_tertiary}>
            {account.description}
          </Text>
        )}
      </div>
    </div>
  );
}

function MultiSelection({ items, onRemove }) {
  return (
    <div className="flex items-center gap-2">
      {items.map((item) => (
        <MultiSelectionItem
          key={item.id}
          icon={item.icon}
          title={item.title}
          onRemove={(event) => {
            event.stopPropagation();
            event.preventDefault();
            onRemove(item);
          }}
        />
      ))}
    </div>
  );
}

function MultiSelectionItem({ icon: NetworkIcon, title, onRemove }) {
  return (
    <div className="flex items-center gap-2 rounded bg-[#F2F3F4] px-2 py-1">
      <div className="flex size-6 items-center justify-center rounded-full">
        <NetworkIcon />
      </div>
      <Text variant={typography.body_md} className="!font-medium">
        {title}
      </Text>
      <Button className="p-0" onClick={onRemove}>
        <RemoveIcon />
      </Button>
    </div>
  );
}

function SelectDropdownIcon({ isOpen }) {
  return (
    <div className="absolute right-4 flex size-5 items-center justify-center">
      <svg
        className={cn('size-5 transition-transform', { 'rotate-180': isOpen })}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
      </svg>
    </div>
  );
}

function RemoveIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5L11 11M11 5L5 11" stroke="#56585E" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
}
