import { useState } from 'react';

import { DialogTypes } from '../constants';

export function useVoiceDialogs() {
  const [selectedVoiceId, setSelectedVoiceId] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isSetAsMainVoiceDialogOpen, setIsSetAsMainVoiceDialogOpen] = useState(false);
  const [isVoiceUploadDialogOpen, setIsVoiceUploadDialogOpen] = useState(false);

  const handleDialogOpen = (id, dialogType) => {
    setSelectedVoiceId(id);
    switch (dialogType) {
      case DialogTypes.EDIT:
        setIsEditDialogOpen(true);
        break;
      case DialogTypes.DELETE:
        setIsDeleteDialogOpen(true);
        break;
      case DialogTypes.SET_MAIN:
        setIsSetAsMainVoiceDialogOpen(true);
        break;
      case DialogTypes.UPLOAD:
        setIsVoiceUploadDialogOpen(true);
        break;
      default:
        break;
    }
  };

  const handleDialogClose = (dialogType) => {
    switch (dialogType) {
      case DialogTypes.EDIT:
        setIsEditDialogOpen(false);
        break;
      case DialogTypes.DELETE:
        setIsDeleteDialogOpen(false);
        break;
      case DialogTypes.SET_MAIN:
        setIsSetAsMainVoiceDialogOpen(false);
        break;
      case DialogTypes.UPLOAD:
        setIsVoiceUploadDialogOpen(false);
        break;
      default:
        break;
    }
    setSelectedVoiceId(null);
  };

  return {
    selectedVoiceId,
    isDeleteDialogOpen,
    isEditDialogOpen,
    isSetAsMainVoiceDialogOpen,
    isVoiceUploadDialogOpen,
    handleDialogOpen,
    handleDialogClose,
  };
}
