import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';

import MainLayout from 'components/layouts/MainLayout';
import { Permissions } from 'utils/auth';

import DailiesCalendar from './DailiesCalendar/DailiesCalendar';
import DailiesForm from './DailiesForm';
import DailiesRssFeedForm from './DailiesRssFeedForm/DailiesRssFeedForm';

export default function DailyBuilderTab(props) {
  const { permissions } = useAuth();
  const navigate = useNavigate();

  // This will be removed in the future, whenever other leaders are able to use this feature
  useEffect(() => {
    if (!permissions.has(Permissions.STUDIO_ADMIN)) navigate('/', { replace: true });
  }, []);

  return (
    <MainLayout data-viewname="PRAY Studio - Daily Builder Tab">
      <Routes>
        <Route index element={<DailiesCalendar {...props} />} />
        <Route path="/create" element={<DailiesForm {...props} />} />
        <Route path="/create/rss" element={<DailiesRssFeedForm {...props} />} />
        <Route path="/daily-series/:dailySeriesId/daily-items/:dailyItemId" element={<DailiesForm {...props} />} />
      </Routes>
    </MainLayout>
  );
}
