import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';
import storage from '@pray/shared/utils/storage';

export async function getArtist({ artistId }) {
  if (!artistId) throw Error('Artist ID is required ');

  const url = `/web/studio/artists/${artistId}`;
  const response = await api.executeGetRequest(url);

  return response.data;
}

export function useArtist(artistId) {
  const user = storage.getUser();

  const query = useQuery({
    enabled: !!artistId && !!user?.is_registered,
    queryKey: queryKeys.artist(artistId),
    queryFn: () => getArtist({ artistId }),
    retry: false,
  });

  return query;
}
