import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => Object.fromEntries(searchParams), [searchParams]);

  function setParams(queryParams = {}) {
    setSearchParams((params) => {
      Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] === null) {
          params.delete(key);
        } else {
          params.set(key, queryParams[key]);
        }
      });
      return params;
    });
  }

  return {
    params,
    setParams,
  };
}
