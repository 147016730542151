import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { navigate } from '@pray/shared/utils/navigation';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useGetAnnouncementsDetails from 'hooks/announcements/useGetAnnouncementsDetails';
import useSaveAnnouncement from 'hooks/announcements/useSaveAnnouncements';

import { announcementStatus, publishTypes } from '../constants';

const FUTURE_DATE_SCHEDULED_AT_ERROR = 'Scheduled Time should be at least 5 mins in the future';

export default function useCreateEditAnnouncementForm() {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const [isLoading, setIsLoading] = useState(false);

  const { id: announcementId } = useParams();
  const { data: announcementData } = useGetAnnouncementsDetails(artistId, announcementId);

  const { saveAnnouncement } = useSaveAnnouncement();

  const form = useForm({
    defaultValues: {
      id: '',
      title: '',
      segment_code: '',
      body: '',
      publishType: publishTypes.NOW,
      resource_type: '',
      resource_image_url: '',
      resource_url: '',
      resource_title: '',
      resource_button_text: '',
      scheduled_at_date: '',
      scheduled_at_time: '',
      content_id: '',
      content_series_id: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    const now = DateTime.local();

    let publishType = publishTypes.NOW;
    let scheduledAtDate = now.toISODate();
    let scheduledAtTime = now.toISOTime({ includeOffset: false }).slice(0, 5);

    if (!announcementData) {
      form.setValue('scheduled_at_date', scheduledAtDate);
      form.setValue('scheduled_at_time', scheduledAtTime);
      return;
    }

    if (announcementData.scheduled_at) {
      const scheduledAtDateTime = DateTime.fromISO(announcementData.scheduled_at);
      scheduledAtDate = scheduledAtDateTime.toISODate();
      scheduledAtTime = scheduledAtDateTime.toISOTime({ includeOffset: false }).slice(0, 5);
      publishType = publishTypes.SCHEDULED;
    }

    form.reset({
      id: announcementData.id,
      title: announcementData.announcement_title || '',
      segment_code: announcementData.segment_id || '',
      body: announcementData.body || '',
      publishType,
      resource_type: mapPostResourceType(announcementData.post_resource?.type) || '',
      resource_image_url: announcementData.post_resource?.image_url || '',
      resource_url: announcementData.post_resource?.url || '',
      resource_title: announcementData.post_resource?.title || '',
      resource_button_text: announcementData.post_resource?.button_text || '',
      scheduled_at_date: scheduledAtDate,
      scheduled_at_time: scheduledAtTime,
      content_id: announcementData.post_resource?.content_id || '',
      content_series_id: announcementData.post_resource?.content_series_id || '',
    });
  }, [announcementData]);

  const formData = form.watch();

  const isRequiredFieldsValid = !!(
    formData.title &&
    formData.segment_code &&
    formData.body &&
    (formData.resource_type !== 'external_url' ||
      (formData.resource_image_url && formData.resource_url && formData.resource_title))
  );

  async function saveAnnouncementChanges({ status = announcementStatus.DRAFT }) {
    const formData = form.getValues();

    const data = {
      announcementId: formData.id,
      artistId,
      status: status || announcementStatus.DRAFT,
      title: formData.title,
      segmentCode: formData.segment_code,
      body: formData.body,
      resourceType: formData.resource_type,
      resourceImageUrl: formData.resource_image_url,
      resourceUrl: formData.resource_url,
      resourceTitle: formData.resource_title,
      resourceButtonText: formData.resource_button_text,
      contentId: formData.content_id,
      contentSeriesId: formData.content_series_id,
    };

    if (formData.publishType === publishTypes.SCHEDULED && formData.scheduled_at_date && formData.scheduled_at_time) {
      const scheduledAt = DateTime.fromISO(`${formData.scheduled_at_date}T${formData.scheduled_at_time}:00`);

      const currentDate = DateTime.fromJSDate(new Date());
      const result = scheduledAt.diff(currentDate, 'seconds').toObject();
      const diffSeconds = result.seconds;

      // we keep a buffer of 4 mins for scheduled time. and always has to be in the future
      if (diffSeconds < 240) {
        throw new Error(FUTURE_DATE_SCHEDULED_AT_ERROR);
      }

      data.scheduledAt = scheduledAt.toUTC().toISO();
    }

    const announcement = await saveAnnouncement(data);

    if (!formData.id) {
      form.setValue('id', announcement.id);
    }
  }

  async function saveAnnouncementDraft() {
    setIsLoading(true);
    try {
      await saveAnnouncementChanges({ status: announcementStatus.DRAFT });
      toast.show({ success: 'Announcement draft saved' });
      navigate(-1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.message === FUTURE_DATE_SCHEDULED_AT_ERROR) {
        toast.show({ error: FUTURE_DATE_SCHEDULED_AT_ERROR });
      } else {
        toast.show({ error: 'Failed to save announcement draft' });
      }
    }
  }

  async function publishAnnouncement() {
    setIsLoading(true);
    try {
      await saveAnnouncementChanges({ status: announcementStatus.PUBLISHED });
      toast.show({ success: `Your announcement "${formData.title}" has been published` });
      navigate(-1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.message === FUTURE_DATE_SCHEDULED_AT_ERROR) {
        toast.show({ error: FUTURE_DATE_SCHEDULED_AT_ERROR });
      } else {
        toast.show({ error: 'Failed to publish announcement' });
      }
    }
  }

  function mapPostResourceType(resourceType) {
    if (resourceType === 'content' || resourceType === 'content-series') {
      return 'content';
    }

    if (resourceType === 'external_url') {
      return 'external_url';
    }

    return '';
  }

  return {
    state: {
      form,
      formData,
      artistId,
      isLoading,
      isRequiredFieldsValid,
    },
    actions: {
      publishAnnouncement,
      saveAnnouncementDraft,
    },
  };
}
