import React from 'react';

import SampleItem from './SampleItem';

export default function UploadedSamples({
  uploadedSamples,
  isPlayingId,
  handlePauseClick,
  handlePlayClick,
  handleDeleteSample,
}) {
  if (uploadedSamples.length === 0) return null;

  return (
    <div className="flex flex-col rounded-2xl border border-[#DEDFE3]">
      {uploadedSamples.map((sample) => (
        <SampleItem
          key={sample.id}
          sample={sample}
          isPlaying={isPlayingId === sample.id}
          onPause={handlePauseClick}
          onPlay={handlePlayClick}
          onDelete={() => handleDeleteSample(sample.id)}
        />
      ))}
    </div>
  );
}
