import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text/Text';

export const ChartLegend = ({ isLoading = false, label = '', color = '', className = '' }) => {
  return (
    <div className={`my-3 flex items-center gap-3 whitespace-nowrap ${className}`}>
      <div className="size-3 rounded-full" style={{ backgroundColor: color }} />
      <Loading isLight {...{ isLoading, margin: '4px 0', height: '11px', width: '120px' }}>
        <Text className="block max-w-[150px] truncate" variant="subhead_small">
          {label}
        </Text>
      </Loading>
    </div>
  );
};
