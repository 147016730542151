import React, { useMemo } from 'react';

import { chartTypes } from '@pray/shared/services/studioService/periodicTable';

import useSegmentDetails from 'hooks/periodicTable/useSegmentDetails';

import { DonutChart } from '../../../../../../components/Charts/DonutChart';
import { ChartWidget } from '../ChartWidget';

export default function EthnicityChart({ artistId, segmentCode, segmentName }) {
  const { isLoading, data } = useSegmentDetails({ artistId, segmentCode, chartType: chartTypes.ethnicity });

  const description = useMemo(() => {
    if (!data?.ethnicity?.length) return null;

    const largestSlice = data.ethnicity.reduce((prev, current) => {
      return +prev.value > +current.value ? prev : current;
    });

    return (
      <>
        <span className="text-[#0B0C0D]">{largestSlice.value}%</span> of members from {segmentName} are{' '}
        <span className="text-[#0B0C0D]">{largestSlice.label}</span>
      </>
    );
  }, [data?.ethnicity]);

  if (isLoading || !!data?.ethnicity?.length) {
    return (
      <ChartWidget
        isLoading={isLoading}
        title="Ethnicity"
        description={description}
        info="This chart shows the breakdown of Ethnicity of PRAYCOM members from the periodic table segment."
      >
        <DonutChart isLoading={isLoading} data={data?.ethnicity} />
      </ChartWidget>
    );
  }
}
