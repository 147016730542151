import React from 'react';

import { typography, buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import EpisodesContent from '../Content/EpisodesContent/EpisodesContent';
import type { CourseEpisode } from '../Content/EpisodesContent/types';

type EpisodesTabProps = {
  episodes: CourseEpisode[];
  selectedEpisodeId: string | null;
  isLoading: boolean;
  isCreatingPending: boolean;
  isDeletingPending: boolean;
  isRegenerating: boolean;
  error: Error | null;
  onContentChange: (episodeId: string, content: string) => void;
  onDeleteEpisode: (episodeId: string) => void;
  onRegenerateContent: (episodeId: string) => Promise<void>;
};

function EpisodesTab({
  episodes,
  selectedEpisodeId,
  isLoading,
  isCreatingPending,
  isDeletingPending,
  isRegenerating,
  error,
  onContentChange,
  onDeleteEpisode,
  onRegenerateContent,
}: EpisodesTabProps) {
  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <div className="flex flex-col items-center gap-4">
          <Text variant={typography.body_md}>Error loading episodes: {error.message}</Text>
          <Button variant={buttons.variant.secondary} onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  return (
    <EpisodesContent
      selectedEpisodeId={selectedEpisodeId || undefined}
      episodes={episodes}
      isLoading={isLoading}
      isCreatingPending={isCreatingPending}
      isDeletingPending={isDeletingPending}
      isRegenerating={isRegenerating}
      onContentChange={onContentChange}
      onDeleteEpisode={onDeleteEpisode}
      onRegenerateContent={onRegenerateContent}
    />
  );
}

export default EpisodesTab;
