export * from './createSocialClips';
export * from './deleteSocialClip';
export * from './getAiSuggestions';
export * from './getSocialClip';
export * from './getSocialClips';
export * from './updateSocialClip';

export function mapSocialClips(data) {
  return {
    status: data.status,
    socialClips: data.social_clips.map(mapSocialClip),
  };
}

export function mapSocialClip(data) {
  return {
    id: data.id,
    contentId: data.content_id,
    title: data.title,
    transcript: data.transcript,
    description: data.description,
    url: data.url,
    duration: data.duration,
    draft: data.draft,
    status: data.status,
    isPublished: data.is_published,
    isFailed: data.failed,
    failedReason: data.failed_reason,
    publishedAt: data.published_at,
    socialPlatforms: data.social_platforms,
    timeRangeStart: data.time_range_start,
    timeRangeEnd: data.time_range_end,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
}
