import { _mockAddEpisode } from './getEpisodes';
import type { CreateEpisodeResponse } from './types';

export type CreateEpisodeServiceParams = {
  artistId: string;
  courseId: string;
};

/**
 * Creates a new episode in a course
 * @param params - The parameters for creating a new episode
 * @param params.artistId - The ID of the artist to add the episode to
 * @param params.courseId - The ID of the course to add the episode to
 * @returns A promise that resolves to the created episode data
 */
export async function createEpisode({
  artistId,
  courseId,
}: CreateEpisodeServiceParams): Promise<CreateEpisodeResponse> {
  if (!artistId) throw new Error('Artist ID is required');
  if (!courseId) throw new Error('Course ID is required');

  await new Promise((resolve) => setTimeout(resolve, 800));

  const newEpisodeId = `ep_${crypto.randomUUID()}`;

  const newEpisodeData = {
    id: newEpisodeId,
    title: 'New Episode',
    content: '<h1>New Episode</h1><p>This is a new episode created with our mock API.</p>',
  };

  _mockAddEpisode({
    artistId,
    courseId,
    episode: newEpisodeData,
  });

  const response: CreateEpisodeResponse = {
    /* eslint-disable camelcase */
    responded_at: new Date().toISOString(),
    object: 'object',
    data: {
      id: newEpisodeId,
      course_id: courseId,
      title: 'New Episode',
      transcript: 'New Episode Transcript',
    },
    /* eslint-enable camelcase */
  };

  return response;

  // Uncomment when server implementation is ready
  // const url = `/studio/artists/${artistId}/courses/${courseId}/episodes`;
  // const response = await api.executePostRequest<CreateEpisodeResponse>(url, {
  //   title,
  //   transcript
  // });
  // return response.data;
}
