import { Edit } from 'lucide-react';
import { useEffect } from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import useForm from '@pray/shared/hooks/useForm';
import { Book, BookProcessStatus } from '@pray/shared/services/studioService/books/types';

import BackLinkButton from '../../../components/BackLinkButton/BackLinkButton';
import LanguageSelect from '../../../components/LanguageSelect/LanguageSelect';
import SideBarContentDetails, { useSideBarContentDetails } from '../../../components/SideBar/SideBarContentDetails';
import TabItem from '../../../components/TabItem/TabItem';
import { useActiveTabStore } from '../../ContentTab/ContentPage/ContentPage';
import { LIBRARY_SIDEBAR_TABS } from '../constants';

import { LOCALES } from '@/constants';
import useAppRoutes from '@/hooks/useAppRoutes';
import { TranscriptAI } from '@/images/icons';

type BookSidebarProps = {
  bookId: string;
  book: Book;
  isLoading: boolean;
  textExtractionStatus: BookProcessStatus;
};

export default function BookSidebar({ bookId, book, isLoading, textExtractionStatus }: BookSidebarProps) {
  const appRoutes = useAppRoutes();

  const activeTabStore = useActiveTabStore();
  const { setContentDetails } = useSideBarContentDetails();

  const languageForm = useForm({
    locale: LOCALES.EN,
  });

  useEffect(() => {
    setContentDetails({
      title: book?.title,
      imageUrl: book?.thumbnail,
    });
  }, [book]);

  const getCustomSpinner = () => {
    return <Spinner color="dark" size="small" className="border-2 border-gray-400" />;
  };

  if (isLoading) {
    return (
      <div className="flex h-full flex-col gap-4 pt-5 ">
        <div className="w-auto">
          <BackLinkButton label="Books" to={appRoutes.artistLibrary()} />
        </div>

        <div className="flex flex-col gap-2 px-5">
          <Loading height={80} isLight width={80} />
          <Loading height={40} isLight width={150} />

          <Loading height={40} isLight />
          <Loading height={40} isLight />
          <Loading height={40} isLight />
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col gap-4 pt-5">
      <div className="mx-2 w-auto">
        <BackLinkButton label="Books" to={appRoutes.artistLibrary()} />
      </div>

      <SideBarContentDetails isDailyType label="Book" />

      <LanguageSelect
        form={languageForm}
        items={[
          {
            language: 'English',
            locale: LOCALES.EN,
            is_primary: true,
          },
        ]}
      />

      <div className="grow [&_a]:px-3">
        <TabItem
          icon={Edit}
          text="Details"
          route={appRoutes.artistLibraryBookDetails(bookId)}
          active={activeTabStore.activeTab === LIBRARY_SIDEBAR_TABS.DETAILS}
          onClick={() => activeTabStore.setActiveTab(LIBRARY_SIDEBAR_TABS.DETAILS)}
        />

        <TabItem
          icon={textExtractionStatus === BookProcessStatus.PROCESSING ? getCustomSpinner : TranscriptAI}
          text="Transcript"
          route={appRoutes.artistLibraryBookTranscript(bookId)}
          active={activeTabStore.activeTab === LIBRARY_SIDEBAR_TABS.TRANSCRIPT}
          onClick={() => activeTabStore.setActiveTab(LIBRARY_SIDEBAR_TABS.TRANSCRIPT)}
        />
      </div>
    </div>
  );
}
