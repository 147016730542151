import { useEffect, useState } from 'react';

export default function useHasScrollTop(elementRef) {
  const [hasScrollTop, setHasScrollTop] = useState(false);

  useEffect(() => {
    const element = elementRef.current;

    function handleScroll() {
      setHasScrollTop(element.scrollTop > 0);
    }

    element.addEventListener('scroll', handleScroll);

    return () => {
      element.removeEventListener('scroll', handleScroll);
    };
  }, [elementRef]);

  return hasScrollTop;
}
