import React, { useEffect, useRef } from 'react';
import { matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom';

import Button from '@pray/shared/components/ui/Button/Button';
import { useAuth } from '@pray/shared/hooks/useAuth';
import storage from '@pray/shared/utils/storage';

import { useStudioContext } from 'context/StudioContext';
import useSendbirdChat from 'hooks/sendbirdChat/useSendbirdChat';
import useAppRoutes from 'hooks/useAppRoutes';
import {
  Analytics,
  ArrowLeft,
  Church,
  Communications,
  Content,
  Donations,
  Overview,
  People,
  SettingsGear,
} from 'images/icons';
import { Permissions } from 'utils/auth';

import useSideBar from './useSideBar';
import { RevenueStatuses } from '../../tabs/SettingsTab/RevenueSettings/constants';
import ArtistDropdown from '../ArtistDropdown/ArtistDropdown';
import TabGroup from '../TabGroup/TabGroup';
import TabItem from '../TabItem/TabItem';

import styles from './SideBar.module.scss';

const ROUTES_MATCHES_WITHOUT_SIDE_BAR = ['/leaders/:leaderId/episodes/:episodeId/transcript/edit'];

export default function SideBar() {
  const mediaQuery = window.matchMedia('(max-width: 768px)');

  const {
    isCollapsed,
    isFullClosed,
    setIsCollapsed,
    setIsFullClosed,
    resetMobileSideBar,
    renderDetails,
    resetRenderDetails,
  } = useSideBar();
  const location = useLocation();

  const handleMediaQueryChange = () => setIsCollapsed(mediaQuery.matches);

  useEffect(() => {
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  /**
   * Close the sidebar when navigating to certain routes {ROUTES_MATCHES_WITHOUT_SIDE_BAR}
   */
  useEffect(() => {
    const shouldCloseSidebar = ROUTES_MATCHES_WITHOUT_SIDE_BAR.some((routeToIgnoreSidebar) =>
      matchPath(routeToIgnoreSidebar, location.pathname)
    );

    setIsFullClosed(shouldCloseSidebar);
  }, [location.pathname]);

  useEffect(() => {
    // only mobile can be collapsed, so we close the menu after navigating
    if (!isCollapsed) {
      resetMobileSideBar();
    } else {
      resetRenderDetails();
    }
  }, [location]);

  const sideBarStyles = [styles.sidebar, isCollapsed && styles.collapsed].filter(Boolean).join(' ');

  if (isFullClosed) {
    return null;
  }

  return (
    <div className={sideBarStyles}>
      <div className="flex h-full flex-col">
        <SideBarItems renderDetails={renderDetails} location={location} />
      </div>
    </div>
  );
}

const ActiveArtistDropdown = ({ isHidden = false }) => {
  const { artists, selectedArtist, setSelectedArtist } = useStudioContext();
  const isRenderDropdown = artists.length > 1;

  const handleArtistChange = (artist) => {
    setSelectedArtist(artist);
  };

  return (
    <div className={isHidden ? 'hidden' : ''}>
      {isRenderDropdown && (
        <div className={styles.item}>
          <ArtistDropdown artists={artists} selectedArtists={[selectedArtist]} onChange={handleArtistChange} />
        </div>
      )}
      {isRenderDropdown && <hr className={styles.separator} />}
    </div>
  );
};

const SideBarItems = ({ renderDetails, location }) => {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const { permissions } = useAuth();
  const previousRouteRef = useRef(appRoutes.artistContent());

  const contentMatch = useMatch('/leaders/:artistId/episodes/:contentType');
  const playlistDetailsMatch = useMatch('/leaders/:artistId/series/:contentSeriesId');
  const playlistEditMatch = useMatch('/leaders/:artistId/series/:contentSeriesId/details');
  const analyticsMatch = useMatch('/analytics/:report');
  const ministryMatch = useMatch('/ministry-match/*');

  const { selectedArtist } = useStudioContext();
  const { unreadMessagesCount, setActiveUser: setActiveSendbirdUser } = useSendbirdChat();
  const hasGivingNotification =
    selectedArtist?.giving_account?.status && selectedArtist.giving_account.status !== RevenueStatuses.ENABLED;
  const selectedArtistId = selectedArtist?.id;
  const artistHasAnalyticsEnabled = selectedArtist?.is_studio_analytics_enabled === true;

  useEffect(() => {
    if (selectedArtistId) {
      handleSendbirdUser();
      handleInitialRoute();
    }
  }, [selectedArtistId]);

  function handleSendbirdUser() {
    const sendbirdUserId = `artist_${selectedArtistId}`;
    setActiveSendbirdUser(sendbirdUserId);
  }

  function handleInitialRoute() {
    const navigationHistory = storage.getNavigationHistory();
    if (navigationHistory.length > 1) {
      navigate(appRoutes.initial(), { replace: true });
    }
  }

  if (contentMatch || playlistDetailsMatch || playlistEditMatch) {
    let previousRoute = location.pathname;

    if (playlistEditMatch) {
      const { contentSeriesId } = playlistEditMatch.params;
      previousRoute = appRoutes.artistPlaylistDetails(contentSeriesId);
    }

    previousRouteRef.current = previousRoute;
  }

  if (renderDetails) {
    return renderDetails({ previousRoute: previousRouteRef.current });
  }

  return (
    <>
      <ActiveArtistDropdown />

      <div className="h-full overflow-y-auto p-2">
        <TabGroup icon={Overview} text="Overview">
          <TabItem text="Dashboard" route={appRoutes.artistDashboard()} />
          <TabItem text="Profile" route={appRoutes.artistProfile()} />
          <TabItem text="Banner" disabled={!selectedArtistId} route={appRoutes.artistBanner()} />
          <TabItem text="Calendar" route={appRoutes.artistCalendar()} />
        </TabGroup>

        <TabGroup icon={Content} text="Content" disabled={!selectedArtist}>
          <TabItem text="Series" disabled={!selectedArtistId} route={appRoutes.artistPlaylists()} />
          <TabItem text="Episodes" disabled={!selectedArtistId} route={appRoutes.artistContent()} />
          <TabItem
            text="Books"
            disabled={!selectedArtistId}
            visible={permissions.has(Permissions.STUDIO_ADMIN)}
            route={appRoutes.artistLibrary()}
          />
          <TabItem
            text="Daily Builder"
            disabled={!selectedArtistId}
            route={appRoutes.dailies()}
            visible={permissions.has(Permissions.VIEW_DAILY_BUILDER)}
            forAdmin
          />
          <TabItem text="Tags" route={appRoutes.tags()} visible={permissions.has(Permissions.VIEW_TAGS)} forAdmin />
        </TabGroup>

        <TabGroup icon={Donations} text="Revenue">
          <TabItem text="Summary" route={appRoutes.artistDonationsSummary()} />
          <TabItem
            text="Funds"
            route={appRoutes.artistDonationsFunds()}
            visible={permissions.has(Permissions.STUDIO_ADMIN)}
            forAdmin
          />
          <TabItem text="Bank Transfer" route={appRoutes.artistDonationsBankPayouts()} />
          <TabItem text="Statements" route={appRoutes.artistDonationsStatements()} />
        </TabGroup>

        <TabGroup icon={People} text="People" disabled={!selectedArtist}>
          <TabItem text="Followers" disabled={!selectedArtistId} route={appRoutes.artistFollowers()} />
          <TabItem
            text="Segments"
            disabled={!selectedArtistId}
            route={appRoutes.artistSegments()}
            visible={permissions.has(Permissions.STUDIO_ADMIN) && artistHasAnalyticsEnabled}
            forAdmin
          />
          <TabItem text="Team" disabled={!selectedArtistId} route={appRoutes.artistTeam()} />
          <TabItem
            text="Lists"
            disabled={!selectedArtistId}
            visible={permissions.has(Permissions.STUDIO_ADMIN)}
            route={appRoutes.artistLists()}
            forAdmin
          />
        </TabGroup>

        <TabGroup
          icon={Analytics}
          text="Analytics"
          disabled={!selectedArtist}
          expanded={!!analyticsMatch}
          visible={artistHasAnalyticsEnabled}
        >
          <TabItem text="Audience" disabled={!selectedArtistId} route={appRoutes.analyticsAudience()} />
          <TabItem text="Listen" disabled={!selectedArtistId} route={appRoutes.analyticsListen()} />
          <TabItem text="Watch" disabled={!selectedArtistId} route={appRoutes.analyticsWatch()} />
          <TabItem text="Periodic Table" disabled={!selectedArtistId} route={appRoutes.analyticsPeriodicTable()} />
        </TabGroup>

        <TabGroup icon={Communications} text="Communications" hasNotifications={!!unreadMessagesCount}>
          <TabItem text="Announcements" disabled={!selectedArtistId} route={appRoutes.artistAnnouncements()} />
          <TabItem
            text="Chats"
            disabled={!selectedArtistId}
            route={appRoutes.artistChats()}
            notifications={unreadMessagesCount}
          />
          <TabItem text="Emails" disabled={!selectedArtistId} route={appRoutes.artistEmails()} />
          <TabItem text="Testimonials" disabled={!selectedArtistId} route={appRoutes.analyticsTestimonials()} />
        </TabGroup>

        <TabGroup
          icon={Church}
          text="Ministry Match"
          disabled={!selectedArtist}
          expanded={!!ministryMatch}
          visible={permissions.has(Permissions.STUDIO_ADMIN)}
          forAdmin
        >
          <TabItem text="Campaigns" disabled={!selectedArtistId} route={appRoutes.ministryMatchCampaigns()} forAdmin />
          <TabItem
            text="Organizations"
            disabled={!selectedArtistId}
            route={appRoutes.ministryMatchOrganizations()}
            forAdmin
          />
        </TabGroup>

        <TabGroup
          icon={SettingsGear}
          text="Settings"
          disabled={!selectedArtist}
          hasNotifications={!!hasGivingNotification}
        >
          <TabItem text="Voices" disabled={!selectedArtistId} route={appRoutes.settingsVoices()} />

          <TabItem
            text="Translations"
            disabled={!selectedArtistId}
            route={appRoutes.settingsTranslations()}
            visible={permissions.has(Permissions.STUDIO_ADMIN)}
            forAdmin
          />
          <TabItem
            text="Revenue"
            disabled={!selectedArtistId}
            route={appRoutes.settingsRevenue()}
            notifications={hasGivingNotification ? 1 : 0}
          />
          <TabItem text="Social Accounts" disabled={!selectedArtistId} route={appRoutes.settingsSocialAccounts()} />
        </TabGroup>
      </div>
    </>
  );
};

export const BackButton = ({ label = 'Back', to = null }) => {
  const navigate = useNavigate();
  const location = useLocation();

  function handleNavigateBack() {
    if (to) {
      navigate(to);
      return;
    }

    const route = location.state?.from || -1;
    navigate(route);
  }

  return (
    <Button
      className="flex flex-row items-center gap-2 px-2 py-[10px] text-base font-normal normal-case"
      onClick={handleNavigateBack}
    >
      <ArrowLeft />
      <span data-itemtext>{location.state?.from ? label : 'Back'}</span>
    </Button>
  );
};
