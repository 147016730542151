import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';

import { useStudioContext } from 'context/StudioContext';
import { Avatar, BulletList, Edit } from 'images/icons';

import EpisodesPanel from './components/Content/EpisodesContent/EpisodesPanel';
import { EpisodesTab, DetailsTab, AvatarTab } from './components/Tabs';
import useCourseDetails from './hooks/useCourseDetails';
import { useCourseEpisodes } from './hooks/useCourseEpisodes';
import type { CourseTabType } from './types';
import { CourseEditTab } from './types';
import EditorContainer, { SidebarTab } from '../../../../components/EditorContainer';

export default function CourseEditPage() {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const [activeTab, setActiveTab] = useState<CourseTabType>(CourseEditTab.EPISODES);

  const {
    episodes,
    selectedEpisodeId,
    isRegenerating,
    isEpisodesLoading,
    isCreatingPending,
    isDeletingPending,
    error,
    handleSelectEpisode,
    handleContentChange,
    handleDeleteEpisode,
    handleRegenerateContent,
    handleCreateEpisode,
  } = useCourseEpisodes({
    courseId,
    artistId,
  });

  const { form } = useCourseDetails({
    courseId,
  });

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId as CourseTabType);
  };

  const handleBack = () => {
    const route = state?.from || -1;
    navigate(route, { replace: true });
  };

  const episodesPanel = (
    <EpisodesPanel
      episodes={episodes}
      selectedEpisodeId={selectedEpisodeId}
      isLoading={isEpisodesLoading || isCreatingPending}
      onCreateEpisode={handleCreateEpisode}
      onSelectEpisode={handleSelectEpisode}
    />
  );

  const sidebarTabs: SidebarTab[] = [
    {
      id: CourseEditTab.EPISODES,
      icon: <BulletList />,
      content: episodesPanel,
      title: 'Episodes',
    },
    {
      id: CourseEditTab.DETAILS,
      icon: <Edit />,
      content: null,
      title: 'Details',
    },
    {
      id: CourseEditTab.AVATAR,
      icon: <Avatar />,
      content: null,
      title: 'Avatar',
    },
  ];

  const headerActions = (
    <div className="flex gap-2">
      <Button variant={buttons.variant.secondary}>Save as draft</Button>
      <Button variant={buttons.variant.primary}>Generate Series</Button>
    </div>
  );

  const getTitle = () => {
    switch (activeTab) {
      case CourseEditTab.EPISODES:
        return 'Edit episode transcript';
      case CourseEditTab.DETAILS:
        return 'Edit series details';
      case CourseEditTab.AVATAR:
        return 'Select Avatar';
      default:
        return 'Edit episode transcript';
    }
  };

  const tabComponents = {
    [CourseEditTab.EPISODES]: (
      <EpisodesTab
        episodes={episodes}
        selectedEpisodeId={selectedEpisodeId}
        isLoading={isEpisodesLoading}
        isCreatingPending={isCreatingPending}
        isDeletingPending={isDeletingPending}
        isRegenerating={isRegenerating}
        error={error}
        onContentChange={handleContentChange}
        onDeleteEpisode={handleDeleteEpisode}
        onRegenerateContent={handleRegenerateContent}
      />
    ),
    [CourseEditTab.DETAILS]: <DetailsTab form={form} />,
    [CourseEditTab.AVATAR]: <AvatarTab />,
  };

  return (
    <EditorContainer
      title={getTitle()}
      headerActions={headerActions}
      sidebarTabs={sidebarTabs}
      defaultSidebarTab={CourseEditTab.EPISODES}
      showSidebarContent={activeTab !== CourseEditTab.DETAILS}
      onBack={handleBack}
      onSidebarTabChange={handleTabChange}
    >
      <div className="size-full">{tabComponents[activeTab]}</div>
    </EditorContainer>
  );
}
