import React, { useRef, useState } from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';
import { Profile } from '@pray/shared/components/ui/Icons/Profile';
import Text from '@pray/shared/components/ui/Text/Text';
import useClickAway from '@pray/shared/hooks/useClickAway';
import { logout } from '@pray/shared/utils/auth';
import storage from '@pray/shared/utils/storage';

import { useStudioContext } from 'context/StudioContext';

import styles from './UserInfoDropdown.module.scss';

export default function UserInfoDropdown() {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { resetContextData } = useStudioContext();

  const handleClose = () => setIsOpen(false);

  useClickAway(containerRef, handleClose);

  const handleLogout = async () => {
    await logout();
    resetContextData();
  };

  const getUserEmail = () => {
    const user = storage.getUser();
    return user?.email;
  };

  return (
    <div ref={containerRef}>
      <Button className={styles.button} data-tapeventname="User Dropdown" onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.userInfo}>
          <Text>{getUserEmail()}</Text>
          <ChevronDown />
        </div>
        <div className={styles.profileIcon}>
          <Profile fill="white" />
        </div>
      </Button>
      {isOpen && (
        <Backdrop onClick={() => setIsOpen(false)}>
          <div className={styles.menu} onMouseLeave={() => setIsOpen(false)}>
            <Button onClick={handleLogout}>Logout</Button>
          </div>
        </Backdrop>
      )}
    </div>
  );
}

const Backdrop = ({ children, onClick }) => (
  <>
    <div className="fixed inset-0 z-[100]" onClick={onClick} role="none" />
    {children}
  </>
);
