import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import { formatCurrency } from '@pray/shared/utils';
import { TableSkeleton } from './TableSkeleton';

export function TransactionList({ data: transactions, hasNextPage, fetchNextPage, onDownload, isFetchingNextPage }) {
  return (
    <>
      <div className="flex justify-end">
        <Button disabled={!transactions?.length} variant={buttons.variant.secondary} onClick={onDownload}>
          Download CSV
        </Button>
      </div>

      <div className="relative overflow-y-visible">
        <Table key={transactions?.map((transaction) => transaction.id)}>
          <Thead>
            <Tr className="sticky top-0 bg-white">
              <Th field="date">Date</Th>
              <Th field="firstName">First Name</Th>
              <Th field="lastName">Last Name</Th>
              <Th field="amount">Amount</Th>
              <Th field="fundName">Fund</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions?.map((transaction) => (
              <Tr key={transaction.id}>
                <Td>{new Date(transaction.date).toLocaleDateString()}</Td>
                <Td>{transaction.firstName}</Td>
                <Td>{transaction.lastName}</Td>
                <Td>{formatCurrency(transaction.amount || 0)}</Td>
                <Td>{transaction.fundName}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} threshold={100} />}
        {isFetchingNextPage && <TableSkeleton type="transactions" rows={3} />}
      </div>
    </>
  );
}
