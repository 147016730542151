import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type SaveChapterDraftParams = {
  artistId: string;
  bookId: string;
  chapterId: string;
  text: string;
};

export function useSaveChapterDraft({
  shouldInvalidateOnSuccess = false,
}: { shouldInvalidateOnSuccess?: boolean } = {}) {
  const queryClient = useQueryClient();

  async function saveChapterDraft({ artistId, bookId, chapterId, text }: SaveChapterDraftParams): Promise<void> {
    await studioService.chapters.saveChapterDraft({
      artistId,
      bookId,
      chapterId,
      text,
    });
  }

  function onSuccess(_, variables) {
    if (shouldInvalidateOnSuccess) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistBookChapter(variables.artistId, variables.bookId, variables.chapterId),
      });
    }
  }

  const { mutateAsync, isPending } = useMutation({
    mutationFn: saveChapterDraft,
    onSuccess,
  });

  return { saveChapterDraft: mutateAsync, isPending };
}
