import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';
import { formatDateUsingShorterMonth } from '@pray/shared/utils/datetime';
import { cn } from '@pray/shared/utils/styles';

export default function ContentDateStatus({ content, className = '' }) {
  /**
   *
   * @param {Object} content The content item
   * Display 'draft' when is_published is false
   * Display 'scheduled' when is_published is true and published_at is in the future
   * Display 'published' when is_published is true and published_at is in the past
   *
   * @returns {String} Returns the status of the content
   */
  const getStatusDisplayed = (content) => {
    if (!content.is_published) return 'Draft';
    return new Date().getTime() < new Date(content.published_at).getTime() ? 'Scheduled' : 'Published';
  };

  /**
   *
   * @param {Object} content The content item
   * Uses updated_at when is_published is false
   * Uses published_at when is_published is true
   *
   * @returns {String} Returns the content status date
   */
  const getDateDisplayed = (content) => {
    if (!content.is_published) return formatDateUsingShorterMonth(content.created_at, true);
    return new Date().getTime() < new Date(content.published_at).getTime()
      ? formatDateUsingShorterMonth(content.published_at, true)
      : formatDateUsingShorterMonth(content.first_published_at || content.published_at, true);
  };

  const statusColor = getStatusDisplayed(content) === 'Scheduled' ? 'text-[#541AA8]' : 'text_tertiary';

  return (
    <div className={cn('mr-6 flex items-center space-x-3 p-1.5', className)}>
      <div className="flex min-w-0 flex-col justify-center">
        <Text className="whitespace-nowrap text-base font-medium tracking-normal">{getDateDisplayed(content)}</Text>
        <Text className={`text-sm font-medium tracking-normal ${statusColor}`}>{getStatusDisplayed(content)}</Text>
      </div>
    </div>
  );
}
