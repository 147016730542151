import { useCallback, useState } from 'react';

import { useGenerateContentStudyGuide } from 'hooks/contentStudyGuide/useContentStudyGuide';

import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useGenerateStudyGuide({ artist, content, locale, setStudyGuide, onWorkflowAIStarted }) {
  const toast = useToastMessage();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const { generateContentStudyGuide } = useGenerateContentStudyGuide();

  const generateStudyGuide = useCallback(
    async (isRegenerate = false) => {
      try {
        if (isRegenerate) {
          setIsRegenerating(true);
        } else {
          setIsProcessing(true);
        }

        const { studyGuide: newStudyGuide } = await generateContentStudyGuide({
          artistId: artist.id,
          contentId: content.id,
          locale,
        });

        setStudyGuide(newStudyGuide);

        if (!isRegenerate) {
          onWorkflowAIStarted();
        }

        toast.show({
          success: `Study guide ${isRegenerate ? 're' : ''}generated successfully`,
        });

        return newStudyGuide;
      } catch (error) {
        toast.show({
          error: `Failed to ${isRegenerate ? 're' : ''}generate study guide`,
        });
        return null;
      } finally {
        if (isRegenerate) {
          setIsRegenerating(false);
        } else {
          setIsProcessing(false);
        }
      }
    },
    [artist.id, content.id, locale, generateContentStudyGuide, setStudyGuide, onWorkflowAIStarted, toast]
  );

  return {
    generateStudyGuide,
    isProcessing,
    isRegenerating,
  };
}
