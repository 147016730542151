import { useEffect, useMemo, useState } from 'react';

import { useAudioPlayer } from '@pray/shared/components/ContentPlayer/FullscreenContentPlayer/FullscreenContentPlayer';
import { useUploaderModal } from '@pray/shared/components/ui/UploaderModal/UploaderModal';
import { useVideoModal } from '@pray/shared/components/VideoModal/VideoModal';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { AUDIO_PLAYER } from 'constants.js';
import useContentList from 'hooks/content/useContentList';

export default function useContentListPage(artistId: string, mediaType: string) {
  const uploader = useUploaderModal();
  const toast = useToastMessage();

  const { playContent } = useAudioPlayer();
  const { playVideo } = useVideoModal();

  const [isSaving, setIsSaving] = useState(false);
  const [isShowDropzone, setIsShowDropzone] = useState(true);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectionDetails, setSelectionDetails] = useState({
    isSomeSelected: false,
    selected: [],
  });
  const [isAddToPlaylistModalOpen, setIsAddToPlaylistModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isRemoveFromPlaylistModalOpen, setIsRemoveFromPlaylistModalOpen] = useState(false);

  const audioContentList = useContentList(artistId, 'audio');
  const videoContentList = useContentList(artistId, 'video');

  const contentList = useMemo(() => {
    return mediaType === 'audio' ? audioContentList : videoContentList;
  }, [mediaType, audioContentList, videoContentList]);

  const episodes = contentList.data?.pages.flatMap((page) => page.data) ?? [];

  useEffect(() => {
    return () => uploader.collapse();
  }, []);

  useEffect(() => {
    setIsShowDropzone(!contentList.isLoading && episodes.length === 0);
  }, [contentList.isLoading, episodes.length]);

  const openAddToPlaylistModal = (content) => {
    setSelectedContent(content);
    setIsAddToPlaylistModalOpen(true);
  };

  const openDeleteConfirmationModal = (content) => {
    setSelectedContent(content);
    setIsDeleteModalOpen(true);
  };

  const handleAddContentToPlaylist = async (playlist) => {
    setIsSaving(true);

    let contentIds = [selectedContent?.id];
    if (selectionDetails.selected.length) {
      contentIds = selectionDetails.selected.map((index) => episodes[index].id);
    }

    await studioService.contentSeries.addContentToPlaylist({
      artistId,
      contentSeriesId: playlist.id,
      contentIds,
    });

    toast.show({ success: 'Episode added to Series' });

    await contentList.refetch();

    setIsAddToPlaylistModalOpen(false);
    setIsSaving(false);
  };

  const handleDeleteContent = async () => {
    let contentIds = [selectedContent?.id];
    if (selectionDetails.selected.length) {
      contentIds = selectionDetails.selected.map((index) => episodes[index].id);
    }

    const response = await studioService.content.deleteArtistContent({
      artistId,
      contentIds,
    });

    if (response?.not_deleted) {
      setIsDeleteModalOpen(false);
      setIsMessageModalOpen(true);
    } else {
      await contentList.refetch();
      setIsDeleteModalOpen(false);
    }
  };

  const handleAudioUpload = async (file) => {
    const data = {
      artistId,
      primaryAudioUrl: file.url,
      primaryAudioFileSize: file.size,
      primaryAudioFileDuration: file.metadata.duration,
    };

    // associate uploaded file with selected artist
    await studioService.content.createArtistContent(data);
  };

  const handleVideoUpload = async (file) => {
    const data = {
      artistId,
      primaryVideoUrl: file.url,
      primaryVideoFileSize: file.size,
      primaryVideoFileDuration: file.metadata.duration,
    };

    // associate uploaded file with selected artist
    const content = await studioService.content.createArtistContent(data);

    // upload video thumbnail
    const { url: thumbnailUrl } = await s3Service.signAndUpload({
      type: 'contentThumbnail',
      file: file.metadata.thumbnailFile,
      signParams: {
        content_id: content.id,
      },
    });

    // update content with thumbnail url
    await studioService.content.updateArtistContent({
      artistId,
      contentId: content.id,
      thumbnailUrl,
    });
  };

  const handleRemoveFromPlaylistContent = async () => {
    let contentIds = [selectedContent?.id];
    if (selectionDetails.selected.length) {
      contentIds = selectionDetails.selected.map((index) => episodes[index].id);
    }
    await Promise.all(
      contentIds.map(async (contentId) => {
        const contentFound = episodes.find((item) => item.id === contentId);
        return studioService.contentSeries.removeContentFromPlaylist({
          artistId,
          contentSeriesId: contentFound?.series_id,
          contentIds: [contentId],
        });
      })
    );

    await contentList.refetch();

    toast.show({ success: 'Episode removed from Series' });
    setIsRemoveFromPlaylistModalOpen(false);
  };

  const handleAcceptedFiles = async (acceptedFiles) => {
    await uploader.uploadFiles({
      type: 'content',
      files: acceptedFiles,
      onFileUploaded: async (file) => {
        // Due to recent changes to Episodes tabs, the handleAcceptedFiles
        // is being initialized with same mediaType on both audio and video tabs,
        // so we need to check the file type to determine which upload function to use.
        // It depends on what tab is loaded first that will be used.
        if (file.type.startsWith('audio')) {
          await handleAudioUpload(file);
          await audioContentList.refetch();
        } else if (file.type.startsWith('video')) {
          await handleVideoUpload(file);
          await videoContentList.refetch();
        } else {
          throw Error(`Invalid media type: ${mediaType}`);
        }
      },
    });
  };

  const handleRejectedFiles = async (rejectedFiles) => {
    const error = rejectedFiles
      .map(({ file, errors }) => {
        return `• ${file.name}: ${errors.map(({ message }) => message).join('; ')}`;
      })
      .join(', ');

    toast.show({ error, timeout: 4000 * rejectedFiles.length });
  };

  const handleItemClick = (content) => {
    if (mediaType === 'audio') {
      playContent({
        contentId: content.id,
        controlOptions: AUDIO_PLAYER.controlOptions,
      });
      return;
    }

    playVideo({
      ...content,
      video_url: content.primary_video_url,
    });
  };

  const openRemoveFromPlaylistConfirmationModal = (content) => {
    setSelectedContent(content);
    setIsRemoveFromPlaylistModalOpen(true);
  };

  const acceptedFileTypes = mediaType === 'audio' ? { 'audio/mpeg': ['.mp3'] } : { 'video/mp4': ['.mp4'] };

  return {
    acceptedFileTypes,
    contentList,
    episodes,
    isAddToPlaylistModalOpen,
    isDeleteModalOpen,
    isMessageModalOpen,
    isRemoveFromPlaylistModalOpen,
    isSaving,
    isShowDropzone,
    selectionDetails,
    handleAcceptedFiles,
    handleAddContentToPlaylist,
    handleDeleteContent,
    handleItemClick,
    handleRejectedFiles,
    handleRemoveFromPlaylistContent,
    openAddToPlaylistModal,
    openDeleteConfirmationModal,
    openRemoveFromPlaylistConfirmationModal,
    setIsAddToPlaylistModalOpen,
    setIsDeleteModalOpen,
    setIsMessageModalOpen,
    setIsRemoveFromPlaylistModalOpen,
    setIsShowDropzone,
    setSelectionDetails,
  };
}
