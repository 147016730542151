import React, { useEffect, useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import { capitalize } from '@pray/shared/utils';

import styles from './AddEditTagModal.module.scss';

const TAG_DESCRIPTION_LIMIT = 200;

export default function AddEditTagModal({ data, isOpen, onClose, onSuccess, onError }) {
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    name: data?.name ?? '',
    description: data?.description ?? '',
  };

  const form = useForm(initialValues);

  useEffect(() => {
    return () => {
      form.setValues(initialValues);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const validateFormData = () => {
    const errors = {};

    // name
    if (form.values.name.trim().length < 2) {
      errors.name = 'Invalid name';
    }

    // description
    if (!form.values.description.trim() || form.values.description.trim().length > TAG_DESCRIPTION_LIMIT) {
      errors.description = 'Invalid description';
    }

    form.setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFormData()) return;

    setIsSaving(true);

    try {
      if (!data?.id) {
        await studioService.tag.createTag({
          name: form.values.name,
          description: form.values.description,
        });
      } else {
        await studioService.tag.updateTag({
          tagId: data.id,
          name: form.values.name,
          description: form.values.description,
        });
      }

      if (onSuccess) await onSuccess();
    } catch (error) {
      const message = error?.response?.data?.data?.[0]?.info ?? 'Failed to save tagging.';
      onError(capitalize(message));
    }
    setIsSaving(false);
  };

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{data?.id ? 'Edit' : 'New'} Tag</Text>

      <form onSubmit={handleSubmit}>
        <div className={styles.formField}>
          <TextInput
            autoFocus
            label="Name"
            name="name"
            value={form.values.name}
            errorMessage={form.errors.name}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextArea
            rows={5}
            label="Description"
            name="description"
            value={form.values.description}
            errorMessage={form.errors.description}
            description={`The text must be ${TAG_DESCRIPTION_LIMIT} characters maximum.`}
            helperText={`${form.values.description.length}/${TAG_DESCRIPTION_LIMIT}`}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.buttons}>
          <Button disabled={isSaving} variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSaving} variant={buttons.variant.primary} onClick={handleSubmit}>
            {isSaving ? <Spinner /> : 'Save'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
