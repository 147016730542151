import api from '@pray/shared/utils/api';

export async function addContentToPlaylist({ artistId, contentSeriesId, contentIds = [] }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');
  if (!contentIds?.length) throw Error('Content IDs are required');

  const data = {
    content_ids: contentIds,
  };

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}/content`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
