import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import useHasScrollTop from '@pray/shared/hooks/useHasScrollTop';

import { ChevronDown } from 'images/icons';
import { cn } from 'styles/utils';

import EventCell from '../components/EventCell';
import TimeCell from '../components/TimeCell';

const ALL_DAY_EVENTS_LIMIT = 3;

export default function DayView({ data = [], currentDate, renderEvent, onTitleUpdate }) {
  const [time, setTime] = useState(DateTime.now());

  /** Generate times */
  const times = Array.from({ length: 24 }).map((_, i) => {
    return currentDate.set({ hour: i, minute: 0, second: 0 });
  });

  /** Update title when weekDays change */
  useEffect(() => {
    const title = currentDate.toFormat('MMMM dd, yyyy');
    onTitleUpdate(title);
  }, [currentDate]);

  /** Re-render every second to update time indicator */
  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateTime.now();
      if (now !== time && now.second === 0) setTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const todayEvents = data.filter((event) => {
    const isOngoingEvent = !event.endDate;
    const startDate = DateTime.fromISO(event.startDate).startOf('day');
    const endDate = DateTime.fromISO(event.endDate).endOf('day');

    return currentDate >= startDate && (currentDate <= endDate || isOngoingEvent);
  });

  return <DayViewTable data={todayEvents} date={currentDate} times={times} renderEvent={renderEvent} />;
}

function DayViewTable({ data = [], date, times, renderEvent }) {
  const containerRef = useRef(null);
  const [isAllDayEventsExpanded, setIsAllDayEventsExpanded] = useState(false);

  const allDayEvents = data.filter((event) => !event.endDate);

  const sameDayEvents = data.filter((event) => {
    return DateTime.fromISO(event.startDate).hasSame(DateTime.fromISO(event.endDate), 'day');
  });

  const eventsByDateAndHour = sameDayEvents.reduce((acc, event) => {
    const startDate = DateTime.fromISO(event.startDate);
    const date = startDate.toFormat('yyyy-MM-dd');
    const hour = startDate.toFormat('HH');

    if (!acc[date]) acc[date] = {};
    if (!acc[date][hour]) acc[date][hour] = [];

    acc[date][hour].push(event);

    return acc;
  }, {});

  const isCalendarScrolled = useHasScrollTop(containerRef);

  return (
    <div ref={containerRef} className="relative h-[calc(100vh-170px)] overflow-y-auto overflow-x-hidden pb-16">
      <table className="w-full table-fixed" cellPadding={0} cellSpacing={0}>
        <thead className={cn('sticky top-0 z-[100] bg-white', isCalendarScrolled && 'shadow-md')}>
          <tr>
            <AllDayCell
              eventCount={allDayEvents.length}
              isExpanded={isAllDayEventsExpanded}
              onMoreButtonClick={() => setIsAllDayEventsExpanded(!isAllDayEventsExpanded)}
            />
            <td key={date} className="h-12">
              <div className="size-full border-y border-r">
                {allDayEvents
                  .slice(0, isAllDayEventsExpanded ? allDayEvents.length : ALL_DAY_EVENTS_LIMIT)
                  .map((event) => {
                    return renderEvent({
                      event,
                      date: DateTime.fromISO(event.startDate),
                      isCompact: true,
                      isDisplayTime: false,
                      isEventStart: true,
                      isEventEnd: true,
                    });
                  })}

                <ShowMoreButton
                  eventCount={allDayEvents.length}
                  isExpanded={isAllDayEventsExpanded}
                  onClick={() => setIsAllDayEventsExpanded(!isAllDayEventsExpanded)}
                />
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {times.map((time, index) => (
            <tr key={time}>
              <TimeCell time={time} isVisible={index !== 0} />
              <EventCell
                date={date}
                time={time}
                getEvents={(dt, hr) => eventsByDateAndHour[dt]?.[hr]}
                renderEvent={renderEvent}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function AllDayCell({ eventCount, isExpanded, onMoreButtonClick }) {
  return (
    <td className="h-12 w-16">
      <div className="flex size-full flex-col items-end justify-between border-y border-r">
        <Text className="pr-3 pt-1" variant={typography.footnote} color={colors.text_tertiary}>
          All-day
        </Text>
        {eventCount > ALL_DAY_EVENTS_LIMIT && (
          <Button className={cn('mr-1 p-2', isExpanded && 'rotate-180')} onClick={onMoreButtonClick}>
            <ChevronDown />
          </Button>
        )}
      </div>
    </td>
  );
}

function ShowMoreButton({ eventCount, isExpanded, onClick }) {
  if (isExpanded || eventCount <= ALL_DAY_EVENTS_LIMIT) return null;

  return (
    <div className="p-1 pt-0.5">
      <Button className="flex h-7 w-full items-center pl-2 text-left hover:bg-[#F2F3F4]" onClick={onClick}>
        <Text className="font-bold" variant={typography.caption_medium} color={colors.text_secondary}>
          {eventCount - ALL_DAY_EVENTS_LIMIT} more
        </Text>
      </Button>
    </div>
  );
}
