import React from 'react';
import slugify from 'slugify';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { cn } from 'styles/utils';

import ContentItem from '../ContentItem/ContentItem';

import styles from './SearchSeriesModal.module.scss';

export default function SearchSeriesModal({
  isOpen,
  isLoading,
  isSaving,
  title,
  disabled = false,
  placeholder = 'Search...',
  items = [],
  searchTerm,
  emptyState,
  onToggleSelectItem,
  onSearchTermChange,
  onAdd,
  onClose,
}) {
  if (!isOpen) return null;

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{title}</Text>

      <div className="flex flex-col gap-4">
        <div className={styles.formField}>
          <TextInput
            autoFocus
            placeholder={placeholder}
            leftIcon={<Search />}
            loading={isLoading}
            value={searchTerm}
            onChange={onSearchTermChange}
          />
        </div>

        <div className="border-gray h-96 overflow-y-auto border p-1">
          {items.length === 0 && emptyState}

          {items.map((item) => (
            <div
              key={item.id}
              className={cn(
                'flex items-center space-x-4',
                item.checked && 'bg-selected-item rounded-md',
                !item.checked && 'hover:bg-[#F2F3F4]'
              )}
              onClick={() => onToggleSelectItem(item)}
            >
              <SeriesOption
                name="series"
                item={item}
                checked={item.checked}
                visible={item.visible}
                onChange={() => onToggleSelectItem(item)}
              />
            </div>
          ))}
        </div>

        <div className={styles.buttons}>
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isSaving || disabled} variant={buttons.variant.primary} onClick={onAdd}>
            {!isSaving ? 'Add' : <Spinner />}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function SeriesOption({ item, name, checked, onChange, visible = true }) {
  const id = `${name}-${slugify(item.id)}`;

  return (
    <div className={cn('flex flex-row align-middle', visible ? 'visible' : 'invisible')}>
      <div className={cn('flex flex-row items-center gap-4 rounded-md p-4', checked && 'border-black')}>
        <input
          id={id}
          type="radio"
          name={name}
          value={item.id}
          checked={checked}
          onChange={onChange}
          className={styles.option}
        />
        <ContentItem image={item.image_url} title={item.title} />
      </div>
    </div>
  );
}
