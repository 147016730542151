import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { colors } from '@pray/shared/components/foundations';
import useDebounce from '@pray/shared/hooks/useDebounce';
import studioService from '@pray/shared/services/studioService';
import { Chapter, mapChapter } from '@pray/shared/services/studioService/books/chapters';
import { BookProcessStatus } from '@pray/shared/services/studioService/books/types';
import logger from '@pray/shared/utils/logger';

import { TIME_BETWEEN_AUTO_SAVES_MS } from '../../../constants';

import { useToastMessage } from '@/components/pages/StudioPage/components/StudioToastMessage';
import { useAddChapter } from '@/hooks/books/chapters/useAddChapter';
import { useChapters } from '@/hooks/books/chapters/useChapters';
import { useChapterVersions } from '@/hooks/books/chapters/useChapterVersions';
import { useDeleteChapter } from '@/hooks/books/chapters/useDeleteChapter';
import { useRenameChapter } from '@/hooks/books/chapters/useRenameChapter';
import { useSaveChapterDraft } from '@/hooks/books/chapters/useSaveChapterDraft';
import { useBook } from '@/hooks/books/useBook';
import { useBookStatus } from '@/hooks/books/useBookStatus';
import { useSaveBookVersion } from '@/hooks/books/useSaveBookVersion';
import useSaveTranscriptVersion from '@/hooks/transcripts/useSaveTranscriptVersion';
import { useAvailableVoices } from '@/hooks/voices/useAvailableVoices';

type UseEditBookTranscriptParams = {
  bookId: string;
};

export type TranscriptEditorForm = {
  speakers: {
    id: string;
    name: string;
    color: string;
    voiceId: string;
  }[];
};

export function useEditBookTranscript({ bookId }: UseEditBookTranscriptParams) {
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [lastChapterId, setLastChapterId] = useState(0);
  const [selectedChapterTranscript, setSelectedChapterTranscript] = useState('');
  const [localTranscripts, setLocalTranscripts] = useState<Record<string, string>>({});
  const [clearChangeHistory, setClearChangeHistory] = useState(false);
  const [isLoadingChapter, setIsLoadingChapter] = useState(false);
  const [isFirstChange, setIsFirstChange] = useState(false);
  const [completedAutoSave, setCompletedAutoSave] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState('');
  const [isRestoringTranscript, setIsRestoringTranscript] = useState(false);

  const debouncedSelectedChapterTranscript = useDebounce(
    localTranscripts[selectedChapterId],
    TIME_BETWEEN_AUTO_SAVES_MS
  );
  const [voices, setVoices] = useState({ artist: [], shared: [] });
  const toast = useToastMessage();
  const { artistId } = useParams();

  const { data: book } = useBook({ artistId, bookId });
  const {
    data: fetchedChapters,
    isLoading: isLoadingChapters,
    isRefetching: isRefetchingChapters,
  } = useChapters({
    artistId,
    bookId,
  });
  const { data: fetchedVoices } = useAvailableVoices({ artistId });
  const { data: bookStatus } = useBookStatus({
    artistId,
    bookId,
  });
  const {
    data,
    isLoading: isTranscriptHistoryLoading,
    refetch: refetchTranscriptHistory,
  } = useChapterVersions({
    artistId,
    bookId,
    chapterId: selectedVersionId,
  });
  const transcriptHistory = data?.published ?? [];

  const { addChapter: addChapterMutation, isPending: isAddingChapter } = useAddChapter();
  const { deleteChapter: deleteChapterMutation } = useDeleteChapter();
  const { renameChapter: renameChapterMutation } = useRenameChapter();
  const { saveChapterDraft: saveChapterDraftMutation, isPending: isAutoSaving } = useSaveChapterDraft();
  const { saveBookVersion: saveBookVersionMutation, isPending: isUpdatingTranscript } = useSaveBookVersion();
  // For now, only en is supported and so it's hardcoded
  const { saveTranscriptVersion: saveTranscriptVersionMutation } = useSaveTranscriptVersion(
    artistId,
    selectedVersionId,
    'en'
  );

  const form = useForm<TranscriptEditorForm>({
    defaultValues: {
      speakers: [
        {
          id: 'speaker-0',
          name: 'Speaker',
          color: colors[0],
          voiceId: '',
        },
      ],
    },
  });
  const speakers = form.watch('speakers');
  const { voiceId: selectedVoiceId } = speakers[0];

  const saveAllDrafts = async () => {
    const saveDraftPromises = [];
    const touchedChaptersIds = Object.keys(localTranscripts);
    touchedChaptersIds.forEach((chapterId) => {
      saveDraftPromises.push(
        saveChapterDraftMutation({ artistId, bookId, chapterId, text: localTranscripts[chapterId] })
      );
    });
    await Promise.all(saveDraftPromises);
  };

  const handleUpdateTranscript = async () => {
    let result = false;
    try {
      await saveAllDrafts();
      await saveBookVersionMutation({ artistId, bookId });
      result = true;
    } catch (error) {
      toast.show({ error: 'Failed to update transcript, try again later' });
      logger.error('Error updating transcript:', error);
    }
    return result;
  };

  const addChapter = async () => {
    try {
      await addChapterMutation({
        artistId,
        bookId,
        title: `Chapter ${lastChapterId + 1}`,
      });
      setLastChapterId(lastChapterId + 1);
    } catch (error) {
      toast.show({ error: 'Failed to add new chapter, try again later' });
      logger.error('Error updating transcript:', error);
    }
  };

  const deleteChapter = async (chapter: Chapter) => {
    const chaptersBeforeDelete = chapters;
    try {
      const newTranscript = chapters.filter((c) => c.id !== chapter.id);
      setChapters(newTranscript);

      // If the deleted chapter was selected, select the first chapter
      if (selectedChapterId === chapter.id) {
        setSelectedChapterId(newTranscript[0]?.id || '');
      }

      await deleteChapterMutation({
        artistId,
        bookId,
        chapterId: chapter.id,
      });

      // remove the chapter from the local transcript
      setLocalTranscripts((prev) => {
        const newTranscripts = { ...prev };
        delete newTranscripts[chapter.id];
        return newTranscripts;
      });
    } catch (error) {
      toast.show({ error: 'Failed to delete chapter, try again later' });
      logger.error('Error deleting chapter:', error);
      setChapters(chaptersBeforeDelete);
    }
  };

  const renameChapterTitle = async (chapter: Chapter, newTitle: string) => {
    const originalTitle = chapter.title;
    try {
      setChapters(chapters.map((c) => (c.id === chapter.id ? { ...c, title: newTitle } : c)));
      await renameChapterMutation({
        artistId,
        bookId,
        chapterId: chapter.id,
        title: newTitle,
      });
    } catch (error) {
      toast.show({ error: 'Failed to rename chapter, try again later' });
      logger.error('Error renaming chapter:', error);
      setChapters(chapters.map((c) => (c.id === chapter.id ? { ...c, title: originalTitle } : c)));
    }
  };

  const handleDownloadAudio = async () => {
    if (bookStatus?.audiobookGeneration !== BookProcessStatus.COMPLETED && chapters?.length === 0) {
      toast.show({ error: 'Audio book is not generated yet' });
      return;
    }

    try {
      toast.show({ success: 'The download will start shortly' });

      // TODO: Implement download audio
      // const { audioUrl } = chapters[0];
      // const audioName = getFormattedFileName(book.title, 'mp3');

      // const response = await fetch(audioUrl);
      // const blob = await response.blob();
      // downloadFileFromBlob(blob, audioName);
    } catch (error) {
      toast.show({ error: 'Failed to download audio' });
      logger.error('Error downloading file:', error);
    }
  };

  const updateChapterTranscript = (chapterId: string, transcript: string) => {
    setLocalTranscripts((prev) => ({ ...prev, [chapterId]: transcript }));
  };

  const handleContentChange = (content: string) => {
    updateChapterTranscript(selectedChapterId, content);
  };

  const hasChapterTranscript = (chapterId: string) => {
    return localTranscripts[chapterId] !== undefined;
  };

  const handleSelectVersion = (versionId: string) => {
    setSelectedVersionId(versionId);
  };

  const restoreTranscript = async ({ transcript }: { transcript: string }) => {
    setIsRestoringTranscript(true);
    try {
      await saveTranscriptVersionMutation({ transcript });
      await saveChapterDraftMutation({ artistId, bookId, chapterId: selectedChapterId, text: transcript });
      handleContentChange(transcript);
      setSelectedChapterTranscript(transcript);
      await refetchTranscriptHistory();
    } catch (error) {
      toast.show({ error: 'Failed to save transcript version' });
      logger.error('Error saving transcript version:', error);
    } finally {
      setIsRestoringTranscript(false);
    }
  };

  // Handle initial chapters fetch
  useEffect(() => {
    if (fetchedChapters?.length) {
      setChapters(fetchedChapters.map((c) => ({ id: c.id, title: c.title, text: '', lastUpdate: c.lastUpdate })));
      setSelectedVersionId(fetchedChapters[0].id);
      if (!selectedChapterId) {
        setSelectedChapterId(fetchedChapters[0].id);
      }
    }
    setLastChapterId(fetchedChapters?.length);
  }, [fetchedChapters, isLoadingChapters]);

  // Handle chapter change / first fetch
  useEffect(() => {
    async function fetchChapter() {
      setIsLoadingChapter(true);
      const response = await studioService.chapters.getChapter({
        artistId,
        bookId,
        chapterId: selectedChapterId,
      });

      const chapter = mapChapter(response.data);

      if (chapter && !hasChapterTranscript(chapter.id)) {
        setLocalTranscripts((prev) => ({ ...prev, [chapter.id]: chapter.draft ?? chapter.text }));
      }
      setIsLoadingChapter(false);
    }

    if (selectedChapterId && !hasChapterTranscript(selectedChapterId)) {
      fetchChapter();
    }
    setIsFirstChange(true);

    // clear undo/redo history
    setClearChangeHistory(true);
    setTimeout(() => {
      setClearChangeHistory(false);
    }, 1);
  }, [selectedChapterId, isLoadingChapters]);

  // Handle chapter transcript change
  useEffect(() => {
    if (
      selectedChapterId &&
      hasChapterTranscript(selectedChapterId) &&
      selectedChapterTranscript !== localTranscripts[selectedChapterId]
    ) {
      setSelectedChapterTranscript(localTranscripts[selectedChapterId]);
    }
  }, [selectedChapterId, isLoadingChapter]);

  // Handle auto-save of transcript
  useEffect(() => {
    if (
      debouncedSelectedChapterTranscript !== null &&
      debouncedSelectedChapterTranscript !== undefined &&
      !isFirstChange
    ) {
      const autoSave = async () => {
        try {
          await saveChapterDraftMutation({
            artistId,
            bookId,
            chapterId: selectedChapterId,
            text: debouncedSelectedChapterTranscript,
          });
        } catch (error) {
          toast.show({ error: 'Failed to auto-save transcript' });
          logger.error('Error auto-saving transcript:', error);
        } finally {
          setCompletedAutoSave(true);
          setTimeout(() => {
            setCompletedAutoSave(false);
          }, 1000);
        }
      };
      autoSave();
    }
    setIsFirstChange(false);
  }, [debouncedSelectedChapterTranscript]);

  // Update the voiceId when the voices are fetched and map voices
  useEffect(() => {
    const mainVoiceId = fetchedVoices?.find((voice) => voice.isMain)?.id;
    form.setValue('speakers.0.voiceId', mainVoiceId);

    const mappedVoices = fetchedVoices?.map((voice) => ({
      ...voice,
      preview_url: voice.audioUrl,
    }));
    setVoices({
      artist: mappedVoices?.filter((voice) => voice.artistId) ?? [],
      shared: mappedVoices?.filter((voice) => !voice.artistId) ?? [],
    });
  }, [fetchedVoices]);

  return {
    book,
    chapters,
    isLoadingChapters,
    selectedChapterId,
    form,
    voices,
    isTranscriptHistoryLoading,
    transcriptHistory,
    clearChangeHistory,
    selectedChapterTranscript,
    isLoadingChapter,
    isUpdatingTranscript,
    isAutoSaving,
    completedAutoSave,
    selectedVoiceId,
    audioBookState: bookStatus?.audiobookGeneration,
    isRestoringTranscript,
    isAddingChapter,
    isRefetchingChapters,
    handleUpdateTranscript,
    setSelectedChapterId,
    addChapter,
    deleteChapter,
    renameChapterTitle,
    handleDownloadAudio,
    handleContentChange,
    handleSelectVersion,
    restoreTranscript,
  };
}
