import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { Chapter } from '@pray/shared/services/studioService/books/chapters';
import { BookProcessStatus } from '@pray/shared/services/studioService/books/types';
import { navigate } from '@pray/shared/utils/navigation';

import { useBookTranscript } from './useBookTranscript';
import GenerateAudioBanner from '../../components/GenerateAudioBanner';

import ButtonCluster from '@/components/pages/StudioPage/components/ButtonCluster/ButtonCluster';
import Markdown from '@/components/pages/StudioPage/components/Markdown/Markdown';
import TabPage from '@/components/pages/StudioPage/components/TabPage/TabPage';
import TranscriptHistoryModal from '@/components/pages/StudioPage/components/TranscriptHistoryModal/TranscriptHistoryModal';
import useAppRoutes from '@/hooks/useAppRoutes';
import { AiStar, BulletList, HistoryClock, VoiceWarning } from '@/images/icons';
import { cn } from '@/styles/utils';
import { formatUpdatedAtDate } from '@/utils/dateTimeUtils';

type BookTranscriptPageProps = {
  textExtractionStatus: BookProcessStatus;
  audiobookGenerationStatus: BookProcessStatus;
  audiobookGenerationUpdatedAt: string;
  lastSavedAt: string;
  bookId: string;
  onDownloadAudio: () => void;
};

type BookTranscriptReadyProps = {
  chapters: Chapter[];
  updatedAt: string;
  audiobookGenerationStatus: BookProcessStatus;
  audiobookGenerationUpdatedAt: string;
  lastSavedAt: string;
  bookId: string;
  artistId: string;
  selectedChapter: Chapter;
  onSelectChapter: (chapterId: string) => void;
};

type BookTranscriptProps = {
  chapters: Chapter[];
  selectedChapter: Chapter;
  onSelectChapter: (chapterId: string) => void;
};

type ChaptersMenuProps = {
  selectedChapter: Chapter;
  chapters: Chapter[];
  onSelectChapter: (chapterId: string) => void;
};

export default function BookTranscriptPage({
  textExtractionStatus,
  audiobookGenerationStatus,
  audiobookGenerationUpdatedAt,
  lastSavedAt,
  bookId,
}: BookTranscriptPageProps) {
  const { artistId } = useParams();
  const {
    chapters,
    isLoading,
    updatedAt,
    transcriptHistory,
    isTranscriptHistoryLoading,
    isRestoringTranscript,
    selectedChapter,
    restoreTranscript,
    handleSelectVersion,
    handleSelectChapter,
  } = useBookTranscript({
    bookId,
  });
  const appRoutes = useAppRoutes();
  const [isTranscriptHistoryModalOpen, setIsTranscriptHistoryModalOpen] = useState(false);

  const renderTopRightButtons = (textExtractionStatus: BookProcessStatus) => {
    if (textExtractionStatus !== BookProcessStatus.COMPLETED) return null;

    const handleEdit = () => {
      navigate(appRoutes.artistLibraryBookTranscriptEdit(bookId));
    };

    return (
      <ButtonCluster text="Edit" onClick={handleEdit} isLoading={false}>
        {/* TODO: Whenever the BE side is ready, we can add the download audio button back in */}
        {/* <DropdownMenu.Item
          className="w-48"
          title="Download Audio"
          leftComponent={<Download />}
          disabled={audiobookGenerationStatus !== BookProcessStatus.COMPLETED}
          onClick={onDownloadAudio}
        /> */}
        <DropdownMenu.Item
          className="w-48"
          title="Version History"
          leftComponent={<HistoryClock />}
          onClick={() => setIsTranscriptHistoryModalOpen(true)}
        />
      </ButtonCluster>
    );
  };

  const pageTitle = 'Transcript';

  if (textExtractionStatus === BookProcessStatus.FAILED || textExtractionStatus === BookProcessStatus.NOT_STARTED) {
    return (
      <TabPage title={pageTitle} topRightButtons={renderTopRightButtons(textExtractionStatus)}>
        <BookTranscriptFailed />
      </TabPage>
    );
  }

  if (textExtractionStatus === BookProcessStatus.COMPLETED && isLoading) {
    return (
      <TabPage title={pageTitle} topRightButtons={renderTopRightButtons(textExtractionStatus)}>
        <BookTranscriptLoading />
      </TabPage>
    );
  }

  if (textExtractionStatus === BookProcessStatus.COMPLETED && !isLoading) {
    return (
      <TabPage title={pageTitle} topRightButtons={renderTopRightButtons(textExtractionStatus)}>
        <BookTranscriptReady
          chapters={chapters}
          selectedChapter={selectedChapter}
          onSelectChapter={handleSelectChapter}
          updatedAt={updatedAt}
          audiobookGenerationStatus={audiobookGenerationStatus}
          audiobookGenerationUpdatedAt={audiobookGenerationUpdatedAt}
          lastSavedAt={lastSavedAt}
          bookId={bookId}
          artistId={artistId}
        />
        <TranscriptHistoryModal
          isMarkdown
          isOpen={isTranscriptHistoryModalOpen}
          isLoadingTranscriptHistory={isTranscriptHistoryLoading}
          isSavingTranscriptVersion={isRestoringTranscript}
          transcriptHistory={transcriptHistory}
          versionedContentList={chapters}
          saveTranscriptVersion={restoreTranscript}
          onSelectVersionedContent={handleSelectVersion}
          onClose={() => setIsTranscriptHistoryModalOpen(false)}
        />
      </TabPage>
    );
  }

  return (
    <TabPage title={pageTitle} topRightButtons={renderTopRightButtons(textExtractionStatus)}>
      <BookTranscriptGenerating />
    </TabPage>
  );
}

function BookTranscriptFailed() {
  return (
    <div className="flex flex-col items-center justify-center pt-32">
      <div className="flex flex-col items-center justify-center gap-8 pt-10">
        <div className="relative">
          <VoiceWarning width={64} height={64} />
        </div>
        <Text variant={typography.heading_lg}>Failed to generate transcript</Text>
      </div>
    </div>
  );
}

function BookTranscriptGenerating() {
  return (
    <div className="flex flex-col items-center justify-center pt-32">
      <div className="flex flex-col items-center justify-center gap-8 pt-10">
        <div className="relative">
          <Spinner size="large" color="purple" className="opacity-80" />
          <AiStar className="absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2" />
        </div>
        <Text variant={typography.heading_lg}>Generating your transcript</Text>
      </div>
    </div>
  );
}

function BookTranscriptLoading() {
  return (
    <div className="flex flex-col gap-8">
      <Loading isLight className="m-0" width={200} height={25} />
      <Loading isLight className="m-0" width="100%" height={70} />
      <Loading isLight className="m-0" width="100%" height={500} />
    </div>
  );
}

function BookTranscriptReady({
  chapters,
  updatedAt,
  audiobookGenerationStatus,
  audiobookGenerationUpdatedAt,
  lastSavedAt,
  bookId,
  artistId,
  selectedChapter,
  onSelectChapter,
}: BookTranscriptReadyProps) {
  return (
    <div className="flex flex-col gap-8">
      <Text variant={typography.body_lg} color={colors.text_tertiary} className="!font-medium">
        Last Update: {formatUpdatedAtDate(updatedAt)}
      </Text>

      <GenerateAudioBanner
        audiobookGenerationStatus={audiobookGenerationStatus}
        audiobookGenerationUpdatedAt={audiobookGenerationUpdatedAt}
        lastSavedAt={lastSavedAt}
        artistId={artistId}
        bookId={bookId}
      />

      <BookTranscript chapters={chapters} selectedChapter={selectedChapter} onSelectChapter={onSelectChapter} />
    </div>
  );
}

function BookTranscript({ chapters, selectedChapter, onSelectChapter }: BookTranscriptProps) {
  const selectedChapterText = selectedChapter?.text;

  return (
    <div className="flex h-[60vh] rounded-[4px] border border-[#E5E5E5]">
      <ChaptersMenu selectedChapter={selectedChapter} onSelectChapter={onSelectChapter} chapters={chapters} />

      <div className="flex-1 overflow-y-auto">
        <Markdown className="font-satoshi max-w-4xl select-text whitespace-pre-line p-10 text-base leading-7">
          {selectedChapterText}
        </Markdown>
      </div>
    </div>
  );
}

function ChaptersMenu({ selectedChapter, chapters, onSelectChapter }: ChaptersMenuProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={cn('flex flex-col gap-4 p-4 w-1/4 overflow-y-auto', !isCollapsed && 'border-r border-[#E5E5E5]')}>
      <div className="flex items-center justify-between gap-2">
        {!isCollapsed && <Text variant={typography.heading_lg}>Chapters</Text>}
        <BulletList className="cursor-pointer" onClick={() => setIsCollapsed((prev) => !prev)} />
      </div>

      {!isCollapsed && (
        <div className="flex flex-col gap-2">
          {chapters.map((chapter) => (
            <Button
              key={chapter.id}
              className={cn(
                'flex justify-start items-center gap-2 p-2',
                selectedChapter?.id === chapter.id && 'bg-[#F3F3F3] rounded-[6px]'
              )}
              tabIndex={0}
              onClick={() => onSelectChapter(chapter.id)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSelectChapter(chapter.id);
                }
              }}
            >
              <Text
                variant={typography.body_lg}
                className="max-w-full truncate text-left !font-medium normal-case"
                title={chapter.title}
              >
                {chapter.title}
              </Text>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}
