import React from 'react';

import AccountOverviewSection from './AccountOverviewSection/AccountOverviewSection';
import AudienceEngagementSection from './AudienceEngagementSection/AudienceEngagementSection';
import AudienceSegmentsSection from './AudienceSegmentsSection/AudienceSegmentsSection';
import SolomonSection from './SolomonSection/SolomonSection';
import WelcomeSection from './WelcomeSection/WelcomeSection';
import { useAuth } from '@pray/shared/hooks/useAuth';
import { Permissions } from 'utils/auth';

export default function DashboardPage() {
  const { permissions } = useAuth();
  const isAdmin = permissions.has(Permissions.STUDIO_ADMIN);

  return (
    <div className="flex max-w-6xl flex-col gap-8">
      <WelcomeSection />
      {isAdmin && <SolomonSection />}
      <AudienceEngagementSection />
      <AccountOverviewSection />
      <AudienceSegmentsSection />
    </div>
  );
}
