import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailTemplateMapper } from '@pray/shared/services/studioService/emailTemplates';

export default function useSaveEmailTemplate() {
  const queryClient = useQueryClient();

  async function saveEmailTemplate({
    artistId = undefined,
    templateId = undefined,
    name = undefined,
    previewImgUrl = undefined,
    rawHtml = undefined,
    rawCss = undefined,
    compiledHtmlCss = undefined,
  }) {
    const data = {
      artistId,
    };

    if (templateId !== undefined) data.templateId = templateId;
    if (name !== undefined) data.name = name;
    if (previewImgUrl !== undefined) data.previewImgUrl = previewImgUrl;
    if (rawHtml !== undefined) data.rawHtml = rawHtml;
    if (rawCss !== undefined) data.rawCss = rawCss;
    if (compiledHtmlCss !== undefined) data.compiledHtmlCss = compiledHtmlCss;

    let template;

    if (!templateId) {
      template = await createTemplate(data);
    } else {
      template = await updateTemplate(data);
    }

    return emailTemplateMapper(template);
  }

  async function createTemplate(data) {
    const response = await studioService.emailTemplates.createEmailTemplate(data);

    return response.data;
  }

  async function updateTemplate(data) {
    const response = await studioService.emailTemplates.updateEmailTemplate(data);

    return response.data;
  }

  const mutation = useMutation({
    mutationFn: saveEmailTemplate,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistEmailTemplates(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveEmailTemplate: mutateAsync,
    ...rest,
  };
}
