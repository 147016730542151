import amplitude from 'amplitude-js';
import axios from 'axios';
import { get } from 'lodash';

import { SERVER_BASE_URI } from '@pray/shared/config';
import { PLATFORM_TYPES } from '@pray/shared/constants';
import { getUserTimezone } from '@pray/shared/utils/datetime';
import logger from '@pray/shared/utils/logger';

import { authenticate } from '../auth';
import storage from '../storage';
import type { RequestConfig, Method, ExtraHeaders, FetchRequestConfig, MutateRequestConfig } from './types';

// withCredentials: true is required for properly sending/receiving cookies from out backend
// https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
export const API = axios.create({
  baseURL: SERVER_BASE_URI,
  withCredentials: true,
});

const executeGetRequest = <T = unknown>(
  uri: string,
  params: FetchRequestConfig['params'] = {},
  options: Omit<FetchRequestConfig, 'params'> = {}
) => {
  return _executeSecureRequest<T>('get', uri, { params, ...options });
};

const executePostRequest = <T = unknown>(
  uri: string,
  json: RequestConfig['data'] = undefined,
  options: MutateRequestConfig = {}
) => {
  return _executeSecureRequest<T>('post', uri, { data: json, ...options });
};

const executePutRequest = <T = unknown>(
  uri: string,
  json: RequestConfig['data'] = undefined,
  options: MutateRequestConfig = {}
) => {
  return _executeSecureRequest<T>('put', uri, { data: json, ...options });
};

const executeDeleteRequest = <T = unknown>(
  uri: string,
  json: RequestConfig['data'] = undefined,
  options: MutateRequestConfig = {}
) => {
  return _executeSecureRequest<T>('delete', uri, { data: json, ...options });
};

const executePatchRequest = <T = unknown>(
  uri: string,
  json: RequestConfig['data'] = undefined,
  options: MutateRequestConfig = {}
) => {
  return _executeSecureRequest<T>('patch', uri, { data: json, ...options });
};

const executeDeprecatedAuthenticateRequest = async (uri, json) => {
  const authToken = storage.getFromStorage('authToken');
  const refreshToken = storage.getFromStorage('refreshToken');

  const deprecatedHeaders = {
    Authorization: authToken,
    'refresh-token': refreshToken,
  };

  const response = await _executeSecureRequest('post', '/0.13/authenticate', { data: json }, deprecatedHeaders);

  storage.removeFromStorage('authToken', true);
  storage.removeFromStorage('refreshToken', true);

  return response;
};

const _executeSecureRequest = <T = unknown>(
  method: Method,
  uri: string,
  options: RequestConfig,
  extraHeaders: ExtraHeaders = {}
) => {
  const deviceId = storage.getDeviceId();
  const amplitudeSessionId = amplitude.getInstance().getSessionId() || null;
  const userTimezone = getUserTimezone();

  const headers = {
    'Content-Type': 'application/json',
    'pray-platform': 'Web',
    hostname: typeof window === 'undefined' ? null : window.location.hostname,
    fingerprint: deviceId,
    amplitude_session_id: amplitudeSessionId,
    timezone: userTimezone,
    ...extraHeaders,
  };

  if (storage.isChromeExtension()) {
    headers['platform-type'] = PLATFORM_TYPES.CHROME_EXTENSION;
  }

  const myOptions = {
    method,
    url: uri,
    responseType: get(options, 'responseType', 'json'),
    headers,
    ...options,
  };

  return API.request<T>(myOptions)
    .then((result) => result)
    .catch((error) => {
      logger.debug('API request error:', uri, error.response);

      if (!uri.endsWith('/authenticate') && (error.response?.status === 403 || error.response?.status === 401)) {
        return authenticate().then(() => {
          return API.request<T>(myOptions)
            .then((result) => result)
            .catch((err) => {
              throw err;
            });
        });
      }
      logger.debug(error);
      throw error;
    });
};

export default {
  executeGetRequest,
  executePostRequest,
  executePutRequest,
  executePatchRequest,
  executeDeleteRequest,
  executeDeprecatedAuthenticateRequest,

  setDefaultHeaders: (headers) => {
    API.defaults.headers.common = { ...API.defaults.headers.common, ...headers };
  },
};
