import React, { useState } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { ContentList } from '@pray/shared/components/ui/Icons/ContentList';
import { CreatorProfile } from '@pray/shared/components/ui/Icons/CreatorProfile';
import Text from '@pray/shared/components/ui/Text/Text';
import { PLACEHOLDER_IMAGE } from '@pray/shared/constants';
import useDOMEvents from '@pray/shared/hooks/useDOMEvents';
import { breakpoints } from '@pray/shared/hooks/useHasMinWidth';
import useMediaQuery from '@pray/shared/hooks/useMediaQuery';
import CloseIcon from '@pray/shared/icons/close';
import PauseIconSmall from '@pray/shared/icons/PauseIconSmall';
import PlayIconSmall from '@pray/shared/icons/PlayIconSmall';
import { formatTime } from '@pray/shared/utils';

import Actions from './Actions/Actions';
import ExtraActionsMenu from './Actions/ExtraActionsMenu';
import Controls from './Controls/Controls';
import EpisodeInformation from './EpisodeInformation/EpisodeInformation';
import Scrubber from './Scrubber/Scrubber';
import useContentReaction from '../../../hooks/useContentReaction';

import styles from './MinimizedContentPlayer.module.scss';

export default function MinimizedContentPlayer({
  audio,
  player,
  currentContent,
  controlOptions,
  handlePlayContent,
  handleSeekUpdate,
  handleShareContent,
  updateReactions,
  onMaximizeButtonClick,
  onClose,
  onChapterSelect,
}) {
  const domEvents = useDOMEvents();
  const isMiniPlayerOnMobile = useMediaQuery(`(max-width: ${breakpoints.medium - 1}px)`);
  const [isShowExtraActions, setIsShowExtraActions] = useState(false);
  const { hasReacted, toggleContentReaction } = useContentReaction(currentContent);

  const extraActions = [
    {
      Icon: CreatorProfile,
      label: `View ${currentContent.artist_name}`,
      to: `/artists/${currentContent.artist_id}`,
    },
    {
      Icon: ContentList,
      label: 'View all episodes',
      to: `/series/${currentContent.content_series_handle}`,
    },
  ];

  const handleMaximizePlayerOnClick = (event) => {
    if (isMiniPlayerOnMobile) {
      onMaximizeButtonClick(event);
    }
  };

  const handleReacionButtonClick = () => {
    toggleContentReaction();
    updateReactions();
  };

  const imageUrl =
    currentContent.image_url ||
    currentContent.content_series_image_url ||
    currentContent.artist_profile_image_url ||
    PLACEHOLDER_IMAGE;

  return (
    <>
      {isShowExtraActions && <ExtraActionsMenu options={extraActions} onClose={() => setIsShowExtraActions(false)} />}

      <div
        data-minimizedplayer
        className={`${styles.fullscreen} ${styles.minimized}`}
        onMouseLeave={() => domEvents.trigger(events.onMouseLeave)}
      >
        <img
          alt={currentContent.title}
          src={imageUrl}
          className={styles.contentImageMinimized}
          onClick={handleMaximizePlayerOnClick}
          role="none"
        />
        <div className={styles.minimizedPlayer}>
          <div className={styles.minimizedContainer}>
            <div className="lg-hide">
              <IconButton
                className={styles.button}
                data-tapeventname={player.isPlaying ? 'Pause' : 'Play'}
                onClick={player.isPlaying ? player.pause : player.play}
              >
                {player.isPlaying ? <PauseIconSmall /> : <PlayIconSmall />}
              </IconButton>
            </div>

            <EpisodeInformation isMinimized currentContent={currentContent} onClick={handleMaximizePlayerOnClick} />

            <div className="sm-hide lg-show">
              <Controls
                isMinimized
                currentContent={currentContent}
                controlOptions={controlOptions}
                isPlaying={player.isPlaying}
                onPlayPauseButtonClick={player.isPlaying ? player.pause : player.play}
                onBackButtonClick={player.skip(-15)}
                onForwardButtonClick={player.skip(15)}
                onSkipBackButtonClick={handlePlayContent(currentContent.previousContentId)}
                onSkipForwardButtonClick={handlePlayContent(currentContent.nextContentId)}
              />
            </div>

            <div className={`sm-hide lg-show ${styles.right}`}>
              <Text variant={typography.footnote} color={colors.text_secondary}>
                {formatTime(audio.currentTime)}&nbsp;/&nbsp;{formatTime(audio.duration)}
              </Text>

              <Actions
                audio={audio}
                player={player}
                isMinimized
                currentContent={currentContent}
                isContentSaved={hasReacted}
                controlOptions={controlOptions}
                onExtraActionsButtonClick={() => setIsShowExtraActions((value) => !value)}
                onReactionButtonClick={handleReacionButtonClick}
                onShareButtonClick={() => handleShareContent(currentContent)}
                onMaximizeButtonClick={onMaximizeButtonClick}
                onChapterSelect={onChapterSelect}
              />
            </div>

            <div className={!controlOptions.isShowClose ? 'lg-hide' : ''}>
              <IconButton data-tapeventname="Close" className={styles.button} onClick={onClose}>
                <CloseIcon fill="#a6a8ad" />
              </IconButton>
            </div>
          </div>

          <div className={styles.scrubber}>
            <Scrubber
              isMinimized
              currentTime={audio.currentTime}
              duration={audio.duration}
              remainingTime={audio.remainingTime}
              onChange={handleSeekUpdate}
              onEventSeekUpdate={player.dragStarted}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const events = {
  onMouseLeave: 'MinimizedContentPlayer.onMouseLeave',
};
