import React from 'react';

export function Download({ fill = '#FFFFFF', ...props }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.25 11.75V15.25C17.25 16.3546 16.3546 17.25 15.25 17.25H2.75C1.64543 17.25 0.75 16.3546 0.75 15.25V11.75M8.99999 12V0.75M8.99999 12L5.5 8.5M8.99999 12L12.5 8.5"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
