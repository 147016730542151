import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

export default function useSegmentDetails({ artistId = '', segmentCode = '', chartType = '' }) {
  async function fetchSegmentDetails() {
    try {
      const response = await studioService.periodicTable.getSegmentDetails({
        artistId,
        segmentCode,
        chartType,
      });

      return response.data;
    } catch (err) {
      logger.error('Failed to fetch segment details', { segmentCode, chartType }, err);
      throw err;
    }
  }

  const query = useQuery({
    enabled: !!artistId && !!segmentCode,
    queryKey: queryKeys.artistPeriodicTableSegments(artistId, segmentCode, chartType),
    queryFn: fetchSegmentDetails,
  });

  return query;
}
