import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { UPLOAD_STEP_COPY } from '../../constants';
import { useCsvDownload } from '../useCsvDownload';

export default function TemplateNote() {
  const downloadCsv = useCsvDownload('template.csv');

  return (
    <div className="flex max-w-md flex-col rounded-2xl bg-[#F1F3FE] p-4">
      <div>
        <Text variant={typography.heading_md}>{UPLOAD_STEP_COPY.TEMPLATE_NOTE_TITLE}</Text>
      </div>
      <Text className="!font-medium" variant={typography.body_lg} color={colors.text_secondary}>
        <button type="button" className="underline hover:opacity-80" onClick={downloadCsv}>
          {UPLOAD_STEP_COPY.TEMPLATE_DOWNLOAD_LINK}
        </button>{' '}
        {UPLOAD_STEP_COPY.TEMPLATE_DOWNLOAD_TEXT}
      </Text>
    </div>
  );
}
