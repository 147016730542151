import React, { useEffect, useState } from 'react';

import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import useWorkflowAIStatus, { TASKS_TO_BE_PROCESSED } from './useWorkflowAIStatus';

const mapTaskNameToMessage = {
  [TASKS_TO_BE_PROCESSED.SUMMARY]: 'Generating Summaries',
  [TASKS_TO_BE_PROCESSED.CHAPTERS]: 'Generating Chapters',
  [TASKS_TO_BE_PROCESSED.SHORT_SUMMARY]: 'Generating Short Summaries',
  [TASKS_TO_BE_PROCESSED.TRANSCRIPT]: 'Generating Transcripts',
  [TASKS_TO_BE_PROCESSED.ARTICLE]: 'Generating Article',
  [TASKS_TO_BE_PROCESSED.ANNOUNCEMENT]: 'Generating Announcement',
  [TASKS_TO_BE_PROCESSED.EMAIL_CAMPAIGN]: 'Generating Email Campaign',
};

function WorkflowAIStatus() {
  const { isLoading, tasksDone } = useWorkflowAIStatus();
  const [percent, setPercent] = useState(0);
  const [processingTask, setProcessingTask] = useState(null);

  useEffect(() => {
    /**
     * We need to calculate the percentage of tasks done
     * @type {number}
     */
    const totalTasks = Object.keys(TASKS_TO_BE_PROCESSED).length;
    const doneTasks = tasksDone.size;
    const percent = (doneTasks / totalTasks) * 100;
    setPercent(Math.min(Math.floor(percent), 100));

    /**
     * We need to set the processing task
     * @type {(string)[]}
     */
    const tasksToBeProcessed = Object.values(TASKS_TO_BE_PROCESSED);
    const tasksNotProcessedYet = tasksToBeProcessed.filter((task) => !tasksDone.has(task));
    const processingTask = tasksNotProcessedYet[Math.floor(Math.random() * tasksNotProcessedYet.length)];
    if (processingTask) {
      setProcessingTask(mapTaskNameToMessage[processingTask]);
    } else {
      setProcessingTask(null);
    }
  }, [tasksDone]);

  if (!isLoading || !processingTask) return null;

  return (
    <div className="mx-2 rounded-md bg-[#F4F2FE] px-4 py-3 text-[#360A76]">
      <div className="mb-3 flex justify-between font-bold">
        <p>AI Optimization</p>
        <span>{percent}%</span>
      </div>

      <div className="relative mb-3 h-2 overflow-hidden rounded-full bg-[#D2C7F9]">
        <div
          className="transition-width absolute left-0 top-0 h-full bg-purple-500 duration-500 ease-in-out"
          style={{ width: `${percent || 0}%` }}
        />
      </div>

      {processingTask && (
        <div className="flex items-center py-1">
          <Spinner className="m-0 mr-2 size-4" size="small" color="purple" />
          <p className="text-xs">{processingTask}</p>
        </div>
      )}
    </div>
  );
}

export default WorkflowAIStatus;
