import React from 'react';
import { useNavigate } from 'react-router-dom';

import { typography } from '@pray/shared/components/foundations';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import Label from '@pray/shared/components/ui/Label/Label';
import { Table, Tbody, Th, Thead, Tr, TdLink } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import { formatDateUsingShorterMonthAndTime } from '@pray/shared/utils/datetime';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useGetAnnouncementsList from 'hooks/announcements/useGetAnnouncementsList';
import useAppRoutes from 'hooks/useAppRoutes';
import { MoneyHandCoins } from 'images/icons';
import { snakeCaseToTitleCase } from 'utils/string';

import ActionButton from '../../../components/ActionButton/ActionButton';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { ImageWithFallback } from '../../../components/ImageWithFallback/ImageWithFallback';
import TabPage from '../../../components/TabPage/TabPage';

const AnnouncementStatus = {
  SCHEDULED: 'scheduled',
  DRAFT: 'draft',
  SENT: 'sent',
};

export default function AnnouncementsList() {
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const announcementsList = useGetAnnouncementsList(artistId);
  const announcements = announcementsList.data?.pages?.flatMap((page) => page.data) || [];

  const renderTopRightButtons = () => {
    return (
      <ActionButton
        text="Create Announcement"
        onClick={() => {
          navigate(appRoutes.artistAnnouncementCreate());
        }}
      />
    );
  };

  return (
    <TabPage
      title="Announcements"
      topRightButtons={renderTopRightButtons()}
      data-viewname={VIEW_EVENT_NAMES.ANNOUNCEMENTS.CAMPAIGNS}
    >
      {(() => {
        if (announcementsList.isLoading) return null;

        if (!announcementsList.isFetching && announcements.length === 0) {
          return (
            <EmptyState
              icon={<MoneyHandCoins />}
              title="You don’t have any Announcement"
              subtitle="To create an Announcement click on the button in the top right"
            />
          );
        }

        return (
          <>
            <AnnouncementsTable
              data={announcements}
              sortBy={announcementsList.sortOrder}
              onSortChange={(value) => {
                announcementsList.setSortOrder(value);
                announcementsList.refetch();
              }}
              onSelectionChange={() => null}
              getRowURL={({ id, status }) => {
                return status.toLowerCase() === AnnouncementStatus.DRAFT ||
                  status.toLowerCase() === AnnouncementStatus.SCHEDULED
                  ? appRoutes.artistAnnouncementEdit(id)
                  : appRoutes.artistAnnouncementDetailsOverview(id);
              }}
            />

            {announcementsList.hasNextPage && <InfiniteScroll fetchData={announcementsList.fetchNextPage} />}
          </>
        );
      })()}
    </TabPage>
  );
}

const AnnouncementsTable = ({ data, sortBy, onSortChange, onSelectionChange, getRowURL }) => {
  return (
    <Table
      sortable
      sortBy={sortBy}
      rowCount={data.length}
      onSortChange={onSortChange}
      onSelectionChange={onSelectionChange}
    >
      <Thead>
        <Tr>
          <Th field="image_url" />
          <Th field="announcement_title">Name</Th>
          <Th field="is_active">Status</Th>
          <Th field="created_timestamp">Send Date</Th>
          <Th field="resource_type" className="hidden lg:table-cell	">
            Resource
          </Th>
          <Th field="segment_id" className="hidden lg:table-cell	">
            Segment
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((announcement) => (
          <Tr key={announcement.id} className="lg:text-md cursor-pointer text-sm">
            <TdLink to={getRowURL(announcement)}>
              <ImageWithFallback imageUrl={announcement.image_url} altText="Announcement" />
            </TdLink>
            <TdLink className="mr-3" to={getRowURL(announcement)}>
              <Text variant={typography.subhead_small}>{announcement.announcement_title}</Text>
            </TdLink>
            <TdLink to={getRowURL(announcement)}>
              <Label className="uppercase" status={announcement.status}>
                {announcement.status}
              </Label>
            </TdLink>
            <TdLink to={getRowURL(announcement)}>
              <Text variant={typography.subhead_small}>
                {announcement.scheduled_at
                  ? formatDateUsingShorterMonthAndTime(announcement.scheduled_at)
                  : formatDateUsingShorterMonthAndTime(announcement.created_timestamp)}
              </Text>
            </TdLink>
            <TdLink className="hidden lg:table-cell" to={getRowURL(announcement)}>
              <Text variant={typography.subhead_small}>{snakeCaseToTitleCase(announcement.resource_type ?? '--')}</Text>
            </TdLink>
            <TdLink className="hidden lg:table-cell" to={getRowURL(announcement)}>
              <Text variant={typography.subhead_small}>{announcement.segment_name ?? '--'}</Text>
            </TdLink>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
