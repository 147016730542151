import React from 'react';

import Text from '@pray/shared/components/ui/Text';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import useDeleteEmailCampaign from 'hooks/emailCampaigns/useDeleteEmailCampaign';

export default function DeleteEmailModal({ artistId, campaignId, onCancel, onDelete }) {
  const toast = useToastMessage();
  const { deleteEmailCampaign } = useDeleteEmailCampaign();

  async function handleDeleteEmail() {
    await deleteEmailCampaign({ artistId, campaignId });
    toast.show({ success: 'Email has been deleted' });
    onDelete();
  }

  return (
    <DeleteContentModal
      isOpen
      title="Confirm Deletion"
      message={
        <>
          <Text>Are you sure you want to delete this email?</Text>
          <Text>This action cannot be undone.</Text>
        </>
      }
      onDelete={handleDeleteEmail}
      onClose={onCancel}
    />
  );
}
