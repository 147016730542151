import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';
import useDebounce from '@pray/shared/hooks/useDebounce';
import { formatNumber } from '@pray/shared/utils';
import { formatDateUsingShorterMonth } from '@pray/shared/utils/datetime';

import { PLACEHOLDER_IMAGE, VIEW_EVENT_NAMES } from 'constants.js';
import useLeadCampaigns from 'hooks/leadCampaigns/useLeadCampaigns';
import useToggleMaster from 'hooks/leadCampaigns/useToggleMaster';
import useAppRoutes from 'hooks/useAppRoutes';
import { Pencil, Plus } from 'images/icons';

import CampaignModal from './CampaignModal/CampaignModal';
import messages from './UiMessages';
import ActionButton from '../../../components/ActionButton/ActionButton';
import EmptyState from '../../../components/EmptyState/EmptyState';
import { useToastMessage } from '../../../components/StudioToastMessage';
import TabPage from '../../../components/TabPage/TabPage';

const campaignTypes = {
  email: 'Email',
  email_and_phone: 'Email, Phone',
};

export default function Campaigns() {
  const [modalProps, setModalProps] = useState(null);
  const [isModalOpened, setIsModalOpened] = useState(true);

  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const leadCampaigns = useLeadCampaigns();
  const { toggleMasterCampaign } = useToggleMaster();
  const toast = useToastMessage();

  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') ?? '');
  const debouncedSearch = useDebounce(search);

  useEffect(() => {
    setSearchParams((params) => {
      if (debouncedSearch) {
        params.set('search', debouncedSearch);
      } else {
        params.delete('search');
      }
      return params;
    });
  }, [debouncedSearch]);

  const renderTopRightButtons = () => {
    return <ActionButton text="Create" to="create" />;
  };

  const handleOnCloseModal = () => {
    setIsModalOpened(false);
  };

  const handleToggleUpdate = async (campaign) => {
    try {
      await toggleMasterCampaign(campaign.id);
    } catch (error) {
      const response = error?.response?.data;
      const errorCode = response?.data[0]?.message;

      if (errorCode === 'target_count_reached') {
        setModalProps({
          campaign,
          title: messages.target_count_reached.title,
          subtitle: messages.target_count_reached.subtitle,
        });

        setIsModalOpened(true);
      } else if (errorCode === 'invalid_scheduled_at_date') {
        setModalProps({
          campaign,
          title: messages.invalid_scheduled_at_date.title,
          subtitle: messages.invalid_scheduled_at_date.subtitle,
        });

        setIsModalOpened(true);
      } else {
        toast.show({ error: 'Failed to update campaign' });
      }
    }
  };

  return (
    <TabPage
      title="Campaigns"
      data-viewname={VIEW_EVENT_NAMES.MINISTRY_MATCH.CAMPAIGNS}
      topRightButtons={renderTopRightButtons()}
    >
      <div className="mb-4 mt-6 w-96">
        <TextInput
          type="search"
          leftIcon={<Search />}
          placeholder="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>

      {(() => {
        if (leadCampaigns.isLoading) return null;

        if (!leadCampaigns.isFetching && !leadCampaigns.hasPreviousPage && !leadCampaigns.hasNextPage) {
          return (
            <EmptyState
              icon={<Plus />}
              title="Create your first campaign"
              subtitle='Click on "Create" to create your first campaign'
            />
          );
        }

        return (
          <>
            {modalProps && (
              <CampaignModal
                isOpen={isModalOpened}
                onClose={handleOnCloseModal}
                title={modalProps.title}
                campaign={modalProps.campaign}
                subtitle={modalProps.subtitle}
              />
            )}

            <CampaignTable
              data={leadCampaigns.data.pages.flatMap((page) => page.data)}
              sortBy={leadCampaigns.sortOrder}
              onSortChange={leadCampaigns.setSortOrder}
              onSelectionChange={() => null}
              handleToggleUpdate={handleToggleUpdate}
              onEditButtonClick={({ id }) => {
                navigate(appRoutes.ministryMatchCampaignDetails(id));
              }}
            />
            {leadCampaigns.hasNextPage && <InfiniteScroll fetchData={leadCampaigns.fetchNextPage} />}
          </>
        );
      })()}
    </TabPage>
  );
}

const CampaignTable = ({ data, sortBy, onSortChange, onSelectionChange, onEditButtonClick, handleToggleUpdate }) => {
  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    return <IconButton icon={Pencil} tooltip="Edit" onClick={() => onEditButtonClick(item)} />;
  };

  return (
    <div className="mt-6">
      <Table
        sortable
        sortBy={sortBy}
        rowCount={data.length}
        rowActions={getRowActions}
        onSortChange={onSortChange}
        onSelectionChange={onSelectionChange}
      >
        <Thead>
          <Tr>
            <Th field="master_toggle">Status</Th>
            <Th field="name">Ad</Th>
            <Th field="client_name">Organization</Th>
            <Th field="type">Type</Th>
            <Th field="scheduled_start_date">Start Date</Th>
            <Th field="scheduled_end_date">End Date</Th>
            <Th field="current_count">Leads</Th>
            <Th field="target_count">Target leads</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((campaign) => (
            <Tr key={campaign.id}>
              <Td>
                <div className="px-6">
                  <ToggleSwitch checked={campaign.master_toggle} onChange={() => handleToggleUpdate(campaign)} />
                </div>
              </Td>
              <Td>
                <div className="flex gap-4">
                  <img
                    alt=""
                    className="size-[50px] min-w-[50px] rounded-md object-cover"
                    src={campaign.image_url}
                    onError={(event) => {
                      event.target.src = PLACEHOLDER_IMAGE;
                    }}
                  />
                  <div className="flex min-w-[192px] max-w-[192px] flex-col justify-center gap-1.5">
                    <Text variant="subhead_small" className="line-clamp-2 font-medium">
                      {campaign.name}
                    </Text>
                  </div>
                </div>
              </Td>
              <Td>{campaign.client.name}</Td>
              <Td>{campaignTypes[campaign.type]}</Td>
              <Td>
                {campaign.scheduled_start_date ? formatDateUsingShorterMonth(campaign.scheduled_start_date, true) : '-'}
              </Td>
              <Td>
                {campaign.scheduled_end_date ? formatDateUsingShorterMonth(campaign.scheduled_end_date, true) : '-'}
              </Td>
              <Td>{campaign.current_count ? formatNumber(campaign.current_count) : '-'}</Td>
              <Td>{campaign.target_count ? formatNumber(campaign.target_count) : '-'}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
};
