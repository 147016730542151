import {
  faBold,
  faItalic,
  faUnderline,
  faLink,
  faListUl,
  faListOl,
  faStrikethrough,
  faChevronDown,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentEditor } from '@tiptap/react';
import React, { useCallback, useMemo, useState, useEffect, useRef, ReactNode } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { cn } from '@pray/shared/utils/styles';

import { ArrowTurnRight, ArrowTurnLeft } from 'images/icons';

import {
  ALIGNMENT_NODE_TYPES,
  ALIGNMENT_OPTIONS,
  DEFAULT_ALIGNMENT_OPTION_ICON,
  DEFAULT_TEXT_STYLING_LABEL,
  TEXT_STYLING_NODE_TYPES,
  TEXT_STYLING_OPTIONS,
} from './constants';

type Option = {
  label: string;
  icon: IconDefinition;
  onClick: () => void;
};

type MenuBarDropdownProps = {
  label: string;
  options: Option[];
  activeIcon: IconDefinition | null;
  isActive: (option: Option) => boolean;
};

function MenuBarDropdown({ label, options, activeIcon, isActive }: MenuBarDropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-flex" ref={dropdownRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="items-left inline-flex min-h-8 cursor-pointer gap-2 whitespace-nowrap border-none bg-transparent p-2 text-base text-inherit hover:bg-gray-100/50"
      >
        {activeIcon ? (
          <FontAwesomeIcon icon={activeIcon} className="size-4" />
        ) : (
          <Text variant={typography.body_md} className="whitespace-nowrap !normal-case">
            {label}
          </Text>
        )}
        <FontAwesomeIcon icon={faChevronDown} className="ml-1 text-base" />
      </Button>
      {isOpen && (
        <div className="absolute left-0 top-full z-50 flex min-w-max flex-col whitespace-nowrap rounded border border-gray-200 bg-white p-1 shadow-md">
          {options.map((option) => (
            <Button
              key={option.label}
              onClick={() => {
                option.onClick();
                setIsOpen(false);
              }}
              className={cn(
                'flex min-h-8 w-full cursor-pointer items-center justify-start gap-2 rounded border-none bg-transparent p-2 text-left text-base',
                isActive(option) ? 'bg-gray-200 text-gray-900' : 'hover:bg-gray-100'
              )}
            >
              <FontAwesomeIcon icon={option.icon} className="size-4 shrink-0" />
              <Text variant={typography.body_md} className="whitespace-nowrap !normal-case">
                {option.label}
              </Text>
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}

type RichTextEditorMenuBarProps = {
  toolbarClassName?: string;
  customButtons?: ReactNode;
};

export default function RichTextEditorMenuBar({ toolbarClassName, customButtons }: RichTextEditorMenuBarProps) {
  const { editor } = useCurrentEditor();

  const setLink = useCallback(() => {
    if (!editor) return;

    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) return;

    if (url === '') {
      editor.chain().focus().unsetLink().run();
      return;
    }

    editor.chain().focus().setLink({ href: url }).run();
  }, [editor]);

  const textStyleOptions = useMemo(() => {
    if (!editor) return [];

    return TEXT_STYLING_OPTIONS.map((option) => ({
      ...option,
      onClick: () => option.onClick(editor),
    }));
  }, [editor]);

  const alignmentOptions = useMemo(() => {
    if (!editor) return [];

    return ALIGNMENT_OPTIONS.map((option) => ({
      ...option,
      onClick: () => option.onClick(editor),
    }));
  }, [editor]);

  const getActiveTextStyle = useCallback(() => {
    if (!editor) return DEFAULT_TEXT_STYLING_LABEL;

    const activeStyle = Object.values(TEXT_STYLING_NODE_TYPES).find((style) =>
      style.attrs ? editor.isActive(style.type, style.attrs) : editor.isActive(style.type)
    );

    return activeStyle?.label || DEFAULT_TEXT_STYLING_LABEL;
  }, [editor]);

  const getActiveAlignment = useCallback(() => {
    if (!editor) return DEFAULT_ALIGNMENT_OPTION_ICON;

    const activeAlignment = Object.values(ALIGNMENT_NODE_TYPES).find((alignment) =>
      editor.isActive({ textAlign: alignment.type })
    );

    return activeAlignment?.icon || DEFAULT_ALIGNMENT_OPTION_ICON;
  }, [editor]);

  const checkTextStyleActive = useCallback(
    (option: Option) => {
      if (!editor) return false;

      const selectedStyle = Object.values(TEXT_STYLING_NODE_TYPES).find((node) => node.label === option.label);

      if (selectedStyle) {
        return selectedStyle.attrs
          ? editor.isActive(selectedStyle.type, selectedStyle.attrs)
          : editor.isActive(selectedStyle.type);
      }

      return false;
    },
    [editor]
  );

  const checkAlignmentActive = useCallback(
    (option: Option) => {
      if (!editor) return false;

      const selectedAlignment = Object.values(ALIGNMENT_NODE_TYPES).find(
        (alignment) => alignment.label === option.label
      );

      return selectedAlignment ? editor.isActive({ textAlign: selectedAlignment.type }) : false;
    },
    [editor]
  );

  if (!editor) {
    return null;
  }

  return (
    <div className={cn('sticky top-0 z-10 mb-10 flex w-full justify-center bg-white p-2', toolbarClassName)}>
      <div className="flex items-center gap-1">
        {/* Undo and Redo controls */}
        <div className="flex items-center gap-2">
          <Button
            variant={buttons.variant.tertiary}
            className={cn('p-2', !editor.can().undo() && 'opacity-50 cursor-not-allowed')}
            disabled={!editor.can().undo()}
            onClick={() => editor.chain().focus().undo().run()}
          >
            <ArrowTurnLeft />
          </Button>
          <Button
            variant={buttons.variant.tertiary}
            className={cn('p-2', !editor.can().redo() && 'opacity-50 cursor-not-allowed')}
            disabled={!editor.can().redo()}
            onClick={() => editor.chain().focus().redo().run()}
          >
            <ArrowTurnRight />
          </Button>
        </div>

        <div className="mx-2 h-6 w-px bg-gray-200" />

        {/* Text style dropdown */}
        <MenuBarDropdown
          label={getActiveTextStyle()}
          options={textStyleOptions}
          isActive={checkTextStyleActive}
          activeIcon={null}
        />

        <div className="mx-2 h-6 w-px bg-gray-200" />

        {/* Text formatting controls */}
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('bold') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faBold} className="size-4" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('italic') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faItalic} className="size-4" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('underline') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faUnderline} className="size-4" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('strike') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faStrikethrough} className="size-4" />
        </Button>
        <Button
          onClick={setLink}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('link') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faLink} className="size-4" />
        </Button>

        <div className="mx-2 h-6 w-px bg-gray-200" />

        {/* List controls */}
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('bulletList') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faListUl} className="size-4" />
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={cn(
            'flex h-8 w-max min-w-8 cursor-pointer items-center justify-center rounded border-none bg-transparent px-2 text-base text-slate-500 transition-all duration-200 hover:bg-slate-100 hover:text-slate-900',
            editor.isActive('orderedList') ? 'bg-slate-100 text-slate-900' : ''
          )}
        >
          <FontAwesomeIcon icon={faListOl} className="size-4" />
        </Button>

        <div className="mx-2 h-6 w-px bg-gray-200" />

        {/* Alignment dropdown */}
        <MenuBarDropdown
          label="Alignment"
          options={alignmentOptions}
          isActive={checkAlignmentActive}
          activeIcon={getActiveAlignment()}
        />

        {/* Custom buttons section */}
        {customButtons && (
          <>
            <div className="mx-2 h-6 w-px bg-gray-200" />
            {customButtons}
          </>
        )}
      </div>

      {/* Divider */}
      <div
        className="absolute bottom-0 h-px w-full"
        style={{
          background: 'linear-gradient(to right, transparent, #EBEBEB 10%, #D2D2D2 50%, #EBEBEB 90%, transparent)',
        }}
      />
    </div>
  );
}
