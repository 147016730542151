import React, { useContext } from 'react';

import { TableContext } from './Table';
import Text from '../Text/Text';

type ThProps = React.HTMLAttributes<HTMLTableCellElement> & {
  field?: string;
  extra?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  sortable?: boolean;
};

export const Th = ({ field, extra = null, children, className = '', sortable = false, ...props }: ThProps) => {
  const { styles, ...context } = useContext(TableContext);
  const isSortable = sortable || context.sortable;
  const [sortField, sortOrder] = context.sortBy?.split(' ');
  const sorted = sortField === field;

  const handleClick = () => {
    if (field === 'checkbox') return;
    if (isSortable) context.updateSorting(field);
  };

  const renderSortingIndicator = () => {
    let indicator = null;
    if (isSortable && sorted) indicator = sortOrder === 'desc' ? '↓' : '↑';
    return <span className={styles.indicator}>{indicator}</span>;
  };

  const renderContents = () => {
    const contents = React.Children.toArray(children).map((child) => {
      if (typeof child === 'string') return <Text key={child}>{child}</Text>;
      return child;
    });

    const classes = [styles.contents, sorted && styles.sorted].join(' ');

    return (
      <div className={classes} onClick={handleClick} role="none">
        {contents} {renderSortingIndicator()}
      </div>
    );
  };

  const renderExtra = () => {
    return extra ? <div className={styles.extra}>{extra}</div> : null;
  };

  const classes = [styles.container, isSortable && styles.sortable, className].filter(Boolean).join(' ');

  return (
    <th {...props} className={className}>
      <div className={classes}>
        {renderContents()}
        {renderExtra()}
      </div>
    </th>
  );
};
