import { COUNTRIES } from '@pray/shared/constants';

export const amountTypes = {
  default: 'default',
  custom: 'custom',
};

export const amountTiers = {
  tier1: { value: 'tier1', label: 'Gracious Giver' },
  tier2: { value: 'tier2', label: 'Giver' },
};

export const donationIntervals = {
  month: 'month',
  year: 'year',
};

export const donationTypes = {
  subscription: 'subscription',
  oneTime: 'one-time',
};

export const donationIntents = {
  paywall: 'paywall',
};

export const intervalAbbr = {
  day: 'day',
  week: 'wk',
  month: 'mo',
  year: 'yr',
};

export const prayerExperienceUserKey = 'onboarding-prayer_experience_level';

export const prayerExperinceOptions = [
  { value: 'new_to_prayer', label: 'I’m new to prayer' },
  { value: 'returning_to_prayer', label: 'I’m returning to prayer' },
  { value: 'pray_regularly', label: 'I pray regularly' },
  { value: 'never_miss_a_day_of_prayer', label: 'I never miss a day of prayer' },
];

export const [USA] = COUNTRIES;

export const defaultAmountType = amountTypes.default;
export const defaultAmountTier = amountTiers.tier1;
export const defaultInterval = donationIntervals.month;
export const defaultIntervalCount = 1;
export const defaultType = donationTypes.subscription;
export const defaultCountry = USA;
export const defaultPrayerExperienceOption = prayerExperinceOptions[0].value;

export const minMonthlyDonationAmount = 500;
export const minAnnuallyDonationAmount = 5000;
