import api from '@pray/shared/utils/api';

export const getLocalizedContentDetails = async ({ artistId = null, contentId = null, locale = null }) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale code is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
