import api from '@pray/shared/utils/api';

type RenameChapterProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
  title?: string;
};

export const renameChapter = async ({ artistId, bookId, chapterId, title }: RenameChapterProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!chapterId) throw Error('Chapter ID is required');
  if (!title) throw Error('Title is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters/${chapterId}`;

  const data = {
    title,
  };

  await api.executePatchRequest(url, data);
};
