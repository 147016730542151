export default function HiddenIcon(props) {
  const { stroke = '#1F2024', className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.4 17.4C15.846 18.5845 13.9537 19.2408 12 19.2727C5.63636 19.2727 2 12 2 12C3.13081 9.89263 4.69921 8.05146 6.6 6.6M10.0909 4.94545C10.7167 4.79898 11.3573 4.72576 12 4.72727C18.3636 4.72727 22 12 22 12C21.4482 13.0324 20.7901 14.0043 20.0364 14.9M13.9273 13.9273C13.6776 14.1952 13.3765 14.4101 13.042 14.5592C12.7074 14.7083 12.3463 14.7884 11.9801 14.7949C11.6139 14.8013 11.2501 14.734 10.9106 14.5968C10.571 14.4596 10.2625 14.2555 10.0035 13.9965C9.74452 13.7375 9.54036 13.429 9.40319 13.0894C9.26602 12.7499 9.19866 12.3861 9.20512 12.0199C9.21158 11.6537 9.29174 11.2926 9.4408 10.958C9.58986 10.6235 9.80478 10.3224 10.0727 10.0727M2 2L22 22"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
