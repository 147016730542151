export const InstagramIcon = (props) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.0455 0.5H20.9545C9.65777 0.5 0.5 9.65777 0.5 20.9545V21.0455C0.5 32.3422 9.65777 41.5 20.9545 41.5H21.0455C32.3422 41.5 41.5 32.3422 41.5 21.0455V20.9545C41.5 9.65777 32.3422 0.5 21.0455 0.5Z"
        fill="url(#paint0_linear_2320_10261)"
        stroke="white"
      />
      <path
        d="M27.1857 9.13281H14.8086C11.3892 9.13281 8.60742 11.9146 8.60742 15.334V26.6572C8.60742 30.0767 11.3892 32.8584 14.8086 32.8584H27.1857C30.6051 32.8584 33.3869 30.0767 33.3869 26.6572V15.334C33.3869 11.9146 30.6051 9.13281 27.1857 9.13281ZM10.795 15.334C10.795 13.1212 12.5958 11.3204 14.8086 11.3204H27.1857C29.3985 11.3204 31.1993 13.1212 31.1993 15.334V26.6572C31.1993 28.87 29.3985 30.6708 27.1857 30.6708H14.8086C12.5958 30.6708 10.795 28.87 10.795 26.6572V15.334Z"
        fill="white"
      />
      <path
        d="M20.9967 26.7628C24.1764 26.7628 26.7648 24.1759 26.7648 20.9947C26.7648 17.8135 24.1778 15.2266 20.9967 15.2266C17.8155 15.2266 15.2285 17.8135 15.2285 20.9947C15.2285 24.1759 17.8155 26.7628 20.9967 26.7628ZM20.9967 17.4155C22.9712 17.4155 24.5772 19.0215 24.5772 20.9961C24.5772 22.9707 22.9712 24.5767 20.9967 24.5767C19.0221 24.5767 17.4161 22.9707 17.4161 20.9961C17.4161 19.0215 19.0221 17.4155 20.9967 17.4155Z"
        fill="white"
      />
      <path
        d="M27.2973 16.163C28.1536 16.163 28.8515 15.4665 28.8515 14.6088C28.8515 13.7512 28.155 13.0547 27.2973 13.0547C26.4397 13.0547 25.7432 13.7512 25.7432 14.6088C25.7432 15.4665 26.4397 16.163 27.2973 16.163Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2320_10261"
          x1="6.84381"
          y1="35.1562"
          x2="35.1562"
          y2="6.84522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAAD4F" />
          <stop offset="0.35" stopColor="#DD2A7B" />
          <stop offset="0.62" stopColor="#9537B0" />
          <stop offset="1" stopColor="#515BD4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const FacebookIcon = (props) => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.0455 1.25H21.9545C10.5199 1.25 1.25 10.5169 1.25 21.9486V22.0396C1.25 33.4714 10.5199 42.7382 21.9545 42.7382H22.0455C33.4801 42.7382 42.75 33.4714 42.75 22.0396V21.9486C42.75 10.5169 33.4801 1.25 22.0455 1.25Z"
        fill="url(#paint0_linear_2320_10280)"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M28.1857 10.1328H15.8086C12.3892 10.1328 9.60742 12.9138 9.60742 16.3322V27.6521C9.60742 31.0705 12.3892 33.8514 15.8086 33.8514H28.1857C31.6051 33.8514 34.3869 31.0705 34.3869 27.6521V16.3322C34.3869 12.9138 31.6051 10.1328 28.1857 10.1328ZM11.795 16.3322C11.795 14.12 13.5958 12.3197 15.8086 12.3197H28.1857C30.3985 12.3197 32.1993 14.12 32.1993 16.3322V27.6521C32.1993 29.8642 30.3985 31.6645 28.1857 31.6645H15.8086C13.5958 31.6645 11.795 29.8642 11.795 27.6521V16.3322Z"
        fill="white"
      />
      <path
        d="M21.9967 27.7594C25.1764 27.7594 27.7648 25.1732 27.7648 21.993C27.7648 18.8128 25.1778 16.2266 21.9967 16.2266C18.8155 16.2266 16.2285 18.8128 16.2285 21.993C16.2285 25.1732 18.8155 27.7594 21.9967 27.7594ZM21.9967 18.4149C23.9712 18.4149 25.5772 20.0204 25.5772 21.9944C25.5772 23.9684 23.9712 25.5739 21.9967 25.5739C20.0221 25.5739 18.4161 23.9684 18.4161 21.9944C18.4161 20.0204 20.0221 18.4149 21.9967 18.4149Z"
        fill="white"
      />
      <path
        d="M28.2963 17.1621C29.1526 17.1621 29.8505 16.4658 29.8505 15.6084C29.8505 14.751 29.154 14.0547 28.2963 14.0547C27.4387 14.0547 26.7422 14.751 26.7422 15.6084C26.7422 16.4658 27.4387 17.1621 28.2963 17.1621Z"
        fill="white"
      />
      <path
        d="M24.854 42.3033L24.8543 42.3032C34.825 40.9141 42.5 32.3549 42.5 22.0007C42.5 10.6787 33.3198 1.5 21.9993 1.5C10.6787 1.5 1.5 10.6788 1.5 22.0007C1.5 32.2041 8.95521 40.667 18.7175 42.2386C19.7865 42.411 20.8838 42.5 22.0007 42.5C22.9685 42.5 23.922 42.4325 24.854 42.3033Z"
        fill="#1877F2"
        stroke="white"
      />
      <path
        d="M24.7851 18.0573V22.4142H30.1748L29.3214 28.2833H24.7851V41.8053C23.8756 41.9314 22.945 41.9973 22.0005 41.9973C20.9102 41.9973 19.8396 41.9104 18.7969 41.7422V28.2833H13.8262V22.4142H18.7969V17.0833C18.7969 13.776 21.4778 11.0938 24.7865 11.0938V11.0966C24.7963 11.0966 24.8047 11.0938 24.8145 11.0938H30.1762V16.1696H26.6728C25.6315 16.1696 24.7865 17.0146 24.7865 18.0559L24.7851 18.0573Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2320_10280"
          x1="7.84381"
          y1="36.1461"
          x2="36.1478"
          y2="7.83516"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAAD4F" />
          <stop offset="0.35" stopColor="#DD2A7B" />
          <stop offset="0.62" stopColor="#9537B0" />
          <stop offset="1" stopColor="#515BD4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const YoutubeIcon = (props) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.854 41.3033L23.8543 41.3032C33.825 39.9141 41.5 31.3549 41.5 21.0007C41.5 9.67875 32.3198 0.5 20.9993 0.5C9.67875 0.5 0.5 9.67879 0.5 21.0007C0.5 31.2041 7.95521 39.667 17.7175 41.2386C18.7865 41.411 19.8838 41.5 21.0007 41.5C21.9685 41.5 22.922 41.4325 23.854 41.3033Z"
        fill="#FF0302"
        stroke="white"
      />
      <g clipPath="url(#clip0_2320_10304)">
        <path
          d="M32.5221 15.1859C32.3864 14.6753 32.119 14.2093 31.7466 13.8346C31.3743 13.4598 30.91 13.1894 30.4003 13.0504C28.5239 12.5459 21.0239 12.5459 21.0239 12.5459C21.0239 12.5459 13.5239 12.5459 11.6476 13.0504C11.1379 13.1894 10.6736 13.4598 10.3012 13.8346C9.92884 14.2093 9.66143 14.6753 9.52574 15.1859C9.02393 17.0704 9.02393 21.0004 9.02393 21.0004C9.02393 21.0004 9.02393 24.9304 9.52574 26.815C9.66143 27.3256 9.92884 27.7916 10.3012 28.1663C10.6736 28.5411 11.1379 28.8115 11.6476 28.9504C13.5239 29.455 21.0239 29.455 21.0239 29.455C21.0239 29.455 28.5239 29.455 30.4003 28.9504C30.91 28.8115 31.3743 28.5411 31.7466 28.1663C32.119 27.7916 32.3864 27.3256 32.5221 26.815C33.0239 24.9304 33.0239 21.0004 33.0239 21.0004C33.0239 21.0004 33.0239 17.0704 32.5221 15.1859Z"
          fill="white"
        />
        <path d="M18.5693 24.5689V17.4316L24.8421 21.0003L18.5693 24.5689Z" fill="#FF0302" />
      </g>
      <defs>
        <clipPath id="clip0_2320_10304">
          <rect width="24" height="24" fill="white" transform="translate(9 9)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const XIcon = (props) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.0455 0.5H20.9545C9.65777 0.5 0.5 9.65777 0.5 20.9545V21.0455C0.5 32.3422 9.65777 41.5 20.9545 41.5H21.0455C32.3422 41.5 41.5 32.3422 41.5 21.0455V20.9545C41.5 9.65777 32.3422 0.5 21.0455 0.5Z"
        fill="url(#paint0_linear_2320_10331)"
        stroke="white"
      />
      <path
        d="M27.1857 9.13281H14.8086C11.3892 9.13281 8.60742 11.9146 8.60742 15.334V26.6572C8.60742 30.0767 11.3892 32.8584 14.8086 32.8584H27.1857C30.6051 32.8584 33.3869 30.0767 33.3869 26.6572V15.334C33.3869 11.9146 30.6051 9.13281 27.1857 9.13281ZM10.795 15.334C10.795 13.1212 12.5958 11.3204 14.8086 11.3204H27.1857C29.3985 11.3204 31.1993 13.1212 31.1993 15.334V26.6572C31.1993 28.87 29.3985 30.6708 27.1857 30.6708H14.8086C12.5958 30.6708 10.795 28.87 10.795 26.6572V15.334Z"
        fill="white"
      />
      <path
        d="M20.9967 26.7628C24.1764 26.7628 26.7648 24.1759 26.7648 20.9947C26.7648 17.8135 24.1778 15.2266 20.9967 15.2266C17.8155 15.2266 15.2285 17.8135 15.2285 20.9947C15.2285 24.1759 17.8155 26.7628 20.9967 26.7628ZM20.9967 17.4155C22.9712 17.4155 24.5772 19.0215 24.5772 20.9961C24.5772 22.9707 22.9712 24.5767 20.9967 24.5767C19.0221 24.5767 17.4161 22.9707 17.4161 20.9961C17.4161 19.0215 19.0221 17.4155 20.9967 17.4155Z"
        fill="white"
      />
      <path
        d="M27.2973 16.163C28.1536 16.163 28.8515 15.4665 28.8515 14.6088C28.8515 13.7512 28.155 13.0547 27.2973 13.0547C26.4397 13.0547 25.7432 13.7512 25.7432 14.6088C25.7432 15.4665 26.4397 16.163 27.2973 16.163Z"
        fill="white"
      />
      <path
        d="M23.8518 41.3033L23.8521 41.3032C33.6615 39.9366 41.2488 31.6294 41.4912 21.5007H41.5V21.0007C41.5 9.67879 32.3216 0.5 21 0.5C9.67844 0.5 0.5 9.67879 0.5 21.0007C0.5 31.2041 7.95492 39.667 17.7155 41.2386C18.7845 41.411 19.8818 41.5 20.9986 41.5C21.9663 41.5 22.9198 41.4325 23.8518 41.3033Z"
        fill="#1C1C1B"
        stroke="white"
      />
      <path
        d="M9.11019 9.82031L18.3338 22.1526L9.05273 32.1796H11.1421L19.2685 23.4012L25.8338 32.1796H32.9429L23.2007 19.1536L31.84 9.82031H29.7506L22.2674 17.905L16.2206 9.82031H9.11158H9.11019ZM12.1819 11.359H15.4471L29.8683 30.6408H26.6032L12.1819 11.359Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2320_10331"
          x1="6.84381"
          y1="35.1562"
          x2="35.1562"
          y2="6.84522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAAD4F" />
          <stop offset="0.35" stopColor="#DD2A7B" />
          <stop offset="0.62" stopColor="#9537B0" />
          <stop offset="1" stopColor="#515BD4" />
        </linearGradient>
      </defs>
    </svg>
  );
};
