import React, { useState } from 'react';
import Cropper from 'react-easy-crop';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import { dataUrlToFileUsingFetch } from '@pray/shared/utils/media';

import { getCroppedImg } from './image-crop-utils';

import styles from './ImageCropModal.module.scss';

export default function ImageCropModal({
  file = null,
  imageUrl = '',
  zoom = 1,
  aspect = 1,
  cropShape = 'rect',
  onConfirm = null,
  onClose = null,
}) {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoomLevel, setZoomLevel] = useState(zoom ?? 1);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    const { width, height } = croppedAreaPixels;
    const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
    setCroppedImage(croppedImage);
    setSize({ width, height });
  };

  const handleDoneClick = async () => {
    const uploadedFile = file;
    const croppedFile = await dataUrlToFileUsingFetch(croppedImage, uploadedFile.name, uploadedFile.type);
    onConfirm?.(croppedFile);
  };

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>Image Editor</Text>

      <div className={styles.cropper}>
        <Cropper
          showGrid
          image={imageUrl}
          crop={crop}
          zoom={zoomLevel}
          aspect={aspect}
          cropShape={cropShape === 'rect' ? 'rect' : 'round'}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoomLevel}
        />
      </div>

      <div className="flex flex-col text-center">
        <Text variant={typography.body_small}>Drag the image to recenter</Text>
        <Text variant={typography.caption_small}>
          {size.width} x {size.height}
        </Text>
      </div>

      <div className={styles.controls}>
        <div className="flex flex-col space-y-4">
          <Text>Zoom</Text>
          <input
            type="range"
            value={zoomLevel}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            className={styles.zoomRange}
            onChange={(event) => setZoomLevel(+event.target.value)}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        <Button variant={buttons.variant.secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={buttons.variant.primary} onClick={handleDoneClick}>
          Done
        </Button>
      </div>
    </Modal>
  );
}
