import api from '@pray/shared/utils/api';

const LEAD_INTEGRATION_TYPE_STUDIO_AUTOMATION = 'STUDIO_AUTOMATION';

export async function createLeadClient({ name, leadIntegrationType, leadIntegrationDetails, artistId }) {
  if (!name) throw Error('Lead Client name is required');
  if (!leadIntegrationType) throw Error('Lead Client integration is required');
  if (!leadIntegrationDetails) throw Error('Lead Client integration details is required');
  if (!artistId) throw Error('Lead Client associated artist is required');

  const data = {
    name,
    lead_integration_type: leadIntegrationType,
    lead_integration_details: leadIntegrationDetails,
    artist_id: artistId,
  };

  // always pass null as lead_integration_details value if lead_integration_type = STUDIO_AUTOMATION
  if (leadIntegrationType === LEAD_INTEGRATION_TYPE_STUDIO_AUTOMATION) data.lead_integration_details = null;

  const url = `/web/studio/lead-clients`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
