import React from 'react';

import { TemplatePreviewMobileButton } from './TemplatePreviewMobileButton';
import { TemplatePreviewMobileNotch } from './TemplatePreviewMobileNotch';

export function TemplatePreviewMobile({ children }) {
  return (
    <div className="relative flex h-[698px] w-[344px] flex-col overflow-hidden rounded-3xl border-2 border-[#DEDFE3] bg-white">
      <TemplatePreviewMobileNotch />
      {children}
      <TemplatePreviewMobileButton />
    </div>
  );
}
