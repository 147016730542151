import api from '@pray/shared/utils/api';
import { ObjectResponse } from '@pray/shared/utils/api/types/response';

/* eslint-disable camelcase */
export type TemplateVoicesResponse = {
  voices: {
    id: string;
    name: string;
    audio_url: string;
  }[];
};
/* eslint-enable camelcase */

export async function getTemplateVoices(): Promise<ObjectResponse<TemplateVoicesResponse>> {
  const url = `/web/studio/voices`;
  const response = await api.executeGetRequest<ObjectResponse<TemplateVoicesResponse>>(url);

  return response.data;
}
