import { useCallback, useEffect, useMemo, useState } from 'react';

import s3Service from '@pray/shared/services/s3Service';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';

import { useToastMessage } from '../../../../components/StudioToastMessage/StudioToastMessage';
import { DialogTypes, SampleUploadStatus, VoiceSteps } from '../constants';
import { useVoiceDialogs } from './useVoiceDialogs';
import { useVoicePlayer } from './useVoicePlayer';
import useVoiceService from './useVoiceService';

export default function useVoiceSettings() {
  const { selectedArtist } = useStudioContext();
  const [uploadedSamples, setUploadedSamples] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(VoiceSteps.LOADING);
  const { isPlayingId, handlePlayClick, handlePauseClick, audioRef } = useVoicePlayer();
  const toast = useToastMessage();

  const {
    selectedVoiceId,
    isDeleteDialogOpen,
    isEditDialogOpen,
    isSetAsMainVoiceDialogOpen,
    isVoiceUploadDialogOpen,
    handleDialogOpen,
    handleDialogClose,
  } = useVoiceDialogs();

  const {
    deleteVoice,
    setMainVoice,
    createVoice,
    updateVoice,
    voices: voicesQuery,
  } = useVoiceService(selectedArtist.id);

  // Replace the manual voices state with voicesQuery data
  const voices = voicesQuery.data || [];

  // Update the useEffect to handle loading states based on query
  useEffect(() => {
    if (voicesQuery.isLoading) {
      setStep(VoiceSteps.LOADING);
    } else {
      setStep(voices.length ? VoiceSteps.VOICES : VoiceSteps.EMPTY);
    }

    return () => {
      if (audioRef?.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [audioRef, voices, voicesQuery.isLoading]);

  const handleDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteVoice({ artistId: selectedArtist.id, voiceId: selectedVoiceId });

      handleDialogClose(DialogTypes.DELETE);
    } finally {
      setIsLoading(false);
    }
  }, [selectedVoiceId, deleteVoice, handleDialogClose]);

  const handleEditConfirm = useCallback(
    async (name) => {
      setIsLoading(true);
      try {
        await updateVoice({ artistId: selectedArtist.id, voiceId: selectedVoiceId, name });

        handleDialogClose(DialogTypes.EDIT);
      } finally {
        setIsLoading(false);
      }
    },
    [selectedVoiceId, updateVoice, handleDialogClose]
  );

  const handleSetAsMainVoiceConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await setMainVoice({ artistId: selectedArtist.id, voiceId: selectedVoiceId });

      handleDialogClose(DialogTypes.SET_MAIN);
    } finally {
      setIsLoading(false);
    }
  }, [selectedVoiceId, setMainVoice, handleDialogClose]);

  const handleCreateVoice = useCallback(
    async (name) => {
      setIsLoading(true);
      try {
        const samples = uploadedSamples.filter((voice) => voice.status === SampleUploadStatus.SUCCESS);

        const voicesUrls = await Promise.all(
          samples.map((sample) =>
            s3Service.signAndUpload({
              file: sample.file,
              type: 'voices',
              fileName: crypto.randomUUID(),
              signParams: {
                artist_id: selectedArtist.id,
              },
            })
          )
        ).catch((err) => {
          logger.error('Failed to upload voice samples', err);
          toast.show({ error: 'Failed to upload voice samples' });
          return [];
        });

        // Extract just the url strings from each object. The response from signAndUpload is now an object with url and rsaPublicKey.
        // Previous it was just a string contain the url.
        const voiceUrlStrings = voicesUrls?.map((item) => item.url);

        await createVoice({ artistId: selectedArtist.id, name, samplesUrls: voiceUrlStrings });

        setUploadedSamples([]);
        handleDialogClose(DialogTypes.UPLOAD);
      } finally {
        setIsLoading(false);
      }
    },
    [uploadedSamples, createVoice, handleDialogClose]
  );

  const handleAcceptedFiles = useCallback((files) => {
    const newSamples = files.map((file) => ({
      id: crypto.randomUUID(),
      name: file.name,
      audioUrl: URL.createObjectURL(file),
      status: SampleUploadStatus.SUCCESS,
      file,
    }));

    setUploadedSamples((prev) => {
      const uniqueNewSamples = newSamples.filter(
        (newSample) => !prev.some((existingSample) => existingSample.name === newSample.name)
      );
      return [...prev, ...uniqueNewSamples];
    });
  }, []);

  const handleRejectedFiles = useCallback((rejectedFiles) => {
    // TODO: Implement API call
    // Upload each file, update the status and get the audio URL
    const rejectedSamples = rejectedFiles.map((rejected) => ({
      id: crypto.randomUUID(),
      name: rejected.file.name,
      audioUrl: null,
      status: SampleUploadStatus.ERROR,
      errorMessage: 'File format not supported',
    }));

    setUploadedSamples((prev) => [...prev, ...rejectedSamples]);
  }, []);

  const handleDeleteSample = useCallback((id) => {
    setUploadedSamples((prev) => prev.filter((voice) => voice.id !== id));
  }, []);

  const handleCreateVoiceClick = useCallback(() => handleDialogOpen(null, DialogTypes.UPLOAD), [handleDialogOpen]);

  const handleEditClick = useCallback((id) => handleDialogOpen(id, DialogTypes.EDIT), [handleDialogOpen]);

  const handleDeleteClick = useCallback((id) => handleDialogOpen(id, DialogTypes.DELETE), [handleDialogOpen]);

  const handleSetAsMainVoiceClick = useCallback((id) => handleDialogOpen(id, DialogTypes.SET_MAIN), [handleDialogOpen]);

  const handleDeleteCancel = useCallback(() => handleDialogClose(DialogTypes.DELETE), [handleDialogClose]);

  const handleEditCancel = useCallback(() => handleDialogClose(DialogTypes.EDIT), [handleDialogClose]);

  const handleSetAsMainVoiceCancel = useCallback(() => handleDialogClose(DialogTypes.SET_MAIN), [handleDialogClose]);

  const handleUploadCancel = useCallback(() => {
    handleDialogClose(DialogTypes.UPLOAD);
    setUploadedSamples([]);
  }, [handleDialogClose]);

  const value = useMemo(
    () => ({
      step,
      voices,
      uploadedSamples,
      isLoading,
      isPlayingId,
      isDeleteDialogOpen,
      isEditDialogOpen,
      isSetAsMainVoiceDialogOpen,
      isVoiceUploadDialogOpen,
      selectedVoiceName: voices.find((voice) => voice.id === selectedVoiceId)?.name,
      handlePlayClick: (id, audioUrl) => handlePlayClick(id, audioUrl),
      handlePauseClick,
      handleCreateVoiceClick,
      handleEditClick,
      handleDeleteClick,
      handleSetAsMainVoiceClick,
      handleDeleteCancel,
      handleEditCancel,
      handleSetAsMainVoiceCancel,
      handleUploadCancel,
      handleDeleteConfirm,
      handleSetAsMainVoiceConfirm,
      handleEditConfirm,
      handleDeleteSample,
      handleAcceptedFiles,
      handleRejectedFiles,
      handleCreateVoice,
    }),
    [
      step,
      voices,
      uploadedSamples,
      isLoading,
      isPlayingId,
      isDeleteDialogOpen,
      isEditDialogOpen,
      isSetAsMainVoiceDialogOpen,
      isVoiceUploadDialogOpen,
      selectedVoiceId,
      handlePlayClick,
      handlePauseClick,
      handleCreateVoiceClick,
      handleEditClick,
      handleDeleteClick,
      handleSetAsMainVoiceClick,
      handleDeleteCancel,
      handleEditCancel,
      handleSetAsMainVoiceCancel,
      handleUploadCancel,
      handleDeleteConfirm,
      handleSetAsMainVoiceConfirm,
      handleEditConfirm,
      handleDeleteSample,
      handleAcceptedFiles,
      handleRejectedFiles,
      handleCreateVoice,
    ]
  );

  return value;
}
