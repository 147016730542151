import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useDownloadArticle({ content }) {
  const toast = useToastMessage();

  const downloadArticle = (article) => {
    try {
      const blob = new Blob([article], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${(content && content.title) || 'article'}.html`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      toast.show({ success: 'Article downloaded successfully' });
    } catch (err) {
      toast.show({ error: 'Failed to download article' });
    }
  };

  return {
    downloadArticle,
  };
}
