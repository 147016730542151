/**
 * Download a file from an array buffer
 * @param {ArrayBuffer|Blob} data - The file contents to download
 * @param {string} name - The name of the file to download
 */
export function downloadFileFromBlob(data, name) {
  // Start downloading file
  const blob = new Blob([data]);
  const localUrl = window.URL.createObjectURL(blob);

  // Download file from URL
  downloadFileFromUrl(localUrl, name);

  // Cleanup
  window.URL.revokeObjectURL(localUrl);
}

/**
 * Download a file from a URL
 * @param {string} url - The URL to download
 * @param {string} name - The name of the file to download
 */
export function downloadFileFromUrl(url, name = '') {
  // Create a temporary link element
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  document.body.removeChild(link);
}
