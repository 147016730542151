import React from 'react';

export function Error(props) {
  const { fill = 'white', className, ...rest } = props;
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.00001 9.41423L5.17158 12.2427C4.78106 12.6332 4.1479 12.6332 3.75737 12.2427C3.36685 11.8521 3.36685 11.219 3.75737 10.8284L6.5858 8.00001L3.75737 5.17158C3.36685 4.78106 3.36685 4.1479 3.75737 3.75737C4.1479 3.36685 4.78106 3.36685 5.17158 3.75737L8.00001 6.5858L10.8284 3.75737C11.219 3.36685 11.8521 3.36685 12.2427 3.75737C12.6332 4.1479 12.6332 4.78106 12.2427 5.17158L9.41423 8.00001L12.2427 10.8284C12.6332 11.219 12.6332 11.8521 12.2427 12.2427C11.8521 12.6332 11.219 12.6332 10.8284 12.2427L8.00001 9.41423Z"
        fill={fill}
      />
    </svg>
  );
}
