import api from '@pray/shared/utils/api';

export const updateSocialClip = async ({
  artistId,
  contentId,
  socialClipId,
  title = null,
  description = null,
  platforms = [],
  publishedAt = null,
}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!socialClipId) throw Error('Social Clip ID is required');

  const params = {
    title,
    description,
    platforms,
    ...(publishedAt ? { published_at: publishedAt } : {}),
  };

  const url = `/web/studio/artists/${artistId}/content/${contentId}/social-clips/${socialClipId}`;
  await api.executePutRequest(url, params);
};
