import { useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapBooks } from '@pray/shared/services/studioService/books';

export function useBooks(artistId: string) {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status, error, refetch } = useInfiniteQuery({
    queryKey: queryKeys.artistBooks(artistId),
    queryFn: ({ pageParam }) => {
      return studioService.books.getBooks({
        artistId,
        lastItemIdentifier: pageParam,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      return lastPage.last_item_identifier;
    },
    enabled: !!artistId,
  });

  const books = data?.pages?.flatMap((page) => mapBooks(page.data)) || [];

  return {
    books,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: status === 'pending',
    isError: status === 'error',
    error,
    refetch,
  };
}
