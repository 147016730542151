import Text from '@pray/shared/components/ui/Text';

import WarningIcon from '../../assets/WarningIcon';
import { MAPPING_BANNER_COPY } from '../../constants';
import { getFieldText, getMapText } from '../../utils/utils';

export default function MappingBanner({ count, hasRequiredField }) {
  const fieldText = getFieldText(count);
  const mapText = getMapText(count);

  return (
    <div className="flex flex-col gap-1">
      {count > 0 && (
        <div className="flex items-center gap-4 rounded-xl bg-[#F7C1B580] p-3.5">
          <div>
            <WarningIcon className="shrink-0" />
          </div>
          <Text className="text-base text-[#441803]">
            {count} {fieldText} unmatched. {mapText}
          </Text>
        </div>
      )}

      {!hasRequiredField && (
        <div className="flex items-center gap-4 rounded-xl bg-[#F7C1B580] p-3.5">
          <div>
            <WarningIcon className="shrink-0" />
          </div>
          <Text className="text-base text-[#441803]">{MAPPING_BANNER_COPY.REQUIRED_FIELDS}</Text>
        </div>
      )}
    </div>
  );
}
