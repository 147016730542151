import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';

export const StatCard = ({ value = '', label = '', info = '', className = '', ...props }) => {
  return (
    <div
      className={`flex max-w-[193px] flex-1 flex-col items-center rounded-xl border border-[#CBCDD2] p-6 ${className}`}
      {...props}
    >
      <Text className="text-[28px] font-bold">{value}</Text>
      <div className="flex items-center gap-1 text-[#56585E]">
        <Text className="font-medium">{label}</Text>
        <Popover title={label} description={info}>
          <Info className="size-4" />
        </Popover>
      </div>
    </div>
  );
};
