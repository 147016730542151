import { useEffect, useRef } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Dropzone, { DropzoneInfo } from '@pray/shared/components/ui/Dropzone/Dropzone';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import Text from '@pray/shared/components/ui/Text/Text';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import MessageModal from 'components/pages/StudioPage/components/MessageModal/MessageModal';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { useStudioContext } from 'context/StudioContext';
import { Playlist, PlaylistRemove, Trash } from 'images/icons';

import ContentTable from './ContentTable/ContentTable';
import ContentTableSkeleton from './ContentTableSkeleton/ContentTableSkeleton';
import SearchPlaylistModal from './SearchPlaylistModal/SearchPlaylistModal';
import useContentListPage from './useContentListPage';
import ActionButton from '../../../components/ActionButton/ActionButton';

import styles from './ContentList.module.scss';

export default function ContentListPage({ mediaType, setTopRightButtons }) {
  const dropzoneRef = useRef(null);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const {
    acceptedFileTypes,
    contentList,
    episodes,
    isAddToPlaylistModalOpen,
    isDeleteModalOpen,
    isMessageModalOpen,
    isRemoveFromPlaylistModalOpen,
    isSaving,
    isShowDropzone,
    selectionDetails,
    handleAcceptedFiles,
    handleAddContentToPlaylist,
    handleDeleteContent,
    handleItemClick,
    handleRejectedFiles,
    handleRemoveFromPlaylistContent,
    openAddToPlaylistModal,
    openDeleteConfirmationModal,
    openRemoveFromPlaylistConfirmationModal,
    setIsAddToPlaylistModalOpen,
    setIsDeleteModalOpen,
    setIsMessageModalOpen,
    setIsRemoveFromPlaylistModalOpen,
    setIsShowDropzone,
    setSelectionDetails,
  } = useContentListPage(artistId, mediaType);

  useEffect(() => {
    setTopRightButtons(renderTopRightComponents());
  }, [selectionDetails]);

  const handleUploadClick = () => {
    dropzoneRef.current.open();
  };

  const renderTopRightComponents = () => {
    const { selected } = selectionDetails;
    // Display Add to Series button if all selected content is not added to a series
    // Display Remove from Series button if all selected content is added to a series
    // Display only Delete when selection contains mixed content, some added to series and some not
    return (
      <div className={styles.tabActions}>
        {Boolean(selected.length) && (
          <div className={styles.bulkActions}>
            <Text className={styles.selectedText}>
              {selected.length} episode{selected.length > 1 ? 's' : ''} selected
            </Text>
            {!selected.every((index) => episodes[index].is_added_to_series) &&
              !selected.some((index) => episodes[index].is_added_to_series) && (
                <Button variant={buttons.variant.secondary} onClick={() => openAddToPlaylistModal(selected)}>
                  <Playlist /> <span>Add to a Series</span>
                </Button>
              )}
            {selected.every((index) => episodes[index].is_added_to_series) && (
              <Button
                variant={buttons.variant.secondary}
                onClick={() => openRemoveFromPlaylistConfirmationModal(selected)}
              >
                <PlaylistRemove /> <span>Remove from Series</span>
              </Button>
            )}
            <Button variant={buttons.variant.secondary} onClick={() => openDeleteConfirmationModal(selected)}>
              <Trash /> <span>Delete</span>
            </Button>
            <div className={styles.verticalSeparator} />
          </div>
        )}
        <ActionButton text="Upload" onClick={handleUploadClick} />
      </div>
    );
  };

  const getDropzoneMessage = (isDragActive) => {
    let title = '';
    let subtitle = '';

    if (isShowDropzone) {
      title = isDragActive ? 'Drop your files to upload' : 'Drag & Drop your files here';
      subtitle = 'They will appear in this page';
    }

    return <DropzoneInfo title={title} subtitle={subtitle} />;
  };

  return (
    <>
      <Section
        className={styles.section}
        onDragEnter={() => setIsShowDropzone(true)}
        onDragOver={() => setIsShowDropzone(true)}
        onDragLeave={() => setIsShowDropzone(false)}
        onDropCapture={() => setIsShowDropzone(false)}
      >
        {contentList.isFetching && episodes.length === 0 && <ContentTableSkeleton rows={5} />}

        {!contentList.isLoading && (
          <>
            <div className={styles.dropzone} style={{ zIndex: isShowDropzone ? 15 : -1 }}>
              <Dropzone
                ref={dropzoneRef}
                options={{ accept: acceptedFileTypes }}
                getDropzoneMessage={getDropzoneMessage}
                onAcceptedFiles={handleAcceptedFiles}
                onRejectedFiles={handleRejectedFiles}
              />
            </div>

            {episodes.length !== 0 && (
              <>
                <ContentTable
                  data={episodes}
                  mediaType={mediaType}
                  sortBy={contentList.sortOrder}
                  onSortChange={contentList.setSortOrder}
                  onSelectionChange={setSelectionDetails}
                  onAddToPlaylist={openAddToPlaylistModal}
                  onItemClick={handleItemClick}
                  onContentUpdated={contentList.refetch}
                />
                {contentList.hasNextPage && <InfiniteScroll fetchData={contentList.fetchNextPage} />}
              </>
            )}
          </>
        )}

        <DeleteContentModal
          isOpen={isDeleteModalOpen}
          title="Delete this content?"
          message="You are about to permanently delete this content and its associated data. This cannot be restored. Are you sure you want to delete this content?"
          onClose={() => setIsDeleteModalOpen(false)}
          onDelete={handleDeleteContent}
        />

        <MessageModal
          isOpen={isMessageModalOpen}
          title="This content is being used on series"
          message="This content is currently being used on 1 or more series. Please remove it from those series before you can permanently delete the content here."
          primaryButtonLabel="OK"
          onPrimaryButtonClick={() => setIsMessageModalOpen(false)}
          onClose={() => setIsMessageModalOpen(false)}
        />

        <DeleteContentModal
          isOpen={isRemoveFromPlaylistModalOpen}
          title="Remove Episode from Series?"
          message="Removing this episode will remove it from the series and cannot be undone. Are you sure you want to proceed?"
          onClose={() => setIsRemoveFromPlaylistModalOpen(false)}
          onDelete={handleRemoveFromPlaylistContent}
          deleteButtonLabel="Remove"
        />
      </Section>

      {isAddToPlaylistModalOpen && (
        <SearchPlaylistModal
          isOpen={isAddToPlaylistModalOpen}
          isSaving={isSaving}
          onClose={() => setIsAddToPlaylistModalOpen(false)}
          onAdd={handleAddContentToPlaylist}
        />
      )}
    </>
  );
}
