import CheckIcon from '../../../assets/CheckIcon';
import HiddenIcon from '../../../assets/HiddenIcon';
import WarningIcon from '../../../assets/WarningIcon';
import { HIDE_FIELD_KEY, HIDE_FIELD_LABEL, MAPPING_STATES } from '../../../constants';

const useMappingRow = (item, availableOptions) => {
  const options = [...availableOptions];

  if (item.presentationName) {
    options.push({ presentationName: item.presentationName, guess: item.guess, header: item.header });
  }

  if (!options.some((option) => option.guess === HIDE_FIELD_KEY)) {
    options.push({ presentationName: HIDE_FIELD_LABEL, guess: HIDE_FIELD_KEY, header: null });
  }

  const getRowState = (guess) => {
    if (guess === HIDE_FIELD_KEY) return MAPPING_STATES.HIDDEN;
    if (guess) return MAPPING_STATES.MATCHED;
    return MAPPING_STATES.UNMATCHED;
  };

  const rowState = getRowState(item.guess);

  const getStyle = () => {
    switch (rowState) {
      case MAPPING_STATES.HIDDEN:
        return 'bg-[#F2F3F4]';
      case MAPPING_STATES.UNMATCHED:
        return 'bg-[#FEF0F0]';
      case MAPPING_STATES.MATCHED:
      default:
        return '';
    }
  };

  const getIcon = () => {
    switch (rowState) {
      case MAPPING_STATES.HIDDEN:
        return <HiddenIcon />;
      case MAPPING_STATES.UNMATCHED:
        return <WarningIcon />;
      case MAPPING_STATES.MATCHED:
      default:
        return <CheckIcon className="m-0.5 size-5 rounded-full border-2 border-[#0DCE94] p-0.5" fill="#0DCE94" />;
    }
  };

  return { options, getStyle, getIcon };
};

export default useMappingRow;
