import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { navigate } from '@pray/shared/utils/navigation';

import ButtonCluster from 'components/pages/StudioPage/components/ButtonCluster';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

import CreateEditAnnouncementForm from './CreateEditAnnouncementForm';
import useCreateEditAnnouncementForm from './useCreateEditAnnouncementForm';
import DeleteAnnouncementModal from '../components/dialogs/DeleteAnnouncementModal';

export default function CreateEditAnnouncementPage() {
  const appRoutes = useAppRoutes();
  const { id: announcementId } = useParams();
  const { state: locationState } = useLocation();
  const { state, actions } = useCreateEditAnnouncementForm();
  const { form, formData, isLoading, artistId, isRequiredFieldsValid } = state;
  const [isDeleteAnnouncementModalOpen, setIsDeleteAnnouncementModalOpen] = useState(false);

  const { errors } = form.formState;

  const { publishAnnouncement, saveAnnouncementDraft } = actions;

  const isEditMode = !!announcementId;
  const tabTitle = isEditMode ? 'Edit Announcement' : 'New Announcement';
  const viewName = isEditMode ? VIEW_EVENT_NAMES.ANNOUNCEMENTS.EDIT : VIEW_EVENT_NAMES.ANNOUNCEMENTS.CREATION;

  return (
    <FormProvider {...form}>
      <TabPage
        title={tabTitle}
        data-viewname={viewName}
        topRightButtons={
          <AnnouncementActionButtons
            isLoading={isLoading}
            isFormValid={isRequiredFieldsValid}
            onCancel={() => {
              if (locationState?.from) {
                navigate(-1);
              } else {
                navigate(appRoutes.artistAnnouncements());
              }
            }}
            onPublish={() =>
              form.trigger().then((isValid) => {
                if (isValid && Object.keys(errors).length === 0) {
                  publishAnnouncement();
                }
              })
            }
            onSaveDraft={() =>
              form.trigger().then((isValid) => {
                if (isValid) {
                  saveAnnouncementDraft();
                }
              })
            }
            onDelete={() => setIsDeleteAnnouncementModalOpen(true)}
            includeDelete={!!formData.id}
          />
        }
      >
        <CreateEditAnnouncementForm />
      </TabPage>

      {isDeleteAnnouncementModalOpen && (
        <DeleteAnnouncementModal
          announcementId={formData.id}
          artistId={artistId}
          onDelete={() => navigate(appRoutes.artistAnnouncements())}
          onCancel={() => setIsDeleteAnnouncementModalOpen(false)}
        />
      )}
    </FormProvider>
  );
}

function AnnouncementActionButtons({
  isLoading,
  isFormValid,
  onCancel,
  onPublish,
  onSaveDraft,
  onDelete,
  includeDelete,
}) {
  return (
    <div className="flex space-x-4">
      <Button variant={buttons.variant.secondary} onClick={onCancel}>
        Cancel
      </Button>

      <ButtonCluster isLoading={isLoading} text="Publish" onClick={onPublish} disabled={!isFormValid}>
        <DropdownMenu.Item className="w-48" title="Save as Draft" onClick={onSaveDraft} />
        {includeDelete && <DropdownMenu.Item className="w-48" title="Delete" onClick={onDelete} />}
      </ButtonCluster>
    </div>
  );
}
