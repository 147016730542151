import api from '@pray/shared/utils/api';

export async function getAnnouncementsByArtistId({ artistId, offset, limit, sortColumn, sortDirection }) {
  if (!artistId) throw Error('Artist ID is required');

  const params = {};

  if (offset) {
    params.offset = offset;
  }

  if (limit) {
    params.limit = limit;
  }

  if (sortColumn) {
    params.sort_column = sortColumn;
  }

  if (sortDirection) {
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/announcements`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
