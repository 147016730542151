import { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

type LeadListEditModalProps = {
  listName: string;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

export default function LeadListEditModal({ listName, isLoading, isOpen, onClose, onSubmit }: LeadListEditModalProps) {
  const [value, setValue] = useState(listName);

  if (!isOpen) return null;

  return (
    <Modal isShowCloseButton noPadding onClose={onClose}>
      <Text variant={typography.heading_2xl} colors={colors.text_primary} className="mb-8">
        Edit list
      </Text>
      <div>
        <TextInput
          placeholder="Edit list name"
          disabled={isLoading}
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        <div className="mt-6 flex justify-end gap-4">
          <Button disabled={isLoading} variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!value || isLoading}
            variant={buttons.variant.primary}
            loading={isLoading}
            onClick={() => onSubmit(value)}
          >
            Save changes
          </Button>
        </div>
      </div>
    </Modal>
  );
}
