import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderFollowers(artistId) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? '';
  const order = searchParams.get('order') ?? '';
  const sortOrder = `${sort} ${order}`.trim();

  const search = searchParams.get('search') ?? '';

  const setSortOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const fetchLeaderFollowers = async (offset) => {
    const [sortColumn, sortDirection] = sortOrder.split(' ');

    const response = await studioService.followers.getLeaderFollowers({
      artistId,
      sortColumn,
      sortDirection,
      offset,
      searchName: search,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    return response;
  };

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistFollowers(artistId, { search, sortOrder }),
    queryFn: ({ pageParam: offset }) => fetchLeaderFollowers(offset),
    getPreviousPageParam: (response) => response.last_item_identifier || undefined,
    getNextPageParam: (response) => response.last_item_identifier || undefined,
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    sortOrder,
    setSortOrder,
  };
}
