import React from 'react';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';

interface LeadListSidebarProps {
  to?: string;
  onBackClick?: () => void;
}

export default function LeadListSidebar({ to, onBackClick }: LeadListSidebarProps) {
  return (
    <div className="flex flex-col gap-6 pt-5">
      <BackLinkButton label="Lists" to={to} {...(onBackClick && { onClick: onBackClick })} />
    </div>
  );
}
