import api from '@pray/shared/utils/api';
import type { ListResponse } from '@pray/shared/utils/api/types/response';

type GetChaptersProps = {
  artistId: string;
  bookId: string;
};

/* eslint-disable camelcase */
export type ChapterResponse = {
  id: string;
  title: string;
  text?: string;
  draft?: string;
  last_update: string;
  audiobook_file_url?: string;
};
/* eslint-enable camelcase */

export const getChapters = async ({ artistId, bookId }: GetChaptersProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters`;

  const response = await api.executeGetRequest<ListResponse<ChapterResponse>>(url);

  return response.data;
};
