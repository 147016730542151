import React from 'react';

const PauseIconSmall = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M13.5 3V13C13.4997 13.2651 13.3942 13.5193 13.2068 13.7068C13.0193 13.8942 12.7651 13.9997 12.5 14H10.25C9.98488 13.9997 9.7307 13.8942 9.54323 13.7068C9.35576 13.5193 9.2503 13.2651 9.25 13V3C9.2503 2.73488 9.35576 2.4807 9.54323 2.29323C9.7307 2.10576 9.98488 2.0003 10.25 2H12.5C12.7651 2.0003 13.0193 2.10576 13.2068 2.29323C13.3942 2.4807 13.4997 2.73488 13.5 3ZM5.75 2H3.5C3.23488 2.0003 2.9807 2.10576 2.79323 2.29323C2.60576 2.4807 2.5003 2.73488 2.5 3V13C2.5003 13.2651 2.60576 13.5193 2.79323 13.7068C2.9807 13.8942 3.23488 13.9997 3.5 14H5.75C6.01512 13.9997 6.2693 13.8942 6.45677 13.7068C6.64424 13.5193 6.7497 13.2651 6.75 13V3C6.7497 2.73488 6.64424 2.4807 6.45677 2.29323C6.2693 2.10576 6.01512 2.0003 5.75 2Z"
        fill="white"
      />
    </svg>
  );
};

export default PauseIconSmall;
