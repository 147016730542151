import { DateTime } from 'luxon';
import { useState } from 'react';

import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { useStudioContext } from 'context/StudioContext';
import useFilterState from 'hooks/calendar/useFilterState';
import useGetCalendarEventsByPeriod from 'hooks/calendar/useGetCalendarEventsByPeriod';

import * as CalendarEvent from './CalendarEvent';
import CalendarHeader from './CalendarHeader/CalendarHeader';
import { eventTypes, viewTypes } from './constants';
import DayView from './DayView/DayView';
import MonthView from './MonthView/MonthView';
import useComboCalendar from './useComboCalendar';
import WeekView from './WeekView/WeekView';

const eventCardsByType = {
  [eventTypes.announcement]: CalendarEvent.AnouncementEvent,
  [eventTypes.daily]: CalendarEvent.DailyEvent,
  [eventTypes.email]: CalendarEvent.EmailEvent,
  [eventTypes.episode]: CalendarEvent.EpisodeEvent,
  [eventTypes.ministryMatch]: CalendarEvent.MinistryMatchEvent,
  [eventTypes.socialPost]: CalendarEvent.SocialPostEvent,
};

export default function ComboCalendar() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const [title, setTitle] = useState('');
  const { currentDate, viewType, setViewType, setCurrentDate } = useComboCalendar();
  const filterState = useFilterState();

  const { data: events = [], isLoading } = useGetCalendarEventsByPeriod({
    artistId,
    startDate: currentDate.startOf('month').toUTC().toISO(),
    endDate: currentDate.endOf('month').toUTC().toISO(),
    filters: {
      types: filterState.selectedTypes,
      statuses: filterState.selectedStatuses,
      series: filterState.selectedSeries,
    },
  });

  function handleTodayClick() {
    setCurrentDate(DateTime.now());
  }

  function handlePrevClick() {
    setCurrentDate(currentDate.minus({ [viewType]: 1 }));
  }

  function handleNextClick() {
    setCurrentDate(currentDate.plus({ [viewType]: 1 }));
  }

  function handleDayClick(day) {
    setCurrentDate(day);
    setViewType(viewTypes.day);
  }

  function renderEvent({ event, ...props }) {
    const EventCard = eventCardsByType[event.type];

    if (!EventCard) return null;

    return <EventCard {...{ viewType, ...event, ...props }} />;
  }

  return (
    <div className="flex flex-col gap-4">
      <CalendarHeader
        title={title}
        viewType={viewType}
        onTodayClick={handleTodayClick}
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
        onViewTypeChange={setViewType}
        filterState={filterState}
      />
      <div className="relative">
        {viewType === viewTypes.month && (
          <MonthView
            data={events}
            currentDate={currentDate}
            renderEvent={renderEvent}
            onTitleUpdate={setTitle}
            onDayClick={handleDayClick}
          />
        )}
        {viewType === viewTypes.week && (
          <WeekView
            data={events}
            currentDate={currentDate}
            renderEvent={renderEvent}
            onTitleUpdate={setTitle}
            onDayClick={handleDayClick}
          />
        )}
        {viewType === viewTypes.day && (
          <DayView data={events} currentDate={currentDate} renderEvent={renderEvent} onTitleUpdate={setTitle} />
        )}
        <LoadingState isLoading={isLoading} />
      </div>
    </div>
  );
}

function LoadingState({ isLoading = true }) {
  if (!isLoading) return null;

  return (
    <div className="z-100 absolute inset-0 flex items-center justify-center bg-white/90">
      <div className="flex flex-col items-center gap-2">
        <Spinner color="black" size="large" />
        <Text>Loading...</Text>
      </div>
    </div>
  );
}
