import { UpdateEpisodeResponse } from './types';

export type UpdateEpisodeServiceParams = {
  artistId: string;
  courseId: string;
  episodeId: string;
  title: string;
  transcript: string;
};

/**
 * Updates an existing episode in a course
 * @param params - The parameters for updating the episode
 * @param params.artistId - The ID of the artist containing the episode
 * @param params.courseId - The ID of the course containing the episode
 * @param params.episodeId - The ID of the episode to update
 * @param params.title - The updated title of the episode
 * @param params.transcript - The updated transcript content of the episode
 * @returns A promise that resolves to the updated episode data
 *
 */
export async function updateEpisode({
  artistId,
  courseId,
  episodeId,
  title,
  transcript,
}: UpdateEpisodeServiceParams): Promise<UpdateEpisodeResponse> {
  if (!artistId) throw new Error('Artist ID is required');
  if (!courseId) throw new Error('Course ID is required');
  if (!episodeId) throw new Error('Episode ID is required');
  if (!title) throw new Error('Title is required');

  await new Promise((resolve) => setTimeout(resolve, 800));

  const response: UpdateEpisodeResponse = {
    /* eslint-disable camelcase */
    responded_at: new Date().toISOString(),
    object: 'object',
    data: {
      id: episodeId,
      course_id: courseId,
      title,
      transcript,
    },
    /* eslint-enable camelcase */
  };

  return response;

  // Uncomment when server implementation is ready
  // const url = `/studio/artists/${artistId}/courses/${courseId}/episodes/${episodeId}`;
  // const response = await api.executePutRequest<UpdateEpisodeResponse>(url, {
  //   title,
  //   transcript
  // });
  // return response.data;
}
