import api from '@pray/shared/utils/api';

export async function updateLocalizedContentSeries({
  artistId = '',
  contentSeriesId = '',
  locale = '',
  imageUrl = undefined,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');
  if (!locale) throw Error('Locale code is required');

  const data = {};

  if (imageUrl !== undefined) data.image_url = imageUrl;

  if (Object.keys(data).length === 0) return null;

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}/locales/${locale}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
