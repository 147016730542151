import { useFieldArray, useFormContext } from 'react-hook-form';

import { TranscriptBlock } from './TranscriptBlock';

export function TranscriptPanel({ voices }) {
  /** @type {import('react-hook-form').UseFormReturn<import('./types').FormValues>} */
  const form = useFormContext();

  const { fields: transcripts, ...transcriptsFieldArray } = useFieldArray({
    control: form.control,
    name: 'transcripts',
  });

  function handleSpeakerChange(index, newSpeakerId) {
    const transcript = transcripts[index];

    // Update the speakerId of current transcript
    transcriptsFieldArray.update(index, { ...transcript, speakerId: newSpeakerId });

    // Check previous paragraph
    if (index > 0 && transcripts[index - 1].speakerId === newSpeakerId) {
      const mergedText = `${transcripts[index - 1].text} ${transcript.text}`;

      // Update previous paragraph with merged text
      transcriptsFieldArray.update(index - 1, { ...transcripts[index - 1], text: mergedText.trim() });

      // Remove current paragraph
      transcriptsFieldArray.remove(index);
    }

    // Check next paragraph
    else if (index < transcripts.length - 1 && transcripts[index + 1].speakerId === newSpeakerId) {
      const mergedText = `${transcript.text} ${transcripts[index + 1].text}`;

      // Update current paragraph with merged text
      transcriptsFieldArray.update(index, { ...transcript, speakerId: newSpeakerId, text: mergedText.trim() });

      // Remove next paragraph
      transcriptsFieldArray.remove(index + 1);
    }

    // Trigger validation if there are errors
    if (form.formState.errors.transcripts) {
      form.trigger('transcripts');
    }
  }

  function handleSplitParagraph(paragraphId, startOffset, endOffset) {
    const paragraphIndex = transcripts.findIndex(({ id }) => id === paragraphId);
    const paragraph = transcripts[paragraphIndex];

    const beforeText = paragraph.text.substring(0, startOffset);
    const selectedText = paragraph.text.substring(startOffset, endOffset);
    const afterText = paragraph.text.substring(endOffset);

    // Remove original paragraph
    transcriptsFieldArray.remove(paragraphIndex);

    // Add initial paragraph if there's text before selection
    if (beforeText.trim()) {
      transcriptsFieldArray.insert(paragraphIndex, { ...paragraph, text: beforeText.trim() });
    }

    // Add selected text as new paragraph
    transcriptsFieldArray.insert(paragraphIndex + (beforeText.trim() ? 1 : 0), {
      id: `paragraph-${Date.now()}-1`,
      text: selectedText.trim(),
      speakerId: '',
      timeframe: '',
    });

    // Add remaining text as new paragraph if it exists
    if (afterText.trim()) {
      transcriptsFieldArray.insert(paragraphIndex + (beforeText.trim() ? 2 : 1), {
        id: `paragraph-${Date.now()}-2`,
        text: afterText.trim(),
        speakerId: '',
        timeframe: '',
      });
    }
  }

  return (
    <div className="flex h-full flex-1 items-start gap-8 overflow-y-auto py-16">
      <div className="flex max-w-3xl flex-col gap-6">
        {transcripts.map((transcript, index) => (
          <TranscriptBlock
            key={transcript.id}
            index={index}
            voices={voices}
            transcript={transcript}
            onSplitParagraph={handleSplitParagraph}
            onSpeakerChange={handleSpeakerChange}
          />
        ))}
      </div>
    </div>
  );
}
