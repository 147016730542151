import api from '@pray/shared/utils/api';

export async function getEmailCampaign({ artistId = '', campaignId = '' }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!campaignId) throw new Error('Campaign ID is required');

  const url = `/web/studio/artists/${artistId}/campaigns/emails/${campaignId}`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
