import { DateTime } from 'luxon';
import { useState } from 'react';

import useQueryParams from 'hooks/useQueryParams';

import { viewTypes } from './constants';

export default function useComboCalendar() {
  const { params, setParams } = useQueryParams();
  const [currentDate, setCurrentDate] = useState(DateTime.now().startOf('day'));

  const viewType = params.view_type || viewTypes.month;

  function setViewType(type) {
    setParams({ view_type: type });
  }

  return {
    currentDate,
    viewType,
    setViewType,
    setCurrentDate,
  };
}
