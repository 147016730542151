import React from 'react';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

export default function FilterHeader({
  selectedTypes,
  selectedStatuses,
  selectedSeries,
  setSelectedTypes,
  setSelectedStatuses,
  setSelectedSeries,
}) {
  function handleOnClick() {
    setSelectedTypes([]);
    setSelectedStatuses([]);
    setSelectedSeries([]);
  }

  return (
    <div className="ml-4 flex items-center justify-between py-4">
      <Text className="text-base font-bold">Filter</Text>
      <Button
        className="cursor-pointer py-1 text-xs"
        disabled={!selectedTypes.length && !selectedStatuses.length && !selectedSeries.length}
        onClick={handleOnClick}
      >
        Clear
      </Button>
    </div>
  );
}
