import React from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { AiConfirm, AiReload, AiRemove, AiStars } from 'images/icons';

export default function AiSuggestion({
  isLoading = false,
  title = 'Suggested',
  text = '',
  onAccept = null,
  onReload = null,
  onDismiss = null,
  reloadButtonProps = {},
  dismissButtonProps = {},
  acceptButtonProps = {},
}) {
  if (!isLoading && !text) return null;

  const getSuggestionText = () => {
    if (isLoading) {
      return (
        <span className="flex items-center gap-3">
          <Spinner size="small" className="border-[#8854F2]" />
          Generating a new suggestion...
        </span>
      );
    }

    return text;
  };

  return (
    <div className="relative z-10 mt-2 flex flex-col gap-2 rounded-md bg-[#8854F233] p-3">
      <div className="flex items-center gap-2">
        <AiStars className="text-[#8854F2]" />
        <Text variant="subhead_medium" className="flex-1 text-[#360A76]">
          {title}
        </Text>
        <div className="flex gap-2">
          <Button
            className="p-0"
            onClick={onReload}
            tooltip="New suggestion"
            disabled={isLoading}
            {...reloadButtonProps}
          >
            <AiReload />
          </Button>
          <Button className="p-0" onClick={onDismiss} tooltip="Dismiss" disabled={isLoading} {...dismissButtonProps}>
            <AiRemove />
          </Button>
          <Button className="p-0" onClick={onAccept} tooltip="Accept" disabled={isLoading} {...acceptButtonProps}>
            <AiConfirm />
          </Button>
        </div>
      </div>
      <Text className="flex text-[#360A76]">{getSuggestionText()}</Text>
    </div>
  );
}
