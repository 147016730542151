import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

export default function TemplateCard({ data: template, actions }) {
  return (
    <div className="space-y-4">
      <div className="group relative">
        <TemplateActions actions={actions} />
        <TemplateCover template={template} />
      </div>
      <Text variant="headline_small" className="max-w-60 truncate font-medium">
        {template.name}
      </Text>
    </div>
  );
}

function TemplateCover({ template }) {
  const { previewImgUrl, compiledHtmlCss } = template;

  let previewElement = null;

  if (previewImgUrl) {
    previewElement = <img src={previewImgUrl} alt="" className="w-full" />;
  } else {
    const previewHtml = `
      <main style="position:relative;">
        <div style="position:absolute;left:0;right:0;top:0;bottom:0;z-index:100;"></div>
        ${compiledHtmlCss}
      </main>
    `;

    previewElement = (
      <iframe
        srcDoc={previewHtml}
        title="Email Preview"
        className="h-[1200px] w-[1000px] origin-top-left scale-[0.25] border-none"
      />
    );
  }

  return (
    <div className="h-[280px] w-[248px] overflow-hidden rounded-md border border-[#D3D3D3] bg-[#F2F3F4]">
      {previewElement}
    </div>
  );
}

function TemplateActions({ actions = { primary: null, secondary: null } }) {
  return (
    <div className="absolute z-10 hidden size-full rounded-md border-2 border-black bg-white/60 group-hover:flex">
      <div className="flex flex-1 flex-col items-center justify-center gap-4 px-14">
        {actions?.primary && (
          <Button full variant={buttons.variant.primary} onClick={actions.primary.onClick}>
            {actions.primary.label}
          </Button>
        )}
        {actions?.secondary && (
          <Button full variant={buttons.variant.secondary} className="!bg-white" onClick={actions.secondary.onClick}>
            {actions.secondary.label}
          </Button>
        )}
      </div>
    </div>
  );
}
