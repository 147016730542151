import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function usePayoutTransactions({ artistId, payoutId }) {
  async function fetchPayoutTransactions(offset) {
    const response = await studioService.donations.getPayoutTransactions({ artistId, payoutId, offset });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    response.data = response.data.map(studioService.donations.payoutTransactionMapper);

    return response;
  }

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId && !!payoutId,
    queryKey: queryKeys.artistPayoutTransactions(artistId, payoutId),
    queryFn: ({ pageParam: offset }) => fetchPayoutTransactions(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
    placeholderData: keepPreviousData,
  });

  return query;
}
