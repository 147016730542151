import Text from '@pray/shared/components/ui/Text/Text';

export default function PeriodicTableMeterRule({ ranges = [] }) {
  if (!ranges?.length) return null;

  return (
    <div className="mx-auto w-8/12 self-center" style={{ gridArea: '---' }}>
      <div className="flex flex-col">
        <div className="flex flex-row">
          {ranges.map(({ color, label }, index) => {
            let extraClass = '';
            if (index === 0) extraClass = 'rounded-l-lg';
            if (index === ranges.length - 1) extraClass = 'rounded-r-lg';
            return (
              <div key={label} className="flex flex-1 flex-col">
                <Text className="text-center font-medium leading-8">{label}</Text>
                <div className={`h-2 ${extraClass}`} style={{ backgroundColor: color }} />
              </div>
            );
          })}
        </div>
        <Text className="text-center text-base font-medium leading-8">Minutes in Prayer</Text>
      </div>
    </div>
  );
}
