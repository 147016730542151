import React, { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Dialog from '@pray/shared/components/ui/Dialog';
import InputField from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text';

import { Close } from 'images/icons';

export default function CreateVoiceNameDialog({ isLoading, onBack, onCreate, onClose }) {
  const [name, setName] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Dialog width="500px">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            <Text variant={typography.heading_2xl}>Create voice</Text>
            <Button className="!pr-0" onClick={onClose}>
              <Close />
            </Button>
          </div>
          <Text variant={typography.body_lg} color={colors.text_tertiary}>
            Define a name for the custom voice
          </Text>
        </div>

        <InputField className="mt-2" value={name} label="Voice Name" onChange={handleNameChange} />

        <hr className="border-[#DEDFE3]" />

        <div className="flex items-center gap-3">
          <Checkbox onChange={() => setIsChecked(!isChecked)} />
          <Text variant={typography.body_md} color={colors.text_tertiary}>
            I hereby confirm that I have all the necessary rights or consents to clone and use this voice.
          </Text>
        </div>

        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onBack}>
            Back
          </Button>
          <Button
            variant={buttons.variant.primary}
            onClick={() => onCreate(name)}
            disabled={!name || !isChecked}
            loading={isLoading}
          >
            Create Voice
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
