import { useEffect } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text';
import { formatCurrency, formatNumber } from '@pray/shared/utils';

import { useStudioContext } from 'context/StudioContext';
import { Community, Currency, Envelope, Instagram, YouTube } from 'images/icons';

import { ConnectableStats, SimpleStats } from './AccountOverviewStats';
import { InstagramBackground, RevenueBackground, YouTubeBackground } from './assets';
import { SOCIAL_PLATFORMS, useAccountOverview } from './useAccountOverview';
import { useRevenueSettings } from '../../SettingsTab/RevenueSettings/useRevenueSettings';

export default function AccountOverviewSection() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { summary, youtube, instagram, isConnectingInstagram, isConnectingYoutube, connectUrl, connect } =
    useAccountOverview({
      artistId,
    });
  const { hasGivingAccount, onSetupAccount } = useRevenueSettings();

  const totalGiving =
    summary?.data?.totalGiving != null ? formatCurrency(summary?.data?.totalGiving) : summary?.data?.totalGiving;
  const youtubeSubscribers = youtube?.data?.count != null ? formatNumber(youtube?.data?.count) : youtube?.data?.count;
  const instagramFollowers =
    instagram?.data?.count != null ? formatNumber(instagram?.data?.count) : instagram?.data?.count;

  useEffect(() => {
    if (connectUrl) {
      window.open(connectUrl, '_blank');
    }
  }, [connectUrl]);

  return (
    <div className="flex flex-col gap-8">
      <Loading isLight isLoading={summary?.isLoading} width={170} height={24} margin="0">
        <Text variant={typography.h2}>Account overview</Text>
      </Loading>
      <div className="flex h-52 gap-4">
        <SimpleStats
          title="Total Pray Followers"
          emptyMessage="Follower data is temporarily unavailable"
          value={summary?.data?.totalFollowers}
          icon={<Community />}
          iconBackgroundColor="bg-[#E3AF4A]/10"
          isLoading={summary?.isLoading}
          isError={summary?.isError}
          onRetry={summary?.refetch}
        />
        <SimpleStats
          title="Email Subscribers"
          emptyMessage="Email Subscriber data is temporarily unavailable"
          value={summary?.data?.totalLeads}
          icon={<Envelope />}
          iconBackgroundColor="bg-[#F2F3F4]"
          isLoading={summary?.isLoading}
          isError={summary?.isError}
          onRetry={summary?.refetch}
        />
        <ConnectableStats
          title="Instagram Followers"
          value={instagramFollowers}
          placeholder="Connect your Instagram to track your social media performance"
          buttonTitle="Connect"
          background={<InstagramBackground />}
          icon={<Instagram />}
          iconBackgroundColor="bg-[#8854F2]/10"
          isLoading={instagram?.isLoading}
          isError={instagram?.isError}
          isConnected={instagram?.data?.isConnected}
          isConnecting={isConnectingInstagram}
          onConnect={() => connect(SOCIAL_PLATFORMS.INSTAGRAM)}
          onRetry={instagram?.refetch}
        />
        <ConnectableStats
          title="Youtube Subscribers"
          value={youtubeSubscribers}
          placeholder="Connect your Youtube account to track your channel performance"
          buttonTitle="Connect"
          background={<YouTubeBackground />}
          icon={<YouTube />}
          iconBackgroundColor="bg-[#EA4345]/10"
          isLoading={youtube?.isLoading}
          isError={youtube?.isError}
          isConnected={youtube?.data?.isConnected}
          isConnecting={isConnectingYoutube}
          onConnect={() => connect(SOCIAL_PLATFORMS.YOUTUBE)}
          onRetry={youtube?.refetch}
        />
        <ConnectableStats
          title="Total Revenue"
          emptyMessage="Revenue data is temporarily unavailable."
          value={totalGiving}
          placeholder="Set up your Donations account to start receiving donations"
          buttonTitle="Set up account"
          background={<RevenueBackground />}
          icon={<Currency />}
          iconBackgroundColor="bg-[#1CAB5F]/10"
          isLoading={summary?.isLoading}
          isError={summary?.isError}
          isConnected={hasGivingAccount}
          isConnecting={false}
          onConnect={onSetupAccount}
          onRetry={summary?.refetch}
        />
      </div>
    </div>
  );
}
