import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import { US_STATES } from '@pray/shared/constants';

export function StateAudienceTooltip({ state, listens, left, top }) {
  return (
    <div className="fixed z-50" style={{ left, top }}>
      <div className="flex flex-col gap-1 rounded-md bg-[#18191CCC] p-2 shadow-md">
        <Text variant={typography.body_sm} className="!font-bold text-white">
          {US_STATES[state].name}
        </Text>
        <Text variant={typography.body_sm} className="text-xs font-medium text-[#DEDFE3]">
          All-time listens
        </Text>
        <Text variant={typography.heading_sm} className="font-bold text-white">
          {listens?.toLocaleString()}
        </Text>
      </div>
    </div>
  );
}
