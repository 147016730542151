import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

import Icons from './assets';

export default function ContentVisibility({ content }) {
  /**
   *
   * @param {Object} content The content item
   * Uses private icon when is_published is false
   * Uses scheduled or public icon when is_published is true, depending on the published_at time regarding current date
   *
   * @returns {String} Returns the visibility icon of the content
   */
  const getVisibilityIcon = (content) => {
    if (!content.is_published) return Icons.PRIVATE;
    return new Date().getTime() < new Date(content.published_at).getTime() ? Icons.SCHEDULED : Icons.PUBLIC;
  };

  /**
   *
   * @param {Object} content The content item
   * Uses 'private' label when is_published is false
   * Uses 'scheduled' or 'public' label when is_published is true, depending on the published_at time regarding current date
   *
   * @returns {String} Returns the content visibility label
   */
  const getVisibilityLabel = (content) => {
    if (!content.is_published) return 'Private';
    return new Date().getTime() < new Date(content.published_at).getTime() ? 'Scheduled' : 'Public';
  };

  const visibilityIcon = getVisibilityIcon(content);
  const visibilityLabel = getVisibilityLabel(content);

  return (
    <div className="flex items-center gap-2">
      <img src={visibilityIcon} alt={visibilityLabel} />
      <Text className="text-base font-medium normal-case tracking-normal">{visibilityLabel}</Text>
    </div>
  );
}
