import api from '@pray/shared/utils/api';

export async function getGivingPayments({
  artistId = '',
  startDate = '',
  endDate = '',
  offset = '',
  limit = '',
  downloadCsv = false,
  viewType = 'subscribers',
}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!startDate) throw new Error('Start Date is required');
  if (!endDate) throw new Error('End Date is required');

  const params = {
    start_date: startDate,
    end_date: endDate,
    view_type: viewType,
  };

  if (offset) params.offset = offset;
  if (limit) params.limit = limit;

  const options = {};

  if (downloadCsv) {
    params.download_csv = true;
    options.responseType = 'blob';
  }

  const url = `/web/studio/artists/${artistId}/giving-payments`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
}
