import Loading from '@pray/shared/components/Loading/Loading';

export function SocialClipsPublishFormLoading() {
  return (
    <div className="flex gap-8">
      <div className="flex max-w-screen-sm flex-1 flex-col gap-8">
        <LoadingSimpleInput />

        <div className="flex flex-col gap-4">
          <LoadingSimpleInput />
          {/* Textarea input */}
          <div className="flex flex-col rounded-md border p-4">
            <Loading isLight width="15%" />
            <Loading isLight width="100%" />
            <Loading isLight width="100%" />
            <Loading isLight width="100%" />
            <div className="flex flex-row justify-between">
              <Loading isLight width="80%" />
              <Loading isLight width="5%" />
            </div>
          </div>
        </div>
      </div>

      {/* Video */}
      <Loading isLight width="264px" height="509px" />
    </div>
  );
}

function LoadingSimpleInput() {
  return (
    <div className="flex flex-col rounded-md border p-4">
      <Loading isLight width="15%" />
      <div className="flex flex-row justify-between">
        <Loading isLight width="80%" />
        <Loading isLight width="5%" />
      </div>
    </div>
  );
}
