import api from '@pray/shared/utils/api';

export const getAllDailyItemsByPeriod = async ({
  artistId = null,
  dailySeriesId = null,
  dailyItemId = null,
  startDate = null,
  endDate = null,
  userTimezone = null,
} = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series ID is required');

  const url = `/web/studio/artists/${artistId}/daily-items?dailySeriesId=${dailySeriesId}&type=christian&startDate=${startDate}&endDate=${endDate}&userTimezone=${userTimezone}`;
  const response = await api.executeGetRequest(url);

  return response.data;
};

export const getAllDailyItemsForDate = async ({ artistId = null, dailySeriesId = null, date = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series ID is required');

  const url = `/web/studio/artists/${artistId}/daily-items?dailySeriesId=${dailySeriesId}&type=christian&startDate=${date}&endDate=${date}`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
