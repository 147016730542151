import React, { useEffect, useRef, useState } from 'react';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import AppBar from 'components/pages/StudioPage/components/AppBar/AppBar';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { Close } from 'images/icons';

import { TemplateGrid } from './TemplateGrid';
import { TemplateTypes } from './TemplateTypes';
import { templateTypes } from '../../constants';

export default function TemplatesModal({
  isTemplateSelector = false,
  onClose = null,
  onApply = null,
  onCreate = null,
  onEdit = null,
  onClone = null,
  onPreview = null,
}) {
  const [templateType, setTemplateType] = useState(templateTypes.prayTemplates);

  const chipAreaRef = useRef(null);
  const gridAreaRef = useRef(null);

  const handleScroll = () => {
    const chipArea = chipAreaRef.current;
    const gridArea = gridAreaRef.current;

    if (gridArea.scrollTop >= 2) {
      chipArea.style.boxShadow = '0px 2px 8px 0px #00000029';
    } else {
      chipArea.style.boxShadow = 'none';
    }
  };

  useEffect(() => {
    const gridArea = gridAreaRef.current;
    gridArea.addEventListener('scroll', handleScroll);

    return () => {
      gridArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const title = isTemplateSelector ? 'Select Template' : 'Manage Templates';
  const viewName = isTemplateSelector
    ? VIEW_EVENT_NAMES.EMAILS.SELECT_TEMPLATE
    : VIEW_EVENT_NAMES.EMAILS.MANAGE_TEMPLATES;

  return (
    <div data-viewname={viewName} className="fixed right-0 top-0 z-[90] size-full bg-white">
      <AppBar />

      <div className="relative">
        <header className="flex h-[72px] items-center justify-between border-b border-[#DEDFE3] px-4">
          <Text variant="h1">{title}</Text>
          <div className="flex items-center gap-3">
            <Button variant="secondary" onClick={onClose} className="flex items-center gap-3">
              <Close />
              Close
            </Button>
          </div>
        </header>

        <div ref={chipAreaRef} className="mb-0.5 w-full bg-white transition-all">
          <div className="mx-auto w-full max-w-[1400px] px-4">
            <TemplateTypes selectedType={templateType} onTypeChange={setTemplateType} />
          </div>
        </div>

        <div ref={gridAreaRef} className="no-scrollbar max-h-[calc(100vh-220px)] w-full overflow-y-auto py-2">
          <div className="mx-auto w-full max-w-[1400px] px-4">
            <div className="mx-auto w-fit max-w-full">
              <TemplateGrid
                templateType={templateType}
                isTemplateSelector={isTemplateSelector}
                onApply={onApply}
                onCreate={onCreate}
                onEdit={onEdit}
                onClone={onClone}
                onPreview={onPreview}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
