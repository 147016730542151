export function ErrorIcon() {
  return (
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="64" height="64" rx="32" fill="#FEF0F0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.75 32C23.75 27.4437 27.4437 23.75 32 23.75C36.5563 23.75 40.25 27.4437 40.25 32C40.25 36.5563 36.5563 40.25 32 40.25C27.4437 40.25 23.75 36.5563 23.75 32ZM32 22.25C26.6152 22.25 22.25 26.6152 22.25 32C22.25 37.3848 26.6152 41.75 32 41.75C37.3848 41.75 41.75 37.3848 41.75 32C41.75 26.6152 37.3848 22.25 32 22.25ZM32.75 36.5V35H31.25V36.5H32.75ZM32.75 27.25V26.75H31.25V27.25V27.5V28V33V33.75H32.75V33V28V27.5V27.25Z"
        fill="#AA0E0F"
      />
    </svg>
  );
}
