import React from 'react';

export default function AnalyticsReport({ title = '', url = '', params = '', ...props }) {
  if (!url || !params) return null;

  const src = `${url}?params=${params}`;

  return (
    <iframe key={title} title={title} src={src} className="h-[calc(100vh-72px)] w-full flex-1 border-none" {...props} />
  );
}
