import { DateTime } from 'luxon';
import React from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text/Text';

import { PLACEHOLDER_IMAGE } from 'constants.js';

const getObjectUrlFromFile = (file) => {
  if (file && file instanceof File) {
    return URL.createObjectURL(file);
  }

  return null;
};

export default function DailySeriesPreview({ form, dailySeries, isLoading = true }) {
  if (!dailySeries?.id) return null;

  const imageFromFile = getObjectUrlFromFile(form.values.image_file);
  const imageUrl = imageFromFile || dailySeries?.image_url || PLACEHOLDER_IMAGE;

  return (
    <div className="mt-2 flex flex-col">
      {isLoading ? (
        <Loading isLight width="170px" height="auto" aspectRatio="3 / 4" />
      ) : (
        <img src={imageUrl} alt="" className="aspect-[3/4] w-44 rounded-md object-cover" />
      )}

      <div className="mt-3">
        <Text className="block text-sm font-bold">Title</Text>
        <Text className="line-clamp-1 w-40 text-sm">{dailySeries.title}</Text>
      </div>
      <div className="mt-3">
        <Text className="block text-sm font-bold">Handle</Text>
        <Text className="line-clamp-1 w-40 text-sm">{dailySeries.handle}</Text>
      </div>
      <div className="mt-3">
        <Text className="block text-sm font-bold">Modified</Text>
        <Text className="text-sm">
          {DateTime.fromISO(dailySeries.updated_at).toLocaleString(DateTime.DATETIME_MED)}
        </Text>
      </div>
    </div>
  );
}
