import { ChannelList, Channel, sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { useState, useMemo, useRef } from 'react';

import logger from '@pray/shared/utils/logger';

import { SENDBIRD } from 'constants.js';

import CreateSendbirdUser from './CreateSendbirdUser';

const buildChannelUrl = (artistId, userId) => {
  return artistId && userId ? `chat_group_artist_${artistId}_user_${userId}` : '';
};

export default function LeaderFollowerChat({ artistId = '', userId = '' }) {
  const [channelUrl, setChannelUrl] = useState('');

  return (
    <div className="group-chat flex h-[calc(100vh-250px)]">
      {!!userId && <CreateSendbirdUser userId={userId} />}

      <LeaderFollowerChannelList
        artistId={artistId}
        userId={userId}
        channelUrl={channelUrl}
        onChannelSelected={setChannelUrl}
      />

      <Channel channelUrl={channelUrl} isLoading={false} />
    </div>
  );
}

const LeaderFollowerChannelList = ({ artistId = '', userId = '', channelUrl = '', onChannelSelected = null }) => {
  const isCreateChannel = useRef(!channelUrl);
  const store = useSendbirdStateContext();
  const createChannel = sendbirdSelectors.getCreateGroupChannel(store);

  const channelQueries = useMemo(() => {
    return {
      channelListQuery: {
        includeEmpty: true,
        customTypesFilter: [SENDBIRD.CUSTOM_TYPES.LEADER_FOLLOWER_1_TO_1_CHANNEL],
      },
      applicationUserListQuery: {
        userIdsFilter: [`artist_${artistId}`],
      },
    };
  }, [artistId]);

  const handleCreateChannel = async () => {
    if (!artistId || !userId || !isCreateChannel.current) return;

    isCreateChannel.current = false;

    const channelUrl = buildChannelUrl(artistId, userId);
    const artistSendbirdUserId = `artist_${artistId}`;
    const userSendbirdUserId = userId;
    const invitedUserIds = [artistSendbirdUserId, userSendbirdUserId];

    try {
      const { memberCount } = await createChannel({
        channelUrl,
        invitedUserIds,
        customType: SENDBIRD.CUSTOM_TYPES.LEADER_FOLLOWER_1_TO_1_CHANNEL,
        isDistinct: true,
      });

      if (memberCount === invitedUserIds.length) {
        onChannelSelected(channelUrl);
      }
    } catch (err) {
      logger.error('Failed creating channel', err);
    }
  };

  return (
    <ChannelList
      isTypingIndicatorEnabled
      isMessageReceiptStatusEnabled
      queries={channelQueries}
      activeChannelUrl={channelUrl}
      disableAutoSelect={!userId}
      onChannelSelect={(channel) => {
        if (userId && !channelUrl) {
          handleCreateChannel();
        } else if (channel?.url) {
          onChannelSelected(channel.url);
        }
      }}
    />
  );
};
