import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetDailySeriesDetails(artistId, dailySeriesId) {
  const fetchDailySeries = async () => {
    const response = await studioService.dailySeries.getDailySeriesById({
      dailySeriesId,
      artistId,
      sortColumn: 'scheduled_at',
      sortDirection: 'desc',
      limit: 10,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId && !!dailySeriesId,
    queryKey: queryKeys.artistDailySeries(artistId, dailySeriesId),
    queryFn: fetchDailySeries,
  });

  return query;
}
