import React from 'react';

export function ChevronDown({ fill = 'white', size = 16, ...props }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96967 7.96918C4.26256 7.67629 4.73744 7.67629 5.03033 7.96918L11.5 14.4389L17.9697 7.96918C18.2626 7.67629 18.7374 7.67629 19.0303 7.96918C19.3232 8.26207 19.3232 8.73695 19.0303 9.02984L12.0303 16.0298C11.7374 16.3227 11.2626 16.3227 10.9697 16.0298L3.96967 9.02984C3.67678 8.73695 3.67678 8.26208 3.96967 7.96918Z"
        fill={fill}
      />
    </svg>
  );
}
