import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetTranscriptionsList(artistId, contentId, locale) {
  const fetchTranscriptionsList = async () => {
    const response = await studioService.workflowAI.getTranscriptionList({
      artistId,
      contentId,
      locale,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.transcripts(artistId, contentId, locale),
    queryFn: fetchTranscriptionsList,
  });

  return query;
}
