import api from '@pray/shared/utils/api';

export async function getGivingAccountLink({ artistId = '' }) {
  if (!artistId) throw new Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/giving-accounts/account-link`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
