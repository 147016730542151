import api from '@pray/shared/utils/api';

export async function getLeadCampaignCsvExport({ leadCampaignId }) {
  if (!leadCampaignId) throw Error('Campaign ID is required');

  const url = `/web/studio/lead-campaigns/${leadCampaignId}/export/csv`;
  const params = {};
  const response = await api.executeGetRequest(url, params, { responseType: 'blob' });

  return response.data;
}
