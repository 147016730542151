import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetCalendarEventsByPeriod({ artistId, startDate, endDate, filters }) {
  async function fetchCalendarEvents() {
    const response = await studioService.calendar.getAllEventsByPeriod({
      artistId,
      startDate,
      endDate,
      filters,
    });

    if (!response.data) return [];

    return response.data.map(studioService.calendar.eventsMapper).sort((a, b) => {
      if (!a.endDate && b.endDate) return -1;
      if (a.endDate && !b.endDate) return 1;

      return DateTime.fromISO(a.startDate).toMillis() - DateTime.fromISO(b.startDate).toMillis();
    });
  }

  const query = useQuery({
    enabled: !!(artistId && startDate && endDate),
    queryKey: queryKeys.artistCalendar(artistId, { startDate, endDate, ...filters }),
    queryFn: fetchCalendarEvents,
  });

  return query;
}
