import React from 'react';

import Button from '@pray/shared/components/ui/Button';

import styles from './IconButton.module.scss';

export default function IconButton({ icon: Icon = null, className = '', size = 'md', children = null, ...props }) {
  const sizeClass = styles[size];
  const classes = [styles.iconButton, sizeClass, className] //
    .filter(Boolean)
    .join(' ');

  const icon = Icon ? <Icon /> : null;

  return (
    <Button className={classes} {...props}>
      {icon}
      {children}
    </Button>
  );
}
