import { colors, typography } from '@pray/shared/components/foundations';
import { Table, Thead, Tbody, Tr, Th, Td } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import type { LeadListResponseContacts, LeadListMappings } from '@pray/shared/services/studioService/leadLists/types';

type ContentTableProps = {
  data: LeadListResponseContacts[];
  fields: LeadListMappings[];
};

export default function ContentTable({ data, fields }: ContentTableProps) {
  return (
    <Table key={data.map((item) => item.id).join('-')} selectable={false} rowCount={data.length} rowActions={null}>
      <Thead>
        <Tr>
          {fields.map((field) => (
            <Th key={field.column_name} field={field.column_name}>
              {field.presentation_name}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((content, index) => (
          <Tr key={`${content.id}-${index}`}>
            {fields.map((field) => (
              <Td key={`${field.column_name}-${content.id}`} className="w-auto">
                <Text className="p-1.5" variant={typography.body_lg} color={colors.text_primary}>
                  {content[field.column_name]}
                </Text>
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
