import React from 'react';

export function ContentList({ color = '#ffffff', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.25 4H20.75M13.25 12H20.75M3.25 20H20.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 9.44155C3.25 9.24722 3.46199 9.1272 3.62862 9.22717L7.89271 11.7856C8.05455 11.8827 8.05455 12.1173 7.89271 12.2144L3.62862 14.7728C3.46199 14.8728 3.25 14.7528 3.25 14.5585V9.44155Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </svg>
  );
}
