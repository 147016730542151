import { DateTime } from 'luxon';
import React from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Copy } from '@pray/shared/components/ui/Icons/Copy';
import Link from '@pray/shared/components/ui/Link/Link';
import Text from '@pray/shared/components/ui/Text/Text';
import { WEB_BASE_URI } from '@pray/shared/config';
import { copyToClipboard } from '@pray/shared/utils';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { PLACEHOLDER_IMAGE } from 'constants.js';

export default function PlaylistPreview({ playlist }) {
  const toast = useToastMessage();
  const imageUrl = playlist?.image_url || PLACEHOLDER_IMAGE;
  const playlistUrl = `${WEB_BASE_URI}/series/${playlist.handle}`;

  const handleCopyUrl = () => {
    copyToClipboard(playlistUrl, () => {
      toast.show({ success: 'Link copied!' });
    });
  };

  if (!playlist?.id) return null;

  return (
    <div className="mt-2 flex flex-col">
      <img src={imageUrl} alt="" className="size-[220px] rounded-md object-cover" />

      <div className="mt-3 flex items-end gap-2">
        <div>
          <Text className="block text-sm font-bold">Playlist Link</Text>
          <Link href={playlistUrl} target="_blank" rel="noreferrer noopener">
            <Text className="w-44 truncate text-sm underline">{playlistUrl}</Text>
          </Link>
        </div>
        <IconButton onClick={handleCopyUrl} data-tapeventname="Copy">
          <Copy />
        </IconButton>
      </div>
      <div className="mt-3">
        <Text className="block text-sm font-bold">Title</Text>
        <Text className="line-clamp-1 w-40 text-sm">{playlist.title}</Text>
      </div>
      <div className="mt-3">
        <Text className="block text-sm font-bold">Modified</Text>
        <Text className="text-sm">{DateTime.fromISO(playlist.updated_at).toLocaleString(DateTime.DATETIME_MED)}</Text>
      </div>
    </div>
  );
}
