import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useEmailCampaignStatus(artistId) {
  function campaignStatusMapper(data) {
    return {
      ...data,
      isActive: data.is_active,
    };
  }

  async function fetchEmailCampaignStatus() {
    const response = await studioService.emailCampaigns.getEmailCampaignStatus({ artistId });

    return campaignStatusMapper(response.data);
  }

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistEmailCampaignStatus(artistId),
    queryFn: fetchEmailCampaignStatus,
  });

  const { isLoading, data } = query;
  const isEmailStatusActive = !isLoading && data?.isActive;

  return { ...query, isEmailStatusActive };
}
