import React from 'react';
import { useFormContext } from 'react-hook-form';

import Select from '@pray/shared/components/ui/Select/Select';
import TextInput from '@pray/shared/components/ui/TextInput';

import Section from 'components/pages/StudioPage/components/Section/Section';

import { payoutIntervalOptions, statementDescriptorMaxLength, statementDescriptorMinLength } from '../../constants';

export default function RevenueAccountInput() {
  const form = useFormContext();
  const { errors } = form.formState;
  const payoutInterval = form.watch('payoutInterval');
  const statementDescriptor = form.watch('statementDescriptor');

  return (
    <Section title="Settings" className="mt-4">
      <Select
        label="Deposit Frequency"
        name="payoutInterval"
        value={payoutInterval}
        items={payoutIntervalOptions}
        getItemLabel={(item) => item?.label}
        getItemValue={(item) => item?.value}
        inputElement={TextInput}
        onChange={(event) => form.setValue('payoutInterval', event.target.value)}
      />

      <div className="flex flex-col gap-3">
        <TextInput
          label="Statement Descriptor"
          name="statementDescriptor"
          value={statementDescriptor}
          helperText={`${statementDescriptor?.length} / ${statementDescriptorMaxLength}`}
          errorMessage={errors.statementDescriptor?.message.toString()}
          {...form.register('statementDescriptor', {
            minLength: {
              value: statementDescriptorMinLength,
              message: `Statement Descriptor must be at least ${statementDescriptorMinLength} characters`,
            },
            maxLength: {
              value: statementDescriptorMaxLength,
              message: `Statement Descriptor must be at most ${statementDescriptorMaxLength} characters`,
            },
            onChange: (event) => {
              const { value } = event.target;
              const sanitizedValue = value.replace(/[^a-z A-Z0-9]/g, '').slice(0, statementDescriptorMaxLength);
              form.setValue('statementDescriptor', sanitizedValue.toUpperCase());
            },
          })}
        />
      </div>
    </Section>
  );
}
