import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export function DetailItem({ title, value }) {
  if (!value) return null;

  return (
    <div className="flex flex-col gap-1">
      <Text variant={typography.subhead_small} className="text-[#56585E]">
        {title}
      </Text>
      <Text className="text-base font-medium">{value}</Text>
    </div>
  );
}
