import { DateTime } from 'luxon';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu';
import { Table, Thead, Tbody, Tr, Th, TdLink, Td } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import type { LeadList } from '@pray/shared/services/studioService/leadLists/mappers/leadListsMapper';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';

import useAppRoutes from 'hooks/useAppRoutes';
import { VerticalEllipsis } from 'images/icons';
import { cn } from 'styles/utils';

import MoreActionsDropdown from '../../components/MoreActionsDropdown';
import { getStatusData } from '../utils/getStatusData';

type ContentTableProps = {
  data: LeadList[];
  onRetry: (id: string) => void;
  onDelete: (id: string) => void;
};

export default function ContentTable({ data, onRetry, onDelete }: ContentTableProps) {
  const appRoutes = useAppRoutes();

  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];

    if (!item || item.status === LeadListStatus.PROCESSING) return [];

    return [
      <MoreActionsDropdown
        component={
          <Button variant={buttons.variant.default} className="rounded-full p-1 hover:shadow-lg">
            <VerticalEllipsis />
          </Button>
        }
      >
        {item.status === LeadListStatus.FAILED && <DropdownMenu.Item title="Retry" onClick={() => onRetry(item.id)} />}
        <DropdownMenu.Item title="Delete" onClick={() => onDelete(item.id)} />
      </MoreActionsDropdown>,
    ];
  };

  return (
    <Table
      key={data.map((item) => item.id).join('-')}
      selectable={false}
      rowCount={data.length}
      rowActions={getRowActions}
    >
      <Thead>
        <Tr>
          <Th field="name">
            <Text variant={typography.body_lg} color={colors.text_secondary}>
              List name
            </Text>
          </Th>
          <Th field="count">
            <Text variant={typography.body_lg} color={colors.text_secondary}>
              Count
            </Text>
          </Th>
          <Th field="created">
            <Text variant={typography.body_lg} color={colors.text_secondary}>
              Created
            </Text>
          </Th>
          <Th field="status">
            <Text variant={typography.body_lg} color={colors.text_secondary} />
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((content) => {
          const to =
            content.status === LeadListStatus.COMPLETE ? appRoutes.artistContactsListDetails(content.id) : null;
          const status = getStatusData(content.status);

          return (
            <Tr key={content.id} className={cn('group', { 'cursor-pointer': to })}>
              <TdLink className="w-5/12" to={to}>
                <Text className="p-1.5" variant={typography.body_lg} color={colors.text_primary}>
                  {content.name}
                </Text>
              </TdLink>
              <TdLink className="w-2/12" to={to}>
                <Text className="p-1.5" variant={typography.body_lg} color={colors.text_primary}>
                  {content.count}
                </Text>
              </TdLink>
              <TdLink className="w-3/12" to={to}>
                <Text className="p-1.5" variant={typography.body_lg} color={colors.text_primary}>
                  {DateTime.fromISO(content.createdAt).toFormat('MMM d, yyyy')}
                </Text>
              </TdLink>
              <Td className="w-2/12">
                <Text className="p-1.5" variant={typography.body_lg} color={status.color}>
                  {status.label}
                </Text>
                {status.icon}
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
