import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text';

export default function SolomonHeadline({ isLoading }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Loading isLoading={isLoading} isLight width={170} height={24} margin="0">
          <Text variant={typography.heading_lg}>Solomon</Text>

          <Text
            variant={typography.body_sm}
            className="rounded bg-[#ECF9ED] px-1.5 py-0.5 !font-bold uppercase text-[#1CAB5F]"
          >
            Beta
          </Text>
        </Loading>
      </div>

      <Loading isLoading={isLoading} isLight width={500} margin="0">
        <Text variant={typography.body_md}>
          Quickly craft devotional ideas and gain audience insights with the power of Solomon AI
        </Text>
      </Loading>
    </div>
  );
}
