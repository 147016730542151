import Text from '@pray/shared/components/ui/Text/Text';

import { Spinner } from 'images/icons';

// @ts-ignore
import { ReactComponent as PeriodicTableImg } from './assets/periodic-table-loading.svg';

export default function PeriodicTableReportLoading() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="mt-36 flex w-[500px] flex-col items-center">
        <PeriodicTableImg className="mb-6 h-auto w-[163px]" />
        <Text variant="h2" className="mb-1">
          Creating Your Periodic Table
        </Text>
        <Text variant="headline_small" className="mb-6 text-center">
          Your Periodic Table is currently being generated. Please wait a moment; this process may take a few minutes.
        </Text>
        <Spinner className="animate-spin" />
      </div>
    </div>
  );
}
