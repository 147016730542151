import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';

import MediaUpload from '../../../components/MediaUpload/MediaUpload';
import Section from '../../../components/Section/Section';

import styles from './DailySeriesEditForm.module.scss';

export default function DailySeriesEditForm({ form, disabled }) {
  return (
    <div>
      <Section className="max-w-4xl">
        <div className={styles.formField}>
          <TextInput
            className="cursor-not-allowed"
            label="Title (required)"
            name="title"
            value={form.values.title}
            errorMessage={form.errors.title}
            disabled={disabled}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextArea
            className="cursor-not-allowed"
            rows={10}
            label="Description (required)"
            name="description"
            value={form.values.description}
            errorMessage={form.errors.description}
            disabled={disabled}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextInput
            placeholder="Handle (optional)"
            name="handle"
            value={form.values.handle}
            errorMessage={form.errors.handle}
            onChange={form.handleChange}
          />
        </div>
      </Section>

      <div className="mt-4">
        <MediaUpload
          imageUrl={form.values.image_url}
          title="Thumbnail"
          description="We require uploading a image that respects the aspect ratio of 3/4 and maximum 512kb. We support JPG and PNG formats."
          uploadButtonLabel={form.values.image_url ? 'Change Image' : 'Upload Image'}
          error={form.errors.image_url}
          cropProps={{ aspect: 3 / 4 }}
          onFileChange={(file) => form.setValue('image_file', file)}
          onError={(error) => form.setError('image_url', error)}
        />
      </div>
    </div>
  );
}
