import React, { lazy, Suspense } from 'react';

import { useAudioPlayer } from './ContentPlayer/FullscreenContentPlayer/FullscreenContentPlayer';
import { useShareModal } from './ShareModal/NewShareModal';
import VideoModal from './VideoModal/VideoModal';

const NewShareModal = lazy(() => import('./ShareModal/NewShareModal'));

const FullscreenContentPlayer = lazy(() => import('./ContentPlayer/FullscreenContentPlayer/FullscreenContentPlayer'));

export default function SharedComponents() {
  return (
    <Suspense fallback={null}>
      <ShareModal />
      <AudioPlayer />
      <VideoModal />
    </Suspense>
  );
}

const ShareModal = () => {
  const { options, closeShareModal } = useShareModal();

  if (!options) return null;

  return <NewShareModal {...options} close={closeShareModal} />;
};

const AudioPlayer = () => {
  const { options, closeAudioPlayer } = useAudioPlayer();

  const handleClose = () => {
    closeAudioPlayer();
    if (options.onClose) options.onClose();
  };

  if (!options?.contentId) return null;

  return <FullscreenContentPlayer key={options.contentId} {...options} onClose={handleClose} />;
};
