import React, { useEffect } from 'react';
import { create } from 'zustand';

import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text/Text';

import placeholderImage from '../../assets/cover-placeholder.webp';
import ContentStatus from '../ContentStatus/ContentStatus';

export const useSideBarContentDetails = create((set) => ({
  imageUrl: '',
  title: '',
  isVisible: false,
  setContentDetails: (details) => set(() => details),
}));

export default function SideBarContentDetails({ label = null, isDailyType = false, renderBottomView = () => null }) {
  const { title, imageUrl, isVisible, setContentDetails } = useSideBarContentDetails();

  useEffect(() => {
    return () => {
      setContentDetails({
        title: '',
        imageUrl: '',
        isVisible: false,
      });
    };
  }, []);

  if (!title)
    return (
      <div className="flex flex-col gap-4 px-5">
        <Loading height={80} isLight width={80} />
        <Loading height={40} isLight width={150} />
      </div>
    );

  return (
    <div className="flex flex-col gap-3 px-4">
      <img
        alt=""
        src={imageUrl || placeholderImage}
        className={`${isDailyType ? 'aspect-[3/4] w-32' : 'size-20'} rounded-md object-cover`}
      />
      <div className="flex flex-col items-start gap-1">
        {label && <Text className="text-xs text-[#56585E]">{label}</Text>}
        <Text className="line-clamp-2 max-w-full select-text break-words text-sm font-medium">{title}</Text>
        {isVisible && <ContentStatus status={isVisible ? 'published' : 'private'} />}
        {renderBottomView?.()}
      </div>
    </div>
  );
}
