import React from 'react';

import styles from './ToggleScwitch.module.scss';

export default function ToggleSwitch(props) {
  return (
    <label className={styles.switch}>
      <input type="checkbox" {...props} />
      <span className={styles.slider} />
    </label>
  );
}
