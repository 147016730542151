import api from '@pray/shared/utils/api';

import type { UpdateLeadListMetadata } from './types';

/* eslint-disable camelcase */
type UpdateLeadListParams = {
  is_retry_processing?: boolean;
};
/* eslint-enable camelcase */

type UpdateLeadListProps = {
  artistId: string;
  listId: string;
  metadata?: UpdateLeadListMetadata;
  retryUpload?: boolean;
};

export async function updateLeadList({ artistId, listId, metadata = {}, retryUpload = false }: UpdateLeadListProps) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!listId) throw new Error('List ID is required');
  if (!metadata?.listName && !retryUpload) throw new Error('List name or retry upload is required');

  const params: UpdateLeadListParams = {};

  if (retryUpload) {
    params.is_retry_processing = retryUpload;
  }

  const body = {
    list_name: metadata?.listName,
  };

  const response = await api.executePutRequest<undefined>(
    `/web/studio/artists/${artistId}/leads-lists/${listId}`,
    body,
    {
      params,
    }
  );

  return response.data;
}
