import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import storage from '@pray/shared/utils/storage';

export default function WelcomeSection() {
  const user = storage.getUser();
  const greeting = user?.name ? `Welcome, ${user?.name}` : 'Welcome';

  return <Text variant={typography.heading_4xl}>{greeting}</Text>;
}
