import api from '@pray/shared/utils/api';

export async function deleteVoice({ artistId, voiceId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!voiceId) throw Error('Voice ID is required');

  const url = `/web/studio/artists/${artistId}/voices/${voiceId}`;
  const response = await api.executeDeleteRequest(url);

  return response?.data;
}
