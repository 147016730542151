import { variations } from './constants';
import PeriodicTableElementCard from './PeriodicTableElementCard';
import PeriodicTableEmptyElementCard from './PeriodicTableEmptyElementCard';
import PeriodicTableMeterRule from './PeriodicTableMeterRule';
import useClassification from './useClassification';

import styles from './PeriodicTable.module.scss';

const MIN_VISIBLE_ELEMENTS = 40;

const PeriodicTable = ({ segmentGroups = [], selectedGroup }) => {
  const classification = useClassification(segmentGroups.flatMap((group) => group.segments));

  const segments = segmentGroups
    .map((group, groupIndex) => {
      if (!group?.segments) return null;

      return group.segments.map((segment) => {
        const gridArea = `E${String(segment.rank).padStart(2, '0')}`;

        return (
          <PeriodicTableElementCard
            key={`PeriodicTableElementCard - Group_${String(group.id)} -Segment - ${segment.segment_code} `}
            to={`segments/${segment.segment_code}`}
            group={group.id}
            rank={segment.rank}
            code={segment.segment_code}
            name={segment.segment_name}
            highlight={{
              value: segment.minutes_consumed,
              label: 'Minutes in Prayer',
              color: classification.getClassificationColor(segment.minutes_consumed),
            }}
            table={{
              caption: '% of Ministry Users',
              records: [
                { label: 'Listens', value: `${segment.listen_percentage}% ` },
                { label: 'Shares', value: `${segment.shares_percentage}% ` },
                { label: 'Follows', value: `${segment.follows_percentage}% ` },
              ],
            }}
            selectedGroup={selectedGroup}
            gridArea={gridArea}
            variation={groupIndex % variations.length}
          />
        );
      });
    })
    .flat();

  // If the number of segments is less than the number of visible elements, add empty elements to fill the grid
  if (segments.length < MIN_VISIBLE_ELEMENTS) {
    const emptyElements = new Array(MIN_VISIBLE_ELEMENTS - segments.length).fill(null);

    const originalSegmentsLength = segments.length;
    emptyElements.forEach((_, index) => {
      const gridArea = `E${String(originalSegmentsLength + index + 1).padStart(2, '0')}`;

      segments.push(
        <PeriodicTableEmptyElementCard key={`PeriodicTableEmptyElementCard-${Math.random()}`} gridArea={gridArea} />
      );
    });
  }

  return (
    <div className="mx-auto w-fit">
      <div className={styles.periodicTable}>
        <PeriodicTableMeterRule ranges={classification.minutesRanges} />
        {segments}
      </div>
    </div>
  );
};

export default PeriodicTable;
