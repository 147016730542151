import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { cn } from 'styles/utils';

export function DonationStatsCard({ title, value, isDisabled }) {
  return (
    <div className="flex flex-col justify-between gap-3 rounded-xl border bg-white p-6">
      <Text className="text-sm font-medium" color={colors.text_secondary}>
        {title}
      </Text>
      <Text className={cn('text-3xl font-bold', isDisabled ? 'text-[#8C8F96]' : '')}>{value}</Text>
    </div>
  );
}
