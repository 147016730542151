import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';
import studioService from '@pray/shared/services/studioService';
import { formatNumberCount } from '@pray/shared/utils';
import { formatDateUsingShorterMonthAndTime } from '@pray/shared/utils/datetime';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useEmailsCampaigns from 'hooks/emailCampaigns/useEmailCampaigns';
import useEmailCampaignStatus from 'hooks/emailCampaigns/useEmailCampaignStatus';
import useCloneEmailTemplate from 'hooks/emailTemplates/useCloneEmailTemplate';
import useDeleteEmailTemplate from 'hooks/emailTemplates/useDeleteEmailTemplate';
import useSaveEmailTemplate from 'hooks/emailTemplates/useSaveEmailTemplate';
import { Permissions } from 'utils/auth';

import { emailStatus, newTemplateName, statusToLabelMap, tempTemplatePreviewImgUrl } from '../constants';

export default function useEmailsListPage() {
  const toast = useToastMessage();
  const { permissions } = useAuth();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const navigate = useNavigate();

  const emailCampaignsQuery = useEmailsCampaigns(artistId);
  const { isEmailStatusActive } = useEmailCampaignStatus(artistId);
  const { cloneEmailTemplate } = useCloneEmailTemplate();
  const { saveEmailTemplate } = useSaveEmailTemplate();
  const { deleteEmailTemplate } = useDeleteEmailTemplate();

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isTemplateDesignerModalOpen, setIsTemplateDesignerModalOpen] = useState(false);
  const [isTemplatePreviewModalOpen, setIsTemplatePreviewModalOpen] = useState(false);
  const [templateForPreview, setTemplateForPreview] = useState(null);

  const isMinistryMatchModalOpen = !isEmailStatusActive && !permissions.has(Permissions.STUDIO_ADMIN);

  const emailCampaigns = useMemo(
    () => emailCampaignsQuery.data?.pages?.flatMap((page) => page.data.map(emailCampaignUIMapper)) || [],
    [emailCampaignsQuery.data]
  );

  function handleEditTemplate(template) {
    setSelectedTemplate(template);
    setIsTemplateDesignerModalOpen(true);
  }

  function handlePreviewTemplate(template) {
    setSelectedTemplate(template);
    setIsTemplatePreviewModalOpen(true);
  }

  async function handleCloneTemplate(template) {
    const data = {
      templateId: template.id,
      name: `${template.name} Clone`,
    };

    const clonedTemplate = await cloneEmailTemplate({ artistId, ...data });

    handleEditTemplate(clonedTemplate);
  }

  async function saveTemplateDesign({ rawHtml, rawCss, compiledHtmlCss }) {
    const { id: templateId, name } = selectedTemplate;

    const data = {
      templateId,
      name,
      rawHtml,
      rawCss,
      compiledHtmlCss,
      previewImgUrl: tempTemplatePreviewImgUrl,
    };

    await saveEmailTemplate({ artistId, ...data });

    toast.show({ success: 'Template saved successfully' });

    closeTemplateDesignerModal();
  }

  async function handleCreateTemplate(template) {
    const data = {
      name: newTemplateName,
      rawHtml: template.rawHtml,
      rawCss: template.rawCss,
      compiledHtmlCss: template.compiledHtmlCss,
    };

    const newTemplate = await saveEmailTemplate({ artistId, ...data });

    setSelectedTemplate(newTemplate);
    setIsTemplateDesignerModalOpen(true);
  }

  async function handleRenameTemplate({ name }) {
    const { id, rawHtml, rawCss, compiledHtmlCss, previewImgUrl } = selectedTemplate;

    const data = {
      templateId: id,
      name,
      previewImgUrl: previewImgUrl || tempTemplatePreviewImgUrl,
      rawHtml,
      rawCss,
      compiledHtmlCss,
    };

    const template = await saveEmailTemplate({ artistId, ...data });

    toast.show({ success: 'Template renamed successfully' });

    setSelectedTemplate(template);
  }

  async function handleSendTemplateTestEmail(emails) {
    const templateId = selectedTemplate.id;

    try {
      setIsLoading(true);
      await studioService.emailTemplates.sendTestEmail({ artistId, templateId, emails });
      toast.show({ success: 'Your test email has been sent' });
    } catch {
      toast.show({ error: 'There was an error sending your test email' });
    } finally {
      setIsLoading(false);
      closeSendTestModal();
    }
  }

  async function handleDeleteTemplate() {
    const templateId = selectedTemplate.id;

    await deleteEmailTemplate({ artistId, templateId });

    toast.show({ success: 'Template deleted successfully' });

    setSelectedTemplate(null);

    closeTemplateDesignerModal();
  }

  const closeSendTestModal = () => setIsSendTestModalOpen(false);
  const closeTemplateDesignerModal = () => setIsTemplateDesignerModalOpen(false);
  const closeTemplatePreviewModal = () => setIsTemplatePreviewModalOpen(false);
  const closeTemplatesModal = () => setIsTemplatesModalOpen(false);
  const closeMinistryMatchModal = () => navigate(-1);
  const openManageTemplatesModal = () => setIsTemplatesModalOpen(true);
  const openSendTestModal = () => setIsSendTestModalOpen(true);
  const selectTemplateForPreview = (template) => {
    setTemplateForPreview(template);
    setIsTemplatePreviewModalOpen(true);
  };

  return {
    state: {
      isLoading,
      emailCampaigns,
      emailCampaignsQuery,
      templateForPreview,
      isEmailStatusActive,
      isSendTestModalOpen,
      isTemplateDesignerModalOpen,
      isTemplatePreviewModalOpen,
      isTemplatesModalOpen,
      isMinistryMatchModalOpen,
      selectedTemplate,
    },
    actions: {
      closeSendTestModal,
      closeTemplatePreviewModal,
      closeTemplateDesignerModal,
      closeTemplatesModal,
      closeMinistryMatchModal,
      handleCloneTemplate,
      handleCreateTemplate,
      handleEditTemplate,
      handlePreviewTemplate,
      openManageTemplatesModal,
      openSendTestModal,
      handleRenameTemplate,
      handleDeleteTemplate,
      saveTemplateDesign,
      handleSendTemplateTestEmail,
      selectTemplateForPreview,
    },
  };
}

function emailCampaignUIMapper(email) {
  const locale = 'en-US';
  const status = email.status || emailStatus.draft;

  return {
    ...email,
    statusLabel: statusToLabelMap[status],
    scheduledAt: formatDateUsingShorterMonthAndTime(email.scheduledAt),
    sendCount: formatNumberCount(email.sendCount),
    openPercentage: email.sendCount ? ((email.openCount / email.sendCount) * 100).toLocaleString(locale) : 0,
    clickPercentage: email.sendCount ? ((email.clickCount / email.sendCount) * 100).toLocaleString(locale) : 0,
  };
}
