import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@pray/shared/components/ui/Button/Button';
import { BackLinkButtonArrow } from '@pray/shared/components/ui/Icons/BackLinkButtonArrow';

import styles from './BackLinkButton.module.scss';

export default function BackLinkButton({ label = 'Back', to = null, ...props }) {
  const navigate = useNavigate();
  const { state } = useLocation();

  function handleBackNavigation() {
    const route = to || state?.from || -1;
    navigate(route, { replace: true });
  }

  return (
    <Button className={styles.backButton} onClick={handleBackNavigation} {...props}>
      <div className="inline-flex items-center justify-start gap-2 p-2">
        <BackLinkButtonArrow fill="currentColor" />
        <span className="text-base font-medium capitalize text-primary">{label}</span>
      </div>
    </Button>
  );
}
