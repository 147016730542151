import type { BookResponse } from '../getBook';
import type { Book } from '../types';

export const mapBooks = (data: BookResponse[]): Book[] => {
  return data.map(mapBook);
};

export const mapBook = (data: BookResponse): Book => {
  const fileName = data.file_url?.split('/')?.pop();
  return {
    id: data.id,
    fileName,
    title: data.title,
    description: data.description,
    thumbnail: data.image_url,
    isPublished: data.is_visible,
    createdAt: data.created_at,
    textExtractionStatus: data.text_extraction_status,
    textExtractionUpdatedAt: data.text_extraction_updated_at,
    audiobookGenerationStatus: data.audiobook_generation_status,
    audiobookGenerationUpdatedAt: data.audiobook_generation_updated_at,
    lastSavedAt: data.last_saved_at,
    // TODO: For now we are only working with private books
    // when we have public books, we should use the published_at date
    publishedAt: data.created_at,
  };
};
