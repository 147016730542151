import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import studioService from '@pray/shared/services/studioService';
import { downloadFileFromBlob } from '@pray/shared/utils/download';
import logger from '@pray/shared/utils/logger';

import usePayoutTransactions from 'hooks/donations/usePayoutTransactions';

import PayoutTransactionTable from './PayoutTransactionsTable';

export function PayoutTransactions({ artistId, payoutId }) {
  const { data, isLoading, hasNextPage, fetchNextPage } = usePayoutTransactions({
    artistId,
    payoutId,
  });

  const transactions = data?.pages.flatMap((page) => page.data) || [];

  async function handleDownloadCSV() {
    try {
      const response = await studioService.donations.getPayoutTransactions({
        artistId,
        payoutId,
        downloadCsv: true,
      });

      await downloadFileFromBlob(response, 'bank_transactions.csv');
    } catch (err) {
      logger.error('Failed to download deposit transactions', err);
    }
  }

  return (
    <>
      <div className="flex justify-between">
        <Text className="text-3xl font-bold">Deposits</Text>
        <Button variant={buttons.variant.secondary} onClick={handleDownloadCSV}>
          Download CSV
        </Button>
      </div>

      {(() => {
        if (isLoading) return null;

        return <PayoutTransactionTable data={transactions} {...{ hasNextPage, fetchNextPage }} />;
      })()}
    </>
  );
}
