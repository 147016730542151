import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { leadListLeadsMapper } from '@pray/shared/services/studioService/leadLists/mappers';

type UseLeadListLeadsProps = {
  artistId: string;
  listId: string;
  filters: Record<string, any>;
};

export default function useLeadListLeads({ artistId, listId, filters }: UseLeadListLeadsProps) {
  const fetchLeads = async ({ pageParam }) => {
    const { search } = filters;
    const response = await studioService.leadLists.getLeadListLeads({
      artistId,
      listId,
      search,
      lastItemIdentifier: pageParam,
      sortDirection: 'asc',
    });

    return leadListLeadsMapper(response);
  };

  const query = useInfiniteQuery({
    initialPageParam: null,
    queryKey: queryKeys.leadListId(artistId, listId, filters),
    queryFn: fetchLeads,
    getNextPageParam: (lastPage) => lastPage.lastItemIdentifier,
    placeholderData: keepPreviousData,
  });

  return query;
}
