import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text';
import AudioPlayingIcon from '@pray/shared/icons/AudioPlayingIcon';

export default function ChapterSelector({ chapters = [], currentChapter, onChapterSelect }) {
  if (!chapters?.length) return null;

  const truncateChapterTitle = (title, selected) => {
    const maxLength = selected ? 23 : 25;
    return title.length > maxLength ? `${title.slice(0, maxLength)}...` : title;
  };

  return (
    <div className="absolute bottom-full right-0 z-[1000] mb-2 max-h-[300px] w-[280px] overflow-y-auto rounded-2xl bg-[#3A3C40] px-4 pb-2 pt-6">
      <Text variant={typography.heading_md}>Chapters</Text>
      <div className="py-2">
        {chapters.map((chapter) => (
          <Button
            key={chapter.id}
            color={colors.text_featured}
            className="flex w-full items-center gap-4 border-none bg-transparent px-2 py-[10px] text-left"
            onClick={() => {
              onChapterSelect(chapter.id);
            }}
          >
            {currentChapter === chapter.id && <AudioPlayingIcon color={colors.$text_featured} />}
            <Text
              variant={typography.body_small}
              className="!font-medium"
              color={currentChapter === chapter.id ? colors.text_featured : colors.text_primary}
            >
              {truncateChapterTitle(chapter.title, currentChapter === chapter.id)}
            </Text>
          </Button>
        ))}
      </div>
    </div>
  );
}
