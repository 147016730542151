import api from '@pray/shared/utils/api';

export async function updateDonationFund({
  artistId = undefined,
  fundId = undefined,
  name = undefined,
  description = undefined,
  imageUrl = undefined,
  isEnabled = undefined,
  defaultAmountMonthly = undefined,
  defaultAmountYearly = undefined,
  amountMonthly2 = undefined,
  amountYearly2 = undefined,
  includeInAppPromotions = undefined,
}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!fundId) throw new Error('Fund ID is required');

  const data = {
    name,
    description,
  };

  if (isEnabled !== undefined) data.is_enabled = isEnabled;
  if (imageUrl !== undefined) data.image_url = imageUrl;
  if (defaultAmountMonthly !== undefined) data.default_amount_monthly = defaultAmountMonthly;
  if (defaultAmountYearly !== undefined) data.default_amount_yearly = defaultAmountYearly;
  if (amountMonthly2 !== undefined) data.amount_monthly_2 = amountMonthly2;
  if (amountYearly2 !== undefined) data.amount_yearly_2 = amountYearly2;
  if (includeInAppPromotions !== undefined) data.include_in_app_promotions = includeInAppPromotions;

  const url = `/web/studio/artists/${artistId}/funds/${fundId}`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
