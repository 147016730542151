import React, { useEffect, useState } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Metadata from '@pray/shared/components/Metadata';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { putArtistLocales } from '@pray/shared/services/studioService/locales';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';
import useArtistLocales from 'hooks/locales/useArtistLocales';

import ActionButton from '../../../components/ActionButton/ActionButton';
import { useToastMessage } from '../../../components/StudioToastMessage';
import TabPage from '../../../components/TabPage/TabPage';
import LanguageListModal from '../LanguageListModal/LanguageListModal';
import { LanguageSetting } from '../LanguageSettings/LanguageSettings';

import styles from './TranslationsSettings.module.scss';

const TranslationSettingsPage = ({ artistLocales, setArtistLocales, artistId }) => {
  const toast = useToastMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const artistLocalesResult = useArtistLocales(artistId);

  /**
   * Show error message if failed to load translations settings
   */
  if (artistLocalesResult.error) {
    toast.show({ error: 'Failed to load translations settings' });
  }

  useEffect(() => {
    /**
     * Set artist locales from the API response
     */
    if (artistLocalesResult?.data?.locales) {
      const artistLocalesResultData = (artistLocalesResult.data.locales || []).map((item) => ({
        id: item.id,
        label: item.label,
        locale: item.locale,
        autoTranslate: item.auto_translate_enabled,
      }));
      setArtistLocales(artistLocalesResultData);
    }
  }, [artistLocalesResult?.data?.locales]);

  /**
   * Toggle language auto translate setting
   * @param artistLocaleId
   */
  const toggleLanguageByLocaleId = (artistLocaleId) => {
    setArtistLocales((prevLocales) => {
      return prevLocales.map((locale) => {
        if (locale.id === artistLocaleId) {
          return { ...locale, autoTranslate: !locale.autoTranslate };
        }
        return locale;
      });
    });
  };

  /**
   * Remove language from the list
   * @param artistLocaleId
   */
  const removeLanguage = (artistLocaleId) => {
    setArtistLocales((prevLocales) => prevLocales.filter((locale) => locale.id !== artistLocaleId));
  };

  /**
   * Add languages to the list
   * @param updatedItems - list of languages added in the modal
   */
  const onAddLanguages = (updatedItems) => {
    // Keep items from artistLocales that exist in updatedItems
    const filteredLocales = artistLocales.filter((locale) =>
      updatedItems.some((item) => item.locale === locale.locale)
    );

    // Add new items from updatedItems that don't exist in artistLocales
    const newItems = updatedItems.filter((item) => !artistLocales.some((locale) => locale.locale === item.locale));

    setArtistLocales([...filteredLocales, ...newItems]);
  };

  return (
    <div className={styles.translationSettings}>
      <div className={styles.headlineWrapper}>
        <Text variant={typography.headline_large}>Automatic Translations</Text>
        <Text variant={typography.body_small} className="text-[#56585E]">
          Setup which languages to automatically translate when uploading new content.
        </Text>
      </div>

      <div className={styles.languageList}>
        {artistLocalesResult.isLoading && (
          <>
            <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
            <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
            <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
          </>
        )}

        {!artistLocalesResult.isLoading && artistLocales.length === 0 && (
          <div className="flex items-center justify-center py-20 text-xs text-[#56585E]">
            This artist have not enabled any languages for automatic translation.
          </div>
        )}

        {!artistLocalesResult.isLoading &&
          artistLocales.map((artistLocale) => (
            <LanguageSetting
              key={artistLocale.id}
              language={artistLocale.label}
              isEnabled={artistLocale.autoTranslate}
              onToggle={() => toggleLanguageByLocaleId(artistLocale.id)}
              onRemove={() => removeLanguage(artistLocale.id)}
            />
          ))}

        <Button type="button" className={styles.addLanguageBtn} onClick={() => setIsModalOpen(true)}>
          <Text className={styles.addLanguageLabel} variant={typography.button_large}>
            + ADD LANGUAGE
          </Text>
        </Button>

        <LanguageListModal
          title="Add Language"
          subtitle="Select the languages you want to enable for translation"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          artistEnabledLocales={artistLocales.map((aLocale) => aLocale.locale)}
          onCheckToggle={onAddLanguages}
        />
      </div>
    </div>
  );
};

export default function TranslationsSettings() {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const [isPublishing, setIsPublishing] = useState(false);
  const [artistLocales, setArtistLocales] = useState([]);

  const handleSave = async () => {
    setIsPublishing(true);
    try {
      const mappedLocales = artistLocales.map((locale) => ({
        id: locale.id,
        locale: locale.locale,
        label: locale.label,
        auto_translate_enabled: Boolean(locale.autoTranslate) || false,
      }));

      await putArtistLocales({ artistId: selectedArtist?.id || null, locales: mappedLocales });

      toast.show({ success: 'Translations settings saved' });
    } catch (error) {
      logger.error(error);
      toast.show({ error: 'Translations settings not saved' });
    } finally {
      setIsPublishing(false);
    }
  };

  const renderTopRightButtons = () => {
    const text = isPublishing ? 'Saving' : 'Save';

    return (
      <div className="flex space-x-4">
        <ActionButton isLoading={isPublishing} text={text} onClick={handleSave} disabled={false} />
      </div>
    );
  };

  return (
    <div data-viewname="PRAY Studio - Settings - Translations">
      <Metadata title="PRAY Studio for Leaders - Translations settings" />

      <TabPage title="Settings" topRightButtons={renderTopRightButtons()}>
        <TranslationSettingsPage
          artistLocales={artistLocales}
          setArtistLocales={setArtistLocales}
          artistId={selectedArtist?.id || null}
        />
      </TabPage>
    </div>
  );
}
