import { useMemo } from 'react';

import { useStudioContext } from 'context/StudioContext';
import useSocialPlatforms from 'hooks/socials/useSocialPlatforms';

import { SocialAccount } from './types';
import { InstagramIcon, FacebookIcon, YoutubeIcon, XIcon } from '../../ContentTab/SocialClipsPage/assets/social-icons';
import { socialNetworks } from '../../ContentTab/SocialClipsPage/constants';

const SOCIAL_PLATFORMS = {
  instagram: {
    id: 'instagram',
    name: 'Instagram',
    icon: InstagramIcon,
  },
  facebook: {
    id: 'facebook',
    name: 'Facebook',
    icon: FacebookIcon,
  },
  youtube: {
    id: 'youtube',
    name: 'Youtube',
    icon: YoutubeIcon,
  },
  twitter: {
    id: 'twitter',
    name: 'X/Twitter',
    icon: XIcon,
  },
};

export const useSocialAccountsSettingsPage = () => {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { socialPlatforms, connectSocialPlatforms, disconnectSocialPlatform } = useSocialPlatforms(artistId);
  const { data: connectedPlatforms, isLoading } = socialPlatforms;

  const socialAccounts = useMemo(() => {
    if (isLoading || !connectedPlatforms) return [];

    return Object.values(SOCIAL_PLATFORMS).map((platform) => {
      const platformData = connectedPlatforms[platform.id];
      const isConnected = platformData.is_connected;

      const socialAccount: SocialAccount = {
        id: socialNetworks[platform.id],
        icon: platform.icon,
        name: platform.name,
        profile: isConnected ? platformData.profile_handle ?? '' : '',
        isConnected,
      };

      return socialAccount;
    });
  }, [connectedPlatforms, isLoading]);

  const handleConnect = () => {
    connectSocialPlatforms();
  };

  const handleDisconnect = async (platform: string) => {
    await disconnectSocialPlatform(platform);
  };

  return {
    socialAccounts,
    isLoading,
    handleConnect,
    handleDisconnect,
  };
};
