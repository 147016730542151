import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export function useGenerateAudiobook() {
  const queryClient = useQueryClient();

  async function generateAudiobook({ artistId, bookId, voiceId }) {
    await studioService.books.generateAudiobook({
      artistId,
      bookId,
      voiceId,
    });
  }

  function onSuccess(_, variables) {
    queryClient.invalidateQueries({ queryKey: queryKeys.artistBookStatus(variables.artistId, variables.bookId) });
  }

  const mutation = useMutation({
    mutationFn: generateAudiobook,
    onSuccess,
  });

  const { mutateAsync, isPending } = mutation;

  return {
    generateAudiobook: mutateAsync,
    isPending,
  };
}
