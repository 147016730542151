import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

export default function useSaveDailySeriesCoverArt(artistId, dailySeriesId) {
  const queryClient = useQueryClient();

  /**
   * @summary Uploads a cover art image to S3 and returns the signed URL
   * @param coverArtType {'square' | 'portrait' | 'landscape'}
   * @param form {Object|null}
   * @param formField {string}
   * @return {Promise<string>}
   */
  async function s3SignedDailySeriesCoverArtUpload(coverArtType = 'square', fileToUpload = null) {
    if (!coverArtType) throw Error('Cover art type is required');
    if (!fileToUpload) throw Error('File URL is required');

    let imageUrl;
    try {
      const response = await s3Service.signAndUpload({
        file: fileToUpload,
        type: 'dailySeries',
        signParams: {
          daily_series_id: dailySeriesId,
          daily_series_cover_art_type: coverArtType,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      imageUrl = null;
    }

    return imageUrl;
  }

  async function saveCoverArts({ imageLandscapeInput, imagePortraitInput, imageSquareInput }) {
    let imageLandscapeUrl;
    let imagePortraitUrl;
    let imageSquareUrl;

    if (imageLandscapeInput) {
      imageLandscapeUrl = await s3SignedDailySeriesCoverArtUpload('square', imageLandscapeInput);
    }

    if (imagePortraitInput) {
      imagePortraitUrl = await s3SignedDailySeriesCoverArtUpload('portrait', imagePortraitInput);
    }

    if (imageSquareInput) {
      imageSquareUrl = await s3SignedDailySeriesCoverArtUpload('landscape', imageSquareInput);
    }

    await studioService.dailySeries.updateDailySeriesCoverArts({
      artistId,
      dailySeriesId,
      imageSquareUrl: imageSquareUrl || imageSquareInput,
      imagePortraitUrl: imagePortraitUrl || imagePortraitInput,
      imageLandscapeUrl: imageLandscapeUrl || imageLandscapeInput,
    });
  }

  const mutation = useMutation({
    mutationFn: saveCoverArts,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistDailySeries(artistId, dailySeriesId),
      });
    },
  });

  const { mutateAsync, isLoading, ...rest } = mutation;

  return {
    saveCoverArts: mutateAsync,
    isSaving: isLoading,
    ...rest,
  };
}
