import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { leadListsMappingMapper } from '@pray/shared/services/studioService/leadLists/mappers';

type UseGetMappingsProps = {
  artistId: string;
  headers: string[];
};

export default function useGetMappings({ artistId, headers }: UseGetMappingsProps) {
  return useQuery({
    enabled: !!headers?.length,
    queryKey: queryKeys.leadListMappings(headers),
    queryFn: async ({ signal }) => {
      const response = await studioService.leadLists.getMappings({
        artistId,
        headers,
        signal,
      });
      return leadListsMappingMapper(response);
    },
  });
}
