export * from '@pray/shared/utils/auth';

const domains = {
  studio: 'studio',
  artist: 'artist.studio',
};

const resources = {
  viewBroadcast: 'view_broadcast',
  viewDailyBuilder: 'view_daily_builder',
  viewTags: 'view_tags',
  viewTeam: 'view_team',
  inviteTeamMember: 'invite_team_member',
  removeTeamMember: 'remove_team_member',
  updateArtistHandle: 'update_artist_handle',
  uploadExtraImageSizes: 'upload_extra_profile_image_sizes',
};

const permission = (domain, resource) => `${domain}-${resource}`;

export const Permissions = {
  STUDIO_ADMIN: permission(domains.studio, '*'),
  VIEW_BROADCAST: permission(domains.artist, resources.viewBroadcast),
  VIEW_DAILY_BUILDER: permission(domains.studio, resources.viewDailyBuilder),
  VIEW_TAGS: permission(domains.studio, resources.viewTags),
  VIEW_TEAM: permission(domains.artist, resources.viewTeam),
  INVITE_TEAM_MEMBER: permission(domains.artist, resources.inviteTeamMember),
  REMOVE_TEAM_MEMBER: permission(domains.artist, resources.removeTeamMember),
  UPDATE_ARTIST_HANDLE: permission(domains.studio, resources.updateArtistHandle),
  UPLOAD_EXTRA_IMAGE_SIZES: permission(domains.studio, resources.uploadExtraImageSizes),
};
