import api from '@pray/shared/utils/api';

export const chartTypes = {
  geochart: 'geochart',
  topContent: 'top_content',
  householdIncome: 'household_income',
  gender: 'gender',
  age: 'age',
  maritalStatus: 'marital_status',
  politicalAffiliation: 'political_affiliation',
  religiousAffiliation: 'religious_affiliation',
  ethnicity: 'ethnicity',
  educationLevel: 'education_level',
};

export const getSegmentDetails = async ({ artistId = '', segmentCode = '', chartType = '' } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!segmentCode) throw Error('Segment code is required');

  const params = {};

  if (chartType) params.chart_type = chartType;

  const url = `/web/studio/artists/${artistId}/analytics/periodic-table/segments/${segmentCode}`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
