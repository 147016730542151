import React, { useState, useMemo, useRef, forwardRef } from 'react';

import { colors } from '../../foundations';
import IconButton from '../IconButton/IconButton';
import { Clear } from '../Icons/Clear';
import { Error, Success } from '../Icons/Icons';
import InputLoader from '../InputLoader/InputLoader';
import Text from '../Text/Text';

import styles from './InputField.module.scss';

function InputField(
  {
    className = '',
    error = null,
    label = null,
    onBlur = null,
    onFocus = null,
    loading = false,
    renderStatusIndicator = true,
    renderSuccessIndicator = false,
    leftIcon = null,
    rightIcon = null,
    rightDetails = null,
    children = null,
    ...props
  },
  ref
) {
  const fieldRef = ref || useRef(null);
  const [active, setActive] = useState(false);

  const inputStyles = [styles.input, className];

  if (label) inputStyles.push(styles.withLabel);
  if (rightIcon) inputStyles.push(styles.withRightIcon);

  function handleLabelClick() {
    fieldRef.current?.focus();
  }

  function handleFocus(event) {
    setActive(true);
    if (onFocus) onFocus(event);
  }

  function handleBlur(event) {
    setActive(false);
    if (onBlur) onBlur(event);
  }

  const labelEmptyStyle = !props.value ? [styles.floating, styles.empty].join(' ') : styles.floating;
  const labelStyle = active || props.value ? labelEmptyStyle : '';
  const activeStyle = active && !error ? styles.inputWrapActive : '';
  const errorStyle = error ? styles.inputWrapError : '';
  const selectStyle = children ? styles.inputSelectWrap : '';

  const wrapperStyles = [styles.inputWrap, labelStyle, activeStyle, errorStyle, selectStyle];
  if (leftIcon) wrapperStyles.push(styles.withLeftIcon);
  if (!renderStatusIndicator) wrapperStyles.push(styles.withoutStatusIndicator);
  if (children) wrapperStyles.push(styles.select);
  if (props.rows) wrapperStyles.push(styles.textarea);
  if (props.disabled) wrapperStyles.push('disabled', styles.disabled);

  const wrapperClasses = wrapperStyles //
    .filter((item) => item)
    .join(' ');

  const indicatorIcon = useMemo(() => {
    if (!renderStatusIndicator) return null;
    if (loading) return <InputLoader />;
    if (error) return <Error fill={colors.$attention} />;
    if (renderSuccessIndicator && props.value) {
      return <Success className={styles.success} fill={colors.$success} />;
    }
    return null;
  }, [props.value, error, loading, renderStatusIndicator]);

  const fieldClasses = inputStyles
    .filter(Boolean) //
    .join(' ');

  const renderInputControl = () => {
    if (children) {
      return (
        <select ref={fieldRef} className={fieldClasses} onFocus={handleFocus} onBlur={handleBlur} {...props}>
          {children}
        </select>
      );
    }

    if (props.rows) {
      return <textarea ref={fieldRef} className={fieldClasses} onFocus={handleFocus} onBlur={handleBlur} {...props} />;
    }

    return <input ref={fieldRef} className={fieldClasses} onFocus={handleFocus} onBlur={handleBlur} {...props} />;
  };

  const renderLeftIcon = () => {
    if (!leftIcon) return null;
    return <div className={styles.leftIcon}>{leftIcon}</div>;
  };

  const renderRightIcon = () => {
    if (props.value && props.type === 'search') {
      return (
        <div className={`${styles.rightIcon} ${styles.clearButton}`}>
          <IconButton onClick={() => props.onChange({ target: { value: '' } })}>
            <Clear />
          </IconButton>
        </div>
      );
    }

    if (rightIcon) return <div className={styles.rightIcon}>{rightIcon}</div>;

    if (indicatorIcon) return <div className={styles.indicator}>{indicatorIcon}</div>;

    return null;
  };

  const renderRightDetails = () => {
    if (rightDetails) return <div className={styles.rightDetails}>{rightDetails}</div>;
    return null;
  };

  return (
    <div>
      <div
        data-id="inputWrap"
        data-tapeventadded="button-placeholder"
        className={wrapperClasses}
        onClick={handleLabelClick}
        role="none"
      >
        {label && <div className={styles.placeholder}>{label}</div>}
        {renderLeftIcon()}
        {renderInputControl()}
        {renderRightIcon()}
        {renderRightDetails()}
      </div>

      {error && <Text className={styles.error}>{error}</Text>}
    </div>
  );
}

export const InputHelperText = ({ className = '', ...props }) => {
  const classes = [styles.helperText, className].join(' ');
  return <Text className={classes} {...props} />;
};

/**
 * @typedef InputFieldProps
 * @property {string} [error]
 * @property {string} [label]
 * @property {boolean} [loading]
 * @property {boolean} [renderStatusIndicator]
 * @property {boolean} [renderSuccessIndicator]
 * @property {React.ReactNode} [leftIcon]
 * @property {React.ReactNode} [rightIcon]
 * @property {React.Children} [children]
 */

/**
 * @type React.ForwardRefRenderFunction<HTMLInputElement, React.InputHTMLAttributes & React.TextareaHTMLAttributes & InputFieldProps>
 */
// @ts-ignore
export default forwardRef(InputField);
