import { useQuery, UseQueryResult } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { Book, mapBook } from '@pray/shared/services/studioService/books';

type UseBookProps = {
  artistId: string;
  bookId: string;
};

export function useBook({ artistId, bookId }: UseBookProps): UseQueryResult<Book> {
  async function fetchBook() {
    const response = await studioService.books.getBook({
      artistId,
      bookId,
    });

    return mapBook(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!bookId,
    queryKey: queryKeys.artistBookDetails(artistId, bookId),
    queryFn: fetchBook,
  });

  return query;
}
