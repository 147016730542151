import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

function mapArtistSummary(data) {
  return {
    leaderName: data.leader_name,
    totalFollowers: data.total_follows,
    totalLeads: data.total_leads,
    totalGiving: data.total_net_amount,
  };
}

function mapSocialAccountSummary(data) {
  return {
    count: data.count,
    isConnected: data.is_connected,
  };
}

export const SOCIAL_PLATFORMS = {
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
};

export function useAccountOverview({ artistId }) {
  const queryClient = useQueryClient();
  const [connectingPlatform, setConnectingPlatform] = useState(null);

  const summaryQuery = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistSummary(artistId),
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const response = await studioService.home.getArtistSummary({ artistId });
      return mapArtistSummary(response.data);
    },
  });

  const youtubeQuery = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistYoutubeSummary(artistId),
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const response = await studioService.home.getArtistYoutubeSummary({ artistId });
      return mapSocialAccountSummary(response.data);
    },
  });

  const instagramQuery = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistInstagramSummary(artistId),
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const response = await studioService.home.getArtistInstagramSummary({ artistId });
      return mapSocialAccountSummary(response.data);
    },
  });

  const connectMutation = useMutation({
    mutationFn: () => studioService.home.postConnectSocialAccount({ artistId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.artistYoutubeSummary(artistId) });
      queryClient.invalidateQueries({ queryKey: queryKeys.artistInstagramSummary(artistId) });
      setConnectingPlatform(null);
    },
  });

  return {
    summary: summaryQuery,
    youtube: youtubeQuery,
    instagram: instagramQuery,
    isConnectingInstagram:
      (connectingPlatform === SOCIAL_PLATFORMS.INSTAGRAM || instagramQuery.isLoading) && !connectMutation.isError,
    isConnectingYoutube:
      (connectingPlatform === SOCIAL_PLATFORMS.YOUTUBE || youtubeQuery.isLoading) && !connectMutation.isError,
    connectUrl: connectMutation.data?.data?.url,
    connectError: connectMutation.error,
    connect: (platform) => {
      setConnectingPlatform(platform);
      connectMutation.mutate();
    },
  };
}
