import React from 'react';

import BackgroundImage from '@pray/shared/components/ui/BackgroundImage/BackgroundImage';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { PLACEHOLDER_IMAGE } from '@pray/shared/constants';
import PlayIcon from '@pray/shared/icons/PlayIconEmpty';

import styles from './ContentItem.module.scss';

export default function ContentItem({
  isVideo = false,
  image = '',
  title = '',
  description = null,
  fallbackImage = null,
  onItemClick = null,
}) {
  const fallbackImageUrl = fallbackImage || PLACEHOLDER_IMAGE;
  const imageUrl = image || fallbackImageUrl;
  const hasImage = image || fallbackImage;

  const handleImageError = (event) => {
    if (fallbackImageUrl) {
      event.target.src = fallbackImageUrl;
    } else {
      event.target.style.display = 'none';
    }
  };

  const style = isVideo
    ? { width: '114px', minWidth: '114px', height: '64px' }
    : { width: '40px', minWidth: '40px', height: '40px' };

  const height = isVideo ? 'h-16' : 'h-10';

  return (
    <div className={`ml-1 mr-6 flex items-center space-x-3 ${height}`}>
      {hasImage && (
        <div className="group relative">
          <BackgroundImage
            className="rounded-md object-cover"
            src={imageUrl}
            style={style}
            onError={handleImageError}
          />
          {onItemClick && (
            <Button
              className="invisible absolute inset-0 flex items-center justify-center bg-black/50 p-0 group-hover:visible"
              onClick={onItemClick}
            >
              <PlayIcon className="m-0 max-h-12 w-10" />
            </Button>
          )}
        </div>
      )}
      <div className="flex min-w-0 flex-col justify-center">
        {title && <Text className={styles.title}>{title}</Text>}
        {description && <Text className={styles.description}>{description}</Text>}
      </div>
    </div>
  );
}
