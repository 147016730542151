import { useEffect, useState } from 'react';

import { copyToClipboard } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { htmlElementToImageUrl } from 'utils/htmlToImage';

import { barData, donutData, lineData, textData1, textData2 } from '../data';

export const Steps = {
  ANALYSING: 1,
  ERROR: 2,
  RESPONSE: 3,
};

export const ResponseType = {
  LINE_CHART: 'line_chart',
  DONUT_CHART: 'donut_chart',
  BAR_CHART: 'bar_chart',
  TEXT_ONLY: 'text_only',
};

export default function useSolomon() {
  const [step, setStep] = useState(null);
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [isFetchingRecentSearches, setIsFetchingRecentSearches] = useState(true);

  const toast = useToastMessage();

  useEffect(() => {
    const fetchRecentSearches = async () => {
      setIsFetchingRecentSearches(true);
      // Simulating API call to fetch recent searches
      setTimeout(() => {
        const mockRecentSearches = [
          'Display a chart of my followers categorized by gender',
          'Display a bar chart of my followers categorized by age',
          'What are the view trends for my content over the past week?',
          'How can I increase engagement to attract followers on Instagram?',
          'What was the total donation amount received in July 2024?',
        ];
        setRecentSearches(mockRecentSearches);
        setIsFetchingRecentSearches(false);
      }, 2000);
    };

    fetchRecentSearches();
  }, []);

  // MOCK
  const getResponseType = (prompt) => {
    if (prompt.toLowerCase().includes('bar')) {
      return ResponseType.BAR_CHART;
    }
    if (prompt.toLowerCase().includes('gender')) {
      return ResponseType.DONUT_CHART;
    }
    if (prompt.toLowerCase().includes('trends')) {
      return ResponseType.LINE_CHART;
    }
    if (prompt.toLowerCase().includes('instagram') || prompt.toLowerCase().includes('2024')) {
      return ResponseType.TEXT_ONLY;
    }

    return ResponseType.BAR_CHART;
  };

  const getDataForType = (type, prompt) => {
    switch (type) {
      case ResponseType.LINE_CHART:
        return lineData;
      case ResponseType.DONUT_CHART:
        return donutData;
      case ResponseType.BAR_CHART:
        return barData;
      case ResponseType.TEXT_ONLY:
        if (prompt.toLowerCase().includes('instagram')) {
          return textData1;
        }
        return textData2;
      default:
        return null;
    }
  };

  const onSubmit = (prompt) => {
    setStep(Steps.ANALYSING);
    setIsLoading(true);
    // Simulating API call
    setTimeout(() => {
      setResponse({
        type: getResponseType(prompt),
        data: getDataForType(getResponseType(prompt), prompt),
      });
      setIsLoading(false);
      setStep(Steps.RESPONSE);
    }, 2000);
  };

  const onDownload = async () => {
    try {
      const dataUrl = await htmlElementToImageUrl('response-wrapper', () => ({
        backgroundColor: 'white',
      }));
      const link = document.createElement('a');
      link.download = 'solomon_response.png';
      link.href = dataUrl;
      link.click();
    } catch (error) {
      logger.log('Error generating image:', error);
    }
  };

  const onCopy = () =>
    copyToClipboard(response.data.text_data, () => {
      toast.show({ success: 'Copied!' });
    });

  const onClose = () => {
    setStep(null);
    setPrompt('');
  };

  const onClear = () => {
    setPrompt('');
  };

  return {
    step,
    prompt,
    response,
    recentSearches,
    isLoading,
    isFetchingRecentSearches,
    setRecentSearches,
    setStep,
    setPrompt,
    setResponse,
    onSubmit,
    onClear,
    onDownload,
    onCopy,
    onClose,
  };
}
