import { ReactNode } from 'react';

import { colors } from '@pray/shared/components/foundations';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';

import WarningIcon from '../../assets/WarningIcon';

export function getStatusData(status: LeadListStatus): { label: string; color: string; icon?: ReactNode } {
  switch (status) {
    case LeadListStatus.COMPLETE:
      return {
        label: '',
        color: colors.text_success,
        icon: null,
      };
    case LeadListStatus.FAILED:
      return {
        label: 'Failed',
        color: colors.attention,
        icon: <WarningIcon />,
      };
    case LeadListStatus.PROCESSING:
      return {
        label: 'Processing',
        color: colors.text_secondary,
        icon: <Spinner size="small" color="black" />,
      };
    default:
      return {
        label: '',
        color: colors.text_secondary,
        icon: null,
      };
  }
}
