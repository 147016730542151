import React from 'react';

export default function PrayLogoPrimary() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2363 306"
      width="2363"
      height="306"
      fill="none"
      style={{ height: 20, width: 155 }}
    >
      <path
        d="M116.485 6.03125H0V300.719H53.2897V206.587H110.547C182.248 206.587 228.588 166.808 228.588 105.272C228.692 44.9807 184.66 6.03125 116.485 6.03125ZM174.572 106.517C174.572 137.998 150.144 158.38 112.336 158.38H53.4192V54.6531H112.336C151.882 54.705 174.572 73.5833 174.572 106.517Z"
        fill="white"
      />
      <path
        d="M552.786 99.8262C552.786 41.9727 510.751 6.03125 443.095 6.03125H311.647V300.719H364.937V198.626H427.873L499.834 300.719H562.744L483.393 189.472C528.203 176.688 552.786 144.896 552.786 99.8262ZM364.937 54.7051H438.92C477.455 54.7051 498.563 71.768 498.563 102.756C498.563 132.189 475.224 151.223 439.231 151.223H364.833L364.937 54.7051Z"
        fill="white"
      />
      <path
        d="M750.281 3.93236L620.623 300.72H675.313L705.342 230.031H842.78L872.264 300.046L872.497 300.591H928.873L799.214 3.80273L750.281 3.93236ZM823.124 182.602H725.05L773.879 68.6579L823.124 182.602Z"
        fill="white"
      />
      <path
        d="M1076.22 134.964L994.973 6.47209L994.688 6.03125H931.855L1048.94 184.623V300.719H1102.64V183.352L1218.79 7.45749L1219.72 6.03125H1158.55L1076.22 134.964Z"
        fill="white"
      />
      <path d="M1288.23 241.597H1230.77V300.721H1288.23V241.597Z" fill="white" />
      <path
        d="M1589.9 218.829C1564.87 242.167 1542.18 256.663 1506.42 256.663C1451.84 256.663 1410.66 212.086 1410.66 152.936C1410.66 94.3048 1451.84 50.0912 1506.42 50.0912C1535.78 50.0912 1560.88 61.2418 1587.8 86.2399L1588.5 86.8882L1622.73 47.4462L1622.1 46.8497C1598.35 24.1595 1566.09 0.976562 1506.84 0.976562C1420.2 0.976562 1354.88 66.6616 1354.88 153.766C1354.88 195.127 1370.05 233.428 1397.61 261.616C1411.59 275.785 1428.28 286.992 1446.69 294.566C1465.09 302.141 1484.84 305.927 1504.74 305.7C1554.01 305.7 1588.63 290.582 1624.21 253.525L1624.83 252.903L1590.55 218.18L1589.9 218.829Z"
        fill="white"
      />
      <path
        d="M1839.21 1.00195C1796.13 1.00195 1756.66 17.0538 1728.06 46.1491C1699.99 74.9133 1684.43 113.603 1684.76 153.792C1684.76 240.429 1750.78 305.751 1838.38 305.751C1881.45 305.751 1920.92 289.725 1949.52 260.604C1977.59 231.84 1993.15 193.15 1992.82 152.962C1992.82 66.324 1926.72 1.00195 1839.21 1.00195ZM1937.1 153.792C1937.1 212.449 1895.04 256.662 1839.26 256.662C1783.01 256.662 1740.59 212.086 1740.59 152.936C1740.59 94.3042 1782.65 50.0906 1838.43 50.0906C1894.62 50.1165 1937.1 94.6932 1937.1 153.792Z"
        fill="white"
      />
      <path
        d="M2305.87 6.03125L2217.26 143.754L2128.91 6.44616L2128.63 6.03125H2072.43V300.719H2124.87V93.6285L2214.82 228.369L2215.11 228.758H2217.76L2308.83 92.7727V300.719H2362.09V6.03125H2305.87Z"
        fill="white"
      />
    </svg>
  );
}
