import { DateTime, Info } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import { ALL_DAY_DAILY_SERIES_IDS, PRAY_ARTIST_NAME } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useGetDailySeriesFromArtist from 'hooks/useGetDailySeriesFromArtist';
import useQueryParams from 'hooks/useQueryParams';
import { ChevronLeft, ChevronRight } from 'images/icons';

import CalendarHourItems from './CalendarHourItems';
import CalendarRowItems from './CalendarRowItems';
import { useToastMessage } from '../StudioToastMessage';

import styles from './EventsCalendar.module.scss';

// The list of views the events calendar can be in.
const viewTypes = [
  {
    name: 'Month',
    value: 'month',
  },
  {
    name: 'Day',
    value: 'day',
  },
];

const DATE_FORMAT = 'yyyy-MM-dd';

export default function EventsCalendar() {
  const { params } = useQueryParams();
  const navigate = useNavigate();

  const toast = useToastMessage();
  const { artists } = useStudioContext();

  // Forcing the artist to be PRAY
  const artistId = artists.find((artist) => artist.name === PRAY_ARTIST_NAME)?.id;
  const { dailySeries } = useGetDailySeriesFromArtist(artistId);
  const [dailyEventItems, setDailyEventItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dailySeriesId, setDailySeriesId] = useState(params.dailySeriesId || '*');
  const [viewType, setViewType] = useState(params.viewType || viewTypes[0].value);
  const [currentDate, setCurrentDate] = useState(() => {
    return params.currentDate && DateTime.fromISO(params.currentDate).isValid
      ? DateTime.fromFormat(params.currentDate, DATE_FORMAT)
      : DateTime.now();
  });

  // Update URL when states changes on the calendar
  useEffect(() => {
    const params = new URLSearchParams();
    params.set('viewType', viewType);
    params.set('currentDate', currentDate.toFormat(DATE_FORMAT));
    params.set('dailySeriesId', dailySeriesId);
    navigate(`?${params.toString()}`, { replace: true });
  }, [viewType, currentDate, dailySeriesId, navigate]);

  /**
   * An array of short week day names starting from Sunday.
   */
  const shortWeekDays = Array.from({ length: 7 }).map((_, i) => Info.weekdays('short')[(i + 6) % 7]);

  /**
   * An async function that fetches the daily item using the provided IDs,
   * maps the API response to the appropriate data object, and sets the daily item data.
   * @async
   * @function
   * @param {number} artistId - The ID of the artist.
   * @param {DateTime} currentDate - The current date choosen by the user
   * @param {string} dailySeriesId - The ID of the daily series or all daily series from Artist = *
   * @returns {Promise<object>}
   */
  const fetchDailyItems = async (artistId, currentDate, dailySeriesId = '*') => {
    try {
      setIsLoading(true);
      const startDate =
        viewType === 'month' ? currentDate.startOf('month').toFormat(DATE_FORMAT) : currentDate.toFormat(DATE_FORMAT);
      const endDate = viewType === 'month' ? currentDate.endOf('month').toFormat(DATE_FORMAT) : startDate;
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const response = await studioService.dailyItems.getAllDailyItemsByPeriod({
        artistId,
        dailySeriesId,
        startDate,
        endDate,
        userTimezone,
      });
      if (!response.data) throw Error('Not found');
      return response.data;
    } catch (error) {
      logger.error(error);
      toast.show({ error: 'Daily Items not found ' });
      return {};
    } finally {
      setIsLoading(false);
    }
  };

  const markAllDayEvents = (events) => {
    return events?.map((event) => {
      return {
        ...event,
        is_all_day: ALL_DAY_DAILY_SERIES_IDS.includes(event.daily_series_id),
      };
    });
  };

  /**
   * useEffect hook that fetches the data of a daily item with the provided IDs
   */
  useEffect(() => {
    async function load() {
      const response = await fetchDailyItems(artistId, currentDate, dailySeriesId);
      if (!active) {
        return;
      }
      setDailyEventItems(response);
    }

    let active = true;
    if (artistId && currentDate) load();
    return () => {
      active = false;
    };
  }, [artistId, currentDate, dailySeriesId, viewType]);

  /**
   * A React hook that generates an array of array of objects, representing each week in a month, containing
   * the dates of the days in the week and their corresponding month.
   * @param {DateTime} currentDate - A date time object representing the current date.
   * @return {Array} - An array of objects representing each week in a month, containing the dates of the days in
   *                   the week and their corresponding month.
   */
  const calendarWeeks = useMemo(() => {
    const firstDayOfTheMonth = currentDate.startOf('month');

    const daysInMonthArray = Array.from({
      length: firstDayOfTheMonth.daysInMonth,
    }).map((_, i) => {
      return firstDayOfTheMonth.set({ day: i + 1 });
    });

    const firstWeekDay = firstDayOfTheMonth.weekday % 7;

    const previousMonthFillArray = Array.from({
      length: firstWeekDay,
    })
      .map((_, i) => {
        return firstDayOfTheMonth.minus({
          days: i + 1,
        });
      })
      .reverse();

    const lastDayInCurrentMonth = firstDayOfTheMonth.set({ day: firstDayOfTheMonth.daysInMonth });
    const lastWeekDay = lastDayInCurrentMonth.weekday;

    const nextMonthFillArray = Array.from({
      length: 7 - (lastWeekDay + 1),
    }).map((_, i) => {
      return lastDayInCurrentMonth.plus({
        days: i + 1,
      });
    });

    const calendarDays = [
      ...previousMonthFillArray.map((date) => {
        return {
          date,
          type: 'previous',
          month: date.monthLong,
        };
      }),
      ...daysInMonthArray.map((date) => {
        return {
          date,
          type: 'current',
          month: date.monthLong,
        };
      }),
      ...nextMonthFillArray.map((date) => {
        return {
          date,
          type: 'next',
          month: date.monthLong,
        };
      }),
    ];

    const calendarWeeks = calendarDays.reduce((weeks, _, i, original) => {
      const isNewWeek = i % 7 === 0;

      if (isNewWeek) {
        weeks.push({
          week: i / 7 + 1,
          days: original.slice(i, i + 7),
        });
      }

      return weeks;
    }, []);

    return calendarWeeks;
  }, [currentDate]);

  /**
   * Handler for the calendar buttons
   */

  const handlePreviousButton = () => {
    const parameters = viewType === 'month' ? { month: 1 } : { day: 1 };

    const previousMonth = currentDate.minus(parameters);
    setCurrentDate(previousMonth);
  };

  const handleNextButton = () => {
    const parameters = viewType === 'month' ? { month: 1 } : { day: 1 };

    const nextMonth = currentDate.plus(parameters);
    setCurrentDate(nextMonth);
  };

  const handleTodayButton = () => {
    setCurrentDate(DateTime.now());
  };

  const navigateToDayViewAtDate = (date) => {
    setViewType('day');
    setCurrentDate(date);
  };

  const renderMonthView = () => {
    return (
      <table className={styles.calendarTable}>
        <thead>
          <tr>
            {shortWeekDays.map((weekDay) => (
              <th className={styles.calendarTableHeaderItem} key={weekDay}>
                {weekDay}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          <CalendarRowItems
            weeks={calendarWeeks}
            events={dailyEventItems}
            navigateToDayViewAtDate={navigateToDayViewAtDate}
          />
        </tbody>
      </table>
    );
  };

  const renderDayView = () => {
    const dailyEventsFromDate = markAllDayEvents(dailyEventItems[currentDate.toFormat(DATE_FORMAT)]);

    return (
      <table className={`${styles.calendarTable} ${styles.withHourLabels}`}>
        <tbody>
          <CalendarHourItems
            date={currentDate}
            hours={Array.from({ length: 25 }).map((_, i) => i - 1)}
            events={dailyEventsFromDate}
          />
        </tbody>
      </table>
    );
  };

  return (
    <div className={styles.eventsCalendarWrapper}>
      <div className={styles.navigationActionableRow}>
        <div className={styles.eventsCalendarSelectContainer}>
          <div className={styles.eventsCalendarSelectWrapper}>
            <Text className={styles.eventsCalendarSelectLabel}>Series:</Text>

            <select
              className={styles.eventsCalendarSelectInput}
              value={dailySeriesId}
              onChange={(e) => setDailySeriesId(e.target.value)}
            >
              <option value="*">All</option>
              {dailySeries.map((ds) => (
                <option key={ds.id} value={ds.id}>
                  {ds.title}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.eventsCalendarSelectWrapper}>
            <Text className={styles.eventsCalendarSelectLabel}>View:</Text>

            <select
              className={styles.eventsCalendarSelectInput}
              value={viewType}
              onChange={(e) => setViewType(e.target.value)}
            >
              {viewTypes.map((viewType) => (
                <option key={viewType.value} value={viewType.value}>
                  {viewType.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={styles.calendarNavigationWrapper}>
          <Text className={styles.currentMonthYearText}>
            {viewType === 'month' && `${currentDate.get('monthLong')} ${currentDate.get('year')}`}
            {viewType === 'day' && currentDate.toFormat('MMMM dd, yyyy')}&nbsp;
          </Text>

          <Button onClick={() => handleTodayButton()} className={styles.todayButton}>
            Today
          </Button>

          <div>
            <Button type="button" onClick={() => handlePreviousButton()}>
              <ChevronLeft />
            </Button>
            <Button type="button" onClick={() => handleNextButton()}>
              <ChevronRight />
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        {isLoading && (
          <div className={styles.spinnerWrapper}>
            <Spinner color="black" />
          </div>
        )}

        {viewType === 'month' && !isLoading && renderMonthView()}

        {viewType === 'day' && !isLoading && renderDayView()}
      </div>
    </div>
  );
}
