import * as config from './config';

export const SUBSCRIPTION_NAME = 'PRAY Premium';

export const COUNTRY_CODE_MAP = {
  'United States': '1',
  Canada: '1',
  'United Kingdom': '44',
  Afghanistan: '93',
  'Åland Islands': '358',
  Albania: '355',
  Algeria: '213',
  'American Samoa': '1684',
  Andorra: '376',
  Angola: '244',
  Anguilla: '1264',
  'Antigua and Barbuda': '1268',
  Argentina: '54',
  Armenia: '374',
  Aruba: '297',
  Australia: '61',
  Austria: '43',
  Azerbaijan: '994',
  Bahamas: '1242',
  Bahrain: '973',
  Bangladesh: '880',
  Barbados: '1246',
  Belarus: '375',
  Belgium: '32',
  Belize: '501',
  Benin: '229',
  Bermuda: '1441',
  Bhutan: '975',
  Bolivia: '591',
  'Bosnia and Herzegovina': '387',
  Botswana: '267',
  Brazil: '55',
  'British Indian Ocean Territory': '246',
  'British Virgin Islands': '1284',
  Brunei: '673',
  Bulgaria: '359',
  'Burkina Faso': '226',
  Burundi: '257',
  Cambodia: '855',
  Cameroon: '237',
  'Cape Verde': '238',
  'Caribbean Netherlands': '599',
  'Cayman Islands': '1345',
  'Central African Republic': '236',
  Chad: '235',
  Chile: '56',
  China: '86',
  'Christmas Island': '61',
  Cocos: '61',
  Colombia: '57',
  Comoros: '269',
  'Congo (DRC)': '243',
  'Congo (Republic)': '242',
  'Cook Islands': '682',
  'Costa Rica': '506',
  Croatia: '385',
  Cuba: '53',
  Curaçao: '599',
  Cyprus: '357',
  'Czech Republic': '420',
  'Côte d’Ivoire': '225',
  Denmark: '45',
  Djibouti: '253',
  Dominica: '1767',
  'Dominican Republic': '1',
  Ecuador: '593',
  Egypt: '20',
  'El Salvador': '503',
  'Equatorial Guinea': '240',
  Eritrea: '291',
  Estonia: '372',
  Ethiopia: '251',
  'Falkland Islands': '500',
  'Faroe Islands': '298',
  Fiji: '679',
  Finland: '358',
  France: '33',
  'French Guiana': '594',
  'French Polynesia': '689',
  Gabon: '241',
  Gambia: '220',
  Georgia: '995',
  Germany: '49',
  Ghana: '233',
  Gibraltar: '350',
  Greece: '30',
  Greenland: '299',
  Grenada: '1473',
  Guadeloupe: '590',
  Guam: '1671',
  Guatemala: '502',
  Guernsey: '44',
  Guinea: '224',
  'Guinea-Bissau': '245',
  Guyana: '592',
  Haiti: '509',
  Honduras: '504',
  'Hong Kong': '852',
  Hungary: '36',
  Iceland: '354',
  India: '91',
  Indonesia: '62',
  Iran: '98',
  Iraq: '964',
  Ireland: '353',
  'Isle of Man': '44',
  Israel: '972',
  Italy: '39',
  Jamaica: '1876',
  Japan: '81',
  Jersey: '44',
  Jordan: '962',
  Kazakhstan: '7',
  Kenya: '254',
  Kiribati: '686',
  Kosovo: '383',
  Kuwait: '965',
  Kyrgyzstan: '996',
  Laos: '856',
  Latvia: '371',
  Lebanon: '961',
  Lesotho: '266',
  Liberia: '231',
  Libya: '218',
  Liechtenstein: '423',
  Lithuania: '370',
  Luxembourg: '352',
  Macau: '853',
  'Macedonia (FYROM)': '389',
  Madagascar: '261',
  Malawi: '265',
  Malaysia: '60',
  Maldives: '960',
  Mali: '223',
  Malta: '356',
  'Marshall Islands': '692',
  Martinique: '596',
  Mauritania: '222',
  Mauritius: '230',
  Mayotte: '262',
  Mexico: '52',
  Micronesia: '691',
  Moldova: '373',
  Monaco: '377',
  Mongolia: '976',
  Montenegro: '382',
  Montserrat: '1664',
  Morocco: '212',
  Mozambique: '258',
  'Myanmar (Burma)': '95',
  Namibia: '264',
  Nauru: '674',
  Nepal: '977',
  Netherlands: '31',
  'New Caledonia': '687',
  'New Zealand': '64',
  Nicaragua: '505',
  Niger: '227',
  Nigeria: '234',
  Niue: '683',
  'Norfolk Island': '672',
  'North Korea': '850',
  'Northern Mariana Islands': '1670',
  Norway: '47',
  Oman: '968',
  Pakistan: '92',
  Palau: '680',
  Palestine: '970',
  Panama: '507',
  'Papua New Guinea': '675',
  Paraguay: '595',
  Peru: '51',
  Philippines: '63',
  Poland: '48',
  Portugal: '351',
  'Puerto Rico': '1',
  Qatar: '974',
  Romania: '40',
  Russia: '7',
  Rwanda: '250',
  Réunion: '262',
  'Saint Barthélemy': '590',
  'Saint Helena': '290',
  'Saint Kitts and Nevis': '1869',
  'Saint Lucia': '1758',
  'Saint Martin': '590',
  'Saint Pierre and Miquelon': '508',
  'Saint Vincent and the Grenadine': '1784',
  Samoa: '685',
  'San Marino': '378',
  'Saudi Arabia': '966',
  Senegal: '221',
  Serbia: '381',
  Seychelles: '248',
  'Sierra Leone': '232',
  Singapore: '65',
  'Sint Maarten': '1721',
  Slovakia: '421',
  Slovenia: '386',
  'Solomon Islands': '677',
  Somalia: '252',
  'South Africa': '27',
  'South Korea': '82',
  'South Sudan': '211',
  Spain: '34',
  'Sri Lanka': '94',
  Sudan: '249',
  Suriname: '597',
  'Svalbard and Jan Mayen': '47',
  Swaziland: '268',
  Sweden: '46',
  Switzerland: '41',
  Syria: '963',
  'São Tomé and Príncipe': '239',
  Taiwan: '886',
  Tajikistan: '992',
  Tanzania: '255',
  Thailand: '66',
  'Timor-Leste': '670',
  Togo: '228',
  Tokelau: '690',
  Tonga: '676',
  'Trinidad and Tobago': '1868',
  Tunisia: '216',
  Turkey: '90',
  Turkmenistan: '993',
  'Turks and Caicos Islands': '1649',
  Tuvalu: '688',
  'U.S. Virgin Islands': '1340',
  Uganda: '256',
  Ukraine: '380',
  'United Arab Emirates': '971',
  Uruguay: '598',
  Uzbekistan: '998',
  Vanuatu: '678',
  'Vatican City': '39',
  Venezuela: '58',
  Vietnam: '84',
  'Wallis and Futuna': '681',
  'Western Sahara': '212',
  Yemen: '967',
  Zambia: '260',
  Zimbabwe: '263',
};

export const COUNTRIES = Object.keys(COUNTRY_CODE_MAP);

export const GEO_URL = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json';

export const US_STATES = {
  AL: { name: 'Alabama', coords: [-86.9023, 32.3182] },
  AK: { name: 'Alaska', coords: [-149.4937, 64.2008] },
  AZ: { name: 'Arizona', coords: [-111.0937, 34.0489] },
  AR: { name: 'Arkansas', coords: [-92.3731, 34.9697] },
  CA: { name: 'California', coords: [-119.4179, 36.7783] },
  CO: { name: 'Colorado', coords: [-105.7821, 39.5501] },
  CT: { name: 'Connecticut', coords: [-72.7554, 41.6032] },
  DE: { name: 'Delaware', coords: [-75.5277, 38.9108] },
  DC: { name: 'District of Columbia', coords: [-77.0369, 38.9072] },
  FL: { name: 'Florida', coords: [-81.5158, 27.9944] },
  GA: { name: 'Georgia', coords: [-83.5002, 32.1656] },
  HI: { name: 'Hawaii', coords: [-155.5828, 19.8968] },
  ID: { name: 'Idaho', coords: [-114.742, 44.0682] },
  IL: { name: 'Illinois', coords: [-89.3985, 40.6331] },
  IN: { name: 'Indiana', coords: [-86.1349, 40.2672] },
  IA: { name: 'Iowa', coords: [-93.0977, 41.878] },
  KS: { name: 'Kansas', coords: [-98.4842, 39.0119] },
  KY: { name: 'Kentucky', coords: [-84.27, 37.8393] },
  LA: { name: 'Louisiana', coords: [-91.9623, 30.9843] },
  ME: { name: 'Maine', coords: [-69.4455, 45.2538] },
  MD: { name: 'Maryland', coords: [-76.6413, 39.0458] },
  MA: { name: 'Massachusetts', coords: [-71.3824, 42.4072] },
  MI: { name: 'Michigan', coords: [-85.6024, 44.3148] },
  MN: { name: 'Minnesota', coords: [-94.6859, 46.7296] },
  MS: { name: 'Mississippi', coords: [-89.3985, 32.3547] },
  MO: { name: 'Missouri', coords: [-91.8318, 37.9643] },
  MT: { name: 'Montana', coords: [-110.3626, 46.8797] },
  NE: { name: 'Nebraska', coords: [-99.9018, 41.4925] },
  NV: { name: 'Nevada', coords: [-116.4194, 38.8026] },
  NH: { name: 'New Hampshire', coords: [-71.5724, 43.1939] },
  NJ: { name: 'New Jersey', coords: [-74.4057, 40.0583] },
  NM: { name: 'New Mexico', coords: [-105.8701, 34.5199] },
  NY: { name: 'New York', coords: [-75.5268, 42.9538] },
  NC: { name: 'North Carolina', coords: [-79.0193, 35.7596] },
  ND: { name: 'North Dakota', coords: [-101.002, 47.5515] },
  OH: { name: 'Ohio', coords: [-82.9071, 40.4173] },
  OK: { name: 'Oklahoma', coords: [-97.0929, 35.0078] },
  OR: { name: 'Oregon', coords: [-120.5542, 43.8041] },
  PA: { name: 'Pennsylvania', coords: [-77.1945, 41.2033] },
  RI: { name: 'Rhode Island', coords: [-71.4774, 41.5801] },
  SC: { name: 'South Carolina', coords: [-81.1637, 33.8361] },
  SD: { name: 'South Dakota', coords: [-99.9018, 43.9695] },
  TN: { name: 'Tennessee', coords: [-86.5804, 35.5175] },
  TX: { name: 'Texas', coords: [-99.9018, 31.9686] },
  UT: { name: 'Utah', coords: [-111.0937, 39.321] },
  VT: { name: 'Vermont', coords: [-72.5778, 44.5588] },
  VA: { name: 'Virginia', coords: [-78.6569, 37.4316] },
  WA: { name: 'Washington', coords: [-120.7401, 47.7511] },
  WV: { name: 'West Virginia', coords: [-80.4549, 38.5976] },
  WI: { name: 'Wisconsin', coords: [-89.6165, 43.7844] },
  WY: { name: 'Wyoming', coords: [-107.2903, 43.0759] },
};

export const EVENTS = {
  DOM_EVENTS: {
    AUTH_STATE_CHANGED: 'authStateChanged',
  },

  PRAY_EVENTS: {
    NAMES: {
      AD: 'Ad',
      CHECKOUT_COMPLETED: 'Checkout Completed',
      ENTERED_EXPERIMENT: 'Entered Experiment',
      ERROR: 'Error',
      LISTEN: 'Listen',
      ROUTE: 'Route',
      SUBMIT_FORM: 'Submit Form',
      TAP: 'Tap',
      VIEW: 'View',
      WATCH: 'Watch',
    },
    SUBMIT_FORM_EVENT_NAMES: {
      EMAIL: 'Email Form',
      EMAIL_LEAD: 'Email Lead Form',
      EMAIL_SUBSCRIBE: 'Subscribe Email Form',
      INTENT: 'Intent Form',
    },
    VIEW_EVENT_NAMES: {
      PAGE_VIEW: 'Page View',
      PAGES: {
        BIBLE_CHAPTER: 'Bible Chapter',
        BIBLE_SITEMAP: 'Bible Sitemap',
        BIBLE_VERSE: 'Bible Verse',
        CHECKOUT: 'Checkout',
        CONTENT_DETAILS: 'Content Details',
        DISCOVER: 'Discover',
        DONATE: 'Donate',
        DONATION_DETAILS: 'Fund History',
        DONATION_HISTORY: 'Donation History',
        MANAGE_COOKIES: 'Manage Cookies',
        MANAGE_DONATIONS: 'Manage Donations',
        MANAGE_SUBSCRIPTIONS: 'Manage Subscriptions',
        ONBOARDING: 'Onboarding',
        PODCASTS: 'Podcasts',
        PRIVACY_POLICY: 'Privacy Policy',
        RECURRING_DONATIONS: 'Recurring Donations',
        SERIES: 'Discover Series',
        SIGNUP: 'Sign Up',
        TERMS_OF_SERVICE: 'Terms of Service',
      },
      TABS: {
        BOTTOM_TAB_BAR: 'Bottom Tab Bar',
        SIDE_TAB_BAR: 'Side Tab Bar',
      },
      AUDIO_PLAYER: 'Audio Player',
      SIGNUP_MODAL: 'Sign Up Modal',
      SHARE_MODAL: 'Share Modal',
      DAILY_DEVOTIONAL: 'Daily Devotional',
      DAILY_DEVOTIONAL_STREAK: 'Daily Devotional Streak',
      DOWNLOAD_PRAY_MODULE: 'Download Pray Module',
      SIGN_UP_BANNER: 'Sign Up Banner',
      SUBSCRIBE_FLOW: {
        PREVIEW: 'Subscribe Flow Preview View',
        INTENT: 'Subscribe Flow Intent View',
        LANDER: 'Subscribe Flow Landing Homepage View',
        TESTIMONIAL: 'Subscribe Flow Testimonial View',
        EMAIL: 'Subscribe Flow Email View',
        PROPS: 'Subscribe Flow Props View',
        EMAIL_AND_PROPS: 'Subscribe Flow Email And Props View',
        MAGIC_LINK_SENT: 'Subscribe Flow Magic Link Sent View',
        CHECKOUT: 'Subscribe Flow Checkout View',
        CHECKOUT_SUCCESS: 'Subscribe Flow Checkout Success View',
      },
      FAMILY_PLAN_FLOW: {
        INTENT: 'Family Flow Intent View',
        TESTIMONIAL: 'Family Flow Testimonial View',
        EMAIL_AND_PROPS: 'Family Plan',

        FAMILY_PLAN_DESCRIPTION: {
          NONE: 'none',
          PURCHASER: 'purchaser',
          REDEEMER: 'redeemer',
        },
      },
      DONATION_FLOW: {
        DONATION_PAYWALL: 'Donation Paywall',
        CHECKOUT_PAGE: 'Donation Checkout',
        LANDING_PAGE: 'Donation Landing',
        PAYWALL_PAGE: 'Donation Paywall',
        QUIZ_PAGE: 'Donation Quiz',
        SUCCESS_PAGE: 'Donation Success',
      },
    },
  },

  FACEBOOK_EVENTS: {
    // https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
    NAMES: {
      ADD_PAYMENT_INFO: 'AddPaymentInfo',
      ADD_TO_CART: 'AddToCart',
      ADD_TO_WISHLIST: 'AddToWishlist',
      COMPLETE_REGISTRATION: 'CompleteRegistration',
      CONTACT: 'Contact',
      CUSTOMIZE_PRODUCT: 'CustomizeProduct',
      DONATE: 'Donate',
      FIND_LOCATION: 'FindLocation',
      INITIATE_CHECKOUT: 'InitiateCheckout',
      LEAD: 'Lead',
      PAGE_VIEW: 'PageView',
      PURCHASE: 'Purchase',
      SCHEDULE: 'Schedule',
      SEARCH: 'Search',
      START_TRIAL: 'StartTrial',
      SUBMIT_APPLICATION: 'SubmitApplication',
      SUBSCRIBE: 'Subscribe',
      VIEW_CONTENT: 'ViewContent',
    },

    CONTENT_CATEGORIES: {
      MEDIA_BUY: 'Media Buy',
      SUBSCRIBE_FLOW: 'Subscribe Flow',
      FAMILY_FLOW: 'Family Flow',
    },
  },

  BRANCH_EVENTS: {
    NAMES: {
      FIRST_LISTEN: 'First Listen',
      PURCHASE: 'PURCHASE',
    },
    CONTENT_SCHEMAS: {
      COMMERCE_PRODUCT: 'COMMERCE_PRODUCT',
    },
    PRODUCT_CATEGORIES: {
      SUBSCRIPTION: 'Subscription',
    },
  },

  GOOGLE_EVENTS: {
    ACTIONS: {
      STARTED_TRIAL: 'Started Trial',
      STARTED_SUBSCRIPTION: 'Started Subscription',
    },
  },
};

export const LOCAL_STORAGE = {
  USER: 'user',
  PRAY_DEVICE_ID: 'prayDeviceId',

  // set cookie domain to '.pray.com' on live sites so that data is shared
  // across subdomains.Sets to current domain otherwise
  COOKIE_DOMAIN:
    // We cannot import/use isOnBrowser here to avoid a circular dependency
    typeof window !== 'undefined' && window.location.hostname.indexOf('.pray.com') > -1 ? '.pray.com' : null,

  // store all the experiment name and variations a user has been bucketed into
  // example format:
  // {
  //   experimentName: variationName,
  //   experimentName: variationName,
  //   experimentName: variationName,
  // }
  ABN_EXPERIMENTS: 'abnExperiments',

  // object to store any info/flags related to this specific device
  // example format:
  // {
  //   isFirstListenAlreadyTracked: boolean,
  // }
  DEVICE_DETAILS: {
    KEY: 'deviceDetails',
    VALUES: {
      IS_FIRST_LISTEN_ALREADY_TRACKED: 'isFirstListenAlreadyTracked',
    },
  },

  COOKIE_BANNER_IS_ACCEPTED: 'cookieBannerIsAccepted',
  COOKIE_BANNER_IS_DISMISSED: 'cookieBannerIsDismissed',

  // store opt-in/out cookie settings here
  // example format:
  // {
  //   isBranchOptOut: true,
  //   isFacebookOptOut: true,
  //   isGoogleOptOut: true,
  // }
  COOKIE_SETTINGS: 'cookieSettings',
};

export const SESSION_STORAGE = {
  CACHE: 'cache',
  DEBUG: 'debug',
  NAVIGATION_HISTORY: 'navigationHistory',
  PLATFORM_TYPE: 'platformType',
  SESSION_TOKEN: 'pray_session_token',
};

export const THEME_COLORS = {
  color_pray_blue: '#4859BE',
  color_pray_white: '#FFFFFF',
  color_pray_purple: '#677DFF',
  color_pray_blue_2: '#28A7FF',
  color_pray_green: '#0DCE94',
  color_pray_dark_green: '#088564',
  color_pray_yellow: '#FAB565',
  color_pray_red: '#FF5B59',
  color_pray_dark_background: '#191C26',
  color_pray_light_background: '#FBFCFF',
};

export const SHARING_CHANNELS = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINK: 'link',
  EXTERNAL: 'external',
};

export const OBJECT_TYPES = {
  DAILY_ITEM: 'daily',
  COMMUNITY: 'community',
  POST: 'post',
  CONTENT: 'content',
  PAGE: 'page',
  CONTENT_SERIES: 'content-series',
};

export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/pray/',
  INSTAGRAM: 'https://www.instagram.com/pray/',
  LINKED_IN: 'https://www.linkedin.com/company/pray.com/',
  TWITTER: 'https://twitter.com/pray/',
  YOUTUBE: 'https://www.youtube.com/c/pray/',
};

export const DISCOVER_MODALS = {
  CONGRATULATIONS: 'congratulations',
  CONTENT_NOT_FOUND: 'content-not-found',
  WELCOME: 'welcome',
};

export const REGISTER_VIEW_NAMES = {
  SUBSCRIBE_FLOW: 'Subscribe Flow',
};

export const BRANCH_LINKS = {
  OPEN_APP: 'https://link.pray.com/AFvjnRzF6hb',
  APP_DOWNLOAD: {
    AUDIO_PLAYER: 'https://link.pray.com/fwEdQLtV1hb',
    DAILY_DELIGHT: 'https://link.pray.com/l4XFAsAV1hb',
    DOWNLOAD_ANDROID_PAGE: 'https://link.pray.com/cxNghjIDAib',
    DOWNLOAD_IOS_PAGE: 'https://link.pray.com/MLuMnKCDAib',
    DOWNLOAD_LEAD_PAGE: 'https://link.pray.com/fKCJx1JkEvb',
    FOOTER: 'https://link.pray.com/haJuUz0T1hb',
    SIGNUP_FLOW: 'https://link.pray.com/A4MOMFra3hb',
    JOIN_MILLIONS_SECTION: 'https://link.pray.com/L2sUfIEU1hb',
    PRIVACY_POLICY: 'https://link.pray.com/3leC8wwV1hb',
  },
};

export const PLATFORMS = {
  ANDROID: 'android',
  IOS: 'ios',
  OTHER: 'other',
};

export const LOCALES = {
  DEFAULT: 'en',
  EN: 'en',
  ES: 'es',
  FR: 'fr',
  PT: 'pt',
  DE: 'de',
  ZH: 'zh',
  HI: 'hi',
  SW: 'sw',
  KO: 'ko',
  TL: 'tl',
  JA: 'ja',
  AR: 'ar',
  IT: 'it',
  ID: 'id',
  NL: 'nl',
  TR: 'tr',
  FIL: 'fil',
  PL: 'pl',
  SV: 'sv',
  BG: 'bg',
  RO: 'ro',
  CS: 'cs',
  EL: 'el',
  FI: 'fi',
  HR: 'hr',
  MS: 'ms',
  SK: 'sk',
  DA: 'da',
  TA: 'ta',
  UK: 'uk',
};

// Development phone carriers to test Bango integration
const devPhoneCarriers = [];

if (config.ENV === 'local' || config.ENV === 'development') {
  devPhoneCarriers.push({ id: 'USA_PRAY', name: 'USA Pray' });
}

export const PHONE_CARRIERS = [...devPhoneCarriers, { id: 'USA_VERIZON', name: 'Verizon' }];

export const PRAY_SHARING_IMAGE = 'https://d339bgihw9yfmi.cloudfront.net/share_image.png';

export const PRAY_EXTERNAL_LINKS = {
  ABOUT_US: 'https://pray.com/articles/founders',
  ARTICLES: 'https://www.pray.com/articles',
  COOKIE_POLICY: 'https://www.pray.com/cookie-policy',
  HELP: 'https://help.pray.com',
  HELP_SYNDICATION_APPLE: 'https://help.pray.com/hc/en-us/articles/13155706430365',
  HELP_SYNDICATION_SPOTIFY:
    'https://help.pray.com/hc/en-us/articles/13155691119389-How-to-Upload-Your-Podcast-to-Spotify',
  PRIVACY_POLICY: 'https://www.pray.com/privacy-policy',
  TERMS_OF_SERVICE: 'https://www.pray.com/terms-of-service',
  WORK: 'https://www.pray.com/work',
  MERCH: 'https://praymerch.store/',
};

export const PLATFORM_TYPES = {
  CHROME_EXTENSION: 'chrome_extension',
};

export const CONTENT_VISIBILITY = {
  PRIVATE: 'private',
  PUBLIC: 'public',
  SCHEDULE: 'schedule',
};

export const CONTENT_ORDER = {
  DATE_PUBLISHED_NEWEST: { key: 'date_published_newest', label: 'Date Published (Newest)' },
  DATE_PUBLISHED_OLDEST: { key: 'date_published_oldest', label: 'Date Published (Oldest)' },
  DATE_CREATED_NEWEST: { key: 'date_created_newest', label: 'Date Added (Newest)' },
  DATE_CREATED_OLDEST: { key: 'date_created_oldest', label: 'Date Added (Oldest)' },
  DESCENDING_NUMBER: { key: 'descending_number', label: 'Descending Order' },
  MANUALLY: { key: 'default', label: 'Manually Sorted' },
};

export const PLACEHOLDER_IMAGE = '/placeholder.png';
