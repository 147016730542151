import React from 'react';

import styles from './Checkbox.module.scss';

export default function Checkbox({ partial = false, circular = false, dark = false, className = '', ...props }) {
  if (circular) {
    return <CircularCheckbox {...props} />;
  }

  const isPartial = partial && !props.checked;

  const classes = [
    // checkbox classes
    styles.checkbox,
    className,
    isPartial && styles.partial,
    dark && styles.dark,
  ]
    .filter(Boolean)
    .join(' ');

  return <input type="checkbox" className={classes} {...props} />;
}

const CircularCheckbox = ({ children = null, ...props }) => {
  return (
    <label className={styles.checkboxArea}>
      <div className={styles.circular}>
        <input id="checkbox" type="checkbox" {...props} />
        <label htmlFor="checkbox" />
        {children}
      </div>
    </label>
  );
};
