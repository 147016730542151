/* eslint-disable no-console */

const PRAY_DEBUG = '@pray/debug';

export default {
  info(...args) {
    console.log(new Date().toLocaleString(), '[INFO]', ...args);
  },

  debug(...args) {
    if (sessionStorage.getItem(PRAY_DEBUG)) {
      console.log(new Date().toLocaleString(), '[DEBUG]', ...args);
    }
  },

  error(...args) {
    console.error(new Date().toLocaleString(), '[ERROR]', ...args);
  },
};
