import TextInput from '@pray/shared/components/ui/TextInput';

import { VIEW_EVENT_NAMES } from 'constants.js';

import LeadsDropzone from '../../components/LeadsDropzone';
import TemplateNote from '../../components/TemplateNote';

export default function UploadCsvStep({
  activeStep,
  title,
  uploadState,
  onChangeTitle,
  onUploadFile,
  onRemoveFile,
  onCancel,
}) {
  return (
    <div data-viewname={VIEW_EVENT_NAMES.CONTACT_LISTS.UPLOAD_FLOW_CSV} className="flex flex-col gap-8">
      <TextInput
        name="title"
        label="List Name"
        placeholder="Enter List Name"
        maxLength={50}
        value={title}
        onChange={(e) => onChangeTitle(e.target.value)}
      />

      <LeadsDropzone
        uploadState={uploadState}
        activeStep={activeStep}
        onUploadFile={onUploadFile}
        onRemoveFile={onRemoveFile}
        onCancel={onCancel}
      />

      <TemplateNote />
    </div>
  );
}
