import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useSaveEmailCampaign() {
  const queryClient = useQueryClient();

  async function saveEmailCampaign({
    artistId = undefined,
    campaignId = undefined,
    name = undefined,
    status = undefined,
    automationType = undefined,
    scheduledAt = undefined,
    subjectText = undefined,
    previewText = undefined,
    previewImgUrl = undefined,
    rawHtml = undefined,
    rawCss = undefined,
    compiledHtmlCss = undefined,
  }) {
    const data = {
      artistId,
    };

    if (campaignId !== undefined) data.campaignId = campaignId;
    if (name !== undefined) data.name = name;
    if (status !== undefined) data.status = status;
    if (automationType !== undefined) data.automationType = automationType;
    if (scheduledAt !== undefined) data.scheduledAt = scheduledAt;
    if (subjectText !== undefined) data.subjectText = subjectText;
    if (previewText !== undefined) data.previewText = previewText;
    if (previewImgUrl !== undefined) data.previewImgUrl = previewImgUrl;
    if (rawHtml !== undefined) data.rawHtml = rawHtml;
    if (rawCss !== undefined) data.rawCss = rawCss;
    if (compiledHtmlCss !== undefined) data.compiledHtmlCss = compiledHtmlCss;

    let campaign;

    if (!campaignId) {
      campaign = await createCampaign(data);
    } else {
      campaign = await updateCampaign(data);
    }

    return campaign;
  }

  async function createCampaign(data) {
    const response = await studioService.emailCampaigns.createEmailCampaign(data);

    return response.data;
  }

  async function updateCampaign(data) {
    const response = await studioService.emailCampaigns.updateEmailCampaign(data);

    return response.data;
  }

  const mutation = useMutation({
    mutationFn: saveEmailCampaign,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistEmailCampaigns(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveEmailCampaign: mutateAsync,
    ...rest,
  };
}
