import React from 'react';

export function Profile(props) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 9.33337C13 7.67652 14.3431 6.33337 16 6.33337C17.6569 6.33337 19 7.67652 19 9.33337C19 10.9902 17.6569 12.3334 16 12.3334C14.3431 12.3334 13 10.9902 13 9.33337ZM16 4.33337C13.2386 4.33337 11 6.57195 11 9.33337C11 12.0948 13.2386 14.3334 16 14.3334C18.7614 14.3334 21 12.0948 21 9.33337C21 6.57195 18.7614 4.33337 16 4.33337ZM9 22.6667C9 21.2322 9.84767 19.8276 11.236 18.7398C12.6206 17.655 14.4077 17 16 17C17.5923 17 19.3794 17.655 20.7639 18.7398C22.1523 19.8276 23 21.2322 23 22.6667C23 23.3752 22.7988 23.8504 22.5037 24.1999C22.1934 24.5673 21.715 24.8705 21.0499 25.1037C19.6904 25.5803 17.8504 25.6667 16 25.6667C14.1496 25.6667 12.3096 25.5803 10.9501 25.1037C10.285 24.8705 9.80656 24.5673 9.4963 24.1999C9.20122 23.8504 9 23.3752 9 22.6667ZM16 15C13.9104 15 11.6975 15.8375 10.0025 17.1655C8.31138 18.4906 7 20.4193 7 22.6667C7 23.7991 7.33855 24.7444 7.96817 25.4901C8.58261 26.2178 9.41386 26.6845 10.2885 26.9911C12.0085 27.5941 14.1685 27.6667 16 27.6667C17.8315 27.6667 19.9915 27.5941 21.7115 26.9911C22.5861 26.6845 23.4174 26.2178 24.0318 25.4901C24.6615 24.7444 25 23.7991 25 22.6667C25 20.4193 23.6886 18.4906 21.9975 17.1655C20.3025 15.8375 18.0896 15 16 15Z"
        fill="#E3AF4A"
        {...props}
      />
    </svg>
  );
}
