import { variations } from './constants';
import PeriodicTableGroupCard from './PeriodicTableGroupCard';

const PeriodicTableGroupsSection = ({ segmentGroups, selectedGroup, onSelectGroup }) => {
  return (
    <div className="mx-auto w-5/6 xl:w-4/6">
      <div className="grid auto-cols-[minmax(0,_144px)] grid-flow-col place-content-center gap-3">
        {segmentGroups.map((group, groupIndex) =>
          group.segments.length === 0 ? null : (
            <PeriodicTableGroupCard
              key={`PeriodicTableGroupCard-Group-${group.id}`}
              value={`${group.value}%`}
              label="Viewership"
              isSelected={selectedGroup === group.id}
              onSelect={() => onSelectGroup(group.id)}
              variation={groupIndex % variations.length}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PeriodicTableGroupsSection;
