import { useEffect, useRef, useState } from 'react';

import useEventListener from '@pray/shared/hooks/useEventListener';

import { STUDIO_MESSAGE_SOURCE } from 'constants.js';

export default function PreviewFrame({ data, isLoading, onLoad, ...props }) {
  const iframeRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);

  function handleMessage(event) {
    const { source, type } = event.data;

    if (source === STUDIO_MESSAGE_SOURCE && type === 'get-data') {
      setIsConnected(true);
    }
  }

  useEventListener('message', handleMessage);

  useEffect(() => {
    if (isConnected) {
      const iframeWindow = iframeRef.current.contentWindow;
      iframeWindow.postMessage({ source: STUDIO_MESSAGE_SOURCE, ...data }, '*');
      setTimeout(() => onLoad?.(), 500);
    }
  }, [isConnected, data]);

  const visibility = isLoading ? 'hidden' : 'visible';

  return <iframe ref={iframeRef} title="preview" style={{ visibility }} {...props} />;
}
