import api from '@pray/shared/utils/api';

export async function updateContentSeries({
  artistId,
  contentSeriesId,
  title = undefined,
  description = undefined,
  imageUrl = undefined,
  isVisible = undefined,
  orderIdBefore = undefined,
  orderIdAfter = undefined,
  distributionOwnerEmail = undefined,
  contentOrder = undefined,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');

  const data = {};

  if (title) data.title = title;
  if (description) data.description = description;
  if (imageUrl) data.image_url = imageUrl;
  if (isVisible !== undefined) data.is_visible = isVisible;
  if (orderIdBefore !== undefined) data.order_number_for_artist_id_before = orderIdBefore;
  if (orderIdAfter !== undefined) data.order_number_for_artist_id_after = orderIdAfter;
  if (distributionOwnerEmail !== undefined) data.distribution_owner_email = distributionOwnerEmail;
  if (contentOrder !== undefined) data.content_order = contentOrder;

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
