import React from 'react';

import styles from './Loading.module.css';

export default function Loading({
  isLoading = true,
  isLight = false,
  width = null,
  height = null,
  borderRadius = '',
  margin = '',
  aspectRatio = '1 / 1',
  style = {},
  className = '',
  children = null,
}) {
  const classes = [styles.loading, isLight ? styles.light : '', className] //
    .filter(Boolean)
    .join(' ');

  if (!isLoading) return children;

  const customStyles = { ...style };

  if (width) customStyles.width = width;
  if (height) customStyles.height = height;
  if (margin) customStyles.margin = margin;
  if (borderRadius) customStyles.borderRadius = borderRadius;
  if (aspectRatio) customStyles.aspectRatio = aspectRatio;

  return <div className={classes} style={{ ...style, ...customStyles }} />;
}
