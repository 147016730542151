import Text from '@pray/shared/components/ui/Text/Text';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import TabItem from 'components/pages/StudioPage/components/TabItem/TabItem';
import { PieChart } from 'images/icons';

import PeriodicTableElementCard from '../../../../../components/PeriodicTable/PeriodicTableElementCard';

export const SegmentDetailsSideBar = ({ segment, variation, classification }) => {
  return (
    <div className="flex flex-col gap-6 pt-5">
      <BackLinkButton label="Periodic Table" />

      <div className="mx-4 flex flex-col gap-4">
        <div className="w-[105px]">
          <PeriodicTableElementCard
            className="no-scaling"
            name={segment.segment_name}
            code={segment.segment_code}
            rank={segment.rank}
            to=""
            group=""
            gridArea=""
            selectedGroup=""
            variation={variation}
            highlight={{
              value: segment.minutes_consumed,
              label: 'Minutes in Prayer',
              color: classification.getClassificationColor(segment.minutes_consumed),
            }}
            table={{
              caption: '% of Ministry Users',
              records: [
                { label: 'Listens', value: `${segment.listen_percentage}% ` },
                { label: 'Shares', value: `${segment.shares_percentage}% ` },
                { label: 'Follows', value: `${segment.follows_percentage}% ` },
              ],
            }}
            onClick={(event) => event.preventDefault()}
          />
        </div>
        <Text className="font-medium">{segment.segment_name}</Text>
      </div>

      <div className="[&_a]:px-3">
        <TabItem
          icon={PieChart}
          text="Overview"
          route={`/analytics/periodic-table/segments/${segment.segment_code}`}
          active
        />
      </div>
    </div>
  );
};
