import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Label from '@pray/shared/components/ui/Label';
import Text from '@pray/shared/components/ui/Text';
import { navigate } from '@pray/shared/utils/navigation';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import MobilePreviewFrame from 'components/pages/StudioPage/components/PhonePreview/MobilePreviewFrame';
import PreviewFrame from 'components/pages/StudioPage/components/PreviewFrame/PreviewFrame';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

import CreateEditFundForm from './CreateEditFundForm';
import useCreateEditFundPage from './useCreateEditFundPage';
import DonationModuleDialog from '../DonationModuleDialog/DonationModuleDialog';

export default function CreateEditFundPage() {
  const appRoutes = useAppRoutes();
  const { id: fundId } = useParams();
  const [isChangesModalOpen, setIsChangesModalOpen] = useState(false);
  const { form, formData, isLoading, isSaving, handleSaveFund } = useCreateEditFundPage();

  const isEditMode = !!fundId;
  const tabTitle = isEditMode ? 'Edit Fund' : 'New Fund';
  const viewName = isEditMode ? VIEW_EVENT_NAMES.DONATIONS.FUNDS_EDIT : VIEW_EVENT_NAMES.DONATIONS.FUNDS_CREATION;

  function handleCancel() {
    if (form.formState.isDirty) {
      setIsChangesModalOpen(true);
    } else {
      navigate(appRoutes.artistDonationsFunds());
    }
  }

  const actionButtons = (
    <>
      <Button variant={buttons.variant.secondary} onClick={handleCancel}>
        Cancel
      </Button>

      <ActionButton
        isLoading={isSaving}
        disabled={isSaving || !form.formState.isDirty}
        text={isSaving ? 'Saving' : 'Save'}
        type="submit"
        form="fundForm"
      />
    </>
  );

  function renderTitleInfo() {
    if (isLoading || !formData.id) return null;

    return (
      <Label className="!px-3 !text-xs" status={formData.isDefault ? 'published' : 'scheduled'}>
        {formData.isDefault ? 'DEFAULT' : 'CAUSE'}
      </Label>
    );
  }

  return (
    <FormProvider {...form}>
      <TabPage title={tabTitle} data-viewname={viewName} titleInfo={renderTitleInfo()} topRightButtons={actionButtons}>
        <div className="flex gap-16 pt-6">
          <div className="max-w-3xl flex-1">
            <CreateEditFundForm data={formData} onSave={handleSaveFund} />
          </div>

          <div className="sticky top-28 h-0">
            <div className="-mt-8 flex max-w-sm flex-col gap-4">
              <Text className="font-medium" color={colors.text_secondary}>
                Fund Preview
              </Text>

              <DonationPreviewFrame src={appRoutes.previewDonation()} data={formData} isLoading={isLoading} />
            </div>
          </div>

          {isChangesModalOpen && (
            <DeleteContentModal
              isOpen={isChangesModalOpen}
              title="Discard fund changes?"
              message="You will loose all the changes made to the fund."
              deleteButtonLabel="Discard Changes"
              cancelButtonLabel="Keep Editing"
              onClose={() => setIsChangesModalOpen(false)}
              onDelete={() => navigate(appRoutes.artistDonationsFunds())}
            />
          )}

          <DonationModuleDialog />
        </div>
      </TabPage>
    </FormProvider>
  );
}

function DonationPreviewFrame({ src, data, isLoading: isDataLoading }) {
  const [isFrameLoading, setIsFrameLoading] = useState(true);

  return (
    <MobilePreviewFrame isLoading={isFrameLoading}>
      <PreviewFrame
        src={src}
        data={data}
        isLoading={isFrameLoading || isDataLoading}
        className="h-[718px] w-[360px]"
        onLoad={() => setIsFrameLoading(false)}
      />
    </MobilePreviewFrame>
  );
}
