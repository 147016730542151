import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import {
  useGetContentTranscriptMultiVoices,
  useUpdateContentTranscriptMultiVoices,
} from '@pray/shared/services/studioService/contentTranscripts';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';

import { LOCALES } from '@/constants';
import useQueryParams from '@/hooks/useQueryParams';

import { useToastMessage } from '../../../components/StudioToastMessage/StudioToastMessage';

export function useEditTranscriptPage() {
  const toast = useToastMessage();
  const { artistId, contentId } = useParams();
  const { params } = useQueryParams();
  const { locale = LOCALES.DEFAULT, targetLocale } = params;

  const { data, isLoading } = useGetContentTranscriptMultiVoices({
    artistId,
    contentId,
    locale,
  });

  const { updateContentTranscriptMultiVoices, isPending: isUpdating } = useUpdateContentTranscriptMultiVoices();

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

  /** @type {import('react-hook-form').UseFormReturn<import('./types').FormValues>} */
  const form = useForm({
    defaultValues: {
      speakers: [],
      transcripts: [],
    },
  });

  const voices = data?.voices || {
    artist: [],
    shared: [],
  };

  const colors = [
    '#387AF0',
    '#8854F2',
    '#EA3979',
    '#FF6B2B',
    '#00C48C',
    '#FFB800',
    '#FF4444',
    '#9C27B0',
    '#00BCD4',
    '#4CAF50',
  ];

  useEffect(() => {
    const speakerCount =
      (data?.transcripts_by_speakers || []).reduce((acc, curr) => Math.max(acc, curr.speaker_number), 0) + 1;

    const speakerVoiceSuggestions = data?.speaker_voice_suggestions || [];

    const initialSpeakers = Array.from({ length: speakerCount }, (_, index) => ({
      id: `speaker-${index}`,
      name: `Speaker ${index + 1}`,
      color: colors[index],
      voiceId:
        speakerVoiceSuggestions.find((suggestion) => suggestion.speaker_id === index)?.eleven_labs_voice_id || '',
    }));

    const initialTranscripts = (data?.transcripts_by_speakers || []).map((transcript, index) => ({
      id: `transcript-${index}`,
      text: transcript.text,
      speakerId: `speaker-${transcript.speaker_number}`,
      timeframe: new Date(transcript.timestamp_start * 1000).toISOString().substring(11, 19),
    }));

    form.reset({
      speakers: initialSpeakers,
      transcripts: initialTranscripts,
    });
  }, [data]);

  function onTranscriptFormSubmit() {
    setIsUpdateDialogOpen(true);
  }

  async function onUpdateTranscript() {
    const formData = form.getValues();
    const speakers = formData.speakers.map((speaker) => speaker.id);

    const multiVoicesTranscript = formData.transcripts.map((transcript) => {
      const speakerIndex = speakers.indexOf(transcript.speakerId);
      const { voiceId } = formData.speakers[speakerIndex];

      return {
        speaker_number: speakerIndex,
        voice_id: voiceId,
        text: transcript.text,
      };
    });

    try {
      await updateContentTranscriptMultiVoices({
        artistId,
        contentId,
        locale,
        targetLocale,
        multiVoicesTranscript,
      });

      toast.show({ success: 'Transcript updated' });
      navigate(-1);
    } catch (error) {
      logger.error(error);
      toast.show({ error: 'Failed to update transcript' });
    }
  }

  return {
    form,
    contentId,
    isLoading,
    isUpdating,
    voices,
    isUpdateDialogOpen,
    setIsUpdateDialogOpen,
    onTranscriptFormSubmit,
    onUpdateTranscript,
  };
}
