import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function usePrayLocales(isEnabled = true) {
  const fetchPrayLocales = async () => {
    const response = await studioService.locales.getAllLocales();

    return response.data;
  };

  const query = useQuery({
    enabled: !!isEnabled,
    queryKey: queryKeys.locales(),
    queryFn: fetchPrayLocales,
  });

  return query;
}
