import api from '@pray/shared/utils/api';

type DeleteBookProps = {
  artistId: string;
  bookId: string;
};

export const deleteBook = async ({ artistId, bookId }: DeleteBookProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  await api.executeDeleteRequest(`/web/studio/artists/${artistId}/books/${bookId}`);
};
