import api from '@pray/shared/utils/api';

export async function updateVoice({ artistId, voiceId, payload }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!voiceId) throw Error('Voice ID is required');
  if (!payload) throw Error('Payload is required');

  const url = `/web/studio/artists/${artistId}/voices/${voiceId}`;
  const response = await api.executePutRequest(url, payload);

  return response?.data;
}
