import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { cn } from 'styles/utils';

import DocumentIcon from '../../assets/DocumentIcon';
import ErrorIcon from '../../assets/ErrorIcon';
import SuccessIcon from '../../assets/SuccessIcon';
import { DROPZONE_COPY, DROPZONE_ERROR_COPY, DROPZONE_STATUS, UPLOAD_STEPS } from '../../constants';
import { cleanFileName } from '../../utils/utils';

export default function LeadsDropzone({
  uploadState,
  activeStep,
  uploadedFileName = null,
  onUploadFile = null,
  onRemoveFile = null,
  onCancel = null,
  onRetry = null,
}) {
  const isInteractive = uploadState.status !== DROPZONE_STATUS.LOADING && activeStep !== UPLOAD_STEPS.IMPORT;
  const [fileName, setFileName] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    maxFiles: 1,
    onDropAccepted: (files) => {
      if (isInteractive) {
        onUploadFile(DROPZONE_STATUS.LOADING, files[0]);
        setFileName(cleanFileName(files[0].name));
      }
    },
    onDropRejected: () => {
      if (isInteractive) {
        onUploadFile(DROPZONE_STATUS.ERROR);
      }
    },
    disabled: !isInteractive,
  });

  const handleRemoveFile = (e) => {
    e.stopPropagation();
    onRemoveFile();
  };

  const handleRetry = (e) => {
    if (activeStep === UPLOAD_STEPS.UPLOAD) {
      onRemoveFile();
    } else {
      e.stopPropagation();
      onRetry();
    }
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <div className="flex flex-col gap-6 ">
      <div
        {...getRootProps()}
        className={cn(
          'flex h-64 justify-center rounded-2xl',
          uploadState.status === DROPZONE_STATUS.EMPTY &&
            'border-[1.5px] border-dashed border-[#A6A8AD] bg-[#FBFBFC] hover:border-[#E3AF4A] cursor-pointer',
          uploadState.status === DROPZONE_STATUS.SUCCESS &&
            'border-[1.5px] border-[#E3AF4A] bg-[#E3AF4A0D] cursor-pointer',
          uploadState.status === DROPZONE_STATUS.ERROR && 'bg-[#FEF0F0] cursor-pointer',
          uploadState.status === DROPZONE_STATUS.LOADING && 'border-[1.5px] border-[#8C8F96] bg-[#FBFBFC]',
          uploadState.status === DROPZONE_STATUS.IMPORT && 'border-[1.5px] border-[#8C8F96] bg-[#FBFBFC]'
        )}
      >
        <input {...getInputProps()} />
        {uploadState.status === DROPZONE_STATUS.EMPTY && <DropzoneEmpty isDragActive={isDragActive} />}
        {uploadState.status === DROPZONE_STATUS.SUCCESS && (
          <DropzoneSuccess
            fileName={fileName || uploadedFileName}
            isInteractive={isInteractive}
            onRemove={handleRemoveFile}
          />
        )}
        {uploadState.status === DROPZONE_STATUS.ERROR && (
          <DropzoneError
            buttonTitle={activeStep === UPLOAD_STEPS.UPLOAD ? 'Upload File' : 'Retry'}
            uploadError={uploadState.error}
            onRetry={handleRetry}
          />
        )}
        {uploadState.status === DROPZONE_STATUS.LOADING && (
          <DropzoneLoading
            message={activeStep === UPLOAD_STEPS.UPLOAD ? 'Uploading' : 'Importing'}
            fileName={fileName || uploadedFileName}
            onCancel={onCancel && handleCancel}
          />
        )}
      </div>

      {/* Dropzone Info */}
      <Text className="!font-medium" variant={typography.body_lg} color={colors.text_tertiary}>
        {DROPZONE_COPY.PERMISSION_NOTICE}
      </Text>
    </div>
  );
}

function DropzoneEmpty({ isDragActive }) {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <DocumentIcon className="flex size-16 rounded-full bg-[#E3AF4A33] p-4" />
      <div className="flex flex-col items-center">
        <Text variant={typography.heading_md}>
          {isDragActive ? DROPZONE_COPY.DRAG_ACTIVE : DROPZONE_COPY.DRAG_INACTIVE}
        </Text>
      </div>
    </div>
  );
}

function DropzoneSuccess({ fileName, isInteractive, onRemove }) {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <SuccessIcon className="flex size-16 rounded-full bg-[#E3AF4A33] p-4" />
      <div className="flex flex-col items-center gap-2">
        <Text variant={typography.heading_md}>{fileName}.csv upload complete</Text>
        {isInteractive && (
          <Button onClick={onRemove} variant={buttons.variant.default}>
            Remove
          </Button>
        )}
      </div>
    </div>
  );
}

function DropzoneError({ buttonTitle, uploadError, onRetry }) {
  const errorMessage = DROPZONE_ERROR_COPY[uploadError];

  return (
    <div className="flex h-full max-w-96 flex-col items-center justify-center gap-4">
      <ErrorIcon className="flex size-16 rounded-full bg-[#FCBEBE] p-4" />
      <div className="flex flex-col items-center gap-2">
        <Text className="text-center !font-medium" variant={typography.body_md} color={colors.text_secondary}>
          {errorMessage}
        </Text>
        <Button onClick={onRetry} variant={buttons.variant.default}>
          {buttonTitle}
        </Button>
      </div>
    </div>
  );
}

function DropzoneLoading({ message, fileName, onCancel }) {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4">
      <Spinner color="black" className="size-12" />
      <div className="flex flex-col items-center gap-2">
        <Text variant={typography.heading_md}>
          {message} {fileName}.csv
        </Text>
        {onCancel && (
          <Button onClick={onCancel} variant={buttons.variant.default}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
}
