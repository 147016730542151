import api from '@pray/shared/utils/api';

import type { GetDailySeriesBasedOnArtistResponse } from './types';

type GetDailySeriesBasedOnArtistProps = {
  artistId: string;
  offset?: number;
};

type GetDailySeriesBasedOnArtistParams = {
  offset?: number;
};

export async function getDailySeriesBasedOnArtist({ artistId, offset }: GetDailySeriesBasedOnArtistProps) {
  if (!artistId) throw Error('Artist ID is required ');

  const params: GetDailySeriesBasedOnArtistParams = {};

  if (offset) params.offset = offset;

  const url = `/web/studio/artists/${artistId}/daily-series`;
  const response = await api.executeGetRequest<GetDailySeriesBasedOnArtistResponse>(url, params);

  return response.data;
}

export async function createDailySeriesWithRSSFeed(artistId, rssFeedData) {
  if (!artistId) throw Error('Artist ID is required ');
  if (!rssFeedData.url) throw Error('RSS Feed URL is required ');
  if (!rssFeedData.title) throw Error('RSS Feed Title is required ');
  if (!rssFeedData.description) throw Error('RSS Feed Description is required ');

  const params = {
    title: rssFeedData.title,
    description: rssFeedData.description,
    rss_feed_url: rssFeedData.url,
    image_url: rssFeedData.image_url,
  };

  const url = `/web/studio/artists/${artistId}/daily-series/rss`;
  const response = await api.executePostRequest(url, params);

  return response.data;
}
