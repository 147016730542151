import React, { useEffect, useRef } from 'react';

export default function InfiniteScroll({ fetchData = null }) {
  const scrollRef = useRef();
  const observerRef = useRef(null);

  const createObserver = () => {
    const zoomLevel = window.devicePixelRatio || 1;
    const rootMargin = `${100 * zoomLevel}px`;

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          fetchData?.();
        }
      },
      {
        root: null,
        rootMargin,
        threshold: 0.1,
      }
    );

    if (scrollRef.current) {
      observerRef.current.observe(scrollRef.current);
    }
  };

  useEffect(() => {
    createObserver();

    const handleResize = () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      createObserver();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [fetchData]);

  return <div ref={scrollRef} style={{ height: '1px' }} />;
}
