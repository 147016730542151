import { colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { cn } from '@pray/shared/utils/styles';

import { AddPlus } from 'images/icons';

import { CourseEpisode } from './types';
import { formatEpisodeTitle } from '../../../utils/formatters';

/**
 * Props for the EpisodesPanel component
 */
export type EpisodesPanelProps = {
  episodes: CourseEpisode[];
  selectedEpisodeId?: string;
  isLoading?: boolean;
  onCreateEpisode: () => void;
  onSelectEpisode: (episodeId: string) => void;
};

/**
 * Skeleton episodes to show when loading
 */
const EpisodesSkeletonLoader = () => {
  return (
    <div className="flex flex-col gap-2">
      <Loading isLight isLoading height={36} width="95%" borderRadius="4px" margin="0" />
      <Loading isLight isLoading height={36} width="95%" borderRadius="4px" margin="0" />
      <Loading isLight isLoading height={36} width="95%" borderRadius="4px" margin="0" />
      <Loading isLight isLoading height={36} width="95%" borderRadius="4px" margin="0" />
      <Loading isLight isLoading height={36} width="95%" borderRadius="4px" margin="0" />
    </div>
  );
};

export default function EpisodesPanel({
  episodes,
  selectedEpisodeId,
  isLoading = false,
  onCreateEpisode,
  onSelectEpisode,
}: EpisodesPanelProps) {
  const renderEpisodeList = () => {
    if (isLoading) {
      return <EpisodesSkeletonLoader />;
    }

    if (episodes.length === 0) {
      return (
        <Text variant={typography.body_sm} color={colors.text_tertiary} className="pt-4 text-center italic">
          No episodes found. Create your first episode.
        </Text>
      );
    }

    return episodes.map((episode, index) => (
      <div
        key={episode.id}
        role="none"
        className={cn(
          'cursor-pointer rounded-md py-2.5 hover:bg-[#F2F3F4]',
          selectedEpisodeId === episode.id && 'bg-[#F2F3F4]'
        )}
        onClick={() => onSelectEpisode(episode.id)}
      >
        <Text variant={typography.body_md} className="px-3 !font-medium">
          {formatEpisodeTitle(episode.title, index)}
        </Text>
      </div>
    ));
  };

  return (
    <div className="flex h-full w-80 flex-col gap-4 overflow-hidden p-4">
      <div className="flex items-center justify-between">
        <Text variant={typography.heading_lg}>Episodes</Text>
        <Button className="!p-0" onClick={onCreateEpisode} disabled={isLoading}>
          <AddPlus className="size-5" />
        </Button>
      </div>

      <div className="flex flex-1 flex-col gap-1 overflow-y-auto">{renderEpisodeList()}</div>
    </div>
  );
}
