import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailCampaignMapper } from '@pray/shared/services/studioService/emailCampaigns';

export default function useEmailCampaigns(artistId) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'scheduled_at';
  const order = searchParams.get('order') ?? 'desc';
  const sortOrder = `${sort} ${order}`;

  function setSortOrder(value) {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  }

  async function fetchEmailCampaigns(offset) {
    const [sortColumn, sortDirection] = sortOrder.split(' ');

    const response = await studioService.emailCampaigns.getAllEmailCampaigns({
      artistId,
      sortColumn,
      sortDirection,
      offset,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    response.data = response.data.map(emailCampaignMapper);

    return response;
  }

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistEmailCampaigns(artistId, sortOrder),
    queryFn: ({ pageParam: offset }) => fetchEmailCampaigns(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    sortOrder,
    setSortOrder,
  };
}
