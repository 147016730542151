import React from 'react';

export function Play({ ...props }) {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.63341 0.105468L12.1302 6.34901C12.4862 6.56076 12.6032 7.02101 12.3914 7.37701C12.3276 7.48429 12.238 7.57395 12.1308 7.63786L1.63398 13.894C1.27817 14.1061 0.817814 13.9896 0.605749 13.6338C0.536538 13.5176 0.5 13.385 0.5 13.2498V0.750061C0.5 0.335847 0.835786 6.10352e-05 1.25 6.10352e-05C1.38496 6.10352e-05 1.51742 0.0364766 1.63341 0.105468Z"
        fill="currentColor"
        {...props}
      />
    </svg>
  );
}
