import api from '@pray/shared/utils/api';

export async function generateArticle({ artistId = null, contentId = null, locale = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/article`;
  const response = await api.executePostRequest(url);

  return response.data;
}
