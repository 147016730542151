export default {
  // ----------------------------------------
  // COLOR NAMES
  // ----------------------------------------

  // brand
  pray_primary: 'pray_primary',
  pray_secondary: 'pray_secondary',
  pray_featured: 'pray_featured',

  // background
  bg_primary: 'bg_primary',
  bg_secondary: 'bg_secondary',
  bg_tertiary: 'bg_tertiary',

  // overlay
  overlay_bg_primary: 'overlay_bg_primary',
  overlay_bg_secondary: 'overlay_bg_secondary',
  overlay_bg_featured: 'overlay_bg_featured',

  // text
  text_primary: 'text_primary',
  text_secondary: 'text_secondary',
  text_tertiary: 'text_tertiary',
  text_featured: 'text_featured',
  text_muted: 'text_muted',
  text_disabled: 'text_disabled',

  // border
  border_divider: 'border_divider',
  border_highlight: 'border_highlight',
  border_covers: 'border_covers',

  // status
  attention: 'attention',
  success: 'success',

  // icons
  icon_primary: 'icon_primary',
  icon_secondary: 'icon_secondary',

  // ----------------------------------------
  // COLOR VALUES
  // ----------------------------------------

  // brand
  $pray_primary: '#0b0c0d',
  $pray_secondary: '#ffffff',
  $pray_featured: '#e3af4a',

  // background
  $bg_primary: '#0b0c0d',
  $bg_secondary: '#18191c',
  $bg_tertiary: '#1f2024',

  // overlays
  $overlay_bg_primary: '#00000045',
  $overlay_bg_secondary: '#ffffff20',
  $overlay_bg_featured: '#e3af4a20',

  // text
  $text_primary: '#ffffff',
  $text_secondary: '#a6a8ad',
  $text_tertiary: '#0b0c0d',
  $text_featured: '#e3af4a',

  // borders
  $border_divider: '#2c2e33',
  $border_highlight: '#ffffff',
  $border_covers: '#ffffff10',

  // status
  $attention: '#ff5b59',
  $success: '#0dce94',

  // icons
  $icon_primary: '#ffffff',
  $icon_secondary: '#3c3c3d',
};
