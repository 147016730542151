import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Metadata from '@pray/shared/components/Metadata';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';

import DailiesForm from './DailiesForm';

function DailiesFormIndex() {
  const toast = useToastMessage();
  const [dailyItemData, setDailyItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;
  const { dailySeriesId, dailyItemId } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  /**
   * Maps an API response to the appropriate data object.
   * @async
   * @function
   * @param {Object} response - The API response object.
   * @returns {Promise} A Promise that resolves to a mapped data object.
   */
  const mapApiResponseToData = async (response) => {
    return {
      id: response.data.id,
      publishDate: response.data.scheduled_at,
      dailySeries: response.data.daily_series_id,
      artistId: response.data.artist_id,
      body: response.data.body || '',
      quote: response.data.quote || '',
      transcript: response.data.transcript || '',
      version: response.data.version || '',
      reference: response.data.reference || '',
      backgroundUrl: response.data.background_image_url || '',
      audioUrl: response.data.audio_url || '',
      videoUrl: response.data.video_url || '',
      backgroundFile: null,
      audioFile: null,
      isMediaLoading: true, // Used to show loading indicator while the background image/audio file are being fetched.
      daily_item_from_rss: response.data.daily_item_from_rss || false,
      portrait_cover_art_image_url: response.data.portrait_cover_art_image_url || null,
      landscape_cover_art_image_url: response.data.landscape_cover_art_image_url || null,
      square_cover_art_image_url: response.data.square_cover_art_image_url || null,
      rss_guid: response.data.rss_guid || null,
      title: response.data.title || '',
      omny_studio_link: response.data.omny_studio_link || null,
      daily_series_start_of_daily_hour: response.data.daily_series_start_of_daily_hour,
      daily_series_end_of_daily_hour: response.data.daily_series_end_of_daily_hour,
    };
  };

  /**
   * An async function that fetches the daily item using the provided IDs,
   * maps the API response to the appropriate data object, and sets the daily item data.
   * @async
   * @function
   * @param {string} artistId - The ID of the artist.
   * @param {string} dailySeriesId - The ID of the daily series.
   * @param {string} dailyItemId - The ID of the daily item.
   * @returns {Promise<void>}
   */
  const fetchDailyItem = async (artistId, dailySeriesId, dailyItemId) => {
    let response = {};
    try {
      response = await studioService.dailyItems.getDailyItemById({
        artistId,
        dailySeriesId,
        dailyItemId,
      });
      if (!response.data) throw Error('Not found');

      setDailyItemData(await mapApiResponseToData(response));
    } catch (error) {
      logger.error(error);
      toast.show({ error: 'Daily not found ' });
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * useEffect hook that fetches the data of a daily item with the provided IDs
   * and updates the state accordingly.
   */
  useEffect(() => {
    if (artistId && dailySeriesId && dailyItemId) {
      fetchDailyItem(artistId, dailySeriesId, dailyItemId);
    }
  }, [artistId, dailySeriesId, dailyItemId]);

  /**
   * When creating a new daily we will not have either the daily series ID or the daily item ID.
   * Then we don't need to wait for anything and can set the loading state to false.
   */
  useEffect(() => {
    if (!dailySeriesId || !dailyItemId) setIsLoading(false);
  }, [dailySeriesId, dailyItemId]);

  return (
    <div data-viewname="PRAY Studio - Daily Builder">
      <Metadata title="PRAY Studio for Leaders - Daily Builder" />

      {!isLoading && (
        <DailiesForm
          data={dailyItemData}
          onSaveSuccess={(_dsId, _diId) => {
            if (state?.from) {
              navigate(-1);
            } else {
              navigate('/dailies');
            }
          }}
        />
      )}
    </div>
  );
}

export default DailiesFormIndex;
