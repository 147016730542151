import { typography } from '@pray/shared/components/foundations';
import Link from '@pray/shared/components/ui/Link/Link';
import Text from '@pray/shared/components/ui/Text';

import { cn } from 'styles/utils';

export default function WeekCalendarEvent({
  title,
  status,
  icon,
  time,
  color,
  backgroundColor,
  onHover,
  isCompact,
  isDisplayTime = true,
  isEventStart,
  isEventEnd,
  ...props
}) {
  const { to } = props;

  return (
    <Link
      to={to}
      className={cn('flex my-0.5 h-full', isEventStart && `border-l-2`, isEventEnd && 'rounded-r mr-0.5')}
      style={{ backgroundColor, borderColor: isEventStart ? color : 'transparent' }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={`flex w-full flex-col py-1 pl-2 text-left ${onHover}`}>
        <Text variant={typography.footnote} className={cn('font-medium truncate', !isEventStart && 'invisible')}>
          {title}
        </Text>
        {!isCompact && (
          <div className="relative flex justify-between pr-2">
            <EventStatus isEventStart={isEventStart} status={status} icon={icon} />
            {isDisplayTime && <EventTime isEventStart={isEventStart} time={time} color={color} />}
          </div>
        )}
      </div>
    </Link>
  );
}

function EventStatus({ status, icon, isEventStart }) {
  return (
    <div className="flex items-center gap-1 overflow-hidden">
      <div className="flex items-center gap-1">
        {isEventStart && icon}
        <Text
          variant={typography.footnote}
          className={cn('font-medium !capitalize z-[1] pr-1', !isEventStart && 'invisible')}
        >
          {status}
        </Text>
      </div>
    </div>
  );
}

function EventTime({ time, color, isEventStart }) {
  return (
    <Text
      variant={typography.caption_medium}
      className={cn('font-bold mt-0.5 px-0.5 whitespace-nowrap', !isEventStart && 'invisible')}
      style={{ color }}
    >
      {time}
    </Text>
  );
}
