import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';

import Loading from '@pray/shared/components/Loading/Loading';

import MainLayout from 'components/layouts/MainLayout';
import useGetDailySeriesDetails from 'hooks/dailySeries/useGetDailySeriesDetails';
import useAppRoutes from 'hooks/useAppRoutes';
import { CoverArt, Edit } from 'images/icons';

import DailiesFeedDetails from './DailiesFeedDetails/DailiesFeedDetails';
import DailyCoverArt from './DailyCoverArt/DailyCoverArt';
import DailySeriesForm from './DailySeriesForm/DailySeriesForm';
import PopupsForm from './PopupsForm/PopupsForm';
import BackLinkButton from '../../components/BackLinkButton/BackLinkButton';
import SideBarContentDetails, { useSideBarContentDetails } from '../../components/SideBar/SideBarContentDetails';
import useSideBar from '../../components/SideBar/useSideBar';
import TabItem from '../../components/TabItem/TabItem';

export default function DailiesFeedTab(props) {
  const location = useLocation();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);
  const seriesMatch = useMatch('/leaders/:artistId/daily-series/:dailySeriesId/:action');

  const renderSeriesDetailsSidebar = () => {
    const { dailySeriesId, artistId, action } = seriesMatch.params;
    return <SeriesDetailsSidebarItems dailySeriesId={dailySeriesId} artistId={artistId} activeItem={action} />;
  };

  useEffect(() => {
    if (seriesMatch) {
      setRenderDetails(renderSeriesDetailsSidebar);
    }
  }, [seriesMatch, location]);

  return (
    <MainLayout data-viewname="PRAY Studio - Dailies Feed Tab">
      <Routes>
        <Route path=":id" element={<DailiesFeedDetails {...props} />} />
        <Route path=":id/details" element={<DailySeriesForm {...props} />} />
        <Route path=":id/cover-art" element={<DailyCoverArt {...props} />} />
        <Route path=":id/popups" element={<PopupsForm {...props} />} />
      </Routes>
    </MainLayout>
  );
}

const SeriesDetailsSidebarItems = ({ dailySeriesId, artistId, activeItem, disabled = false }) => {
  const appRoutes = useAppRoutes();
  const { data: dailySeriesResponse, isLoading, isRefetching } = useGetDailySeriesDetails(artistId, dailySeriesId);
  const { setContentDetails } = useSideBarContentDetails();

  useEffect(() => {
    if (dailySeriesResponse) {
      setContentDetails({
        title: dailySeriesResponse.title,
        imageUrl: dailySeriesResponse.image_url,
      });
    }
  }, [dailySeriesResponse, isLoading, isRefetching]);

  return (
    <div className="flex flex-col gap-6 pt-5">
      <BackLinkButton label="Series" to={appRoutes.artistDailyPlaylistDetails(dailySeriesId)} />

      <div className="flex items-center justify-center">
        {isRefetching || isLoading ? (
          <Loading isLight width="120px" height="auto" aspectRatio="3 / 4" />
        ) : (
          <SideBarContentDetails isDailyType />
        )}
      </div>

      <div className="[&_a]:px-3 [&_button]:px-3">
        <TabItem
          icon={Edit}
          text="Playlist details"
          disabled={disabled}
          route={appRoutes.artistDailySeriesDetailsForm(dailySeriesId)}
          active={activeItem === 'details'}
        />
        <TabItem
          icon={CoverArt}
          text="Cover Art"
          disabled={disabled}
          route={appRoutes.artistDailySeriesDetailsCoverArtForm(dailySeriesId)}
          active={activeItem === 'cover-art'}
        />
      </div>
    </div>
  );
};
