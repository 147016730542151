import React, { useEffect, useMemo, useState } from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Table, Tbody, Tr, Td, Th, Thead, RowAction } from '@pray/shared/components/ui/Table';

import { layout } from 'components/layouts/constants';
import useAppRoutes from 'hooks/useAppRoutes';
import { Anonymous, Call, Chat, Envelope } from 'images/icons';

export default function FollowersList({ data = [], sortBy = '', onSortChange = null }) {
  const appRoutes = useAppRoutes();
  const followers = useMemo(() => data.map(followersMapper), [data]);

  const handleScroll = () => {
    const tableHeader = document.querySelector('#sticky-header');

    if (tableHeader.offsetTop > 224) {
      tableHeader.classList.add('z-10');
    } else {
      tableHeader.classList.remove('z-10');
    }
  };

  useEffect(() => {
    const mainArea = document.getElementById(layout.main);
    mainArea.addEventListener('scroll', handleScroll);

    return () => {
      mainArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRowActions = (rowIndex) => {
    const follower = followers[rowIndex];

    if (!follower) return [];

    return [
      <RowAction visible key={`call-${follower.id}}`}>
        <IconButton icon={Call} tooltip="Call" disabled />
      </RowAction>,
      <RowAction visible key={`email-${follower.id}}`}>
        <IconButton icon={Envelope} tooltip="Email" disabled />
      </RowAction>,
      <RowAction visible key={`chat-${follower.id}}`}>
        <IconButton icon={Chat} tooltip="Chat" to={appRoutes.artistChatsUserId(follower.id)} />
      </RowAction>,
    ];
  };

  return (
    <Table
      key={data.map(({ id }) => id).join('-')}
      sortBy={sortBy}
      rowCount={data.length}
      rowActions={getRowActions}
      onSortChange={onSortChange}
    >
      <Thead id="sticky-header" className="sticky top-[76px] bg-white shadow-[0px_1px_0px_0px_#e7e7e7]">
        <Tr>
          <Th />
          <Th field="name">Name</Th>
          <Th field="city">City</Th>
          <Th field="region">State</Th>
        </Tr>
      </Thead>
      <Tbody>
        {followers.map((follower) => (
          <Tr key={follower.id}>
            <Td className="w-14 pr-2 text-left">
              <ProfileImage url={follower.profileImageUrl} />
            </Td>
            <Td className="w-2/5 [&_span]:max-w-sm [&_span]:truncate">{decodeURIComponent(follower.name || '')}</Td>
            <Td className="w-1/5">{decodeURIComponent(follower.city || '')}</Td>
            <Td className="w-1/5">{decodeURIComponent(follower.state || '')}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

const ProfileImage = ({ url: profileImageUrl }) => {
  const [isError, setIsError] = useState(false);

  if (!profileImageUrl || isError) {
    return <Anonymous className="w-10" />;
  }

  return (
    <img
      src={profileImageUrl}
      alt="Follower"
      className="size-10 rounded-full object-cover"
      onError={() => setIsError(true)}
    />
  );
};

const followersMapper = (follower) => {
  const { first_name: firstName = '', last_name: lastName = '' } = follower;

  return {
    id: follower.id,
    name: firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : 'Pray Member',
    profileImageUrl: follower.profile_image_url,
    city: follower.location_city,
    state: follower.location_region,
  };
};
