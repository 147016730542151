import { typography } from '@pray/shared/components/foundations';
import Link from '@pray/shared/components/ui/Link/Link';
import Text from '@pray/shared/components/ui/Text';

import { cn } from 'styles/utils';

export default function DayCalendarEvent({
  title,
  status,
  icon,
  time,
  color,
  backgroundColor,
  onHover,
  isDisplayTime,
  isEventStart,
  isEventEnd,
  ...props
}) {
  const { to } = props;

  return (
    <Link
      to={to}
      className={cn('flex my-0.5 h-full', isEventStart && `border-l-2`, isEventEnd && 'rounded-r mr-0.5')}
      style={{ backgroundColor, borderColor: isEventStart ? color : 'transparent' }}
      onClick={(event) => event.stopPropagation()}
    >
      <div className={`flex flex-1 items-start gap-3 px-2 py-1 text-left ${onHover}`}>
        {isDisplayTime && (
          <Text
            variant={typography.footnote}
            className={cn('font-bold', !isEventStart && 'invisible')}
            style={{ color }}
          >
            {time}
          </Text>
        )}
        <Text variant={typography.footnote} className={cn('flex-1 font-medium truncate', !isEventStart && 'invisible')}>
          {title}
        </Text>
        <div className="flex items-center gap-1">
          {isEventStart && icon}
          <Text variant={typography.footnote} className={cn('font-medium !capitalize', !isEventStart && 'invisible')}>
            {status}
          </Text>
        </div>
      </div>
    </Link>
  );
}
