import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { Filter } from 'images/icons';
import { cn } from 'styles/utils';

export default function FilterButton({ title, isOpened, onClick }) {
  return (
    <div className={cn('flex rounded-full', isOpened ? 'bg-[#0b0c0d]' : 'bg-[#f2f3f4] hover:bg-[#dedfe3]')}>
      <Button
        className={cn(
          'flex items-center gap-2 text-base font-medium normal-case',
          isOpened ? 'text-white' : 'text-[#0b0c0d]'
        )}
        onClick={onClick}
      >
        <Filter className="size-5" style={{ color: isOpened ? 'white' : '#0b0c0d' }} />
        <Text variant={typography.heading_md}>{title}</Text>
      </Button>
    </div>
  );
}
