import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { AiFeather } from 'images/icons';

export default function SolomonErrorLabel() {
  return (
    <div className="flex items-center gap-2">
      <AiFeather />
      <Text className="!font-medium" variant={typography.body_lg}>
        I'm not sure how to answer that right now. Try rephrasing your question, or check back later while I gather more
        data.
      </Text>
    </div>
  );
}
