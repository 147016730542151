import React, { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import styles from './DeleteContentModal.module.scss';

export default function DeleteContentModal({
  isOpen = false,
  title = '',
  message = null,
  extraMessage = null,
  isDeleteButtonEnabled = true,
  deleteButtonLabel = 'Delete',
  cancelButtonLabel = 'Cancel',
  onClose = null,
  onDelete = null,
}) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
  };

  if (!isOpen) return null;

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{title}</Text>
      <Text className={styles.message}>{message}</Text>
      {extraMessage}
      <div className={styles.buttons}>
        <Button variant={buttons.variant.secondary} disabled={isDeleting} onClick={onClose}>
          {cancelButtonLabel}
        </Button>
        <Button variant={buttons.variant.danger} disabled={!isDeleteButtonEnabled || isDeleting} onClick={handleDelete}>
          {isDeleting ? <Spinner /> : deleteButtonLabel}
        </Button>
      </div>
    </Modal>
  );
}
