import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Pagination from '@pray/shared/components/ui/Table/Pagination';
import Text from '@pray/shared/components/ui/Text/Text';
import usePagination from '@pray/shared/hooks/usePagination';
import studioService from '@pray/shared/services/studioService';
import { capitalize } from '@pray/shared/utils';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import ContentItem from 'components/pages/StudioPage/components/ContentItem/ContentItem';
import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import useAppRoutes from 'hooks/useAppRoutes';
import { Pencil, Tag, Trash } from 'images/icons';

import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';
import TagContentModal from '../TagContentModal/TagContentModal';

import styles from './TagDetails.module.scss';

const TypeFilter = {
  ALL: { value: 'all', label: 'All' },
  PLAYLISTS: { value: 'content_series', label: 'Series' },
  EPISODES: { value: 'content', label: 'Episodes' },
  LEADERS: { value: 'artist', label: 'Leaders' },
  CHAPTERS: { value: 'book_chapter', label: 'Chapters' },
};

export default function TagDetails() {
  const appRoutes = useAppRoutes();
  const [tag, setTag] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isTagContentModalOpen, setIsTagContentModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const { id: tagId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'name';
  const order = searchParams.get('order') ?? 'asc';
  const contentSortOrder = `${sort} ${order}`;

  const [typeFilter, setTypeFilter] = useState(searchParams.get('type') ?? TypeFilter.ALL.value);

  const setContentSortOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const {
    isFirstPage,
    isLastPage,
    page,
    data: content,
    fetchData: fetchTagDetails,
    refetchData: refetchTagDetails,
    fetchPreviousPage,
    fetchNextPage,
  } = usePagination(async (params = {}) => {
    const [sortColumn, sortDirection] = contentSortOrder.split(' ');
    const response = await studioService.tag.getTagById({
      tagId,
      typeFilter: typeFilter !== TypeFilter.ALL.value ? typeFilter : '',
      sortColumn,
      sortDirection,
      ...params,
    });

    setTag(response.data);

    return {
      data: response.data.taggings,
      nextItemId: response.next_item,
    };
  });

  const removeTagging = async () => {
    await studioService.taggings.removeTagging({
      taggingId: selectedContent.id,
    });
    await refetchTagDetails();
    setSelectedContent(null);
    setIsRemoveModalOpen(false);
  };

  useEffect(() => {
    fetchTagDetails(false, { offset: 0 });
    // update type param in the URL
    if (typeFilter === TypeFilter.ALL.value) {
      searchParams.delete('type');
    } else {
      searchParams.set('type', typeFilter);
    }
    setSearchParams(searchParams);
  }, [tagId, typeFilter]);

  useEffect(() => {
    if (content.length) refetchTagDetails();
  }, [contentSortOrder]);

  if (!tag) return null;

  return (
    <TabPage>
      <BackLinkButton to={appRoutes.tags()} label="Tags" />

      <TabHeader
        title={tag.name}
        description={tag.description}
        onTagContentClick={() => setIsTagContentModalOpen(true)}
      />

      <Section className={styles.section}>
        <div className="flex">
          {Object.values(TypeFilter).map((type) => (
            <TagType
              key={type.value}
              value={type.value}
              active={typeFilter === type.value}
              onClick={() => setTypeFilter(type.value)}
            >
              {type.label}
            </TagType>
          ))}
        </div>

        {content.length === 0 ? (
          <EmptyState
            icon={<Tag />}
            title="No content added"
            subtitle={`Click "Tag Content" to link your content with this tag`}
          />
        ) : (
          <div>
            <TagsTable
              data={content}
              sortBy={contentSortOrder}
              onSortChange={setContentSortOrder}
              onEditButtonClick={(item) => {
                setSelectedContent(item);
                setIsTagContentModalOpen(true);
              }}
              onRemoveButtonClick={(item) => {
                setSelectedContent(item);
                setIsRemoveModalOpen(true);
              }}
            />
            <Pagination
              page={page}
              isPreviousEnabled={!isFirstPage}
              isNextEnabled={!isLastPage}
              onPreviousClick={fetchPreviousPage}
              onNextClick={fetchNextPage}
            />
          </div>
        )}
      </Section>

      {isTagContentModalOpen && (
        <TagContentModal
          tagId={tag.id}
          tagging={selectedContent}
          contentTypes={Object.values(TypeFilter).filter((type) => type !== TypeFilter.ALL)}
          onClose={() => {
            setSelectedContent(null);
            setIsTagContentModalOpen(false);
          }}
          onSuccess={async () => {
            await refetchTagDetails();
            setSelectedContent(null);
            setIsTagContentModalOpen(false);
          }}
        />
      )}

      <DeleteContentModal
        isOpen={isRemoveModalOpen}
        title="Remove content from tag"
        message="You are about to remove content from a tag. Are you sure you want to remove?"
        deleteButtonLabel="Remove"
        onClose={() => setIsRemoveModalOpen(false)}
        onDelete={removeTagging}
      />
    </TabPage>
  );
}

const TabHeader = ({ title, description, onTagContentClick }) => {
  return (
    <div className={styles.header}>
      <div className={styles.info}>
        <Text className={styles.title}>{title}</Text>
        {!!description && (
          <div>
            <Text className={styles.description}>{description}</Text>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button variant={buttons.variant.primary} onClick={onTagContentClick}>
          Tag Content
        </Button>
      </div>
    </div>
  );
};

const TagsTable = ({ data, sortBy, onSortChange, onEditButtonClick, onRemoveButtonClick }) => {
  const getContentType = (type) => {
    const contentType = Object.keys(TypeFilter).find((key) => type === TypeFilter[key]?.value);
    return contentType ? TypeFilter[contentType].label : capitalize(type);
  };

  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    return [
      <IconButton key={`edit-${item.id}`} icon={Pencil} tooltip="Edit" onClick={() => onEditButtonClick(item)} />,
      <IconButton key={`remove-${item.id}`} icon={Trash} tooltip="Remove" onClick={() => onRemoveButtonClick(item)} />,
    ];
  };

  return (
    <Table sortable sortBy={sortBy} rowCount={data.length} rowActions={getRowActions} onSortChange={onSortChange}>
      <Thead>
        <Tr>
          <Th field="name">Name</Th>
          <Th field="type">Type</Th>
          <Th field="priority">Ranking</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item) => (
          <Tr key={item.name}>
            <Td>
              <ContentItem image={item.image_url} title={item.name} />
            </Td>
            <Td>
              <Text>{getContentType(item.type)}</Text>
            </Td>
            <Td>
              <Text>{item.priority}</Text>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const TagType = ({ active = false, ...props }) => {
  const color = active ? colors.text_secondary : colors.text_primary;
  const variant = active ? buttons.variant.primary : buttons.variant.secondary;
  return (
    <Button variant={variant} color={color} className="mr-2 rounded-full py-1 font-normal normal-case" {...props} />
  );
};
