import api from '@pray/shared/utils/api';

export async function createAnnouncement(artistId, data) {
  if (!artistId) throw new Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/announcements`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
