import React from 'react';

const ShareIcon = (props) => {
  const { color, ...rest } = props;

  return (
    <svg viewBox="0 0 24 24" {...rest}>
      <path
        d="M12 1.93945L9 4.93945C8.70714 5.23231 8.70714 5.70714 9 6V6C9.29286 6.29286 9.76768 6.29286 10.0605 6L11.25 4.81055V15.75H12.75V4.81055L13.9395 6C14.2323 6.29286 14.7071 6.29286 15 6V6C15.2929 5.70714 15.2929 5.23232 15 4.93945L12 1.93945ZM6.75 8.25C5.51627 8.25 4.5 9.26627 4.5 10.5V19.5C4.5 20.7337 5.51627 21.75 6.75 21.75H17.25C18.4837 21.75 19.5 20.7337 19.5 19.5V10.5C19.5 9.26627 18.4837 8.25 17.25 8.25H15V9.75H17.25C17.6738 9.75 18 10.0762 18 10.5V19.5C18 19.9238 17.6738 20.25 17.25 20.25H6.75C6.32623 20.25 6 19.9238 6 19.5V10.5C6 10.0762 6.32623 9.75 6.75 9.75H9V8.25H6.75Z"
        fill="white"
      />
    </svg>
  );
};

export default ShareIcon;
