import React from 'react';

import Button from '@pray/shared/components/ui/Button';

import { Copy, Download, Refresh } from 'images/icons';

export default function SolomonResponseActions({ isText, onDownload, onCopy, onRefresh }) {
  return (
    <div className="inline-flex w-fit rounded-md border border-[#DEDFE3] bg-[#F2F3F440]">
      {isText ? <IconButton onClick={onCopy} Icon={Copy} /> : <IconButton onClick={onDownload} Icon={Download} />}
      <IconButton onClick={onRefresh} Icon={Refresh} />
    </div>
  );
}

const IconButton = ({ onClick, Icon }) => (
  <Button className="p-2" onClick={onClick}>
    <Icon width={16} height={16} />
  </Button>
);
