import api from '@pray/shared/utils/api';

export async function updateDailyItemById({ artistId, dailySeriesId, payload, dailyItemId }) {
  if (!payload) throw Error('Payload is required');
  if (!artistId) throw Error('Artist ID is required');
  if (!dailyItemId) throw Error('Daily Item Id is required');
  if (!dailySeriesId) throw Error('Daily Series Id is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}/daily-items/${dailyItemId}`;
  const response = await api.executePutRequest(url, payload);

  return response?.data?.data;
}
