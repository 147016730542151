import usePrayContext from 'hooks/usePrayContext';
import { useLayoutEffect } from 'react';

import { EVENTS } from '@pray/shared/constants';
import { useAuth } from '@pray/shared/hooks/useAuth';
import storage from '@pray/shared/utils/storage';

import { addTapEventListener, handleAddClickListener, handleRemoveClickListener } from './tapEventHandler';
import { addViewEventListener } from './viewEventHandler';

export default function EventListener({ rootSelector = '#___gatsby' }) {
  const auth = useAuth();
  const prayContext = usePrayContext();

  function authStateChangeListener() {
    const user = storage.getUser();
    auth.setUser(user);
    prayContext.setCurrentUser(user);
  }

  useLayoutEffect(() => {
    const appRootElement = document.querySelector(rootSelector);
    appRootElement.querySelectorAll('*').forEach((node) => {
      handleAddClickListener(node);
      addViewEventListener(node);
    });

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          addTapEventListener(node);
          addViewEventListener(node);
        });
        if (mutation.type === 'attributes') {
          addTapEventListener(mutation.target);
          addViewEventListener(mutation.target);
        }
      });
    });

    mutationObserver.observe(appRootElement, {
      childList: true,
      subtree: true,
      attributeFilter: ['data-viewname', 'data-viewfbcontentcategory', 'data-eventprops', 'data-tapeventname'],
    });

    document.addEventListener(EVENTS.DOM_EVENTS.AUTH_STATE_CHANGED, authStateChangeListener);

    return () => {
      mutationObserver.disconnect();
      appRootElement.querySelectorAll('*').forEach(handleRemoveClickListener);
      document.removeEventListener(EVENTS.DOM_EVENTS.AUTH_STATE_CHANGED, authStateChangeListener);
    };
  }, []);

  return null;
}

// CustomEvent polyfill for IE9+
(() => {
  if (typeof window === 'undefined' || typeof window.CustomEvent === 'function') return;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();
