export * from './getSocialPosts';

export function mapSocialPost(data) {
  return {
    id: data.id,
    contentId: data.content_id,
    title: data.title,
    transcript: data.transcript,
    url: data.url,
    duration: data.duration,
    draft: data.draft,
    isPublished: data.is_published,
    isFailed: data.failed,
    failedReason: data.failed_reason,
    publishedAt: data.published_at,
    timeRangeStart: data.time_range_start,
    timeRangeEnd: data.time_range_end,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
  };
}
