import { LOCAL_STORAGE } from '@pray/shared/constants';

import storage from './storage';

function isCookieBannerAccepted() {
  const isAccepted = storage.getFromStorage(LOCAL_STORAGE.COOKIE_BANNER_IS_ACCEPTED) === 'true';
  return isAccepted;
}

function isCookieBannerDismissed() {
  const isDismissed = storage.getFromStorage(LOCAL_STORAGE.COOKIE_BANNER_IS_DISMISSED) === 'true';
  return isDismissed;
}

function updateCookieBannerAcceptance(isAccepted) {
  if (!isAccepted) {
    const cookieSettings = {
      isBranchOptOut: true,
      isFacebookOptOut: true,
      isGoogleOptOut: true,
      isLiveConnectOptOut: true,
    };
    updateCookieSettings(cookieSettings);
  }
  storage.upsertToStorage(LOCAL_STORAGE.COOKIE_BANNER_IS_ACCEPTED, isAccepted);

  storage.upsertToStorage(LOCAL_STORAGE.COOKIE_BANNER_IS_DISMISSED, true);
}

function isUserInGDPRZone() {
  const date = new Date();
  const timezoneOffsetInMinutes = date.getTimezoneOffset();
  const timezoneOffsetInHours = timezoneOffsetInMinutes / 60;
  const isEUCountry = timezoneOffsetInHours >= -3 && timezoneOffsetInHours <= 1;
  const isPST = timezoneOffsetInHours === 7 || timezoneOffsetInHours === 8;

  return isEUCountry || isPST;
}

export const defaultCookieSettings = {
  isBranchOptOut: false,
  isFacebookOptOut: false,
  isGoogleOptOut: false,
  isLiveConnectOptOut: false,
};

/**
 * @typedef {Object} cookieSettings
 * @property {boolean} isBranchOptOut
 * @property {boolean} isFacebookOptOut
 * @property {boolean} isGoogleOptOut
 * @property {boolean} isLiveConnectOptOut
 *
 * Returns the serialized cookie settings
 * @returns {cookieSettings} cookieSettings
 */
function getCookieSettings() {
  const stringifiedCookieSettings = storage.getFromStorage(LOCAL_STORAGE.COOKIE_SETTINGS);
  const cookieSettings = stringifiedCookieSettings ? JSON.parse(stringifiedCookieSettings) : defaultCookieSettings;

  return cookieSettings;
}

function updateCookieSettings({
  isBranchOptOut = null,
  isFacebookOptOut = null,
  isGoogleOptOut = null,
  isLiveConnectOptOut = null,
}) {
  const currentCookieSettings = getCookieSettings();

  const newCookieSettings = { ...currentCookieSettings };
  if (isBranchOptOut !== null) {
    newCookieSettings.isBranchOptOut = isBranchOptOut;
  }
  if (isFacebookOptOut !== null) {
    newCookieSettings.isFacebookOptOut = isFacebookOptOut;
  }
  if (isGoogleOptOut !== null) {
    newCookieSettings.isGoogleOptOut = isGoogleOptOut;
  }
  if (isLiveConnectOptOut !== null) {
    newCookieSettings.isLiveConnectOptOut = isLiveConnectOptOut;
  }

  const stringifiedCookieSettings = JSON.stringify(newCookieSettings);
  storage.upsertToStorage(LOCAL_STORAGE.COOKIE_SETTINGS, stringifiedCookieSettings, false);

  return newCookieSettings;
}

export {
  isCookieBannerAccepted,
  isCookieBannerDismissed,
  updateCookieBannerAcceptance,
  isUserInGDPRZone,
  getCookieSettings,
  updateCookieSettings,
};
