import React from 'react';

export function Gift({ fill = '#FFFFFF', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.6155 6.46143H4.38451C3.6198 6.46143 2.99988 7.08135 2.99988 7.84606V12C2.99988 12.7647 3.6198 13.3846 4.38451 13.3846H19.6155C20.3802 13.3846 21.0001 12.7647 21.0001 12V7.84606C21.0001 7.08135 20.3802 6.46143 19.6155 6.46143Z"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6155 13.3848V19.6156C19.6155 19.9828 19.4696 20.335 19.2099 20.5947C18.9502 20.8544 18.5981 21.0002 18.2308 21.0002H5.76915C5.40193 21.0002 5.04974 20.8544 4.79007 20.5947C4.5304 20.335 4.38452 19.9828 4.38452 19.6156V13.3848"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6.46143V21.0001"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1539 3L12 6.46158L7.84607 3"
        stroke={fill}
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
