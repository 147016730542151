import React, { useRef } from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import useClickAway from '@pray/shared/hooks/useClickAway';

import styles from './ExtraActionsMenu.module.scss';

export default function ExtraActionsMenu({ options, onClose, ...props }) {
  const containerRef = useRef(null);

  useClickAway(containerRef, onClose);

  return (
    <div ref={containerRef} className={styles.extraActionsMenu} {...props}>
      {options.map(({ Icon, label, ...optionProps }) => (
        <Button key={label} className={styles.option} full {...optionProps}>
          {Icon && (
            <div className={styles.iconWrap}>
              <Icon />
            </div>
          )}
          <Text>{label}</Text>
        </Button>
      ))}
    </div>
  );
}

ExtraActionsMenu.events = {
  toggleDisplay: 'ExtraActionsMenu.toggleDisplay',
};
