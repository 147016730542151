import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export async function updateArtist({
  artistId,
  name = undefined,
  handle = undefined,
  description = undefined,
  givingPreference = undefined,
  givingExternalUrl = undefined,
  isGivingTurnedOff = undefined,
  profileImageUrl = undefined,
  profileVerticalImageUrl = undefined,
  profileHorizontalImageUrl = undefined,
  facecoinImageUrl = undefined,
  isStudioAnalyticsEnabled = undefined,
  isVerified = undefined,
  isLocationSearchEnabled = undefined,
  googlePlaceId = undefined,
  locationName = undefined,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (name !== undefined && !name) throw Error('name must be updated with a value');
  if (handle !== undefined && !handle) throw Error('handle must be updated with a value');

  const data = {};

  if (name !== undefined) data.name = name;
  if (handle !== undefined) data.handle = handle;
  if (description !== undefined) data.description = description || null;
  if (profileImageUrl !== undefined) data.profile_image_url = profileImageUrl || null;
  if (profileVerticalImageUrl !== undefined) data.profile_vertical_image_url = profileVerticalImageUrl || null;
  if (profileHorizontalImageUrl !== undefined) data.profile_horizontal_image_url = profileHorizontalImageUrl || null;
  if (facecoinImageUrl !== undefined) data.facecoin_image_url = facecoinImageUrl || null;
  if (givingPreference !== undefined) data.giving_preference = givingPreference || null;
  if (givingExternalUrl !== undefined) data.giving_external_url = givingExternalUrl || null;
  if (isGivingTurnedOff !== undefined) data.is_giving_turned_off = isGivingTurnedOff;
  if (isStudioAnalyticsEnabled !== undefined) data.is_studio_analytics_enabled = isStudioAnalyticsEnabled;
  if (isVerified !== undefined) data.is_verified = isVerified;
  if (isLocationSearchEnabled !== undefined) data.is_location_search_enabled = isLocationSearchEnabled;
  if (googlePlaceId !== undefined) data.google_place_id = googlePlaceId || null;
  if (locationName !== undefined) data.location_name = locationName || null;

  const url = `/web/studio/artists/${artistId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}

export function useUpdateArtist() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: updateArtist,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        exact: true,
        queryKey: queryKeys.artist(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    updateArtist: mutateAsync,
    ...rest,
  };
}
