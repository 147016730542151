import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type DeleteBookParams = {
  artistId: string;
  bookId: string;
};

export function useDeleteBook() {
  const queryClient = useQueryClient();

  async function deleteBook({ artistId, bookId }: DeleteBookParams): Promise<void> {
    await studioService.books.deleteBook({
      artistId,
      bookId,
    });
  }

  function onSuccess(_, variables) {
    queryClient.invalidateQueries({ queryKey: queryKeys.artistBooks(variables.artistId) });
  }

  const { mutateAsync } = useMutation({
    mutationFn: deleteBook,
    onSuccess,
  });

  return { deleteBook: mutateAsync };
}
