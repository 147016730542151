import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDeleteTestimonial() {
  const queryClient = useQueryClient();

  async function deleteTestimonial({ artistId, testimonialId }) {
    await studioService.testimonials.deleteTestimonial({
      artistId,
      testimonialId,
    });
  }

  const mutation = useMutation({
    mutationFn: deleteTestimonial,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistTestimonials(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteTestimonial: mutateAsync,
    ...rest,
  };
}
