import React from 'react';

import MainLayout from 'components/layouts/MainLayout';
import { VIEW_EVENT_NAMES } from 'constants.js';

import ComboCalendar from './ComboCalendar/ComboCalendar';
import TabPage from '../../components/TabPage/TabPage';

export default function CalendarTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Calendar Tab">
      <TabPage data-viewname={VIEW_EVENT_NAMES.CALENDAR} containerProps={{ style: { paddingBottom: 0 } }}>
        <ComboCalendar />
      </TabPage>
    </MainLayout>
  );
}
