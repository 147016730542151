import React from 'react';

import MinimizeIcon from './MinimizeIcon';

const MaximizeIcon = (props) => {
  const iconProps = {
    ...props,
    style: {
      transform: 'rotate(-180deg)',
    },
  };

  return <MinimizeIcon {...iconProps} />;
};

export default MaximizeIcon;
