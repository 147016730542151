import React from 'react';

import Portal from '@pray/shared/components/ui/Portal/Portal';
import useHasMounted from '@pray/shared/hooks/useHasMounted';

import { layout } from './constants';

import styles from './FooterPortal.module.scss';

export default function FooterPortal({ children }) {
  const hasMounted = useHasMounted();

  if (!hasMounted) return null;

  return (
    <Portal id={layout.footer}>
      <div className="sm-show md-hide">
        <div className={styles.mobilePublishButtonWrap}>{children}</div>
      </div>
    </Portal>
  );
}
