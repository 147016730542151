import React from 'react';

import DeleteVoiceDialog from './DeleteVoiceDialog';
import EditVoiceNameDialog from './EditVoiceNameDialog';
import SetAsMainVoiceDialog from './SetAsMainVoiceDialog';
import VoiceUploadDialog from './VoiceUploadDialog';

export default function VoiceDialogs({
  // Dialog visibility states
  isEditDialogOpen,
  isDeleteDialogOpen,
  isSetAsMainVoiceDialogOpen,
  isVoiceUploadDialogOpen,

  // Shared props
  selectedVoiceName,
  isLoading,

  // Edit dialog handlers
  onEditCancel,
  onEditConfirm,

  // Delete dialog handlers
  onDeleteCancel,
  onDeleteConfirm,

  // Set as main voice handlers
  onSetAsMainVoiceCancel,
  onSetAsMainVoiceConfirm,

  // Voice upload dialog props and handlers
  uploadedSamples,
  isPlayingId,
  onCreateVoice,
  onUploadCancel,
  onAcceptedFiles,
  onRejectedFiles,
  onPlayClick,
  onPauseClick,
  onDeleteSample,
}) {
  return (
    <>
      {isEditDialogOpen && (
        <EditVoiceNameDialog
          voiceName={selectedVoiceName}
          isLoading={isLoading}
          onCancel={onEditCancel}
          onConfirm={onEditConfirm}
          data-testid="edit-voice-dialog"
        />
      )}

      {isDeleteDialogOpen && (
        <DeleteVoiceDialog
          voiceName={selectedVoiceName}
          isLoading={isLoading}
          onCancel={onDeleteCancel}
          onConfirm={onDeleteConfirm}
          data-testid="delete-voice-dialog"
        />
      )}

      {isSetAsMainVoiceDialogOpen && (
        <SetAsMainVoiceDialog
          voiceName={selectedVoiceName}
          isLoading={isLoading}
          onCancel={onSetAsMainVoiceCancel}
          onConfirm={onSetAsMainVoiceConfirm}
          data-testid="set-main-voice-dialog"
        />
      )}

      {isVoiceUploadDialogOpen && (
        <VoiceUploadDialog
          uploadedSamples={uploadedSamples}
          isLoading={isLoading}
          isPlayingId={isPlayingId}
          onCreate={onCreateVoice}
          onClose={onUploadCancel}
          onAcceptedFiles={onAcceptedFiles}
          onRejectedFiles={onRejectedFiles}
          handlePlayClick={onPlayClick}
          handlePauseClick={onPauseClick}
          handleDeleteSample={onDeleteSample}
          data-testid="voice-upload-dialog"
        />
      )}
    </>
  );
}
