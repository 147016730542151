import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import { typography, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import useEventListener from '@pray/shared/hooks/useEventListener';

import { cn } from 'styles/utils';

import DateDetailsModal from './DateDetailsModal';
import useMonthView from './useMonthView';

const MAX_EVENTS = 4;

export default function MonthView({ data = [], currentDate, renderEvent, onTitleUpdate, onDayClick }) {
  const tableRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  const { detailsModalDate, shortWeekDays, calendarWeeks, setDetailsModalDate } = useMonthView(currentDate);

  useEffect(() => {
    onTitleUpdate(currentDate.toFormat('LLLL yyyy'));
  }, [currentDate]);

  useEffect(() => {
    setTableWidth(tableRef.current?.offsetWidth);
  }, [tableRef.current?.offsetWidth]);

  useEventListener('resize', () => {
    setTableWidth(tableRef.current?.offsetWidth);
  });

  return (
    <>
      <div className="relative h-[calc(100vh-170px)] overflow-y-auto overflow-x-hidden pb-8">
        <table ref={tableRef} className="w-full table-fixed" cellPadding={0} cellSpacing={0}>
          <thead className="sticky top-0 z-10 bg-white">
            <tr>
              {shortWeekDays.map((weekDay) => {
                const isCurrentWeekDay = weekDay === DateTime.now().toFormat('cccc');
                const isCurrentMonth = currentDate.get('month') === DateTime.now().get('month');
                const isCurrentWeekDayAndMonth = isCurrentWeekDay && isCurrentMonth;

                return (
                  <th key={weekDay}>
                    <div className="border-b p-2.5">
                      <Text
                        variant={typography.subhead_small}
                        color={isCurrentWeekDayAndMonth ? colors.text_primary : colors.text_secondary}
                        className={isCurrentWeekDayAndMonth && '!font-bold'}
                      >
                        {weekDay}
                      </Text>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {calendarWeeks.map(({ week, days }) => (
              <tr key={week} className="relative">
                {days.map(({ date, type }) => {
                  const isCurrentMonth = type === 'current';
                  const color = isCurrentMonth ? colors.text_primary : colors.text_tertiary;
                  const isToday = DateTime.now().hasSame(date, 'day');

                  const events = data.filter((event) => {
                    const isOngoingEvent = !event.endDate;
                    const isStartDateInRange = date >= DateTime.fromISO(event.startDate).startOf('day');
                    const isEndDateInRange = date <= DateTime.fromISO(event.endDate).endOf('day') || isOngoingEvent;

                    return isStartDateInRange && isEndDateInRange;
                  });

                  return (
                    <td className="group h-48 align-top">
                      <div
                        className="size-full cursor-pointer border-b border-r pb-1 group-first-of-type:border-l"
                        onClick={() => onDayClick(date)}
                        role="none"
                      >
                        <DayLabel date={date} color={color} isToday={isToday} />

                        <MonthEventCard
                          date={date}
                          events={events}
                          tableWidth={tableWidth || tableRef.current?.offsetWidth}
                          renderEvent={renderEvent}
                        />

                        <ShowMoreButton
                          eventCount={events.length}
                          onClick={(event) => {
                            event.stopPropagation();
                            setDetailsModalDate({ date, events, target: event.target });
                          }}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {detailsModalDate && (
        <DateDetailsModal
          date={detailsModalDate.date}
          events={detailsModalDate.events}
          anchorEl={detailsModalDate.target}
          renderEvent={renderEvent}
          onClose={() => setDetailsModalDate(null)}
        />
      )}
    </>
  );
}

function DayLabel({ date, color, isToday }) {
  return (
    <div className="flex justify-center p-1">
      <Text
        color={color}
        variant={typography.footnote}
        className={cn(
          'flex size-6 items-center justify-center rounded-full font-medium',
          isToday ? 'bg-black !text-white' : 'group-hover:bg-[#F2F3F4]'
        )}
      >
        {date.get('day')}
      </Text>
    </div>
  );
}

function MonthEventCard({ events, date, tableWidth, renderEvent }) {
  return (
    <div className="flex flex-col gap-0.5">
      {events.slice(0, MAX_EVENTS).map((event) => {
        const startDate = DateTime.fromISO(event.startDate);
        const endDate = DateTime.fromISO(event.startDate);
        const isOngoingEvent = !event.endDate;
        const weekDay = date.get('weekday');
        const isSaturday = weekDay === 6;
        const isSunday = weekDay === 7;
        const isEventStart = isSunday || date.hasSame(startDate, 'day');
        const isEventEnd = date.hasSame(endDate, 'day');
        const isDisplayTime = !isOngoingEvent;

        // calculate the event width
        const daysPerWeek = 7;
        const eventDurationInDays = isOngoingEvent ? daysPerWeek : endDate.diff(startDate, 'days').days || 1;
        const daysUntilSaturday = isSaturday ? 1 : daysPerWeek - weekDay || daysPerWeek;
        const dayWidthInParcentage = 100 / daysPerWeek - 0.03;
        const eventWidth = dayWidthInParcentage * Math.min(daysUntilSaturday, eventDurationInDays);
        const eventProps = { isSaturday, isEventStart, isEventEnd, isDisplayTime };

        if (!isOngoingEvent) {
          const calendarWidth = tableWidth || 0;
          const dayWidthInPixels = ((dayWidthInParcentage - 0.6) / 100) * calendarWidth;
          eventProps.width = `${dayWidthInPixels}px`;
        }

        return (
          <div key={event.id} className="h-11">
            {isEventStart && (
              <div className="absolute" style={{ width: `${eventWidth}%` }}>
                {renderEvent({ event, date: startDate, ...eventProps })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function ShowMoreButton({ eventCount, onClick }) {
  if (eventCount <= MAX_EVENTS) return null;

  return (
    <div className="-mb-1 p-1">
      <Button className="flex h-7 w-full items-center pl-2 text-left hover:bg-[#F2F3F4]" onClick={onClick}>
        <Text color={colors.text_secondary} variant={typography.caption_medium} className="font-bold">
          {eventCount - MAX_EVENTS} more
        </Text>
      </Button>
    </div>
  );
}
