import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import MainLayout from '@/components/layouts/MainLayout';
import BackLinkButton from '@/components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import DeleteContentModal from '@/components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import useSideBar from '@/components/pages/StudioPage/components/SideBar/useSideBar';
import { VIEW_EVENT_NAMES } from '@/constants';
import useAppRoutes from '@/hooks/useAppRoutes';

import { SocialClipsPublishForm } from './SocialClipsPublishForm';
import { SocialClipsPublishFormLoading } from './SocialClipsPublishFormLoading';
import { useSocialClipsPublishPage } from './useSocialClipsPublishPage';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import TabPage from '../../../../components/TabPage/TabPage';
import { pageStates, publishOptions, socialClipStatus } from '../constants';

export default function SocialClipsPublishPage() {
  const appRoutes = useAppRoutes();
  const [state, setState] = useState(pageStates.loading);
  const { artistId, contentId, socialClipId } = useParams();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { form, isLoading, isPublishing, isDeleting, socialClip, handleSubmit, handleDelete } =
    useSocialClipsPublishPage({
      artistId,
      contentId,
      socialClipId,
    });

  const isScheduled = !isLoading && socialClip.status === socialClipStatus.scheduled;

  useEffect(() => {
    if (isLoading) {
      setState(pageStates.loading);
      return;
    }

    if (socialClip) {
      setState(pageStates.ready);
    }
  }, [isLoading, socialClip]);

  function renderPublishButton() {
    if (isScheduled) {
      return (
        <ActionButton
          text="Cancel"
          disabled={isDeleting}
          isLoading={isDeleting}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      );
    }

    const publishOption = form.watch('publishOption');
    const socialAccounts = form.getValues('socialAccounts');

    let buttonText = publishOption === publishOptions.now ? 'Publish Now' : 'Schedule';

    if (isPublishing) {
      buttonText = publishOption === publishOptions.now ? 'Publishing...' : 'Scheduling...';
    }

    const isPublishDisabled = isPublishing || !socialAccounts.length;

    return (
      <ActionButton
        isLoading={isPublishing}
        disabled={isPublishDisabled}
        text={buttonText}
        form="socialClipsPublishForm"
        type="submit"
      />
    );
  }

  useEffect(() => {
    setRenderDetails(() => (
      <div className="flex flex-col gap-6 pt-5">
        <BackLinkButton label="All Social Clips" to={appRoutes.artistContentSocialClips(contentId)} />
      </div>
    ));
  }, [contentId]);

  return (
    <MainLayout data-viewname="PRAY Studio - Content Tab">
      <TabPage
        data-viewname={VIEW_EVENT_NAMES.SOCIAL_CLIPS.PUBLISH}
        title="Publish your social clip"
        topRightButtons={renderPublishButton()}
      >
        {state !== pageStates.loading && (
          <Text variant={typography.body_lg} color={colors.text_tertiary} className="!font-medium">
            Choose Which Social Networks to Share Your Clip
          </Text>
        )}
        <div className="flex flex-col gap-8 pt-10">
          {state === pageStates.loading && <SocialClipsPublishFormLoading />}
          {state === pageStates.ready && (
            <FormProvider {...form}>
              <SocialClipsPublishForm isDisabled={isScheduled} socialClip={socialClip} onSubmit={handleSubmit} />
            </FormProvider>
          )}
        </div>
      </TabPage>

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title="Cancel this social post?"
        message="You are about to cancel this social post. This action cannot be undone. Are you sure you want to cancel it?"
        deleteButtonLabel="Cancel Post"
        cancelButtonLabel="Keep Post"
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
    </MainLayout>
  );
}
