import { DateTime } from 'luxon';
import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

import PrayCalendarHeader from './PrayCalendarHeader/PrayCalendarHeader';

import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.scss';

const today = DateTime.now();

const dateRangePresets = [
  {
    label: 'Last 7 days',
    startDate: today.minus({ days: 7 }).startOf('day').toJSDate(),
    endDate: today.endOf('day').toJSDate(),
  },
  {
    label: 'Last 14 days',
    startDate: today.minus({ days: 14 }).startOf('day').toJSDate(),
    endDate: today.endOf('day').toJSDate(),
  },
  {
    label: 'Last 90 days',
    startDate: today.minus({ days: 90 }).startOf('day').toJSDate(),
    endDate: today.endOf('day').toJSDate(),
  },
  {
    label: 'Last 365 days',
    startDate: today.minus({ days: 365 }).startOf('day').toJSDate(),
    endDate: today.endOf('day').toJSDate(),
  },
  {
    label: 'Last 2 years',
    startDate: today
      .startOf('day')
      .minus({ days: 365 * 2 })
      .toJSDate(),
    endDate: today.endOf('day').toJSDate(),
  },
  {
    label: 'This Month',
    startDate: today.startOf('month').startOf('day').toJSDate(),
    endDate: today.endOf('month').endOf('day').toJSDate(),
  },
  {
    label: 'Last Month',
    startDate: today.minus({ months: 1 }).startOf('month').startOf('day').toJSDate(),
    endDate: today.minus({ months: 1 }).endOf('month').endOf('day').toJSDate(),
  },
  {
    label: 'This Year',
    startDate: today.startOf('year').startOf('day').toJSDate(),
    endDate: today.endOf('year').endOf('day').toJSDate(),
  },
];

const reducer = (state, updates) => ({ ...state, ...updates });

export default function DateRangePicker(props) {
  const [defaultRange] = dateRangePresets;
  const initialState = {
    startDate: props.startDate ?? defaultRange.startDate,
    endDate: props.endDate ?? defaultRange.endDate,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { startDate, endDate } = state;

  const handleDateRangeChange = ([startDate, endDate]) => {
    const parsedEndDate = endDate && DateTime.fromJSDate(endDate).endOf('day').toJSDate();
    // @ts-ignore
    dispatch({ startDate, endDate: parsedEndDate });
  };

  useEffect(() => {
    if (startDate && endDate) {
      props.onChange([startDate, endDate]);
    }
  }, [startDate, endDate]);

  return (
    <div className="date-range-picker-container">
      <DatePicker
        {...props}
        selectsRange
        monthsShown={2}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        openToDate={startDate}
        dateFormat="MMM dd, yyyy"
        onChange={handleDateRangeChange}
        // @ts-ignore
        // eslint-disable-next-line
        calendarContainer={(renderProps) => <CalendarContainer {...renderProps} onChange={handleDateRangeChange} />}
        renderCustomHeader={(renderProps) => <PrayCalendarHeader {...renderProps} isDateRangePicker />}
        renderDayContents={(day) => <span>{day}</span>}
        customInput={<TextInput label={props.label} className="w-60" readOnly />}
      />
    </div>
  );
}

const CalendarContainer = ({ children, onChange }) => (
  <div className="flex gap-4 rounded-lg border border-gray-200 bg-white px-4 shadow">
    <div className="flex flex-col gap-2 px-2 py-4">
      <Text className="mb-1 !font-medium">Presets</Text>
      {dateRangePresets.map((preset) => (
        <DateRangePreset {...preset} key={preset.label} onChange={onChange} />
      ))}
    </div>
    {children}
  </div>
);

const DateRangePreset = ({ label, startDate, endDate, onChange }) => (
  <Button className="!p-1 text-left" onClick={() => onChange([startDate, endDate])}>
    <Text className="!font-medium normal-case text-[#56585E]" variant="subhead_small">
      {label}
    </Text>
  </Button>
);
