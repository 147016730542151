import React, { useEffect, useRef, useState } from 'react';

import { colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';

import styles from './Tabs.module.scss';

export function Tabs({ tabs = [], activeTab = 0, tabStyle = 'tabs', onChange = null }) {
  const TabComponent = tabStyle === 'pills' ? PillTabs : DefaultTabs;

  return <TabComponent tabs={tabs} activeTab={activeTab} onChange={onChange} />;
}

const DefaultTabs = ({ tabs = [], activeTab = 0, onChange = null }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab ?? 0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    setActiveTabIndex(activeTab);
  }, [activeTab]);

  useEffect(() => {
    const currentTab = tabsRef.current[activeTabIndex];
    setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
    setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
  }, [activeTabIndex]);

  return (
    <div className="relative">
      <div className="flex border-b">
        {tabs.map((tab, index) => (
          <div
            key={`tab-${tab.title}`}
            ref={(ref) => {
              tabsRef.current[index] = ref;
            }}
          >
            <Button
              to={tab.path}
              role="tab"
              className="rounded-none px-4 py-3 font-bold uppercase hover:bg-gray-100"
              onClick={() => {
                setActiveTabIndex(index);
                onChange?.(index);
              }}
            >
              {tab.title}
            </Button>
          </div>
        ))}
      </div>
      {!!tabUnderlineWidth && (
        <span
          className="absolute bottom-0 block h-0.5 transition-all duration-300"
          style={{
            left: tabUnderlineLeft,
            width: tabUnderlineWidth,
            backgroundColor: colors.$bg_primary,
          }}
        />
      )}
    </div>
  );
};

const PillTabs = ({ tabs = [], activeTab = 0, onChange = null }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab ?? 0);

  useEffect(() => {
    setActiveTabIndex(activeTab);
  }, [activeTab]);

  return (
    <div className="relative">
      <div className="flex">
        {tabs.map((tab, index) => (
          <div key={`tab-${tab.title}`}>
            <Button
              to={tab.path}
              role="tab"
              className={`${styles.pill} ${activeTabIndex === index ? styles.active : ''}`}
              onClick={() => {
                setActiveTabIndex(index);
                onChange?.(index);
              }}
            >
              {tab.title}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};
