import api from '@pray/shared/utils/api';

export async function createGivingAccount({ artistId = '' }) {
  if (!artistId) throw new Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/giving-accounts`;
  const response = await api.executePostRequest(url);

  return response.data;
}
