import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailTemplateMapper } from '@pray/shared/services/studioService/emailTemplates';

export default function useCloneEmailTemplate() {
  const queryClient = useQueryClient();

  async function cloneEmailTemplate({ artistId = undefined, templateId = undefined, name = undefined }) {
    const response = await studioService.emailTemplates.cloneEmailTemplate({
      artistId,
      templateId,
      name,
    });

    return emailTemplateMapper(response.data);
  }

  const mutation = useMutation({
    mutationFn: cloneEmailTemplate,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistEmailTemplates(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    cloneEmailTemplate: mutateAsync,
    ...rest,
  };
}
