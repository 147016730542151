import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailCampaignMapper } from '@pray/shared/services/studioService/emailCampaigns';

export default function useEmailCampaign(artistId, campaignId) {
  async function fetchEmailCampaign() {
    const response = await studioService.emailCampaigns.getEmailCampaign({
      artistId,
      campaignId,
    });

    return emailCampaignMapper(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!campaignId,
    queryKey: queryKeys.artistEmailCampaign(artistId, campaignId),
    queryFn: fetchEmailCampaign,
  });

  return query;
}
