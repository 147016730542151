import React, { useState } from 'react';

import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll';

import BooksListLoading from './BooksListLoading';
import BooksListTable from './BooksListTable';
import BooksListUploader from './BooksListUploader';
import ActionButton from '../../../components/ActionButton/ActionButton';
import TabPage from '../../../components/TabPage/TabPage';
import { BOOK_LIST_STATE } from '../constants';
import { useBookList } from './useBookList';

import MainLayout from '@/components/layouts/MainLayout';
import { useStudioContext } from '@/context/StudioContext';

export default function BooksListPage() {
  const { selectedArtist } = useStudioContext();
  const [isShowDropzone, setIsShowDropzone] = useState(false);
  const { state, books, dropzoneRef, hasNextPage, handleUploadClick, fetchNextPage, addBook, handleClickPlay } =
    useBookList();

  const renderTopRightButtons = () => {
    return <ActionButton text="Add Book" onClick={handleUploadClick} />;
  };

  return (
    <MainLayout data-viewname="PRAY Studio - Library Tab">
      <TabPage title="Books" topRightButtons={renderTopRightButtons()}>
        <div
          className="relative min-h-[calc(100vh-340px)]"
          onDragEnter={() => setIsShowDropzone(true)}
          onDragOver={() => setIsShowDropzone(true)}
        >
          {state === BOOK_LIST_STATE.LOADING && <BooksListLoading />}
          {state === BOOK_LIST_STATE.LIST && (
            <>
              <BooksListTable books={books} onPlay={handleClickPlay} />
              {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} />}
            </>
          )}
          {selectedArtist?.id && (
            <BooksListUploader
              dropzoneRef={dropzoneRef}
              isShowDropzone={state === BOOK_LIST_STATE.EMPTY || isShowDropzone}
              onAddBook={addBook}
              onDragLeave={() => setIsShowDropzone(false)}
            />
          )}
        </div>
      </TabPage>
    </MainLayout>
  );
}
