import { useState, useEffect } from 'react';

import useForm from '@pray/shared/hooks/useForm';

import { useStudioContext } from 'context/StudioContext';

type UseCourseDetailsProps = {
  courseId?: string;
};

export default function useCourseDetails({ courseId }: UseCourseDetailsProps) {
  const [courseData, setCourseData] = useState<{
    id?: string;
    title?: string;
    description?: string;
    primary_image_url?: string;
  } | null>(null);

  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const form = useForm({
    title: courseData?.title || '',
    description: courseData?.description || '',
    primary_image_url: courseData?.primary_image_url || '',
    primary_image_file: null,
  });

  useEffect(() => {
    const fetchCourseData = async () => {
      if (!courseId || !artistId) return;

      try {
        setCourseData({
          id: courseId,
          title: 'Sample Course Title',
          description: 'Sample course description text.',
          primary_image_url: 'https://picsum.photos/1400/1400',
        });
      } catch (error) {
        console.error('Failed to fetch course data:', error);
      }
    };

    fetchCourseData();
  }, [courseId, artistId]);

  useEffect(() => {
    if (courseData) {
      form.setValues({
        title: courseData.title || '',
        description: courseData.description || '',
        primary_image_url: courseData.primary_image_url || '',
        primary_image_file: null,
      });
    }
  }, [courseData]);

  return {
    form,
  };
}
