import React, { Children, ReactNode, isValidElement, useEffect, useState } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { cn } from '@pray/shared/utils/styles';

import EditorContainerHeader from './components/EditorContainerHeader';
import EditorContainerSidebar from './components/EditorContainerSidebar';
import type { SidebarTab } from './types';

type EditorContainerProps<FormValuesType> = {
  title: string;
  subtitle?: string;
  children: ReactNode;
  form?: UseFormReturn<FormValuesType>;
  className?: string;
  headerActions?: ReactNode;
  sidebarTabs?: SidebarTab[];
  defaultSidebarTab?: string;
  isLoading?: boolean;
  showSidebarContent?: boolean;
  onBack: () => void;
  onSidebarTabChange?: (tabId: string) => void;
};

export default function EditorContainer<FormValuesType>({
  title,
  subtitle,
  children,
  form,
  className,
  headerActions,
  sidebarTabs = [],
  defaultSidebarTab,
  isLoading = false,
  showSidebarContent = true,
  onBack,
  onSidebarTabChange,
}: EditorContainerProps<FormValuesType>) {
  const [activeSidebarTab, setActiveSidebarTab] = useState<string>(defaultSidebarTab || sidebarTabs[0]?.id || '');

  useEffect(() => {
    if (defaultSidebarTab) {
      setActiveSidebarTab(defaultSidebarTab);
    }
  }, [defaultSidebarTab]);

  const handleSidebarTabChange = (tabId: string) => {
    setActiveSidebarTab(tabId);
    onSidebarTabChange?.(tabId);
  };

  // Find the active tab's content
  const activeTabContent = sidebarTabs.find((tab) => tab.id === activeSidebarTab)?.content;
  const childrenArray = Children.toArray(children);
  const mainContent = childrenArray.find((child) => isValidElement(child));

  const content = (
    <>
      <EditorContainerSidebar
        sidebarTabs={sidebarTabs}
        activeSidebarTab={activeSidebarTab}
        activeTabContent={activeTabContent}
        showContent={showSidebarContent}
        handleSidebarTabChange={handleSidebarTabChange}
      />
      <div className="flex-1 overflow-auto p-1">{mainContent}</div>
    </>
  );

  return (
    <div className={cn('fixed right-0 top-0 z-[100] size-full bg-white', className)}>
      <EditorContainerHeader
        title={title}
        subtitle={subtitle}
        isLoading={isLoading}
        onBack={onBack}
        headerActions={headerActions}
      />

      <div className="flex h-[calc(100%-72px)]">
        {form ? <FormProvider {...form}>{content}</FormProvider> : content}
      </div>
    </div>
  );
}
