import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useUpdateArtist } from '@pray/shared/services/studioService/artist';
import {
  createGivingAccount,
  getGivingAccountLink,
  useUpdateGivingAccount,
} from '@pray/shared/services/studioService/givingAccounts';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import { payoutIntervals, RevenueStatuses } from './constants';

export const useRevenueSettings = () => {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const { updateArtist } = useUpdateArtist();
  const { updateGivingAccount } = useUpdateGivingAccount();

  const artistId = selectedArtist?.id;
  const hasGivingAccount = Boolean(selectedArtist.giving_account);
  const status = selectedArtist.giving_account?.status;

  const [isPaused, setIsPaused] = useState(selectedArtist.is_giving_turned_off);
  const [isShowActionModal, setIsShowActionModal] = useState(false);
  const [isShowUpdateAccountModal, setIsShowUpdateAccountModal] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    setup: false,
    save: false,
    viewAccount: false,
    statusChange: false,
  });

  const setLoading = (action, isLoading) => {
    setLoadingStates((prevState) => ({ ...prevState, [action]: isLoading }));
  };

  const formValues = useMemo(() => {
    return {
      payoutInterval: selectedArtist?.giving_account?.payout_interval || payoutIntervals.weekly,
      statementDescriptor: selectedArtist?.giving_account?.statement_descriptor || '',
    };
  }, [selectedArtist]);

  const form = useForm({
    defaultValues: {
      payoutInterval: formValues.payoutInterval,
      statementDescriptor: formValues.statementDescriptor,
    },
  });

  useEffect(() => {
    if (selectedArtist) {
      form.reset({
        payoutInterval: formValues.payoutInterval,
        statementDescriptor: formValues.statementDescriptor,
      });
    }
  }, [selectedArtist, formValues]);

  useEffect(() => {
    const statusesToShowModal = [
      RevenueStatuses.ENABLED_FIELDS_DUE,
      RevenueStatuses.PAUSED_FIELDS_DUE,
      RevenueStatuses.PAYOUTS_PAUSED_FIELDS_DUE,
      RevenueStatuses.PAYMENTS_PAUSED_FIELDS_DUE,
    ];

    setIsShowUpdateAccountModal(statusesToShowModal.includes(status));
  }, [status]);

  const onCloseUpdateAccountModal = () => {
    setIsShowUpdateAccountModal(false);
  };

  const isButtonDisabled = useMemo(() => {
    const { payoutInterval, statementDescriptor } = form.getValues();
    return payoutInterval === formValues.payoutInterval && statementDescriptor === formValues.statementDescriptor;
  }, [form.getValues(), formValues]);

  const onSetupAccount = async () => {
    try {
      setLoading('setup', true);

      const newWindow = window.open(appRoutes.revenueExternalPage(), '_blank');
      await createGivingAccount({ artistId });
      const { data: linkData } = await getGivingAccountLink({ artistId });
      updateArtist({ artistId, givingPreference: 'pray' });

      // @ts-ignore
      // This window property will be read by the revenue external page
      newWindow.redirectUrl = linkData.url;
    } catch (error) {
      toast.show({ error: 'Failed to set up giving account. Please try again.' });
    } finally {
      setLoading('setup', false);
    }
  };

  const onSave = async () => {
    try {
      setLoading('save', true);
      const { payoutInterval, statementDescriptor } = form.getValues();

      await updateGivingAccount({
        artistId,
        accountId: selectedArtist.giving_account?.id,
        payoutInterval,
        statementDescriptor,
      });

      toast.show({ success: 'Changes saved successfully' });
    } catch (error) {
      toast.show({ error: 'Failed to update revenue settings. Please try again.' });
    } finally {
      setLoading('save', false);
    }
  };

  const onModalAction = () => {
    if (isPaused) {
      onResume();
    } else {
      onPause();
    }
  };

  const onPause = async () => {
    try {
      setLoading('statusChange', true);
      await updateArtist({ artistId, isGivingTurnedOff: true });
      setIsPaused(true);
      toast.show({ success: 'Revenue paused successfully' });
    } catch (error) {
      toast.show({ error: 'Failed to pause revenue. Please try again.' });
    } finally {
      setLoading('statusChange', false);
      setIsShowActionModal(false);
    }
  };

  const onResume = async () => {
    try {
      setLoading('statusChange', true);
      await updateArtist({ artistId, isGivingTurnedOff: false });
      setIsPaused(false);
      toast.show({ success: 'Revenue resumed successfully' });
    } catch (error) {
      toast.show({ error: 'Failed to resume revenue. Please try again.' });
    } finally {
      setLoading('statusChange', false);
      setIsShowActionModal(false);
    }
  };

  const onOpenPauseModal = () => {
    setIsShowActionModal(true);
  };

  const onCancelPauseModal = () => {
    setIsShowActionModal(false);
  };

  const onViewAccount = async () => {
    try {
      setLoading('viewAccount', true);
      const { data: linkData } = await getGivingAccountLink({ artistId });
      window.open(`${appRoutes.revenueExternalPage()}?url=${linkData.url}`, '_blank');
      setIsShowUpdateAccountModal(false);
    } catch (error) {
      toast.show({ error: 'Failed to get account update link. Please try again.' });
    } finally {
      setLoading('viewAccount', false);
    }
  };

  return {
    form,
    status,
    isPaused,
    isButtonDisabled,
    isShowActionModal,
    isShowUpdateAccountModal,
    isLoadingSetup: loadingStates.setup,
    isLoadingSave: loadingStates.save,
    isLoadingViewAccount: loadingStates.viewAccount,
    isLoadingStatusChange: loadingStates.statusChange,
    hasGivingAccount,
    onSave,
    onPause,
    onResume,
    onViewAccount,
    onSetupAccount,
    onOpenPauseModal,
    onCancelPauseModal,
    onModalAction,
    onCloseUpdateAccountModal,
  };
};
