import React, { forwardRef } from 'react';

import Button from '../Button/Button';
import Text from '../Text/Text';

import styles from './DropdownMenu.module.scss';

const CUSTOM_ADMIN_STYLE = 'custom-admin-background';

/**
 * @typedef DropdownMenuProps
 * @property {boolean} isOpen
 * @property {boolean} [hasSeparator]
 * @property {React.ReactNode} [component]
 * @property {React.ReactNode} children
 * @property {string} [className='']
 * @property {React.MouseEventHandler<HTMLButtonElement>} [onClose]
 * @property {React.FocusEventHandler<HTMLDivElement>} [onBlur]
 * @property {boolean} [forAdmin=false]
 * @property {boolean} [disabled=false]
 */

/**
 * @typedef {Object} DropdownMenuItemProps
 * @property {string} [title]
 * @property {React.ReactNode} [titleComponent]
 * @property {string} [description]
 * @property {React.ReactNode} [leftComponent]
 * @property {React.ReactNode} [rightComponent]
 * @property {string} [className]
 */

/**
 * @typedef {Object} DropdownMenuComponent
 * @extends {React.ForwardRefExoticComponent<DropdownMenuCustomProps & React.RefAttributes<HTMLDivElement>>}
 * @property {React.FC<DropdownMenuItemProps>} [Item] - The dropdown menu item component.
 */

/** @type {DropdownMenuComponent} */
const DropdownMenu = forwardRef((/** @type {DropdownMenuProps} */ props, ref) => {
  const {
    isOpen,
    hasSeparator = true,
    component,
    children,
    className = '',
    forAdmin = false,
    disabled = false,
    onClose,
    onBlur,
  } = props;

  const customAdminStyle = forAdmin ? CUSTOM_ADMIN_STYLE : '';
  const classes = [
    styles.menu,
    hasSeparator && styles.itemSeparator,
    className,
    customAdminStyle,
    disabled && styles.disabled,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <>
      <div ref={ref} onBlur={onBlur} className={classes}>
        {component}
        {isOpen && <div className={styles.items}>{children}</div>}
      </div>

      {isOpen && <div className={styles.backdrop} onClick={onClose} />}
    </>
  );
});

DropdownMenu.Item = ({
  title,
  titleComponent = null,
  description = null,
  leftComponent = null,
  rightComponent = null,
  className = '',
  ...props
}) => {
  return (
    <div className={[styles.itemWrap, className].join(' ')}>
      <Button className={styles.item} {...props}>
        {leftComponent}
        <div className={styles.itemLabel}>
          {title && <Text className={styles.title}>{title}</Text>}
          {titleComponent}
          {description && <Text className={styles.description}>{description}</Text>}
        </div>
        {rightComponent}
      </Button>
    </div>
  );
};

export default DropdownMenu;
