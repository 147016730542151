import Loading from '@pray/shared/components/Loading/Loading';

export function SocialClipsLoading() {
  return (
    <div className="flex size-full flex-col gap-8">
      <LoadingClip />
      <LoadingClip />
    </div>
  );
}

function LoadingClip() {
  return (
    <div className="flex max-w-screen-lg gap-8">
      {/* Video */}
      <Loading isLight width="264px" height="509px" />

      <div className="flex flex-1 flex-col gap-4">
        {/* Header */}
        <div className="flex w-full justify-between gap-4">
          <div className="flex flex-1 flex-col">
            <Loading isLight width="76px" />
            <Loading isLight width="182px" />
          </div>
          <Loading isLight width="80px" />
        </div>

        {/* Transcript */}
        <div className="flex flex-col rounded-md border p-4">
          <Loading isLight width="100%" />
          <Loading isLight width="100%" />
          <Loading isLight width="80%" />
          <Loading isLight width="20%" />
        </div>

        {/* Buttons */}
        <div className="flex w-full justify-end gap-4">
          <Loading isLight width="140px" height="40px" />
          <Loading isLight width="140px" height="40px" />
        </div>
      </div>
    </div>
  );
}
