import React, { ReactNode } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text';

import { ArrowLeft } from 'images/icons';

export type HeaderProps = {
  title: string;
  subtitle?: string;
  isLoading?: boolean;
  headerActions?: ReactNode;
  onBack: () => void;
};

export default function EditorContainerHeader({ title, subtitle, isLoading, headerActions, onBack }: HeaderProps) {
  return (
    <div className="grid h-[72px] w-full grid-cols-3 items-center border-b px-4">
      <div className="flex gap-4">
        <Button variant={buttons.variant.secondary} className="flex gap-2" onClick={onBack}>
          <ArrowLeft />
        </Button>
      </div>

      <div className="flex flex-col items-center justify-center">
        <Text variant={typography.heading_md} className="!font-medium">
          {title}
        </Text>
        {subtitle && !isLoading && (
          <Text variant={typography.body_md} color={colors.text_tertiary} className="!font-medium">
            {subtitle}
          </Text>
        )}
        {isLoading && <Loading isLight height={20} width={100} />}
      </div>

      <div className="flex items-center justify-end">{headerActions}</div>
    </div>
  );
}
