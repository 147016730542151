import React from 'react';

export function TriangleDanger({ stroke = '#E11900' }) {
  return (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81409 17.4368H17.1971C18.7791 17.4368 19.7721 15.7268 18.9861 14.3528L11.8001 1.7878C11.0091 0.404804 9.01509 0.403804 8.22309 1.7868L1.02509 14.3518C0.239091 15.7258 1.23109 17.4368 2.81409 17.4368Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0024 10.4148V7.3148"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99512 13.5H10.0051"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
