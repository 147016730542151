import api from '@pray/shared/utils/api';

export const getLeaderPeriodicTable = async ({ artistId }) => {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/analytics/periodic-table`;
  const { data } = await api.executeGetRequest(url);

  return data;
};
