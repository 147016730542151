import api from '@pray/shared/utils/api';

export async function getDonationFund({ artistId = '', fundId = '' }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!fundId) throw new Error('Fund ID is required');

  const url = `/web/studio/artists/${artistId}/funds/${fundId}`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
