import { OBJECT_TYPES } from '@pray/shared/constants';
import api from '@pray/shared/utils/api';
import cache from '@pray/shared/utils/cache';

async function getShareLink(channel, objectType, shareObject) {
  const resourceName = getResourceName(objectType);
  const resourceId = shareObject.id;
  const url = `/0.9/${resourceName}/${resourceId}/share?channel=${channel}&tags=web`;

  return cache.getAsync(
    {
      key: url,
      ttl: 1 * 60 * 60 * 1000, // 1 hour
    },
    async () => {
      const response = await api.executeGetRequest(url);
      const data = response.data.data[0].link;

      return {
        data,
        skipCaching: true,
      };
    }
  );
}

function getResourceName(objectType) {
  if (objectType === OBJECT_TYPES.COMMUNITY) return 'organizations';
  if (objectType === OBJECT_TYPES.CONTENT) return 'content';
  if (objectType === OBJECT_TYPES.CONTENT_SERIES) return 'content-series';
  if (objectType === OBJECT_TYPES.DAILY_ITEM) return 'daily-items';
  return `${objectType}s`;
}

export default {
  getShareLink,
  getResourceName,
};
