import React from 'react';

import { getOptimizedUrl } from '@pray/shared/utils/generateOptimizedImageUrl';

/**
 * @typedef {Object} ImageProps
 * @property {string} src Image source URL
 * @property {string} [fallback] Fallback image format
 * @property {string} [fallbackImage] Fallback image URL
 * @property {'small'|'medium'|'large'|undefined} [size] Optimized image size
 * @property {boolean} [skipOptimize] Skip image optimization
 */

/**
 * @param {ImageProps & React.ImgHTMLAttributes<HTMLImageElement>} props
 * @returns {React.ReactElement} Image element
 */
export default function Image({ src, fallback = 'png', fallbackImage = '', size, skipOptimize, onError, ...props }) {
  const pictureSourceRef = React.useRef(null);

  if (!src) return null;

  const getImageType = (imgExt) => {
    const type = imgExt === 'jpg' ? 'jpeg' : imgExt;
    return `image/${type}`;
  };

  const useOptimizedUrl = !skipOptimize && size;
  const imgSrc = useOptimizedUrl ? getOptimizedUrl(src, size) : src;
  const imgExt = imgSrc.split(/[#?]/)[0].split('.').pop().trim();
  const imgType = getImageType(imgExt);
  const fallbackSrc = /^(jpe?g|png|gif|webp)$/.test(fallback) ? imgSrc.replace(`.${imgExt}`, `.${fallback}`) : fallback;

  const handleImageError = (event) => {
    const img = event.target;

    // try to load the image with it's original src
    if (img.src !== src) {
      img.src = src;
      pictureSourceRef.current.srcset = src;
      return;
    }

    // try to load the fallback image
    if (fallbackImage && img.src !== fallbackImage) {
      img.src = fallbackImage;
      pictureSourceRef.current.srcset = fallbackImage;
      return;
    }

    // hide the image
    img.style.visibility = 'hidden';

    onError?.(event);
  };

  // 'data-item' prop added for CSS targeting from external components
  return (
    <picture>
      <source ref={pictureSourceRef} srcSet={imgSrc} type={imgType} />
      <img alt="" data-item src={fallbackSrc} loading="lazy" onError={handleImageError} {...props} />
    </picture>
  );
}
