import React from 'react';

import Button from '@pray/shared/components/ui/Button';

const buttonClassName = 'w-full text-left text-[15px] font-medium !capitalize hover:bg-[#E7E7E7]';

export default function VoiceOptionsMenu({
  optionsRef,
  isMainVoice,
  handleDeleteClick,
  handleEditClick,
  handleSetAsMainVoiceClick,
}) {
  return (
    <div
      ref={optionsRef}
      className="absolute right-0 top-3/4 z-50 w-48 rounded-2xl bg-white shadow-[0px_0px_40px_0px_rgba(69,71,69,0.20)]"
    >
      <div className="p-1">
        <Button className={buttonClassName} onClick={handleEditClick}>
          Edit name
        </Button>
        <Button className={buttonClassName} onClick={handleDeleteClick}>
          Delete
        </Button>
        {!isMainVoice && (
          <Button className={buttonClassName} onClick={handleSetAsMainVoiceClick}>
            Set as primary voice
          </Button>
        )}
      </div>
    </div>
  );
}
