import React from 'react';

import Metadata from '@pray/shared/components/Metadata';

import { layout } from './constants';

export default function MainLayout({ children, ...props }) {
  return (
    <>
      <Metadata title="PRAY Studio for Leaders - Content Creators" />

      <div id={layout.main} className="flex flex-1 flex-col overflow-y-auto" {...props}>
        {children}
        <div id={layout.footer} className="fixed inset-x-0 bottom-0 z-10" />
      </div>
    </>
  );
}
