import { DateTime } from 'luxon';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import useForm from '@pray/shared/hooks/useForm';

import MainLayout from 'components/layouts/MainLayout';
import { useStudioContext } from 'context/StudioContext';

import AudienceReport from './AnalyticsReport/AudienceReport';
import ListenReport from './AnalyticsReport/ListenReport';
import { PeriodicTableSegmentDetails, PeriodicTableReport } from './AnalyticsReport/PeriodicTableReport';
import TestimonialsReport from './AnalyticsReport/TestimonialsReport';
import WatchReport from './AnalyticsReport/WatchReport';

const startDate = DateTime.utc().minus({ days: 91 }).toJSDate();
const endDate = DateTime.utc().minus({ days: 1 }).toJSDate();

export default function AnalyticsTab() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const form = useForm({
    startDate,
    endDate,
    artistIds: [],
  });

  return (
    <MainLayout data-viewname="PRAY Studio - Analytics Tab">
      <Routes>
        <Route path="listen" element={<ListenReport artistId={artistId} />} />
        <Route path="watch" element={<WatchReport artistId={artistId} />} />
        <Route path="audience" element={<AudienceReport artistId={artistId} />} />
        <Route path="testimonials" element={<TestimonialsReport form={form} />} />
        <Route path="periodic-table" element={<PeriodicTableReport />} />
        <Route path="periodic-table/segments/:segmentCode" element={<PeriodicTableSegmentDetails />} />
      </Routes>
    </MainLayout>
  );
}
