import React, { useEffect, useRef, useState } from 'react';

import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { AudioWaveBlack, Close, CloudUpload, VideoCameraBlack } from 'images/icons';

import ActionButton from '../ActionButton/ActionButton';

import styles from './UploaderButton.module.scss';

export default function UploaderButton({
  fileType = 'image', // image or audio
  text,
  name,
  disabled,
  onChange,
  previewFile,
  isLoading = false,
  validExtensions = [],
  error: validationError = null,
  ...props
}) {
  const inputRef = useRef();
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (previewFile && previewFile.size > 0) {
      const url = URL.createObjectURL(previewFile);
      setFile(previewFile);
      setFileUrl(url);
      onChange(previewFile, url);
    } else if (typeof previewFile === 'string') {
      setFile(null);
      setFileUrl(previewFile);
      onChange(null, previewFile);
    }
  }, [previewFile]);

  useEffect(() => {
    if (validationError) {
      setError(validationError);
    }
  }, [validationError]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const getFileName = (urlString) => {
    return urlString.split('/').pop();
  };

  /**
   * Get the file type from the accepts prop
   * @returns {string} - the file type from accepts prop Input
   */
  const getFileType = () => {
    const fileTypes = {
      image: 'image/*',
      audio: 'audio/*',
      video: 'video/*',
    };
    return fileTypes[fileType] ? fileTypes[fileType] : fileTypes.image;
  };

  const handleChange = (event) => {
    setError('');
    const [file] = event.target.files;

    const extension = file.name.split('.').pop();
    if (validExtensions.length > 0 && !validExtensions.includes(extension.toLowerCase())) {
      setError(`Only ${validExtensions.join(', ')} files are allowed`);
      return;
    }

    setFile(file);

    // create a new URL for the file
    const url = URL.createObjectURL(file);
    setFileUrl(url);

    if (onChange) onChange(file, url);
  };

  const handleCloseButton = () => {
    setFileUrl(null);
    setFile(null);
    if (onChange) onChange(null, null);
  };

  const getIcon = () => {
    switch (fileType) {
      case 'audio':
        return <AudioWaveBlack />;
      case 'video':
        return <VideoCameraBlack />;
      default:
        return <img src={fileUrl} alt="small preview" />;
    }
  };

  if (isLoading) {
    return (
      <div>
        <div className={styles.selectedFile}>
          <div className={styles.filePreviewWrapper}>
            <Spinner color="dark" />
            <div className={styles.fileName}>Loading...</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <input
        className={styles.hiddenInput}
        name={name}
        type="file"
        ref={inputRef}
        onChange={handleChange}
        accept={getFileType()}
      />

      {fileUrl ? (
        <div className={`${styles.selectedFile} ${disabled && styles.disabledSelectedFile}`}>
          <div className={styles.filePreviewWrapper}>
            {getIcon()}
            <div className={styles.fileName}>{file?.name || getFileName(fileUrl)}</div>
          </div>

          <button
            {...(disabled ? { disabled: true } : {})}
            type="button"
            aria-label="close button"
            onClick={handleCloseButton}
          >
            <Close />
          </button>
        </div>
      ) : (
        <>
          <ActionButton
            onClick={handleClick}
            className={styles.uploadButton}
            text={text}
            icon={<CloudUpload />}
            {...props}
          />
          {error && <Text className={styles.error}>{error}</Text>}
        </>
      )}
    </div>
  );
}
