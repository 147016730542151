import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { isOnBrowser } from '@pray/shared/utils';

// @ts-ignore
import { ReactComponent as PeriodicTableErrorImg } from './assets/periodic-table-error.svg';

export default function PeriodicTableReportLoading() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="mt-36 flex w-[500px] flex-col items-center">
        <PeriodicTableErrorImg className="mb-6 h-auto w-[163px]" />
        <Text variant={typography.h2} className="mb-1">
          Data Retrieval Unsuccessful
        </Text>
        <Text variant={typography.headline_small} className="mb-6 text-center">
          Apologies, we're experiencing difficulty fetching your data. Please wait a moment and try refreshing the page.
        </Text>
        {isOnBrowser() && (
          // @ts-ignore
          <Button variant={buttons.variant.secondary} onClick={() => window.location.reload()}>
            Refresh Page
          </Button>
        )}
      </div>
    </div>
  );
}
