import { useState } from 'react';

import { navigate } from '@pray/shared/utils/navigation';

import useAppRoutes from 'hooks/useAppRoutes';

import { DROPZONE_STATUS, UPLOAD_STEPS } from '../constants';
import { useImportLeads } from './steps/ImportLeadsStep/useImportLeads';
import useFieldMapping from './steps/MapFieldsStep/useFieldMapping';
import useUploadCsv from './steps/UploadLeadsStep/useUploadCsv';

const useUploadLeadsFlow = () => {
  const appRoutes = useAppRoutes();

  const [activeStep, setActiveStep] = useState(UPLOAD_STEPS.UPLOAD);
  const [file, setFile] = useState(null);
  const [uploadState, setUploadState] = useState({
    status: DROPZONE_STATUS.EMPTY,
    error: null,
  });

  const { title, headers, mappings, setTitle, handleUploadFile, handleRemoveFile, handleCancelUpload } = useUploadCsv(
    setUploadState,
    setFile
  );
  const { userEditedMappings, availableOptions, unmatchedMappings, hasRequiredField, handleFieldChange } =
    useFieldMapping(headers, mappings);

  const { isCreateContactListComplete, handleImportLeads, handleRetry } = useImportLeads({
    setUploadState,
    name: title,
    mapping: userEditedMappings,
    file,
  });

  const [isCancelUploadOpened, setIsCancelUploadOpened] = useState(false);
  const [isCancelMappingOpened, setIsCancelMappingOpened] = useState(false);
  const [isCancelImportOpened, setIsCancelImportOpened] = useState(false);

  function handleNextClick() {
    if (activeStep === UPLOAD_STEPS.UPLOAD) {
      setActiveStep(UPLOAD_STEPS.MAP);
    } else if (activeStep === UPLOAD_STEPS.MAP) {
      setActiveStep(UPLOAD_STEPS.IMPORT);
    } else if (activeStep === UPLOAD_STEPS.IMPORT) {
      navigate(appRoutes.artistLists());
    }
  }

  function handleCancelClick() {
    if (activeStep === UPLOAD_STEPS.UPLOAD) {
      // If the user has not mapped any fields, navigate to the lists page without showing the cancel confirmation
      if (!mappings || Object.keys(mappings).length === 0) {
        navigate(appRoutes.artistLists());
        return;
      }

      setIsCancelUploadOpened(true);
    } else if (activeStep === UPLOAD_STEPS.MAP) {
      setIsCancelMappingOpened(true);
    } else if (activeStep === UPLOAD_STEPS.IMPORT) {
      if (uploadState.status === DROPZONE_STATUS.SUCCESS) {
        navigate(appRoutes.artistLists());
      } else {
        setIsCancelImportOpened(true);
      }
    }
  }

  const isNextButtonDisabled =
    (activeStep === UPLOAD_STEPS.UPLOAD && uploadState.status !== DROPZONE_STATUS.SUCCESS) ||
    (activeStep === UPLOAD_STEPS.MAP && unmatchedMappings.length > 0) ||
    !hasRequiredField;

  const isCancelButtonVisible = !(activeStep === UPLOAD_STEPS.IMPORT);
  const isNextButtonVisible =
    activeStep !== UPLOAD_STEPS.IMPORT || (activeStep === UPLOAD_STEPS.IMPORT && isCreateContactListComplete);

  const getCompletedSteps = () => {
    const allSteps = Object.values(UPLOAD_STEPS);
    const activeStepIndex = allSteps.indexOf(activeStep);
    const completedSteps = allSteps.slice(0, activeStepIndex);

    if (activeStep === UPLOAD_STEPS.IMPORT && uploadState.status === DROPZONE_STATUS.SUCCESS) {
      completedSteps.push(activeStep);
    }

    return completedSteps;
  };

  return {
    activeStep,
    isCancelUploadOpened,
    isCancelMappingOpened,
    isCancelImportOpened,
    isNextButtonDisabled,
    isNextButtonVisible,
    isCancelButtonVisible,
    uploadState,
    title,
    userEditedMappings,
    availableOptions,
    unmatchedMappings,
    hasRequiredField,
    getCompletedSteps,

    setIsCancelUploadOpened,
    setIsCancelMappingOpened,
    setIsCancelImportOpened,
    setTitle,
    handleNextClick,
    handleCancelClick,
    handleUploadFile,
    handleRemoveFile,
    handleFieldChange,
    handleImportLeads,
    handleCancelUpload,
    handleRetry,
  };
};

export default useUploadLeadsFlow;
