import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { AiStar, AiSummary } from 'images/icons';

export default function WorkflowAIEmptyState({
  content,
  handleWorkflowAIButton,
  title,
  description,
  isProcessing = false,
}) {
  /**
   * If the content is still processing, show the loading state
   */
  if (content?.is_workflow_ai_processing || isProcessing) {
    return (
      <div className="flex min-h-[500px] items-center justify-center">
        <div className="flex flex-col items-center text-center">
          <div className="flex flex-col gap-6">
            <div className="relative">
              <Spinner size="large" color="purple" className="opacity-80" />
              <AiStar className="absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2" />
            </div>
            <Text variant={typography.h1} className="font-bold">
              Generating your {title}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Empty state
   */
  return (
    <div className="flex min-h-[500px] items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <AiSummary />
        <Text variant={typography.h1} className="p-6 font-bold">
          {title}
        </Text>
        <Text variant={typography.body} className="max-w-[450px] pb-6">
          {description}
        </Text>

        <Button
          className="flex items-center gap-2 bg-[#8854F2] text-white"
          disabled={isProcessing || content?.is_workflow_ai_processing}
          onClick={() => handleWorkflowAIButton()}
        >
          {(isProcessing || content?.is_workflow_ai_processing) && <Spinner size="small" />}
          {isProcessing || content?.is_workflow_ai_processing ? 'Generating...' : 'Generate'}
        </Button>
      </div>
    </div>
  );
}
