// Size constants
export const INDICATOR_SIZE = 'size-6';
export const CONNECTOR_SIZE_VERTICAL = 'h-6 w-0.5';
export const CONNECTOR_SIZE_HORIZONTAL = 'w-6 h-0.5';

// Color constants
export const ACTIVE_COLOR_CLASS = 'bg-[#0B0C0D]';
export const INACTIVE_COLOR_CLASS = 'bg-[#71747B]';
export const ACTIVE_BORDER_CLASS = 'border-[#0B0C0D]';
export const INACTIVE_BORDER_CLASS = 'border-[#71747B]';
