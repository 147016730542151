import { useEffect, useState } from 'react';

import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

export default function useGetDailySeriesFromArtist(artistId) {
  const [dailySeries, setDailySeries] = useState([]);

  const fetchDailySeriesFromArtist = async (artistId) => {
    const { data } = await studioService.dailySeries.getDailySeriesBasedOnArtist({
      artistId,
    });
    return data;
  };

  useEffect(() => {
    if (!artistId) return;
    fetchDailySeriesFromArtist(artistId).then(setDailySeries).catch(logger.error);
  }, [artistId]);

  return {
    dailySeries,
  };
}
