export function PaperIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.71354 9.66634H10.3802M5.71354 6.33301H11.7135M4.38021 13.6663H13.0469C13.7833 13.6663 14.3802 13.0694 14.3802 12.333V3.66634C14.3802 2.92996 13.7833 2.33301 13.0469 2.33301H4.38021C3.64383 2.33301 3.04688 2.92996 3.04688 3.66634V12.333C3.04688 13.0694 3.64383 13.6663 4.38021 13.6663Z"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function PlayIcon() {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0087 7.74356L5.61205 2.68087C4.94556 2.27902 4.0957 2.75899 4.0957 3.53725V13.6626C4.0957 14.4409 4.94556 14.9209 5.61205 14.519L14.0087 9.45631C14.6536 9.06749 14.6536 8.13237 14.0087 7.74356Z"
        stroke="black"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CameraIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.38086 3.66667C2.38086 3.29848 2.67934 3 3.04753 3H10.3809C10.749 3 11.0475 3.29848 11.0475 3.66667V12.3333C11.0475 12.7015 10.749 13 10.3809 13H3.04753C2.67934 13 2.38086 12.7015 2.38086 12.3333V3.66667Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M11.0475 6.66667L14.5651 4.90787C14.7868 4.79705 15.0475 4.95822 15.0475 5.20601V10.794C15.0475 11.0418 14.7868 11.2029 14.5651 11.0921L11.0475 9.33333V6.66667Z"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EnvelopeIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.71354 3.7579L7.86922 7.97619C8.36038 8.37804 9.06671 8.37804 9.55786 7.97619L14.7135 3.7579M14.8802 5.30033V10.7003C14.8802 11.4471 14.8802 11.8204 14.7349 12.1056C14.6071 12.3565 14.4031 12.5605 14.1522 12.6883C13.867 12.8337 13.4936 12.8337 12.7469 12.8337H4.68021C3.93347 12.8337 3.5601 12.8337 3.27489 12.6883C3.024 12.5605 2.82003 12.3565 2.6922 12.1056C2.54688 11.8204 2.54688 11.4471 2.54688 10.7003V5.30033C2.54688 4.55359 2.54688 4.18022 2.6922 3.895C2.82003 3.64412 3.024 3.44015 3.27489 3.31232C3.5601 3.16699 3.93347 3.16699 4.68021 3.16699H12.7469C13.4936 3.16699 13.867 3.16699 14.1522 3.31232C14.4031 3.44015 14.6071 3.64412 14.7349 3.895C14.8802 4.18022 14.8802 4.55359 14.8802 5.30033Z"
        stroke="#0B0C0D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChurchIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8143 10.1907L11.0331 8.73413C10.8134 8.61906 10.6757 8.39153 10.6757 8.14353V6.65666C10.6757 6.43733 10.5679 6.23204 10.3873 6.10763L8.35299 4.7065C8.12386 4.54866 7.82079 4.54975 7.59279 4.70924L5.59392 6.10731C5.41558 6.23205 5.30936 6.43597 5.30936 6.65361V8.14446C5.30936 8.39199 5.17223 8.61913 4.95321 8.73439L2.18652 10.1907"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5573 9.53223V13.6662C12.5573 14.0344 12.2588 14.3329 11.8906 14.3329H9.99107C9.62287 14.3329 9.3244 14.0344 9.3244 13.6662V12.4556C9.3244 11.7317 8.73754 11.1448 8.0136 11.1448C7.28974 11.1448 6.70287 11.7317 6.70287 12.4556V13.6662C6.70287 14.0344 6.40439 14.3329 6.0362 14.3329H4.02214C3.65395 14.3329 3.35547 14.0344 3.35547 13.6662V9.53223"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7.96777 7.69238V8.83952" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.77441 2.58691H9.22668" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.97168 4.4446V2.58747V1.66699" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function ImageIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66699 3.16634C2.66699 2.15382 3.4878 1.33301 4.50033 1.33301H11.5003C12.5128 1.33301 13.3337 2.15382 13.3337 3.16634V12.833C13.3337 13.8455 12.5128 14.6663 11.5003 14.6663H4.50033C3.4878 14.6663 2.66699 13.8455 2.66699 12.833V3.16634ZM4.50033 2.33301C4.04009 2.33301 3.66699 2.7061 3.66699 3.16634V9.6259L4.70396 8.58893C5.41992 7.87297 6.58073 7.87297 7.29669 8.58893L10.3741 13.6663H11.5003C11.9606 13.6663 12.3337 13.2932 12.3337 12.833V3.16634C12.3337 2.7061 11.9606 2.33301 11.5003 2.33301H4.50033ZM9.33366 13.6663L6.58958 9.29604C6.26414 8.9706 5.73651 8.9706 5.41107 9.29604L3.66699 11.0401V12.833C3.66699 13.2932 4.04009 13.6663 4.50033 13.6663H9.33366Z"
        fill="black"
      />
      <circle cx="9.75" cy="4.75" r="1.25" fill="black" />
    </svg>
  );
}
