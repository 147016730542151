import React from 'react';

import { BarChart } from 'components/pages/StudioPage/components/Charts/BarChart';
import { DonutChart } from 'components/pages/StudioPage/components/Charts/DonutChart';
import { LineChart } from 'components/pages/StudioPage/components/Charts/LineChart';

export default function SolomonChart({ response }) {
  const ResponseType = {
    BAR_CHART: 'bar_chart',
    LINE_CHART: 'line_chart',
    DONUT_CHART: 'donut_chart',
    TEXT_ONLY: 'text_only',
  };

  const renderChart = () => {
    switch (response.type) {
      case ResponseType.BAR_CHART:
        return <BarChart data={response.data.chart_data} width={400} height={300} title="" />;
      case ResponseType.DONUT_CHART:
        return <DonutChart data={response.data.chart_data} />;
      case ResponseType.LINE_CHART:
        return <LineChart data={response.data.chart_data} width={400} height={300} />;
      default:
        return null;
    }
  };

  return <div className="w-1/2">{renderChart()}</div>;
}
