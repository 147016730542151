import React from 'react';

function CheckCircleFilled(props) {
  const { color } = props;
  return (
    <svg viewBox="0 0 18 18" {...props}>
      <g id="Page-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="sales-page-3" transform="translate(-423.000000, -511.000000)">
          <g id="Group-6-2" transform="translate(423.000000, 511.000000)">
            <circle id="Oval" fill={color} cx="9" cy="9" r="9" />
            <polyline
              id="Line"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              points="5.25 9 8.25 11.625 12.75 6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckCircleFilled;
