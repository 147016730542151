// Generate a random AES-GCM key
export async function generateRandomKey() {
  if (typeof window === 'undefined') return null;

  const key = await crypto.subtle.generateKey(
    { name: 'AES-GCM', length: 256 },
    true, // Key is extractable
    ['encrypt', 'decrypt']
  );
  return key;
}

export async function encrypt(data: BufferSource, key: CryptoKey = undefined) {
  if (typeof window === 'undefined') return null;

  key = key ?? (await generateRandomKey()); // Generate key on the fly
  const iv = crypto.getRandomValues(new Uint8Array(12)); // Random Initialization Vector

  // Encrypt the data
  const ciphertext = await crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, data);

  return {
    ciphertext,
    iv,
    key,
  };
}

export async function decrypt(cipher: BufferSource, key: CryptoKey, iv: Uint8Array) {
  if (typeof window === 'undefined') return null;

  const decrypted = await crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, cipher);

  return decrypted;
}

export const extractKey = (key: CryptoKey) => {
  return crypto.subtle.exportKey('raw', key);
};
