import React from 'react';

import { cn } from '@pray/shared/utils/styles';

import {
  ACTIVE_COLOR_CLASS,
  CONNECTOR_SIZE_HORIZONTAL,
  CONNECTOR_SIZE_VERTICAL,
  INACTIVE_COLOR_CLASS,
} from './constants';

type ConnectorProps = {
  /** Whether the step is active */
  isActive: boolean;
  /** Whether the step is completed */
  isCompleted: boolean;
};

/**
 * Vertical connector component
 * Used to connect steps in a vertical stepper
 */
export function VerticalConnector({ isActive, isCompleted }: ConnectorProps) {
  return (
    <div
      className={cn(
        CONNECTOR_SIZE_VERTICAL,
        'ml-3',
        isCompleted || isActive ? ACTIVE_COLOR_CLASS : INACTIVE_COLOR_CLASS
      )}
    />
  );
}

/**
 * Horizontal connector component
 * Used to connect steps in a horizontal stepper
 */
export function HorizontalConnector({ isActive, isCompleted }: ConnectorProps) {
  return (
    <div
      className={cn(CONNECTOR_SIZE_HORIZONTAL, isCompleted || isActive ? ACTIVE_COLOR_CLASS : INACTIVE_COLOR_CLASS)}
    />
  );
}
