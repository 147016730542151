import React, { memo, useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import studioService from '@pray/shared/services/studioService';

import AppBar from 'components/pages/StudioPage/components/AppBar/AppBar';
import ButtonCluster from 'components/pages/StudioPage/components/ButtonCluster';
import Stripo from 'components/pages/StudioPage/components/Stripo/Stripo';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useStripoApi from 'hooks/useStripoApi';
import { ChevronLeft, HistoryClock, Redo, Undo } from 'images/icons';

import { Devices, SourceCode } from '../assets';
import DeleteTemplateModal from './Dialogs/DeleteTemplateModal';
import EmailContentRequiredModal from './Dialogs/EmailContentRequiredModal';
import RenameTemplateModal from './Dialogs/RenameTemplateModal';
import SaveEmailAsTemplateModal from './Dialogs/SaveEmailAsTemplateModal';
import SendTestEmailModal from './Dialogs/SendTestEmailModal';

const EmailDesignerModal = ({
  id = '',
  name = '',
  html = '',
  css = '',
  isTemplateDesigner = false,
  onChangeTemplate = null,
  onRenameTemplate = null,
  onDeleteTemplate = null,
  onPreview = null,
  onSave = null,
  onClose = null,
}) => {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const stripoApi = useStripoApi();

  const [email, setEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] = useState(false);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [isEmailRequiredModalOpen, setIsEmailRequiredModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const [isRenamingTemplate, setIsRenamingTemplate] = useState(false);

  useBeforeUnload((event) => {
    event.preventDefault();
    handleSave();
  });

  async function handleSave() {
    try {
      setIsLoading(true);

      const { template, email } = await stripoApi.getTemplateAndCompiledEmail();

      await onSave({
        rawHtml: template.html,
        rawCss: template.css,
        compiledHtmlCss: email.html,
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  }

  async function handleSend(emails) {
    try {
      setIsSendingTestEmail(true);
      await studioService.emailCampaigns.sendTestEmail({ artistId, campaignId: id, emails });
      toast.show({ success: 'Your test email has been sent' });
    } catch {
      toast.show({ error: 'There was an error sending your test email' });
    } finally {
      setIsSendingTestEmail(false);
      setIsSendTestModalOpen(false);
    }
  }

  async function handleRenameTemplate(name) {
    setIsRenamingTemplate(true);
    await onRenameTemplate({ name });
    setIsRenamingTemplate(false);
    setIsRenameModalOpen(false);
  }

  async function handleSaveAsTemplate() {
    const { template, email } = await stripoApi.getTemplateAndCompiledEmail();

    setEmail({
      rawHtml: template.html,
      rawCss: template.css,
      compiledHtmlCss: email.html,
    });

    setIsSaveAsTemplateModalOpen(true);
  }

  async function handlePreview() {
    const { template, email } = await stripoApi.getTemplateAndCompiledEmail();

    const data = {
      rawHtml: template.html,
      rawCss: template.css,
      compiledHtmlCss: email.html,
    };

    await Promise.all([
      onSave(data), // save email/template
      onPreview(data), // open preview modal
    ]);
  }

  async function handleSendTestEmail() {
    if (id && html && css) {
      setIsSendTestModalOpen(true);

      const { template, email } = await stripoApi.getTemplateAndCompiledEmail();

      const data = {
        rawHtml: template.html,
        rawCss: template.css,
        compiledHtmlCss: email.html,
      };

      onSave(data);
    } else {
      setIsEmailRequiredModalOpen(true);
    }
  }

  function closeSaveAsTemplateModal() {
    setEmail(null);
    setIsSaveAsTemplateModalOpen(false);
  }

  function renderActionButtons() {
    if (isTemplateDesigner) {
      return (
        <TemplateActionButtons
          isSaving={isLoading}
          disabled={isInitializing || isLoading}
          onSave={handleSave}
          onRenameTemplate={() => setIsRenameModalOpen(true)}
          onDeleteTemplate={() => setIsDeleteModalOpen(true)}
        />
      );
    }

    return (
      <EmailActionButtons
        isSaving={isLoading}
        disabled={isInitializing || isLoading}
        onSave={handleSave}
        onChangeTemplate={onChangeTemplate}
        onSaveAsTemplate={handleSaveAsTemplate}
        onSendTestEmail={handleSendTestEmail}
      />
    );
  }

  return (
    <div data-viewname={VIEW_EVENT_NAMES.EMAILS.DESIGN_EMAIL} className="fixed right-0 top-0 z-[99] size-full bg-white">
      <AppBar />

      <header className="flex h-[72px] items-center justify-between border-b border-[#DEDFE3] px-4">
        <div className="flex w-[400px] items-center gap-3">
          <BackButton onClick={onClose} />
          <UndoRedoActions disabled={isInitializing} />
        </div>

        <div className="flex flex-1 justify-center">
          <Text variant={typography.heading_md}>{name}</Text>
        </div>

        <div className="flex w-[400px] items-center justify-end gap-3">
          <ViewSourceCodeButton disabled={isInitializing} />
          <PreviewButton disabled={isInitializing} onClick={handlePreview} />
          {renderActionButtons()}
        </div>
      </header>

      <main className="relative min-h-[calc(100vh-145px)] w-full">
        {isInitializing && <LoadingIndicator />}
        <Stripo emailId={id} html={html} css={css} onTemplateLoaded={() => setIsInitializing(false)} />
      </main>

      {isSaveAsTemplateModalOpen && (
        <SaveEmailAsTemplateModal
          artistId={artistId}
          email={email}
          onSaved={closeSaveAsTemplateModal}
          onCancel={closeSaveAsTemplateModal}
        />
      )}

      {isSendTestModalOpen && (
        <SendTestEmailModal
          onSend={handleSend}
          onCancel={() => setIsSendTestModalOpen(false)}
          isLoading={isSendingTestEmail}
        />
      )}

      {isEmailRequiredModalOpen && <EmailContentRequiredModal onClose={() => setIsEmailRequiredModalOpen(false)} />}

      {isRenameModalOpen && (
        <RenameTemplateModal
          name={name}
          onSave={handleRenameTemplate}
          onCancel={() => setIsRenameModalOpen(false)}
          isLoading={isRenamingTemplate}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteTemplateModal onDelete={onDeleteTemplate} onCancel={() => setIsDeleteModalOpen(false)} />
      )}
    </div>
  );
};

function BackButton(props) {
  return (
    <Button variant={buttons.variant.secondary} className="z-100 flex items-center gap-2 pl-2 pr-3" {...props}>
      <ChevronLeft />
      Back
    </Button>
  );
}

function UndoRedoActions({ disabled }) {
  const showChangeHistory = () => {
    const link = document.querySelector('#changeHistoryLink');

    if (link) link.click();
  };

  return (
    <div id="changeHistoryContainer" className="hidden items-center">
      <Button
        id="undoButton"
        variant={buttons.variant.secondary}
        className="rounded-r-none !border-r-0 p-2"
        disabled={disabled}
      >
        <Undo />
      </Button>
      <Button id="changeHistoryLink" className="hidden" />
      <Button
        id="changeHistoryButton"
        variant={buttons.variant.secondary}
        className="rounded-none p-2"
        onClick={showChangeHistory}
        disabled={disabled}
      >
        <HistoryClock />
      </Button>
      <Button
        id="redoButton"
        variant={buttons.variant.secondary}
        className="rounded-l-none !border-l-0 p-2"
        disabled={disabled}
      >
        <Redo />
      </Button>
    </div>
  );
}

function ViewSourceCodeButton({ ...props }) {
  return (
    <Button id="codeEditor" variant={buttons.variant.secondary} className="p-2" {...props}>
      <SourceCode />
    </Button>
  );
}

function PreviewButton(props) {
  return (
    <Button variant={buttons.variant.secondary} className="flex items-center gap-2 px-3" {...props}>
      <Devices />
      Preview
    </Button>
  );
}

function EmailActionButtons({ isSaving, disabled, onSave, onSaveAsTemplate, onChangeTemplate, onSendTestEmail }) {
  return (
    <ButtonCluster text={isSaving ? 'Saving...' : 'Save & Exit'} disabled={disabled} onClick={onSave}>
      <DropdownMenu.Item className="w-40" title="Save as Template" onClick={onSaveAsTemplate} />
      <DropdownMenu.Item className="w-40" title="Change Template" onClick={onChangeTemplate} />
      <DropdownMenu.Item className="w-40" title="Send Test Email" onClick={onSendTestEmail} />
    </ButtonCluster>
  );
}

function TemplateActionButtons({ isSaving, disabled, onSave, onRenameTemplate, onDeleteTemplate }) {
  return (
    <ButtonCluster text={isSaving ? 'Saving...' : 'Save & Exit'} disabled={disabled} onClick={onSave}>
      <DropdownMenu.Item className="w-40" title="Rename Template" onClick={onRenameTemplate} />
      <DropdownMenu.Item className="w-40" title="Delete Template" onClick={onDeleteTemplate} />
    </ButtonCluster>
  );
}

function LoadingIndicator() {
  return (
    <div className="absolute inset-0 z-[100] flex items-center justify-center bg-black/20">
      <Spinner color="black" size="large" />
    </div>
  );
}

export default memo(EmailDesignerModal, (prevProps, nextProps) => {
  const isSameId = prevProps.id === nextProps.id;
  const isSameName = prevProps.name === nextProps.name;
  const isSameHtml = prevProps.html === nextProps.html;
  const isSameCss = prevProps.css === nextProps.css;
  return isSameId && isSameName && isSameHtml && isSameCss;
});
