import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import * as React from 'react';

import { cn } from '@/styles/utils';

const SelectValue = SelectPrimitive.Value;

/**
 * The trigger for the select component.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & React.RefAttributes<HTMLButtonElement>>}
 */
const SelectTrigger = React.forwardRef(({ className, children, ...props }, ref) => {
  const baseClasses =
    'flex h-10 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm';
  const textClasses = 'ring-offset-background placeholder:text-muted-foreground [&>span]:line-clamp-1';
  const focusClasses = 'focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2';
  const hoverClasses = 'hover:outline hover:outline-[#8C8F96]';
  const disabledClasses = 'disabled:cursor-not-allowed disabled:opacity-50';

  return (
    <SelectPrimitive.Trigger
      ref={ref}
      className={cn(baseClasses, textClasses, focusClasses, hoverClasses, disabledClasses, className)}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <ChevronDown className="size-4 opacity-50" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
});

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

/**
 * The scroll up button for the select component.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectScrollUpButton = React.forwardRef(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <ChevronUp className="size-4" />
  </SelectPrimitive.ScrollUpButton>
));

SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

/**
 * The scroll down button for the select component.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectScrollDownButton = React.forwardRef(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn('flex cursor-default items-center justify-center py-1', className)}
    {...props}
  >
    <ChevronDown className="size-4" />
  </SelectPrimitive.ScrollDownButton>
));

SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

/**
 * The content of the select component.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectContent = React.forwardRef(({ className, children, position = 'popper', ...props }, ref) => {
  const baseClasses =
    'relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md';

  const animationClasses =
    'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95';

  const slideClasses =
    'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2';

  const popperClasses =
    position === 'popper' &&
    'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1';

  const viewportBaseClasses = 'p-1';
  const viewportPopperClasses =
    position === 'popper' && 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]';

  return (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cn(baseClasses, animationClasses, slideClasses, popperClasses, className)}
        position={position}
        {...props}
      >
        <SelectScrollUpButton />
        <SelectPrimitive.Viewport className={cn(viewportBaseClasses, viewportPopperClasses)}>
          {children}
        </SelectPrimitive.Viewport>
        <SelectScrollDownButton />
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  );
});

SelectContent.displayName = SelectPrimitive.Content.displayName;

/**
 * A group of select items.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Group> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectGroup = React.forwardRef(({ className, ...props }, ref) => (
  <SelectPrimitive.Group ref={ref} className={cn('p-1', className)} {...props} />
));

SelectGroup.displayName = SelectPrimitive.Group.displayName;

/**
 * A label for a select group.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectLabel = React.forwardRef(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-2 pr-8 text-sm font-medium text-[#71747B]', className)}
    {...props}
  />
));

SelectLabel.displayName = SelectPrimitive.Label.displayName;

/**
 * A select item.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectItem = React.forwardRef(({ className, children, ...props }, ref) => {
  const baseClasses = 'relative flex w-full text-sm outline-none';
  const interactionClasses = 'cursor-pointer select-none';
  const layoutClasses = 'items-center rounded-sm py-1.5 pr-8 pl-2';
  const focusClasses = 'focus:bg-accent focus:text-accent-foreground';
  const disabledClasses = 'data-[disabled]:pointer-events-none data-[disabled]:opacity-50';

  const indicatorClasses = 'absolute right-3 flex size-3.5 items-center justify-center';
  const checkClasses = 'size-4';

  return (
    <SelectPrimitive.Item
      ref={ref}
      value=""
      className={cn(baseClasses, interactionClasses, layoutClasses, focusClasses, disabledClasses, className)}
      {...props}
    >
      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

      <span className={indicatorClasses}>
        <SelectPrimitive.ItemIndicator>
          <Check className={checkClasses} />
        </SelectPrimitive.ItemIndicator>
      </span>
    </SelectPrimitive.Item>
  );
});

SelectItem.displayName = SelectPrimitive.Item.displayName;

/**
 * A separator for a select group.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> & React.RefAttributes<HTMLDivElement>>}
 */
const SelectSeparator = React.forwardRef(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} />
));

SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

/**
 * The select component.
 * @type {React.ForwardRefExoticComponent<React.ComponentPropsWithoutRef<typeof SelectPrimitive.Root> &
 * {
 *   placeholder?: React.ReactNode;
 *   size?: 'default' | 'small';
 *   className?: string;
 *   error?: boolean;
 * }
 * >}
 */
const Select = React.forwardRef(
  ({ placeholder, children, value, error, size = 'default', className, ...props }, ref) => {
    const sizeClasses = {
      'h-8 px-2': size === 'small',
      'h-10 px-3': size === 'default',
    };

    const errorClasses = {
      'border-[#AA0E0F] bg-[#FEF0F0] text-[#0B0C0D]': error,
    };

    return (
      <SelectPrimitive.Root value={value} {...props}>
        <SelectTrigger ref={ref} className={cn(sizeClasses, errorClasses, className)}>
          <SelectValue placeholder={placeholder}>{placeholder}</SelectValue>
        </SelectTrigger>
        <SelectContent className="z-[100]">{children}</SelectContent>
      </SelectPrimitive.Root>
    );
  }
);

Select.displayName = SelectPrimitive.Root.displayName;

export { Select, SelectItem, SelectGroup, SelectLabel };
