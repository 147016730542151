import React, { useEffect, useRef, useState } from 'react';
import { create } from 'zustand';

import ContentPlayer from '@pray/shared/components/ContentPlayer/ContentPlayer';
import { eventPropsToString } from '@pray/shared/components/events/eventUtils';
import { useShareModal } from '@pray/shared/components/ShareModal/NewShareModal';
import ToastMessage from '@pray/shared/components/ui/ToastMessage/ToastMessage';
import { EVENTS } from '@pray/shared/constants';

import MaximizedContentPlayer from './MaximizedContentPlayer';
import MinimizedContentPlayer from './MinimizedContentPlayer';

export const useAudioPlayer = create((set) => ({
  options: null,

  playContent: (options) =>
    set(() => ({
      options: {
        ...options,
        timestamp: Date.now(),
        chapters: options.chapters || [],
      },
    })),

  closeAudioPlayer: () => set({ options: null }),
}));

export default function FullscreenContentPlayer({ contentId, chapters = [], onClose, isMinimized = true, ...props }) {
  const { openShareModal } = useShareModal();
  const [currentContentId, setCurrentContentId] = useState(contentId);
  const [isPlayerMinimized, setIsPlayerMinimized] = useState(isMinimized);
  const [toastMessage, setToastMessage] = useState('');

  const playerRef = useRef(null);

  const controlOptions = {
    // player controls
    isShowSkipBackward: true,
    isShowSkipForward: true,
    // player actions
    isShowExtraActions: true,
    isShowViewAllEpisodes: true,
    isShowViewProfile: true,
    isShowSave: true,
    isShowSubscribe: true,
    isShowShare: true,
    isShowMaximize: true,
    isShowClose: false,
    ...props.controlOptions,
  };

  const handleShareContent = (currentContent) => {
    openShareModal({
      id: currentContent?.id,
      isOpen: true,
      isPlayer: true,
      image: currentContent.image_url,
      objectName: 'Content',
      objectType: currentContent.object,
      shareObject: currentContent,
    });
  };

  const toggleMinimizeMaximizePlayer = (event) => {
    if (event) event.preventDefault();

    setIsPlayerMinimized(!isPlayerMinimized);
  };

  const handleChapterSelect = (chapterId) => {
    const chapter = chapters.find((availableChapter) => availableChapter.id === chapterId);
    if (chapter) {
      setCurrentContentId(chapter.id);
    }
  };

  useEffect(() => {
    if (contentId) {
      setCurrentContentId(contentId);
    } else if (onClose) {
      onClose();
    }
  }, [contentId]);

  useEffect(() => {
    if (currentContentId && contentId === currentContentId) {
      const player = playerRef.current;
      player.pause();
      player.moveTo(0);
      player.play();
    }
  }, [currentContentId, contentId, props.timestamp]);

  return (
    <ContentPlayer {...props} autoPlayNextContent currentContentId={currentContentId}>
      {({ player, audio, content: currentContent, updateContentData }) => {
        playerRef.current = player;

        const contentWithChapters = {
          ...currentContent,
          chapters,
        };

        const handlePlayContent = (_contentId) => () => {
          if (_contentId) {
            setCurrentContentId(_contentId);
          } else {
            player.moveTo(0);
          }
        };

        const handleSeekUpdate = (event) => {
          const time = event.target.value;
          player.moveTo(time, true);
        };

        const updateReactions = async () => {
          const incr = currentContent.has_reacted ? -1 : 1;

          updateContentData({
            has_reacted: !currentContent.has_reacted,
            reactions_count: (currentContent.reactions_count || 0) + incr,
          });

          setToastMessage(incr === 1 ? 'Saved to favorites' : 'Removed from favorites');
        };

        if (!currentContent.audio_url) return null;

        return (
          <div
            className="theme-dark"
            data-audioplayer
            data-viewname={EVENTS.PRAY_EVENTS.VIEW_EVENT_NAMES.AUDIO_PLAYER}
            data-eventprops={eventPropsToString(currentContent.metrics_properties)}
          >
            {!!toastMessage && <ToastMessage onTimeout={() => setToastMessage('')}>{toastMessage}</ToastMessage>}

            {isPlayerMinimized ? (
              <MinimizedContentPlayer
                audio={audio}
                player={player}
                currentContent={contentWithChapters}
                controlOptions={controlOptions}
                handlePlayContent={handlePlayContent}
                handleSeekUpdate={handleSeekUpdate}
                handleShareContent={handleShareContent}
                updateReactions={updateReactions}
                onMaximizeButtonClick={toggleMinimizeMaximizePlayer}
                onClose={onClose}
                onChapterSelect={handleChapterSelect}
              />
            ) : (
              <MaximizedContentPlayer
                audio={audio}
                player={player}
                currentContent={currentContent}
                controlOptions={controlOptions}
                handlePlayContent={handlePlayContent}
                handleSeekUpdate={handleSeekUpdate}
                handleShareContent={handleShareContent}
                updateReactions={updateReactions}
                onMinimizeButtonClick={toggleMinimizeMaximizePlayer}
                onClose={onClose}
              />
            )}
          </div>
        );
      }}
    </ContentPlayer>
  );
}
