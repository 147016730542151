export function eventPropsToObject(props) {
  if (!props) return {};
  return typeof props === 'string' ? JSON.parse(props) : props;
}

export function eventPropsToString(props) {
  if (!props) return '';
  return typeof props === 'string' ? props : JSON.stringify(props);
}

export function getClosestParentAttribute(element, attributeName) {
  if (!element || !attributeName) return null;

  const propValue = element.getAttribute(attributeName);

  if (!propValue && element.nodeName && element.nodeName.toLowerCase() !== 'body') {
    return getClosestParentAttribute(element.parentNode, attributeName);
  }

  return propValue;
}
