import api from '@pray/shared/utils/api';

import type { PostConnectSocialAccountResponse } from './types';

export async function postConnectSocialAccount({ artistId }) {
  if (!artistId) throw new Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/socials/connect`;
  const response = await api.executePostRequest<PostConnectSocialAccountResponse>(url, {});

  return response.data;
}
