import React, { forwardRef } from 'react';

import { cn } from '@pray/shared/utils/styles';

import { buttons, colors } from '../../foundations';
import Spinner from '../../v1/Spinner/Spinner';
import Link from '../Link/Link';

/**
 * Custom props for the Button component
 * @typedef CustomProps
 * @property {React.Ref<HTMLButtonElement>} [ref]
 * @property {string} [to]
 * @property {string} [href]
 * @property {string} [variant]
 * @property {string} [color]
 * @property {string} [size]
 * @property {boolean} [full]
 * @property {string} [tooltip]
 * @property {string} [className]
 * @property {boolean} [loading]
 * @property {React.ReactNode} [children]
 * @property {React.MouseEventHandler<HTMLButtonElement>} [onClick]
 */

/**
 * Props for the Button component
 * @typedef {CustomProps & React.ComponentPropsWithoutRef<'button'>} ButtonProps
 */

/**
 * A versatile button component that supports multiple variants, sizes, states and navigation options
 *
 * | Property | Type | Description |
 * | --- | --- | --- |
 * | ref | React.Ref<HTMLButtonElement> | Reference to the button element |
 * | to | string | Internal route path that transforms the button into a Link component for client-side navigation |
 * | href | string | External URL that transforms the button into a Link component for external navigation |
 * | variant | string | Visual style variant of the button (e.g. 'primary', 'secondary', 'outline'). See `buttons.variant` for all options |
 * | color | string | Theme color to apply to the button (e.g. 'primary', 'success', 'warning'). See `colors` for all options |
 * | size | string | Size preset for the button dimensions (e.g. 'small', 'medium', 'large'). See `buttons.size` for all options |
 * | full | boolean | When true, button expands to fill the full width of its container |
 * | tooltip | string | Helper text that appears in a tooltip when hovering over the button |
 * | className | string | Custom CSS classes to apply additional styling to the button |
 * | loading | boolean | When true, displays a loading spinner and disabled state |
 * | children | React.ReactNode | Content to render inside the button (text, icons, or other React elements) |
 * | onClick | React.MouseEventHandler<HTMLButtonElement> | Function called when the button is clicked. Receives the click event as an argument |
 *
 * ```jsx
 * import Button from '@pray/shared/components/ui/Button';
 * import { buttons } from '@pray/shared/components/foundations';
 *
 * // Basic button
 * <Button>Click me</Button>
 *
 * // Button with variant
 * <Button variant={buttons.variant.primary}>Primary Button</Button>
 *
 * // Loading state
 * <Button loading>Loading...</Button>
 *
 * // As a link
 * <Button to="/some-route">Internal Link</Button>
 * <Button href="https://example.com">External Link</Button>
 *
 * // With tooltip
 * <Button tooltip="Helpful tip">Hover me</Button>
 * ```
 */
const Button = forwardRef((/** @type {ButtonProps} */ props, ref) => {
  const {
    variant = '',
    color = '',
    size = '',
    full = false,
    loading = false,
    className = '',
    tooltip = '',
    children,
    onClick,
    ...buttonProps
  } = props;

  const { to, href } = buttonProps;
  const variantStyle = variant;
  const colorStyle = colors[color] ?? '';
  const sizeStyle = buttons.size[size] ?? '';
  const fullStyle = full ? 'full' : '';

  const classes = cn('button', variantStyle, sizeStyle, colorStyle, fullStyle, className);

  const handleClick = (event) => {
    event.currentTarget.blur();
    onClick?.(event);
  };

  const renderTooltip = () => {
    if (!tooltip) return null;
    return <div className="button-tooltip">{tooltip}</div>;
  };

  if (to || href)
    return (
      <Link ref={ref} {...buttonProps} className={classes} onClick={handleClick}>
        {renderTooltip()}
        {loading ? <Spinner color="" /> : children}
      </Link>
    );

  return (
    <button ref={ref} type="button" {...buttonProps} className={classes} onClick={handleClick}>
      {renderTooltip()}
      {loading ? <Spinner color="" /> : children}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;
