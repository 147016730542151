import { colors } from '../components/foundations';

export default function AudioPlayingIcon({ color = colors.text_primary }) {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 1C5 0.447715 5.44772 0 6 0C6.55228 0 7 0.447715 7 1V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V1Z"
        fill={color}
      />
      <path
        d="M0 5C0 4.44771 0.447715 4 1 4C1.55228 4 2 4.44772 2 5V15C2 15.5523 1.55228 16 1 16C0.447715 16 0 15.5523 0 15V5Z"
        fill={color}
      />
      <path
        d="M11 7C10.4477 7 10 7.44772 10 8V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V8C12 7.44772 11.5523 7 11 7Z"
        fill={color}
      />
    </svg>
  );
}
