import api from '@pray/shared/utils/api';

export async function createTagging({ tagId, priority, artistId, bookChapterId, contentId, contentSeriesId }) {
  if (!tagId) {
    throw Error('Tag ID is required');
  }
  if (!artistId && !bookChapterId && !contentId && !contentSeriesId) {
    throw Error('Either Artist ID, Book Chapter ID, Content ID or Content Series ID is required');
  }

  const data = {
    tag_id: tagId,
  };

  if (priority) {
    data.priority = priority;
  }

  if (artistId) {
    data.artist_id = artistId;
  } else if (bookChapterId) {
    data.book_chapter_id = bookChapterId;
  } else if (contentId) {
    data.content_id = contentId;
  } else if (contentSeriesId) {
    data.content_series_id = contentSeriesId;
  }

  const url = `/web/studio/taggings`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
