import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { PayoutStatus } from '../Payouts/PayoutStatus';

export function PayoutDetailsHeader({ date, status }) {
  return (
    <div className="flex flex-col gap-1">
      <Text color={colors.text_secondary} className="text-sm font-medium">
        Date
      </Text>
      <div className="flex flex-row items-center gap-4">
        <Text className="text-3xl font-bold">{date}</Text>
        <PayoutStatus status={status} />
      </div>
    </div>
  );
}
