import type { ChapterResponse } from '../getChapters';
import type { Chapter } from '../types';

export const mapChapters = (data: ChapterResponse[]): Chapter[] => {
  return data.map(mapChapter);
};

export const mapChapter = (data: ChapterResponse): Chapter => {
  return {
    id: data.id,
    title: data.title,
    text: data.text,
    draft: data.draft,
    lastUpdate: data.last_update,
    audiobookFileUrl: data?.audiobook_file_url ?? null,
  };
};
