import api from '@pray/shared/utils/api';

export async function updateTag({ tagId, name, description }) {
  if (!tagId) throw Error('Tag ID is required');

  const data = {};

  if (name !== undefined) data.name = name;
  if (description !== undefined) data.description = description;

  const url = `/web/studio/tags/${tagId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
