import { useEffect, useState } from 'react';

import { CONTENT_VISIBILITY, LOCALES } from '@pray/shared/constants';
import studioService from '@pray/shared/services/studioService';

import { useSideBarContentDetails } from 'components/pages/StudioPage/components/SideBar/SideBarContentDetails';

export default function usePlaylistDetails({ artistId, contentSeriesId, locale = null }) {
  const [isLoading, setIsLoading] = useState(true);
  const [playlist, setPlaylist] = useState({});
  const [translations, setTranslations] = useState([]);
  const { setContentDetails } = useSideBarContentDetails();

  const updatePlaylistTranslations = async () => {
    const response = await studioService.contentSeries.getContentSeriesById({
      artistId,
      contentSeriesId,
    });

    setTranslations(response.data.translations || []);
  };

  const fetchContentSeriesData = async (isRefetch = true) => {
    if (!artistId || !contentSeriesId) {
      setIsLoading(false);
      return;
    }

    setIsLoading(!isRefetch);

    try {
      if (!locale || locale === LOCALES.DEFAULT) {
        // fetch default locale playlist
        const response = await studioService.contentSeries.getContentSeriesById({
          artistId,
          contentSeriesId,
        });

        const playlistData = response.data;
        setPlaylist(playlistData);
        setTranslations(playlistData.translations || []);

        setContentDetails({
          title: playlistData.title,
          imageUrl: playlistData.image_url,
          isVisible: playlistData.is_visible,
        });

        return;
      }

      // fetch localized playlist details
      const response = await studioService.contentSeriesTranslation.getLocalizedContentSeriesDetails({
        artistId,
        contentSeriesId,
        locale,
      });

      const playlistData = response.data;

      if (playlistData) {
        setPlaylist((prevData) => {
          const nextPlaylistData = {
            ...prevData,
            ...playlistData,
            visibility: playlistData.is_visible ? CONTENT_VISIBILITY.PUBLIC : CONTENT_VISIBILITY.PRIVATE,
            locale,
          };

          if (prevData.is_processing && !playlistData?.is_processing) {
            delete nextPlaylistData.is_processing;
            updatePlaylistTranslations();
          }

          return nextPlaylistData;
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContentSeriesData(false);
  }, [artistId, contentSeriesId, locale]);

  useEffect(() => {
    let timeout;
    if (playlist?.is_processing) {
      timeout = setTimeout(fetchContentSeriesData, 15 * 1000); // fetch content series details every 15 seconds
    }
    return () => clearTimeout(timeout);
  }, [playlist]);

  return {
    isLoading,
    playlist,
    translations,
    fetchContentSeriesData,
  };
}
