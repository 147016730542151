import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOCALES } from '@pray/shared/constants';
import studioService from '@pray/shared/services/studioService';
import { useUpdateContentTranscriptMultiVoices } from '@pray/shared/services/studioService/contentTranscripts';

import WorkflowAITranslationStatusBanner from '../../../components/WorkflowAITranslationStatusBanner';
import { workflowAiTranslationStatus } from '../../../components/WorkflowAITranslationStatusBanner/constants';

import TranslateBanner from '@/components/pages/StudioPage/components/TranslateBanner/TranslateBanner';
import useTriggerTranslationSynthesis from '@/hooks/transcripts/useTriggerTranslationSynthesis';
import useAppRoutes from '@/hooks/useAppRoutes';

export default function ContentDetailsBanners({
  artistId,
  title,
  locale,
  content,
  isLoading,
  isLocalized,
  translations,
  workflowAIDetails,
  onTranslationStarted,
}) {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const { triggerTranslationSynthesis, submittedAt } = useTriggerTranslationSynthesis(artistId, content?.id, locale);
  const { updateContentTranscriptMultiVoices } = useUpdateContentTranscriptMultiVoices();

  const items = translations || [];

  const selectedItem = useMemo(() => {
    if (!locale) return items.find((item) => item.locale === LOCALES.DEFAULT);
    return items.find((item) => item.locale === locale);
  }, [locale, items]);

  const language = selectedItem?.language || '';

  if (isLoading) return null;

  const isMultiSpeakers = (content.speaker_count || 0) > 1;
  const isVoiceAssigned = !!content.en_multi_voices_transcript_exists;
  const isWorkflowAIStatusProcessing = Object.values(workflowAiTranslationStatus).includes(
    workflowAIDetails.translationStatus
  );

  const handleTranslateButtonClick = async () => {
    const { content_id: contentId } = content;

    if (isMultiSpeakers) {
      await updateContentTranscriptMultiVoices({
        artistId,
        contentId,
        locale: LOCALES.DEFAULT,
        targetLocale: locale,
        useExistingMultiVoicesTranscript: true,
      });
    } else {
      await studioService.contentTranslation.triggerContentTranslation({
        artistId,
        contentId,
        locale,
      });
    }

    onTranslationStarted?.();
  };

  const isShowAnalyzingAudioBanner = content.transcript_status === 'processing' && !content.is_video;
  const isShowMultiSpeakersBanner = !isLocalized && isMultiSpeakers && !isVoiceAssigned && !content.is_video;
  const isShowTranslationBanner = !isLocalized || content.is_processing;
  const isShowWorkflowAIStatusBanner = isLocalized && isWorkflowAIStatusProcessing;

  if (
    !isShowAnalyzingAudioBanner &&
    !isShowMultiSpeakersBanner &&
    !isShowTranslationBanner &&
    !isShowWorkflowAIStatusBanner
  ) {
    return null;
  }

  return (
    <div className="max-w-screen-sm space-y-4">
      {(() => {
        if (isShowAnalyzingAudioBanner) {
          return <AnalyzingAudioBanner />;
        }

        if (isShowMultiSpeakersBanner) {
          return (
            <MultiSpeakersBanner
              onButtonClick={() => {
                const transcriptVoicesUrl = appRoutes.artistContentTranscriptVoices(content.content_id);
                navigate(`${transcriptVoicesUrl}?locale=${LOCALES.DEFAULT}&targetLocale=${locale}`);
              }}
            />
          );
        }

        if (isShowTranslationBanner) {
          return (
            <TranslationBanner
              title={title}
              content={content}
              language={language}
              onTranslateButtonClick={handleTranslateButtonClick}
            />
          );
        }

        return null;
      })()}

      {isShowWorkflowAIStatusBanner && (
        <WorkflowAITranslationStatusBanner
          translationStatus={workflowAIDetails.translationStatus}
          isButtonDisabled={submittedAt !== 0}
          onButtonClick={triggerTranslationSynthesis}
        />
      )}
    </div>
  );
}

function TranslationBanner({ title, language, content, onTranslateButtonClick }) {
  if (content.translation_status === 'pending' || content.is_processing) {
    return (
      <TranslateBanner
        title={`Your episode is being translated in ${language}.`}
        description="You can leave this page and come back later to check on the progress. The average translation time is 5-10 minutes, depending on the length of the episode."
        isProcessing
        buttonLabel="Translating"
        onTranslateButtonClick={onTranslateButtonClick}
      />
    );
  }

  if (content.translation_status === 'failed') {
    return (
      <TranslateBanner
        title="Sorry, something went wrong."
        description="Please click 'Retry Translate' to try again"
        buttonLabel="Retry Translate"
        onTranslateButtonClick={onTranslateButtonClick}
      />
    );
  }

  return (
    <TranslateBanner
      title={`Translate "${title}" into ${language}`}
      description={`Pray.com will translate your episode’s title and description into ${language} and use advanced AI to translate your voice into ${language}. This innovative approach ensures effortless and accurate translation, enhancing listeners’ experience.`}
      isProcessing={content.is_processing}
      buttonLabel={content.is_processing ? 'Translating' : 'Translate'}
      onTranslateButtonClick={onTranslateButtonClick}
    />
  );
}

function AnalyzingAudioBanner() {
  return (
    <TranslateBanner
      variant="audio-analysis"
      title="🔎 Analyzing your Audio"
      description="Our AI model is analyzing your file to identify how many speakers are present. This step is essential to ensure accurate translation and seamless output."
      isProcessing
      buttonLabel="Analyzing"
    />
  );
}

function MultiSpeakersBanner({ onButtonClick }) {
  return (
    <TranslateBanner
      variant="multi-speakers"
      title="🗣️ We’ve detected Multi-Speakers"
      description="Multiple speakers detected in your audio. Assign custom voices for each speaker to ensure accurate translation."
      buttonLabel="Assign Voices"
      onTranslateButtonClick={onButtonClick}
    />
  );
}
