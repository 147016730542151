import api from '@pray/shared/utils/api';

export async function removeTagging({ taggingId }) {
  if (!taggingId) throw Error('Tagging ID is required');

  const url = `/web/studio/taggings/${taggingId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data.data;
}
