import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useRetriggerTranslations(artistId, contentId) {
  const queryClient = useQueryClient();

  async function retriggerTranslations({ targetLocaleCodes, sourceContentIds, sourceContentSeriesIds }) {
    const response = await studioService.translations.retriggerTranslations({
      artistId,
      targetLocaleCodes,
      sourceContentIds,
      sourceContentSeriesIds,
    });

    return response?.data?.[0];
  }

  const mutation = useMutation({
    mutationFn: retriggerTranslations,
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: contentId ? queryKeys.artistContent(artistId, contentId) : queryKeys.artistContent(artistId),
        exact: false,
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    retriggerTranslations: mutateAsync,
    ...rest,
  };
}
