import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import InputField from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import TimePicker from '@pray/shared/components/ui/TimePicker';
import { useAuth } from '@pray/shared/hooks/useAuth';
import useForm from '@pray/shared/hooks/useForm';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import { copyToClipboard } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';
import { isValidUrl } from '@pray/shared/utils/validation';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import ArtistDropdown from 'components/pages/StudioPage/components/ArtistDropdown/ArtistDropdown';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import DailyPhonePreview from 'components/pages/StudioPage/components/PhonePreview/PhonePreview';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import UploaderButton from 'components/pages/StudioPage/components/UploaderButton/UploaderButton';
import { DAILY_TYPE, PRAY_ARTIST_NAME } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useGetDailySeriesFromArtist from 'hooks/useGetDailySeriesFromArtist';
import { Copy } from 'images/icons';
import { Permissions } from 'utils/auth';

import styles from './DailiesForm.module.scss';

const initialFormData = {
  id: null,
  publishDate: '',
  dailySeries: '',
  artistId: null,
  body: '',
  transcript: '',
  version: '',
  reference: '',
  background: '',
  audio: '',
  video: '',
};

export default function DailiesForm({ data: dailyItem, onSaveSuccess = (_dailySeriesId, _responseId) => null }) {
  const toast = useToastMessage();
  const { permissions } = useAuth();
  const { artists, selectedArtist } = useStudioContext();
  const [isPublishing, setIsPublishing] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewAudio, setPreviewAudio] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const [dailyArtistList, setDailyArtistList] = useState(artists);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [coverArtSuggestions, setCoverArtSuggestions] = useState({
    square: null,
    portrait: null,
    landscape: null,
  });

  // Forcing the artist to be PRAY
  const artistId = artists.find((artist) => artist.name === PRAY_ARTIST_NAME)?.id;
  const { dailySeries } = useGetDailySeriesFromArtist(artistId);

  const [searchParams] = useSearchParams();
  const queryParamsPublishDate = searchParams.get('date');
  const queryParamsHour = searchParams.get('hour');

  const getInitialData = () => {
    const data = dailyItem?.id ? dailyItem : initialFormData;

    /**
     * We have two types of dailies:
     * - Ones with pre-defined start of daily hour e.g. Morning, Evening, etc.
     *    - The scheduled at time should be the same for all user across the globe
     *    - The time for a user in New York should be the same for a user in Tokyo
     * - Pray Radio Dailies which the time
     *    - The scheduled at time should be set according to the user's timezone
     *    - Like a live event that happens in TV, the time for a user in
     *    New York should be different from a user in Tokyo
     */

    // The field daily_series_start_of_daily_hour is set in the daily series metadata
    // Example of Daily Series like this: "Morning", "Evening", "Nightly"
    const dailySeriesStartOfDailyHour = dailyItem?.daily_series_start_of_daily_hour ?? null;
    const hasStartOfDailyHour = dailySeriesStartOfDailyHour !== null;

    let publishDateTime = '';

    if (data.publishDate) {
      if (hasStartOfDailyHour) {
        // In this case we need to manually set the time of the publish date to the start of the daily hour.
        // Since this is the day it will be presented for each user on Pray App.
        publishDateTime = DateTime.fromISO(data.publishDate, { zone: 'utc' })
          .set({ hour: dailySeriesStartOfDailyHour })
          .toFormat('yyyy-MM-dd HH:mm');
      } else {
        // In this case we don't have a start of daily hour, so we just set the publish date as it is.
        publishDateTime = DateTime.fromISO(data.publishDate).toFormat('yyyy-MM-dd HH:mm');
      }
    } else {
      publishDateTime = DateTime.local().toISODate();
    }

    const [publishDate = '', startTime = ''] = publishDateTime.split(' ');

    const dailyType = data.videoUrl ? DAILY_TYPE.VIDEO : DAILY_TYPE.AUDIO;

    return {
      id: data.id || '',
      publishDate,
      startTime,
      dailySeries: data.dailySeries || '',
      artistId: data.artistId || null,
      body: data.body || '',
      transcript: data.transcript || '',
      quote: data.quote || '',
      version: data.version || '',
      reference: data.reference || '',
      background: data.backgroundFile || data.backgroundUrl || null,
      audio: data.audioFile || data.audioUrl || null,
      video: data.videoFile || data.videoUrl || null,

      portrait_cover_art_image_url: data.portrait_cover_art_image_url || null,
      landscape_cover_art_image_url: data.landscape_cover_art_image_url || null,
      square_cover_art_image_url: data.square_cover_art_image_url || null,
      leader_background_image_url: data.leader_background_image_url || null,

      daily_item_from_rss: data.daily_item_from_rss || false,

      dailyType: data.dailyType || dailyType,
    };
  };

  const form = useForm(getInitialData());

  useEffect(() => {
    if (queryParamsPublishDate && DateTime.fromISO(queryParamsPublishDate).isValid) {
      form.setValue('publishDate', queryParamsPublishDate);
    }

    if (queryParamsHour) {
      form.setValue('startTime', DateTime.local().set({ hour: +queryParamsHour, minute: 0 }).toFormat('HH:mm'));
    }
  }, [queryParamsPublishDate, queryParamsHour]);

  useEffect(() => {
    if (!dailyItem?.artistId) return;

    const fetchDailyArtist = async (artistId) => {
      const { data: artist } = await studioService.artist.getArtist({ artistId });
      logger.info('Found Daily Artist', artist);
      setDailyArtistList((artists) => [...artists, artist]);
    };

    const dailyArtistExists = dailyArtistList.some(({ id }) => id === dailyItem.artistId);
    if (!dailyArtistExists) {
      fetchDailyArtist(dailyItem.artistId).catch((error) => {
        const errorMsg = "Couldn't fetch Artist associated with Daily. Try refreshing the page.";
        logger.error(errorMsg, error);
        toast.show({ error: errorMsg });
      });
    }
  }, []);

  useEffect(() => {
    if (!form.values.artistId) return;

    fetchCoverArt(form.values.artistId).then((coverArtData) => {
      form.setValue('leader_background_image_url', coverArtData.leader_background_image_url);

      setCoverArtSuggestions({
        square: coverArtData.square,
        portrait: coverArtData.portrait,
        landscape: coverArtData.landscape,
      });
    });
  }, [form.values.artistId]);

  const validateDaily = () => {
    const errors = {};

    if (!form.values.publishDate) errors.publishDate = 'Invalid publish date';
    if (!form.values.dailySeries.trim()) errors.dailySeries = 'Invalid Daily Series';
    if (form.values.dailyType === DAILY_TYPE.AUDIO) {
      if (!form.values.quote.trim()) errors.quote = 'Invalid opening prayer text';
      if (!form.values.body.trim()) errors.body = 'Invalid quote text';
      if (!form.values.version.trim()) errors.version = 'Invalid version text';
      if (!form.values.reference.trim()) errors.reference = 'Invalid reference text';
      if (!form.values.audio) errors.audio = 'Audio file is required, please upload a file';
    }
    if (form.values.dailyType === DAILY_TYPE.VIDEO) {
      if (!form.values.video) errors.video = 'Video file is required, please upload a file';
    }
    if (!form.values.background) errors.background = 'Background image is required, please upload a file';

    form.setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const saveFileToS3 = async (payload, file, formName) => {
    let url = null;
    try {
      const response = await s3Service.signAndUpload({
        file,
        type: 'dailyItem',
        signParams: {
          daily_item_id: payload.id || 'NEW',
        },
      });
      url = response.url;
    } catch (err) {
      logger.error('Failed to upload file to S3', err);
      form.setError(formName, err);
      throw err;
    }

    return { url, formName };
  };

  const saveFileToS3ArtistAsset = async (payload, file, formName) => {
    let url = null;
    try {
      const response = await s3Service.signAndUpload({
        file,
        type: 'artistAsset',
        signParams: {
          artist_id: payload.artist_id,
          artist_asset_type: 'daily-background',
        },
      });
      url = response.url;
    } catch (err) {
      logger.error('Failed to upload file to S3', err);
      form.setError(formName, err);
      throw err;
    }

    return { url, formName };
  };

  const handleBlurOnAudioInput = async (e) => {
    const url = e.target.value;
    if (!isValidUrl(url, { scheme: 'https' })) return;
    setPreviewAudio(url);
    form.setValue('audio', url);
  };

  const handleBlurOnVideoInput = async (e) => {
    const url = e.target.value;
    if (!isValidUrl(url, { scheme: 'https' })) return;
    setPreviewVideo(url);
    form.setValue('video', url);
  };

  const handlePublish = async () => {
    if (!validateDaily()) return;

    // Merge the publish date and start time into a single DateTime object.
    const [hour, minute] = form.values.startTime.split(':');
    const publishDate = DateTime.fromISO(form.values.publishDate).set({
      hour: Number(hour),
      minute: Number(minute),
    });

    const scheduledAtUserDateOnly = form.values.publishDate;

    const payload = {
      id: dailyItem?.id || 'NEW',
      artist_id: form.values.artistId || selectedArtist.id,
      daily_series_id: form.values.dailySeries,
      scheduled_at: publishDate.toUTC().toISO(),
      scheduled_at_user_date_only: scheduledAtUserDateOnly,
      body: form.values.body ?? null,
      quote: form.values.quote ?? null,
      version: form.values.version ?? null,
      reference: form.values.reference ?? null,
      type: 'christian',
      square_cover_art_image_url: form.values.square_cover_art_image_url,
      portrait_cover_art_image_url: form.values.portrait_cover_art_image_url,
      landscape_cover_art_image_url: form.values.landscape_cover_art_image_url,
    };

    try {
      setIsPublishing(true);

      const {
        background,
        audio,
        video,
        square_cover_art_image_url: squareCoverArtImageUrl,
        portrait_cover_art_image_url: portraitCoverArtImageUrl,
        landscape_cover_art_image_url: landscapeCoverArtImageUrl,
        leader_background_image_url: leaderBackgroundImageUrl,
      } = form.values;

      const promises = [];
      if (background instanceof File) {
        promises.push(saveFileToS3(payload, background, 'background'));
      }
      if (audio instanceof File) {
        promises.push(saveFileToS3(payload, audio, 'audio'));
      }
      if (video instanceof File) {
        promises.push(saveFileToS3(payload, video, 'video'));
      }
      if (squareCoverArtImageUrl instanceof File) {
        promises.push(saveFileToS3(payload, squareCoverArtImageUrl, 'square_cover_art_image_url'));
      }
      if (portraitCoverArtImageUrl instanceof File) {
        promises.push(saveFileToS3(payload, portraitCoverArtImageUrl, 'portrait_cover_art_image_url'));
      }
      if (landscapeCoverArtImageUrl instanceof File) {
        promises.push(saveFileToS3(payload, landscapeCoverArtImageUrl, 'landscape_cover_art_image_url'));
      }
      if (leaderBackgroundImageUrl instanceof File) {
        promises.push(saveFileToS3ArtistAsset(payload, leaderBackgroundImageUrl, 'leader_background_image_url'));
      }

      const defaultUrls = {
        background: form.values.background,
        audio: form.values.audio,
        video: form.values.video,
        square_cover_art_image_url: form.values.square_cover_art_image_url,
        portrait_cover_art_image_url: form.values.portrait_cover_art_image_url,
        landscape_cover_art_image_url: form.values.landscape_cover_art_image_url,
        leader_background_image_url: form.values.leader_background_image_url,
      };

      const responses = await Promise.all(promises);
      const urls = responses.reduce((acc, item) => ({ ...acc, [item.formName]: item.url }), defaultUrls);

      payload.background_image_url = urls.background;
      payload.audio_url = urls.audio;
      payload.video_url = urls.video;
      payload.square_cover_art_image_url = urls.square_cover_art_image_url;
      payload.portrait_cover_art_image_url = urls.portrait_cover_art_image_url;
      payload.landscape_cover_art_image_url = urls.landscape_cover_art_image_url;
      payload.leader_background_image_url = urls.leader_background_image_url;

      if (!payload.id || payload.id === 'NEW') {
        const [response] = await studioService.dailyItems.createDailyItems({
          artistId: selectedArtist.id,
          payload,
          dailySeriesId: form.values.dailySeries,
        });

        logger.info('Successfully created daily item', response);
        toast.show({ success: 'Daily Item created successfully' });
        onSaveSuccess(response.daily_series_id, response.id);
      } else {
        const [response] = await studioService.dailyItems.updateDailyItemById({
          artistId: selectedArtist.id,
          dailySeriesId: dailyItem?.dailySeries || form.values.dailySeries,
          dailyItemId: payload.id,
          payload,
        });

        logger.info('Successfully updated the daily item', response);
        toast.show({ success: 'Daily Item updated successfully' });
        onSaveSuccess(response.daily_series_id, response.id);
      }
    } catch (error) {
      logger.error('Failed on publishing the Daily Item', error);
    } finally {
      setIsPublishing(false);
    }
  };

  const renderTopRightButtons = () => {
    return (
      <div className="flex space-x-4">
        <Button
          variant={buttons.variant.secondary}
          onClick={() => {
            if (state?.from) {
              navigate(-1);
            } else {
              navigate('/dailies');
            }
          }}
        >
          Cancel
        </Button>

        <ActionButton isLoading={isPublishing} text="Save" onClick={handlePublish} />
      </div>
    );
  };

  const dailyArtist = form.values.artistId ? [dailyArtistList.find(({ id }) => id === form.values.artistId) ?? {}] : [];

  const handleDailyArtistChange = async (artist) => {
    const artistExists = dailyArtistList.some(({ id }) => id === artist.id);

    if (!artistExists) {
      setDailyArtistList((artists) => [...artists, artist]);
    }

    form.setValue('artistId', artist.id);

    const coverArtData = await fetchCoverArt(artist.id);

    // Set the cover art images to the form
    form.setValue('square_cover_art_image_url', coverArtData.square);
    form.setValue('landscape_cover_art_image_url', coverArtData.landscape);
    form.setValue('portrait_cover_art_image_url', coverArtData.portrait);
  };

  const fetchCoverArt = async (artistId) => {
    try {
      const { data: artistCoverArt } = await studioService.artist.getArtistCoverArt({ artistId });
      return artistCoverArt;
    } catch (error) {
      logger.error('Failed to fetch cover art for artist', error);
      return {};
    }
  };

  const handleShowSuggestionsButton = () => {
    if (!form) return;
    if (!form.values.artistId) return;
    if (!coverArtSuggestions) return;

    form.setValue('square_cover_art_image_url', coverArtSuggestions.square);
    form.setValue('landscape_cover_art_image_url', coverArtSuggestions.landscape);
    form.setValue('portrait_cover_art_image_url', coverArtSuggestions.portrait);
  };

  const handleCopyLink = (attribute) => {
    copyToClipboard(
      form.values[attribute],
      () => toast.show({ success: 'Link copied to clipboard' }),
      () => toast.show({ error: "Sorry, the link wasn't copied due to an error" })
    );
  };

  return (
    <TabPage title="New Daily" topRightButtons={renderTopRightButtons()}>
      <div className={styles.grid}>
        <div className={styles.form}>
          <Section title="Add Basic Details">
            {permissions.has(Permissions.STUDIO_ADMIN) && (
              <div className={styles.formField}>
                <InputField
                  label="Daily Type"
                  className={styles.selectField}
                  name="dailyType"
                  value={form.values.dailyType}
                  error={form.errors.dailyType}
                  onChange={form.handleChange}
                >
                  <option value="audio">Audio</option>
                  <option value="video">Video</option>
                </InputField>
              </div>
            )}
            <div className="flex gap-3">
              <div className={`${styles.formField} z-10 w-full`}>
                <DatePicker
                  {...(form.values.daily_item_from_rss && { disabled: true })}
                  label="Publish date"
                  value={form.values.publishDate}
                  errorMessage={form.errors.publishDate}
                  onChange={(date) => form.setValue('publishDate', date)}
                />
              </div>

              <div className={`${styles.formField} z-10 w-full`}>
                <TimePicker
                  {...(form.values.daily_item_from_rss && { disabled: true })}
                  {...(typeof dailyItem?.daily_series_start_of_daily_hour === 'number' && { disabled: true })}
                  label="Start time"
                  value={form.values.startTime}
                  errorMessage={form.errors.startTime}
                  onChange={(time) => form.setValue('startTime', time)}
                />
              </div>
            </div>

            <div className={styles.formField}>
              <InputField
                {...(form.values.daily_item_from_rss && { disabled: true })}
                label="Daily Series"
                className={styles.selectField}
                name="dailySeries"
                value={form.values.dailySeries}
                error={form.errors.dailySeries}
                onChange={form.handleChange}
              >
                <option value="" />

                {dailySeries.map((ds) => (
                  <option key={ds.id} value={ds.id}>
                    {ds.title}
                  </option>
                ))}
              </InputField>
            </div>

            {form.values.daily_item_from_rss && (
              <div className={styles.formField}>
                <TextInput
                  disabled
                  placeholder="Title"
                  className={styles.selectField}
                  name="title"
                  value={dailyItem.title}
                />
              </div>
            )}

            {form.values.daily_item_from_rss && (
              <div className={[styles.formField, styles.formFieldWithButton].join(' ')} style={{ display: 'flex' }}>
                <TextInput
                  disabled
                  placeholder="Omny ID"
                  className={styles.omnyId}
                  name="omny_id"
                  value={dailyItem.rss_guid}
                />

                <Button
                  variant={buttons.variant.secondary}
                  size={buttons.size.medium}
                  key="signup-button"
                  target="_blank"
                  to={dailyItem.omny_studio_link}
                >
                  Link
                </Button>
              </div>
            )}

            <div className={styles.formField}>
              <ArtistDropdown
                isFormElement
                label={dailyArtist?.[0]?.name !== PRAY_ARTIST_NAME && 'Leader'}
                artists={dailyArtistList}
                selectedArtists={dailyArtist}
                onChange={handleDailyArtistChange}
              />
            </div>
          </Section>

          <Section title="Fill out the content">
            {form.values.dailyType === DAILY_TYPE.AUDIO && (
              <div className={styles.formField}>
                <TextArea
                  rows={5}
                  label="Quote"
                  name="body"
                  value={form.values.body}
                  errorMessage={form.errors.body}
                  onChange={form.handleChange}
                />
              </div>
            )}

            {form.values.dailyType === DAILY_TYPE.AUDIO && (
              <div className={styles.formField}>
                <TextArea
                  rows={5}
                  label="Opening Prayer"
                  name="quote"
                  value={form.values.quote}
                  errorMessage={form.errors.quote}
                  onChange={form.handleChange}
                />
              </div>
            )}

            {form.values.dailyType === DAILY_TYPE.VIDEO && (
              <div className={styles.formField}>
                <TextInput
                  placeholder="Title"
                  className={styles.selectField}
                  name="body"
                  value={form.values.body}
                  onChange={form.handleChange}
                />
              </div>
            )}

            <div className={styles.formField}>
              <TextArea
                disabled
                rows={5}
                label="Transcript"
                name="transcript"
                value={form.values.transcript}
                errorMessage={form.errors.transcript}
                onChange={form.handleChange}
              />
            </div>

            {form.values.dailyType === DAILY_TYPE.AUDIO && (
              <div className={styles.formField}>
                <TextInput
                  label="Version"
                  name="version"
                  value={form.values.version}
                  errorMessage={form.errors.version}
                  onChange={form.handleChange}
                />
              </div>
            )}

            {form.values.dailyType === DAILY_TYPE.AUDIO && (
              <div className={styles.formField}>
                <TextInput
                  label="Reference"
                  name="reference"
                  value={form.values.reference}
                  errorMessage={form.errors.reference}
                  onChange={form.handleChange}
                />
              </div>
            )}
          </Section>

          <Section title="Add Media">
            <div className={styles.formField}>
              <Text className={styles.label}>Background Image</Text>
              <Text className={styles.description}>Supported image formats include .jpg, .png, and .jpeg.</Text>
              <UploaderButton
                text="Add Image"
                name="background"
                fileType="image"
                validExtensions={['jpg', 'png', 'jpeg']}
                previewFile={form.values.background}
                error={form.errors.background}
                onChange={(file, url) => {
                  setPreviewImage(url);
                  form.setValue('background', file || url);
                }}
              />
            </div>

            {form.values.dailyType === DAILY_TYPE.AUDIO && (
              <div className={styles.formField}>
                <Text className={styles.label}>Audio</Text>
                <Text className={styles.description}>Supported audio formats include .mp3, .wav, and .aac.</Text>
                <div className={previewAudio ? [styles.audioSection, styles.oneItem].join(' ') : styles.audioSection}>
                  <UploaderButton
                    text="Add Audio"
                    name="audio"
                    disabled={form.values.daily_item_from_rss}
                    previewFile={form.values.audio}
                    onChange={(file, url) => {
                      setPreviewAudio(url);
                      form.setValue('audio', file || url);
                    }}
                    fileType="audio"
                    validExtensions={['mp3', 'wav', 'aac']}
                    error={form.errors.audio}
                  />
                  {!previewAudio && (
                    <TextInput
                      className={styles.audioUrl}
                      placeholder="Enter URI Link"
                      onBlur={handleBlurOnAudioInput}
                    />
                  )}
                  {previewAudio && typeof form.values.audio === 'string' && (
                    <div className={[styles.audioSection__url, styles.typography, styles.footnote].join(' ')}>
                      <p>
                        <strong>URL Link used</strong>
                      </p>
                      <div className={styles.audioSection__url__container}>
                        <p>{form.values.audio}</p>
                        <button type="button" onClick={() => handleCopyLink('audio')}>
                          <Copy />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {form.values.dailyType === DAILY_TYPE.VIDEO && (
              <div className={styles.formField}>
                <Text className={styles.label}>Video</Text>
                <Text className={styles.description}>Supported video formats include .mp4.</Text>
                <div
                  className={
                    previewAudio || previewVideo ? [styles.audioSection, styles.oneItem].join(' ') : styles.audioSection
                  }
                >
                  <UploaderButton
                    text="Add Video"
                    name="video"
                    disabled={form.values.daily_item_from_rss}
                    previewFile={form.values.video}
                    onChange={(file, url) => {
                      setPreviewVideo(url);
                      form.setValue('video', file || url);
                    }}
                    fileType="video"
                    validExtensions={['mp4']}
                    error={form.errors.video}
                  />
                  {!previewVideo && (
                    <TextInput
                      className={styles.audioUrl}
                      placeholder="Enter URI Link"
                      onBlur={handleBlurOnVideoInput}
                    />
                  )}
                  {previewVideo && typeof form.values.video === 'string' && (
                    <div className={[styles.audioSection__url, styles.typography, styles.footnote].join(' ')}>
                      <Text className="text-black" variant={typography.heading_md}>
                        URL Link used
                      </Text>
                      <div className={styles.audioSection__url__container}>
                        <p>{form.values.video}</p>
                        <Button variant={buttons.variant.tertiary} onClick={() => handleCopyLink('video')}>
                          <Copy />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className={styles.formField}>
              <div className="mt-6 flex items-center justify-between">
                <div>
                  <Text className={styles.label}>Cover Art</Text>

                  <Text className={[styles.description, '!mb-8'].join(' ')}>
                    Supported image formats include .jpg, .png, and .jpeg.
                  </Text>
                </div>
                <div>
                  <Button className="mb-6" variant={buttons.variant.secondary} onClick={handleShowSuggestionsButton}>
                    Show suggestions
                  </Button>
                </div>
              </div>

              <div className={styles.coverArtField}>
                <MediaUpload
                  labelPlacement="right"
                  imageUrl={form.values.square_cover_art_image_url}
                  title="Square"
                  description="Use a picture that’s 1080x1080 pixels and 4MB or less."
                  uploadButtonLabel={form.values.square_cover_art_image_url ? 'Change Image' : 'Upload Image'}
                  error={form.errors.square_cover_art_image_url}
                  cropProps={{ aspect: 1 }}
                  onFileChange={(file) => form.setValue('square_cover_art_image_url', file)}
                  onError={(error) => form.setError('square_cover_art_image_url', error)}
                />
              </div>
              <div className={styles.coverArtField}>
                <MediaUpload
                  labelPlacement="right"
                  imageUrl={form.values.portrait_cover_art_image_url}
                  title="Portrait"
                  description="Use a picture that’s 1080x1620 pixels and 4MB or less."
                  uploadButtonLabel={form.values.portrait_cover_art_image_url ? 'Change Image' : 'Upload Image'}
                  error={form.errors.portrait_cover_art_image_url}
                  cropProps={{ aspect: 0.667 }}
                  onFileChange={(file) => form.setValue('portrait_cover_art_image_url', file)}
                  onError={(error) => form.setError('portrait_cover_art_image_url', error)}
                />
              </div>
              <div className={styles.coverArtField}>
                <MediaUpload
                  labelPlacement="right"
                  imageUrl={form.values.landscape_cover_art_image_url}
                  title="Landscape"
                  description="Use a picture that’s 2040x1152 pixels and 4MB or less."
                  uploadButtonLabel={form.values.landscape_cover_art_image_url ? 'Change Image' : 'Upload Image'}
                  error={form.errors.landscape_cover_art_image_url}
                  cropProps={{ aspect: 1.77 }}
                  onFileChange={(file) => form.setValue('landscape_cover_art_image_url', file)}
                  onError={(error) => form.setError('landscape_cover_art_image_url', error)}
                />
              </div>
            </div>

            <div className={styles.formField}>
              <Text className={styles.label}>Pray Radio Background Image</Text>
              <Text className={[styles.description, '!mb-8'].join(' ')}>
                Supported image formats include .jpg, .png, and .jpeg.
              </Text>

              <MediaUpload
                labelPlacement="right"
                title="Portrait"
                description="Use a picture that’s 1080x1620 pixels and 4MB or less."
                imageUrl={form.values.leader_background_image_url}
                uploadButtonLabel={form.values.leader_background_image_url ? 'Change Image' : 'Upload Image'}
                error={form.errors.leader_background_image_url}
                cropProps={{ aspect: 9 / 16 }}
                onFileChange={(file) => form.setValue('leader_background_image_url', file)}
                onError={(error) => form.setError('leader_background_image_url', error)}
              />
            </div>
          </Section>
        </div>

        <div className={styles.phoneView}>
          <DailyPhonePreview
            previewImage={previewImage || form.values.background}
            previewAudio={previewAudio || form.values.audio}
            previewVideo={previewVideo || form.values.video}
            body={form.values.body}
            reference={form.values.reference}
            isLoading={dailyItem?.isMediaLoading || false}
          />
        </div>
      </div>
    </TabPage>
  );
}
