import {
  faAlignCenter,
  faAlignRight,
  faAlignLeft,
  faParagraph,
  faHeading,
  faCode,
  faQuoteRight,
} from '@fortawesome/free-solid-svg-icons';
import { Editor } from '@tiptap/react';

export const TEXT_STYLING_LABELS = {
  HEADING_1: 'Heading 1',
  HEADING_2: 'Heading 2',
  HEADING_3: 'Heading 3',
  CODE_BLOCK: 'Code Block',
  QUOTE: 'Quote',
  TEXT: 'Text',
};

export const DEFAULT_TEXT_STYLING_LABEL = TEXT_STYLING_LABELS.TEXT;

export const TEXT_STYLING_OPTIONS = [
  {
    label: TEXT_STYLING_LABELS.TEXT,
    icon: faParagraph,
    onClick: (editor: Editor) => editor.chain().focus().setParagraph().run(),
  },
  {
    label: TEXT_STYLING_LABELS.HEADING_1,
    icon: faHeading,
    onClick: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 1 }).run(),
  },
  {
    label: TEXT_STYLING_LABELS.HEADING_2,
    icon: faHeading,
    onClick: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 2 }).run(),
  },
  {
    label: TEXT_STYLING_LABELS.HEADING_3,
    icon: faHeading,
    onClick: (editor: Editor) => editor.chain().focus().toggleHeading({ level: 3 }).run(),
  },
  {
    label: TEXT_STYLING_LABELS.CODE_BLOCK,
    icon: faCode,
    onClick: (editor: Editor) => editor.chain().focus().toggleCodeBlock().run(),
  },
  {
    label: TEXT_STYLING_LABELS.QUOTE,
    icon: faQuoteRight,
    onClick: (editor: Editor) => editor.chain().focus().toggleBlockquote().run(),
  },
];

export const TEXT_STYLING_NODE_TYPES = {
  PARAGRAPH: { type: 'paragraph', attrs: null, label: TEXT_STYLING_LABELS.TEXT },
  HEADING_1: { type: 'heading', attrs: { level: 1 }, label: TEXT_STYLING_LABELS.HEADING_1 },
  HEADING_2: { type: 'heading', attrs: { level: 2 }, label: TEXT_STYLING_LABELS.HEADING_2 },
  HEADING_3: { type: 'heading', attrs: { level: 3 }, label: TEXT_STYLING_LABELS.HEADING_3 },
  CODE_BLOCK: { type: 'codeBlock', attrs: null, label: TEXT_STYLING_LABELS.CODE_BLOCK },
  QUOTE: { type: 'blockquote', attrs: null, label: TEXT_STYLING_LABELS.QUOTE },
};

export const ALIGNMENT_LABELS = {
  LEFT: 'Align Left',
  CENTER: 'Align Center',
  RIGHT: 'Align Right',
};

export const ALIGNMENT_OPTIONS = [
  {
    label: ALIGNMENT_LABELS.LEFT,
    icon: faAlignLeft,
    type: 'left',
    onClick: (editor: Editor) => editor.chain().focus().setTextAlign('left').run(),
  },
  {
    label: ALIGNMENT_LABELS.CENTER,
    icon: faAlignCenter,
    type: 'center',
    onClick: (editor: Editor) => editor.chain().focus().setTextAlign('center').run(),
  },
  {
    label: ALIGNMENT_LABELS.RIGHT,
    icon: faAlignRight,
    type: 'right',
    onClick: (editor: Editor) => editor.chain().focus().setTextAlign('right').run(),
  },
];

export const ALIGNMENT_NODE_TYPES = {
  LEFT: { type: 'left', label: ALIGNMENT_LABELS.LEFT, icon: faAlignLeft },
  CENTER: { type: 'center', label: ALIGNMENT_LABELS.CENTER, icon: faAlignCenter },
  RIGHT: { type: 'right', label: ALIGNMENT_LABELS.RIGHT, icon: faAlignRight },
};

export const DEFAULT_ALIGNMENT_OPTION_ICON = ALIGNMENT_NODE_TYPES.LEFT.icon;
