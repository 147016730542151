import type { ArtistVoicesResponse } from '../getArtistVoices';
import type { TemplateVoicesResponse } from '../getTemplateVoices';
import type { Voice } from '../types';

export const mapVoices = (data: ArtistVoicesResponse | TemplateVoicesResponse): Voice[] => {
  return data.voices.map(mapVoice);
};

export const mapVoice = (data: ArtistVoicesResponse['voices'][number]): Voice => {
  return {
    id: data.id,
    artistId: data.artist_id,
    name: data.name,
    audioUrl: data.audio_url,
    isMain: data.is_primary,
  };
};
