import api from '@pray/shared/utils/api';

export async function saveTranscriptVersion({ artistId = null, contentId = null, locale = null, transcript }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');
  if (!transcript) throw Error('Transcript is required');

  const data = {
    transcript,
  };

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/transcripts`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
