import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { Geographies, Marker } from 'react-simple-maps';

import { GEO_URL, US_STATES } from '@pray/shared/constants';

export function LeaderMarker({ artistState, artistAvatar }) {
  // Artist pulsing effect around the avatar
  const artistPulse = useSpring({
    from: { r: 24, opacity: 0.8 },
    to: { r: 32, opacity: 0 },
    config: { duration: 1000 },
    loop: true,
  });

  return (
    <Geographies geography={GEO_URL}>
      {({ projection }) => {
        const artistCoords = projection(US_STATES[artistState]?.coords);

        return (
          <>
            {/* Animated pulsing circle around the artist avatar */}
            <animated.circle
              cx={artistCoords[0]}
              cy={artistCoords[1]}
              r={artistPulse.r}
              fill="none"
              stroke="#E3AF4A"
              strokeWidth={4}
              opacity={artistPulse.opacity}
            />
            {/* Artist's avatar */}
            <Marker coordinates={US_STATES[artistState]?.coords}>
              <foreignObject width={48} height={48} style={{ transform: 'translate(-24px, -24px)' }}>
                <div
                  className="size-full rounded-full border-2 bg-cover bg-center"
                  style={{ backgroundImage: `url(${artistAvatar})`, width: '48px', height: '48px' }}
                />
              </foreignObject>
            </Marker>
          </>
        );
      }}
    </Geographies>
  );
}
