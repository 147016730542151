export const audience = 'Ministry Match Emails';
export const draftCampaignName = 'Untitled Campaign';
export const newTemplateName = 'Untitled Template';
export const tempEmailPreviewImgUrl = 'https://pray.com/images/email-preview.jpg';
export const tempTemplatePreviewImgUrl = 'https://pray.com/images/template-preview.jpg';

export const emailStatus = {
  draft: 'draft',
  now: 'now',
  scheduled: 'scheduled',
  sent: 'sent',
  active: 'active',
  stopped: 'stopped',
};

export const statusToLabelMap = {
  draft: 'draft',
  scheduled: 'scheduled',
  sent: 'published',
  active: 'published',
  stopped: 'unpublished',
};

export const emailActions = {
  createEmail: 'create-email',
  createTemplate: 'create-template',
  manageTemplates: 'manage-templates',
  previewTemplate: 'preview-template',
  selectTemplate: 'select-template',
};

export const templateTypes = {
  prayTemplates: 'pray_templates',
  userTemplates: 'my_templates',
};

export const automationTypes = {
  newMinistryMatchLead: 'new_ministry_match_lead',
};

export const publishTypes = {
  schedule: 'schedule',
  automation: 'automation',
};

export const nameMaxLength = 50;
export const subjectMaxLength = 50;
export const previewTextMaxLength = 50;
