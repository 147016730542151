import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDeleteEmailCampaign() {
  const queryClient = useQueryClient();

  async function deleteEmailCampaign({ artistId = undefined, campaignId = undefined }) {
    return studioService.emailCampaigns.deleteEmailCampaign({ artistId, campaignId });
  }

  const mutation = useMutation({
    mutationFn: deleteEmailCampaign,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistEmailCampaigns(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteEmailCampaign: mutateAsync,
    ...rest,
  };
}
