import React from 'react';

export default function FacebookIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9983 4.00011C12.9705 4.00011 3.99829 12.9714 3.99829 24.0001C3.99829 35.0288 12.9705 44.0001 23.9983 44.0001C35.027 44.0001 43.9983 35.0288 43.9983 24.0001C43.9983 12.9714 35.027 4.00011 23.9983 4.00011ZM30.0852 16.174H27.2244C25.5392 16.174 24.8679 16.5697 24.8679 17.7445V20.5219H30.0852L29.2157 24.8697H24.8679V36.174H19.6505V24.8697H17.0418V20.5219H19.6505V18.1097C19.6505 14.4358 21.1166 11.8262 25.3731 11.8262C27.6531 11.8262 30.0852 12.6958 30.0852 12.6958V16.174Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
