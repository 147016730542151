import React from 'react';
import Helmet from 'react-helmet';

import { PRAY_SHARING_IMAGE } from '@pray/shared/constants';

const Metadata = ({
  description = '',
  image = '',
  canonicalPath = '',
  title = '',
  isNotIndexed = false,
  branchMetadataDeeplink = '',
  themeColor = '#0b0c0d',
}) => {
  const defaultTitle = `Pray: The World's #1 App for Daily Prayer and Biblical Audio Content`;
  const defaultDescription =
    'Pray.com is the #1 app in the world for daily prayer and Biblical audio content. Share inspiration, sleep better and be inspired. Make prayer a priority with Pray.';

  const uri = canonicalPath ? `https://pray.com${canonicalPath}` : 'https://www.pray.com/';

  return (
    <Helmet>
      <title>{`${title || defaultTitle}`}</title>
      <meta charSet="utf-8" />
      <meta
        name="keywords"
        content="pray, prayer, faith, connect, empower, purpose, pray.com, subscription, content, bible, stories, sleep"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      <meta name="description" content={`${description || defaultDescription}`} />
      <meta
        name="robots"
        content={`${
          isNotIndexed ? 'noindex' : 'index'
        }, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />
      <meta name="theme-color" content={themeColor} />

      <link rel="icon" href="/favicon.png" type="image/png" />

      <meta property="fb:app_id" content="260292711225813" />

      <meta property="og:site_name" content="Pray" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${title || defaultTitle}`} />
      <meta property="og:description" content={`${description || defaultDescription}`} />
      <meta property="og:image" content={`${image || PRAY_SHARING_IMAGE}`} />
      <meta property="og:url" content={uri} />

      <meta property="twitter:title" content={`${title || defaultTitle}`} />
      <meta property="twitter:description" content={`${description || defaultDescription}`} />
      <meta property="twitter:url" content={uri} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:alt" content="pray.com" />

      {branchMetadataDeeplink && <meta name="branch:deeplink:deeplink" content={branchMetadataDeeplink} />}

      <script type="application/ld+json" async="true">
        {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "url": "https://www.pray.com",
            "logo": "https://d339bgihw9yfmi.cloudfront.net/logomark.png",
            "name": "Pray",
            "alternateName": "Pray.com",
            "sameAs": [
              "https://www.facebook.com/pray/",
              "https://twitter.com/pray/",
              "https://www.instagram.com/pray/",
              "https://www.youtube.com/channel/UCNyNg5QgG5irAuFCs8L8BSg",
              "https://www.linkedin.com/company/pray.com"
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default Metadata;
