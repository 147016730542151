import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CALENDAR_ROW_HEIGHT } from 'constants.js';

import CalendarHourItem from './CalendarHourItem';

import styles from './EventsCalendar.module.scss';

const DEFAULT_DAILY_DURATION = 30;

export default function CalendarHourItems({ date, hours, events }) {
  const navigate = useNavigate();
  const [eventsWithPositionStyles, setEventsWithPositionStyles] = useState([]);

  useEffect(() => {
    if (!events) {
      setEventsWithPositionStyles([]);
      return;
    }

    const eventsWithPositionStyles = getEventsWithPositionStyles(events);
    setEventsWithPositionStyles(eventsWithPositionStyles);
  }, [events]);

  /**
   * Navigate to the create daily item page
   * @param date - {DateTime}
   * @param hour - {number}
   */
  const navigateToCreateDailyItem = (date, hour = 0) => {
    navigate(`/dailies/create?date=${date.toFormat('yyyy-MM-dd')}&hour=${hour}`, {
      state: {
        from: 'DailiesCalendar',
      },
    });
  };

  /**
   * Get the duration of the event in minutes
   *
   * @param {object} event - The event for which the duration is being calculated
   * @returns number - The duration of the event in minutes
   */
  const getEventDuration = (event) => {
    if (!event.duration || Number.isNaN(parseFloat(event.duration)) || event.duration <= 0) {
      return DEFAULT_DAILY_DURATION;
    }

    return (parseFloat(event.duration) / 60).toFixed(2); // Convert duration from seconds to minutes
  };

  const getEventsForHour = (events, hour) => {
    if (!events) return [];

    const eventsForHour = events
      .filter((event) => {
        if (hour === -1) {
          return event.is_all_day;
        }

        // If the event is all day, we don't want to show it in hour slots of the calendar
        if (event.is_all_day) return false;

        const item24Hour = hour;
        const eventStartHour = DateTime.fromISO(event.scheduled_at).get('hour');
        const eventEndTime = DateTime.fromISO(event.scheduled_at).plus({ minutes: getEventDuration(event) });
        const eventEndHour = eventEndTime.get('hour');

        if (
          eventStartHour === item24Hour || // Event starts at the beginning of the hour
          (eventStartHour < item24Hour && eventEndHour > item24Hour) || // Event spans over the hour
          (eventEndHour === item24Hour && eventEndTime.get('minute') > 0) // Event ends within the hour
        ) {
          return true;
        }

        return false;
      })
      .map((event) => {
        // Mark events that started before the hour and span into the hour
        const spansIntoHour = DateTime.fromISO(event.scheduled_at).get('hour') < hour;

        return {
          ...event,
          spansIntoHour,
        };
      });

    return eventsForHour;
  };

  const getEventDurationInThisHourSlot = (event, hour) => {
    const eventEndTime = DateTime.fromISO(event.scheduled_at).plus({ minutes: getEventDuration(event) });
    // Time object for the current hour
    const currentHourTime = DateTime.fromISO(event.scheduled_at).set({ hour }).startOf('hour');
    const differenceInMinutesOfThisHourAndEventEnd = eventEndTime.diff(currentHourTime, 'minutes').minutes;
    const durationOverlappingIntoHour =
      differenceInMinutesOfThisHourAndEventEnd > 60 ? 60 : differenceInMinutesOfThisHourAndEventEnd;

    return durationOverlappingIntoHour;
  };

  const getEventHeightBasedOnDuration = (event, hour) => {
    const eventStartHour = DateTime.fromISO(event.scheduled_at).get('hour');

    if (eventStartHour === hour) {
      // Calculate the total height of the event based on the duration
      return (((getEventDuration(event) / 60) * 100) / 100) * CALENDAR_ROW_HEIGHT;
    }

    const durationOverlappingIntoHour = getEventDurationInThisHourSlot(event, hour);

    return (((durationOverlappingIntoHour / 60) * 100) / 100) * CALENDAR_ROW_HEIGHT;
  };

  const getOriginalEventWithStyle = (event, eventsWithPositionStyles) => {
    return eventsWithPositionStyles.find((eventWithStyle) => {
      return eventWithStyle.id === event.id && !eventWithStyle.spansIntoHour;
    });
  };

  const updateOriginalEventStyles = (event, eventsWithPositionStyles, styles) => {
    const originalEventWithStyle = getOriginalEventWithStyle(event, eventsWithPositionStyles);

    if (!originalEventWithStyle) return eventsWithPositionStyles;

    const updatedOriginalEventWithStyle = {
      ...originalEventWithStyle,
      styles: {
        ...originalEventWithStyle.styles,
        ...styles,
      },
    };

    const updatedEventsWithPositionStyles = eventsWithPositionStyles.map((eventWithStyle) => {
      if (eventWithStyle.id === event.id && !eventWithStyle.spansIntoHour) {
        return updatedOriginalEventWithStyle;
      }

      return eventWithStyle;
    });

    return updatedEventsWithPositionStyles;
  };

  /**
   * Get the slots with events that span into the hour
   *
   * @param {Array<Object>} events - Array of events for the hour
   * @param {string} hour - The hour for which the events are being assigned positions
   * @param {Array<Object>} eventsWithPositionStyles - Array of events with top and width positions assigned
   *
   * @returns {Array<Object, number>} - Array of slots with events that span into the hour
   */
  const getDefaultSlotsWithEventsSpanningIntoTheHour = (events, hour, eventsWithPositionStyles) => {
    const slotsWithEvents = [];

    events.forEach((event) => {
      if (event.spansIntoHour) {
        const eventDurationSpanningIntoHour = getEventDurationInThisHourSlot(event, hour);

        const originalEventWithStyle = getOriginalEventWithStyle(event, eventsWithPositionStyles);

        slotsWithEvents.push({
          slotCovered: `${0}-${eventDurationSpanningIntoHour}`,
          events: [
            {
              ...event,
              slotIndex: originalEventWithStyle.slotIndex,
              styles: {
                ...originalEventWithStyle.styles,
                height: `${getEventHeightBasedOnDuration(event, hour)}px`,
              },
            },
          ],
        });
      }
    });

    return slotsWithEvents;
  };

  /**
   * Assign the top and width positions for each event in the hour based on the duration of the event
   * allowing for events that overlap each other to be displayed side by side on the calendar
   *
   * @param {Array<Object>} events - Array of events for the hour
   * @param {string} hour - The hour for which the events are being assigned positions
   * @returns {Array<Object>} - Array of events with top and width positions assigned
   */
  const getEventsWithPositionStylesForHour = (events, hour, eventsWithPositionStyles) => {
    let previousEventsWithPositionStyles = eventsWithPositionStyles;

    const eventsSortedByDuration = events
      .filter((event) => !event.is_all_day)
      .sort((a, b) => {
        return b.duration - a.duration;
      });

    // A slot is defined as a vertical space on the calendar that can be occupied by
    // a number of events stacked on top of each other.
    // In the format Array<{ slotCovered: string, events: Array<Object> }>
    // Example: [{ slotCovered: '0-45', events: [event1, event2] }, { slotCovered: '30-60', events: [event3] }]
    const slotsWithEvents = getDefaultSlotsWithEventsSpanningIntoTheHour(
      events,
      hour,
      previousEventsWithPositionStyles
    );

    eventsSortedByDuration.forEach((event) => {
      if (event.spansIntoHour) return;

      const eventStartMinute = DateTime.fromISO(event.scheduled_at).get('minute');
      const eventEndMinute = eventStartMinute + getEventDuration(event);

      if (slotsWithEvents.length === 0) {
        slotsWithEvents.push({
          slotCovered: `${eventStartMinute}-${eventEndMinute}`,
          events: [event],
        });

        return;
      }

      const lastSlot = slotsWithEvents[slotsWithEvents.length - 1];
      const [lastSlotStartMinute, lastSlotEndMinute] = lastSlot.slotCovered.split('-');

      // Check whether the event can be added to the last slot
      // If the is not within the coverage of the last slot we can add it to the last slot
      if (
        (eventStartMinute >= lastSlotStartMinute && eventStartMinute < lastSlotEndMinute) ||
        (eventEndMinute > lastSlotStartMinute && eventEndMinute <= lastSlotEndMinute)
      ) {
        slotsWithEvents.push({
          slotCovered: `${eventStartMinute}-${eventEndMinute}`,
          events: [event],
        });
      } else {
        const newLastSlotStartMinute = lastSlotStartMinute < eventStartMinute ? lastSlotStartMinute : eventStartMinute;
        const newLastSlotEndMinute = lastSlotEndMinute > eventEndMinute ? lastSlotEndMinute : eventEndMinute;

        slotsWithEvents[slotsWithEvents.length - 1] = {
          slotCovered: `${newLastSlotStartMinute}-${newLastSlotEndMinute}`,
          events: [...lastSlot.events, event],
        };
      }
    });

    const eventsWithStyles = [];

    // Assign the top and width positions for each event in the hour based on the slots
    for (let slotIndex = 0; slotIndex < slotsWithEvents.length; slotIndex += 1) {
      const widthPercentage = 100 / slotsWithEvents.length;
      const previousSlotLeft =
        slotIndex !== 0 ? parseFloat(eventsWithStyles[eventsWithStyles.length - 1].styles.left.replace('%', '')) : 0;
      const previousSlotWidth =
        slotIndex !== 0 ? parseFloat(eventsWithStyles[eventsWithStyles.length - 1].styles.width.replace('%', '')) : 0;
      const leftPercentage = previousSlotLeft + previousSlotWidth;

      const slot = slotsWithEvents[slotIndex];
      const sortedSlotEvents = slot.events.sort((a, b) => {
        const aStartMinute = DateTime.fromISO(a.scheduled_at).get('minute');
        const bStartMinute = DateTime.fromISO(b.scheduled_at).get('minute');

        return aStartMinute - bStartMinute;
      });

      // eslint-disable-next-line no-loop-func
      const sortedSlotEventsWithStyles = sortedSlotEvents.map((event) => {
        // If the event already has styles, we want to update the width and left styles
        if (event.styles) {
          if (parseFloat(event.styles.width.replace('%', '')) > widthPercentage) {
            previousEventsWithPositionStyles = updateOriginalEventStyles(event, previousEventsWithPositionStyles, {
              width: `${widthPercentage}%`,
              left: `${leftPercentage}%`,
            });
          }
          return {
            ...event,
            styles: {
              ...event.styles,
              width: `${widthPercentage}%`,
              left: `${leftPercentage}%`,
            },
          };
        }

        const styles = {
          position: 'absolute',
          left: `${leftPercentage}%`,
          width: `${widthPercentage}%`,
          height: `${getEventHeightBasedOnDuration(event, hour)}px`,
        };

        const eventStartMinute = DateTime.fromISO(event.scheduled_at).get('minute');
        const topPercentage = (eventStartMinute / 60) * 100;

        styles.top = `${topPercentage}%`;

        return {
          ...event,
          forHour: hour,
          slotIndex,
          styles,
        };
      });

      eventsWithStyles.push(...sortedSlotEventsWithStyles);
    }

    return [...previousEventsWithPositionStyles, eventsWithStyles];
  };

  const getEventsWithPositionStyles = (events) => {
    let eventsWithPositionStyles = [];

    hours.forEach((hour) => {
      const eventsForHour = getEventsForHour(events, hour);

      if (hour === -1) {
        eventsWithPositionStyles.push(...eventsForHour.map((event) => ({ ...event, forHour: hour })));
        return;
      }

      const eventsWithPositionStylesForHour = getEventsWithPositionStylesForHour(
        eventsForHour,
        hour,
        eventsWithPositionStyles
      );

      eventsWithPositionStyles = eventsWithPositionStylesForHour.flat();
    });

    return eventsWithPositionStyles;
  };

  const getEventsMarkedForTheHour = (eventsWithMetadata, hour) => {
    return eventsWithMetadata.filter((events) => {
      return events.forHour === hour;
    });
  };

  return hours.map((hour) => {
    return (
      <tr
        onClick={() => navigateToCreateDailyItem(date, hour)}
        onKeyDown={() => navigateToCreateDailyItem(date, hour)}
        className={`${styles.calendarTableRow} ${styles.rowForHour}`}
        key={hour}
      >
        <CalendarHourItem
          key={hour}
          hour={hour}
          date={date}
          events={getEventsMarkedForTheHour(eventsWithPositionStyles, hour)}
        />
      </tr>
    );
  });
}
