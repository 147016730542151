import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Select from '@pray/shared/components/ui/Select/Select';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useForm from '@pray/shared/hooks/useForm';
import logger from '@pray/shared/utils/logger';

import FooterPortal from 'components/layouts/FooterPortal';
import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import ArtistDropdown from 'components/pages/StudioPage/components/ArtistDropdown/ArtistDropdown';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useTestIntegration from 'hooks/integrations/useTestIntegration';
import useLeadClientIntegrations from 'hooks/leadCampaigns/useLeadClientIntegrations';
import useLeadClient from 'hooks/leadClients/useLeadClient';
import useSaveLeadClient from 'hooks/leadClients/useSaveLeadClient';
import useAppRoutes from 'hooks/useAppRoutes';

import churchIcon from 'images/icons/church.svg';

import styles from './OrganizationForm.module.scss';

const LEAD_INTEGRATION_TYPE_STUDIO_AUTOMATION = 'STUDIO_AUTOMATION';

export default function OrganizationForm() {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const navigate = useNavigate();

  const { id: leadClientId } = useParams();
  const { data: leadClient } = useLeadClient(leadClientId);
  const { data: clientIntegrations } = useLeadClientIntegrations();
  const { isLoading, saveLeadClient } = useSaveLeadClient();
  const { selectedArtist } = useStudioContext();

  const form = useForm({
    id: leadClient?.id || '',
    name: leadClient?.name || '',
    image_url: leadClient?.image_url || '',
    lead_integration_type: leadClient?.lead_integration_type || '',
    ...leadClient?.lead_integration_details,
    artist_id: leadClient?.artist?.id || selectedArtist?.id || '',
    created_at: leadClient?.created_at || '',
    updated_at: leadClient?.updated_at || '',
    artist_raw: leadClient?.artist || selectedArtist || null,
  });

  const integrations = clientIntegrations || [];
  const selectedIntegrationType = form.values.lead_integration_type;

  const selectedIntegration = useMemo(() => {
    if (!selectedIntegrationType || !integrations.length) return null;

    return integrations.find((integration) => integration.type === selectedIntegrationType);
  }, [integrations, selectedIntegrationType]);

  const handleSaveOrganization = async () => {
    try {
      const { id } = await saveLeadClient({ form, selectedIntegration });
      navigate(appRoutes.ministryMatchOrganizationDetails(id), { replace: true });

      toast.show({ success: 'Organization saved successfully!' });

      form.setErrors({});
      form.setChanged(false);
    } catch (err) {
      logger.error('Failed to save organization', err);
      const error = err.response?.data?.data?.[0]?.messages?.public;
      if (error) toast.show({ error });
    }
  };

  const renderActionButtons = () => {
    return (
      <div className="flex flex-1 space-x-3">
        <Button
          variant={buttons.variant.secondary}
          onClick={() => navigate(appRoutes.ministryMatchOrganizations(), { replace: true })}
        >
          Cancel
        </Button>

        <ActionButton
          isLoading={isLoading}
          disabled={isLoading || !form.changed}
          text={isLoading ? 'Saving' : 'Save'}
          onClick={handleSaveOrganization}
        />
      </div>
    );
  };

  const renderTopRightButtons = () => (
    <div>
      <div className="hidden md:block">{renderActionButtons()}</div>
    </div>
  );

  const isEditMode = !!leadClientId;
  const title = isEditMode ? 'Organization Details' : 'New Organization';
  const viewName = isEditMode
    ? VIEW_EVENT_NAMES.MINISTRY_MATCH.ORGANIZATIONS_EDIT
    : VIEW_EVENT_NAMES.MINISTRY_MATCH.ORGANIZATIONS_CREATION;

  return (
    <TabPage title={title} viewName={viewName} topRightButtons={renderTopRightButtons()}>
      <div className="mt-4 flex flex-col gap-16 md:flex-row">
        <div className="flex flex-col gap-9">
          <OrganizationDetails form={form} />
          <IntegrationSetup form={form} integrations={integrations} selectedIntegration={selectedIntegration} />
        </div>
        <div>{isEditMode && <OrganizationMetadata form={form} />}</div>
      </div>
      <FooterPortal>{renderActionButtons()}</FooterPortal>
    </TabPage>
  );
}

const OrganizationMetadata = ({ form }) => {
  const modifiedDate = form.values.updated_at || form.values.created_at;

  return (
    <div className="mt-2 flex flex-col">
      <div className="flex size-[208px] items-center justify-center overflow-hidden rounded-md bg-[#f2f2f2]">
        <img
          alt=""
          src={form.values.image_url || churchIcon}
          className={!form.values.image_url ? 'size-10' : ''}
          onError={(event) => {
            event.target.src = churchIcon;
          }}
        />
      </div>
      {modifiedDate && (
        <div className="mt-3">
          <Text className="block text-sm font-bold">Modified</Text>
          <Text className="text-sm">{DateTime.fromISO(modifiedDate).toLocaleString(DateTime.DATETIME_MED)}</Text>
        </div>
      )}
    </div>
  );
};

const OrganizationDetails = ({ form }) => {
  const { artists } = useStudioContext();
  const selectedArtist = artists?.length && form?.values?.artist_id ? form.values.artist_raw : null;

  const handleOrgArtistChange = async (artist) => {
    form.setValue('artist_id', artist.id);
    form.setValue('artist_raw', artist);
  };

  return (
    <Section className="max-w-4xl">
      <Text variant="h2">Details</Text>

      <div className={styles.formField}>
        <TextInput
          label="Organization Name (required)"
          name="name"
          value={form.values.name}
          errorMessage={form.errors.name}
          onChange={form.handleChange}
        />
      </div>

      <div className={styles.formField}>
        <ArtistDropdown
          isFormElement
          label="Associated Artist"
          artists={artists}
          selectedArtists={selectedArtist ? [selectedArtist] : []}
          onChange={handleOrgArtistChange}
        />
      </div>

      <div className={styles.formField}>
        <MediaUpload
          imageUrl={form.values.image_url}
          title="Organization Image"
          description="We recommend uploading a square image with a transparent background. We support PNG and JPG formats."
          uploadButtonLabel={form.values.image_url ? 'Change Image' : 'Upload Image'}
          error={form.errors.image_url}
          cropProps={{ aspect: 1 }}
          onValidateImage={(img) => img.height === img.width}
          onFileChange={(file) => form.setValue('image_file', file)}
          onError={(error) => form.setError('image_url', error)}
        />
      </div>
    </Section>
  );
};

const IntegrationSetup = ({ form, integrations, selectedIntegration }) => {
  const toast = useToastMessage();
  const isEditMode = !!form.values.id;
  const [testResponse, setTestResponse] = useState(null);
  const { testIntegration, isLoading } = useTestIntegration();

  const getIntegrationLogo = (integration) => {
    const int = integration || selectedIntegration;
    return int ? <img src={int.image_url} alt="" className="size-9 translate-x-[-4px] rounded" /> : null;
  };

  const handleTestIntegration = async () => {
    try {
      if (!selectedIntegration.fields) {
        throw new Error('No fields found for selected integration');
      }

      /**
       * Extract integration details from form values, based on selected integration fields
       */
      const integrationDetails = selectedIntegration.fields.reduce((acc, field) => {
        acc[field.property_name] = form.values[field.property_name];
        return acc;
      }, {});

      /**
       * Test integration with the provided details
       */
      const response = await testIntegration({
        artistId: form.values.artist_id,
        type: selectedIntegration.type,
        integrationDetails,
      });

      /**
       * Prepare response data to display in UI
       * @type {{isError: boolean, dummyData, name, message}}
       */
      const testResponse = {
        isError: response.result.statusCode !== 200,
        name: response.result.name,
        message: response.result.message,
        dummyData: response.dummyData,
      };

      setTestResponse(testResponse);
    } catch (err) {
      logger.error('Failed to test integration', err);
      toast.show({ error: 'Failed to test integration' });
    }
  };

  const getTestResponseComponent = () => {
    if (!testResponse) return null;

    if (testResponse.isError) {
      return (
        <div className="rounded-md bg-red-200 p-3 text-red-800">
          <span className="block font-bold">Setup Error</span>
          <span className="block">{testResponse.name}</span>
          <span className="mt-2 block text-xs">{testResponse.message}</span>
        </div>
      );
    }

    return (
      <div className="rounded-md bg-green-200 p-3 text-green-800">
        <span className="block font-bold">Your integration is setup correctly</span>
        <span className="block text-xs">{JSON.stringify(testResponse.dummyData, null, 2)}</span>
      </div>
    );
  };

  if (isEditMode && !selectedIntegration) {
    return (
      <Section className="max-w-4xl">
        <Text variant="h2">Custom Integration</Text>
        <Text className="block">Contact admin to update this custom integration</Text>
      </Section>
    );
  }

  return (
    <Section className="max-w-4xl">
      <Text variant="h2">Integration Setup</Text>

      <div className={`${styles.formField} space-y-4`}>
        <div className={form.values.lead_integration_type ? styles.selected : ''}>
          <Select
            label="Integration"
            name="lead_integration_type"
            value={form.values.lead_integration_type}
            error={form.errors.lead_integration_type}
            items={integrations}
            getItemLabel={(integration) => integration?.label}
            getItemValue={(integration) => integration?.type}
            getLeftIcon={getIntegrationLogo}
            onChange={form.handleChange}
          />
        </div>

        {selectedIntegration?.fields.map((field) => (
          <div className={styles.formField} key={field.property_name}>
            <TextInput
              label={`${field.label} ${field.is_required ? '(required)' : ''}`}
              name={field.property_name}
              value={form.values[field.property_name]}
              errorMessage={form.errors[field.property_name]}
              onChange={form.handleChange}
            />
          </div>
        ))}

        {selectedIntegration && selectedIntegration?.type !== LEAD_INTEGRATION_TYPE_STUDIO_AUTOMATION && (
          <Button variant={buttons.variant.secondary} onClick={handleTestIntegration} disabled={isLoading}>
            {isLoading ? 'Sending data...' : 'Test Integration'}
          </Button>
        )}

        {testResponse && getTestResponseComponent()}
      </div>
    </Section>
  );
};
