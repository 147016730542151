import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@pray/shared/components/ui/Button/Button';
import { Table, Thead, Tbody, Tr, Th, TdLink } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';
import { formatDuration } from '@pray/shared/utils';

import ContentDateStatus from 'components/pages/StudioPage/components/ContentDateStatus/ContentDateStatus';
import ContentTranslations from 'components/pages/StudioPage/components/ContentTranslations/ContentTranslations';
import ContentVisibility from 'components/pages/StudioPage/components/ContentVisibility/ContentVisibility';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage/StudioToastMessage';
import useAppRoutes from 'hooks/useAppRoutes';

import audioIcon from '../../../../assets/audio-icon.png';
import ContentItem from '../../../../components/ContentItem/ContentItem';
import visibilityIcon from '../../../PlaylistsTab/assets/chevron-bottom.svg';
import ContentVisibilityModal from '../../../PlaylistsTab/PlaylistItems/ContentVisibilityModal/ContentVisibilityModal';

import styles from './ContentTable.module.scss';

export default function ContentTable({
  data,
  mediaType,
  sortBy,
  onSelectionChange,
  onSortChange,
  onItemClick,
  onAddToPlaylist,
  onContentUpdated,
}) {
  const appRoutes = useAppRoutes();
  const location = useLocation();
  const [content, setContent] = useState(null);
  const [contentVisibilityAnchorEl, setContentVisibilityAnchorEl] = useState(null);
  const toast = useToastMessage();
  const navigate = useNavigate();

  const getContentDescription = (content) => {
    if (!content.description) {
      return (
        <Button
          to={appRoutes.artistContentDetails(content.id)}
          className={`p-0 font-normal normal-case ${styles.addDescription}`}
        >
          Add description
        </Button>
      );
    }
    return content.description;
  };

  const handleVisibilityItemClick = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    setContent(content);
    setContentVisibilityAnchorEl(event.currentTarget);
  };

  const handleSeriesClick = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    navigate(appRoutes.artistPlaylistDetails(content.series_id), { replace: true, state: { from: location } });
  };

  const handleOnAddToPlaylist = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    onAddToPlaylist(content);
  };

  const handleItemClick = (event, content) => {
    event.stopPropagation();
    event.preventDefault();
    onItemClick(content);
  };

  return (
    <>
      <Table
        key={data.map((item) => item.id).join('-')}
        selectable
        sortable={false}
        sortBy={sortBy}
        rowCount={data.length}
        rowActions={null}
        onSortChange={onSortChange}
        onSelectionChange={onSelectionChange}
      >
        <Thead>
          <Tr>
            <Th field="title">Episode</Th>
            <Th field="duration">Duration</Th>
            <Th field="visiblity">Visibility</Th>
            <Th field="date">Date</Th>
            <Th field="series">Series</Th>
            <Th field="translations">Translations</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((content) => (
            <Tr key={content.id} className="group cursor-pointer">
              <TdLink width="25%" className="max-w-md p-1" to={appRoutes.artistContentDetails(content.id)}>
                {mediaType === 'audio' && (
                  <ContentItem
                    image={content.primary_image_url}
                    fallbackImage={audioIcon}
                    title={content.title}
                    description={getContentDescription(content)}
                    onItemClick={(event) => handleItemClick(event, content)}
                  />
                )}
                {mediaType === 'video' && (
                  <ContentItem
                    isVideo
                    image={content.thumbnail_image_url}
                    title={content.title}
                    description={getContentDescription(content)}
                    onItemClick={(event) => handleItemClick(event, content)}
                  />
                )}
              </TdLink>
              <TdLink width="7%" to={appRoutes.artistContentDetails(content.id)}>
                <Text className="p-1.5 text-base font-medium tracking-normal">
                  {formatDuration(content.primary_audio_file_duration || content.primary_video_file_duration)}
                </Text>
              </TdLink>
              <TdLink width="14%" className="cursor-pointer" to={appRoutes.artistContentDetails(content.id)}>
                <Button className="px-0" onClick={(event) => handleVisibilityItemClick(event, content)}>
                  <div className="mr-2 flex min-w-24 items-center gap-2">
                    <ContentVisibility content={content} />
                    <img alt="visibility" src={visibilityIcon} className="invisible size-3 group-hover:visible" />
                  </div>
                </Button>
              </TdLink>
              <TdLink width="13%" to={appRoutes.artistContentDetails(content.id)}>
                <ContentDateStatus content={content} />
              </TdLink>
              <TdLink width="21%" to={appRoutes.artistContentDetails(content.id)}>
                {content.series_title ? (
                  <Text
                    className="p-1.5 text-base font-medium tracking-normal hover:underline"
                    onClick={(event) => handleSeriesClick(event, content)}
                  >
                    {content.series_title}
                  </Text>
                ) : (
                  <Text
                    className="p-1.5 text-base font-bold tracking-normal text-[#B68B38] hover:underline"
                    onClick={(event) => handleOnAddToPlaylist(event, content)}
                  >
                    Add to a Series
                  </Text>
                )}
              </TdLink>
              <TdLink width="20%" to={appRoutes.artistContentDetails(content.id)}>
                <ContentTranslations translations={content.translations} />
              </TdLink>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {!!contentVisibilityAnchorEl && (
        <ContentVisibilityModal
          data={content}
          anchorEl={contentVisibilityAnchorEl}
          onClose={() => {
            setContentVisibilityAnchorEl(null);
            setContent(null);
          }}
          onSuccess={async () => {
            await onContentUpdated();
            setContentVisibilityAnchorEl(null);
            setContent(null);
          }}
          onError={(error) => {
            toast.show({ error });
          }}
        />
      )}
    </>
  );
}
