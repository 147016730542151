import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

import Loading from '@pray/shared/components/Loading/Loading';

import { ChartLegend } from './components/ChartLegend';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const family = 'Satoshi';
const borderColor = '#FFFFFF';
const hoverBorderColor = '#94733325';

const colorPalette = [
  '#FFF0D6',
  '#F7E5C5',
  '#EFDAB5',
  '#E7D0A4',
  '#DFC593',
  '#D7BA83',
  '#CFAF72',
  '#C7A561',
  '#BF9A51',
  '#B78F40',
];

const getLabelColors = (count) => {
  if (count <= 0) return [];
  if (count >= colorPalette.length) return colorPalette;

  const step = colorPalette.length / count;
  const selectedColors = [];

  for (let i = 0; i < count; i++) {
    const colorIndex = Math.floor(i * step);
    selectedColors.push(colorPalette[colorIndex]);
  }

  return selectedColors;
};

export const DonutChart = ({ isLoading = false, data = [] }) => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  if (isLoading) return <LoadingState />;

  const colors = getLabelColors(data.length);
  const chartData = {
    labels: data.map(({ label }) => label),
    datasets: [
      {
        data: data.map(({ value }) => value),
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  return (
    <div className="flex items-center">
      <div className="size-72 py-3" onMouseLeave={() => setActiveTooltip(null)}>
        <Chart data={chartData} onHover={setActiveTooltip} />
      </div>
      <div>
        {data.map((item, index) => {
          const grayscale = isLoading ? 'grayscale' : '';
          const hover = !activeTooltip || activeTooltip === item.label ? 'opacity-100' : 'opacity-20';
          return (
            <ChartLegend
              key={item.label}
              isLoading={isLoading}
              label={item.label}
              color={colors[index]}
              className={`${hover} ${grayscale}`}
            />
          );
        })}
      </div>
    </div>
  );
};

const Chart = ({ data, onHover }) => (
  <Doughnut
    data={data}
    options={{
      onHover(event, _, chart) {
        const [tooltip] = chart.tooltip?.title || [];
        onHover(tooltip);
      },
      datasets: {
        doughnut: {
          spacing: 1,
          borderWidth: 4,
          borderRadius: 6,
          borderColor,
          hoverBorderColor,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              return `Listens: ${context.formattedValue}%`;
            },
          },
          padding: 24,
          backgroundColor: '#000000',
          titleFont: {
            family,
            size: 17,
            weight: 700,
          },
          displayColors: false,
          bodyColor: '#C6CCD8',
          bodyFont: {
            family,
            size: 17,
            weight: 700,
          },
        },
        datalabels: {
          formatter: (value) => {
            return `${value}%`;
          },
          color: '#1C1C1E',
          font: {
            family,
            weight: 500,
          },
        },
        legend: {
          display: false,
        },
      },
    }}
  />
);

const LoadingState = () => (
  <div className="flex gap-8 py-8">
    {/* Donut chart */}
    <div className="relative w-fit">
      <div className="absolute left-1/2 top-1/2 size-44 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white" />
      <Loading isLight {...{ margin: '36px 0', height: '330px', width: '330px', borderRadius: '100%' }} />
    </div>

    {/* Legend labels */}
    <div className="flex flex-1 flex-col justify-center gap-2">
      <LoadingLegend />
      <LoadingLegend />
      <LoadingLegend />
    </div>
  </div>
);

const LoadingLegend = () => (
  <div className="flex items-center gap-3">
    <div className="size-3 rounded-full bg-gray-300" />
    <Loading isLight {...{ margin: '8px 0', height: '11px', width: '75px' }} />
  </div>
);
