import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export default function SampleNote() {
  const requirements = [
    'Audio files, up to 10MB each',
    'Up to 10 minutes of Audio',
    'Only 1 speaker and minimum long pauses',
    'High-quality sound with minimum background noise',
  ];

  return (
    <div className="flex flex-col justify-center gap-4 rounded-2xl bg-blue-50 p-4" role="note">
      <div className="flex flex-col">
        {requirements.map((requirement) => (
          <Text key={requirement} className="!font-medium" variant={typography.body_md} color={colors.text_tertiary}>
            • {requirement}
          </Text>
        ))}
      </div>
      <Text variant={typography.body_md} className="!font-medium !text-[#184087]">
        <strong>Pro Tip: </strong>
        Create separate voices for different tones and emotions, such as formal, calm, or excited.
      </Text>
    </div>
  );
}
