import { ArrowLeft } from 'lucide-react';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { useContentPlayer } from '@pray/shared/components/ContentPlayer';
import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';
import { LOCALES } from '@pray/shared/constants';
import { navigate } from '@pray/shared/utils/navigation';

import { Spinner } from '@/images/icons';
import { AUDIO_PLAYER } from 'constants.js';

import { TranscriptPanel } from './TranscriptPanel';
import { useEditTranscriptPage } from './useEditTranscriptPage';
import { VoiceSettingsPanel } from './VoiceSettingsPanel';

export default function EditTranscriptPage({ content }) {
  const contentPlayer = useContentPlayer();

  const {
    form,
    contentId,
    voices,
    isLoading,
    isUpdating,
    isUpdateDialogOpen,
    setIsUpdateDialogOpen,
    onTranscriptFormSubmit,
    onUpdateTranscript,
  } = useEditTranscriptPage();

  useEffect(() => {
    if (contentId) {
      contentPlayer.playContent(contentId, {
        autoPlay: false,
        locale: LOCALES.DEFAULT,
        controlOptions: { ...AUDIO_PLAYER.controlOptions, isShowClose: false },
      });
    }

    return () => {
      contentPlayer.closePlayer();
    };
  }, [contentId]);

  if (isLoading) {
    return (
      <div className="fixed right-0 top-0 z-[99] size-full bg-white">
        <div className="flex h-full flex-col items-center justify-center gap-3">
          <Spinner className="size-10 animate-spin" />
          <Text variant={typography.heading_md}>Loading...</Text>
        </div>
      </div>
    );
  }

  return (
    <FormProvider {...form}>
      <form
        className="fixed right-0 top-0 z-[100] size-full bg-white"
        onSubmit={form.handleSubmit(onTranscriptFormSubmit)}
      >
        <TopNav title={content?.title} />
        <div className="flex h-[calc(100%-164px)]">
          <VoiceSettingsPanel voices={voices} />
          <TranscriptPanel voices={voices} />
        </div>
      </form>
      {isUpdateDialogOpen && (
        <UpdateTranscriptDialog
          isLoading={isUpdating}
          onUpdate={onUpdateTranscript}
          onCancel={() => setIsUpdateDialogOpen(false)}
        />
      )}
    </FormProvider>
  );
}

function TopNav({ title }) {
  return (
    <div className="flex h-[72px] items-center justify-between border-b border-[#DEDFE3] px-4">
      <div>
        <Button
          variant={buttons.variant.secondary}
          className="!border-[#D5D5D5] p-2 shadow"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft />
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Text variant={typography.heading_md}>Edit Transcript</Text>
        <Text variant={typography.body_sm} className="text-[#00000099]">
          {title}
        </Text>
      </div>
      <div>
        <Button type="submit" variant={buttons.variant.primary}>
          Update
        </Button>
      </div>
    </div>
  );
}

function UpdateTranscriptDialog({ isLoading, onUpdate, onCancel }) {
  return (
    <Dialog>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_2xl}>Update Transcript</Text>
        <Text color={colors.text_secondary}>
          Do you want to update your edits? Updating your transcript will start the translation process.
        </Text>
        <div className="flex flex-row justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onCancel}>
            Cancel
          </Button>
          <Button variant={buttons.variant.primary} disabled={isLoading} loading={isLoading} onClick={onUpdate}>
            Update
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
