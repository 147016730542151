import React, { useState, useEffect } from 'react';

import { colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import audioAnalysis from './assets/audio-analysis.svg';
import multiSpeakers from './assets/multi-speakers.svg';
import translation from './assets/translation.svg';

import styles from './TranslateBanner.module.scss';

/**
 * A banner component for displaying translation-related information and actions
 * @param {Object} props
 * @param {string} [props.title=''] - The title text to display in the banner
 * @param {string} [props.description=''] - The description text to display in the banner
 * @param {string} [props.buttonLabel='Translate'] - The text to display on the action button
 * @param {boolean} [props.isProcessing=false] - Whether the translation is currently processing
 * @param {'translation'|'multi-speakers'|'audio-analysis'} [props.variant='translation'] - The variant of banner to display
 * @param {Function} [props.onTranslateButtonClick=null] - Callback function when translate button is clicked
 */
export default function TranslateBanner({
  title = '',
  description = '',
  buttonLabel = 'Translate',
  variant = 'translation',
  isProcessing = false,
  onTranslateButtonClick = null,
}) {
  const [isLoading, setIsLoading] = useState(isProcessing);

  useEffect(() => {
    setIsLoading(isProcessing);
  }, [isProcessing]);

  const handleTranslate = async () => {
    setIsLoading(true);
    onTranslateButtonClick?.();
  };

  return (
    <div className={styles.container}>
      <div className="flex gap-8">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-2">
            <Text className={styles.title}>{title}</Text>
            <Text className={styles.description} color={colors.text_secondary}>
              {description}
            </Text>
          </div>
          <div>
            <Button
              variant="primary"
              color="text_secondary"
              disabled={isLoading}
              className="flex flex-row items-center gap-2 disabled:!bg-gray-300 disabled:!text-gray-500"
              onClick={handleTranslate}
            >
              {isLoading ? <Spinner size="small" /> : null}
              {buttonLabel}
            </Button>
          </div>
        </div>
        {variant === 'translation' && <img alt="" src={translation} />}
        {variant === 'multi-speakers' && <img alt="" src={multiSpeakers} />}
        {variant === 'audio-analysis' && <img alt="" src={audioAnalysis} />}
      </div>
    </div>
  );
}
