import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import useClickAway from '@pray/shared/hooks/useClickAway';
import { usePreventNativePickers } from '@pray/shared/hooks/usePreventNativePickers';

import Button from '../Button';
import TextInput from '../TextInput';

import styles from './TimePicker.module.scss';

const timeAmPm = {
  AM: 'AM',
  PM: 'PM',
};

export default function TimePicker({ value, onChange, ...props }) {
  const popoverRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const timeValue = useMemo(getTimeValue, [value]);
  const [selectedTime, setSelectedTime] = useState(timeValue);

  function getTimeValue() {
    if (!value) return { hour: '01', minute: '00', ampm: timeAmPm.AM };

    const [hour, minute] = value.split(':');
    const time = DateTime.local().set({ hour, minute }).toFormat('hh:mm a');

    return {
      hour: time.slice(0, 2),
      minute: time.slice(3, 5),
      ampm: time.slice(6, 8),
    };
  }

  function handleTimeChanged() {
    let hour = +selectedTime.hour;
    if (selectedTime.ampm === timeAmPm.PM && hour !== 12) {
      hour += 12;
    } else if (selectedTime.ampm === timeAmPm.AM && hour === 12) {
      hour = 0;
    }

    const timeDate = DateTime.local().set({
      hour,
      minute: +selectedTime.minute,
    });

    const newTimeValue = timeDate.toISOTime().slice(0, 5);

    onChange(newTimeValue);
  }

  function handleInputChange(e) {
    const inputValue = e.target.value;
    if (!inputValue) return;

    const [hours, minutes] = inputValue.split(':');
    const hoursNum = parseInt(hours, 10);
    const minutesNum = parseInt(minutes, 10);

    if (hoursNum < 0 || hoursNum > 23 || minutesNum < 0 || minutesNum > 59) {
      return;
    }

    if (onChange) {
      const newTime = DateTime.local()
        .set({
          hour: hoursNum,
          minute: minutesNum,
        })
        .toFormat('HH:mm');

      onChange(newTime);
    }
  }

  function handleOpenTimePicker() {
    const popover = popoverRef.current;
    const popoverBottom = popover.getBoundingClientRect().bottom;
    const windowHeight = window.innerHeight;

    if (windowHeight - popoverBottom < 250) {
      popover.classList.add(styles.directionUp);
    } else {
      popover.classList.remove(styles.directionUp);
    }

    setIsPopoverOpen(true);
  }

  useEffect(() => {
    if (
      timeValue.hour !== selectedTime.hour ||
      timeValue.minute !== selectedTime.minute ||
      timeValue.ampm !== selectedTime.ampm
    ) {
      setSelectedTime(timeValue);
    }
  }, [timeValue]);

  useEffect(() => {
    if (
      selectedTime.hour &&
      selectedTime.minute &&
      selectedTime.ampm &&
      (selectedTime.hour !== timeValue.hour ||
        selectedTime.minute !== timeValue.minute ||
        selectedTime.ampm !== timeValue.ampm)
    ) {
      handleTimeChanged();
    }
  }, [selectedTime]);

  useClickAway(popoverRef, () => setIsPopoverOpen(false));

  return (
    <div className={styles.timePickerContainer}>
      <TimePickerInput {...props} value={value} onClick={handleOpenTimePicker} onChange={handleInputChange} />

      <div ref={popoverRef}>
        {isPopoverOpen && (
          <div className={styles.timePickerPopover}>
            <Hours value={selectedTime.hour} onChange={(hour) => setSelectedTime({ ...selectedTime, hour })} />
            <Minutes value={selectedTime.minute} onChange={(minute) => setSelectedTime({ ...selectedTime, minute })} />
            <AmPm value={selectedTime.ampm} onChange={(ampm) => setSelectedTime({ ...selectedTime, ampm })} />
          </div>
        )}
      </div>
    </div>
  );
}

function Hours({ value, onChange }) {
  return (
    <div className={styles.pickerList}>
      {Array.from({ length: 12 }, (_, i) => i + 1).map((i) => {
        const hour = formatTime(i);
        return (
          <Button key={hour} className={value === hour && styles.selected} onClick={() => onChange(hour)}>
            {hour}
          </Button>
        );
      })}
    </div>
  );
}

function Minutes({ value, onChange }) {
  return (
    <div className={styles.pickerList}>
      {Array.from({ length: 60 }, (_, i) => i).map((i) => {
        const minute = formatTime(i);
        return (
          <Button key={minute} className={value === minute && styles.selected} onClick={() => onChange(minute)}>
            {minute}
          </Button>
        );
      })}
    </div>
  );
}

function AmPm({ value, onChange }) {
  return (
    <div className={styles.pickerList}>
      <Button className={value === timeAmPm.AM && styles.selected} onClick={() => onChange(timeAmPm.AM)}>
        AM
      </Button>
      <Button className={value === timeAmPm.PM && styles.selected} onClick={() => onChange(timeAmPm.PM)}>
        PM
      </Button>
    </div>
  );
}

function TimePickerInput(props) {
  const classes = [styles.timePickerInputContainer, props.disabled && styles.timePickerInputDisabled];

  usePreventNativePickers();

  return (
    <div className={classes.join(' ')}>
      <ClockIcon className={styles.timePickerIcon} />
      <TextInput {...props} type="time" style={{ backgroundColor: 'transparent' }} />
    </div>
  );
}

function ClockIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.75V12L14.75 14.75M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z"
        stroke="#1F2024"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function formatTime(value) {
  return value < 10 ? `0${value}` : `${value}`;
}
