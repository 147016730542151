import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';

import { cn } from 'styles/utils';

export default function Section({ title = '', subtitle = '', className = '', children = null, ...props }) {
  return (
    <section className={cn('flex flex-col gap-4', className)} {...props}>
      {(title || subtitle) && (
        <div className="flex flex-col gap-1">
          {!!title && <Text variant={typography.heading_lg}>{title}</Text>}
          {!!subtitle && (
            <Text variant={typography.body_lg} color="text_secondary">
              {subtitle}
            </Text>
          )}
        </div>
      )}
      {children}
    </section>
  );
}
