import React, { forwardRef } from 'react';

import CheckCircleFilled from '@pray/shared/icons/check-circle-filled';

import { typography } from '../../foundations';
import Text from '../Text/Text';

import styles from './SelectionCard.module.scss';

/**
 * @typedef SelectionCardProps
 * @property {string} title - The title of the selection card.
 * @property {string} text - The text content of the selection card.
 * @property {boolean} [disabled] - Whether the selection card is disabled.
 * @property {string} [className] - Custom styles for the selection card.
 */

/**
 * A reusable selection card component.
 *
 * @component
 * @type React.FC<React.InputHTMLAttributes & SelectionCardProps>
 * @returns {JSX.Element} The rendered selection card component.
 */
const SelectionCard = forwardRef(({ title, text, className = '', disabled = false, ...props }, ref) => {
  // default classes
  const classes = [styles.selectionCard, disabled && styles.disabled, className];

  // final classes
  const classNames = classes.filter((item) => item).join(' ');

  return (
    <label className={classNames}>
      <input ref={ref} type="checkbox" className={styles.checkbox} {...props} disabled={disabled} />

      <div className={styles.body}>
        <Text variant={typography.body_lg} className={styles.title}>
          {title}
        </Text>
        <Text variant={typography.body_lg} className={styles.text}>
          {text}
        </Text>
        <CheckCircleFilled className={styles.icon} />
      </div>
    </label>
  );
});

export default SelectionCard;
