export function Voices({ stroke = '#E3AF4A' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame">
        <path
          id="Vector"
          d="M8.8 19.9998V15.8998L10.7 16.0998C11.2531 16.0691 11.7766 15.8398 12.1742 15.4539C12.5717 15.0681 12.8167 14.5518 12.864 13.9998V8.29983C12.8706 6.85918 12.3047 5.47489 11.2907 4.4515C10.2767 3.42812 8.89766 2.84946 7.457 2.84283C6.01634 2.8362 4.63206 3.40214 3.60867 4.41615C2.58529 5.43016 2.00663 6.80918 2 8.24983C2 11.0498 2.656 11.3038 3 12.7998C3.23248 13.7033 3.24243 14.6497 3.029 15.5578L2 19.9998"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M19.7998 17.7998C21.2056 16.3938 21.9956 14.4871 21.9962 12.4989C21.9968 10.5107 21.2078 8.60355 19.8028 7.19678"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M17.0001 14.9999C17.3273 14.6728 17.5863 14.2841 17.7623 13.8562C17.9383 13.4283 18.0278 12.9698 18.0254 12.5072C18.0231 12.0445 17.9291 11.587 17.7488 11.1609C17.5685 10.7348 17.3055 10.3487 16.9751 10.0249"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
