import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { Close } from 'images/icons';

export function SolomonRecentSearch({ value, onSelect, onRemove }) {
  return (
    <div
      key={value}
      role="none"
      className="group flex cursor-pointer items-center justify-between py-2 pl-4 hover:rounded-md hover:bg-gray-50"
      onMouseDown={() => onSelect(value)}
    >
      <Text variant={typography.subhead_small}>{value}</Text>
      <Button
        className="invisible cursor-pointer group-hover:visible"
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onRemove(prompt);
        }}
      >
        <Close width={16} height={16} />
      </Button>
    </div>
  );
}
