import React from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Link from '@pray/shared/components/ui/Link/Link';

import { Hamburger } from 'images/icons';

import useSideBar from '../SideBar/useSideBar';
import UserInfoDropdown from '../UserInfoDropdown/UserInfoDropdown';
import UserNotifications from '../UserNotifications/UserNotifications';

import prayStudioLogo from 'images/pray-studio-logo.svg';

import styles from './AppBar.module.scss';

export default function AppBar() {
  const { toggleCollapsed } = useSideBar();

  return (
    <div className={`theme-dark ${styles.container}`}>
      <Button onClick={toggleCollapsed} className="md:hidden">
        <Hamburger className="text-white" />
      </Button>
      <Link to="/">
        <img src={prayStudioLogo} alt="Pray Studio" />
      </Link>
      <div className="flex items-center gap-1">
        <UserNotifications />
        <UserInfoDropdown />
      </div>
    </div>
  );
}
