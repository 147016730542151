import Text from '@pray/shared/components/ui/Text/Text';

/**
 * @param {Object} props
 * @param {boolean | number} [props.count=false]
 * @returns {React.ReactElement}
 */
export const NotificationsBadgeDot = () => <div className="rounded-full bg-red-600 p-[3px]" />;

/**
 * @param {Object} props
 * @param {undefined | number} props.count
 * @returns {React.ReactElement}
 */
export const NotificationsBadgeWithCount = ({ count }) => {
  if (!count || count === 0) return null;

  const countLabel = count > 99 ? '99+' : count;

  return (
    <div className="flex grow justify-end">
      <span className="flex max-h-[24px] min-w-[24px] max-w-fit items-center justify-center rounded-full bg-red-600 p-[3px]">
        <Text variant="button_medium" className="font-bold text-white">
          {countLabel}
        </Text>
      </span>
    </div>
  );
};
