import React, { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import useForm from '@pray/shared/hooks/useForm';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';
import { isValidUrl } from '@pray/shared/utils/validation';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';

import ActionButton from '../../../components/ActionButton/ActionButton';
import placeholderImage from '../../../components/MediaUpload/assets/placeholder-image.png';
import MediaUpload from '../../../components/MediaUpload/MediaUpload';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './BannerForm.module.scss';

const initialFormData = {
  image_url: '',
  header: '',
  description: '',
  cta_text: '',
  cta_url: '',
};

const BANNER_HEADER_LIMIT = 80;
const BANNER_DESCRIPTION_LIMIT = 80;
const CTA_TEXT_LIMIT = 20;

export default function BannerForm({ data: bannerData, onSaveSuccess = () => null }) {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const [isPublishing, setIsPublishing] = useState(false);

  const getInitialData = () => {
    const data = bannerData?.id ? bannerData : initialFormData;
    return {
      id: data.id || '',
      header: data.header || '',
      image_url: data.image_url || '',
      description: data.description || '',
      cta_text: data.cta_text || '',
      cta_url: data.cta_url || '',
    };
  };

  const form = useForm(getInitialData());

  const validateBanner = () => {
    const errors = {};

    if (!form.values.image_url.trim() && !form.values.image_file) {
      errors.image_url = 'Invalid cover image';
    }

    if (!form.values.header.trim() || form.values.header.length > 80) {
      errors.header = 'Invalid title';
    }

    if (!form.values.description.trim() || form.values.description.length > 80) {
      errors.description = 'Invalid description';
    }

    if (!form.values.cta_text.trim() || form.values.cta_text.length > 80) {
      errors.cta_text = 'Invalid button text';
    }

    if (!form.values.cta_url || !isValidUrl(form.values.cta_url, { scheme: 'https' })) {
      errors.cta_url = 'Invalid button URL';
    }

    form.setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handlePublish = async () => {
    if (!validateBanner()) return;

    const data = {
      promoUnitId: bannerData?.id,
      artistId: selectedArtist.id,
      header: form.values.header,
      imageUrl: form.values.image_url,
      description: form.values.description,
      ctaText: form.values.cta_text,
      ctaUrl: form.values.cta_url,
      isPublished: true,
    };

    try {
      setIsPublishing(true);

      // upload banner image
      if (form.values.image_file) {
        try {
          const { url } = await s3Service.signAndUpload({
            file: form.values.image_file,
            type: 'artistPromoUnit',
            signParams: {
              artist_promo_unit_id: data.promoUnitId || null,
            },
          });
          data.imageUrl = url;
        } catch (err) {
          form.setError('image_url', err);
          return;
        }
      }

      if (!data.promoUnitId) {
        await studioService.promoUnit.addPromoUnit(data);
      } else {
        await studioService.promoUnit.updatePromoUnit(data);
      }

      toast.show({ success: 'Banner was published successfully' });
      await onSaveSuccess();
    } catch (err) {
      logger.debug('Failed to publish banner', err);
    } finally {
      setIsPublishing(false);
    }
  };

  const handleUnpublish = async () => {
    await studioService.promoUnit.updatePromoUnit({
      promoUnitId: bannerData?.id,
      artistId: selectedArtist.id,
      isPublished: false,
    });
    toast.show({ success: 'Banner was unpublished successfully' });
    await onSaveSuccess();
  };

  const renderTopRightButtons = () => {
    let text = isPublishing ? 'Publishing' : 'Publish';
    if (bannerData?.is_published) text = isPublishing ? 'Saving Changes' : 'Save Changes';

    return (
      <div className="flex space-x-4">
        {bannerData?.is_published && (
          <Button variant={buttons.variant.secondary} onClick={handleUnpublish}>
            Unpublish
          </Button>
        )}
        <ActionButton isLoading={isPublishing} text={text} onClick={handlePublish} />
      </div>
    );
  };

  return (
    <div data-viewname="PRAY Studio - Banner Tab">
      <Metadata title="PRAY Studio for Leaders - Content Creators" />

      <TabPage title="Banner" topRightButtons={renderTopRightButtons()}>
        <Section title="Banner Image" className="mt-8 max-w-4xl">
          <div className="mt-4">
            <MediaUpload
              placeholderImage={placeholderImage}
              imageUrl={form.values.image_url}
              title="Banner cover image*"
              description="It’s recommended to use a picture that’s at least 1080 x 1080 pixels and 4MB or less. Use a PNG or JPEG."
              uploadButtonLabel={form.values.image_url ? 'Change Image' : 'Upload Image'}
              maxSizeMB={4}
              error={form.errors.image_url}
              onFileChange={(file) => form.setValue('image_file', file)}
              onError={(error) => form.setError('image_url', error)}
            />
          </div>
          <div className={styles.formField}>
            <Text className={styles.label}>Banner title*</Text>
            <TextInput
              placeholder="Banner title"
              name="header"
              value={form.values.header}
              errorMessage={form.errors.header}
              description={`The text must be ${BANNER_HEADER_LIMIT} characters maximum.`}
              helperText={`${form.values.header.length}/${BANNER_HEADER_LIMIT}`}
              onChange={form.handleChange}
            />
          </div>

          <div className={styles.formField}>
            <Text className={styles.label}>Banner description*</Text>
            <TextArea
              rows={4}
              placeholder="Banner description"
              name="description"
              value={form.values.description}
              errorMessage={form.errors.description}
              description={`The text must be ${BANNER_DESCRIPTION_LIMIT} characters maximum.`}
              helperText={`${form.values.description.length}/${BANNER_DESCRIPTION_LIMIT}`}
              onChange={form.handleChange}
            />
          </div>

          <div className={styles.formField}>
            <Text className={styles.label}>Button text*</Text>
            <TextInput
              placeholder="Button text"
              name="cta_text"
              value={form.values.cta_text}
              errorMessage={form.errors.cta_text}
              description={`The text must be ${CTA_TEXT_LIMIT} characters maximum.`}
              helperText={`${form.values.cta_text.length}/${CTA_TEXT_LIMIT}`}
              onChange={form.handleChange}
            />
          </div>

          <div className={styles.formField}>
            <Text className={styles.label}>Button URL*</Text>
            <TextInput
              placeholder="Button HTTPS URL"
              name="cta_url"
              value={form.values.cta_url}
              errorMessage={form.errors.cta_url}
              description="Note: Button URL is required to be a secure https:// link"
              onChange={form.handleChange}
            />
          </div>
        </Section>
      </TabPage>
    </div>
  );
}
