import api from '@pray/shared/utils/api';

type UploadBookProps = {
  artistId: string;
  fileUrl: string;
};

export const uploadBook = async ({ artistId, fileUrl }: UploadBookProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!fileUrl) throw Error('File URL is required');

  const url = `/web/studio/artists/${artistId}/books/upload`;
  const data = {
    book_file_url: fileUrl,
  };

  await api.executePostRequest(url, data);
};
