import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { AiStars } from 'images/icons';

import { workflowAiTranslationStatus } from './constants';

export default function WorkflowAITranslationStatusBanner({
  translationStatus,
  onButtonClick,
  isButtonDisabled = false,
}) {
  return (
    <>
      {translationStatus === workflowAiTranslationStatus.inProgress && (
        <div className="mb-6 flex w-full flex-col items-center gap-5 rounded-xl border border-[#D2C7F9] bg-purple-100 px-4 py-3 font-medium text-[#2C0665] lg:flex-row">
          <div className="flex items-center gap-4">
            <div className="w-8">
              <Spinner color="purple" />
            </div>
            <Text className="mr-4">
              We are generating a new audio from the updated transcript. During this time the episode preview will not
              be available
            </Text>
          </div>
        </div>
      )}

      {translationStatus === workflowAiTranslationStatus.pending && (
        <div className="mb-6 flex w-full flex-col items-center justify-between gap-5 rounded-xl border border-[#D2C7F9] bg-purple-100 px-4 py-3 font-medium text-[#2C0665] lg:flex-row">
          <div className="flex items-center gap-4">
            <div className="w-8">
              <AiStars className="size-5 text-[#8854F2]" />
            </div>
            <Text className="mr-4">
              A new version of the transcript has been saved. You can generate new audio from the updated transcript
            </Text>
          </div>

          <Button
            variant={buttons.variant.primary}
            className="m-0 !border-transparent !bg-[#7C32E6] px-3 py-2 text-xs"
            onClick={onButtonClick}
            disabled={isButtonDisabled}
          >
            Generate
          </Button>
        </div>
      )}
    </>
  );
}
