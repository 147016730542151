type MediaMetadata = {
  duration?: number;
  thumbnailFile?: File;
};

export const getMediaMetadata = async (file: File): Promise<MediaMetadata> => {
  const metadata: MediaMetadata = {
    duration: 0,
    thumbnailFile: null,
  };

  const setVideoThumbnail = async (video: HTMLVideoElement): Promise<void> => {
    // Try different timestamps (in seconds) until we find a non-black frame
    const timestamps = [5, 7, 9];

    const captureFrame = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      return canvas;
    };

    const isFrameBlack = (canvas: HTMLCanvasElement) => {
      const context = canvas.getContext('2d');
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      // Check if all pixels are black or nearly black
      for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];

        if (r > 10 || g > 10 || b > 10) return false;
      }

      return true;
    };

    return new Promise((resolve) => {
      video.preload = 'metadata';
      video.muted = true;
      video.volume = 0;
      video.playsInline = true;

      let timestampIndex = 0;

      const tryCapture = () => {
        if (timestampIndex >= timestamps.length) {
          // If all timestamps failed, use whatever we get at the first position
          [video.currentTime] = timestamps;
          return;
        }

        video.currentTime = timestamps[timestampIndex];
      };

      video.addEventListener('seeked', () => {
        const canvas = captureFrame();

        if (isFrameBlack(canvas) && timestampIndex < timestamps.length - 1) {
          timestampIndex++;
          tryCapture();
          return;
        }

        const type = 'image/jpeg';

        canvas.toBlob((blob) => {
          metadata.thumbnailFile = new File([blob], `thumbnail_${file.name}.jpg`, { type });
          video.pause();
          resolve();
        }, type);
      });

      tryCapture();

      video.play();
    });
  };

  const setMediaDuration = (media: HTMLVideoElement | HTMLAudioElement): void => {
    metadata.duration = Math.floor(media.duration);
  };

  return new Promise((resolve) => {
    const [element] = file.type.split('/'); // file.type = 'audio/*' | 'video/*'

    if (element !== 'audio' && element !== 'video') {
      // For other media types, we don't return any metadata
      resolve({});
      return;
    }

    const media = document.createElement(element);
    media.src = URL.createObjectURL(file);
    media.addEventListener('loadedmetadata', async () => {
      setMediaDuration(media);
      if (element === 'video') await setVideoThumbnail(media as HTMLVideoElement);
      resolve(metadata);
    });
  });
};

export const dataUrlToFileUsingFetch = async (url: string, fileName: string, mimeType: string): Promise<File> => {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();

  return new File([buffer], fileName, { type: mimeType });
};

export const dataUrlToFile = (url: string, fileName: string): File => {
  const [mediaType, data] = url.split(',');
  const mime = mediaType.match(/:(.*?);/)?.[0];

  let n = data.length;
  const arr = new Uint8Array(n);

  while (n--) {
    arr[n] = data.charCodeAt(n);
  }

  return new File([arr], fileName, { type: mime });
};
