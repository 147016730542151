export function AudioIcon({ color = '#387AF0', className = '' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 7.9433C4.5 6.9768 5.2835 6.19329 6.25 6.19329C7.2165 6.19329 8 6.9768 8 7.94329L8 16.0567C8 17.0232 7.2165 17.8067 6.25 17.8067C5.2835 17.8067 4.5 17.0232 4.5 16.0567L4.5 7.9433Z"
        fill={color}
      />
      <path
        d="M10 6C10 4.89543 10.8954 4 12 4C13.1046 4 14 4.89543 14 6L14 18C14 19.1046 13.1046 20 12 20C10.8954 20 10 19.1046 10 18L10 6Z"
        fill={color}
      />
      <path
        d="M16 7.9433C16 6.9768 16.7835 6.19329 17.75 6.19329C18.7165 6.19329 19.5 6.9768 19.5 7.94329V16.0567C19.5 17.0232 18.7165 17.8067 17.75 17.8067C16.7835 17.8067 16 17.0232 16 16.0567L16 7.9433Z"
        fill={color}
      />
    </svg>
  );
}

export function UnselectedVoiceIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="black" fillOpacity="0.02" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#8C8F96" strokeDasharray="3 3" />
      <path
        d="M9.42235 7.40377L16.4202 11.5661C16.6576 11.7073 16.7355 12.0141 16.5944 12.2515C16.5518 12.323 16.4921 12.3828 16.4206 12.4254L9.42273 16.5962C9.18553 16.7375 8.87862 16.6598 8.73725 16.4226C8.69111 16.3452 8.66675 16.2568 8.66675 16.1666V7.8335C8.66675 7.55735 8.89061 7.3335 9.16675 7.3335C9.25672 7.3335 9.34502 7.35777 9.42235 7.40377Z"
        fill="#A6A8AD"
      />
    </svg>
  );
}

export function SelectedVoiceIcon({ isHovered = false, ...props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="11.5"
        fill={isHovered ? 'black' : 'black'}
        fillOpacity={isHovered ? '1' : '0.02'}
      />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke={isHovered ? 'black' : '#3A3C40'} />
      <path
        d="M9.42223 7.40377L16.4201 11.5661C16.6574 11.7073 16.7354 12.0141 16.5942 12.2515C16.5517 12.323 16.492 12.3828 16.4205 12.4254L9.42261 16.5962C9.1854 16.7375 8.8785 16.6598 8.73713 16.4226C8.69098 16.3452 8.66663 16.2568 8.66663 16.1666V7.8335C8.66663 7.55735 8.89048 7.3335 9.16663 7.3335C9.2566 7.3335 9.3449 7.35777 9.42223 7.40377Z"
        fill={isHovered ? 'white' : '#3A3C40'}
      />
    </svg>
  );
}

export function SpeakerIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86665 13.3333V10.6L7.13331 10.7333C7.50207 10.7128 7.85104 10.5599 8.11609 10.3027C8.38113 10.0455 8.54443 9.70131 8.57598 9.33333V5.53333C8.5804 4.5729 8.20311 3.65004 7.5271 2.96778C6.85109 2.28552 5.93175 1.89975 4.97131 1.89533C4.01088 1.89091 3.08802 2.26821 2.40576 2.94421C1.7235 3.62022 1.33773 4.53956 1.33331 5.5C1.33331 7.36667 1.77065 7.536 1.99998 8.53333C2.15497 9.13565 2.1616 9.76656 2.01931 10.372L1.33331 13.3333"
        stroke="#3A3C40"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2 11.8667C14.1372 10.9294 14.6639 9.65825 14.6643 8.33275C14.6647 7.00726 14.1387 5.73585 13.202 4.798"
        stroke="#3A3C40"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3333 10C11.5514 9.78193 11.7242 9.52278 11.8415 9.23754C11.9588 8.95229 12.0184 8.64662 12.0169 8.33819C12.0153 8.02976 11.9526 7.7247 11.8325 7.44065C11.7123 7.15661 11.5369 6.8992 11.3167 6.68333"
        stroke="#3A3C40"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CheckIcon({ className = '' }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18 7L9.69231 17L6 13.3636"
        stroke="#188149"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
