import React from 'react';

import { cn } from '@pray/shared/utils/styles';

import { colors, typography } from '../../foundations';
import Text from '../Text';
import { HorizontalConnector, VerticalConnector } from './Connectors';
import StepIndicator from './StepIndicator';
import useStepStatus from './useStepStatus';

type StepItemProps = {
  /** The step name/label */
  step: string;
  /** The index of the step (0-based) */
  index: number;
  /** The currently active step */
  activeStep: string;
  /** Array of completed step names */
  completedSteps: string[];
  /** Whether the stepper is vertical */
  isVertical: boolean;
  /** Total number of steps */
  totalSteps: number;
};

/**
 * Individual step item component
 * Renders a single step in the stepper with its indicator and connector
 */
export default function StepItem({ step, index, activeStep, completedSteps, isVertical, totalSteps }: StepItemProps) {
  const { isActive, isCompleted } = useStepStatus(step, activeStep, completedSteps);
  const isNotLastStep = index < totalSteps - 1;

  return (
    <li className={cn('flex gap-3', isVertical ? 'flex-col items-start' : 'flex-row items-center')}>
      <div className="flex items-center gap-3">
        <StepIndicator isCompleted={isCompleted} isActive={isActive} index={index} />
        <Text
          className="!font-medium"
          variant={typography.body_lg}
          color={isActive || isCompleted ? colors.text_primary : colors.text_tertiary}
        >
          {step}
        </Text>
      </div>

      {isNotLastStep &&
        (isVertical ? (
          <VerticalConnector isActive={isActive} isCompleted={isCompleted} />
        ) : (
          <HorizontalConnector isActive={isActive} isCompleted={isCompleted} />
        ))}
    </li>
  );
}
