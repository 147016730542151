// Helper function to calculate monthly retention rate
export function calculateChurnRate() {
  const startRetention = 1.0; // 100%
  const endRetention = 0.3; // 30%
  const decayPeriod = 12; // 12 months

  // Formula: 1 - (end/start)^(1/months)
  return 1 - (endRetention / startRetention) ** (1 / decayPeriod);
}

export function calculateEstimatedLtv(subscriberCount, averageDonationAmount) {
  const churnRate = calculateChurnRate();
  return subscriberCount * (averageDonationAmount / churnRate);
}
