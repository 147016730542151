import api from '@pray/shared/utils/api';

export const getAllTestimonials = async ({
  artistId = '',
  type = '',
  startDate = '',
  endDate = '',
  offset = 0,
  limit = 0,
  sortColumn = 'created_at',
  sortDirection = 'DESC',
} = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!startDate) throw Error('Start Date is required');
  if (!endDate) throw Error('End Date is required');

  const params = {
    start_date: startDate,
    end_date: endDate,
  };

  if (type) params.type = type;
  if (offset) params.offset = offset;
  if (limit) params.limit = limit;
  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  } 

  const url = `/web/studio/artists/${artistId}/testimonials`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
