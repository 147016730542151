import api from '@pray/shared/utils/api';

export async function validateRssFeedUrl(url) {
  if (!url) throw Error('URL is required');

  try {
    const data = {
      url,
    };

    const response = await api.executePostRequest(`/web/studio/validate/rss-feed`, data);

    return response.data.data;
  } catch (err) {
    throw err.response.data.data?.[0]?.info;
  }
}
