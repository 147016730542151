import { DateTime, Duration } from 'luxon';

const MONTH_NAMES = 'January,February,March,April,May,June,July,August,September,October,November,December'.split(',');

export function toDateTime(dateObj) {
  /* TODO: Use this everywhere instead of DateTime.fromISO(), Date(), DateTime.fromJSDate() */
  if (typeof dateObj === 'string') {
    return DateTime.fromISO(dateObj);
  }
  if (dateObj instanceof Date) {
    return DateTime.fromJSDate(dateObj);
  }
  if (DateTime.isDateTime(dateObj)) {
    return dateObj;
  }
  if (typeof dateObj === 'number') {
    return DateTime.fromMillis(dateObj);
  }

  throw new Error('Invalid date object type');
}

export function formatDateUsingFullMonth(dateValue, displayYear = false) {
  if (!dateValue) return null;
  const date = new Date(dateValue);
  const month = MONTH_NAMES[date.getMonth()];
  const year = displayYear ? `, ${date.getFullYear()}` : '';
  return `${month} ${date.getDate()}${year}`;
}

export function formatDateUsingShorterMonth(dateValue, displayYear = false) {
  if (!dateValue) return null;

  return DateTime.fromISO(dateValue)
    .setLocale('en-US')
    .toLocaleString({
      month: 'short',
      day: '2-digit',
      year: displayYear ? 'numeric' : undefined,
    });
}

export function formatDateUsingShorterMonthAndTime(dateObj) {
  if (!dateObj) return null;
  const dateTime = toDateTime(dateObj);
  return dateTime.toFormat('ccc, MMM d yyyy, h:mm a');
}

export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * @summary Returns an ISO date format for start of day, necessary for DatePicker to avoid infinte onChange state loop
 *
 * @param {Object} dateObj JS date object
 * @return {String}
 */
export function formatDateUsingISOForDatePicker(dateObj) {
  if (!dateObj) return null;
  const dateTime = DateTime.fromJSDate(dateObj);
  return `${dateTime.toFormat('yyyy-MM-dd')}T00:00:00.000Z`;
}

/**
 * @summary Formats seconds to a string in the format of "mm:ss"
 * @param {number} seconds - The number of seconds to format
 * @returns {string} The formatted string
 */
export function formatSeconds(seconds) {
  if (seconds === null || seconds === undefined || seconds < 0) return '-';
  return Duration.fromObject({ seconds }).toFormat('mm:ss');
}

export default {
  formatDateUsingFullMonth,
  formatDateUsingShorterMonth,
  getUserTimezone,
  formatDateUsingISOForDatePicker,
};
