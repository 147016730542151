import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import generatingSocialClips from './assets/generating-social-clips.svg';

export function GeneratingSocialClips() {
  return (
    <div className="flex flex-col items-center justify-center gap-6 pt-32">
      <div className="flex flex-col items-center justify-center">
        <img src={generatingSocialClips} alt="Generating Social Clips" />
      </div>
      <div className="flex flex-col items-center justify-center gap-4">
        <Text variant={typography.h2}>Generating Social Clips</Text>
        <Text variant={typography.body_lg} className="text-center">
          This will take a couple of minutes.
          <br />
          You can check back later.
        </Text>
      </div>
    </div>
  );
}
