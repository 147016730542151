import React from 'react';

import CalendarDayItem from './CalendarDayItem';

import styles from './EventsCalendar.module.scss';

export default function CalendarRowItems({ weeks: calendarWeeks, events, navigateToDayViewAtDate }) {
  return calendarWeeks.map(({ week, days }) => {
    return (
      <tr className={styles.calendarTableRow} key={week}>
        {days.map(({ date, type, month }) => (
          <CalendarDayItem
            key={date.toString()}
            date={date}
            type={type}
            month={month}
            events={events}
            navigateToDayViewAtDate={navigateToDayViewAtDate}
          />
        ))}
      </tr>
    );
  });
}
