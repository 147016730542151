import api from '@pray/shared/utils/api';

export async function validateArtistHandle({ handle }) {
  if (!handle) throw Error('Handle is required');

  try {
    const data = {
      handle,
    };

    const url = `/web/validate/artist-handle`;
    await api.executePostRequest(url, data);
  } catch (err) {
    throw err.response.data.data?.[0]?.info;
  }
}
