import React from 'react';

export function Copy() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.25 17.25V5.75C20.25 4.64543 19.3546 3.75 18.25 3.75H6.75"
        stroke="#4B4D52"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 6.75H5.75C4.64543 6.75 3.75 7.64543 3.75 8.75V18.25C3.75 19.3546 4.64543 20.25 5.75 20.25H15.25C16.3546 20.25 17.25 19.3546 17.25 18.25V8.75C17.25 7.64543 16.3546 6.75 15.25 6.75Z"
        stroke="#4B4D52"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
