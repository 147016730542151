import React, { useEffect, useMemo, useState } from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Dropdown from '@pray/shared/components/ui/Dropdown/Dropdown';
import Text from '@pray/shared/components/ui/Text/Text';
import useDebounce from '@pray/shared/hooks/useDebounce';
import usePagination from '@pray/shared/hooks/usePagination';
import studioService from '@pray/shared/services/studioService';

import { CUSTOM_ADMIN_STYLE } from 'constants.js';
import { ChevronDown } from 'images/icons';

import userAvatar from './assets/user-avatar.svg';

import styles from './ArtistDropdown.module.scss';

export default function ArtistDropdown({
  isCollapsed = false,
  isMultiSelect = false,
  isFormElement = false,
  artists = [],
  selectedArtists = [],
  onChange,
  label = null,
  forAdmin = false,
}) {
  const selectedArtistCount = selectedArtists.length ? `${selectedArtists.length} selected` : '';
  const selectedArtist = selectedArtists && selectedArtists.length && selectedArtists[0] ? selectedArtists[0] : {};

  const { name: artistName, profile_image_url: profileImageUrl } = selectedArtist;
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);
  const [items, setItems] = useState(artists ?? []);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const {
    isLoading,
    isLastPage,
    fetchData: fetchArtists,
  } = usePagination(async (params = {}, isRefetch = false) => {
    const response = await studioService.artist.getArtists({
      search: debouncedSearch,
      sortColumn: 'name',
      sortDirection: 'asc',
      ...params,
    });

    setItems((prevData) => {
      const newData = response.data;
      if (isRefetch) return newData;
      return [...prevData, ...newData];
    });

    return {
      data: response.data,
      nextItemId: response.last_item_identifier,
    };
  });

  const handleArtistChange = (artist) => {
    setSearch('');
    if (artist) onChange?.(artist);
    if (!isMultiSelect || !artist) setIsOpen(false);
  };

  const handleKeyUp = (event) => {
    if (event.code === 'Escape') setIsOpen(false);
    if (event.code === 'Enter') fetchArtists(true);
  };

  useEffect(() => {
    fetchArtists(true, { offset: 0 });
  }, [debouncedSearch]);

  useEffect(() => {
    let active = true;

    setTimeout(() => {
      if (isOpen && !isLastPage && active) fetchArtists(false);
    }, 200);

    return () => {
      active = false;
    };
  }, [scrollPercentage]);

  const customAdminStyle = forAdmin ? CUSTOM_ADMIN_STYLE : '';
  const dropdownStyles = [
    styles.dropdown,
    isCollapsed && styles.collapsed,
    isFormElement && styles.inputWrap,
    customAdminStyle,
  ].join(' ');

  const dropdownItems = useMemo(() => {
    return selectedArtists
      .sort((a, b) => a.name.localeCompare(b.name))
      .concat(items.filter(({ id }) => !selectedArtists.some((artist) => artist.id === id)));
  }, [selectedArtists, items]);

  if (!isOpen && artists.length < 2) return null;

  const imageUrl = profileImageUrl || userAvatar;
  const getItemId = (artist) => ['ArtistDropdown', label, artist.id].filter((section) => !!section).join('_');

  return (
    <>
      <Button className={dropdownStyles} onClick={() => setIsOpen(!isOpen)}>
        {imageUrl && <img className={styles.image} src={imageUrl} alt="" />}
        <Text data-itemtext className={styles.name}>
          {artistName && label && <label>{label}</label>}
          {artistName || selectedArtistCount || 'Select a Leader'}
        </Text>
        <ChevronDown />
      </Button>

      {isOpen && (
        <Dropdown
          items={dropdownItems}
          isLoading={isLoading}
          getItemId={getItemId}
          getImage={(artist) => artist.profile_image_url}
          getTitle={(artist) => artist.name}
          getIsSelected={(artist) => selectedArtists.some(({ id }) => artist.id === id)}
          fallbackImage={userAvatar}
          searchTerm={search}
          searchPlaceholder="Search leaders"
          noItemsMessage="No leaders found"
          infiniteScrollThreshold={0.9}
          onInfiniteScroll={setScrollPercentage}
          onSearchChange={(event) => setSearch(event.target.value)}
          onKeyUp={handleKeyUp}
          onChange={handleArtistChange}
        />
      )}
    </>
  );
}
