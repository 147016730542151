import React from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { Close, Pin } from 'images/icons';

export default function OrganizationPlaceDetails({ address, onRemoveClick }) {
  if (!address) return null;

  return (
    <div className="mt-4 flex items-center gap-3 rounded-md bg-[#F3F3F2] p-4 text-[#3A3C40]">
      <Pin />
      <div>
        <Text variant="subhead_small" className="mr-4 font-semibold">
          {address}
        </Text>
      </div>
      <div className="flex flex-1 justify-end">
        <Button className="px-2" onClick={onRemoveClick}>
          <Close className="h-3 w-3" />
        </Button>
      </div>
    </div>
  );
}
