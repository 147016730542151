import React, { useEffect } from 'react';
import { create } from 'zustand';

import s3Service from '@pray/shared/services/s3Service';
import AsyncTaskQueue from '@pray/shared/utils/asyncTaskQueue';
import logger from '@pray/shared/utils/logger';
import { getMediaMetadata } from '@pray/shared/utils/media';

import CircularProgress from '../CircularProgress/CircularProgress';
import IconButton from '../IconButton/IconButton';
import { ChevronDown, Close, TriangleDanger } from '../Icons/Icons';
import Text from '../Text/Text';

import audioIcon from './assets/audio-icon.svg';
import completeIcon from './assets/check-green.svg';
import epubIcon from './assets/epub-icon.svg';
import htmlIcon from './assets/html-icon.svg';
import pdfIcon from './assets/pdf-icon.svg';
import txtIcon from './assets/txt-icon.svg';
import videoIcon from './assets/video-icon.svg';

import styles from './UploaderModal.module.scss';

const uploadManager = new AsyncTaskQueue(1);

export const useUploaderModal = create((set) => ({
  isVisible: false,
  isExpanded: false,
  totalPercentage: 0,
  title: '',
  error: '',
  files: {},
  options: null,

  collapse: () => set(() => ({ isExpanded: false })),

  uploadFiles: (options) => set(() => ({ options })),

  updateState: (partial) => set(() => partial),

  updateFiles: (files) =>
    set(() => {
      const state = { files };

      // update total percentage
      const fileList = Object.values(files);
      const sumPercentage = (total, file) => total + (file.percentage ?? 0);
      state.totalPercentage = Math.ceil(fileList.reduce(sumPercentage, 0) / fileList.length);

      // collapse when upload is complete
      if (state.totalPercentage === 100) {
        set({ title: 'Upload complete' });
        setTimeout(() => set({ isExpanded: false }), 1000);
      }
      // update status in modal title
      else if (state.totalPercentage > 0) {
        const uploadingCount = fileList.filter((file) => file.isComplete).length + 1;
        const pluralSuffix = fileList.length > 1 ? 's' : '';
        state.title = `Uploading - ${uploadingCount} of ${fileList.length} file${pluralSuffix}`;
      }

      return state;
    }),
}));

export default function UploaderModal() {
  const { isVisible, isExpanded, title, error, files, totalPercentage, options, updateState, updateFiles } =
    useUploaderModal();

  useEffect(() => {
    if (!options) return;
    uploadFiles(options).finally(() => updateState({ options: null }));
  }, [options]);

  const uploadFile = async (file, type, onProgress) => {
    file.percentage = 0;
    file.isComplete = false;
    file.isUploading = false;
    onProgress(file);

    const response = await s3Service.signAndUpload({
      type,
      file,
      options: {
        onUploadProgress: (percentage) => {
          file.percentage = percentage < 90 ? percentage : 90;
          file.isUploading = true;
          onProgress(file);
        },
      },
    });

    file.url = response.url;
  };

  const uploadFiles = async (
    options = {
      type: '',
      files: [],
      onFileUploaded: (file) => null,
    }
  ) => {
    if (!options.files?.length) return;

    const onProgress = (uploadedFile) => {
      // update error state
      if (uploadedFile.error) {
        updateState({ error: 'An error occurred while uploading a file' });
      }

      const { files } = useUploaderModal.getState();
      files[uploadedFile.name] = uploadedFile;

      updateFiles(files);
    };

    updateFiles(options.files.reduce((acc, file) => ({ ...acc, [file.name]: file }), {}));

    updateState({
      title: 'Uploading',
      isVisible: true,
      isExpanded: true,
      error: null,
    });

    options.files.forEach((file) => {
      uploadManager.pushTask(async () => {
        try {
          const [metadata] = await Promise.all([
            getMediaMetadata(file),
            uploadFile(file, options.type, onProgress), //
          ]);
          file.metadata = metadata;
          await options.onFileUploaded?.(file);
          file.percentage = 100;
          file.isComplete = true;
        } catch (err) {
          logger.error('Failed to upload file', err);
          file.error = 'Failed to upload file';
        } finally {
          file.isUploading = false;
          onProgress(file);
        }
      });
    });
  };

  const handleCloseModal = () => {
    updateState({
      isVisible: false,
      title: '',
      files: {},
    });
  };

  const toggleExpand = () => {
    updateState({ isExpanded: !isExpanded });
  };

  if (!isVisible) return null;

  const fileList = Object.values(files);
  const isUploadError = !!error;
  const isUploadComplete = totalPercentage === 100;
  const isCloseButtonDisabled = !error && !isUploadComplete;

  const classes = [styles.container];
  if (!isExpanded) classes.push(styles.minimized);

  const selectIcon = (fileType) => {
    if (fileType.startsWith('audio')) return audioIcon;
    if (fileType.startsWith('video')) return videoIcon;
    if (fileType.includes('pdf')) return pdfIcon;
    if (fileType.includes('epub')) return epubIcon;
    if (fileType.includes('html')) return htmlIcon;
    if (fileType === 'text/plain') return txtIcon;
    return videoIcon;
  };

  return (
    <div className={classes.join(' ')}>
      <div className={styles.header}>
        <Text className={styles.title}>
          {title}
          {isUploadComplete ? <img src={completeIcon} alt="complete" /> : null}
          {isUploadError ? <TriangleDanger /> : null}
        </Text>
        <div className={styles.buttons}>
          <IconButton icon={ChevronDown} className={styles.minimizeMaximizeButton} onClick={toggleExpand} />
          <IconButton icon={Close} onClick={handleCloseModal} disabled={isCloseButtonDisabled} />
        </div>
      </div>
      {isExpanded && (
        <>
          {isUploadError && (
            <div className={styles.error}>
              <Text>{error}</Text>
            </div>
          )}
          <div className={styles.body}>
            {fileList.map((file) => (
              <div className={styles.item} key={file.name}>
                <div className={styles.info}>
                  <div className={styles.icon}>
                    <img src={selectIcon(file.type)} alt="" />
                  </div>
                  <Text>{file.name}</Text>
                </div>
                <div className={styles.rowStatus}>
                  {file.isUploading && <CircularProgress value={file.percentage} />}
                  {file.isComplete && <img src={completeIcon} alt="complete" />}
                  {file.error && <TriangleDanger />}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
