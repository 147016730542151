import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Dropzone, { DropzoneInfo } from '@pray/shared/components/ui/Dropzone/Dropzone';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { useUploaderModal } from '@pray/shared/components/ui/UploaderModal/UploaderModal';
import studioService from '@pray/shared/services/studioService';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { CONTENT_ORDER, CONTENT_SERIES_HANDLER, VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useRetriggerTranslations from 'hooks/translations/retrigger';
import useAppRoutes from 'hooks/useAppRoutes';

import ContentTable from './ContentTable/ContentTable';
import TabHeader from './TabHeader/TabHeader';
import DeleteContentModal from '../../../components/DeleteContentModal/DeleteContentModal';
import MessageModal from '../../../components/MessageModal/MessageModal';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './PlaylistItems.module.scss';

export default function PlaylistItems() {
  const toast = useToastMessage();
  const dropzoneRef = useRef(null);
  const [isShowContent, setIsShowContent] = useState(true);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isShowDropzone, setIsShowDropzone] = useState(true);
  const [selectionDetails, setSelectionDetails] = useState({
    isSomeSelected: false,
    selected: [],
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const uploader = useUploaderModal();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const [playlist, setPlaylist] = useState({
    id: '',
    title: '',
    image_url: '',
    description: '',
    is_visible: false,
    handle: '',
    content_order: CONTENT_ORDER.MANUALLY.key,
  });

  const location = useLocation();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const { id: contentSeriesId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState([]);
  const [nextItem, setNextItem] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);

  const { retriggerTranslations, isPending: triggerTranslationPending } = useRetriggerTranslations(artistId);

  const handleRetriggerTranslations = async () => {
    const params = {
      sourceContentSeriesIds: [contentSeriesId],
    };

    const response = await retriggerTranslations(params);
    if (response.object === 'duplicate') {
      const errorMessage =
        response?.resource?.message ||
        'There is already a translation triggered for this locale, wait 15 minutes before retrying';

      toast.show({ error: errorMessage });
    } else {
      toast.show({ success: 'The re-translation will start shortly' });
    }
  };

  const fetchContentSeriesDetails = async ({ replace = false, offset = nextItem, limit = 25 } = {}) => {
    if (isLoading || (offset && !hasMoreData)) return;

    setIsLoading(true);

    const response = await studioService.contentSeries.getContentSeriesById({
      artistId,
      contentSeriesId,
      limit,
      offset,
    });

    const playlistDetails = response.data;
    const playlistItems = playlistDetails.content || [];

    setPlaylist(playlistDetails);

    setContent((content) => {
      if (replace) return playlistItems;

      return [...content, ...playlistItems];
    });

    setIsShowContent(true);
    setNextItem(response.next_item);
    setHasMoreData(playlistItems.length === limit && response.next_item !== nextItem);
    setIsLoading(false);
  };

  const refetchContentSeriesDetails = (limitIncrement = 0) => {
    const limit = content.length + limitIncrement;

    return fetchContentSeriesDetails({ replace: true, offset: 0, limit });
  };

  useEffect(() => {
    if (artistId && contentSeriesId) {
      fetchContentSeriesDetails();
    }
  }, [artistId, contentSeriesId]);

  const setContentSortOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const handleDeleteContentSeries = async () => {
    await studioService.contentSeries.deleteContentSeries({
      artistId,
      contentSeriesId,
    });

    navigate(appRoutes.artistPlaylists(), { replace: true });
  };

  useEffect(() => {
    setIsShowDropzone(content.length === 0);
  }, [content]);

  const handleUploadNewFile = () => {
    dropzoneRef.current.open();
  };

  const handleAddContentToPlaylist = async (selectedContent = []) => {
    const contentIds = selectedContent.map((content) => content.id);

    await studioService.contentSeries.addContentToPlaylist({
      artistId,
      contentSeriesId,
      contentIds,
    });

    await refetchContentSeriesDetails(contentIds.length);
  };

  const openDeleteConfirmationModal = (content) => {
    setSelectedContent(content);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteContent = async () => {
    let contentIds = [selectedContent?.id];

    if (selectionDetails.selected.length) {
      contentIds = selectionDetails.selected.map((index) => content[index].id);
    }

    await removeContentFromPlaylist(contentIds);
  };

  const removeContentFromPlaylist = async (contentIds) => {
    await studioService.contentSeries.removeContentFromPlaylist({
      artistId,
      contentSeriesId,
      contentIds,
    });

    await refetchContentSeriesDetails(-contentIds.length);

    toast.show({ success: 'Episode removed from Series' });
  };

  const handleAcceptedFiles = async (acceptedFiles) => {
    await uploader.uploadFiles({
      type: 'content',
      files: acceptedFiles,
      onFileUploaded: async (file) => {
        let contentPayload = { artistId };

        if (file.type.includes('audio')) {
          contentPayload = {
            ...contentPayload,
            primaryAudioUrl: file.url,
            primaryAudioFileSize: file.size,
            primaryAudioFileDuration: file.metadata.duration,
          };
        } else if (file.type.includes('video')) {
          contentPayload = {
            ...contentPayload,
            primaryVideoUrl: file.url,
            primaryVideoFileSize: file.size,
            primaryVideoFileDuration: file.metadata.duration,
          };
        } else {
          throw Error('File type not supported');
        }

        // associate updloaded file with selected artist
        const content = await studioService.content.createArtistContent(contentPayload);

        // associate content to current playlist
        await studioService.contentSeries.addContentToPlaylist({
          artistId,
          contentSeriesId,
          contentIds: [content.id],
        });

        // update content list
        await refetchContentSeriesDetails(acceptedFiles.length);
      },
    });
  };

  const getDropzoneMessage = (isDragActive) => {
    let title = '';
    let subtitle = '';

    if (isShowDropzone) {
      title = isDragActive ? 'Drop your files to upload' : 'Drag & Drop your files here';
      subtitle = 'They will be added as episodes in this series';
    }

    return <DropzoneInfo title={title} subtitle={subtitle} />;
  };

  if (!playlist.id) return null;

  const hideButtons = !!(
    playlist.handle === CONTENT_SERIES_HANDLER.RADIO || playlist.handle === CONTENT_SERIES_HANDLER.TV
  );

  const from = location.state?.from?.pathname || '';
  const backLinkLabel = from.includes('episodes') ? 'Episodes' : 'Series';

  return (
    <TabPage data-viewname={VIEW_EVENT_NAMES.SERIES.SERIES}>
      <BackLinkButton label={backLinkLabel} />

      <TabHeader
        image={playlist.image_url}
        title={playlist.title}
        description={playlist.description}
        status={playlist.is_visible ? 'published' : 'private'}
        selectionDetails={selectionDetails}
        openDeleteConfirmationModal={openDeleteConfirmationModal}
        handleDeletePlaylist={handleDeleteContentSeries}
        onUploadNewFileClick={handleUploadNewFile}
        onAddContentToPlaylist={handleAddContentToPlaylist}
        onRetriggerTranslations={handleRetriggerTranslations}
        triggerTranslationPending={triggerTranslationPending}
        onEditPlaylistButtonClick={() => {
          navigate(appRoutes.artistPlaylistDetailsForm(playlist.id), {
            state: {
              from: location.pathname,
            },
          });
        }}
        hideButtons={hideButtons}
      />
      <Section
        className={styles.section}
        onDragEnter={() => setIsShowDropzone(true)}
        onDragOver={() => setIsShowDropzone(true)}
        onDragLeave={() => setIsShowDropzone(false)}
        onDropCapture={() => setIsShowDropzone(false)}
      >
        {isShowContent && (
          <>
            {!isLoading && (
              <div className={styles.dropzone} style={{ zIndex: isShowDropzone ? 1 : -1 }}>
                <Dropzone
                  ref={dropzoneRef}
                  options={{
                    accept: {
                      'audio/mpeg': ['.mp3'],
                      'video/mp4': ['.mp4'],
                    },
                  }}
                  getDropzoneMessage={getDropzoneMessage}
                  onAcceptedFiles={handleAcceptedFiles}
                />
              </div>
            )}

            {content.length !== 0 && (
              <>
                <ContentTable
                  data={content}
                  hideButtons={hideButtons}
                  isSortingEnabled={playlist.content_order === CONTENT_ORDER.MANUALLY.key}
                  onSortChange={setContentSortOrder}
                  onSelectionChange={setSelectionDetails}
                  onContentUpdated={refetchContentSeriesDetails}
                  onRemoveContent={openDeleteConfirmationModal}
                />
                {hasMoreData && <InfiniteScroll fetchData={fetchContentSeriesDetails} />}
              </>
            )}
          </>
        )}
      </Section>

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title="Remove this episode?"
        message="You are about to remove this episode and its associated data from the series. This cannot be restored. Are you sure you want to remove this episode?"
        deleteButtonLabel="Remove"
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeleteContent();
          setIsDeleteModalOpen(false);
        }}
      />
      <MessageModal
        title="This file is being used on series"
        message="This file is currently being used on 1 or more series. Please remove it from those series before you can permanently delete the file here."
        isOpen={isMessageModalOpen}
        primaryButtonLabel="OK"
        onPrimaryButtonClick={() => setIsMessageModalOpen(false)}
        onClose={() => setIsMessageModalOpen(false)}
      />
    </TabPage>
  );
}
