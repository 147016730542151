import api from '@pray/shared/utils/api';

type UpdateBookProps = {
  artistId: string;
  bookId: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  coverFile?: File | null;
};

export const updateBook = async ({ artistId, bookId, title, description, imageUrl }: UpdateBookProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}`;

  const data = {
    ...(title ? { title } : {}),
    ...(description ? { description } : {}),
    ...(imageUrl ? { image_url: imageUrl } : {}),
  };

  await api.executePutRequest(url, data);
};
