import React from 'react';

import { buttons, typography, colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { EmailDialogModal } from './EmailDialogModal';

export default function EmailContentRequiredModal({ onClose }) {
  const dialogButtons = [
    {
      label: 'Update Email',
      variant: buttons.variant.primary,
      action: onClose,
    },
  ];

  return (
    <EmailDialogModal title="Email Details Required" buttons={dialogButtons} onClose={onClose}>
      <Text variant={typography.body} color={colors.text_primary}>
        Before sending a test email, you must first include a subject and design the content and appearance of your
        email.
      </Text>
    </EmailDialogModal>
  );
}
