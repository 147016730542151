import * as announcements from './announcements';
import * as artist from './artist';
import * as auth from './auth';
import * as books from './books';
import * as chapters from './books/chapters';
import * as calendar from './calendar';
import * as content from './content';
import * as contentArticle from './contentArticle';
import * as contentSeries from './contentSeries';
import * as contentSeriesTranslation from './contentSeriesTranslation';
import * as contentStudyGuide from './contentStudyGuide';
import * as contentTranscripts from './contentTranscripts';
import * as contentTranslation from './contentTranslation';
import * as courses from './courses';
import * as dailyItems from './dailyItems';
import * as dailySeries from './dailySeries';
import * as donationFunds from './donationFunds';
import * as donations from './donations';
import * as emailCampaigns from './emailCampaigns';
import * as emailTemplates from './emailTemplates';
import * as externalMetadata from './externalMetadata';
import * as followers from './followers';
import * as givingAccounts from './givingAccounts';
import * as givingPayments from './givingPayments';
import * as home from './home';
import * as integrations from './integrations';
import * as leadCampaigns from './leadCampaigns';
import * as leadClients from './leadClients';
import * as leadLists from './leadLists';
import * as locales from './locales';
import * as periodicTable from './periodicTable';
import * as promoUnit from './promoUnit';
import * as rssFeed from './rssFeed';
import * as segments from './segments';
import * as socialClips from './socialClips';
import * as socialPosts from './socialPosts';
import * as socials from './socials';
import * as tag from './tag';
import * as taggings from './taggings';
import * as team from './team';
import * as testimonials from './testimonials';
import * as tokens from './tokens';
import * as translations from './translations';
import * as voices from './voices';
import * as workflowAI from './workflowAI';

export default {
  announcements,
  artist,
  auth,
  calendar,
  content,
  contentArticle,
  contentSeries,
  contentSeriesTranslation,
  contentStudyGuide,
  contentTranscripts,
  contentTranslation,
  courses,
  dailyItems,
  dailySeries,
  donationFunds,
  donations,
  emailCampaigns,
  leadLists,
  emailTemplates,
  externalMetadata,
  followers,
  givingAccounts,
  givingPayments,
  home,
  integrations,
  leadCampaigns,
  leadClients,
  locales,
  periodicTable,
  promoUnit,
  rssFeed,
  segments,
  socialClips,
  socialPosts,
  tag,
  taggings,
  team,
  testimonials,
  tokens,
  workflowAI,
  voices,
  socials,
  translations,
  books,
  chapters,
};
