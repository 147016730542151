import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderContentSeries(artistId) {
  const fetchLeaderContentSeries = async (offset) => {
    const response = await studioService.contentSeries.getAllContentSeries({
      artistId,
      offset,
    });

    if (response.next_item === offset) {
      delete response.next_item;
    }

    return response;
  };

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistContentSeries(artistId),
    queryFn: ({ pageParam: offset }) => fetchLeaderContentSeries(offset),
    getPreviousPageParam: (response) => response.next_item || undefined,
    getNextPageParam: (response) => response.next_item || undefined,
    placeholderData: keepPreviousData,
  });

  return query;
}
