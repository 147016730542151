import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

import { BookProcessStatus } from './types';

/* eslint-disable camelcase */
export type BookResponse = {
  id: string;
  title: string;
  description: string;
  handle: string;
  image_url: string;
  is_visible: boolean;
  file_url: string;
  text_extraction_status: BookProcessStatus;
  text_extraction_updated_at?: string;
  audiobook_generation_status: BookProcessStatus;
  audiobook_generation_updated_at?: string;
  last_saved_at?: string;
  created_at: string;
  updated_at: string;
};
/* eslint-enable camelcase */

type GetBookProps = {
  artistId: string;
  bookId: string;
};

export const getBook = async ({ artistId, bookId }: GetBookProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}`;
  const response = await api.executeGetRequest<ObjectResponse<BookResponse>>(url);

  return response.data;
};
