import { createContext, useContext, useEffect, useState } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { CUSTOM_ADMIN_STYLE } from 'constants.js';
import { ChevronDown } from 'images/icons';
import { cn } from 'styles/utils';

import { NotificationsBadgeDot } from '../NotificationsBadge/NotificationsBadge';

const TabGroupContext = createContext({
  registerTabRoute: (route) => route,
});

export const useTabGroupContext = () => useContext(TabGroupContext);

/**
 * @param {Object} props
 * @param {React.ComponentType} props.icon
 * @param {string} props.text
 * @param {string | JSX.Element | JSX.Element[]} props.children
 * @param {boolean} [props.disabled=false]
 * @param {boolean} [props.expanded=false]
 * @param {boolean} [props.visible=true]
 * @param {boolean} [props.hasNotifications=false]
 * @param {boolean} [props.forAdmin=false]
 * @returns {JSX.Element}
 */
const TabGroup = ({
  icon: Icon,
  text,
  children,
  disabled = false,
  expanded = false,
  visible = true,
  hasNotifications = false,
  forAdmin = false,
}) => {
  const [routes, setRoutes] = useState([]);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const hasActiveTab = routes.some((route) => window.location.pathname.startsWith(route));

  useEffect(() => {
    if (hasActiveTab && !expanded) setIsExpanded(true);
  }, [hasActiveTab]);

  if (!visible) return null;

  const handleToggle = () => setIsExpanded(!isExpanded);

  const registerTabRoute = (route) => setRoutes((prevRoutes) => [...prevRoutes, route]);

  return (
    <TabGroupContext.Provider value={{ registerTabRoute }}>
      <Button
        disabled={disabled}
        className={cn(
          'flex h-12 w-full flex-row items-center gap-3 text-left normal-case tracking-normal',
          hasActiveTab && !isExpanded && 'bg-[#E6E7E8]',
          forAdmin ? CUSTOM_ADMIN_STYLE : 'active:bg-[#E6E7E8] hover:bg-[#F2F3F4]'
        )}
        onClick={handleToggle}
      >
        <Icon />

        <Text data-itemtext className="flex-1 font-medium" variant={typography.headline_small}>
          {text}
        </Text>

        {hasNotifications && <NotificationsBadgeDot />}

        <div className={isExpanded ? 'rotate-180' : ''}>
          <ChevronDown />
        </div>
      </Button>

      <div className={cn('flex-col gap-1 mb-1 pt-1 [&_a]:my-0 [&_a]:pl-10', isExpanded ? 'flex' : 'hidden')}>
        {children}
      </div>
    </TabGroupContext.Provider>
  );
};

export default TabGroup;
