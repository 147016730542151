import { ChapterVersionsResponse } from '../getChapterVersions';
import { ChapterVersions, VersionDiff } from '../types';

export const mapChapterVersions = (data: ChapterVersionsResponse): ChapterVersions => {
  return {
    published: data.published.map(mapVersionDiff),
    draft: data.draft.map(mapVersionDiff),
    mostRecentTranscript: data.most_recent_transcript,
  };
};

export const mapVersionDiff = (data: ChapterVersionsResponse['published' | 'draft'][number]): VersionDiff => {
  return {
    transcript: data.transcript,
    updatedAt: data.updated_at,
    changes: data.changes,
    diff: data.diff,
  };
};

export const mapVersionDiffList = (data: ChapterVersionsResponse['published' | 'draft']): VersionDiff[] => {
  return data.map(mapVersionDiff);
};
