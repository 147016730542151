import React from 'react';

import Button from '../../Button';

import styles from './DatePickerHeader.module.scss';

export default function DatePickerHeader({
  title = null,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  onLeftButtonClick,
  onRightButtonClick,
  onTitleClick = null,
}) {
  return (
    <div className={styles.datePickerHeader}>
      <Button className={styles.navigationButton} disabled={isLeftButtonDisabled} onClick={onLeftButtonClick}>
        <ChevronLeft />
      </Button>

      {title && (
        <Button className={styles.title} onClick={onTitleClick}>
          {title}
        </Button>
      )}

      <Button className={styles.navigationButton} disabled={isRightButtonDisabled} onClick={onRightButtonClick}>
        <ChevronRight />
      </Button>
    </div>
  );
}

function ChevronLeft() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 20L7 12L15 4" stroke="#1F2024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function ChevronRight() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 20L17 12L9 4" stroke="#1F2024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
