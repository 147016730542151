import api from '@pray/shared/utils/api';

export async function deleteDailySeries({ artistId, dailySeriesId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series ID is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data.data;
}
