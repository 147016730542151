import React, { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { PRAY_EXTERNAL_LINKS } from '@pray/shared/constants';

import { NewWindow } from 'images/icons';

import applePodcastLogo from './assets/apple-podcast.png';
import spotifyLogo from './assets/spotify-logo.svg';

export default function DistributionForm({ form, onCopyUrl }) {
  return (
    <div className="max-w-3xl">
      <RssFeedLink url={form.values.distribution_rss_feed_url} onCopy={onCopyUrl} />

      <DistributionVerification ownerEmail={form.values.distribution_owner_email} onChange={form.handleChange} />

      <DistributionChannels rssFeedUrl={form.values.distribution_rss_feed_url} />
    </div>
  );
}

const RssFeedLink = ({ url, onCopy }) => {
  return (
    <div className="flex flex-col gap-2">
      <Text className="font-bold">Your RSS Feed</Text>
      <div className="flex items-center justify-between">
        <Text className="h-12 flex-1 truncate rounded-md rounded-r-none border border-r-0 border-gray-400 px-4 py-3 font-medium">
          {url}
        </Text>
        <Button
          variant={buttons.variant.primary}
          className="flex w-28 justify-center rounded-l-none py-3"
          onClick={onCopy}
        >
          Copy
        </Button>
      </div>
    </div>
  );
};

const DistributionVerification = ({ ownerEmail, onChange }) => (
  <div className="mt-12 flex flex-col">
    <Text variant="h2">Distribution Verification</Text>

    <Text className="mt-4 font-bold">Owner Email</Text>
    <Text className="my-2">
      Distribution channels, such as Spotify, require you to set an owner email to verify your RSS Feed. Add your email
      here to ensure you can verify your RSS Feed successfully with all distribution channels.
    </Text>

    <TextInput
      type="email"
      name="distribution_owner_email"
      label="Owner Email"
      value={ownerEmail}
      onChange={onChange}
    />
  </div>
);

const DistributionChannels = ({ rssFeedUrl }) => {
  const [expanded, setExpanded] = useState({
    apple: false,
    spotify: false,
  });

  const toggleAccordionSection = (section) => {
    setExpanded((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="mt-12">
      <Text variant="h2">Distribution Channels</Text>
      <Text className="mt-2">
        Distribution channels will automatically update with your latest content after you successfully submit to each
        channel and pass the review process. Submit to your desired distribution channels using the links below.
      </Text>

      <div className="mt-10 flex flex-col gap-4">
        <AccordionSection
          isOpen={expanded.apple}
          buttonImage={applePodcastLogo}
          buttonText="Apple Podcasts"
          onClick={() => toggleAccordionSection('apple')}
        >
          <div className="mt-4 rounded-md bg-[#F3F3F2] p-4">
            <Text className="font-bold">Distribute to Apple Podcasts</Text>
            <Text className="mt-1">
              Get started by submitting your podcast to Apple Podcasts. For step-by-step help, use our{' '}
              <a
                href={PRAY_EXTERNAL_LINKS.HELP_SYNDICATION_APPLE}
                target="_blank"
                rel="noreferrer noopener"
                className="font-bold underline"
              >
                How To Submit To Apple Podcasts
              </a>{' '}
              guide.
            </Text>
            <Button
              target="_blank"
              rel="noreferrer noopener"
              href={`https://podcastsconnect.apple.com/my-podcasts/new-feed?submitfeed=${rssFeedUrl}`}
              variant={buttons.variant.primary}
              className="mt-5 inline-flex flex-row items-center gap-2"
            >
              Submit to Apple Podcast <NewWindow />
            </Button>
          </div>
        </AccordionSection>

        <AccordionSection
          isOpen={expanded.spotify}
          buttonImage={spotifyLogo}
          buttonText="Spotify"
          onClick={() => toggleAccordionSection('spotify')}
        >
          <div className="mt-4 rounded-md bg-[#F3F3F2] p-4">
            <Text className="font-bold">Distribute to Spotify</Text>
            <Text className="mt-1">
              Get started by submitting your podcast to Spotify. For step-by-step help, use our{' '}
              <a
                href={PRAY_EXTERNAL_LINKS.HELP_SYNDICATION_SPOTIFY}
                target="_blank"
                rel="noreferrer noopener"
                className="font-bold underline"
              >
                How To Submit To Spotify
              </a>{' '}
              guide.
            </Text>
            <Button
              target="_blank"
              rel="noreferrer noopener"
              href="https://podcasters.spotify.com/"
              variant={buttons.variant.primary}
              className="mt-5 inline-flex flex-row items-center gap-2"
            >
              Submit to Spotify <NewWindow />
            </Button>
          </div>
        </AccordionSection>
      </div>
    </div>
  );
};

const AccordionSection = ({ isOpen = false, buttonImage = null, buttonText = '', onClick = null, children }) => {
  const toggleClasses = isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0';
  const chevronClasses = isOpen ? 'transform rotate-180' : '';

  return (
    <div className="rounded-md border border-gray-300 p-4 py-2">
      <Button
        className="flex w-full items-center justify-between p-0 py-2 text-left font-semibold"
        onClick={onClick}
        aria-expanded={isOpen}
        aria-controls="accordion-text"
      >
        <div className="flex items-center gap-4">
          <img src={buttonImage} alt="" />
          <Text className="text-base font-bold normal-case">{buttonText}</Text>
        </div>
        <ChevronDown size={24} fill="#3C3C3D" className={chevronClasses} />
      </Button>

      <div
        id="accordion-text"
        role="region"
        aria-labelledby="accordion-title"
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${toggleClasses}`}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </div>
  );
};
