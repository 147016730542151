import { useQuery, UseQueryResult } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapVoices, type Voice } from '@pray/shared/services/studioService/voices';

type UseArtistVoicesProps = {
  artistId: string;
};

export function useArtistVoices({ artistId }: UseArtistVoicesProps): UseQueryResult<Voice[]> {
  async function fetchArtistVoices() {
    const response = await studioService.voices.getArtistVoices({ artistId });

    return mapVoices(response.data);
  }

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistVoices(artistId),
    queryFn: fetchArtistVoices,
  });

  return query;
}
