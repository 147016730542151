import React from 'react';

import IconButton from '../IconButton/IconButton';
import { ChevronLeft, ChevronRight } from '../Icons/Icons';
import Text from '../Text/Text';

import styles from './Pagination.module.scss';

type PaginationProps = {
  page: number;
  isPreviousEnabled?: boolean;
  isNextEnabled?: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
};

export default function Pagination({
  page,
  isPreviousEnabled = true,
  isNextEnabled = true,
  onPreviousClick = null,
  onNextClick = null,
}: PaginationProps) {
  return (
    <div className={styles.pagination}>
      <IconButton icon={ChevronLeft} disabled={!isPreviousEnabled} onClick={onPreviousClick} />
      {Boolean(page) && <Text>Page {page}</Text>}
      <IconButton icon={ChevronRight} disabled={!isNextEnabled} onClick={onNextClick} />
    </div>
  );
}
