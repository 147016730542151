import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import type { CourseEpisode } from '@pray/shared/services/studioService/courses/types';

type UseGetEpisodesProps = {
  artistId: string;
  courseId: string;
};

/**
 * Hook for fetching course episodes.
 *
 * @param artistId - The ID of the artist who owns the course
 * @param courseId - The ID of the course containing the episodes
 * @returns A query object for fetching episodes
 */
export default function useGetEpisodes({ artistId, courseId }: UseGetEpisodesProps) {
  const fetchEpisodes = async (): Promise<CourseEpisode[]> => {
    if (!artistId || !courseId) return [];

    const response = await studioService.courses.getEpisodes({
      artistId,
      courseId,
    });

    return response.data;
  };

  return useQuery({
    enabled: !!artistId && !!courseId,
    queryFn: fetchEpisodes,
    queryKey: queryKeys.artistCourseEpisodes(artistId, courseId),
  });
}
