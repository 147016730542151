import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useDebounce from '@pray/shared/hooks/useDebounce';
import studioService from '@pray/shared/services/studioService';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';
import { formatNumber } from '@pray/shared/utils';
import { downloadFileFromBlob } from '@pray/shared/utils/download';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useDeleteLeadList from 'hooks/leadList/useDeleteLeadList';
import useLeadListLeads from 'hooks/leadList/useLeadListLeads';
import useLeadListMetadata from 'hooks/leadList/useLeadListMetadata';
import { useUpdateLeadListMetadata } from 'hooks/leadList/useUpdateLeadListMetadata';
import useAppRoutes from 'hooks/useAppRoutes';
import { PeopleTogether } from 'images/icons';

import ContentTable from './components/ContentTable';
import ContentTableSkeleton from './components/ContentTableSkeleton';
import ErrorState from './components/ErrorState';
import LeadListEditModal from './components/LeadListEditModal';
import useSideBar from '../../../components/SideBar/useSideBar';
import LeadListSidebar from '../components/LeadListSidebar';
import MoreActionsDropdown from '../components/MoreActionsDropdown';

export default function LeadListDetails() {
  const location = useLocation();
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const toast = useToastMessage();

  const { artistId, listId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams.get('search') ?? '');

  const [isExporting, setIsExporting] = useState(false);
  const [isContactListEditModalOpen, setIsContactListEditModalOpen] = useState(false);
  const [isContactListDeleteModalOpen, setIsContactListDeleteModalOpen] = useState(false);

  const debouncedSearch = useDebounce(search);
  const filters = useMemo(() => ({ search: debouncedSearch }), [debouncedSearch]);
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const {
    data: listMetadata,
    isLoading: isLoadingListMetadata,
    isError: isErrorListMetadata,
  } = useLeadListMetadata({
    artistId,
    listId,
  });

  const {
    data: contactsData,
    isLoading: isLoadingContacts,
    isFetching: isFetchingContacts,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useLeadListLeads({
    artistId,
    listId,
    filters,
  });

  const { isPending: isUpdatingListName, updateLeadListMetadata } = useUpdateLeadListMetadata({
    artistId,
    listId,
  });

  const { isPending: isDeletingList, deleteLeadList } = useDeleteLeadList({ artistId });

  const isPageContentAllowedToRender = useMemo(() => {
    return listMetadata?.status === LeadListStatus.COMPLETE;
  }, [listMetadata?.status]);

  useEffect(() => {
    setRenderDetails(() => <LeadListSidebar to={appRoutes.artistLists()} />);
  }, [location]);

  const contacts = contactsData?.pages.flatMap((page) => page.contacts) || [];

  useEffect(() => {
    if (!isLoadingListMetadata && !isErrorListMetadata && !isPageContentAllowedToRender) {
      toast.show({ error: 'List is still being processed. Please check back later.' });
      navigate(appRoutes.artistLists());
    }
  }, [isLoadingListMetadata, isErrorListMetadata, isPageContentAllowedToRender]);

  useEffect(() => {
    if (debouncedSearch) {
      searchParams.set('search', debouncedSearch);
    } else {
      searchParams.delete('search');
    }
    setSearchParams(searchParams);
  }, [debouncedSearch]);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const response = await studioService.leadLists.getLeadListCsvDownload({
        artistId,
        listId,
      });
      downloadFileFromBlob(response, `${listMetadata?.name}.csv`);
      toast.show({ success: 'Contact list successfully exported' });
    } catch (error) {
      toast.show({ error: 'Error exporting contact list' });
    } finally {
      setIsExporting(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLeadList({ listId });
      toast.show({ success: 'List deleted' });
      navigate(appRoutes.artistLists());
    } catch (error) {
      toast.show({ error: 'Error deleting list' });
    }
  };

  const handleSaveListName = async (listName: string) => {
    try {
      await updateLeadListMetadata({
        listName,
      });
      setIsContactListEditModalOpen(false);
      toast.show({ success: 'List name updated' });
    } catch (error) {
      toast.show({ error: 'Error updating list name' });
    }
  };

  const renderContent = () => {
    if (!isPageContentAllowedToRender) {
      return null;
    }

    if ((isLoadingContacts || isFetchingContacts || isLoadingListMetadata) && !isFetchingNextPage) {
      return <ContentTableSkeleton />;
    }
    if (contacts.length > 0) {
      return (
        <>
          <ContentTable data={contacts} fields={listMetadata.mappings} />
          {isFetchingNextPage && <ContentTableSkeleton rows={5} />}
          {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} />}
        </>
      );
    }
    return null;
  };

  const formatContactsCount = useCallback(() => {
    const count = listMetadata?.count || 0;
    const text = count === 1 ? 'contact' : 'contacts';
    return `${formatNumber(count)} ${text}`;
  }, [listMetadata?.count]);

  if (isErrorListMetadata) {
    return <ErrorState />;
  }

  return (
    <TabPage
      title={listMetadata?.name}
      isLoading={!listMetadata || isLoadingListMetadata}
      data-viewname={VIEW_EVENT_NAMES.CONTACT_LISTS.DETAILS}
      topRightButtons={
        <>
          <ActionButton
            className="min-w-32"
            text={!isExporting && 'Export .CSV'}
            variant={buttons.variant.secondary}
            isLoading={isExporting}
            isDisabled={isExporting}
            onClick={handleExport}
          />
          <MoreActionsDropdown>
            <DropdownMenu.Item
              className="w-48"
              title="Edit list name"
              onClick={() => setIsContactListEditModalOpen(true)}
            />
            <DropdownMenu.Item className="w-48" title="Delete" onClick={() => setIsContactListDeleteModalOpen(true)} />
          </MoreActionsDropdown>
        </>
      }
    >
      <div className="flex items-center gap-2">
        <PeopleTogether />
        <Loading
          isLight
          width={100}
          borderRadius="6px"
          className="m-1"
          aspectRatio="4 / 1"
          isLoading={!listMetadata || isLoadingListMetadata}
        >
          <Text variant={typography.body_lg} color={colors.text_tertiary}>
            {formatContactsCount()}
          </Text>
        </Loading>
      </div>
      <div className="mb-4 mt-6 w-96">
        <TextInput
          disabled={!contactsData || isLoadingContacts}
          type="search"
          leftIcon={<Search />}
          placeholder="Search"
          loading={!isLoadingContacts && isFetchingContacts}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>
      {renderContent()}
      <LeadListEditModal
        key={listMetadata?.name}
        listName={listMetadata?.name}
        isLoading={isUpdatingListName}
        isOpen={isContactListEditModalOpen}
        onClose={() => setIsContactListEditModalOpen(false)}
        onSubmit={handleSaveListName}
      />
      <DeleteContentModal
        title="Delete list"
        message={`You are about to permanently delete this list and its ${formatContactsCount()}. This action cannot be undone.
        Are you sure you want to proceed?`}
        deleteButtonLabel="Yes, delete"
        cancelButtonLabel="No, cancel"
        isOpen={isContactListDeleteModalOpen}
        isDeleteButtonEnabled={!isDeletingList}
        onClose={() => setIsContactListDeleteModalOpen(false)}
        onDelete={handleDelete}
      />
    </TabPage>
  );
}
