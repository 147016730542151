import { create } from 'zustand';

import storage from '../utils/storage';

export const useAuth = create((set) => ({
  domainId: '',
  user: storage.getUser(),
  permissions: { allow: {}, deny: {} },
  setDomainId: (domainId) => set(() => ({ domainId })),
  setUser: (user) => set(() => ({ user })),
  setPermissions: (permissions) => set(() => ({ permissions: enhancePermissions(permissions) })),
  setUserAndPermissions: (user, permissions) => set(() => ({ user, permissions: enhancePermissions(permissions) })),
}));

function enhancePermissions(permissions) {
  const { domainId } = useAuth.getState();

  return {
    ...permissions,
    has: (permission) => hasPermission(permission, permissions, domainId),
    hasAny: (...p) => p.some((permission) => hasPermission(permission, permissions, domainId)),
    hasAll: (...p) => p.every((permission) => hasPermission(permission, permissions, domainId)),
  };
}

function hasPermission(permission = '', permissions = { allow: {}, deny: {} }, domainId = '') {
  const [domain, resource] = permission.split('-');

  if (!domain || !resource) return false;

  if (!permissions.allow) permissions.allow = {};
  if (!permissions.deny) permissions.deny = {};

  // 1. check if permission is denied explicitly
  const isDeniedExplicitly = checkPermissions(domain, resource, permissions.deny, domainId, true);
  if (isDeniedExplicitly) return false;

  // 2. check if permission is allowed explicitly
  const isAllowedExplicitly = checkPermissions(domain, resource, permissions.allow, domainId, true);
  if (isAllowedExplicitly) return true;

  // 3. check if permission is denied by wildcard
  const isDeniedWithStar = checkPermissions(domain, resource, permissions.deny, domainId, false);
  if (isDeniedWithStar) return false;

  // 4. check if permission is allowed by wildcard
  const isAllowedWithStar = checkPermissions(domain, resource, permissions.allow, domainId, false);
  if (isAllowedWithStar) return true;

  // 5. check for permission at upper domain level
  if (domain.includes('.')) {
    const permissionInUpperDomain = permission.substring(permission.indexOf('.') + 1);
    // check permission recursively for unlimited number of upper domains
    return hasPermission(permissionInUpperDomain, permissions, domainId);
  }

  // if not allowed by any of the checks above then it should be denied
  return false;
}

const findPermissionScope = (domain, resource, rules, exact) => {
  const permission = `${domain}-${resource}`;
  // find by exact match
  let scope = rules[permission];

  // find by domain + wildcard
  if (!scope && !exact) {
    const ruleKey = Object.keys(rules).find((key) => key === `${domain}-*`);
    if (ruleKey) scope = rules[ruleKey];
  }

  // make sure permission scope is an array
  return Array.isArray(scope) ? scope : null;
};

const checkPermissions = (domain, resource, rules, domainId, exact) => {
  const permissionScope = findPermissionScope(domain, resource, rules, exact);
  if (!permissionScope) return false;

  return permissionScope.includes('*') || permissionScope.includes(domainId);
};
