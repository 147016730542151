export default {
  // caption
  caption_small: 'caption_small',
  caption_link: 'caption_link',
  caption_medium: 'caption_medium',
  // footnote
  footnote_link: 'footnote_link',
  footnote: 'footnote',
  // button
  button_small: 'button_small',
  button_medium: 'button_medium',
  button_large: 'button_large',
  button_full: 'button_full',
  // subhead
  subhead_small: 'subhead_small',
  subhead_medium: 'subhead_medium',
  // body
  body_small: 'body_small',
  body_link: 'body_link',
  body: 'body',
  // headline
  headline_small: 'headline_small',
  headline_medium: 'headline_medium',
  headline_large: 'headline_large',
  // headings
  h2: 'h2',
  h1: 'h1',
  hero: 'hero',

  // display
  display_small: 'display_small',
  display_medium: 'display_medium',

  // others
  daily_prayer: 'daily_prayer',

  // new typography variants
  heading_5xl: 'heading-5xl',
  heading_4xl: 'heading-4xl',
  heading_3xl: 'heading-3xl',
  heading_2xl: 'heading-2xl',
  heading_xl: 'heading-xl',
  heading_lg: 'heading-lg',
  heading_md: 'heading-md',
  heading_sm: 'heading-sm',
  body_lg: 'body-lg',
  body_md: 'body-md',
  body_sm: 'body-sm',
};

export const typographyConfig = {
  '.body-lg': {
    css: {
      letterSpacing: 'var(--type-scale-body-lg-letter-spacing)',
      fontSize: 'var(--type-scale-body-lg-font-size)',
      lineHeight: 'var(--type-scale-body-lg-line-height)',
      fontFamily: 'var(--type-scale-body-lg-font-family)',
      fontWeight: 'var(--type-scale-body-lg-font-weight)',
    },
  },
  '.body-md': {
    css: {
      letterSpacing: 'var(--type-scale-body-md-letter-spacing)',
      fontSize: 'var(--type-scale-body-md-font-size)',
      lineHeight: 'var(--type-scale-body-md-line-height)',
      fontFamily: 'var(--type-scale-body-md-font-family)',
      fontWeight: 'var(--type-scale-body-md-font-weight)',
    },
  },
  '.body-sm': {
    css: {
      letterSpacing: 'var(--type-scale-body-sm-letter-spacing)',
      fontSize: 'var(--type-scale-body-sm-font-size)',
      lineHeight: 'var(--type-scale-body-sm-line-height)',
      fontFamily: 'var(--type-scale-body-sm-font-family)',
      fontWeight: 'var(--type-scale-body-sm-font-weight)',
    },
  },
  '.button-lg': {
    css: {
      letterSpacing: 'var(--type-scale-button-lg-letter-spacing)',
      fontSize: 'var(--type-scale-button-lg-font-size)',
      lineHeight: 'var(--type-scale-button-lg-line-height)',
      fontFamily: 'var(--type-scale-button-lg-font-family)',
      fontWeight: 'var(--type-scale-button-lg-font-weight)',
      textTransform: 'var(--type-scale-button-lg-font-case)',
    },
  },
  '.button-md': {
    css: {
      letterSpacing: 'var(--type-scale-button-md-letter-spacing)',
      fontSize: 'var(--type-scale-button-md-font-size)',
      lineHeight: 'var(--type-scale-button-md-line-height)',
      fontFamily: 'var(--type-scale-button-md-font-family)',
      fontWeight: 'var(--type-scale-button-md-font-weight)',
      textTransform: 'var(--type-scale-button-md-font-case)',
    },
  },
  '.button-sm': {
    css: {
      letterSpacing: 'var(--type-scale-button-sm-letter-spacing)',
      fontSize: 'var(--type-scale-button-sm-font-size)',
      lineHeight: 'var(--type-scale-button-sm-line-height)',
      fontFamily: 'var(--type-scale-button-sm-font-family)',
      fontWeight: 'var(--type-scale-button-sm-font-weight)',
      textTransform: 'var(--type-scale-button-sm-font-case)',
    },
  },
  '.heading-2xl': {
    css: {
      letterSpacing: 'var(--type-scale-heading-2xl-letter-spacing)',
      fontSize: 'var(--type-scale-heading-2xl-font-size)',
      lineHeight: 'var(--type-scale-heading-2xl-line-height)',
      fontFamily: 'var(--type-scale-heading-2xl-font-family)',
      fontWeight: 'var(--type-scale-heading-2xl-font-weight)',
    },
  },
  '.heading-3xl': {
    css: {
      letterSpacing: 'var(--type-scale-heading-3xl-letter-spacing)',
      fontSize: 'var(--type-scale-heading-3xl-font-size)',
      lineHeight: 'var(--type-scale-heading-3xl-line-height)',
      fontFamily: 'var(--type-scale-heading-3xl-font-family)',
      fontWeight: 'var(--type-scale-heading-3xl-font-weight)',
    },
  },
  '.heading-4xl': {
    css: {
      letterSpacing: 'var(--type-scale-heading-4xl-letter-spacing)',
      fontSize: 'var(--type-scale-heading-4xl-font-size)',
      lineHeight: 'var(--type-scale-heading-4xl-line-height)',
      fontFamily: 'var(--type-scale-heading-4xl-font-family)',
      fontWeight: 'var(--type-scale-heading-4xl-font-weight)',
    },
  },
  '.heading-5xl': {
    css: {
      letterSpacing: 'var(--type-scale-heading-5xl-letter-spacing)',
      fontSize: 'var(--type-scale-heading-5xl-font-size)',
      lineHeight: 'var(--type-scale-heading-5xl-line-height)',
      fontFamily: 'var(--type-scale-heading-5xl-font-family)',
      fontWeight: 'var(--type-scale-heading-5xl-font-weight)',
    },
  },
  '.heading-lg': {
    css: {
      letterSpacing: 'var(--type-scale-heading-lg-letter-spacing)',
      fontSize: 'var(--type-scale-heading-lg-font-size)',
      lineHeight: 'var(--type-scale-heading-lg-line-height)',
      fontFamily: 'var(--type-scale-heading-lg-font-family)',
      fontWeight: 'var(--type-scale-heading-lg-font-weight)',
    },
  },
  '.heading-md': {
    css: {
      letterSpacing: 'var(--type-scale-heading-md-letter-spacing)',
      fontSize: 'var(--type-scale-heading-md-font-size)',
      lineHeight: 'var(--type-scale-heading-md-line-height)',
      fontFamily: 'var(--type-scale-heading-md-font-family)',
      fontWeight: 'var(--type-scale-heading-md-font-weight)',
    },
  },
  '.heading-sm': {
    css: {
      letterSpacing: 'var(--type-scale-heading-sm-letter-spacing)',
      fontSize: 'var(--type-scale-heading-sm-font-size)',
      lineHeight: 'var(--type-scale-heading-sm-line-height)',
      fontFamily: 'var(--type-scale-heading-sm-font-family)',
      fontWeight: 'var(--type-scale-heading-sm-font-weight)',
    },
  },
  '.heading-xl': {
    css: {
      letterSpacing: 'var(--type-scale-heading-xl-letter-spacing)',
      fontSize: 'var(--type-scale-heading-xl-font-size)',
      lineHeight: 'var(--type-scale-heading-xl-line-height)',
      fontFamily: 'var(--type-scale-heading-xl-font-family)',
      fontWeight: 'var(--type-scale-heading-xl-font-weight)',
    },
  },
};
