import { DateTime } from 'luxon';

/**
 * Format date string to human readable
 * @param dateString - date string
 * @returns {string}
 */
export function formatUpdatedAtDate(dateString) {
  const now = DateTime.now();
  const inputDate = DateTime.fromISO(dateString);

  if (!inputDate.isValid) return dateString;

  const yesterday = now.minus({ days: 1 }).startOf('day');
  const isYesterday = inputDate >= yesterday && inputDate < now.startOf('day');
  const isToday = inputDate.hasSame(now, 'day');

  if (isToday) {
    return `Today, ${inputDate.toFormat('h:mm a')}`;
  }

  if (isYesterday) {
    return `Yesterday at ${inputDate.toFormat('h:mm a')}`;
  }

  return `${inputDate.toFormat('MMM d')} at ${inputDate.toFormat('h:mm a')}`;
}
