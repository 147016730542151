import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';

import Section from 'components/pages/StudioPage/components/Section/Section';

import { CUSTOM_ADMIN_STYLE } from 'constants.js';

export default function LeaderVerification({ form, forAdmin = false }) {
  const customStyle = `flex items-center gap-3 ${forAdmin ? CUSTOM_ADMIN_STYLE : ''}`;
  return (
    <Section title="Verification">
      <div className={customStyle}>
        <ToggleSwitch name="is_verified" checked={form.values.is_verified} onChange={form.handleChange} />
        <Text>Disable/Enable Verification for this Leader</Text>
      </div>
    </Section>
  );
}
