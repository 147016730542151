import { colors, typography } from '@pray/shared/components/foundations';
import Select from '@pray/shared/components/ui/Select/Select';
import { Td, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';

import ArrowIcon from '../../assets/ArrowIcon';
import useMappingRow from '../steps/MapFieldsStep/useMappingRow';

export default function MappingRow({ item, availableOptions, onChange }) {
  const { options, getStyle, getIcon } = useMappingRow(item, availableOptions);

  return (
    <Tr className={getStyle()}>
      <Td>
        <div className="px-2">
          <Text className="!font-medium" variant={typography.body_lg} color={colors.text_primary}>
            {item.header}
          </Text>
        </div>
      </Td>
      <Td>
        <ArrowIcon />
      </Td>
      <Td>
        <div className="flex items-center gap-6 px-2">
          <Select
            items={options}
            value={item.guess}
            placeholder="Select"
            getItemLabel={(option) => option.presentationName}
            getItemValue={(option) => option.guess}
            onChange={(event) => onChange(item, event)}
          />
          {getIcon()}
        </div>
      </Td>
    </Tr>
  );
}
