import React, { useEffect } from 'react';

import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';

import { Calendar } from 'images/icons';

import DateRangePicker from '../DateRangePicker/DateRangePicker';

export default function ReportHeader({ form = null, title = '', className = '' }) {
  useEffect(() => {
    if (!form?.values) return;

    const { startDate, endDate } = form.values;

    if (startDate && endDate && startDate > endDate) {
      form.setError('endDate', 'Invalid date range');
    } else {
      form.setError('endDate', null);
    }
  }, [form?.values]);

  return (
    <div className={`z-10 flex items-center justify-between ${className}`}>
      <Text className="text-[40px] font-bold leading-[48px]">{title}</Text>

      <div className="flex gap-4">
        <div>
          <DateRangePicker
            label="Date range"
            indicatorIcon={<Calendar />}
            startDate={form.values.startDate}
            endDate={form.values.endDate}
            onChange={([startDate, endDate]) => {
              form.setValue('startDate', startDate);
              form.setValue('endDate', endDate);
            }}
          />
          <div className="absolute">
            <InputHelperText className="!text-red-500">{form.errors.endDate}</InputHelperText>
          </div>
        </div>
      </div>
    </div>
  );
}
