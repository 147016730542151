import React, { forwardRef } from 'react';

import { cn } from '@pray/shared/utils/styles';

import { typography } from '../../foundations';
import InputLoader from '../InputLoader/InputLoader';
import Text from '../Text/Text';

import styles from './TextInput.module.scss';

/**
 * Custom props for the TextInput component
 * @typedef CustomProps
 * @property {React.Ref<HTMLInputElement>} [ref] Reference to the input element
 * @property {string} [label] Label text displayed above the input
 * @property {React.ReactNode} [infoElement] Optional info icon/element displayed next to label
 * @property {string} [description] Description text displayed below the input
 * @property {string} [helperText] Helper text displayed below the input (e.g. character count)
 * @property {string} [errorMessage] Error message displayed below the input when validation fails
 * @property {string} [className] Additional CSS classes to apply to the input
 * @property {boolean} [isError] Whether to there is an error or not
 * @property {boolean} [loading] Whether to show a loading spinner
 * @property {React.ReactNode} [leftIcon] Icon element to display on the left side of input
 * @property {React.ReactNode} [rightIcon] Icon element to display on the right side of input
 */

/**
 * Props for the TextInput component
 * @typedef {CustomProps & React.ComponentPropsWithoutRef<'input'>} TextInputProps
 */

/**
 * A text input component with support for labels, icons, validation, and loading states
 *
 * | Property | Type | Description |
 * | --- | --- | --- |
 * | ref | React.Ref<HTMLInputElement> | Reference to the input element |
 * | label | string | Label text displayed above the input |
 * | infoElement | React.ReactNode | Optional info icon/element displayed next to label |
 * | description | string | Description text displayed below the input |
 * | helperText | string | Helper text displayed below the input (e.g. character count) |
 * | errorMessage | string | Error message displayed below the input when validation fails |
 * | className | string | Additional CSS classes to apply to the input |
 * | isError | boolean} | Whether to there is an error or not |
 * | loading | boolean | Whether to show a loading spinner |
 * | leftIcon | React.ReactNode | Icon element to display on the left side of input |
 * | rightIcon | React.ReactNode | Icon element to display on the right side of input |
 *
 * ```jsx
 * import TextInput from '@pray/shared/components/ui/TextInput';
 *
 * // Basic usage
 * <TextInput
 *   label="Email"
 *   placeholder="Enter your email"
 *   required
 * />
 *
 * // With validation
 * <TextInput
 *   label="Username"
 *   errorMessage="Username is required"
 *   helperText="5-15 characters"
 * />
 *
 * // With icons
 * <TextInput
 *   label="Search"
 *   leftIcon={<SearchIcon />}
 *   rightIcon={<ClearIcon />}
 * />
 * ```
 */
const TextInput = forwardRef((/** @type {TextInputProps} */ props, ref) => {
  const {
    description,
    errorMessage,
    helperText,
    infoElement,
    label,
    leftIcon,
    isError,
    loading,
    required,
    rightIcon,
    ...inputProps
  } = props;

  return (
    <div className={styles.container}>
      <label className={cn(styles.inputBase, props.disabled && styles.disabled, isError && styles.error)}>
        {leftIcon}
        <div className={styles.inputWrap}>
          <InputLabel {...{ infoElement, ...props }} />
          <input ref={ref} {...inputProps} />
        </div>
        {loading ? <InputLoader /> : rightIcon}
      </label>
      <InputFooter {...props} />
    </div>
  );
});

export function InputLabel(props) {
  const { label, required, infoElement } = props;

  return (
    <div className={styles.labelRow}>
      <Text className={styles.label}>{label}</Text>
      {required && <Text className={styles.required}>*</Text>}
      {infoElement}
    </div>
  );
}

export function InputFooter(props) {
  const { errorMessage, description, helperText } = props;

  if (!description && !errorMessage && !helperText) {
    return null;
  }

  return (
    <div className={styles.footer}>
      <div className={styles.footerCol}>
        {!!description && (
          <Text className={styles.description} variant={typography.body_sm}>
            {description}
          </Text>
        )}
        {!!errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
      {!!helperText && (
        <Text className={styles.info} variant={typography.body_sm}>
          {helperText}
        </Text>
      )}
    </div>
  );
}

export function InfoIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 8C2 11.3133 4.68605 14 8 14C11.3139 14 14 11.3133 14 8C14 4.68605 11.3139 2 8 2C4.68605 2 2 4.68605 2 8Z"
        stroke="#56585E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0038 10.4621V7.59573M8 5.5695V5.52734"
        stroke="#56585E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ErrorIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3_315)">
        <path
          d="M17.25 10C17.25 14.0041 14.0041 17.25 10 17.25C5.99594 17.25 2.75 14.0041 2.75 10C2.75 5.99594 5.99594 2.75 10 2.75C14.0041 2.75 17.25 5.99594 17.25 10Z"
          stroke="#AA0E0F"
          strokeWidth="1.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 13C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15C9.4477 15 9 14.5523 9 14C9 13.4477 9.4477 13 10 13ZM9.48303 11.3335V8.33347V5.33347H10.6497V8.33347V11.3335H9.48303Z"
          fill="#AA0E0F"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_315">
          <rect width="16" height="16" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ErrorMessage({ message, className = '' }) {
  const classes = [styles.errorMessage, className].join(' ');

  return (
    <Text className={classes} variant={typography.body_sm}>
      <ErrorIcon />
      {message}
    </Text>
  );
}

TextInput.displayName = 'TextInput';

export default TextInput;
