import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';

import MainLayout from 'components/layouts/MainLayout';
import { useStudioContext } from 'context/StudioContext';
import useLeaderSegments from 'hooks/segments/useLeaderSegments';
import { Team } from 'images/icons';

import SegmentsList from './SegmentsList';
import EmptyState from '../../components/EmptyState/EmptyState';
import TabPage from '../../components/TabPage/TabPage';

export default function SegmentsTab() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const leaderSegments = useLeaderSegments(artistId);
  const segments = leaderSegments.data ?? [];

  const renderPage = () => {
    if (leaderSegments.isLoading) {
      return null;
    }

    if (!leaderSegments.isFetching && segments.length === 0) {
      return (
        <EmptyState icon={<Team />} title="No segments to display yet" subtitle="Your segments will show up here" />
      );
    }

    return <SegmentsList data={segments} />;
  };

  return (
    <MainLayout data-viewname="PRAY Studio - Segments Tab">
      <TabPage
        title="Segments"
        titleInfo={
          <Popover description="Segments are a way to group your followers based on their attributes and activity.">
            <Info className="size-5 text-[#56585E]" />
          </Popover>
        }
      >
        {renderPage()}
      </TabPage>
    </MainLayout>
  );
}
