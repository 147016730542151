import React, { useEffect, useRef } from 'react';

import styles from './ToastMessage.module.scss';

export default function ToastMessage({ timeout = 3000, onTimeout, ...props }) {
  const toastRef = useRef(null);

  const classes = [styles.toast, styles.enter] //
    .filter((item) => item)
    .join(' ');

  useEffect(() => {
    let timeout1;
    let timeout2;

    if (timeout) {
      timeout1 = setTimeout(() => {
        toastRef.current.classList.add(styles.leave);
      }, timeout);

      timeout2 = setTimeout(onTimeout, timeout + 100);
    }

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [timeout]);

  return <div ref={toastRef} className={classes} {...props} />;
}
