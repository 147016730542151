import React from 'react';

const VolumeOffIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 5.41415C12.75 3.85508 10.865 3.07427 9.76256 4.17672L7.05546 6.88382C6.82104 7.11824 6.50309 7.24994 6.17157 7.24994H4.75C3.23122 7.24994 2 8.48116 2 9.99994V13.9999C2 15.5187 3.23122 16.7499 4.75 16.7499H6.17157C6.50309 16.7499 6.82104 16.8816 7.05546 17.1161L9.76256 19.8232C10.865 20.9256 12.75 20.1448 12.75 18.5857V5.41415ZM10.8232 5.23738C10.9807 5.07989 11.25 5.19142 11.25 5.41415V18.5857C11.25 18.8085 10.9807 18.92 10.8232 18.7625L8.11612 16.0554C7.60039 15.5397 6.90092 15.2499 6.17157 15.2499H4.75C4.05964 15.2499 3.5 14.6903 3.5 13.9999V9.99994C3.5 9.30959 4.05964 8.74994 4.75 8.74994H6.17157C6.90092 8.74994 7.60039 8.46021 8.11612 7.94448L10.8232 5.23738Z"
        fill="white"
      />
      <path
        d="M17.0303 8.96967C16.7374 8.67678 16.2626 8.67678 15.9697 8.96967C15.6768 9.26256 15.6768 9.73744 15.9697 10.0303L17.9393 12L15.9697 13.9697C15.6768 14.2626 15.6768 14.7374 15.9697 15.0303C16.2626 15.3232 16.7374 15.3232 17.0303 15.0303L19 13.0607L20.9697 15.0303C21.2626 15.3232 21.7374 15.3232 22.0303 15.0303C22.3232 14.7374 22.3232 14.2626 22.0303 13.9697L20.0607 12L22.0303 10.0303C22.3232 9.73744 22.3232 9.26256 22.0303 8.96967C21.7374 8.67678 21.2626 8.67678 20.9697 8.96967L19 10.9393L17.0303 8.96967Z"
        fill="white"
      />
    </svg>
  );
};

export default VolumeOffIcon;
