import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

export function useDeleteSocialClip() {
  async function deleteSocialClip({ artistId, contentId, socialClipId }) {
    await studioService.socialClips.deleteSocialClip({
      artistId,
      contentId,
      socialClipId,
    });
  }

  const { mutateAsync, ...mutation } = useMutation({
    mutationFn: deleteSocialClip,
  });

  return {
    ...mutation,
    deleteSocialClip: mutateAsync,
  };
}
