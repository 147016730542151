import React from 'react';

export default function EmailIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 4C12.9713 4 4 12.9713 4 24C4 35.0287 12.9713 44 24 44C35.0287 44 44 35.0287 44 24C44 12.9713 35.0287 4 24 4ZM34.1411 16.5237L25.6305 24.0654C24.8451 24.8508 23.5879 24.8508 22.8023 24.0654L14.2917 16.5237C14.5536 16.2095 14.9463 16 15.3654 16H33.0674C33.5126 16 33.8792 16.2095 34.1411 16.5237ZM34.4261 17.6493V30.0879L27.4343 23.8555L34.4261 17.6493ZM26.3575 24.8246C25.755 25.4007 24.9694 25.6888 24.2101 25.6888C23.4508 25.6888 22.6651 25.4007 22.0627 24.8246L21.7746 24.5627L14.1806 31.3188C14.4162 31.7115 14.8614 31.9996 15.3328 31.9996H33.0348C33.5324 31.9996 33.9515 31.7377 34.1871 31.3188L26.6454 24.5627L26.3575 24.8246ZM14 30.0879V17.6493L20.9918 23.8555L14 30.0879Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
