import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';

import Flags from '../LanguageSelect/assets';

import styles from './ContentTranslations.module.scss';

export default function ContentTranslations({ translations }) {
  const flagIcons = {
    ar: Flags.AR,
    en: Flags.EN,
    es: Flags.ES,
    fr: Flags.FR,
    hi: Flags.HI,
    it: Flags.IT,
    ja: Flags.JA,
    ko: Flags.KO,
    pt: Flags.PT,
    sw: Flags.SW,
    tl: Flags.TL,
    zh: Flags.ZH,
  };

  const visibleTranslations = translations.filter(({ is_localized }) => is_localized);

  const getTranslations = (translations) => {
    if (!translations?.length) {
      return (
        <Text className="text-base font-medium" color={colors.text_secondary}>
          No languages
        </Text>
      );
    }

    // Proceed displaying the languages. Only show the first 3, and the rest of them will be shown as a +number
    const fullDisplayItems = translations.slice(0, 3);

    const translationsDisplay = fullDisplayItems.map((item, index) => (
      <div className={styles.translation} key={`translation-${index}`}>
        <img src={flagIcons[item.locale]} alt={item.locale} className={styles.translationIcon} />
        <Text className="text-xs font-bold uppercase tracking-normal" color={colors.text_secondary}>
          {item.locale}
        </Text>
      </div>
    ));

    const remainingItems = translations.length - fullDisplayItems.length;

    return (
      <div className="mr-6 flex items-center space-x-3 p-1.5">
        {translationsDisplay}
        {remainingItems > 0 && (
          <Text
            className={`text-xs font-medium tracking-normal ${styles.translationExtra}`}
            color={colors.text_primary}
          >
            +{remainingItems}
          </Text>
        )}
      </div>
    );
  };

  return getTranslations(visibleTranslations);
}
