import { encrypt as encryptAes, extractKey as extractAesKey } from '@pray/shared/utils/encryption/aes-gcm';

export const encryptFile = async <T extends File>(file: T) => {
  // Encrypt the encoded file

  const fileBuffer = await file.arrayBuffer();
  const buffer = new Uint8Array(fileBuffer);

  const { ciphertext, iv, key } = await encryptAes(buffer);

  // Extract the AES key
  const rawKey = await extractAesKey(key);

  return {
    ciphertext,
    iv,
    key: rawKey,
  };
};
