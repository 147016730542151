import { BookStatusResponse } from '../getBookStatus';
import type { BookStatus } from '../types';

export const mapBookStatus = (data: BookStatusResponse): BookStatus => {
  return {
    textExtraction: data.text_extraction_status,
    textExtractionUpdatedAt: data.text_extraction_updated_at,
    audiobookGeneration: data.audiobook_generation_status,
    audiobookGenerationUpdatedAt: data.audiobook_generation_updated_at,
  };
};
