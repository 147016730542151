import React from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';

export default function RevenuePauseResumeModal({
  isPaused,
  isLoadingStatusChange,
  onCancelPauseModal,
  onModalAction,
}) {
  const title = isPaused ? 'Resume revenue?' : 'Pause revenue?';

  const description = isPaused
    ? 'Resuming will allow you to start receiving revenue again. You can pause anytime if needed.'
    : 'Pausing will temporarily stop all incoming revenue. You can resume anytime.';

  const actionButtonLabel = isPaused ? 'Resume' : 'Pause';

  return (
    <div className="fixed inset-0 z-[999] flex items-center justify-center bg-[#000000A6]">
      <div className="m-4 flex w-full max-w-lg flex-col gap-6 rounded-2xl bg-white p-6 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.12)]">
        <Text variant={typography.h1}>{title}</Text>

        <Text className="!font-medium" variant={typography.body_lg} color={colors.text_secondary}>
          {description}
        </Text>

        <div className="mt-4 flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onCancelPauseModal}>
            Cancel
          </Button>
          <ActionButton
            text={actionButtonLabel}
            variant={buttons.variant.primary}
            onClick={onModalAction}
            isLoading={isLoadingStatusChange}
            disabled={isLoadingStatusChange}
          />
        </div>
      </div>
    </div>
  );
}
