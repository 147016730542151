import { EMAIL_CAMPAIGNS } from 'constants.js';

const {
  REPLACEMENTS: { PREVIEW_TEXT },
} = EMAIL_CAMPAIGNS;

/**
/* @typedef {Object} Template
/* @property {string} html - The HTML content of the template.
/* @property {string} css - The CSS content of the template.
/* @property {number} width - The width of the template.
/* @property {number} height - The height of the template.
*/

/**
/* @typedef {Object} Email
/* @property {string} html - The HTML content of the email.
/* @property {any} error - Any error that may occur.
*/

/**
 * Custom hook for accessing the Stripo API.
 */
export default function useStripoApi() {
  /**
   * Retrieves a template from the Stripo API.
   * @returns {Promise<Template>} A promise that resolves to an object containing the HTML, CSS, width, and height of the template.
   */
  async function getTemplate() {
    // @ts-ignore
    const stripoApi = window.StripoApi;

    return new Promise((resolve) => {
      stripoApi.getTemplate((html, css, width, height) => {
        resolve({ html, css, width, height });
      });
    });
  }

  /**
   * Sets the hidden preheader text for the email.
   * @returns {Promise<void>}
   */
  async function setHiddenPreHeader() {
    // @ts-ignore
    const stripoApi = window.StripoApi;
    stripoApi.setHiddenPreHeader(`{{${PREVIEW_TEXT}}}`);
  }

  /**
   * Compiles the email using the Stripo API.
   * @returns {Promise<Email>} A promise that resolves to an object containing the error (if any) and the compiled HTML.
   */
  async function compileEmail() {
    // @ts-ignore
    const stripoApi = window.StripoApi;

    return new Promise((resolve) => {
      stripoApi.compileEmail((error, html) => {
        resolve({ error, html });
      });
    });
  }

  /**
   * Retrieves the template HTML, CSS, and the compiled email.
   * @returns {Promise<{template: Template, email: Email}>} The template HTML, CSS, and the compiled email.
   */
  async function getTemplateAndCompiledEmail() {
    setHiddenPreHeader();

    const [template, email] = await Promise.all([
      getTemplate(), // get template html & css
      compileEmail(), // get compiled email
    ]);

    return { template, email };
  }

  return {
    getTemplate,
    compileEmail,
    getTemplateAndCompiledEmail,
  };
}
