import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';

import MainLayout from 'components/layouts/MainLayout';
import ProtectedRoute from 'components/router/ProtectedRoute';
import { Permissions } from 'utils/auth';

import { PayoutDetailsPage } from './BankTransfer/PayoutDetails/PayoutDetailsPage';
import PayoutsPage from './BankTransfer/Payouts/PayoutsPage';
import CreateEditFundPage from './CreateEditFundPage/CreateEditFundPage';
import DonationsSummaryPage from './DonationsSummaryPage/DonationsSummaryPage';
import FundListPage from './FundListPage/FundListPage';
import StatementsPage from './StatementsPage/StatementsPage';

export default function DonationsTab() {
  const { permissions } = useAuth();

  return (
    <MainLayout data-viewname="PRAY Studio - Revenue Tab">
      <Routes>
        <Route path="summary" element={<DonationsSummaryPage />} />
        <Route
          path="funds"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <FundListPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="funds/create"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <CreateEditFundPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="funds/:id/edit"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <CreateEditFundPage />
            </ProtectedRoute>
          }
        />
        <Route path="payouts" element={<PayoutsPage />} />
        <Route path="payouts/:id" element={<PayoutDetailsPage />} />
        <Route path="statements" element={<StatementsPage />} />
      </Routes>
    </MainLayout>
  );
}
