import { useEffect } from 'react';

export default function WebHealthCheckPage() {
  useEffect(() => {
    fetch('/web-healthcheck.json')
      .then((res) => res.json())
      .then((info) => {
        document.write(JSON.stringify(info, null, 2));
      });
  }, []);

  return null;
}
