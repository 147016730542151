import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

import type { ChapterResponse } from './getChapters';

type GetChapterProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

export const getChapter = async ({ artistId, bookId, chapterId }: GetChapterProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!chapterId) throw Error('Chapter ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters/${chapterId}`;

  const response = await api.executeGetRequest<ObjectResponse<ChapterResponse>>(url);

  return response.data;
};
