import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { InfiniteData } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import type { LeadLists } from '@pray/shared/services/studioService/leadLists/mappers';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';

export default function useRetryProcessingLeadList({ artistId, filters }) {
  const queryClient = useQueryClient();

  const retryProcessingLeadList = async ({ listId }) => {
    const response = await studioService.leadLists.updateLeadList({
      artistId,
      listId,
      retryUpload: true,
    });
    return response;
  };

  const mutation = useMutation({
    mutationFn: retryProcessingLeadList,
    onMutate: async ({ listId }) => {
      const queryKey = queryKeys.leadLists(artistId, filters);

      await queryClient.cancelQueries({ queryKey });
      const previousLists = queryClient.getQueryData(queryKey);

      queryClient.setQueryData<InfiniteData<LeadLists>>(queryKey, (old) => {
        if (!old) return old;

        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            lists: page.lists.map((list) =>
              list.id === listId ? { ...list, status: LeadListStatus.PROCESSING } : list
            ),
          })),
        };
      });

      return { previousLists, listId };
    },
    onError: (err, variables, { previousLists }) => {
      if (previousLists) {
        queryClient.setQueryData(queryKeys.leadLists(artistId, filters), previousLists);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadLists(artistId, filters),
        exact: false,
      });
    },
  });

  return mutation;
}
