import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

import styles from './Header.module.scss';

export default function Header({ title = '', titleInfo = null, right = null }) {
  return (
    <div className={styles.header}>
      <div className={`${styles.headerTitle} flex items-center gap-3`}>
        <Text>{title}</Text>
        {titleInfo ? <div className="translate-y-1">{titleInfo}</div> : null}
      </div>
      {right}
    </div>
  );
}
