import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DonationPreviewPage from './DonationPreviewPage/DonationPreviewPage';

/**
 * The routes declared here are supposed to be loaded as a preview
 * page (usually embeded in a preview iframe).
 */
export default function StudioPreviewPage() {
  return (
    <Routes>
      <Route path="/donation" element={<DonationPreviewPage />} />
    </Routes>
  );
}
