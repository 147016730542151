import React from 'react';

const SkipBackIcon = (props) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.2833 25.6001C25.5662 25.6001 25.8375 25.4877 26.0376 25.2877C26.2376 25.0876 26.35 24.8163 26.35 24.5334C26.35 24.532 26.35 24.5307 26.35 24.5293L26.35 16.0001L26.35 7.47093C26.35 7.46954 26.35 7.46815 26.35 7.46676C26.35 7.18386 26.2376 6.91255 26.0376 6.71251C25.8375 6.51247 25.5662 6.40009 25.2833 6.40009C25.0561 6.40053 24.8349 6.47352 24.6521 6.60843L24.65 6.60843C24.6401 6.6159 24.6304 6.62354 24.6208 6.63134L12.9729 15.1022C12.8226 15.1989 12.699 15.3317 12.6135 15.4886C12.5279 15.6455 12.4832 15.8214 12.4833 16.0001C12.4834 16.1752 12.5266 16.3475 12.6091 16.5019C12.6916 16.6563 12.8108 16.7881 12.9562 16.8855L24.65 25.3918C24.8334 25.5271 25.0554 25.6001 25.2833 25.6001ZM8.74998 25.6001C9.63317 25.6001 10.35 24.8833 10.35 24.0001L10.35 8.00009C10.35 7.11689 9.63318 6.40009 8.74998 6.40009C7.86678 6.40009 7.14998 7.11689 7.14998 8.00009L7.14997 24.0001C7.14997 24.8833 7.86678 25.6001 8.74998 25.6001Z"
        fill="white"
      />
    </svg>
  );
};

export default SkipBackIcon;
