import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetAnnouncementFromWorkflowAI(artistId, contentId, locale) {
  const fetchData = async () => {
    try {
      const response = await studioService.announcements.getAnnouncementFromWorkflowAI({
        artistId,
        contentId,
        locale,
      });

      return response.data;
    } catch (err) {
      return null;
    }
  };

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.artistAnnouncementWorkflowAI(artistId, contentId, locale),
    queryFn: fetchData,
    refetchInterval: 7000,
    refetchOnWindowFocus: true,
  });

  return query;
}
