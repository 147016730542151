import { Chart as ChartJS, Tooltip, Legend, Filler, BarElement, LinearScale, CategoryScale, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

ChartJS.register(BarElement, Filler, Tooltip, Legend, ChartDataLabels, LinearScale, CategoryScale, Title);

export const BarChart = ({ data, options = {}, title, ...props }) => {
  const defaultOptions = {
    responsive: true,
    indexAxis: 'x',
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'Satoshi',
            weight: 'normal',
          },
          color: '#666666',
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          family: 'Satoshi',
          size: 16,
          weight: '600',
        },
        padding: {
          top: 0,
          bottom: 16,
        },
        align: 'start',
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => `${value}%`,
        color: 'black',
        font: {
          family: 'Satoshi',
          weight: 'bold',
          size: 14,
        },
      },
    },
    maintainAspectRatio: true,
  };

  // Deep merge the default options with provided options
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options.plugins,
      title: {
        ...defaultOptions.plugins.title,
        ...options.plugins?.title,
      },
      datalabels: {
        ...defaultOptions.plugins.datalabels,
        ...options.plugins?.datalabels,
      },
    },
  };

  return <Bar {...props} data={data} options={mergedOptions} />;
};
