import { DateTime } from 'luxon';
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { usePreventNativePickers } from '@pray/shared/hooks/usePreventNativePickers';

import DatePickerHeader from './DatePickerHeader';
import TextInput from '../TextInput';

import styles from './DatePicker.module.scss';

const PickerModes = {
  DATE: 'date',
  MONTH: 'month',
  YEAR: 'year',
};

export default function DatePicker({ value, onChange, label = '', errorMessage = '', disabled = false, ...props }) {
  const [pickerMode, setPickerMode] = useState(PickerModes.DATE);

  const selectedDate = value ? DateTime.fromISO(value.split('T')[0]).toJSDate() : null;
  const inputProps = { label, errorMessage, disabled };
  const datePickerProps = {
    disabled,
    selected: selectedDate,
    showPopperArrow: false,
    dateFormat: 'yyyy-MM-dd',
    customInput: <DatePickerInput {...inputProps} />,
    onCalendarClose: () => setPickerMode(PickerModes.DATE),
    onChange: (date) => {
      const dateValue = DateTime.fromJSDate(date).toISODate();
      onChange(dateValue);
    },
    ...props,
  };

  if (pickerMode === PickerModes.MONTH) {
    return <MonthPicker datePickerProps={datePickerProps} onYearClick={() => setPickerMode(PickerModes.YEAR)} />;
  }

  if (pickerMode === PickerModes.YEAR) {
    return <YearPicker datePickerProps={datePickerProps} />;
  }

  return (
    <div className={styles.datePickerContainer}>
      <ReactDatePicker
        {...datePickerProps}
        customInput={<DatePickerInput {...inputProps} />}
        renderCustomHeader={(headerProps) => (
          <DatePickerHeader
            title={DateTime.fromJSDate(headerProps.date).toFormat('MMMM yyyy')}
            isLeftButtonDisabled={headerProps.prevMonthButtonDisabled}
            isRightButtonDisabled={headerProps.nextMonthButtonDisabled}
            onLeftButtonClick={headerProps.decreaseMonth}
            onRightButtonClick={headerProps.increaseMonth}
            onTitleClick={() => setPickerMode(PickerModes.MONTH)}
          />
        )}
        calendarClassName={styles.calendar}
        weekDayClassName={() => styles.weekDay}
        dayClassName={() => styles.day}
      />
    </div>
  );
}

function DatePickerInput(props) {
  const classes = [styles.datePickerInputContainer, props.disabled && styles.datePickerInputDisabled];

  usePreventNativePickers();

  return (
    <div className={classes.join(' ')}>
      <CalendarIcon className={styles.datePickerIcon} />
      <TextInput {...props} type="date" style={{ backgroundColor: 'transparent' }} />
    </div>
  );
}

function CalendarIcon(props) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.75 9.75H20.25M7.75 4.75V2.75M16.25 4.75V2.75M5.75 20.25H18.25C19.3546 20.25 20.25 19.3546 20.25 18.25V6.75C20.25 5.64543 19.3546 4.75 18.25 4.75H5.75C4.64543 4.75 3.75 5.64543 3.75 6.75V18.25C3.75 19.3546 4.64543 20.25 5.75 20.25Z"
        stroke="#1F2024"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function MonthPicker({ datePickerProps, onYearClick }) {
  return (
    <div className={styles.datePickerContainer}>
      <ReactDatePicker
        {...datePickerProps}
        startOpen
        showMonthYearPicker
        showFourColumnMonthYearPicker
        renderCustomHeader={(headerProps) => (
          <DatePickerHeader
            title={DateTime.fromJSDate(headerProps.date).toFormat('yyyy')}
            isLeftButtonDisabled={headerProps.prevYearButtonDisabled}
            isRightButtonDisabled={headerProps.nextYearButtonDisabled}
            onLeftButtonClick={headerProps.decreaseYear}
            onRightButtonClick={headerProps.increaseYear}
            onTitleClick={onYearClick}
          />
        )}
      />
    </div>
  );
}

function YearPicker({ datePickerProps }) {
  return (
    <div className={styles.datePickerContainer}>
      <ReactDatePicker
        {...datePickerProps}
        startOpen
        showYearPicker
        yearItemNumber={20}
        renderCustomHeader={(headerProps) => (
          <DatePickerHeader
            isLeftButtonDisabled={headerProps.prevYearButtonDisabled}
            isRightButtonDisabled={headerProps.nextYearButtonDisabled}
            onLeftButtonClick={headerProps.decreaseYear}
            onRightButtonClick={headerProps.increaseYear}
          />
        )}
      />
    </div>
  );
}
