import { useMemo, useState } from 'react';

import { useAuth } from '@pray/shared/hooks/useAuth';

import {
  filterStatuses,
  availableFilterTypes,
  eventTypes,
} from 'components/pages/StudioPage/tabs/CalendarTab/ComboCalendar/constants';
import { useStudioContext } from 'context/StudioContext';
import useLeaderContentSeries from 'hooks/contentSeries/useLeaderContentSeries';
import { Permissions } from 'utils/auth';

export default function useFilterState() {
  const { permissions } = useAuth();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const fetchedSeries = useLeaderContentSeries(artistId);

  const filterSeries = fetchedSeries.data?.pages
    .flatMap((page) => page.data)
    .map((series) => ({
      id: series.id,
      name: series.title,
      image: series.image_url,
    }));

  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);

  const numberOfSelectedFilters = selectedTypes.length + selectedStatuses.length + selectedSeries.length;

  const filterTitle = useMemo(() => {
    if (numberOfSelectedFilters === 0) {
      return 'Filters';
    }
    if (numberOfSelectedFilters === 1) {
      return '1 filter applied';
    }
    return `${numberOfSelectedFilters} filters applied`;
  }, [numberOfSelectedFilters]);

  const filterTypes = permissions.has(Permissions.STUDIO_ADMIN)
    ? availableFilterTypes
    : availableFilterTypes.filter((type) => type.id !== eventTypes.daily && type.id !== eventTypes.ministryMatch);

  return {
    isFilterOpened,
    setIsFilterOpened,
    selectedTypes,
    setSelectedTypes,
    selectedStatuses,
    setSelectedStatuses,
    selectedSeries,
    setSelectedSeries,
    filterTitle,
    filterTypes,
    filterStatuses,
    filterSeries,
    fetchedSeries,
  };
}
