import React, { useEffect } from 'react';

import styles from './Tooltip.module.scss';

export default function Tooltip({ timeout = 0, onTimeout = () => {}, children }) {
  useEffect(() => {
    if (timeout) {
      setTimeout(onTimeout, timeout);
    }
  }, [timeout]);

  return <div className={styles.tooltip}>{children}</div>;
}
