import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type MediaType = 'audio' | 'video';

export default function useContentList(artistId: string, mediaType: MediaType) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'created_at';
  const order = searchParams.get('order') ?? 'desc';
  const sortOrder = `${sort} ${order}`;

  function setSortOrder(value) {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  }

  async function fetchContentList(offset) {
    const [sortColumn, sortDirection] = sortOrder.split(' ');

    const response = await studioService.content.getArtistContent({
      artistId,
      mediaType,
      sortColumn,
      sortDirection,
      offset,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    return response;
  }

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId && !!mediaType,
    queryKey: queryKeys.artistContent(artistId, mediaType, sortOrder),
    queryFn: ({ pageParam: offset }) => fetchContentList(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
    placeholderData: keepPreviousData,
  });

  return {
    ...query,
    sortOrder,
    setSortOrder,
  };
}
