import React, { useState, useEffect, useRef } from 'react';
import ReactHlsPlayer from 'react-hls-player';

export const audioStatus = {
  PLAYING: 'playing',
  PAUSED: 'paused',
};

const AudioPlayer = ({ children, ...props }) => {
  const [url, setUrl] = useState(null);
  const [status, setStatus] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentVolume, setCurrentVolume] = useState(100);

  const audioRef = useRef(null);

  useEffect(() => {
    setUrl(props.url);
  }, [props.url]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (url) {
        audio.load();
      } else {
        audio.pause();
      }
    }
  }, [url]);

  const {
    autoPlay = true,
    previousUrl,
    nextUrl,
    onPlay,
    onPlayError,
    onPause,
    onPrevious,
    onNext,
    onBeforeSkip,
    onAfterSkip,
    onTimeUpdated,
    onAudioLoaded,
    onAudioEnded,
    onDragStarted,
  } = props;

  async function play(event) {
    event?.preventDefault();

    const audio = audioRef.current;
    try {
      await audio.play();

      setStatus(audioStatus.PLAYING);

      if (onPlay) onPlay(audio);
    } catch (error) {
      if (onPlayError) onPlayError(error, audio);
    }
  }

  async function pause(event) {
    event?.preventDefault();

    const audio = audioRef.current;
    await audio.pause();

    setStatus(audioStatus.PAUSED);

    if (onPause) onPause();
  }

  function previous(event) {
    event?.preventDefault();

    setUrl(previousUrl);

    if (onPrevious) onPrevious();
  }

  function next(event) {
    event?.preventDefault();

    setUrl(nextUrl);

    if (onNext) onNext();
  }

  function moveTo(time, skipEvent = false) {
    if (onBeforeSkip && !skipEvent) onBeforeSkip();

    const audio = audioRef.current;
    audio.currentTime = time;

    if (onAfterSkip) onAfterSkip();
  }

  function dragStarted() {
    if (onDragStarted) onDragStarted();
  }

  function skip(seconds) {
    return () => {
      const audio = audioRef.current;
      let newTime = audio.currentTime + seconds;
      if (newTime < 0) newTime = 0;
      if (newTime > audio.duration) newTime = audio.duration;

      moveTo(newTime);
    };
  }

  function volume(value) {
    setCurrentVolume(value);

    const audio = audioRef.current;
    audio.volume = value / 100;
  }

  function handleAudioLoaded() {
    const audio = audioRef.current;
    setDuration(audio.duration);

    if (autoPlay) play();
    if (onAudioLoaded) onAudioLoaded(audio);
  }

  function handleTimeUpdated() {
    const audio = audioRef.current;
    setCurrentTime(audio.currentTime);

    if (onTimeUpdated) onTimeUpdated(audio);
  }

  function handleAudioEnded() {
    const audio = audioRef.current;
    setStatus(audioStatus.PAUSED);

    if (onAudioEnded) onAudioEnded(audio);
  }

  const controller = {
    player: {
      play,
      pause,
      previous,
      next,
      moveTo,
      skip,
      volume,
      dragStarted,
      status,
      isPlaying: status === audioStatus.PLAYING,
    },
    playback: {
      url,
      previousUrl,
      nextUrl,
    },
    audio: {
      duration,
      currentTime,
      currentVolume,
      remainingTime: duration - currentTime,
      paused: audioRef.current?.paused,
      ended: audioRef.current?.ended,
    },
  };

  const isM3u8 = url?.includes('.m3u8');

  return (
    <>
      {isM3u8 ? (
        <ReactHlsPlayer
          playerRef={audioRef}
          src={url}
          preload="auto"
          crossOrigin="anonymous"
          onLoadedData={handleAudioLoaded}
          onTimeUpdate={handleTimeUpdated}
          onEnded={handleAudioEnded}
          width={0} // Adding this to avoid the player from rendering and blocking the UI
        />
      ) : (
        <audio
          ref={audioRef}
          src={url}
          onLoadedData={handleAudioLoaded}
          onTimeUpdate={handleTimeUpdated}
          onEnded={handleAudioEnded}
        />
      )}

      {children(controller)}
    </>
  );
};

export default AudioPlayer;
