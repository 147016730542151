import { useRef, useState } from 'react';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';

export function useVoicePlayer() {
  const [isPlayingId, setIsPlayingId] = useState(null);
  const audioRef = useRef(null);
  const toast = useToastMessage();

  const handlePlayClick = (id, audioUrl) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }

    const audio = new Audio(audioUrl);
    audioRef.current = audio;

    audio.addEventListener('error', () => {
      toast.show({ error: 'Failed to play audio' });
      setIsPlayingId(null);
      audioRef.current = null;
    });

    audio.play().catch(() => {
      toast.show({ error: 'Failed to play audio' });
      setIsPlayingId(null);
      audioRef.current = null;
    });

    setIsPlayingId(id);

    audio.onended = () => {
      setIsPlayingId(null);
      audioRef.current = null;
    };
  };

  const handlePauseClick = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setIsPlayingId(null);
  };

  return { isPlayingId, handlePlayClick, handlePauseClick, audioRef };
}
