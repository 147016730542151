import { useQueryClient } from '@tanstack/react-query';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';

import queryKeys from '@pray/shared/queryKeys';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';
import useGetMappings from 'hooks/leadList/useGetMappings';

import { DROPZONE_STATUS, DROPZONE_ERROR, CSV_WORKER_THRESHOLD } from '../../../constants';
import { cleanFileName } from '../../../utils/utils';

const useUploadCsv = (setUploadState, setFile) => {
  const queryClient = useQueryClient();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const [title, setTitle] = useState('');
  const [headers, setHeaders] = useState([]);
  const [mappings, setMappings] = useState([]);

  const { data, isError } = useGetMappings({ artistId, headers });

  const getCsvHeaders = async (csvContent) => {
    return new Promise((resolve, reject) => {
      const useWorker = csvContent.length > CSV_WORKER_THRESHOLD;
      let headers = null;

      Papa.parse(csvContent, {
        header: true,
        skipEmptyLines: true,
        worker: useWorker,
        // The step callback processes each row as it's parsed
        // We use it to quickly extract headers and abort parsing once we have them
        // This is more efficient for large files since we don't need to parse the entire file
        step: (row, parser) => {
          if (!headers) {
            headers = row.meta.fields;
            parser.abort();

            if (headers && headers.length > 0) {
              resolve(headers);
            } else {
              reject(new Error('No valid headers found in the CSV file.'));
            }
          }
        },
        // The complete callback is our fallback if step doesn't resolve
        // This handles edge cases where the file might not have headers
        // or when the parser doesn't trigger the step callback (csv with very few rows)
        complete: () => {
          if (!headers) {
            const firstRowResult = Papa.parse(csvContent, {
              header: false,
              preview: 1,
              skipEmptyLines: true,
            });

            if (firstRowResult.data && firstRowResult.data[0] && firstRowResult.data[0].length > 0) {
              resolve(firstRowResult.data[0]);
            } else {
              reject(new Error('No valid headers found in the CSV file.'));
            }
          }
        },
        error: (error) => {
          logger.error('Error parsing CSV:', error);
          reject(error);
        },
      });
    });
  };

  useEffect(() => {
    if (data) {
      setMappings(data);
      setUploadState({ status: DROPZONE_STATUS.SUCCESS, error: null });
    } else if (isError) {
      setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.UPLOAD_ERROR });
      handleCleanStates();
    }
  }, [data, isError]);

  const readCsvFile = (file) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const csvContent = event.target.result;
        const csvHeaders = await getCsvHeaders(csvContent);

        // If the headers are the same and the data is already fetched, set the upload state to success
        if (JSON.stringify(headers) === JSON.stringify(csvHeaders) && data) {
          setUploadState({ status: DROPZONE_STATUS.SUCCESS, error: null });
        } else {
          setHeaders(csvHeaders);
        }

        setFile(file);
      } catch (error) {
        logger.error('Error processing CSV headers:', error);
        setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.WRONG_FILE_CONTENT });
      }
    };

    reader.onerror = () => {
      setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.WRONG_FILE_CONTENT });
    };

    reader.readAsText(file);
  };

  const handleCleanStates = () => {
    setHeaders([]);
    setMappings([]);
    setFile(null);
    queryClient.removeQueries({ queryKey: queryKeys.leadListMappings(headers) });
  };

  const handleSetTitle = (file) => {
    if (!title && file.name) {
      setTitle(cleanFileName(file.name));
    }
  };

  const handleRemoveFile = () => {
    setUploadState({ status: DROPZONE_STATUS.EMPTY, error: null });
    handleCleanStates();
  };

  const handleUploadFile = (status, file) => {
    setUploadState({ status });

    if (status === DROPZONE_STATUS.LOADING) {
      readCsvFile(file);
      handleSetTitle(file);
    }
  };

  const handleCancelUpload = async () => {
    await queryClient.cancelQueries({ queryKey: queryKeys.leadListMappings(headers) });

    setUploadState({ status: DROPZONE_STATUS.EMPTY, error: null });
    setTitle('');
    handleCleanStates();
  };

  return { title, headers, mappings, setTitle, handleUploadFile, handleRemoveFile, handleCancelUpload };
};

export default useUploadCsv;
