import React from 'react';

export function ChevronLeftMd(props) {
  return (
    <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0404 0.959397C11.431 1.34992 11.431 1.98309 11.0404 2.37361L2.41421 10.9998L11.0404 19.6261C11.431 20.0166 11.431 20.6498 11.0404 21.0403C10.6499 21.4308 10.0168 21.4308 9.62623 21.0403L0.292893 11.7069C-0.0976311 11.3164 -0.0976311 10.6833 0.292893 10.2927L9.62623 0.959397C10.0168 0.568873 10.6499 0.568873 11.0404 0.959397Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
