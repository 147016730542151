export function isValidEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function isValidUrl(url = '', options = { scheme: '' }) {
  try {
    // Check if the URL is valid
    const { protocol } = new URL(url);

    // Check if the scheme matches, if provided
    if (options.scheme && protocol !== `${options.scheme}:`) {
      return false;
    }

    return true;
  } catch {
    return false;
  }
}

export function isValidName(name) {
  // Check if name is empty or just whitespace
  if (!name || !name.trim()) {
    return false;
  }

  // Check if name contains at least 3 characters
  if (name.trim().length < 3) {
    return false;
  }

  // Check if name only contains letters, spaces, hyphens and apostrophes
  const nameRegex = /^[a-zA-Z\s'-]+$/;
  return nameRegex.test(name.trim());
}

export default {
  isValidEmail,
  isValidUrl,
  isValidName,
};
