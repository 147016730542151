import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderPrayRadioTvContentSeries(artistId) {
  const fetchLeaderContentSeries = async () => {
    const response = await studioService.contentSeries.getPrayRadioTvContentSeries({
      artistId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistPrayRadioTvContentSeries(artistId),
    queryFn: fetchLeaderContentSeries,
  });

  return query;
}
