import React from 'react';

import styles from './DonationOption.module.scss';

export default function DonationOption({ name, isSelected, isFeatured, onChange, children }) {
  const classes = [styles.option];
  if (isSelected) classes.push(styles.selected);
  if (isFeatured) classes.push(styles.featured);

  return (
    <button type="button" className={classes.join(' ')} onClick={() => onChange(name)}>
      {children}
    </button>
  );
}
