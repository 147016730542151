import { UseQueryResult, useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { Chapter, mapChapters } from '@pray/shared/services/studioService/books/chapters';

type UseChaptersProps = {
  artistId: string;
  bookId: string;
};

export function useChapters({ artistId, bookId }: UseChaptersProps): UseQueryResult<Chapter[]> {
  async function fetchChapters() {
    const response = await studioService.chapters.getChapters({
      artistId,
      bookId,
    });

    return mapChapters(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!bookId,
    queryKey: queryKeys.artistBookChapters(artistId, bookId),
    queryFn: fetchChapters,
  });

  return query;
}
