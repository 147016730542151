import React from 'react';

import { buttons } from '@pray/shared/components/foundations';

import { EmailDialogModal } from './EmailDialogModal';

export default function ConfirmDeactivationModal({ isLoading = false, onCancel, onSend }) {
  const dialogButtons = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: onCancel, disabled: isLoading },
    {
      label: 'Stop Automation',
      variant: buttons.variant.danger,
      action: onSend,
      disabled: isLoading,
      loading: isLoading,
    },
  ];

  return (
    <EmailDialogModal title="Confirm Deactivation" buttons={dialogButtons} onClose={onCancel} isLoading={isLoading}>
      <p>
        Any messages scheduled to be sent while this automation is stopped will be permanently canceled. You will be
        able to restart this automation by clicking <strong>Resume Automation</strong>.
      </p>
      <p>New leads from Ministry Match campaigns will no longer receive any emails from this automation.</p>
    </EmailDialogModal>
  );
}
