import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import logger from '@pray/shared/utils/logger';

import { maxTitleLength, publishOptions } from '../constants';

import { useToastMessage } from '@/components/pages/StudioPage/components/StudioToastMessage';
import { useDeleteSocialClip } from '@/hooks/socialClips/useDeleteSocialClip';
import { useSocialClip } from '@/hooks/socialClips/useSocialClip';
import { useUpdateSocialClip } from '@/hooks/socialClips/useUpdateSocialClip';
import useAppRoutes from '@/hooks/useAppRoutes';

export function useSocialClipsPublishPage({ artistId, contentId, socialClipId }) {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();

  const { isPending: isPublishing, updateSocialClip } = useUpdateSocialClip();
  const { isPending: isDeleting, deleteSocialClip } = useDeleteSocialClip();
  const { data: socialClip, isLoading } = useSocialClip(artistId, contentId, socialClipId);

  const form = useForm({
    defaultValues: {
      socialAccounts: [],
      title: '',
      description: '',
      publishOption: publishOptions.now,
      publishDate: '',
      publishTime: '',
    },
  });

  useEffect(() => {
    if (!isLoading && socialClip) {
      form.setValue('title', socialClip.title);
      form.setValue('description', socialClip.description);
      form.setValue('socialAccounts', socialClip?.socialPlatforms?.map(({ platform }) => platform) ?? []);
      form.setValue('publishOption', socialClip.publishedAt ? publishOptions.scheduled : publishOptions.now);
      form.setValue(
        'publishDate',
        socialClip.publishedAt ? DateTime.fromISO(socialClip.publishedAt).toFormat('yyyy-MM-dd') : ''
      );
      form.setValue(
        'publishTime',
        socialClip.publishedAt ? DateTime.fromISO(socialClip.publishedAt).toFormat('HH:mm') : ''
      );
    }
  }, [isLoading, socialClip]);

  async function handleSubmit(data) {
    const { socialAccounts, title, description, publishOption, publishDate, publishTime } = data;

    const payload = {
      artistId,
      contentId,
      socialClipId,
      title,
      description,
      platforms: socialAccounts?.map((platform) => ({ platform })) ?? [],
      publishedAt: publishOptions.now,
    };

    // Check title for max length
    if (title.length > maxTitleLength) {
      form.setError('title', { message: `Title must be ${maxTitleLength} characters maximum` });
      return;
    }

    // If scheduled, check if the date and time is valid
    if (publishOption === publishOptions.scheduled) {
      if (!isValidScheduledDateTime(publishDate, publishTime)) return;
      const scheduledDateTime = DateTime.fromISO(`${publishDate}T${publishTime}`);
      payload.publishedAt = scheduledDateTime.toUTC().toISO();
    }

    try {
      await updateSocialClip(payload);
      toast.show({ success: 'Social clip published successfully' });
    } catch (error) {
      logger.error('Failed to publish social clip', error);
      toast.show({ error: 'Failed to publish social clip' });
    } finally {
      navigate(appRoutes.artistContentSocialClips(contentId));
    }
  }

  async function handleDelete() {
    try {
      await deleteSocialClip({ artistId, contentId, socialClipId });
      toast.show({ success: 'Social clip cancelled successfully' });
    } catch (error) {
      logger.error('Failed to cancel social clip', error);
      toast.show({ error: 'Failed to cancel social clip' });
    } finally {
      navigate(appRoutes.artistContentSocialClips(contentId));
    }
  }

  function isValidScheduledDateTime(publishDate, publishTime) {
    const now = DateTime.now();

    // Check if date is in the past
    const nowDate = DateTime.fromISO(now.toFormat('yyyy-MM-dd'));
    const scheduledDate = DateTime.fromISO(publishDate);
    const scheduledDateTime = DateTime.fromISO(`${publishDate}T${publishTime}`);

    if (scheduledDate < nowDate) {
      form.setError('publishDate', { message: 'Scheduled date and time must be in the future' });
      return false;
    }

    // If same date but time is in the past
    if (scheduledDate.equals(nowDate) && scheduledDateTime < now) {
      form.setError('publishTime', { message: 'Scheduled date and time must be in the future' });
      return false;
    }

    return true;
  }

  return {
    form,
    isLoading,
    isPublishing,
    isDeleting,
    socialClip,
    handleSubmit,
    handleDelete,
  };
}
