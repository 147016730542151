import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useSaveDonationFund(artistId) {
  const queryClient = useQueryClient();

  async function saveDonationFund({
    fundId = undefined,
    name = undefined,
    description = undefined,
    isEnabled = undefined,
    imageUrl = undefined,
    defaultAmountMonthly = undefined,
    defaultAmountYearly = undefined,
    amountMonthly2 = undefined,
    amountYearly2 = undefined,
    includeInAppPromotions = undefined,
  }) {
    const data = {
      artistId,
    };

    if (fundId !== undefined) data.fundId = fundId;
    if (name !== undefined) data.name = name;
    if (description !== undefined) data.description = description;
    if (imageUrl !== undefined) data.imageUrl = imageUrl;
    if (isEnabled !== undefined) data.isEnabled = isEnabled;
    if (defaultAmountMonthly !== undefined) data.defaultAmountMonthly = defaultAmountMonthly;
    if (defaultAmountYearly !== undefined) data.defaultAmountYearly = defaultAmountYearly;
    if (amountMonthly2 !== undefined) data.amountMonthly2 = amountMonthly2;
    if (amountYearly2 !== undefined) data.amountYearly2 = amountYearly2;
    if (includeInAppPromotions !== undefined) data.includeInAppPromotions = includeInAppPromotions;

    let fund;

    if (!fundId) {
      fund = await createDonationFund(data);
    } else {
      fund = await updateDonationFund(data);
    }

    return fund;
  }

  async function createDonationFund(data) {
    const response = await studioService.donationFunds.createDonationFund(data);

    return response.data;
  }

  async function updateDonationFund(data) {
    const response = await studioService.donationFunds.updateDonationFund(data);

    return response.data;
  }

  const mutation = useMutation({
    mutationFn: saveDonationFund,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistFunds(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveDonationFund: mutateAsync,
    ...rest,
  };
}
