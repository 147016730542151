import { useEffect } from 'react';

export default function useClickAway(elementRef, callback) {
  useEffect(() => {
    const handler = (event) => {
      const element = elementRef.current;
      if (element && !element.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);
}
