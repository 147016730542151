import { useState } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import Text from '@pray/shared/components/ui/Text';

import MainLayout from '@/components/layouts/MainLayout';
import { Info } from '@/images/icons';

import { GeneratingSocialClips } from './GeneratingSocialClips';
import { SocialClipsEmptyState } from './SocialClipsEmptyState';
import { SocialClipsGenerationFailed } from './SocialClipsGenarationFailed';
import { SocialClipsInfoDialog } from './SocialClipsInfoDialog';
import { SocialClipsInvalidFormat } from './SocialClipsInvalidFormat';
import { SocialClipsList } from './SocialClipsList';
import { SocialClipsLoading } from './SocialClipsLoading';
import { useSocialClipsPage } from './useSocialClipsPage';
import TabPage from '../../../components/TabPage/TabPage';

export default function SocialClipsPage({ content }) {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const { data, state, states, handleGenerateSocialClips } = useSocialClipsPage(content);

  return (
    <MainLayout data-viewname="PRAY Studio - Content Tab">
      <TabPage
        title="Social Clips"
        titleInfo={
          <IconButton data-tapeventname="Close" onClick={() => setIsInfoOpen(true)}>
            <Info className="size-6 text-[#56585E]" />
          </IconButton>
        }
      >
        <Text variant={typography.body_lg} color={colors.text_tertiary} className="!font-medium">
          Turn long videos into shorts, and publish them on all social platforms with one click.
        </Text>

        <div className="flex max-w-4xl flex-col gap-8 pt-10">
          {state === states.loading && <SocialClipsLoading />}
          {state === states.invalidFormat && <SocialClipsInvalidFormat />}
          {state === states.generating && <GeneratingSocialClips />}
          {state === states.empty && <SocialClipsEmptyState onGenerateClick={handleGenerateSocialClips} />}
          {state === states.error && <SocialClipsGenerationFailed onGenerateClick={handleGenerateSocialClips} />}
          {state === states.list && <SocialClipsList socialClips={data.socialClips} />}
        </div>
      </TabPage>

      {isInfoOpen && <SocialClipsInfoDialog onClose={() => setIsInfoOpen(false)} />}
    </MainLayout>
  );
}
