import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { emailTemplates } from '@pray/shared/data/emails-data';
import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { emailTemplateMapper } from '@pray/shared/services/studioService/emailTemplates';

export default function useEmailTemplates(artistId, type) {
  const fetchEmailTemplates = async (offset) => {
    const response = await studioService.emailTemplates.getAllEmailTemplates({
      artistId,
      type,
      offset,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    let templates = [];

    if (!offset) {
      templates = templates.concat(emailTemplates);
    }

    response.data = templates.concat(response.data).map(emailTemplateMapper);

    return response;
  };

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistEmailTemplates(artistId, 'emailTemplates', type),
    queryFn: ({ pageParam: offset }) => fetchEmailTemplates(offset),
    getPreviousPageParam: (response) => response.last_item_identifier || undefined,
    getNextPageParam: (response) => response.last_item_identifier || undefined,
    placeholderData: keepPreviousData,
  });

  return query;
}
