import { randomId } from '@pray/shared/utils';
import api from '@pray/shared/utils/api';
import logger from '@pray/shared/utils/logger';

async function signS3({ type, file_name: fileName, file_type: fileType, ...extraParams }) {
  if (!type) throw Error('Type is required');
  if (!fileName) throw Error('File name is required');
  if (!fileType) throw Error('File type is required');

  const body = {
    type,
    file_name: fileName,
    file_type: fileType,
  };

  if (extraParams) {
    Object.keys(extraParams).forEach((key) => {
      if (extraParams[key] === null || extraParams[key] === undefined) {
        delete extraParams[key];
      }
    });

    Object.assign(body, extraParams);
  }

  const response = await api.executePostRequest(`/web/sign-s3`, body);

  return response.data.data;
}

async function uploadSignedRequest({
  signed_request: signedRequest,
  file,
  options = {
    onUploadProgress: (percentComplete) => {},
  },
}) {
  if (!signedRequest) throw Error('Signed Request URL is required');
  if (!file) throw Error('File is required');

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr);
        } else {
          reject(xhr);
        }
      }
    };

    const { onUploadProgress } = options || {};

    if (onUploadProgress) {
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / file.size) * 100;
          onUploadProgress(Math.floor(percentComplete));
        }
      };
    }

    xhr.open('PUT', signedRequest);
    xhr.send(file);
  });
}

export default {
  async signAndUpload({ file, type, options = null, signParams = null, fileName = null }) {
    if (!file) throw Error('File is required');
    if (!type) throw Error('Type is required');

    let uploadFileName = fileName || file.name;
    uploadFileName = file.type.startsWith('image/') ? `${randomId()}___${uploadFileName}` : uploadFileName;

    const params = {
      type,
      file_name: uploadFileName,
      file_type: file.type,
    };

    if (signParams) Object.assign(params, signParams);

    try {
      const signResponse = await signS3(params);

      await uploadSignedRequest({
        signed_request: signResponse.signed_request,
        file,
        options,
      });

      return {
        url: encodeURI(signResponse.url),
        rsaPublicKey: signResponse.rsa_public_key,
      };
    } catch (err) {
      const message = 'Failed to upload the file';
      logger.error(message, err);
      throw Error(message);
    }
  },
};
