import amplitude from 'amplitude-js';
import branch from 'branch-sdk';
import { AMPLITUDE_KEY, BRANCH_KEY, ENV, FACEBOOK_PIXEL_ID, GOOGLE_ANALYTICS_KEY } from 'config.js';
import ReactFacebookPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

import { isOnBrowser } from '@pray/shared/utils';
import { authenticate } from '@pray/shared/utils/auth';
import { initializeBrazeSdk } from '@pray/shared/utils/braze';
import { getCookieSettings, isCookieBannerAccepted } from '@pray/shared/utils/gdpr';
import storage from '@pray/shared/utils/storage';

// gatsby-browser calls this in the onClientEntry function (called immediately when site loads)
const defaultInitOptions = {
  skipAmplitude: false,
  skipBranch: false,
  skipFacebookPixel: false,
  skipBraze: false,
  skipGoogleAnalytics: false,
  skipAuthentication: false,
};

/**
 * @param {object} initOptions
 * @param {boolean} [initOptions.skipAmplitude]
 * @param {boolean} [initOptions.skipBranch]
 * @param {boolean} [initOptions.skipFacebookPixel]
 * @param {boolean} [initOptions.skipBraze]
 * @param {boolean} [initOptions.skipGoogleAnalytics]
 * @param {boolean} [initOptions.skipAuthentication]
 */
const initialize = async (initOptions = defaultInitOptions) => {
  const deviceId = storage.getDeviceId();
  const user = storage.getUser();
  const isUserRegistered = storage.isUserRegistered();
  const userId = user && user.id && isUserRegistered ? user.id : null;
  const options = { ...defaultInitOptions, ...initOptions };

  const { skipAmplitude, skipBranch, skipFacebookPixel, skipBraze, skipGoogleAnalytics, skipAuthentication } = options;

  if (!skipAmplitude) _initializeAmplitude(deviceId, userId);
  if (!skipBranch) _initializeBranch(deviceId);
  if (!skipFacebookPixel) _initializeFacebookPixel();

  // initialize braze only if the user is registered
  if (isUserRegistered) {
    if (!skipBraze) initializeBrazeSdk(userId);
  }

  const isInitializationDelayed = !isCookieBannerAccepted();
  if (!isInitializationDelayed) {
    if (!skipGoogleAnalytics) _initializeGoogleAnalytics();
  }

  if (isOnBrowser()) {
    if (!skipAuthentication) await authenticate();
  }
};

const initializeAfterCookieBannerAcceptance = () => {
  _initializeGoogleAnalytics();
};

function _initializeAmplitude(deviceId, userId) {
  // set up amplitude
  // https://amplitude.github.io/Amplitude-JavaScript/
  // https://developers.amplitude.com/docs/advanced-settings#setting-configuration-options-javascript
  // https://github.com/amplitude/Amplitude-JavaScript/blob/master/src/options.js#L14
  const amplitudeConfigOptions = {
    deviceId,
    disableCookies: true, // uses localstorage instead
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    logLevel: ENV === 'master' ? 'WARN' : 'INFO',
  };

  amplitude.getInstance().init(AMPLITUDE_KEY, userId, amplitudeConfigOptions, () => {});
}

function _initializeFacebookPixel() {
  const { isFacebookOptOut } = getCookieSettings();
  const isFacebookTrackingDisabled = !isCookieBannerAccepted() || isFacebookOptOut;

  // https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
  const advancedMatching = {};

  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };

  ReactFacebookPixel.init(FACEBOOK_PIXEL_ID, advancedMatching, options);
  // https://www.npmjs.com/package/react-facebook-pixel GDPR compliance section
  if (isFacebookTrackingDisabled) ReactFacebookPixel.revokeConsent();
}

function _initializeBranch(deviceId) {
  // set up branch
  const branchOptions = {
    no_journeys: false,
  };

  const { isBranchOptOut } = getCookieSettings();
  const isBranchTrackingDisabled = !isCookieBannerAccepted() || isBranchOptOut;
  // https://help.branch.io/developers-hub/docs/web-advanced-features#enable--disable-user-tracking
  if (isBranchTrackingDisabled) branchOptions.tracking_disabled = true;

  branch.init(BRANCH_KEY, branchOptions);
  // https://help.branch.io/developers-hub/docs/web-full-reference#setidentityidentity-callback
  branch.setIdentity(deviceId);
}

function _initializeGoogleAnalytics() {
  ReactGA.initialize(GOOGLE_ANALYTICS_KEY, {
    debug: false,
    gaOptions: {},
  });
}

export default {
  initialize,
  initializeAfterCookieBannerAcceptance,
};
