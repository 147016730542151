import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Info } from '@pray/shared/components/ui/Icons/Info';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useDebounce from '@pray/shared/hooks/useDebounce';

import MainLayout from 'components/layouts/MainLayout';
import { useStudioContext } from 'context/StudioContext';
import useLeaderFollowerMetadata from 'hooks/followers/useLeaderFollowerMetadata';
import useLeaderFollowers from 'hooks/followers/useLeaderFollowers';
import { Team } from 'images/icons';

import FollowersList from './FollowersList';
import EmptyState from '../../components/EmptyState/EmptyState';
import TabPage from '../../components/TabPage/TabPage';

export default function FollowersTab() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') ?? '');
  const debouncedSearch = useDebounce(search);

  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const leaderFollowers = useLeaderFollowers(artistId);
  const leaderFollowerMetadata = useLeaderFollowerMetadata(artistId);

  const followers = leaderFollowers.data?.pages.flatMap((page) => page.data) || [];
  const followerCount = leaderFollowerMetadata.data?.follower_count || 0;

  useEffect(() => {
    setSearchParams((params) => {
      if (debouncedSearch) {
        params.set('search', debouncedSearch);
      } else {
        params.delete('search');
      }
      return params;
    });
  }, [debouncedSearch]);

  return (
    <MainLayout data-viewname="PRAY Studio - Followers Tab">
      <TabPage
        title="Followers"
        titleInfo={
          <Popover title="Followers" description="This table shows your followers">
            <Info className="size-5 text-[#56585E]" />
          </Popover>
        }
      >
        {!!followerCount && (
          <div className="flex items-center gap-2">
            <FollowersIcon />
            <Text className="font-bold text-[#56585E]">{followerCount.toLocaleString()} Followers</Text>
          </div>
        )}

        <div className="mb-4 mt-6 w-96">
          <TextInput
            type="search"
            leftIcon={<Search />}
            placeholder="Search by name"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>

        {(() => {
          if (leaderFollowers.isLoading) return null;

          if (!leaderFollowers.isFetching && !leaderFollowers.hasPreviousPage && !leaderFollowers.hasNextPage) {
            return (
              <EmptyState
                icon={<Team />}
                title="No followers to display yet"
                subtitle="Your followers will show up here"
              />
            );
          }

          return (
            <>
              <FollowersList
                data={followers}
                sortBy={leaderFollowers.sortOrder}
                onSortChange={leaderFollowers.setSortOrder}
              />
              {leaderFollowers.hasNextPage && <InfiniteScroll fetchData={leaderFollowers.fetchNextPage} />}
            </>
          );
        })()}
      </TabPage>
    </MainLayout>
  );
}

function FollowersIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.33325 4.66667C2.33325 3.19391 3.52716 2 4.99992 2C6.47268 2 7.66659 3.19391 7.66659 4.66667C7.66659 6.13943 6.47268 7.33333 4.99992 7.33333C3.52716 7.33333 2.33325 6.13943 2.33325 4.66667Z"
        fill="#56585E"
      />
      <path
        d="M8.33325 4.66667C8.33325 3.19391 9.52716 2 10.9999 2C12.4727 2 13.6666 3.19391 13.6666 4.66667C13.6666 6.13943 12.4727 7.33333 10.9999 7.33333C9.52716 7.33333 8.33325 6.13943 8.33325 4.66667Z"
        fill="#56585E"
      />
      <path
        d="M4.95233 8C7.29528 8 9.48752 9.92842 9.7368 13.4648L9.77453 14H0.130127L0.167851 13.4648C0.417138 9.92842 2.60938 8 4.95233 8Z"
        fill="#56585E"
      />
      <path
        d="M10.7344 13.3945L10.7771 14H15.8678L15.8301 13.4648C15.5808 9.92842 13.3886 8 11.0456 8C10.2533 8 9.47818 8.22055 8.78963 8.64925C9.86489 9.75754 10.5926 11.3824 10.7344 13.3945Z"
        fill="#56585E"
      />
    </svg>
  );
}
