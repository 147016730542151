import React, { useEffect, useMemo, useRef, useState } from 'react';

import useClickAway from '@pray/shared/hooks/useClickAway';

import { colors } from '../../foundations';
import Button from '../Button/Button';
import { Check } from '../Icons/Check';
import { ChevronDown } from '../Icons/ChevronDown';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import InputField from '../InputField/InputField';
import Text from '../Text/Text';

import styles from './Select.module.scss';

/**
 * @typedef {Record<string, any> | string} SelectItem
 *
 * @typedef {Object} SelectGroup
 * @property {string} label
 * @property {SelectItem[]} items
 *
 * @typedef {Object} SelectProps
 * @property {string} [name]
 * @property {string} label
 * @property {string} value
 * @property {string} [placeholder]
 * @property {SelectItem[]} items
 * @property {SelectGroup[]} [groups]
 * @property {boolean} [disabled]
 * @property {(item: SelectItem) => React.ReactNode} [renderItemLabel]
 * @property {(item: SelectItem) => string} getItemLabel
 * @property {(item: SelectItem) => string} getItemValue
 * @property {(item: SelectItem) => number} [getGroupIndex]
 * @property {(item: SelectItem) => React.ReactNode} [getLeftIcon]
 * @property {(item: SelectItem) => string} [getLeftImage]
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} onChange
 * @property {() => void} [onInfiniteScroll]
 * @property {React.ElementType} [inputElement]
 *
 * @param {SelectProps} props
 * @returns {JSX.Element}
 */
export default function Select({
  name = '',
  value = '',
  items = [],
  groups = [],
  disabled = false,
  inputElement = null,
  renderItemLabel = null,
  getItemLabel = null,
  getItemValue = null,
  getGroupIndex = null,
  getLeftIcon = null,
  getLeftImage = null,
  onChange = null,
  onInfiniteScroll = null,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const selectedItem = items.find((item) => getItemValue?.(item) === value);

  useClickAway(containerRef, () => closeDropdown());

  useEffect(() => {
    if (!isOpen) return undefined;

    const dropdownElement = dropdownRef.current;

    if (dropdownElement) {
      dropdownElement.addEventListener('wheel', handleScroll, { passive: false });
      dropdownElement.addEventListener('touchmove', handleScroll, { passive: false });
    }

    return () => {
      if (dropdownElement) {
        dropdownElement.removeEventListener('wheel', handleScroll);
        dropdownElement.removeEventListener('touchmove', handleScroll);
      }
    };
  }, [isOpen]);

  const initialGroups = useMemo(() => {
    if (!groups.length) return [{ label: null, items: [] }];
    return groups.map((group) => ({ label: group, items: [] }));
  }, [groups]);

  const groupedItems = useMemo(() => {
    return items?.reduce((acc, item) => {
      const groupIndex = getGroupIndex?.(item) || 0;
      if (acc[groupIndex]) {
        acc[groupIndex].items.push(item);
      }
      return acc;
    }, initialGroups);
  }, [items, groups, getGroupIndex, initialGroups]);

  const getInputValue = () => {
    if (!value) return '';
    return selectedItem ? getItemLabel?.(selectedItem) : '';
  };

  const renderEmptyMessage = () => (
    <div className="py-2 text-center">
      <Text color={colors.text_tertiary}>No items</Text>
    </div>
  );

  const renderItem = (item) => {
    const itemLabel = renderItemLabel?.(item) || getItemLabel?.(item);
    const itemValue = getItemValue?.(item);
    const leftIcon = getLeftIcon?.(item);
    const leftImage = getLeftImage?.(item);
    const isSelected = itemValue === value;

    const handleChange = (event) => {
      event.stopPropagation();

      onChange?.({
        target: {
          name,
          value: itemValue,
          item,
        },
      });

      closeDropdown();
    };

    return (
      <Button key={itemValue} className={styles.item} onClick={handleChange}>
        {leftIcon && <div className={styles.leftIcon}>{leftIcon}</div>}
        {leftImage && <img alt={itemLabel} src={leftImage} className={styles.leftImage} />}
        {!!itemLabel && <Text className={styles.itemLabel}>{itemLabel}</Text>}
        {isSelected && <Check />}
      </Button>
    );
  };

  const handleScroll = (event) => {
    if (dropdownRef.current?.contains(event.target)) {
      const { scrollTop, scrollHeight, clientHeight } = dropdownRef.current;
      const atTop = scrollTop === 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight;

      if ((atTop && event.deltaY < 0) || (atBottom && event.deltaY > 0)) {
        event.preventDefault();
      }
    }
  };

  const toggleDropdown = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => setIsOpen(false);

  const dropdownStyles = [styles.container, disabled && styles.disabled].filter(Boolean).join(' ');

  const AppropriateInputElement = (props) => {
    if (inputElement) {
      const InputElement = inputElement;
      return <InputElement {...props} />;
    }

    return <InputField {...props} />;
  };

  return (
    <div
      ref={containerRef}
      role="none"
      data-testid="select-container"
      className={dropdownStyles}
      onClick={toggleDropdown}
    >
      <AppropriateInputElement
        readOnly
        {...props}
        value={getInputValue()}
        className="cursor-pointer"
        leftIcon={getLeftIcon?.(selectedItem)}
        rightIcon={!props.loading ? <ChevronDown fill="#56585e" /> : null}
      />
      {!disabled && isOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.groups}>
            {groupedItems.map((group) => (
              <div key={group.label} className={styles.group}>
                {group.label && <Text className={styles.groupLabel}>{group.label}</Text>}
                <div className={styles.items}>
                  {group.items.map(renderItem)}
                  {!group.items.length && renderEmptyMessage()}
                </div>
              </div>
            ))}
          </div>
          {onInfiniteScroll && <InfiniteScroll fetchData={onInfiniteScroll} />}
        </div>
      )}
    </div>
  );
}
