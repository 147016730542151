import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';
import studioService from '@pray/shared/services/studioService';
import { formatDateUsingShorterMonthAndTime } from '@pray/shared/utils/datetime';

import ContentItem from 'components/pages/StudioPage/components/ContentItem/ContentItem';
import ContentStatus from 'components/pages/StudioPage/components/ContentStatus/ContentStatus';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import audioIcon from '../../../assets/audio-icon.png';
import BackLinkButton from '../../../components/BackLinkButton/BackLinkButton';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';
import DeletePlaylistModal from '../../PlaylistsTab/DeletePlaylistModal/DeletePlaylistModal';

import styles from './DailiesFeedDetails.module.scss';

export default function DailiesFeedDetails() {
  const [isShowEpisodes, setIsShowEpisodes] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const location = useLocation();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();

  const { id: dailySeriesId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'scheduled_at';
  const order = searchParams.get('order') ?? 'desc';
  const dailyItemSortOrder = `${sort} ${order}`;

  const [episodes, setEpisodes] = useState([]);
  const [nextItem, setNextItem] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);

  const [dailySeriesItem, setDailySeriesItem] = useState({
    id: null,
    title: null,
    description: null,
    image_url: null,
  });

  const setDailyItemOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const fetchFeedDetails = async ({ replace = false, offset = nextItem, limit = 25 } = {}) => {
    if (offset && !hasMoreData) return;

    const [sortColumn, sortDirection] = dailyItemSortOrder.split(' ');

    const response = await studioService.dailySeries.getDailySeriesById({
      artistId,
      dailySeriesId,
      offset,
      limit,
      sortColumn,
      sortDirection,
    });

    setDailySeriesItem(response.data);
    const playlistItems = response.data.daily_items || [];

    setEpisodes((episodes) => {
      if (replace) return playlistItems;

      return [...episodes, ...playlistItems];
    });

    setIsShowEpisodes(true);
    setNextItem(response.next_item);

    if (!replace) {
      setHasMoreData(playlistItems.length === limit && response.next_item !== nextItem);
    }
  };

  const handleDeleteDailySeries = async () => {
    await studioService.dailySeries.deleteDailySeries({
      artistId,
      dailySeriesId,
    });

    navigate(appRoutes.artistPlaylists(), { replace: true });
  };

  const refetchFeedDetails = () => {
    return fetchFeedDetails({
      replace: true,
      offset: 0,
      limit: episodes.length,
    });
  };

  useEffect(() => {
    if (artistId) {
      fetchFeedDetails();
    }
  }, [artistId, dailySeriesId]);

  useEffect(() => {
    if (episodes.length) refetchFeedDetails();
  }, [dailyItemSortOrder]);

  if (!dailySeriesItem.id) return null;

  return (
    <TabPage>
      <BackLinkButton label="Series" />

      <TabHeader
        image={dailySeriesItem.image_url}
        title={dailySeriesItem.title}
        description={dailySeriesItem.description}
        status="published"
        handleDeleteDailySeries={handleDeleteDailySeries}
        onEditPlaylistButtonClick={() => {
          navigate(appRoutes.artistDailySeriesDetailsForm(dailySeriesId), {
            state: {
              from: location.pathname,
            },
          });
        }}
      />

      <Section className={styles.section}>
        {isShowEpisodes && episodes.length !== 0 && (
          <div className="mt-3">
            <ContentTable data={episodes} sortBy={dailyItemSortOrder} onSortChange={setDailyItemOrder} />
            {hasMoreData && <InfiniteScroll fetchData={fetchFeedDetails} />}
          </div>
        )}
      </Section>
    </TabPage>
  );
}

const TabHeader = ({ title, image, description, status, handleDeleteDailySeries, onEditPlaylistButtonClick }) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleOpenMoreMenu = () => {
    setIsMoreMenuOpen((state) => !state);
  };

  return (
    <div className={styles.header}>
      <img className={styles.image} src={image} alt={title} />
      <div className={styles.info}>
        <div className={styles.titleWrap}>
          <Text className={styles.title}>{title}</Text>
        </div>
        <Text className={styles.description}>{description}</Text>
        <ContentStatus status={status} />
      </div>

      <div className={styles.actions}>
        <DropdownMenu
          isOpen={isMoreMenuOpen}
          onClose={toggleOpenMoreMenu}
          component={
            <Button variant={buttons.variant.secondary} onClick={toggleOpenMoreMenu}>
              •••
            </Button>
          }
        >
          <DropdownMenu.Item
            title="Edit series"
            onClick={() => {
              onEditPlaylistButtonClick();
            }}
          />
          <DropdownMenu.Item
            title="Delete"
            onClick={() => {
              toggleOpenMoreMenu();
              setIsDeleteModalOpen(true);
            }}
          />
        </DropdownMenu>
      </div>

      <DeletePlaylistModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeleteDailySeries();
          setIsDeleteModalOpen(false);
        }}
      />
    </div>
  );
};

const ContentTable = ({ data, sortBy, onSortChange }) => {
  const appRoutes = useAppRoutes();

  const redirectUserToDailyItemForm = (dailyItem) => {
    const { series_id: dailySeriesId, id: dailyItemId } = dailyItem;
    window.open(appRoutes.dailySeriesIdDailyItemsId(dailySeriesId, dailyItemId), '_blank'); // Open in a new tab
  };

  return (
    <Table sortable sortBy={sortBy} rowCount={data.length} rowActions={null} onSortChange={onSortChange}>
      <Thead>
        <Tr>
          <Th field="reference">Name</Th>
          <Th field="scheduled_at">Scheduled</Th>
          <Th field="Artist.name">Artist</Th>
          <Th field="destroyed">Status</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item) => (
          <Tr key={item.id} className="cursor-pointer" onClick={() => redirectUserToDailyItemForm(item)}>
            <Td width="55%">
              <ContentItem
                image={item.primary_image_url || item.thumbnail_image_url}
                fallbackImage={audioIcon}
                title={item.title}
                description={item.description}
              />
            </Td>
            <Td width="25%">
              <Text variant={typography.subhead_small}>{formatDateUsingShorterMonthAndTime(item.published_at)}</Text>
            </Td>
            <Td width="15%">
              <Text variant={typography.subhead_small}>{item?.artist?.name || 'Artist not found'}</Text>
            </Td>
            <Td width="5%">
              <ContentStatus status={item.is_published ? 'published' : 'draft'} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
