import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type UseCreateEpisodeProps = {
  artistId: string;
  courseId: string;
};

/**
 * Hook for creating a new course episode.
 *
 * @param artistId - The ID of the artist who owns the course
 * @param courseId - The ID of the course containing the episode
 * @returns A mutation object for creating an episode
 */
export default function useCreateEpisode({ artistId, courseId }: UseCreateEpisodeProps) {
  const queryClient = useQueryClient();

  const createEpisode = async () => {
    const response = await studioService.courses.createEpisode({
      artistId,
      courseId,
    });

    return response.data;
  };

  const mutation = useMutation({
    mutationFn: createEpisode,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistCourseEpisodes(artistId, courseId),
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistCourseEpisode(artistId, courseId, data.id),
      });
    },
  });

  return mutation;
}
