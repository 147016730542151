import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDeleteEmailTemplate() {
  const queryClient = useQueryClient();

  async function deleteEmailTemplate({ artistId = undefined, templateId = undefined }) {
    const response = await studioService.emailTemplates.deleteEmailTemplate({ artistId, templateId });

    return response.data;
  }

  const mutation = useMutation({
    mutationFn: deleteEmailTemplate,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistEmailTemplates(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteEmailTemplate: mutateAsync,
    ...rest,
  };
}
