import { DateTime } from 'luxon';
import { useEffect } from 'react';

export function TimeIndicator({ date, time }) {
  const now = DateTime.now();
  const isToday = date.hasSame(now, 'day');
  const isCurrentTime = isToday && time.hasSame(now, 'hour');
  const elementId = `time-indicator-${now.toISO()}`;
  const top = `${(now.minute / 60) * 100}%`;

  useEffect(() => {
    if (isCurrentTime) {
      const element = document.getElementById(elementId);
      element.scrollIntoView({ block: 'center' });
    }
  }, []);

  if (!isCurrentTime) return null;

  return (
    <div id={elementId} className="absolute z-[99] w-full" style={{ top }}>
      <div className="absolute -left-2 ml-px size-3 rounded-full bg-[#E71D20]" />
      <div className="absolute left-0 top-1 mt-px h-0.5 w-full bg-[#E71D20]" />
    </div>
  );
}
