import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Thead, Tr, Th } from '@pray/shared/components/ui/Table';

export default function ContentTableSkeleton({ rows = 8 }) {
  return (
    <Table key="content-table-skeleton" sortable={false} rowCount={rows}>
      <Thead>
        <Tr>
          <Th field="col" className="w-4/12">
            <Loading height={20} isLight width={70} borderRadius="6px" />
          </Th>
          <Th field="col" className="w-2/12">
            <Loading height={20} isLight width={58} borderRadius="6px" />
          </Th>
          <Th field="col" className="w-3/12">
            <Loading height={20} isLight width={68} borderRadius="6px" />
          </Th>
          <Th field="col" className="w-2/12" />
        </Tr>
        {[...Array(rows)].map((_, i) => (
          <Tr key={i}>
            <Th field="col" className="w-4/12 max-w-md ">
              <Loading height={24} isLight width={102} borderRadius="6px" />
            </Th>
            <Th field="col" className="w-2/12 max-w-md ">
              <Loading height={24} isLight width={52} borderRadius="6px" />
            </Th>
            <Th field="col" className="w-3/12 max-w-md ">
              <Loading height={24} isLight width={96} borderRadius="6px" />
            </Th>
            <Th field="col" className="w-2/12 max-w-md ">
              <Loading height={24} isLight width={76} borderRadius="6px" />
            </Th>
          </Tr>
        ))}
      </Thead>
    </Table>
  );
}
