import React from 'react';

import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useDonationPayouts from 'hooks/donations/useDonationPayouts';

import PayoutsEmpty from './PayoutsEmpty';
import PayoutsTable from './PayoutsTable';
import DonationModuleDialog from '../../DonationModuleDialog/DonationModuleDialog';

export default function PayoutsPage() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const { data, isLoading, hasNextPage, fetchNextPage } = useDonationPayouts({ artistId });

  const payouts = data?.pages.flatMap((page) => page.data) || [];

  return (
    <TabPage title="Bank Transfer" data-viewname={VIEW_EVENT_NAMES.DONATIONS.BANK_TRANSFERS}>
      {(() => {
        if (isLoading) return null;

        if (!payouts?.length) return <PayoutsEmpty />;

        return <PayoutsTable data={payouts} {...{ hasNextPage, fetchNextPage }} />;
      })()}
      <DonationModuleDialog />
    </TabPage>
  );
}
