import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';

import TagDetails from './TagDetails/TagDetails';
import TagList from './TagList/TagList';

export default function TagsTab(props) {
  return (
    <MainLayout data-viewname="PRAY Studio - Tags Tab">
      <Routes>
        <Route index element={<TagList {...props} />} />
        <Route path=":id" element={<TagDetails {...props} />} />
      </Routes>
    </MainLayout>
  );
}
