import { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Check } from '@pray/shared/components/ui/Icons/Check';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { BookProcessStatus } from '@pray/shared/services/studioService/books/types';

import GenerateAudioModal from './GenerateAudioModal';

import { AiStars, Close, VoiceWarning } from '@/images/icons';

type GenerateAudioBannerProps = {
  audiobookGenerationStatus: BookProcessStatus;
  audiobookGenerationUpdatedAt: string;
  lastSavedAt: string;
  artistId: string;
  bookId: string;
};

type BannerBaseProps = {
  icon: React.ReactNode;
  message: string;
  action?: React.ReactNode;
  variant?: 'warning' | 'success' | 'featured';
};

export default function GenerateAudioBanner({
  audiobookGenerationStatus,
  audiobookGenerationUpdatedAt,
  lastSavedAt,
  artistId,
  bookId,
}: GenerateAudioBannerProps) {
  const [isCloseBanner, setIsCloseBanner] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderModal = () => (
    <GenerateAudioModal
      isOpen={isModalOpen}
      artistId={artistId}
      onClose={() => setIsModalOpen(false)}
      bookId={bookId}
    />
  );

  if (audiobookGenerationStatus === BookProcessStatus.NOT_STARTED) {
    return (
      <>
        <BannerBase
          icon={<AiStars className="text-[#E3AF4A]" />}
          message="Use PRAY AI to Generate the Audio Book from your book transcript"
          action={
            <Button
              variant={buttons.variant.primary}
              color={colors.pray_featured}
              size={buttons.size.xsmall}
              className="!text-black"
              onClick={() => setIsModalOpen(true)}
            >
              Generate Audio
            </Button>
          }
        />
        {renderModal()}
      </>
    );
  }

  if (audiobookGenerationStatus === BookProcessStatus.PROCESSING) {
    return <BannerBase icon={<Spinner color="gold" />} message="Your audio book is being generated..." />;
  }

  if (audiobookGenerationStatus === BookProcessStatus.FAILED) {
    return (
      <>
        <BannerBase
          icon={<VoiceWarning />}
          message="Failed to generate audio book"
          action={
            <Button variant={buttons.variant.primary} size={buttons.size.xsmall} onClick={() => setIsModalOpen(true)}>
              Retry
            </Button>
          }
          variant="warning"
        />
        {renderModal()}
      </>
    );
  }

  const isOutdated =
    audiobookGenerationUpdatedAt && lastSavedAt && new Date(audiobookGenerationUpdatedAt) <= new Date(lastSavedAt);

  if (audiobookGenerationStatus === BookProcessStatus.COMPLETED && !isOutdated && !isCloseBanner) {
    return (
      <BannerBase
        icon={<Check color="#048848" />}
        message="Your audio book is generated!"
        action={
          <Button onClick={() => setIsCloseBanner(true)}>
            <Close />
          </Button>
        }
        variant="success"
      />
    );
  }

  if (audiobookGenerationStatus === BookProcessStatus.COMPLETED && isOutdated) {
    return (
      <>
        <BannerBase
          icon={<AiStars className="text-[#E3AF4A]" />}
          message="You've updated the transcript, but audio remain unchanged. Update it now to keep them aligned."
          action={
            <Button
              variant={buttons.variant.primary}
              color={colors.pray_featured}
              size={buttons.size.xsmall}
              className="!text-black"
              onClick={() => setIsModalOpen(true)}
            >
              Update Audio
            </Button>
          }
        />
        {renderModal()}
      </>
    );
  }

  return null;
}

function BannerBase({ icon, message, action, variant = 'featured' }: BannerBaseProps) {
  const variantStyles = {
    warning: 'border-[#AA0E0F] bg-[#FEF0F0]',
    success: 'border-[#0488481A] bg-[#0488481A]',
    featured: 'border-[#F9CE8E] bg-[#FDF2E5]',
  };

  return (
    <div
      className={`flex min-h-[70px] items-center justify-between gap-4 rounded-md border ${variantStyles[variant]} p-4`}
    >
      <div className="flex items-center gap-4">
        {icon}
        <Text className="!font-medium" variant={typography.body_lg}>
          {message}
        </Text>
      </div>
      {action}
    </div>
  );
}
