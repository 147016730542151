import React, { useEffect, useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover/Popover';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { useContentStudyGuide } from 'hooks/contentStudyGuide/useContentStudyGuide';

import ButtonCluster from '../../../../components/ButtonCluster';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import { useToastMessage } from '../../../../components/StudioToastMessage';
import TabPage from '../../../../components/TabPage/TabPage';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';
import { useCopyStudyGuide } from './actions/useCopyStudyGuide';
import { useDownloadStudyGuide } from './actions/useDownloadStudyGuide';
import { useGenerateStudyGuide } from './actions/useGenerateStudyGuide';
import { useSaveStudyGuide } from './actions/useSaveStudyGuide';
import { StudyGuideLoading } from './StudyGuideLoading';

export const STUDY_GUIDE_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

export function StudyGuide({ onWorkflowAIStarted, artist, content, locale, setContent }) {
  const toast = useToastMessage();
  const [studyGuide, setStudyGuide] = useState(null);
  const { data, isLoading, error } = useContentStudyGuide(artist.id, content.id, locale);

  const { generateStudyGuide, isProcessing, isRegenerating } = useGenerateStudyGuide({
    artist,
    content,
    locale,
    setStudyGuide,
    onWorkflowAIStarted,
  });

  const { saveStudyGuide, isSaving } = useSaveStudyGuide({
    artist,
    content,
    locale,
    setStudyGuide,
  });

  const { downloadStudyGuide } = useDownloadStudyGuide({
    content,
  });

  const { copyStudyGuide } = useCopyStudyGuide();

  useEffect(() => {
    if (data?.studyGuide) {
      setStudyGuide(data.studyGuide);
    }
  }, [data]);

  useEffect(() => {
    if (studyGuide) {
      setContent((prevContent) => ({
        ...prevContent,
        studyGuide,
      }));
    }
  }, [studyGuide, setContent]);

  useEffect(() => {
    if (error) {
      toast.show({ error: 'Failed to fetch study guide' });
    }
  }, [error]);

  const renderTopRightButtons = () => {
    if (!content) return null;
    return (
      <div className="sm-hide md-show flex gap-4">
        <Button
          variant={buttons.variant.primary}
          disabled={isRegenerating}
          onClick={() => generateStudyGuide(true)}
          loading={isRegenerating}
          className="m-0 !border-transparent !bg-[#7C32E6] px-3 py-2 text-xs"
        >
          Regenerate
        </Button>
        <ButtonCluster text="Save" onClick={() => saveStudyGuide(studyGuide)} loading={isSaving}>
          <DropdownMenu.Item title="Copy" onClick={() => copyStudyGuide(studyGuide)} />
          <DropdownMenu.Item title="Download" onClick={() => downloadStudyGuide(studyGuide)} />
        </ButtonCluster>
      </div>
    );
  };

  if (isLoading) {
    return <StudyGuideLoading />;
  }

  if (!studyGuide && !isLoading) {
    return (
      <WorkflowAIEmptyState
        isProcessing={isProcessing}
        content={content}
        handleWorkflowAIButton={() => generateStudyGuide(false)}
        title="Study Guide"
        description="Create and manage study guides generated from your content"
      />
    );
  }

  return (
    <TabPage
      title="Study Guide"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_STUDY_GUIDE}
      topRightButtons={renderTopRightButtons()}
      titleInfo={
        <Popover title="Study Guide" description="Create and manage study guides generated from your content">
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-full max-w-none px-8">
        <RichTextEditor
          content={studyGuide}
          onChange={setStudyGuide}
          className="min-h-[300px] focus:bg-black/[0.01] focus:outline-none"
          toolbarClassName="top-[75px]"
        />
      </div>
    </TabPage>
  );
}
