import React, { useState } from 'react';

import { colors, typography, buttons } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

import { EmailDialogModal } from './EmailDialogModal';

export default function RenameTemplateModal({ name = '', onCancel, onSave, isLoading = false }) {
  const [templateName, setTemplateName] = useState(name);
  const [error, setError] = useState('');

  const dialogButtons = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: handleCancel, disabled: isLoading },
    { label: 'Save', variant: buttons.variant.primary, action: handleSave, disabled: isLoading, loading: isLoading },
  ];

  function handleInputChange(event) {
    setTemplateName(event.target.value);
    setError('');
  }

  function handleSave() {
    if (!templateName) {
      setError('Please enter a title');
      return;
    }

    onSave(templateName);
    setTemplateName(null);
    setError('');
  }

  function handleCancel() {
    setTemplateName(null);
    onCancel();
    setError('');
  }

  return (
    <EmailDialogModal
      title="Rename Email Template"
      description="Change the name of this email template"
      buttons={dialogButtons}
      onClose={handleCancel}
      isLoading={isLoading}
    >
      <div className="flex h-auto flex-col gap-1">
        <TextInput
          label="Template name"
          placeholder="Please enter template name"
          value={templateName}
          onChange={handleInputChange}
          required
        />
        <Text variant={typography.caption_medium} color={colors.attention}>
          {error}
        </Text>
      </div>
    </EmailDialogModal>
  );
}
