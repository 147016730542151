import type { GetMappingsResponse } from '../getMappings';

export type LeadListMapping = {
  header: string;
  guess: string;
  presentationName: string;
};

export function leadListsMappingMapper(data: GetMappingsResponse): LeadListMapping[] {
  return data?.data.map((mapping) => ({
    header: mapping.header,
    guess: mapping.guess,
    presentationName: mapping.presentation_name,
  }));
}
