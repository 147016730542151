import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React from 'react';
import { useParams } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { SENDBIRD_APP_ID } from 'config.js';
import { useStudioContext } from 'context/StudioContext';

import LeaderFollowerChat from './LeaderFollowerChat';
import TabPage from '../../components/TabPage/TabPage';

import '@sendbird/uikit-react/dist/index.css';
import './sendbird-uikit-custom.scss';

export default function ChatsTab() {
  const { userId } = useParams();
  const { selectedArtist } = useStudioContext();

  const artistId = selectedArtist?.id;

  if (!artistId) return null;

  const sendbirdUserIdentifier = `artist_${artistId}`;
  const nickname = selectedArtist.name;
  const profileUrl = selectedArtist.profile_image_url;

  return (
    <SendbirdProvider
      appId={SENDBIRD_APP_ID}
      userId={sendbirdUserIdentifier}
      nickname={nickname}
      profileUrl={profileUrl}
    >
      <MainLayout data-viewname="PRAY Studio - Chats Tab">
        <TabPage title="Chats">
          <LeaderFollowerChat artistId={artistId} userId={userId} />
        </TabPage>
      </MainLayout>
    </SendbirdProvider>
  );
}
