import api from '@pray/shared/utils/api';

export const getPromoUnit = async ({ artistId = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/artist-promo-unit`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
