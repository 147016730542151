import Text from '@pray/shared/components/ui/Text/Text';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';

export default function DeletePlaylistModal({ isOpen = false, onClose = null, onDelete = null }) {
  return (
    <DeleteContentModal
      isOpen={isOpen}
      title="Delete series"
      message="You are about to delete this series and its associated data. Are you sure you want to continue?"
      extraMessage={<DeleteWarning title="Warning:" message="This action is not reversible." />}
      deleteButtonLabel="Delete series"
      onClose={onClose}
      onDelete={onDelete}
    />
  );
}

const DeleteWarning = ({ title = '', message = '' }) => (
  <div className="mt-6 flex gap-1 rounded-md bg-[#FCDEDE] p-4 text-[#670405]">
    <Text className="font-bold">{title}</Text>
    <Text>{message}</Text>
  </div>
);
