import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDeleteLeadList({ artistId }) {
  const queryClient = useQueryClient();

  async function deleteLeadList({ listId, filters = {} }) {
    await studioService.leadLists.deleteLeadList(artistId, listId);
  }

  const mutation = useMutation({
    mutationFn: deleteLeadList,
    onMutate: async ({ listId, filters = {} }) => {
      const queryKey = queryKeys.leadLists(artistId, filters);

      await queryClient.cancelQueries({ queryKey });
      const previousLists = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old) => {
        if (!old) return old;

        return {
          ...old,
          pages: old.pages.map((page) => ({
            ...page,
            lists: page.lists.filter((list) => list.id !== listId),
          })),
        };
      });

      return { previousLists, filters };
    },
    onError: (_error, _variables, context) => {
      if (context?.previousLists) {
        queryClient.setQueryData(queryKeys.leadLists(artistId, context.filters), context.previousLists);
      }
    },
    onSettled: (_data, _error, { listId, filters = {} }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadListMetadata(artistId, listId),
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.leadLists(artistId, filters),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteLeadList: mutateAsync,
    ...rest,
  };
}
