import React from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { ChevronLeft, ChevronRight } from 'images/icons';

import DatePickerUtils from './date-picker-utils';

import styles from './PrayCalendarHeader.module.scss';

export default function PrayCalendarHeader({
  monthDate,
  decreaseMonth,
  increaseMonth,
  customHeaderCount,
  isDateRangePicker = false,
  prevMonthButtonDisabled = false,
  nextMonthButtonDisabled = false,
}) {
  const month = DatePickerUtils.months[DatePickerUtils.getMonth(monthDate)];
  const year = DatePickerUtils.getYear(monthDate);

  const isStartMonth = !isDateRangePicker || customHeaderCount === 0;
  const isEndMonth = !isDateRangePicker || customHeaderCount === 1;

  return (
    <div className={styles.calendarHeader}>
      {isStartMonth ? (
        <Button
          type="button"
          className={styles.chevronButtons}
          disabled={prevMonthButtonDisabled}
          onClick={decreaseMonth}
        >
          <ChevronLeft />
        </Button>
      ) : (
        <span />
      )}

      <div className={styles.monthYearWrapper}>
        <Text className={styles.headerMonthYear}>{month}</Text>
        <Text className={styles.headerMonthYear}>&nbsp;{year}</Text>
      </div>

      {isEndMonth ? (
        <Button
          type="button"
          className={styles.chevronButtons}
          disabled={nextMonthButtonDisabled}
          onClick={increaseMonth}
        >
          <ChevronRight />
        </Button>
      ) : (
        <span />
      )}
    </div>
  );
}
