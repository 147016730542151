import React, { useState } from 'react';

import { cn } from 'styles/utils';

import SolomonHeadline from './SolomonHeadline';
import SolomonInput from './SolomonInput';
import SolomonAnalysingLabel from './SolomonResponses/SolomonAnalysingLabel';
import SolomonErrorLabel from './SolomonResponses/SolomonErrorLabel';
import SolomonResponse from './SolomonResponses/SolomonResponse';
import useSolomon, { Steps } from './useSolomon';

export default function SolomonSection() {
  const {
    step,
    prompt,
    response,
    recentSearches,
    isLoading,
    isFetchingRecentSearches,
    setPrompt,
    setRecentSearches,
    onSubmit,
    onClear,
    onDownload,
    onCopy,
    onClose,
  } = useSolomon();
  const [isFocused, setIsFocused] = useState(false);

  const renderStep = () => {
    if (step === Steps.ANALYSING) {
      return <SolomonAnalysingLabel />;
    }

    if (step === Steps.ERROR) {
      return <SolomonErrorLabel />;
    }

    if (step === Steps.RESPONSE) {
      return (
        <SolomonResponse
          response={response}
          onCopy={onCopy}
          onDownload={onDownload}
          onRefresh={() => onSubmit(prompt)}
          onClose={onClose}
        />
      );
    }

    return null;
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-8 rounded-2xl border bg-[#FCFCFC] p-6 hover:border-[#8854F2]',
        isFocused ? 'border-[#8854F2]' : 'border-[#DEDFE3]'
      )}
    >
      <SolomonHeadline isLoading={isFetchingRecentSearches} />

      <SolomonInput
        value={prompt}
        recentSearches={recentSearches}
        isFocused={isFocused}
        isLoading={isFetchingRecentSearches}
        isDisabled={isLoading}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(event) => setPrompt(event.target.value)}
        onSubmit={onSubmit}
        onClear={onClear}
        onAddRecentSearch={(prompt) =>
          setRecentSearches((prevSearches) => {
            const filteredSearches = prevSearches.filter((search) => search.toLowerCase() !== prompt.toLowerCase());
            return [prompt, ...filteredSearches];
          })
        }
        onRemoveRecentSearch={(promptToRemove) => {
          setRecentSearches(recentSearches.filter((prompt) => prompt !== promptToRemove));
        }}
        onPromptSelected={(value) => {
          setPrompt(value);
          onSubmit(value);
          setIsFocused(false);
        }}
      />

      {renderStep()}
    </div>
  );
}
