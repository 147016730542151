import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import styles from './MessageModal.module.scss';

export default function MessageModal({
  title,
  message,
  isOpen,
  isLoading = false,
  primaryButtonLabel,
  secondaryButtonLabel = null,
  onPrimaryButtonClick,
  onSecondaryButtonClick = null,
  onClose,
}) {
  if (!isOpen) return null;

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{title}</Text>
      <Text className={styles.message}>{message}</Text>

      <div className={styles.buttons}>
        {secondaryButtonLabel && (
          <Button disabled={isLoading} variant={buttons.variant.secondary} onClick={onSecondaryButtonClick}>
            {secondaryButtonLabel}
          </Button>
        )}
        <Button disabled={isLoading} variant={buttons.variant.primary} onClick={onPrimaryButtonClick}>
          {isLoading ? <Spinner color="black" /> : primaryButtonLabel}
        </Button>
      </div>
    </Modal>
  );
}
