import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useDebounce from '@pray/shared/hooks/useDebounce';

import useQueryParams from '../useQueryParams';

export const useLocaleFromQueryParams = () => {
  const { params } = useQueryParams();
  const navigate = useNavigate();
  const queryLocale = params.locale;

  const debouncedLocale = useDebounce(queryLocale, 500);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('locale', queryLocale);
    navigate(`?${params.toString()}`, { replace: false });
  }, [debouncedLocale, navigate]);

  return queryLocale;
};
