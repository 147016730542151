export default class DatePickerUtils {
  static get years() {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 2;
    const endYear = currentYear + 10;
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }

    return years;
  }

  static get months() {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
  }

  /**
   * @param {Date} date
   * @returns string
   */
  static getYear(date) {
    return date.getFullYear();
  }

  /**
   * @param {Date} date
   * @returns string
   */
  static getMonth(date) {
    return date.getMonth();
  }
}
