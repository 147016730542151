import { DateTime } from 'luxon';

import { PRAY_ARTIST_NAME } from 'constants.js';

import CalendarTimeNowIndicator from './CalendarTimeNowIndicator';

import styles from './EventsCalendar.module.scss';

export default function CalendarHourItem({ date, hour, events }) {
  const navigateToEditDailyItem = (event, dailyItem) => {
    event.cancelBubble = true;
    event.stopPropagation();
    window.open(`/dailies/daily-series/${dailyItem.daily_series_id}/daily-items/${dailyItem.id}`, '_blank');
  };

  const getEventTitle = (event) => {
    const defaultDailyVideoTitle = 'Video Daily';
    const dailyTitle = event.title || event.reference || (event.video_url ? defaultDailyVideoTitle : '');

    if (event.is_all_day) {
      return (
        <p className={styles.calendarTableEventItemText}>
          {event.daily_series_name} - {dailyTitle}
        </p>
      );
    }

    // Event time will be displayed in the format of 12:00 AM
    const eventTime = DateTime.fromISO(event.scheduled_at).toFormat('h:mm a');

    return (
      <p className={styles.calendarTableEventItemText}>
        {eventTime} &nbsp; &nbsp; {event.daily_series_name} &middot; {dailyTitle}
      </p>
    );
  };

  const getHour = (hour) => {
    if (hour === -1) return 'All-day';

    const date = new Date();
    date.setHours(hour);

    return date.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true });
  };

  return (
    <div className={styles.calendarTableRowItem}>
      <CalendarTimeNowIndicator date={date} hour={hour} />
      <div className={styles.calendarHourTableRowLabel}>{getHour(hour)}</div>
      <div className={styles.wrapperTd}>
        {events.map((dailyItem) => {
          return (
            <div
              onClick={(event) => navigateToEditDailyItem(event, dailyItem)}
              key={dailyItem.id}
              role="presentation"
              className={styles.calendarTableEventItem}
              style={{
                background: `url('${dailyItem.background_image_url}'), #CBCBCB`,
                backgroundSize: 'cover',
                ...(dailyItem.styles || {}),
                ...(dailyItem.spansIntoHour && {
                  opacity: 0,
                }),
              }}
            >
              {getEventTitle(dailyItem)}
              {dailyItem?.artist_name !== PRAY_ARTIST_NAME && (
                <span className={styles.calendarTableEventItemArtistBubble}>
                  <img
                    src={dailyItem?.artist_profile_image_url}
                    alt={`Daily by ${dailyItem?.artist_name}`}
                    title={`Daily by ${dailyItem?.artist_name}`}
                  />
                </span>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
