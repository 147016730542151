import React from 'react';

function PauseIcon(props) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 32C0 14.3269 14.3269 0 32 0C49.6731 0 64 14.3269 64 32C64 49.6731 49.6731 64 32 64C14.3269 64 0 49.6731 0 32Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M27.1669 22.6665C28.3454 22.6665 28.9347 22.6665 29.3008 23.0326C29.6669 23.3987 29.6669 23.988 29.6669 25.1665C29.6669 29.7221 29.6669 34.2776 29.6669 38.8332C29.6669 40.0117 29.6669 40.6009 29.3008 40.9671C28.9347 41.3332 28.3454 41.3332 27.1669 41.3332H26.1669C24.9884 41.3332 24.3991 41.3332 24.033 40.9671C23.6669 40.601 23.6669 40.0117 23.6669 38.8332C23.6668 34.2774 23.6665 29.7215 23.6674 25.1657C23.6676 23.9876 23.6678 23.3986 24.0339 23.0325C24.4 22.6665 24.9891 22.6665 26.1673 22.6665H27.1669ZM37.8336 22.6665C39.0121 22.6665 39.6013 22.6665 39.9675 23.0326C40.3336 23.3987 40.3336 23.988 40.3336 25.1665C40.3336 29.7221 40.3336 34.2776 40.3336 38.8332C40.3336 40.0117 40.3336 40.6009 39.9675 40.9671C39.6013 41.3332 39.0121 41.3332 37.8336 41.3332H36.8336C35.6551 41.3332 35.0658 41.3332 34.6997 40.9671C34.3336 40.6009 34.3336 40.0117 34.3336 38.8332C34.3336 34.2776 34.3336 29.7221 34.3336 25.1665C34.3336 23.988 34.3336 23.3987 34.6997 23.0326C35.0658 22.6665 35.6551 22.6665 36.8336 22.6665H37.8336Z"
        fill="white"
      />
    </svg>
  );
}

export default PauseIcon;
