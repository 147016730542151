import { useState } from 'react';
import { lookup } from 'reverse-geocode';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';

import USAudienceMap, { getStateCode } from 'components/pages/StudioPage/components/USAudienceMap/USAudienceMap';
import { US_STATES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import { Anonymous, Info } from 'images/icons';

export function AudienceMap({ data, isLoading, isError, refetch }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { totalReach = 0, reachByLocation = [] } = data || {};
  const { selectedArtist } = useStudioContext();
  const avatar = selectedArtist?.profile_image_url || '';

  // Get leader state
  const selectedArtistState = lookup(selectedArtist?.latitude, selectedArtist?.longitude, 'us')?.state_abbr;
  const leaderState = selectedArtistState && US_STATES[selectedArtistState] ? selectedArtistState : 'KS';

  // Aggregate audience data by state
  const audience = Object.values(
    reachByLocation?.reduce((acc, { state, count }) => {
      const stateCode = getStateCode(state);
      if (!stateCode) return acc;
      if (!acc[stateCode]) {
        acc[stateCode] = { state, count: 0, coords: US_STATES[stateCode]?.coords };
      }
      acc[stateCode].count += count;
      return acc;
    }, {})
  );

  function leaderImage() {
    if (!avatar) return <Anonymous />;
    return <img src={avatar} alt="avatar" className="size-12 rounded-full border-4 border-solid border-[#E3AF4A80]" />;
  }

  return (
    <div className="flex flex-col gap-2">
      <Loading isLight isLoading={isLoading} width={170} height={24} margin="0">
        <div className="flex items-center gap-2">
          {leaderImage()}
          <Text variant={typography.heading_xl}>{selectedArtist?.name}</Text>
          <Button className="p-0 text-[#00000099]" onClick={() => setIsDialogOpen(true)}>
            <Info className="size-5" />
          </Button>
        </div>
      </Loading>

      <USAudienceMap
        data={{
          artist: {
            state: leaderState,
            avatar,
          },
          audience,
        }}
        isLoading={isLoading || isError}
      />

      <div className="flex flex-col gap-1">
        {!isError && (
          <>
            <Loading isLight isLoading={isLoading} width={170} height={24} margin="0">
              <Text variant={typography.heading_md} className="text-[#3E3E3E]">
                Total Reach
              </Text>
            </Loading>
            <Loading isLight isLoading={isLoading} width={60} margin="0">
              <Text variant={typography.heading_3xl} className="text-[#3E3E3E]">
                {totalReach.toLocaleString()}
              </Text>
            </Loading>
          </>
        )}

        {isError && (
          <Text variant={typography.body_md} color={colors.text_tertiary} className="!font-medium">
            Engagement data is temporarily unavailable
          </Text>
        )}
      </div>

      {isDialogOpen && (
        <Dialog>
          <div className="flex flex-col gap-6">
            <Text variant={typography.heading_2xl}>Your reach map</Text>
            <Text color={colors.text_secondary}>
              This map displays the total reach of your content across the United States since you started using our
              platform. It highlights the regions where your content has been engaged with, giving you a visual
              representation of your nationwide impact. The numbers shown are cumulative, updated daily, to reflect all
              interactions over time.
            </Text>
            <div className="flex flex-row justify-end">
              <Button variant={buttons.variant.primary} onClick={() => setIsDialogOpen(false)}>
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
