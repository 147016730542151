export default function BulletList({ color = 'white' }) {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.6 3.2C2.48366 3.2 3.2 2.48366 3.2 1.6C3.2 0.716344 2.48366 0 1.6 0C0.716344 0 0 0.716344 0 1.6C0 2.48366 0.716344 3.2 1.6 3.2Z"
        fill={color}
      />
      <path
        d="M1.6 9.60039C2.48366 9.60039 3.2 8.88405 3.2 8.00039C3.2 7.11673 2.48366 6.40039 1.6 6.40039C0.716344 6.40039 0 7.11673 0 8.00039C0 8.88405 0.716344 9.60039 1.6 9.60039Z"
        fill={color}
      />
      <path
        d="M1.6 16.0008C2.48366 16.0008 3.2 15.2844 3.2 14.4008C3.2 13.5171 2.48366 12.8008 1.6 12.8008C0.716344 12.8008 0 13.5171 0 14.4008C0 15.2844 0.716344 16.0008 1.6 16.0008Z"
        fill={color}
      />
      <path d="M6.40002 1.59961H16" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M6.40002 8H16" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" />
      <path d="M6.40002 14.4004H16" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="square" />
    </svg>
  );
}
