import api from '@pray/shared/utils/api';

export async function getPayoutTransactions({
  artistId = '',
  payoutId = '',
  offset = '',
  limit = '',
  downloadCsv = false,
}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!payoutId) throw new Error('Payout ID is required');

  const params = {};

  if (offset) params.offset = offset;
  if (limit) params.limit = limit;

  const options = {};

  if (downloadCsv) {
    params.download_csv = true;
    options.responseType = 'blob';
  }

  const url = `/web/studio/artists/${artistId}/payouts/${payoutId}/transactions`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
}
