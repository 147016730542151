import React, { useState } from 'react';

import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import EventsCalendar from 'components/pages/StudioPage/components/EventsCalendar/EventsCalendar';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';

function DailiesList() {
  const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false);

  const renderTopRightButtons = () => {
    return (
      <div className="flex space-x-4">
        <DropdownMenu
          isOpen={isCreateMenuOpen}
          onClose={() => setIsCreateMenuOpen((state) => !state)}
          component={<ActionButton text="Create" onClick={() => setIsCreateMenuOpen((state) => !state)} />}
        >
          <DropdownMenu.Item to="/dailies/create/rss" title="Daily Series (RSS)" />
          <DropdownMenu.Item to="/dailies/create" title="Daily" />
        </DropdownMenu>
      </div>
    );
  };

  return (
    <TabPage title="Dailies" topRightButtons={renderTopRightButtons()}>
      <div>
        <EventsCalendar />
      </div>
    </TabPage>
  );
}

export default DailiesList;
