import api from '@pray/shared/utils/api';

export async function addPromoUnit({
  artistId,
  header = '',
  description = '',
  imageUrl = '',
  ctaUrl = '',
  ctaText = '',
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!header) throw Error('Header is required');
  if (!description) throw Error('Description is required');
  if (!imageUrl) throw Error('Image URL is required');
  if (!ctaUrl) throw Error('CTA URL is required');
  if (!ctaText) throw Error('CTA Text is required');

  const data = {
    header,
    description,
    cta_url: ctaUrl,
    cta_text: ctaText,
    image_url: imageUrl,
  };

  const url = `/web/studio/artists/${artistId}/artist-promo-unit`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
