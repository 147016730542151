import React, { useRef } from 'react';

import { typography, colors } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import useClickAway from '@pray/shared/hooks/useClickAway';

import { useUserNotifications } from './useUserNotifications';

export default function UserNotifications() {
  const containerRef = useRef(null);
  const { notifications, isLoading, isOpen, setIsOpen, onNotificationClicked } = useUserNotifications();

  useClickAway(containerRef, () => setIsOpen(false));

  return (
    <div ref={containerRef} className="relative">
      <Button data-tapeventname="User Notifications" className="translate-y-1 p-0" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <BellIconOn className="size-5" /> : <BellIconOff className="size-5" />}
        <NotificationCount count={notifications?.length} />
      </Button>
      {isOpen && (
        <div className="absolute right-0 top-11 w-96 rounded-lg bg-white shadow-lg">
          {isLoading && <LoadingNotifications />}
          {!isLoading && <Notifications data={notifications} onClick={onNotificationClicked} />}
        </div>
      )}
    </div>
  );
}

function Notifications({ data, onClick }) {
  if (data.length === 0) {
    return (
      <Notification
        title="Nothing New to See"
        message="You're all caught up! Check back later for updates or alerts tailored just for you."
        icon={<SuccessIcon />}
      />
    );
  }

  return (
    <div className="max-h-[520px] overflow-y-auto">
      {data.map((notification) => (
        <Notification
          key={notification.id}
          title={notification.title}
          message={notification.message}
          icon={notification.icon}
          onClick={() => onClick(notification)}
        />
      ))}
    </div>
  );
}

function Notification({ title, message, icon, onClick = null }) {
  return (
    <div
      role="none"
      className="flex cursor-pointer gap-4 p-4 text-left normal-case first:rounded-t-xl last:rounded-b-xl hover:bg-[#F2F3F4]"
      onClick={onClick}
    >
      <div className="size-10">{icon}</div>
      <div className="flex flex-col">
        <Text variant={typography.heading_md} className="!text-black">
          {title}
        </Text>
        <Text variant={typography.body_md} color={colors.text_tertiary} className="line-clamp-3 !font-normal">
          {message}
        </Text>
      </div>
    </div>
  );
}

function LoadingNotifications() {
  return (
    <div>
      <LoadingNotification />
      <LoadingNotification />
      <LoadingNotification />
    </div>
  );
}

function LoadingNotification() {
  return (
    <div className="flex items-center gap-4 p-4">
      <Loading isLight width={40} height={40} borderRadius="100%" />
      <div className="flex flex-1 justify-between gap-2">
        <div className="flex flex-col gap-2">
          <Loading isLight width={76} height={16} margin="0" />
          <Loading isLight width={182} height={16} margin="0" />
        </div>
        <Loading isLight width={40} height={16} margin="0" />
      </div>
    </div>
  );
}

function NotificationCount({ count }) {
  if (!count) return null;

  return (
    <div className="absolute -right-3 -top-3 flex size-[22px] items-center justify-center rounded-full border-2 border-black bg-red-500 p-1">
      <Text className="text-[10px] font-bold text-white">{count < 10 ? count : '9+'}</Text>
    </div>
  );
}

function BellIconOn(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.20702 8.94736C5.66097 5.5427 8.56521 3 12 3C15.4348 3 18.339 5.54271 18.793 8.94737L20 18H4L5.20702 8.94736Z"
        fill="white"
      />
      <path
        d="M16 18C15.3267 19.7659 13.7891 21 12 21C10.2109 21 8.67327 19.7659 8 18M20 18L18.793 8.94737C18.339 5.54271 15.4348 3 12 3C8.56521 3 5.66097 5.5427 5.20702 8.94736L4 18H20Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

function BellIconOff(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 18C15.3267 19.7659 13.7891 21 12 21C10.2109 21 8.67327 19.7659 8 18M20 18L18.793 8.94737C18.339 5.54271 15.4348 3 12 3C8.56521 3 5.66097 5.5427 5.20702 8.94736L4 18H20Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

function SuccessIcon(props) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#ECF9ED"
      />
      <path
        d="M23 17.5L18.5 23L16.5 21M29.25 20C29.25 25.1086 25.1086 29.25 20 29.25C14.8914 29.25 10.75 25.1086 10.75 20C10.75 14.8914 14.8914 10.75 20 10.75C25.1086 10.75 29.25 14.8914 29.25 20Z"
        stroke="#188149"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
