import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useContentPlayer } from '@pray/shared/components/ContentPlayer/hooks/useContentPlayer';
import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';
import studioService from '@pray/shared/services/studioService';
import { formatDuration } from '@pray/shared/utils';

import BackLinkButton from 'components/pages/StudioPage/components/BackLinkButton/BackLinkButton';
import ContentDateStatus from 'components/pages/StudioPage/components/ContentDateStatus/ContentDateStatus';
import ContentItem from 'components/pages/StudioPage/components/ContentItem/ContentItem';
import ContentStatus from 'components/pages/StudioPage/components/ContentStatus/ContentStatus';
import ContentVisibility from 'components/pages/StudioPage/components/ContentVisibility/ContentVisibility';
import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import { AUDIO_PLAYER, LOCALES, VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import audioIcon from '../../../assets/audio-icon.png';
import ButtonCluster from '../../../components/ButtonCluster';
import RSSLabel from '../../../components/RSSLabel/RSSLabel';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './RssFeedDetails.module.scss';

export default function RssFeedDetails() {
  const [isShowEpisodes, setIsShowEpisodes] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { id: rssFeedId } = useParams();
  const [episodes, setEpisodes] = useState([]);
  const [nextItem, setNextItem] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(false);

  const [rssFeed, setRssFeed] = useState({
    id: null,
    title: null,
    description: null,
    image_url: null,
    is_visible: false,
  });

  const fetchRssFeedDetails = async ({ replace = false, offset = nextItem, limit = 25 } = {}) => {
    if (offset && !hasMoreData) return;

    const response = await studioService.rssFeed.getRssFeedById({
      artistId,
      rssFeedId,
      limit,
      offset,
    });

    setRssFeed(response.data);

    setEpisodes((episodes) => {
      if (replace) return response.data.content;

      return [...episodes, ...response.data.content];
    });

    setIsShowEpisodes(true);
    setNextItem(response.next_item);

    if (!replace) {
      setHasMoreData(response.data.content.length === limit && response.next_item !== nextItem);
    }
  };

  const refetchRssFeedDetails = () => {
    return fetchRssFeedDetails({
      replace: true,
      offset: 0,
      limit: episodes.length,
    });
  };

  useEffect(() => {
    if (artistId) {
      fetchRssFeedDetails();
    }
  }, [artistId, rssFeedId]);

  useEffect(() => {
    if (episodes.length) refetchRssFeedDetails();
  }, []);

  const handleDeleteRssFeed = async () => {
    await studioService.rssFeed.deleteRssFeed({
      artistId,
      rssFeedId,
    });
    navigate(appRoutes.artistPlaylists(), { replace: true });
  };

  if (!rssFeed.id) return null;

  const onEditButtonClick = async () => {
    navigate(appRoutes.artistRssFeedDetailsForm(rssFeedId));
  };

  return (
    <TabPage data-viewname={VIEW_EVENT_NAMES.SERIES.RSS_SERIES}>
      <BackLinkButton to={appRoutes.artistPlaylists()} label="Series" />

      <TabHeader
        image={rssFeed.image_url}
        title={rssFeed.title}
        description={rssFeed.description}
        status={rssFeed.is_visible ? 'published' : 'private'}
        onDeleteButtonClick={() => setIsDeleteModalOpen(true)}
        onEditButtonClick={onEditButtonClick}
      />

      <Section className={styles.section}>
        <div className={styles.message}>
          <Info className={styles.icon} />
          <Text variant={typography.subhead_small}>
            New episodes added to your RSS feed will automatically be pulled in and published to Pray.com
          </Text>
        </div>

        {isShowEpisodes && episodes.length !== 0 && (
          <div className="mt-3">
            <ContentTable data={episodes} />
            {hasMoreData && <InfiniteScroll fetchData={fetchRssFeedDetails} />}
          </div>
        )}
      </Section>

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title="Delete this RSS Feed?"
        message="You are about to delete this RSS Feed and its associated data. This action cannot be undone. Are you sure you want to delete this RSS Feed?"
        deleteButtonLabel="Delete"
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteRssFeed}
      />
    </TabPage>
  );
}

const TabHeader = ({ title, image, description, status, onDeleteButtonClick, onEditButtonClick }) => {
  return (
    <div className={styles.header}>
      <img className={styles.image} src={image} alt={title} />
      <div className={styles.info}>
        <div className={styles.titleWrap}>
          <Text className={styles.title}>{title}</Text>
          <RSSLabel />
        </div>
        <Text className={styles.description}>{description}</Text>
        <ContentStatus status={status} />
      </div>
      <div className="flex items-center">
        <ButtonCluster text="Edit" onClick={onEditButtonClick}>
          <DropdownMenu.Item className="w-48" title="Delete RSS Series" onClick={onDeleteButtonClick} />
        </ButtonCluster>
      </div>
    </div>
  );
};

const ContentTable = ({ data }) => {
  const { playContent } = useContentPlayer();

  const handleItemClick = (content) => {
    playContent(content.id, {
      locale: LOCALES.DEFAULT,
      controlOptions: AUDIO_PLAYER.controlOptions,
    });
  };

  return (
    <Table sortable={false} rowCount={data.length}>
      <Thead>
        <Tr>
          <Th width="5%">#</Th>
          <Th width="35%" field="title">
            <Text data-tooltip-id="episode-tooltip" data-tooltip-place="top-start">
              Episode
            </Text>
            <ReactTooltip
              id="episode-tooltip"
              className={styles.customTooltip}
              place="top-start"
              arrowColor="transparent"
            >
              <Text className="heading_md text_primary font-medium">Episode</Text>
              <Text className="text_secondary text-sm font-medium">
                This number shows the episode's position in the series.
              </Text>
            </ReactTooltip>
          </Th>
          <Th width="15%" field="duration">
            <Text data-tooltip-id="duration-tooltip" data-tooltip-place="top">
              Duration
            </Text>
            <ReactTooltip
              id="duration-tooltip"
              className={styles.customTooltip}
              place="top-start"
              arrowColor="transparent"
            >
              <Text className="heading_md text_primary font-medium">Duration</Text>
              <Text className="text_secondary text-sm font-medium">Duration of the episode</Text>
            </ReactTooltip>
          </Th>
          <Th width="15%" field="is_published">
            <Text data-tooltip-id="visibility-tooltip" data-tooltip-place="top">
              Visibility
            </Text>
            <ReactTooltip
              id="visibility-tooltip"
              className={styles.customTooltip}
              place="top-start"
              arrowColor="transparent"
            >
              <Text className="heading_md text_primary font-medium">Visibility</Text>
              <Text className="text_secondary text-sm font-medium">
                You can click an episode’s visibility to change to public, private or schedule to release.
              </Text>
            </ReactTooltip>
          </Th>
          <Th width="20%" field="date">
            <Text data-tooltip-id="date-tooltip" data-tooltip-place="top">
              Date
            </Text>
            <ReactTooltip id="date-tooltip" className={styles.customTooltip} place="top-start" arrowColor="transparent">
              <Text className="heading_md text_primary font-medium">Date</Text>
              <Text className="text_secondary text-sm font-medium">
                You’ll see the upload date if your episode is private. If your episode is scheduled you’ll see the
                schedule date. If your episode is public you will see the published date.
              </Text>
            </ReactTooltip>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((item, index) => (
          <Tr key={item.id}>
            <Td width="5%" className="cursor-pointer">
              <div className="flex h-8 w-5 items-center justify-center">
                <Text className="block">{index + 1}</Text>
              </div>
            </Td>
            <Td width="35%" className="cursor-pointer pl-1">
              <ContentItem
                image={item.image_url}
                fallbackImage={audioIcon}
                title={item.title}
                description={item.description}
                onItemClick={() => handleItemClick(item)}
              />
            </Td>
            <Td width="15%" className="cursor-pointer pl-1.5">
              <Text variant="subhead_small">{formatDuration(item.duration ?? 0)}</Text>
            </Td>
            <Td width="15%" className="cursor-pointer pl-1.5">
              <Button className="px-0">
                <div className="flex items-center gap-2">
                  <ContentVisibility content={item} />
                </div>
              </Button>
            </Td>
            <Td width="20%" className="cursor-pointer">
              <ContentDateStatus content={item} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
