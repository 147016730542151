import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useStatementYearlyCounts(artistId) {
  async function fetchData() {
    const response = await studioService.givingPayments.getStatementYearlyCounts({
      artistId,
    });

    return response.data;
  }

  const query = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistGivingStatementYearlyCounts(artistId),
    queryFn: fetchData,
  });

  return query;
}
