import React from 'react';

import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';

import FilterSection from '../FilterSection';
import FilterSeries from '../Series/FilterSeries';
import FilterStatus from '../Status/FilterStatus';
import FilterType from '../Type/FilterType';

export default function FilterSections({
  fetchedSeries,
  filterSeries = [],
  filterStatuses = [],
  filterTypes = [],
  selectedSeries,
  selectedStatuses = [],
  selectedTypes = [],
  setSelectedSeries,
  setSelectedStatuses,
  setSelectedTypes,
}) {
  return (
    <div className="flex max-h-[460px] flex-col gap-4 overflow-y-auto p-4">
      {/* Types Section */}
      <FilterSection title="Types">
        {filterTypes.map((type) => (
          <FilterType
            key={type.id}
            id={type.id}
            title={type.title}
            bgColor={type.bgColor}
            borderColor={type.borderColor}
            setSelectedTypes={setSelectedTypes}
            selectedTypes={selectedTypes}
          />
        ))}
      </FilterSection>

      {/* Status Section */}
      <FilterSection title="Status">
        {filterStatuses.map((status) => (
          <FilterStatus
            key={status.id}
            id={status.id}
            title={status.title}
            textColor={status.textColor}
            bgColor={status.bgColor}
            setSelectedStatuses={setSelectedStatuses}
            selectedStatuses={selectedStatuses}
          />
        ))}
      </FilterSection>

      {/* Series Section */}
      <FilterSection title="Series" isColumn>
        {filterSeries.map((series) => (
          <FilterSeries
            id={series.id}
            key={series.id}
            title={series.name}
            image={series.image}
            setSelectedSeries={setSelectedSeries}
            selectedSeries={selectedSeries}
          />
        ))}
        {fetchedSeries.hasNextPage && <InfiniteScroll fetchData={fetchedSeries.fetchNextPage} />}
      </FilterSection>
    </div>
  );
}
