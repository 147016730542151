const queryKey = (...args) => args;

export default {
  // artists
  artists: (...args) => queryKey('artists', ...args),
  artist: (artistId) => queryKey('artists', artistId),

  // artist voices
  artistVoices: (artistId) => queryKey('artists', artistId, 'voices'),
  artistVoice: (artistId, voiceId) => queryKey('artists', artistId, 'voices', voiceId),

  // artist calendar
  artistCalendar: (artistId, ...args) => queryKey('artists', artistId, 'calendar', ...args),

  // artist content details
  artistContentDetails: (artistId, contentId) => queryKey('artists', artistId, 'content', contentId, 'details'),
  artistContentDetailsLocale: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'details', 'locale', locale),
  artistContentDetailsLocaleTranscriptMultiVoices: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'details', 'locale', locale, 'transcripts', 'multiVoices'),

  // artist content series
  artistContentSeries: (artistId) => queryKey('artists', artistId, 'contentSeries'),
  artistContentSeriesById: (artistId, contentSeriesId) =>
    queryKey('artists', artistId, 'contentSeries', contentSeriesId),
  artistPrayRadioTvContentSeries: (artistId) => queryKey('artists', artistId, 'contentSeries', 'prayRadioTv'),
  // artist content
  artistContent: (artistId, ...args) => queryKey('artists', artistId, 'content', ...args),

  // artist content social clips
  artistContentSocialClips: (artistId, contentId) => queryKey('artists', artistId, 'content', contentId, 'socialClips'),
  artistContentSocialClipById: (artistId, contentId, socialClipId) =>
    queryKey('artists', artistId, 'content', contentId, 'socialClips', socialClipId),

  // artist social posts
  artistSocialPosts: (artistId, ...args) => queryKey('artists', artistId, 'socialPosts', ...args),

  // artist daily serires
  artistDailySeries: (artistId, dailySeriesId) => queryKey('artists', artistId, 'dailySeries', dailySeriesId),
  artistDailySeriesCoverArts: (artistId, dailySeriesId) =>
    queryKey('artists', artistId, 'dailySeries', dailySeriesId, 'coverArts'),

  // artist announcements
  artistAnnouncements: (artistId) => queryKey('artists', artistId, 'announcements'),
  artistAnnouncement: (artistId, announcementId) => queryKey('artists', artistId, 'announcements', announcementId),
  artistAnnouncementWorkflowAI: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'locale', locale, 'announcement'),
  artistAnnouncementMetrics: (artistId, announcementId) =>
    queryKey('artists', artistId, 'announcements', announcementId, 'metrics'),

  // artist locales
  artistLocales: (artistId) => queryKey('artist', artistId, 'locales'),

  // artist funds
  artistFunds: (artistId) => queryKey('artist', artistId, 'funds'),
  artistFund: (artistId, fundId) => queryKey('artist', artistId, 'funds', fundId),

  // artist giving payments
  artistGivingPayments: (artistId, ...args) => queryKey('artists', artistId, 'givingPayments', ...args),
  artistGivingStatementYearlyCounts: (artistId) =>
    queryKey('artists', artistId, 'givingPayments', 'statementYearlyCounts'),

  // artist giving summary
  artistGivingSummary: (artistId, ...args) => queryKey('artists', artistId, 'givingSummary', ...args),

  // artist donations
  artistDonationPayouts: (artistId, ...args) => queryKey('artists', artistId, 'payouts', ...args),
  artistPayoutById: (artistId, payoutId, ...args) => queryKey('artists', artistId, 'payouts', payoutId, ...args),
  artistPayoutTransactions: (artistId, payoutId, ...args) =>
    queryKey('artists', artistId, 'payouts', payoutId, 'transactions', ...args),

  // artist email campaigns
  artistEmailCampaigns: (artistId, ...args) => queryKey('artists', artistId, 'emailCampaigns', ...args),
  artistEmailCampaign: (artistId, campaignId) => queryKey('artists', artistId, 'emailCampaigns', campaignId),
  artistEmailCampaignStatus: (artistId) => queryKey('artists', artistId, 'emailCampaigns', 'status'),
  artistEmailCampaignFromWorkflowAI: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'locale', locale, 'email'),

  // artist email templates
  artistEmailTemplates: (artistId, ...args) => queryKey('artists', artistId, 'emailTemplates', ...args),

  // artist followers
  artistFollowers: (artistId, ...args) => queryKey('artists', artistId, 'followers', ...args),
  artistFollowersMetadata: (artistId) => queryKey('artists', artistId, 'followers', 'metadata'),

  // artist testimonials
  artistTestimonials: (artistId, ...args) => queryKey('artists', artistId, 'testimonials', ...args),

  // artist periodic table
  artistPeriodicTable: (artistId) => queryKey('artists', artistId, 'periodicTable'),
  artistPeriodicTableSegments: (artistId, ...args) =>
    queryKey('artists', artistId, 'periodicTable', 'segments', ...args),

  // artist summary
  artistSummary: (artistId) => queryKey('artists', artistId, 'summary'),
  artistInstagramSummary: (artistId) => queryKey('artists', artistId, 'social', 'instagram'),
  artistYoutubeSummary: (artistId) => queryKey('artists', artistId, 'social', 'youtube'),

  // artist impact metrics
  artistImpactMetrics: (artistId) => queryKey('artists', artistId, 'impactMetrics'),

  // locales
  locales: () => queryKey('locales'),

  // funds
  fund: (fundId) => queryKey('funds', fundId),

  // lead campaigns
  leadCampaigns: (...args) => ['leadCampaigns', ...args],
  leadCampaign: (campaignId) => ['leadCampaigns', campaignId],

  // lead clients
  leadClients: (...args) => ['leadClients', ...args],
  leadClient: (clientId) => ['leadClients', clientId],
  leadClientIntegrations: () => ['leadClientIntegrations'],

  // ministries
  ministryMatch: (userId, latitude, longitude) =>
    queryKey('ministryMatch', 'userId', userId, 'latitude', latitude, 'longitude', longitude),

  // transcripts
  transcripts: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'locales', locale, 'transcripts'),

  artistConnectedSocialPlatforms: (artistId) => ['artist', artistId, 'socials', 'connected-platforms'],
  artistConnectAccountUrl: (artistId) => ['artist', artistId, 'socials', 'connect-account-url'],
  // onboarding
  onboardingSteps: () => queryKey('onboarding'),

  // email contact list
  leadLists: (artistId, filters) => queryKey('artists', artistId, 'leadLists', 'filters', filters),
  leadListId: (artistId, listId, filters) => queryKey('artists', artistId, 'leadList', listId, 'filters', filters),
  leadListIdMappings: (artistId, listId) => ['artists', artistId, 'leadLists', listId, 'mappings'],
  leadListMetadata: (artistId, listId) => queryKey('artists', artistId, 'leadList', listId, 'metadata'),
  leadListMappings: (headers) => ['leadLists', 'mappings', ...headers],
  createLeadList: (artistId) => ['artist', artistId, 'leadLists'],
  retryUploadLeadList: (artistId, listId) => ['artist', artistId, 'leadList', listId, 'upload'],

  // Add this if not already present
  artistContentArticle: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'locale', locale, 'article'),

  artistContentStudyGuide: (artistId, contentId, locale) =>
    queryKey('artists', artistId, 'content', contentId, 'locale', locale, 'study-guide'),

  // artist books
  artistBooks: (artistId) => ['artists', artistId, 'books'],
  artistBookDetails: (artistId, bookId) => ['artists', artistId, 'books', bookId],
  artistBookStatus: (artistId, bookId) => ['artists', artistId, 'books', bookId, 'status'],
  artistBookChapters: (artistId, bookId) => ['artists', artistId, 'books', bookId, 'chapters'],
  artistBookChapter: (artistId, bookId, chapterId) => ['artists', artistId, 'books', bookId, 'chapters', chapterId],
  artistBookChapterDraft: (artistId, bookId, chapterId) => [
    'artists',
    artistId,
    'books',
    bookId,
    'chapters',
    chapterId,
    'draft',
  ],
  artistBookChapterVersions: (artistId, bookId, chapterId) => [
    'artists',
    artistId,
    'books',
    bookId,
    'chapters',
    'versions',
    chapterId,
  ],
  artistBookAllChaptersVersions: (artistId, bookId) => ['artists', artistId, 'books', bookId, 'chapters', 'versions'],

  // courses
  artistCourses: (artistId) => queryKey('artists', artistId, 'courses'),
  artistCourse: (artistId, courseId) => queryKey('artists', artistId, 'courses', courseId),
  artistCourseEpisodes: (artistId, courseId) => queryKey('artists', artistId, 'courses', courseId, 'episodes'),
  artistCourseEpisode: (artistId, courseId, episodeId) =>
    queryKey('artists', artistId, 'courses', courseId, 'episodes', episodeId),
};
