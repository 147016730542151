import { useQuery } from '@tanstack/react-query';

import { LOCALES } from '@pray/shared/constants';
import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetArtistContentDetails(artistId, contentId, locale) {
  const fetchArtistContentDetails = async () => {
    if (locale && locale !== LOCALES.DEFAULT) {
      const response = await studioService.contentTranslation.getLocalizedContentDetails({
        artistId,
        contentId,
        locale,
      });

      return response.data;
    }

    const response = await studioService.content.getArtistContentDetails({
      artistId,
      contentId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId && !!contentId,
    queryKey: queryKeys.artistContentDetailsLocale(artistId, contentId, locale),
    queryFn: fetchArtistContentDetails,
    refetchOnWindowFocus: true,
  });

  return query;
}
