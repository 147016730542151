import React, { useRef, useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import useClickAway from '@pray/shared/hooks/useClickAway';

export default function MoreActionsDropdown({
  component,
  children,
}: {
  component?: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
}) {
  const menuRef = useRef(null);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const toggleOpenMoreMenu = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  };

  const handleBlur = (event) => {
    const menu = menuRef.current;
    if (menu && !menu.contains(event.relatedTarget)) {
      setIsMoreMenuOpen(false);
    }
  };

  useClickAway(menuRef, () => setIsMoreMenuOpen(false));

  return (
    <DropdownMenu
      ref={menuRef}
      isOpen={isMoreMenuOpen}
      onClose={toggleOpenMoreMenu}
      onBlur={handleBlur}
      hasSeparator={false}
      component={
        component ? (
          React.cloneElement(component, { onClick: toggleOpenMoreMenu })
        ) : (
          <Button onClick={toggleOpenMoreMenu} variant={buttons.variant.secondary}>
            •••
          </Button>
        )
      }
    >
      {children}
    </DropdownMenu>
  );
}
