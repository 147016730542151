import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import type { LeadListMappingItem } from '@pray/shared/services/studioService/leadLists/types';

type CreateLeadListHookParams = {
  name: string;
  mapping: LeadListMappingItem[];
  s3Url: string;
  initializationVector: string;
  secretKey: string;
};

export default function useCreateLeadList({ artistId }: { artistId: string }) {
  const queryClient = useQueryClient();

  const createLeadList = async ({
    name,
    mapping,
    s3Url,
    initializationVector,
    secretKey,
  }: CreateLeadListHookParams) => {
    const response = await studioService.leadLists.postCreateLeadList({
      name,
      mapping,
      s3Url,
      initializationVector,
      secretKey,
      artistId,
    });
    return response.data;
  };

  const mutation = useMutation({
    mutationFn: createLeadList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.createLeadList(artistId),
      });
    },
  });

  return mutation;
}
