import React from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import styles from './MessageModal.module.scss';

/*
 * Props:
 * @message = string
 * @closeModal (function) called when modal closes itself
 */

const MessageModal = ({ message, closeModal }) => {
  return (
    <div className={styles.messageModalWrap}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.message}>
            <Text variant={typography.body}>{message}</Text>
          </div>
          <Button full variant={buttons.variant.primary} className={styles.button} onClick={closeModal}>
            GOT IT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
