import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Image from '@pray/shared/components/ui/Image/Image';
import Text from '@pray/shared/components/ui/Text';

export default function FilterSeries({ id, title, image, setSelectedSeries, selectedSeries }) {
  const handleCheckboxClick = () => {
    if (selectedSeries.includes(id)) {
      setSelectedSeries(selectedSeries.filter((seriesId) => seriesId !== id));
    } else {
      setSelectedSeries([...selectedSeries, id]);
    }
  };

  return (
    <label className="flex items-center gap-[10px]">
      <Checkbox checked={selectedSeries.includes(id)} onChange={handleCheckboxClick} />
      <Image src={image} className="size-8 rounded" />
      <Text color={colors.text_primary} className="text-[15px] font-medium">
        {title}
      </Text>
    </label>
  );
}
