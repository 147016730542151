import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CurrencyInput } from 'react-currency-mask';

import { colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading';
import AppBar from '@pray/shared/components/ui/AppBar/AppBar';
import Button from '@pray/shared/components/ui/Button';
import Image from '@pray/shared/components/ui/Image/Image';
import Text from '@pray/shared/components/ui/Text/Text';
import TextExpander from '@pray/shared/components/ui/TextExpander/TextExpander';
import { EVENTS } from '@pray/shared/constants';
import { formatCurrency } from '@pray/shared/utils';
import { cn } from '@pray/shared/utils/styles';

import coverImage from '../assets/donation-cover-image.png';
import {
  amountTiers,
  donationIntervals,
  intervalAbbr,
  amountTypes,
  minMonthlyDonationAmount,
  minAnnuallyDonationAmount,
} from '../constants';
import DonationFrequency from '../DonationFrequency/DonationFrequency';
import DonationOption from '../DonationOption/DonationOption';

import styles from './DonationAmountPage.module.scss';

export default function DonationAmountPage({
  form,
  fund = {},
  isPreview = false,
  showAppBar = true,
  isLoading = false,
  showCustomAmountButton = false,
  showCustomAmountField = false,
  onContinue,
  onCustomAmountSelect,
}) {
  const isMonthly = form.values.interval === donationIntervals.month;
  const minimumDonationAmount = isMonthly ? minMonthlyDonationAmount : minAnnuallyDonationAmount;
  const isValid = form.values.amount >= minimumDonationAmount;

  return (
    <div
      data-viewname={EVENTS.PRAY_EVENTS.VIEW_EVENT_NAMES.DONATION_FLOW.DONATION_PAYWALL}
      className={styles.container}
    >
      {!isPreview && showAppBar && (
        <div className={styles.appBarContainer}>
          <AppBar />
        </div>
      )}

      <ImageContainer url={fund.image_url} isLoading={isLoading} />

      <div className={styles.content}>
        <Headline fund={fund} isLoading={isLoading} />
        <DonationTypes form={form} fund={fund} showCustomAmountField={showCustomAmountField} />

        {showCustomAmountField && (
          <InlineCustomAmount form={form} isValid={isValid} minimumDonationAmount={minimumDonationAmount} />
        )}

        {!showCustomAmountField && (
          <DonationOptions
            form={form}
            fund={fund}
            showCustomAmountButton={showCustomAmountButton}
            onCustomAmountSelect={onCustomAmountSelect}
          />
        )}

        <Button disabled={!isValid} className={styles.continue} onClick={onContinue}>
          Continue
        </Button>
      </div>
    </div>
  );
}

const ImageContainer = ({ url, isLoading }) => {
  function onImageLoadError(event) {
    event.target.src = coverImage;
  }

  if (isLoading) {
    return <Loading className="!m-5 !mx-auto h-48 w-[91%] lg:h-64" />;
  }

  return (
    <div className={styles.imageContainer}>
      <Image src={url || coverImage} onError={onImageLoadError} />
    </div>
  );
};

const Headline = ({ fund, isLoading }) => {
  if (isLoading) {
    return <LoadingHeadline />;
  }

  return (
    <div className={styles.headline}>
      <Text variant={typography.h1}>{fund.name}</Text>
      {!!fund.description && (
        <TextExpander className={styles.textExpander} expandButtonText="Show More" collapseButtonText="Show Less">
          {fund.description}
        </TextExpander>
      )}
    </div>
  );
};

const DonationTypes = ({ form, fund, showCustomAmountField }) => {
  function handleIntervalChange(interval) {
    form.setValue('interval', interval);

    const isTier1Selected = amountTiers.tier1.value === form.values.amountTier;

    if (interval === donationIntervals.month) {
      const amount = isTier1Selected ? fund.default_amount_monthly : fund.amount_monthly_2;
      form.setValue('amount', amount);
    }

    if (interval === donationIntervals.year) {
      const amount = isTier1Selected ? fund.default_amount_yearly : fund.amount_yearly_2;
      form.setValue('amount', amount);
    }
  }

  return (
    <div className={cn(styles.frequencyContainer, { '!items-center': showCustomAmountField })}>
      <Text className={styles.frequencyTitle} variant={typography.heading_lg}>
        I want to give
      </Text>
      <div className={styles.frequencies}>
        <DonationFrequency
          interval={donationIntervals.month}
          value={form.values.interval}
          onChange={handleIntervalChange}
        />
        <DonationFrequency
          interval={donationIntervals.year}
          value={form.values.interval}
          onChange={handleIntervalChange}
        />
      </div>
    </div>
  );
};

const DonationOptions = ({ form, fund, showCustomAmountButton, onCustomAmountSelect }) => {
  const subtitle = '1 Ads Free Subscription';

  const donationOptions = useMemo(() => {
    const options = [
      {
        isFeatured: true,
        value: amountTiers.tier1.value,
        title: amountTiers.tier1.label,
        subtitle,
        amountByInterval: {
          month: fund.default_amount_monthly,
          year: fund.default_amount_yearly,
        },
      },
    ];

    // Only include second option if showCustomAmountButton is false
    if (!showCustomAmountButton) {
      options.push({
        value: amountTiers.tier2.value,
        title: amountTiers.tier2.label,
        subtitle,
        amountByInterval: {
          month: fund.amount_monthly_2,
          year: fund.amount_yearly_2,
        },
      });
    }

    return options;
  }, [fund, showCustomAmountButton]);

  return (
    <div className={styles.optionsContainer}>
      {donationOptions.map((option) => (
        <DonationOption
          key={option.value}
          name={option.value}
          isFeatured={option.isFeatured}
          isSelected={form.values.amountTier === option.value}
          onChange={(amountTier) => {
            form.setValue('amountTier', amountTier);

            const isTier1Selected = amountTier === amountTiers.tier1.value;

            if (form.values.interval === donationIntervals.month) {
              const amount = isTier1Selected ? fund.default_amount_monthly : fund.amount_monthly_2;
              form.setValue('amount', amount);
            }

            if (form.values.interval === donationIntervals.year) {
              const amount = isTier1Selected ? fund.default_amount_yearly : fund.amount_yearly_2;
              form.setValue('amount', amount);
            }
          }}
        >
          <div className={styles.optionContainer}>
            <div className={styles.optionRow1}>
              <Text className={styles.optionTitle} variant={typography.heading_md}>
                {option.title}
              </Text>
              {option.amountByInterval && (
                <div className={styles.optionAmount}>
                  <Text variant={typography.heading_md}>
                    {formatCurrency(option.amountByInterval[form.values.interval])}
                    <Text variant={typography.heading_md} color={colors.text_secondary}>
                      /{intervalAbbr[form.values.interval] || form.values.interval}
                    </Text>
                  </Text>
                </div>
              )}
            </div>
            {!fund.default && (
              <div className={styles.optionRow2}>
                <Text className={styles.optionSubtitle} variant={typography.heading_md} color={colors.text_secondary}>
                  {option.subtitle}
                </Text>
              </div>
            )}
          </div>
        </DonationOption>
      ))}
      {showCustomAmountButton && (
        <DonationOption name="custom" isSelected={false} isFeatured={false} onChange={onCustomAmountSelect}>
          <Text className={styles.optionTitle} variant={typography.heading_md}>
            Enter Custom Amount
          </Text>
        </DonationOption>
      )}
    </div>
  );
};

const InlineCustomAmount = ({ form, isValid, minimumDonationAmount }) => {
  const inputRef = useRef(null);
  const [amount, setAmount] = useState(0);

  function handleChange(_, value) {
    setAmount(value);

    const valueInCents = Math.round(value * 100);
    form.setValue('amount', valueInCents);
  }

  useEffect(() => {
    form.setValue('amountType', amountTypes.custom);
    handleChange(null, amount);
    inputRef.current?.focus();
  }, [form.values.interval, form.values.amount]);

  return (
    <div className={styles.inlineCustomAmountContainer}>
      <Text className={styles.customAmountTitle} variant={typography.heading_lg}>
        Enter donation amount
      </Text>

      <div className={styles.customAmountInputContainer}>
        <CurrencyInput
          ref={inputRef}
          type="tel"
          locale="en-US"
          currency="USD"
          placeholder="$0.00"
          className={styles.customAmountInput}
          onChangeValue={handleChange}
        />

        <div className={cn(styles.customAmountInfo, { invisible: isValid })}>
          <Text
            variant={typography.body_sm}
            className={styles.customAmountNote}
            color={isValid ? colors.text_secondary : colors.attention}
          >
            Minimum {form.values.interval}ly amount is {formatCurrency(minimumDonationAmount, 0)}.
          </Text>
        </div>
      </div>
    </div>
  );
};

const LoadingHeadline = () => {
  return (
    <div className={cn(styles.headline, '!gap-0 mt-12')}>
      <Loading className="!my-1 !mb-3 h-7 w-64" />
      <Loading className="!my-1 h-5" />
      <Loading className="!my-1 h-5" />
      <Loading className="!my-1 h-5 w-48" />
    </div>
  );
};
