import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function usePeriodicTable({ artistId }) {
  async function fetchPeriodicTable() {
    const response = await studioService.periodicTable.getLeaderPeriodicTable({ artistId });

    return response.data;
  }

  return useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistPeriodicTable(),
    queryFn: fetchPeriodicTable,
  });
}
