import OpenBookIcon from '@pray/shared/icons/OpenBook';

type BookCoverProps = {
  image: string;
};

export function BookCover({ image }: BookCoverProps) {
  return (
    <div className="flex size-full items-center justify-center overflow-hidden rounded-sm bg-gray-200">
      {image ? <img src={image} className="size-full object-cover" alt="Book Cover" /> : <OpenBookIcon />}
    </div>
  );
}
