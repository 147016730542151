import React, { useEffect } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Stepper from '@pray/shared/components/ui/Stepper/Stepper';
import { navigate } from '@pray/shared/utils/navigation';

import useAppRoutes from 'hooks/useAppRoutes';

import LeadListSidebar from '../components/LeadListSidebar';
import { UPLOAD_STEPS } from '../constants';
import CancelImportDialog from './components/dialogs/CancelImportDialog';
import CancelMappingDialog from './components/dialogs/CancelMappingDialog';
import CancelUploadDialog from './components/dialogs/CancelUploadDialog';
import ImportLeadsStep from './steps/ImportLeadsStep/ImportLeadsStep';
import MapFieldsStep from './steps/MapFieldsStep/MapFieldsStep';
import UploadCsvStep from './steps/UploadLeadsStep/UploadCsvStep';
import useUploadLeadsFlow from './useUploadLeadsFlow';
import ActionButton from '../../../components/ActionButton/ActionButton';
import useSideBar from '../../../components/SideBar/useSideBar';
import TabPage from '../../../components/TabPage/TabPage';

export default function UploadLeadsFlow() {
  const appRoutes = useAppRoutes();

  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const {
    activeStep,
    isCancelUploadOpened,
    isCancelMappingOpened,
    isCancelImportOpened,
    isNextButtonDisabled,
    isNextButtonVisible,
    isCancelButtonVisible,
    uploadState,
    title,
    userEditedMappings,
    availableOptions,
    unmatchedMappings,
    hasRequiredField,
    getCompletedSteps,

    setIsCancelUploadOpened,
    setIsCancelMappingOpened,
    setIsCancelImportOpened,
    setTitle,
    handleNextClick,
    handleCancelClick,
    handleUploadFile,
    handleRemoveFile,
    handleFieldChange,
    handleImportLeads,
    handleCancelUpload,
    handleRetry,
  } = useUploadLeadsFlow();

  useEffect(() => {
    setRenderDetails(() => <LeadListSidebar onBackClick={handleCancelClick} />);
  }, [activeStep, uploadState]);

  function renderActionButtons() {
    return (
      <>
        {isCancelButtonVisible && (
          <ActionButton text="Cancel" variant={buttons.variant.secondary} onClick={handleCancelClick} />
        )}
        {isNextButtonVisible && (
          <ActionButton
            text={activeStep === UPLOAD_STEPS.IMPORT ? 'Done' : 'Next'}
            disabled={isNextButtonDisabled}
            onClick={handleNextClick}
          />
        )}
      </>
    );
  }

  function renderStepContent() {
    if (activeStep === UPLOAD_STEPS.UPLOAD) {
      return (
        <UploadCsvStep
          activeStep={activeStep}
          title={title}
          uploadState={uploadState}
          onChangeTitle={setTitle}
          onUploadFile={handleUploadFile}
          onRemoveFile={handleRemoveFile}
          onCancel={handleCancelUpload}
        />
      );
    }

    if (activeStep === UPLOAD_STEPS.MAP) {
      return (
        <MapFieldsStep
          mappings={userEditedMappings}
          availableOptions={availableOptions}
          unmatchedMappings={unmatchedMappings}
          hasRequiredField={hasRequiredField}
          handleFieldChange={handleFieldChange}
        />
      );
    }

    if (activeStep === UPLOAD_STEPS.IMPORT) {
      return (
        <ImportLeadsStep
          uploadState={uploadState}
          activeStep={activeStep}
          fileName={title}
          onImportLeads={handleImportLeads}
          onRetry={handleRetry}
        />
      );
    }

    return null;
  }

  return (
    <TabPage title="Lists" topRightButtons={renderActionButtons()}>
      <Stepper
        className="mt-8"
        steps={Array.from(Object.values(UPLOAD_STEPS))}
        activeStep={activeStep}
        completedSteps={getCompletedSteps()}
      >
        <div className="mt-4 flex max-w-3xl flex-col">{renderStepContent()}</div>
      </Stepper>

      {isCancelUploadOpened && (
        <CancelUploadDialog
          onBackToUpload={() => setIsCancelUploadOpened(false)}
          onCancel={() => {
            navigate(appRoutes.artistLists());
          }}
        />
      )}

      {isCancelMappingOpened && (
        <CancelMappingDialog
          onResumeMapping={() => setIsCancelMappingOpened(false)}
          onDiscardMapping={() => {
            navigate(appRoutes.artistLists());
          }}
        />
      )}

      {isCancelImportOpened && (
        <CancelImportDialog
          onBackToImport={() => setIsCancelImportOpened(false)}
          onCancel={() => {
            navigate(appRoutes.artistLists());
          }}
        />
      )}
    </TabPage>
  );
}
