import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderDailySeries(artistId) {
  const fetchLeaderDailySeries = async (offset) => {
    const response = await studioService.dailySeries.getDailySeriesBasedOnArtist({
      artistId,
      offset,
    });

    return response;
  };

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistDailySeries(artistId),
    queryFn: ({ pageParam: offset }) => fetchLeaderDailySeries(offset),
    getPreviousPageParam: (response) => response.next_item || undefined,
    getNextPageParam: (response) => response.next_item || undefined,
    placeholderData: keepPreviousData,
  });

  return query;
}
