import React, { useState } from 'react';
import { useLocation, useMatch, useParams } from 'react-router-dom';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Voices } from '@pray/shared/components/ui/Icons/Voices';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { Chapter } from '@pray/shared/services/studioService/books/chapters';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';
import { cn } from '@pray/shared/utils/styles';

import { useEditBookTranscript } from './useEditBookTranscript';
import { CHAPTER_TITLE_MAX_LENGTH, EDIT_TRANSCRIPT_SIDEBAR_PANELS } from '../../../constants';

import ButtonCluster from '@/components/pages/StudioPage/components/ButtonCluster';
import { useToastMessage } from '@/components/pages/StudioPage/components/StudioToastMessage';
import TranscriptEditor from '@/components/pages/StudioPage/components/TranscriptEditor/TranscriptEditor';
import TranscriptHistoryModal from '@/components/pages/StudioPage/components/TranscriptHistoryModal/TranscriptHistoryModal';
import { VoiceSettingsPanel } from '@/components/pages/StudioPage/tabs/ContentTab/EditTranscriptPage/VoiceSettingsPanel';
import { useGenerateAudiobook } from '@/hooks/books/useGenerateAudiobook';
import useAppRoutes from '@/hooks/useAppRoutes';
import { AddPlus, BulletList, HistoryClock, VerticalEllipsis } from '@/images/icons';

type ChapterSideBarProps = {
  chapters: Chapter[];
  selectedChapterId: string;
  isLoadingChapters: boolean;
  isAddingChapter: boolean;
  addChapter: () => void;
  setSelectedChapterId: (chapterId: string) => void;
  setIsRenameChapterModalOpen: (isOpen: boolean) => void;
  setIsDeleteChapterModalOpen: (isOpen: boolean) => void;
  setMenuChapterId: (chapterId: string) => void;
};

type DeleteChapterModalProps = {
  isOpen: boolean;
  selectedChapter: Chapter;
  onClose: () => void;
  onDeleteChapter: (chapter: Chapter) => void;
};

type RenameChapterModalProps = {
  isOpen: boolean;
  selectedChapter: Chapter;
  onClose: () => void;
  onRenameChapter: (chapter: Chapter, newTitle: string) => void;
};

type GenerateAudioModalProps = {
  isOpen: boolean;
  artistId: string;
  bookId: string;
  voiceId: string;
  onClose: () => void;
  changeSidebarPanel: (panel: string) => void;
};

type SelectMainVoiceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  changeSidebarPanel: (panel: string) => void;
};

export default function EditBookTranscriptPage() {
  const { bookId, artistId } = useParams();
  const location = useLocation();
  const routeMatch = useMatch('/leaders/:artistId/library/:bookId/transcript/edit/voices');
  const appRoutes = useAppRoutes();

  const [isRenameChapterModalOpen, setIsRenameChapterModalOpen] = useState(false);
  const [isDeleteChapterModalOpen, setIsDeleteChapterModalOpen] = useState(false);
  const [menuChapterId, setMenuChapterId] = useState(null);
  const [isTranscriptHistoryModalOpen, setIsTranscriptHistoryModalOpen] = useState(false);
  const [isGenerateAudioModalOpen, setIsGenerateAudioModalOpen] = useState(false);
  const [activeSidebarPanel, setActiveSidebarPanel] = useState(
    routeMatch ? EDIT_TRANSCRIPT_SIDEBAR_PANELS.VOICES : EDIT_TRANSCRIPT_SIDEBAR_PANELS.CHAPTERS
  );

  const {
    book,
    chapters,
    isLoadingChapters,
    selectedChapterId,
    form,
    voices,
    isTranscriptHistoryLoading,
    transcriptHistory,
    clearChangeHistory,
    selectedChapterTranscript,
    isLoadingChapter,
    isUpdatingTranscript,
    isAutoSaving,
    completedAutoSave,
    selectedVoiceId,
    isRestoringTranscript,
    isAddingChapter,
    isRefetchingChapters,
    handleUpdateTranscript,
    setSelectedChapterId,
    addChapter,
    deleteChapter,
    renameChapterTitle,
    handleContentChange,
    handleSelectVersion,
    restoreTranscript,
  } = useEditBookTranscript({ bookId });

  const onUpdateTranscript = async () => {
    if (await handleUpdateTranscript()) {
      setIsGenerateAudioModalOpen(true);
    }
  };

  const handleBack = () => {
    const route = location.state?.from || -1;
    navigate(route);
  };

  const handlePanelChange = (panel: string) => {
    setActiveSidebarPanel(panel);
    const newUrl =
      panel === EDIT_TRANSCRIPT_SIDEBAR_PANELS.VOICES
        ? appRoutes.artistLibraryBookTranscriptEditVoices(bookId)
        : appRoutes.artistLibraryBookTranscriptEdit(bookId);
    navigate(newUrl, { replace: true });
  };

  const sidebarTabs = [
    {
      id: EDIT_TRANSCRIPT_SIDEBAR_PANELS.CHAPTERS,
      icon: <BulletList />,
      content: (
        <ChapterSideBar
          chapters={chapters}
          selectedChapterId={selectedChapterId}
          isLoadingChapters={isLoadingChapters}
          isAddingChapter={isAddingChapter || isRefetchingChapters}
          addChapter={addChapter}
          setSelectedChapterId={setSelectedChapterId}
          setIsRenameChapterModalOpen={setIsRenameChapterModalOpen}
          setIsDeleteChapterModalOpen={setIsDeleteChapterModalOpen}
          setMenuChapterId={setMenuChapterId}
        />
      ),
    },
    {
      id: EDIT_TRANSCRIPT_SIDEBAR_PANELS.VOICES,
      icon: <Voices stroke="#56585E" />,
      content: <VoiceSettingsPanel voices={voices} useElevenLabsVoiceId={false} />,
    },
  ];

  const headerActions = (
    <div className="flex items-center gap-2">
      {isAutoSaving && (
        <Text variant={typography.body_md} color={colors.text_tertiary}>
          Saving in progress
        </Text>
      )}
      {completedAutoSave && (
        <Text variant={typography.body_md} color={colors.text_tertiary}>
          Saved
        </Text>
      )}
      <ButtonCluster text="Update" isLoading={isLoadingChapters || isUpdatingTranscript} onClick={onUpdateTranscript}>
        {/* TODO: Whenever the BE side is ready, we can add the download audio button back in */}
        {/* <DropdownMenu.Item
          title="Download Audio"
          leftComponent={<Download />}
          disabled={audioBookState !== BookProcessStatus.COMPLETED}
          onClick={handleDownloadAudio}
        /> */}
        <DropdownMenu.Item
          title="Version History"
          leftComponent={<HistoryClock />}
          onClick={() => setIsTranscriptHistoryModalOpen(true)}
        />
      </ButtonCluster>
    </div>
  );

  return (
    <>
      <TranscriptEditor
        useMarkdown
        title="Edit Transcript"
        subtitle={book?.title}
        isLoading={isLoadingChapters || isLoadingChapter}
        headerActions={headerActions}
        sidebarTabs={sidebarTabs}
        defaultSidebarTab={activeSidebarPanel}
        content={selectedChapterTranscript}
        resetChangeHistory={clearChangeHistory}
        form={form}
        onBack={handleBack}
        onChange={handleContentChange}
        onSidebarTabChange={handlePanelChange}
      />

      <DeleteChapterModal
        isOpen={isDeleteChapterModalOpen}
        selectedChapter={chapters?.find((chapter) => chapter.id === menuChapterId)}
        onClose={() => setIsDeleteChapterModalOpen(false)}
        onDeleteChapter={deleteChapter}
      />

      <RenameChapterModal
        isOpen={isRenameChapterModalOpen}
        selectedChapter={chapters?.find((chapter) => chapter.id === menuChapterId)}
        onClose={() => setIsRenameChapterModalOpen(false)}
        onRenameChapter={renameChapterTitle}
      />

      <GenerateAudioModal
        isOpen={isGenerateAudioModalOpen}
        artistId={artistId}
        bookId={bookId}
        voiceId={selectedVoiceId}
        onClose={() => setIsGenerateAudioModalOpen(false)}
        changeSidebarPanel={handlePanelChange}
      />

      <TranscriptHistoryModal
        isMarkdown
        isOpen={isTranscriptHistoryModalOpen}
        isLoadingTranscriptHistory={isTranscriptHistoryLoading}
        isSavingTranscriptVersion={isRestoringTranscript}
        transcriptHistory={transcriptHistory}
        versionedContentList={chapters}
        onSelectVersionedContent={handleSelectVersion}
        saveTranscriptVersion={restoreTranscript}
        onClose={() => setIsTranscriptHistoryModalOpen(false)}
      />
    </>
  );
}

function ChapterSideBar({
  chapters,
  selectedChapterId,
  isLoadingChapters,
  isAddingChapter,
  addChapter,
  setSelectedChapterId,
  setIsRenameChapterModalOpen,
  setIsDeleteChapterModalOpen,
  setMenuChapterId,
}: ChapterSideBarProps) {
  const [openMenuId, setOpenMenuId] = useState(null);

  return (
    <div className="flex w-full flex-col gap-4 overflow-y-auto p-4">
      <div className="flex items-center justify-between gap-2">
        <Text variant={typography.heading_lg}>Chapters</Text>
        <Button variant={buttons.variant.tertiary} className="p-2" onClick={addChapter} disabled={isAddingChapter}>
          <AddPlus />
        </Button>
      </div>
      {/* Reverse order of chapters so that the dropdown menus don't overlap with each other */}
      <div className="flex w-full flex-col-reverse gap-2">
        {isLoadingChapters ? (
          <ChaptersLoading />
        ) : (
          // Reverse back to original order
          <>
            {isAddingChapter && <Loading isLight height={45} width={280} />}
            {(chapters || []).toReversed().map((chapter) => (
              <div
                key={chapter.id}
                className={cn(
                  'flex items-center gap-2 p-2 justify-between group',
                  selectedChapterId === chapter.id && 'bg-[#F3F3F3] rounded-[6px]'
                )}
              >
                <Button
                  key={chapter.id}
                  className="max-w-[90%] p-0"
                  tabIndex={0}
                  onClick={() => setSelectedChapterId(chapter.id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSelectedChapterId(chapter.id);
                    }
                  }}
                >
                  <Text
                    variant={typography.body_lg}
                    className="w-full truncate text-left !font-medium normal-case"
                    title={chapter.title}
                  >
                    {chapter.title}
                  </Text>
                </Button>
                <DropdownMenu
                  isOpen={openMenuId === chapter.id}
                  component={
                    <Button
                      className="p-1 opacity-0 transition-opacity group-hover:opacity-100"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenMenuId(openMenuId === chapter.id ? null : chapter.id);
                      }}
                    >
                      <VerticalEllipsis width={20} height={20} />
                    </Button>
                  }
                  onClose={() => setOpenMenuId(null)}
                >
                  <DropdownMenu.Item
                    className="!border-none"
                    title="Rename"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setIsRenameChapterModalOpen(true);
                      setOpenMenuId(null);
                      setMenuChapterId(chapter.id);
                    }}
                  />
                  <DropdownMenu.Item
                    className="!border-none"
                    title="Delete"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setIsDeleteChapterModalOpen(true);
                      setOpenMenuId(null);
                      setMenuChapterId(chapter.id);
                    }}
                  />
                </DropdownMenu>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

function ChaptersLoading() {
  return (
    <>
      <Loading isLight height={45} width={280} />
      <Loading isLight height={45} width={280} />
      <Loading isLight height={45} width={280} />
    </>
  );
}

function DeleteChapterModal({ isOpen, selectedChapter, onClose, onDeleteChapter }: DeleteChapterModalProps) {
  if (!isOpen) return null;

  const handleDeleteChapter = () => {
    onDeleteChapter(selectedChapter);
    onClose();
  };

  return (
    <Modal isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_lg}>Remove Chapter</Text>
        <Text variant={typography.body_lg}>
          Are you sure you want to remove <span className="break-words font-bold">"{selectedChapter.title}"</span>? The
          corresponding audio will be also deleted.
        </Text>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={buttons.variant.primary} color={colors.error} onClick={handleDeleteChapter}>
            Remove Chapter
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function RenameChapterModal({ isOpen, selectedChapter, onClose, onRenameChapter }: RenameChapterModalProps) {
  if (!isOpen) return null;

  const [newChapterTitle, setNewChapterTitle] = useState(selectedChapter.title);

  const handleRenameChapter = () => {
    onRenameChapter(selectedChapter, newChapterTitle);
    onClose();
  };

  return (
    <Modal isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_lg}>Rename Chapter</Text>
        <TextInput
          label="Chapter Title"
          value={newChapterTitle}
          placeholder="Enter chapter title"
          autoFocus
          onChange={(e) => setNewChapterTitle(e.target.value)}
          maxLength={CHAPTER_TITLE_MAX_LENGTH}
        />
        <InputHelperText>
          <span />
          <span>
            {newChapterTitle.length}/{CHAPTER_TITLE_MAX_LENGTH}
          </span>
        </InputHelperText>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={buttons.variant.primary} disabled={!newChapterTitle.trim()} onClick={handleRenameChapter}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function GenerateAudioModal({
  isOpen,
  artistId,
  bookId,
  voiceId,
  onClose,
  changeSidebarPanel,
}: GenerateAudioModalProps) {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const [isSelectVoiceModalOpen, setIsSelectVoiceModalOpen] = useState(false);

  const { generateAudiobook, isPending: isGeneratingAudio } = useGenerateAudiobook();

  const handleGenerateAudio = async () => {
    if (!voiceId) {
      setIsSelectVoiceModalOpen(true);
      return;
    }

    try {
      await generateAudiobook({ artistId, bookId, voiceId });
      navigate(appRoutes.artistLibraryBookDetails(bookId));
    } catch (error) {
      logger.error('Failed to generate audio book', error);
      toast.show({ error: 'Failed to generate audio book' });
    } finally {
      onClose();
    }
  };

  if (!isOpen) return null;

  if (isSelectVoiceModalOpen) {
    const onVoiceModalClose = () => {
      setIsSelectVoiceModalOpen(false);
      onClose();
    };

    return <SelectMainVoiceModal isOpen={isOpen} onClose={onVoiceModalClose} changeSidebarPanel={changeSidebarPanel} />;
  }

  return (
    <Modal isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_lg}>Generate Audio Book?</Text>
        <Text variant={typography.body_lg}>Your transcript is updated. Ready to generate audio?</Text>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            No
          </Button>
          <Button variant={buttons.variant.primary} onClick={handleGenerateAudio} loading={isGeneratingAudio}>
            Yes, generate audio
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function SelectMainVoiceModal({ isOpen, onClose, changeSidebarPanel }: SelectMainVoiceModalProps) {
  if (!isOpen) return null;

  const handleSelectVoice = () => {
    changeSidebarPanel(EDIT_TRANSCRIPT_SIDEBAR_PANELS.VOICES);
    onClose();
  };

  return (
    <Modal isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_lg}>No Voice Selected</Text>
        <Text variant={typography.body_lg}>Please select a voice to be able to generate audio.</Text>
        <Button variant={buttons.variant.primary} onClick={handleSelectVoice}>
          Select Voice
        </Button>
      </div>
    </Modal>
  );
}
