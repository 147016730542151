import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { Close } from 'images/icons';

function Modal({ children }) {
  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center">
      <div className="relative flex w-[550px] flex-col gap-6 rounded-xl bg-white p-8  opacity-100">{children}</div>
    </div>
  );
}

export function EmailDialogModal({ title, description = '', buttons, onClose, isLoading = false, children }) {
  function renderOverlay() {
    return <div className="fixed inset-0 z-[100] bg-black opacity-45" />;
  }

  function renderHeadline() {
    return (
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-1">
          <Text variant={typography.h1} color={colors.text_primary}>
            {title}
          </Text>
          <Text variant={typography.body} color={colors.text_secondary}>
            {description}
          </Text>
        </div>
        <Button alt="close" className="p-2" onClick={() => onClose()} disabled={isLoading}>
          <Close />
        </Button>
      </div>
    );
  }

  function renderFooter() {
    return (
      <div className="flex justify-end gap-3">
        {buttons.map((button) => (
          <Button
            key={button.label}
            variant={button.variant}
            onClick={button.action}
            disabled={button.disabled}
            loading={button.loading}
          >
            {button.label}
          </Button>
        ))}
      </div>
    );
  }

  return (
    <>
      {renderOverlay()}
      <Modal>
        {renderHeadline()}
        {children}
        {renderFooter()}
      </Modal>
    </>
  );
}
