import api from '@pray/shared/utils/api';

export async function getAllEventsByPeriod({ artistId, startDate, endDate, filters = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!startDate) throw Error('Start Date is required');
  if (!endDate) throw Error('End Date is required');

  const payload = {
    mock: true,
    start_datetime: startDate,
    end_datetime: endDate,
    filters,
  };

  const url = `/web/studio/artists/${artistId}/calendar`;
  const response = await api.executePostRequest(url, payload);

  return response.data;
}
