import React, { createRef, useState } from 'react';

import styles from './TimePicker.module.scss';

export default function TimePickerComponent({ className, label, indicatorIcon, ...props }) {
  const timePickerRef = createRef();
  const [active, setActive] = useState(false);

  const classes = [styles.timePicker, className];

  if (label) classes.push(styles.withLabel);

  function handleLabelClick() {
    timePickerRef.current.focus();
  }

  function handleFocus() {
    setActive(true);
  }

  function handleBlur() {
    setActive(false);
  }

  const labelStyle = active || props.value ? styles.floating : '';
  const disabledStyle = props.disabled ? styles.timePickerWrapDisabled : '';

  return (
    <label
      role="none"
      htmlFor={props.name}
      data-id="timePickerWrap"
      onClick={handleLabelClick}
      className={`${styles.timePickerWrap} ${labelStyle} ${disabledStyle}`}
    >
      <div className={styles.timePickerInputContainer}>
        <div className={styles.placeholder}>{label}</div>
        <input
          type="time"
          ref={timePickerRef}
          className={classes.join(' ')}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
      </div>
      {indicatorIcon && <div className={styles.indicator}>{indicatorIcon}</div>}
    </label>
  );
}
