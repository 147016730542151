export function DarkTheme({ className = '', ...props }) {
  const classes = ['theme-dark', className].filter(Boolean).join(' ');

  return <div className={classes} {...props} />;
}

export function LightTheme({ className, ...props }) {
  const classes = ['theme-light', className].filter(Boolean).join(' ');

  return <div className={classes} {...props} />;
}
