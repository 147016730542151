import api from '@pray/shared/utils/api';

export async function createArtistContent({
  artistId = null,
  primaryAudioUrl = null,
  primaryAudioFileDuration = null,
  primaryAudioFileSize = null,
  primaryVideoUrl = null,
  primaryVideoFileDuration = null,
  primaryVideoFileSize = null,
}) {
  if (!artistId) throw Error('ID is required');

  if (!primaryAudioUrl && !primaryVideoUrl) throw Error('Audio or video file URL is required');

  const data = {
    premium_type: 'free',
  };

  if (primaryAudioUrl) {
    if (!primaryAudioFileDuration) throw Error('Audio file duration is required');
    if (!primaryAudioFileSize) throw Error('Audio file size is required');

    data.primary_audio_url = primaryAudioUrl;
    data.primary_audio_file_duration = primaryAudioFileDuration;
    data.primary_audio_file_size = primaryAudioFileSize;
  } else if (primaryVideoUrl) {
    if (!primaryVideoFileDuration) throw Error('Video file duration is required');
    if (!primaryVideoFileSize) throw Error('Video file size is required');

    data.primary_video_url = primaryVideoUrl;
    data.primary_video_file_duration = primaryVideoFileDuration;
    data.primary_video_file_size = primaryVideoFileSize;
  }

  const url = `/web/studio/artists/${artistId}/content`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
