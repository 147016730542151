import api from '@pray/shared/utils/api';

async function fetchContentData({ id, locale }) {
  const params = {};

  if (locale) params.locale = locale;

  const response = await api.executeGetRequest(`/web/content/${id}`, params);

  return response?.data?.data;
}

async function fetchContentSeriesData({ id, offset, artistId = null }) {
  const params = {};

  if (offset) params.offset = offset;
  if (artistId) params.artist_id = artistId;

  const url = `/web/content-series/${id}`;
  const response = await api.executeGetRequest(url, params);

  return response?.data;
}

async function updateContentReactions({ id }) {
  const data = { type: 'pray' };
  const response = await api.executePostRequest(`/0.9/content/${id}/reactions`, data);

  return response?.data?.data?.[0];
}

async function addContentReaction({ contentId, type = 'heart' }) {
  if (!contentId) throw Error('Content ID is required');

  const response = await api.executePostRequest(`/web/content/${contentId}/reactions`, { type });

  return response?.data;
}

async function removeContentReaction({ contentId, type = 'heart' }) {
  if (!contentId) throw Error('Content ID is required');

  const response = await api.executeDeleteRequest(`/web/content/${contentId}/reactions`, { type });

  return response?.data;
}

export default {
  addContentReaction,
  fetchContentData,
  fetchContentSeriesData,
  removeContentReaction,
  updateContentReactions,
};
