import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

export default function useTestIntegration() {
  async function testIntegration({ artistId, type, integrationDetails }) {
    const response = await studioService.integrations.testIntegration({
      artistId,
      type,
      integrationDetails,
    });

    return response.data;
  }

  const mutation = useMutation({
    mutationFn: testIntegration,
  });

  const { mutateAsync, isPending, ...rest } = mutation;

  return {
    testIntegration: mutateAsync,
    isLoading: isPending,
    ...rest,
  };
}
