import api from '@pray/shared/utils/api';

export const getSocialPosts = async ({ artistId = null, status = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');

  const params = {};

  if (status) params.status = status;

  const url = `/web/studio/artists/${artistId}/social-posts`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
