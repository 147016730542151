import { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Text from '@pray/shared/components/ui/Text';

export function EngagementTable({ data: engagementData = [], isLoading }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div className="flex flex-1 flex-col gap-6">
      <Loading isLight isLoading={isLoading} width={170} height={24} margin="0">
        <div className="flex items-center gap-2">
          <Text variant={typography.heading_xl}>Engagement</Text>
          <Button className="p-0 text-[#00000099]" onClick={() => setIsDialogOpen(true)}>
            <Info className="size-5" />
          </Button>
        </div>
      </Loading>

      <div className="flex flex-col gap-2">
        <table className="w-full">
          <thead className="border-b border-[#E7E7E7] text-left">
            <tr>
              <th className="p-2 py-3">
                <Loading isLight isLoading={isLoading} margin="0">
                  <Text className="font-medium" color={colors.text_secondary}>
                    #
                  </Text>
                </Loading>
              </th>
              <th className="p-2 py-3">
                <Loading isLight isLoading={isLoading} width={80} margin="0">
                  <Text className="font-medium" color={colors.text_secondary}>
                    City, State
                  </Text>
                </Loading>
              </th>
              <th className="p-2 py-3 text-right">
                <Loading isLight isLoading={isLoading} width={80} margin="0">
                  <Text className="font-medium" color={colors.text_secondary}>
                    Reach
                  </Text>
                </Loading>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {(isLoading ? Array(10).fill(null) : engagementData.slice(0, 10)).map((item, index) => (
              <tr key={index} className={isLoading ? '' : 'even:bg-[#F3F3F3]'}>
                <td className="px-2 py-3">
                  <Loading isLight isLoading={isLoading} width={16} margin="0">
                    <Text className="font-bold">{index + 1}</Text>
                  </Loading>
                </td>
                <td className="px-2 py-3">
                  <Loading isLight isLoading={isLoading} width={80} margin="0">
                    <Text className="font-medium">
                      {item?.city}, {item?.state}
                    </Text>
                  </Loading>
                </td>
                <td className="px-2 py-3 text-right">
                  <Loading isLight isLoading={isLoading} width={80} margin="0">
                    <Text className="font-bold">{item?.count?.toLocaleString()}</Text>
                  </Loading>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isDialogOpen && (
        <Dialog>
          <div className="flex flex-col gap-6">
            <Text variant={typography.heading_2xl}>Engagement</Text>
            <Text color={colors.text_secondary}>
              This list shows the top 10 cities where your content has been most consumed. The rankings are based on the
              total engagement your content has received, including views, likes, shares, and comments from each city.
              The numbers are cumulative, giving you an ongoing snapshot of where your audience is most active.
            </Text>
            <div className="flex flex-row justify-end">
              <Button variant={buttons.variant.primary} onClick={() => setIsDialogOpen(false)}>
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}
