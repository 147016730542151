import api from '@pray/shared/utils/api';
import { ObjectResponse } from '@pray/shared/utils/api/types/response';

/* eslint-disable camelcase */
export type ArtistVoicesResponse = {
  voices: {
    id: string;
    artist_id: number;
    name: string;
    audio_url: string;
    is_primary: boolean;
  }[];
};
/* eslint-enable camelcase */

export async function getArtistVoices({
  artistId,
}: {
  artistId: string;
}): Promise<ObjectResponse<ArtistVoicesResponse>> {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/voices`;
  const response = await api.executeGetRequest<ObjectResponse<ArtistVoicesResponse>>(url);

  return response.data;
}
