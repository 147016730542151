import { ArrowDown, ArrowUp } from 'lucide-react';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import remarkDirective from 'remark-directive';
import remarkDirectiveRehype from 'remark-directive-rehype';
import remarkGfm from 'remark-gfm';

import { buttons, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading';
import Button from '@pray/shared/components/ui/Button';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import { Diff, VersionDiff } from '@pray/shared/services/studioService/books/chapters/types';
import { hashCode } from '@pray/shared/utils';
import { cn } from '@pray/shared/utils/styles';

import { BulletList, CrossedCloseResizable } from 'images/icons';
import { formatUpdatedAtDate } from 'utils/dateTimeUtils';

import { MARKDOWN_COMPONENTS_VERSION_HISTORY } from '../../tabs/LibraryTab/constants';
import ActionButton from '../ActionButton/ActionButton';

type VersionedContent = {
  id: string;
  title: string;
};

type TranscriptHistoryModalProps = {
  isOpen: boolean;
  isLoadingTranscriptHistory: boolean;
  isSavingTranscriptVersion: boolean;
  isMarkdown?: boolean;
  transcriptHistory: VersionDiff[];
  versionedContentList?: VersionedContent[];
  onSelectVersionedContent?: (id: string) => void;
  onClose: () => void;
  saveTranscriptVersion: ({ transcript }: { transcript: string }) => Promise<void>;
};

type DiffTranscriptComponentProps = {
  itemDiffs: Diff[];
  transcript: string;
  isMarkdown: boolean;
  scrollPositions: MutableRefObject<HTMLElement[]>;
};

type MarkdownDiffTranscriptComponentProps = {
  itemDiffs: Diff[];
  transcript: string;
  handleRef: (el: HTMLDivElement | null) => void;
};

type ConfirmModalProps = {
  isOpen: boolean;
  isSavingTranscriptVersion: boolean;
  onClose: () => void;
  handleRestore: () => void;
};

type VersionedContentSelectorProps = {
  versionedContentList: VersionedContent[];
  onSelectVersionedContent: (id: string) => void;
};

type DirectiveComponentProps = {
  children?: React.ReactNode;
};

type ChangesNavigatorProps = {
  diffIndex: number;
  scrollPositions: MutableRefObject<HTMLElement[]>;
  handleScroll: (direction: 1 | -1) => void;
};

// Custom components for handling diff directives
const createDirectiveComponents = (handleRef: (el: HTMLDivElement | null) => void) => ({
  ...MARKDOWN_COMPONENTS_VERSION_HISTORY,
  'diff-added': ({ children }: DirectiveComponentProps) => {
    return (
      <span
        ref={handleRef}
        className="font-sfMono whitespace-pre-line bg-[#ECF9ED] leading-7 text-[#188149]"
        data-diff-type="added"
      >
        {children || <span>&nbsp;</span>}
      </span>
    );
  },
  'diff-removed': ({ children }: DirectiveComponentProps) => {
    return (
      <span
        ref={handleRef}
        className="font-sfMono whitespace-pre-line bg-[#FEF0F0] leading-7 text-[#AA0E0F] line-through"
        data-diff-type="removed"
      >
        {children || <span>&nbsp;</span>}
      </span>
    );
  },
});

export default function TranscriptHistoryModal({
  isOpen,
  isLoadingTranscriptHistory,
  isSavingTranscriptVersion,
  isMarkdown = false,
  transcriptHistory = [],
  versionedContentList = [],
  onSelectVersionedContent,
  onClose,
  saveTranscriptVersion,
}: TranscriptHistoryModalProps) {
  const [chosenVersion, setChosenVersion] = useState(0);
  const [isConfirmRestoreModalOpen, setIsConfirmRestoreModalOpen] = useState(false);
  const [diffIndex, setDiffIndex] = useState(-1);

  const scrollPositions = useRef([]);

  if (!isOpen) return null;

  async function handleRestore() {
    const transcriptToRestore = transcriptHistory[chosenVersion]?.transcript;
    await saveTranscriptVersion({ transcript: transcriptToRestore });
    setIsConfirmRestoreModalOpen(false);
    setChosenVersion(0);
  }

  const handleScroll = (direction: 1 | -1) => {
    const newIndex = diffIndex + direction;
    setDiffIndex(newIndex);
    scrollPositions.current[newIndex].scrollIntoView({ behavior: 'smooth' });

    const highlightClasses = ['border', 'border-gray-500'];
    scrollPositions.current.forEach((item) => {
      item.classList.remove(...highlightClasses);
      item.querySelectorAll('*').forEach((child) => {
        child.classList.remove(...highlightClasses);
      });
    });

    const selectedItem = scrollPositions.current[newIndex].children?.[0] || scrollPositions.current[newIndex];

    selectedItem.classList.add(...highlightClasses);
    setTimeout(() => {
      selectedItem.classList.remove(...highlightClasses);
    }, 1000);
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const isTop = e.currentTarget.scrollTop === 0;
    const isBottom = e.currentTarget.scrollTop + e.currentTarget.clientHeight >= e.currentTarget.scrollHeight;

    // Reenable scrolling from the first change
    if (isTop) {
      setDiffIndex(-1);
    }

    // Reenable scrolling from the last change
    if (isBottom) {
      setDiffIndex(scrollPositions.current.length);
    }
  };

  const handleVersionChange = (index: number) => {
    scrollPositions.current = [];
    setChosenVersion(index);
    setDiffIndex(-1);
  };

  const handleContentChange = (id: string) => {
    scrollPositions.current = [];
    setDiffIndex(-1);
    setChosenVersion(0);
    onSelectVersionedContent?.(id);
  };

  return (
    <div>
      <ConfirmModal
        handleRestore={handleRestore}
        onClose={() => setIsConfirmRestoreModalOpen(false)}
        isOpen={isConfirmRestoreModalOpen}
        isSavingTranscriptVersion={isSavingTranscriptVersion}
      />

      <Modal type="large" isShowCloseButton onClose={onClose} noPadding closeIcon={<CloseIconModal />}>
        <div className="grid h-screen grid-cols-1 gap-4 overflow-auto md:h-auto md:grid-cols-4">
          {/* Sidebar */}
          <div className="col-span-1 flex h-full flex-col justify-between md:border-r md:border-[#E4E4E4]">
            <div className="px-4 pt-6">
              <Text className="mb-4 text-xl font-bold">Version History</Text>

              <ul className="h-full overflow-auto md:max-h-[515px]">
                {transcriptHistory.length === 0 && (
                  <li className="mb-1">
                    {isLoadingTranscriptHistory ? (
                      <div className="flex flex-col gap-2">
                        <Loading isLight width={200} height={50} />
                        <Loading isLight width={200} height={50} />
                        <Loading isLight width={200} height={50} />
                      </div>
                    ) : (
                      <Text className="px-4 py-2 text-center">No version yet</Text>
                    )}
                  </li>
                )}
                {transcriptHistory.map((item, index) => {
                  if (index === 0)
                    return (
                      <li className="mb-1" key={item.updatedAt}>
                        <Button
                          onClick={() => handleVersionChange(index)}
                          className={`
                        ${
                          chosenVersion === index ? 'bg-gray-100' : 'hover:bg-gray-50'
                        } w-full rounded-md px-4 py-2 text-left text-sm font-normal normal-case`}
                        >
                          <Text>{formatUpdatedAtDate(item.updatedAt)}</Text>
                          <div className="flex items-center space-x-1.5">
                            <span className="size-2 rounded-full bg-[#188149]" />
                            <Text className="text-[#56585E]">Current Version</Text>
                          </div>
                        </Button>
                      </li>
                    );

                  return (
                    <li className="mb-1" key={item.updatedAt}>
                      <Button
                        onClick={() => handleVersionChange(index)}
                        className={`${
                          chosenVersion === index ? 'bg-gray-100' : 'hover:bg-gray-50'
                        } w-full rounded-md p-3 text-left text-sm font-normal normal-case`}
                      >
                        <Text>{formatUpdatedAtDate(item.updatedAt)}</Text>
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="border-t p-4">
              <Button
                className="w-full"
                variant={buttons.variant.primary}
                onClick={() => setIsConfirmRestoreModalOpen(true)}
                disabled={chosenVersion === 0}
              >
                Restore
              </Button>
            </div>
          </div>

          {/* Main Content */}
          <div className="font-sfMono col-span-3 px-4">
            <div className="mt-3 flex flex-row-reverse items-center justify-between">
              <ChangesNavigator diffIndex={diffIndex} scrollPositions={scrollPositions} handleScroll={handleScroll} />

              <VersionedContentSelector
                versionedContentList={versionedContentList}
                onSelectVersionedContent={handleContentChange}
              />
            </div>
            <div className={!versionedContentList.length ? 'mt-16' : 'mt-8'}>
              <div className="h-full break-words pb-14 pr-10 text-lg md:h-[600px] md:overflow-auto" onScroll={onScroll}>
                {isLoadingTranscriptHistory ? (
                  <div className="flex h-full items-center justify-center">
                    <Spinner size="small" color="black" className="border-gray-500" />
                  </div>
                ) : (
                  <DiffTranscriptComponent
                    scrollPositions={scrollPositions}
                    isMarkdown={isMarkdown}
                    transcript={transcriptHistory[chosenVersion]?.transcript}
                    itemDiffs={transcriptHistory[chosenVersion]?.diff}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const ChangesNavigator = ({ diffIndex, scrollPositions, handleScroll }: ChangesNavigatorProps) => {
  const [scrollPositionsLength, setScrollPositionsLength] = useState(0);

  useEffect(() => {
    setScrollPositionsLength(scrollPositions.current.length);
  }, [scrollPositions.current]);

  return (
    <div className="mr-16 flex gap-2">
      <Button variant={buttons.variant.tertiary} disabled={diffIndex <= 0} onClick={() => handleScroll(-1)}>
        <ArrowUp />
      </Button>
      <Button
        variant={buttons.variant.tertiary}
        disabled={diffIndex >= scrollPositionsLength - 1}
        onClick={() => handleScroll(1)}
      >
        <ArrowDown />
      </Button>
    </div>
  );
};

const VersionedContentSelector = ({
  versionedContentList,
  onSelectVersionedContent,
}: VersionedContentSelectorProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState<VersionedContent | null>(
    versionedContentList.length ? versionedContentList[0] : null
  );

  if (!versionedContentList.length || !onSelectVersionedContent) return null;

  const handleContentSelect = (item: VersionedContent) => {
    setIsDropdownOpen(false);
    setSelectedContent(item);
    onSelectVersionedContent(item.id);
  };

  return (
    <div className="w-full max-w-sm">
      <div className="relative">
        <Button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          className="flex w-full items-center justify-between rounded bg-white px-4 py-2 text-left normal-case"
        >
          <div className="flex w-5/6 items-center gap-3">
            <BulletList />
            <Text className="w-5/6 break-words">{selectedContent?.title || 'Select chapter'}</Text>
          </div>
          <ChevronDown
            fill="#333"
            className={cn(isDropdownOpen && 'rotate-180', 'transition-transform duration-200')}
          />
        </Button>

        {isDropdownOpen && (
          <div className="absolute z-10 mt-1 max-h-64 w-full overflow-y-auto overflow-x-hidden rounded-md border border-gray-200 bg-white shadow-lg">
            {versionedContentList.map((item) => (
              <Button
                key={item.id}
                onClick={() => handleContentSelect(item)}
                className={cn(
                  'w-full px-4 py-2 text-left normal-case hover:bg-gray-50',
                  selectedContent?.id === item.id ? 'bg-gray-100' : ''
                )}
              >
                <Text className="text-sm">{item.title}</Text>
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const CloseIconModal = () => {
  return (
    <div className="rounded-full border  p-2">
      <div className="size-3">
        <CrossedCloseResizable />
      </div>
    </div>
  );
};

const ConfirmModal = ({ isOpen, isSavingTranscriptVersion, onClose, handleRestore }: ConfirmModalProps) => {
  if (!isOpen) return null;

  return (
    <Modal className="z-[999]" type="default" isOpen={isOpen} onClose={onClose} noPadding>
      <Text className="text-3xl font-bold">Confirm Restore</Text>

      <Text className="my-4 text-base" variant={typography.body_small}>
        Are you sure you want to restore this version of the transcript? This will replace the current transcript with
        the selected version.
      </Text>

      <div className="mt-5 flex justify-end gap-4">
        <Button variant={buttons.variant.secondary} onClick={onClose}>
          Cancel
        </Button>
        <ActionButton text="Restore" onClick={handleRestore} isLoading={isSavingTranscriptVersion} />
      </div>
    </Modal>
  );
};

const DiffTranscriptComponent = ({
  itemDiffs,
  transcript,
  isMarkdown,
  scrollPositions,
}: DiffTranscriptComponentProps) => {
  const handleRef = (el: HTMLDivElement | null) => {
    if (!el || scrollPositions.current.includes(el)) return;

    scrollPositions.current = [...scrollPositions.current, el];
  };

  if (isMarkdown) {
    return <MarkdownDiffTranscriptComponent itemDiffs={itemDiffs} transcript={transcript} handleRef={handleRef} />;
  }

  if (!itemDiffs?.length) {
    return <Text className="font-sfMono whitespace-pre-line leading-7">{transcript}</Text>;
  }

  return (
    <div>
      {itemDiffs.map((itemDiff, index) => {
        const key = hashCode(`${itemDiff.value}-${index}`);
        const { value } = itemDiff;

        if (itemDiff.added) {
          return (
            <div ref={handleRef} key={key} className="inline">
              <Text className="font-sfMono inline select-text whitespace-pre-line bg-[#ECF9ED] leading-7 text-[#188149]">
                {value}
              </Text>
            </div>
          );
        }

        if (itemDiff.removed) {
          return (
            <div ref={handleRef} key={key} className="inline">
              <Text className="font-sfMono inline select-text whitespace-pre-line bg-[#FEF0F0] leading-7 text-[#AA0E0F] line-through">
                {value}
              </Text>
            </div>
          );
        }

        return (
          <Text className="font-sfMono inline select-text whitespace-pre-line leading-7 text-[#0B0C0D]" key={key}>
            {value}
          </Text>
        );
      })}
    </div>
  );
};

const MarkdownDiffTranscriptComponent = ({
  itemDiffs,
  transcript,
  handleRef,
}: MarkdownDiffTranscriptComponentProps) => {
  if (!itemDiffs?.length) {
    return (
      <Markdown
        className="font-sfMono whitespace-pre-line leading-7"
        components={MARKDOWN_COMPONENTS_VERSION_HISTORY}
        remarkPlugins={[remarkGfm]}
      >
        {transcript}
      </Markdown>
    );
  }

  const directiveContent = itemDiffs
    .map((itemDiff, index) => {
      const key = `diff-${index}-${hashCode(itemDiff.value)}`;
      const { value } = itemDiff;

      // Add backslashes to escape and avoid erroneuos use of directive syntax
      const sanitizedValue = value.replace(/:(.*?)/g, '\\:$1');

      // Use directive syntax for the diff content
      if (itemDiff.added) {
        // if it includes a new line, we need to use the paragraph syntax
        if (itemDiff.value.includes('\n')) {
          return `\n:::diff-added{dataKey="${key}"}\n${sanitizedValue}\n:::\n`;
        }
        return `:diff-added[${sanitizedValue}]{dataKey="${key}"} `;
      }

      if (itemDiff.removed) {
        if (itemDiff.value.includes('\n')) {
          return `\n:::diff-removed{dataKey="${key}"}\n${sanitizedValue}\n:::\n`;
        }
        return `:diff-removed[${sanitizedValue}]{dataKey="${key}"} `;
      }

      return sanitizedValue;
    })
    .join('');

  const directiveComponents = React.useMemo(() => createDirectiveComponents(handleRef), []);

  return (
    <div className="whitespace-pre-line">
      <Markdown
        className="font-sfMono whitespace-pre-line leading-7"
        components={directiveComponents}
        remarkPlugins={[remarkGfm, remarkDirective, remarkDirectiveRehype]}
      >
        {directiveContent}
      </Markdown>
    </div>
  );
};
