import React from 'react';

import CheckIcon from '@pray/shared/icons/CheckIcon/CheckIcon';
import { cn } from '@pray/shared/utils/styles';

import { ACTIVE_BORDER_CLASS, INACTIVE_BORDER_CLASS, INDICATOR_SIZE } from './constants';
import RoundedNumber from './RoundedNumber';

type StepIndicatorProps = {
  /** Whether the step is completed */
  isCompleted: boolean;
  /** Whether the step is active */
  isActive: boolean;
  /** The index of the step (0-based) */
  index: number;
};

/**
 * Step indicator component (either CheckIcon or RoundedNumber)
 * Displays a check icon for completed steps or a numbered indicator for active/inactive steps
 */
export default function StepIndicator({ isCompleted, isActive, index }: StepIndicatorProps) {
  if (isCompleted) {
    return <CheckIcon className={cn(INDICATOR_SIZE, 'rounded-full bg-black p-1')} />;
  }

  return (
    <RoundedNumber
      number={index + 1}
      isActive={isActive}
      size={INDICATOR_SIZE}
      activeBorderClass={ACTIVE_BORDER_CLASS}
      inactiveBorderClass={INACTIVE_BORDER_CLASS}
    />
  );
}
