import type { LeadListResponseContacts, LeadListResponse } from '../types';

type LeadListLeads = {
  respondedAt: string;
  object: 'list';
  lastItemIdentifier: unknown;
  count: number;
  contacts: LeadListResponseContacts[];
};

export const leadListLeadsMapper = (data: LeadListResponse): LeadListLeads => {
  return {
    respondedAt: data?.responded_at,
    object: data?.object,
    lastItemIdentifier: data?.last_item_identifier,
    count: data?.data_count,
    contacts: data?.data,
  };
};
