import api from '@pray/shared/utils/api';

export async function putArtistLocales({ artistId = '', locales = [] }) {
  if (!artistId) throw Error('Artist ID is required');

  const data = {
    locales,
  };

  const url = `/web/studio/artists/${artistId}/locales`;
  const response = await api.executePutRequest(url, data);
  return response.data;
}
