import React from 'react';

import Button from '../Button/Button';
import Link from '../Link/Link';
import Text from '../Text/Text';

import styles from './Chip.module.scss';

/**
 * Custom props for the Chip component
 * @typedef CustomProps
 * @property {boolean} [selected] Whether the chip is in a selected state
 * @property {boolean} [disabled] Whether the chip is in a disabled state
 * @property {string} [to] Internal route path that transforms the chip into a Link component
 * @property {string} [href] External URL that transforms the chip into a Link component
 * @property {string} [className] Custom CSS classes for additional styling
 * @property {React.ReactNode} [children] Content to render inside the chip
 * @property {() => void} [onClick] Function called when chip is clicked (renders as Button if provided)
 */

/**
 * Props for the Chip component
 * @typedef {CustomProps & React.ComponentPropsWithoutRef<'div'>} ChipProps
 */

/**
 * A versatile chip component that can render as a button, link, or static element
 *
 * | Property | Type | Description |
 * | --- | --- | --- |
 * | selected | boolean | When true, applies selected visual state to the chip |
 * | disabled | boolean | When true, prevents interaction and applies disabled styling |
 * | children | string \| React.ReactNode | Content to render inside the chip (text or other React elements) |
 * | className | string | Custom CSS classes to apply additional styling |
 * | to | string | Internal route path that transforms the chip into a Link component |
 * | href | string | External URL that transforms the chip into a Link component |
 * | onClick | function | Click handler function. When provided, renders chip as a Button component |
 *
 * ```jsx
 * import Chip from '@pray/shared/components/ui/Chip';
 *
 * // Basic chip
 * <Chip>Static Chip</Chip>
 *
 * // Selected state
 * <Chip selected>Selected Chip</Chip>
 *
 * // As a link
 * <Chip to="/some-route">Internal Link</Chip>
 * <Chip href="https://example.com">External Link</Chip>
 *
 * // As a button
 * <Chip onClick={() => console.log('clicked')}>
 *   Clickable Chip
 * </Chip>
 *
 * // Disabled state
 * <Chip disabled>Disabled Chip</Chip>
 * ```
 */
export default function Chip({ selected = false, children, className, ...props }) {
  const { to, href, disabled, onClick } = props;

  let Component = null;

  if (to || href) {
    Component = Link;
  } else if (onClick) {
    Component = Button;
  } else {
    Component = 'div';
  }

  const label = typeof children === 'string' ? <Text className={styles.label}>{children}</Text> : children;

  const classes = [styles.chip, className];

  if (selected) classes.push(styles.selected);

  if (disabled) classes.push(styles.disabled);

  return (
    // @ts-ignore
    <Component className={classes.join(' ')} {...props}>
      {label}
    </Component>
  );
}
