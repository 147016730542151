import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from 'context/StudioContext';
import useEmailCampaign from 'hooks/emailCampaigns/useEmailCampaign';
import useEmailCampaignStatus from 'hooks/emailCampaigns/useEmailCampaignStatus';
import useSaveEmailCampaign from 'hooks/emailCampaigns/useSaveEmailCampaign';

import {
  audience,
  automationTypes,
  draftCampaignName,
  emailStatus,
  nameMaxLength,
  previewTextMaxLength,
  publishTypes,
  subjectMaxLength,
  tempEmailPreviewImgUrl,
} from '../constants';

export default function useCreateEditEmailPage() {
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEmailDesignerModalOpen, setIsEmailDesignerModalOpen] = useState(false);
  const [isEmailRequiredModalOpen, setIsEmailRequiredModalOpen] = useState(false);
  const [isReviewAndPublishModalOpen, setIsReviewAndPublishModalOpen] = useState(false);
  const [isUpdateEmailAutomationWarningModalOpen, setIsUpdateEmailAutomationWarningModalOpen] = useState(false);
  const [isConfirmDeactivationModalOpen, setIsConfirmDeactivationModalOpen] = useState(false);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [templateForPreview, setTemplateForPreview] = useState(null);
  const [isSendingTestEmail, setIsSendingTestEmail] = useState(false);
  const isTemplatePreviewModalOpen = !!templateForPreview;

  const { isEmailStatusActive } = useEmailCampaignStatus(artistId);
  const { saveEmailCampaign } = useSaveEmailCampaign();

  const form = useForm({
    defaultValues: {
      id: null,
      name: '',
      status: emailStatus.scheduled,
      audience,
      subjectText: '',
      previewText: '',
      previewImgUrl: '',
      publishType: publishTypes.schedule,
      scheduledAtDate: '',
      scheduledAtTime: '',
      rawHtml: '',
      rawCss: '',
      compiledHtmlCss: '',
      htmlCssUpdatedAt: '',
    },
  });

  // IMP: During creation, the Campaign ID isn't present in the param even
  // though it's present in the form after saving the draft.
  // So, we need to get the Campaign ID from the form if it's not present in the params.
  const { id: paramCampaignId } = useParams();
  const campaignId = paramCampaignId ?? form.getValues('id');
  const { data: emailCampaign } = useEmailCampaign(artistId, campaignId);

  useEffect(() => {
    if (!emailCampaign) {
      const now = DateTime.local();
      form.setValue('scheduledAtDate', now.toISODate());
      form.setValue('scheduledAtTime', now.toISOTime({ includeOffset: false }).slice(0, 5));
      return;
    }

    let status = emailStatus.now;
    let scheduledAtDate = '';
    let scheduledAtTime = '';

    if (emailCampaign.scheduledAt) {
      const scheduledAt = DateTime.fromISO(emailCampaign.scheduledAt);
      scheduledAtDate = scheduledAt.toISODate();
      scheduledAtTime = scheduledAt.toISOTime({ includeOffset: false }).slice(0, 5);
      status = emailStatus.scheduled;
    }

    if (emailCampaign.automationType) {
      status = emailCampaign.status === emailStatus.draft ? emailStatus.active : emailCampaign.status;
      scheduledAtDate = DateTime.now().toISODate();
      scheduledAtTime = DateTime.now().toISOTime({ includeOffset: false }).slice(0, 5);
    }

    form.reset({
      id: emailCampaign.id,
      name: emailCampaign.name || '',
      status,
      audience,
      subjectText: emailCampaign.subjectText || '',
      previewText: emailCampaign.previewText || '',
      previewImgUrl: emailCampaign.previewImgUrl || '',
      publishType: emailCampaign.automationType ? publishTypes.automation : publishTypes.schedule,
      scheduledAtDate,
      scheduledAtTime,
      rawHtml: emailCampaign.rawHtml || '',
      rawCss: emailCampaign.rawCss || '',
      compiledHtmlCss: emailCampaign.compiledHtmlCss || '',
      htmlCssUpdatedAt: emailCampaign.htmlCssUpdatedAt || '',
    });
  }, [emailCampaign]);

  const formData = form.watch();
  const isAutomation = formData.publishType === publishTypes.automation;
  const isDraft = !emailCampaign?.status || emailCampaign?.status === emailStatus.draft;

  async function saveEmailChanges({ status = emailCampaign?.status } = {}) {
    const formData = form.getValues();

    const data = {
      campaignId: formData.id,
      name: formData.name || draftCampaignName,
      status: status || formData.status || emailStatus.draft,
      automationType: formData.publishType === publishTypes.automation ? automationTypes.newMinistryMatchLead : null,
      subjectText: formData.subjectText,
      previewText: formData.previewText,
      previewImgUrl: formData.previewImgUrl || tempEmailPreviewImgUrl,
      rawHtml: formData.rawHtml,
      rawCss: formData.rawCss,
      compiledHtmlCss: formData.compiledHtmlCss,
    };

    if (!formData.id) {
      data.status = emailStatus.draft;
    }

    if (formData.status === emailStatus.scheduled) {
      const scheduledAt = DateTime.fromISO(`${formData.scheduledAtDate}T${formData.scheduledAtTime}:00`);
      data.scheduledAt = scheduledAt.toUTC().toISO();
    } else if ([emailStatus.active, emailStatus.stopped, emailStatus.now].includes(formData.status)) {
      data.scheduledAt = null;
    }

    if (data.status === emailStatus.draft) {
      if (!data.scheduledAt) {
        const scheduledAt = DateTime.local().set({ second: 0, millisecond: 0 });
        data.scheduledAt = scheduledAt.toUTC().toISO();
      }
    }

    try {
      if (formData.name.length > nameMaxLength) {
        throw new Error('Email name is too long', { cause: 'name' });
      }

      if (formData.subjectText.length > subjectMaxLength) {
        throw new Error('Email subject is too long', { cause: 'subjectText' });
      }

      if (formData.previewText.length > previewTextMaxLength) {
        throw new Error('Email preview text is too long', { cause: 'previewText' });
      }

      setIsSaving(true);
      const campaign = await saveEmailCampaign({ artistId, ...data });
      setIsSaving(false);

      if (!formData.id) {
        form.setValue('id', campaign.id);
      }

      return true;
    } catch (error) {
      logger.error(error);

      if (error.cause) {
        form.setError(error.cause, { message: error.message });
      }

      throw error;
    }
  }

  async function saveEmailDraft() {
    setIsLoading(true);

    try {
      await saveEmailChanges({ status: emailStatus.draft });
      toast.show({ success: 'Email draft saved' });
      navigate(-1);
    } catch (error) {
      logger.error(error);
      toast.show({ error: 'There was an error saving your email draft' });
    } finally {
      setIsLoading(false);
    }
  }

  async function saveEmailDesign({ rawHtml, rawCss, compiledHtmlCss }) {
    form.setValue('rawHtml', rawHtml);
    form.setValue('rawCss', rawCss);
    form.setValue('compiledHtmlCss', compiledHtmlCss);

    saveEmailChanges();
  }

  async function handleSendEmail() {
    const { rawHtml, rawCss, compiledHtmlCss } = formData;

    if (!rawHtml || !rawCss || !compiledHtmlCss) {
      form.setError('rawHtml', { message: 'Missing email content' });
      return;
    }

    form.clearErrors('rawHtml');

    const isFormValid = Object.keys(form.formState.errors).length === 0;

    if (isFormValid && isValidScheduledDate()) {
      if (!isDraft && isAutomation) {
        openUpdateEmailAutomationWarningModal();
      } else {
        openReviewAndPublishModal();
      }
    } else {
      toast.show({ error: 'Please fix the errors in the form' });
    }
  }

  async function sendEmailCampaign() {
    setIsLoading(true);

    const { status } = formData;
    try {
      await saveEmailChanges({ status });
      const action = status === emailStatus.now ? 'sent' : 'scheduled';
      toast.show({ success: `Your email "${formData.name}" has been ${action}` });
      navigate(-1);
    } catch (error) {
      logger.error(error);
      let errorMsg = 'There was an error sending your email';
      if (error.name === 'AxiosError' && error?.response) {
        errorMsg = error.response.data?.data[0]?.info ?? errorMsg;
      }

      toast.show({ error: errorMsg });
    } finally {
      setIsLoading(false);
    }
  }

  function isValidScheduledDate() {
    if (
      formData.publishType === publishTypes.automation ||
      (formData.publishType === publishTypes.schedule && formData.status === emailStatus.now)
    ) {
      return true;
    }

    const scheduledAt = `${formData.scheduledAtDate}T${formData.scheduledAtTime}:00`;
    const scheduledDate = DateTime.fromFormat(formData.scheduledAtDate, 'yyyy-MM-dd');
    const scheduledDateTime = DateTime.fromISO(scheduledAt);
    const localDateTime = DateTime.local();

    if (scheduledDateTime > localDateTime) return true;

    const isToday = scheduledDate >= localDateTime.startOf('day');

    if (isToday) {
      form.setError('scheduledAtTime', { message: 'Please select a future time' });
    } else {
      form.setError('scheduledAtDate', { message: 'Please select a future date' });
    }

    return false;
  }

  async function handleDesignEmail() {
    await saveEmailChanges();

    const emailHasContent = formData.rawHtml || formData.rawCss;

    if (!emailHasContent) {
      openSelectTemplateModal();
    } else {
      openEmailDesignerModal();
    }
  }

  function handleOpenTestModal() {
    const { id, subjectText, compiledHtmlCss } = formData;
    const isFormValid = id && subjectText && compiledHtmlCss;

    if (isFormValid) {
      openSendTestModal();
    } else {
      openEmailRequiredModal();
    }
  }

  async function handleChangeAutomationStatus(status) {
    try {
      await saveEmailChanges({ status });

      toast.show({ success: `Email automation has been ${status === emailStatus.active ? 'resumed' : 'stopped'}` });
    } catch {
      toast.show({ error: 'There was an error while updating the automation status' });
    } finally {
      setIsConfirmDeactivationModalOpen(false);
    }
  }

  async function handleSendTestEmail(emails) {
    const campaignId = formData.id;

    if (!campaignId) return;

    try {
      setIsSendingTestEmail(true);
      await studioService.emailCampaigns.sendTestEmail({ artistId, campaignId, emails });
      toast.show({ success: 'Your test email has been sent' });
    } catch {
      toast.show({ error: 'There was an error sending your test email' });
    } finally {
      setIsSendingTestEmail(false);
      closeSendTestModal();
    }
  }

  function selectTemplate(template) {
    form.setValue('rawHtml', template.rawHtml);
    form.setValue('rawCss', template.rawCss);
    closeTemplatesModal();
    openEmailDesignerModal();
  }

  const closeEmailDesignerModal = () => setIsEmailDesignerModalOpen(false);
  const closeEmailRequiredModal = () => setIsEmailRequiredModalOpen(false);
  const closeReviewAndPublishModal = () => setIsReviewAndPublishModalOpen(false);
  const closeUpdateEmailAutomationWarningModal = () => setIsUpdateEmailAutomationWarningModalOpen(false);
  const closeConfirmDeactivationModal = () => setIsConfirmDeactivationModalOpen(false);
  const closeSendTestModal = () => setIsSendTestModalOpen(false);
  const closeTemplatePreviewModal = () => setTemplateForPreview(null);
  const closeTemplatesModal = () => setIsTemplatesModalOpen(false);
  const openEmailDesignerModal = () => setIsEmailDesignerModalOpen(true);
  const openEmailRequiredModal = () => setIsEmailRequiredModalOpen(true);
  const openReviewAndPublishModal = () => setIsReviewAndPublishModalOpen(true);
  const openUpdateEmailAutomationWarningModal = () => setIsUpdateEmailAutomationWarningModalOpen(true);
  const openConfirmDeactivationModal = () => setIsConfirmDeactivationModalOpen(true);
  const openSelectTemplateModal = () => setIsTemplatesModalOpen(true);
  const openSendTestModal = () => setIsSendTestModalOpen(true);
  const selectTemplateForPreview = (template) => setTemplateForPreview(template);

  return {
    state: {
      form,
      formData,
      artistId,
      templateForPreview,
      isEmailDesignerModalOpen,
      isLoading,
      isSaving,
      isDraft,
      isAutomation,
      isEmailStatusActive,
      isTemplatesModalOpen,
      isSendTestModalOpen,
      isEmailRequiredModalOpen,
      isReviewAndPublishModalOpen,
      isUpdateEmailAutomationWarningModalOpen,
      isConfirmDeactivationModalOpen,
      isTemplatePreviewModalOpen,
      isSendingTestEmail,
    },
    actions: {
      closeEmailDesignerModal,
      closeEmailRequiredModal,
      closeReviewAndPublishModal,
      closeUpdateEmailAutomationWarningModal,
      closeConfirmDeactivationModal,
      closeTemplatePreviewModal,
      closeSendTestModal,
      closeTemplatesModal,
      handleDesignEmail,
      handleSendEmail,
      handleOpenTestModal,
      handleSendTestEmail,
      handleChangeAutomationStatus,
      openReviewAndPublishModal,
      openUpdateEmailAutomationWarningModal,
      openConfirmDeactivationModal,
      openSelectTemplateModal,
      saveEmailDesign,
      saveEmailDraft,
      selectTemplate,
      selectTemplateForPreview,
      sendEmailCampaign,
    },
  };
}
