import api from '@pray/shared/utils/api';

export const getDailyItemById = async ({ artistId = null, dailySeriesId = null, dailyItemId = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series ID is required');
  if (!dailyItemId) throw Error('Daily Item ID is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}/daily-items/${dailyItemId}`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
