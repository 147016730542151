import api from '@pray/shared/utils/api';

export async function getArtistCoverArt({ artistId }) {
  if (!artistId) throw Error('Artist ID is required ');

  const url = `/web/studio/artists/${artistId}/cover-arts`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
