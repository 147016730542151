import React from 'react';

import Loading from '@pray/shared/components/Loading/Loading';

export default function LoadingContentPage() {
  return (
    <div className="m-10 flex flex-col">
      <div className="flex flex-row justify-between">
        <Loading isLight height={80} width={250} />
        <Loading isLight height={50} width={120} />
      </div>

      <div className="mt-6">
        <Loading isLight height={500} />
      </div>
    </div>
  );
}
