import api from '@pray/shared/utils/api';

export async function getTranscriptionList({ artistId = null, contentId = null, locale = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/transcripts`;
  const response = await api.executeGetRequest(url, {});

  return response.data;
}
