import { randomId } from '@pray/shared/utils';
import eventTracking from '@pray/shared/utils/eventTracking';
import logger from '@pray/shared/utils/logger';

import { eventPropsToObject, getClosestParentAttribute } from './eventUtils';

const lastClickHandler = {};

function shouldProcessClickEvent(element) {
  if (element) {
    const eventId = element.getAttribute('data-tapeventadded');
    const lastExecution = lastClickHandler[eventId];
    if (!lastExecution || Date.now() - lastExecution > 20) {
      lastClickHandler[eventId] = Date.now();
      return true;
    }
  }
  return false;
}

function isTargetElement(element) {
  if (!element) return false;

  return /^a$/i.test(element.tagName) || element.type === 'submit' || element.onclick;
}

function getClickTargetElement(element) {
  if (!element) return null;

  if (isTargetElement(element)) return element;

  if (element.nodeName && element.nodeName.toLowerCase() !== 'body') {
    return getClickTargetElement(element.parentNode);
  }

  return null;
}

function clickEventHandler(event) {
  const element = getClickTargetElement(event.target || event.srcElement);
  if (shouldProcessClickEvent(element)) {
    const tapEventName = element.getAttribute('data-tapeventname');
    const isSendDefaultsToFb = element.getAttribute('data-tap-issenddefaultstofb') || false;
    const eventProps = eventPropsToObject(getClosestParentAttribute(element, 'data-eventprops'));
    const buttonText = element.textContent?.trim() || element.innerText?.trim();
    const buttonName = tapEventName || buttonText;

    if (buttonText) eventProps.button_text = buttonText;

    if (!eventProps.view_name) {
      const viewName = getClosestParentAttribute(element, 'data-viewname');
      if (viewName) eventProps.view_name = viewName;
    }

    eventTracking.trackEventTap(buttonName, null, null, isSendDefaultsToFb, eventProps);

    logger.debug('tapEventName:', buttonName);
    if (eventProps) logger.debug(' - tapEventProps:', eventProps);
  }

  return true;
}

export function handleAddClickListener(element) {
  if (isTargetElement(element) && !element.getAttribute('data-tapeventadded')) {
    element.addEventListener('click', clickEventHandler);
    element.setAttribute('data-tapeventadded', randomId());
  }
}

export function handleRemoveClickListener(element) {
  if (element.getAttribute('data-tapeventadded')) {
    element.removeEventListener('click', clickEventHandler);
    element.removeAttribute('data-tapeventadded');
  }
}

export function addTapEventListener(element) {
  handleAddClickListener(element);
  if (element.querySelectorAll) {
    element.querySelectorAll('*').forEach(handleAddClickListener);
  }
}

export default {
  addTapEventListener,
  handleAddClickListener,
  handleRemoveClickListener,
};
