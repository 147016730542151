import React from 'react';
import { useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { copyToClipboard } from '@pray/shared/utils';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import usePlaylistDetails from 'hooks/usePlaylistDetails';

import TabPage from '../../../components/TabPage/TabPage';

export default function RssFeedDistribution() {
  const toast = useToastMessage();
  const { id: contentSeriesId } = useParams();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { playlist } = usePlaylistDetails({ artistId, contentSeriesId });

  function handleCopyUrl() {
    copyToClipboard(playlist.rss_feed_url, () => {
      toast.show({ success: 'Link copied!' });
    });
  }

  return (
    <TabPage title="Distribution" data-viewname={VIEW_EVENT_NAMES.SERIES.RSS_SERIES_DISTRIBUTION}>
      <div className="flex max-w-3xl flex-col gap-12">
        <Text>
          Make your Series available as a podcast to the world by linking your secure RSS Feed to the platforms your
          listeners use.
        </Text>

        <DistributionForm playlist={playlist} onCopyUrl={handleCopyUrl} />
      </div>
    </TabPage>
  );
}

function DistributionForm({ playlist, onCopyUrl }) {
  return (
    <div className="flex flex-col gap-2">
      <Text className="font-bold">Your RSS Feed</Text>
      <div className="flex items-center justify-between">
        <Text className="h-12 flex-1 truncate rounded-md rounded-r-none border border-r-0 border-gray-400 px-4 py-3 font-medium">
          {playlist.rss_feed_url}
        </Text>
        <Button
          variant={buttons.variant.primary}
          className="flex w-28 justify-center rounded-l-none py-3"
          onClick={onCopyUrl}
        >
          Copy
        </Button>
      </div>
    </div>
  );
}
