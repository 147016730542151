import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';
import storage from '@pray/shared/utils/storage';

export async function getArtists({
  search = '',
  offset = 0,
  sortColumn = '',
  sortDirection = 'asc',
  options = {},
} = {}) {
  const params = {};

  if (search) {
    params.search = search;
  } else if (offset) {
    params.offset = offset;
  }

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
}

export function useArtists({ search = '', offset = 0, sortColumn = '', sortDirection = 'asc' } = {}) {
  const user = storage.getUser();

  const query = useQuery({
    enabled: !!user?.is_registered,
    queryKey: queryKeys.artists({ search, offset, sortColumn, sortDirection }),
    queryFn: () => getArtists({ search, offset, sortColumn, sortDirection }),
  });

  return query;
}
