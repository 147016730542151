import React, { useEffect } from 'react';

export default function Dialog({ children, width = '480px', onEscapeKeyDown = null, onBackdropClick = null }) {
  function handleEscapeKey(event) {
    if (event.key === 'Escape') onEscapeKeyDown?.();
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey);
    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [handleEscapeKey]);

  return (
    <div className="fixed inset-0 z-[100]">
      <div className="fixed inset-0 bg-black opacity-45" onClick={onBackdropClick} role="none" />
      <div
        style={{ width }}
        className="relative left-1/2 top-1/2 flex w-[480px] -translate-x-1/2 -translate-y-1/2 flex-col rounded-2xl bg-white opacity-100"
      >
        <div className="m-6 flex flex-col gap-4">{children}</div>
      </div>
    </div>
  );
}
