import { DateTime } from 'luxon';
import { useEffect, useRef, useState } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import useHasScrollTop from '@pray/shared/hooks/useHasScrollTop';

import { ChevronDown } from 'images/icons';
import { cn } from 'styles/utils';

import WeekDayCell from './WeekDayCell';
import EventCell from '../components/EventCell';
import TimeCell from '../components/TimeCell';

const ALL_DAY_EVENTS_LIMIT = 3;

export default function WeekView({ data: events = [], currentDate, renderEvent, onTitleUpdate, onDayClick }) {
  const [time, setTime] = useState(DateTime.now());

  const weekDays = [
    { date: currentDate.set({ weekday: 0 }) },
    { date: currentDate.set({ weekday: 1 }) },
    { date: currentDate.set({ weekday: 2 }) },
    { date: currentDate.set({ weekday: 3 }) },
    { date: currentDate.set({ weekday: 4 }) },
    { date: currentDate.set({ weekday: 5 }) },
    { date: currentDate.set({ weekday: 6 }) },
  ];

  const times = Array.from({ length: 24 }).map((_, i) => {
    return currentDate.set({ hour: i, minute: 0, second: 0 });
  });

  /** Update title when weekDays change */
  useEffect(() => {
    const weekStartMonth = weekDays[0].date.toFormat('MMMM');
    const weekEndMonth = weekDays[6].date.toFormat('MMMM');
    const isSameMonth = weekStartMonth === weekEndMonth;
    const month = isSameMonth ? weekStartMonth : `${weekStartMonth} - ${weekEndMonth}`;
    const title = `${month} ${currentDate.toFormat('yyyy')}`;
    onTitleUpdate(title);
  }, [weekDays]);

  /** Re-render every second to update time indicator */
  useEffect(() => {
    const interval = setInterval(() => {
      const now = DateTime.now();
      if (now !== time && now.second === 0) setTime(now);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <WeekViewTable data={events} weekDays={weekDays} times={times} renderEvent={renderEvent} onDayClick={onDayClick} />
  );
}

function WeekViewTable({ data: events = [], weekDays, times, renderEvent, onDayClick }) {
  const containerRef = useRef(null);
  const [highlightedDay, setHighlightedDay] = useState(null);
  const [isAllDayEventsExpanded, setIsAllDayEventsExpanded] = useState(false);

  const allDayEvents = events.filter((event) => {
    const isOngoingEvent = !event.endDate;
    const startDate = DateTime.fromISO(event.startDate);
    const endDate = DateTime.fromISO(event.endDate);

    return isOngoingEvent || !startDate.hasSame(endDate, 'day');
  });

  const sameDayEvents = events.filter((event) => {
    const startDate = DateTime.fromISO(event.startDate);
    const endDate = DateTime.fromISO(event.endDate);

    return startDate.hasSame(endDate, 'day');
  });

  const eventsByDateAndHour = sameDayEvents.reduce((acc, event) => {
    const startDate = DateTime.fromISO(event.startDate);
    const date = startDate.toFormat('yyyy-MM-dd');
    const hour = startDate.toFormat('HH');

    if (!acc[date]) acc[date] = {};
    if (!acc[date][hour]) acc[date][hour] = [];

    acc[date][hour].push(event);

    return acc;
  }, {});

  // detect if calendar is scrolled
  const isCalendarScrolled = useHasScrollTop(containerRef);

  return (
    <div ref={containerRef} className="relative h-[calc(100vh-170px)] overflow-y-auto overflow-x-hidden">
      <table className="w-full table-fixed" cellPadding={0} cellSpacing={0}>
        <thead className={cn('sticky top-0 z-[100] bg-white', isCalendarScrolled && 'shadow-md')}>
          <tr>
            <th className="w-16" />
            {weekDays.map(({ date }) => (
              <WeekDayCell
                key={date.get('weekdayShort')}
                date={date}
                highlightedDay={highlightedDay}
                onDayClick={onDayClick}
              />
            ))}
          </tr>
          <tr>
            <AllDayCell
              eventCount={allDayEvents.length}
              isExpanded={isAllDayEventsExpanded}
              onShowMoreClick={() => setIsAllDayEventsExpanded(!isAllDayEventsExpanded)}
            />
            {weekDays.map(({ date }) => {
              const events = allDayEvents.filter((event) => {
                const isOngoingEvent = !event.endDate;
                const isStartDateInRange = date >= DateTime.fromISO(event.startDate).startOf('day');
                return isStartDateInRange && isOngoingEvent;
              });

              return (
                <td key={date} className="h-12">
                  <div className="size-full border-y border-r">
                    <AllDayEventCard
                      date={date}
                      events={events.slice(0, isAllDayEventsExpanded ? events.length : ALL_DAY_EVENTS_LIMIT)}
                      renderEvent={renderEvent}
                    />
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {times.map((time, index) => {
            return (
              <tr key={time}>
                <TimeCell time={time} isVisible={index !== 0} />

                {weekDays.map(({ date }) => {
                  const day = date.get('day');

                  return (
                    <EventCell
                      key={`${day}-${time}`}
                      date={date}
                      time={time}
                      getEvents={(dt, hr) => eventsByDateAndHour[dt]?.[hr]}
                      renderEvent={renderEvent}
                      onMouseEnter={() => setHighlightedDay(day)}
                      onMouseLeave={() => setHighlightedDay(null)}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function AllDayCell({ eventCount, isExpanded, onShowMoreClick }) {
  return (
    <td className="h-12">
      <div className="flex size-full flex-col items-end justify-between border-y border-r">
        <Text className="pr-3 pt-1" variant={typography.footnote} color={colors.text_tertiary}>
          All-day
        </Text>
        {eventCount > ALL_DAY_EVENTS_LIMIT && (
          <Button className={cn('mr-1 p-2', isExpanded && 'rotate-180')} onClick={onShowMoreClick}>
            <ChevronDown />
          </Button>
        )}
      </div>
    </td>
  );
}

function AllDayEventCard({ events, date, renderEvent }) {
  return (
    <div className="flex flex-col gap-0.5">
      {events.map((event) => {
        const startDate = DateTime.fromISO(event.startDate);
        const endDate = DateTime.fromISO(event.endDate);
        const isOngoingEvent = !event.endDate;
        const weekDay = date.get('weekday');
        const isSaturday = weekDay === 6;
        const isSunday = weekDay === 7;
        const isEventStart = startDate.hasSame(date, 'day') || (isSunday && startDate <= date);
        const isEventEnd = endDate.hasSame(date, 'day');

        // calculate the event width
        const eventDurationInDays = isOngoingEvent ? 7 : endDate.diff(startDate, 'days').days || 1;
        const daysUntilSaturday = isSaturday ? 1 : 7 - weekDay || 7;
        const dayWidthInParcentage = Math.round(100 / 7);
        const eventWidth = dayWidthInParcentage * Math.min(daysUntilSaturday, eventDurationInDays);

        const eventProps = { isAllDay: true, isSaturday, isEventStart, isEventEnd };

        return (
          <div key={event.id} className="-mt-0.5 mb-0.5 h-[44px]">
            {isEventStart && (
              <div className="absolute" style={{ width: `${eventWidth}%` }}>
                {renderEvent({ event, date: startDate, ...eventProps })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
