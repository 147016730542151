import { buttons } from '@pray/shared/components/foundations';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { useAuth } from '@pray/shared/hooks/useAuth';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import PrayModuleDialog from 'components/pages/StudioPage/components/PrayModuleDialog/PrayModuleDialog';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';
import { Envelope } from 'images/icons';
import { Permissions } from 'utils/auth';

import EmailCampaignsTable from './EmailCampaignsTable';
import useEmailsListPage from './useEmailsListPage';
import SendTestEmailModal from '../components/Dialogs/SendTestEmailModal';
import EmailDesignerModal from '../components/EmailDesignerModal';
import TemplatePreviewModal from '../components/TemplatePreview/TemplatePreviewModal';
import TemplatesModal from '../components/TemplatesModal';

export default function EmailsListPage() {
  const appRoutes = useAppRoutes();
  const { permissions } = useAuth();
  const { state, actions } = useEmailsListPage();

  const {
    isLoading,
    selectedTemplate,
    emailCampaigns,
    emailCampaignsQuery,
    isEmailStatusActive,
    isTemplateDesignerModalOpen,
    isTemplatePreviewModalOpen,
    isTemplatesModalOpen,
    isSendTestModalOpen,
    isMinistryMatchModalOpen,
  } = state;

  const {
    // actions
    closeTemplateDesignerModal,
    closeTemplatesModal,
    closeTemplatePreviewModal,
    closeSendTestModal,
    closeMinistryMatchModal,
    openManageTemplatesModal,
    openSendTestModal,
    handleCloneTemplate,
    handleCreateTemplate,
    handleEditTemplate,
    handlePreviewTemplate,
    handleRenameTemplate,
    handleDeleteTemplate,
    handleSendTemplateTestEmail,
    saveTemplateDesign,
  } = actions;

  function renderActionButtons() {
    if (!isEmailStatusActive && !permissions.has(Permissions.STUDIO_ADMIN)) return null;

    return (
      <>
        <ActionButton text="Manage Templates" variant={buttons.variant.secondary} onClick={openManageTemplatesModal} />
        <ActionButton text="Create Email" to={appRoutes.artistEmailsCreate()} />
      </>
    );
  }

  return (
    <>
      <TabPage
        title="Emails"
        data-viewname={VIEW_EVENT_NAMES.EMAILS.CAMPAIGNS}
        topRightButtons={renderActionButtons()}
        titleInfo={<Info className="hidden size-5 text-[#56585E]" />}
      >
        {(() => {
          if (emailCampaignsQuery.isLoading) return null;

          if (!emailCampaignsQuery.isFetching && emailCampaigns.length === 0) {
            return (
              <EmptyState
                icon={<Envelope />}
                title="Create your first Email"
                subtitle='Click on "Create Email" to create your first Email'
              />
            );
          }

          return (
            <>
              <EmailCampaignsTable
                data={emailCampaigns}
                sortBy={emailCampaignsQuery.sortOrder}
                onSortChange={emailCampaignsQuery.setSortOrder}
              />
              {emailCampaignsQuery.hasNextPage && <InfiniteScroll fetchData={emailCampaignsQuery.fetchNextPage} />}
            </>
          );
        })()}
      </TabPage>

      {isMinistryMatchModalOpen && (
        <PrayModuleDialog
          title="Welcome to PRAY Studio Email"
          message="To start sending emails, you will need to turn on Ministry Match. To learn more, please contact one of our account executives to help you get started!"
          onDone={closeMinistryMatchModal}
        />
      )}

      {isTemplatesModalOpen && (
        <TemplatesModal
          onCreate={handleCreateTemplate}
          onClone={handleCloneTemplate}
          onEdit={handleEditTemplate}
          onPreview={handlePreviewTemplate}
          onClose={closeTemplatesModal}
        />
      )}

      {isTemplateDesignerModalOpen && (
        <EmailDesignerModal
          isTemplateDesigner
          id={selectedTemplate.id}
          name={selectedTemplate.name}
          html={selectedTemplate.rawHtml}
          css={selectedTemplate.rawCss}
          onSave={saveTemplateDesign}
          onClose={closeTemplateDesignerModal}
          onRenameTemplate={handleRenameTemplate}
          onDeleteTemplate={handleDeleteTemplate}
          onPreview={handlePreviewTemplate}
        />
      )}

      {isTemplatePreviewModalOpen && (
        <TemplatePreviewModal
          subjectText={selectedTemplate.name}
          html={selectedTemplate.compiledHtmlCss}
          onBack={closeTemplatePreviewModal}
          onSend={openSendTestModal}
        />
      )}

      {isSendTestModalOpen && (
        <SendTestEmailModal onCancel={closeSendTestModal} onSend={handleSendTemplateTestEmail} isLoading={isLoading} />
      )}
    </>
  );
}
