import Loading from '@pray/shared/components/Loading/Loading';

export default function DailySeriesLoadingState() {
  return (
    <div className="flex-col p-10">
      <Loading isLight isLoading width="100%" height={30} />

      <Loading isLight isLoading width="200px" height={50} />

      <Loading isLight isLoading width="100%" height={400} />
    </div>
  );
}
