import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export async function createArtist({
  name,
  handle,
  description,
  profileImageUrl,
  profileVerticalImageUrl,
  profileHorizontalImageUrl,
  facecoinImageUrl,
  isStudioAnalyticsEnabled,
  isVerified,
  isLocationSearchEnabled,
  googlePlaceId,
  locationName,
}) {
  if (!name) throw Error('Name is required');
  if (!handle) throw Error('Handle is required');

  // required fields
  const data = {
    name,
    handle,
    is_studio_analytics_enabled: isStudioAnalyticsEnabled || false,
    is_verified: isVerified || false,
    is_location_search_enabled: isLocationSearchEnabled || false,
  };

  // optional fields
  if (description) data.description = description || null;
  if (profileImageUrl) data.profile_image_url = profileImageUrl || null;
  if (profileVerticalImageUrl) data.profile_vertical_image_url = profileVerticalImageUrl || null;
  if (profileHorizontalImageUrl) data.profile_horizontal_image_url = profileHorizontalImageUrl || null;
  if (facecoinImageUrl) data.facecoin_image_url = facecoinImageUrl || null;
  if (googlePlaceId) data.google_place_id = googlePlaceId || null;
  if (locationName) data.location_name = locationName || null;

  const url = `/web/studio/artists`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}

export function useCreateArtist() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createArtist,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artists(),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    createArtist: mutateAsync,
    ...rest,
  };
}
