import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

export default function useSaveLeadClient() {
  const queryClient = useQueryClient();

  function validateClient(form, integration) {
    const errors = {};

    // validate client details
    if (!form.values.name.trim()) errors.name = 'Invalid name';
    if (!form.values.lead_integration_type.trim()) errors.lead_integration_type = 'Invalid integration';

    // validate integration setup
    integration?.fields.forEach((field) => {
      if (field.is_required && !form.values[field.property_name]) {
        errors[field.property_name] = `Invalid ${field.label}`;
      }
    });

    form.setErrors(errors);

    if (Object.keys(errors).length) throw Error('Invalid data');
  }

  async function uploadClientImage(form, clientId) {
    if (!form.values.image_file) return null;

    let imageUrl = null;

    try {
      const response = await s3Service.signAndUpload({
        file: form.values.image_file,
        type: 'leadClient',
        signParams: {
          lead_client_id: clientId || null,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      form.setError('image_url', err);
    }

    return imageUrl;
  }

  async function createClient(form, clientData) {
    const { data } = await studioService.leadClients.createLeadClient(clientData);

    if (!form.values.image_file) return;

    Object.assign(clientData, data);

    const imageUrl = await uploadClientImage(form, clientData.id);

    await updateClient(form, { id: clientData.id, imageUrl });
  }

  async function updateClient(form, clientData) {
    const imageUrl = await uploadClientImage(form, clientData.id);

    if (imageUrl) {
      clientData.imageUrl = imageUrl;
    }

    await studioService.leadClients.updateLeadClient(clientData);
  }

  async function saveLeadClient({ form, selectedIntegration }) {
    // validate client required fields
    validateClient(form, selectedIntegration);

    // get integration setup fields
    const leadIntegrationDetails = selectedIntegration.fields.reduce((result, field) => {
      result[field.property_name] = form.values[field.property_name];
      return result;
    }, {});

    // save or create client based on id field
    const clientData = {
      id: form.values.id,
      name: form.values.name,
      leadIntegrationType: form.values.lead_integration_type,
      leadIntegrationDetails,
      artistId: form.values.artist_id,
    };

    if (!clientData.id) {
      await createClient(form, clientData);
    } else {
      await updateClient(form, clientData);
    }

    return clientData;
  }

  const mutation = useMutation({
    mutationFn: saveLeadClient,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadClients(),
      });
    },
  });

  const { mutateAsync, isPending, ...rest } = mutation;

  return {
    saveLeadClient: mutateAsync,
    isLoading: isPending,
    ...rest,
  };
}
