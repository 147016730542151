import React, { useState } from 'react';

import { colors, typography, buttons } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { isValidEmail } from '@pray/shared/utils/validation';

import { EmailDialogModal } from './EmailDialogModal';

export default function SendTestEmailModal({ onCancel, onSend, isLoading }) {
  const [emailAddresses, setEmailAddresses] = useState('');
  const [error, setError] = useState('');

  const buttonRow = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: onCancel, disabled: isLoading },
    { label: 'Send', variant: buttons.variant.primary, action: validateInput, disabled: isLoading, loading: isLoading },
  ];

  function handleInputChange(event) {
    setError('');
    setEmailAddresses(event.target.value);
  }

  function validateInput() {
    const emails = emailAddresses.split(',').map((email) => email.trim());
    const invalidEmails = emails.filter((email) => !isValidEmail(email));

    if (invalidEmails.length > 0) {
      const validationError = 'Please enter valid email addresses';
      setError(validationError);
      return;
    }

    onSend(emails);
  }

  return (
    <EmailDialogModal
      title="Send a Test Email"
      description="Send a test email to your addresses."
      buttons={buttonRow}
      onClose={onCancel}
      isLoading={isLoading}
    >
      <div className="flex h-auto flex-col gap-1">
        <TextInput
          label="Email addresses"
          placeholder="Ex. freddie@example.com, queen@example.it"
          errorMessage={error}
          value={emailAddresses}
          onChange={handleInputChange}
          disabled={isLoading}
          required
        />

        <Text variant={typography.body_small} color={colors.text_secondary}>
          Use commas to separate multiple emails.
        </Text>
      </div>
    </EmailDialogModal>
  );
}
