import { Color } from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import { TextAlign } from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { EditorState } from '@tiptap/pm/state';
import { Editor, EditorProvider } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useState, useMemo, useEffect, ReactNode } from 'react';
import './RichTextEditor.scss';
import { Markdown } from 'tiptap-markdown';

import Loading from '@pray/shared/components/Loading/Loading';
import { cn } from '@pray/shared/utils/styles';

import RichTextEditorMenuBar from './RichTextEditorMenuBar';

type RichTextEditorProps = {
  content: string;
  className?: string;
  useMarkdown?: boolean;
  resetChangeHistory?: boolean;
  toolbarClassName?: string;
  customButtons?: ReactNode;
  isLoading?: boolean;
  onChange: (content: string) => void;
};

/**
 * Skeleton content for the editor when in loading state
 */
const SkeletonContent = () => (
  <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-3/5 space-y-1 pt-4">
    <Loading isLight isLoading height={40} width="45%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="90%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="90%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="60%" borderRadius="4px" />
  </div>
);

/**
 * RichTextEditor component
 *
 * A reusable rich text editor component with a consistent UI and functionality
 * that can be used across different features like ContentArticle and StudyGuide.
 *
 * @component
 * @param {Object} props
 * @param {string} props.content - The HTML content to display in the editor
 * @param {ReactNode} props.customButtons - Custom buttons to add to the editor toolbar
 * @param {boolean} props.isLoading - Whether to show loading skeleton instead of editor content
 * @param {Function} props.onChange - Callback function when content changes
 */
export default function RichTextEditor({
  content,
  className,
  useMarkdown,
  resetChangeHistory,
  toolbarClassName,
  customButtons,
  isLoading = false,
  onChange,
}: RichTextEditorProps) {
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [editorInstance, setEditorInstance] = useState(null);

  const extensions = useMemo(
    () => [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure(),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
        codeBlock: {
          HTMLAttributes: {
            class: 'code-block',
          },
        },
      }),
      ...(useMarkdown
        ? [
            Markdown.configure({
              transformPastedText: true,
            }),
          ]
        : []),
      TextAlign.configure({
        types: ['heading', 'paragraph', 'listItem', 'codeBlock', 'blockquote', 'textStyle'],
      }),
      Link.configure({
        openOnClick: false,
        HTMLAttributes: {
          class: 'article-link',
        },
        isAllowedUri() {
          // TODO: Fix issue where inserting more than one link, the editor freezes
          // For now, we're disabling links in markdown as it won't be used
          return !useMarkdown;
        },
      }),
      Underline,
    ],
    [useMarkdown]
  );

  const editorConfig = useMemo(
    () => ({
      extensions,
      onUpdate: ({ editor }: { editor: Editor }) => {
        onChange(useMarkdown ? editor.storage.markdown.getMarkdown() : editor.getHTML());
      },
      editorProps: {
        attributes: {
          class: cn(
            'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-[60%] focus:outline-none',
            isLoading && 'hidden'
          ),
        },
      },
      onCreate: ({ editor }: { editor: Editor }) => {
        setIsEditorReady(true);
        setEditorInstance(editor);
      },
      slotBefore: isEditorReady ? (
        <>
          <RichTextEditorMenuBar toolbarClassName={toolbarClassName} customButtons={customButtons} />
          {isLoading && <SkeletonContent />}
        </>
      ) : null,
    }),
    [extensions, content, onChange, isEditorReady, toolbarClassName, useMarkdown, customButtons]
  );

  useEffect(() => {
    const currentContent = useMarkdown ? editorInstance?.storage.markdown.getMarkdown() : editorInstance?.getHTML();
    if (editorInstance && content !== currentContent) {
      editorInstance.commands.setContent(content, false);
    }
  }, [content, editorInstance, useMarkdown]);

  useEffect(() => {
    if (resetChangeHistory && editorInstance) {
      editorInstance.view.updateState(
        EditorState.create({
          doc: editorInstance.state.doc,
          plugins: editorInstance.state.plugins,
          schema: editorInstance.state.schema,
        })
      );
    }
  }, [resetChangeHistory, editorInstance]);

  return (
    <div className={cn('relative w-full', className)}>
      <EditorProvider {...editorConfig} />
    </div>
  );
}
