import { useQuery, UseQueryResult } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapBookStatus } from '@pray/shared/services/studioService/books/mappers/bookStatusMapper';
import { BookStatus, BookProcessStatus } from '@pray/shared/services/studioService/books/types';

type UseBookStatusProps = {
  artistId: string;
  bookId: string;
};

export function useBookStatus({ artistId, bookId }: UseBookStatusProps): UseQueryResult<BookStatus> {
  async function fetchBookStatus() {
    const response = await studioService.books.getBookStatus({
      artistId,
      bookId,
    });

    return mapBookStatus(response.data);
  }

  const isProcessing = (bookStatus?: BookStatus) =>
    bookStatus?.textExtraction === BookProcessStatus.PROCESSING ||
    bookStatus?.audiobookGeneration === BookProcessStatus.PROCESSING;

  const query = useQuery({
    enabled: !!artistId && !!bookId,
    queryKey: queryKeys.artistBookStatus(artistId, bookId),
    refetchInterval: (data) => (isProcessing(data.state?.data) ? 5000 : null),
    queryFn: fetchBookStatus,
  });

  return query;
}
