import { useEffect, useState } from 'react';

import { HIDE_FIELD_KEY, HIDE_FIELD_LABEL, REQUIRED_HEADERS } from '../../../constants';

const useFieldMapping = (headers, initialData = []) => {
  const [userEditedMappings, setUserEditedMappings] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [unmatchedMappings, setUnmatchedMappings] = useState([]);

  const hasRequiredField = userEditedMappings.some((option) => REQUIRED_HEADERS.includes(option.guess));

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setUserEditedMappings(() => {
        const initialMappings = getInitialMappings(initialData);
        const newMappings = initialMappings;
        setUnmatchedMappings(newMappings.filter((mapping) => mapping.guess === null));
        return newMappings;
      });
      setAvailableOptions(getAvailableOptions(initialData));
    }
  }, [initialData]);

  const getInitialMappings = (data) =>
    data
      .filter((row) => row.header !== null)
      .map((row) => {
        const index = headers.indexOf(row.header);
        return {
          header: row.header,
          guess: row.guess,
          presentationName: row.presentationName,
          index,
        };
      });

  const getAvailableOptions = (data) =>
    data
      .filter((item) => item.header === null && item.presentationame !== null)
      .map((item) => ({
        header: item.header,
        guess: item.guess,
        presentationName: item.presentationName,
      }));

  const updateMappings = (item, selectedGuess, selectedValue) => {
    setUserEditedMappings((prev) => {
      const updatedMappings = prev.map((mapping) =>
        mapping.header === item.header ? { ...mapping, guess: selectedGuess, presentationName: selectedValue } : mapping
      );
      updateUnmatchedMappings(updatedMappings);
      return updatedMappings;
    });
  };

  const updateAvailableOptions = (selectedValue, previousValue) => {
    setAvailableOptions((prev) => {
      const updatedOptions = prev.filter((option) => option.guess !== selectedValue);
      if (previousValue) {
        const previousOption = initialData.find((option) => option.guess === previousValue);
        if (previousOption) {
          updatedOptions.push(previousOption);
        }
      }
      return updatedOptions;
    });
  };

  const updateUnmatchedMappings = (updatedMappings) => {
    setUnmatchedMappings(updatedMappings.filter((mapping) => mapping.guess === null));
  };

  const handleFieldChange = (item, event) => {
    const selectedGuess = event.target.value;
    const selectedValue =
      selectedGuess === HIDE_FIELD_KEY
        ? HIDE_FIELD_LABEL
        : initialData.find((item) => item.guess === selectedGuess)?.presentationName || null;

    updateMappings(item, selectedGuess, selectedValue);
    updateAvailableOptions(selectedGuess, item.guess);
  };

  return {
    userEditedMappings,
    availableOptions,
    unmatchedMappings,
    hasRequiredField,
    handleFieldChange,
  };
};

export default useFieldMapping;
