export default {
  target_count_reached: {
    title: 'Unable to turn on campaign',
    subtitle: 'To turn this campaign back on, you will need to increase the target lead count.',
  },
  invalid_scheduled_at_date: {
    title: 'Unable to turn on campaign',
    subtitle: 'To turn this campaign back on, you will need to update the scheduled date(s).',
  },
};
