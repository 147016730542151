import api from '@pray/shared/utils/api';

export async function updateLeadClient({
  id: leadClientId,
  name,
  imageUrl,
  leadIntegrationType,
  leadIntegrationDetails,
  artistId,
}) {
  if (!leadClientId) throw Error('Lead Client ID is required');

  const data = {};

  if (name !== undefined) data.name = name;
  if (imageUrl !== undefined) data.image_url = imageUrl;
  if (leadIntegrationType !== undefined) data.lead_integration_type = leadIntegrationType;
  if (leadIntegrationDetails !== undefined) data.lead_integration_details = leadIntegrationDetails;
  if (artistId !== undefined) data.artist_id = artistId;

  const url = `/web/studio/lead-clients/${leadClientId}`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
