import { animated, useSprings } from '@react-spring/web';
import React, { useEffect, useState } from 'react';
import { Marker } from 'react-simple-maps';

export function PulsingMarker({ coords, audience = [] }) {
  const [opacity, setOpacity] = useState(0);

  const pulsingCircle = useSprings(
    audience.length,
    audience.map(() => ({
      from: { r: 8 },
      to: { r: 12 },
      config: { duration: 1000 },
      loop: true,
    }))
  )[0];

  useEffect(() => {
    setOpacity(0);

    setTimeout(() => {
      setOpacity(1);
    }, 500);
  }, [audience]);

  return (
    <Marker coordinates={coords}>
      <animated.circle
        r={pulsingCircle.r}
        fill="#FFFFFF"
        stroke="#E3AF4A75"
        strokeWidth={8}
        style={{
          opacity,
          transition: 'opacity 0.5s ease',
        }}
      />
    </Marker>
  );
}
