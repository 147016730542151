import { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import useSelection from '@pray/shared/hooks/useSelection';

import { Select, SelectItem } from '@/components/ui/select';
import { Tooltip } from '@/components/ui/tooltip';

import { AudioIcon, SpeakerIcon } from './icons';

export function TranscriptBlock({ transcript, index, voices, onSplitParagraph, onSpeakerChange }) {
  const selection = useSelection();
  const [tooltipPosition, setTooltipPosition] = useState(null);

  const form = useFormContext();
  const speakers = form.watch('speakers');
  const speakerId = form.watch(`transcripts.${index}.speakerId`);

  const selectedSpeaker = speakers.find(({ id }) => id === speakerId);
  const allVoices = voices.artist.concat(voices.shared);

  const voiceNameBySpeaker = useMemo(() => {
    return speakers.reduce((acc, speaker) => {
      acc[speaker.id] = allVoices.find((voice) => voice.eleven_labs_voice_id === speaker.voiceId)?.name || speaker.name;
      return acc;
    }, {});
  }, [speakers, allVoices]);

  const handleTextSelection = useCallback(() => {
    if (!selection.toString() || selection.toString().trim() === selection.anchorNode.parentElement.innerText.trim()) {
      setTooltipPosition(null);
      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    const position = {
      x: rect.left + rect.width / 2,
      y: rect.top - 40,
    };

    setTooltipPosition(position);
  }, [selection]);

  const handleChangeSpeaker = useCallback(() => {
    const { startOffset, endOffset } = selection.getRangeAt(0);

    onSplitParagraph(transcript.id, startOffset, endOffset);
    setTooltipPosition(null);

    selection.removeAllRanges();
  }, [transcript.id, onSplitParagraph, selection]);

  const placeholderIcon = <AudioIcon color={selectedSpeaker?.color} />;
  const placeholderText = voiceNameBySpeaker[selectedSpeaker?.id] || 'Assign a speaker';

  const placeholder = (
    <div className="flex items-center gap-2 pr-3">
      {placeholderIcon} {placeholderText}
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-4 px-32">
        <div>
          <Controller
            control={form.control}
            name={`transcripts.${index}.speakerId`}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                size="small"
                placeholder={placeholder}
                value={field.value}
                error={!!form.formState.errors?.transcripts?.[index]?.speakerId}
                onValueChange={(value) => onSpeakerChange(index, value)}
              >
                {speakers.map((speaker) => (
                  <SelectItem key={speaker.id} value={speaker.id}>
                    <div className="flex items-center gap-2 pr-3">
                      <AudioIcon color={speaker.color} /> {voiceNameBySpeaker[speaker.id]}
                    </div>
                  </SelectItem>
                ))}
              </Select>
            )}
          />
        </div>
        <Text className="font-sfMono text-[14px] text-[#797979]">{transcript.timeframe}</Text>
      </div>

      <Text className="px-32 font-inter text-[16px] font-normal leading-[32px]" onMouseUp={handleTextSelection}>
        {transcript.text}
      </Text>

      {tooltipPosition && (
        <ChangeSpeakerTooltipButton
          position={tooltipPosition}
          onChangeSpeaker={handleChangeSpeaker}
          onClose={() => setTooltipPosition(null)}
        />
      )}
    </div>
  );
}

function ChangeSpeakerTooltipButton({ position, onChangeSpeaker, onClose }) {
  return (
    <Tooltip position={position} onClose={onClose}>
      <Button
        className="flex items-center gap-2 px-4 py-2 font-inter text-[12px] font-medium normal-case text-[#3A3C40]"
        onClick={onChangeSpeaker}
      >
        <SpeakerIcon />
        Change Speaker
      </Button>
    </Tooltip>
  );
}
