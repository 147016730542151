import { useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import RadioButtonGroup from '@pray/shared/components/ui/RadioButtonGroup/RadioButtonGroup';
import Select from '@pray/shared/components/ui/Select/Select';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import studioService from '@pray/shared/services/studioService';
import { downloadFileFromBlob } from '@pray/shared/utils/download';
import logger from '@pray/shared/utils/logger';

import Section from 'components/pages/StudioPage/components/Section/Section';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useStatementYearlyCounts from 'hooks/givingPayments/useStatementsYearlyCounts';

import DonationModuleDialog from '../DonationModuleDialog/DonationModuleDialog';

const donwloadOptions = {
  gifts: 'gifts',
  totals: 'totals',
};

export default function StatementsPage() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { data: yearlyCounts } = useStatementYearlyCounts(artistId);
  const years = yearlyCounts?.filter((item) => !!item.year).map((item) => item.year);

  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState(null);
  const [downloadOption, setDownloadOption] = useState(donwloadOptions.gifts);

  async function handleDownloadCsv() {
    setIsLoading(true);
    try {
      const response = await studioService.givingPayments.getTaxStatementReport({
        artistId,
        year: +year,
        option: downloadOption,
      });
      await downloadFileFromBlob(response, `${downloadOption}-tax-statement-${year}.csv`);
    } catch (error) {
      logger.error('Failed to download tax statement', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <TabPage
      title="Statements"
      data-viewname={VIEW_EVENT_NAMES.DONATIONS.STATEMENTS}
      titleInfo={<Info className="hidden size-5 text-[#56585E]" />}
    >
      <Section
        title="Tax Statement"
        subtitle="Select a year and click generate to download your tax statement."
        className="mt-4 max-w-screen-sm"
      >
        <Select
          label="Year"
          placeholder="Select a year"
          items={years}
          value={String(year)}
          inputElement={TextInput}
          getItemLabel={(year) => String(year)}
          getItemValue={(year) => String(year)}
          onChange={(event) => setYear(event.target.value)}
        />

        <div className="mt-2 flex flex-col">
          <Text variant={typography.headline_medium}>Select Download Options</Text>
          <RadioButtonGroup
            radioGroupName="downloadOption"
            options={[
              {
                value: donwloadOptions.gifts,
                label: 'Itemized Transactions',
                description: 'Detailed list of individual transactions you have received.',
                typography: typography.subhead_small,
              },
              {
                value: donwloadOptions.totals,
                label: 'Summary Statement',
                description: 'A summary overview of your total revenue.',
                typography: typography.subhead_small,
              },
            ]}
            value={downloadOption}
            onChange={setDownloadOption}
          />
        </div>

        <div>
          <Button
            variant={buttons.variant.primary}
            disabled={!years?.length || isLoading || !downloadOption || !year}
            className="flex items-center gap-3"
            onClick={handleDownloadCsv}
          >
            {isLoading && <Spinner />}
            {isLoading ? 'Generating...' : 'Generate'}
          </Button>
        </div>
      </Section>
      <DonationModuleDialog />
    </TabPage>
  );
}
