import { UseQueryResult, useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { type ChapterVersions, mapChapterVersions } from '@pray/shared/services/studioService/books/chapters';

type UseChapterVersionsProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

const CHAPTER_VERSIONS_CACHE_TIME = 1000 * 60 * 5; // 5 minutes

export function useChapterVersions({
  artistId,
  bookId,
  chapterId,
}: UseChapterVersionsProps): UseQueryResult<ChapterVersions> {
  async function fetchChapterVersions() {
    const response = await studioService.chapters.getChapterVersions({
      artistId,
      bookId,
      chapterId,
    });

    return mapChapterVersions(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!bookId && !!chapterId,
    queryKey: queryKeys.artistBookChapterVersions(artistId, bookId, chapterId),
    staleTime: CHAPTER_VERSIONS_CACHE_TIME,
    queryFn: fetchChapterVersions,
  });

  return query;
}
