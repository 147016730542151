import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export default function RevenueExternalPageModal() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  function startRedirectTimer() {
    const progressBar = document.getElementById('progress-bar');
    const startTime = Date.now();
    const duration = 5000; // 5 seconds

    function updateProgressBar() {
      const currentTime = Date.now();
      const elapsedTime = 1000 + currentTime - startTime;
      const progress = Math.min((elapsedTime / duration) * 100, 100);
      progressBar.style.width = `${progress}%`;

      if (progress < 100) {
        requestAnimationFrame(updateProgressBar);
        return;
      }

      if (progress > 95) {
        redirectToExternalPage();
      }
    }

    requestAnimationFrame(updateProgressBar);
  }

  function redirectToExternalPage() {
    const interval = setInterval(() => {
      // @ts-ignore
      // Read redirect url from query params or window property
      const redirectUrl = params.get('url') || window.redirectUrl;

      if (redirectUrl) {
        clearInterval(interval);
        window.location.href = redirectUrl;
      }
    }, 1000);
  }

  useEffect(startRedirectTimer, []);

  useEffect(() => {
    // Close the window after if it doesn't redirect after 30 seconds
    const timeout = setTimeout(() => window.close(), 30 * 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="fixed inset-0 bg-white">
      <div className="fixed inset-0 flex items-center justify-center bg-black/50">
        <div className="relative flex w-[480px] flex-col gap-6 overflow-hidden rounded-2xl bg-white p-6 shadow-[0px_4px_16px_0px_rgba(0,0,0,0.12)]">
          <div id="progress-bar" className="absolute left-0 top-0 h-1.5 bg-black transition-all" />
          <Text variant={typography.h1}>You will be redirected to an external page</Text>
          <Text className="!font-medium text-[#56585e]" variant={typography.body_lg}>
            Follow all the steps and provide the necessary information to set up your revenue account.
          </Text>
        </div>
      </div>
    </div>
  );
}
