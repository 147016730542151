import { formatNumberCount } from '@pray/shared/utils';

import { classifications } from './constants';
import { divideRangeIntoFour } from './helpers';

export default function useClassification(segments = []) {
  const minutes = segments.map((segment) => segment.minutes_consumed);
  const min = Math.min.apply(null, minutes);
  const max = Math.max.apply(null, minutes);
  const ranges = divideRangeIntoFour(0, roundMaxValue(max));
  const colors = [classifications.red, classifications.yellow, classifications.lime, classifications.green];

  const getClassificationColor = (minutes) => {
    const classification = minutesClassifications.find(
      (classification) => minutes >= classification.min && minutes <= classification.max
    );

    return classification?.statusColor ?? 'transparent';
  };

  const minutesRanges = ranges
    .map(([min, max], index) => {
      const formattedMin = formatNumberCount(min);
      const formattedMax = formatNumberCount(max);

      if (index === 0) {
        return {
          color: colors[index],
          label: `< ${formattedMax}`,
        };
      }

      if (index === ranges.length - 1) {
        return {
          color: colors[index],
          label: `> ${formattedMin}`,
        };
      }

      return {
        color: colors[index],
        label: `${formattedMax} - ${formattedMin}`,
      };
    })
    .reverse();

  const minutesClassifications = ranges.map(([min, max], index) => ({
    min,
    max,
    statusColor: colors[index],
  }));

  return {
    min,
    max,
    ranges,
    minutesRanges,
    minutesClassifications,
    getClassificationColor,
  };
}

function roundMaxValue(value) {
  if (value % 100 === 0) return value;

  return value - (value % 100) + 100;
}
