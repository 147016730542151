import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text/Text';
import { chartTypes } from '@pray/shared/services/studioService/periodicTable';
import { nthNumber } from '@pray/shared/utils';

import useSideBar from 'components/pages/StudioPage/components/SideBar/useSideBar';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useSegmentDetails from 'hooks/periodicTable/useSegmentDetails';

import { ChartWidget } from './ChartWidget';
import { GeoChart } from './GeoChart';
import { SegmentDetailsSideBar } from './SegmentDetailsSideBar';
import { StatCard } from './StatCard';
import { TableChart } from './TableChart';
import AgeChart from './widgets/AgeChart';
import EducationLevelChart from './widgets/EducationLevelChart';
import EthnicityChart from './widgets/EthnicityChart';
import GenderChart from './widgets/GenderChart';
import HouseholdIncomeChart from './widgets/HouseholdIncomeChart';
import MaritalStatusChart from './widgets/MaritalStatusChart';
import PoliticalAffiliationChart from './widgets/PoliticalAffiliationChart';
import ReligiousAffiliationChart from './widgets/ReligiousAffiliationChart';
import { variations } from '../../../../../components/PeriodicTable/constants';
import useClassification from '../../../../../components/PeriodicTable/useClassification';

export default function PeriodicTableSegmentDetails() {
  const location = useLocation();
  const { segmentCode } = useParams();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const { data: segment = {}, isLoading } = useSegmentDetails({ artistId, segmentCode });

  const classification = useClassification([
    { minutes_consumed: segment.minimum_minutes_consumed || 0 },
    { minutes_consumed: segment.maximum_minutes_consumed || 0 },
  ]);

  const segmentVariation = useMemo(() => {
    if (segment.rank <= 3) return 0;
    if (segment.rank <= 7) return 1;
    if (segment.rank <= 11) return 2;
    if (segment.rank <= 15) return 3;
    if (segment.rank <= 19) return 4;
    return 5;
  }, [segment]);

  useEffect(() => {
    if (segment.segment_code) {
      setRenderDetails(() => (
        <SegmentDetailsSideBar segment={segment} variation={segmentVariation} classification={classification} />
      ));
    }
  }, [segment, classification, location]);

  return (
    <div
      data-viewname={VIEW_EVENT_NAMES.ANALYTICS.PERIODIC_TABLE_SEGMENT_OVERVIEW}
      className="my-12 ml-20 flex max-w-[1060px] flex-col gap-6"
    >
      <SegmentHeader isLoading={isLoading} segment={segment} />

      <SegmentViewership isLoading={isLoading} segment={segment} variationColor={variations[segmentVariation].border} />

      <SegmentSummary
        isLoading={isLoading}
        segment={segment}
        classificationColor={classification.getClassificationColor(segment.minutes_consumed)}
      />

      <div className="flex flex-col gap-6">
        <GeographyChart artistId={artistId} segmentCode={segmentCode} />
        <TopContentTable artistId={artistId} segmentCode={segmentCode} />
      </div>

      <div className="flex flex-row gap-6">
        <HouseholdIncomeChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
        <GenderChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
      </div>

      <div className="flex flex-row gap-6">
        <AgeChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
        <MaritalStatusChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
      </div>

      <div className="flex flex-row gap-6">
        <PoliticalAffiliationChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
        <ReligiousAffiliationChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
      </div>

      <div className="flex flex-row gap-6">
        <EthnicityChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
        <EducationLevelChart artistId={artistId} segmentCode={segmentCode} segmentName={segment.segment_name} />
      </div>
    </div>
  );
}

function SegmentHeader({ isLoading, segment }) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-1">
        <Loading isLight {...{ isLoading, margin: '9px 0', height: '44px', width: '100px' }}>
          <Text variant="display_small">{segment.segment_code}</Text>
        </Loading>
        <Loading isLight {...{ isLoading, margin: '3px 0', height: '18px', width: '200px' }}>
          <Text variant="headline_small" className="font-medium text-[#00000060]">
            {segment.segment_name}
          </Text>
        </Loading>
      </div>
      <Loading isLight {...{ isLoading, margin: '3px 0', height: '48px', width: '650px' }}>
        <Text className="max-w-2xl font-medium text-[#0B0C0D]">{segment.segment_description}</Text>
      </Loading>
    </div>
  );
}

function SegmentViewership({ isLoading, segment, variationColor }) {
  return (
    <Loading isLight {...{ isLoading, margin: '0', height: '36px', width: '150px', borderRadius: '20px' }}>
      <div className="mt-[-2] flex w-fit items-center gap-2 rounded-full bg-[#F2F3F4] px-3 py-2">
        <div className="size-3 rounded" style={{ backgroundColor: variationColor }} />
        <Text variant="subhead_small" className="font-medium">
          {segment.viewership_percentage}% Viewership
        </Text>
      </div>
    </Loading>
  );
}

function SegmentSummary({ isLoading, segment, classificationColor }) {
  return (
    <div className="flex flex-wrap gap-6">
      <Loading isLight {...{ isLoading, margin: '2px 0', height: '112px', width: '192px', borderRadius: '12px' }}>
        <StatCard
          className="border-none"
          style={{ backgroundColor: classificationColor }}
          value={segment.minutes_consumed?.toLocaleString()}
          label="Minutes in prayer"
          info="This metric shows the total number of minutes PRAY.COM members spent from the periodic table segment spent on the app."
        />
      </Loading>

      <Loading isLight {...{ isLoading, margin: '2px 0', height: '112px', width: '192px', borderRadius: '12px' }}>
        <StatCard
          value={`${segment.listen_percentage}%`}
          label="Listens"
          info="This metric displays the percentage of PRAY.COM members spent listening to your content from the periodic table segment within the app."
        />
      </Loading>

      <Loading isLight {...{ isLoading, margin: '2px 0', height: '112px', width: '192px', borderRadius: '12px' }}>
        <StatCard
          value={`${segment.shares_percentage}%`}
          label="Shares"
          info="This metric shows the percentage of shares by PRAY.COM members from the periodic table segment within the app."
        />
      </Loading>

      <Loading isLight {...{ isLoading, margin: '2px 0', height: '112px', width: '192px', borderRadius: '12px' }}>
        <StatCard
          value={`${segment.follows_percentage}%`}
          label="Follows"
          info="This metric shows the percentage of followers you have from PRAY.COM members from the periodic table segment."
        />
      </Loading>

      <Loading isLight {...{ isLoading, margin: '2px 0', height: '112px', width: '192px', borderRadius: '12px' }}>
        <StatCard
          value={nthNumber(segment.rank)}
          label="Rank"
          info="This metric shows the viewership rank for the segment against all the other segments from the periodic table"
        />
      </Loading>
    </div>
  );
}

function GeographyChart({ artistId, segmentCode }) {
  const { isLoading, data } = useSegmentDetails({ artistId, segmentCode, chartType: chartTypes.geochart });

  if (isLoading || !!data?.geochart) {
    return (
      <ChartWidget
        isLoading={isLoading}
        title="Geography"
        description="Members by geographic region"
        info="Location of PRAY.COM members from the periodic table segment by state."
      >
        <GeoChart key={Date.now()} isLoading={isLoading} data={data?.geochart} />
      </ChartWidget>
    );
  }
}

function TopContentTable({ artistId, segmentCode }) {
  const { isLoading, data } = useSegmentDetails({ artistId, segmentCode, chartType: chartTypes.topContent });

  if (isLoading || !!data?.top_content?.length) {
    return (
      <ChartWidget
        isLoading={isLoading}
        title="Top Content"
        info="This table shows the top listened to tracks from PRAY.COM members from the perodic table segment."
      >
        <TableChart isLoading={isLoading} data={data?.top_content} />
      </ChartWidget>
    );
  }
}
