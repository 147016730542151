import api from '@pray/shared/utils/api';

export async function updateAnnouncement(artistId, announcementId, data) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!announcementId) throw new Error('Announcement ID is required');

  const url = `/web/studio/artists/${artistId}/announcements/${announcementId}`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
