import { useCallback } from 'react';

import { CSV_HEADERS } from '../constants';

export const useCsvDownload = (filename) => {
  const headers = CSV_HEADERS;

  const downloadCsv = useCallback(() => {
    const csvContent = `data:text/csv;charset=utf-8,${headers.join(',')}\n`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [filename]);

  return downloadCsv;
};
