import React, { useState } from 'react';

import { colors, typography, buttons } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import useSaveEmailTemplate from 'hooks/emailTemplates/useSaveEmailTemplate';

import { EmailDialogModal } from './EmailDialogModal';
import { tempTemplatePreviewImgUrl } from '../../constants';

export default function SaveEmailAsTemplateModal({ artistId, email, onSaved, onCancel }) {
  const toast = useToastMessage();
  const { saveEmailTemplate } = useSaveEmailTemplate();

  const [name, setName] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const saveButtonLabel = isLoading ? 'Saving...' : 'Save';

  const dialogButtons = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: handleCancel, disabled: isLoading },
    {
      label: saveButtonLabel,
      variant: buttons.variant.primary,
      action: handleSave,
      disabled: isLoading,
      loading: isLoading,
    },
  ];

  async function saveEmailAsTemplate() {
    try {
      setIsLoading(true);
      const { rawHtml, rawCss, compiledHtmlCss } = email;

      const templateData = {
        artistId,
        name,
        rawHtml,
        rawCss,
        compiledHtmlCss,
        previewImgUrl: tempTemplatePreviewImgUrl,
      };

      await saveEmailTemplate(templateData);

      toast.show({ success: 'Your email has been saved as template' });

      onSaved?.();
    } finally {
      setIsLoading(false);
    }
  }

  function handleInputChange(event) {
    setName(event.target.value);
    setError('');
  }

  async function handleSave() {
    if (!name) {
      setError('Please enter a name');
      return;
    }

    await saveEmailAsTemplate();
    setName(null);
    setError('');
  }

  function handleCancel() {
    setName(null);
    onCancel();
    setError('');
  }

  return (
    <EmailDialogModal
      title="Save Email Template"
      description="Save this email for future use as a template."
      buttons={dialogButtons}
      onClose={handleCancel}
      isLoading={isLoading}
    >
      <div className="flex h-auto flex-col gap-1">
        <TextInput label="Template name" placeholder="Untitled" value={name} onChange={handleInputChange} required />
        <Text variant={typography.caption_medium} color={colors.attention}>
          {error}
        </Text>
      </div>
    </EmailDialogModal>
  );
}
