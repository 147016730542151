import React from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Copy } from '@pray/shared/components/ui/Icons/Copy';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import { copyToClipboard } from '@pray/shared/utils';

import Section from 'components/pages/StudioPage/components/Section/Section';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';

import styles from '../ProfileTab.module.scss';

export default function ProfileBasicInfo({
  form,
  artistHandle,
  profileUrl,
  validationValues,
  isHandleEditable,
  isGivingUrlVisible,
  isGivingUrlEditable,
}) {
  const toast = useToastMessage();

  const handleCopyLeaderUrl = () => {
    copyToClipboard(profileUrl, () => {
      toast.show({ success: 'Link copied!' });
    });
  };

  const suggestArtistHandle = () => {
    if (form.values.name.trim() && !form.values.handle.trim()) {
      form.setValue('handle', form.values.name.trim().toLowerCase().replace(/[ ]/g, ''));
    }
  };

  return (
    <Section title="Basic Info" className="max-w-4xl">
      <div className={styles.formField}>
        <Text className={styles.label}>Leader Name</Text>
        <Text className={styles.description}>The creator name that you want to display for this content.</Text>

        <TextInput
          label="Full Name"
          name="name"
          value={form.values.name}
          errorMessage={form.errors.name}
          onChange={form.handleChange}
          onBlur={suggestArtistHandle}
        />

        <InputHelperText>
          <span>
            Your name must contain {validationValues.profileNameMinLength}-{validationValues.profileNameMaxLength}{' '}
            letters.
          </span>
          <span>
            {form.values.name.length}/{validationValues.profileNameMaxLength}
          </span>
        </InputHelperText>
      </div>

      <div className={styles.formField}>
        <Text className={styles.label}>Leader URL</Text>
        <Text className={styles.description}>
          This is the standard web address for your channel. It includes your unique channel ID at the end of the URL.
          You will not be able to edit this URL after publishing your content.
        </Text>

        <div className={styles.leaderUrl}>
          <span className={styles.placeholder}>
            <span className="sm-hide md-show">www.</span>
            <span>pray.com/{!isHandleEditable ? artistHandle : null}</span>
          </span>

          {isHandleEditable ? (
            <TextInput
              name="handle"
              value={form.values.handle}
              errorMessage={form.errors.handle}
              onChange={(event) => {
                // enforce lowercase and remove special characters
                form.handleChange({
                  target: {
                    type: event.target.type,
                    name: event.target.name,
                    value: event.target.value
                      .toLowerCase() // Convert to lowercase
                      .replace(/\s+/g, '-') // Replace spaces with hyphens
                      .replace(/[^a-z0-9-]/g, ''), // Remove characters that are not alphanumeric or hyphen
                  },
                });
              }}
            />
          ) : (
            <div>
              <TextInput disabled value="" errorMessage={form.errors.handle} />
              {!form.errors.handle && (
                <IconButton className={styles.copyButton} onClick={handleCopyLeaderUrl} data-tapeventname="Copy">
                  <Copy />
                </IconButton>
              )}
            </div>
          )}

          <InputHelperText>
            <span>
              Your custom URL must contain {validationValues.handleMinLength}-{validationValues.handleMaxLength}{' '}
              letters. Please do not use spaces, symbols, or special characters.
            </span>
            <span>
              {form.values.handle.length}/{validationValues.handleMaxLength}
            </span>
          </InputHelperText>
        </div>
      </div>

      <div className={styles.formField}>
        <Text className={styles.label}>Description</Text>
        <Text className={styles.description}>
          Tell viewers about your channel. Your description will appear in your channel page and search results, among
          other places.
        </Text>

        <TextArea
          rows={5}
          label="Description"
          name="description"
          value={form.values.description}
          errorMessage={form.errors.description}
          description={`The text must be ${validationValues.profileDescriptionMaxLength} characters maximum.`}
          helperText={`${form.values.description.length}/${validationValues.profileDescriptionMaxLength}`}
          onChange={form.handleChange}
        />
      </div>

      {isGivingUrlVisible && (
        <div className={styles.formField}>
          <Text className={styles.label}>Giving URL</Text>
          <Text className={styles.description}>
            The external link you want your viewers to visit to support you with donations.
          </Text>

          <TextInput
            label="Giving URL"
            name="giving_external_url"
            disabled={!isGivingUrlEditable}
            value={form.values.giving_external_url}
            errorMessage={form.errors.giving_external_url}
            description="Note: Your giving URL must start with https://."
            onChange={form.handleChange}
          />
        </div>
      )}
    </Section>
  );
}
