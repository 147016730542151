import React, { useContext } from 'react';

import { TableContext } from './Table';
import Link from '../Link/Link';
import Text from '../Text/Text';

type TdLinkProps = React.HTMLAttributes<HTMLTableCellElement> & {
  to: string;
  children?: React.ReactNode;
};

export const TdLink = ({ to, children, ...props }: TdLinkProps) => {
  const { styles } = useContext(TableContext);

  let contents = children;

  if (typeof contents === 'string') {
    contents = <Text>{contents}</Text>;
  }

  // Note: <td> needs "height: 1rem" so that the height of <a> matches the height of <td>.
  // It's needed because a child of a <td> can only have "height: 100%" if the parent has an explicitly-set height.
  // It DOES NOT actually set the height of the <a> to 1rem.
  // It sets the min-height of the <td> to 1rem.  That doesn't matter, because Table.module.scss already makes rows ≥56px tall.
  return (
    <td {...props} style={{ height: '1rem' }}>
      <Link to={to} style={{ height: '100%' }}>
        <div className={styles.container} style={{ height: '100%' }}>
          <div className={styles.contents}>{contents}</div>
        </div>
      </Link>
    </td>
  );
};

export default TdLink;
