import React from 'react';
import { create } from 'zustand';

import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import VideoPlayer from '@pray/shared/components/VideoPlayer/VideoPlayer';

import styles from './VideoModal.module.scss';

export const useVideoModal = create((set) => ({
  content: null,
  playVideo: (content) => set(() => ({ content })),
  onClose: () => set(() => ({ content: null })),
}));

export default function VideoModal() {
  const { content, onClose } = useVideoModal();

  if (!content) return null;

  return (
    <Modal customStyles={styles} onClose={onClose} data-videomodal>
      <Text className={styles.title}>{content.title}</Text>
      <VideoPlayer autoPlay content={content} options={{ muted: false }} />
    </Modal>
  );
}
