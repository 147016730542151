import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip, Legend, ChartDataLabels);

const family = 'Satoshi';

export const LineChart = ({ data, ...props }) => {
  return (
    <Line
      {...props}
      data={data}
      options={{
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
        elements: {
          line: {
            tension: 0.3,
          },
        },
        plugins: {
          tooltip: {
            padding: 16,
            backgroundColor: '#000000',
            titleFont: {
              family,
              size: 17,
              weight: 700,
            },
            displayColors: false,
            bodyColor: '#C6CCD8',
            bodyFont: {
              family,
              size: 17,
              weight: 700,
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              boxHeight: 6,
              boxWidth: 6,
              font: {
                family,
                weight: 700,
              },
            },
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};
