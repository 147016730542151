import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import { announcementStatus } from 'components/pages/StudioPage/tabs/AnnouncementsTab/constants';

export default function useSaveAnnouncements() {
  const queryClient = useQueryClient();

  async function saveAnnouncement({
    announcementId = undefined,
    artistId = undefined,
    status = undefined,
    title = undefined,
    segmentCode = undefined,
    body = undefined,
    resourceType = undefined,
    resourceImageUrl = undefined,
    resourceUrl = undefined,
    resourceTitle = undefined,
    resourceButtonText = undefined,
    scheduledAt = undefined,
    contentId = undefined,
    contentSeriesId = undefined,
  }) {
    const data = {
      artistId,
    };

    if (title !== undefined) data.title = title;
    if (status !== undefined) data.status = status;
    if (segmentCode !== undefined) data.segmentCode = segmentCode;
    if (body !== undefined) data.body = body;
    if (resourceImageUrl !== undefined) {
      data.resourceImageUrl = resourceImageUrl;

      // Upload resource image if a new image was selected.
      if (resourceImageUrl instanceof File) {
        const imageUrl = await saveAnnouncementResourceImage(resourceImageUrl, artistId);
        data.resourceImageUrl = imageUrl;
      }
    }
    if (resourceType !== undefined) data.resourceType = resourceType;
    if (resourceUrl !== undefined) data.resourceUrl = resourceUrl;
    if (resourceTitle !== undefined) data.resourceTitle = resourceTitle;
    if (resourceButtonText !== undefined) data.resourceButtonText = resourceButtonText;
    if (scheduledAt !== undefined) data.scheduledAt = scheduledAt;
    if (contentId !== undefined) data.contentId = contentId;
    if (contentSeriesId !== undefined) data.contentSeriesId = contentSeriesId;

    const createEditAnnouncementBody = {
      title: data.title,
      body: data.body,
      recipients: {
        segments: [data.segmentCode],
      },
      is_active: data.status === announcementStatus.PUBLISHED,
      scheduled_at: data.scheduledAt,
    };

    // Add an announcement resource if available
    const resourceData = await getResourceData({
      resourceType: data.resourceType,
      resourceTitle: data.resourceTitle,
      resourceUrl: data.resourceUrl,
      resourceImageUrl: data.resourceImageUrl,
      resourceButtonText: data.resourceButtonText,
      contentId: data.contentId,
      contentSeriesId: data.contentSeriesId,
    });

    if (resourceData) {
      createEditAnnouncementBody.resource = resourceData;
    }

    let announcement;

    if (!announcementId) {
      announcement = await createAnnouncement(createEditAnnouncementBody, artistId);
    } else {
      announcement = await updateAnnouncement(createEditAnnouncementBody, artistId, announcementId);
    }

    return announcement;
  }

  async function getResourceData({
    resourceType = undefined,
    resourceTitle = undefined,
    resourceUrl = undefined,
    resourceImageUrl = undefined,
    resourceButtonText = undefined,
    contentId = undefined,
    contentSeriesId = undefined,
  }) {
    if (resourceType === 'content' && contentId) {
      return {
        type: 'content',
        content_id: contentId,
      };
    }

    if (resourceType === 'content' && contentSeriesId) {
      return {
        type: 'content-series',
        content_series_id: contentSeriesId,
      };
    }

    if (resourceType === 'external_url' && resourceUrl && resourceImageUrl) {
      return {
        type: 'external_url',
        title: resourceTitle,
        url: resourceUrl,
        image_url: resourceImageUrl,
        button_text: resourceButtonText,
      };
    }

    return null;
  }

  async function createAnnouncement(createAnnouncementBody, artistId) {
    const response = await studioService.announcements.createAnnouncement(artistId, createAnnouncementBody);

    return response?.data;
  }

  async function updateAnnouncement(editAnnouncementBody, artistId, announcementId) {
    const response = await studioService.announcements.updateAnnouncement(
      artistId,
      announcementId,
      editAnnouncementBody
    );

    return response?.data;
  }

  const saveAnnouncementResourceImage = async (file, artistId) => {
    try {
      const { url } = await s3Service.signAndUpload({
        file,
        type: 'artistAsset',
        signParams: {
          artist_id: artistId,
          artist_asset_type: 'daily-background',
        },
      });

      return url;
    } catch (error) {
      logger.error('Failed to upload announcement resource image', error);
      throw new Error('Failed to upload announcement resource image');
    }
  };

  const mutation = useMutation({
    mutationFn: saveAnnouncement,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistAnnouncements(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveAnnouncement: mutateAsync,
    ...rest,
  };
}
