import { useQuery, useMutation } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export function useContentStudyGuide(artistId, contentId, locale) {
  async function fetchContentStudyGuide() {
    const { data } = await studioService.contentStudyGuide.getStudyGuide({
      artistId,
      contentId,
      locale,
    });
    return data;
  }

  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.artistContentStudyGuide(artistId, contentId, locale),
    queryFn: fetchContentStudyGuide,
  });

  return query;
}

export function useGenerateContentStudyGuide() {
  async function generateContentStudyGuide({ artistId, contentId, locale }) {
    const { data } = await studioService.contentStudyGuide.generateStudyGuide({
      artistId,
      contentId,
      locale,
    });

    return data;
  }

  const mutation = useMutation({
    mutationFn: generateContentStudyGuide,
  });

  return {
    generateContentStudyGuide: mutation.mutateAsync,
    ...mutation,
  };
}

export function useUpdateContentStudyGuide() {
  async function updateContentStudyGuide({ artistId, contentId, locale, studyGuide }) {
    const { data } = await studioService.contentStudyGuide.updateStudyGuide({
      artistId,
      contentId,
      locale,
      studyGuide,
    });
    return data;
  }

  const mutation = useMutation({
    mutationFn: updateContentStudyGuide,
  });

  return {
    updateContentStudyGuide: mutation.mutateAsync,
    ...mutation,
  };
}
