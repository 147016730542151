export const divideRangeIntoFour = (min, max) => {
  const totalRange = max - min;
  const rangeSize = totalRange / 4;
  const ranges = [];

  for (let i = 0; i < 4; i++) {
    let rangeStart = Math.floor(min + i * rangeSize) + (i === 0 ? 0 : 1);
    const rangeEnd = Math.floor(i === 3 ? max : rangeStart + rangeSize);
    if (i !== 0 && ranges[i - 1][1] === rangeStart) rangeStart++;
    ranges.push([rangeStart, rangeEnd]);
  }

  return ranges;
};

export const findRange = (ranges, number) => {
  for (let i = 0; i < ranges.length; i++) {
    const [rangeStart, rangeEnd] = ranges[i];
    if (number >= rangeStart && number <= rangeEnd) return i + 1;
  }

  return -1;
};
