import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { PRAY_ARTIST_NAME } from 'constants.js';

import styles from './EventsCalendar.module.scss';

export default function CalendarDayItem({ date, type, month, events: dailyEventItems, navigateToDayViewAtDate }) {
  const navigate = useNavigate();
  const [toggleMore, setToggleMore] = useState(false);
  const dailyEventsFromDate = dailyEventItems[date.toFormat('yyyy-MM-dd')];
  const hasMoreThanThreeEvents = dailyEventsFromDate?.length > 3;
  const dailyEventsLength = dailyEventsFromDate?.length || 0;

  /**
   *
   * A function that takes a date object, a string type, and a month number, and returns a JSX element
   * if the date is on a not-current month's first or last day.
   * @param {DateTime} date - A date time object representing a specific date.
   * @param {string} type - A string that can be either 'current' or 'notCurrent' to indicate the type of
   *                        month the date belongs to.
   * @param {number} month - A number representing the month of the provided date object.
   * @return {JSX.Element | null} - A JSX element if the date is on a not-current month's first or last day,
   *                               otherwise null.
   */
  const getPrefixMonthOnNotCurrentMonthDays = (date, type, month) => {
    return [1, date.set({ day: date.daysInMonth }).get('day')].includes(date.get('day')) && <span>{month}&nbsp;</span>;
  };

  /**
   *
   * A function that generates a JSX element representing a day of a month.
   * @param {DateTime} date - A date time object representing a specific date.
   * @return {JSX.Element} A JSX element representing a day of a month.
   */
  const getDayFromMonth = (date) => {
    const todayDate = DateTime.now();
    const isToday =
      todayDate.get('day') === date.get('day') &&
      todayDate.get('month') === date.get('month') &&
      todayDate.get('year') === date.get('year');

    return <span className={[styles.monthDay, isToday ? styles.today : ''].join(' ')}>{date.get('day')}</span>;
  };

  const navigateToEditDailyItem = (event, dailyItem) => {
    event.cancelBubble = true;
    event.stopPropagation();
    window.open(`/dailies/daily-series/${dailyItem.daily_series_id}/daily-items/${dailyItem.id}`, '_blank');
  };

  const navigateToCreateDailyItem = (date) => {
    navigate(`/dailies/create?date=${date.toFormat('yyyy-MM-dd')}`);
  };

  const navigateToDayView = (date) => {
    navigateToDayViewAtDate(date);
  };

  const getNumberOfEventsToShow = () => {
    if (toggleMore) return dailyEventsLength;
    return 2;
  };

  const handleToggleMore = (event) => {
    event.stopPropagation();
    setToggleMore(!toggleMore);
  };

  const formatText = (text) => {
    const CHAR_LIMIT = 25;
    return text.length > CHAR_LIMIT ? `${text.slice(0, CHAR_LIMIT)}...` : text;
  };

  const dailyEventTitle = (dailyItem) => {
    const defaultDailyVideoTitle = 'Video Daily';
    const dailyTitle = dailyItem.title || dailyItem.reference || (dailyItem.video_url ? defaultDailyVideoTitle : '');
    return dailyTitle;
  };

  return (
    <td
      onClick={() => navigateToDayView(date)}
      onKeyDown={() => navigateToDayView(date)}
      role="presentation"
      className={[styles.calendarTableRowItem, type !== 'current' ? styles.notCurrent : styles.current].join(' ')}
    >
      {getPrefixMonthOnNotCurrentMonthDays(date, type, month)}
      {getDayFromMonth(date)}

      <div className={styles.wrapperTd}>
        {(dailyEventsFromDate || [])
          .slice(0, hasMoreThanThreeEvents ? getNumberOfEventsToShow() : 3)
          .map((dailyItem) => (
            <div
              onClick={(event) => navigateToEditDailyItem(event, dailyItem)}
              onKeyDown={(event) => navigateToEditDailyItem(event, dailyItem)}
              key={dailyItem.id}
              role="presentation"
              className={styles.calendarTableEventItem}
              style={{
                background: `url('${dailyItem.background_image_url}'), #CBCBCB`,
                backgroundSize: 'cover',
              }}
            >
              <p className={styles.calendarTableEventItemText}>
                {formatText(`${dailyItem.daily_series_name} - ${dailyEventTitle(dailyItem)}`)}
              </p>

              {dailyItem?.artist_name !== PRAY_ARTIST_NAME && (
                <span className={styles.calendarTableEventItemArtistBubble}>
                  <img
                    src={dailyItem?.artist_profile_image_url}
                    alt={`Daily by ${dailyItem?.artist_name}`}
                    title={`Daily by ${dailyItem?.artist_name}`}
                  />
                </span>
              )}
            </div>
          ))}

        {hasMoreThanThreeEvents && (
          <Button onClick={handleToggleMore} className={styles.eventsMoreItems}>
            <Text className={styles.eventsMoreItemsText}>
              {!toggleMore ? `+${dailyEventsLength - 2} MORE` : `HIDE`}
            </Text>
          </Button>
        )}
      </div>
    </td>
  );
}
