import { useRef, useState, useEffect } from 'react';

import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { formatTime } from '@pray/shared/utils';

export function SocialClipVideo({ videoUrl }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isRepeat, setIsRepeat] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const video = videoRef.current;

    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
    };

    const handleLoadedMetadata = () => {
      setDuration(video.duration);
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    video.addEventListener('loadedmetadata', handleLoadedMetadata);

    return () => {
      video.removeEventListener('timeupdate', handleTimeUpdate);
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, []);

  useEffect(() => {
    if (isMuted) {
      videoRef.current.muted = true;
    } else {
      videoRef.current.muted = false;
    }
  }, [isMuted]);

  useEffect(() => {
    if (isRepeat) {
      videoRef.current.loop = true;
    } else {
      videoRef.current.loop = false;
    }
  }, [isRepeat]);

  return (
    <div className="group relative">
      <video
        ref={videoRef}
        className="h-[509px] w-[264px] overflow-hidden rounded-lg border object-cover"
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      >
        <source src={videoUrl} type="video/mp4" />
        <track kind="captions" />
      </video>

      {/* progress bar */}
      <div className="absolute inset-x-4 top-4 h-[3px] rounded bg-white/50">
        <div
          className="absolute left-0 top-0 h-full rounded bg-white transition-all duration-300"
          style={{ width: `${(currentTime / duration) * 100}%` }}
        />
      </div>

      <div className="absolute right-4 top-7 flex gap-1 rounded bg-[#18191C] p-1">
        <Text className="text-xs text-white">{formatTime(currentTime)}</Text>
        <Text className="text-xs text-white/60">{formatTime(duration)}</Text>
      </div>

      <Button
        className="absolute left-1/2 top-1/2 flex size-14 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white/10 p-0 opacity-0 backdrop-blur-2xl transition-opacity duration-300 group-hover:opacity-100"
        onClick={() => {
          if (isPlaying) {
            videoRef.current.pause();
          } else {
            videoRef.current.play();
          }
        }}
      >
        {!isPlaying ? <PlayIcon /> : <PauseIcon />}
      </Button>

      <Button
        tooltip={isMuted ? 'Enable sound' : 'Disable sound'}
        className="absolute bottom-16 right-2 flex size-10 items-center justify-center rounded-full bg-white/10 p-0.5 opacity-0 backdrop-blur-2xl transition-opacity duration-300 group-hover:opacity-100"
        onClick={() => setIsMuted(!isMuted)}
      >
        {isMuted ? <SoundOffIcon /> : <SoundOnIcon />}
      </Button>

      <Button
        tooltip={isRepeat ? 'Disable repeat' : 'Enable repeat'}
        className="absolute bottom-4 right-2 flex size-10 items-center justify-center rounded-full bg-white/10 p-0.5 opacity-0 backdrop-blur-2xl transition-opacity duration-300 group-hover:opacity-100"
        onClick={() => setIsRepeat(!isRepeat)}
      >
        {isRepeat ? <RepeatOffIcon /> : <RepeatOnIcon />}
      </Button>
    </div>
  );
}

function PlayIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8446 6.80754L24.8403 15.1323C25.315 15.4146 25.4709 16.0283 25.1886 16.5029C25.1035 16.646 24.9841 16.7655 24.8411 16.8507L10.8453 25.1923C10.3709 25.4751 9.75713 25.3197 9.47437 24.8453C9.38209 24.6904 9.33337 24.5135 9.33337 24.3333V7.66699C9.33337 7.11471 9.78109 6.66699 10.3334 6.66699C10.5133 6.66699 10.6899 6.71555 10.8446 6.80754Z"
        fill="white"
      />
    </svg>
  );
}

function PauseIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1672 6.66675C12.3457 6.66675 12.9349 6.66675 13.301 7.03286C13.6672 7.39898 13.6672 7.98824 13.6672 9.16675C13.6672 13.7223 13.6672 18.2779 13.6672 22.8334C13.6672 24.0119 13.6672 24.6012 13.301 24.9673C12.9349 25.3334 12.3457 25.3334 11.1672 25.3334H10.1672C8.98864 25.3334 8.39939 25.3334 8.03327 24.9673C7.66716 24.6012 7.66714 24.0119 7.66712 22.8334C7.66701 18.2776 7.66673 13.7218 7.66765 9.16598C7.66789 7.98786 7.66801 7.3988 8.03411 7.03278C8.40021 6.66675 8.98931 6.66675 10.1675 6.66675H11.1672ZM21.8338 6.66675C23.0123 6.66675 23.6016 6.66675 23.9677 7.03286C24.3338 7.39898 24.3338 7.98824 24.3338 9.16675C24.3338 13.7223 24.3338 18.2779 24.3338 22.8334C24.3338 24.0119 24.3338 24.6012 23.9677 24.9673C23.6016 25.3334 23.0123 25.3334 21.8338 25.3334H20.8338C19.6553 25.3334 19.066 25.3334 18.6999 24.9673C18.3338 24.6012 18.3338 24.0119 18.3338 22.8334C18.3338 18.2779 18.3338 13.7223 18.3338 9.16675C18.3338 7.98824 18.3338 7.39898 18.6999 7.03286C19.066 6.66675 19.6553 6.66675 20.8338 6.66675H21.8338Z"
        fill="white"
      />
    </svg>
  );
}

function SoundOnIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 4.70203C10.9998 4.56274 10.9583 4.42663 10.8809 4.31088C10.8034 4.19514 10.6934 4.10493 10.5647 4.05166C10.436 3.99838 10.2944 3.98442 10.1577 4.01154C10.0211 4.03866 9.89559 4.10564 9.797 4.20403L6.413 7.58703C6.2824 7.7184 6.12703 7.82256 5.95589 7.89345C5.78475 7.96435 5.60124 8.00057 5.416 8.00003H3C2.73478 8.00003 2.48043 8.10539 2.29289 8.29292C2.10536 8.48046 2 8.73481 2 9.00003V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8947 2.73478 16 3 16H5.416C5.60124 15.9995 5.78475 16.0357 5.95589 16.1066C6.12703 16.1775 6.2824 16.2817 6.413 16.413L9.796 19.797C9.8946 19.8958 10.0203 19.9631 10.1572 19.9904C10.2941 20.0177 10.436 20.0037 10.5649 19.9503C10.6939 19.8968 10.804 19.8063 10.8815 19.6902C10.959 19.5741 11.0002 19.4376 11 19.298V4.70203Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 9C16.6491 9.86548 17 10.9181 17 12C17 13.0819 16.6491 14.1345 16 15"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.364 18.3637C20.1998 17.528 20.8627 16.5359 21.315 15.4439C21.7673 14.352 22.0001 13.1816 22.0001 11.9997C22.0001 10.8178 21.7673 9.6475 21.315 8.55557C20.8627 7.46363 20.1998 6.47147 19.364 5.63574"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function SoundOffIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9C16.5044 9.67234 16.8311 10.461 16.95 11.293"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3633 5.63281C20.6423 6.91108 21.5066 8.54497 21.8435 10.3215C22.1804 12.0981 21.9744 13.935 21.2523 15.5928"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2 2L22 22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7 7L6.413 7.587C6.2824 7.71838 6.12703 7.82253 5.95589 7.89342C5.78475 7.96432 5.60124 8.00054 5.416 8H3C2.73478 8 2.48043 8.10536 2.29289 8.29289C2.10536 8.48043 2 8.73478 2 9V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H5.416C5.60124 15.9995 5.78475 16.0357 5.95589 16.1066C6.12703 16.1775 6.2824 16.2816 6.413 16.413L9.796 19.797C9.8946 19.8958 10.0203 19.9631 10.1572 19.9904C10.2941 20.0177 10.436 20.0037 10.5649 19.9503C10.6939 19.8968 10.804 19.8063 10.8815 19.6902C10.959 19.5741 11.0002 19.4376 11 19.298V11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.82812 4.1706C9.92401 4.07435 10.0463 4.00874 10.1795 3.98208C10.3128 3.95543 10.4509 3.96893 10.5764 4.02088C10.702 4.07283 10.8093 4.16089 10.8847 4.27389C10.9601 4.38689 11.0003 4.51974 11.0001 4.6556V5.3416"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function RepeatOnIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C8.32412 21.25 5.14904 19.1058 3.65722 16M2.75 12L1 9.5M2.75 12L5.5 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5668 8.0527L15.8166 11.1745C15.9946 11.2804 16.0531 11.5105 15.9472 11.6885C15.9153 11.7422 15.8705 11.787 15.8169 11.8189L10.5671 14.9471C10.3892 15.0531 10.1589 14.9948 10.0529 14.8169C10.0183 14.7589 10 14.6925 10 14.6249V8.375C10 8.1679 10.1679 8 10.3751 8C10.4426 8 10.5088 8.01821 10.5668 8.0527Z"
        fill="white"
      />
    </svg>
  );
}

function RepeatOffIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C8.32412 21.25 5.14904 19.1058 3.65722 16M2.75 12L1 9.5M2.75 12L5.5 10"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0801 8C10.6765 8 11.16 8.4835 11.16 9.07993C11.16 11.0266 11.16 12.9733 11.16 14.92C11.16 15.5165 10.6765 16 10.08 16C9.48358 16 9.00006 15.5165 9.00004 14.92C9.00001 12.9732 8.99991 11.0264 9.00024 9.07965C9.00034 8.48333 9.48379 8 10.0801 8ZM13.92 8C14.5165 8 15 8.48353 15 9.07999C15 11.0267 15 12.9733 15 14.92C15 15.5165 14.5165 16 13.92 16C13.3235 16 12.84 15.5165 12.84 14.92C12.84 12.9733 12.84 11.0267 12.84 9.07999C12.84 8.48353 13.3235 8 13.92 8Z"
        fill="white"
      />
    </svg>
  );
}
