import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Text from '@pray/shared/components/ui/Text';

export default function FilterType({ id, title, bgColor, borderColor, selectedTypes, setSelectedTypes }) {
  const handleCheckboxClick = () => {
    if (selectedTypes.includes(id)) {
      setSelectedTypes(selectedTypes.filter((existingKey) => existingKey !== id));
    } else {
      setSelectedTypes([...selectedTypes, id]);
    }
  };

  return (
    <label
      className="flex items-center gap-2 rounded-e-md border-l-2 px-2 py-1"
      style={{ borderColor, backgroundColor: bgColor }}
    >
      <Checkbox
        className="bg-white checked:bg-transparent"
        checked={selectedTypes.includes(id)}
        onChange={handleCheckboxClick}
      />
      <Text color={colors.text_primary} className="text-sm font-medium">
        {title}
      </Text>
    </label>
  );
}
