import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';

import { VIEW_EVENT_NAMES } from '@/constants';
import { VideoCamera } from '@/images/icons';

import EmptyState from '../../../components/EmptyState/EmptyState';

export function SocialClipsEmptyState({ onGenerateClick }) {
  return (
    <div className="mx-auto flex max-w-96 flex-col gap-6" data-viewname={VIEW_EVENT_NAMES.SOCIAL_CLIPS.EMPTY_STATE}>
      <EmptyState
        icon={<VideoCamera />}
        title="Generate Social Clips"
        subtitle="Turn long videos into shorts, and publish them on all social platforms with one click."
      />
      <div className="flex justify-center">
        <Button variant={buttons.variant.primary} onClick={onGenerateClick}>
          Generate Social Clips
        </Button>
      </div>
    </div>
  );
}
