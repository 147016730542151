import React from 'react';

export default function TwitterIcon(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9983 4.00011C12.9696 4.00011 3.99829 12.9714 3.99829 24.0001C3.99829 35.0288 12.9696 44.0001 23.9983 44.0001C35.027 44.0001 43.9983 35.0288 43.9983 24.0001C43.9983 12.9714 35.027 4.00011 23.9983 4.00011ZM33.7696 20.1079C33.7783 20.3132 33.7835 20.5219 33.7835 20.7314C33.7835 27.0949 28.9374 34.4349 20.0774 34.4349C17.3574 34.4349 14.8244 33.6375 12.6939 32.2705C13.0705 32.3158 13.4522 32.3375 13.8426 32.3375C16.1 32.3375 18.1774 31.5688 19.8244 30.2766C17.7166 30.2384 15.9392 28.8427 15.3244 26.9314C15.6192 26.9879 15.9209 27.0184 16.2322 27.0184C16.6713 27.0184 17.0974 26.9601 17.5 26.8488C15.2983 26.4045 13.6383 24.4601 13.6383 22.1253C13.6383 22.1045 13.6383 22.0845 13.6383 22.0645C14.2879 22.4253 15.0296 22.6427 15.82 22.6679C14.5261 21.8027 13.6774 20.3297 13.6774 18.6584C13.6774 17.7758 13.9139 16.9488 14.3287 16.2358C16.7052 19.1497 20.2548 21.0671 24.2574 21.2688C24.1748 20.9158 24.1313 20.5488 24.1313 20.1714C24.1313 17.5114 26.2879 15.3549 28.9487 15.3549C30.3339 15.3549 31.5852 15.9392 32.4635 16.8758C33.56 16.6601 34.5922 16.2592 35.5226 15.7079C35.1635 16.8314 34.3992 17.7758 33.4035 18.3723C34.3792 18.254 35.3061 17.9966 36.1713 17.6132C35.5261 18.5792 34.7096 19.4288 33.7696 20.1079Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
