import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { Table, Thead, Tbody, Tr, Th, Td } from '@pray/shared/components/ui/Table';
import Pagination from '@pray/shared/components/ui/Table/Pagination';
import Text from '@pray/shared/components/ui/Text/Text';
import { useAuth } from '@pray/shared/hooks/useAuth';
import usePagination from '@pray/shared/hooks/usePagination';
import studioService from '@pray/shared/services/studioService';
import { formatDateUsingShorterMonth } from '@pray/shared/utils/datetime';

import MainLayout from 'components/layouts/MainLayout';
import { useStudioContext } from 'context/StudioContext';
import { Team } from 'images/icons';
import { Permissions } from 'utils/auth';

import InviteMemberModal from './InviteMemberModal/InviteMemberModal';
import plusIcon from '../../assets/plus-icon.svg';
import ActionButton from '../../components/ActionButton/ActionButton';
import DeleteContentModal from '../../components/DeleteContentModal/DeleteContentModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import Section from '../../components/Section/Section';
import { useToastMessage } from '../../components/StudioToastMessage';
import TabPage from '../../components/TabPage/TabPage';

import styles from './TeamTab.module.scss';

export default function TeamTab() {
  const toast = useToastMessage();
  const { permissions } = useAuth();
  const [isShowMembers, setIsShowMembers] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const {
    selectedArtist: { id: artistId },
  } = useStudioContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'email';
  const order = searchParams.get('order') ?? 'asc';
  const memberSortOrder = `${sort} ${order}`;

  const setMemberSortOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const {
    isFirstPage,
    isLastPage,
    page,
    data: membersData,
    fetchData: fetchTeamMembersData,
    refetchData: refetchTeamMembersData,
    fetchPreviousPage,
    fetchNextPage,
  } = usePagination(async (params = {}) => {
    const [sortColumn, sortDirection] = memberSortOrder.split(' ');
    const response = await studioService.team.getAllTeamMembers({
      artistId,
      sortColumn,
      sortDirection,
      ...params,
    });

    setIsShowMembers(true);

    return {
      data: response.data,
      nextItemId: response.last_item_identifier,
    };
  });

  useEffect(() => {
    if (artistId) {
      setIsShowMembers(false);
      fetchTeamMembersData();
    }
  }, [artistId]);

  useEffect(() => {
    if (membersData.length) refetchTeamMembersData();
  }, [memberSortOrder]);

  const renderTopRightButton = () => {
    if (!permissions.has(Permissions.INVITE_TEAM_MEMBER)) return null;
    return (
      <ActionButton text="Add" icon={<img src={plusIcon} alt="Add" />} onClick={() => setIsInviteModalOpen(true)} />
    );
  };

  return (
    <MainLayout data-viewname="PRAY Studio - Team Tab">
      <TabPage title="Team" topRightButtons={renderTopRightButton()}>
        {(() => {
          if (isShowMembers) {
            if (membersData.length === 0) {
              return (
                <EmptyState
                  icon={<Team />}
                  title="No team members yet"
                  subtitle="Your team members will show up here"
                />
              );
            }

            return (
              <TeamMemberTable
                data={membersData}
                page={page}
                sortBy={memberSortOrder}
                artistId={artistId}
                isFirstPage={isFirstPage}
                isLastPage={isLastPage}
                onPreviousPageButtonClick={fetchPreviousPage}
                onNextPageButtonClick={fetchNextPage}
                onSortChange={setMemberSortOrder}
                onDeleted={(member) => {
                  refetchTeamMembersData();
                  toast.show({ success: `${member.email} has been removed` });
                }}
                onInviteResent={(member) => {
                  toast.show({ success: `A new invite has been sent to ${member.email}` });
                }}
              />
            );
          }

          return null;
        })()}
      </TabPage>

      <InviteMemberModal
        artistId={artistId}
        isOpen={isInviteModalOpen}
        onInviteSent={async () => {
          await refetchTeamMembersData();
          toast.show({ success: 'The invite was sent successfully' });
          setIsInviteModalOpen(false);
        }}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </MainLayout>
  );
}

const getStatusDescription = (user) => {
  if (user.status === 'active') return `Joined on ${formatDateUsingShorterMonth(user.created_at, true)}`;
  return <Text color={colors.text_muted}>Invite sent</Text>;
};

const TeamMemberTable = ({
  data,
  page,
  sortBy,
  artistId,
  isFirstPage,
  isLastPage,
  onPreviousPageButtonClick,
  onNextPageButtonClick,
  onSortChange,
  onDeleted,
  onInviteResent,
}) => {
  const [member, setMember] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleResendInvite = async (member) => {
    await studioService.team.resendTeamMemberEmail({
      artistId,
      teamMemberId: member.id,
    });

    onInviteResent(member);
  };

  const handleDeleteMember = (member) => {
    setMember(member);
    setIsDeleteModalOpen(true);
  };

  const deleteMember = async () => {
    await studioService.team.removeTeamMembers({
      artistId,
      teamMemberIds: [member.id],
    });

    await onDeleted(member);
    setIsDeleteModalOpen(false);
  };

  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    return (
      <div className={styles.rowActions}>
        {item.status === 'invited' && (
          <Button
            key={`resend-${item.id}`}
            size={buttons.size.small}
            variant={buttons.variant.secondary}
            onClick={() => handleResendInvite(item)}
          >
            Resend
          </Button>
        )}
        {data.length > 1 && (
          <Button
            key={`remove-${item.id}`}
            size={buttons.size.small}
            className={styles.remove}
            variant={buttons.variant.secondary}
            onClick={() => handleDeleteMember(item)}
          >
            Remove
          </Button>
        )}
      </div>
    );
  };

  return (
    <Section>
      <Table sortable sortBy={sortBy} rowCount={data.length} rowActions={getRowActions} onSortChange={onSortChange}>
        <Thead>
          <Tr>
            <Th field="email" width="40%">
              Email
            </Th>
            <Th field="role_name">Role</Th>
            <Th field="status">Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((member) => (
            <Tr key={member.email}>
              <Td>{member.email}</Td>
              <Td className="capitalize">{member.role_name}</Td>
              <Td>{getStatusDescription(member)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {(!isFirstPage || !isLastPage) && (
        <Pagination
          page={page}
          isPreviousEnabled={!isFirstPage}
          isNextEnabled={!isLastPage}
          onPreviousClick={onPreviousPageButtonClick}
          onNextClick={onNextPageButtonClick}
        />
      )}

      <DeleteContentModal
        isOpen={isDeleteModalOpen}
        title={
          <>
            Remove <span className="capitalize">{member?.role_name}</span>?
          </>
        }
        message={
          <>
            You are about to remove <b>{member?.email}</b>. They will lose access to your content. You will have to send
            a new invite to the user to invite them again. Are you sure you want to remove?
          </>
        }
        deleteButtonLabel="Remove"
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={deleteMember}
      />
    </Section>
  );
};
