import React from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';

import { AiStarsButton, AiStarsButtonDisabled } from 'images/icons';
import { cn } from 'styles/utils';

export default function AiSuggestionButton({ isLoading = false, hasInput = true, className = '', ...props }) {
  return (
    <IconButton
      className={cn('translate-x-2 translate-y-2 cursor-pointer bg-white z-10', className)}
      icon={isLoading || !hasInput ? AiStarsButtonDisabled : AiStarsButton}
      disabled={isLoading}
      {...props}
    />
  );
}
