import api from '@pray/shared/utils/api';

export async function testIntegration({ artistId, type, integrationDetails }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!type) throw Error('Type is required');
  if (!integrationDetails) throw Error('Integration details is required');

  const data = {
    type,
    integration_details: integrationDetails,
  };

  const url = `/web/studio/artists/${artistId}/lead-client-test`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
