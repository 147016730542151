import { useState } from 'react';

import logger from '../utils/logger';

const pageDataCount = 25;

const defaultPaginationOptions = {
  isLoading: true,
  isInfinite: false,
};

export default function usePagination(dataFetcher, options = defaultPaginationOptions) {
  const initialState = {
    data: [],
    page: 0,
    lastId: 0,
    isLoading: options.isLoading ?? false,
    isInfinite: options.isInfinite ?? false,
    isFirstPage: true,
    isLastPage: false,
    params: {
      offset: 0,
      limit: pageDataCount,
      ...options.params,
    },
  };

  const [state, setState] = useState(initialState);

  const updateState = (updates) => {
    setState((prevState) => {
      logger.debug('\nPagination State:\n', prevState, '\nUpdates:\n', updates);
      return { ...prevState, ...updates };
    });
  };

  const fetchPreviousPage = () => {
    const previousPage = state.page > 1 ? state.page - 2 : 0;
    return fetchPage(previousPage * pageDataCount);
  };

  const fetchNextPage = () => {
    const nextPage = state.page;
    return fetchPage(nextPage * pageDataCount);
  };

  const fetchPage = (offset) => fetchData(false, { offset });

  const fetchData = async (isRefetch = false, params = {}) => {
    updateState({ isLoading: true });

    const queryParams = { ...state.params };
    if (isRefetch) queryParams.offset = state.lastId;

    Object.assign(queryParams, params);

    const { nextItemId, data: pageData } = await dataFetcher(queryParams, isRefetch);

    const lastId = queryParams.offset;
    const page = nextItemId && pageDataCount ? Math.ceil(nextItemId / pageDataCount) : 1;
    const isFirstPage = page === 1;
    const isLastPage = lastId === nextItemId || pageData?.length < pageDataCount;
    const data = state.isInfinite ? [...state.data, ...pageData] : pageData;

    updateState({
      data,
      page,
      lastId,
      isLoading: false,
      isFirstPage,
      isLastPage,
      params: {
        ...state.params,
        ...params,
        offset: nextItemId,
      },
    });
  };

  const refetchData = () => fetchData(true);

  return {
    ...state,
    fetchData,
    refetchData,
    fetchPreviousPage,
    fetchNextPage,
  };
}
