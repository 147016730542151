import React from 'react';

export function Success(props) {
  const { fill = 'white', className, ...rest } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        {...rest}
        d="M8.90138 15.7628L5.38023 11.8466C4.83572 11.241 3.95289 11.241 3.40838 11.8466C2.86387 12.4522 2.86387 13.4341 3.40838 14.0397L7.90545 19.0412C8.45549 19.6529 9.34728 19.6529 9.89732 19.0412L20.5916 7.14724C21.1361 6.54164 21.1361 5.55979 20.5916 4.95419C20.0471 4.3486 19.1643 4.3486 18.6198 4.95419L8.90138 15.7628Z"
        fill={fill}
      />
    </svg>
  );
}
