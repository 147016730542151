import React, { useContext } from 'react';

import { TableContext } from './Table';
import { randomId } from '../../../utils';
import Checkbox from '../Checkbox/Checkbox';

type TrProps = React.HTMLAttributes<HTMLTableRowElement> & {
  index?: number | null;
  children?: React.ReactNode;
  className?: string;
};

export const Tr = React.forwardRef(({ index = null, children, className = '', ...props }: TrProps, ref) => {
  const { styles, selectable, selected, handleSelect, rowActions } = useContext(TableContext);

  const renderCheckbox = (templateCell) => {
    // If this table is not `selectable` do not render the checkbox
    if (!selectable) return null;

    const value = index ?? 'all';
    const isHeaderRow = index === null;
    const checkboxProps = {
      value,
      checked: selected[value],
      partial: isHeaderRow && !selected.all && selected.some,
    };

    return createCell(templateCell, {
      key: `checkbox-${index}`,
      field: 'checkbox',
      className: styles.checkbox,
      children: <Checkbox onChange={handleSelect} {...checkboxProps} />,
    });
  };

  const renderRowActions = (templateCell) => {
    let children = null;

    // row action cell for table rows
    if (index !== null) {
      children = <div className={styles.rowActions}>{rowActions(index)}</div>;
    }

    // row actions cell header
    return createCell(templateCell, {
      key: `action-${randomId()}`,
      className: styles.rowActionsCell,
      children,
    });
  };

  const createCell = (templateCell, props = {}) => {
    // Reset cell properties for the checkbox container cell
    const cellProps = Object.keys(templateCell.props).reduce((acc, key) => ({ ...acc, [key]: null }), {});

    // Cloning the first cell of each row to be the checkbox container.
    // With that we get a `Th` for the table header row and a `Td` for the table body rows.
    return React.cloneElement(templateCell, {
      ...cellProps,
      ...props,
    });
  };

  const renderCells = () => {
    const cells = React.Children.toArray(children);
    const [templateCell] = cells;

    return []
      .concat(selectable ? renderCheckbox(templateCell) : [])
      .concat(cells)
      .concat(rowActions ? renderRowActions(templateCell) : []);
  };

  const classes = [className, selected[index] && styles.selected] //
    .filter(Boolean)
    .join(' ');

  return (
    <tr ref={ref} className={classes} {...props}>
      {renderCells()}
    </tr>
  );
});
