import Link from '@pray/shared/components/ui/Link/Link';
import Text from '@pray/shared/components/ui/Text';

import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

export default function GivingPreferenceMessageBox() {
  const appRoutes = useAppRoutes();
  const { selectedArtist } = useStudioContext();

  if (selectedArtist?.giving_preference !== 'off') return null;

  return (
    <div className="my-4 flex items-center justify-between rounded-md border border-[#C5D0FB] bg-[#F1F3FE] p-4">
      <div className="flex items-center gap-4">
        <InfoIcon />
        <Text className="font-medium">
          The revenue functionality is currently turned off. To enable revenue, please update your settings.
        </Text>
      </div>
      <Link to={appRoutes.settingsRevenue()}>
        <Text className="text-sm font-bold uppercase tracking-wider">Go to Settings</Text>
      </Link>
    </div>
  );
}

function InfoIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.75 11H12L12 16.25M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z"
        stroke="#2261D3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="11.375" y="7.375" width="1.25" height="1.25" rx="0.625" fill="black" stroke="black" strokeWidth="0.25" />
    </svg>
  );
}
