import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';

import { cn } from '@pray/shared/utils/styles';

import Text from '../Text/Text';

import uploadIcon from './assets/upload-icon.svg';

import styles from './Dropzone.module.scss';

const Dropzone = ({ options = {}, getDropzoneMessage = null, onAcceptedFiles = null, onRejectedFiles = null }, ref) => {
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (onAcceptedFiles) onAcceptedFiles(acceptedFiles);
    if (onRejectedFiles) onRejectedFiles(rejectedFiles);
  }, []);

  const { getRootProps, getInputProps, ...dropzone } = useDropzone({
    onDrop,
    noClick: true,
    ...options,
  });

  useImperativeHandle(ref, () => dropzone, [ref]);

  const visibleStyle = dropzone.isDragActive ? styles.visible : '';
  const className = [styles.dropzone, visibleStyle].join(' ');
  const dropzoneInfo = getDropzoneMessage?.(dropzone.isDragActive);

  return (
    <div className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      {dropzoneInfo && <div className={styles.info}>{dropzoneInfo}</div>}
    </div>
  );
};

export const DropzoneInfo = ({ title, subtitle, customIcon = null }) => (
  <div className={styles.dropzoneInfo}>
    {(title || subtitle) && (
      <img src={customIcon || uploadIcon} alt="Upload" className={cn(!customIcon ? styles.rotate : '')} />
    )}
    {title && <Text className={styles.title}>{title}</Text>}
    {subtitle && <Text className={styles.subtitle}>{subtitle}</Text>}
  </div>
);

export default forwardRef(Dropzone);
