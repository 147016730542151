import api from '@pray/shared/utils/api';

export const getLocalizedContentSeriesDetails = async ({ artistId = null, contentSeriesId = null, locale = null }) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');
  if (!locale) throw Error('Locale code is required');

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}/locales/${locale}`;
  const response = await api.executeGetRequest(url);

  return response.data;
};
