import { typography } from '@pray/shared/components/foundations';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Select from '@pray/shared/components/ui/Select/Select';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import { CONTENT_VISIBILITY, CONTENT_ORDER } from '@pray/shared/constants';

import Alert from 'components/pages/StudioPage/components/Alert/Alert';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import Section from 'components/pages/StudioPage/components/Section/Section';

import styles from './RssFeedDetailsEditForm.module.scss';

export default function RssFeedDetailsEditForm({ form, playlist, isPrimary, onChange }) {
  const isPrivateAlertVisible =
    form.values.visibility === CONTENT_VISIBILITY.PRIVATE && (!playlist?.id || playlist?.is_visible);

  const visibilityOptions = {
    PRIVATE: { key: 'private', label: 'Private' },
    PUBLIC: { key: 'public', label: 'Public' },
  };

  return (
    <div>
      <Section className="max-w-4xl">
        <Text variant={typography.headline_medium}>About your series</Text>

        <div className={styles.formField}>
          <TextInput
            label="Title"
            name="title"
            value={form.values.title}
            errorMessage={form.errors.title}
            disabled={!isPrimary}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextArea
            rows={5}
            label="Description"
            name="description"
            value={form.values.description}
            errorMessage={form.errors.description}
            disabled={!isPrimary}
            onChange={form.handleChange}
          />
        </div>
      </Section>

      <div className="mt-4">
        <MediaUpload
          imageUrl={form.values.image_url}
          title="Thumbnail"
          description="We recommend uploading an image of at least 1400x1400 pixels and maximum 512kb. We support jpg and png formats."
          uploadButtonLabel={form.values.image_url ? 'Change Image' : 'Upload Image'}
          error={form.errors.image_url}
          cropProps={{ aspect: 1 }}
          onValidateImage={(img) => {
            const isSquare = img.height === img.width;
            const isMinimumSize = img.width >= 1400;
            return isSquare && isMinimumSize;
          }}
          onFileChange={(file) => {
            form.setValue('image_file', file);
            onChange();
          }}
          onError={(error) => form.setError('image_url', error)}
        />
      </div>

      {isPrimary && (
        <div className="mt-10">
          <div className={styles.formField}>
            <Text variant={typography.headline_medium} className="mb-4">
              Series visibility
            </Text>

            <Select
              label="Visibility"
              items={Object.values(visibilityOptions)}
              value={form.values.visibility}
              getItemLabel={(item) => item.label}
              getItemValue={(item) => item.key}
              onChange={(event) => form.setValue('visibility', event.target.value)}
            />
          </div>
        </div>
      )}

      {isPrivateAlertVisible && (
        <Alert
          icon={<Info color="#A34515" />}
          message="Your content will no longer be visible on Pray.com, and will also be removed from any distribution channels you may have set up."
        />
      )}

      <div className={`${styles.formField} mt-10 flex flex-col gap-6`}>
        <div className="flex flex-col gap-2">
          <Text variant={typography.headline_medium}>Episodes Order</Text>
          <Text className="text-sm text-[#3A3C40]">Change the order of the episodes in your series</Text>
        </div>

        <Select
          label="Order by"
          items={Object.values(CONTENT_ORDER)}
          value={form.values.contentOrder}
          getItemLabel={(item) => item.label}
          getItemValue={(item) => item.key}
          onChange={(event) => form.setValue('contentOrder', event.target.value)}
        />
      </div>
    </div>
  );
}
