import { useCallback, useState } from 'react';

import { useUpdateContentStudyGuide } from 'hooks/contentStudyGuide/useContentStudyGuide';

import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useSaveStudyGuide({ artist, content, locale, setStudyGuide }) {
  const toast = useToastMessage();
  const [isSaving, setIsSaving] = useState(false);
  const { updateContentStudyGuide } = useUpdateContentStudyGuide();

  const saveStudyGuide = useCallback(
    async (studyGuide) => {
      try {
        setIsSaving(true);
        await updateContentStudyGuide({
          artistId: artist.id,
          contentId: content.id,
          locale,
          studyGuide,
        });

        setStudyGuide(studyGuide);

        toast.show({ success: 'Study guide saved successfully' });
      } catch (error) {
        toast.show({ error: 'Failed to save study guide' });
      } finally {
        setIsSaving(false);
      }
    },
    [artist.id, content.id, locale, updateContentStudyGuide, setStudyGuide, toast]
  );

  return {
    saveStudyGuide,
    isSaving,
  };
}
