import React, { useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

import ContentListPage from './ContentList/ContentListPage';
import TabPage from '../../components/TabPage/TabPage';
import { Tabs } from '../../components/Tabs/Tabs';

const tabs = [
  { title: 'Audio', path: 'audio', mediaType: 'audio' },
  { title: 'Video', path: 'video', mediaType: 'video' },
];

export default function ContentTab() {
  const appRoutes = useAppRoutes();
  const { pathname } = useLocation();
  const [topRightButtons, setTopRightButtons] = useState(null);

  const activeTab = useMemo(() => {
    const currentPath = pathname.split('/').pop();
    const tabIndex = tabs.findIndex((tab) => tab.path === currentPath);
    return tabIndex !== -1 ? tabIndex : 0;
  }, [pathname]);

  return (
    <MainLayout data-viewname="PRAY Studio - Episodes Tab">
      <TabPage title="Episodes" data-viewname={VIEW_EVENT_NAMES.CONTENT.EPISODES} topRightButtons={topRightButtons}>
        <Tabs tabs={tabs} activeTab={activeTab} tabStyle="pills" />
        <Routes>
          {tabs.map((tab) => (
            <Route
              key={tab.path}
              path={tab.path}
              element={<ContentListPage mediaType={tab.mediaType} setTopRightButtons={setTopRightButtons} />}
            />
          ))}
          <Route path="*" element={<Navigate to={appRoutes.artistContentAudio()} replace />} />
        </Routes>
      </TabPage>
    </MainLayout>
  );
}
