import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetDailySeriesCoverArts(artistId, dailySeriesId) {
  const fetchDailySeriesCoverArts = async () => {
    const response = await studioService.dailySeries.getDailySeriesCoverArts({
      artistId,
      dailySeriesId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId && !!dailySeriesId,
    queryKey: queryKeys.artistDailySeriesCoverArts(artistId, dailySeriesId),
    queryFn: fetchDailySeriesCoverArts,
  });

  return query;
}
