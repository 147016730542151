import api from '@pray/shared/utils/api';

export const getAllLeadClients = async ({
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
  options = {},
} = {}) => {
  const params = {};

  if (offset) params.offset = offset;

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/lead-clients`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
};
