import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';

import CampaignForm from './Campaigns/CampaignForm/CampaignForm';
import Campaigns from './Campaigns/Campaigns';
import OrganizationForm from './Organizations/OrganizationForm/OrganizationForm';
import Organizations from './Organizations/Organizations';

export default function MinistryMatchTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Ministry Match Tab">
      <Routes>
        <Route path="campaigns" element={<Campaigns />} />
        <Route path="campaigns/create" element={<CampaignForm />} />
        <Route path="campaigns/:id" element={<CampaignForm />} />

        <Route path="organizations" element={<Organizations />} />
        <Route path="organizations/create" element={<OrganizationForm />} />
        <Route path="organizations/:id" element={<OrganizationForm />} />
      </Routes>
    </MainLayout>
  );
}
