import { Duration } from 'luxon';
import React, { useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import { copyToClipboard } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { ChevronDark } from 'images/icons';

import Section from '../../../../components/Section/Section';
import { useToastMessage } from '../../../../components/StudioToastMessage';
import TabPage from '../../../../components/TabPage/TabPage';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';

function ContentSummary({ workflowAIDetails, onWorkflowAIStarted, artist, content, locale, setContent }) {
  return (
    <TabPage
      title="Summary"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_SUMMARY}
      titleInfo={
        <Popover
          title="Summary"
          description="Here you can see the summaries and chapters from your content generated by Artificial Intelligence "
        >
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      {!workflowAIDetails?.isLoading && (
        <Section className="flex gap-4">
          <ContentSummaryWrapper
            workflowAIDetails={workflowAIDetails}
            onWorkflowAIStarted={onWorkflowAIStarted}
            artist={artist}
            content={content}
            locale={locale}
            setContent={setContent}
          />
        </Section>
      )}

      {workflowAIDetails?.isLoading && (
        <Section className="flex h-[70vh] w-full items-center justify-center">
          <div className="flex-col items-center gap-2">
            <Spinner size="large" color="purple" className="opacity-80" />
            <Text className="mt-4">Loading...</Text>
          </div>
        </Section>
      )}
    </TabPage>
  );
}

const ContentSummaryWrapper = ({ workflowAIDetails, onWorkflowAIStarted, artist, content, locale, setContent }) => {
  const [openIndices, setOpenIndices] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const toast = useToastMessage();

  const form = useForm({
    summary: workflowAIDetails?.summary || '',
    short_summary: workflowAIDetails?.shortSummary || '',
    chapters: workflowAIDetails?.chapters || [],
  });

  async function handleWorkflowAIButton() {
    const { id: artistId } = artist;
    const { id: contentId } = content;

    setIsProcessing(true);
    await studioService.workflowAI.triggerWorkflowAIForContent({
      artistId,
      contentId,
      locale,
    });

    /**
     * Update the content state to show the loading state in Content Details page
     */
    setContent((prevData) => {
      return {
        ...prevData,
        is_workflow_ai_processing: true,
      };
    });

    /**
     * Call the onWorkflowAIStarted function to update the content details page
     * The error is already handled by the callers
     */
    await onWorkflowAIStarted();
    setIsProcessing(false);
  }

  /**
   * Empty and Loading state
   */
  if (!(workflowAIDetails.summary && workflowAIDetails.shortSummary && workflowAIDetails.chapters)) {
    return (
      <WorkflowAIEmptyState
        content={content}
        isProcessing={isProcessing}
        handleWorkflowAIButton={handleWorkflowAIButton}
        title="Summary"
        description="Effortlessly create personalized messages for your audience. Promote content, share updates, or inspire your
            community."
      />
    );
  }

  function handleToggle(index) {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      setOpenIndices([...openIndices, index]);
    }
  }

  function renderRightIcon(type, content) {
    return (
      <Button
        variant={buttons.variant.secondary}
        className="!absolute bottom-1 right-0 z-10 p-1 px-3 text-xs"
        onClick={() => {
          copyToClipboard(
            content,
            () => toast.show({ success: 'Text copied to clipboard' }),
            () => toast.show({ error: 'Failed to copy text' })
          );
        }}
      >
        Copy
      </Button>
    );
  }

  return (
    <>
      {/* Summary */}
      <div className="mt-2 w-full">
        <Text variant={typography.heading_lg} className="mb-2">
          Summary
        </Text>

        <TextArea
          rows={6}
          name="summary"
          rightIcon={renderRightIcon('summary', form.values.summary)}
          disabled={false}
          value={form.values.summary}
          errorMessage={form.errors.summary}
          onChange={form.handleChange}
        />
      </div>

      {/* Short Summary */}
      <div className="mt-2 w-full">
        <Text variant={typography.heading_lg} className="mb-2">
          Quote
        </Text>

        <TextArea
          rows={4}
          name="short_summary"
          rightIcon={renderRightIcon('short_summary', form.values.short_summary)}
          disabled={false}
          value={form.values.short_summary}
          errorMessage={form.errors.short_summary}
          onChange={form.handleChange}
        />
      </div>

      {/* Chapters */}
      <div className="mt-2 w-full">
        <Text variant={typography.heading_lg}>Chapters</Text>

        {form.values.chapters.length === 0 && <ChapterLoading />}

        {form.values.chapters.map((chapter, index) => (
          <ChapterAccordionItem
            key={chapter.chapter_index}
            chapter={chapter}
            index={index}
            isOpen={openIndices.includes(index)}
            form={form}
            renderRightIcon={renderRightIcon}
            onToggle={handleToggle}
          />
        ))}
      </div>
    </>
  );
};

const ChapterLoading = () => {
  return (
    <div className="my-3 flex flex-row rounded-lg border border-gray-200 bg-gray-100 px-4 py-3">
      <div className="flex items-center gap-3">
        <Spinner color="dark" size="small" className="border-2 border-gray-400" />
        Processing chapters...
      </div>
    </div>
  );
};

const ChapterAccordionItem = ({ chapter, index, isOpen, onToggle, form, renderRightIcon }) => {
  function secondsToHHMMSS(seconds) {
    try {
      return Duration.fromObject({ seconds }).toFormat('mm:ss');
    } catch (err) {
      logger.error('Error converting seconds to HH:MM:SS', err);
      return '00:00';
    }
  }

  return (
    <div
      key={chapter.chapter_index}
      className="my-3 flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-100 px-4 py-3"
    >
      <button
        type="button"
        className="text-md flex w-full items-center justify-between text-left font-medium leading-10 focus:outline-none"
        onClick={() => onToggle(index)}
      >
        <Text className="text-base font-bold">
          Chapter {chapter.chapter_index} - {chapter.chapter_title}
        </Text>
        <Text className="text-sm">
          {secondsToHHMMSS(chapter.chapter_timestamp_start)} - {secondsToHHMMSS(chapter.chapter_timestamp_end)}
          <Text className="ml-4">
            {isOpen ? (
              <ChevronDark className="inline-block rotate-180 transition-transform duration-200" />
            ) : (
              <ChevronDark className="inline-block transition-transform duration-200" />
            )}
          </Text>
        </Text>
      </button>

      {isOpen && (
        <>
          <TextArea
            rows={2}
            name={`chapters_${index}_short_summary`}
            label="Quote"
            rightIcon={renderRightIcon('chapter_short_summary', form.values.chapters[index].chapter_short_summary)}
            disabled={false}
            value={form.values.chapters[index].chapter_short_summary}
            className="text-base"
            onChange={form.handleChange}
          />
          <TextArea
            rows={4}
            name={`chapters_${index}_long_summary`}
            label="Summary"
            rightIcon={renderRightIcon('chapter_long_summary', form.values.chapters[index].chapter_long_summary)}
            disabled={false}
            value={form.values.chapters[index].chapter_long_summary}
            className="text-base"
            onChange={form.handleChange}
          />
        </>
      )}
    </div>
  );
};

export default ContentSummary;
