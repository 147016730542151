import React from 'react';

import CookieBanner from '@pray/shared/components/CookieBanner/CookieBanner';
import EventListener from '@pray/shared/components/events/EventListener';
import PrayContextProvider from '@pray/shared/context/PrayContext';

import { rootSelector } from '../..';

export default function PageLayout({ children }) {
  return (
    <PrayContextProvider>
      {children}
      <EventListener rootSelector={rootSelector} />
      <CookieBanner />
    </PrayContextProvider>
  );
}
