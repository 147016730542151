import { CourseEpisode, GetEpisodesResponse } from './types';

export type GetEpisodesParams = {
  courseId: string;
  artistId: string;
};

// Persistent mock store
// This will maintain the state between function calls
const mockStore = new Map<string, CourseEpisode[]>();

// Episodes Mock Data
const getInitialEpisodes = (): CourseEpisode[] => [
  {
    id: '1',
    title: 'Introduction to Prayer',
    content: `<h1>Introduction to Prayer</h1>
    <p>Prayer is the foundation of our relationship with God. It is through prayer that we communicate with Him, expressing our gratitude, confessing our sins, and presenting our requests.</p>
    <h2>What We'll Learn</h2>
    <p>In this first episode, we'll explore the basics of prayer and why it's essential for spiritual growth. We'll discuss different types of prayer and how to establish a consistent prayer routine.</p>`,
  },
  {
    id: '2',
    title: 'Building a Prayer Habit',
    content: `<h1>Building a Prayer Habit</h1>
    <p>Consistency is key when it comes to developing a meaningful prayer life. In this episode, we'll discuss practical strategies for making prayer a daily habit.</p>
    <h2>Creating Sacred Space</h2>
    <p>Designating a specific time and place for prayer can help establish a routine.</p>`,
  },
  {
    id: '3',
    title: 'Overcoming Challenges in Prayer',
    content: `<h1>Overcoming Challenges in Prayer</h1>
    <p>Even the most devoted believers encounter obstacles in their prayer life. This episode addresses common challenges and how to overcome them.</p>`,
  },
];

/**
 * Get all episodes for a specific course
 * @param params - The parameters for fetching episodes
 * @param params.courseId - The ID of the course
 * @param params.artistId - The ID of the artist
 * @returns A promise that resolves to the episodes data
 */
export async function getEpisodes({ courseId, artistId }: GetEpisodesParams): Promise<GetEpisodesResponse> {
  if (!courseId) throw new Error('Course ID is required');
  if (!artistId) throw new Error('Artist ID is required');

  await new Promise((resolve) => setTimeout(resolve, 800));

  const storeKey = `${artistId}:${courseId}`;

  if (!mockStore.has(storeKey)) {
    mockStore.set(storeKey, getInitialEpisodes());
  }

  const courseEpisodes = mockStore.get(storeKey) || [];

  const response: GetEpisodesResponse = {
    /* eslint-disable camelcase */
    responded_at: new Date().toISOString(),
    object: 'object',
    data: courseEpisodes,
    /* eslint-enable camelcase */
  };

  return response;

  // Uncomment when server implementation is ready
  // const url = `/web/artists/${artistId}/courses/${courseId}/episodes`;
  // const response = await api.executeGetRequest<GetEpisodesResponse>(url);
  // return response.data;
}

// Add helper functions to modify the mock data
// These will be called by your create/update/delete functions
// This is a temporary solution to avoid calling the server
// When the server is ready, these functions will be removed

/**
 * Helper to add an episode to the mock store
 */
export function _mockAddEpisode(params: { artistId: string; courseId: string; episode: CourseEpisode }): void {
  const { artistId, courseId, episode } = params;
  const storeKey = `${artistId}:${courseId}`;

  if (!mockStore.has(storeKey)) {
    mockStore.set(storeKey, getInitialEpisodes());
  }

  const episodes = mockStore.get(storeKey) || [];
  mockStore.set(storeKey, [...episodes, episode]);
}

/**
 * Helper to delete an episode from the mock store
 */
export function _mockDeleteEpisode(params: { artistId: string; courseId: string; episodeId: string }): void {
  const { artistId, courseId, episodeId } = params;
  const storeKey = `${artistId}:${courseId}`;

  if (!mockStore.has(storeKey)) return;

  const episodes = mockStore.get(storeKey) || [];
  const updatedEpisodes = episodes.filter((ep) => ep.id !== episodeId);
  mockStore.set(storeKey, updatedEpisodes);
}
