import api from '@pray/shared/utils/api';

export async function getTaxStatementReport({ artistId = '', year = 0, option = 'gifts' } = {}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!year) throw new Error('Year is required');

  const params = {
    report: option, // 'gifts' or 'totals'
    year,
  };

  const options = {
    responseType: 'blob',
  };

  const url = `/web/studio/artists/${artistId}/giving-payments/statements/tax-statement`;
  const response = await api.executeGetRequest(url, params, options);

  return response.data;
}
