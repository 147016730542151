import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeadCampaign(leadCampaignId) {
  const query = useQuery({
    enabled: !!leadCampaignId,
    queryKey: queryKeys.leadCampaign(leadCampaignId),
    queryFn: async () => {
      const response = await studioService.leadCampaigns.getLeadCampaignById({
        leadCampaignId,
      });

      return response.data;
    },
  });

  return query;
}
