import { datadogLogs } from '@datadog/browser-logs';

import { DATADOG_CLIENT_TOKEN, DATADOG_ENV, DATADOG_SERVICE, DATADOG_SITE } from 'config.js';

function initializeDatadog() {
  if (DATADOG_ENV !== 'master') return;

  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    service: DATADOG_SERVICE,
    site: DATADOG_SITE,
    env: DATADOG_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'],
  });
}

initializeDatadog();

export default initializeDatadog;
