import React from 'react';

import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import { navigate } from '@pray/shared/utils/navigation';

import useAppRoutes from 'hooks/useAppRoutes';

import { PayoutBankDetails } from './PayoutBankDetails';
import { PayoutStatus } from './PayoutStatus';

export default function PayoutsTable({ data: payouts, hasNextPage, fetchNextPage }) {
  const appRoutes = useAppRoutes();

  return (
    <div className="overflow-y-hidden-scrollbar relative mt-4">
      <Table key={payouts?.map((payout) => payout.id)}>
        <Thead>
          <Tr className="sticky top-0 bg-white">
            <Th field="date">Date</Th>
            <Th field="status">Status</Th>
            <Th field="sent-to">Sent to</Th>
            <Th field="gross">Gross</Th>
            <Th field="fees-refunds">Fees & Refunds</Th>
            <Th field="net">Net Transferred</Th>
          </Tr>
        </Thead>
        <Tbody>
          {payouts?.map((payout) => (
            <Tr
              key={payout.id}
              className="cursor-pointer"
              onClick={() =>
                navigate(appRoutes.artistDonationsBankPayout(payout.id), { state: { payout }, replace: true })
              }
            >
              <Td>{payout.date}</Td>
              <Td>
                <PayoutStatus status={payout.status} />
              </Td>
              <Td>
                <PayoutBankDetails bank={payout.bank} />
              </Td>
              <Td>{payout.grossAmount}</Td>
              <Td>{payout.feeAmount}</Td>
              <Td>{payout.netAmount}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} />}
    </div>
  );
}
