import React, { useEffect, useState } from 'react';

import { typography } from '@pray/shared/components/foundations';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Options } from '@pray/shared/components/ui/Icons/Options';
import Text from '@pray/shared/components/ui/Text/Text';
import { SHARING_CHANNELS } from '@pray/shared/constants';
import useContentReaction from '@pray/shared/hooks/useContentReaction';
import MinimizeIcon from '@pray/shared/icons/MinimizeIcon';
import contentSharingService from '@pray/shared/services/contentSharingService';
import { isOnBrowser } from '@pray/shared/utils';

import Actions from './Actions/Actions';
import Controls from './Controls/Controls';
import EpisodeInformation from './EpisodeInformation/EpisodeInformation';
import OptionsModal from './OptionsModal/OptionsModal';
import Scrubber from './Scrubber/Scrubber';

import styles from './MaximizedContentPlayer.module.scss';

export default function MaximizedContentPlayer({
  audio,
  player,
  currentContent,
  controlOptions,
  handlePlayContent,
  handleSeekUpdate,
  handleShareContent,
  updateReactions,
  onMinimizeButtonClick,
  onClose,
}) {
  const [sharingUrl, setSharingUrl] = useState('');
  const [isShowOptions, setIsShowOptions] = useState(false);
  const { hasReacted, toggleContentReaction } = useContentReaction(currentContent);

  const toggleOptionsModal = () => setIsShowOptions(!isShowOptions);

  const handleNavigation = () => {
    if (isShowOptions) toggleOptionsModal();
    onMinimizeButtonClick();
  };

  const handleShareClick = () => {
    currentContent.external_sharing_url = sharingUrl;
    handleShareContent(currentContent);
  };

  const handleReacionButtonClick = () => {
    toggleContentReaction();
    updateReactions();
  };

  const prefetchSharingUrl = async () => {
    const url = await contentSharingService.getShareLink(
      SHARING_CHANNELS.EXTERNAL,
      currentContent.object,
      currentContent
    );

    setSharingUrl(url);
  };

  useEffect(() => {
    if (isOnBrowser()) {
      prefetchSharingUrl();
      document.body.classList.add('noscroll');
      return () => {
        document.body.classList.remove('noscroll');
      };
    }
  }, []);

  return (
    <>
      <div className={styles.fullscreen}>
        <div className={styles.appBar}>
          <IconButton onClick={onMinimizeButtonClick} data-tapeventname="Minimize" className={styles.appBarButton}>
            <MinimizeIcon />
          </IconButton>

          <Text variant={typography.subhead_medium} data-artistname>
            {currentContent.artist_name || currentContent.author_name}
          </Text>

          <IconButton
            onClick={toggleOptionsModal}
            data-tapeventname="Options"
            className={`${styles.appBarButton} ${styles.close}`}
          >
            <Options />
          </IconButton>
        </div>

        <div className={styles.imageContainer}>
          <img className={styles.contentImage} src={currentContent.image_url} alt={currentContent.title} />
        </div>

        <div className={styles.bottom}>
          <EpisodeInformation currentContent={currentContent} />

          <Scrubber
            currentTime={audio.currentTime}
            duration={audio.duration}
            remainingTime={audio.remainingTime}
            onChange={handleSeekUpdate}
            onEventSeekUpdate={player.dragStarted}
          />

          <div className={styles.controlsAndActions}>
            <div className="flex-1" />
            <Controls
              currentContent={currentContent}
              controlOptions={controlOptions}
              isPlaying={player.isPlaying}
              onPlayPauseButtonClick={player.isPlaying ? player.pause : player.play}
              onBackButtonClick={player.skip(-15)}
              onForwardButtonClick={player.skip(15)}
              onSkipBackButtonClick={handlePlayContent(currentContent.previousContentId)}
              onSkipForwardButtonClick={handlePlayContent(currentContent.nextContentId)}
            />

            <div className="flex-1">
              <Actions
                isContentSaved={hasReacted}
                currentContent={currentContent}
                controlOptions={controlOptions}
                onReactionButtonClick={handleReacionButtonClick}
                onShareButtonClick={handleShareClick}
                onNavigate={handleNavigation}
                onClose={onClose}
              />
            </div>
          </div>
        </div>
      </div>

      <OptionsModal
        currentContent={currentContent}
        isOpen={isShowOptions}
        isContentSaved={hasReacted}
        onClose={toggleOptionsModal}
        onSave={handleReacionButtonClick}
        onShare={handleShareClick}
        onNavigate={handleNavigation}
      />
    </>
  );
}
