import { typography, colors } from '@pray/shared/components/foundations';
import Label from '@pray/shared/components/ui/Label';
import { Table, Thead, Tr, Th, Tbody, TdLink } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';

import useAppRoutes from 'hooks/useAppRoutes';

import { emailStatus } from '../constants';

export default function EmailCampaignsTable({ data, sortBy, onSortChange }) {
  const appRoutes = useAppRoutes();

  function getURL(email) {
    return email.status === emailStatus.draft
      ? appRoutes.artistEmailsEdit(email.id)
      : appRoutes.artistEmailsOverview(email.id);
  }

  return (
    <div className="mt-6">
      <Table sortBy={sortBy} rowCount={data.length} onSortChange={onSortChange}>
        <Thead>
          <Tr>
            <Th sortable field="name">
              Email
            </Th>
            <Th sortable field="status">
              Status
            </Th>
            <Th sortable field="scheduled_at">
              Send Date
            </Th>
            <Th>Sends</Th>
            <Th>Open %</Th>
            <Th>Click %</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((email) => (
            <Tr key={email.id} className="cursor-pointer">
              <TdLink to={getURL(email)}>{email.name}</TdLink>
              <TdLink to={getURL(email)}>
                <Label className="uppercase" status={email.statusLabel}>
                  {email.status}
                </Label>
              </TdLink>
              <TdLink to={getURL(email)}>{email.scheduledAt || <EmptyCol />}</TdLink>
              <TdLink to={getURL(email)}>{email.sendCount || <EmptyCol />}</TdLink>
              <TdLink to={getURL(email)}>{email.openPercentage ? `${email.openPercentage}%` : <EmptyCol />}</TdLink>
              <TdLink to={getURL(email)}>{email.clickPercentage ? `${email.clickPercentage}%` : <EmptyCol />}</TdLink>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}

function EmptyCol() {
  return (
    <Text variant={typography.subhead_small} color={colors.text_disabled}>
      --
    </Text>
  );
}
