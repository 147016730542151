import api from '@pray/shared/utils/api';

export async function createLeadCampaign({
  name,
  title,
  subtitle,
  disclaimer,
  buttonText,
  type,
  targetCount,
  autopopulatePhoneNumber,
  leadClientId,
  scheduledStartDate,
  scheduledEndDate,
  dailyTargetLimit,
}) {
  if (!name) throw Error('Name is required');
  if (!title) throw Error('Title is required');
  if (!subtitle) throw Error('Description is required');
  if (!buttonText) throw Error('Button text is required');
  if (!type) throw Error('Type is required');
  if (!targetCount) throw Error('Target count is required');
  if (!leadClientId) throw Error('Organization is required');
  if (!scheduledStartDate) throw Error('Start date is required');

  const data = {
    name,
    title,
    subtitle,
    button_text: buttonText,
    type,
    target_count: targetCount,
    lead_client_id: leadClientId,
    scheduled_start_date: scheduledStartDate,
    daily_target_limit: dailyTargetLimit,
  };

  if (disclaimer) data.disclaimer = disclaimer;
  if (autopopulatePhoneNumber) data.auto_populate_phone_number = autopopulatePhoneNumber;
  if (scheduledEndDate) data.scheduled_end_date = scheduledEndDate;

  const url = `/web/studio/lead-campaigns`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
