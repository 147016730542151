export default {
  // style
  border_none: 'border_none',
  border_divider: 'border_divider',
  border_button: 'border_button',
  border_secondary: 'border_secondary',

  // radius
  radius_none: 'radius_none',
  radius_small: 'radius_small',
  radius_medium: 'radius_medium',
  radius_big: 'radius_big',
  radius_huge: 'radius_huge',
};
