/**
 * See https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdEvent#.Type
 */
const AdEvents = {
  CLICK: 'click',
  COMPLETE: 'complete',
  ERROR: 'error',
  PAUSED: 'pause',
  RESUMED: 'resume',
  SKIPPED: 'skip',
  STARTED: 'start',
};

export default function initializeAds(player = null, adTagUrl = '', onAdEvent = null) {
  const isAdsEnabled = window.google && adTagUrl;

  if (!player || !isAdsEnabled) return;

  player.ima({ adTagUrl });
  player.ima.initializeAdDisplayContainer();

  player.on('adsready', () => {
    if (!onAdEvent) return;

    const extraData = {};

    Object.keys(AdEvents).forEach((adEvent) => {
      player.ima.addEventListener(AdEvents[adEvent], (event) => {
        const now = Date.now();
        const eventType = event.type;
        const { data } = event.getAd();
        const { timeOffset } = data.adPodInfo;

        if (eventType === AdEvents.STARTED) extraData.startTime = now;

        extraData.currentTime = now;
        extraData.adDuration = (extraData.currentTime - extraData.startTime) / 1000;
        extraData.adCompletionRate = extraData.adDuration / data.duration;
        extraData.adPlacementTime = timeOffset;

        if (timeOffset === 0) {
          extraData.adPlacement = 'pre-roll';
        } else if (timeOffset > 0) {
          extraData.adPlacement = 'mid-roll';
        } else if (timeOffset === -1) {
          extraData.adPlacement = 'post-roll';
        }

        const adData = { ...data, ...extraData };

        onAdEvent(eventType, adData);
      });
    });
  });

  player.on('adserror', (event) => {
    onAdEvent?.(AdEvents.ERROR, event?.data?.AdError?.data);
  });
}
