import React from 'react';

import { typography, colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { VoiceWarning } from 'images/icons';

import { MAX_SAMPLES } from '../../constants';

export default function SamplesCounter({ uploadedSamples }) {
  const isMaxSamples = uploadedSamples.length === MAX_SAMPLES;

  return (
    <div className="flex flex-col gap-0.5">
      <Text
        className="!font-medium"
        variant={typography.body_md}
        color={isMaxSamples ? colors.attention : colors.text_tertiary}
      >
        {`Samples ${uploadedSamples.length}/${MAX_SAMPLES}`}
      </Text>
      {isMaxSamples && (
        <Text className="flex items-center gap-1" variant={typography.body_sm} color={colors.attention}>
          <VoiceWarning width={16} height={16} />
          You've reached the maximum file limit
        </Text>
      )}
    </div>
  );
}
