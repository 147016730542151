import { useEffect, useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import studioService from '@pray/shared/services/studioService';

import { useStudioContext } from 'context/StudioContext';

import SearchItemsModal from '../../../../components/SearchItemsModal/SearchItemsModal';

import audioIcon from 'images/icons/audio.svg';
import videoIcon from 'images/icons/video.svg';

export default function SearchContentModal({ onAdd, onUploadContentClick, onClose }) {
  const { selectedArtist } = useStudioContext();
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const selectedItems = items.filter((item) => item.checked);
  const isAllSelected = selectedItems.length === items.length;
  const selectionMessage = `${selectedItems.length} series selected`;
  const itemSelectionInfo = selectedItems.length ? selectionMessage : '';
  const isNoItemsAvailable = !isLoading && !searchTerm && !items.length;

  const fetchArtistContent = async () => {
    setIsLoading(true);
    const response = await studioService.content.getArtistContent({
      artistId: selectedArtist.id,
      search: searchTerm,
      isAddedToSeries: false,
    });
    setItems(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    let active = true;

    if (!searchTerm) {
      fetchArtistContent();
    } else {
      setTimeout(() => {
        if (active) fetchArtistContent();
      }, 1000);
    }

    return () => {
      active = false;
    };
  }, [searchTerm]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggleSelectAllFiles = () => {
    setItems((prev) =>
      prev.map((item) => ({
        ...item,
        checked: !isAllSelected,
      }))
    );
  };

  const handleToggleSelectItem = (selectedItem) => {
    setItems((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.id === selectedItem.id ? !item.checked : item.checked,
      }))
    );
  };

  const renderEmptyState = () => {
    if (isLoading) return null;

    if (!searchTerm) {
      return (
        <div className="flex h-full flex-col items-center justify-center space-y-1 text-center">
          <Text variant={typography.headline_medium}>No content available</Text>
          <Text variant={typography.headline_small} color={colors.text_muted}>
            Upload a file to add to your series
          </Text>
          <div className="pt-4">
            <Button variant={buttons.variant.primary} onClick={onUploadContentClick}>
              Upload File
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="flex h-full flex-col items-center justify-center space-y-1 text-center">
        <Text variant={typography.headline_medium}>No results</Text>
        <Text variant={typography.headline_small} color={colors.text_muted}>
          There were no results for “{searchTerm}”. <br />
          Please try again.
        </Text>
      </div>
    );
  };

  const renderContentImage = (item) => {
    const fallbackIcon = item.primary_video_url ? videoIcon : audioIcon;
    return item.primary_image_url || fallbackIcon;
  };

  return (
    <SearchItemsModal
      isOpen
      isLoading={false}
      title="Search Content"
      placeholder="Search content"
      disabled={isNoItemsAvailable}
      searchTerm={searchTerm}
      itemListTitle="All episodes"
      itemSelectionInfo={itemSelectionInfo}
      items={items}
      getTitle={(item) => item.title}
      getImage={renderContentImage}
      toggleSelectAllButtonLabel={isAllSelected ? 'Deselect all' : 'Select all'}
      emptyState={renderEmptyState()}
      onSearchTermChange={handleSearchTermChange}
      onToggleSelectAll={handleToggleSelectAllFiles}
      onToggleSelectItem={handleToggleSelectItem}
      onAdd={() => onAdd(selectedItems)}
      onClose={onClose}
    />
  );
}
