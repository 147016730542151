import amplitude from 'amplitude-js';

import { EVENTS, LOCAL_STORAGE, SESSION_STORAGE } from '@pray/shared/constants';
import logger from '@pray/shared/utils/logger';

import api from './api';
import { initializeBrazeSdk } from './braze';
import eventTracking from './eventTracking';
import storage from './storage';

async function authenticate() {
  if (typeof auth.customAuthentication === 'function') {
    return auth.customAuthentication();
  }

  try {
    // cleanup for previously stored tokens (use http cookies instead now)
    // TODO: safely remove this and associated code after 1 year (~ Jan 2023)
    if (storage.getFromStorage('authToken')) {
      const response = await api.executeDeprecatedAuthenticateRequest();
      _getUserFromResponseAndStoreLocally(response);
      return null;
    }

    const response = await api.executePostRequest(`/0.13/authenticate`, {});
    _getUserFromResponseAndStoreLocally(response);
  } catch (err) {
    logger.debug('authenticate error', err);
  }

  return null;
}

async function authenticateSSO(idToken) {
  try {
    const response = await api.executePostRequest(`/0.13/authenticate/firebase`, {
      id_token: idToken,
    });

    const user = _getUserFromResponseAndStoreLocally(response);

    if (user.is_registered) {
      amplitude.getInstance().setUserId(user.id);
      initializeBrazeSdk(user.id);
    }

    return response.data.data[0];
  } catch (err) {
    logger.debug('authenticate with SSO error', err);
  }

  return null;
}

// registerViewName is used to specify which page the user is registering from
// and is passed to the backend to be sent with the 'User Registered' event
async function getEmailStatus({
  email,
  queryParams = null,
  customRoute = null,
  isWebSubscribeFlow = false,
  registerViewName = null,
}) {
  if (!email) {
    logger.error('getEmailStatus: email is required');
    return null;
  }

  const user = storage.getUser();
  const isUserRegistered = storage.isUserRegistered();

  try {
    const response = await api.executePostRequest('/0.9/email-status', {
      email,
      web_query_params: queryParams,
      web_subscribe_route: customRoute,
      is_web_subscribe_flow: isWebSubscribeFlow,
    });

    const responseData = response?.data?.data;
    if (!responseData) throw new Error('invalid response for email-status');

    const isEmailAvailable = responseData.is_email_available;
    const isUserSubscribed = responseData.is_email_associated_to_user_with_active_subscription;
    const isUserSubscribedWithFamilyPlan = responseData.is_email_associated_to_user_with_family_subscription;

    const details = {
      isEmailAvailable,
      isUserSubscribed,
      isUserSubscribedWithFamilyPlan,
    };

    if (isEmailAvailable) {
      if (isUserRegistered) {
        try {
          api.executePutRequest(`/0.9/users/${user.id}`, { email });
        } catch (error) {
          logger.debug('error:', error);
        }
      } else {
        // when saving an email to a user that is not registered, we must call /register
        // https://prayinc.atlassian.net/wiki/spaces/EN/pages/1556512836/User+Registered+Server
        try {
          const registerForm = { email };
          if (registerViewName) registerForm.view_name = registerViewName;
          await register(registerForm);
        } catch (error) {
          logger.debug('error:', error);
        }
      }
    }

    return details;
  } catch (error) {
    logger.debug('getEmailStatus error:', error);
    throw error;
  } finally {
    const formFields = { email };
    const isFbCustomLeadEvent = true;

    eventTracking.trackEventSubmitForm(
      EVENTS.PRAY_EVENTS.SUBMIT_FORM_EVENT_NAMES.EMAIL_SUBSCRIBE,
      formFields,
      isFbCustomLeadEvent
    );
  }
}

function register(form) {
  return api
    .executePostRequest(`/0.13/register`, form)
    .then((response) => {
      const data = response.data.data[0];

      if (data.object === 'error' && data.info === 'Invalid verification code') {
        return 'invalidVerificationCode';
      }
      if (data.verification === false) {
        return 'invalidVerificationCode';
      }
      if (data.verification === true) {
        return 'invalidEmailAddress';
      }
      if (data.object === 'error') {
        return 'invalidVerificationCode';
      }

      const user = _getUserFromResponseAndStoreLocally(response);

      if (user.is_registered) {
        amplitude.getInstance().setUserId(user.id);
        initializeBrazeSdk(user.id);
        _sendEventsAfterUserRegistration(user);
      }

      return null;
    })
    .catch((error) => {
      // handle this error, passing it as a known response
      if (error?.data?.status === 400) {
        return 'invalidVerificationCode';
      }
      if (error?.message?.indexOf('verification code') > -1) {
        return 'invalidVerificationCode';
      }
      return error;
    });
}

function login(form) {
  return api
    .executePostRequest(`/0.13/login`, form)
    .then((response) => {
      const data = response.data.data[0];
      if (data.verification === false) {
        return 'invalidVerificationCode';
      }

      const user = _getUserFromResponseAndStoreLocally(response);

      if (user.is_registered) {
        amplitude.getInstance().setUserId(user.id);
        initializeBrazeSdk(user.id);
      }

      return null;
    })
    .catch((error) => {
      logger.debug('error', error);

      if (error.response.data?.data?.[0]?.info === 'User has not set up email login') {
        return 'emailLoginNotSetUp';
      }
      if (error.response.data?.data?.[0]?.info === 'Magic Link Expired') {
        return 'magicLinkExpired';
      }
      return 'invalidPassword';
    });
}

function logout() {
  return api
    .executePostRequest(`/0.13/logout`, {})
    .then(() => {
      _clearUserSession();

      authenticate();

      amplitude.getInstance().setUserId(null);
    })
    .catch((error) => {
      logger.debug('error', error);
    });
}

function _clearUserSession() {
  // remove local storage items
  storage.removeFromStorage(LOCAL_STORAGE.USER, true);

  // remove session storage items
  sessionStorage.removeItem(SESSION_STORAGE.CACHE);
}

function _getUserFromResponseAndStoreLocally(response) {
  const { tokens } = response.data.data[0];

  if (!tokens || !tokens.length) {
    logger.debug('invalid tokens in response', response);
    return {};
  }

  const sessionToken = tokens.find((x) => x.type === 'session')?.token;
  const refreshtoken = tokens.find((x) => x.type === 'refresh')?.token;

  const user = _decodeSessionTokenAndGetUser(sessionToken);
  if (!user) {
    logger.debug('invalid user in response', response);
    return {};
  }
  user.is_logged_in = !!refreshtoken;

  // convert user fields to their proper types
  _normalizeUserProperties(user);

  storage.upsertToStorage(LOCAL_STORAGE.USER, user, false);

  return user;
}

function _decodeSessionTokenAndGetUser(sessionToken) {
  try {
    const base64Url = sessionToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    const decodedToken = JSON.parse(jsonPayload);
    return decodedToken.user;
  } catch (error) {
    logger.debug('error in _decodeSessionTokenAndGetUser', error);
    return null;
  }
}

function _normalizeUserProperties(user) {
  if (user.is_subscribed !== undefined) user.is_subscribed = _convertToBoolean(user.is_subscribed);
  if (user.is_registered !== undefined) user.is_registered = _convertToBoolean(user.is_registered);
  if (user.is_verified !== undefined) user.is_verified = _convertToBoolean(user.is_verified);
}

function _convertToBoolean(value) {
  return String(value) === 'true' || String(value) === '1';
}

function _sendEventsAfterUserRegistration(user) {
  const eventData = {
    user_id: user.id,
    email: user.email,
  };

  eventTracking.trackFacebookEvent(EVENTS.FACEBOOK_EVENTS.NAMES.COMPLETE_REGISTRATION, eventData);
  eventTracking.trackFacebookEvent(EVENTS.FACEBOOK_EVENTS.NAMES.LEAD, eventData);
}

export { authenticate, authenticateSSO, getEmailStatus, login, logout, register };

const auth = {
  customAuthentication: null,
};

export default auth;
