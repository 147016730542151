import { colors, buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';

export function SocialClipsInfoDialog({ onClose = null }) {
  return (
    <Dialog onEscapeKeyDown={onClose}>
      <Text variant={typography.h1}>Social Clips</Text>
      <Text className="!font-medium" variant={typography.body_lg} color={colors.text_secondary}>
        Social Clips are short videos taken from your video that highlight the important and optimal content for sharing
        across various social channels. Social Clips help you reduce the cost and time needed to grow and engage your
        social media following.
      </Text>

      <div className="mt-4 flex justify-end gap-4">
        <Button variant={buttons.variant.secondary} onClick={onClose}>
          Close
        </Button>
      </div>
    </Dialog>
  );
}
