import { DEFAULT_MARKDOWN_COMPONENTS } from '../../components/Markdown/Markdown';

export const EDIT_TRANSCRIPT_SIDEBAR_PANELS = {
  CHAPTERS: 'chapters',
  VOICES: 'voices',
};

export const AUDIO_BOOK_STATE = {
  NOT_STARTED: 'not_started',
  GENERATING: 'generating',
  AVAILABLE: 'available',
};

export const LIBRARY_SIDEBAR_TABS = {
  DETAILS: 'details',
  TRANSCRIPT: 'transcript',
};

export const MARKDOWN_COMPONENTS_VERSION_HISTORY = {
  ...DEFAULT_MARKDOWN_COMPONENTS,
  p: ({ children }) => <p className="bg-inherit">{children}</p>,
};

export const BOOK_LIST_STATE = {
  LOADING: 'loading',
  EMPTY: 'empty',
  LIST: 'list',
};

export const INPUTS_MAX_LENGTH = 140;
export const CHAPTER_TITLE_MAX_LENGTH = 100;

export const TIME_BETWEEN_AUTO_SAVES_MS = 1500;

export const ACCEPTED_FILE_TYPES = {
  'application/pdf': ['.pdf'],
  'application/epub+zip': ['.epub'],
  'text/plain': ['.txt'],
  // TODO: Uncomment when it's supported by the BE
  // 'text/html': ['.html'],
};

export const ACCEPTED_FILE_EXTENSIONS_STRING = Object.values(ACCEPTED_FILE_TYPES)
  .flat()
  .join(', ')
  .replace(/,([^,]*)$/, ' and$1');
