import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';

const TRANSACTION_TYPE = {
  CHARGE: 'charge',
  REFUND: 'refund',
};

export default function PayoutTransactionTable({ data: transactions, hasNextPage, fetchNextPage }) {
  return (
    <div className="overflow-y-hidden-scrollbar relative">
      <Table key={transactions?.map((_, index) => index)}>
        <Thead>
          <Tr className="sticky top-0 bg-white">
            <Th field="date">Date</Th>
            <Th field="name">Name</Th>
            <Th field="fund">Fund</Th>
            <Th field="amount">Amount</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions?.map((transaction, index) => (
            <Tr key={index}>
              <Td>{transaction.date}</Td>
              <Td>{transaction.userName}</Td>
              <Td>{transaction.fundName}</Td>
              <Td>
                <Text color={transaction.type === TRANSACTION_TYPE.REFUND ? colors.attention : colors.text_primary}>
                  {transaction.amount}
                </Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} />}
    </div>
  );
}
