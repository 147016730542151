import React, { useEffect, useMemo, useState } from 'react';

import { Table, Tbody, Tr, Td, Th, Thead } from '@pray/shared/components/ui/Table';

import { layout } from 'components/layouts/constants';

export default function SegmentsList({ data = [], sortBy = '' }) {
  const [sort, setSort] = useState({ field: null, direction: 'asc' });

  const sortedData = useMemo(() => {
    if (!sort.field) return data;

    return [...data].sort((a, b) => {
      if (a[sort.field] < b[sort.field]) return sort.direction === 'asc' ? -1 : 1;
      if (a[sort.field] > b[sort.field]) return sort.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, sort]);

  const segments = useMemo(() => sortedData.map(segmentsMapper), [sortedData]);

  const segmentsFieldMapper = (field) => {
    switch (field) {
      case 'name':
        return 'name';
      case 'members':
        return 'member_count';
      default:
        return 'name';
    }
  };

  const onSortChange = (event) => {
    const [fieldKey, direction] = event.split(' ');
    const field = segmentsFieldMapper(fieldKey);

    setSort({
      field,
      direction,
    });
  };

  const handleScroll = () => {
    const tableHeader = document.querySelector('#sticky-header');

    if (tableHeader.offsetTop > 224) {
      tableHeader.classList.add('z-10');
    } else {
      tableHeader.classList.remove('z-10');
    }
  };

  useEffect(() => {
    const mainArea = document.getElementById(layout.main);
    mainArea.addEventListener('scroll', handleScroll);

    return () => {
      mainArea.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getRowActions = () => {
    return [];
  };

  return (
    <Table
      key={data.map(({ id }) => id).join('-')}
      sortBy={sortBy}
      rowCount={data.length}
      rowActions={getRowActions}
      onSortChange={onSortChange}
    >
      <Thead id="sticky-header" className="sticky top-[76px] bg-white shadow-[0px_1px_0px_0px_#e7e7e7]">
        <Tr>
          <Th field="name" sortable>
            Name
          </Th>
          <Th field="members" sortable>
            Members
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {segments.map((segment) => (
          <Tr key={segment.code}>
            <Td className="w-2/5 [&_span]:max-w-sm [&_span]:truncate">{segment.name}</Td>
            <Td className="w-1/5">{segment.members} Members</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}

const segmentsMapper = (segment) => {
  return {
    name: segment.name,
    code: segment.code,
    members: segment.member_count,
  };
};
