import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Label from '@pray/shared/components/ui/Label';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import PlayIcon from '@pray/shared/icons/PlayIconEmpty';
import { BookProcessStatus, type Book } from '@pray/shared/services/studioService/books/types';
import { navigate } from '@pray/shared/utils/navigation';

import ContentDateStatus from '../../../components/ContentDateStatus/ContentDateStatus';
import { BookCover } from '../components/BookCover';
import GenerateAudioModal from '../components/GenerateAudioModal';

import { useBookStatus } from '@/hooks/books/useBookStatus';
import useAppRoutes from '@/hooks/useAppRoutes';
import { AiStars } from '@/images/icons';

type BookListTableProps = {
  books: Book[];
  onPlay: (bookId: string) => void;
};

export default function BooksListTable({ books, onPlay }: BookListTableProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedBookId, setSelectedBookId] = useState<string | null>(null);
  const { artistId } = useParams();

  const handleGenerateAudio = (bookId: string) => {
    setSelectedBookId(bookId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Table sortable={false} rowCount={books.length}>
        <Thead>
          <Tr>
            <Th className="p-1">Name</Th>
            <Th className="max-w-md p-1">Date</Th>
            <Th className="max-w-md p-1">Audio Book</Th>
          </Tr>
        </Thead>
        <Tbody>
          {books.map((book) => (
            <BookRow key={book.id} book={book} onPlay={onPlay} onGenerateAudio={handleGenerateAudio} />
          ))}
        </Tbody>
      </Table>

      <GenerateAudioModal
        isOpen={isModalOpen}
        bookId={selectedBookId}
        artistId={artistId}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

function BookRow({
  book,
  onPlay,
  onGenerateAudio,
}: {
  book: Book;
  onPlay: (bookId: string) => void;
  onGenerateAudio: (bookId: string) => void;
}) {
  const { artistId } = useParams();
  const appRoutes = useAppRoutes();

  const { data, isLoading } = useBookStatus({ artistId, bookId: book.id });
  const audioBookState = data?.audiobookGeneration;
  const textExtractionState = data?.textExtraction;

  return (
    <Tr key={book.id} onClick={() => navigate(appRoutes.artistLibraryBookDetails(book.id))}>
      <Td className="w-1/4 p-1">
        <div className="flex flex-row gap-4">
          <div className="group relative h-[92px] w-[60px]">
            <BookCover image={book.thumbnail} />
            {audioBookState === BookProcessStatus.COMPLETED && (
              <Button
                className="invisible absolute inset-0 flex items-center justify-center bg-black/50 p-0 group-hover:visible"
                onClick={(e) => {
                  e.stopPropagation();
                  onPlay(book.id);
                }}
              >
                <PlayIcon className="m-0 max-h-12 w-10" />
              </Button>
            )}
          </div>
          <div className="flex max-w-[500px] flex-1 flex-col justify-center gap-2">
            <Text className="break-words text-base font-medium tracking-normal">{book.title}</Text>
            <Text className="text_tertiary break-words text-sm font-medium tracking-normal">{book.description}</Text>
          </div>
        </div>
      </Td>
      <Td className="w-[10%] p-1">
        <div className="flex flex-col justify-center gap-2">
          <ContentDateStatus
            content={{
              is_published: book.isPublished,
              created_at: book.createdAt,
              published_at: book.publishedAt,
            }}
          />
        </div>
      </Td>
      <Td className="w-[15%] p-1">
        <StatusColumn
          audioBookState={audioBookState}
          textExtractionState={textExtractionState}
          bookId={book.id}
          onGenerateAudio={onGenerateAudio}
          isLoading={isLoading}
        />
      </Td>
    </Tr>
  );
}

function StatusColumn({ audioBookState, textExtractionState, bookId, onGenerateAudio, isLoading }) {
  if (textExtractionState === BookProcessStatus.FAILED) {
    return (
      <Label status="unpublished" className="uppercase">
        Failed to extract chapters
      </Label>
    );
  }

  if (textExtractionState !== BookProcessStatus.COMPLETED && audioBookState === BookProcessStatus.NOT_STARTED) {
    return (
      <Button
        variant={buttons.variant.primary}
        color={colors.pray_featured}
        className="flex items-center gap-2"
        disabled
      >
        <Spinner /> Extracting Chapters
      </Button>
    );
  }

  return (
    <AudioBookButton state={audioBookState} bookId={bookId} onGenerateAudio={onGenerateAudio} isLoading={isLoading} />
  );
}

function AudioBookButton({ state, bookId, onGenerateAudio, isLoading }) {
  if (isLoading) {
    return (
      <Button
        variant={buttons.variant.primary}
        color={colors.pray_featured}
        className="flex items-center gap-2"
        disabled
        loading
      />
    );
  }

  if (state === BookProcessStatus.FAILED) {
    return (
      <Button
        variant={buttons.variant.primary}
        color={colors.pray_featured}
        className="flex items-center gap-2 !text-black"
        onClick={(e) => {
          e.stopPropagation();
          onGenerateAudio(bookId);
        }}
      >
        <AiStars className="text-black" /> Retry generating audio
      </Button>
    );
  }

  if (state === BookProcessStatus.PROCESSING) {
    return (
      <Button
        variant={buttons.variant.primary}
        color={colors.pray_featured}
        className="flex items-center gap-2"
        disabled
      >
        <Spinner /> Generating
      </Button>
    );
  }

  if (state === BookProcessStatus.COMPLETED) {
    return (
      <Label status="published" className="uppercase">
        Available
      </Label>
    );
  }

  return (
    <Button
      variant={buttons.variant.primary}
      color={colors.pray_featured}
      className="flex items-center gap-2 !text-black"
      onClick={(e) => {
        e.stopPropagation();
        onGenerateAudio(bookId);
      }}
    >
      <AiStars className="text-black" /> Generate Audio
    </Button>
  );
}
