import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

export default function MobilePreviewFrame({ isLoading, children }) {
  return (
    <MobileFrame>
      <MobileViewport isLoading={isLoading}>{children}</MobileViewport>
    </MobileFrame>
  );
}

function MobileFrame({ children }) {
  return (
    <div className="relative flex origin-top-left scale-90 flex-col items-center overflow-hidden rounded-[64px] border-8 border-black bg-black">
      {children}
      <MobileNotch />
    </div>
  );
}

function MobileViewport({ isLoading, children }) {
  return (
    <div className="relative mb-[34px] size-full bg-white/10">
      {isLoading && (
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
          <Spinner size="large" />
          <Text className="text-white/70">Loading preview...</Text>
        </div>
      )}
      {children}
    </div>
  );
}

function MobileNotch() {
  return (
    <div className="absolute inset-x-0 bottom-0 flex justify-center bg-black">
      <div className="m-4 h-1.5 w-32 rounded-full bg-white" />
    </div>
  );
}
