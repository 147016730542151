import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { EmptyDonations } from 'images/icons';

export function TransactionPaymentsEmpty({ viewType = 'subscribers' }) {
  const messages = {
    subscribers: {
      title: 'No subscribers found',
      subtitle: "You don't have any subscribers in the selected timeframe.",
    },
    organizations: {
      title: 'No organizations found',
      subtitle: 'There are no organizations to display in the selected timeframe.',
    },
    transactions: {
      title: 'No transactions found',
      subtitle: 'There are no transactions to display in the selected timeframe.',
    },
  };

  const { title, subtitle } = messages[viewType] || messages.subscribers;

  return (
    <div className="mt-32 flex flex-col items-center justify-center gap-2">
      <EmptyDonations className="mb-2 size-16" />
      <Text variant={typography.h2} color={colors.text_primary}>
        {title}
      </Text>
      <Text
        color={colors.text_tertiary}
        className="w-72 text-center text-[17px] font-medium leading-[22px] text-[#71747B]"
      >
        {subtitle}
      </Text>
    </div>
  );
}
