/**
 * ContentArticle component
 *
 * A component that provides blog post creation and editing functionality for article content.
 * It processes audio/video content to generate blog posts from transcripts using AI.
 *
 * Component Structure:
 * - Uses RichTextEditor for content editing with markdown support
 * - Integrates with WorkflowAIEmptyState for initial content generation
 * - Manages article state and content updates
 *
 * Features:
 * - AI-powered article generation from transcripts
 * - Rich text editing with formatting controls
 * - Article regeneration for content refinement
 * - Save, copy, and download capabilities
 * - Loading states and error handling
 *
 * Actions (from ./actions/):
 * - useGenerateArticle: Handles initial generation and regeneration
 * - useSaveArticle: Manages article saving and updates
 * - useDownloadArticle: Handles article downloading as HTML
 * - useCopyArticle: Manages clipboard operations
 *
 * @component
 * @param {Object} props
 * @param {Function} props.onWorkflowAIStarted - Callback when AI workflow starts
 * @param {Object} props.artist - Artist details for content association
 * @param {Object} props.content - Content details and metadata
 * @param {string} props.locale - Content locale for internationalization
 * @param {Function} props.setContent - Callback to update parent content state
 */

import { useState, useEffect } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover/Popover';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { useContentArticle } from 'hooks/contentArticle/useContentArticle';

import ContentArticleLoading from './ContentArticleLoading';
import ButtonCluster from '../../../../components/ButtonCluster';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';
import { useToastMessage } from '../../../../components/StudioToastMessage';
import TabPage from '../../../../components/TabPage/TabPage';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';
import { useCopyArticle } from './actions/useCopyArticle';
import { useDownloadArticle } from './actions/useDownloadArticle';
import { useGenerateArticle } from './actions/useGenerateArticle';
import { useSaveArticle } from './actions/useSaveArticle';

export const ARTICLE_STATUS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
  ARCHIVED: 'archived',
};

export default function ContentArticle({ onWorkflowAIStarted, artist, content, locale, setContent }) {
  const toast = useToastMessage();
  const [article, setArticle] = useState(null);
  const { data, isLoading, error } = useContentArticle(artist.id, content.id, locale);

  const { generateArticle, isProcessing, isRegenerating } = useGenerateArticle({
    artist,
    content,
    locale,
    setArticle,
    onWorkflowAIStarted,
  });

  const { saveArticle, isSaving } = useSaveArticle({
    artist,
    content,
    locale,
    setArticle,
  });

  const { downloadArticle } = useDownloadArticle({
    content,
  });

  const { copyArticle } = useCopyArticle();

  useEffect(() => {
    if (data?.article) {
      setArticle(data.article);
    }
  }, [data]);

  useEffect(() => {
    if (article) {
      setContent((prevContent) => ({
        ...prevContent,
        article,
      }));
    }
  }, [article, setContent]);

  useEffect(() => {
    if (error) {
      toast.show({ error: 'Failed to fetch article' });
    }
  }, [error]);

  const renderTopRightButtons = () => {
    if (!content) return null;
    return (
      <div className="sm-hide md-show flex gap-4">
        <Button
          variant={buttons.variant.primary}
          disabled={isRegenerating}
          onClick={() => generateArticle(true)}
          loading={isRegenerating}
          className="m-0 !border-transparent !bg-[#7C32E6] px-3 py-2 text-xs"
        >
          Regenerate
        </Button>
        <ButtonCluster text="Save" onClick={() => saveArticle(article)} loading={isSaving}>
          <DropdownMenu.Item title="Copy" onClick={() => copyArticle(article)} />
          <DropdownMenu.Item title="Download" onClick={() => downloadArticle(article)} />
        </ButtonCluster>
      </div>
    );
  };

  if (isLoading) {
    return <ContentArticleLoading />;
  }

  if (!article && !isLoading) {
    return (
      <WorkflowAIEmptyState
        isProcessing={isProcessing}
        content={content}
        handleWorkflowAIButton={() => generateArticle(false)}
        title="Article"
        description="Create and manage article blog posts generated from your content"
      />
    );
  }

  return (
    <TabPage
      title="Article"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_BLOG}
      topRightButtons={renderTopRightButtons()}
      titleInfo={
        <Popover title="Article" description="Create and manage article blog posts generated from your content">
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-full max-w-none px-8">
        <RichTextEditor
          content={article}
          onChange={setArticle}
          className="min-h-[300px] focus:bg-black/[0.01] focus:outline-none"
          toolbarClassName="top-[75px]"
        />
      </div>
    </TabPage>
  );
}
