import React from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr, RowAction } from '@pray/shared/components/ui/Table';
import { formatCurrency } from '@pray/shared/utils';

import useAppRoutes from 'hooks/useAppRoutes';
import { Chat, Envelope } from 'images/icons';

import { TableSkeleton } from './TableSkeleton';

export function SubscriberList({ data: subscribers, hasNextPage, fetchNextPage, isFetchingNextPage }) {
  const appRoutes = useAppRoutes();

  const getRowActions = (rowIndex) => {
    const subscriber = subscribers[rowIndex];

    if (!subscriber) return [];

    return [
      <RowAction visible key={`email-${subscriber.id}}`}>
        <IconButton icon={Envelope} tooltip="Email" disabled />
      </RowAction>,
      <RowAction visible key={`chat-${subscriber.id}}`}>
        <IconButton icon={Chat} tooltip="Chat" to={appRoutes.artistChatsUserId(subscriber.id)} />
      </RowAction>,
    ];
  };

  return (
    <div className="relative overflow-y-visible">
      <Table
        key={subscribers?.map((subscriber) => subscriber.id)}
        rowCount={subscribers?.length}
        rowActions={getRowActions}
      >
        <Thead>
          <Tr className="sticky top-0 bg-white">
            <Th />
            <Th field="firstName">First Name</Th>
            <Th field="lastName">Last Name</Th>
            <Th field="startDate">Start Date</Th>
            <Th field="interval">Interval</Th>
            <Th field="renewals">Renewals</Th>
            <Th field="amount">Amount</Th>
            <Th field="totalRevenue">Total Revenue</Th>
          </Tr>
        </Thead>
        <Tbody>
          {subscribers?.map((subscriber) => (
            <Tr key={subscriber.id}>
              <Td />
              <Td>{subscriber.firstName}</Td>
              <Td>{subscriber.lastName}</Td>
              <Td>{new Date(subscriber.startDate).toLocaleDateString()}</Td>
              <Td>{subscriber.interval === 'month' ? 'Monthly' : 'Annual'}</Td>
              <Td>{subscriber.renewalCount}</Td>
              <Td>{formatCurrency(subscriber.subscriptionAmount || 0)}</Td>
              <Td>{formatCurrency(subscriber.totalRevenue || 0)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} threshold={100} />}
      {isFetchingNextPage && <TableSkeleton type="subscribers" rows={3} />}
    </div>
  );
}
