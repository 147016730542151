import api from '@pray/shared/utils/api';

export async function getDailySeriesCoverArts({ artistId, dailySeriesId }) {
  if (!artistId) throw Error('Artist ID is required ');
  if (!dailySeriesId) throw Error('Daily Series ID is required');

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}/cover-arts`;
  const response = await api.executeGetRequest(url);

  return response.data;
}
