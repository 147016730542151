import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';

export default function Alert({ icon = null, title = '', message = '', className = 'max-w-[740px]' }) {
  return (
    <div className={`bg-[#fae0db] ${className} rounded-xl px-4 py-3`}>
      <div className="flex space-x-4">
        {icon}
        <div className="block">
          {title && (
            <Text className="text-[#441803]" variant={typography.heading_lg}>
              {title}
            </Text>
          )}
          <Text className="text-[#441803]" variant={typography.heading_md}>
            {message}
          </Text>
        </div>
      </div>
    </div>
  );
}
