import React, { useEffect } from 'react';
import videojs from 'video.js';
import 'videojs-contrib-ads';
import 'videojs-ima';

import initializeAds from './utils/initializeAds';
import initializeEventListeners from './utils/initializeEventListeners';

import 'video.js/dist/video-js.css';
import 'videojs-ima/dist/videojs.ima.css';

import './Video.scss';

export default function Video({
  videoUrl = '',
  adTagUrl = '',
  onAdEvent = null,
  onReady = null,
  onDisposed = null,
  onPlay = null,
  onPause = null,
  onWaiting = null,
  onPlaying = null,
  onTimeUpdate = null,
  onSeeking = null,
  onSeeked = null,
  onEnded = null,
  onError = null,
  onLoadedData = null,
  onLoadedMetadata = null,
}) {
  const playerElementId = 'content_video';

  const options = {
    autoplay: true,
    muted: true,
    playsinline: true,
    controls: true,
    responsive: true,
    fluid: true,
  };

  useEffect(() => {
    const player = videojs(playerElementId, options);

    initializeAds(player, adTagUrl, onAdEvent);

    player.on('ready', () => {
      initializeEventListeners(player, {
        onEnded,
        onError,
        onLoadedData,
        onLoadedMetadata,
        onPause,
        onPlay,
        onPlaying,
        onSeeked,
        onSeeking,
        onTimeUpdate,
        onWaiting,
      });

      onReady?.(player);
    });

    return () => {
      onDisposed?.(player, player.currentTime());
      player.dispose();
    };
  }, []);

  return (
    <div data-vjs-player>
      <video id={playerElementId} className="video-js vjs-big-play-centered" playsInline>
        <source src={videoUrl} />
      </video>
    </div>
  );
}
