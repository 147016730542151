import React from 'react';

const JumpBackIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4701 1.36322C11.1772 1.07033 10.7023 1.07033 10.4094 1.36322L7.75759 4.01505C7.4647 4.30794 7.4647 4.78282 7.75759 5.07571L10.4095 7.72758C10.7024 8.02048 11.1772 8.02048 11.4701 7.72758C11.763 7.43469 11.763 6.95982 11.4701 6.66692L10.0573 5.25411C12.5609 4.58557 15.3401 5.23418 17.3026 7.19665C20.2317 10.1258 20.2318 14.8751 17.3027 17.8041C14.3736 20.7332 9.62437 20.7332 6.69523 17.804C4.09599 15.2048 3.80283 11.1707 5.81751 8.24866C6.05263 7.90765 5.96679 7.4406 5.62578 7.20548C5.28477 6.97035 4.81772 7.05619 4.58259 7.39721C2.1645 10.9043 2.51424 15.7443 5.63456 18.8647C9.14948 22.3796 14.8485 22.3797 18.3634 18.8648C21.8782 15.3499 21.8782 9.6509 18.3633 6.13599C16.1492 3.92192 13.0703 3.10323 10.2168 3.6772L11.4701 2.42388C11.763 2.13099 11.763 1.65612 11.4701 1.36322Z"
        fill="#A6A8AD"
      />
      <path
        d="M9.86995 14.8366V10.9551H9.15245C8.93015 10.9551 8.74995 10.7749 8.74995 10.5526C8.74995 10.3303 8.93015 10.1501 9.15245 10.1501H10.7309V14.8366C10.7309 15.0744 10.5382 15.2671 10.3004 15.2671C10.0627 15.2671 9.86995 15.0744 9.86995 14.8366Z"
        fill="#A6A8AD"
      />
      <path
        d="M15.2362 13.4891C15.2362 14.5951 14.4592 15.3511 13.3182 15.3511C12.4078 15.3511 11.7604 14.8555 11.5867 14.0705C11.5337 13.8308 11.7392 13.6291 11.9847 13.6291C12.2302 13.6291 12.4136 13.8373 12.5091 14.0634C12.6416 14.377 12.9329 14.5531 13.3392 14.5531C13.9412 14.5531 14.3262 14.1611 14.3262 13.5381C14.3262 12.9431 13.9692 12.5371 13.3532 12.5371C12.9612 12.5371 12.6042 12.7331 12.4222 12.9991L11.6312 12.8311L12.2612 10.1501H14.5152C14.7433 10.1501 14.9282 10.335 14.9282 10.5631C14.9282 10.7912 14.7433 10.9761 14.5152 10.9761H12.9052L12.6322 12.0401C12.8772 11.8791 13.1852 11.7881 13.5352 11.7881C14.5572 11.7881 15.2362 12.4811 15.2362 13.4891Z"
        fill="#A6A8AD"
      />
    </svg>
  );
};

export default JumpBackIcon;
