import { useState, useCallback, useEffect } from 'react';

import { RegenerationStatus } from '@pray/shared/services/studioService/courses/types';

import { useRegenerateEpisode, useCreateEpisode, useDeleteEpisode, useGetEpisodes } from 'hooks/courses';

import type { CourseEpisode } from '../components/Content/EpisodesContent/types';

export type UseCourseEpisodesProps = {
  courseId: string;
  artistId: string;
};

export type UseCourseEpisodesReturn = {
  episodes: CourseEpisode[];
  selectedEpisodeId: string | undefined;
  isRegenerating: boolean;
  isEpisodesLoading: boolean;
  isCreatingPending: boolean;
  isDeletingPending: boolean;
  error: Error | null;
  handleSelectEpisode: (episodeId: string) => void;
  handleContentChange: (episodeId: string, content: string) => void;
  handleDeleteEpisode: (episodeId: string) => void;
  handleRegenerateContent: (episodeId: string) => Promise<void>;
  handleCreateEpisode: () => Promise<void>;
};

export function useCourseEpisodes({ courseId, artistId }: UseCourseEpisodesProps): UseCourseEpisodesReturn {
  const [episodes, setEpisodes] = useState<CourseEpisode[]>([]);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState<string | undefined>(undefined);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const { mutateAsync: regenerateEpisodeAsync } = useRegenerateEpisode({ artistId, courseId });
  const { mutateAsync: createEpisodeAsync, isPending: isCreatingPending } = useCreateEpisode({ artistId, courseId });
  const { deleteEpisode, isPending: isDeletingPending } = useDeleteEpisode({ artistId, courseId });

  const {
    data: fetchedEpisodes,
    isLoading: isEpisodesLoading,
    error: fetchError,
  } = useGetEpisodes({
    artistId,
    courseId,
  });

  useEffect(() => {
    if (fetchedEpisodes) {
      setEpisodes(fetchedEpisodes);
    }
  }, [fetchedEpisodes]);

  useEffect(() => {
    if (fetchError) {
      setError(new Error('Failed to fetch episodes'));
    }
  }, [fetchError]);

  const handleSelectEpisode = useCallback((episodeId: string | null) => {
    setSelectedEpisodeId(episodeId || undefined);
  }, []);

  useEffect(() => {
    // If there are episodes but no selected episode, select the first one
    if (episodes?.length > 0 && !selectedEpisodeId) {
      handleSelectEpisode(episodes[0].id);
    }

    // If the selected episode is no longer in the list (was deleted)
    // and there are other episodes, select the first one
    if (selectedEpisodeId && episodes?.length > 0 && !episodes.some((ep) => ep.id === selectedEpisodeId)) {
      handleSelectEpisode(episodes[0].id);
    }

    // If there are no episodes, clear the selection
    if (episodes?.length === 0 && selectedEpisodeId) {
      handleSelectEpisode(null);
    }
  }, [episodes, selectedEpisodeId, handleSelectEpisode]);

  const handleContentChange = useCallback(
    (episodeId: string, content: string) => {
      setEpisodes((prev) => prev.map((episode) => (episode.id === episodeId ? { ...episode, content } : episode)));
    },
    [courseId]
  );

  const handleDeleteEpisode = useCallback(
    async (episodeId: string) => {
      try {
        await deleteEpisode({ episodeId });

        if (selectedEpisodeId === episodeId) {
          const updatedEpisodes = episodes.filter((ep) => ep.id !== episodeId);

          if (updatedEpisodes.length > 0) {
            setSelectedEpisodeId(updatedEpisodes[0].id);
          } else {
            setSelectedEpisodeId(undefined);
          }
        }
      } catch (err) {
        setError(new Error('Failed to delete episode'));
      }
    },
    [deleteEpisode, episodes, selectedEpisodeId]
  );

  const handleRegenerateContent = useCallback(
    async (episodeId: string) => {
      setIsRegenerating(true);

      try {
        const data = await regenerateEpisodeAsync({ episodeId });

        // Simulate updating the content with the regenerated content
        const regeneratedContent = `<h1>Regenerated Episode Content</h1>
      <p>This is regenerated content for episode ${episodeId} in course ${courseId}.</p>
      <p>Status: ${data.status}</p>
      <p>Generation ID: ${data.generation_id}</p>
      <p>Message: ${data.message}</p>
      <h2>Additional Content</h2>
      <p>In production, this content would come from the AI generation service.</p>`;

        setEpisodes((prev) =>
          prev.map((episode) => (episode.id === episodeId ? { ...episode, content: regeneratedContent } : episode))
        );

        if (data.status === RegenerationStatus.PROCESSING) {
          console.log('Content regeneration is processing. In a production app, we would poll for updates.');
        } else if (data.status === RegenerationStatus.FAILED) {
          setError(new Error(`Content regeneration failed: ${data.message}`));
        }
      } catch (err) {
        setError(new Error('Failed to regenerate content'));
      } finally {
        setIsRegenerating(false);
      }
    },
    [courseId, regenerateEpisodeAsync]
  );

  const handleCreateEpisode = useCallback(async () => {
    try {
      const data = await createEpisodeAsync();

      const newEpisode: CourseEpisode = {
        id: data.id,
        title: data.title,
        content: `<h1>${data.title}</h1><p>${data.transcript}</p>`,
      };

      setEpisodes((prev) => [...prev, newEpisode]);
      setSelectedEpisodeId(newEpisode.id);
    } catch (err) {
      setError(new Error('Failed to create episode'));
    }
  }, [createEpisodeAsync]);

  return {
    episodes,
    selectedEpisodeId,
    isRegenerating,
    isEpisodesLoading,
    isCreatingPending,
    isDeletingPending,
    error,
    handleSelectEpisode,
    handleContentChange,
    handleDeleteEpisode,
    handleRegenerateContent,
    handleCreateEpisode,
  };
}
