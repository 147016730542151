import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

import type { LeadListMappings, LeadListStatus } from './types';

type GetLeadListMetadataProps = {
  artistId: string;
  listId: string;
};

/* eslint-disable camelcase */
export type GetLeadListMetadataResponse = ObjectResponse<{
  id: string;
  list_name: string;
  file_processing_status: LeadListStatus;
  file_column_mapping: LeadListMappings[];
  count: number;
  created_at: string;
  updated_at: string;
}>;
/* eslint-enable camelcase */

export async function getLeadListMetadata({ artistId, listId }: GetLeadListMetadataProps) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!listId) throw new Error('List ID is required');

  const url = `/web/studio/artists/${artistId}/leads-lists/${listId}`;
  const response = await api.executeGetRequest<GetLeadListMetadataResponse>(url);

  return response.data;
}
