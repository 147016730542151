import api from '@pray/shared/utils/api';

export async function deleteEmailTemplate({ artistId = undefined, templateId = undefined }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!templateId) throw new Error('Template ID is required');

  const url = `/web/studio/artists/${artistId}/email-templates/${templateId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data;
}
