import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

type RenameChapterParams = {
  artistId: string;
  bookId: string;
  chapterId: string;
  title?: string;
};

export function useRenameChapter() {
  async function renameChapter({ artistId, bookId, chapterId, title }: RenameChapterParams): Promise<void> {
    await studioService.chapters.renameChapter({
      artistId,
      bookId,
      chapterId,
      title,
    });
  }

  const { mutateAsync } = useMutation({
    mutationFn: renameChapter,
  });

  return { renameChapter: mutateAsync };
}
