import api from '@pray/shared/utils/api';

export async function updateTagging({ taggingId, priority }) {
  if (!taggingId) {
    throw Error('Tagging ID is required');
  }

  const data = {
    priority,
  };

  if (data.priority === '' || data.priority === undefined) {
    data.priority = null;
  }

  const url = `/web/studio/taggings/${taggingId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
