import { RegenerateEpisodeResponse, RegenerationStatus } from './types';

export type RegenerateEpisodeServiceParams = {
  episodeId: string;
};

/**
 * Regenerates content for a course episode using AI
 * @param params - The parameters for the regenerate episode request
 * @param params.episodeId - The ID of the episode to regenerate
 * @returns A promise that resolves to the regenerate episode response data
 */
export async function regenerateEpisode({ episodeId }: RegenerateEpisodeServiceParams) {
  if (!episodeId) throw new Error('Episode ID is required');

  await new Promise((resolve) => setTimeout(resolve, 1000));

  const response: RegenerateEpisodeResponse = {
    /* eslint-disable camelcase */
    responded_at: new Date().toISOString(),
    object: 'object',
    data: {
      id: episodeId,
      generation_id: `gen_${Math.random().toString(36).substring(2, 10)}`,
      message: 'Episode regeneration has been initiated',
      status: RegenerationStatus.PROCESSING,
    },
    /* eslint-enable camelcase */
  };

  return response;

  // Uncomment when server implementation is ready
  // const url = `/studio/courses/episodes/${episodeId}/regenerate`;
  // const response = await api.executePostRequest<RegenerateEpisodeResponse>(url);
  // return response.data;
}
