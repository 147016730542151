import { toBlob, toPixelData, toPng } from 'html-to-image';
import slugify from 'slugify';

import { dataUrlToFileUsingFetch } from '@pray/shared/utils/media';
/**
 * Retrieves the DOM node based on the provided element ID.
 * If the node is an iframe, it returns the content window's document body.
 * @param {string} elementId - The ID of the element.
 * @returns {HTMLElement} - The DOM node.
 */
function getNode(elementId) {
  const node = document.getElementById(elementId);

  if (node.nodeName === 'iframe') {
    return node.contentWindow.document.body;
  }

  return node;
}

/**
 * Converts an HTML element to an image URL.
 * @param {string} elementId - The ID of the element to convert.
 * @param {Function} getOptions - A function that returns the conversion options.
 * @returns {Promise<string>} - A promise that resolves to the image URL.
 *
 * @example
 * const url = await htmlElementToImageUrl('preview-iframe', (iframe) => ({
 *   backgroundColor: 'white',
 *   width: iframe.offsetWidth,
 *   height: iframe.offsetHeight,
 * }));
 *
 */
export async function htmlElementToImageUrl(elementId, getOptions) {
  const node = getNode(elementId);
  const options = getOptions?.(node);
  const url = await toPng(node, options);

  return url;
}

/**
 * Converts an HTML element to an image blob.
 * @param {string} elementId - The ID of the element to convert.
 * @param {Function} getOptions - A function that returns the conversion options.
 * @returns {Promise<Blob>} - A promise that resolves to the image blob.
 */
export async function htmlElementToImageBlob(elementId, getOptions) {
  const node = getNode(elementId);
  const options = getOptions?.(node);
  const blob = await toBlob(node, options);

  return blob;
}

/**
 * Converts an HTML element to pixel data.
 * @param {string} elementId - The ID of the element to convert.
 * @param {Function} getOptions - A function that returns the conversion options.
 * @returns {Promise<Uint8ClampedArray>} - A promise that resolves to the pixel data.
 */
export async function htmlElementToImagePixelData(elementId, getOptions) {
  const node = getNode(elementId);
  const options = getOptions?.(node);
  const pixelData = await toPixelData(node, options);

  return pixelData;
}

export async function htmlCodeToImageFile(htmlCode, name, options = {}) {
  if (!htmlCode) return null;

  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.srcdoc = htmlCode;
    iframe.width = options.width || '800';
    iframe.height = options.height || '600';
    iframe.style.scale = options.scale || '0.5';
    iframe.style.position = 'absolute';
    iframe.style.backgroundColor = options.backgroundColor || 'white';

    function filter(node) {
      return node.nodeName !== '#comment';
    }

    iframe.onload = async () => {
      try {
        const imageUrl = await toPng(iframe, { filter });
        const slug = slugify(name || 'preview');
        const fileName = `${slug}-${Date.now()}.png`;
        const file = await dataUrlToFileUsingFetch(imageUrl, fileName, 'image/png');

        resolve(file);
      } catch (error) {
        reject(error);
      } finally {
        document.body.removeChild(iframe);
      }
    };

    document.body.appendChild(iframe);
  });
}
