import { DISCOVER_MODALS } from '@pray/shared/constants';
import { parseQueryString } from '@pray/shared/utils/parseFromQuery';

import storage from './storage';
import Link from '../components/ui/Link/Link';

export const navigator = {
  initialize: ({ navigate, Link: RouterLink }) => {
    // Set navigation function based on app router
    navigator.navigate = navigate;

    // Link component based on app router
    Link.Component = RouterLink;
  },

  navigate: (url, options) => {
    // This function needs to be overriden upon app initialization
    console.log('navigate: not implemented!', url, options);
  },
};

export const navigate = (...args) => {
  navigator.navigate(...args);
};

export const navigateToLink = (defaultUrl, queryParams = null) => {
  const query = queryParams ?? parseQueryString();
  const { origin } = window?.location;
  const { next_url: nextUrl } = query;
  const redirectUrl = nextUrl?.startsWith(origin) || nextUrl?.startsWith('/') ? nextUrl : defaultUrl;

  return navigate(redirectUrl);
};

function navigateToDiscoverPageWithState(state = {}) {
  navigate('/discover/', {
    state: {
      ...state,
    },
  });
}

function navigateToDiscoverPageAndShowModal(modalId, state = {}) {
  navigateToDiscoverPageWithState({
    modalId,
    ...state,
  });
}

export function navigateToDiscoverPageAndShowContentNotFoundModal(state) {
  navigateToDiscoverPageAndShowModal(DISCOVER_MODALS.CONTENT_NOT_FOUND, state);
}

export function navigateToDiscoverPageAndShowCongratulationsModal(state) {
  navigateToDiscoverPageAndShowModal(DISCOVER_MODALS.CONGRATULATIONS, state);
}

export function navigateToDiscoverPageAndOpenPrayRadio() {
  navigateToDiscoverPageWithState({
    isOpenPrayRadio: true,
  });
}

export function navigateToPreviousPrayPage() {
  const navigationHistory = storage.getNavigationHistory();

  // If there is a history, go back
  if (navigationHistory.length > 1) {
    window.history.back();
    return;
  }

  // Otherwise, navigate to the fallback page
  navigate('/discover');
}

export default {
  navigateToDiscoverPageAndShowContentNotFoundModal,
  navigateToDiscoverPageAndShowCongratulationsModal,
  navigateToDiscoverPageAndOpenPrayRadio,
};
