import { useState, useCallback } from 'react';

import { useGenerateContentArticle } from 'hooks/contentArticle/useContentArticle';

import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useGenerateArticle({ artist, content, locale, setArticle, onWorkflowAIStarted }) {
  const toast = useToastMessage();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const { generateContentArticle } = useGenerateContentArticle();

  const generateArticle = useCallback(
    async (isRegenerate = false) => {
      try {
        if (isRegenerate) {
          setIsRegenerating(true);
        } else {
          setIsProcessing(true);
        }

        const { article: newArticle } = await generateContentArticle({
          artistId: artist.id,
          contentId: content.id,
          locale,
        });

        setArticle(newArticle);

        if (!isRegenerate) {
          onWorkflowAIStarted();
        }

        toast.show({
          success: `Article ${isRegenerate ? 're' : ''}generated successfully`,
        });

        return newArticle;
      } catch (error) {
        toast.show({
          error: `Failed to ${isRegenerate ? 're' : ''}generate article`,
        });
        return null;
      } finally {
        if (isRegenerate) {
          setIsRegenerating(false);
        } else {
          setIsProcessing(false);
        }
      }
    },
    [artist.id, content.id, locale, generateContentArticle, setArticle, onWorkflowAIStarted, toast]
  );

  return {
    generateArticle,
    isProcessing,
    isRegenerating,
  };
}
