import api from '@pray/shared/utils/api';

export async function createVoice({ artistId, payload }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!payload) throw Error('Payload is required');
  if (!payload.name) throw Error('Voice name is required');
  if (!payload.sample_urls || !Array.isArray(payload.sample_urls) || payload.sample_urls.length === 0)
    throw Error('Voice sample URLs are required');

  const url = `/web/studio/artists/${artistId}/voices`;
  const response = await api.executePostRequest(url, payload);

  return response?.data;
}
