import SendbirdChat from '@sendbird/chat';
import { GroupChannelHandler, GroupChannelModule, SuperChannelFilter } from '@sendbird/chat/groupChannel';
import { create } from 'zustand';

import { SENDBIRD_APP_ID } from 'config.js';
import { SENDBIRD } from 'constants.js';

const sendbirdConnection = SendbirdChat.init({
  appId: SENDBIRD_APP_ID,
  modules: [new GroupChannelModule()],
});

const connectSendbirdUser = async (userId) => {
  if (!userId) return {};

  const user = await sendbirdConnection.connect(userId);

  return user;
};

const fetchSendbirdUserData = async (userId) => {
  const user = await connectSendbirdUser(userId);

  if (!user) return {};

  const unreadMessagesCount = await sendbirdConnection.groupChannel.getTotalUnreadMessageCount({
    channelCustomTypesFilter: [SENDBIRD.CUSTOM_TYPES.LEADER_FOLLOWER_1_TO_1_CHANNEL],
    superChannelFilter: SuperChannelFilter.NON_SUPER,
  });

  return {
    user,
    unreadMessagesCount,
  };
};

const addSendbirdEventHandlers = (userId, refresh) => {
  sendbirdConnection.groupChannel.addGroupChannelHandler(
    `${userId}_groupChannelHandler`,
    new GroupChannelHandler({
      onChannelChanged: (channel) => {
        if (channel.customType === SENDBIRD.CUSTOM_TYPES.LEADER_FOLLOWER_1_TO_1_CHANNEL) {
          refresh();
        }
      },
    })
  );
};

const removeAllSendbirdEventHandlers = () => {
  sendbirdConnection.removeAllUserEventHandler();
  sendbirdConnection.groupChannel.removeAllGroupChannelHandlers();
};

const useSendbirdChat = create((set, get) => ({
  activeUserId: null,
  user: null,
  unreadMessagesCount: 0,

  setActiveUser: async (userId) => {
    const { user, unreadMessagesCount } = await fetchSendbirdUserData(userId);

    set((state) => {
      if (state.activeUserId !== userId) {
        try {
          removeAllSendbirdEventHandlers();
        } finally {
          addSendbirdEventHandlers(userId, get().refresh);
        }
      }

      return {
        activeUserId: userId,
        user,
        unreadMessagesCount,
      };
    });
  },

  refresh: async () => {
    const { activeUserId } = get();
    const { user, unreadMessagesCount } = await fetchSendbirdUserData(activeUserId);

    set({
      user,
      unreadMessagesCount,
    });
  },
}));

export default useSendbirdChat;
