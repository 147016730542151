import { animated, useSprings } from '@react-spring/web';
import React, { useReducer } from 'react';
import { Geographies } from 'react-simple-maps';

import { GEO_URL } from '@pray/shared/constants';

const useForceUpdate = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
};

export function AnimatedBalls({ index, isEnabled, from, to, audience = [] }) {
  const forceUpdate = useForceUpdate();

  // Animation for moving balls along arcs
  const ballAnimations = useSprings(
    audience.length,
    audience.map((_, i) => ({
      t: 1,
      loop: true,
      from: { t: 0 },
      config: { duration: 2500 },
      delay: i * 250,
      onChange: () => forceUpdate(),
    }))
  );

  // Function to interpolate position along the quadratic Bézier curve (arc)
  const calculateArcPosition = (from, to, tVal, projection) => {
    const fromProjected = projection(from);
    const toProjected = projection(to);
    const midX = (fromProjected[0] + toProjected[0]) / 2;
    const midY = (fromProjected[1] + toProjected[1]) / 2 - 300; // Higher arc curve

    // Quadratic Bézier formula
    const x = (1 - tVal) * (1 - tVal) * fromProjected[0] + 2 * (1 - tVal) * tVal * midX + tVal * tVal * toProjected[0];
    const y = (1 - tVal) * (1 - tVal) * fromProjected[1] + 2 * (1 - tVal) * tVal * midY + tVal * tVal * toProjected[1];

    return { x, y };
  };

  return (
    <Geographies geography={GEO_URL}>
      {({ projection }) => {
        const animatedPosition = ballAnimations[index].t.to((tVal) => calculateArcPosition(from, to, tVal, projection));

        return (
          <animated.circle
            cx={animatedPosition.to((pos) => pos.x)}
            cy={animatedPosition.to((pos) => pos.y)}
            r={10}
            fill="#FFFFFF"
            stroke="#E3AF4A50"
            strokeWidth={8}
            style={{ opacity: isEnabled ? 1 : 0 }}
          />
        );
      }}
    </Geographies>
  );
}
