import React from 'react';

const VolumeOnIcon = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20.3C10.5 20.3 10.1 20.1 9.8 19.8L7.1 17.1C6.9 16.9 6.5 16.7 6.2 16.7H4.8C3.2 16.8 2 15.5 2 14V10C2 8.50004 3.2 7.20004 4.8 7.20004H6.2C6.5 7.20004 6.8 7.10004 7.1 6.80004L9.8 4.10004C10.3 3.60004 11.1 3.40004 11.7 3.70004C12.4 4.00004 12.8 4.60004 12.8 5.30004V18.5C12.8 19.2 12.4 19.8 11.7 20.1C11.5 20.3 11.2 20.3 11 20.3ZM4.8 8.80004C4.1 8.80004 3.6 9.40004 3.6 10V14C3.6 14.7 4.2 15.2 4.8 15.2H6.2C6.9 15.2 7.6 15.5 8.1 16L10.8 18.7C10.9 18.8 11 18.8 11.1 18.8C11.2 18.8 11.3 18.7 11.3 18.6V12V5.40004C11.3 5.30004 11.2 5.20004 11.1 5.20004C11 5.20004 10.9 5.20004 10.8 5.30004L8.1 7.90004C7.6 8.50004 6.9 8.80004 6.2 8.80004H4.8Z"
        fill="white"
      />
      <path
        d="M18.8007 19.7999C18.6007 19.7999 18.4007 19.6999 18.2007 19.4999C17.9007 19.1999 18.0007 18.6999 18.3007 18.3999C18.3043 18.3963 18.3105 18.3905 18.3191 18.3824C18.5492 18.1667 20.5007 16.3378 20.5007 11.9999C20.5007 7.66195 18.5492 5.83304 18.3191 5.61735C18.3105 5.6093 18.3043 5.60349 18.3007 5.59989C18.0007 5.29989 17.9007 4.89989 18.2007 4.49989C18.5007 4.19989 18.9007 4.09989 19.2007 4.39989C19.2048 4.40405 19.2135 4.41185 19.2263 4.42336C19.5207 4.68838 22.0007 6.92046 22.0007 11.9999C22.0007 17.0793 19.5207 19.3114 19.2263 19.5764C19.2135 19.5879 19.2048 19.5957 19.2007 19.5999C19.1007 19.6999 18.9007 19.7999 18.8007 19.7999Z"
        fill="white"
      />
      <path
        d="M14.8999 16.5C14.9999 16.7 15.2999 16.8 15.4999 16.8C15.617 16.8 15.6999 16.7657 15.7685 16.7372C15.817 16.7171 15.8585 16.7 15.8999 16.7C15.9064 16.6934 15.922 16.68 15.9455 16.6598C16.279 16.3723 18.1999 14.7163 18.1999 12.1C18.1999 9.48363 16.279 7.82771 15.9455 7.54017C15.922 7.51999 15.9064 7.50655 15.8999 7.49999C15.5999 7.19999 15.0999 7.29999 14.8999 7.59999C14.5999 7.89999 14.6999 8.29999 14.9999 8.59999C15.0999 8.59999 16.6999 9.99999 16.6999 12.1C16.6999 14.2 14.9999 15.5 14.9999 15.5C14.6999 15.7 14.6999 16.2 14.8999 16.5Z"
        fill="white"
      />
    </svg>
  );
};

export default VolumeOnIcon;
