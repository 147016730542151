import { randomId } from '@pray/shared/utils';

export * from './getAllEventsByPeriod';

export function eventsMapper(event) {
  return {
    id: randomId(),
    type: event.type,
    title: event.title,
    status: event.status,
    startDate: event.start_datetime,
    endDate: event.end_datetime,
    payload: event.payload,
  };
}
