import { useStudioContext } from 'context/StudioContext';

import { AudienceMap } from './AudienceMap';
import { EngagementTable } from './EngagementTable';
import useAudienceEngagement from './useAudienceEngagement';

export default function AudienceEngagementSection() {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const { data, isLoading, isError, refetch } = useAudienceEngagement({ artistId });
  const engagementData = data?.reachByLocation?.sort((a, b) => b.count - a.count);

  return (
    <div className="grid grid-cols-1 gap-8 rounded-2xl border border-[#DEDFE3] p-6 xl:grid-cols-6">
      <div className="xl:col-span-4">
        <AudienceMap data={data} isLoading={isLoading} isError={isError} refetch={refetch} />
      </div>
      {!isError && (
        <div className="xl:col-span-2">
          <EngagementTable data={engagementData} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}
