import api from '@pray/shared/utils/api';

export async function updateEmailCampaign({
  artistId = undefined,
  campaignId = undefined,
  name = undefined,
  status = undefined,
  scheduledAt = undefined,
  subjectText = undefined,
  previewText = undefined,
  previewImgUrl = undefined,
  rawHtml = undefined,
  rawCss = undefined,
  compiledHtmlCss = undefined,
  automationType = undefined,
}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!campaignId) throw new Error('Campaign ID is required');

  const data = {
    name,
    status,
    automation_type: automationType,
    scheduled_at: scheduledAt,
    subject_text: subjectText,
    preview_text: previewText,
    preview_img_url: previewImgUrl,
    raw_html: rawHtml,
    raw_css: rawCss,
    compiled_html_css: compiledHtmlCss,
  };

  const url = `/web/studio/artists/${artistId}/campaigns/emails/${campaignId}`;
  const response = await api.executePutRequest(url, data);

  return response.data;
}
