import React, { useState } from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';

export default function DailySeriesFormDropdown({ isDisabled = false, onDelete = null }) {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const toggleOpenMoreMenu = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  };

  return (
    <DropdownMenu
      isOpen={isMoreMenuOpen}
      onClose={toggleOpenMoreMenu}
      component={
        <Button disabled={isDisabled} onClick={toggleOpenMoreMenu}>
          •••
        </Button>
      }
    >
      <DropdownMenu.Item
        title="Delete"
        onClick={() => {
          onDelete();
          toggleOpenMoreMenu();
        }}
      />
    </DropdownMenu>
  );
}
