import { useEffect, useState } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { useStudioContext } from 'context/StudioContext';
import useLeaderContentById from 'hooks/content/useLeaderContentById';
import useLeaderContentSeriesById from 'hooks/contentSeries/useLeaderContentSeriesById';
import { Ellipsis, MagicWand } from 'images/icons';

import BasePhonePreview from './BasePhonePreview';

export default function AnnouncementPhonePreview({
  body,
  resourceType,
  resourceContentId,
  resourceContentSeriesId,
  resourceImageUrl,
  resourceTitle = '',
  resourceButtonText = '',
}) {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const selectedContent = useLeaderContentById(artistId, resourceContentId);
  const selectedContentSeries = useLeaderContentSeriesById(artistId, resourceContentSeriesId);

  const [imageUrl, setImage] = useState(null);
  const [resourceData, setResourceData] = useState(null);
  const [canPreviewAnnouncement, setCanPreviewAnnouncement] = useState(!!body);
  const [computedResourceType, setComputedResourceType] = useState(resourceType);

  // Determine if the announcement can be previewed
  useEffect(() => {
    setCanPreviewAnnouncement(!!body);
  }, [body]);

  // Set image based on the resource image URL
  useEffect(() => {
    if (resourceImageUrl instanceof File) {
      setImage(URL.createObjectURL(resourceImageUrl));
      return;
    }

    setImage(resourceImageUrl);
  }, [resourceImageUrl]);

  // Set computed resource type based on the selected resource type and post resource parameters
  useEffect(() => {
    if (resourceType === 'content' && resourceContentSeriesId && !resourceContentId) {
      setComputedResourceType('content-series');
    } else {
      setComputedResourceType(resourceType);
    }
  }, [resourceType, resourceContentId, resourceContentSeriesId]);

  // Set resource data based on the selected resource type
  useEffect(() => {
    if (computedResourceType === 'content-series' && selectedContentSeries?.data) {
      setResourceData({
        imageUrl: selectedContentSeries?.data?.image_url,
        title: selectedContentSeries?.data?.title,
        subtitle: selectedArtist?.name,
        buttonText: null,
      });
    } else if (computedResourceType === 'content' && selectedContent?.data) {
      setResourceData({
        imageUrl: selectedContent?.data?.primary_image_url,
        title: selectedContent?.data?.title,
        subtitle: selectedArtist?.name,
        buttonText: null,
      });
    } else if (computedResourceType === 'external_url' && resourceImageUrl) {
      setResourceData({
        imageUrl,
        title: resourceTitle,
        buttonText: resourceButtonText,
      });
    } else {
      setResourceData(null);
    }
  }, [
    computedResourceType,
    selectedContent.data,
    selectedContentSeries.data,
    imageUrl,
    resourceTitle,
    resourceButtonText,
  ]);

  return (
    <BasePhonePreview isScrollable>
      {canPreviewAnnouncement ? (
        <div className="size-full bg-black px-4 py-14">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <img src={selectedArtist?.profile_image_url} alt={selectedArtist.name} className="size-10 rounded-full" />
              <div className="ml-4 flex flex-col items-start justify-start">
                <Text variant={typography.subhead_medium} className="font-bold">
                  {selectedArtist?.name}
                </Text>
                <Text variant={typography.body_small} className="font-normal text-[#A6A8AD]">
                  Now
                </Text>
              </div>
            </div>
            <Ellipsis />
          </div>
          <Text variant={typography.body_small} wordLimit={23} className="mt-3 font-normal text-white">
            {body}
          </Text>
          {resourceData && (
            <div className="mt-3 w-full">
              <img src={resourceData.imageUrl} alt={resourceData.title} className="w-full" />
              {resourceData.title && (
                <div className="flex items-center justify-between bg-[#232426] p-3">
                  <div className="flex flex-col space-y-[2px]">
                    {resourceData.title && <Text variant={typography.subhead_medium}>{resourceData.title}</Text>}
                    {resourceData.subtitle && (
                      <Text className="text-[#A6A8AD]" variant={typography.footnote}>
                        {resourceData.subtitle}
                      </Text>
                    )}
                  </div>
                  {resourceData.buttonText && (
                    <Button size="small" className="mt-2 bg-white">
                      <Text color={colors.text_tertiary} variant={typography.button_small}>
                        {resourceData.buttonText}
                      </Text>
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <PhoneEmptyState />
      )}
    </BasePhonePreview>
  );
}

const PhoneEmptyState = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <MagicWand />
      <div className="mt-3 text-center">
        Your preview will
        <br />
        &nbsp;appear here
      </div>
    </div>
  );
};
