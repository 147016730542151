import React, { useState } from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';

import Section from 'components/pages/StudioPage/components/Section/Section';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import usePeriodicTable from 'hooks/periodicTable/usePeriodicTable';
import { Info } from 'images/icons';

import PeriodicTableReportError from './PeriodicTableReportError';
import PeriodicTableReportLoading from './PeriodicTableReportLoading';
import PeriodicTable from '../../../../components/PeriodicTable/PeriodicTable';
import PeriodicTableGroupsSection from '../../../../components/PeriodicTable/PeriodicTableGroupsSection';
import PeriodicTableInfoDrawer from '../../../../components/PeriodicTable/PeriodicTableInfoDrawer';

import styles from './PeriodicTableReport.module.scss';

export default function PeriodicTableReport() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const { selectedArtist } = useStudioContext();
  const { data: periodicTableData, isLoading, isError } = usePeriodicTable({ artistId: selectedArtist.id });

  const toggleDrawerOpen = () => {
    setIsDrawerOpen((isOpen) => !isOpen);
  };

  const toggleSelectedGroup = (group) => {
    if (group === selectedGroup) {
      return setSelectedGroup(null);
    }

    return setSelectedGroup(group);
  };

  const renderInfo = () => {
    return (
      <IconButton data-tapeventname="Close" onClick={toggleDrawerOpen}>
        <Info className="size-6 text-[#56585E]" />
      </IconButton>
    );
  };

  return (
    <TabPage title="Periodic Table" data-viewname={VIEW_EVENT_NAMES.ANALYTICS.PERIODIC_TABLE} titleInfo={renderInfo()}>
      <Section className={styles.periodicTableContainer}>
        {isLoading && <PeriodicTableReportLoading />}
        {isError && <PeriodicTableReportError />}
        <RenderedPeriodicTable
          segmentGroups={periodicTableData?.segmentGroups}
          selectedGroup={selectedGroup}
          toggleSelectedGroup={toggleSelectedGroup}
        />
      </Section>
      <PeriodicTableInfoDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
    </TabPage>
  );
}

const RenderedPeriodicTable = ({ segmentGroups, selectedGroup, toggleSelectedGroup }) => {
  if (!segmentGroups) return null;

  return (
    <>
      <PeriodicTableGroupsSection
        segmentGroups={segmentGroups}
        selectedGroup={selectedGroup}
        onSelectGroup={toggleSelectedGroup}
      />
      <PeriodicTable segmentGroups={segmentGroups} selectedGroup={selectedGroup} />
    </>
  );
};
