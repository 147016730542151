import api from '@pray/shared/utils/api';

export const getLeaderFollowers = async ({
  artistId = '',
  searchName = '',
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
}) => {
  if (!artistId) throw Error('Artist ID is required');

  const params = {};

  if (searchName) params.search_name = searchName;

  if (offset) params.offset = offset;

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/followers`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
