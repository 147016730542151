import { colors } from '@pray/shared/components/foundations';

export const lineData = {
  title: `Here's your content's view trends for the past week`,
  chart_data: {
    labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    datasets: [
      {
        label: 'Last week',
        data: [1500, 1900, 1950, 2400, 2100, 3200, 3400],
        borderColor: colors.$text_secondary,
      },
      {
        label: 'Current week',
        data: [1700, 1700, 2550, 2600, 2500, 3400, 4572],
        borderColor: colors.$pray_featured,
        fill: 'origin',
        backgroundColor: '#E3AF4A38',
      },
    ],
  },
  text_data: `- **27%** more views compared to the past 7 days.
- Your highest touchpoint is **4,572 views**, which is **12%** more than last week.
- Your lowest touchpoint is **1,834 views**, **7%** higher than last week.`,
};

export const barData = {
  title: `Here's a bar chart representing your Pray followers classified by age`,
  chart_data: {
    labels: ['<18', '18-25', '26-35', '36-45', '46-59', '>60'],
    datasets: [
      {
        label: 'Followers',
        data: [2, 5, 9, 21, 27, 36],
        backgroundColor: '#E3AF4A',
      },
    ],
  },
  text_data: `Here are some insights from your audience:

- The majority of your followers (**63%**) are over 45 years old
- Your largest age group is **60+ years old**, making up **36%** of your audience
- There's a steady increase in followers as age increases, with a **significant jump after 35**`,
};

export const donutData = {
  title: `Here's a pie chart representing your Pray followers classified by gender. The chart shows an estimated distribution of 50.5% female and 49.5% male.`,
  chart_data: [
    { label: 'Female', value: 59 },
    { label: 'Male', value: 41 },
  ],
  text_data: `Here are some insights from your audience:

- **59%** of your followers are female, while **41%** are male
- This gender split may influence content strategy and engagement approaches
- Consider tailoring some content to appeal specifically to your female majority`,
};

export const textData1 = {
  title: `To increase engagement and attract more followers on Instagram, consider these strategies:`,
  chart_data: null,
  text_data: `1. **Consistent Posting**: Post regularly to keep your audience engaged. Aim for a schedule that works for you, whether it's daily or a few times a week.

2. **High-Quality Content**: Share visually appealing and relevant content that resonates with your target audience. Use high-resolution images, creative videos, and eye-catching graphics.

3. **Use Hashtags Strategically**: Research and include popular and niche-specific hashtags to increase the visibility of your posts. Don’t overdo it; around 5-10 well-chosen hashtags can be effective.

4. **Engage with Your Audience**: Respond to comments, messages, and mentions promptly. Engage with your followers’ content by liking, commenting, and sharing.

5. **Collaborate with Others**: Partner with influencers or brands that align with your values. Collaborations can expose your account to a broader audience.

6. **Utilize Instagram Stories and Reels**: Use Stories and Reels to share behind-the-scenes content, quick tips, or trending challenges. These formats are great for increasing engagement.

7. **Host Giveaways or Contests**: Encourage participation by offering prizes for likes, shares, or tagging friends. Make sure the rules are simple and the prizes are appealing.

8. **Analyze and Optimize**: Use Instagram Insights to track the performance of your posts and identify what works best. Adjust your strategy based on the data.

9. **Create a Strong Bio**: Your Instagram bio should clearly state what your account is about and include a call to action. It’s the first impression visitors get, so make it count.

10. **Engage with Similar Accounts**: Follow and interact with accounts similar to yours. Comment thoughtfully on their posts to increase your visibility in the community.

By consistently applying these strategies, you can build a more engaged following on Instagram.`,
};

export const textData2 = {
  title: 'The total donation amount received in July 2024 was:',
  chart_data: null,
  text_data: `# **$27,593.88**`,
};
