import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { Search } from '@pray/shared/components/ui/Icons/Search';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import TextInput from '@pray/shared/components/ui/TextInput';
import useDebounce from '@pray/shared/hooks/useDebounce';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useDeleteLeadList from 'hooks/leadList/useDeleteLeadList';
import useLeadLists from 'hooks/leadList/useLeadLists';
import useRetryProcessingLeadList from 'hooks/leadList/useRetryProcessingLeadList';
import useAppRoutes from 'hooks/useAppRoutes';
import { Document } from 'images/icons';

import ContentTable from './components/ContentTable';
import ContentSkeleton from './components/ContentTableSkeleton';
import ErrorState from './components/ErrorState';
import { useToastMessage } from '../../../components/StudioToastMessage';

export default function LeadList() {
  const { artistId } = useParams();
  const appRoutes = useAppRoutes();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') ?? '');
  const [isSearching, setIsSearching] = useState(false);

  const toast = useToastMessage();
  const debouncedSearch = useDebounce(search);
  const filters = useMemo(() => ({ search: debouncedSearch }), [debouncedSearch]);

  const { data, isError, isLoading, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } = useLeadLists({
    artistId,
    filters,
  });

  const lists = data?.pages.flatMap((page) => page.lists) || [];

  const { deleteLeadList } = useDeleteLeadList({ artistId });

  const { mutateAsync: retryProcessing } = useRetryProcessingLeadList({ artistId, filters });

  const handleRetry = (listId: string) => {
    retryProcessing({ listId });
  };

  const handleDelete = async (listId: string) => {
    try {
      await deleteLeadList({ listId, filters });
      toast.show({ success: 'List deleted' });
    } catch (error) {
      toast.show({ error: 'Error deleting list' });
    }
  };

  // updating search param in the URL
  useEffect(() => {
    setIsSearching(true);
    if (debouncedSearch) {
      searchParams.set('search', debouncedSearch);
    } else {
      searchParams.delete('search');
    }
    setSearchParams(searchParams);
  }, [debouncedSearch]);

  useEffect(() => {
    if (!isFetching && isSearching) {
      setIsSearching(false);
    }
  }, [isFetching, isSearching]);

  const renderSearchInput = () => {
    if ((isFetching && !isFetchingNextPage) || lists.length > 0 || debouncedSearch) {
      return (
        <div className="mb-4 mt-6 w-96">
          <TextInput
            disabled={isSearching}
            type="search"
            leftIcon={<Search />}
            placeholder="Search"
            value={search}
            loading={isSearching}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
      );
    }
    return null;
  };

  const renderContent = () => {
    // Show skeleton when:
    // - isLoading: During initial data fetch when component mounts
    // - isSearching: When user is actively searching (prevents flashing content during search)
    // - (isFetching && !lists.length): During any fetch operation when no data is available yet
    // - !isFetchingNextPage: Ensures we don't show this skeleton during pagination operations
    if ((isLoading || isSearching || (isFetching && !lists.length)) && !isFetchingNextPage) {
      return <ContentSkeleton />;
    }

    if (lists.length > 0) {
      return (
        <>
          <ContentTable data={lists} onRetry={handleRetry} onDelete={handleDelete} />
          {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} />}
          {isFetchingNextPage && <ContentSkeleton rows={5} />}
        </>
      );
    }

    if (isError) {
      return <ErrorState />;
    }

    return (
      <EmptyState
        icon={<Document />}
        title={debouncedSearch ? 'No lists found' : 'Upload Your First List'}
        subtitle={
          debouncedSearch
            ? 'Try a different search'
            : 'Start by uploading a CSV file containing your user data. Once uploaded, you can review and manage the information effortlessly.'
        }
      />
    );
  };

  return (
    <TabPage
      title="Lists"
      data-viewname={VIEW_EVENT_NAMES.CONTACT_LISTS.LISTS}
      topRightButtons={<ActionButton text="Upload new list" to={appRoutes.artistContactsListUpload()} />}
    >
      {renderSearchInput()}
      {renderContent()}
    </TabPage>
  );
}
