import PRIVATE from './private.svg';
import PUBLIC from './public.svg';
import SCHEDULED from './scheduled.svg';

const Icons = {
  PRIVATE,
  PUBLIC,
  SCHEDULED,
};

export default Icons;
