import React, { useEffect, useRef } from 'react';

import Loading from '@pray/shared/components/Loading/Loading';

import Header from '../Header/Header';

import styles from './TabPage.module.scss';

export default function TabPage({
  title = '',
  titleInfo = null,
  topRightButtons = null,
  isLoading = false,
  children = null,
  containerProps = {},
  ...props
}) {
  const headerRef = useRef(null);

  const renderTopRightButtons = () => <div className={styles.rightButtons}>{topRightButtons}</div>;

  useEffect(() => {
    const header = headerRef.current;
    const observer = new IntersectionObserver(
      ([event]) => {
        header.classList.toggle(styles.stickyHeader, event.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    observer.observe(header);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div {...props}>
      <div ref={headerRef} className={styles.headerWrap}>
        <Loading height={54} isLight width={140} borderRadius="6px" isLoading={isLoading}>
          {!!title && <Header title={title} titleInfo={titleInfo} right={renderTopRightButtons()} />}
        </Loading>
      </div>
      <div className={styles.container} {...containerProps}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}
