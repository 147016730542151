import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import studioService from '@pray/shared/services/studioService';
import { mapVersionDiffList } from '@pray/shared/services/studioService/books/chapters/mappers/chapterVersionsMapper';
import { downloadFileFromBlob } from '@pray/shared/utils/download';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useGetTranscriptionsList from 'hooks/transcripts/useGetTranscriptionsList';
import useSaveTranscriptVersion from 'hooks/transcripts/useSaveTranscriptVersion';
import useTriggerTranslationSynthesis from 'hooks/transcripts/useTriggerTranslationSynthesis';
import { formatUpdatedAtDate } from 'utils/dateTimeUtils';

import { TRANSCRIPT_HISTORY_FETCH_STATUS, TRANSCRIPT_HISTORY_TYPES } from './constants';
import ButtonCluster from '../../../../components/ButtonCluster';
import Section from '../../../../components/Section/Section';
import TabPage from '../../../../components/TabPage/TabPage';
import WorkflowAITranslationStatusBanner from '../../../../components/WorkflowAITranslationStatusBanner';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';

import TranscriptHistoryModal from '@/components/pages/StudioPage/components/TranscriptHistoryModal/TranscriptHistoryModal';

function ContentTranscript({ workflowAIDetails, onWorkflowAIStarted, artist, content, locale, setContent }) {
  const [isTranscriptHistoryModalOpen, setIsTranscriptHistoryModalModalOpen] = useState(false);
  const { data: transcriptHistory, fetchStatus: transcriptHistoryFetchStatus } = useGetTranscriptionsList(
    artist.id,
    content.id,
    locale
  );
  const navigate = useNavigate();

  const handleDownloadButton = () => {
    downloadFileFromBlob(
      transcriptHistory?.published[0]?.transcript || workflowAIDetails?.transcript,
      `${locale}_published.txt`
    );
  };

  const renderTopRightButtons = () => {
    if (!content || !transcriptHistory?.published?.length || content.is_video) return null;

    const isDisabled = (content.speaker_count || 0) > 1;

    return (
      <div className="sm-hide md-show">
        <ButtonCluster text="Edit" disabled={isDisabled} onClick={() => navigate(`edit?locale=${locale}`)}>
          <DropdownMenu.Item title="Version History" onClick={() => setIsTranscriptHistoryModalModalOpen(true)} />
          <DropdownMenu.Item title="Download" onClick={handleDownloadButton} />
        </ButtonCluster>
      </div>
    );
  };

  return (
    <TabPage
      title="Transcript"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_TRANSCRIPT}
      topRightButtons={renderTopRightButtons()}
      titleInfo={
        <Popover
          title="Transcript"
          description="Here you can see the transcript from your content generated by Artificial Intelligence"
        >
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      {!workflowAIDetails?.isLoading && (
        <Section className="flex gap-4">
          <ContentTranscriptWrapper
            workflowAIDetails={workflowAIDetails}
            onWorkflowAIStarted={onWorkflowAIStarted}
            artist={artist}
            content={content}
            locale={locale}
            setContent={setContent}
            isTranscriptHistoryModalOpen={isTranscriptHistoryModalOpen}
            setIsTranscriptHistoryModalModalOpen={setIsTranscriptHistoryModalModalOpen}
            transcriptHistory={transcriptHistory}
            transcriptHistoryFetchStatus={transcriptHistoryFetchStatus}
          />
        </Section>
      )}

      {workflowAIDetails?.isLoading && (
        <Section className="flex h-[70vh] w-full items-center justify-center">
          <div className="flex-col items-center gap-2">
            <Spinner size="large" color="purple" className="opacity-80" />
            <Text className="mt-4">Loading...</Text>
          </div>
        </Section>
      )}
    </TabPage>
  );
}

const ContentTranscriptWrapper = ({
  workflowAIDetails,
  onWorkflowAIStarted,
  artist,
  content,
  locale,
  setContent,
  isTranscriptHistoryModalOpen,
  setIsTranscriptHistoryModalModalOpen,
  transcriptHistory,
  transcriptHistoryFetchStatus,
}) => {
  const { saveTranscriptVersion, isPending: isSavingTranscriptVersion } = useSaveTranscriptVersion(
    artist.id,
    content.id,
    locale
  );

  const { triggerTranslationSynthesis, submittedAt } = useTriggerTranslationSynthesis(artist.id, content.id, locale);

  async function handleWorkflowAIButton() {
    const { id: artistId } = artist;
    const { id: contentId } = content;

    setContent((prevData) => {
      return {
        ...prevData,
        is_workflow_ai_processing: true,
      };
    });

    await studioService.workflowAI.triggerWorkflowAIForContent({
      artistId,
      contentId,
      locale,
    });

    onWorkflowAIStarted();
  }

  /**
   * Empty and Loading state
   */
  if (!workflowAIDetails.transcript) {
    return (
      <WorkflowAIEmptyState
        content={content}
        handleWorkflowAIButton={handleWorkflowAIButton}
        title="Transcript"
        description="Effortlessly create personalized messages for your audience. Promote content, share updates, or inspire your
            community."
      />
    );
  }

  return (
    <div className="w-full">
      <TranscriptHistoryModal
        isOpen={isTranscriptHistoryModalOpen}
        isLoadingTranscriptHistory={transcriptHistoryFetchStatus === TRANSCRIPT_HISTORY_FETCH_STATUS.FETCHING}
        isSavingTranscriptVersion={isSavingTranscriptVersion}
        transcriptHistory={mapVersionDiffList(transcriptHistory?.published ?? [])}
        saveTranscriptVersion={saveTranscriptVersion}
        onClose={() => setIsTranscriptHistoryModalModalOpen(false)}
      />

      <div className="mb-4 flex items-center gap-4">
        <Text className="select-text">Last Update: {formatUpdatedAtDate(workflowAIDetails?.updatedAt)}</Text>

        {(transcriptHistoryFetchStatus === TRANSCRIPT_HISTORY_FETCH_STATUS.FETCHING ||
          !workflowAIDetails?.updatedAt) && (
          <div className="flex items-center gap-2 text-sm">
            <Spinner size="small" color="black" className="border-gray-500" />
            <Text>Loading latest data...</Text>
          </div>
        )}

        {transcriptHistory?.most_recent_transcript?.type === TRANSCRIPT_HISTORY_TYPES.DRAFT && (
          <div className="rounded-full bg-[#F2F3F4] px-2 py-1 leading-4">
            <Text className="text-[10px] font-bold uppercase tracking-wider text-[#56585E]">unpublished changes</Text>
          </div>
        )}
      </div>

      <WorkflowAITranslationStatusBanner
        translationStatus={workflowAIDetails.translationStatus}
        isButtonDisabled={submittedAt !== 0}
        onButtonClick={triggerTranslationSynthesis}
      />

      <div className="flex justify-center rounded-xl bg-[#F2F3F4] p-10 py-14">
        <div className="max-w-4xl select-text whitespace-pre-line rounded-lg bg-white p-10 font-sfMono text-base leading-7 shadow">
          {transcriptHistory?.published[0]?.transcript || workflowAIDetails?.transcript}
        </div>
      </div>
    </div>
  );
};

export default ContentTranscript;
