import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';

import styles from './DonationFrequency.module.scss';

export default function DonationFrequency({ interval, value, onChange }) {
  const title = `${interval}ly`.toUpperCase();

  return (
    <button
      name={interval}
      type="button"
      className={`${styles.type} ${value === interval ? styles.selected : styles.unselected}`}
      onClick={() => onChange(interval)}
    >
      <Text variant={typography.button_medium}>{title}</Text>
    </button>
  );
}
