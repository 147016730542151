import api from '@pray/shared/utils/api';

type SaveChapterDraftProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
  text: string;
};

export const saveChapterDraft = async ({ artistId, bookId, chapterId, text }: SaveChapterDraftProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!chapterId) throw Error('Chapter ID is required');
  if (text === undefined) throw Error('Text is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters/${chapterId}/draft`;

  const data = {
    text,
  };

  await api.executePostRequest(url, data);
};
