import React from 'react';

import MainLayout from 'components/layouts/MainLayout';

import DashboardPage from './DashboardPage';
import TabPage from '../../components/TabPage/TabPage';

export default function DashboardTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Dashboard Tab">
      <TabPage>
        <DashboardPage />
      </TabPage>
    </MainLayout>
  );
}
