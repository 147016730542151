import { useRef, useState, useEffect } from 'react';

import { useAudioPlayer } from '@pray/shared/components/ContentPlayer/FullscreenContentPlayer/FullscreenContentPlayer';
import { useUploaderModal } from '@pray/shared/components/ui/UploaderModal/UploaderModal';
import studioService from '@pray/shared/services/studioService';
import { mapChapters } from '@pray/shared/services/studioService/books/chapters';

import { BOOK_LIST_STATE } from '../constants';

import { AUDIO_BOOK_PLAYER } from '@/constants';
import { useStudioContext } from '@/context/StudioContext';
import { useBooks } from '@/hooks/books/useBooks';
import { useUploadBook } from '@/hooks/books/useUploadBook';

export function useBookList() {
  const [state, setState] = useState(BOOK_LIST_STATE.LOADING);
  const dropzoneRef = useRef(null);
  const uploader = useUploaderModal();
  const { selectedArtist } = useStudioContext();
  const artistId: string | undefined = selectedArtist?.id;
  const { playContent } = useAudioPlayer();

  const { books, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } = useBooks(artistId);
  const { uploadBook } = useUploadBook();

  useEffect(() => {
    if (isLoading) {
      setState(BOOK_LIST_STATE.LOADING);
      return;
    }

    if (books && books.length > 0) {
      setState(BOOK_LIST_STATE.LIST);
    } else {
      setState(BOOK_LIST_STATE.EMPTY);
    }
  }, [isLoading, books]);

  const addBook = async (acceptedFiles: File[]) => {
    await uploader.uploadFiles({
      type: 'artist',
      signParams: {
        artist_id: artistId,
      },
      files: acceptedFiles,
      onFileUploaded: async (file) => {
        // No catch, let uploader handle errors
        try {
          await uploadBook({ artistId, fileUrl: file.url });
        } finally {
          // Always refetch to be sure the list is updated
          // in edge cases where the route fails but the book is added
          await refetch();
        }
      },
    });
  };

  const handleUploadClick = () => {
    dropzoneRef.current.open();
  };

  const handleClickPlay = async (bookId: string) => {
    const fetchChapters = async (artistId: string, bookId: string) => {
      const response = await studioService.chapters.getChapters({
        artistId,
        bookId,
      });

      return mapChapters(response.data);
    };

    const chapters = await fetchChapters(artistId, bookId);

    if (chapters.length > 0) {
      playContent({
        contentId: chapters[0].id,
        controlOptions: AUDIO_BOOK_PLAYER.controlOptions,
        chapters,
      });
    }
  };

  return {
    state,
    books,
    dropzoneRef,
    isFetching: isLoading || isFetchingNextPage,
    hasNextPage,
    addBook,
    handleUploadClick,
    fetchNextPage,
    refetch,
    handleClickPlay,
  };
}
