export * from './getAllDonationFunds';
export * from './getDonationFund';
export * from './createDonationFund';
export * from './updateDonationFund';

export function fundMapper(fund) {
  return {
    id: fund.id,
    name: fund.name,
    description: fund.description,
    imageUrl: fund.image_url,
    totalDonationAmount: fund.total_donation_amount,
    isEnabled: fund.is_enabled,
    isDefault: fund.default,
    defaultAmountMonthly: fund.default_amount_monthly,
    defaultAmountYearly: fund.default_amount_yearly,
    amountMonthly2: fund.amount_monthly_2,
    amountYearly2: fund.amount_yearly_2,
    includeInAppPromotions: fund.include_in_app_promotions,
  };
}
