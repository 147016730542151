import React, { useEffect, useState } from 'react';

import { colors, typography, buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import studioService from '@pray/shared/services/studioService';

import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import SearchSeriesModal from '../../../../components/SearchSeriesModal/SearchSeriesModal';

export default function SearchPlaylistModal({ isOpen, isSaving, onAdd, onClose }) {
  const appRoutes = useAppRoutes();
  const { selectedArtist } = useStudioContext();
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  let selectedItem = filteredItems.find((item) => item.checked);
  const isNoItemsAvailable = !isLoading && !searchTerm && !filteredItems.length;

  const fetchPlaylists = async () => {
    setIsLoading(true);

    const response = await studioService.contentSeries.getAllContentSeries({
      artistId: selectedArtist.id,
      search: searchTerm,
      includeRssSeries: false,
    });

    setItems(response.data);
    setFilteredItems(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    let active = true;
    if (isOpen && !searchTerm) {
      fetchPlaylists();
    }

    return () => {
      active = false;
    };
  }, [isOpen, searchTerm]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    const regex = new RegExp(event.target.value, 'i');
    setFilteredItems(items.filter((playlist) => regex.test(playlist.title)));
  };

  const handleToggleSelectItem = (selectedPlaylist) => {
    selectedItem = selectedPlaylist;
    setFilteredItems((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.id === selectedItem.id,
      }))
    );
  };

  const renderEmptyState = () => {
    if (isLoading) {
      return (
        <div className="flex h-full flex-col items-center justify-center space-y-1 text-center">
          <Spinner color="dark" size="medium" />
        </div>
      );
    }

    if (!searchTerm) {
      return (
        <div className="flex h-full flex-col items-center justify-center space-y-1 text-center">
          <Text variant={typography.headline_medium}>No series available</Text>
          <Text variant={typography.headline_small} color={colors.text_muted}>
            Create a series to add content
          </Text>
          <div className="pt-4">
            <Button to={appRoutes.artistPlaylistCreate()} variant={buttons.variant.primary}>
              Create Series
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="flex h-full flex-col items-center justify-center space-y-1 text-center">
        <Text variant={typography.headline_medium}>No results</Text>
        <Text variant={typography.headline_small} color={colors.text_muted}>
          There were no results for “{searchTerm}”. <br />
          Please try again.
        </Text>
      </div>
    );
  };

  return (
    <SearchSeriesModal
      isOpen={isOpen}
      isLoading={false}
      isSaving={isSaving}
      title="Select one Series"
      placeholder="Search series"
      disabled={isNoItemsAvailable || !selectedItem}
      searchTerm={searchTerm}
      items={filteredItems}
      emptyState={renderEmptyState()}
      onSearchTermChange={handleSearchTermChange}
      onToggleSelectItem={handleToggleSelectItem}
      onAdd={() => onAdd(selectedItem)}
      onClose={onClose}
    />
  );
}
