import { DateTime, Info } from 'luxon';
import { useState, useMemo } from 'react';

export default function useMonthView(currentDate = DateTime.now()) {
  const [detailsModalDate, setDetailsModalDate] = useState(null);
  const shortWeekDays = Array.from({ length: 7 }).map((_, i) => Info.weekdays('long')[(i + 6) % 7]);

  const calendarWeeks = useMemo(() => {
    const firstDayOfTheMonth = currentDate.startOf('month');

    const daysInMonthArray = Array.from({ length: firstDayOfTheMonth.daysInMonth }).map((_, i) => {
      return firstDayOfTheMonth.set({ day: i + 1 });
    });

    const firstWeekDay = firstDayOfTheMonth.weekday % 7;

    const previousMonthFillArray = Array.from({ length: firstWeekDay })
      .map((_, i) => firstDayOfTheMonth.minus({ days: i + 1 }))
      .reverse();

    const lastDayInCurrentMonth = firstDayOfTheMonth.set({ day: firstDayOfTheMonth.daysInMonth });
    const lastWeekDay = lastDayInCurrentMonth.weekday;

    const nextMonthFillArray = Array.from({ length: 7 - (lastWeekDay + 1) }).map((_, i) => {
      return lastDayInCurrentMonth.plus({ days: i + 1 });
    });

    const calendarDays = [
      ...previousMonthFillArray.map((date) => ({ date, type: 'previous', month: date.monthLong })),
      ...daysInMonthArray.map((date) => ({ date, type: 'current', month: date.monthLong })),
      ...nextMonthFillArray.map((date) => ({ date, type: 'next', month: date.monthLong })),
    ];

    const calendarWeeks = calendarDays.reduce((weeks, _, i, original) => {
      const isNewWeek = i % 7 === 0;

      if (isNewWeek) {
        weeks.push({
          week: i / 7 + 1,
          days: original.slice(i, i + 7),
        });
      }

      return weeks;
    }, []);

    return calendarWeeks;
  }, [currentDate]);

  return {
    detailsModalDate,
    shortWeekDays,
    calendarWeeks,
    setDetailsModalDate,
  };
}
