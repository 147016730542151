import React, { useEffect } from 'react';
import { useLocation, useMatch, useParams, useNavigate } from 'react-router-dom';
import { create } from 'zustand';

import { useContentPlayer } from '@pray/shared/components/ContentPlayer';
import { LOCALES } from '@pray/shared/constants';

import MainLayout from 'components/layouts/MainLayout';
import { useContentLocale } from 'components/pages/StudioPage/tabs/ContentTab/ContentPage/hooks/useContentLocale';

import ContentDetails from './ContentDetails';
import ContentSidebar from './ContentSidebar';
import LoadingContentPage from './LoadingContentPage';
import { useSideBarContentDetails } from '../../../components/SideBar/SideBarContentDetails';
import useSideBar from '../../../components/SideBar/useSideBar';
import ContentAnnouncement from '../ContentDetails/ContentAnnouncement/ContentAnnouncement';
import ContentArticle from '../ContentDetails/ContentArticle/ContentArticle';
import ContentEmail from '../ContentDetails/ContentEmail/ContentEmail';
import ContentSummary from '../ContentDetails/ContentSummary/ContentSummary';
import ContentTranscript from '../ContentDetails/ContentTranscript/ContentTranscript';
import { StudyGuide } from '../ContentDetails/StudyGuide/StudyGuide';
import EditTranscriptPage from '../EditTranscriptPage/EditTranscriptPage';
import SocialClipsPage from '../SocialClipsPage/SocialClipsPage';

export const useActiveTabStore = create((set) => ({
  activeTab: 'details',
  setActiveTab: (tab) => set(() => ({ activeTab: tab })),
}));

export default function ContentPage() {
  /**
   * Hooks
   */
  const routeMatch = useMatch('/leaders/:artistId/episodes/:episodeId/*');
  const activeTabStore = useActiveTabStore();
  const { setContentDetails } = useSideBarContentDetails();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);
  const location = useLocation();
  const navigate = useNavigate();
  const { contentId } = useParams();
  const contentPlayer = useContentPlayer();

  const {
    content,
    locale,
    selectedArtist,
    workflowAIDetails,
    isLoading: isLoadingContent,
    refetch: fetchContentDetails,
    handleWorkflowAIStatusUpdate,
    setContent,
  } = useContentLocale();

  /**
   * Functions
   */
  const onTranslationStarted = async () => {
    await fetchContentDetails({ cancelRefetch: true });
  };

  const onWorkflowAIStarted = async () => {
    await fetchContentDetails({ cancelRefetch: true });
  };

  /**
   * Use Effects
   */

  /**
   * Every time we change the dependencies from this useEffect, we need
   * to re-render the content sidebar with the new content details
   */
  useEffect(() => {
    if (content) {
      setContentDetails({
        title: content.title,
        imageUrl: content.primary_image_url || content.thumbnail_image_url,
      });

      handleWorkflowAIStatusUpdate(content);
    }

    setRenderDetails(() => (
      <ContentSidebar
        contentId={contentId}
        content={content}
        localeCode={locale}
        activeTab={activeTabStore.activeTab}
        isFetchingContentData={isLoadingContent}
      />
    ));
  }, [content, location, activeTabStore.activeTab]);

  /**
   * Set active tab based on route
   * In case no tab is provided we default to 'details'
   */
  useEffect(() => {
    if (routeMatch) {
      const activeTab = routeMatch.params['*'] || 'details';
      activeTabStore.setActiveTab(activeTab);
    }
  }, [routeMatch]);

  /**
   * Check if locale is set (in query params)
   * In case it's not we're using the default
   */
  useEffect(() => {
    if (!locale) {
      navigate(
        {
          pathname: location.pathname,
          search: `?locale=${LOCALES.DEFAULT}`,
          hash: location.hash,
        },
        { replace: true }
      );
    }
  }, [locale]);

  /**
   * Close content player when unmounting content page
   */
  useEffect(() => {
    return () => {
      contentPlayer.closePlayer();
    };
  }, []);

  return (
    <MainLayout data-viewname="PRAY Studio - Content Tab">
      {(isLoadingContent || !content) && <LoadingContentPage />}

      {content && activeTabStore.activeTab === 'details' && (
        <ContentDetails
          artist={selectedArtist}
          content={content}
          locale={locale}
          isLoadingContent={isLoadingContent}
          workflowAIDetails={workflowAIDetails}
          fetchContentDetails={fetchContentDetails}
          onTranslationStarted={onTranslationStarted}
        />
      )}

      {content && activeTabStore.activeTab === 'summary' && (
        <ContentSummary
          artist={selectedArtist}
          content={content}
          locale={locale}
          workflowAIDetails={workflowAIDetails}
          setContent={setContent}
          onWorkflowAIStarted={onWorkflowAIStarted}
        />
      )}

      {content && activeTabStore.activeTab === 'transcript' && (
        <ContentTranscript
          artist={selectedArtist}
          content={content}
          locale={locale}
          workflowAIDetails={workflowAIDetails}
          setContent={setContent}
          onWorkflowAIStarted={onWorkflowAIStarted}
        />
      )}

      {content && activeTabStore.activeTab === 'announcement' && (
        <ContentAnnouncement
          artist={selectedArtist}
          content={content}
          locale={locale}
          workflowAIDetails={workflowAIDetails}
          setContent={setContent}
          onWorkflowAIStarted={onWorkflowAIStarted}
        />
      )}

      {content && activeTabStore.activeTab === 'email' && (
        <ContentEmail
          artist={selectedArtist}
          content={content}
          locale={locale}
          workflowAIDetails={workflowAIDetails}
          setContent={setContent}
          onWorkflowAIStarted={onWorkflowAIStarted}
        />
      )}

      {content && activeTabStore.activeTab === 'article' && (
        <ContentArticle
          workflowAIDetails={workflowAIDetails}
          artist={selectedArtist}
          onWorkflowAIStarted={onWorkflowAIStarted}
          content={content}
          locale={locale}
          setContent={setContent}
        />
      )}

      {content && activeTabStore.activeTab === 'study-guide' && (
        <StudyGuide
          workflowAIDetails={workflowAIDetails}
          artist={selectedArtist}
          onWorkflowAIStarted={onWorkflowAIStarted}
          content={content}
          locale={locale}
          setContent={setContent}
        />
      )}

      {content && activeTabStore.activeTab === 'transcript/voices' && <EditTranscriptPage content={content} />}

      {content && activeTabStore.activeTab === 'social-clips' && <SocialClipsPage content={content} />}
    </MainLayout>
  );
}
