import React, { useEffect } from 'react';

import Button from '../Button/Button';
import { ChevronLeftMd } from '../Icons/Icons';
import PrayLogoPrimary from '../PrayLogo/PrayLogoPrimary';

import styles from './AppBar.module.scss';

export default function AppBar({
  left = undefined,
  center = undefined,
  right = undefined,
  absolute = false,
  fixed = false,
  translucent = false,
  transparent = false,
  className = '',
  onBack = undefined,
  ...props
}) {
  const appBarStyles = [styles.appbar, className];

  if (absolute) appBarStyles.push(styles.absolute);
  if (fixed) appBarStyles.push(styles.fixed);
  if (translucent) appBarStyles.push(styles.translucent);
  if (transparent) appBarStyles.push(styles.transparent);

  const classes = appBarStyles //
    .filter((item) => item)
    .join(' ');

  useEffect(() => {
    if (!transparent) return undefined;

    const handleScroll = () => {
      const appBar = document.querySelector('[data-appbar]');
      const action = window.pageYOffset <= 24 ? 'remove' : 'add';
      appBar.classList[action]?.(styles.primaryAppBar);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [transparent]);

  const renderLeftComponent = () => {
    if (!left && onBack) {
      return (
        <Button onClick={onBack}>
          <ChevronLeftMd />
        </Button>
      );
    }

    return left;
  };

  const renderCenterComponent = () => {
    if (center === undefined) {
      return <PrayLogoPrimary />;
    }

    return center;
  };

  const renderRightComponent = () => right;

  return (
    <div className={classes} {...props} data-appbar>
      <div className={styles.left} data-left>
        {renderLeftComponent()}
      </div>
      <div className={styles.center} data-center>
        {renderCenterComponent()}
      </div>
      <div className={styles.right} data-right>
        {renderRightComponent()}
      </div>
    </div>
  );
}
