import React, { useMemo, useCallback, useState, useEffect } from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import useDOMEvents from '@pray/shared/hooks/useDOMEvents';
import VolumeOffIcon from '@pray/shared/icons/VolumeOffIcon';
import VolumeOnIcon from '@pray/shared/icons/VolumeOnIcon';

import { events as miniPlayerEvents } from '../MinimizedContentPlayer';

import classes from './Volume.module.css';

const Volume = ({ currentVolume, maxVolume, onChange, className = '' }) => {
  const domEvents = useDOMEvents();
  const [isVolumeBarVisible, setIsVolumeBarVisible] = useState(false);

  const hideVolumeBar = () => setIsVolumeBarVisible(false);

  useEffect(() => {
    return domEvents.on(miniPlayerEvents.onMouseLeave, hideVolumeBar);
  }, []);

  const progressStyle = useMemo(() => {
    const completed = Math.min((currentVolume / maxVolume) * 100, 100);
    return {
      background: `linear-gradient(to right, #ffffff ${completed}%, rgba(255,255,255,0.2) 0)`,
    };
  }, [currentVolume, maxVolume]);

  const toggleVolumeMute = useCallback(() => {
    const nextVolume = currentVolume > 0 ? 0 : 100;
    onChange(nextVolume);
  }, [currentVolume, onChange]);

  return (
    <div className={[classes.volume, className].join(' ')}>
      {isVolumeBarVisible && (
        <div className={classes.volumeBar}>
          <input
            className={classes.input}
            type="range"
            step="1"
            min="0"
            style={progressStyle}
            max={maxVolume}
            value={currentVolume}
            onChange={(event) => onChange(event.target.value)}
          />
        </div>
      )}

      <IconButton
        data-tapeventname={currentVolume > 0 ? 'Mute' : 'Unmute'}
        onClick={toggleVolumeMute}
        onMouseOver={() => setIsVolumeBarVisible(true)}
      >
        {currentVolume > 0 ? <VolumeOnIcon /> : <VolumeOffIcon />}
      </IconButton>
    </div>
  );
};

export default Volume;
