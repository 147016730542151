import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import s3Service from '@pray/shared/services/s3Service';
import { BookProcessStatus } from '@pray/shared/services/studioService/books/types';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';

import { useToastMessage } from '../../../components/StudioToastMessage/StudioToastMessage';

import { useStudioContext } from '@/context/StudioContext';
import { useChapters } from '@/hooks/books/chapters/useChapters';
import { useBook } from '@/hooks/books/useBook';
import { useBookStatus } from '@/hooks/books/useBookStatus';
import { useDeleteBook } from '@/hooks/books/useDeleteBook';
import { useUpdateBook } from '@/hooks/books/useUpdateBook';
import useAppRoutes from '@/hooks/useAppRoutes';

type BookDetailsProps = {
  bookId: string;
};

export function useBookDetails({ bookId }: BookDetailsProps) {
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToastMessage();
  const { selectedArtist } = useStudioContext();
  const artistId: string | undefined = selectedArtist?.id;
  const appRoutes = useAppRoutes();

  const { data: book, isLoading } = useBook({
    artistId,
    bookId,
  });

  const { updateBook } = useUpdateBook();
  const { deleteBook } = useDeleteBook();
  const { data: bookStatus } = useBookStatus({
    artistId,
    bookId,
  });
  const { data: chapters } = useChapters({
    artistId,
    bookId,
  });

  useEffect(() => {
    if (book && !isLoading) {
      form.setValue('title', book.title);
      form.setValue('description', book.description || '');
      form.setValue('cover_url', book.thumbnail);
    }
  }, [book, isLoading]);

  const form = useForm({
    defaultValues: {
      title: '',
      description: '',
      cover_url: '',
      cover_file: null,
    },
  });

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const formData = form.getValues();

      if (formData.cover_file) {
        const { url } = await s3Service.signAndUpload({
          file: formData.cover_file,
          type: 'contentSeries',
          signParams: {
            content_series_id: bookId,
          },
        });

        formData.cover_url = url;
      }

      await updateBook({
        artistId,
        bookId,
        title: formData.title,
        description: formData.description,
        imageUrl: formData.cover_url,
      });

      toast.show({ success: 'Book saved successfully' });
    } catch (error) {
      toast.show({ error: 'Failed to save book' });
      logger.error('Error saving book:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteBook({ artistId, bookId });
      toast.show({ success: 'Book deleted successfully' });
      navigate(appRoutes.artistLibrary());
    } catch (error) {
      toast.show({ error: 'Failed to delete book' });
      logger.error('Error deleting book:', error);
    }
  };

  const handleDownloadAudio = async () => {
    if (bookStatus?.audiobookGeneration !== BookProcessStatus.COMPLETED && chapters?.length === 0) {
      toast.show({ error: 'Audio book is not generated yet' });
      return;
    }

    try {
      toast.show({ success: 'The download will start shortly' });

      // TODO: Implement download audio
      // const { audioUrl } = chapters[0];
      // const audioName = getFormattedFileName(book.title, 'mp3');

      // const response = await fetch(audioUrl);
      // const blob = await response.blob();
      // downloadFileFromBlob(blob, audioName);
    } catch (error) {
      toast.show({ error: 'Failed to download audio' });
      logger.error('Error downloading file:', error);
    }
  };

  return {
    form,
    book,
    isLoading,
    textExtractionStatus: bookStatus?.textExtraction,
    isSaving,
    audiobookGenerationStatus: bookStatus?.audiobookGeneration,
    audiobookGenerationUpdatedAt: bookStatus?.audiobookGenerationUpdatedAt,
    chapters,
    handleSave,
    handleDelete,
    handleDownloadAudio,
  };
}
