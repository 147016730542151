import api from '@pray/shared/utils/api';

import { CreateLeadListParams, CreateLeadListResponse } from './types';

export async function postCreateLeadList({
  name,
  mapping,
  s3Url,
  initializationVector,
  secretKey,
  artistId,
}: CreateLeadListParams) {
  if (!artistId) throw new Error('Artist ID should not be null or empty');
  if (!s3Url) throw new Error('S3 URL should not be null or empty');
  if (!mapping || mapping.length === 0) throw new Error('Mapping should not be null or empty');
  if (!initializationVector) throw new Error('Initialization vector should not be null or empty');
  if (!secretKey) throw new Error('Secret key should not be null or empty');

  const url = `/web/studio/artists/${artistId}/leads-lists`;

  const params = {
    list_name: name,
    file_column_mapping: mapping,
    encrypted_file_s3_url: s3Url,
    encrypted_aes_initialization_vector: initializationVector,
    encrypted_aes_key: secretKey,
  };

  const response = await api.executePostRequest<CreateLeadListResponse>(url, params);
  return response.data;
}
