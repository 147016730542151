import api from '@pray/shared/utils/api';

export async function createDonationFund({
  artistId = undefined,
  name = undefined,
  description = undefined,
  imageUrl = undefined,
  defaultAmountMonthly = undefined,
  defaultAmountYearly = undefined,
  amountMonthly2 = undefined,
  amountYearly2 = undefined,
}) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!name) throw new Error('Fund name is required');
  if (!description) throw new Error('Fund description is required');
  if (!defaultAmountMonthly) throw new Error('Default amount monthly is required');
  if (!defaultAmountYearly) throw new Error('Default amount yearly is required');

  const data = {
    name,
    description,
    default_amount_monthly: defaultAmountMonthly,
    default_amount_yearly: defaultAmountYearly,
  };

  if (imageUrl !== undefined) data.image_url = imageUrl;
  if (amountMonthly2 !== undefined) data.amount_monthly_2 = amountMonthly2;
  if (amountYearly2 !== undefined) data.amount_yearly_2 = amountYearly2;

  const url = `/web/studio/artists/${artistId}/funds`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
