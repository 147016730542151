import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';

import Loading from '@pray/shared/components/Loading/Loading';
import Label from '@pray/shared/components/ui/Label/Label';

import AnnouncementAnalytics from './AnnouncementAnalytics/AnnouncementAnalytics';
import AnnouncementOverview from './AnnouncementOverview/AnnouncementOverview';
import AnnouncementsList from './AnnouncementsList/AnnouncementsList';
import CreateEditAnnouncementPage from './CreateEditAnnouncement/CreateEditAnnouncementPage';
import useGetAnnouncementsDetails from '../../../../../hooks/announcements/useGetAnnouncementsDetails';
import useAppRoutes from '../../../../../hooks/useAppRoutes';
import MainLayout from '../../../../layouts/MainLayout';
import BackLinkButton from '../../components/BackLinkButton/BackLinkButton';
import { ImageWithFallback } from '../../components/ImageWithFallback/ImageWithFallback';
import { useSideBarContentDetails } from '../../components/SideBar/SideBarContentDetails';
import useSideBar from '../../components/SideBar/useSideBar';
import TabItem from '../../components/TabItem/TabItem';

export default function AnnouncementsTab(props) {
  const location = useLocation();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);
  const routeMatch = useMatch('/leaders/:artistId/announcements/:announcementId/:action');

  const renderSidebarDetails = () => {
    const { announcementId, artistId, action } = routeMatch.params;
    return <SidebarDetails artistId={artistId} announcementId={announcementId} action={action} />;
  };

  useEffect(() => {
    if (routeMatch && routeMatch.params?.action !== 'edit') setRenderDetails(renderSidebarDetails);
  }, [routeMatch, location]);

  return (
    <MainLayout data-viewname="PRAY Studio - Announcements">
      <Routes>
        <Route index element={<AnnouncementsList {...props} />} />
        <Route path=":id/overview" element={<AnnouncementOverview {...props} />} />
        <Route path=":id/analytics" element={<AnnouncementAnalytics {...props} />} />
        <Route path="/create" element={<CreateEditAnnouncementPage />} />
        <Route path=":id/edit" element={<CreateEditAnnouncementPage />} />
      </Routes>
    </MainLayout>
  );
}

function SidebarDetails({ artistId, announcementId, action }) {
  const appRoutes = useAppRoutes();
  const { data, isLoading } = useGetAnnouncementsDetails(artistId, announcementId);
  const { setContentDetails } = useSideBarContentDetails();

  useEffect(() => {
    if (data) {
      setContentDetails({
        title: data.title,
        imageUrl: data.image_url,
      });
    }
  }, [data, isLoading]);

  const renderLoading = () => {
    return (
      <div className="flex items-center justify-center">
        <Loading isLight width="100px" height="auto" aspectRatio="3 / 4" />
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div className="px-4">
        <ImageWithFallback imageUrl={data.post_resource?.image_url} altText={data.post_resource?.title} />
        <span className="mt-4 inline-block text-[16px]">{data.announcement_title}</span>
        <div className="mt-2 block">
          <Label className="uppercase" status={data.status}>
            {data.status}
          </Label>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-6 pt-5">
      <BackLinkButton label="All Announcements" to={appRoutes.artistAnnouncements()} />

      {isLoading ? renderLoading() : null}
      {!isLoading && renderDetails()}

      <div className="[&_a]:px-3 [&_button]:px-3">
        <TabItem
          text="Overview"
          route={appRoutes.artistAnnouncementDetailsOverview(announcementId)}
          active={action === 'overview'}
        />
        <TabItem
          text="Analytics"
          route={appRoutes.artistAnnouncementDetailsAnalytics(announcementId)}
          active={action === 'analytics'}
        />
      </div>
    </div>
  );
}
