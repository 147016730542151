import { useMemo } from 'react';

import BackgroundImage from '@pray/shared/components/ui/BackgroundImage/BackgroundImage';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { PLACEHOLDER_IMAGE } from '@pray/shared/constants';
import { cn } from '@pray/shared/utils/styles';

import ContentStatus from 'components/pages/StudioPage/components/ContentStatus/ContentStatus';
import RSSLabel from 'components/pages/StudioPage/components/RSSLabel/RSSLabel';
import useAppRoutes from 'hooks/useAppRoutes';

import dragHandle from '../../assets/drag-handle.svg';
import { PlaylistItemProps } from '../../types';

import styles from './PlaylistItem.module.scss';

export function PlaylistItem({
  playlist,
  orderNumber,
  isDailyItem,
  isDragging,
  isDragDisabled,
  ...props
}: PlaylistItemProps) {
  const appRoutes = useAppRoutes();
  const placeholderImage = PLACEHOLDER_IMAGE;
  const playlistStatus = playlist.is_visible ? 'published' : 'draft';

  const detailsRoute = useMemo(() => {
    if (playlist.type === 'daily') {
      return appRoutes.artistDailyPlaylistDetails(playlist.id);
    }

    return playlist.rss_feed_url
      ? appRoutes.artistRssFeedDetails(playlist.id)
      : appRoutes.artistPlaylistDetails(playlist.id);
  }, [appRoutes, playlist.id, playlist.rss_feed_url, playlist.type]);

  return (
    <Button to={detailsRoute} className={cn(styles.playlist, isDragging && !isDragDisabled && styles.dragging)}>
      <div className={!isDragDisabled ? styles.dragHandle : styles.noDragHandle} {...props}>
        <Text>{!isDragDisabled ? orderNumber : ''}</Text>
        {!isDragDisabled && <img src={dragHandle} alt="drag" />}
      </div>

      <BackgroundImage
        src={playlist.image_url || placeholderImage}
        className={cn(styles.image, isDailyItem ? styles.dailyImage : styles.contentImage)}
        onError={(event) => {
          event.target.src = placeholderImage;
        }}
      />

      <div className="flex flex-col">
        <div className="flex items-center">
          <Text className={styles.title}>{playlist.title}</Text>
          {playlist.rss_feed_url && <RSSLabel />}
        </div>
        <Text className={styles.description}>{playlist.description}</Text>
        <div>
          <ContentStatus status={playlistStatus} />
        </div>
      </div>
    </Button>
  );
}
