import React from 'react';

import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { CUSTOM_ADMIN_STYLE } from 'constants.js';

export default function FeaturedProfileImages({ form, forAdmin = false }) {
  const customStyle = `mt-4 ${forAdmin ? CUSTOM_ADMIN_STYLE : ''}`;
  return (
    <Section title="Featured Profile Image">
      <div className={customStyle}>
        <MediaUpload
          imageUrl={form.values.profile_vertical_image_url}
          title="Profile Image (Vertical)"
          description="Upload the vertical profile image for the Leader here"
          uploadButtonLabel={form.values.profile_vertical_image_url ? 'Change Image' : 'Upload Image'}
          supportedFileTypes={['jpg', 'jpeg', 'png']}
          error={form.errors.profile_vertical_image_url}
          onFileChange={(file) => form.setValue('profile_vertical_image_file', file)}
          onError={(error) => form.setError('profile_vertical_image_url', error)}
        />
      </div>
      <div className={customStyle}>
        <MediaUpload
          imageUrl={form.values.profile_horizontal_image_url}
          title="Profile Image (Horizontal)"
          description="Upload the horizontal profile image for the Leader here"
          uploadButtonLabel={form.values.profile_horizontal_image_url ? 'Change Image' : 'Upload Image'}
          supportedFileTypes={['jpg', 'jpeg', 'png']}
          error={form.errors.profile_horizontal_image_url}
          onFileChange={(file) => form.setValue('profile_horizontal_image_file', file)}
          onError={(error) => form.setError('profile_horizontal_image_url', error)}
        />
      </div>
      <div className={customStyle}>
        <MediaUpload
          isCircleImage
          imageUrl={form.values.facecoin_image_url}
          title="Profile Image (Circle)"
          description="Upload the circle profile image for the Leader here"
          uploadButtonLabel={form.values.facecoin_image_url ? 'Change Image' : 'Upload Image'}
          supportedFileTypes={['jpg', 'jpeg', 'png']}
          error={form.errors.facecoin_image_url}
          onFileChange={(file) => form.setValue('facecoin_image_file', file)}
          onError={(error) => form.setError('facecoin_image_url', error)}
        />
      </div>
    </Section>
  );
}
