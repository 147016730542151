import { GetLeadListMetadataResponse } from '../getLeadListMetadata';

export const leadListMetadataMapper = (data: GetLeadListMetadataResponse) => {
  return {
    name: data.data.list_name,
    count: data.data.count,
    status: data.data.file_processing_status,
    mappings: data.data.file_column_mapping,
    createdAt: data.data.created_at,
    updatedAt: data.data.updated_at,
  };
};
