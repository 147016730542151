import { useCallback } from 'react';

export function useCopyStudyGuide() {
  const handleCopy = useCallback(async (studyGuide) => {
    try {
      await navigator.clipboard.writeText(studyGuide);
    } catch (error) {
      console.error('Failed to copy study guide:', error);
    }
  }, []);

  return {
    copyStudyGuide: handleCopy,
  };
}
