import React from 'react';

export function Options({ fill = '#FFFFFF', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.4004 10.3848C18.2925 10.3848 19.0157 11.108 19.0157 12.0002C19.0157 12.8923 18.2925 13.6155 17.4004 13.6155C16.5082 13.6155 15.785 12.8923 15.785 12.0002C15.785 11.108 16.5082 10.3848 17.4004 10.3848ZM12.0157 10.3848C12.9079 10.3848 13.6311 11.108 13.6311 12.0002C13.6311 12.8923 12.9079 13.6155 12.0157 13.6155C11.1236 13.6155 10.4004 12.8923 10.4004 12.0002C10.4004 11.108 11.1236 10.3848 12.0157 10.3848ZM6.63113 10.3848C7.52328 10.3848 8.24652 11.108 8.24652 12.0002C8.24652 12.8923 7.52328 13.6155 6.63113 13.6155C5.73898 13.6155 5.01575 12.8923 5.01575 12.0002C5.01575 11.108 5.73898 10.3848 6.63113 10.3848Z"
        fill={fill}
      />
    </svg>
  );
}
