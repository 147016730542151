import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';

import ContentItem from '../ContentItem/ContentItem';

import styles from './SearchItemsModal.module.scss';

export default function SearchItemsModal({
  isOpen,
  isLoading,
  title,
  disabled = false,
  itemListTitle,
  itemSelectionInfo,
  placeholder = 'Search...',
  items = [],
  getImage = (_) => null,
  getTitle = (_) => null,
  searchTerm,
  toggleSelectAllButtonLabel = 'Select all',
  emptyState,
  onToggleSelectAll,
  onToggleSelectItem,
  onSearchTermChange,
  onAdd,
  onClose,
}) {
  if (!isOpen) return null;

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{title}</Text>

      <div>
        <div className={styles.formField}>
          <TextInput
            autoFocus
            placeholder={placeholder}
            leftIcon={<Search />}
            loading={isLoading}
            value={searchTerm}
            onChange={onSearchTermChange}
          />
        </div>

        <div className="mt-2 flex flex-row items-center py-2">
          <div className="flex-1">
            <Text className="mr-2 font-bold">{itemListTitle}</Text>
            <Text className="text-muted">{itemSelectionInfo}</Text>
          </div>
          <Button disabled={disabled} className="px-0" onClick={onToggleSelectAll}>
            {toggleSelectAllButtonLabel}
          </Button>
        </div>

        <div className="border-gray h-96 overflow-y-auto border p-1">
          {items.length === 0 && emptyState}

          {items.map((item) => (
            <div key={item.id} className={`flex items-center space-x-4 p-2 ${item.checked ? 'bg-selected-item' : ''}`}>
              <Checkbox checked={item.checked} onChange={() => onToggleSelectItem(item)} />
              <ContentItem image={getImage?.(item)} title={getTitle(item)} />
            </div>
          ))}
        </div>

        <div className={styles.buttons}>
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={disabled} variant={buttons.variant.primary} onClick={onAdd}>
            Add
          </Button>
        </div>
      </div>
    </Modal>
  );
}
