import React from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { formatNumber } from '@pray/shared/utils';

import { cn } from 'styles/utils';

function BaseStats({ title, icon, iconBackgroundColor, isLoading, children }) {
  return (
    <div className="relative flex flex-1 flex-col justify-between overflow-hidden rounded-2xl border border-[#DEDFE3] p-4">
      <div className="relative z-10 flex flex-1 flex-col justify-between">
        <div className="flex flex-col gap-2">
          <Loading isLight isLoading={isLoading} width={40} height={40} borderRadius="100%" margin="0">
            <div className={cn('flex items-center justify-center rounded-full size-10', iconBackgroundColor)}>
              {icon}
            </div>
          </Loading>

          <Loading isLight isLoading={isLoading} width={80} margin="0">
            <Text variant={typography.body_lg} color={colors.text_primary} className="!font-medium">
              {title}
            </Text>
          </Loading>
        </div>
        <Loading isLight isLoading={isLoading} height={32} margin="0">
          {children}
        </Loading>
      </div>
    </div>
  );
}

export function SimpleStats({ title, emptyMessage, value, icon, iconBackgroundColor, isLoading, isError, onRetry }) {
  if (isError) {
    return (
      <BaseStats title={title} icon={icon} iconBackgroundColor={iconBackgroundColor} isLoading={isLoading}>
        <div className="flex">
          <Button className="inline-block" onClick={onRetry} loading={isLoading} variant={buttons.variant.secondary}>
            Retry
          </Button>
        </div>
      </BaseStats>
    );
  }

  if (emptyMessage && !value) {
    return (
      <BaseStats title={title} icon={icon} iconBackgroundColor={iconBackgroundColor} isLoading={isLoading}>
        <Text variant={typography.body_md} color={colors.text_tertiary} className="!font-medium">
          {emptyMessage}
        </Text>
      </BaseStats>
    );
  }

  return (
    <BaseStats title={title} icon={icon} iconBackgroundColor={iconBackgroundColor} isLoading={isLoading}>
      <Text variant={typography.heading_2xl}>{formatNumber(value)}</Text>
    </BaseStats>
  );
}

export function ConnectableStats({
  title,
  emptyMessage = '',
  value,
  placeholder,
  buttonTitle,
  background,
  icon,
  iconBackgroundColor,
  isLoading,
  isError,
  isConnected,
  isConnecting,
  onConnect,
  onRetry,
}) {
  const renderValue = () => {
    if (isError) {
      return (
        <div className="flex">
          <Button className="inline-block" onClick={onRetry} loading={isLoading} variant={buttons.variant.secondary}>
            Retry
          </Button>
        </div>
      );
    }

    if (isConnected) {
      if (!value) {
        return (
          <Text variant={typography.body_md} color={colors.text_tertiary} className="!font-medium">
            {emptyMessage}
          </Text>
        );
      }
      return <Text variant={typography.heading_2xl}>{value}</Text>;
    }

    return (
      <div className="flex">
        <Button className="inline-block" onClick={onConnect} loading={isConnecting} variant={buttons.variant.primary}>
          {buttonTitle}
        </Button>
      </div>
    );
  };

  return (
    <div className="relative flex flex-1 flex-col justify-between overflow-hidden rounded-2xl border border-[#DEDFE3] p-4">
      {!isLoading && !isConnected && <div className="absolute inset-0 z-0">{background}</div>}
      <div className="relative z-10 flex flex-1 flex-col justify-between">
        <div className="flex flex-col gap-2">
          <Loading isLight isLoading={isLoading} width={40} height={40} borderRadius="100%" margin="0">
            <div className={cn('flex items-center justify-center rounded-full size-10', iconBackgroundColor)}>
              {icon}
            </div>
          </Loading>

          <Loading isLight isLoading={isLoading} width={80} margin="0">
            {isConnected ? (
              <Text variant={typography.body_lg} color={colors.text_primary} className="!font-medium">
                {title}
              </Text>
            ) : (
              <Text variant={typography.body_md} color={colors.text_tertiary} className="!font-medium">
                {placeholder}
              </Text>
            )}
          </Loading>
        </div>
        <Loading isLight isLoading={isLoading} height={32} margin="0">
          {renderValue()}
        </Loading>
      </div>
    </div>
  );
}
