import api from '@pray/shared/utils/api';

export async function updateArtistContent({
  artistId,
  contentId,
  title = undefined,
  description = undefined,
  primaryImageUrl = undefined,
  thumbnailUrl = undefined,
  isPublished = undefined,
  publishedAt = undefined,
  orderIdBefore = undefined,
  orderIdAfter = undefined,
  isEmailAutoGenerated = undefined,
  isAnnouncementAutoGenerated = undefined,
  locales = [],
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');

  const data = {};

  if (title) data.title = title;
  if (description !== undefined) data.description = description;
  if (primaryImageUrl !== undefined) data.primary_image_url = primaryImageUrl;
  if (thumbnailUrl !== undefined) data.thumbnail_url = thumbnailUrl;
  if (isPublished !== undefined) data.is_published = isPublished;
  if (publishedAt !== undefined) data.published_at = publishedAt;
  if (orderIdBefore !== undefined) data.order_number_for_content_series_id_before = orderIdBefore;
  if (orderIdAfter !== undefined) data.order_number_for_content_series_id_after = orderIdAfter;
  if (isEmailAutoGenerated !== undefined) data.is_email_ai_auto_generated = isEmailAutoGenerated;
  if (isAnnouncementAutoGenerated !== undefined) data.is_announcement_ai_auto_generated = isAnnouncementAutoGenerated;
  if (locales.length) data.locales = locales;

  const url = `/web/studio/artists/${artistId}/content/${contentId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
