import api from '@pray/shared/utils/api';

export async function getLeadClientById({ leadClientId }) {
  if (!leadClientId) throw Error('Lead Client ID is required');

  const params = {};
  const url = `/web/studio/lead-clients/${leadClientId}`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
