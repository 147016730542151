import { colors, typography } from '@pray/shared/components/foundations';
import { Table, Tbody, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text';

import { VIEW_EVENT_NAMES } from 'constants.js';

import { MAPPING_STEP_COPY } from '../../../constants';
import MappingBanner from '../../components/MappingBanner';
import MappingRow from '../../components/MappingRow';

export default function MapFieldsStep({
  mappings,
  availableOptions,
  unmatchedMappings,
  hasRequiredField,
  handleFieldChange,
}) {
  return (
    <div data-viewname={VIEW_EVENT_NAMES.CONTACT_LISTS.MAPPINGS} className="flex max-w-xl flex-col gap-8">
      <div className="flex flex-col gap-4">
        <Text className="!font-medium" variant={typography.body_lg} color={colors.text_secondary}>
          {MAPPING_STEP_COPY.VERIFY_MAPPINGS_TEXT}
        </Text>
        {(unmatchedMappings.length > 0 || !hasRequiredField) && (
          <MappingBanner count={unmatchedMappings.length} hasRequiredField={hasRequiredField} />
        )}
      </div>
      <Table>
        <Thead>
          <Tr>
            <Th width="40%">
              <Text className="!font-mediu text-left" variant={typography.subhead_small} color={colors.text_secondary}>
                {MAPPING_STEP_COPY.FILE_FIELDS_TEXT}
              </Text>
            </Th>
            <Th width="15%" />
            <Th width="45%">
              <Text className="text-left !font-medium" variant={typography.subhead_small} color={colors.text_secondary}>
                {MAPPING_STEP_COPY.STUDIO_FIELDS_TEXT}
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {mappings
            .filter((item) => item.header !== null)
            .map((item, index) => (
              <MappingRow
                key={`${item.header}-${index}`}
                item={item}
                availableOptions={availableOptions}
                onChange={handleFieldChange}
              />
            ))}
        </Tbody>
      </Table>
    </div>
  );
}
