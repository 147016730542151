import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGetAnnouncementMetrics(artistId, announcementId) {
  const fetchData = async () => {
    const response = await studioService.announcements.getAnnouncementMetrics({
      artistId,
      announcementId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!artistId && !!announcementId,
    queryKey: queryKeys.artistAnnouncementMetrics(artistId, announcementId),
    queryFn: fetchData,
  });

  return query;
}
