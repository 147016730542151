import React from 'react';

import AnnouncementPhonePreview from '../../../components/PhonePreview/AnnouncementPhonePreview';
import PhonePreview from '../../../components/PhonePreview/PhonePreview';

export default function AnnouncementPreview({ isLoading, data }) {
  if (isLoading || !data) {
    return <PhonePreview isLoading loadingText="Your preview will appear here" />;
  }

  return (
    <AnnouncementPhonePreview
      resourceType={data?.post_resource?.type}
      resourceContentId={data?.post_resource?.content_id}
      resourceContentSeriesId={data?.post_resource?.content_series_id}
      resourceTitle={data?.post_resource?.title}
      resourceImageUrl={data?.post_resource?.image_url}
      resourceButtonText={data?.post_resource?.button_text}
      body={data?.body}
    />
  );
}
