import React, { createContext, useState, useCallback } from 'react';

import { GOOGLE_ANALYTICS_KEY } from '@pray/shared/config';
import { getCookieSettings } from '@pray/shared/utils/gdpr';
import storage from '@pray/shared/utils/storage';

export const PrayContext = createContext(null);

const PrayContextProvider = ({ children }) => {
  const [isGoogleAnalyticsDisabled, setIsGoogleAnalyticsDisabled] = useState(getCookieSettings().isGoogleOptOut);

  const [authState, setAuthState] = useState({
    currentUser: storage.getUser(),
    previousUser: null,
  });

  const context = {
    /* auth state */
    authState,
    setCurrentUser: useCallback((user) => {
      setAuthState({
        previousUser: authState.user,
        currentUser: user,
      });
    }, []),

    disableGoogleAnalytics: setIsGoogleAnalyticsDisabled,
  };

  if (typeof window !== 'undefined') {
    window[`ga-disable-${GOOGLE_ANALYTICS_KEY}`] = isGoogleAnalyticsDisabled;
  }

  return <PrayContext.Provider value={context}>{children}</PrayContext.Provider>;
};

export default PrayContextProvider;
