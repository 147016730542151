import React from 'react';

import Text from '@pray/shared/components/ui/Text';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import useDeleteAnnouncement from 'hooks/announcements/useDeleteAnnouncement';

export default function DeleteAnnouncementModal({ artistId, announcementId, onCancel, onDelete }) {
  const toast = useToastMessage();
  const { deleteAnnouncement } = useDeleteAnnouncement();

  async function handleDeleteAnnouncement() {
    await deleteAnnouncement({ artistId, announcementId });
    toast.show({ success: 'Announcement has been deleted' });
    onDelete();
  }

  return (
    <DeleteContentModal
      isOpen
      title="Confirm Deletion"
      message={
        <>
          <Text>Are you sure you want to delete this announcement?</Text>
          <Text>This action cannot be undone.</Text>
        </>
      }
      onDelete={handleDeleteAnnouncement}
      onClose={onCancel}
    />
  );
}
