import { useQuery, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { fundMapper } from '@pray/shared/services/studioService/donationFunds';

export default function useDonationFund(artistId, fundId) {
  const queryClient = useQueryClient();

  async function fetchDonationFund() {
    const response = await studioService.donationFunds.getDonationFund({
      artistId,
      fundId,
    });

    return fundMapper({ id: fundId, ...response.data });
  }

  const query = useQuery({
    enabled: !!artistId && !!fundId,
    queryKey: queryKeys.artistFund(artistId, fundId),
    queryFn: fetchDonationFund,
    initialData: () => {
      const fundsData = queryClient.getQueryData([artistId, 'funds']);
      // @ts-ignore
      const funds = fundsData?.pages?.flatMap((page) => page.data) || [];
      return funds.find((fund) => fund.id === fundId) || undefined;
    },
  });

  return query;
}
