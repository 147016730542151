import { useEffect } from 'react';

export default function useBodyStyles(stylesOjbectOrFunction, deps = []) {
  useEffect(() => {
    const body = document.querySelector('body');
    const isFunctionStyles = typeof stylesOjbectOrFunction === 'function';
    const previousStyles = { ...body.style };
    const newStyles = isFunctionStyles ? stylesOjbectOrFunction() : stylesOjbectOrFunction;

    // apply new styles
    Object.assign(body.style, newStyles || {});

    return () => {
      // remove new styles
      const newStyleKeys = Object.keys(newStyles);

      newStyleKeys.forEach((key) => {
        body.style[key] = previousStyles[key] || 'initial';
      });

      // reset previous styles
      const prevStyleKeys = Object.keys(previousStyles);

      prevStyleKeys
        .filter((key) => newStyleKeys.includes(key))
        .forEach((key) => {
          body.style[key] = previousStyles[key];
        });
    };
  }, deps);

  return null;
}
