import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

import styles from './PodcastPreview.module.scss';

export default function PodcastPreview({ title, subtitle, image = null }) {
  return (
    <div className={styles.container}>
      {image && <img className={styles.image} src={image} alt="podcast" />}

      <div className={styles.info}>
        <Text className={styles.title}>{title}</Text>
        <Text className={styles.subtitle}>{subtitle}</Text>
      </div>

      <div className={styles.clear} />
    </div>
  );
}
