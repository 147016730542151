import { LeadListStatus, GetLeadListsResponse } from '../types';

export type LeadList = {
  id: string;
  name: string;
  count: number;
  createdAt: string;
  status: LeadListStatus;
};

export type LeadLists = {
  respondedAt: string;
  object: 'list';
  lastItemIdentifier: unknown;
  count: number;
  lists: LeadList[];
};

export function leadListsMapper(data: GetLeadListsResponse): LeadLists {
  return {
    respondedAt: data?.responded_at,
    object: data?.object,
    lastItemIdentifier: data?.last_item_identifier,
    count: data?.data_count,
    lists: data?.data.map((list) => ({
      id: list.id,
      name: list.list_name,
      count: list.count,
      createdAt: list.created_at,
      status: list.status,
    })),
  };
}
