import { DateTime } from 'luxon';
import React, { useEffect } from 'react';

import { eventPropsToString } from '@pray/shared/components/events/eventUtils';
import { colors, typography } from '@pray/shared/components/foundations';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import RadioButtonGroup from '@pray/shared/components/ui/RadioButtonGroup/RadioButtonGroup';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import TimePicker from '@pray/shared/components/ui/TimePicker';
import { CONTENT_VISIBILITY, LOCALES } from '@pray/shared/constants';

import AiSuggestionBox from 'components/pages/StudioPage/components/AiSuggestion/AiSuggestionBox';
import AiSuggestionButton from 'components/pages/StudioPage/components/AiSuggestion/AiSuggestionButton';
import useAiSuggestion from 'components/pages/StudioPage/components/AiSuggestion/useAiSuggestion';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import useQueryParams from 'hooks/useQueryParams';

import MediaUpload from '../../../../components/MediaUpload/MediaUpload';

import styles from './ContentDetailsForm.module.scss';

const MAX_DESCRIPTION_LENGTH = 200;

export default function ContentDetailsForm({
  form,
  isVideo = false,
  isPrimary = false,
  content = null,
  artist = null,
  onRemoveContentImage = null,
}) {
  const artistId = artist?.id;
  const contentId = content?.id;
  const { params } = useQueryParams();
  const toast = useToastMessage();
  const aiTitle = useAiSuggestion({ artistId, contentId, property: 'title' });
  const aiDescription = useAiSuggestion({ artistId, contentId, property: 'description' });
  const hasLocale = params.locale !== LOCALES.DEFAULT;

  useEffect(() => {
    if (aiTitle.error) {
      toast.show({ error: aiTitle.error });
      aiTitle.resetSuggestion();
    }

    if (aiDescription.error) {
      toast.show({ error: aiDescription.error });
      aiDescription.resetSuggestion();
    }
  }, [aiTitle.error, aiDescription.error]);

  const getTitleTooltip = () => {
    if (aiTitle.isLoading) return null;
    return aiTitle.text ? 'New Suggestion' : 'Generate AI Title';
  };

  const getDescriptionTooltip = () => {
    if (aiDescription.isLoading) return null;
    return aiDescription.text ? 'New Suggestion' : 'Generate AI Description';
  };

  const contentEventProps = eventPropsToString({
    artist_id: artistId,
    artist_name: artist?.name,
    item_id: content?.id,
    title: content?.title,
    series_id: content?.series_id,
    series_title: content?.series_title,
  });

  return (
    <div className="max-w-screen-sm flex-1" data-eventprops={contentEventProps}>
      <div className={styles.formField}>
        <TextInput
          label="Title (required)"
          name="title"
          rightIcon={
            !hasLocale && (
              <AiSuggestionButton
                data-tapeventname="Title - A.I. Suggestion"
                isLoading={aiTitle.isLoading}
                tooltip={getTitleTooltip()}
                onClick={aiTitle.fetchSuggestion}
              />
            )
          }
          errorMessage={form.errors.title}
          disabled={!isPrimary}
          value={form.values.title}
          onChange={form.handleChange}
        />
      </div>

      <AiSuggestionBox
        text={aiTitle.text}
        isLoading={aiTitle.isLoading}
        onAccept={() => {
          form.setValue('title', aiTitle.text);
          aiTitle.resetSuggestion();
        }}
        onReload={aiTitle.fetchSuggestion}
        onDismiss={aiTitle.resetSuggestion}
        acceptButtonProps={{ 'data-tapeventname': 'Accept - Title - A.I. Suggestion' }}
        dismissButtonProps={{ 'data-tapeventname': 'Dismiss - Title - A.I. Suggestion' }}
        reloadButtonProps={{ 'data-tapeventname': 'Reload - Title - A.I. Suggestion' }}
      />

      <div className={styles.formField}>
        <TextArea
          rows={5}
          label="Description"
          name="description"
          rightIcon={
            !hasLocale && (
              <AiSuggestionButton
                data-tapeventname="Description - A.I. Suggestion"
                isLoading={aiDescription.isLoading}
                tooltip={getDescriptionTooltip()}
                onClick={aiDescription.fetchSuggestion}
              />
            )
          }
          disabled={!isPrimary}
          value={form.values.description}
          errorMessage={form.errors.description}
          onChange={form.handleChange}
        />

        <InputHelperText>
          <span>The text must be {MAX_DESCRIPTION_LENGTH} characters maximum.</span>
          <span>
            {form.values.description.length}/{MAX_DESCRIPTION_LENGTH}
          </span>
        </InputHelperText>
      </div>

      <AiSuggestionBox
        text={aiDescription.text}
        isLoading={aiDescription.isLoading}
        onAccept={() => {
          form.setValue('description', aiDescription.text);
          aiDescription.resetSuggestion();
        }}
        onReload={aiDescription.fetchSuggestion}
        onDismiss={aiDescription.resetSuggestion}
        acceptButtonProps={{ 'data-tapeventname': 'Accept - Description - A.I. Suggestion' }}
        dismissButtonProps={{ 'data-tapeventname': 'Dismiss - Description - A.I. Suggestion' }}
        reloadButtonProps={{ 'data-tapeventname': 'Reload - Description - A.I. Suggestion' }}
      />

      {!isVideo && (
        <div className={styles.formField}>
          <MediaUpload
            imageUrl={form.values.primary_image_url}
            title="Thumbnail"
            description="It’s recommended to use a picture that’s at least 98 x 98 pixels and 4MB or less. Use a PNG or JPEG."
            uploadButtonLabel={form.values.primary_image_url ? 'Change Image' : 'Upload Image'}
            secondaryButtonLabel={form.values.primary_image_url ? 'Remove Image' : ''}
            onSecondaryButtonClick={onRemoveContentImage}
            error={form.errors.primary_image_url}
            onFileChange={(file) => form.setValue('primary_image_file', file)}
            onError={(error) => form.setError('primary_image_url', error)}
          />
        </div>
      )}

      {isVideo && (
        <div className={`${styles.formField} ${styles.videoThumb}`}>
          <Text variant={typography.headline_medium}>Thumbnail</Text>
          <Text variant={typography.subhead_small} color={colors.text_muted}>
            Upload a picture that shows what's in your video. It’s recommended to use a picture that’s at least 98 x 98
            pixels and 4MB or less. Use a PNG or JPEG.
          </Text>
          <MediaUpload
            imageUrl={form.values.thumbnail_image_url}
            uploadButtonLabel={form.values.thumbnail_image_url ? 'Change Image' : 'Upload Image'}
            error={form.errors.thumbnail_image_url}
            cropProps={{ aspect: 16 / 9 }}
            onFileChange={(file) => form.setValue('thumbnail_image_file', file)}
            onError={(error) => form.setError('thumbnail_image_url', error)}
          />
        </div>
      )}

      <div className={styles.formField}>
        <Text variant={typography.headline_medium}>Visibility</Text>

        <RadioButtonGroup
          options={[
            {
              value: CONTENT_VISIBILITY.PUBLIC,
              label: 'Public',
              description: 'Can be listened to by anyone',
            },
            {
              value: CONTENT_VISIBILITY.PRIVATE,
              label: 'Private',
              description: 'Only visible to you',
            },
            {
              value: CONTENT_VISIBILITY.SCHEDULE,
              label: 'Schedule',
              description: 'Can be listened to by anyone after scheduled date and time',
            },
          ]}
          radioGroupName="visibility"
          value={form.values.visibility}
          onChange={(value) => form.setValue('visibility', value)}
        />

        {form.values.visibility === CONTENT_VISIBILITY.SCHEDULE && (
          <div className={styles.scheduleDateTimeGroup}>
            <div className={styles.scheduleDate}>
              <DatePicker
                name="scheduled_date"
                minDate={DateTime.local().startOf('day').toJSDate()}
                value={form.values.scheduled_date}
                error={form.errors.scheduled_date}
                onChange={(date) => form.setValue('scheduled_date', date)}
              />
            </div>

            <div className={styles.scheduleTime}>
              <TimePicker
                name="scheduled_time"
                value={form.values.scheduled_time}
                error={form.errors.scheduled_time}
                onChange={(time) => form.setValue('scheduled_time', time)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
