import { useEffect } from 'react';

import studioService from '@pray/shared/services/studioService';
import storage from '@pray/shared/utils/storage';

import './stripo.styles.scss';

export default function Stripo({ emailId, html, css, onTemplateLoaded }) {
  useEffect(() => {
    if (!emailId || !html || !css) return;
    initStripo({ emailId, html, css, onTemplateLoaded });
  }, [emailId, html, css]);

  return (
    <div className="w-full">
      <Notifications />
      <div className="mx-auto flex h-[calc(100vh-140px)] max-w-[1440px]">
        <EmailEditingArea />
        <EmailFormattingControls />
      </div>
    </div>
  );
}

function Notifications() {
  return <div className="notification-zone" />;
}

function EmailEditingArea() {
  return <div id="stripoPreviewContainer" className="w-full" />;
}

function EmailFormattingControls() {
  return (
    <div className="no-scrollbar max-h-[calc(100vh-140px)] w-full max-w-[400px] overflow-y-auto px-2">
      <div>
        <div id="stripoSettingsContainer" className="w-full scale-95 rounded-lg border shadow-xl" />
      </div>
    </div>
  );
}

function initStripo(options) {
  const scriptId = 'stripoScript';
  const stripoScript = document.getElementById(scriptId);

  if (stripoScript) document.body.removeChild(stripoScript);

  const script = document.createElement('script');
  script.id = scriptId;
  script.type = 'text/javascript';
  script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
  script.onload = () => initStripoPlugin(options);

  document.body.appendChild(script);
}

function initStripoPlugin({ emailId, html, css, onTemplateLoaded }) {
  window.Stripo.init({
    settingsId: 'stripoSettingsContainer',
    previewId: 'stripoPreviewContainer',
    codeEditorButtonId: 'codeEditor',
    undoButtonId: 'undoButton',
    redoButtonId: 'redoButton',
    locale: 'en',
    html,
    css,
    apiRequestData: {
      emailId,
    },
    userFullName: storage.getUser().name,
    versionHistory: {
      changeHistoryLinkId: 'changeHistoryLink',
      onInitialized() {
        const container = document.querySelector('#changeHistoryContainer');
        const button = document.querySelector('#changeHistoryButton');
        const link = document.querySelector('#changeHistoryLink');

        if (container) container.style.display = 'flex';
        if (button) button.title = link.textContent;
      },
    },
    getAuthToken(callback) {
      studioService.tokens.getStripoToken().then((response) => {
        callback(response.data.token);
      });
    },
    onTemplateLoaded,
  });
}
