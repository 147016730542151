export const announcementStatus = {
  NOW: 'now',
  SENT: 'sent',
  DRAFT: 'draft',
  PUBLISHED: 'published',
  SCHEDULED: 'scheduled',
};

export const publishTypes = {
  NOW: 'now',
  SCHEDULED: 'scheduled',
};
