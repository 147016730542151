import React, { useEffect, useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Dropdown from '@pray/shared/components/ui/Dropdown/Dropdown';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import Image from '@pray/shared/components/ui/Image/Image';
import InputBase from '@pray/shared/components/ui/InputField/InputBase';
import InputWrapper from '@pray/shared/components/ui/InputField/InputWrapper';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import useDebounce from '@pray/shared/hooks/useDebounce';
import studioService from '@pray/shared/services/studioService';

import { PLACEHOLDER_IMAGE } from 'constants.js';
import { Check, ChevronDown, Minus, Plus, Remove } from 'images/icons';

import styles from './TagContentModal.module.scss';

const emptyImageUrl = PLACEHOLDER_IMAGE;

export default function TagContentModal({ tagId, tagging, contentTypes = [], onClose, onSuccess }) {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedContent, setSelectedContent] = useState(tagging ?? null);
  const isEditMode = !!tagging?.id;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsSaving(true);

    const {
      artist_id: artistId,
      content_id: contentId,
      content_series_id: contentSeriesId,
      book_chapter_id: bookChapterId,
      priority,
    } = selectedContent;

    const priorityValue = priority ? Number(priority) : null;

    if (isEditMode) {
      await studioService.taggings.updateTagging({
        taggingId: tagging.id,
        priority: priorityValue,
      });
    } else {
      await studioService.taggings.createTagging({
        tagId,
        priority: priorityValue,
        artistId,
        contentId,
        contentSeriesId,
        bookChapterId,
      });
    }

    onSuccess?.();
  };

  const incrementPriority = (incr) => {
    const priority = selectedContent.priority ? Number(selectedContent.priority) : 0;
    const newPriority = priority + incr;
    setPriority(newPriority > 0 ? String(newPriority) : '');
  };

  const setPriority = (value) => {
    let priority = value.replace(/\D/g, '');
    if (Number(priority) <= 0) priority = '';
    setSelectedContent((prevValue) => ({ ...prevValue, priority }));
  };

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const handlePriorityKeyDown = (event) => {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      incrementPriority(+1);
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      incrementPriority(-1);
    }
  };

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>{isEditMode ? 'Edit Ranking' : 'Tag Content'}</Text>

      <form onSubmit={handleSubmit}>
        {!isEditMode && (
          <div className={styles.formField}>
            <SearchField contentTypes={contentTypes} onItemSelected={setSelectedContent} />
          </div>
        )}

        {selectedContent && (
          <>
            <div className={styles.formField}>
              <Text variant={typography.subhead_medium}>Your selection</Text>
              <div className="mt-2 flex flex-row items-center space-x-2 rounded border border-black p-2">
                <Image src={selectedContent?.image_url} fallbackImage={emptyImageUrl} className="w-10 rounded" />
                <Text variant={typography.headline_small} className={styles.itemName}>
                  {selectedContent?.name}
                </Text>
                {!isEditMode && <IconButton icon={Remove} tooltip="Remove" onClick={() => setSelectedContent(null)} />}
              </div>
            </div>

            <div className={styles.formField}>
              <Text variant={typography.subhead_medium}>Ranking</Text>
              <div className="mt-2 flex flex-row items-center">
                <InputWrapper>
                  <IconButton onClick={() => incrementPriority(-1)}>
                    <Minus />
                  </IconButton>
                  <InputBase
                    maxLength={5}
                    placeholder="0"
                    value={selectedContent?.priority ? String(selectedContent.priority) : ''}
                    onKeyDown={handlePriorityKeyDown}
                    onChange={handlePriorityChange}
                    className="w-12 text-center"
                  />
                  <IconButton onClick={() => incrementPriority(+1)}>
                    <Plus />
                  </IconButton>
                </InputWrapper>
              </div>
            </div>
          </>
        )}

        <div className={styles.buttons}>
          <Button disabled={isSaving} variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isSaving || !selectedContent}
            variant={buttons.variant.primary}
            className="flex items-center justify-center gap-2"
            onClick={handleSubmit}
          >
            {isSaving ? <Spinner /> : null}
            {isEditMode ? 'Save' : 'Add'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

const SearchField = ({ contentTypes, onItemSelected }) => {
  const [search, setSearch] = useState('');
  const searchTerm = useDebounce(search);
  const [isItemDropdownOpen, setIsItemDropdownOpen] = useState(false);
  const [isTypeDropdownOpen, setIsTypeDropdownOpen] = useState(false);
  const [contentType, setContentType] = useState(contentTypes[0]);
  const [items, setItems] = useState([]);

  const searchItemsForTagging = async () => {
    if (!searchTerm) {
      setIsItemDropdownOpen(false);
      setItems([]);
      return;
    }

    const response = await studioService.taggings.searchItems({
      type: contentType.value,
      search: searchTerm,
    });

    setItems(response.data);
    setIsItemDropdownOpen(true);
  };

  useEffect(() => {
    searchItemsForTagging();
  }, [searchTerm, contentType]);

  return (
    <>
      <InputWrapper>
        <Search fill="black" />
        <InputBase
          type="search"
          placeholder="Search"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <DropdownMenu
          isOpen={isTypeDropdownOpen}
          hasSeparator={false}
          onClose={() => setIsTypeDropdownOpen(false)}
          className="z-30 mr-[-12px] pr-4"
          component={
            <Button
              className="flex flex-row items-center gap-2 px-0 font-normal normal-case"
              onClick={() => setIsTypeDropdownOpen(!isTypeDropdownOpen)}
            >
              {contentType.label} <ChevronDown />
            </Button>
          }
        >
          {contentTypes.map((type) => (
            <DropdownMenu.Item
              key={type.value}
              className={styles.typeItem}
              title={type.label}
              rightComponent={contentType.value === type.value ? <Check /> : null}
              onClick={() => {
                onItemSelected(null);
                setContentType(type);
                setIsTypeDropdownOpen(false);
              }}
            />
          ))}
        </DropdownMenu>
      </InputWrapper>

      {isItemDropdownOpen && (
        <Dropdown
          items={items}
          isAutoHeight
          isRenderSearchField={false}
          isRenderBackdrop={false}
          getItemId={(item) => item.id}
          getImage={(item) => item.image_url}
          getTitle={(item) => item.name}
          fallbackImage={emptyImageUrl}
          noItemsMessage={`No ${contentType.label} found`}
          onSearchChange={(event) => setSearch(event.target.value)}
          onChange={(item) => {
            setSearch('');
            setIsItemDropdownOpen(false);
            onItemSelected(item);
          }}
        />
      )}
    </>
  );
};
