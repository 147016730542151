import React from 'react';

import { cn } from '@pray/shared/utils/styles';

import StepItem from './StepItem';
import { StepperOrientation, StepperProps } from './types';

/**
 * Stepper component
 * Displays a sequence of steps with their current status
 */
export default function Stepper({
  steps,
  activeStep,
  completedSteps = [],
  className,
  children,
  orientation = StepperOrientation.HORIZONTAL,
}: StepperProps) {
  const isVertical = orientation === StepperOrientation.VERTICAL;

  return (
    <div className={cn('flex flex-col gap-3', className)}>
      <ol className={cn('flex gap-3', isVertical ? 'flex-col' : 'flex-row')}>
        {steps.map((step, index) => (
          <StepItem
            key={step}
            step={step}
            index={index}
            activeStep={activeStep}
            completedSteps={completedSteps}
            isVertical={isVertical}
            totalSteps={steps.length}
          />
        ))}
      </ol>
      {children}
    </div>
  );
}
