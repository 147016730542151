import api from '@pray/shared/utils/api';
import type { CursorInfiniteQueryParams } from '@pray/shared/utils/api/types/fetch';

import { LeadListResponse } from './types';

type GetLeadListContactsProps = {
  artistId: string;
  listId: string;
  lastItemIdentifier?: string;
  search?: string;
  sortDirection?: 'asc' | 'desc';
};

/*
  By default,
  - contacts are sorted by created_at;
  - and newest or oldest from last_item_identifier are by created_at as well.
*/
export async function getLeadListLeads({
  artistId,
  listId,
  lastItemIdentifier,
  search = '',
  sortDirection = 'asc',
}: GetLeadListContactsProps) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!listId) throw new Error('List ID is required');

  const params: CursorInfiniteQueryParams = {};

  if (search) {
    params.search = search;
  }

  if (lastItemIdentifier) {
    params.last_item_identifier = lastItemIdentifier;
  }

  if (sortDirection) {
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/leads-lists/${listId}/leads`;
  const response = await api.executeGetRequest<LeadListResponse>(url, params);

  return response.data;
}
