export const CSV_HEADERS = [
  'First Name',
  'Last name',
  'Email',
  'Phone Number',
  'Address Line 1',
  'Address Line 2',
  'City',
  'State Province Region',
  'Postal Code',
  'Country',
];

// Size threshold in characters for using a web worker when parsing CSV
export const CSV_WORKER_THRESHOLD = 50000;

export const UPLOAD_STEPS = {
  UPLOAD: 'Upload file',
  MAP: 'Map fields',
  IMPORT: 'Import contacts',
};

export const DROPZONE_STATUS = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading',
};

export const MAPPING_STATES = {
  HIDDEN: 'Hidden',
  UNMATCHED: 'Unmatched',
  MATCHED: 'Matched',
};

export const DROPZONE_ERROR = {
  WRONG_FILE_CONTENT: 'WRONG_FILE_CONTENT',
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  IMPORT_ERROR: 'IMPORT_ERROR',
  USER_CANCELLED: 'USER_CANCELLED',
};

export const REQUIRED_HEADERS = ['email'];

export const HIDE_FIELD_KEY = 'hide_this_field';
export const HIDE_FIELD_LABEL = 'Hide this field';

export const DROPZONE_COPY = {
  DRAG_ACTIVE: 'Drop your file here',
  DRAG_INACTIVE: 'Drop your CSV file or click to browse',
  PERMISSION_NOTICE:
    "It's important for your deliverability that everyone you send to has given you explicit permission to email them. PRAY.COM does not allow purchased or rented lists.",
};

export const DROPZONE_ERROR_COPY = {
  UPLOAD_ERROR: 'Something went wrong during the upload process. Please try uploading your file again.',
  IMPORT_ERROR: 'Something went wrong during the import process. Please try importing your file again.',
  WRONG_FILE_CONTENT: 'The uploaded file is not a valid CSV file. Please ensure your file is properly formatted.',
  USER_CANCELLED: 'The upload was cancelled.',
};

export const UPLOAD_STEP_COPY = {
  TEMPLATE_NOTE_TITLE: 'Need help?',
  TEMPLATE_DOWNLOAD_LINK: 'Download our CSV template',
  TEMPLATE_DOWNLOAD_TEXT: 'for correct formatting',
};

export const MAPPING_STEP_COPY = {
  VERIFY_MAPPINGS_TEXT: "Verify that your file's columns are properly matched with contact properties",
  FILE_FIELDS_TEXT: 'Fields in your file',
  STUDIO_FIELDS_TEXT: 'Fields in Pray Studio',
};

export const CANCEL_UPLOAD_DIALOG_COPY = {
  TITLE: 'Cancel upload',
  DESCRIPTION:
    'You’ve successfully uploaded your file. If you cancel now, your progress will be lost, and you’ll need to re-upload the file to continue. Are you sure you want to cancel?',
  BACK_TO_UPLOAD: 'Go back to upload',
  CANCEL: 'Yes, Cancel',
};

export const CANCEL_MAPPING_DIALOG_COPY = {
  TITLE: 'Exit Field Mapping',
  DESCRIPTION:
    'Your uploaded data isn’t fully mapped yet. If you cancel now, your mappings won’t be saved, and you’ll need to start over. Are you sure you want to exit this step?',
  RESUME_MAPPING: 'Resume mapping',
  DISCARD_MAPPING: 'Discard mapping',
};

export const CANCEL_IMPORT_DIALOG_COPY = {
  TITLE: 'Stop Adding Contacts',
  DESCRIPTION:
    'The system is currently adding your contacts to the list. If you cancel now, the process will stop, and incomplete data won’t be saved. Do you want to stop adding contacts?',
  BACK_TO_IMPORT: 'Continue adding',
  CANCEL: 'Yes, stop',
};

export const MAPPING_BANNER_COPY = {
  REQUIRED_FIELDS: 'Please map the email field. This field is required to import contacts.',
};
