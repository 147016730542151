import api from '@pray/shared/utils/api';

export const getArtistContentSuggestions = async ({ artistId = '', contentId = '', property = '' } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!property) throw Error('Property is required');

  const params = {
    property,
  };

  const url = `/web/studio/artists/${artistId}/content/${contentId}/suggestions`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
