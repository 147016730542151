import { Route, Routes } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';

import MainLayout from 'components/layouts/MainLayout';
import ProtectedRoute from 'components/router/ProtectedRoute';
import { Permissions } from 'utils/auth';

import RevenueSettingsPage from './RevenueSettings/RevenueSettingsPage';
import SocialAccountsSettingsPage from './SocialAccountsSettingsPage/SocialAccountsSettingsPage';
import TranslationsSettings from './TranslationsSettings/TranslationsSettings';
import VoiceSettingsPage from './VoiceSettings/VoiceSettingsPage';

export default function SettingsTab(props) {
  const { permissions } = useAuth();

  return (
    <MainLayout data-viewname="PRAY Studio - Settings Tab">
      <Routes>
        <Route
          path="translations"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <TranslationsSettings {...props} />
            </ProtectedRoute>
          }
        />
        <Route path="voices" element={<VoiceSettingsPage {...props} />} />
        <Route path="revenue" element={<RevenueSettingsPage {...props} />} />
        <Route path="social-accounts" element={<SocialAccountsSettingsPage {...props} />} />
      </Routes>
    </MainLayout>
  );
}
