import React, { useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';

import { Close } from 'images/icons';

import CreateVoiceNameDialog from './CreateVoiceNameDialog';
import { MAX_SAMPLES, SampleUploadStatus } from '../../constants';
import { useSampleDropzone } from '../../hooks/useVoiceDropzone';
import SamplesCounter from '../SamplesUploader/SamplesCounter';
import SamplesDropzone from '../SamplesUploader/SamplesDropzone';
import SampleNote from '../SamplesUploader/SamplesNote';
import UploadedSamples from '../SamplesUploader/UploadedSamples';

export default function VoiceUploadDialog({
  uploadedSamples,
  isLoading,
  isPlayingId,
  onCreate,
  onClose,
  onAcceptedFiles,
  onRejectedFiles,
  handlePlayClick,
  handlePauseClick,
  handleDeleteSample,
}) {
  const isMaxSamples = uploadedSamples.length === MAX_SAMPLES;
  const isValid =
    uploadedSamples.length > 0 && uploadedSamples.every((sample) => sample.status === SampleUploadStatus.SUCCESS);

  const [isCreateNameDialogOpen, setIsCreateNameDialogOpen] = useState(false);
  const { getRootProps, getInputProps } = useSampleDropzone({
    uploadedSamples,
    onAcceptedFiles,
    onRejectedFiles,
  });

  if (isCreateNameDialogOpen) {
    return (
      <CreateVoiceNameDialog
        isLoading={isLoading}
        onBack={() => setIsCreateNameDialogOpen(false)}
        onCreate={onCreate}
        onClose={onClose}
      />
    );
  }

  return (
    <Dialog>
      <div className="flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <Text variant={typography.heading_2xl}>Upload audio samples</Text>
          <Button className="!pr-0" onClick={onClose}>
            <Close />
          </Button>
        </div>
        <Text className="!font-medium" variant={typography.body_lg} color={colors.text_tertiary}>
          Upload voice files to create a custom voice for use in your series and episodes
        </Text>
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <SamplesCounter uploadedSamples={uploadedSamples} />

        <UploadedSamples
          uploadedSamples={uploadedSamples}
          isPlayingId={isPlayingId}
          handlePauseClick={handlePauseClick}
          handlePlayClick={handlePlayClick}
          handleDeleteSample={handleDeleteSample}
        />

        {!isMaxSamples && <SamplesDropzone getRootProps={getRootProps} getInputProps={getInputProps} />}

        <SampleNote />
      </div>

      <div className="mt-2 flex items-center justify-end gap-4">
        <Button variant={buttons.variant.secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={buttons.variant.primary} disabled={!isValid} onClick={() => setIsCreateNameDialogOpen(true)}>
          Next
        </Button>
      </div>
    </Dialog>
  );
}
