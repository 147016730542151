import { useCallback, useSyncExternalStore } from 'react';

export default function useSelection() {
  const subscribe = useCallback((callback) => {
    document.addEventListener('selectionchange', callback);
    return () => document.removeEventListener('selectionchange', callback);
  }, []);

  const getSnapshot = () => window.getSelection();

  return useSyncExternalStore(subscribe, getSnapshot);
}
