import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export default function AvatarContent() {
  return (
    <div className="flex flex-col gap-4 p-6">
      <Text variant={typography.heading_md}>Avatar</Text>
      <div className="rounded-md border p-4">
        <Text variant={typography.body_md}>
          This is the Avatar content. UI for managing playlist avatar will be implemented here.
        </Text>
      </div>
    </div>
  );
}
