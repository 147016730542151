import api from '@pray/shared/utils/api';

export async function cloneEmailTemplate({ artistId = undefined, templateId = undefined, name = undefined }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!templateId) throw new Error('Template ID is required');
  if (!name) throw new Error('Template name is required');

  const data = {
    name,
    email_template_id: templateId,
  };

  const url = `/web/studio/artists/${artistId}/email-templates/clone`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
