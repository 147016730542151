import './config/datadog';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';

import StudioContextProvider from 'context/StudioContext';

import App from './App';

import '@pray/shared/styles/normalize.css';
import '@pray/shared/styles/global.css';

import './styles/app.scss';
import './styles/globals.css';

export const rootSelector = '#root';

const root = ReactDOM.createRoot(document.querySelector(rootSelector)!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <StudioContextProvider>
      <App />
    </StudioContextProvider>
  </QueryClientProvider>
);
