import React from 'react';

export function BackLinkButtonArrow(props) {
  return (
    <div style={{ position: 'relative', width: '24', height: '24' }} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path
          stroke="#1F2024"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M10 18.25 3.75 12m0 0L10 5.75M3.75 12h16.5"
        />
      </svg>
    </div>
  );
}
