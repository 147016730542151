import { DateTime } from 'luxon';
import React from 'react';
import { useMatch } from 'react-router-dom';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useGetAnnouncementsDetails from 'hooks/announcements/useGetAnnouncementsDetails';

import AnnouncementPreview from './AnnouncementPreview';
import TabPage from '../../../components/TabPage/TabPage';

export default function AnnouncementOverview() {
  const routeMatch = useMatch('/leaders/:artistId/announcements/:announcementId/:action');
  const { announcementId, artistId } = routeMatch.params;
  const { data, isLoading } = useGetAnnouncementsDetails(artistId, announcementId);
  const hasResource = data?.post_resource?.url;

  const getDetails = () => {
    return (
      <div className="ml-auto flex max-w-[350px] flex-col space-y-6">
        <Text variant={typography.h2}>Details</Text>

        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Announcement
          </Text>
          <Text color={colors.text_primary} wordLimit={36} className="font-medium">
            {data?.body}
          </Text>
        </div>
        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Publish date
          </Text>
          <Text color={colors.text_primary} className="font-medium">
            Scheduled on&nbsp;
            {(data?.scheduled_at
              ? DateTime.fromISO(data?.scheduled_at)
              : DateTime.fromMillis(data?.created_timestamp)
            ).toFormat('MMM dd, yyyy')}
          </Text>
        </div>
        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Link
          </Text>
          <a href={data?.post_resource?.url} target="_blank" rel="noreferrer">
            <Text color={colors.text_primary} className={`font-medium ${hasResource && 'cursor-pointer underline'}`}>
              {data?.post_resource?.url ?? 'No Link Attached'}
            </Text>
          </a>
        </div>
        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Segment
          </Text>
          <Text color={colors.text_primary} className="font-medium">
            {data?.segment_name ?? '--'}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <TabPage title="Overview" data-viewname={VIEW_EVENT_NAMES.ANNOUNCEMENTS.OVERVIEW}>
      <div className="mt-6 grid grid-cols-5 gap-2">
        <div className="col-span-3 flex items-center justify-center">
          <AnnouncementPreview isLoading={isLoading} data={data} />
        </div>

        <div className="col-span-2">{data ? getDetails() : <div>Loading</div>}</div>
      </div>
    </TabPage>
  );
}
