import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useLeadClientIntegrations from 'hooks/leadCampaigns/useLeadClientIntegrations';
import useLeadClients from 'hooks/leadClients/useLeadClients';
import useAppRoutes from 'hooks/useAppRoutes';
import { Pencil, Plus } from 'images/icons';

import ActionButton from '../../../components/ActionButton/ActionButton';
import EmptyState from '../../../components/EmptyState/EmptyState';
import TabPage from '../../../components/TabPage/TabPage';

import churchIcon from 'images/icons/church.svg';

export default function Organizations() {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const leadClients = useLeadClients();

  const renderTopRightButtons = () => {
    return <ActionButton text="Create" to="create" />;
  };

  return (
    <TabPage
      title="Organizations"
      viewName={VIEW_EVENT_NAMES.MINISTRY_MATCH.ORGANIZATIONS}
      topRightButtons={renderTopRightButtons()}
    >
      {(() => {
        if (leadClients.isLoading) return null;

        if (!leadClients.isFetching && !leadClients.hasPreviousPage && !leadClients.hasNextPage) {
          return (
            <EmptyState
              icon={<Plus />}
              title="Create your first organization"
              subtitle='Click on "Create" to create your first organization'
            />
          );
        }

        return (
          <>
            <OrganizationsTable
              data={leadClients.data.pages.flatMap((page) => page.data)}
              sortBy={leadClients.sortOrder}
              onSortChange={leadClients.setSortOrder}
              onSelectionChange={() => null}
              onEditButtonClick={({ id }) => {
                navigate(appRoutes.ministryMatchOrganizationDetails(id));
              }}
            />
            {leadClients.hasNextPage && <InfiniteScroll fetchData={leadClients.fetchNextPage} />}
          </>
        );
      })()}
    </TabPage>
  );
}

const OrganizationsTable = ({ data, sortBy, onSortChange, onSelectionChange, onEditButtonClick }) => {
  const { data: leadClientIntegrations } = useLeadClientIntegrations();

  const getIntegrationDetails = (organization) => {
    const integrationType = organization.lead_integration_type;
    return leadClientIntegrations?.find(({ type }) => integrationType === type);
  };

  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    return <IconButton icon={Pencil} tooltip="Edit" onClick={() => onEditButtonClick(item)} />;
  };

  return (
    <div className="mt-6">
      <Table
        sortable
        sortBy={sortBy}
        rowCount={data.length}
        rowActions={getRowActions}
        onSortChange={onSortChange}
        onSelectionChange={onSelectionChange}
      >
        <Thead>
          <Tr>
            <Th field="name">Organization</Th>
            <Th field="lead_integration_type">Integration</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((organization) => {
            const organizationImage = organization.image_url || churchIcon;
            const integrationDetails = getIntegrationDetails(organization);

            return (
              <Tr key={organization.id}>
                <Td>
                  <div className="flex gap-4">
                    <div className="flex size-10 items-center justify-center overflow-hidden rounded-md bg-[#f2f2f2]">
                      <img
                        alt=""
                        src={organizationImage}
                        onError={(event) => {
                          event.target.src = churchIcon;
                        }}
                      />
                    </div>
                    <div className="flex max-w-[192px] flex-col justify-center">
                      <Text variant="subhead_small" className="line-clamp-2 font-medium">
                        {organization.name}
                      </Text>
                      {organization.description && (
                        <Text variant="footnote" className="font-medium text-[#707070]">
                          {organization.description}
                        </Text>
                      )}
                    </div>
                  </div>
                </Td>
                <Td>
                  <div className="flex items-center gap-2">
                    {integrationDetails && (
                      <img className="size-6 rounded-md" src={integrationDetails.image_url} alt="" />
                    )}
                    <div className="flex max-w-[192px] flex-col">
                      <Text variant="subhead_small" className="line-clamp-2 font-medium">
                        {integrationDetails?.label}
                      </Text>
                    </div>
                  </div>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};
