import React from 'react';

import { typography, buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Image from '@pray/shared/components/ui/Image/Image';
import Text from '@pray/shared/components/ui/Text';

import defaultCoverImage from './pray-module-dialog-cover.png';

export default function PrayModuleDialog({ title, message, image = null, onDone }) {
  return (
    <div>
      <div className="fixed inset-0 z-[100] bg-black opacity-45" />
      <div className="fixed inset-0 z-[100] flex items-center justify-center">
        <div className="relative flex w-[480px] flex-col rounded-2xl bg-white opacity-100">
          <Image src={image || defaultCoverImage} alt="" className="rounded-t-2xl" />
          <div className="m-6 flex flex-col gap-4">
            <Text variant={typography.h1}>{title}</Text>
            <Text variant={typography.body_md}>{message}</Text>
            <div className="mt-6 flex flex-row justify-end">
              <Button variant={buttons.variant.primary} onClick={onDone}>
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
