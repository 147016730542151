import api from '@pray/shared/utils/api';

/**
 * @param {object} params
 * @param {'content'|'content_series'|'artist'|'book_chapter'} params.type
 * @param {string} params.search
 */
export const searchItems = async ({ type, search }) => {
  if (!['content', 'content_series', 'artist', 'book_chapter'].includes(type)) {
    throw Error('Invalid type');
  }

  if (!search?.trim()) {
    throw Error('Search term is required');
  }

  const params = { type, search };
  const url = `web/studio/taggings/item-search`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
};
