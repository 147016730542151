import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { EmptyPayouts } from 'images/icons';

export default function PayoutsEmpty() {
  return (
    <div className="mt-32 flex flex-col items-center justify-center gap-2">
      <EmptyPayouts className="mb-2 size-16" />
      <Text variant={typography.h2} color={colors.text_primary}>
        No Bank Transfers
      </Text>
      <Text
        color={colors.text_tertiary}
        className="w-72 text-center text-[17px] font-medium leading-[22px] text-[#71747B]"
      >
        You haven't received any revenue.
      </Text>
    </div>
  );
}
