import { StepStatus } from './types';

/**
 * Custom hook to determine the status of a step
 *
 * @param step - The step name/label
 * @param activeStep - The currently active step
 * @param completedSteps - Array of completed step names
 * @returns Object containing isActive, isCompleted flags and status enum
 */
export default function useStepStatus(step: string, activeStep: string, completedSteps: string[] = []) {
  // A step is active if it matches the active step
  const isActive = step === activeStep;

  // A step is completed if it's in the completedSteps array
  const isCompleted = completedSteps.includes(step);

  let status = StepStatus.INACTIVE;
  if (isCompleted) {
    status = StepStatus.COMPLETED;
  } else if (isActive) {
    status = StepStatus.ACTIVE;
  }

  return {
    isActive,
    isCompleted,
    status,
  };
}
