import React from 'react';

const PlayIconSmall = (props) => {
  return (
    <svg viewBox="0 0 16 16" {...props}>
      <path
        d="M14.5195 7.14648L5.52148 1.64746C5.36992 1.55478 5.1964 1.50415 5.01878 1.50079C4.84116 1.49743 4.66585 1.54145 4.51089 1.62833C4.35593 1.71521 4.22692 1.84181 4.13712 1.9951C4.04733 2.14839 4 2.32283 4 2.50048V13.4995C4.00014 13.6771 4.04757 13.8515 4.13739 14.0047C4.22722 14.1579 4.35622 14.2844 4.51113 14.3712C4.66604 14.4581 4.84128 14.5021 5.01884 14.4988C5.19641 14.4956 5.3699 14.4451 5.52148 14.3525L14.5195 8.85351C14.6657 8.76413 14.7865 8.6387 14.8703 8.48925C14.954 8.3398 14.998 8.17133 14.998 8C14.998 7.82866 14.954 7.6602 14.8703 7.51075C14.7865 7.36129 14.6657 7.23587 14.5195 7.14648Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIconSmall;
