import api from '@pray/shared/utils/api';

export default {
  async getSharingLinks({ id = null, type = null }) {
    if (!id) throw Error('Sharing Entity ID is required');
    if (!type) throw Error('Sharing Entity Type is required');

    const params = {
      type,
    };

    const url = `/web/entity/${id}/share`;
    const response = await api.executeGetRequest(url, params);

    return response.data;
  },
};
