import React from 'react';

import styles from './CircularProgress.module.scss';

export default function CircularProgress({ value = 0 }) {
  // This number represents 100% and is used to calculate the progress animation
  const totalValue = 136;

  return (
    <div className={styles.container}>
      <span className={styles.loader} role="progressbar" aria-label="Loading">
        <svg viewBox="22 22 44 44">
          <circle className={styles.backgroundCircle} cx="44" cy="44" r="20" fill="none" strokeWidth="3" />
          <circle
            className={styles.circle}
            style={{
              strokeDasharray: totalValue,
              strokeDashoffset: totalValue - (value * totalValue) / 100,
            }}
            cx="44"
            cy="44"
            r="20"
            fill="none"
            strokeWidth="3"
          />
        </svg>
      </span>
    </div>
  );
}
