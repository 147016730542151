import React from 'react';

import useBodyStyles from '@pray/shared/hooks/useBodyStyles';
import useEventListener from '@pray/shared/hooks/useEventListener';

import closeIcon from '../assets/images/close-icon.svg';

import defaultStyles from './Modal.module.css';

export default function Modal({
  backgroundComponent = null,
  children,
  customStyles = {},
  isShowCloseButton = true,
  fullWidth = false,
  onClose,
  className = '',
  ...props
}) {
  const styles = {
    ...defaultStyles,
    ...customStyles,
  };

  const handleKeyDown = (event) => {
    const key = event.key.toLowerCase();
    if (key === 'escape' && onClose) onClose();
  };

  useEventListener('keydown', handleKeyDown);

  useBodyStyles({
    overflow: 'hidden',
  });

  const modalStyles = fullWidth ? styles.full : className;

  return (
    <div className={styles.container} {...props}>
      {backgroundComponent}
      <div className={`${styles.modal} ${modalStyles}`}>
        {isShowCloseButton && (
          <div className={styles.close} data-tapeventname="Close">
            <img src={closeIcon} alt="Close" role="none" onClick={onClose} />
          </div>
        )}
        <div className={styles.viewport}>{children}</div>
      </div>
    </div>
  );
}
