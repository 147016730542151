import React, { useMemo } from 'react';

import { SESSION_STORAGE } from '@pray/shared/constants';

import { ANALYTIC_REPORTS, VIEW_EVENT_NAMES } from 'constants.js';

import AnalyticsReport from './AnalyticsReport';

export default function AudienceReport({ artistId }) {
  const params = useMemo(() => {
    const token = sessionStorage.getItem(SESSION_STORAGE.SESSION_TOKEN);

    if (!artistId || !token) return null;

    const reportParams = {
      'Artist.artist_ids': artistId,
      'Artist.token': token,
    };

    const stringifiedParams = JSON.stringify(reportParams);

    return encodeURIComponent(stringifiedParams);
  }, [artistId]);

  return (
    <AnalyticsReport
      title="Audience"
      data-viewname={VIEW_EVENT_NAMES.ANALYTICS.AUDIENCE}
      url={ANALYTIC_REPORTS.AUDIENCE}
      params={params}
    />
  );
}
