import React from 'react';
import { FormProvider } from 'react-hook-form';

import { buttons, typography } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { Info } from 'images/icons';

import RevenuePauseResumeModal from './RevenueComponents/Modal/RevenuePauseResumeModal';
import RevenueAccountDetails from './RevenueComponents/RevenueAccountDetails';
import RevenueAccountSetup from './RevenueComponents/RevenueAccountSetup';
import { useRevenueSettings } from './useRevenueSettings';

export default function RevenueSettings() {
  const {
    form,
    hasGivingAccount,
    isLoadingSave,
    isLoadingStatusChange,
    isPaused,
    isShowActionModal,
    isLoadingSetup,
    isLoadingViewAccount,
    isButtonDisabled,
    status,
    onSave,
    onViewAccount,
    onSetupAccount,
    onCancelPauseModal,
    onModalAction,
    onOpenPauseModal,
  } = useRevenueSettings();

  const renderTopRightButtons = () => {
    if (!hasGivingAccount) return null;

    return (
      <div className="flex gap-4">
        <Button variant={buttons.variant.secondary} onClick={onOpenPauseModal}>
          {isPaused ? 'Resume Revenue' : 'Pause Revenue'}
        </Button>

        <ActionButton
          type="submit"
          form="donationSettings"
          text="Save Changes"
          disabled={isButtonDisabled || isLoadingSave}
          isLoading={isLoadingSave}
        />
      </div>
    );
  };

  return (
    <div data-viewname={VIEW_EVENT_NAMES.DONATIONS.SETTINGS}>
      <Metadata title="PRAY Studio for Leaders - Revenue settings" />

      <FormProvider {...form}>
        <TabPage title="Revenue Settings" topRightButtons={renderTopRightButtons()}>
          {isPaused && <RevenueSettingsAlert />}

          {hasGivingAccount && (
            <form id="donationSettings" onSubmit={form.handleSubmit(onSave)} noValidate>
              <RevenueAccountDetails
                status={status}
                isLoadingViewAccount={isLoadingViewAccount}
                onViewAccount={onViewAccount}
              />
            </form>
          )}

          {!hasGivingAccount && <RevenueAccountSetup isLoadingSetup={isLoadingSetup} onSetupAccount={onSetupAccount} />}

          {isShowActionModal && (
            <RevenuePauseResumeModal
              isPaused={isPaused}
              isLoadingStatusChange={isLoadingStatusChange}
              onCancelPauseModal={onCancelPauseModal}
              onModalAction={onModalAction}
            />
          )}
        </TabPage>
      </FormProvider>
    </div>
  );
}

function RevenueSettingsAlert() {
  return (
    <div className="mt-5 flex items-center gap-4 rounded-md bg-[#F7C1B580] p-4">
      <Info color="#441803" />
      <Text className="!font-medium" variant={typography.body_lg}>
        You paused revenue. To begin collecting again, click the resume revenue button.
      </Text>
    </div>
  );
}
