import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';

import { CANCEL_MAPPING_DIALOG_COPY } from '../../../constants';

export default function CancelMappingDialog({ onResumeMapping, onDiscardMapping }) {
  return (
    <Dialog>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_2xl}>{CANCEL_MAPPING_DIALOG_COPY.TITLE}</Text>
        <Text className="!font-medium" color={colors.text_secondary}>
          {CANCEL_MAPPING_DIALOG_COPY.DESCRIPTION}
        </Text>
        <div className="mt-4 flex flex-row justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onResumeMapping}>
            {CANCEL_MAPPING_DIALOG_COPY.RESUME_MAPPING}
          </Button>
          <Button variant={buttons.variant.primary} onClick={onDiscardMapping}>
            {CANCEL_MAPPING_DIALOG_COPY.DISCARD_MAPPING}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
