import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import api from '@pray/shared/utils/api';

export const getContentTranscriptMultiVoices = async ({ artistId = null, contentId = null, locale = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!locale) throw Error('Locale is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/locales/${locale}/transcripts/multi-voices`;
  const response = await api.executeGetRequest(url);

  return response.data;
};

export function useGetContentTranscriptMultiVoices({ artistId, contentId, locale }) {
  const query = useQuery({
    enabled: !!artistId && !!contentId && !!locale,
    queryKey: queryKeys.artistContentDetailsLocaleTranscriptMultiVoices(artistId, contentId, locale),
    queryFn: async () => {
      const response = await getContentTranscriptMultiVoices({
        artistId,
        contentId,
        locale,
      });

      return response.data;
    },
  });

  return query;
}
