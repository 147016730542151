import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

export function useCreateSocialClips() {
  async function createSocialClips({ artistId, contentId }) {
    await studioService.socialClips.createSocialClips({
      artistId,
      contentId,
    });
  }

  const mutation = useMutation({
    mutationFn: createSocialClips,
  });

  const { mutateAsync } = mutation;

  return {
    createSocialClips: mutateAsync,
  };
}
