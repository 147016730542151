import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buttons, colors } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import SocialAuth from '@pray/shared/components/SocialAuth/SocialAuth';
import Button from '@pray/shared/components/ui/Button/Button';
import { Logo } from '@pray/shared/components/ui/Icons/Logo';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { PRAY_EXTERNAL_LINKS } from '@pray/shared/constants';
import { getEmailStatus } from '@pray/shared/utils/auth';
import logger from '@pray/shared/utils/logger';
import { parseQueryString } from '@pray/shared/utils/parseFromQuery';
import { cn } from '@pray/shared/utils/styles';
import { isValidEmail } from '@pray/shared/utils/validation';

import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import styles from './IndexPage.module.scss';

const Steps = {
  EMAIL: 1,
  CONFIRMATION: 2,
};

export default function IndexPage() {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const queryParams = parseQueryString();
  const [step, setStep] = useState(Steps.EMAIL);
  const [email, setEmail] = useState(queryParams.email || '');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { authenticate } = useStudioContext();

  useEffect(() => {
    document.body.classList.add('theme-dark');
    document.body.classList.remove('theme-light');
  }, []);

  const handleEmailValidation = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const customRoute = appRoutes.login();
      const queryParams = window.location.search.substring(1);

      const emailStatus = await getEmailStatus({
        customRoute,
        queryParams,
        email,
      });

      if (emailStatus.isEmailAvailable) {
        navigate(appRoutes.initial());
      } else {
        setStep(Steps.CONFIRMATION);
      }
    } catch (err) {
      logger.debug(err);
      setError('Failed to validate email address. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    const isEmailValid = isValidEmail(value);

    setError(isEmailValid ? '' : 'Please enter a valid email address');
    setEmail(value);
  };

  const handleAuthStarted = () => {
    setIsLoading(true);
  };

  const handleAuthError = () => {
    setIsLoading(false);
  };

  const handleAuthSuccess = async () => {
    await authenticate();
  };

  const renderStep = () => {
    if (step === Steps.EMAIL) {
      return (
        <>
          <section className={styles.header}>
            <Text className={styles.title}>Welcome!</Text>
            <Text className={styles.subtitle}>Become a Leader on PRAY today</Text>
            <Text className={styles.description}>Sign up or log in with your email to manage your content on PRAY</Text>
          </section>

          <form className={styles.email} onSubmit={handleEmailValidation}>
            <div className={cn(styles.input, 'theme-light')}>
              <TextInput
                type="email"
                placeholder="Enter your email"
                value={email}
                errorMessage={error}
                disabled={isLoading}
                onChange={handleEmailChange}
              />
            </div>
            <Button
              color={colors.pray_featured}
              disabled={!email || !!error || isLoading}
              variant={buttons.variant.primary}
              onClick={handleEmailValidation}
            >
              Get Started
            </Button>
          </form>

          <div className={styles.socialButtons}>
            <SocialAuth
              mode="compact"
              disabled={isLoading}
              onAuthStarted={handleAuthStarted}
              onAuthError={handleAuthError}
              onAuthSuccess={handleAuthSuccess}
            />
          </div>

          <Text className={styles.terms}>
            By continuing to use PRAY, you agree to our{' '}
            <a href={PRAY_EXTERNAL_LINKS.TERMS_OF_SERVICE} target="_blank" rel="noreferrer">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href={PRAY_EXTERNAL_LINKS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </Text>
        </>
      );
    }

    if (step === Steps.CONFIRMATION) {
      return (
        <section className={styles.header}>
          <Text className={styles.title}>Welcome!</Text>
          <Text className={styles.subtitle}>Great! Now, check your email.</Text>
          <Text className={styles.confirmation}>
            To confirm your email address, tap the button in the message we sent to <span>{email}</span>.
          </Text>

          <Text className={styles.didntReceive}>Didn&apos;t receive the email?</Text>

          <Button className={styles.resendButton} variant={buttons.variant.secondary} onClick={handleEmailValidation}>
            Resend Link
          </Button>
        </section>
      );
    }

    return null;
  };

  return (
    <div className={styles.page} data-viewname="Studio - Index page">
      <Metadata title="PRAY Studio for Leaders - Content Creators" />

      <Logo className={styles.logo} />

      <div className={styles.main}>{renderStep()}</div>

      <div className={styles.footer}>
        <div className={styles.gradient} />
      </div>
    </div>
  );
}
