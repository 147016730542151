import React, { useMemo, useRef, useEffect } from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import TextArea from '@pray/shared/components/ui/TextArea';

import AiSuggestionButton from 'components/pages/StudioPage/components/AiSuggestion/AiSuggestionButton';
import { Close } from 'images/icons';

import { SolomonRecentSearch } from './SolomonResponses/SolomonRecentSearch';

const sanitizeInput = (input) => {
  return input.replace(/<[^>]*>?/gm, '').replace(/[^\w\s.,?!-]/gi, '');
};

export default function SolomonInput({
  value,
  isFocused,
  isLoading,
  isDisabled,
  recentSearches,
  onSubmit,
  onClear,
  onPromptSelected,
  onAddRecentSearch,
  onRemoveRecentSearch,
  onFocus,
  onBlur,
  onChange,
}) {
  const textareaRef = useRef(null);

  const filteredRecentSearches = useMemo(() => {
    if (!value) return recentSearches;
    return recentSearches.filter(
      (recentSearch) =>
        recentSearch.toLowerCase().includes(value.toLowerCase()) && recentSearch.toLowerCase() !== value.toLowerCase()
    );
  }, [value, recentSearches]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const sanitizedValue = sanitizeInput(value);
    onSubmit(sanitizedValue);
    onBlur();

    if (sanitizedValue.trim()) {
      onAddRecentSearch(sanitizedValue.trim());
    }
  };

  const handleRecentSearchSelected = (prompt) => {
    onPromptSelected(prompt);
    const sanitizedValue = sanitizeInput(prompt);

    onSubmit(sanitizedValue);
    onBlur();
  };

  return (
    <form onSubmit={onSubmit} className="relative">
      <Loading isLoading={isLoading} isLight height={56} margin="0">
        <TextArea
          ref={textareaRef}
          rows={1}
          value={value}
          placeholder="Ask Solomon"
          className="focus-within:before:!outline-[#8854F2] hover:before:!outline-[#8854F2]"
          disabled={isDisabled}
          rightIcon={
            <div className="flex items-center gap-2">
              {value.length > 0 && (
                <Button className="z-10 !p-0" onClick={onClear}>
                  <Close />
                </Button>
              )}

              <AiSuggestionButton
                hasInput={value.length > 0}
                onClick={(event) => {
                  if (value.length > 0) {
                    event.preventDefault();
                    handleSubmit(event);
                  }
                }}
              />
            </div>
          }
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey && value.length > 0) {
              event.preventDefault();
              handleSubmit(event);
            }
          }}
        />
      </Loading>

      {isFocused && filteredRecentSearches.length > 0 && (
        <div className="absolute z-10 mt-4 max-h-48 w-full rounded-2xl bg-white p-2 shadow-[0px_0px_40px_0px_rgba(69,71,69,0.20)]">
          <div className="max-h-44 overflow-y-auto pr-2">
            {filteredRecentSearches.map((value) => (
              <SolomonRecentSearch
                key={value}
                value={value}
                onSelect={() => handleRecentSearchSelected(value)}
                onRemove={() => onRemoveRecentSearch(value)}
              />
            ))}
          </div>
        </div>
      )}
    </form>
  );
}
