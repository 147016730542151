import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGivingSummary({ artistId, startDate, endDate }) {
  async function fetchGivingSummary() {
    const response = await studioService.donations.getGivingSummary({ artistId, startDate, endDate });
    return givingSummaryMapper(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!startDate && !!endDate,
    queryKey: queryKeys.artistGivingSummary(artistId, { startDate, endDate }),
    queryFn: fetchGivingSummary,
  });

  return query;
}

function givingSummaryMapper(givingSummary) {
  return {
    totalDonationAmount: givingSummary.total_donation_amount,
    averageDonationAmount: givingSummary.average_donation_amount,
    totalDonations: givingSummary.total_donations,
    totalDonors: givingSummary.total_donors,
  };
}
