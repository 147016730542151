import { useState } from 'react';

import contentService from '@pray/shared/services/contentService';

export default function useContentReaction(content = {}) {
  const [hasReacted, setHasReacted] = useState(content.has_reacted);

  const toggleContentReaction = async (type) => {
    const contentId = content.id;

    if (hasReacted) {
      try {
        setHasReacted(false);
        await contentService.removeContentReaction({ contentId, type });
      } catch {
        setHasReacted(true);
      }
    } else {
      try {
        setHasReacted(true);
        await contentService.addContentReaction({ contentId, type });
      } catch {
        setHasReacted(false);
      }
    }
  };

  return {
    hasReacted,
    toggleContentReaction,
  };
}
