import React, { useEffect, useRef, useState } from 'react';

import AudioPlayer from '@pray/shared/components/AudioPlayer/AudioPlayer';
import { LOCALES } from '@pray/shared/constants';
import contentService from '@pray/shared/services/contentService';
import eventTracking from '@pray/shared/utils/eventTracking';
import logger from '@pray/shared/utils/logger';
import { navigateToDiscoverPageAndShowContentNotFoundModal } from '@pray/shared/utils/navigation';
import storage from '@pray/shared/utils/storage';

const ContentPlayer = ({
  contentItems = [],
  locale,
  itemType = 'content',
  isFetchContentData = true,
  onContentNotFound = navigateToDiscoverPageAndShowContentNotFoundModal,
  children,
  ...props
}) => {
  const [currentContentId, setCurrentContentId] = useState('');
  const [currentContent, setCurrentContent] = useState({});
  const [contentCache, setContentCache] = useState({});

  const [playlist, setPlaylist] = useState([]);
  const [previousContentId, setPreviousContentId] = useState();
  const [nextContentId, setNextContentId] = useState();

  const audioRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    updateContentCache();
  }, []);

  useEffect(() => {
    setCurrentContentId(props.currentContentId);
  }, [props.currentContentId]);

  useEffect(() => {
    if (!currentContentId) {
      setCurrentContent({});
      setPlaylist([]);
      return;
    }

    if (currentContentId && currentContent.id !== currentContentId) {
      fetchContentData();
    }
  }, [currentContentId]);

  useEffect(() => {
    handleContentAccess();

    return handleContentChanged;
  }, [currentContent.id]);

  useEffect(() => {
    contentRef.current = currentContent;
  }, [currentContent]);

  function updateContentCache() {
    const cache = contentItems.reduce((acc, item) => {
      return { ...acc, [item.id]: { ...item, cached: true } };
    }, {});
    setContentCache(cache);
  }

  async function fetchContentData() {
    let contentData = contentCache[currentContentId];

    if (contentData?.audio_url) {
      setCurrentContent(contentData);
    }

    if (isFetchContentData || !contentData || !contentData?.cached) {
      try {
        contentData = await contentService.fetchContentData({
          id: currentContentId,
          locale,
        });

        const updatedContentData = {
          ...contentData,
          ...contentCache[currentContentId],
        };

        setContentCache({
          ...contentCache,
          [currentContentId]: updatedContentData,
        });

        /**
         * Append timestamp to audio url to avoid cloudfront caching
         * This audio can be updated by the user and we want to make sure the latest audio is played
         */
        if (locale !== LOCALES.DEFAULT && updatedContentData.audio_url) {
          const audioUrl = updatedContentData.audio_url;
          try {
            const url = new URL(audioUrl);
            url.searchParams.append('ts', Date.now());
            updatedContentData.audio_url = url.href;
          } catch (err) {
            logger.error('Failed to append timestamp to audio url', err);
            updatedContentData.audio_url = audioUrl;
          }
        }

        setCurrentContent(updatedContentData);
      } catch (err) {
        const isNotFound = err.response.data?.data?.[0]?.info === 'content not found';
        if (isNotFound && onContentNotFound) onContentNotFound();
      }
    }

    updateNextContent(contentData);
  }

  function handleContentAccess() {
    if (props.isFreeAccess || !currentContent.id) return;

    const user = storage.getUser();
    const isUserSubscribed = user?.is_subscribed;
    const contentIsFree = currentContent.is_free !== false && currentContent.is_free !== 0;
    const hasAccess = currentContent.has_access !== false && currentContent.has_access !== 0;

    if (!isUserSubscribed && (!contentIsFree || !hasAccess)) {
      window.location.replace('/subscribe');
    }
  }

  function handleContentChanged() {
    const audio = audioRef.current;
    if (!audio.paused) trackListenEvent();
  }

  function updateNextContent(contentData) {
    let currentIndex = playlist.findIndex((item) => item?.id === currentContentId);

    if (currentIndex === -1) {
      playlist.push(contentData);
      setPlaylist(playlist);
      currentIndex = playlist.length - 1;
    }

    const previousContent = playlist[currentIndex - 1];
    setPreviousContentId(previousContent?.id);
    setNextContentId(contentData?.next_content_id);
  }

  function handleAudioEnded(...args) {
    trackListenEvent();
    if (nextContentId) setCurrentContentId(nextContentId);
    if (props.onAudioEnded) props.onAudioEnded(...args);
  }

  function updateContentData(updates) {
    setCurrentContent((prevData) => ({
      ...prevData,
      ...updates,
    }));
  }

  function trackListenEvent() {
    const { amplitudeData } = getListenEventData();
    if (amplitudeData) eventTracking.trackEventListen(...amplitudeData);
  }

  function getListenEventData() {
    const audio = audioRef.current;
    const content = contentRef.current;
    const listenDuration =
      content.startTime > audio.currentTime
        ? content.startTime - audio.currentTime
        : audio.currentTime - content.startTime;

    let amplitudeData = null;

    // Listen only progress of the audio
    if (listenDuration >= 0.1) {
      const contentDuration = audio.duration;
      const completionRate = listenDuration / contentDuration;
      const endingPercentage = (content.startTime + listenDuration) / contentDuration;
      const contentSeriesTitle = content.content_series_title;
      const metricsProperties = content.metrics_properties;

      amplitudeData = [
        content.title,
        content.id,
        content.startTime,
        contentDuration,
        listenDuration,
        completionRate,
        endingPercentage,
        contentSeriesTitle,
        itemType,
        metricsProperties,
      ];
    }

    return {
      amplitudeData,
    };
  }

  function setStartTime(startTime) {
    const content = contentRef.current;
    if (content) content.startTime = startTime;
  }

  function handlePlay() {
    const audio = audioRef.current;
    setStartTime(audio.currentTime);
  }

  function handlePause() {
    trackListenEvent();
  }

  function handleDragStarted() {
    trackListenEvent();
  }

  function handleBeforeSkip() {
    const audio = audioRef.current;
    const isPlaying = !audio.paused && audio.currentTime > 0 && !audio.ended;
    if (isPlaying) trackListenEvent();
  }

  function handleAfterSkip() {
    const audio = audioRef.current;
    setStartTime(audio.currentTime);
  }

  return (
    <AudioPlayer
      {...props}
      url={currentContent.audio_url}
      onPlay={handlePlay}
      onPause={handlePause}
      onBeforeSkip={handleBeforeSkip}
      onAfterSkip={handleAfterSkip}
      onAudioEnded={handleAudioEnded}
      onDragStarted={handleDragStarted}
    >
      {(audioController) => {
        const contentController = {
          ...audioController,
          content: {
            ...currentContent,
            previousContentId,
            nextContentId,
          },
          updateContentData,
        };

        audioRef.current = audioController.audio;

        return children && children(contentController);
      }}
    </AudioPlayer>
  );
};

export default ContentPlayer;
