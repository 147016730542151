import { useEffect, useState } from 'react';

import logger from '@pray/shared/utils/logger';

import { useToastMessage } from '@/components/pages/StudioPage/components/StudioToastMessage';
import { useStudioContext } from '@/context/StudioContext';
import { useChapter } from '@/hooks/books/chapters/useChapter';
import { useChapters } from '@/hooks/books/chapters/useChapters';
import { useChapterVersions } from '@/hooks/books/chapters/useChapterVersions';
import { useSaveChapterDraft } from '@/hooks/books/chapters/useSaveChapterDraft';
import useSaveTranscriptVersion from '@/hooks/transcripts/useSaveTranscriptVersion';

export function useBookTranscript({ bookId }: { bookId: string }) {
  const [updatedAt, setUpdatedAt] = useState(null);
  const [selectedChapterId, setSelectedChapterId] = useState('');
  const [selectedVersionId, setSelectedVersionId] = useState('');
  const [isRestoringTranscript, setIsRestoringTranscript] = useState(false);

  const { selectedArtist } = useStudioContext();
  const artistId: string | undefined = selectedArtist?.id;
  const toast = useToastMessage();

  const { data: chapters, isLoading } = useChapters({ artistId, bookId });

  const { data: selectedChapter } = useChapter({
    artistId,
    bookId,
    chapterId: selectedChapterId,
  });
  const {
    data,
    isLoading: isTranscriptHistoryLoading,
    refetch: refetchTranscriptHistory,
  } = useChapterVersions({
    artistId,
    bookId,
    chapterId: selectedVersionId,
  });
  const transcriptHistory = data?.published ?? [];

  const { saveChapterDraft: saveChapterDraftMutation } = useSaveChapterDraft({
    shouldInvalidateOnSuccess: true,
  });
  // For now, only en is supported and so it's hardcoded
  const { saveTranscriptVersion: saveTranscriptVersionMutation } = useSaveTranscriptVersion(
    artistId,
    selectedVersionId,
    'en'
  );

  const restoreTranscript = async ({ transcript }: { transcript: string }) => {
    setIsRestoringTranscript(true);
    try {
      await saveTranscriptVersionMutation({ transcript });
      await saveChapterDraftMutation({ artistId, bookId, chapterId: selectedVersionId, text: transcript });
      await refetchTranscriptHistory();
    } catch (error) {
      toast.show({ error: 'Failed to save transcript version' });
      logger.error('Error saving transcript version:', error);
    } finally {
      setIsRestoringTranscript(false);
    }
  };

  const handleSelectVersion = (versionId: string) => {
    setSelectedVersionId(versionId);
  };

  const handleSelectChapter = (chapterId: string) => {
    setSelectedChapterId(chapterId);
  };

  useEffect(() => {
    if (chapters?.length) {
      const lastUpdate = chapters
        .map((chapter) => chapter.lastUpdate)
        .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];
      setUpdatedAt(lastUpdate);
      setSelectedChapterId(chapters[0]?.id);
      setSelectedVersionId(chapters[0]?.id);
    }
  }, [chapters]);

  return {
    chapters,
    isLoading,
    updatedAt,
    transcriptHistory,
    isTranscriptHistoryLoading,
    isRestoringTranscript,
    selectedChapter,
    restoreTranscript,
    handleSelectVersion,
    handleSelectChapter,
  };
}
