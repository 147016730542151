import { useFormContext } from 'react-hook-form';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';
import { formatCurrency } from '@pray/shared/utils';

import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';

import { nameMaxLength, descriptionMaxLength } from './constants';

export default function CreateEditFundForm({ data, onSave }) {
  const form = useFormContext();
  const { errors } = form.formState;
  const { name, description, imageUrl, isDefault, includeInAppPromotions } = data;

  return (
    <form id="fundForm" onSubmit={form.handleSubmit(onSave)} noValidate>
      <div className="space-y-6">
        <TextInput
          label="Fund Name"
          placeholder="Enter fund name"
          errorMessage={errors.name?.message?.toString()}
          required
          helperText={`${name.length} / ${nameMaxLength}`}
          {...form.register('name', {
            required: 'Fund name is required',
            maxLength: { value: nameMaxLength, message: 'Fund name is too long' },
          })}
        />

        <section className="space-y-4">
          <Text variant={typography.heading_lg}>Fund Description</Text>
          <TextInput
            rows={5}
            required
            label="Description"
            placeholder="Provide a summary about your fund"
            errorMessage={errors.description?.message?.toString()}
            helperText={`${description.length} / ${descriptionMaxLength}`}
            {...form.register('description', {
              required: 'Fund description is required',
              maxLength: { value: descriptionMaxLength, message: 'Fund description is too long' },
            })}
          />
        </section>

        <section className="space-y-6">
          <div className="space-y-1">
            <Text variant={typography.heading_lg}>Add an Image</Text>
            <Text variant={typography.headline_small} className="block">
              Supported image formats include .jpg, .png, and .jpeg.
            </Text>
          </div>

          <MediaUpload
            imageUrl={imageUrl}
            title="Fund Image"
            description="Use a picture that's at least 1080x720 pixels and 4MB or less."
            labelPlacement="right"
            uploadButtonLabel={imageUrl ? 'Change Image' : 'Upload Image'}
            error={errors.imageUrl?.message?.toString()}
            cropProps={{ aspect: 3 / 2 }}
            minifyImage={{ maxSizeMB: 1, maxWidthOrHeight: 500 }}
            onError={(error) => form.setError('imageUrl', error)}
            onFileChange={(file) => {
              form.setValue('imageFile', file, { shouldDirty: true });
              if (file) {
                const reader = new FileReader();
                reader.onloadend = () => form.setValue('imageBase64', reader.result);
                reader.readAsDataURL(file);
              }
            }}
          />
        </section>

        <section className="space-y-4 pt-4">
          <Text variant={typography.heading_lg}>Fund Amounts</Text>
          <div className="grid grid-cols-1 gap-3 xl:grid-cols-2">
            <TextInput
              required
              label="Default Amount Monthly (in cents)"
              placeholder="Amount in cents"
              errorMessage={errors.defaultAmountMonthly?.message?.toString()}
              description={data.defaultAmountMonthly ? formatCurrency(data.defaultAmountMonthly, 2) : ' '}
              {...form.register('defaultAmountMonthly', {
                required: 'Default amount monthly is required',
                onChange: (event) => {
                  const amount = event.target.value.replace(/\D/g, '') || '';
                  form.setValue('defaultAmountMonthly', amount);
                },
              })}
            />
            <TextInput
              required
              label="Default Amount Yearly (in cents)"
              placeholder="Amount in cents"
              errorMessage={errors.defaultAmountYearly?.message?.toString()}
              description={data.defaultAmountYearly ? formatCurrency(data.defaultAmountYearly, 2) : ' '}
              {...form.register('defaultAmountYearly', {
                required: 'Default amount yearly is required',
                onChange: (event) => {
                  const amount = event.target.value.replace(/\D/g, '') || '';
                  form.setValue('defaultAmountYearly', amount);
                },
              })}
            />
            <TextInput
              required={!isDefault}
              label="Amount Monthly 2 (in cents)"
              placeholder="Amount in cents"
              errorMessage={errors.amountMonthly2?.message?.toString()}
              description={data.amountMonthly2 ? formatCurrency(data.amountMonthly2, 2) : ' '}
              {...form.register('amountMonthly2', {
                required: !isDefault ? 'Amount monthly 2 is required' : null,
                onChange: (event) => {
                  const amount = event.target.value.replace(/\D/g, '') || '';
                  form.setValue('amountMonthly2', amount);
                },
              })}
            />
            <TextInput
              required={!isDefault}
              label="Amount Yearly 2 (in cents)"
              placeholder="Amount in cents"
              errorMessage={errors.amountYearly2?.message?.toString()}
              description={data.amountYearly2 ? formatCurrency(data.amountYearly2, 2) : ' '}
              {...form.register('amountYearly2', {
                required: !isDefault ? 'Amount yearly 2 is required' : null,
                onChange: (event) => {
                  const amount = event.target.value.replace(/\D/g, '') || '';
                  form.setValue('amountYearly2', amount);
                },
              })}
            />
          </div>
        </section>

        <section className="space-y-4 pt-4">
          <Text variant={typography.heading_lg}>Display In Popups</Text>
          <div className="flex items-center gap-3">
            <ToggleSwitch
              checked={includeInAppPromotions}
              onChange={() => form.setValue('includeInAppPromotions', !includeInAppPromotions, { shouldDirty: true })}
            />
            <Text variant={typography.headline_small}>Disable/Enable This Fund From Showing In Popups.</Text>
          </div>
        </section>
      </div>
    </form>
  );
}
