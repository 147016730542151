import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useToggleMaster() {
  const queryClient = useQueryClient();

  async function toggleMasterCampaign(campaignId) {
    const response = await studioService.leadCampaigns.updateMasterToggle({
      leadCampaignId: campaignId,
    });

    return response;
  }

  const mutation = useMutation({
    mutationFn: toggleMasterCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.leadCampaigns(),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    toggleMasterCampaign: mutateAsync,
    ...rest,
  };
}
