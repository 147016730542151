import { useEffect } from 'react';

import { VIEW_EVENT_NAMES } from 'constants.js';

import LeadsDropzone from '../../components/LeadsDropzone';
import TemplateNote from '../../components/TemplateNote';

export default function ImportLeadsStep({ uploadState, activeStep, fileName, onImportLeads, onRetry }) {
  useEffect(() => {
    onImportLeads();
  }, []);

  return (
    <div data-viewname={VIEW_EVENT_NAMES.CONTACT_LISTS.IMPORT_CONTACTS} className="flex flex-col gap-8">
      <LeadsDropzone uploadState={uploadState} activeStep={activeStep} uploadedFileName={fileName} onRetry={onRetry} />

      <TemplateNote />
    </div>
  );
}
