import Chip from '@pray/shared/components/ui/Chip';

import { templateTypes } from '../../constants';

export function TemplateTypes({ selectedType, onTypeChange }) {
  return (
    <div className="flex gap-4 py-4">
      <Chip
        selected={selectedType === templateTypes.prayTemplates}
        onClick={() => onTypeChange(templateTypes.prayTemplates)}
      >
        Pray Templates
      </Chip>
      <Chip
        selected={selectedType === templateTypes.userTemplates}
        onClick={() => onTypeChange(templateTypes.userTemplates)}
      >
        My Templates
      </Chip>
    </div>
  );
}
