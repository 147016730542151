import React from 'react';

import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover/Popover';

import { VIEW_EVENT_NAMES } from 'constants.js';

import TabPage from '../../../../components/TabPage/TabPage';

const TOOLBAR_BUTTONS = ['format', 'style', 'align', 'list'];
const CONTENT_LINES = ['paragraph1', 'paragraph2', 'paragraph3', 'paragraph4', 'paragraph5'];

export function StudyGuideLoading() {
  return (
    <TabPage
      title="Study Guide"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_STUDY_GUIDE}
      titleInfo={
        <Popover title="Study Guide" description="Create and manage study guides generated from your content">
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
      topRightButtons={
        <div className="sm-hide md-show flex gap-4">
          <div className="h-8 w-24 animate-pulse rounded bg-gray-200" />
          <div className="h-8 w-20 animate-pulse rounded bg-gray-200" />
        </div>
      }
    >
      {/* Editor toolbar skeleton */}
      <div className="mb-4 border-b p-4">
        <div className="flex items-center gap-4">
          <div className="h-6 w-28 animate-pulse rounded bg-gray-200" />
          <div className="flex gap-2">
            {TOOLBAR_BUTTONS.map((button) => (
              <div key={button} className="h-6 w-8 animate-pulse rounded bg-gray-200" />
            ))}
          </div>
        </div>
      </div>

      {/* Study guide content skeleton */}
      <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-full max-w-none px-8">
        <div className="space-y-4">
          <div className="h-8 w-3/4 animate-pulse rounded bg-gray-200" />
          {CONTENT_LINES.map((line) => (
            <div
              key={line}
              className="h-4 animate-pulse rounded bg-gray-200"
              style={{ width: `${Math.random() * 20 + 75}%` }}
            />
          ))}
        </div>
      </div>
    </TabPage>
  );
}

export default StudyGuideLoading;
