import { useEffect } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { variations } from 'components/pages/StudioPage/components/PeriodicTable/constants';
import PeriodicTableElementCard from 'components/pages/StudioPage/components/PeriodicTable/PeriodicTableElementCard';
import useClassification from 'components/pages/StudioPage/components/PeriodicTable/useClassification';
import { useStudioContext } from 'context/StudioContext';
import usePeriodicTable from 'hooks/periodicTable/usePeriodicTable';
import useAppRoutes from 'hooks/useAppRoutes';
import { Spinner } from 'images/icons';

export default function AudienceSegmentsSection() {
  const appRoutes = useAppRoutes();
  const { selectedArtist } = useStudioContext();
  const isAnalyticsEnabled = selectedArtist.is_studio_analytics_enabled;

  const { data: periodicTableData, isLoading, isError, refetch } = usePeriodicTable({ artistId: selectedArtist.id });
  const segmentGroups = periodicTableData?.segmentGroups;
  const classification = useClassification(segmentGroups?.flatMap((group) => group.segments));

  useEffect(() => {
    refetch();
  }, [refetch, selectedArtist.id]);

  const limitedSegments =
    segmentGroups?.reduce((acc, group, groupIndex) => {
      if (acc.length >= 9) return acc;
      const remainingSlots = 9 - acc.length;
      const segments = group.segments.slice(0, remainingSlots).map((segment) => ({
        ...segment,
        groupId: group.id,
        groupIndex,
      }));
      return [...acc, ...segments];
    }, []) || [];

  if (isError || !isAnalyticsEnabled) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6 rounded-2xl border border-[#DEDFE3] p-6">
      <div className="flex items-center justify-between">
        <Loading isLight isLoading={isLoading} width={170} height={24} margin="0">
          <Text variant={typography.h2}>Audience Segments</Text>
        </Loading>
        <Loading isLight isLoading={isLoading} width={80} margin="0">
          {!isError && (
            <Button className="-mr-4" to={appRoutes.analyticsPeriodicTable()}>
              <Text variant={typography.subhead_medium}>See more</Text>
            </Button>
          )}
        </Loading>
      </div>

      <div className="grid grid-cols-3 gap-4 sm:grid-cols-5 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-9">
        {!isLoading &&
          limitedSegments.map((segment) => {
            return (
              <PeriodicTableElementCard
                key={segment.segment_code}
                to={appRoutes.analyticsSegment(segment.segment_code)}
                group={segment.groupId}
                rank={segment.rank}
                code={segment.segment_code}
                name={segment.segment_name}
                variation={segment.groupIndex % variations.length}
                highlight={{
                  value: segment.minutes_consumed,
                  label: 'Minutes in Prayer',
                  color: classification.getClassificationColor(segment.minutes_consumed),
                }}
                table={{
                  caption: '% of Ministry Users',
                  records: [
                    { label: 'Listens', value: `${segment.listen_percentage}% ` },
                    { label: 'Shares', value: `${segment.shares_percentage}% ` },
                    { label: 'Follows', value: `${segment.follows_percentage}% ` },
                  ],
                }}
                gridArea=""
                selectedGroup=""
              />
            );
          })}
      </div>

      {isLoading && (
        <div className="flex items-center justify-center p-10">
          <Spinner className="animate-spin" />
        </div>
      )}
    </div>
  );
}
