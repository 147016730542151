import { SESSION_STORAGE } from '@pray/shared/constants';

export default {
  set(key, value, ttl) {
    if (typeof window === 'undefined') return;

    const cacheEntry = {
      expiresAt: ttl ? Date.now() + ttl : 0,
      value,
    };

    const cache = _getCache();
    cache[key] = cacheEntry;

    sessionStorage.setItem(SESSION_STORAGE.CACHE, JSON.stringify(cache));
  },

  get(key, isRevalidate) {
    if (typeof window === 'undefined') return null;

    const cache = _getCache();

    const cacheEntry = cache[key];
    if (!cacheEntry) return null;

    if (isRevalidate || (cacheEntry.expiresAt && cacheEntry.expiresAt < Date.now())) {
      sessionStorage.removeItem(key);
      return null;
    }

    return cacheEntry.value;
  },

  async getAsync(options, dataFetcher) {
    const { key, ttl, isRevalidate } = options;
    const value = this.get(key, isRevalidate);
    if (value) return value;

    const { data, skipCaching } = await dataFetcher();

    if (!skipCaching) {
      this.set(key, data, ttl);
    }

    return data;
  },

  entries() {
    if (typeof window === 'undefined') return [];

    const cache = _getCache();

    return Object.entries(cache);
  },

  update(key, value) {
    if (typeof window === 'undefined') return;

    const cache = _getCache();
    cache[key].value = value;

    sessionStorage.setItem(SESSION_STORAGE.CACHE, JSON.stringify(cache));
  },
};

function _getCache() {
  const cacheJson = sessionStorage.getItem(SESSION_STORAGE.CACHE);
  if (!cacheJson) return {};

  return JSON.parse(cacheJson);
}
