import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { Document } from 'images/icons';

export default function ErrorState() {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex w-[500px] flex-col items-center">
        <Document className="mb-6 h-auto w-[163px]" />
        <Text variant={typography.h2} className="mb-1">
          Data Retrieval Unsuccessful
        </Text>
        <Text variant={typography.headline_small} className="mb-6 text-center">
          Something went wrong. Please wait a moment and try refreshing the page.
        </Text>
        <Button variant={buttons.variant.secondary} onClick={() => window.location.reload()}>
          Refresh Page
        </Button>
      </div>
    </div>
  );
}
