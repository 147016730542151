import api from '@pray/shared/utils/api';

export async function deleteTestimonial({ artistId, testimonialId }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!testimonialId) throw Error('Testimonial ID is required');

  const url = `/web/studio/artists/${artistId}/testimonials/${testimonialId}`;
  const response = await api.executeDeleteRequest(url);

  return response.data;
}
