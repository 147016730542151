import { typography } from '@pray/shared/components/foundations';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';

import AiSuggestionButton from 'components/pages/StudioPage/components/AiSuggestion/AiSuggestionButton';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';

import placeholderImage from 'components/pages/StudioPage/components/MediaUpload/assets/placeholder-image.png';

export const MAX_TITLE_LENGTH = 140;
export const MAX_DESCRIPTION_LENGTH = 200;

export type CourseDetailsFormValues = {
  title: string;
  description: string;
  primary_image_url: string;
  primary_image_file: File | null;
};

export type DetailsContentProps = {
  form: {
    values: CourseDetailsFormValues;
    errors: Record<string, string>;
    handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    setValue: <K extends keyof CourseDetailsFormValues>(name: K, value: CourseDetailsFormValues[K]) => void;
    setError: (name: string, error: string) => void;
  };
};

export default function DetailsContent({ form }: DetailsContentProps) {
  return (
    <div className="flex max-w-2xl flex-col gap-6 p-4">
      <Text variant={typography.heading_lg}>Details</Text>

      <div className="flex flex-col gap-1">
        <TextArea
          label="Title (required)"
          name="title"
          rows={2}
          value={form.values.title}
          rightIcon={
            <AiSuggestionButton
              data-tapeventname="Title - A.I. Suggestion"
              isLoading={false}
              tooltip={null}
              onClick={() => { }}
            />
          }
          errorMessage={form.errors.title}
          onChange={form.handleChange}
        />

        <InputHelperText>
          <span />
          <span>
            {form.values.title.length}/{MAX_TITLE_LENGTH}
          </span>
        </InputHelperText>
      </div>

      <div className="flex flex-col gap-1">
        <TextArea
          label="Description"
          name="description"
          rows={5}
          value={form.values.description}
          rightIcon={
            <AiSuggestionButton
              data-tapeventname="Description - A.I. Suggestion"
              isLoading={false}
              tooltip={null}
              onClick={() => { }}
            />
          }
          errorMessage={form.errors.description}
          onChange={form.handleChange}
        />

        <InputHelperText>
          <span />
          <span>
            {form.values.description.length}/{MAX_DESCRIPTION_LENGTH}
          </span>
        </InputHelperText>
      </div>

      <div className="flex flex-col gap-1">
        <Text variant={typography.heading_md}>Thumbnail</Text>
        <Text variant={typography.body_md}>
          Please upload a square image (1400x1400 pixels minimum, 512KB maximum) in JPG or PNG format.
        </Text>

        <MediaUpload
          imageUrl={form.values.primary_image_url}
          placeholderImage={placeholderImage}
          uploadButtonLabel={form.values.primary_image_url ? 'Change Image' : 'Upload Image'}
          secondaryButtonLabel={form.values.primary_image_url ? 'Remove Image' : ''}
          cropProps={{ aspect: 16 / 9 }}
          error={form.errors.primary_image_url}
          onFileChange={(file) => form.setValue('primary_image_file', file)}
          onError={(error) => form.setError('primary_image_url', error)}
        />
      </div>
    </div>
  );
}
