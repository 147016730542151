import { useState } from 'react';

import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

export default function useParseResourceUrl() {
  const [isLoading, setIsLoading] = useState(false);

  async function parseResourceUrl({ resourceUrl = undefined }) {
    setIsLoading(true);

    try {
      const parseResourceUrlBody = {
        url: resourceUrl,
      };

      const metadata = await studioService.externalMetadata.parseExternalUrl(parseResourceUrlBody);

      return metadata;
    } catch (error) {
      logger.error('Failed to parse resource url', error);
      // NOTE: We are returning an empty object here to avoid breaking the form when the metadata is not found
      return {};
    } finally {
      setIsLoading(false);
    }
  }

  return { parseResourceUrl, isLoading };
}
