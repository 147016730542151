import appboy from '@braze/web-sdk';

import { BRAZE_KEY, BRAZE_SDK_ENDPOINT } from '@pray/shared/config';

import storage from './storage';

export const initializeBrazeSdk = (userId) => {
  const user = storage.getUser();
  const options = {
    baseUrl: BRAZE_SDK_ENDPOINT,
    noCookies: true, // uses localstorage instead
  };

  if (user.is_registered) {
    appboy.initialize(BRAZE_KEY, options);
    appboy.display.automaticallyShowNewInAppMessages();
    appboy.changeUser(userId);
  }
};

export default {
  initializeBrazeSdk,
};
