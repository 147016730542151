import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const Portal = ({ id, children }) => {
  const mountPoint = document.getElementById(id);
  const wrapperElement = document.createElement('div');

  useEffect(() => {
    if (!mountPoint) return;

    mountPoint.appendChild(wrapperElement);
    return () => {
      mountPoint.removeChild(wrapperElement);
    };
  }, [id, mountPoint, wrapperElement]);

  return createPortal(children, wrapperElement);
};

export default Portal;
