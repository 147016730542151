import api from '@pray/shared/utils/api';

export async function getRssFeedById({ artistId, rssFeedId, offset = 0, limit = 25 }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!rssFeedId) throw Error('RSS Feed ID is required');

  const params = {
    limit,
  };

  if (offset) {
    params.offset = offset;
  }

  const url = `/web/studio/artists/${artistId}/rss-feeds/${rssFeedId}`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
