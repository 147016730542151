import logger from '../logger';

export async function encrypt(publicKey: CryptoKey, data: BufferSource) {
  if (typeof window === 'undefined') return null;

  const cipher = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    publicKey,
    data
  );

  return cipher;
}

export async function importKey(publicKey: BufferSource) {
  if (typeof window === 'undefined') return null;

  try {
    const rsaPublicKeyCryptoKey = await window.crypto.subtle.importKey(
      'spki',
      publicKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt']
    );

    return rsaPublicKeyCryptoKey;
  } catch (error) {
    logger.error('Error importing RSA public key', error);
    return null;
  }
}
