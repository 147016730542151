import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation, useMatch, useParams } from 'react-router-dom';

import BookDetails from './BookDetails';
import BookDetailsLoading from './BookDetailsLoading';
import { useBookDetails } from './useBookDetails';
import useSideBar from '../../../components/SideBar/useSideBar';
import { useActiveTabStore } from '../../ContentTab/ContentPage/ContentPage';
import BookSidebar from '../components/BookSidebar';
import { LIBRARY_SIDEBAR_TABS } from '../constants';
import BookTranscriptPage from './BookTranscript/BookTranscriptPage';

import MainLayout from '@/components/layouts/MainLayout';

export default function BookDetailsPage() {
  const location = useLocation();
  const routeMatch = useMatch('/leaders/:artistId/library/:bookId/*');
  const { bookId } = useParams();
  const {
    book,
    audiobookGenerationStatus,
    audiobookGenerationUpdatedAt,
    isLoading,
    form,
    isSaving,
    textExtractionStatus,
    chapters,
    handleSave,
    handleDelete,
    handleDownloadAudio,
  } = useBookDetails({ bookId });

  const activeTabStore = useActiveTabStore();

  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  useEffect(() => {
    setRenderDetails(() => (
      <BookSidebar bookId={bookId} book={book} isLoading={isLoading} textExtractionStatus={textExtractionStatus} />
    ));
  }, [book, activeTabStore.activeTab, textExtractionStatus, location]);

  useEffect(() => {
    if (routeMatch) {
      const activeTab = routeMatch.params['*'] || LIBRARY_SIDEBAR_TABS.DETAILS;
      activeTabStore.setActiveTab(activeTab);
    }
  }, [routeMatch]);

  return (
    <MainLayout data-viewname="PRAY Studio - Library Tab">
      {(isLoading || !book) && <BookDetailsLoading />}
      {!isLoading && book && activeTabStore.activeTab === LIBRARY_SIDEBAR_TABS.DETAILS && (
        <FormProvider {...form}>
          <BookDetails
            book={book}
            chapters={chapters}
            audiobookGenerationStatus={audiobookGenerationStatus}
            audiobookGenerationUpdatedAt={audiobookGenerationUpdatedAt}
            isSaving={isSaving}
            textExtractionStatus={textExtractionStatus}
            handleSave={handleSave}
            handleDelete={handleDelete}
            onDownloadAudio={handleDownloadAudio}
          />
        </FormProvider>
      )}
      {!isLoading && book && activeTabStore.activeTab === LIBRARY_SIDEBAR_TABS.TRANSCRIPT && (
        <BookTranscriptPage
          textExtractionStatus={textExtractionStatus}
          audiobookGenerationStatus={audiobookGenerationStatus}
          audiobookGenerationUpdatedAt={audiobookGenerationUpdatedAt}
          lastSavedAt={book.lastSavedAt}
          bookId={bookId}
          onDownloadAudio={handleDownloadAudio}
        />
      )}
    </MainLayout>
  );
}
