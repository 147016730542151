export function appendDateToFileName(fileName: string) {
  const date = Date.now();
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
    return `${fileName}_${date}`;
  }

  const name = fileName.slice(0, dotIndex);
  const extension = fileName.slice(dotIndex);

  return `${name}_${date}${extension}`;
}
