import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';
import { cn } from '@pray/shared/utils/styles';

import RichTextEditor from 'components/pages/StudioPage/components/RichTextEditor/RichTextEditor';
import { Refresh, Trash } from 'images/icons';

import { CourseEpisode } from './types';

interface EpisodesContentProps {
  episodes: CourseEpisode[];
  selectedEpisodeId?: string;
  isLoading?: boolean;
  isCreatingPending?: boolean;
  isDeletingPending?: boolean;
  isRegenerating?: boolean;
  onContentChange: (episodeId: string, content: string) => void;
  onDeleteEpisode: (episodeId: string) => void;
  onRegenerateContent: (episodeId: string) => void;
}

const SkeletonContent = () => (
  <div className="prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto w-3/5 space-y-1 pt-4">
    <Loading isLight isLoading height={40} width="45%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="90%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="90%" borderRadius="4px" />
    <Loading isLight isLoading height={24} width="60%" borderRadius="4px" />
  </div>
);

export default function EpisodesContent({
  episodes,
  selectedEpisodeId,
  isLoading = false,
  isCreatingPending = false,
  isDeletingPending = false,
  isRegenerating = false,
  onContentChange,
  onDeleteEpisode,
  onRegenerateContent,
}: EpisodesContentProps) {
  const selectedEpisode = episodes.find((episode) => episode.id === selectedEpisodeId);

  const handleContentChange = (content: string) => {
    if (!selectedEpisodeId) return;
    onContentChange(selectedEpisodeId, content);
  };

  const customButtons = (
    <div className="flex items-center gap-3">
      <Button
        title="Delete Episode"
        className="flex cursor-pointer items-center justify-center rounded border-none bg-transparent p-0.5"
        onClick={() => selectedEpisodeId && onDeleteEpisode(selectedEpisodeId)}
      >
        <Trash className="size-6" />
      </Button>

      <Button
        title="Regenerate Content"
        disabled={isRegenerating}
        className="flex size-6 cursor-pointer items-center justify-center rounded border-[#B097FC] bg-[#D2C7F9] p-0.5"
        onClick={() => selectedEpisodeId && onRegenerateContent(selectedEpisodeId)}
      >
        <Refresh className={cn('size-4', isRegenerating && 'animate-spin')} />
      </Button>
    </div>
  );

  if (isLoading || isCreatingPending || isDeletingPending) {
    return <SkeletonContent />;
  }

  if (!selectedEpisode) {
    return (
      <div className="flex h-full">
        <Text variant={typography.body_md}>Select an episode to edit its content or create a new episode.</Text>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <RichTextEditor
        content={selectedEpisode.content || ''}
        customButtons={customButtons}
        isLoading={isRegenerating}
        className="min-h-[400px] w-full [&>div]:outline-none [&_h1]:my-4 [&_h1]:text-2xl [&_h1]:font-bold [&_h2]:my-4 [&_h2]:text-xl [&_h2]:font-bold [&_h3]:my-4 [&_h3]:text-lg [&_h3]:font-bold [&_p]:my-4"
        onChange={handleContentChange}
      />
    </div>
  );
}
