import { useEffect, useState } from 'react';

import {
  defaultAmountTier,
  defaultInterval,
  defaultIntervalCount,
  defaultType,
} from '@pray/shared/components/pages/DonationPage/constants';
import DonationAmountPage from '@pray/shared/components/pages/DonationPage/DonationAmount/DonationAmountPage';
import { DarkTheme } from '@pray/shared/components/ui/Theme';
import useEventListener from '@pray/shared/hooks/useEventListener';
import useForm from '@pray/shared/hooks/useForm';

import { STUDIO_MESSAGE_SOURCE } from 'constants.js';

export default function DonationPreviewPage() {
  const [data, setData] = useState({
    name: '',
    description: '',
    imageUrl: '',
    imageBase64: null,
    isDefault: false,
    defaultAmountMonthly: '1999',
    defaultAmountYearly: '19999',
    amountMonthly2: '999',
    amountYearly2: '9999',
  });

  const previewFund = {
    default: data.isDefault,
    name: data.name || '[Fund Name]',
    description: data.description || '[Fund Description]',
    image_url: data.imageBase64 || data.imageUrl,
    default_amount_monthly: data.defaultAmountMonthly,
    default_amount_yearly: data.defaultAmountYearly,
    amount_monthly_2: data.amountMonthly2,
    amount_yearly_2: data.amountYearly2,
  };

  const previewForm = useForm({
    amount: '',
    amountTier: defaultAmountTier.value,
    interval: defaultInterval,
    intervalCount: defaultIntervalCount,
    type: defaultType,
  });

  function handleMessage(event) {
    if (event.data.source === STUDIO_MESSAGE_SOURCE) {
      setData(event.data);
    }
  }

  useEventListener('message', handleMessage);

  useEffect(() => {
    window.parent.postMessage({ source: STUDIO_MESSAGE_SOURCE, type: 'get-data' }, '*');
  }, []);

  return (
    <DarkTheme>
      <DonationAmountPage isPreview form={previewForm} fund={previewFund} />
    </DarkTheme>
  );
}
