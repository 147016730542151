import api from '@pray/shared/utils/api';

export async function createTag({ name, description }) {
  if (!name) throw Error('Tag name is required');
  if (!description) throw Error('Tag description is required');

  const data = {
    name,
    description,
  };

  const url = `/web/studio/tags`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
