import { useEffect } from 'react';

export default function useEventListener(eventName, eventHandler) {
  useEffect(() => {
    window.addEventListener(eventName, eventHandler);
    return () => {
      window.removeEventListener(eventName, eventHandler);
    };
  }, []);
}
