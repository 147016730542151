import React from 'react';

import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import { formatCurrency } from '@pray/shared/utils';

import { TableSkeleton } from './TableSkeleton';
import { calculateEstimatedLtv } from './utils';

export function OrganizationList({ data = [], hasNextPage, fetchNextPage, isFetchingNextPage }) {
  return (
    <div className="relative overflow-y-visible">
      <Table>
        <Thead>
          <Tr className="sticky top-0 bg-white">
            <Th field="name">Organization Name</Th>
            <Th field="subscriberCount">Total Subscribers</Th>
            <Th field="averageDonation">Average Donation Amount</Th>
            <Th field="totalRevenue">Total Revenue Collected</Th>
            <Th field="estimatedLifetime">Estimated Lifetime Revenue</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((org) => {
            const averageDonationAmount = (org.averageDonationAmount || 0) / 100;
            const estimatedLtv = calculateEstimatedLtv(org.subscriberCount || 0, averageDonationAmount);

            return (
              <Tr key={org.id}>
                <Td>{org.name}</Td>
                <Td>{org.subscriberCount?.toLocaleString() || 0}</Td>
                <Td>{formatCurrency(org.averageDonationAmount || 0)}</Td>
                <Td>{formatCurrency(org.totalRevenue || 0)}</Td>
                <Td>{formatCurrency(Math.floor(estimatedLtv * 100))}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {hasNextPage && <InfiniteScroll fetchData={fetchNextPage} threshold={100} />}
      {isFetchingNextPage && <TableSkeleton type="organizations" rows={3} />}
    </div>
  );
}
