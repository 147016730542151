import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

export default function useSaveDailySeries(artistId, dailySeriesId) {
  const queryClient = useQueryClient();

  async function saveDailySeries({ title, description, handle, imageUrl, imageFile }) {
    // If there is any image file, upload it to S3

    if (imageFile) {
      try {
        const response = await s3Service.signAndUpload({
          file: imageFile,
          type: 'dailySeries',
          signParams: {
            daily_series_id: dailySeriesId,
          },
        });
        imageUrl = response.url;
      } catch (err) {
        throw new Error('Failed to upload image file', {
          cause: { image_url: err },
        });
      }
    }

    await studioService.dailySeries.updateDailySeries({
      artistId,
      dailySeriesId,
      title,
      description,
      backgroundImageUrl: imageUrl,
      handle,
    });
  }

  const mutation = useMutation({
    mutationFn: saveDailySeries,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistDailySeries(artistId, dailySeriesId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    saveDailySeries: mutateAsync,
    ...rest,
  };
}
