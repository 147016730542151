import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';
import useSideBar from 'components/pages/StudioPage/components/SideBar/useSideBar';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useEmailCampaign from 'hooks/emailCampaigns/useEmailCampaign';
import { EmailBounces, EmailClicks, EmailEnvelope, EmailOpen, EmailSpam, EmailUnsubscribe } from 'images/icons';

import { EmailAnalyticsItem } from './EmailAnalyticsItem';
import { EmailDetailsItems } from './EmailDetailsItems';

export default function EmailAnalyticsPage() {
  const { selectedArtist } = useStudioContext();
  const { id: emailId } = useParams();
  const location = useLocation();
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const emailResponse = useEmailCampaign(selectedArtist?.id, emailId);
  const email = useMemo(() => emailMapper(emailResponse.data), [emailResponse.data]);

  useEffect(() => {
    setRenderDetails(() => <EmailDetailsItems email={email} activeItem="analytics" />);
  }, [location, email]);

  function emailMapper(email) {
    const locale = 'en-US';
    const decimalOptions = { minimumFractionDigits: 1, maximumFractionDigits: 1 };
    const openPercentage = email?.sendCount
      ? ((email.openCount / email.sendCount) * 100).toLocaleString(locale, decimalOptions)
      : 0;
    const clickPercentage = email?.sendCount
      ? ((email.clickCount / email.sendCount) * 100).toLocaleString(locale, decimalOptions)
      : 0;

    return {
      ...email,
      openPercentage,
      clickPercentage,
    };
  }

  return (
    <MainLayout>
      <TabPage title="Analytics" data-viewname={VIEW_EVENT_NAMES.EMAILS.ANALYTICS}>
        <div className="grid max-w-7xl grid-cols-1 gap-5 pt-6 lg:grid-cols-3">
          <EmailAnalyticsItem icon={<EmailEnvelope />} title="Sends" value={email?.sendCount} />
          <EmailAnalyticsItem
            icon={<EmailOpen />}
            title="Opens"
            value={email?.openCount}
            description="unique"
            subtitle="Open rate"
            variation={email?.openPercentage}
          />
          <EmailAnalyticsItem
            icon={<EmailClicks />}
            title="Clicks"
            value={email?.clickCount}
            description="unique"
            subtitle="CTR"
            variation={email?.clickPercentage}
          />
          <EmailAnalyticsItem icon={<EmailUnsubscribe />} title="Unsubscribes" value={email?.unsubscribeCount} />
          <EmailAnalyticsItem icon={<EmailSpam />} title="Spam Report" value={email?.spamReportCount} />
          <EmailAnalyticsItem icon={<EmailBounces />} title="Bounces" value={email?.bounceCount} />
        </div>
      </TabPage>
    </MainLayout>
  );
}
