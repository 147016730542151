import React from 'react';

import { buttons } from '@pray/shared/components/foundations';

import { EmailDialogModal } from './EmailDialogModal';

export default function UpdateEmailAutomationWarningModal({ isLoading = false, onCancel, onSend }) {
  const dialogButtons = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: onCancel, disabled: isLoading },
    { label: 'Update', variant: buttons.variant.primary, action: onSend, disabled: isLoading, loading: isLoading },
  ];

  return (
    <EmailDialogModal title="Updating an active email" buttons={dialogButtons} onClose={onCancel} isLoading={isLoading}>
      <p>Changes made to an active automation will apply to users who have not received this email.</p>
    </EmailDialogModal>
  );
}
