import api from '@pray/shared/utils/api';

export async function createEmailTemplate({
  artistId = undefined,
  name = undefined,
  status = undefined,
  scheduledAt = undefined,
  subjectText = undefined,
  previewText = undefined,
  previewImgUrl = undefined,
  rawHtml = undefined,
  rawCss = undefined,
  compiledHtmlCss = undefined,
}) {
  if (!artistId) throw new Error('Artist ID is required');

  const data = {
    name,
    status,
    scheduled_at: scheduledAt,
    subject_text: subjectText,
    preview_text: previewText,
    preview_img_url: previewImgUrl,
    raw_html: rawHtml,
    raw_css: rawCss,
    compiled_html_css: compiledHtmlCss,
  };

  const url = `/web/studio/artists/${artistId}/email-templates`;
  const response = await api.executePostRequest(url, data);

  return response.data;
}
