import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { BankPlaceholder } from 'images/icons';

export function PayoutBankDetails({ bank, showLogo = false }) {
  return (
    <div className="flex flex-row items-center gap-3">
      {showLogo && (
        <div className="flex items-center justify-center rounded-full bg-slate-100 p-3">
          <BankPlaceholder />
        </div>
      )}
      <div className="flex flex-col">
        <Text className="text-base font-medium">{bank?.name}</Text>
        <div className="flex flex-row gap-1">
          <Text color={colors.text_tertiary} className="text-sm font-medium">
            ••••• {bank?.lastFourDigits}
          </Text>
          <Text color={colors.text_tertiary} className="text-sm font-medium uppercase">
            ({bank?.currency})
          </Text>
        </div>
      </div>
    </div>
  );
}
