import api from '@pray/shared/utils/api';

export const getSocialClip = async ({ artistId = null, contentId = null, socialClipId = null } = {}) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentId) throw Error('Content ID is required');
  if (!socialClipId) throw Error('Social Clip ID is required');

  const url = `/web/studio/artists/${artistId}/content/${contentId}/social-clips/${socialClipId}`;
  const { data } = await api.executeGetRequest(url);

  return data;
};
