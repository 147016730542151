import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

type DeleteChapterParams = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

export function useDeleteChapter() {
  async function deleteChapter({ artistId, bookId, chapterId }: DeleteChapterParams): Promise<void> {
    await studioService.chapters.deleteChapter({
      artistId,
      bookId,
      chapterId,
    });
  }

  const { mutateAsync } = useMutation({
    mutationFn: deleteChapter,
  });

  return { deleteChapter: mutateAsync };
}
