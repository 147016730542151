import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { mapSocialClips } from '@pray/shared/services/studioService/socialClips';

import {
  pollInterval,
  socialClipGenerationStatus,
} from '@/components/pages/StudioPage/tabs/ContentTab/SocialClipsPage/constants';

export function useSocialClips(artistId, contentId) {
  async function fetchSocialClips() {
    const response = await studioService.socialClips.getSocialClips({
      artistId,
      contentId,
    });

    return mapSocialClips(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!contentId,
    queryKey: queryKeys.artistContentSocialClips(artistId, contentId),
    queryFn: fetchSocialClips,
    refetchInterval: (data) => (data.state?.data?.status === socialClipGenerationStatus.started ? pollInterval : null),
  });

  return query;
}
