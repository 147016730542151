import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import queryKeys from '@pray/shared/queryKeys';

import useGetAnnouncementFromWorkflowAI from 'hooks/announcements/useGetAnnouncementFromWorkflowAI';

export function useGetContentAnnouncement({ workflowAIDetails, artist, content, locale }) {
  const queryClient = useQueryClient();
  const previousAnnouncementIdRef = useRef(null); // useRef to store previous announcement_id
  const { data, isLoading, refetch } = useGetAnnouncementFromWorkflowAI(artist.id, content.id, locale);

  // Effect to refetch data when announcement_id changes from null to a value
  useEffect(() => {
    const previousAnnouncementId = previousAnnouncementIdRef.current;
    const currentAnnouncementId = workflowAIDetails?.announcementId;

    if (previousAnnouncementId === null && currentAnnouncementId !== null) {
      const announcementQueryKey = queryKeys.artistAnnouncementWorkflowAI(artist.id, content.id, locale);
      queryClient.invalidateQueries({ queryKey: announcementQueryKey }).then(() => refetch({ cancelRefetch: true }));
    }

    // Update the ref to the current value
    previousAnnouncementIdRef.current = currentAnnouncementId;
  }, [workflowAIDetails?.announcementId]);

  return {
    data,
    isLoading,
  };
}
