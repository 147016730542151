import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';
import studioService from '@pray/shared/services/studioService';
import { formatDateUsingShorterMonth } from '@pray/shared/utils/datetime';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useGetEmailCampaignFromWorkflowAI from 'hooks/emailCampaigns/useGetEmailCampaignFromWorkflowAI';
import useAppRoutes from 'hooks/useAppRoutes';

import TabPage from '../../../../components/TabPage/TabPage';
import { audience } from '../../../EmailsTab/constants';
import { DetailItem } from '../../../EmailsTab/EmailOverviewPage/DetailItem';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';

function emailMapper(email) {
  if (!email) return null;

  const scheduledDate = email?.scheduledAt;
  const formattedDate = formatDateUsingShorterMonth(scheduledDate, true);

  return { ...email, formattedDate };
}

export default function ContentEmailWrapper({
  workflowAIDetails,
  onWorkflowAIStarted,
  artist,
  content,
  locale,
  setContent,
}) {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();

  const emailResponse = useGetEmailCampaignFromWorkflowAI(artist.id, content.id, locale);
  const email = useMemo(() => emailMapper(emailResponse.data), [emailResponse.data]);

  const renderTopRightButtons = () => {
    if (!content) return null;

    return (
      <div className="sm-hide md-show flex gap-4">
        <Button
          variant={buttons.variant.tertiary}
          disabled={emailResponse.isLoading || !email}
          onClick={() => navigate(appRoutes.artistEmailCampaignAnalyticsOverview(email.id))}
        >
          View Analytics
        </Button>

        <Button
          variant={buttons.variant.primary}
          disabled={emailResponse.isLoading || !email}
          onClick={() => navigate(appRoutes.artistEmailCampaignEdit(email.id))}
        >
          Edit Email
        </Button>
      </div>
    );
  };

  return (
    <TabPage
      title="Email"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_EMAIL}
      topRightButtons={renderTopRightButtons()}
      titleInfo={
        <Popover
          title="Email"
          description="Here you can view the email campaign sample which Pray Workflow AI created specially for you based in your content"
        >
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      <ContentEmail
        workflowAIDetails={workflowAIDetails}
        onWorkflowAIStarted={onWorkflowAIStarted}
        artist={artist}
        content={content}
        locale={locale}
        setContent={setContent}
        emailCampaign={email}
      />
    </TabPage>
  );
}

function ContentEmail({ onWorkflowAIStarted, artist, content, locale, setContent, emailCampaign, workflowAIDetails }) {
  const [isProcessing, setIsProcessing] = useState(false);

  async function handleWorkflowAIButton() {
    const { id: artistId } = artist;
    const { id: contentId } = content;

    setIsProcessing(true);
    setContent((prevData) => {
      return {
        ...prevData,
        is_workflow_ai_processing: true,
      };
    });

    await studioService.workflowAI.triggerWorkflowAIForContent({
      artistId,
      contentId,
      locale,
    });

    onWorkflowAIStarted();
    setIsProcessing(false);
  }

  /**
   * Loading and empty states here
   */
  if (!workflowAIDetails?.emailCampaignId) {
    return (
      <WorkflowAIEmptyState
        isProcessing={isProcessing}
        content={content}
        handleWorkflowAIButton={handleWorkflowAIButton}
        title="Email"
        description="Effortlessly create personalized messages for your audience. Promote content, share updates, or inspire your
            community."
      />
    );
  }

  return (
    <div className="mt-8 flex gap-16">
      <div className="flex h-[calc(100vh-275px)] w-[732px] items-center rounded-xl border bg-[#F2F3F4]">
        <iframe
          id="preview-iframe"
          srcDoc={emailCampaign?.compiledHtmlCss}
          title="Email Preview"
          className="no-scrollbar size-full overflow-x-hidden border-none"
        />
      </div>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_xl}>Details</Text>
        <DetailItem title="Subject line" value={emailCampaign?.subjectText} />
        <DetailItem title="Preview Text" value={emailCampaign?.previewText} />
        <DetailItem
          title="Publish Date"
          value={emailCampaign?.formattedDate ? `Scheduled on ${emailCampaign?.formattedDate}` : '-'}
        />
        <DetailItem title="Type" value={emailCampaign?.automationType ? 'Email Automation' : 'Email'} />
        <DetailItem title="Segment" value={audience} />
      </div>
    </div>
  );
}
