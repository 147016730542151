import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { leadListMetadataMapper } from '@pray/shared/services/studioService/leadLists/mappers';
import { LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';

type LeadListMetadataOptions = {
  enabled?: boolean;
  refetchInterval?: number | false;
  enableAutoRefetch?: boolean;
};

type UseLeadListMetadataProps = {
  artistId: string;
  listId: string;
  options?: LeadListMetadataOptions;
};

const defaultOptions: LeadListMetadataOptions = {
  enabled: true,
  refetchInterval: 30000,
  enableAutoRefetch: false,
};

export default function useLeadListMetadata({ artistId, listId, options = defaultOptions }: UseLeadListMetadataProps) {
  const { enabled, refetchInterval, enableAutoRefetch } = { ...defaultOptions, ...options };

  const fetchLeadListMetadata = async () => {
    const response = await studioService.leadLists.getLeadListMetadata({
      artistId,
      listId,
    });

    return leadListMetadataMapper(response);
  };

  const query = useQuery({
    queryKey: queryKeys.leadListMetadata(artistId, listId),
    queryFn: fetchLeadListMetadata,
    enabled: Boolean(artistId && listId && enabled),
    refetchInterval: (query) => {
      if (!enableAutoRefetch) return false;

      if (query.state.data?.status === LeadListStatus.PROCESSING) {
        return refetchInterval;
      }

      return false;
    },
  });

  return query;
}
