import api from '@pray/shared/utils/api';

type GetLeadListCsvDownloadProps = {
  artistId: string;
  listId: string;
};

export async function getLeadListCsvDownload({ artistId, listId }: GetLeadListCsvDownloadProps) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!listId) throw new Error('List ID is required');

  const options = {
    responseType: 'blob' as const,
  };

  const url = `/web/studio/artists/${artistId}/leads-lists/${listId}/leads/download`;
  const response = await api.executeGetRequest<Blob>(url, {}, options);

  return response.data;
}
