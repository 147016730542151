const imageSizeVariant = ['small', 'medium', 'large'];

/**
 * Generate optimized image url
 *
 * @param {string} imageUrl
 * @param {'small' | 'medium' | 'large' | undefined} size
 *
 * @return {string}
 */
export function getOptimizedUrl(imageUrl, size) {
  const suffix = imageSizeVariant.includes(size) ? `_${size}` : '';
  const optimizationSuffix = `_optimized${suffix}`;

  if (!imageUrl || imageUrl.includes(optimizationSuffix)) return imageUrl;

  const pathArray = imageUrl.split('.');
  pathArray[pathArray.length - 2] += optimizationSuffix;

  const optimizedPath = pathArray.join('.');
  return optimizedPath;
}

/**
 * Generate optimized background url
 *
 * @param {string} imageUrl
 * @param {'small' | 'medium' | 'large' | undefined} size
 *
 * @return {string}
 */
export function getOptimizedBackgroundUrl(imageUrl, size) {
  const optimizedImageUrl = getOptimizedUrl(imageUrl, size);

  return `url(${optimizedImageUrl}), url(${imageUrl})`;
}
