import React, { useEffect, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';

import WebHealthCheckPage from '@pray/shared/components/WebHealthCheckPage/WebHealthCheckPage';
import eventTracking from '@pray/shared/utils/eventTracking';
import { navigator } from '@pray/shared/utils/navigation';
import storage from '@pray/shared/utils/storage';

import PageLayout from 'components/layouts/PageLayout';
import StudioPage from 'components/pages/StudioPage/StudioPage';
import StudioPreviewPage from 'components/pages/StudioPreviewPage/StudioPreviewPage';
import useAppRoutes from 'hooks/useAppRoutes';
import IndexPage from 'pages';
import RevenueExternalPageModal from 'pages/RevenueExternalPageModal';

import { layout } from './components/layouts/constants';
import { ExternalRedirect } from './components/router/ExternalRedirect';
import { EXTERNAL_REDIRECTS } from './constants';

export default function AppRoutes() {
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathRef = useRef<string | null>(null);
  const previousPathRef = useRef<string | null>(null);

  function trackLocationChange() {
    if (currentPathRef.current !== location.pathname) {
      if (currentPathRef.current !== null) {
        previousPathRef.current = currentPathRef.current;
      }

      currentPathRef.current = location.pathname;

      eventTracking.trackEventRoute({
        name: currentPathRef.current,
        source: previousPathRef.current,
      });

      storage.trackRouteChanged(location.pathname);

      window.scrollTo(0, 0);
      document.getElementById(layout.main)?.scrollTo(0, 0);
    }
  }

  navigator.initialize({ navigate, Link });

  useEffect(() => {
    trackLocationChange();
  }, [location]);

  return (
    <PageLayout>
      <Routes>
        {EXTERNAL_REDIRECTS.map(({ path, to }) => (
          <Route key={path} path={path} element={<ExternalRedirect to={to} />} />
        ))}
        <Route path={appRoutes.index()} element={<IndexPage />} />
        <Route path={appRoutes.studio()} element={<StudioPage />} />
        <Route path={appRoutes.healthCheck()} element={<WebHealthCheckPage />} />
        <Route path={appRoutes.preview()} element={<StudioPreviewPage />} />
        <Route path={appRoutes.revenueExternalPage()} element={<RevenueExternalPageModal />} />
      </Routes>
    </PageLayout>
  );
}
