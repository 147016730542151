import React from 'react';

function PlayIcon({ outerFill = 'white', outerFillOpacity = '0.2', innerFill = 'white', ...props }) {
  return (
    <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.5 32C0.5 14.3269 14.8269 0 32.5 0C50.1731 0 64.5 14.3269 64.5 32C64.5 49.6731 50.1731 64 32.5 64C14.8269 64 0.5 49.6731 0.5 32Z"
        fill={outerFill}
        fillOpacity={outerFillOpacity}
      />
      <path
        d="M27.3446 22.8075L41.3403 31.1323C41.815 31.4146 41.9709 32.0283 41.6886 32.5029C41.6035 32.646 41.4841 32.7655 41.3411 32.8507L27.3453 41.1923C26.8709 41.4751 26.2571 41.3197 25.9744 40.8453C25.8821 40.6904 25.8334 40.5135 25.8334 40.3333V23.667C25.8334 23.1147 26.2811 22.667 26.8334 22.667C27.0133 22.667 27.1899 22.7155 27.3446 22.8075Z"
        fill={innerFill}
      />
    </svg>
  );
}

export default PlayIcon;
