import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from 'firebase/auth';
import React, { useState } from 'react';

import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import logger from '@pray/shared/utils/logger';

import { auth } from '../../services/firebaseService';
import { authenticateSSO } from '../../utils/auth';

import facebook from './assets/facebook.svg';
import google from './assets/google.svg';

import './SocialAuth.scss';

export default function SocialAuth({
  mode = 'full',
  disabled = false,
  onAuthStarted = null,
  onAuthSuccess = null,
  onAuthError = null,
  hideTopLabel = false,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');

  const isCompactMode = mode === 'compact';

  async function handleSocialAuthentication(user) {
    try {
      onAuthStarted?.();
      setIsProcessing(true);
      const response = await authenticateSSO(user.accessToken);
      onAuthSuccess?.(response);
    } catch (err) {
      logger.error('Failed to authenticate with SSO', err);
      setError(err.message);
      onAuthError?.(err);
    } finally {
      setIsProcessing(false);
    }
  }

  function handleSocialAuthError(err) {
    setIsProcessing(false);

    if (err.code !== 'auth/popup-closed-by-user') {
      throw err;
    }
  }

  async function handleAppleSignIn() {
    try {
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      const { user } = await signInWithPopup(auth, provider);
      await handleSocialAuthentication(user);
    } catch (err) {
      handleSocialAuthError(err);
    }
  }

  async function handleGoogleSignIn() {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      await handleSocialAuthentication(user);
    } catch (err) {
      handleSocialAuthError(err);
    }
  }

  async function handleFacebookSignIn() {
    try {
      const provider = new FacebookAuthProvider();
      const { user } = await signInWithPopup(auth, provider);
      await handleSocialAuthentication(user);
    } catch (err) {
      handleSocialAuthError(err);
    }
  }

  const isAuthDisabled = disabled || isProcessing;

  return (
    <div className={`social-auth__container ${mode} ${hideTopLabel && 'hideTopLabel'}`}>
      {!hideTopLabel && (
        <div className="social-auth__top-label">
          <Text>OR {isCompactMode ? 'CONTINUE WITH' : ''}</Text>
        </div>
      )}
      <div className="social-auth__buttons">
        <Button disabled={isAuthDisabled} data-tapeventname="Continue with Apple" onClick={handleAppleSignIn}>
          <AppleLogo />
          {!isCompactMode && 'Continue with Apple'}
        </Button>

        <Button disabled={isAuthDisabled} data-tapeventname="Continue with Google" onClick={handleGoogleSignIn}>
          <img src={google} alt="google logo" />
          {!isCompactMode && 'Continue with Google'}
        </Button>

        <Button disabled={isAuthDisabled} data-tapeventname="Continue with Facebook" onClick={handleFacebookSignIn}>
          <img src={facebook} alt="facebook logo" />
          {!isCompactMode && 'Continue with Facebook'}
        </Button>
      </div>

      {error && <Text className="social-auth__error">{error}</Text>}
    </div>
  );
}

const AppleLogo = ({ color = 'white' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6593 19.7751C15.7589 20.7227 14.7655 20.575 13.8185 20.1282C12.8118 19.6725 11.8915 19.6436 10.8283 20.1282C9.50426 20.7479 8.80155 20.5677 8.00415 19.7751C3.50214 14.7493 4.16665 7.0935 9.28331 6.80528C10.5243 6.87733 11.3931 7.54924 12.1241 7.60508C13.2105 7.3655 14.2505 6.67918 15.4133 6.76925C16.8105 6.89174 17.8554 7.4898 18.5531 8.56522C15.6791 10.4386 16.3603 14.5458 19 15.6986C18.4717 17.2028 17.7939 18.6889 16.6576 19.7878L16.6593 19.7751ZM12.0244 6.75123C11.8898 4.51754 13.5594 2.68014 15.4798 2.5C15.7439 5.07596 13.3202 7.00343 12.0244 6.75123Z"
      fill={color}
    />
  </svg>
);
