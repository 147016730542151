import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { CONTENT_VISIBILITY, LOCALES } from '@pray/shared/constants';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import FooterPortal from 'components/layouts/FooterPortal';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';
import usePlaylistDetails from 'hooks/usePlaylistDetails';

import RssFeedDetailsEditForm from './RssFeedDetailsEditForm/RssFeedDetailsEditForm';
import ButtonCluster from '../../../components/ButtonCluster';
import TabPage from '../../../components/TabPage/TabPage';
import DeletePlaylistModal from '../../PlaylistsTab/DeletePlaylistModal/DeletePlaylistModal';
import PlaylistPreview from '../../PlaylistsTab/PlaylistPreview/PlaylistPreview';

export default function RssFeedDetailsForm({ form = null, playlist = {}, setPlaylist = null, setTranslations = null }) {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const { id: contentSeriesId } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const locale = form.values.locale || LOCALES.DEFAULT;
  const { isLoading, fetchContentSeriesData, ...playlistDetails } = usePlaylistDetails({
    artistId,
    contentSeriesId,
    locale,
  });

  const isPrimary = form.values.locale === LOCALES.DEFAULT;

  const savePrimaryPlaylist = async () => {
    const errors = {};
    const { title, description, contentOrder } = form.values;
    const isVisible = form.values.visibility === CONTENT_VISIBILITY.PUBLIC;
    const isCreatePlaylist = !form.values.id;

    if (form.values.title.trim().length < 2) {
      errors.title = 'Invalid title';
    }

    if (form.values.description.trim().length < 2) {
      errors.description = 'Invalid description';
    }

    if (!form.values.image_url && !form.values.image_file) {
      errors.image_url = 'Invalid image';
    }

    form.setErrors(errors);

    if (Object.keys(errors).length) return;

    let imageUrl;

    // upload playlist image
    if (form.values.image_file) {
      try {
        const response = await s3Service.signAndUpload({
          file: form.values.image_file,
          type: 'contentSeries',
          signParams: {
            content_series_id: form.values.id || null,
          },
        });
        imageUrl = response.url;
      } catch (err) {
        form.setError('image_url', err);
        return;
      }
    }

    // create/update playlist
    if (isCreatePlaylist) {
      const playlist = await studioService.contentSeries.createContentSeries({
        artistId,
        title,
        description,
        imageUrl,
        isVisible,
        contentOrder,
      });

      toast.show({ success: 'Series created successfully' });

      navigate(appRoutes.artistPlaylistDetailsForm(playlist.id));
    } else {
      await studioService.contentSeries.updateContentSeries({
        artistId,
        contentSeriesId: form.values.id,
        title,
        description,
        imageUrl,
        isVisible,
        contentOrder,
      });

      toast.show({ success: 'Series updated successfully' });
      form.setErrors({});
      await fetchContentSeriesData();
    }
  };

  const saveLocalizedPlaylist = async () => {
    const { id: contentSeriesId, locale, image_file: imageFile } = form.values;

    if (!imageFile) return;

    // upload thumbnail image
    const fileNameParts = imageFile.name.split('.');
    const extension = fileNameParts.pop();
    const fileName = `${fileNameParts.join('.')}_${locale}.${extension}`;

    let imageUrl;

    try {
      const response = await s3Service.signAndUpload({
        fileName,
        file: imageFile,
        type: 'contentSeries',
        signParams: {
          content_series_id: contentSeriesId || null,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      form.setError('image_url', err);
      return;
    }

    const updates = {
      artistId,
      contentSeriesId,
      locale,
      imageUrl,
    };

    await studioService.contentSeriesTranslation.updateLocalizedContentSeries(updates);
  };

  const handleSavePlaylist = async () => {
    try {
      setIsSaving(true);

      if (isPrimary) {
        await savePrimaryPlaylist();
      } else {
        await saveLocalizedPlaylist();
      }
    } catch (err) {
      logger.debug('Failed to save playlist', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeletePlaylist = async () => {
    await studioService.contentSeries.deleteContentSeries({
      artistId,
      contentSeriesId,
    });

    navigate(appRoutes.artistPlaylists(), { replace: true });
  };

  const handleOnChange = async () => {
    setHasChanges(true);
  };

  const renderActionButtons = () => {
    return (
      <div className="flex items-center">
        <ButtonCluster text={isSaving ? 'Saving' : 'Save'} onClick={handleSavePlaylist}>
          <DropdownMenu.Item
            className="w-48"
            title="Delete RSS Series"
            onClick={() => {
              setIsDeleteModalOpen(true);
            }}
          />
        </ButtonCluster>
      </div>
    );
  };

  const renderTopRightButtons = () => (
    <div>
      <div className="hidden md:block">{renderActionButtons()}</div>
    </div>
  );

  useEffect(() => {
    form.setValues({
      ...form.values,
      id: playlist.id || '',
      title: playlist.title || '',
      description: playlist.description || '',
      image_url: playlist.image_url || '',
      visibility: playlist.is_visible === false ? CONTENT_VISIBILITY.PRIVATE : CONTENT_VISIBILITY.PUBLIC,
      contentOrder: playlist.content_order || form.values.contentOrder,
    });
  }, [playlist]);

  useEffect(() => {
    if (playlistDetails.playlist) {
      setPlaylist((prev) => ({ ...prev, ...playlistDetails.playlist }));
    }
    if (playlistDetails.translations.length) {
      setTranslations(playlistDetails.translations);
    }
  }, [playlistDetails.playlist, playlistDetails.translations]);

  const renderRssFeedDetailsForm = () => {
    return (
      <>
        {!isLoading && (
          <>
            <RssFeedDetailsEditForm playlist={playlist} form={form} isPrimary={isPrimary} onChange={handleOnChange} />
            <PlaylistPreview playlist={playlist} />
          </>
        )}
      </>
    );
  };

  return (
    <TabPage
      title="RSS Series Details"
      data-viewname={VIEW_EVENT_NAMES.SERIES.DETAILS}
      topRightButtons={renderTopRightButtons()}
    >
      <div className="mt-4 flex flex-col gap-10 md:flex-row">{renderRssFeedDetailsForm()}</div>

      <FooterPortal>{renderActionButtons()}</FooterPortal>

      <DeletePlaylistModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeletePlaylist();
          setIsDeleteModalOpen(false);
        }}
      />
    </TabPage>
  );
}
