import api from '@pray/shared/utils/api';
import type { PaginatedListResponse } from '@pray/shared/utils/api/types/response';

import type { BookResponse } from './getBook';

type GetBooksProps = {
  artistId: string;
  lastItemIdentifier?: string;
};

export const getBooks = async ({ artistId, lastItemIdentifier }: GetBooksProps) => {
  if (!artistId) throw Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/books`;

  const response = await api.executeGetRequest<PaginatedListResponse<BookResponse>>(url, {
    limit: 10,
    last_item_identifier: lastItemIdentifier,
  });

  return response.data;
};
