import React, { useEffect, useState } from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';

import styles from './InviteMemberModal.module.scss';

export default function InviteMemberModal({ isOpen, artistId, onInviteSent, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    email: '',
  });

  useEffect(() => {
    if (!isOpen) {
      form.setValue('email', '');
      setIsLoading(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    const emails = form.values.email.split(',').map((email) => email.trim());

    await studioService.team.addTeamMembers({ artistId, emails });

    await onInviteSent();
  };

  return (
    <Modal customStyles={styles} isShowCloseButton={false} onClose={onClose}>
      <Text className={styles.title}>Invite team members</Text>
      <Text className="block">Add new users to manage your content.</Text>

      <form onSubmit={handleSubmit}>
        <div className="my-8">
          <TextInput
            autoFocus
            label="Email address"
            name="email"
            value={form.values.email}
            errorMessage={form.errors.email}
            onChange={form.handleChange}
          />
          <InputHelperText>Separate email addresses with a comma to invite multiple members</InputHelperText>
        </div>

        <Text variant={typography.h2}>Access level</Text>
        <Text className="mt-2">
          Team members will be added as <strong>Editor</strong> and will be able to edit, upload and manage your
          content.
        </Text>

        <div className={styles.buttons}>
          <Button disabled={isLoading} variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={isLoading} variant={buttons.variant.primary} onClick={handleSubmit}>
            {isLoading ? <Spinner /> : 'Send Invite'}
          </Button>
        </div>
      </form>
    </Modal>
  );
}
