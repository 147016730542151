import React from 'react';

export function Bookmark({ fill = 'white', ...props }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75032 5.50025C4.75024 3.98139 5.98146 2.75 7.50037 2.75H16.5C18.0188 2.75 19.25 3.98122 19.25 5.5V19.3788C19.25 20.9026 17.614 21.8667 16.281 21.1284L12.2423 18.8915C12.0915 18.808 11.9085 18.808 11.7578 18.8915L7.719 21.1284C6.38599 21.8666 4.74999 20.9026 4.75003 19.3788C4.75007 17.9371 4.75013 16.4955 4.7502 15.0539C4.75035 11.8694 4.7505 8.68481 4.75032 5.50025ZM7.50037 4.25C6.80997 4.25 6.25028 4.80972 6.25032 5.50016C6.2505 8.68481 6.25035 11.8695 6.2502 15.0541C6.25013 16.4957 6.25007 17.9372 6.25003 19.3788C6.25002 19.7597 6.65899 20.0008 6.99225 19.8162L11.031 17.5793C11.6339 17.2454 12.3661 17.2454 12.969 17.5793L17.0078 19.8162C17.341 20.0007 17.75 19.7597 17.75 19.3788V5.5C17.75 4.80964 17.1904 4.25 16.5 4.25H7.50037Z"
        fill={fill}
      />
    </svg>
  );
}
