import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import appData from '@pray/shared/utils/appData';
import auth from '@pray/shared/utils/auth';
import initialize from '@pray/shared/utils/initialize';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';

import AppRoutes from './app.routes';

export default function App() {
  const { authenticate } = useStudioContext();
  const [hasInitialized, setHasInitialized] = useState(false);

  async function initializeServices() {
    try {
      auth.customAuthentication = authenticate;

      await initialize.initialize();

      setHasInitialized(true);
      appData.setIsAppDataError(false);
    } catch (err) {
      logger.error('Failed to initialize app services', err);

      if (appData.isAppDataError()) {
        appData.setIsAppDataError(false);
        setHasInitialized(true);
        return;
      }

      await appData.clearAllAppData();

      appData.setIsAppDataError(true);
      window.location.reload();
    }
  }

  useEffect(() => {
    initializeServices();
  }, []);

  if (!hasInitialized) return null;

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}
