import eventTracking from '@pray/shared/utils/eventTracking';
import logger from '@pray/shared/utils/logger';

import { getClosestParentAttribute } from './eventUtils';

function triggerViewNameEvent(element) {
  const viewName = element.getAttribute('data-viewname');
  const viewEventAdded = element.getAttribute('data-vieweventadded');

  if (!viewName || viewEventAdded) return;

  const eventProps = getClosestParentAttribute(element, 'data-eventprops');
  const fbContentCategory = element.getAttribute('data-viewfbcontentcategory');
  element.setAttribute('data-vieweventadded', Date.now());

  eventTracking.trackEventView(viewName, fbContentCategory, eventProps);

  logger.debug('viewEventName:', viewName);
  if (fbContentCategory) logger.debug(' - viewContentCategory:', fbContentCategory);
  if (eventProps) logger.debug(' - viewEventProps:', eventProps);
}

export function addViewEventListener(element) {
  if (element.querySelectorAll) {
    triggerViewNameEvent(element);
    element.querySelectorAll('[data-viewname]').forEach(triggerViewNameEvent);
  }
}

export default {
  addViewEventListener,
};
