import appboy from '@braze/web-sdk';
import amplitude from 'amplitude-js';
import branch from 'branch-sdk';
import ReactFacebookPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

import { EVENTS, LOCAL_STORAGE, PLATFORM_TYPES } from '@pray/shared/constants';
import { formatDecimal } from '@pray/shared/utils';
import { isCookieBannerAccepted } from '@pray/shared/utils/gdpr';
import logger from '@pray/shared/utils/logger';
import { parseQueryString } from '@pray/shared/utils/parseFromQuery';
import storage from '@pray/shared/utils/storage';

const { NONE } = EVENTS.PRAY_EVENTS.VIEW_EVENT_NAMES.FAMILY_PLAN_FLOW.FAMILY_PLAN_DESCRIPTION;
const USD_CURRENCY = 'USD';

/**
 * Track Ad events
 *
 * See https://prayinc.atlassian.net/wiki/spaces/EN/pages/3310485505/Ad#AdEvent-Types---Mobile-and-Web
 *
 * @param {string} eventType Event type name
 * @param {object} adData Object containing ad data
 * @param {object} extraInfo Object containing adNetwork and metricsProperties
 */
const trackEventAd = (eventType, adData, extraInfo = {}) => {
  const eventName = EVENTS.PRAY_EVENTS.NAMES.AD;
  const eventData = {
    ad_event_type: eventType,
  };

  if (eventType === 'error') {
    eventData.ad_error_code = adData.errorCode;
    eventData.ad_error_reason = adData.errorMessage;
  } else {
    eventData.ad_id = adData.adId;
    eventData.ad_advertiser = adData.advertiserName;
    eventData.ad_media_type = adData.contentType?.split('/')?.[0]; // 'video/mp4' -> 'video', 'audio/mp3' -> 'audio
    eventData.ad_creative_source = adData.creativeAdId ? 'internal' : 'external';
    eventData.ad_creative_name = adData.title;
    eventData.ad_placement = adData.adPlacement;
    eventData.ad_placement_time = adData.adPlacementTime;
    eventData.ad_content_duration = adData.duration;
    eventData.ad_duration = adData.adDuration;
    eventData.ad_completion_rate = adData.adCompletionRate;
  }

  const { adNetwork, metricsProperties } = extraInfo;
  if (adNetwork) eventData.ad_server = adNetwork;
  if (metricsProperties) _addMetricsProperties(eventData, metricsProperties);

  _trackEvent({ eventName, eventData });
};

const trackEventCheckoutCompleted = (
  price,
  sku,
  productId,
  isSkuHasFreeTrial,
  isUserEligibleForTrial,
  familyPlanDescription = NONE,
  familyPlanId = null,
  giftQuantity
) => {
  const eventName = EVENTS.PRAY_EVENTS.NAMES.CHECKOUT_COMPLETED;
  const eventData = {
    sku_has_free_trial: isSkuHasFreeTrial,
    sku,
  };

  // Optional params
  if (familyPlanDescription) eventData.family_plan = familyPlanDescription;
  if (familyPlanId) eventData.family_plan_id = familyPlanId;
  if (giftQuantity) eventData.gift_quantity = giftQuantity;

  const fbEventName = isUserEligibleForTrial
    ? EVENTS.FACEBOOK_EVENTS.NAMES.START_TRIAL
    : EVENTS.FACEBOOK_EVENTS.NAMES.SUBSCRIBE;
  const priceInDollars = price ? parseInt(price, 10) / 100 : null;
  const fbEventData = {
    currency: USD_CURRENCY,
    value: priceInDollars,
  };

  const gaActionName = isUserEligibleForTrial
    ? EVENTS.GOOGLE_EVENTS.ACTIONS.STARTED_TRIAL
    : EVENTS.GOOGLE_EVENTS.ACTIONS.STARTED_SUBSCRIPTION;
  const gaEventData = _createGoogleEventData(eventName, gaActionName, sku, null);

  const branchEventName = EVENTS.BRANCH_EVENTS.NAMES.PURCHASE;
  const branchEventDataAndCustomData = {
    currency: USD_CURRENCY,
    revenue: priceInDollars,
  };
  const branchContentItems = [
    {
      $content_schema: EVENTS.BRANCH_EVENTS.CONTENT_SCHEMAS.COMMERCE_PRODUCT,
      $canonical_identifier: productId,
      $publicly_indexable: true,
      $locally_indexable: true,
      $quantity: 1,
      $price: priceInDollars,
      $sku: sku,
    },
  ];

  _trackEvent({
    eventName,
    eventData,
    fbEventName,
    fbEventData,
    gaEventData,
    branchEventName,
    branchEventDataAndCustomData,
    branchContentItems,
  });
};

const trackEventEnteredExperiment = (experimentName, variationName) => {
  if (!experimentName || !variationName) return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.ENTERED_EXPERIMENT;
  const eventData = {
    user_id: storage?.getUser()?.id || null,
    name: experimentName,
    cohort: variationName,
  };

  _trackEvent({ eventName, eventData });
};

const trackEventError = (error) => {
  if (typeof window === 'undefined') return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.ERROR;
  const eventData = {
    error_reason: error.message,
  };

  _trackEvent({ eventName, eventData });
};

const trackEventListen = (
  title,
  itemId,
  startTime,
  contentDuration,
  listenDuration,
  completionRate,
  endingPercentage,
  seriesTitle,
  itemType,
  metricsProperties
) => {
  // do not track listen events with 0 duration (can happen e.g. if user clicks on
  // locked content and is redirected to paywall)
  if (!listenDuration) return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.LISTEN;
  const eventData = {
    title,
    item_id: itemId,
    start_time: startTime,
    content_duration: contentDuration,
    listen_duration: listenDuration,
    completion_rate: completionRate,
    ending_percentage: endingPercentage,
    series_title: seriesTitle,
    item_type: itemType,
  };

  _handleAdditionalEventProperties(eventData, metricsProperties);

  _addContentTotalListenDuration(listenDuration);

  const isFirstListenAlreadyTracked = storage.isFirstListenAlreadyTracked();
  let branchEventName = null;
  let branchEventDataAndCustomData = null;

  if (!isFirstListenAlreadyTracked) {
    const deviceDetails = storage.getFromStorage(LOCAL_STORAGE.DEVICE_DETAILS.KEY) || {};
    deviceDetails[LOCAL_STORAGE.DEVICE_DETAILS.VALUES.IS_FIRST_LISTEN_ALREADY_TRACKED] = true;
    storage.upsertToStorage(LOCAL_STORAGE.DEVICE_DETAILS.KEY, deviceDetails, false);

    branchEventName = EVENTS.BRANCH_EVENTS.NAMES.FIRST_LISTEN;
    branchEventDataAndCustomData = {};
  }

  _trackEvent({
    eventName,
    eventData,
    branchEventName,
    branchEventDataAndCustomData,
  });
};

const trackEventRoute = ({ name, source }) => {
  if (!name) return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.ROUTE;
  const eventData = {
    name,
  };

  if (source) eventData.source = source;

  const fbEventName = EVENTS.FACEBOOK_EVENTS.NAMES.PAGE_VIEW;
  const fbEventData = {
    content_name: name,
  };

  _trackEvent({ eventName, eventData, fbEventName, fbEventData });
};

const trackEventSubmitForm = (name, formFields, isFbCustomLeadEvent = false, fbContentCategory) => {
  const eventName = EVENTS.PRAY_EVENTS.NAMES.SUBMIT_FORM;
  const eventData = {
    form_name: name,
    ...formFields,
  };

  let fbEventName = null;
  let fbEventData = null;
  if (isFbCustomLeadEvent) {
    fbEventName = EVENTS.FACEBOOK_EVENTS.NAMES.LEAD;
    fbEventData = {
      content_name: name,
      content_category: fbContentCategory,
    };
  }

  const gaEventData = _createGoogleEventData(eventName, eventName, name, null);

  _trackEvent({ eventName, eventData, fbEventName, fbEventData, gaEventData });
};

const trackEventTap = (name, fbCustomEventName, fbContentCategory, isSendDefaultsToFb, eventProperties) => {
  if (typeof window === 'undefined') return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.TAP;
  const eventData = {
    button_name: name,
    url: window.location.href,
  };

  if (eventProperties?.view_name) eventData.view_name = eventProperties.view_name;
  if (eventProperties?.button_text) eventData.button_text = eventProperties.button_text;

  _handleAdditionalEventProperties(eventData, eventProperties);

  _addUtmQueryParams(eventData);

  let fbEventName = null;
  let fbEventData = null;
  if (fbCustomEventName) {
    fbEventName = fbCustomEventName;
    fbEventData = {
      content_category: fbContentCategory,
    };
  }
  if (isSendDefaultsToFb) {
    fbEventName = eventName;
    fbEventData = eventData;
  }

  const gaEventData = _createGoogleEventData(eventName, eventName, name, null);

  _trackEvent({ eventName, eventData, fbEventName, fbEventData, gaEventData });
};

const trackEventView = (name, fbContentCategory, eventProperties) => {
  if (typeof window === 'undefined') return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.VIEW;
  const eventData = {
    view_name: name,
    url: window.location.href,
  };

  _handleAdditionalEventProperties(eventData, eventProperties);

  _addUtmQueryParams(eventData);

  const fbEventName = EVENTS.FACEBOOK_EVENTS.NAMES.VIEW_CONTENT;
  const fbEventData = {
    content_name: name,
    content_category: fbContentCategory,
  };

  let gaEventData = _createGoogleEventData(eventName, eventName, name, null);
  if (name === EVENTS.PRAY_EVENTS.VIEW_EVENT_NAMES.PAGE_VIEW) {
    gaEventData = null;
  }

  _trackEvent({
    eventName,
    eventData,
    fbEventName,
    fbEventData,
    gaEventData,
  });
};

const trackEventWatch = (eventData) => {
  if (!eventData.watch_duration) return;

  const eventName = EVENTS.PRAY_EVENTS.NAMES.WATCH;

  _trackEvent({
    eventName,
    eventData,
  });
};

const trackFacebookEvent = (fbEventName, fbEventData) => {
  if (!fbEventName || !fbEventData) return;

  const user = storage.getUser();
  const deviceId = storage.getDeviceId();

  fbEventData.user_id = user ? user.id : null;
  fbEventData.device_id = deviceId;

  const isStandardEvent = Object.values(EVENTS.FACEBOOK_EVENTS.NAMES).includes(fbEventName);

  if (isStandardEvent) {
    ReactFacebookPixel.track(fbEventName, fbEventData);
  } else {
    ReactFacebookPixel.trackCustom(fbEventName, fbEventData);
  }
};

const _createGoogleEventData = (category, action, label, value) => {
  // https://support.google.com/analytics/answer/1033068#Anatomy
  // note if add value, must be of type non-negative integer or event will not send at all!

  if (!category || !action) return undefined;

  const eventData = {
    category,
    action,
  };
  if (label) eventData.label = label;
  if (value && Number.isInteger(value) && value >= 0) eventData.value = value;

  return eventData;
};

const _addDefaultPropertiesToEventData = (eventData, user) => {
  if (user) eventData.pray_user_id = user.id;
  eventData.domain = window.location.hostname;
};

const _addExtraPropertiesToEventData = (eventData) => {
  if (storage.isChromeExtension()) eventData.platform_type = PLATFORM_TYPES.CHROME_EXTENSION;
};

const _addContentTotalListenDuration = (listenDuration) => {
  const identify = new amplitude.Identify().add('content_total_listen_duration', listenDuration);
  amplitude.getInstance().identify(identify);
};

const _addMetricsProperties = (eventData, metricsProperties) => {
  if (!metricsProperties) return;

  eventData.title = eventData.title || metricsProperties.title;
  eventData.item_id = eventData.item_id || metricsProperties.item_id;
  eventData.item_type = eventData.item_type || metricsProperties.item_type;
  eventData.series_id = metricsProperties.series_id;
  eventData.series_title = eventData.series_title || metricsProperties.series_title;
  eventData.series_handle = metricsProperties.series_handle;
  eventData.artist_id = metricsProperties.artist_id;
  eventData.artist_name = metricsProperties.artist_name;
  eventData.content_category = metricsProperties.content_category;
  eventData.consumption_mode = metricsProperties.consumption_mode;
  eventData.media_type = metricsProperties.media_type;
};

const _addProductProperties = (eventData, productProperties) => {
  if (!productProperties) return;

  const { sku, sku_bango: skuBango, price, discount } = productProperties;

  eventData.price = formatDecimal(price);
  eventData.sku = sku || skuBango;

  if (discount) {
    eventData.discount_price = formatDecimal(discount.price);
    if (discount.amount_off) eventData.discount_amount_off = formatDecimal(discount.amount_off);
    if (discount.percent_off) eventData.discount_percent_off = discount.percent_off;
  }
};

const _addUtmQueryParams = (eventData) => {
  const queryParams = parseQueryString();

  if (queryParams.utm_source) eventData.utm_source = queryParams.utm_source;
  if (queryParams.utm_medium) eventData.utm_medium = queryParams.utm_medium;
  if (queryParams.utm_campaign) eventData.utm_campaign = queryParams.utm_campaign;
};

const _handleAdditionalEventProperties = (eventData, eventProperties) => {
  try {
    const parsedEventProperties = typeof eventProperties === 'string' ? JSON.parse(eventProperties) : eventProperties;

    if (!parsedEventProperties || Object.keys(parsedEventProperties).length === 0) return;

    if (parsedEventProperties.sku || parsedEventProperties.sku_bango) {
      _addProductProperties(eventData, parsedEventProperties);
    } else {
      _addMetricsProperties(eventData, parsedEventProperties);
    }
  } catch (err) {
    logger.error('Error parsing additional event properties', err);
  }
};

const _trackEvent = async ({
  eventName,
  eventData,
  fbEventName = '',
  fbEventData = null,
  gaEventData = null,
  branchEventName = '',
  branchEventDataAndCustomData = null,
  branchContentItems = null,
}) => {
  const user = storage.getUser();
  const deviceId = storage.getDeviceId();
  const isUserRegistered = storage.isUserRegistered();

  _addDefaultPropertiesToEventData(eventData, user);
  _addExtraPropertiesToEventData(eventData);

  amplitude.getInstance().logEvent(eventName, eventData);

  if (isUserRegistered) {
    try {
      appboy.logCustomEvent(eventName, eventData);
    } catch (error) {
      logger.error('Failed to log event in Braze', error);
    }
  }

  trackFacebookEvent(fbEventName, fbEventData);

  if (gaEventData) {
    const isGDPRActive = !isCookieBannerAccepted();
    if (ReactGA && !isGDPRActive) ReactGA.event(gaEventData);
  }

  if (branchEventName) {
    branchEventDataAndCustomData.$custom_fields = {
      user_id: user ? user.id : null,
      device_id: deviceId,
    };

    // branch api fails silently if all custom property values are not strings
    branchEventDataAndCustomData = _convertAllObjectPropsToString(branchEventDataAndCustomData);

    if (branchContentItems) {
      branch.logEvent(branchEventName, branchEventDataAndCustomData, branchContentItems);
    } else {
      branch.logEvent(branchEventName, branchEventDataAndCustomData);
    }
  }
};

const _convertAllObjectPropsToString = (object) => {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    const value = object[key];
    if (value !== null && typeof value === 'object') {
      newObject[key] = _convertAllObjectPropsToString(value);
    } else if (typeof value === 'number' || typeof value === 'boolean' || typeof value === 'string') {
      newObject[key] = value.toString();
    }
  });

  return newObject;
};

export default {
  trackEventAd,
  trackEventCheckoutCompleted,
  trackEventEnteredExperiment,
  trackEventError,
  trackEventListen,
  trackEventRoute,
  trackEventSubmitForm,
  trackEventTap,
  trackEventView,
  trackEventWatch,
  trackFacebookEvent,
};
