import api from '@pray/shared/utils/api';

type GenerateAudiobookProps = {
  artistId: string;
  bookId: string;
  voiceId: string;
};

export const generateAudiobook = async ({ artistId, bookId, voiceId }: GenerateAudiobookProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/generate-audiobook`;
  const data = {
    voice_id: voiceId,
  };

  await api.executePostRequest(url, data);
};
