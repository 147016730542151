import React from 'react';

import { formatCurrency } from '@pray/shared/utils';

import { BarChart } from 'components/pages/StudioPage/components/Charts/BarChart';
import useGivingSummary from 'hooks/donations/useGivingSummary';

import { DonationStatsCard } from './DonationStatsCard';

// Helper function to calculate monthly retention rate
function calculateChurnRate() {
  const startRetention = 1.0; // 100%
  const endRetention = 0.3; // 30%
  const decayPeriod = 12; // 12 months

  // Formula: 1 - (end/start)^(1/months)
  return 1 - (endRetention / startRetention) ** (1 / decayPeriod);
}

function calculateCumulativeRevenue(totalDonors, averageDonationAmountNum, months) {
  const churnRate = calculateChurnRate();
  let cumulativeAmount = 0;

  let monthlyAmount = totalDonors * averageDonationAmountNum;
  for (let month = 0; month < months; month++) {
    monthlyAmount *= 1 - churnRate;
    cumulativeAmount += monthlyAmount;
  }

  return cumulativeAmount;
}

export function DonationStats({ artistId, startDate, endDate }) {
  const { data: summary, isLoading } = useGivingSummary({ artistId, startDate, endDate });

  const totalDonationAmount = formatCurrency(summary?.totalDonationAmount || 0);
  const totalDonors = summary?.totalDonors || 0;

  const isDisabled = isLoading || summary?.totalDonations === undefined;
  const averageDonationAmount = summary?.averageDonationAmount / 100 || 0;
  const churnRate = calculateChurnRate();
  const estimatedLtv = totalDonors * (averageDonationAmount / churnRate);

  const months = 36;
  // Calculate cumulative revenue data for 36 months
  const cumulativeRevenueData = {
    labels: Array.from({ length: months }, (_, i) => `M${i + 1}`),
    datasets: [
      {
        label: 'Cumulative Revenue',
        data: Array.from({ length: months }, (_, month) => {
          return calculateCumulativeRevenue(totalDonors, averageDonationAmount, month + 1);
        }),
        backgroundColor: Array.from({ length: months }, (_, month) => {
          const monthRevenue = calculateCumulativeRevenue(totalDonors, averageDonationAmount, month + 1);
          // Use full opacity for historical data, light shade for projections
          return monthRevenue <= (summary?.totalDonationAmount || 0) / 100
            ? '#E3AF4A' // Historical - solid gold
            : '#FFF0D6'; // Projected - light cream color
        }),
        barThickness: 16,
      },
    ],
  };

  return (
    <div className="w-full space-y-6">
      <div className="grid grid-cols-1 gap-6 py-4 md:grid-cols-2 lg:grid-cols-4">
        <DonationStatsCard
          isDisabled={isDisabled}
          title="Estimated Lifetime Revenue"
          value={`$${Math.floor(estimatedLtv).toLocaleString()}`}
        />
        <DonationStatsCard isDisabled={isDisabled} title="Total Revenue Collected" value={totalDonationAmount} />
        <DonationStatsCard
          isDisabled={isDisabled}
          title="Average Monthly Subscription Amount"
          value={formatCurrency(averageDonationAmount * 100)}
        />
        <DonationStatsCard isDisabled={isDisabled} title="Total Subscribers" value={totalDonors} />
      </div>

      <div className="w-full">
        <div className="h-[300px] w-full rounded-lg border border-[#CBCDD2] p-6">
          <BarChart
            title="Projected Cumulative Revenue"
            data={cumulativeRevenueData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              indexAxis: 'x',
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                  ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                    maxTicksLimit: months,
                  },
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    borderDash: [2],
                    color: '#e5e7eb',
                  },
                  ticks: {
                    callback: (value) => `$${Math.round(value).toLocaleString()}`,
                    maxTicksLimit: 10,
                    padding: 10,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false, // Hide data labels since they might overlap
                },
                tooltip: {
                  backgroundColor: 'white',
                  titleColor: 'black',
                  bodyColor: 'black',
                  borderColor: '#CBCDD2',
                  borderWidth: 1,
                  padding: 12,
                  callbacks: {
                    title: (tooltipItems) => `Month ${tooltipItems[0].dataIndex + 1}`,
                    label: (context) => {
                      const monthRevenue = context.raw;
                      const isHistorical = monthRevenue <= (summary?.totalDonationAmount || 0) / 100;
                      return `${isHistorical ? 'Actual' : 'Projected'} Revenue: $${Math.round(
                        monthRevenue
                      ).toLocaleString()}`;
                    },
                  },
                },
              },
              layout: {
                padding: {
                  left: 10,
                  right: 30,
                  top: 20,
                  bottom: 10,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
