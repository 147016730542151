import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { CONTENT_VISIBILITY, LOCALES, CONTENT_ORDER } from '@pray/shared/constants';
import { useAuth } from '@pray/shared/hooks/useAuth';
import useForm from '@pray/shared/hooks/useForm';

import MainLayout from 'components/layouts/MainLayout';
import ProtectedRoute from 'components/router/ProtectedRoute';
import useAppRoutes from 'hooks/useAppRoutes';
import { Edit, Rocket } from 'images/icons';
import { Permissions } from 'utils/auth';

import { SERIES_TABS } from './constants';
import CourseEditPage from './Courses/CourseEdit/CourseEditPage';
import PlaylistDistribution from './PlaylistDistribution/PlaylistDistribution';
import PlaylistForm from './PlaylistForm/PlaylistForm';
import PlaylistItems from './PlaylistItems/PlaylistItems';
import PlaylistsPage from './PlaylistsPage/PlaylistsPage';
import BackLinkButton from '../../components/BackLinkButton/BackLinkButton';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import SideBarContentDetails from '../../components/SideBar/SideBarContentDetails';
import useSideBar from '../../components/SideBar/useSideBar';
import TabItem from '../../components/TabItem/TabItem';

export default function PlaylistsTab() {
  const { permissions } = useAuth();
  const location = useLocation();
  const appRoutes = useAppRoutes();
  const playlistEditMatch = useMatch('/leaders/:artistId/series/:contentSeriesId/:action');
  const [playlist, setPlaylist] = useState({});
  const [translations, setTranslations] = useState([]);
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const initialValues = {
    id: '',
    title: '',
    description: '',
    image_url: '',
    visibility: CONTENT_VISIBILITY.PUBLIC,
    locale: LOCALES.DEFAULT,
    contentOrder: CONTENT_ORDER.MANUALLY.key,
  };

  const form = useForm(initialValues);

  const props = {
    form,
    playlist,
    translations,
    setPlaylist,
    setTranslations,
  };

  useEffect(() => {
    if (!playlistEditMatch) {
      setPlaylist({});
      form.setValues(initialValues);
    }
  }, [playlistEditMatch]);

  const renderPlaylistSideBar = () => {
    const { contentSeriesId, action } = playlistEditMatch.params;

    return (
      <PlaylistDetailsItems
        form={form}
        translations={translations}
        appRoutes={appRoutes}
        contentSeriesId={contentSeriesId}
        activeItem={action}
      />
    );
  };

  useEffect(() => {
    if (playlistEditMatch) {
      setRenderDetails(renderPlaylistSideBar);
    }
  }, [playlistEditMatch, translations, location]);

  return (
    <MainLayout data-viewname="PRAY Studio - Series Tab">
      <Routes>
        <Route index element={<PlaylistsPage activeTab={SERIES_TABS.SERIES} />} />
        <Route path="content" element={<PlaylistsPage activeTab={SERIES_TABS.SERIES} />} />
        <Route
          path="dailies"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <PlaylistsPage activeTab={SERIES_TABS.DAILES} />
            </ProtectedRoute>
          }
        />
        <Route path="create" element={<PlaylistForm isCreate {...props} />} />
        <Route path=":id" element={<PlaylistItems />} />
        <Route path=":id/details" element={<PlaylistForm {...props} />} />
        <Route path=":id/distribution" element={<PlaylistDistribution {...props} />} />
        <Route
          path="courses/:courseId/edit"
          element={
            <ProtectedRoute hasPermission={permissions.has(Permissions.STUDIO_ADMIN)}>
              <CourseEditPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </MainLayout>
  );
}

const PlaylistDetailsItems = ({
  form = null,
  translations = [],
  appRoutes = null,
  contentSeriesId = '',
  activeItem = '',
  disabled = false,
}) => {
  return (
    <div className="flex flex-col gap-6 pt-5">
      <div className="mx-2 w-auto">
        <BackLinkButton label="Series" />
      </div>

      <SideBarContentDetails />

      <LanguageSelect form={form} items={translations} />

      <div className="[&_a]:px-3 [&_button]:px-3">
        <TabItem
          icon={Edit}
          text="Series Details"
          route={appRoutes.artistPlaylistDetailsForm(contentSeriesId)}
          disabled={disabled}
          active={activeItem === 'details'}
        />
        <TabItem
          icon={Rocket}
          text="Distribution"
          route={appRoutes.artistPlaylistDistribution(contentSeriesId)}
          disabled={disabled}
          active={activeItem === 'distribution'}
        />
      </div>
    </div>
  );
};
