import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDonationPayouts({ artistId }) {
  async function fetchDonationPayouts(offset) {
    const response = await studioService.donations.getDonationPayouts(artistId, offset);

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    response.data = response.data.map(studioService.donations.payoutsMapper);

    return response;
  }

  const query = useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!artistId,
    queryKey: queryKeys.artistDonationPayouts(artistId),
    queryFn: ({ pageParam: offset }) => fetchDonationPayouts(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
    placeholderData: keepPreviousData,
  });

  return query;
}
