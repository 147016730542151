import { UseQueryResult, useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { Chapter, mapChapter } from '@pray/shared/services/studioService/books/chapters';

type UseChapterProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

const CHAPTER_CACHE_TIME = 1000 * 60 * 5; // 5 minutes

export function useChapter({ artistId, bookId, chapterId }: UseChapterProps): UseQueryResult<Chapter> {
  async function fetchChapter() {
    const response = await studioService.chapters.getChapter({
      artistId,
      bookId,
      chapterId,
    });

    return mapChapter(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!bookId && !!chapterId,
    queryKey: queryKeys.artistBookChapter(artistId, bookId, chapterId),
    staleTime: CHAPTER_CACHE_TIME,
    queryFn: fetchChapter,
  });

  return query;
}
