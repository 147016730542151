import React, { useEffect, useState } from 'react';

import studioService from '@pray/shared/services/studioService';

import MainLayout from 'components/layouts/MainLayout';
import { useStudioContext } from 'context/StudioContext';

import BannerForm from './BannerForm/BannerForm';

export default function BannerTab() {
  const [bannerData, setBannerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;

  const fetchBannerData = async () => {
    const response = await studioService.promoUnit.getPromoUnit({ artistId });
    setBannerData(response.data ?? null);
    setIsLoading(false);
  };

  useEffect(() => {
    if (artistId) fetchBannerData();
  }, [artistId]);

  return (
    <MainLayout data-viewname="PRAY Studio - Banner Tab">
      {!isLoading && <BannerForm data={bannerData} onSaveSuccess={fetchBannerData} />}
    </MainLayout>
  );
}
