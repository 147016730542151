export const variations = [
  { background: '#E4DEEF', border: '#8854F2', color: '#360A76' }, // purple
  { background: '#DAE3F4', border: '#387AF0', color: '#082559' }, // blue
  { background: '#D5E6DD', border: '#1CAB5F', color: '#04341A' }, // green
  { background: '#F0E7D6', border: '#E3AF4A', color: '#2D2008' }, // yellow
  { background: '#F6E1DA', border: '#F68F6F', color: '#441803' }, // orange
  { background: '#F1DDDD', border: '#FA8686', color: '#670405' }, // red
];

export const classifications = {
  green: '#85C2A1',
  lime: '#B7D197',
  yellow: '#F9CE8E',
  red: '#FCBEBE',
};

export const minutesClassifications = [
  { statusColor: classifications.green, min: 1500000, max: Number.POSITIVE_INFINITY },
  { statusColor: classifications.lime, min: 800000, max: 1499999 },
  { statusColor: classifications.yellow, min: 500000, max: 799999 },
  { statusColor: classifications.red, min: Number.NEGATIVE_INFINITY, max: 499999 },
];
