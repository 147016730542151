import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Info } from '@pray/shared/components/ui/Icons/Info';
import Text from '@pray/shared/components/ui/Text/Text';
import { LOCALES } from '@pray/shared/constants';
import studioService from '@pray/shared/services/studioService';
import { copyToClipboard } from '@pray/shared/utils';
import logger from '@pray/shared/utils/logger';
import { isValidEmail } from '@pray/shared/utils/validation';

import FooterPortal from 'components/layouts/FooterPortal';
import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import Alert from 'components/pages/StudioPage/components/Alert/Alert';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import usePlaylistDetails from 'hooks/usePlaylistDetails';

import DistributionChecklist from './DistributionChecklist/DistributionChecklist';
import DistributionForm from './DistributionForm/DistributionForm';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './PlaylistDistribution.module.scss';

export default function PlaylistDistribution({
  form = null,
  playlist = {},
  setPlaylist = null,
  setTranslations = null,
}) {
  const toast = useToastMessage();
  const { id: contentSeriesId } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const locale = form.values.locale || LOCALES.DEFAULT;
  const playlistDetails = usePlaylistDetails({ artistId, contentSeriesId, locale });

  const distributionStatus = useMemo(() => {
    const hasTitleAndDescription = !!playlist?.title && !!playlist?.description;
    const hasThumbnail = !!playlist?.image_url;
    const isVisible = !!playlist?.is_visible;
    const hasPublishedEpisodes = !!playlist?.content?.some((episode) => episode.is_published);
    const isReady = hasTitleAndDescription && hasThumbnail && isVisible && hasPublishedEpisodes;

    return {
      isReady,
      hasTitleAndDescription,
      hasThumbnail,
      isVisible,
      hasPublishedEpisodes,
    };
  }, [playlist]);

  useEffect(() => {
    form.setValue('distribution_rss_feed_url', playlist?.distribution_rss_feed_url || '');
    form.setValue('distribution_owner_email', playlist?.distribution_owner_email || '');
  }, [playlist]);

  useEffect(() => {
    if (playlistDetails.playlist) {
      setPlaylist((prev) => ({ ...prev, ...playlistDetails.playlist }));
    }
    if (playlistDetails.translations.length) {
      setTranslations(playlistDetails.translations);
    }
  }, [playlistDetails.playlist, playlistDetails.translations]);

  const handleCopyUrl = () => {
    copyToClipboard(form.values.distribution_rss_feed_url, () => {
      toast.show({ success: 'Link copied!' });
    });
  };

  const handleSaveDistribution = async () => {
    setIsSaving(true);

    if (form.values.distribution_owner_email && !isValidEmail(form.values.distribution_owner_email)) {
      form.setError('distribution_owner_email', 'Invalid email address');
      return;
    }

    try {
      await studioService.contentSeries.updateContentSeries({
        artistId,
        contentSeriesId,
        distributionOwnerEmail: form.values.distribution_owner_email || null,
      });

      toast.show({ success: 'Series distribution info was saved!' });
    } catch (err) {
      logger.error('Failed to update Series distribution info', err);
    }

    setIsSaving(false);
  };

  const renderActionButtons = () => (
    <div className="flex flex-1 space-x-3">
      {distributionStatus.isReady && (
        <ActionButton isLoading={isSaving} text={isSaving ? 'Saving' : 'Save'} onClick={handleSaveDistribution} />
      )}
    </div>
  );

  const renderTopRightButtons = () => (
    <div className={styles.rightButtons}>
      <div className="hidden md:block">{renderActionButtons()}</div>
    </div>
  );

  const renderDistributionBody = () => {
    if (!playlist.id) return null;

    if (locale !== LOCALES.DEFAULT && !playlist.id) {
      return (
        <div className="mt-6">
          <Alert
            icon={<Info color="#A34515" />}
            message="Distribution is not available until the translation process is complete."
          />
        </div>
      );
    }

    return (
      <div className="mt-12">
        {!distributionStatus.isReady ? (
          <DistributionChecklist distributionStatus={distributionStatus} />
        ) : (
          <DistributionForm form={form} onCopyUrl={handleCopyUrl} />
        )}
      </div>
    );
  };

  return (
    <TabPage
      title="Distribution"
      data-viewname={VIEW_EVENT_NAMES.SERIES.DISTRIBUTION}
      topRightButtons={renderTopRightButtons()}
    >
      <Text className="max-w-screen-sm">
        Make your Series available as a podcast to the world by linking your secure RSS Feed to the platforms your
        listeners use.
      </Text>

      {renderDistributionBody()}

      <FooterPortal>{renderActionButtons()}</FooterPortal>
    </TabPage>
  );
}
