function initializeEventListeners(
  player,
  {
    onPlay,
    onPause,
    onWaiting,
    onPlaying,
    onTimeUpdate,
    onSeeking,
    onSeeked,
    onEnded,
    onError,
    onLoadedData,
    onLoadedMetadata,
  }
) {
  let currentTimeSecond = 0;
  let previousTimeSecond = 0;
  let startPositionSecond = 0;

  player.on('play', (event) => {
    onPlay?.(event, player, player.currentTime());
  });

  player.on('pause', (event) => {
    onPause?.(event, player, player.currentTime());
  });

  player.on('waiting', (event) => {
    onWaiting?.(event, player, player.currentTime());
  });

  player.on('playing', (event) => {
    onPlaying?.(event, player, player.currentTime());
  });

  player.on('timeupdate', (event) => {
    const currentTime = player.currentTime();
    onTimeUpdate?.(event, player, currentTime);
    previousTimeSecond = currentTimeSecond;
    currentTimeSecond = currentTime;
    if (previousTimeSecond < currentTimeSecond) {
      startPositionSecond = previousTimeSecond;
      previousTimeSecond = currentTimeSecond;
    }
  });

  player.on('seeking', (event) => {
    player.off('timeupdate', () => null);
    player.one('seeked', () => null);
    onSeeking?.(event, player, player.currentTime());
  });

  player.on('seeked', (event) => {
    const completeTimeSecond = player.currentTime();
    onSeeked?.(event, player, startPositionSecond, completeTimeSecond);
  });

  player.on('ended', (event) => {
    onEnded?.(event, player);
  });

  player.on('error', (event) => {
    onError?.(event, player);
  });

  player.on('loadeddata', (event) => {
    onLoadedData?.(event, player);
  });

  player.on('loadedmetadata', (event) => {
    onLoadedMetadata?.(event, player);
  });
}

export default initializeEventListeners;
