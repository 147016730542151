import { useState, useEffect } from 'react';

import { type LeadListMappingItem, LeadListStatus } from '@pray/shared/services/studioService/leadLists/types';
import binaryToBase64 from '@pray/shared/utils/encoding/binaryToBase64';

import { useStudioContext } from 'context/StudioContext';
import useCreateLeadList from 'hooks/leadList/useCreateLeadList';
import useLeadListMetadata from 'hooks/leadList/useLeadListMetadata';
import useRetryProcessingLeadList from 'hooks/leadList/useRetryProcessingLeadList';

import { DROPZONE_ERROR, DROPZONE_STATUS, HIDE_FIELD_KEY } from '../../../constants';
import { useUploadEncryptedFile } from '../../hooks/useUploadEncryptedFile';

type UploadState = {
  status: (typeof DROPZONE_STATUS)[keyof typeof DROPZONE_STATUS];
  error: (typeof DROPZONE_ERROR)[keyof typeof DROPZONE_ERROR] | null;
};

type MappingItem = {
  guess: string;
  presentationName?: string;
  header?: string;
  index?: number;
};

type UseImportLeadsProps = {
  name: string;
  mapping: MappingItem[];
  file: File;
  setUploadState: (state: UploadState) => void;
};

type ImportLeadsResult = {
  isCreateContactListComplete: boolean;
  handleImportLeads: () => Promise<void>;
  handleRetry: () => Promise<void>;
};

enum CreateContactListState {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

/**
 * Hook to handle the import of leads from a CSV file
 */
export function useImportLeads({ name, mapping, file, setUploadState }: UseImportLeadsProps): ImportLeadsResult {
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const [leadListId, setLeadListId] = useState<string | null>(null);
  const [createContactListState, setCreateContactListState] = useState<CreateContactListState>(
    CreateContactListState.IDLE
  );

  const { encryptAndUploadFile } = useUploadEncryptedFile({ artistId });
  const createContactList = useCreateLeadList({ artistId });
  const retryProcessingLeadList = useRetryProcessingLeadList({
    artistId,
    filters: {},
  });

  const leadListMetadata = useLeadListMetadata({
    artistId,
    listId: leadListId || '',
    options: {
      enabled: Boolean(leadListId),
      enableAutoRefetch: true,
      refetchInterval: 30000,
    },
  });

  useEffect(() => {
    if (!leadListMetadata.data) return;

    if (leadListMetadata.data.status === LeadListStatus.COMPLETE) {
      setUploadState({ status: DROPZONE_STATUS.SUCCESS, error: null });
    } else if (leadListMetadata.data.status === LeadListStatus.FAILED) {
      setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.IMPORT_ERROR });
    }
  }, [leadListMetadata.data, setUploadState]);

  useEffect(() => {
    if (leadListMetadata.error) {
      setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.IMPORT_ERROR });
    }
  }, [leadListMetadata.error, setUploadState]);

  async function handleMutateLeads() {
    try {
      const { rsaEncryptedAesIv, rsaEncryptedAesKey, rsaEncryptedAesUrl } = await encryptAndUploadFile(file, name);

      const cleanedMapping: LeadListMappingItem[] = mapping
        .filter((item) => item.guess !== HIDE_FIELD_KEY)
        .map((item) => ({
          column_name: item.guess,
          index: item.index,
        }));

      const result = await createContactList.mutateAsync({
        name,
        mapping: cleanedMapping,
        s3Url: binaryToBase64(rsaEncryptedAesUrl),
        initializationVector: binaryToBase64(rsaEncryptedAesIv),
        secretKey: binaryToBase64(rsaEncryptedAesKey),
      });

      if (result?.id) {
        setLeadListId(result.id);
        setCreateContactListState(CreateContactListState.SUCCESS);
      } else {
        setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.IMPORT_ERROR });
        setCreateContactListState(CreateContactListState.FAILURE);
      }
    } catch (error) {
      setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.IMPORT_ERROR });
      setCreateContactListState(CreateContactListState.FAILURE);
    }
  }

  async function handleImportLeads() {
    setUploadState({ status: DROPZONE_STATUS.LOADING, error: null });
    await handleMutateLeads();
  }

  async function handleRetry() {
    setUploadState({ status: DROPZONE_STATUS.LOADING, error: null });

    // If createContactList failed, we need to start the whole process again
    if (createContactListState === CreateContactListState.FAILURE || !leadListId) {
      await handleMutateLeads();
    }
    // If createContactList succeeded but lambda processing failed, we need to retry just the processing
    else {
      try {
        await retryProcessingLeadList.mutateAsync({ listId: leadListId });
      } catch (error) {
        setUploadState({ status: DROPZONE_STATUS.ERROR, error: DROPZONE_ERROR.IMPORT_ERROR });
      }
    }
  }

  const isCreateContactListComplete = createContactListState === CreateContactListState.SUCCESS

  return { isCreateContactListComplete, handleImportLeads, handleRetry };
}
