import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type AddChapterParams = {
  artistId: string;
  bookId: string;
  title: string;
};

export function useAddChapter() {
  const queryClient = useQueryClient();

  async function addChapter({ artistId, bookId, title }: AddChapterParams) {
    await studioService.chapters.addChapter({
      artistId,
      bookId,
      title,
    });
  }

  function onSuccess(_, variables) {
    queryClient.invalidateQueries({ queryKey: queryKeys.artistBookChapters(variables.artistId, variables.bookId) });
  }

  const mutation = useMutation({
    mutationFn: addChapter,
    onSuccess,
  });

  const { mutateAsync, isPending } = mutation;

  return {
    addChapter: mutateAsync,
    isPending,
  };
}
