import api from '@pray/shared/utils/api';

export async function getTagById({ tagId, typeFilter = '', offset = 0, sortColumn = '', sortDirection = 'asc' }) {
  if (!tagId) throw Error('Tag ID is required');

  const params = {};

  if (typeFilter) {
    params.type_filter = typeFilter;
  }

  if (offset) {
    params.offset = offset;
  }

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/tags/${tagId}`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
