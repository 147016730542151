import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAudioPlayer } from '@pray/shared/components/ContentPlayer/FullscreenContentPlayer';
import queryKeys from '@pray/shared/queryKeys';

import { useStudioContext } from 'context/StudioContext';
import { useQueryState } from 'hooks/useQueryState';

import useGetArtistContentDetails from './useGetArtistContentDetails';
import useWorkflowAIStatus, { TASKS_TO_BE_PROCESSED } from '../../ContentDetails/WorkflowAIStatus/useWorkflowAIStatus';

export function useContentLocale() {
  /**
   * Hooks
   */
  const queryClient = useQueryClient();
  const { selectedArtist } = useStudioContext();
  const { closeAudioPlayer } = useAudioPlayer();
  const { contentId } = useParams();
  const { state: locale, setState: setLocale } = useQueryState('locale');
  const { setWorkflowStatusLoading, addTaskDone, clearTasksDone } = useWorkflowAIStatus();
  const contentDetailsQuery = useGetArtistContentDetails(selectedArtist.id, contentId, locale);
  const { data: contentData, dataUpdatedAt, isLoading, refetch } = contentDetailsQuery;

  /**
   * Internal State
   */
  const [content, setContent] = useState(contentData);
  const [workflowAIDetails, setWorkflowAIDetails] = useState({
    version: null,
    chapters: null,
    summary: null,
    shortSummary: null,
    transcript: null,
    locale: null,
    updatedAt: null,
    translationStatus: null,
    isLoading: true,
    announcementId: null,
    emailCampaignId: null,
    article: null,
    studyGuide: null,
  });

  /**
   * Utility Functions
   */
  const handleWorkflowAIStatusUpdate = (contentData) => {
    setWorkflowAIDetails({
      version: contentData?.workflow_ai_metadata?.version || null,
      chapters: contentData?.workflow_ai_metadata?.chapters || null,
      summary: contentData?.workflow_ai_metadata?.summary || null,
      shortSummary: contentData?.workflow_ai_metadata?.short_summary || null,
      transcript: contentData?.workflow_ai_metadata?.transcript || null,
      updatedAt: contentData?.workflow_ai_metadata?.updated_at || null,
      translationStatus: contentData?.workflow_ai_metadata?.translation_status || null,
      locale: contentData?.locale || null,
      announcementId: contentData?.workflow_ai_metadata?.announcement_id || null,
      emailCampaignId: contentData?.workflow_ai_metadata?.email_campaign_id || null,
      article: contentData?.workflow_ai_metadata?.article || null,
      studyGuide: contentData?.workflow_ai_metadata?.study_guide || null,
      isLoading: false,
    });

    /**
     * Handle already processed workflow AI
     */
    if (!contentData?.is_workflow_ai_processing || contentData?.transcript_status === 'failed') {
      setWorkflowStatusLoading(false);
      clearTasksDone();
      return;
    }

    /**
     * Handle processing workflow AI
     */
    setWorkflowStatusLoading(true);

    if (contentData?.workflow_ai_metadata?.transcript) {
      addTaskDone(TASKS_TO_BE_PROCESSED.TRANSCRIPT);
    }

    if (contentData?.workflow_ai_metadata?.summary) {
      addTaskDone(TASKS_TO_BE_PROCESSED.SUMMARY);
    }

    if (contentData?.workflow_ai_metadata?.short_summary) {
      addTaskDone(TASKS_TO_BE_PROCESSED.SHORT_SUMMARY);
    }

    if (contentData?.workflow_ai_metadata?.article) {
      addTaskDone(TASKS_TO_BE_PROCESSED.ARTICLE);
    }

    if (contentData?.workflow_ai_metadata?.chapters && contentData?.workflow_ai_metadata?.chapters.length > 0) {
      addTaskDone(TASKS_TO_BE_PROCESSED.CHAPTERS);
    }

    if (contentData?.workflow_ai_metadata?.study_guide) {
      addTaskDone(TASKS_TO_BE_PROCESSED.STUDY_GUIDE);
    }
  };

  useEffect(() => {
    setContent(contentData);
  }, [contentData]);

  useEffect(() => {
    closeAudioPlayer();
  }, [selectedArtist?.id, contentId, locale]);

  useEffect(() => {
    const artistId = selectedArtist?.id;

    let timeout;
    if (artistId && contentId && locale) {
      const queryKey = queryKeys.artistContentDetailsLocale(artistId, contentId, locale);

      const isContentGenerating = content?.is_processing;
      const isWorkflowAIProcessing = content?.is_workflow_ai_processing;
      const isTranslationInProgress = content?.workflow_ai_metadata?.translation_status === 'in_progress';
      const isProcessingTranscript = content?.transcript_status === 'processing';

      const shouldRefetch =
        isContentGenerating || isWorkflowAIProcessing || isTranslationInProgress || isProcessingTranscript;

      if (shouldRefetch) {
        timeout = setTimeout(() => {
          queryClient.invalidateQueries({ queryKey }).then(() => refetch({ cancelRefetch: true }));
        }, 5 * 1000); // fetch content details every 5 seconds
      }
    }

    return () => clearTimeout(timeout);
  }, [content, dataUpdatedAt]);

  return {
    content,
    locale,
    selectedArtist,
    isLoading,
    dataUpdatedAt,
    workflowAIDetails,
    setContent,
    handleWorkflowAIStatusUpdate,
    setLocale,
    refetch,
  };
}
