import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { SelectedVoiceIcon, UnselectedVoiceIcon } from './icons';

import { Select, SelectGroup, SelectItem, SelectLabel } from '@/components/ui/select';

export function VoiceDropdown({ voices, index, useElevenLabsVoiceId }) {
  /** @type {import('react-hook-form').UseFormReturn<import('./types').FormValues>} */
  const form = useFormContext();
  const voiceId = form.watch(`speakers.${index}.voiceId`);

  const allVoices = voices.artist.concat(voices.shared);
  const idToUse = useElevenLabsVoiceId ? 'eleven_labs_voice_id' : 'id';
  const selectedVoice = allVoices.find((voice) => voice[idToUse] === voiceId);

  const voiceGroups = useMemo(() => {
    const groups = [];

    if (voices.artist.length) {
      groups.push({ name: 'My Voices', voices: voices.artist });
    }

    if (voices.shared.length) {
      groups.push({ name: 'Template Voices', voices: voices.shared });
    }

    return groups;
  }, [voices]);

  const placeholderIcon = voiceId ? <SelectedVoiceIcon /> : <UnselectedVoiceIcon />;
  const placeholderLabel = selectedVoice?.name || 'Assign a voice';

  const placeholder = (
    <div className="flex items-center gap-2">
      {placeholderIcon}
      <Text variant={typography.body_md} className="truncate">
        {placeholderLabel}
      </Text>
    </div>
  );

  return (
    <Controller
      control={form.control}
      name={`speakers.${index}.voiceId`}
      rules={{ required: true }}
      render={({ field }) => (
        <Select
          placeholder={placeholder}
          value={field.value}
          error={!!form.formState.errors?.speakers?.[index]?.voiceId}
          onValueChange={field.onChange}
        >
          {voiceGroups.map((group) => (
            <SelectGroup key={group.name}>
              <SelectLabel>{group.name}</SelectLabel>
              {group.voices.map((voice) => (
                <SelectItem key={voice.id} value={voice[idToUse]}>
                  <div className="flex items-center gap-2">
                    <VoicePreview voice={voice} />
                    {voice.name}
                  </div>
                </SelectItem>
              ))}
            </SelectGroup>
          ))}
        </Select>
      )}
    />
  );
}

function VoicePreview({ voice }) {
  const [isHovered, setIsHovered] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(voice.preview_url);

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [voice.preview_url]);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    if (isHovered) {
      audio.play().catch();
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
  }, [isHovered]);

  return (
    <SelectedVoiceIcon
      isHovered={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
}
