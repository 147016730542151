export * from './getGivingSummary';
export * from './getGivingPayments';
export * from './getDonationPayouts';
export * from './getPayoutTransactions';
export * from './getPayoutById';

export function payoutsMapper(transfer) {
  return {
    id: transfer.stripe_payout_id,
    date: transfer.date,
    grossAmount: transfer.gross_amount,
    feeAmount: transfer.fee_amount,
    netAmount: transfer.net_amount,
    status: transfer.status,
    bank: {
      name: transfer.bank_account.bank_name,
      lastFourDigits: transfer.bank_account.last4,
      currency: transfer.bank_account.currency,
    },
  };
}

export function payoutTransactionMapper(transaction) {
  return {
    id: transaction.id,
    type: transaction.type,
    userName: transaction.user_name,
    fundName: transaction.fund_name,
    date: transaction.date,
    amount: transaction.amount,
  };
}
