const domEventService = {
  on(event, handler) {
    if (typeof document === 'undefined') return null;
    document.addEventListener(event, handler);
    return () => domEventService.remove(event, handler);
  },

  trigger(event, detail) {
    if (typeof document === 'undefined') return;
    document.dispatchEvent(new CustomEvent(event, { detail }));
  },

  remove(event, handler) {
    if (typeof document === 'undefined') return;
    document.removeEventListener(event, handler);
  },
};

export default function useDOMEvents() {
  return domEventService;
}
