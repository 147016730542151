import api from '@pray/shared/utils/api';

import type { GetConnectedSocialPlatformsResponse } from './types';

type GetConnectedSocialPlatformsProps = {
  artistId: string;
  includeUserDetails?: boolean;
};

export async function getConnectedSocialPlatforms({
  artistId = '',
  includeUserDetails = false,
}: GetConnectedSocialPlatformsProps): Promise<GetConnectedSocialPlatformsResponse | null> {
  if (!artistId) throw new Error('Artist ID is required');

  const url = `/web/studio/artists/${artistId}/socials/connect`;
  const params: Record<string, boolean> = {};

  if (includeUserDetails) params.include_user_details = includeUserDetails;

  const response = await api.executeGetRequest<GetConnectedSocialPlatformsResponse | undefined>(url, params);

  return response.status === 204 ? null : response?.data ?? null;
}
