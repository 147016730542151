import React from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import { VoiceDelete, VoicePlaying } from 'images/icons';

import { SampleUploadStatus } from '../../constants';
import VoicePlaybackButton from '../VoicePlaybackButton';
import SampleStatusIcon from './SampleStatusIcon';

export default function SampleItem({ sample, isPlaying, onPause, onPlay, onDelete }) {
  return (
    <div className="flex items-center justify-between gap-2 p-4">
      <div className="flex items-center gap-4">
        <VoicePlaybackButton
          voice={sample}
          isPlaying={isPlaying}
          disabled={sample.status === SampleUploadStatus.UPLOADING || sample.status === SampleUploadStatus.ERROR}
          onPause={onPause}
          onPlay={onPlay}
        />
        <div className="flex flex-col justify-center">
          <Text variant={typography.heading_md} nowrap className="max-w-[250px] truncate">
            {sample.name}
          </Text>
          {sample.status === SampleUploadStatus.ERROR && (
            <Text className="!font-medium" variant={typography.body_lg} color={colors.attention}>
              {sample.errorMessage}
            </Text>
          )}
        </div>
      </div>
      <div className="flex items-center gap-1">
        {isPlaying && <VoicePlaying />}

        <SampleStatusIcon status={sample.status} />

        <Button className="!p-0" onClick={onDelete}>
          <VoiceDelete />
        </Button>
      </div>
    </div>
  );
}
