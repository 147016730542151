import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useCopyArticle() {
  const toast = useToastMessage();

  const copyArticle = async (article) => {
    try {
      await navigator.clipboard.writeText(article);
      toast.show({ success: 'Article copied to clipboard' });
    } catch (err) {
      toast.show({ error: 'Failed to copy article' });
    }
  };

  return {
    copyArticle,
  };
}
