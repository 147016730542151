import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import Modal from '@pray/shared/components/ui/Modal';
import Text from '@pray/shared/components/ui/Text';
import { LOCALES } from '@pray/shared/constants';
import useDebounce from '@pray/shared/hooks/useDebounce';
import useForm from '@pray/shared/hooks/useForm';
import { downloadFileFromBlob } from '@pray/shared/utils/download';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import { useLocaleFromQueryParams } from 'hooks/locales/useLocaleFromQueryParams';
import useGetTranscriptionsList from 'hooks/transcripts/useGetTranscriptionsList';
import useSaveTranscriptDraftVersion from 'hooks/transcripts/useSaveTranscriptDraftVersion';
import useSaveTranscriptVersion from 'hooks/transcripts/useSaveTranscriptVersion';
import useTriggerTranslationSynthesis from 'hooks/transcripts/useTriggerTranslationSynthesis';
import useAppRoutes from 'hooks/useAppRoutes';
import { ArrowLeft, Redo, Undo } from 'images/icons';

import ActionButton from '../../../components/ActionButton/ActionButton';
import ButtonCluster from '../../../components/ButtonCluster';
import { useToastMessage } from '../../../components/StudioToastMessage';

export default function TranscriptEditForm() {
  const toast = useToastMessage();
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [isGenerateAudioModalOpen, setIsGenerateAudioModalOpen] = useState(false);
  const locale = useLocaleFromQueryParams();
  const { selectedArtist } = useStudioContext();
  const navigate = useNavigate();
  const { contentId } = useParams();
  const appRoutes = useAppRoutes();
  const textareaRef = useRef(null);
  const artistId = selectedArtist?.id;
  const { data } = useGetTranscriptionsList(artistId, contentId, locale);
  const form = useForm({ transcript: data?.most_recent_transcript?.transcript });
  const debouncedTranscript = useDebounce(form.values.transcript, 1500);

  const { saveTranscriptVersion, isPending: isPendingPublish } = useSaveTranscriptVersion(artistId, contentId, locale);

  const { saveTranscriptionDraftVersion, isPending: isPendingDraftUpdate } = useSaveTranscriptDraftVersion(
    artistId,
    contentId,
    locale,
    false
  );

  const { triggerTranslationSynthesis, isPending: isPendingTriggerSynthesis } = useTriggerTranslationSynthesis(
    artistId,
    contentId,
    locale
  );

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [form.values.transcript]);

  useEffect(() => {
    if (debouncedTranscript !== undefined && form.changed) {
      saveTranscriptionDraftVersion({ transcript: debouncedTranscript }).then(() => {
        form.setChanged(false);
      });
    }
  }, [debouncedTranscript]);

  const handleUndo = () => {
    if (undoStack.length > 0) {
      const newRedoStack = [form.values.transcript, ...redoStack];
      const newUndoStack = [...undoStack];
      const lastState = newUndoStack.pop();
      form.setValue('transcript', lastState);
      setUndoStack(newUndoStack);
      setRedoStack(newRedoStack);
    }
  };

  const handleRedo = () => {
    if (redoStack.length > 0) {
      const newUndoStack = [...undoStack, form.values.transcript];
      const newRedoStack = [...redoStack];
      const nextState = newRedoStack.shift();
      form.setValue('transcript', nextState);
      setUndoStack(newUndoStack);
      setRedoStack(newRedoStack);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setUndoStack([...undoStack, form.values.transcript]);
    form.setValue('transcript', value);
    setRedoStack([]);
  };

  const getBackRoutePath = () => {
    const defaultBackRoute = appRoutes.artistContentTranscript(contentId);
    return `${defaultBackRoute}?locale=${locale}`;
  };

  const handlePublishButton = async () => {
    await saveTranscriptVersion({ transcript: form.values.transcript });
    toast.show({ success: 'Transcript updated' });

    if (locale === LOCALES.EN) {
      navigate(getBackRoutePath());
      return;
    }

    setIsGenerateAudioModalOpen(true);
  };

  const handleDownloadButton = () => {
    downloadFileFromBlob(form.values.transcript, `${locale}_draft.txt`);
  };

  const handleGenerateAudioButton = async () => {
    await triggerTranslationSynthesis();
    navigate(getBackRoutePath());
  };

  const handleCloseGenerateAudioModal = () => {
    navigate(getBackRoutePath());
  };

  return (
    <div className="w-full bg-[#F2F3F4]" data-viewname={VIEW_EVENT_NAMES.CONTENT.TRANSCRIPT_EDIT}>
      {/* Header */}
      <div className="grid h-[72px] w-full grid-cols-3 items-center border-b border-gray-200 bg-white px-4 shadow">
        {/* Left panel */}
        <div className="flex gap-4">
          <Button
            variant={buttons.variant.secondary}
            className="flex gap-2"
            onClick={() => navigate(getBackRoutePath())}
          >
            <ArrowLeft />
            Back
          </Button>

          <div className="hidden items-center rounded-lg border border-black md:flex">
            <button
              className="h-full border-r border-black p-2"
              type="button"
              onClick={handleUndo}
              disabled={undoStack.length === 0}
            >
              <Undo />
            </button>
            <button className="p-2" type="button" onClick={handleRedo} disabled={redoStack.length === 0}>
              <Redo />
            </button>
          </div>
        </div>

        {/* Center panel */}
        <div className="text-center">
          <Text className="hidden font-medium md:block">Edit Transcript</Text>
        </div>

        {/* Right panel */}
        <div className="flex items-center justify-end">
          <Text className="mr-7 opacity-70">{isPendingDraftUpdate ? 'Saving in progress' : 'Saved'}</Text>

          <ButtonCluster
            text="Update"
            onClick={handlePublishButton}
            isLoading={isPendingPublish}
            disabled={form.changed || isPendingDraftUpdate || isPendingPublish}
          >
            <DropdownMenu.Item title="Download" onClick={handleDownloadButton} />
          </ButtonCluster>
        </div>
      </div>

      {/* Content */}
      <div className="flex justify-center bg-[#F2F3F4] p-10 py-14">
        <textarea
          ref={textareaRef}
          className="mb-20 w-full max-w-4xl resize-none overflow-hidden rounded-lg border-none p-10 font-sfMono text-base leading-7 shadow focus:outline-none focus:ring-0"
          placeholder="Enter text here..."
          value={form.values.transcript}
          onChange={handleChange}
          rows={1}
        />
      </div>

      <GenerateNewAudioModal
        isOpen={isGenerateAudioModalOpen}
        handleClose={handleCloseGenerateAudioModal}
        handleGenerate={handleGenerateAudioButton}
        isLoading={isPendingTriggerSynthesis}
      />
    </div>
  );
}

const GenerateNewAudioModal = ({ isOpen, handleClose, handleGenerate, isLoading }) => {
  if (!isOpen) return null;

  return (
    <Modal className="z-[999]" type="default" isOpen={isOpen} onClose={handleClose} noPadding>
      <Text className="text-3xl font-bold">Generate new Audio</Text>

      <Text className="my-4 text-base" variant={typography.body_small}>
        You have made edits to the transcript. Do you want to generate new audio from the updated transcript?
      </Text>

      <div className="mt-5 flex justify-end gap-4">
        <Button variant={buttons.variant.secondary} onClick={handleClose}>
          No thank you
        </Button>
        <ActionButton text="Generate" onClick={handleGenerate} isLoading={isLoading} />
      </div>
    </Modal>
  );
};
