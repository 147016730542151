import { DateTime } from 'luxon';
import { useState } from 'react';

import { CONTENT_VISIBILITY, LOCALES } from '@pray/shared/constants';
import useForm from '@pray/shared/hooks/useForm';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';

import { useStudioContext } from 'context/StudioContext';
import { isSafari } from 'utils/navigation';

import useQueryParams from './useQueryParams';

export default function useContentDetails({
  data = null,
  isVideo = false,
  maxDescriptionLength = 200,
  onContentSaved = null,
  onContentDeleted = null,
  onContentImageRemoved = null,
}) {
  const { params } = useQueryParams();
  const [isSaving, setIsSaving] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist.id;
  const queryLocale = params.locale;

  const isLocaleSupported = Object.values(LOCALES).find((locale) => locale === queryLocale);

  const form = useForm({
    locale: isLocaleSupported ? queryLocale : data?.locale || LOCALES.DEFAULT,
    title: data?.title || '',
    description: data?.description || '',
    primary_image_url: data?.primary_image_url || '',
    thumbnail_image_url: data?.thumbnail_image_url || '',
    ...getPublishParameters(data),
  });

  const { locale } = form.values;
  const isPrimaryContent = locale === LOCALES.DEFAULT;

  const savePrimaryContent = async () => {
    const errors = {};
    const { title, description, scheduled_date: scheduledDate, scheduled_time: scheduledTime } = form.values;

    if (!title.trim()) {
      errors.title = 'Invalid title';
    }

    if (description.trim() && description.trim().length > maxDescriptionLength) {
      errors.description = 'Invalid description';
    }

    // Check if the scheduled date and time are valid if the content is scheduled
    if (!(scheduledDate && scheduledTime) && form.values.visibility === CONTENT_VISIBILITY.SCHEDULE) {
      if (!scheduledDate) {
        errors.scheduled_date = 'Invalid date';
      }

      if (!scheduledTime) {
        errors.scheduled_time = 'Invalid time';
      }
    }

    // Check if the scheduled date time is in the past if the user selected the "schedule" option
    if (scheduledDate && scheduledTime && form.values.visibility === CONTENT_VISIBILITY.SCHEDULE) {
      const scheduledDateTime = DateTime.fromFormat(`${scheduledDate} ${scheduledTime}`, 'yyyy-MM-dd HH:mm');

      if (scheduledDateTime < DateTime.now()) {
        errors.scheduled_date = 'Past date';
        errors.scheduled_time = 'Past time';
      }
    }

    form.setErrors(errors);

    if (Object.keys(errors).length) return;

    let imageUrl;

    // upload cover image
    if (form.values.primary_image_file) {
      try {
        const response = await s3Service.signAndUpload({
          file: form.values.primary_image_file,
          type: 'content',
          signParams: {
            content_id: data.id || null,
          },
        });
        imageUrl = response.url;
      } catch (err) {
        form.setError('primary_image_url', err);
        return;
      }
    }

    if (form.values.thumbnail_image_file) {
      try {
        const response = await s3Service.signAndUpload({
          file: form.values.thumbnail_image_file,
          type: 'contentThumbnail',
          signParams: {
            content_id: data.id || null,
          },
        });
        imageUrl = response.url;
      } catch (err) {
        form.setError('thumbnail_image_url', err);
        return;
      }
    }

    const isPublished = form.values.visibility !== CONTENT_VISIBILITY.PRIVATE;
    let publishedAt =
      scheduledDate && scheduledTime
        ? DateTime.fromFormat(`${scheduledDate} ${scheduledTime}`, 'yyyy-MM-dd HH:mm').toUTC()
        : null;

    // If the content is published and doesn't have a published_at date, we need to set it to now
    if (isPublished && !publishedAt) {
      publishedAt = DateTime.now().toUTC();
    }

    // If we are updating to the "Public" visibility, we need to set the published_at date to now
    // if the published_at date is in the future
    if (
      form.values.visibility === CONTENT_VISIBILITY.PUBLIC &&
      publishedAt &&
      // @ts-ignore - Luxon doesn't have a `isBefore` method
      DateTime.now() < DateTime.fromISO(publishedAt)
    ) {
      publishedAt = DateTime.now().toUTC();
    }

    const updates = {
      artistId,
      contentId: data.id,
      title: form.values.title,
      description: form.values.description?.trim(),
      isPublished,
      publishedAt: !isPublished ? null : publishedAt,
    };

    if (isVideo) {
      updates.thumbnailUrl = imageUrl || form.values.thumbnail_image_url;
    } else {
      updates.primaryImageUrl = imageUrl || form.values.primary_image_url;
    }

    await studioService.content.updateArtistContent(updates);
  };

  const saveLocalizedContent = async () => {
    if (!form.values.primary_image_file) return;

    let imageUrl;

    // upload cover image
    const fileNameParts = form.values.primary_image_file.name.split('.');
    const extension = fileNameParts.pop();
    const fileName = `${fileNameParts.join('.')}_${locale}.${extension}`;

    try {
      const response = await s3Service.signAndUpload({
        type: 'content',
        file: form.values.primary_image_file,
        fileName,
        signParams: {
          content_id: data.id || null,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      form.setError('primary_image_url', err);
      return;
    }

    const updates = {
      artistId,
      contentId: data.id,
      locale: form.values.locale,
      primaryImageUrl: imageUrl || form.values.primary_image_url,
    };

    await studioService.contentTranslation.updateLocalizedContent(updates);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsSaving(true);

      if (isPrimaryContent) {
        await savePrimaryContent();
      } else {
        await saveLocalizedContent();
      }

      onContentSaved?.();
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveContentImage = async () => {
    if (isPrimaryContent) {
      await studioService.content.updateArtistContent({
        artistId,
        contentId: data.id,
        primaryImageUrl: '',
      });
    } else {
      await studioService.contentTranslation.updateLocalizedContent({
        artistId,
        contentId: data.id,
        locale,
        primaryImageUrl: '',
      });
    }

    onContentImageRemoved?.();
  };

  const handleDeleteContent = async () => {
    if (isPrimaryContent) {
      await studioService.content.deleteArtistContent({
        artistId,
        contentIds: [data.id],
      });
    } else {
      await studioService.contentTranslation.deleteLocalizedContent({
        artistId,
        contentId: data.id,
        locale,
      });
    }

    onContentDeleted?.();
  };

  return {
    form,
    isSaving,
    handleSubmit,
    handleDeleteContent,
    handleRemoveContentImage,
  };
}

function getPublishParameters(data) {
  // Default publish parameters are for a private content
  const parameters = {
    visibility: CONTENT_VISIBILITY.PRIVATE,
    scheduled_date: null,
    scheduled_time: null,
  };

  // Detecting if we are using Safari browser and set default schedule date for the next day
  if (isSafari()) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    parameters.scheduled_date = DateTime.fromJSDate(tomorrow).toFormat('yyyy-MM-dd');
    parameters.scheduled_time = DateTime.fromJSDate(tomorrow).toFormat('HH:mm');
  }

  if (!data || !data.is_published) {
    return parameters;
  }

  // If content is published, we need to check if it's scheduled or not
  if (!data.published_at) {
    return {
      ...parameters,
      visibility: CONTENT_VISIBILITY.PUBLIC,
    };
  }

  // If content is published and has a published_at date, it's scheduled
  // We need to set the scheduled_date and scheduled_time parameters
  return {
    ...parameters,
    // Check if the scheduled date is in the the future in order to set the visibility to 'schedule'
    visibility:
      DateTime.now() < DateTime.fromISO(data.published_at) ? CONTENT_VISIBILITY.SCHEDULE : CONTENT_VISIBILITY.PUBLIC,
    scheduled_date: DateTime.fromISO(data.published_at).toFormat('yyyy-MM-dd'),
    scheduled_time: DateTime.fromISO(data.published_at).toFormat('HH:mm'),
  };
}
