import { useState, useEffect, useMemo } from 'react';

import studioService from '@pray/shared/services/studioService';
import type { GetAllContentSeriesResponseData } from '@pray/shared/services/studioService/contentSeries/types';
import type { GetDailySeriesBasedOnArtistResponseData } from '@pray/shared/services/studioService/dailySeries/types';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';
import useLeaderContentSeries from 'hooks/contentSeries/useLeaderContentSeries';
import useLeaderPrayRadioTvContentSeries from 'hooks/contentSeries/useLeaderPrayRadioTvContentSeries';
import useLeaderDailySeries from 'hooks/dailySeries/useLeaderDailySeries';
import useAppRoutes from 'hooks/useAppRoutes';

import { SERIES_TABS } from '../constants';
import { Playlist, PlaylistTab, SeriesTabsType } from '../types';

export function usePlaylistsPage(activeTab: SeriesTabsType) {
  const appRoutes = useAppRoutes();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);

  const dailySeriesQuery = useLeaderDailySeries(artistId);
  const contentSeriesQuery = useLeaderContentSeries(artistId);
  const prayRadioTvContentSeriesQuery = useLeaderPrayRadioTvContentSeries(artistId);

  const hasMoreData = activeTab === SERIES_TABS.SERIES ? contentSeriesQuery.hasNextPage : dailySeriesQuery.hasNextPage;
  const isLoading =
    contentSeriesQuery.isLoading || dailySeriesQuery.isLoading || prayRadioTvContentSeriesQuery.isLoading;
  const isFetching =
    contentSeriesQuery.isFetching || dailySeriesQuery.isFetching || prayRadioTvContentSeriesQuery.isFetching;

  const dailySeries = dailySeriesQuery.data?.pages.flatMap((page) => page.data) || [];
  const contentSeries = contentSeriesQuery.data?.pages.flatMap((page) => page.data) || [];
  const restrictedPlaylists = prayRadioTvContentSeriesQuery.data || [];

  const playlistTabs: PlaylistTab[] = [
    { title: 'Content', path: appRoutes.artistContentPlaylists() },
    { title: 'Dailies', path: appRoutes.artistDailiesPlaylists() },
  ];

  useEffect(() => {
    if (isLoading) return;

    if (activeTab === SERIES_TABS.SERIES) {
      const filteredPlaylists = contentSeries.filter((item) => !restrictedPlaylists.some(({ id }) => id === item.id));
      setPlaylists(mapContentSeriesData(filteredPlaylists));
      return;
    }

    if (activeTab === SERIES_TABS.DAILES) {
      setPlaylists(mapDailySeriesData(dailySeries));
    }
  }, [activeTab, isLoading, contentSeriesQuery.data, dailySeriesQuery.data, restrictedPlaylists]);

  const fetchPlaylistsData = () => {
    if (activeTab === SERIES_TABS.SERIES) {
      contentSeriesQuery.fetchNextPage();
    } else {
      dailySeriesQuery.fetchNextPage();
    }
  };

  const handleOnDragEnd = async ({ destination, source }) => {
    if (!destination || !artistId) return;

    const reorderedPlaylists = reorderPlaylists(playlists, source.index, destination.index);
    setPlaylists(reorderedPlaylists);

    const newIndex = destination.index;
    const orderIdBefore = newIndex > 0 ? reorderedPlaylists[newIndex - 1].id : null;
    const orderIdAfter = newIndex < reorderedPlaylists.length - 1 ? reorderedPlaylists[newIndex + 1].id : null;
    const playlistId = playlists[source.index].id;

    try {
      if (activeTab === SERIES_TABS.SERIES) {
        await studioService.contentSeries.updateContentSeries({
          artistId,
          contentSeriesId: playlistId,
          orderIdBefore,
          orderIdAfter,
        });
        contentSeriesQuery.refetch();
      }

      if (activeTab === SERIES_TABS.DAILES) {
        await studioService.dailySeries.updateDailySeries({
          artistId,
          dailySeriesId: playlistId,
          orderIdBefore,
          orderIdAfter,
        });
        dailySeriesQuery.refetch();
      }
    } catch (error) {
      logger.error('Error reordering playlists', error);
    }
  };

  const isEmpty = useMemo(() => {
    if (!artistId || isLoading || isFetching) return false;

    if (activeTab === SERIES_TABS.SERIES) {
      return contentSeries.length === 0 && restrictedPlaylists.length === 0;
    }

    return dailySeries.length === 0;
  }, [activeTab, isLoading, isFetching]);

  return {
    activeTab,
    hasMoreData,
    isAddMenuOpen,
    isLoading,
    isEmpty,
    playlists,
    playlistTabs,
    restrictedPlaylists,
    fetchPlaylistsData,
    handleOnDragEnd,
    setIsAddMenuOpen,
  };
}

const reorderPlaylists = (list: Playlist[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const mapDailySeriesData = (dailySeriesData: GetDailySeriesBasedOnArtistResponseData[]): Playlist[] => {
  return dailySeriesData.map((playlist) => ({
    id: playlist.id,
    title: playlist.title,
    handle: playlist.handle,
    description: playlist.description,
    image_url: playlist.background_image_url || playlist.image_url,
    is_visible: !playlist.disabled,
    rss_feed_url: playlist.rss_feed_url,
    type: 'daily',
  }));
};

const mapContentSeriesData = (contentSeriesData: GetAllContentSeriesResponseData[]): Playlist[] => {
  return contentSeriesData.map((contentSeries) => ({
    ...contentSeries,
    type: 'content',
  }));
};
