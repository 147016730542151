import { AMPLITUDE_KEY, ENV } from '@pray/shared/config';
import { EVENTS, LOCAL_STORAGE, PLATFORM_TYPES, SESSION_STORAGE } from '@pray/shared/constants';
import { parseQueryString } from '@pray/shared/utils/parseFromQuery';

function initializeStorage() {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(SESSION_STORAGE.NAVIGATION_HISTORY, []);
  }
}

initializeStorage();

const getEnvKey = (key) => {
  if (['master', 'qa-2'].includes(ENV)) return key;
  return `${ENV}/${key}`;
};

export default {
  upsertToStorage(key, value, isAddedToCookies) {
    if (typeof window === 'undefined') return null;
    const envKey = getEnvKey(key);
    try {
      if (key === LOCAL_STORAGE.USER || key === LOCAL_STORAGE.DEVICE_DETAILS.KEY) {
        localStorage.setItem(envKey, JSON.stringify(value));
        if (isAddedToCookies) _setCookie(envKey, JSON.stringify(value));
        if (key === LOCAL_STORAGE.USER) {
          document.dispatchEvent(new CustomEvent(EVENTS.DOM_EVENTS.AUTH_STATE_CHANGED));
        }
      } else {
        localStorage.setItem(envKey, value);
        if (isAddedToCookies) _setCookie(envKey, value);
      }
      return true;
    } catch (err) {
      return false;
    }
  },

  getFromStorage(key) {
    if (typeof window === 'undefined') return null;
    const envKey = getEnvKey(key);
    try {
      const item = localStorage.getItem(envKey) || _getCookie(envKey);
      if (key === LOCAL_STORAGE.USER || key === LOCAL_STORAGE.DEVICE_DETAILS.KEY) {
        return JSON.parse(item);
      }
      return item;
    } catch (err) {
      return null;
    }
  },

  removeFromStorage(key, isRemovedFromCookies) {
    if (typeof window === 'undefined') return;
    const envKey = getEnvKey(key);
    localStorage.removeItem(envKey);
    if (isRemovedFromCookies) _removeCookie(envKey);
    if (key === LOCAL_STORAGE.USER) {
      document.dispatchEvent(new CustomEvent(EVENTS.DOM_EVENTS.AUTH_STATE_CHANGED));
    }
  },

  /**
   * @typedef {Object} UserType
   * @property {string} [id] User id
   * @property {string} [email] User email
   * @property {string} [name] User name
   * @property {string} [first_name] User first name
   * @property {string} [last_name] User last name
   * @property {string} [profile_image] User profile image
   * @property {boolean} [is_logged_in] User login state
   * @property {boolean} [is_registered] User registration state
   *
   * @returns {UserType | null} Stored user
   */
  getUser() {
    return this.getFromStorage(LOCAL_STORAGE.USER) || null;
  },

  getDeviceId() {
    const previouslyStoredDeviceId = this.getFromStorage(LOCAL_STORAGE.PRAY_DEVICE_ID);
    if (previouslyStoredDeviceId) return previouslyStoredDeviceId;

    const oldDeviceIdGeneratedByAmplitude = _getDeviceIdPreviouslySetByAmplitude();
    if (oldDeviceIdGeneratedByAmplitude) {
      this.upsertToStorage(LOCAL_STORAGE.PRAY_DEVICE_ID, oldDeviceIdGeneratedByAmplitude, true);
      return oldDeviceIdGeneratedByAmplitude;
    }

    const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let newDeviceId = 'pray_';
    for (let i = 0; i < 20; i += 1) {
      newDeviceId += base64Chars.charAt(Math.floor(Math.random() * base64Chars.length));
    }
    this.upsertToStorage(LOCAL_STORAGE.PRAY_DEVICE_ID, newDeviceId, true);
    return newDeviceId;
  },

  isUserLoggedIn() {
    const user = this.getUser();
    if (user && user.is_logged_in === true) return true;
    return false;
  },

  isUserRegistered() {
    const user = this.getUser();
    return user && (user.is_registered === 1 || user.is_registered === true || user.is_registered === 'true');
  },

  isFirstListenAlreadyTracked() {
    const deviceDetails = this.getFromStorage(LOCAL_STORAGE.DEVICE_DETAILS.KEY);
    return !!(deviceDetails && deviceDetails.isFirstListenAlreadyTracked === true);
  },

  trackRouteChanged(pathname) {
    const history = this.getNavigationHistory();
    history.push(pathname);
    sessionStorage.setItem(SESSION_STORAGE.NAVIGATION_HISTORY, JSON.stringify(history));
  },

  getNavigationHistory() {
    const json = sessionStorage.getItem(SESSION_STORAGE.NAVIGATION_HISTORY);
    if (!json) return [];
    return JSON.parse(json);
  },

  saveUserExperiments(experiments) {
    const user = this.getUser();
    const guestUserExperimentsKey = `${LOCAL_STORAGE.ABN_EXPERIMENTS}_guestUser`;
    const userIdExperimentsKey = `${LOCAL_STORAGE.ABN_EXPERIMENTS}_${user.id}`;
    const experimentsKey = user.id ? userIdExperimentsKey : guestUserExperimentsKey;

    this.upsertToStorage(experimentsKey, JSON.stringify(experiments));
  },

  getUserExperiments() {
    const user = this.getUser();

    /**
     * This 'guestUser' key will be used to save guest user experiments because they won't
     * have a valid user id before the authentication endpoint call.
     */
    const guestUserExperimentsKey = `${LOCAL_STORAGE.ABN_EXPERIMENTS}_guestUser`;

    /**
     * This 'user id' key will be used to save authenticated user experiments.
     */
    const userIdExperimentsKey = `${LOCAL_STORAGE.ABN_EXPERIMENTS}_${user.id}`;

    /**
     * Define the key to use based on user authentication state
     */
    const experimentsKey = user.id ? userIdExperimentsKey : guestUserExperimentsKey;

    /**
     * The previous experiments storage was not user specific. This logic will be used to move any
     * existing experiments to the new model under a user key.
     */
    const experimentsFromDeprecatedStorage = this.getFromStorage(LOCAL_STORAGE.ABN_EXPERIMENTS);

    let experiments;

    if (experimentsFromDeprecatedStorage) {
      experiments = experimentsFromDeprecatedStorage;
      /* Move deprecated experiments to a user key */
      this.upsertToStorage(experimentsKey, experiments);
      this.removeFromStorage(LOCAL_STORAGE.ABN_EXPERIMENTS);
    } else {
      /* Retrieve experiments stored for 'guestUser' */
      const guestUserExperiments = this.getFromStorage(guestUserExperimentsKey);
      /* If there are guest user experiments but the user is currently authenticated... */
      if (guestUserExperiments && user.id) {
        experiments = guestUserExperiments;
        /* Move guest user experiments to the current user key */
        this.upsertToStorage(userIdExperimentsKey, guestUserExperiments);
        this.removeFromStorage(guestUserExperimentsKey);
      } else {
        /* Otherwise, retrieve the experiments stored for the authenticated user */
        experiments = this.getFromStorage(userIdExperimentsKey);
      }
    }

    return experiments ? JSON.parse(experiments) : {};
  },

  isChromeExtension() {
    const platformType = sessionStorage.getItem(SESSION_STORAGE.PLATFORM_TYPE);
    return platformType === PLATFORM_TYPES.CHROME_EXTENSION;
  },

  initializePlatformType() {
    const queryParams = parseQueryString();
    if (queryParams.platform_type === PLATFORM_TYPES.CHROME_EXTENSION) {
      sessionStorage.setItem(SESSION_STORAGE.PLATFORM_TYPE, PLATFORM_TYPES.CHROME_EXTENSION);
    }
  },
};

// currently setting cookies to ensure cross domain login available for pray-web
// remove after pray-web is completely deprecated

function _getCookie(cname) {
  if (typeof document === 'undefined') return null;
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function _setCookie(cname, cvalue, exdays) {
  if (typeof document === 'undefined') return;
  exdays = exdays || 365;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

  let cookie = `${cname}=${cvalue}; expires=${d.toUTCString()}; + ";`;
  if (LOCAL_STORAGE.COOKIE_DOMAIN) cookie += `domain=${LOCAL_STORAGE.COOKIE_DOMAIN};`;
  cookie += `path=/`;

  document.cookie = cookie;
}

function _removeCookie(cname) {
  if (typeof document === 'undefined') return;

  let cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  if (LOCAL_STORAGE.COOKIE_DOMAIN) cookie += `domain=${LOCAL_STORAGE.COOKIE_DOMAIN};`;
  cookie += `path=/`;

  document.cookie = cookie;
}

function _getDeviceIdPreviouslySetByAmplitude() {
  let deviceId = null;
  // amplitude cookie key details:
  // https://github.com/amplitude/Amplitude-JavaScript/blob/94369161fdcdf8edf04513e7a3e9780ba9828911/src/amplitude-client.js#L102
  const amplitudeCookieKey = `amp_${AMPLITUDE_KEY.slice(0, 6)}`;
  const amplitudeCookieValue = _getCookie(amplitudeCookieKey);

  // deviceId is first in concatenated string with '.' separator
  // https://github.com/amplitude/Amplitude-JavaScript/blob/94369161fdcdf8edf04513e7a3e9780ba9828911/src/Metadata-storage.js#L78
  if (amplitudeCookieValue) {
    [deviceId] = amplitudeCookieValue.split('.');
  }

  return deviceId;
}
