import React, { useState } from 'react';

import { cn } from '@pray/shared/utils/styles';

import Button from '../Button/Button';
import { ChevronDown } from '../Icons/ChevronDown';
import Text from '../Text/Text';

export default function TextExpander({
  className = '',
  collapseNumWords = 30,
  expandButtonText = 'See more',
  collapseButtonText = 'See less',
  expanded = false,
  children,
}) {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const shouldCollapse = children.split(' ').length > collapseNumWords;
  const collapsedText = children.split(' ').slice(0, collapseNumWords).join(' ');
  const text = isExpanded || !shouldCollapse ? children : `${collapsedText}...`;

  return (
    <div className={className}>
      <Text>{text}</Text>
      {shouldCollapse && (
        <Button className="!flex items-center gap-2" onClick={() => setIsExpanded(!isExpanded)}>
          <span>{isExpanded ? collapseButtonText : expandButtonText}</span>
          <ChevronDown className={cn('transition-all duration-100 stroke-gray-500', isExpanded && 'rotate-180')} />
        </Button>
      )}
    </div>
  );
}
