export const VoiceSteps = {
  LOADING: 'loading',
  EMPTY: 'empty',
  VOICES: 'voices',
};

export const SampleUploadStatus = {
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const MOCK_VOICES = [
  {
    id: '1',
    name: 'Obi Wan Kenobi',
    isMain: true,
    audioUrl: 'https://www.myinstants.com/media/sounds/obi-wan-hello-there.mp3',
  },
  {
    id: '2',
    name: 'Luke Skywalker',
    isMain: false,
    audioUrl: 'https://www.soundboard.com/track/download/139420',
  },
  {
    id: '3',
    name: 'Darth Vader',
    isMain: false,
    audioUrl: 'https://www.soundboard.com/track/download/59593',
  },
];

export const DialogTypes = {
  EDIT: 'edit',
  DELETE: 'delete',
  SET_MAIN: 'setMain',
  UPLOAD: 'upload',
};

export const VOICE_MESSAGES = {
  DELETE: {
    SUCCESS: 'Voice deleted successfully',
    ERROR: 'Failed to delete voice',
  },
  SET_MAIN: {
    SUCCESS: 'Primary voice updated successfully',
    ERROR: 'Failed to update primary voice',
  },
  CREATE: {
    SUCCESS: 'Voice created successfully',
    ERROR: 'Failed to create voice',
  },
  UPDATE: {
    SUCCESS: 'Voice updated successfully',
    ERROR: 'Failed to update voice',
  },
};

export const MAX_SAMPLES = 5;
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB
export const MAX_FILES = 5;
export const ACCEPTED_AUDIO_FORMATS = ['.mp3', '.wav', '.m4a', '.aac'];
