import usePrayContext from 'hooks/usePrayContext';
import React, { useEffect, useState } from 'react';

import MessageModal from '@pray/shared/components/MessageModal/MessageModal';
import { login } from '@pray/shared/utils/auth';
import logger from '@pray/shared/utils/logger';
import { navigate } from '@pray/shared/utils/navigation';
import { parseQueryString, removeQueryParamsFromUrl } from '@pray/shared/utils/parseFromQuery';
import storage from '@pray/shared/utils/storage';

const MagicLogin = ({
  isSubscribeFlow = false,
  handleSuccessfulLogin = null,
  handleSuccessfulTokenLogin = null,
  onMessageModalClosed = null,
  onMagicLoginChecked = null,
}) => {
  const prayContext = usePrayContext();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageModalMessage, setMessageModalMessage] = useState('');

  const checkForMagicLinkLoginQueryParams = async () => {
    const query = parseQueryString();
    const magicEmail = query.email;
    const magicCountryCode = query.country_code;
    const magicPhone = query.phone;
    const magicCode = query.code;
    const magicLoginToken = query.mlt || query.magic_login_token;

    const isInfoForCodeAvailable = !!(magicEmail || (magicPhone && magicCountryCode));
    const isCodeAndInfoAvailable = !!(magicCode && isInfoForCodeAvailable);
    const isMagicLoginTokenAvailable = !!magicLoginToken;

    if (isMagicLoginTokenAvailable) {
      await _tokenMagicLogin(magicLoginToken);
      return;
    }

    if (isCodeAndInfoAvailable) {
      const form = {
        email: magicEmail || null,
        country_code: magicCountryCode || null,
        phone: magicPhone || null,
        code: magicCode || null,
      };

      try {
        const errorMessage = await login(form);

        let modalMessage = 'You have been successfully logged in';

        if (errorMessage === null) {
          const user = storage.getUser();
          // update user in prayContext
          prayContext.setCurrentUser(user);
          // redirect subscribed users to Discover page in subscribe flows
          if (isSubscribeFlow && user.is_subscribed) {
            navigate('/discover/');
            return;
          }
          // call success handler function if present
          if (handleSuccessfulLogin) handleSuccessfulLogin();
        } else if (errorMessage === 'magicLinkExpired') {
          modalMessage =
            'Your login link expired. Please click the new link in the email we just sent you to successfully log in';
        } else {
          modalMessage = 'Login to web failed, please try again';
        }

        setIsMessageModalOpen(true);
        setMessageModalMessage(modalMessage);
      } catch (error) {
        setIsMessageModalOpen(true);
        setMessageModalMessage('Login to web failed, please try again');
        logger.debug('Magic Login error', error);
      }
    }
  };

  const removeMagicLinkParamsFromUrl = () => {
    removeQueryParamsFromUrl({
      only: [
        'email',
        'code',
        'country_code',
        'phone',
        'mlt',
        'magic_login_token',
        '$web_only',
        '_branch_match_id',
        '_branch_referrer',
      ],
    });
  };

  async function _tokenMagicLogin(magicLoginToken) {
    // silently fail/succeed with token magic login
    try {
      const form = {
        magic_login_token: magicLoginToken,
      };

      await login(form);

      if (handleSuccessfulTokenLogin) {
        handleSuccessfulTokenLogin();
      } else if (handleSuccessfulLogin) {
        handleSuccessfulLogin();
      }
      removeMagicLinkParamsFromUrl();
    } catch (error) {
      logger.debug('error', error);
    }
  }

  const handleCloseMessageModal = () => {
    onMessageModalClosed?.();
    setIsMessageModalOpen(false);
    removeMagicLinkParamsFromUrl();
  };

  useEffect(() => {
    checkForMagicLinkLoginQueryParams().finally(() => {
      onMagicLoginChecked?.();
    });
  }, []);

  if (!isMessageModalOpen) return null;

  return <MessageModal message={messageModalMessage} closeModal={handleCloseMessageModal} />;
};

export default MagicLogin;
