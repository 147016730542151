import { DateTime } from 'luxon';
import React from 'react';

import { useAudioPlayer } from '@pray/shared/components/ContentPlayer/FullscreenContentPlayer/FullscreenContentPlayer';
import Button from '@pray/shared/components/ui/Button/Button';
import { Play } from '@pray/shared/components/ui/Icons/Play';
import Text from '@pray/shared/components/ui/Text/Text';
import { useVideoModal } from '@pray/shared/components/VideoModal/VideoModal';

import { AUDIO_PLAYER, LOCALES } from 'constants.js';

import placeholderImage from '../../../../assets/cover-placeholder.webp';

export default function ContentPreview({ content, locale = LOCALES.DEFAULT }) {
  const { playVideo } = useVideoModal();
  const { playContent } = useAudioPlayer();
  const imageUrl = content?.primary_image_url || content?.thumbnail_image_url || placeholderImage;

  const handleItemClick = () => {
    if (content.primary_audio_url) {
      playContent({
        contentId: content.id,
        locale,
        controlOptions: AUDIO_PLAYER.controlOptions,
      });
    } else if (content.primary_video_url) {
      playVideo({
        ...content,
        video_url: content.primary_video_url,
      });
    }
  };

  return (
    <div className="mt-2 flex flex-col px-4">
      <div className="group relative">
        <Button
          className="absolute flex size-full items-center justify-center from-[#00000016] to-[#00000016] group-hover:bg-gradient-to-r"
          onClick={handleItemClick}
        >
          <div className="flex size-11 items-center justify-center rounded-full bg-white pl-1">
            <Play />
          </div>
        </Button>

        <img src={imageUrl} alt="" className="size-[180px] rounded-md object-cover" />
      </div>

      <div className="mt-3">
        <Text className="block text-sm font-bold">Title</Text>
        <Text className="line-clamp-1 w-40 text-sm">{content.title}</Text>
      </div>

      {content.updated_at && (
        <div className="mt-3">
          <Text className="block text-sm font-bold">Modified</Text>
          <Text className="text-sm">{DateTime.fromISO(content.updated_at).toLocaleString(DateTime.DATETIME_MED)}</Text>
        </div>
      )}
    </div>
  );
}
