import { useState } from 'react';

import { buttons } from '@pray/shared/components/foundations';
import BackgroundImage from '@pray/shared/components/ui/BackgroundImage/BackgroundImage';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';
import Text from '@pray/shared/components/ui/Text/Text';
import { useAuth } from '@pray/shared/hooks/useAuth';

import { PLACEHOLDER_IMAGE } from 'constants.js';
import { PlaylistRemove } from 'images/icons';
import { Permissions } from 'utils/auth';

import ActionButton from '../../../../components/ActionButton/ActionButton';
import ContentStatus from '../../../../components/ContentStatus/ContentStatus';
import DeletePlaylistModal from '../../DeletePlaylistModal/DeletePlaylistModal';
import SearchContentModal from '../SearchContentModal/SearchContentModal';

import styles from './TabHeader.module.scss';

export default function TabHeader({
  title,
  image,
  description,
  status,
  selectionDetails,
  openDeleteConfirmationModal,
  handleDeletePlaylist,
  onUploadNewFileClick,
  onAddContentToPlaylist,
  onEditPlaylistButtonClick,
  hideButtons,
  onRetriggerTranslations,
  triggerTranslationPending,
}) {
  const [isSearchContentModalOpen, setIsSearchContentModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { selected } = selectionDetails;
  const placeholderImage = PLACEHOLDER_IMAGE;

  return (
    <div className={styles.header}>
      <BackgroundImage
        className={styles.image}
        src={image || placeholderImage}
        onError={(event) => {
          event.target.src = placeholderImage;
        }}
      />
      <div className={styles.info}>
        <Text className={styles.title}>{title}</Text>
        <Text className={styles.description}>{description}</Text>
        <ContentStatus status={status} />
      </div>

      {!hideButtons && (
        <div className={styles.actions}>
          {Boolean(selected.length) && (
            <div className={styles.bulkActions}>
              <Text className={styles.selectedText}>
                {selected.length} item{selected.length > 1 ? 's' : ''} selected
              </Text>
              <Button variant={buttons.variant.secondary} onClick={() => openDeleteConfirmationModal()}>
                <PlaylistRemove /> <span>Remove from Series</span>
              </Button>
              <div className={styles.verticalSeparator} />
            </div>
          )}

          <PlaylistButtons
            onAddContentClick={() => setIsSearchContentModalOpen(true)}
            onUploadFileClick={onUploadNewFileClick}
            onEditPlaylistClick={onEditPlaylistButtonClick}
            onDeletePlaylistClick={() => setIsDeleteModalOpen(true)}
            onRetriggerTranslations={onRetriggerTranslations}
            triggerTranslationPending={triggerTranslationPending}
          />
        </div>
      )}

      <DeletePlaylistModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeletePlaylist();
          setIsDeleteModalOpen(false);
        }}
      />

      {isSearchContentModalOpen && (
        <SearchContentModal
          onAdd={(selectedContent) => {
            onAddContentToPlaylist(selectedContent);
            setIsSearchContentModalOpen(false);
          }}
          onUploadContentClick={() => {
            setIsSearchContentModalOpen(false);
            onUploadNewFileClick();
          }}
          onClose={() => setIsSearchContentModalOpen(false)}
        />
      )}
    </div>
  );
}

function PlaylistButtons({
  onAddContentClick,
  onUploadFileClick,
  onEditPlaylistClick,
  onDeletePlaylistClick,
  onRetriggerTranslations,
  triggerTranslationPending,
}) {
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  function toggleOpenAddMenu(event) {
    event?.stopPropagation();
    setIsMoreMenuOpen(false);
    setIsAddMenuOpen((isOpen) => !isOpen);
  }

  function toggleOpenMoreMenu(event) {
    event?.stopPropagation();
    setIsAddMenuOpen(false);
    setIsMoreMenuOpen((isOpen) => !isOpen);
  }

  const { permissions } = useAuth();
  const isAdmin = permissions.has(Permissions.STUDIO_ADMIN);

  return (
    <div className="flex items-center" role="none" onClick={() => setIsAddMenuOpen(false)}>
      <DropdownMenu
        isOpen={isAddMenuOpen}
        onClose={toggleOpenAddMenu}
        disabled={triggerTranslationPending}
        component={
          <ActionButton
            text="Add"
            className="h-10 rounded-r-none disabled:border-none"
            onClick={toggleOpenAddMenu}
            isLoading={triggerTranslationPending}
          />
        }
      >
        <DropdownMenu.Item
          title="Add from existing content"
          description="Add from previously uploaded files"
          onClick={() => {
            toggleOpenAddMenu();
            onAddContentClick();
          }}
        />
        <DropdownMenu.Item
          title="Upload a new file"
          description="Browse from your device"
          onClick={() => {
            toggleOpenAddMenu();
            onUploadFileClick();
          }}
        />
      </DropdownMenu>

      <DropdownMenu
        disabled={triggerTranslationPending}
        hasSeparator={false}
        isOpen={isMoreMenuOpen}
        onClose={toggleOpenMoreMenu}
        component={
          <Button
            variant={buttons.variant.primary}
            className="size-10 rounded-l-none !border-l-[#56585E] p-3 disabled:!border-l-gray-200"
            onClick={toggleOpenMoreMenu}
            disabled={triggerTranslationPending}
          >
            <ChevronDown fill={triggerTranslationPending ? 'black' : '#c1c1c1'} />
          </Button>
        }
      >
        <DropdownMenu.Item
          title="Edit series"
          onClick={() => {
            onEditPlaylistClick();
          }}
        />

        {isAdmin && (
          <DropdownMenu.Item
            title="Re-Translate"
            onClick={() => {
              toggleOpenMoreMenu();
              onRetriggerTranslations();
            }}
          />
        )}

        <DropdownMenu.Item
          title="Delete"
          onClick={() => {
            toggleOpenMoreMenu();
            onDeletePlaylistClick();
          }}
        />
      </DropdownMenu>
    </div>
  );
}
