import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { CONTENT_VISIBILITY, LOCALES } from '@pray/shared/constants';
import s3Service from '@pray/shared/services/s3Service';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import FooterPortal from 'components/layouts/FooterPortal';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TranslateBanner from 'components/pages/StudioPage/components/TranslateBanner/TranslateBanner';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';
import usePlaylistDetails from 'hooks/usePlaylistDetails';

import PlaylistEditForm from './PlaylistEditForm/PlaylistEditForm';
import ActionButton from '../../../components/ActionButton/ActionButton';
import TabPage from '../../../components/TabPage/TabPage';
import DeletePlaylistModal from '../DeletePlaylistModal/DeletePlaylistModal';
import PlaylistPreview from '../PlaylistPreview/PlaylistPreview';

export default function PlaylistForm({
  form = null,
  isCreate = false,
  playlist = {},
  translations = [],
  setPlaylist = null,
  setTranslations = null,
}) {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const { id: contentSeriesId } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const locale = form.values.locale || LOCALES.DEFAULT;
  const { isLoading, fetchContentSeriesData, ...playlistDetails } = usePlaylistDetails({
    artistId,
    contentSeriesId,
    locale,
  });

  const isProcessing = !!playlist.is_processing;
  const isPrimary = form.values.locale === LOCALES.DEFAULT;

  const isLocalized = useMemo(() => {
    return playlist.locale === locale || translations.find((item) => item.locale === locale)?.is_localized;
  }, [form.values.locale, playlist, translations]);

  const selectedItem = useMemo(() => {
    if (!form.values.locale) return translations.find((item) => item.locale === LOCALES.DEFAULT);
    return translations.find((item) => item.locale === form.values.locale);
  }, [form.values.locale, translations]);

  const language = selectedItem?.language || '';

  const savePrimaryPlaylist = async () => {
    const errors = {};
    const { title, description } = form.values;
    const isVisible = form.values.visibility === CONTENT_VISIBILITY.PUBLIC;
    const { contentOrder } = form.values;
    const isCreatePlaylist = !form.values.id;

    if (form.values.title.trim().length < 2) {
      errors.title = 'Invalid title';
    }

    if (form.values.description.trim().length < 2) {
      errors.description = 'Invalid description';
    }

    if (!form.values.image_url && !form.values.image_file) {
      errors.image_url = 'Invalid image';
    }

    form.setErrors(errors);

    if (Object.keys(errors).length) return;

    let imageUrl;

    // upload playlist image
    if (form.values.image_file) {
      try {
        const { url } = await s3Service.signAndUpload({
          file: form.values.image_file,
          type: 'contentSeries',
          signParams: {
            content_series_id: form.values.id || null,
          },
        });
        imageUrl = url;
      } catch (err) {
        form.setError('image_url', err);
        return;
      }
    }

    // create/update playlist
    if (isCreatePlaylist) {
      const playlist = await studioService.contentSeries.createContentSeries({
        artistId,
        title,
        description,
        imageUrl,
        isVisible,
        contentOrder,
      });

      toast.show({ success: 'Series created successfully' });

      navigate(appRoutes.artistPlaylistDetailsForm(playlist.id));
    } else {
      await studioService.contentSeries.updateContentSeries({
        artistId,
        contentSeriesId: form.values.id,
        title,
        description,
        imageUrl,
        isVisible,
        contentOrder,
      });

      toast.show({ success: 'Series updated successfully' });
      form.setErrors({});
      await fetchContentSeriesData();
    }
  };

  const saveLocalizedPlaylist = async () => {
    const { id: contentSeriesId, locale, image_file: imageFile } = form.values;

    if (!imageFile) return;

    // upload thumbnail image
    const fileNameParts = imageFile.name.split('.');
    const extension = fileNameParts.pop();
    const fileName = `${fileNameParts.join('.')}_${locale}.${extension}`;

    let imageUrl;

    try {
      const response = await s3Service.signAndUpload({
        fileName,
        file: imageFile,
        type: 'contentSeries',
        signParams: {
          content_series_id: contentSeriesId || null,
        },
      });
      imageUrl = response.url;
    } catch (err) {
      form.setError('image_url', err);
      return;
    }

    const updates = {
      artistId,
      contentSeriesId,
      locale,
      imageUrl,
    };

    await studioService.contentSeriesTranslation.updateLocalizedContentSeries(updates);
  };

  const handleSavePlaylist = async () => {
    try {
      setIsSaving(true);

      if (isPrimary) {
        await savePrimaryPlaylist();
      } else {
        await saveLocalizedPlaylist();
      }
    } catch (err) {
      logger.debug('Failed to save playlist', err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeletePlaylist = async () => {
    await studioService.contentSeries.deleteContentSeries({
      artistId,
      contentSeriesId,
    });

    navigate(appRoutes.artistPlaylists(), { replace: true });
  };

  const handleTranslateButtonClick = async () => {
    await studioService.contentSeriesTranslation.triggerContentSeriesTranslation({
      artistId,
      contentSeriesId,
      locale,
    });

    fetchContentSeriesData();
  };

  const renderActionButtons = () => {
    const isCreateForm = !contentSeriesId;

    return (
      <div className="flex flex-1 space-x-3">
        {isCreateForm && (
          <Button
            variant={buttons.variant.secondary}
            onClick={() => navigate(appRoutes.artistPlaylists(), { replace: true })}
          >
            Cancel
          </Button>
        )}
        <ActionButton
          isLoading={isSaving}
          disabled={!isCreateForm && !isLocalized}
          text={isSaving ? 'Saving' : 'Save'}
          onClick={handleSavePlaylist}
        />
        {!!playlist.id && isPrimary && (
          <OptionsDropdown isDisabled={!isLocalized || isSaving} onDelete={() => setIsDeleteModalOpen(true)} />
        )}
      </div>
    );
  };

  const renderTopRightButtons = () => (
    <div>
      <div className="hidden md:block">{renderActionButtons()}</div>
    </div>
  );

  useEffect(() => {
    form.setValues({
      ...form.values,
      id: playlist.id || '',
      title: playlist.title || '',
      description: playlist.description || '',
      image_url: playlist.image_url || '',
      visibility: playlist.is_visible === false ? CONTENT_VISIBILITY.PRIVATE : CONTENT_VISIBILITY.PUBLIC,
      contentOrder: playlist.content_order || form.values.contentOrder,
    });
  }, [playlist]);

  useEffect(() => {
    if (playlistDetails.playlist) {
      setPlaylist((prev) => ({ ...prev, ...playlistDetails.playlist }));
    }
    if (playlistDetails.translations.length) {
      setTranslations(playlistDetails.translations);
    }
  }, [playlistDetails.playlist, playlistDetails.translations]);

  const renderPlaylistForm = () => {
    if (isCreate) {
      return <PlaylistEditForm playlist={playlist} form={form} isPrimary={isPrimary} />;
    }

    return (
      <>
        {!isLoading && (!isLocalized || isProcessing) && (
          <div className="max-w-screen-md">
            <TranslateBanner
              title={`Translate "${playlist.title}" into ${language}`}
              description={`
                Pray.com will translate your series title and description into ${language}, and will translate each
                individual piece of content in this series into ${language} (including the titles, descriptions, and audio
                files for each piece of content). Each audio file will be translated into ${language} using advanced AI,
                ensuring effortless and accurate translation.
              `}
              buttonLabel="Translate"
              isProcessing={isProcessing}
              onTranslateButtonClick={handleTranslateButtonClick}
            />
          </div>
        )}
        {!isLoading && isLocalized && !isProcessing && (
          <>
            <PlaylistEditForm playlist={playlist} form={form} isPrimary={isPrimary} />
            <PlaylistPreview playlist={playlist} />
          </>
        )}
      </>
    );
  };

  const tabTitle = isCreate ? 'New Series' : 'Series Details';
  const viewName = isCreate ? VIEW_EVENT_NAMES.SERIES.CREATION : VIEW_EVENT_NAMES.SERIES.DETAILS;

  return (
    <TabPage title={tabTitle} data-viewname={viewName} topRightButtons={renderTopRightButtons()}>
      <div className="mt-4 flex flex-col gap-10 md:flex-row">{renderPlaylistForm()}</div>

      <FooterPortal>{renderActionButtons()}</FooterPortal>

      <DeletePlaylistModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={async () => {
          await handleDeletePlaylist();
          setIsDeleteModalOpen(false);
        }}
      />
    </TabPage>
  );
}

const OptionsDropdown = ({ isDisabled = false, onDelete = null }) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);

  const toggleOpenMoreMenu = () => {
    setIsMoreMenuOpen(!isMoreMenuOpen);
  };

  return (
    <DropdownMenu
      isOpen={isMoreMenuOpen}
      onClose={toggleOpenMoreMenu}
      component={
        <Button disabled={isDisabled} onClick={toggleOpenMoreMenu}>
          •••
        </Button>
      }
    >
      <DropdownMenu.Item
        title="Delete"
        onClick={() => {
          onDelete();
          toggleOpenMoreMenu();
        }}
      />
    </DropdownMenu>
  );
};
