import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation, useMatch } from 'react-router-dom';

import { CONTENT_VISIBILITY, LOCALES, CONTENT_ORDER } from '@pray/shared/constants';
import useForm from '@pray/shared/hooks/useForm';

import MainLayout from 'components/layouts/MainLayout';
import useAppRoutes from 'hooks/useAppRoutes';
import { Edit, Rocket } from 'images/icons';

import RssFeedDetails from './RssFeedDetails/RssFeedDetails';
import RssFeedDetailsForm from './RssFeedDetailsForm/RssFeedDetailsForm';
import RssFeedDistribution from './RssFeedDistribution/RssFeedDistribution';
import RssFeedForm from './RssFeedForm/RssFeedForm';
import BackLinkButton from '../../components/BackLinkButton/BackLinkButton';
import SideBarContentDetails from '../../components/SideBar/SideBarContentDetails';
import useSideBar from '../../components/SideBar/useSideBar';
import TabItem from '../../components/TabItem/TabItem';

export default function RssFeedTab() {
  const location = useLocation();
  const appRoutes = useAppRoutes();
  const playlistEditMatch = useMatch('/leaders/:artistId/rss-feed/:contentSeriesId/:action');
  const [playlist, setPlaylist] = useState({});
  const [translations, setTranslations] = useState([]);
  const setRenderDetails = useSideBar((state) => state.setRenderDetails);

  const initialValues = {
    id: '',
    title: '',
    description: '',
    image_url: '',
    visibility: CONTENT_VISIBILITY.PUBLIC,
    locale: LOCALES.DEFAULT,
    contentOrder: CONTENT_ORDER.MANUALLY.key,
  };

  const form = useForm(initialValues);

  const props = {
    form,
    playlist,
    translations,
    setPlaylist,
    setTranslations,
  };

  useEffect(() => {
    if (!playlistEditMatch) {
      setPlaylist({});
      form.setValues(initialValues);
    }
  }, [playlistEditMatch]);

  const renderRssFeedSideBar = () => {
    const { contentSeriesId, action } = playlistEditMatch.params;

    return <RssFeedDetailsItems appRoutes={appRoutes} contentSeriesId={contentSeriesId} activeItem={action} />;
  };

  useEffect(() => {
    if (playlistEditMatch) {
      setRenderDetails(renderRssFeedSideBar);
    }
  }, [playlistEditMatch, translations, location]);

  return (
    <MainLayout data-viewname="PRAY Studio - RSS Feed Tab">
      <Routes>
        <Route path="create" element={<RssFeedForm {...props} />} />
        <Route path=":id" element={<RssFeedDetails {...props} />} />
        <Route path=":id/details" element={<RssFeedDetailsForm {...props} />} />
        <Route path=":id/distribution" element={<RssFeedDistribution {...props} />} />
      </Routes>
    </MainLayout>
  );
}

const RssFeedDetailsItems = ({ appRoutes = null, contentSeriesId = '', activeItem = '', disabled = false }) => {
  return (
    <div className="flex flex-col gap-6 pt-5">
      <div className="mx-2 w-auto">
        <BackLinkButton label="Series" />
      </div>

      <SideBarContentDetails />

      <div className="[&_a]:px-3 [&_button]:px-3">
        <TabItem
          icon={Edit}
          text="Series Details"
          route={appRoutes.artistRssFeedDetailsForm(contentSeriesId)}
          disabled={disabled}
          active={activeItem === 'details'}
        />
        <TabItem
          icon={Rocket}
          text="Distribution"
          route={appRoutes.artistRssFeedDistribution(contentSeriesId)}
          disabled={disabled}
          active={activeItem === 'distribution'}
        />
      </div>
    </div>
  );
};
