import { Calendar } from 'lucide-react';
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';

import Text from '@pray/shared/components/ui/Text';
import { useAuth } from '@pray/shared/hooks/useAuth';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import { downloadFileFromBlob } from '@pray/shared/utils/download';
import logger from '@pray/shared/utils/logger';

import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useGivingPayments from 'hooks/donations/useGivingPayments';
import { Permissions } from 'utils/auth';

import { DonationStats } from './DonationStats';
import { OrganizationList } from './OrganizationList';
import { SubscriberList } from './SubscriberList';
import { TableSkeleton } from './TableSkeleton';
import { TransactionList } from './TransactionList';
import { TransactionPaymentsEmpty } from './TransactionPaymentsEmpty';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import DonationModuleDialog from '../DonationModuleDialog/DonationModuleDialog';

import styles from './DonationsSummaryPage.module.scss';

const today = DateTime.now();
const reportFilters = {
  startDate: today.startOf('day').minus({ days: 365 }).toJSDate(),
  endDate: today.endOf('day').toJSDate(),
};

const TABS = {
  SUBSCRIBERS: 'subscribers',
  TRANSACTIONS: 'transactions',
  ORGANIZATIONS: 'organizations',
};

export default function DonationsSummaryPage() {
  const { selectedArtist } = useStudioContext();
  const { permissions } = useAuth();
  const artistId = selectedArtist.id;
  const [activeTab, setActiveTab] = useState(TABS.SUBSCRIBERS);

  // Redirect non-admin users if they somehow access organizations tab
  useEffect(() => {
    if (activeTab === TABS.ORGANIZATIONS && !permissions.has(Permissions.STUDIO_ADMIN)) {
      setActiveTab(TABS.SUBSCRIBERS);
    }
  }, [activeTab, permissions]);

  const form = useForm(reportFilters);
  const { startDate, endDate } = form.values;

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useGivingPayments({
    artistId,
    startDate,
    endDate,
    viewType: activeTab,
  });

  const items = data?.pages.flatMap((page) => page.data) || [];

  const handleDownloadCSV = async () => {
    try {
      const response = await studioService.donations.getGivingPayments({
        artistId,
        startDate,
        endDate,
        downloadCsv: true,
        viewType: activeTab,
      });

      await downloadFileFromBlob(response, `${activeTab}_${startDate.getTime()}.csv`);
    } catch (err) {
      logger.error('Failed to download csv', err);
    }
  };

  const renderContent = () => {
    if (isLoading) return <TableSkeleton type={activeTab} />;
    if (!items?.length) return <TransactionPaymentsEmpty viewType={activeTab} />;

    switch (activeTab) {
      case TABS.ORGANIZATIONS:
        return (
          <OrganizationList
            data={items}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        );
      case TABS.TRANSACTIONS:
        return (
          <TransactionList
            data={items}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            onDownload={handleDownloadCSV}
            isFetchingNextPage={isFetchingNextPage}
          />
        );
      default:
        return (
          <SubscriberList
            data={items}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        );
    }
  };

  const renderTopRightButtons = () => (
    <div className={styles.headerControls}>
      <DateRangePicker
        label="Date range"
        indicatorIcon={<Calendar />}
        startDate={form.values.startDate}
        endDate={form.values.endDate}
        onChange={([startDate, endDate]) => {
          form.setValue('startDate', startDate);
          form.setValue('endDate', endDate);
        }}
      />
    </div>
  );

  return (
    <TabPage
      title="Summary"
      topRightButtons={renderTopRightButtons()}
      data-viewname={VIEW_EVENT_NAMES.DONATIONS.SUMMARY}
      containerProps={{
        style: {
          paddingBottom: 0,
        },
      }}
    >
      <div className={styles.container}>
        <DonationStats artistId={artistId} startDate={startDate} endDate={endDate} />

        <div className={styles.content}>
          <div className={styles.sectionHeader}>
            <Text className={styles.sectionTitle}>Revenue Details</Text>
          </div>

          <div className={styles.tabs}>
            {Object.entries(TABS).map(([key, value]) => {
              if (value === TABS.ORGANIZATIONS && !permissions.has(Permissions.STUDIO_ADMIN)) {
                return null;
              }

              return (
                <button
                  key={key}
                  onClick={() => setActiveTab(value)}
                  className={activeTab === value ? styles.activeTab : styles.tab}
                  type="button"
                >
                  {key.charAt(0) + key.slice(1).toLowerCase()}
                </button>
              );
            })}
          </div>

          {renderContent()}
        </div>
      </div>

      <DonationModuleDialog />
    </TabPage>
  );
}
