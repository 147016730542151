import { typography, colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export default function TimeCell({ time, isVisible = true }) {
  return (
    <td className="h-36">
      <div className="size-full border-b border-r pr-3 pt-1 text-right">
        {isVisible && (
          <Text variant={typography.footnote} color={colors.text_tertiary}>
            {time.toFormat('h a')}
          </Text>
        )}
      </div>
    </td>
  );
}
