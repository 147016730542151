import React from 'react';

import useBodyStyles from '@pray/shared/hooks/useBodyStyles';
import useEventListener from '@pray/shared/hooks/useEventListener';
import { combineStyles } from '@pray/shared/utils';

import Button from '../Button/Button';
import { Close } from '../Icons/Icons';

import defaultStyles from './Modal.module.scss';

export default function Modal({
  children,
  customStyles = {},
  onClose = null,
  onBackdropClick = null,
  isShowCloseButton = true,
  className = '',
  noPadding = false,
  closeIcon = null,
  type = 'default', // default, large
  ...props
}) {
  const styles = combineStyles(defaultStyles, customStyles);

  const handleKeyDown = (event) => {
    const key = event.key?.toLowerCase();
    if (key === 'escape') onClose();
  };

  useEventListener('keydown', handleKeyDown);

  useBodyStyles({
    overflow: 'hidden',
  });

  const stylesArray = [styles.container, className];
  if (type === 'large') stylesArray.push(styles.large);
  const classes = stylesArray.filter((item) => item).join(' ');

  return (
    <div className={classes} onClick={onBackdropClick} role="none" {...props}>
      <div className={styles.modal} data-modal>
        {isShowCloseButton && onClose && (
          <Button className={styles.close} data-closebutton data-tapeventname="Close" onClick={onClose}>
            {closeIcon || <Close />}
          </Button>
        )}
        <div className={noPadding ? defaultStyles.viewportCustom : styles.viewport}>{children}</div>
      </div>
    </div>
  );
}
