import React, { useState } from 'react';

import { Chain } from '../../../../../images/icons';

export const ImageWithFallback = ({ imageUrl, altText }) => {
  const [hasError, setHasError] = useState(!imageUrl);

  const handleError = () => {
    setHasError(true);
  };

  return (
    <div>
      {hasError ? (
        // Display the SVG icon when there is an error
        <div className="mr-3 flex size-12 items-center justify-center rounded-md bg-gray-200 p-4">
          <Chain className="size-5" />
        </div>
      ) : (
        // Otherwise, try loading the image
        <img className="mr-3 size-12 rounded-md" src={imageUrl} alt={altText} onError={handleError} />
      )}
    </div>
  );
};
