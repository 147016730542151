import React from 'react';

import { colors, typography, buttons } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { EmailDialogModal } from './EmailDialogModal';
import { emailStatus } from '../../constants';

export default function ReviewAndPublishEmailModal({
  isLoading = false,
  status,
  date,
  time,
  segmentName,
  onCancel,
  onSend,
}) {
  const dialogButtons = [
    { label: 'Cancel', variant: buttons.variant.secondary, action: onCancel, disabled: isLoading },
    { label: 'Send', variant: buttons.variant.primary, action: onSend, disabled: isLoading, loading: isLoading },
  ];

  function formattedDate() {
    if (status === emailStatus.now) return 'Now';

    if (!date || !time) return '';

    const fullDate = new Date(`${date}T${time}`);
    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(fullDate);

    return formattedDate.replace('am', 'AM').replace('pm', 'PM');
  }

  function renderItem({ title, value }) {
    return (
      <div className="flex flex-col gap-1 p-4">
        <Text variant={typography.heading_md} color={colors.text_primary}>
          {title}
        </Text>
        <Text variant={typography.body} color={colors.text_secondary}>
          {value}
        </Text>
      </div>
    );
  }

  return (
    <EmailDialogModal
      title="Review and Publish"
      description="Please take a moment to review the details of your email before sending:"
      buttons={dialogButtons}
      onClose={onCancel}
      isLoading={isLoading}
    >
      <div className="flex h-auto flex-col rounded-xl bg-[#F2F3F4]">
        {renderItem({ title: 'Publish Date', value: `${formattedDate()}` })}
        <hr className="border-t border-[#E5E7EB]" />
        {renderItem({ title: 'Send to', value: `${segmentName}` })}
      </div>
    </EmailDialogModal>
  );
}
