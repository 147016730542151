import React, { useContext } from 'react';

import { TableContext } from './Table';

type RowActionProps = React.HTMLAttributes<HTMLDivElement> & {
  visible?: boolean;
  children?: React.ReactNode;
};

export const RowAction = ({ visible = false, children, ...rowActionProps }: RowActionProps) => {
  const { styles } = useContext(TableContext);

  const props = { ...rowActionProps };

  if (visible) props.className = styles.visible;

  return <div {...props}>{children}</div>;
};
