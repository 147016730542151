import React, { useEffect, useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Button from '@pray/shared/components/ui/Button/Button';
import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text/Text';

import usePrayLocales from 'hooks/locales/usePrayLocales';
import { CrossedClose } from 'images/icons';

export default function LanguageListModal({
  isOpen,
  title,
  subtitle,
  disabled = false,
  itemListTitle,
  itemSelectionInfo,
  onClose,
  artistEnabledLocales = [],
  onCheckToggle = () => {},
}) {
  const [locales, setLocales] = useState([]);
  const prayLocalesResult = usePrayLocales(isOpen);

  useEffect(() => {
    if (prayLocalesResult.data) {
      const prayLocales = prayLocalesResult.data.map((locale) => {
        locale.checked = artistEnabledLocales.includes(locale.locale);
        return locale;
      });
      setLocales(prayLocales);
    }
  }, [prayLocalesResult.data]);

  const handleChangeCheckedState = (id) => {
    const updatedLocales = locales.map((locale) => {
      if (locale.id === id) {
        return { ...locale, checked: !locale.checked }; // Toggle the checked state
      }
      return locale;
    });

    setLocales(updatedLocales);
  };

  const onAdd = () => {
    const checkedLocales = locales.filter((locale) => locale.checked);
    onCheckToggle(checkedLocales);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Modal isShowCloseButton onClose={onClose} noPadding closeIcon={<CrossedClose />}>
      <Text className="text-3xl font-bold">{title}</Text>
      <Text className="text-[#56585E]" variant={typography.body_small}>
        {subtitle}
      </Text>

      <div>
        <div className="flex flex-row items-center py-2">
          <div className="flex-1">
            <Text className="mr-2 font-bold">{itemListTitle}</Text>
            <Text className="text-muted">{itemSelectionInfo}</Text>
          </div>
        </div>

        <div className="border-gray mb-4 h-96 overflow-y-auto border p-1">
          {prayLocalesResult.isLoading && (
            <>
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
              <Loading isLight {...{ margin: '4px 0', height: '50px', width: '100%', borderRadius: '8px' }} />
            </>
          )}

          {!prayLocalesResult.isLoading && locales.length === 0 && <Text>No locales found</Text>}

          {!prayLocalesResult.isLoading &&
            locales.map((locale) => (
              <div
                key={locale.id}
                role="none"
                className="flex cursor-pointer items-center p-4 hover:bg-[#F2F3F4]"
                onClick={() => handleChangeCheckedState(locale.id)}
              >
                <Checkbox checked={locale.checked} />
                <Text className="ml-4" variant={typography.body}>
                  {locale.label}
                </Text>
              </div>
            ))}
        </div>

        <div className="flex flex-row-reverse">
          <Button disabled={disabled} variant={buttons.variant.primary} color={colors.text_secondary} onClick={onAdd}>
            Add
          </Button>
          <Button className="mr-4" variant={buttons.variant.secondary} onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
