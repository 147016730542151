import { useSendbirdStateContext } from '@sendbird/uikit-react';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { useEffect } from 'react';

import { SENDBIRD_APP_ID } from '@pray/shared/config';

export default function CreateSendbirdUser({ userId = '', userName = '', userImageUrl = '', onUserConnected = null }) {
  if (!userId) return null;

  return (
    <SendbirdProvider appId={SENDBIRD_APP_ID} userId={userId} nickname={userName} profileUrl={userImageUrl}>
      <UserConnectionMonitor userId={userId} onConnected={onUserConnected} />
    </SendbirdProvider>
  );
}

const UserConnectionMonitor = ({ userId = '', onConnected }) => {
  const globalStore = useSendbirdStateContext();
  const userStore = globalStore?.stores?.userStore;
  const isUserInitialized = userStore?.initialized;

  useEffect(() => {
    if (isUserInitialized && userStore.user.userId === userId) {
      onConnected?.();
    }
  }, [isUserInitialized]);

  return null;
};
