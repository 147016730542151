import React from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import Dialog from '@pray/shared/components/ui/Dialog';
import Text from '@pray/shared/components/ui/Text';

export default function DeleteVoiceDialog({ voiceName, isLoading, onCancel, onConfirm }) {
  return (
    <Dialog width="500px">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <Text variant={typography.heading_2xl}>Delete Voice</Text>
          <Text variant={typography.body_lg}>
            {`Are you sure you want to delete ${voiceName}? All of your data will be permanently removed. This action cannot be undone.`}
          </Text>
        </div>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onCancel}>
            Cancel
          </Button>
          <Button variant={buttons.variant.primary} onClick={onConfirm} loading={isLoading}>
            Delete
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
