import React from 'react';

import Checkbox from '@pray/shared/components/ui/Checkbox/Checkbox';
import Text from '@pray/shared/components/ui/Text';

export default function FilterStatus({ id, title, textColor, bgColor, setSelectedStatuses, selectedStatuses }) {
  const handleCheckboxClick = () => {
    if (selectedStatuses.includes(id)) {
      setSelectedStatuses(selectedStatuses.filter((existingId) => existingId !== id));
    } else {
      setSelectedStatuses([...selectedStatuses, id]);
    }
  };

  return (
    <label className="flex items-center gap-[6px] rounded-full px-3 py-1" style={{ backgroundColor: bgColor }}>
      <Checkbox
        className="bg-white checked:bg-transparent"
        checked={selectedStatuses.includes(id)}
        onChange={handleCheckboxClick}
      />
      <Text className="text-xs font-bold uppercase" style={{ color: textColor }}>
        {title}
      </Text>
    </label>
  );
}
