import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

import type { ChapterResponse } from './getChapters';

type AddChapterProps = {
  artistId: string;
  bookId: string;
  title: string;
};

export const addChapter = async ({ artistId, bookId, title }: AddChapterProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!title) throw Error('Title is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters`;
  const data = {
    title,
  };

  const response = await api.executePostRequest<ObjectResponse<ChapterResponse>>(url, data);

  return response.data;
};
