export default function SuccessIcon(props) {
  const { stroke = '#E3AF4A', className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M15.5 9.5L11 15L9 13M21.75 12C21.75 17.1086 17.6086 21.25 12.5 21.25C7.39137 21.25 3.25 17.1086 3.25 12C3.25 6.89137 7.39137 2.75 12.5 2.75C17.6086 2.75 21.75 6.89137 21.75 12Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
