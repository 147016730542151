import api from '@pray/shared/utils/api';

export async function triggerContentSeriesTranslation({ artistId = null, contentSeriesId = null, locale = null }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!contentSeriesId) throw Error('Content Series ID is required');
  if (!locale) throw Error('Locale is required');

  const data = {};

  const url = `/web/studio/artists/${artistId}/content-series/${contentSeriesId}/locales/${locale}`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
