import api from '@pray/shared/utils/api';

export async function createContentSeries({
  artistId = '',
  title = '',
  description = '',
  imageUrl = '',
  isVisible = true,
  contentOrder = 'default',
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!title) throw Error('Title is required');
  if (!description) throw Error('Description is required');
  if (!imageUrl) throw Error('Image URL is required');

  const data = {
    title,
    description,
    image_url: imageUrl,
    is_visible: isVisible,
    content_order: contentOrder,
  };

  const url = `/web/studio/artists/${artistId}/content-series`;
  const response = await api.executePostRequest(url, data);

  return response.data.data;
}
