import { DateTime } from 'luxon';
import { Controller, useFormContext } from 'react-hook-form';

import { colors, typography } from '@pray/shared/components/foundations';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import SelectionCard from '@pray/shared/components/ui/SelectionCard';
import Text from '@pray/shared/components/ui/Text';
import TextArea from '@pray/shared/components/ui/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import TimePicker from '@pray/shared/components/ui/TimePicker';

import AiSuggestionBox from '@/components/pages/StudioPage/components/AiSuggestion/AiSuggestionBox';
import AiSuggestionButton from '@/components/pages/StudioPage/components/AiSuggestion/AiSuggestionButton';
import useAiSuggestion from '@/components/pages/StudioPage/components/AiSuggestion/useAiSuggestion';
import { useStudioContext } from '@/context/StudioContext';
import useSocialPlatforms from '@/hooks/socials/useSocialPlatforms';
import { VideoCamera } from '@/images/icons';

import { FacebookIcon, InstagramIcon, XIcon, YoutubeIcon } from '../assets/social-icons';
import { maxTitleLength, publishOptions, socialNetworks } from '../constants';
import SocialAccountDropdown from '../SocialAccountDropdown';
import { SocialClipVideo } from '../SocialClipVideo';

const SOCIAL_PLATFORMS = {
  instagram: {
    id: 'instagram',
    icon: InstagramIcon,
    connectedTitle: 'Instagram Reels',
    disconnectedTitle: 'Connect Instagram',
    platformKey: 'instagram',
  },
  facebook: {
    id: 'facebook',
    icon: FacebookIcon,
    connectedTitle: 'Facebook Video',
    disconnectedTitle: 'Connect Facebook',
    platformKey: 'facebook',
  },
  youtube: {
    id: 'youtube',
    icon: YoutubeIcon,
    connectedTitle: 'Youtube',
    disconnectedTitle: 'Youtube',
    platformKey: 'youtube',
  },
  twitter: {
    id: 'twitter',
    icon: XIcon,
    connectedTitle: 'X',
    disconnectedTitle: 'Connect X',
    platformKey: 'twitter',
  },
};

export function SocialClipsPublishForm({ socialClip, isDisabled, onSubmit }) {
  const form = useFormContext();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;
  const profileImageUrl = selectedArtist?.profile_image_url;
  const { socialPlatforms, connectSocialPlatforms } = useSocialPlatforms(artistId);
  const { data: connectedPlatforms } = socialPlatforms;

  const contentId = socialClip?.contentId;
  const socialClipId = socialClip?.id;
  const aiTitle = useAiSuggestion({ artistId, contentId, socialClipId, property: 'title' });
  const aiDescription = useAiSuggestion({ artistId, contentId, socialClipId, property: 'description' });

  const getTitleTooltip = () => {
    if (aiTitle.isLoading) return null;
    return aiTitle.text ? 'New Suggestion' : 'Generate AI Title';
  };

  const getDescriptionTooltip = () => {
    if (aiDescription.isLoading) return null;
    return aiDescription.text ? 'New Suggestion' : 'Generate AI Description';
  };

  return (
    <form id="socialClipsPublishForm" className="flex gap-8" onSubmit={form.handleSubmit(onSubmit)} noValidate>
      <div className="flex max-w-screen-md flex-1 flex-col gap-8">
        <section className="flex flex-col gap-4">
          <Controller
            name="socialAccounts"
            control={form.control}
            rules={{ required: 'Select at least one social account' }}
            render={({ field: { value, onChange } }) => {
              function getSocialPlatformItems() {
                if (socialPlatforms.isLoading) return [];

                return Object.values(SOCIAL_PLATFORMS).map((platform) => {
                  const platformData = connectedPlatforms?.[platform.platformKey];
                  const isConnected = platformData?.is_connected;

                  return {
                    id: socialNetworks[platform.id],
                    icon: platform.icon,
                    title: isConnected ? platform.connectedTitle : platform.disconnectedTitle,
                    description: isConnected ? platformData?.profile_handle ?? '' : '',
                    profileImageUrl: isConnected ? platformData?.profile_image_url ?? profileImageUrl : '',
                    isConnected,
                    isSelected: value.includes(socialNetworks[platform.id]),
                  };
                });
              }

              function handleAccountSelect(account) {
                if (!account.isConnected) return;

                if (account.isSelected) {
                  onChange(value.filter((id) => id !== account.id));
                } else {
                  onChange([...value, account.id]);
                }
              }

              function handleAccountRemove(account) {
                onChange(value.filter((id) => id !== account.id));
              }

              return (
                <SocialAccountDropdown
                  items={getSocialPlatformItems()}
                  disabled={isDisabled}
                  onSelect={handleAccountSelect}
                  onRemove={handleAccountRemove}
                  onConnect={connectSocialPlatforms}
                />
              );
            }}
          />
        </section>

        <section className="flex flex-col gap-4">
          <Text variant={typography.heading_xl}>Details</Text>
          <div>
            <Controller
              control={form.control}
              name="title"
              rules={{ required: 'Clip Title is required' }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="Clip Title"
                  placeholder="Enter your clip title"
                  disabled={isDisabled}
                  errorMessage={fieldState.error?.message}
                  helperText={`${form.getValues('title')?.length ?? 0}/${maxTitleLength}`}
                  rightIcon={
                    !isDisabled && (
                      <AiSuggestionButton
                        data-tapeventname="Social Clip Title - A.I. Suggestion"
                        isLoading={aiTitle.isLoading}
                        tooltip={getTitleTooltip()}
                        onClick={aiTitle.fetchSuggestion}
                      />
                    )
                  }
                  {...field}
                />
              )}
            />

            <AiSuggestionBox
              text={aiTitle.text}
              isLoading={aiTitle.isLoading}
              onAccept={() => {
                form.setValue('title', aiTitle.text);
                aiTitle.resetSuggestion();
              }}
              onReload={aiTitle.fetchSuggestion}
              onDismiss={aiTitle.resetSuggestion}
              acceptButtonProps={{ 'data-tapeventname': 'Accept - Title - A.I. Suggestion' }}
              dismissButtonProps={{ 'data-tapeventname': 'Dismiss - Title - A.I. Suggestion' }}
              reloadButtonProps={{ 'data-tapeventname': 'Reload - Title - A.I. Suggestion' }}
            />
          </div>

          <div>
            <Controller
              control={form.control}
              name="description"
              rules={{ required: 'Post Description is required' }}
              render={({ field, fieldState }) => (
                <TextArea
                  rows={5}
                  label="Post Description"
                  placeholder="Enter your post description"
                  disabled={isDisabled}
                  errorMessage={fieldState.error?.message}
                  rightIcon={
                    !isDisabled && (
                      <AiSuggestionButton
                        data-tapeventname="Social Clip Description - A.I. Suggestion"
                        isLoading={aiDescription.isLoading}
                        tooltip={getDescriptionTooltip()}
                        onClick={aiDescription.fetchSuggestion}
                      />
                    )
                  }
                  {...field}
                />
              )}
            />

            <AiSuggestionBox
              text={aiDescription.text}
              isLoading={aiDescription.isLoading}
              onAccept={() => {
                form.setValue('description', aiDescription.text);
                aiDescription.resetSuggestion();
              }}
              onReload={aiDescription.fetchSuggestion}
              onDismiss={aiDescription.resetSuggestion}
              acceptButtonProps={{ 'data-tapeventname': 'Accept - Social Clip Description - A.I. Suggestion' }}
              dismissButtonProps={{ 'data-tapeventname': 'Dismiss - Social Clip Description - A.I. Suggestion' }}
              reloadButtonProps={{ 'data-tapeventname': 'Reload - Social Clip Description - A.I. Suggestion' }}
            />
          </div>
        </section>

        <section className="flex flex-col gap-4">
          <PublishOptionCards isDisabled={isDisabled} />
          <ScheduledPublishFields isDisabled={isDisabled} />
        </section>
      </div>

      <div className="flex flex-col gap-4">
        <Text variant={typography.heading_sm} color={colors.text_primary} className="mb-2 flex items-center gap-2">
          <VideoCamera /> Video preview
        </Text>
        <SocialClipVideo videoUrl={socialClip.url} />
      </div>
    </form>
  );
}

function PublishOptionCards({ isDisabled }) {
  const form = useFormContext();
  const socialAccounts = form.watch('socialAccounts');

  if (!socialAccounts.length) return null;

  return (
    <div className="flex flex-col gap-4">
      <Text variant={typography.heading_xl}>Publish options</Text>

      <div className="flex flex-col gap-3 lg:flex-row">
        <Controller
          control={form.control}
          name="publishOption"
          render={({ field }) => (
            <SelectionCard
              type="radio"
              title="Publish Now"
              text="Publish your clip immediately"
              className="w-full"
              disabled={isDisabled}
              value={publishOptions.now}
              checked={field.value === publishOptions.now}
              onChange={() => form.setValue('publishOption', publishOptions.now)}
            />
          )}
        />
        <Controller
          control={form.control}
          name="publishOption"
          render={({ field }) => (
            <SelectionCard
              type="radio"
              title="Schedule Time"
              text="Schedule for a specific date and time in the future"
              className="w-full"
              disabled={isDisabled}
              value={publishOptions.scheduled}
              checked={field.value === publishOptions.scheduled}
              onChange={() => form.setValue('publishOption', publishOptions.scheduled)}
            />
          )}
        />
      </div>
    </div>
  );
}

function ScheduledPublishFields({ isDisabled }) {
  const form = useFormContext();
  const publishOption = form.watch('publishOption');

  if (publishOption !== publishOptions.scheduled) return null;

  return (
    <div className="flex flex-row justify-between gap-4">
      <div className="flex-1">
        <Controller
          control={form.control}
          name="publishDate"
          rules={{ required: 'Publish date is required' }}
          render={({ field, fieldState }) => (
            <DatePicker
              label="Publish date"
              minDate={DateTime.local().startOf('day').toJSDate()}
              value={field.value}
              disabled={isDisabled}
              errorMessage={fieldState.error?.message}
              onChange={(date) => form.setValue('publishDate', date)}
            />
          )}
        />
      </div>

      <div className="flex-1">
        <Controller
          control={form.control}
          name="publishTime"
          rules={{ required: 'Publish time is required' }}
          render={({ field, fieldState }) => (
            <TimePicker
              label="Time"
              value={field.value}
              disabled={isDisabled}
              errorMessage={fieldState.error?.message}
              onChange={(time) => form.setValue('publishTime', time)}
            />
          )}
        />
      </div>
    </div>
  );
}
