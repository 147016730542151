export default function DocumentIcon(props) {
  const { stroke = '#E3AF4A', className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M9.5 7H15.5M9.5 11H15.5M9.5 15H11.5M7.5 21H17.5C18.6046 21 19.5 20.1046 19.5 19V5C19.5 3.89543 18.6046 3 17.5 3H7.5C6.39543 3 5.5 3.89543 5.5 5V19C5.5 20.1046 6.39543 21 7.5 21Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
