import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';
import { navigate } from '@pray/shared/utils/navigation';

import { useStudioContext } from 'context/StudioContext';

export default function useAppRoutes() {
  const { user } = useAuth();
  const location = useLocation();
  const leaderRouteMatch = useMatch('/leaders/:artistId/*');
  const getArtistIdParam = (artistId) => (artistId !== 'profile' ? artistId : '');
  const artistIdUrlParam = getArtistIdParam(leaderRouteMatch?.params?.artistId);
  const { selectedArtist, setSelectedArtist, artists } = useStudioContext();
  const artistId = selectedArtist?.id || artistIdUrlParam;
  const isUserRegistered = !!user?.is_registered;

  useEffect(() => {
    if (!isUserRegistered) return;

    // @todo - this is a temporary fix for the issue when artistIdUrlParam is not set in url,
    // since the url does not starts with /leaders
    if (!location.pathname.startsWith('/leaders')) {
      return;
    }

    // there's a selected artist id but no artist id url param
    if (selectedArtist?.id && !artistIdUrlParam) {
      const artistProfileRoute = asRoute('leaders', selectedArtist?.id, 'profile');
      navigate(artistProfileRoute, { replace: true });
      return;
    }

    // there's an artist id url param but no selected artist id
    if (artistIdUrlParam && !selectedArtist?.id) {
      setSelectedArtist({ id: artistIdUrlParam });
    }
  }, [isUserRegistered, artistIdUrlParam, selectedArtist?.id, location.pathname]);

  const asRoute = (...parts) => {
    const path = parts.filter(Boolean).join('/');
    return path.startsWith('/') ? path : `/${path}`;
  };

  return {
    artistId,

    // ARTIST ROUTES ---------------------------------------

    // artist dashboard
    artistDashboard: () => asRoute('leaders', artistId, 'dashboard'),

    // artist calendar
    artistCalendar: () => asRoute('leaders', artistId, 'calendar'),

    // artist profile
    artistProfile: (id = '') => asRoute('leaders', id || artistId, 'profile'),

    // artist content
    artistContent: () => asRoute('leaders', artistId, 'episodes'),
    artistContentAudio: () => asRoute('leaders', artistId, 'episodes', 'audio'),
    artistContentVideo: () => asRoute('leaders', artistId, 'episodes', 'video'),
    artistContentDetails: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'details'),
    artistContentSummary: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'summary'),
    artistContentTranscript: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'transcript'),
    artistContentTranscriptVoices: (contentId) =>
      asRoute('leaders', artistId, 'episodes', contentId, 'transcript', 'voices'),
    artistContentAnnouncement: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'announcement'),
    artistContentEmail: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'email'),

    artistContentArticle: (id) => asRoute('leaders', artistId, 'episodes', id, 'article'),
    artistContentSocialClips: (contentId) => asRoute('leaders', artistId, 'episodes', contentId, 'social-clips'),
    artistContentSocialClipsPublish: (contentId, socialClipId) =>
      asRoute('leaders', artistId, 'episodes', contentId, 'social-clips', socialClipId, 'publish'),
    artistLibrary: () => asRoute('leaders', artistId, 'library'),
    artistLibraryBookDetails: (id) => asRoute('leaders', artistId, 'library', id, 'details'),
    artistLibraryBookTranscript: (id) => asRoute('leaders', artistId, 'library', id, 'transcript'),
    artistLibraryBookTranscriptEdit: (id) => asRoute('leaders', artistId, 'library', id, 'transcript', 'edit'),
    artistLibraryBookTranscriptEditVoices: (id) =>
      asRoute('leaders', artistId, 'library', id, 'transcript', 'edit', 'voices'),

    artistContentStudyGuide: (id) => asRoute('leaders', artistId, 'episodes', id, 'study-guide'),

    // artist donations
    artistDonationsSummary: () => asRoute('leaders', artistId, 'donations', 'summary'),
    artistDonationsFunds: () => asRoute('leaders', artistId, 'donations', 'funds'),
    artistDonationsBankPayouts: () => asRoute('leaders', artistId, 'donations', 'payouts'),
    artistDonationsFundsCreate: () => asRoute('leaders', artistId, 'donations', 'funds', 'create'),
    artistDonationsFundsEdit: (id) => asRoute('leaders', artistId, 'donations', 'funds', id, 'edit'),
    artistDonationsStatements: () => asRoute('leaders', artistId, 'donations', 'statements'),
    artistDonationsBankPayout: (id) => asRoute('leaders', artistId, 'donations', 'payouts', id),

    // artist playlists / rss feed
    artistPlaylists: () => asRoute('leaders', artistId, 'series'),
    artistContentPlaylists: () => asRoute('leaders', artistId, 'series', 'content'),
    artistDailiesPlaylists: () => asRoute('leaders', artistId, 'series', 'dailies'),
    artistPlaylistCreate: () => asRoute('leaders', artistId, 'series', 'create'),
    artistPlaylistDetails: (id) => asRoute('leaders', artistId, 'series', id),
    artistDailyPlaylistDetails: (id) => asRoute('leaders', artistId, 'daily-series', id),
    artistPlaylistDetailsForm: (id) => asRoute('leaders', artistId, 'series', id, 'details'),
    artistDailySeriesDetailsForm: (id) => asRoute('leaders', artistId, 'daily-series', id, 'details'),
    artistDailySeriesDetailsCoverArtForm: (id) => asRoute('leaders', artistId, 'daily-series', id, 'cover-art'),
    artistDailySeriesDetailsPopUpsForm: (id) => asRoute('leaders', artistId, 'daily-series', id, 'popups'),
    artistPlaylistDistribution: (id) => asRoute('leaders', artistId, 'series', id, 'distribution'),
    artistRssFeedCreate: () => asRoute('leaders', artistId, 'rss-feed', 'create'),
    artistRssFeedDetails: (id) => asRoute('leaders', artistId, 'rss-feed', id),
    artistRssFeedDetailsForm: (id) => asRoute('leaders', artistId, 'rss-feed', id, 'details'),
    artistRssFeedDistribution: (id) => asRoute('leaders', artistId, 'rss-feed', id, 'distribution'),

    // artist banner
    artistBanner: () => asRoute('leaders', artistId, 'banner'),

    // artist announcements
    artistAnnouncements: () => asRoute('leaders', artistId, 'announcements'),
    artistAnnouncementCreate: () => asRoute('leaders', artistId, 'announcements', 'create'),
    artistAnnouncementEdit: (id) => asRoute('leaders', artistId, 'announcements', id, 'edit'),
    artistAnnouncementDetailsOverview: (id) => asRoute('leaders', artistId, 'announcements', id, 'overview'),
    artistAnnouncementDetailsAnalytics: (id) => asRoute('leaders', artistId, 'announcements', id, 'analytics'),

    // artist email campaigns
    artistEmailCampaignEdit: (id) => asRoute('leaders', artistId, 'emails', id, 'edit'),
    artistEmailCampaignAnalyticsOverview: (id) => asRoute('leaders', artistId, 'emails', id, 'analytics'),

    // artist chats
    artistChats: () => asRoute('leaders', artistId, 'chats'),
    artistChatsUserId: (userId) => asRoute('leaders', artistId, 'chats', userId),

    // artist emails
    artistEmails: () => asRoute('leaders', artistId, 'emails'),
    artistEmailsCreate: () => asRoute('leaders', artistId, 'emails', 'create'),
    artistEmailsEdit: (id) => asRoute('leaders', artistId, 'emails', id, 'edit'),
    artistEmailsAnalytics: (id) => asRoute('leaders', artistId, 'emails', id, 'analytics'),
    artistEmailsOverview: (id) => asRoute('leaders', artistId, 'emails', id, 'overview'),

    // artist followers
    artistFollowers: () => asRoute('leaders', artistId, 'followers'),

    // artist segments
    artistSegments: () => asRoute('leaders', artistId, 'segments'),

    // artist team
    artistTeam: () => asRoute('leaders', artistId, 'team'),

    // artist lists
    artistLists: () => asRoute('leaders', artistId, 'lists'),
    artistContactsListDetails: (id) => asRoute('leaders', artistId, 'lists', id, 'details'),
    artistContactsListUpload: () => asRoute('leaders', artistId, 'lists', 'upload'),

    // artist ministry match
    ministryMatchCampaigns: () => asRoute('ministry-match', 'campaigns'),
    ministryMatchCampaignDetails: (id) => asRoute('ministry-match', 'campaigns', id),
    ministryMatchOrganizations: () => asRoute('ministry-match', 'organizations'),
    ministryMatchOrganizationDetails: (id) => asRoute('ministry-match', 'organizations', id),

    // GENERAL ROUTES ---------------------------------------

    // index
    index: () => '/',

    // preview
    preview: () => '/preview/*',
    previewDonation: () => '/preview/donation',

    // studio
    studio: () => '/*',

    // healthcheck
    healthCheck: () => '/web-healthcheck',

    // dailies
    dailies: () => '/dailies',
    dailySeriesIdDailyItemsId: (dailySeriesId, dailyItemId) =>
      asRoute('dailies', 'daily-series', dailySeriesId, 'daily-items', dailyItemId),

    // tags
    tags: () => `/tags`,
    tagDetails: (id) => `/tags/${id}`,

    // analytics
    analyticsListen: () => asRoute('analytics', 'listen'),
    analyticsWatch: () => asRoute('analytics', 'watch'),
    analyticsAudience: () => asRoute('analytics', 'audience'),
    analyticsTestimonials: () => asRoute('analytics', 'testimonials'),
    analyticsPeriodicTable: () => asRoute('analytics', 'periodic-table'),
    analyticsSegment: (id) => asRoute('analytics', 'periodic-table', 'segments', id),

    // settings
    settingsTranslations: () => asRoute('settings', 'translations'),
    settingsVoices: () => asRoute('settings', 'voices'),
    settingsRevenue: () => asRoute('settings', 'revenue'),
    settingsSocialAccounts: () => asRoute('settings', 'social-accounts'),
    // external redirects
    revenueExternalPage: () => asRoute('revenue', 'external-page'),

    // login
    login: () => asRoute('login'),

    // initial route
    initial: () => {
      if (!artistId && !artists.length) {
        return asRoute('leaders', 'profile');
      }

      return asRoute('leaders', artistId, 'dashboard');
    },
  };
}
