import api from '@pray/shared/utils/api';

export async function updateDailySeries({
  artistId,
  dailySeriesId,
  title = undefined,
  description = undefined,
  backgroundImageUrl = undefined,
  handle = undefined,
  orderIdBefore = undefined,
  orderIdAfter = undefined,
}) {
  if (!artistId) throw Error('Artist ID is required');
  if (!dailySeriesId) throw Error('Daily Series Id is required');

  const data = {};
  if (title) data.title = title;
  if (description) data.description = description;
  if (backgroundImageUrl) data.background_image_url = backgroundImageUrl;
  if (handle) data.handle = handle;
  if (orderIdBefore !== undefined) data.order_number_for_artist_id_before = orderIdBefore;
  if (orderIdAfter !== undefined) data.order_number_for_artist_id_after = orderIdAfter;

  const url = `/web/studio/artists/${artistId}/daily-series/${dailySeriesId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
