import React from 'react';

const AnchorLink = 'a';

function Link(props) {
  const { to, disabled } = props;

  const handleClickWhenDisabled = (event) => {
    if (disabled) event.preventDefault();
  };

  const onClick = disabled ? handleClickWhenDisabled : props.onClick;

  if (to) return <Link.Component {...props} onClick={onClick} />;

  return <AnchorLink {...props} onClick={onClick} />;
}

Link.Component = AnchorLink;

export default Link;
