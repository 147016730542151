import { useFieldArray, useFormContext } from 'react-hook-form';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import { AudioIcon, CheckIcon } from './icons';
import { VoiceDropdown } from './VoiceDropdown';

export function VoiceSettingsPanel({ voices, useElevenLabsVoiceId = true }) {
  return (
    <div className="h-full w-[356px] overflow-y-auto border-r border-[#DEDFE3] p-6">
      <div className="flex flex-col gap-4">
        <Text variant={typography.heading_lg}>Voice Settings</Text>
        <VoiceInfoMessage />
        <SpeakerCards voices={voices} useElevenLabsVoiceId={useElevenLabsVoiceId} />
      </div>
    </div>
  );
}

function SpeakerCards({ voices, useElevenLabsVoiceId }) {
  /** @type {import('react-hook-form').UseFormReturn<import('./types').FormValues>} */
  const form = useFormContext();

  const { fields: speakers } = useFieldArray({
    control: form.control,
    name: 'speakers',
  });

  return (
    <div className="flex flex-col gap-3">
      {speakers.map((speaker, index) => (
        <div key={speaker.id} className="flex flex-col gap-3 rounded-xl border border-[#0B0C0D0D] bg-[#F2F3F4] p-4">
          <div className="flex items-center gap-2">
            <AudioIcon color={speaker.color} />
            <Text variant={typography.heading_sm}>{speaker.name}</Text>
          </div>
          <VoiceDropdown index={index} voices={voices} useElevenLabsVoiceId={useElevenLabsVoiceId} />
        </div>
      ))}
    </div>
  );
}

function VoiceInfoMessage() {
  /** @type {import('react-hook-form').UseFormReturn<import('./types').FormValues>} */
  const form = useFormContext();
  const speakers = form.watch('speakers');
  const isCompleted = speakers.every((speaker) => speaker.voiceId);

  return isCompleted ? <VoiceMessageCompleted /> : <VoiceMessagePending />;
}

function VoiceMessagePending() {
  return (
    <div className="flex items-center gap-3 rounded-xl border border-[#2261D31A] bg-[#2261D31A] p-4">
      <div className="rounded-full bg-[#2261D333] p-2">
        <AudioIcon className="size-8" />
      </div>
      <Text className="text-[14px] text-[#184087]">Assign voices to speakers to translate the content.</Text>
    </div>
  );
}

function VoiceMessageCompleted() {
  return (
    <div className="flex items-center gap-3 rounded-xl border border-[#B6E2CB] bg-[#ECF9ED] p-4">
      <div className="rounded-full bg-[#B6E2CB] p-2">
        <CheckIcon className="size-8" />
      </div>
      <Text className="text-[14px] text-[#04341A]">
        You assigned all the voices to speakers. Click update to translate.
      </Text>
    </div>
  );
}
