import { GoogleMap, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useRef, useState } from 'react';

import TextInput from '@pray/shared/components/ui/TextInput';
import { GOOGLE_MAPS_API_KEY } from '@pray/shared/config';

import { Pin } from 'images/icons';

const containerStyle = {
  width: '100%',
  height: '400px',
};

export default function PlaceSearchInput({ latitude, longitude, onPlaceChanged = null, onMapClick = null }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-maps-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  const mapRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const [zoom, setZoom] = useState(16);
  const [center, setCenter] = useState({
    lat: latitude || 33.8964590195855,
    lng: longitude || -118.1210732460022,
  });

  const findPlaceById = (placeId) => {
    return new Promise((resolve) => {
      const map = mapRef.current;

      if (!map || !placeId) {
        return;
      }

      const request = {
        placeId,
        fields: ['place_id', 'name', 'formatted_address', 'geometry'],
      };

      const service = new google.maps.places.PlacesService(map);

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(place);
        }
      });
    });
  };

  const onAutocompleteLoad = (autocomplete) => {
    autoCompleteRef.current = autocomplete;
  };

  const handlePlaceChanged = () => {
    const autocomplete = autoCompleteRef.current;

    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    setCenter(place.geometry.location);
    setZoom(18);
    onPlaceChanged?.(place);
  };

  const handleMapClick = async (event) => {
    if (!onMapClick) return;

    const place = await findPlaceById(event.placeId);

    onMapClick(place);
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  if (!isLoaded) return null;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      zoom={zoom}
      center={center}
      onClick={handleMapClick}
      onLoad={handleMapLoad}
      options={{ mapTypeControl: false }}
    >
      <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={handlePlaceChanged}>
        <div className="absolute m-3 w-96 shadow-xl">
          <TextInput type="search" leftIcon={<Pin />} placeholder="Find by name or address" />
        </div>
      </Autocomplete>
    </GoogleMap>
  );
}
