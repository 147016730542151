import React, { useState } from 'react';

import Video from '@pray/shared/components/ui/Video/Video';
import eventTracking from '@pray/shared/utils/eventTracking';

import styles from './VideoPlayer.module.scss';

export default function VideoPlayer({
  isLive = false,
  content = {
    id: '',
    object: '',
    title: '',
    artist_name: '',
    content_series_id: '',
    content_series_title: '',
    content_category: '',
    video_url: '',
    start_time: 0,
    finish_time: 0,
    adsMetadata: {
      adNetwork: '',
      adTagUrl: '',
    },
    metrics_properties: {},
  },
  onError = null,
  onReady = null,
  onPlay = null,
  onPause = null,
  onEnded = null,
  onSeeked = null,
  onDisposed = null,
}) {
  const [isReady, setIsReady] = useState(false);

  function trackWatchEvent(startTime, finishTime, duration) {
    content.start_time = finishTime;

    const { amplitudeData } = getWatchEventData(startTime, finishTime, duration);
    if (amplitudeData) eventTracking.trackEventWatch(amplitudeData);
  }

  function getWatchEventData(startTime, finishTime, duration) {
    let amplitudeData = null;

    const watchDuration = startTime > finishTime ? startTime - finishTime : finishTime - startTime;

    if (watchDuration >= 0.1) {
      const completionRate = watchDuration / duration;
      const endingPercentage = (startTime + watchDuration) / duration;

      amplitudeData = {
        item_id: content.id,
        item_type: content.object,
        title: content.title,
        start_time: startTime,
        content_duration: duration,
        watch_duration: watchDuration,
        completion_rate: completionRate,
        ending_percentage: endingPercentage,
        series_id: content.content_series_id,
        series_title: content.content_series_title,
        playback_speed: 1.0,
        artist_name: content.artist_name,
        content_category: content.content_category,
        source: 'video_player',
      };
    }

    return {
      amplitudeData,
    };
  }

  const handleReady = () => {
    if (isLive) setIsReady(true);
    onReady?.();
  };

  const handlePlay = (event, player, currentTime) => {
    if (!content.start_time) content.start_time = currentTime;
    onPlay?.(player, currentTime);
  };

  const handlePause = (event, player, currentTime) => {
    content.finish_time = currentTime;
    trackWatchEvent(content.start_time, content.finish_time, player.duration());
    onPause?.(player, currentTime);
  };

  const handleEnded = (event, player) => {
    content.finish_time = player.currentTime();
    trackWatchEvent(content.start_time, content.finish_time, player.duration());
    onEnded?.(player);
  };

  const handleSeeked = (event, player, startTime, finishTime) => {
    content.finish_time = startTime;
    trackWatchEvent(content.start_time, content.finish_time, player.duration());
    onSeeked?.(player, startTime, finishTime);
  };

  const handleDisposed = (player, currentTime) => {
    content.finish_time = currentTime;
    trackWatchEvent(content.start_time, content.finish_time, player.duration());
    onDisposed?.(player, currentTime);
  };

  const handleAdEvents = (eventType, adData) => {
    const extraInfo = {
      adNetwork: content.adsMetadata?.adNetwork,
      metricsProperties: content.metrics_properties,
    };
    eventTracking.trackEventAd(eventType, adData, extraInfo);
  };

  return (
    <>
      {isReady && <div className={styles.live}>LIVE</div>}

      <Video
        key={content.id}
        videoUrl={content.video_url}
        adTagUrl={content.adsMetadata?.adTagUrl}
        onError={onError}
        onEnded={handleEnded}
        onReady={handleReady}
        onPlay={handlePlay}
        onPause={handlePause}
        onSeeked={handleSeeked}
        onDisposed={handleDisposed}
        onAdEvent={handleAdEvents}
      />
    </>
  );
}
