import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { useContentPlayer } from '@pray/shared/components/ContentPlayer/hooks/useContentPlayer';
import Button from '@pray/shared/components/ui/Button';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Text from '@pray/shared/components/ui/Text/Text';
import studioService from '@pray/shared/services/studioService';
import { formatDuration } from '@pray/shared/utils';
import storage from '@pray/shared/utils/storage';

import ContentDateStatus from 'components/pages/StudioPage/components/ContentDateStatus/ContentDateStatus';
import ContentTranslations from 'components/pages/StudioPage/components/ContentTranslations/ContentTranslations';
import ContentVisibility from 'components/pages/StudioPage/components/ContentVisibility/ContentVisibility';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import { AUDIO_PLAYER, LOCALES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';
import { Pencil, PlaylistRemove } from 'images/icons';

import audioIcon from '../../../../assets/audio-icon.png';
import ContentItem from '../../../../components/ContentItem/ContentItem';
import MessageModal from '../../../../components/MessageModal/MessageModal';
import visibilityIcon from '../../assets/chevron-bottom.svg';
import dragHandle from '../../assets/drag-handle.svg';
import ContentVisibilityModal from '../ContentVisibilityModal/ContentVisibilityModal';

import styles from './ContentTable.module.scss';

export default function ContentTable({
  data,
  isSortingEnabled,
  onSortChange,
  onSelectionChange,
  onContentUpdated,
  onRemoveContent,
  hideButtons,
}) {
  const appRoutes = useAppRoutes();
  const { playContent } = useContentPlayer();
  const [content, setContent] = useState(null);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentVisibilityAnchorEl, setContentVisibilityAnchorEl] = useState(null);
  const { selectedArtist } = useStudioContext();
  const location = useLocation();
  const artistId = selectedArtist.id;
  const navigate = useNavigate();
  const toast = useToastMessage();

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(data || []);
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleOnDragEnd = async ({ destination, source }) => {
    if (!destination) return;

    const reorderedItems = reorder(items, source.index, destination.index);
    setItems(reorderedItems);

    const newIndex = destination.index;
    const orderIdBefore = newIndex > 0 ? reorderedItems[newIndex - 1].id : null;
    const orderIdAfter = newIndex < reorderedItems.length - 1 ? reorderedItems[newIndex + 1].id : null;

    await studioService.content.updateArtistContent({
      artistId,
      contentId: items[source.index].id,
      orderIdBefore,
      orderIdAfter,
    });
  };

  const handleItemClick = (e, content) => {
    e.stopPropagation();
    playContent(content.id, {
      locale: LOCALES.DEFAULT,
      controlOptions: AUDIO_PLAYER.controlOptions,
    });
  };

  const handleOnRemoveContent = (e, content) => {
    e.stopPropagation();
    onRemoveContent(content);
  };

  const togglePublishedState = async () => {
    setIsLoading(true);
    await studioService.content.updateArtistContent({
      artistId,
      contentId: content.id,
      isPublished: !content.is_published,
    });
    await onContentUpdated();
    setIsLoading(false);
  };

  const handleVisibilityItemClick = (event, content) => {
    event.stopPropagation();
    if (!hideButtons) {
      // Allow opening the visibility modal when user has permissions to see the Edit button
      // It will also block for Pray-Radio and Pray-TV series.
      setContent(content);
      setContentVisibilityAnchorEl(event.currentTarget);
    }
  };

  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    if (!item || hideButtons) return [];

    return [
      <IconButton
        key={`edit-${item.id}`}
        className={styles.actionButton}
        icon={Pencil}
        tooltip="Edit"
        to={appRoutes.artistContentDetails(item.id)}
        onClick={() => storage.upsertToStorage('sourceBackPage', location.pathname)}
      />,
      <IconButton
        key={`remove-${item.id}`}
        className={styles.actionButton}
        icon={PlaylistRemove}
        tooltip="Remove from series"
        onClick={(e) => handleOnRemoveContent(e, item)}
      />,
    ];
  };

  const handleRowClick = (item) => {
    if (!hideButtons) {
      // Allow opening the content only when user has permissions to see the Edit button
      // It will also block opening the contents from Pray-Radio and Pray-TV series.
      navigate(appRoutes.artistContentDetails(item.id), { replace: true });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="playlistItems">
          {(provided) => (
            <Table
              rowCount={items.length}
              rowActions={getRowActions}
              onSortChange={onSortChange}
              onSelectionChange={onSelectionChange}
              onDragEnd={handleOnDragEnd}
              ref={provided.innerRef}
              className={styles.contentTable}
            >
              <Thead>
                <Tr>
                  <Th width="5%">#</Th>
                  <Th width="30%" field="title">
                    <Text data-tooltip-id="episode-tooltip" data-tooltip-place="top">
                      Episode
                    </Text>
                    <ReactTooltip
                      id="episode-tooltip"
                      className={styles.customTooltip}
                      place="top-start"
                      arrowColor="transparent"
                    >
                      <Text className="heading_md text_primary font-medium">Episode</Text>
                      <Text className="text_secondary text-sm font-medium">
                        This number shows the episode's position in the series.
                      </Text>
                    </ReactTooltip>
                  </Th>
                  <Th width="10%" field="duration">
                    <Text data-tooltip-id="duration-tooltip" data-tooltip-place="top">
                      Duration
                    </Text>
                    <ReactTooltip
                      id="duration-tooltip"
                      className={styles.customTooltip}
                      place="top-start"
                      arrowColor="transparent"
                    >
                      <Text className="heading_md text_primary font-medium">Duration</Text>
                      <Text className="text_secondary text-sm font-medium">Duration of the episode</Text>
                    </ReactTooltip>
                  </Th>
                  <Th width="15%" field="is_published">
                    <Text data-tooltip-id="visibility-tooltip" data-tooltip-place="top">
                      Visibility
                    </Text>
                    <ReactTooltip
                      id="visibility-tooltip"
                      className={styles.customTooltip}
                      place="top-start"
                      arrowColor="transparent"
                    >
                      <Text className="heading_md text_primary font-medium">Visibility</Text>
                      <Text className="text_secondary text-sm font-medium">
                        You can click an episode’s visibility to change to public, private or schedule to release.
                      </Text>
                    </ReactTooltip>
                  </Th>
                  <Th width="18%" field="created_at">
                    <Text data-tooltip-id="date-tooltip" data-tooltip-place="top">
                      Date
                    </Text>
                    <ReactTooltip
                      id="date-tooltip"
                      className={styles.customTooltip}
                      place="top-start"
                      arrowColor="transparent"
                    >
                      <Text className="heading_md text_primary font-medium">Date</Text>
                      <Text className="text_secondary text-sm font-medium">
                        You’ll see the upload date if your episode is private. If your episode is scheduled you’ll see
                        the schedule date. If your episode is public you will see the published date.
                      </Text>
                    </ReactTooltip>
                  </Th>
                  <Th width="22%" field="title">
                    <Text data-tooltip-id="translations-tooltip" data-tooltip-place="top">
                      Translations
                    </Text>
                    <ReactTooltip
                      id="translations-tooltip"
                      className={styles.customTooltip}
                      place="top-start"
                      arrowColor="transparent"
                    >
                      <Text className="heading_md text_primary font-medium">Translations</Text>
                      <Text className="text_secondary text-sm font-medium">
                        See in which language your eposides are translated{' '}
                      </Text>
                    </ReactTooltip>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((item, index) => {
                  const isDragDisabled = items.length < 2 || hideButtons || !isSortingEnabled;

                  return (
                    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
                      {(provided, { isDragging }) => {
                        const draggingStyle = isDragging ? 'bg-gray-200' : '';
                        return (
                          <Tr
                            index={index}
                            className={`group ${isDragging ? styles.dragging : ''}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            onClick={() => handleRowClick(item)}
                          >
                            <Td width="5%" className="cursor-pointer">
                              {!isDragDisabled ? (
                                <div
                                  className={`flex h-8 w-5 items-center justify-center rounded group-hover:bg-gray-200 ${draggingStyle}`}
                                  {...provided.dragHandleProps}
                                >
                                  <Text className={isDragging ? 'hidden' : 'block group-hover:hidden'}>
                                    {index + 1}
                                  </Text>
                                  <img
                                    className={isDragging ? 'block' : 'hidden group-hover:block'}
                                    src={dragHandle}
                                    alt="drag"
                                  />
                                </div>
                              ) : (
                                <div className="flex h-8 w-5 items-center justify-center">
                                  <Text className="block">{index + 1}</Text>
                                </div>
                              )}
                            </Td>
                            <Td width="30%" className="cursor-pointer">
                              <ContentItem
                                image={item.primary_image_url}
                                fallbackImage={audioIcon}
                                title={item.title}
                                description={item.description}
                                onItemClick={(e) => handleItemClick(e, item)}
                              />
                            </Td>
                            <Td width="10%" className="cursor-pointer pl-1.5">
                              <Text variant="subhead_small">
                                {formatDuration(
                                  item.primary_video_file_duration ?? item.primary_audio_file_duration ?? 0
                                )}
                              </Text>
                            </Td>
                            <Td width="15%" className="cursor-pointer">
                              <Button className="px-0" onClick={(event) => handleVisibilityItemClick(event, item)}>
                                <div className="flex items-center gap-2">
                                  <ContentVisibility content={item} />
                                  {!hideButtons && (
                                    <img
                                      alt="visibility"
                                      src={visibilityIcon}
                                      className="invisible size-3 group-hover:visible"
                                    />
                                  )}
                                </div>
                              </Button>
                            </Td>
                            <Td width="18%" className="cursor-pointer pl-1.5">
                              <ContentDateStatus content={item} />
                            </Td>
                            <Td width="22%" className="cursor-pointer pl-1.5">
                              <ContentTranslations translations={item.translations} />
                            </Td>
                          </Tr>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      <MessageModal
        isOpen={isPublishModalOpen}
        isLoading={isLoading}
        title={`${!content?.is_published ? 'Publish' : 'Unpublish'} this episode?`}
        message={(() => {
          const action = !content?.is_published ? 'publish' : 'unpublish';
          return `You are about to ${action} ${content?.title}. Are you sure you want to ${action} this episode?`;
        })()}
        primaryButtonLabel={!content?.is_published ? 'Publish' : 'Unpublish'}
        secondaryButtonLabel="Cancel"
        onPrimaryButtonClick={async () => {
          await togglePublishedState();
          setIsPublishModalOpen(false);
        }}
        onSecondaryButtonClick={() => setIsPublishModalOpen(false)}
        onClose={() => setIsPublishModalOpen(false)}
      />

      {!!contentVisibilityAnchorEl && (
        <ContentVisibilityModal
          data={content}
          anchorEl={contentVisibilityAnchorEl}
          onClose={() => {
            setContentVisibilityAnchorEl(null);
            setContent(null);
          }}
          onSuccess={async () => {
            await onContentUpdated();
            setContentVisibilityAnchorEl(null);
            setContent(null);
          }}
          onError={(error) => {
            toast.show({ error });
          }}
        />
      )}
    </>
  );
}
