import React, { useEffect, useState } from 'react';

export default function BackgroundImage({ src, placeholderImageSrc = '', onError = null, style = null, ...props }) {
  const [styles, setStyles] = useState(style || {});
  const [imageUrl, setImageUrl] = useState(src || placeholderImageSrc);

  useEffect(() => {
    if (onError) {
      const image = new Image();
      image.src = imageUrl;

      image.onerror = () => {
        const event = {
          target: {
            src: imageUrl,
            style: {},
          },
        };

        // call onError callback with custom event
        onError(event);

        // handle image src change
        if (event.target.src !== imageUrl) {
          setImageUrl(event.target.src);
        }

        // handle image style change
        if (event.target.style) {
          setStyles((styles) => ({ ...styles, ...event.target.style }));
        }
      };
    }
  }, [imageUrl, onError]);

  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        ...styles,
      }}
      {...props}
    />
  );
}
