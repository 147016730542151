import React, { useEffect, useState } from 'react';

import { colors, typography } from '../../foundations';
import Text from '../Text/Text';

import styles from './RadioButtonGroup.module.scss';

export default function RadioButtonGroup({ radioGroupName, options = [], value = '', onChange = null, ...props }) {
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div className={styles.radioButtonGroup}>
      {options.map((option) => (
        <label htmlFor={option.value} className={styles.radioButton} key={option.value}>
          <div className={styles.labelContainer}>
            <input
              type="radio"
              id={option.value}
              name={radioGroupName}
              value={option.value}
              checked={option.value === selectedOption}
              onChange={(evt) => onChange(evt.target.value)}
              className={styles.radio}
              disabled={option.disabled ?? false}
              {...props}
            />
            <Text
              variant={option.typography ?? typography.headline_small}
              color={colors.pray_primary}
              className={styles.label}
            >
              {option.label}
            </Text>
          </div>
          {option.description && (
            <Text className={styles.labelDescription} color={colors.text_tertiary}>
              {option.description}
            </Text>
          )}
        </label>
      ))}
    </div>
  );
}
