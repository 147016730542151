import { useEffect, useState } from 'react';

export default function useForm(initialValues) {
  const [fields, setFields] = useState({});
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [changed, setChanged] = useState(false);

  function setValue(name, value) {
    const field = fields[name] || {};
    field.changed = true;

    if (!changed) setChanged(true);
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
    setFields((prevFields) => ({ ...prevFields, [name]: field }));
  }

  function setError(field, message) {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: message }));
  }

  function handleChange(event) {
    const { type, name, value, checked } = event.target;
    if (type === 'checkbox') {
      setValue(name, checked);
    } else {
      setValue(name, value);
    }
  }

  function reactiveSetValues(newValues) {
    setValues(newValues);
    setChanged(true);
  }

  useEffect(() => {
    setValues(initialValues);
  }, [JSON.stringify(initialValues)]);

  return {
    changed,
    fields,
    values,
    errors,
    isValid: Object.keys(errors).length === 0,
    handleChange,
    setChanged,
    setError,
    setErrors,
    setValue,
    setValues: reactiveSetValues,
  };
}
