import { useInfiniteQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useGivingPayments({ artistId, startDate, endDate, viewType }) {
  async function fetchData(offset) {
    const response = await studioService.donations.getGivingPayments({
      artistId,
      startDate,
      endDate,
      offset,
      viewType,
    });

    if (response.last_item_identifier === offset) {
      delete response.last_item_identifier;
    }

    response.data = response.data.map((item) => givingPaymentsMapper(item, viewType));

    return response;
  }

  return useInfiniteQuery({
    initialPageParam: 0,
    enabled: !!(artistId && startDate && endDate),
    queryKey: queryKeys.artistGivingPayments(artistId, { startDate, endDate, viewType }),
    queryFn: ({ pageParam: offset }) => fetchData(offset),
    getPreviousPageParam: (response) => response.last_item_identifier,
    getNextPageParam: (response) => response.last_item_identifier,
  });
}

export function givingPaymentsMapper(data, viewType = 'subscribers') {
  switch (viewType) {
    case 'organizations':
      return {
        id: data.id,
        name: data.name || '',
        subscriberCount: data.subscriber_count || 0,
        averageDonationAmount: data.average_donation_amount || 0,
        totalRevenue: data.total_revenue || 0,
        estimatedLifetimeRevenue: data.estimated_lifetime_revenue || 0,
      };
    case 'transactions':
      return {
        id: data.id,
        date: data.transaction_date,
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        amount: data.amount || 0,
        fundName: data.fund_name || '',
      };
    default:
      return {
        id: data.id,
        firstName: data.first_name || '',
        lastName: data.last_name || '',
        startDate: data.subscription_start_date,
        renewalCount: data.renewal_count || 0,
        subscriptionAmount: data.subscription_amount || 0,
        interval: data.interval || 'month',
        totalRevenue: data.total_revenue || 0,
      };
  }
}
