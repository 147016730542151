import React from 'react';

import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import Section from 'components/pages/StudioPage/components/Section/Section';

export default function ProfileImage({ form }) {
  const minPixelWidth = 98;
  const minPixelHeight = 98;
  const maxSizeMB = 4;

  return (
    <Section title="Profile Image">
      <div className="mt-4">
        <MediaUpload
          imageUrl={form.values.profile_image_url}
          title="Leader Profile Image (Square)"
          description={`Add a PNG or JPEG picture to use as your leader profile image. The picture must be at least ${minPixelWidth} x ${minPixelHeight} pixels, ${maxSizeMB}MB or less, and will be cropped to be square.`}
          uploadButtonLabel={form.values.profile_image_url ? 'Change Image' : 'Upload Image'}
          error={form.errors.profile_image_url}
          cropProps={{ aspect: 1 }}
          maxSizeMB={maxSizeMB}
          supportedFileTypes={['jpg', 'jpeg', 'png']}
          onValidateImage={(img) => {
            if (img.height < minPixelHeight || img.width < minPixelWidth) {
              throw new Error(`Image must be at least ${minPixelWidth} x ${minPixelHeight} pixels.`);
            }

            if (img.height !== img.width) {
              throw Error('Image must be a square.');
            }

            return true;
          }}
          minifyImage={{ maxSizeMB: 1, maxWidthOrHeight: 500 }}
          onFileChange={(file) => form.setValue('profile_image_file', file)}
          onError={(error) => form.setError('profile_image_url', error)}
        />
      </div>
    </Section>
  );
}
