const appDataError = 'appDataError';

async function clearAllAppData() {
  // Clear Local Storage
  localStorage.clear();

  // Clear Session Storage
  sessionStorage.clear();

  // Clear Cookies
  document.cookie.split(';').forEach((cookie) => {
    const name = cookie.split('=')[0];
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  });

  // Clear IndexedDB
  if (window.indexedDB) {
    const databases = await window.indexedDB.databases();
    databases.forEach((db) => {
      window.indexedDB.deleteDatabase(db.name);
    });
  }

  // Clear Cache Storage
  if (window.caches) {
    const cacheNames = await caches.keys();
    cacheNames.forEach((cacheName) => {
      caches.delete(cacheName);
    });
  }
}

function isAppDataError() {
  return !!window.sessionStorage.getItem(appDataError);
}

function setIsAppDataError(isError) {
  if (isError) {
    window.sessionStorage.setItem(appDataError, 'true');
  } else {
    window.sessionStorage.removeItem(appDataError);
  }
}

export default {
  clearAllAppData,
  isAppDataError,
  setIsAppDataError,
};
