import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Thead, Tr, Th } from '@pray/shared/components/ui/Table';

export default function ContentTableSkeleton({ rows = 8 }) {
  return (
    <Table key="content-table-skeleton" sortable={false} rowCount={rows} disabled>
      <Thead>
        <Tr>
          <Th field="col" width="30%">
            <Loading height={20} isLight width={70} borderRadius="6px" />
          </Th>
          <Th field="col" width="23%">
            <Loading height={20} isLight width={70} borderRadius="6px" />
          </Th>
          <Th field="col" width="23%">
            <Loading height={20} isLight width={70} borderRadius="6px" />
          </Th>
          <Th field="col" width="23%">
            <Loading height={20} isLight width={70} borderRadius="6px" />
          </Th>
        </Tr>
        {[...Array(rows)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Tr key={`skeleton-row-${i}`}>
            <Th field="col" width="30%" className="max-w-md ">
              <Loading height={24} isLight width={100} borderRadius="6px" />
            </Th>
            <Th field="col" width="23%" className="max-w-md ">
              <Loading height={24} isLight width={100} borderRadius="6px" />
            </Th>
            <Th field="col" width="23%" className="max-w-md ">
              <Loading height={24} isLight width={100} borderRadius="6px" />
            </Th>
            <Th field="col" width="23%" className="max-w-md ">
              <Loading height={24} isLight width={100} borderRadius="6px" />
            </Th>
          </Tr>
        ))}
      </Thead>
    </Table>
  );
}
