import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAudioPlayer } from '@pray/shared/components/ContentPlayer/FullscreenContentPlayer';
import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Modal from '@pray/shared/components/ui/Modal';
import Text from '@pray/shared/components/ui/Text';
import TextArea from '@pray/shared/components/ui/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import PlayIcon from '@pray/shared/icons/PlayIconEmpty';
import { Chapter } from '@pray/shared/services/studioService/books/chapters/types';
import { Book, BookProcessStatus } from '@pray/shared/services/studioService/books/types';

import placeholderImage from '../../../assets/cover-placeholder.webp';
import ButtonCluster from '../../../components/ButtonCluster/ButtonCluster';
import ContentDateStatus from '../../../components/ContentDateStatus/ContentDateStatus';
import MediaUpload from '../../../components/MediaUpload/MediaUpload';
import TabPage from '../../../components/TabPage/TabPage';
import { BookCover } from '../components/BookCover';
import GenerateAudioBanner from '../components/GenerateAudioBanner';
import { INPUTS_MAX_LENGTH } from '../constants';

import { AUDIO_BOOK_PLAYER } from '@/constants';
import { Trash } from '@/images/icons';

type BookDetailsProps = {
  book: Book;
  audiobookGenerationStatus: BookProcessStatus;
  audiobookGenerationUpdatedAt: string;
  textExtractionStatus: BookProcessStatus;
  isSaving: boolean;
  chapters: Chapter[];
  handleSave: () => Promise<void>;
  handleDelete: () => Promise<void>;
  onDownloadAudio: () => void;
};

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
};

export default function BookDetails({
  book,
  audiobookGenerationStatus,
  audiobookGenerationUpdatedAt,
  isSaving,
  chapters,
  textExtractionStatus,
  handleSave,
  handleDelete,
}: BookDetailsProps) {
  const form = useFormContext();
  const { playContent } = useAudioPlayer();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { artistId } = useParams();
  const chaptersWithAudio = chapters?.filter((chapter) => Boolean(chapter?.audiobookFileUrl)) ?? [];

  const renderTopRightButtons = () => {
    return (
      <ButtonCluster text="Save" onClick={handleSave} isLoading={isSaving}>
        {/* TODO: Whenever the BE side is ready, we can add the download audio button back in */}
        {/* <DropdownMenu.Item
          className="w-48"
          title="Download Audio"
          leftComponent={<Download />}
          onClick={onDownloadAudio}
          disabled={audiobookGenerationStatus !== BookProcessStatus.COMPLETED}
        /> */}
        <DropdownMenu.Item
          className="w-48"
          title="Delete"
          leftComponent={<Trash />}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </ButtonCluster>
    );
  };

  return (
    <TabPage title="Details" topRightButtons={renderTopRightButtons()}>
      <form id="bookDetailsForm" className="mt-8 flex gap-[200px]" onSubmit={form.handleSubmit(handleSave)} noValidate>
        <div className="flex max-w-screen-md flex-1 flex-col">
          <section className="flex flex-col gap-4">
            {textExtractionStatus === BookProcessStatus.COMPLETED && (
              <GenerateAudioBanner
                audiobookGenerationStatus={audiobookGenerationStatus}
                audiobookGenerationUpdatedAt={audiobookGenerationUpdatedAt}
                lastSavedAt={book.lastSavedAt}
                artistId={artistId}
                bookId={book.id}
              />
            )}

            <div className="mt-6 flex flex-col gap-3">
              <Controller
                control={form.control}
                name="title"
                rules={{ required: 'Book Title is required' }}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col gap-2">
                    <TextInput
                      label="Title"
                      required
                      placeholder="Enter your book title"
                      errorMessage={fieldState.error?.message}
                      maxLength={INPUTS_MAX_LENGTH}
                      {...field}
                    />
                    <InputHelperText>
                      <span />
                      <span>
                        {(form.getValues('title') || '').length}/{INPUTS_MAX_LENGTH}
                      </span>
                    </InputHelperText>
                  </div>
                )}
              />
              <Controller
                control={form.control}
                name="description"
                rules={{ required: 'Book Description is required' }}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col gap-2">
                    <TextArea
                      rows={5}
                      label="Description"
                      placeholder="Enter your book description"
                      errorMessage={fieldState.error?.message}
                      maxLength={INPUTS_MAX_LENGTH}
                      {...field}
                    />
                    <InputHelperText>
                      <span />
                      <span>
                        {(form.getValues('description') || '').length}/{INPUTS_MAX_LENGTH}
                      </span>
                    </InputHelperText>
                  </div>
                )}
              />
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <Text variant={typography.headline_medium}>
                  Thumbnail <Text className="font-bold text-red-500">*</Text>
                </Text>
                <Text variant={typography.subhead_small} color={colors.text_muted}>
                  Use a picture that's at least 98 x 98 pixels and 4MB or less. Use a PNG or JPEG.
                </Text>
                <MediaUpload
                  imageUrl={form.getValues('cover_url')}
                  uploadButtonLabel={form.getValues('cover_url') ? 'Change Image' : 'Upload Image'}
                  onFileChange={(file) => form.setValue('cover_file', file)}
                  onError={(error) => form.setError('cover_url', error)}
                  cropProps={{ aspect: 3 / 4 }}
                />
              </div>
            </div>
          </section>
        </div>

        <div className="flex flex-col gap-4">
          <div className="relative h-[200px] w-[130px]">
            <BookCover image={book.thumbnail || placeholderImage} />
            {audiobookGenerationStatus === BookProcessStatus.COMPLETED && chapters?.length > 0 && (
              <Button
                className="absolute inset-0 flex items-center justify-center p-0"
                onClick={(e) => {
                  e.stopPropagation();
                  playContent({
                    contentId: chaptersWithAudio[0].id,
                    controlOptions: AUDIO_BOOK_PLAYER.controlOptions,
                    chapters: chaptersWithAudio,
                  });
                }}
              >
                <PlayIcon className="m-0 max-h-12 w-10" outerFillOpacity="1" innerFill="black" />
              </Button>
            )}
          </div>
          <div className="flex flex-col">
            <Text variant={typography.heading_md} color={colors.text_primary} className="mb-2 flex items-center gap-2">
              Title
            </Text>
            <Text variant={typography.body_md} color={colors.text_primary}>
              {book.title}
            </Text>
          </div>
          <div className="flex flex-col">
            <Text variant={typography.heading_md} color={colors.text_primary} className="flex items-center gap-2">
              Date
            </Text>
            <ContentDateStatus
              content={{
                is_published: book.isPublished,
                created_at: book.createdAt,
                published_at: book.publishedAt,
              }}
              className="!p-0"
            />
          </div>
        </div>
      </form>
      <DeleteModal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} onDelete={handleDelete} />
    </TabPage>
  );
}

function DeleteModal({ isOpen, onClose, onDelete }: DeleteModalProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  if (!isOpen) return null;

  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
    onClose();
  };

  return (
    <Modal isShowCloseButton={false} noPadding onClose={onClose}>
      <div className="flex flex-col gap-6">
        <Text variant={typography.heading_lg}>Delete Book?</Text>
        <Text variant={typography.body_lg}>Are you sure you want to delete this book?</Text>
        <div className="flex justify-end gap-4">
          <Button variant={buttons.variant.secondary} onClick={onClose}>
            No
          </Button>
          <Button variant={buttons.variant.primary} onClick={handleDelete} loading={isDeleting}>
            Yes, delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}
