import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DropdownMenu from '@pray/shared/components/ui/DropdownMenu/DropdownMenu';
import { navigate } from '@pray/shared/utils/navigation';

import ButtonCluster from 'components/pages/StudioPage/components/ButtonCluster';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

import CreateEditEmailForm from './CreateEditEmailForm';
import EmailPreviewFrame from './EmailPreviewFrame';
import useCreateEditEmailPage from './useCreateEditEmailPage';
import ConfirmDeactivationModal from '../components/Dialogs/ConfirmDeactivationModal';
import DeleteEmailModal from '../components/Dialogs/DeleteEmailModal';
import EmailContentRequiredModal from '../components/Dialogs/EmailContentRequiredModal';
import ReviewAndPublishEmailModal from '../components/Dialogs/ReviewAndPublishEmailModal';
import SendTestEmailModal from '../components/Dialogs/SendTestEmailModal';
import UpdateEmailAutomationWarningModal from '../components/Dialogs/UpdateEmailAutomationWarningModal';
import EmailDesignerModal from '../components/EmailDesignerModal';
import TemplatePreviewModal from '../components/TemplatePreview/TemplatePreviewModal';
import TemplatesModal from '../components/TemplatesModal';
import { draftCampaignName, emailStatus } from '../constants';

export default function CreateEditEmailPage() {
  const appRoutes = useAppRoutes();
  const { id: emailCampaignId } = useParams();
  const { state, actions } = useCreateEditEmailPage();

  const [isDeleteEmailModalOpen, setIsDeleteEmailModalOpen] = useState(false);

  const {
    form,
    formData,
    artistId,
    templateForPreview,
    isEmailDesignerModalOpen,
    isEmailRequiredModalOpen,
    isLoading,
    isSaving,
    isDraft,
    isAutomation,
    isEmailStatusActive,
    isReviewAndPublishModalOpen,
    isUpdateEmailAutomationWarningModalOpen,
    isConfirmDeactivationModalOpen,
    isSendTestModalOpen,
    isTemplatePreviewModalOpen,
    isTemplatesModalOpen,
    isSendingTestEmail,
  } = state;

  const {
    closeEmailDesignerModal,
    closeEmailRequiredModal,
    closeReviewAndPublishModal,
    closeUpdateEmailAutomationWarningModal,
    closeConfirmDeactivationModal,
    closeTemplatePreviewModal,
    closeSendTestModal,
    closeTemplatesModal,
    handleDesignEmail,
    handleOpenTestModal,
    handleSendTestEmail,
    handleSendEmail,
    handleChangeAutomationStatus,
    openConfirmDeactivationModal,
    openSelectTemplateModal,
    saveEmailDesign,
    saveEmailDraft,
    selectTemplate,
    selectTemplateForPreview,
    sendEmailCampaign,
  } = actions;

  const isEditMode = !!emailCampaignId;
  const tabTitle = isEditMode ? 'Edit Email' : 'New Email';
  const canDelete = isEditMode && (isDraft || formData.status === emailStatus.scheduled);
  const viewName = isEditMode ? VIEW_EVENT_NAMES.EMAILS.EDIT : VIEW_EVENT_NAMES.EMAILS.CREATION;

  return (
    <FormProvider {...form}>
      <TabPage
        title={tabTitle}
        data-viewname={viewName}
        topRightButtons={
          <EmailActionButtons
            isAutomation={isAutomation}
            isActive={!isDraft && formData.status === emailStatus.active}
            isDraft={isDraft}
            isLoading={isLoading}
            isRenderDeleteButton={canDelete}
            onSaveDraft={saveEmailDraft}
            onSendTestEmail={handleOpenTestModal}
            onStopAutomation={openConfirmDeactivationModal}
            onResumeAutomation={() => handleChangeAutomationStatus(emailStatus.active)}
            onDeleteEmail={() => setIsDeleteEmailModalOpen(true)}
          />
        }
      >
        <main className="flex gap-16">
          <CreateEditEmailForm
            data={formData}
            isDraft={isDraft}
            isEmailStatusActive={isEmailStatusActive}
            onDesignEmail={handleDesignEmail}
            onSendEmail={handleSendEmail}
          />
          <div className="relative min-w-80">
            <EmailPreviewFrame html={formData.compiledHtmlCss} />
          </div>
        </main>
      </TabPage>

      {isTemplatesModalOpen && (
        <TemplatesModal
          isTemplateSelector
          onClose={closeTemplatesModal}
          onApply={selectTemplate}
          onCreate={selectTemplate}
          onPreview={selectTemplateForPreview}
        />
      )}

      {isTemplatePreviewModalOpen && (
        <TemplatePreviewModal
          subjectText={formData.subjectText || formData.name}
          html={templateForPreview.compiledHtmlCss}
          onBack={closeTemplatePreviewModal}
          onSend={handleOpenTestModal}
        />
      )}

      {isEmailDesignerModalOpen && (
        <EmailDesignerModal
          id={formData.id}
          name={formData.name || draftCampaignName}
          html={formData.rawHtml}
          css={formData.rawCss}
          onPreview={selectTemplateForPreview}
          onSave={saveEmailDesign}
          onChangeTemplate={() => {
            closeEmailDesignerModal();
            openSelectTemplateModal();
          }}
          onClose={closeEmailDesignerModal}
        />
      )}

      {isReviewAndPublishModalOpen && (
        <ReviewAndPublishEmailModal
          isLoading={isLoading}
          status={formData.status}
          date={!isAutomation ? formData.scheduledAtDate : ''}
          time={!isAutomation ? formData.scheduledAtTime : ''}
          segmentName={formData.audience}
          onCancel={closeReviewAndPublishModal}
          onSend={sendEmailCampaign}
        />
      )}

      {isUpdateEmailAutomationWarningModalOpen && (
        <UpdateEmailAutomationWarningModal
          isLoading={isLoading}
          onCancel={closeUpdateEmailAutomationWarningModal}
          onSend={sendEmailCampaign}
        />
      )}

      {isConfirmDeactivationModalOpen && (
        <ConfirmDeactivationModal
          isLoading={isSaving}
          onCancel={closeConfirmDeactivationModal}
          onSend={() => handleChangeAutomationStatus(emailStatus.stopped)}
        />
      )}

      {isSendTestModalOpen && (
        <SendTestEmailModal onCancel={closeSendTestModal} onSend={handleSendTestEmail} isLoading={isSendingTestEmail} />
      )}

      {isEmailRequiredModalOpen && <EmailContentRequiredModal onClose={closeEmailRequiredModal} />}

      {isDeleteEmailModalOpen && (
        <DeleteEmailModal
          campaignId={formData.id}
          artistId={artistId}
          onDelete={() => navigate(appRoutes.artistEmails())}
          onCancel={() => setIsDeleteEmailModalOpen(false)}
        />
      )}
    </FormProvider>
  );
}

function EmailActionButtons({
  isAutomation,
  isActive,
  isDraft,
  isLoading,
  isRenderDeleteButton,
  onSaveDraft,
  onSendTestEmail,
  onDeleteEmail,
  onStopAutomation,
  onResumeAutomation,
}) {
  return (
    <div className="flex items-center gap-3">
      {isRenderDeleteButton && (
        <Button variant={buttons.variant.secondary} onClick={onDeleteEmail}>
          Delete
        </Button>
      )}

      {!isAutomation && (
        <ButtonCluster text="Send Email" type="submit" form="emailForm" disabled={isLoading}>
          <DropdownMenu.Item className="w-40" title="Save as Draft" onClick={onSaveDraft} />
          <DropdownMenu.Item className="w-40" title="Send Test Email" onClick={onSendTestEmail} />
        </ButtonCluster>
      )}

      {isAutomation && (
        <ButtonCluster
          text={isDraft ? 'Publish Automation' : 'Update Automation'}
          type="submit"
          form="emailForm"
          disabled={isLoading}
        >
          <DropdownMenu.Item className="w-40" title="Send Test Email" onClick={onSendTestEmail} />
          {!isDraft && isActive && (
            <DropdownMenu.Item className="w-40" title="Stop Automation" onClick={onStopAutomation} />
          )}
          {!isDraft && !isActive && (
            <DropdownMenu.Item className="w-40" title="Resume Automation" onClick={onResumeAutomation} />
          )}
        </ButtonCluster>
      )}
    </div>
  );
}
