import React, { useEffect } from 'react';

import { typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';

import { CUSTOM_ADMIN_STYLE } from 'constants.js';

import { NotificationsBadgeWithCount } from '../NotificationsBadge/NotificationsBadge';
import { useTabGroupContext } from '../TabGroup/TabGroup';

import styles from './TabItem.module.scss';

export default function TabItem({
  icon: Icon = null,
  text = null,
  route = '',
  disabled = false,
  visible = true,
  className = '',
  notifications = 0,
  active = false,
  forAdmin = false,
  ...props
}) {
  const { registerTabRoute } = useTabGroupContext();

  useEffect(() => {
    if (route) registerTabRoute(route);
  }, [route]);

  if (!visible) return null;

  const isActive = active || window.location.pathname.startsWith(route);

  const classes = [styles.tab, isActive && styles.active, className, forAdmin && CUSTOM_ADMIN_STYLE]
    .filter(Boolean) //
    .join(' ');

  const renderIcon = () => {
    if (typeof Icon === 'function') {
      return Icon();
    }
    return <Icon />;
  };

  const tabRoute = disabled ? '#' : route;

  return (
    <Button className={classes} to={tabRoute} disabled={disabled} {...props}>
      {Icon && <div className={styles.icon}>{renderIcon()}</div>}
      <Text data-itemtext variant={typography.headline_small} className="font-medium tracking-normal">
        {text}
      </Text>
      <NotificationsBadgeWithCount count={notifications} />
    </Button>
  );
}
