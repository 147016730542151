import React from 'react';

export function Verified(props) {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9695 8.02968C21.1418 7.29529 20.8863 6.52642 20.3086 6.04128L19.0389 4.97487C18.8112 4.78366 18.629 4.5441 18.5056 4.27362L17.8163 2.76308C17.5184 2.1102 16.8935 1.66697 16.1789 1.60165L14.3637 1.43574C14.0842 1.41019 13.8132 1.3261 13.5684 1.18893L11.978 0.29808C11.3707 -0.0421181 10.6302 -0.0420653 10.0229 0.29822L8.43362 1.18879C8.18867 1.32605 7.91756 1.41019 7.63794 1.43575L5.82241 1.60164C5.10753 1.66697 4.48249 2.11041 4.18466 2.76358L3.49633 4.27313C3.37285 4.54392 3.1905 4.78375 2.96256 4.97511L1.69261 6.0413C1.11478 6.52641 0.859115 7.29541 1.03147 8.02993L1.38666 9.54365C1.45716 9.84412 1.45717 10.1568 1.38669 10.4573L1.03144 11.9717C0.859106 12.7064 1.11492 13.4754 1.69293 13.9605L2.96232 15.0258C3.19041 15.2172 3.37289 15.4571 3.49642 15.728L4.18467 17.2374C4.48249 17.8905 5.10751 18.334 5.82237 18.3993L7.63733 18.5652C7.91671 18.5907 8.1876 18.6748 8.43239 18.8118L10.0229 19.7028C10.6302 20.043 11.3707 20.043 11.978 19.7028L13.5672 18.8123C13.8122 18.6749 14.0834 18.5907 14.3631 18.5652L16.179 18.3992C16.8936 18.3339 17.5185 17.8908 17.8164 17.238L18.5056 15.7281C18.629 15.4577 18.8112 15.2182 19.0388 15.0271L20.3087 13.9605C20.8863 13.4754 21.1418 12.7066 20.9696 11.9723L20.6142 10.4573C20.5437 10.1568 20.5437 9.84412 20.6142 9.54365L20.9695 8.02968ZM15.1195 6.65929C15.4587 6.897 15.541 7.36469 15.3033 7.70391L11.481 13.1585C11.251 13.4867 10.8036 13.5761 10.4651 13.3614L7.5984 11.5432C7.2486 11.3214 7.14489 10.858 7.36674 10.5082C7.58859 10.1584 8.052 10.0547 8.4018 10.2765L10.6636 11.7111L14.0749 6.8431C14.3126 6.50388 14.7802 6.42159 15.1195 6.65929Z"
        fill="white"
        {...props}
      />
    </svg>
  );
}
