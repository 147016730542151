import React, { useEffect, useState } from 'react';
import { ComposableMap } from 'react-simple-maps';

import { US_STATES } from '@pray/shared/constants';

import { AnimatedBalls } from './components/AnimatedBalls';
import { LeaderAudienceArcs } from './components/LeaderAudienceArcs';
import { LeaderMarker } from './components/LeaderMarker';
import { PulsingMarker } from './components/PulsingMarker';
import { StateAudienceTooltip } from './components/StateAudienceTooltip';
import { States } from './components/States';

export const getStateCode = (stateName) => {
  return Object.entries(US_STATES).find(([_, data]) => data.name === stateName)?.[0];
};

export default function USAudienceMap({ data, isLoading }) {
  const [activeState, setActiveState] = useState(null);
  const [slicedAudience, setSlicedAudience] = useState([]);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const { artistState, artistAvatar, processedAudience } = React.useMemo(
    () => ({
      artistState: data?.artist?.state,
      artistAvatar: data?.artist?.avatar,
      processedAudience: data?.audience || [],
    }),
    [data]
  );

  useEffect(() => {
    let startIndex = 0;
    const updateAudience = () => {
      setSlicedAudience(() => {
        const nextSlice = processedAudience.slice(startIndex, startIndex + 10);
        startIndex = (startIndex + 10) % processedAudience.length;
        return nextSlice.length < 10 ? nextSlice.concat(processedAudience.slice(0, 10 - nextSlice.length)) : nextSlice;
      });
    };

    updateAudience();
    setShouldAnimate(true);

    const interval = setInterval(() => {
      setShouldAnimate(false);

      setTimeout(() => {
        updateAudience();
        setShouldAnimate(true);
      }, 500);
    }, 10000);

    return () => clearInterval(interval);
  }, [processedAudience]);

  const handleMouseLeave = React.useCallback(() => {
    setActiveState(null);
  }, []);

  const handleStateMouseEnter = React.useCallback((state) => {
    setActiveState(state);
  }, []);

  if (isLoading) {
    return (
      <div className="h-auto w-full overflow-hidden">
        <ComposableMap projection="geoAlbersUsa" viewBox="0 0 800 600" style={{ width: '100%', height: 'auto' }}>
          <States isLoading={isLoading} />
        </ComposableMap>
      </div>
    );
  }

  return (
    <div className="h-auto w-full overflow-hidden" onMouseLeave={handleMouseLeave}>
      <ComposableMap projection="geoAlbersUsa" viewBox="0 0 800 600" style={{ width: '100%', height: 'auto' }}>
        {/* States */}
        <States audience={processedAudience} onMouseEnter={handleStateMouseEnter} />

        {/* Arc paths from artist to audience */}
        <LeaderAudienceArcs audience={slicedAudience} artistState={artistState} activeState={activeState} />

        {/* Audience Markers and Moving Balls */}
        {slicedAudience.map((audience, index) => {
          const stateCode = getStateCode(audience.state);
          const audienceStateCoords = US_STATES[stateCode]?.coords;
          const artistStateCoords = US_STATES[artistState]?.coords;

          return (
            <React.Fragment key={`marker-${audience.state}`}>
              {/* Pulsing Circle at Audience Location */}
              <PulsingMarker coords={audienceStateCoords} audience={slicedAudience} />

              {/* Animated Ball Moving Along the Arc */}
              <AnimatedBalls
                index={index}
                isEnabled={!activeState}
                from={artistStateCoords}
                to={audienceStateCoords}
                audience={slicedAudience}
              />
            </React.Fragment>
          );
        })}

        {/* Artist Marker - Circle Avatar with Pulsing Effect */}
        <LeaderMarker artistState={artistState} artistAvatar={artistAvatar} />
      </ComposableMap>

      {activeState && (
        <StateAudienceTooltip
          state={activeState?.state}
          listens={processedAudience.find((aud) => getStateCode(aud.state) === activeState?.state)?.count}
          left={activeState?.left}
          top={activeState?.top}
        />
      )}
    </div>
  );
}
