import Image from '@pray/shared/components/ui/Image/Image';

export default function EmailDetailsPreview({ data: email }) {
  if (!email) return null;

  if (email.compiledHtmlCss) {
    const previewHtml = `
      <main style="position:relative;">
        <div style="position:absolute;left:0;right:0;top:0;bottom:0;z-index:100;"></div>
        ${email.compiledHtmlCss}
      </main>
    `;

    return (
      <div className="relative h-[178px] w-[112px] overflow-hidden rounded-md border border-[#e6e6e6] bg-[#F2F3F4]">
        <iframe
          srcDoc={previewHtml}
          title="Email Preview"
          className="h-[800px] w-[640px] origin-top-left scale-[0.175] border-none"
        />
      </div>
    );
  }

  return <Image src={email.previewImgUrl} className="mb-2 h-[178px] w-[112px] rounded-md" />;
}
