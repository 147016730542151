import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import Text from '@pray/shared/components/ui/Text/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';
import { isValidUrl } from '@pray/shared/utils/validation';

import { VIEW_EVENT_NAMES } from 'constants.js';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

import ActionButton from '../../../components/ActionButton/ActionButton';
import PodcastPreview from '../../../components/PodcastPreview/PodcastPreview';
import Section from '../../../components/Section/Section';
import TabPage from '../../../components/TabPage/TabPage';

import styles from './RssFeedForm.module.scss';

const initialFormData = {
  url: '',
};

let keyUpTimeout;

export default function RssFeedForm() {
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { selectedArtist } = useStudioContext();
  const [podcastPreview, setPodcastPreview] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isLoadingPodcastPreview, setIsLoadingPodcastPreview] = useState(false);

  const form = useForm(initialFormData);

  const validateUrl = (url) => {
    const errors = {};

    // Podcast RSS URL
    if (!url || !isValidUrl(url, { scheme: 'https' })) {
      errors.url = 'Invalid URL';
    }

    form.setErrors(errors);

    const isError = Object.keys(errors).length === 0;
    return isError;
  };

  const loadPodcastPreviewData = async (url) => {
    clearTimeout(keyUpTimeout);

    if (!validateUrl(url)) {
      setPodcastPreview(null);
      return;
    }

    try {
      setIsLoadingPodcastPreview(true);
      const previewData = await studioService.rssFeed.validateRssFeedUrl(url);
      setPodcastPreview({ url, ...previewData });
    } catch (err) {
      setPodcastPreview(null);
      form.setError('url', 'Failed to validate RSS Feed URL');
    } finally {
      setIsLoadingPodcastPreview(false);
    }
  };

  const handlePublish = async () => {
    const { url } = podcastPreview;
    if (!validateUrl(url)) return;

    setIsPublishing(true);

    try {
      const artistId = selectedArtist.id;
      await studioService.rssFeed.createRssFeed(artistId, url);
      navigate(appRoutes.artistPlaylists(), { replace: true });
    } catch (err) {
      logger.debug('Failed to publish leader profile', err);
      form.setError('url', 'Failed to publish RSS Feed URL');
    } finally {
      setIsPublishing(false);
    }
  };

  const handlePodcastRssUrlBlur = () => {
    loadPodcastPreviewData(form.values.url);
  };

  const hanldePodcastPreviewKeyUp = () => {
    clearTimeout(keyUpTimeout);
    keyUpTimeout = setTimeout(() => loadPodcastPreviewData(form.values.url), 2000);
  };

  const handlePodcastRssUrlPaste = (event) => {
    const url = event.clipboardData.getData('text');
    loadPodcastPreviewData(url);
  };

  const renderTopRightButtons = () => {
    const text = isPublishing ? 'Publishing' : 'Publish';

    return (
      <div className="flex space-x-4">
        <Button
          variant={buttons.variant.secondary}
          onClick={() => navigate(appRoutes.artistPlaylists(), { replace: true })}
        >
          Cancel
        </Button>
        <ActionButton isLoading={isPublishing} text={text} onClick={handlePublish} disabled={!podcastPreview} />
      </div>
    );
  };

  return (
    <TabPage
      title="New RSS Feed"
      data-viewname={VIEW_EVENT_NAMES.SERIES.RSS_SERIES_CREATION}
      topRightButtons={renderTopRightButtons()}
    >
      <Section title="RSS Feed" className="mt-8 max-w-4xl">
        <div className={styles.formField}>
          <Text className={styles.label}>Podcast RSS URL</Text>
          <Text className={styles.description}>
            Add your RSS URL to publish your podcast on your leader page. We will automatically gather the title, image
            and description. Your content will go live once you hit &quot;Publish&quot;.
          </Text>

          <TextInput
            label="Podcast RSS URL"
            name="url"
            value={form.values.url}
            errorMessage={form.errors.url}
            loading={isLoadingPodcastPreview}
            onBlur={handlePodcastRssUrlBlur}
            onKeyUp={hanldePodcastPreviewKeyUp}
            onPaste={handlePodcastRssUrlPaste}
            onChange={form.handleChange}
          />

          <InputHelperText>Note: RSS feed URL is required to be a secure https:// link</InputHelperText>
        </div>

        {podcastPreview?.title && (
          <PodcastPreview
            title={podcastPreview.title}
            subtitle={podcastPreview.description}
            image={podcastPreview.image_url}
          />
        )}
      </Section>
    </TabPage>
  );
}
