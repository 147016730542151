import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import Label from '@pray/shared/components/ui/Label';
import Popover from '@pray/shared/components/ui/Popover';
import Text from '@pray/shared/components/ui/Text';
import studioService from '@pray/shared/services/studioService';

import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';

import { useGetContentAnnouncement } from './useGetContentAnnouncement';
import Section from '../../../../components/Section/Section';
import TabPage from '../../../../components/TabPage/TabPage';
import AnnouncementPreview from '../../../AnnouncementsTab/AnnouncementOverview/AnnouncementPreview';
import WorkflowAIEmptyState from '../WorkflowAIEmptyState/WorkflowAIEmptyState';

const ANNOUNCEMENT_STATUSES = {
  DRAFT: 'Draft',
  SENT: 'Sent',
  SCHEDULED: 'Scheduled',
};

export default function ContentAnnouncementWrapper({
  workflowAIDetails,
  onWorkflowAIStarted,
  artist,
  content,
  locale,
  setContent,
}) {
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();
  const { data: announcementData, isLoading: isAnnouncementDataLoading } = useGetContentAnnouncement({
    artist,
    content,
    locale,
    workflowAIDetails,
  });

  const renderTopRightButtons = () => {
    if (!content) return null;
    if (!announcementData || announcementData?.status === ANNOUNCEMENT_STATUSES.DRAFT) return null;

    return (
      <div className="sm-hide md-show">
        <Button
          variant={buttons.variant.tertiary}
          disabled={isAnnouncementDataLoading || !announcementData}
          onClick={() => navigate(appRoutes.artistAnnouncementDetailsAnalytics(announcementData.id))}
        >
          View Analytics
        </Button>
      </div>
    );
  };

  return (
    <TabPage
      title="Announcement"
      data-viewname={VIEW_EVENT_NAMES.CONTENT.DETAILS_ANNOUNCEMENT}
      topRightButtons={renderTopRightButtons()}
      titleInfo={
        <Popover title="Announcement" description="Here you can view the details of the announcement created by AI.">
          <Info className="size-5 text-[#56585E]" />
        </Popover>
      }
    >
      <ContentAnnouncement
        workflowAIDetails={workflowAIDetails}
        onWorkflowAIStarted={onWorkflowAIStarted}
        artist={artist}
        content={content}
        locale={locale}
        setContent={setContent}
        announcementData={announcementData}
      />
    </TabPage>
  );
}

function ContentAnnouncement({
  onWorkflowAIStarted,
  artist,
  content,
  locale,
  setContent,
  announcementData,
  workflowAIDetails,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const hasResource = announcementData?.post_resource?.url;

  async function handleWorkflowAIButton() {
    const { id: artistId } = artist;
    const { id: contentId } = content;

    setIsProcessing(true);
    setContent((prevData) => {
      return {
        ...prevData,
        is_workflow_ai_processing: true,
      };
    });

    await studioService.workflowAI.triggerWorkflowAIForContent({
      artistId,
      contentId,
      locale,
    });

    onWorkflowAIStarted();
    setIsProcessing(false);
  }

  /**
   * Loading and empty states here
   */
  if (!workflowAIDetails?.announcementId || !announcementData) {
    return (
      <WorkflowAIEmptyState
        isProcessing={isProcessing}
        content={content}
        handleWorkflowAIButton={handleWorkflowAIButton}
        title="Announcement"
        description="Effortlessly create personalized messages for your audience. Promote content, share updates, or inspire your
            community."
      />
    );
  }

  const getDetails = (data) => {
    if (!data) {
      return <div>Loading...</div>;
    }

    return (
      <div className="ml-auto flex max-w-[350px] flex-col space-y-6">
        <Text variant={typography.h2}>Details</Text>

        <div className="flex flex-col items-start space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Status
          </Text>

          <Label className="uppercase" status={data.status}>
            {data.status}
          </Label>
        </div>

        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Announcement
          </Text>
          <Text color={colors.text_primary} wordLimit={36} className="font-medium">
            {data?.body}
          </Text>
        </div>

        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Publish date
          </Text>
          <Text color={colors.text_primary} className="font-medium">
            {data?.scheduled_at ? (
              <>Scheduled at {DateTime.fromISO(data.scheduled_at).toFormat("MMMM d, yyyy 'at' h:mm a")}</>
            ) : (
              'Not scheduled'
            )}
          </Text>
        </div>

        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Link
          </Text>
          <a href={data?.post_resource?.url} target="_blank" rel="noreferrer">
            <Text color={colors.text_primary} className={`font-medium ${hasResource && 'cursor-pointer underline'}`}>
              {data?.post_resource?.url ?? 'No Link Attached'}
            </Text>
          </a>
        </div>
        <div className="flex flex-col space-y-1">
          <Text variant={typography.subhead_small} color={colors.text_secondary}>
            Segment
          </Text>
          <Text color={colors.text_primary} className="font-medium">
            {data?.segment_name ?? '--'}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Section className="flex gap-4">
        <div className="mt-6 grid grid-cols-5 gap-2">
          <div className="col-span-3 flex items-center justify-center">
            <AnnouncementPreview isLoading={false} data={announcementData} />
          </div>

          <div className="col-span-2">{getDetails(announcementData)}</div>
        </div>
      </Section>
    </div>
  );
}
