import React, { useMemo } from 'react';

import { chartTypes } from '@pray/shared/services/studioService/periodicTable';

import useSegmentDetails from 'hooks/periodicTable/useSegmentDetails';

import { DonutChart } from '../../../../../../components/Charts/DonutChart';
import { ChartWidget } from '../ChartWidget';

export default function GenderChart({ artistId, segmentCode, segmentName }) {
  const { isLoading, data } = useSegmentDetails({ artistId, segmentCode, chartType: chartTypes.gender });

  const description = useMemo(() => {
    if (!data?.gender?.length) return null;

    const largestSlice = data.gender.reduce((prev, current) => {
      return +prev.value > +current.value ? prev : current;
    });

    return (
      <>
        <span className="text-[#0B0C0D]">{largestSlice.value}%</span> of members from {segmentName} are{' '}
        <span className="text-[#0B0C0D]">{largestSlice.label}</span>
      </>
    );
  }, [data?.gender]);

  if (isLoading || !!data?.gender?.length) {
    return (
      <ChartWidget
        isLoading={isLoading}
        title="Gender"
        description={description}
        info="This chart shows the breakdown of Gender of PRAY.COM members from the periodic table segment."
      >
        <DonutChart isLoading={isLoading} data={data?.gender} />
      </ChartWidget>
    );
  }
}
