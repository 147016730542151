export const viewTypes = {
  day: 'day',
  week: 'week',
  month: 'month',
};

export const eventTypes = {
  announcement: 'announcement',
  daily: 'daily',
  email: 'email',
  episode: 'episode',
  ministryMatch: 'ministry-match',
  socialPost: 'social-post',
};

export const eventTypeColors = {
  [eventTypes.announcement]: { primary: '#8854F2', secondary: '#F4F2FE', onHover: 'hover:bg-[#8854F2]/[.08]' },
  [eventTypes.daily]: { primary: '#188149', secondary: '#ECF9ED', onHover: 'hover:bg-[#1CAB5F]/[.08]' },
  [eventTypes.email]: { primary: '#2261D3', secondary: '#F1F3FE', onHover: 'hover:bg-[#387AF0]/[.08]' },
  [eventTypes.episode]: { primary: '#C95B22', secondary: '#FDF1EF', onHover: 'hover:bg-[#C95B22]/[.08]' },
  [eventTypes.ministryMatch]: { primary: '#E3AF4A', secondary: '#FDF2E5', onHover: 'hover:bg-[#E3AF4A]/[.08]' },
  [eventTypes.socialPost]: { primary: '#E71D20', secondary: '#FEF0F0', onHover: 'hover:bg-[#E71D20]/[.08]' },
};

export const availableFilterTypes = [
  { id: 'announcement', title: 'Announcement', bgColor: '#F4F2FE', borderColor: '#8854F2' },
  { id: 'daily', title: 'Dailies', bgColor: '#ECF9ED', borderColor: '#1CAB5F' },
  { id: 'email', title: 'Email', bgColor: '#F1F3FE', borderColor: '#387AF0' },
  { id: 'episode', title: 'Episode', bgColor: '#FDF1EF', borderColor: '#C95B22' },
  { id: 'ministry-match', title: 'Ministry Match', bgColor: '#FDF2E5', borderColor: '#E3AF4A' },
  { id: 'social-post', title: 'Social Post', bgColor: '#FEF0F0', borderColor: '#E71D20' },
];

export const filterStatuses = [
  { id: 'published', title: 'Published', textColor: '#04341A', bgColor: '#B6E2CB' },
  { id: 'sent', title: 'Sent', textColor: '#04341A', bgColor: '#B6E2CB' },
  { id: 'draft', title: 'Draft', textColor: '#56585E', bgColor: '#F2F3F4' },
  { id: 'scheduled', title: 'Scheduled', textColor: '#360A76', bgColor: '#D2C7F9' },
];
