import Datamap from 'datamaps/dist/datamaps.usa';
import React, { useEffect, useRef, useState } from 'react';

import { ChartLegend } from '../../../../../components/Charts/components/ChartLegend';
import { divideRangeIntoFour, findRange } from '../../../../../components/PeriodicTable/helpers';
import loadingMap from '../assets/loading-us-map.png';

const defaultFill = '#FFF0D6';
const borderColor = '#79551040';
const highlightBorderColor = '#79551070';

const fills = {
  range1: defaultFill,
  range2: '#FDE5B9',
  range3: '#E3AF4A',
  range4: '#B78F40',
  defaultFill,
};

export const GeoChart = ({ isLoading = false, data = {} }) => {
  const mapRef = useRef(null);
  const [activeState, setActiveState] = useState(null);
  const [ranges, setRanges] = useState(null);

  const handleMapLoad = (datamap) => {
    datamap.svg.selectAll('.datamaps-subunit').on('mouseenter', (geo) => setActiveState(geo.id), true);
    datamap.svg.selectAll('.datamaps-subunit').on('mouseleave', () => setActiveState(null), true);
  };

  useEffect(() => {
    if (isLoading || !data || !Object.keys(data).length) return;

    const onlyValues = Object.values(data).map((value) => value);
    const minValue = Math.min.apply(null, onlyValues);
    const maxValue = Math.max.apply(null, onlyValues);
    const ranges = divideRangeIntoFour(minValue, maxValue);
    setRanges(ranges);

    // fill dataset in appropriate format
    const dataset = {};

    Object.entries(data).forEach(([state, value]) => {
      const range = findRange(ranges, value);
      const fillKey = `range${range}`;

      dataset[state] = {
        value,
        range,
        fillKey,
      };
    });

    // eslint-disable-next-line
    new Datamap({
      scope: 'usa',
      element: mapRef.current,
      projection: 'mercator',
      fills,
      data: dataset,
      done: handleMapLoad,
      geographyConfig: {
        borderColor,
        highlightBorderWidth: 2,
        // don't change color on mouse hover
        highlightFillColor: (geo) => fills[geo.fillKey] || defaultFill,
        // only change border
        highlightBorderColor,
        // show desired information in tooltip
        popupTemplate: (geo, data) => {
          // don't show tooltip if state isn't present in dataset
          if (!data) return null;

          const state = geo.properties.name;
          const listens = data.value;

          // tooltip content
          return `
            <div class="flex flex-col gap-2.5 p-6 rounded-md bg-[#0B0C0D] font-[Satoshi] shadow-md">
              <span class="text-white text-[17px] font-bold whitespace-nowrap">${state}</span>
              <div class="whitespace-nowrap">
                <span class="text-[#C6CCD8] font-medium">Listens:</span>
                <span class="text-white font-bold">${listens}%</span>
              </div>
            </div>
          `;
        },
      },
    });
  }, [data, isLoading]);

  return (
    <div className="relative flex items-center gap-1">
      {/* Geo Map Chart */}
      <div ref={mapRef} className="relative h-[440px] w-[880px]">
        {isLoading && <img src={loadingMap} alt="Loading..." className="ml-12 mt-5 w-full max-w-[700px]" />}
      </div>

      {/* Color Range Legend */}
      <div className="relative left-[-96px] w-36">
        {ranges?.map((range, index) => {
          const fillKey = `range${index + 1}`;

          let hover = 'opacity-100';

          if (activeState) {
            const activeRange = findRange(ranges, data[activeState]);
            hover = activeRange === index + 1 ? 'opacity-100' : 'opacity-20';
          }

          const grayscale = isLoading ? 'grayscale' : '';

          return (
            <ChartLegend
              key={fillKey}
              color={fills[fillKey]}
              label={`${range[0]}% - ${range[1]}%`}
              className={`${hover} ${grayscale}`}
            />
          );
        })}
      </div>
    </div>
  );
};
