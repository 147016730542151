import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type SocialPlatform = 'instagram' | 'facebook' | 'youtube' | 'twitter';

type SocialPlatformInfo = {
  is_connected: boolean;
  profile_handle?: string;
  profile_image_url?: string;
  profile_url?: string;
};

type ConnectedPlatforms = Record<SocialPlatform, SocialPlatformInfo>;

type UseSocialPlatformsReturn = {
  socialPlatforms: UseQueryResult<ConnectedPlatforms>;
  connectSocialPlatforms: () => Promise<void>;
  disconnectSocialPlatform: (platformId: string) => Promise<void>;
};

export default function useSocialPlatforms(artistId: string): UseSocialPlatformsReturn {
  const queryClient = useQueryClient();
  const shouldFetchUserDetails = useRef(true);

  const connectedPlatforms = useRef({
    instagram: { is_connected: false },
    facebook: { is_connected: false },
    youtube: { is_connected: false },
    twitter: { is_connected: false },
  });

  const fetchConnectedPlatforms = async () => {
    const includeUserDetails = shouldFetchUserDetails.current;
    const response = await studioService.socials.getConnectedSocialPlatforms({
      artistId,
      includeUserDetails,
    });

    const data = response?.data ?? {};

    shouldFetchUserDetails.current = false;

    connectedPlatforms.current = Object.keys(data).reduce((combinedResult, platform) => {
      return {
        ...combinedResult,
        [platform]: { ...connectedPlatforms.current[platform], ...data[platform] },
      };
    }, connectedPlatforms.current);

    return connectedPlatforms.current;
  };

  const socialPlatforms = useQuery({
    queryKey: queryKeys.artistConnectedSocialPlatforms(artistId),
    queryFn: fetchConnectedPlatforms,
    enabled: !!artistId,
    refetchInterval: 5000,
  });

  const connectSocialPlatforms = async () => {
    const response = await studioService.home.postConnectSocialAccount({ artistId });
    const connectionUrl = response.data?.url;

    window.open(connectionUrl, '_blank');
  };

  const disconnectSocialPlatform = async (platformId: string) => {
    await studioService.socials.disconnectSocialPlatform({ artistId, platformId });
    queryClient.cancelQueries({ queryKey: queryKeys.artistConnectedSocialPlatforms(artistId) });
    shouldFetchUserDetails.current = true;
    socialPlatforms.refetch();
  };

  function handleWindowFocus() {
    shouldFetchUserDetails.current = true;
    queryClient.cancelQueries({ queryKey: queryKeys.artistConnectedSocialPlatforms(artistId) });
    socialPlatforms.refetch();
  }

  useEffect(() => {
    window.addEventListener('focus', handleWindowFocus);
    return () => window.removeEventListener('focus', handleWindowFocus);
  }, []);

  return {
    socialPlatforms,
    connectSocialPlatforms,
    disconnectSocialPlatform,
  };
}
