import React from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import { Voices } from '@pray/shared/components/ui/Icons/Voices';
import Text from '@pray/shared/components/ui/Text';

export default function EmptyVoiceState({ onClick }) {
  return (
    <div className="mt-36 flex flex-col items-center justify-center gap-4">
      <div className="rounded-full bg-[#E3AF4A33] p-5">
        <Voices stroke={colors.$pray_featured} />
      </div>
      <div className="flex max-w-80 flex-col items-center justify-center gap-2">
        <Text variant={typography.heading_lg} color={colors.text_primary}>
          Create custom voice
        </Text>
        <Text className="text-center" variant={typography.body_lg} color={colors.text_tertiary}>
          Upload voice samples to create a custom voice for your series by submitting audio files of your original voice
        </Text>
      </div>
      <Button variant={buttons.variant.secondary} size={buttons.size.small} onClick={onClick}>
        Create voice
      </Button>
    </div>
  );
}
