import { Info } from '@pray/shared/components/ui/Icons/Info';
import InfiniteScroll from '@pray/shared/components/ui/InfiniteScroll/InfiniteScroll';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import EmptyState from 'components/pages/StudioPage/components/EmptyState/EmptyState';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { VIEW_EVENT_NAMES } from 'constants.js';
import useAppRoutes from 'hooks/useAppRoutes';
import { Donations } from 'images/icons';

import FundsListTable from './FundListTable';
import GivingPreferenceMessageBox from './GivinPreferenceMessageBox';
import useFundListPage from './useFundListPage';
import DonationModuleDialog from '../DonationModuleDialog/DonationModuleDialog';

export default function FundListPage() {
  const appRoutes = useAppRoutes();
  const { funds, fundsQuery, handleToggleEnabled } = useFundListPage();

  const actionButtons = <ActionButton text="Create Fund" to={appRoutes.artistDonationsFundsCreate()} />;

  return (
    <TabPage
      title="Funds"
      data-viewname={VIEW_EVENT_NAMES.DONATIONS.FUNDS}
      topRightButtons={actionButtons}
      titleInfo={<Info className="hidden size-5 text-[#56585E]" />}
    >
      <GivingPreferenceMessageBox />

      {(() => {
        if (fundsQuery.isLoading) return null;

        if (!fundsQuery.isFetching && funds.length === 0) {
          return (
            <EmptyState
              icon={<Donations />}
              title="Create your Fund"
              subtitle='Click on "Create Fund" to start collecting revenue'
            />
          );
        }

        return (
          <>
            <FundsListTable data={funds} onToggleEnabled={handleToggleEnabled} />
            {fundsQuery.hasNextPage && <InfiniteScroll fetchData={fundsQuery.fetchNextPage} />}
          </>
        );
      })()}

      <DonationModuleDialog />
    </TabPage>
  );
}
