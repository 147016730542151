import React from 'react';

import Text from '@pray/shared/components/ui/Text/Text';

import styles from './EmptyState.module.scss';

export default function EmptyState({ icon, title, subtitle }) {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <Text className={styles.title}>{title}</Text>
      <Text className={styles.subtitle}>{subtitle}</Text>
    </div>
  );
}
