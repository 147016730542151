import React from 'react';

import Text from '@pray/shared/components/ui/Text';

export function TemplatePreviewDesktopHeadline({ profileImageUrl, from, subject }) {
  return (
    <div className="flex items-center gap-3 px-4">
      <img src={profileImageUrl} alt={from} className="size-12 rounded-full" />
      <div className="flex flex-col gap-1 bg-white py-3">
        <Text variant="body_small" className="text-[#71747B]">
          {from || 'My organization'}
        </Text>
        <Text variant="body_small" className="text-[#0B0C0D]">
          {subject || 'Subject line'}
        </Text>
      </div>
    </div>
  );
}
