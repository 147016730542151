import React, { ReactNode } from 'react';

import { buttons, typography, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text';
import { cn } from '@pray/shared/utils/styles';

import type { SidebarTab } from '../types';

type SidebarProps = {
  sidebarTabs: SidebarTab[];
  activeSidebarTab: string;
  activeTabContent: ReactNode;
  showContent?: boolean;
  handleSidebarTabChange: (tabId: string) => void;
};

export default function EditorContainerSidebar({
  sidebarTabs,
  activeSidebarTab,
  activeTabContent,
  showContent = true,
  handleSidebarTabChange,
}: SidebarProps) {
  if (sidebarTabs.length === 0) return null;

  return (
    <div className="flex">
      <div className="flex w-fit min-w-14 flex-col items-center gap-4 border-r px-1 pt-3">
        {sidebarTabs.map((tab) => (
          <div key={tab.id} className="flex flex-col items-center gap-1">
            <Button
              variant={buttons.variant.tertiary}
              className={cn('p-2', activeSidebarTab === tab.id && '!bg-[#F2F3F4]')}
              onClick={() => handleSidebarTabChange(tab.id)}
            >
              {tab.icon}
            </Button>
            {tab.title && (
              <Text variant={typography.body_sm} color={colors.text_primary} className="text-center !font-medium">
                {tab.title}
              </Text>
            )}
          </div>
        ))}
      </div>

      {showContent && <div className="flex w-[316px] border-r">{activeTabContent}</div>}
    </div>
  );
}
