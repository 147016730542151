import React from 'react';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Modal from '@pray/shared/components/ui/Modal/Modal';
import Text from '@pray/shared/components/ui/Text';

import { CrossedClose } from 'images/icons';

import ActionButton from '../../../../components/ActionButton/ActionButton';

export default function CampaignModal({ campaign, isOpen, onClose, title, subtitle }) {
  if (!isOpen) return null;

  return (
    <Modal isShowCloseButton onClose={onClose} noPadding closeIcon={<CrossedClose />}>
      <Text className="text-3xl font-bold">{title}</Text>

      <Text className="my-7 text-[#56585E]" variant={typography.body_small}>
        {subtitle}
      </Text>

      <div className="flex justify-end gap-4">
        <Button variant={buttons.variant.secondary} onClick={onClose}>
          Cancel
        </Button>
        <ActionButton text="Edit Campaign" to={`/ministry-match/campaigns/${campaign.id}`} />
      </div>
    </Modal>
  );
}
