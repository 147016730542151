import React from 'react';

import Button from '@pray/shared/components/ui/Button';

import { PauseVoice, PlayVoice } from 'images/icons';
import { cn } from 'styles/utils';

const COLORS = {
  playing: '#DEDFE3',
  default: '#0B0C0D',
};

export default function VoicePlaybackButton({ voice, isPlaying, disabled = false, onPause, onPlay }) {
  return (
    <Button
      className={cn('rounded-full bg-black p-2', isPlaying ? `bg-[${COLORS.playing}]` : `bg-[${COLORS.default}]`)}
      disabled={disabled}
      onClick={isPlaying ? onPause : () => onPlay(voice.id, voice.audioUrl)}
    >
      {isPlaying ? <PauseVoice width={24} height={24} /> : <PlayVoice width={24} height={24} />}
    </Button>
  );
}
