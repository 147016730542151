import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import { Info } from 'images/icons';

import { getRevenueStatusConfig } from '../../constants';

export default function RevenueStatusAlert({ status, isLoadingViewAccount, onAction }) {
  const { label, button, color } = getRevenueStatusConfig(status) || {};

  return (
    <div
      className="flex flex-col gap-6 rounded-2xl border"
      style={{
        borderColor: color?.border,
        backgroundColor: color?.background,
      }}
    >
      <div className="flex flex-col gap-8 px-4 py-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2">
            <Info className={`text-[${color?.text}]`} />
            <Text className={`text-[${color?.text}] !font-bold`} variant={typography.heading_md}>
              {label?.title}
            </Text>
          </div>
          <Text className={`text-[${color?.text}] !font-medium`} variant={typography.body_md}>
            {label?.description}
          </Text>
        </div>
        <ActionButton
          text={button?.title}
          className="w-fit"
          variant={button?.variant}
          onClick={onAction}
          disabled={isLoadingViewAccount}
        />
      </div>
    </div>
  );
}
