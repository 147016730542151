import { useRef } from 'react';

import useClickAway from '@pray/shared/hooks/useClickAway';
import { cn } from '@pray/shared/utils/styles';

export function Tooltip({ position, children, onClose, className = '', ...props }) {
  const elementRef = useRef(null);
  const tooltipProps = { ...props };

  if (position) {
    tooltipProps.style = {
      top: `${position.y}px`,
      left: `${position.x}px`,
    };
  }

  useClickAway(elementRef, onClose);

  return (
    <div
      ref={elementRef}
      className={cn(
        'absolute rounded-md border border-[#0B0C0D1A] bg-white shadow-[0px_1px_4px_0px_#00000029] hover:bg-[#F2F3F4]',
        className
      )}
      {...tooltipProps}
    >
      {children}
    </div>
  );
}
