/**
 * Get a formatted file name
 *
 * @param name - The raw name of the file
 * @param extension - The extension of the file
 * @param locale - The locale of the file (optional)
 */
export function getFormattedFileName(name: string, extension: string, locale?: string) {
  const nameWithNoSpaces = name.replace(/\s+/g, '_');
  // remove any character that is not a letter, number, or hyphen
  const formattedName = nameWithNoSpaces.replace(/[^a-zA-Z0-9_-]/g, '');
  return `${formattedName}${locale ? `_${locale}` : ''}.${extension}`;
}
