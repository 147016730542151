import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeaderContentSeriesById(artistId, contentSeriesId) {
  const fetchLeaderContentSeriesById = async () => {
    const response = await studioService.contentSeries.getContentSeriesById({
      artistId,
      contentSeriesId,
    });

    return response.data;
  };

  const query = useQuery({
    enabled: !!(artistId && contentSeriesId),
    queryKey: queryKeys.artistContentSeriesById(artistId, contentSeriesId),
    queryFn: fetchLeaderContentSeriesById,
  });

  return query;
}
