import React, { useMemo, useRef, useEffect } from 'react';

import { colors, typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';
import { formatTime } from '@pray/shared/utils';

import styles from './Scrubber.module.css';

const Scrubber = (props) => {
  const { currentTime, duration, remainingTime, onChange, onEventSeekUpdate, isMinimized = false } = props;

  const rangeRef = useRef();

  const progressStyle = useMemo(() => {
    const completed = Math.min((currentTime / duration) * 100, 100);
    return {
      background: `linear-gradient(to right, #FFFFFF ${completed}%, rgba(255,255,255,0.2) 0)`,
    };
  }, [currentTime, duration, isMinimized]);

  useEffect(() => {
    if (rangeRef && rangeRef.current) {
      rangeRef.current.addEventListener('mousedown', onEventSeekUpdate);
    }
    return () => {
      if (rangeRef && rangeRef.current) {
        rangeRef.current.removeEventListener('mousedown', onEventSeekUpdate);
      }
    };
  }, [rangeRef]);

  return (
    <div className={[styles.scrubberContainer, isMinimized ? styles.scrubberContainerMinimized : ''].join(' ')}>
      {isMinimized && <div className={styles.timestamp}>{formatTime(currentTime)}</div>}

      <div className={styles.seekbar}>
        <input
          className={styles.input}
          type="range"
          step="0.01"
          min="0"
          style={progressStyle}
          max={duration}
          value={currentTime}
          ref={rangeRef}
          onChange={onChange}
        />
      </div>

      {isMinimized && <div className={styles.timestamp}>-{formatTime(remainingTime)}</div>}

      {!isMinimized && (
        <div className={styles.timestamps}>
          <Text variant={typography.caption_small} color={colors.text_secondary} className={styles.timestamp}>
            {formatTime(currentTime)}
          </Text>

          <Text variant={typography.caption_small} color={colors.text_secondary} className={styles.timestamp}>
            -{formatTime(remainingTime)}
          </Text>
        </div>
      )}
    </div>
  );
};

export default Scrubber;
