type CheckIconProps = {
  /** Fill color for the icon */
  fill?: string;
  /** Additional CSS classes */
  className?: string;
};

export default function CheckIcon({ fill = 'white', className, ...rest }: CheckIconProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...rest}
    >
      <path
        d="M5.93426 10.5085L3.58682 7.89774C3.22381 7.49402 2.63526 7.49402 2.27226 7.89774C1.90925 8.30147 1.90925 8.95604 2.27226 9.35977L5.2703 12.6941C5.63699 13.102 6.23152 13.102 6.59821 12.6941L13.7277 4.76482C14.0908 4.3611 14.0908 3.70652 13.7277 3.3028C13.3647 2.89907 12.7762 2.89907 12.4132 3.3028L5.93426 10.5085Z"
        fill={fill}
      />
    </svg>
  );
}
