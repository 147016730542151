import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { CALENDAR_ROW_HEIGHT } from 'constants.js';

import styles from './EventsCalendar.module.scss';

export default function CalendarTimeNowIndicator({ date, hour }) {
  const [currentDateTime, setCurrentDateTime] = useState(DateTime.local());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDateTime(DateTime.local());
    }, 60000); // 1 minute

    return () => clearInterval(interval);
  }, []);

  const checkIfCurrentTimeIsInDateAndHour = (date, hour) => {
    if (hour === -1) return false;

    return currentDateTime.hour === hour && currentDateTime.hasSame(date, 'day');
  };

  const getIndicatorTopPosition = (initialTopPosition = 0) => {
    const currentMinute = currentDateTime.minute;
    const percentageOfHourSlot = currentMinute / 60;

    return initialTopPosition + percentageOfHourSlot * CALENDAR_ROW_HEIGHT;
  };

  return (
    checkIfCurrentTimeIsInDateAndHour(date, hour) && (
      <div className={styles.calendarTimeNowIndicator}>
        <div style={{ top: getIndicatorTopPosition(-10) }} className={styles.calendarTimeNowLabel}>
          {currentDateTime.toFormat('h:mm a')}
        </div>
        <div style={{ top: getIndicatorTopPosition() }} className={styles.calendarTimeNowIndicatorLine}>
          <div className={styles.calendarTimeNowIndicatorCircle} />
        </div>
      </div>
    )
  );
}
