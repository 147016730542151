import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useLeadClientIntegrations() {
  const result = useQuery({
    queryKey: queryKeys.leadClientIntegrations(),
    queryFn: () => studioService.leadClients.getAllLeadClientIntegrations(),
  });

  return result;
}
