import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import { ChevronDown } from '@pray/shared/components/ui/Icons/ChevronDown';
import { ChevronLeft } from '@pray/shared/components/ui/Icons/ChevronLeft';
import { Info } from '@pray/shared/components/ui/Icons/Info';
import { InputHelperText } from '@pray/shared/components/ui/InputField/InputField';
import RadioButtonGroup from '@pray/shared/components/ui/RadioButtonGroup/RadioButtonGroup';
import Select from '@pray/shared/components/ui/Select/Select';
import Text from '@pray/shared/components/ui/Text/Text';
import TextArea from '@pray/shared/components/ui/TextArea/TextArea';
import TextInput from '@pray/shared/components/ui/TextInput';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';
import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import { formatDateUsingISOForDatePicker } from '@pray/shared/utils/datetime';
import { downloadFileFromBlob } from '@pray/shared/utils/download';
import logger from '@pray/shared/utils/logger';

import FooterPortal from 'components/layouts/FooterPortal';
import ActionButton from 'components/pages/StudioPage/components/ActionButton/ActionButton';
import ContentStatus from 'components/pages/StudioPage/components/ContentStatus/ContentStatus';
import MediaUpload from 'components/pages/StudioPage/components/MediaUpload/MediaUpload';
import Section from 'components/pages/StudioPage/components/Section/Section';
import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';
import { PLACEHOLDER_IMAGE, VIEW_EVENT_NAMES } from 'constants.js';
import useLeadCampaign from 'hooks/leadCampaigns/useLeadCampaign';
import useSaveLeadCampaign from 'hooks/leadCampaigns/useSaveLeadCampaign';
import useToggleMaster from 'hooks/leadCampaigns/useToggleMaster';
import useLeadClient from 'hooks/leadClients/useLeadClient';
import useLeadClients from 'hooks/leadClients/useLeadClients';
import useAppRoutes from 'hooks/useAppRoutes';
import { USAFlag } from 'images/icons';

import PhonePreview from '../../../../components/PhonePreview/PhonePreview';
import messages from '../UiMessages';

import churchIcon from 'images/icons/church.svg';

import styles from './CampaignForm.module.scss';

export default function CampaignForm() {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const navigate = useNavigate();

  const { id: campaignId } = useParams();
  const { data: campaign } = useLeadCampaign(campaignId);
  const { isPending: isLoading, saveLeadCampaign } = useSaveLeadCampaign();
  const { toggleMasterCampaign } = useToggleMaster();

  const form = useForm({
    id: campaign?.id || '',
    name: campaign?.name || '',
    title: campaign?.title || '',
    subtitle: campaign?.subtitle || '',
    button_text: campaign?.button_text || '',
    image_url: campaign?.image_url || '',
    type: campaign?.type || 'email',
    auto_populate_phone_number: campaign?.auto_populate_phone_number || false,
    target_count: campaign?.target_count || '',
    current_count: campaign?.current_count || '',
    lead_client_id: campaign?.lead_client_id || '',
    schedule_type: campaign?.scheduled_end_date ? 'start_and_end' : 'start',
    scheduled_start_date: campaign?.scheduled_start_date || formatDateUsingISOForDatePicker(new Date()),
    scheduled_end_date: campaign?.scheduled_end_date || '',
    created_at: campaign?.created_at || '',
    updated_at: campaign?.updated_at || '',
    master_toggle: campaign?.master_toggle || false,
    leads_strategy: campaign?.daily_target_limit ? 'daily_target' : 'fast_as_possible',
    daily_target_limit: campaign?.daily_target_limit,
  });

  const handleSaveCampaign = async () => {
    try {
      const { id } = await saveLeadCampaign(form);
      navigate(appRoutes.ministryMatchCampaignDetails(id), { replace: true });

      toast.show({ success: 'Campaign saved successfully!' });

      form.setErrors({});
      form.setChanged(false);
    } catch (err) {
      logger.error('Failed to save campaign', err);
    }
  };

  const handleToggleUpdate = async (campaign) => {
    try {
      await toggleMasterCampaign(campaign.id);
      toast.show({ success: 'Campaign updated successfully!' });
    } catch (error) {
      const response = error?.response?.data;
      const errorCode = response?.data[0]?.message;

      if (errorCode === 'target_count_reached') {
        toast.show({
          error: messages.target_count_reached.subtitle,
        });
      } else if (errorCode === 'invalid_scheduled_at_date') {
        toast.show({
          error: messages.invalid_scheduled_at_date.subtitle,
        });
      } else {
        toast.show({ error: 'Failed to update campaign' });
      }
    }
  };

  const handleDownloadLeads = async () => {
    try {
      const response = await studioService.leadCampaigns.getLeadCampaignCsvExport({
        leadCampaignId: campaignId,
      });

      await downloadFileFromBlob(response, `leads_${campaign.name}.csv`);
    } catch (err) {
      logger.error('Failed to download leads csv for campaign', err);
    }
  };

  const renderActionButtons = () => {
    return (
      <div className="flex flex-1 space-x-3">
        <Button
          variant={buttons.variant.secondary}
          onClick={() => navigate(appRoutes.ministryMatchCampaigns(), { replace: true })}
        >
          Cancel
        </Button>

        <ActionButton
          isLoading={isLoading}
          disabled={isLoading || !form.changed}
          text={isLoading ? 'Saving' : 'Save'}
          onClick={handleSaveCampaign}
        />
      </div>
    );
  };

  const renderTopRightButtons = () => (
    <div>
      <div className="hidden md:block">{renderActionButtons()}</div>
    </div>
  );

  const renderDownloadDetails = () => {
    return (
      <Section className="z-10 max-w-4xl">
        <div>
          <Text variant="h2">Download options</Text>
          <br />
          <br />
          <div className={styles.formField}>
            <Button variant={buttons.variant.secondary} onClick={handleDownloadLeads} target="_blank">
              Download Leads
            </Button>
          </div>
        </div>
      </Section>
    );
  };

  const title = campaignId ? 'Campaign Details' : 'New Campaign';
  const viewName = campaignId
    ? VIEW_EVENT_NAMES.MINISTRY_MATCH.CAMPAIGNS_EDIT
    : VIEW_EVENT_NAMES.MINISTRY_MATCH.CAMPAIGNS_CREATION;

  return (
    <TabPage title={title} viewName={viewName} topRightButtons={renderTopRightButtons()}>
      <div className="mt-4 flex flex-col gap-16 md:flex-row">
        <div className="flex flex-col gap-9">
          <ToggleMaster form={form} onChange={() => handleToggleUpdate(form.values)} />
          <CampaignDetails form={form} />
          <Organization form={form} />
          <OfferDetails form={form} />
          <Schedule form={form} />
          <LeadSettings form={form} />
          {form.values.id && <div>{renderDownloadDetails()} </div>}
        </div>

        <div className="overflow-hidden">
          <div>{form.values.id && <CampaignMetadata form={form} />}</div>
          <CampaignOverview data={form.values} isLoading={isLoading} />
        </div>
      </div>

      <FooterPortal>{renderActionButtons()}</FooterPortal>
    </TabPage>
  );
}

function ToggleMaster({ form, onChange }) {
  return (
    <div>
      {form.values.id && (
        <div
          className={`flex items-center rounded-lg p-6 ${form.values.master_toggle ? 'bg-orange-50' : 'bg-gray-50'}`}
        >
          <ToggleSwitch checked={form.values.master_toggle} onChange={onChange} />

          <span className="ml-4 text-sm">
            {form.values.master_toggle ? 'Your campaign is running' : 'Your campaign is disabled'}
          </span>
        </div>
      )}
    </div>
  );
}

export const ImageWithCustomFallback = ({ imageUrl, altText, className }) => {
  const [hasError, setHasError] = useState(!imageUrl);

  const handleError = () => {
    setHasError(true);
  };

  // Reset error state when imageUrl changes
  useEffect(() => {
    setHasError(!imageUrl);
  }, [imageUrl]);

  return (
    <div className="flex w-full justify-center">
      {hasError ? (
        <img className={className} src={PLACEHOLDER_IMAGE} alt={altText} />
      ) : (
        <img className={className} src={imageUrl} alt={altText} onError={handleError} />
      )}
    </div>
  );
};

function CampaignOverview({ isLoading, data }) {
  const [imageUrl, setImageUrl] = useState(data?.image_url);

  useEffect(() => {
    if (data?.image_file instanceof File) {
      setImageUrl(URL.createObjectURL(data?.image_file));
    } else {
      setImageUrl(data?.image_url);
    }
  }, [data?.image_file]);

  useEffect(() => {
    setImageUrl(data?.image_url);
  }, [data?.image_url]);

  if (isLoading || !data) {
    return <PhonePreview isLoading loadingText="Your preview will appear here" />;
  }

  return (
    <PhonePreview>
      <div className={`${styles.scrollBarCustom} h-[595px] flex-col justify-between overflow-y-auto px-3`}>
        {/* main content */}
        <div className="w-full">
          {/* app header */}
          <div className="mb-1 flex items-center justify-between text-xs text-gray-400">
            <ChevronLeft />
          </div>

          {/* campaign header */}
          <div className="my-2">
            <span className="block pb-1 text-lg font-bold">{data.title}</span>
            <span className="block text-xs text-gray-400">{data.subtitle}</span>
          </div>

          {/*  capture phone number section */}
          {data.type === 'email_and_phone' && (
            <div className="my-4">
              <p className="text-xs">{data?.body}</p>
              <div className="flex w-full max-w-sm items-center rounded-lg border border-gray-200 bg-neutral-700 p-3 text-white">
                <USAFlag className="size-5 text-xl text-green-500" />
                <ChevronDown className="ml-2 text-xl text-green-500" />
                <span className="ml-4 text-xs text-gray-200">Phone number</span>
              </div>
            </div>
          )}

          {/*  post image */}
          <div className="my-4 flex w-full items-center justify-center rounded-xl">
            <ImageWithCustomFallback
              className="max-h-[300px] w-full rounded-xl object-contain"
              imageUrl={imageUrl}
              altText={data?.title}
            />
          </div>
        </div>

        {/* button content */}
        <div>
          {data.type === 'email_and_phone' && (
            <span className="block py-2 text-[11px] leading-[13px] text-gray-400">
              By tapping the button below I hereby provide my express consent for Pray.com or its affiliates to contact
              me via the email and phone number, including text/media messaging, provided.
            </span>
          )}
          <button type="button" className="w-full rounded-md bg-gray-100 p-3 text-xs font-bold uppercase text-gray-900">
            {data.button_text || 'Button text'}
          </button>
        </div>
      </div>
    </PhonePreview>
  );
}

const CampaignMetadata = ({ form }) => {
  const modifiedDate = form.values.updated_at || form.values.created_at;

  return (
    <div className="mb-4 mt-2 flex flex-col">
      <div className="mt-3">
        <Text className="block text-sm font-bold">Status</Text>
        <Text className="line-clamp-1 w-40 text-sm">
          <ContentStatus status={form.values.master_toggle ? 'active' : 'inactive'} />
        </Text>
      </div>
      {modifiedDate && (
        <div className="mt-3">
          <Text className="block text-sm font-bold">Modified</Text>
          <Text className="text-sm">
            {DateTime.fromISO(form.values.updated_at).toLocaleString(DateTime.DATETIME_MED)}
          </Text>
        </div>
      )}
    </div>
  );
};

const CampaignDetails = ({ form }) => {
  return (
    <Section className="max-w-4xl">
      <div className={styles.formField}>
        <TextInput
          label="Campaign Title (required)"
          name="name"
          value={form.values.name}
          errorMessage={form.errors.name}
          onChange={form.handleChange}
        />
      </div>
    </Section>
  );
};

const OfferDetails = ({ form }) => {
  return (
    <>
      <Section className="max-w-4xl">
        <div>
          <Text variant="h2">Offer Details</Text>
          <span className="block text-sm text-[#3A3C40]"> The offer details for the campaign </span>
        </div>

        <div className={styles.formField}>
          <TextInput
            label="Offer title (required)"
            name="title"
            value={form.values.title}
            errorMessage={form.errors.title}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextArea
            rows={5}
            label="Description (required)"
            name="subtitle"
            value={form.values.subtitle}
            errorMessage={form.errors.subtitle}
            onChange={form.handleChange}
          />
        </div>

        <div className={styles.formField}>
          <TextInput
            label="Button text (required)"
            name="button_text"
            value={form.values.button_text}
            errorMessage={form.errors.button_text}
            onChange={form.handleChange}
          />
        </div>
      </Section>

      <div className={styles.formField}>
        <MediaUpload
          imageUrl={form.values.image_url}
          title="Free image resource"
          description="We recommend uploading a square image with a transparent background. We support PNG and JPG formats."
          uploadButtonLabel={form.values.image_url ? 'Change Image' : 'Upload Image'}
          error={form.errors.image_url}
          cropProps={{ aspect: 1 }}
          onValidateImage={(img) => img.height === img.width}
          minifyImage={{
            maxSizeMB: 1,
            maxWidthOrHeight: 500,
          }}
          onFileChange={(file) => form.setValue('image_file', file)}
          onError={(error) => form.setError('image_url', error)}
        />
      </div>
    </>
  );
};

const LeadSettings = ({ form }) => {
  /**
   * Handle the change of the lead strategy
   * @param value {'fast_as_possible' | 'daily_target'}
   */
  const handleLeadStrategyChange = (value) => {
    form.setValue('leads_strategy', value);

    if (value === 'fast_as_possible') return;

    /**
     * In case daily_target is selected with end date, calculate the daily target limit
     * dividing the target count by the days left
     */
    const targetLeadCount = form.values.target_count;
    const scheduledEndDate = form.values.scheduled_end_date;
    if (scheduledEndDate) {
      const endDateDateTime = DateTime.fromFormat(scheduledEndDate.slice(0, 10), 'yyyy-MM-dd');
      const today = DateTime.now();
      const daysLeft = endDateDateTime.diff(today, 'days').days;
      form.setValue('daily_target_limit', Math.ceil(targetLeadCount / daysLeft));
    }
  };

  return (
    <>
      <Section>
        <div>
          <Text variant={typography.headline_medium}>Lead Type</Text>

          <RadioButtonGroup
            radioGroupName="type"
            options={[
              {
                value: 'email',
                label: 'Email',
                description: 'Email address',
              },
              {
                value: 'email_and_phone',
                label: 'Email + Phone Number',
                description: 'Email address and phone number',
              },
            ]}
            value={form.values.type}
            onChange={(value) => form.setValue('type', value)}
          />

          <div className="ml-9 flex items-center gap-4">
            <ToggleSwitch
              name="auto_populate_phone_number"
              checked={form.values.auto_populate_phone_number}
              onChange={form.handleChange}
            />
            <Text className="flex items-center gap-2">
              Phone number will be Autopopulated <Info />
            </Text>
          </div>
        </div>
      </Section>

      <Section>
        <Text variant={typography.headline_medium}>Target Leads</Text>

        <div className={styles.formField}>
          <TextInput
            type="number"
            label="Total Leads count"
            name="target_count"
            min={1}
            value={form.values.target_count}
            errorMessage={form.errors.target_count}
            onChange={form.handleChange}
            description="Once the target leads count has been reached, the campaign will automatically be turned off"
          />
        </div>

        <RadioButtonGroup
          radioGroupName="leads_strategy"
          options={[
            {
              value: 'fast_as_possible',
              label: 'Fast as possible',
              description: 'Leads will be collected as fast as possible',
            },
            {
              value: 'daily_target',
              label: 'Daily target',
              description: 'Leads will be collected daily until the target is reached',
            },
          ]}
          value={form.values.leads_strategy}
          onChange={handleLeadStrategyChange}
        />

        {form.values.leads_strategy === 'daily_target' && (
          <div className="ml-9 mt-[-24px]">
            <div>
              <TextInput
                type="number"
                label="Daily Leads Count (required)"
                required
                name="daily_target_limit"
                value={form.values.daily_target_limit}
                errorMessage={form.errors.daily_target_limit}
                onChange={form.handleChange}
              />
              <span className="block text-xs text-[#3A3C40]">
                The number of leads that will be collected daily until the target is reached
              </span>
            </div>
          </div>
        )}

        {form.values.id && (
          <div className={styles.formField}>
            <TextInput
              disabled
              label="Total Leads collected"
              name="current_count"
              value={form.values.current_count}
              errorMessage={form.errors.current_count}
            />
          </div>
        )}
      </Section>
    </>
  );
};

const Organization = ({ form }) => {
  const isEditMode = !!form.values.id;
  const leadClientId = isEditMode ? form.values.lead_client_id : null;
  const { data: leadClient } = useLeadClient(leadClientId);
  const { data, isFetching, fetchNextPage } = useLeadClients();

  const organizations = useMemo(() => {
    if (isEditMode && leadClient) return [leadClient];
    return data?.pages.flatMap((page) => page.data) || [];
  }, [data, isEditMode, leadClient]);

  const getOrganizationLogo = (organization) => {
    const org = organization || organizations.find((org) => org.id === form.values.lead_client_id);

    if (!org) return null;

    return (
      <img
        alt=""
        src={org.image_url || churchIcon}
        className="size-9 translate-x-[-4px] rounded"
        onError={(event) => {
          event.target.src = churchIcon;
        }}
      />
    );
  };

  return (
    <Section className="max-w-4xl">
      <div>
        <Text variant="h2">Organization</Text>
        <span className="block text-sm text-[#3A3C40]"> The organization assigned to the campaign </span>
      </div>

      <div className={styles.formField}>
        <div className={form.values.lead_client_id ? styles.selected : ''}>
          <Select
            label="Organization"
            name="lead_client_id"
            value={form.values.lead_client_id}
            error={form.errors.lead_client_id}
            items={organizations}
            disabled={isEditMode}
            loading={isFetching}
            getItemLabel={(organization) => organization?.name}
            getItemValue={(organization) => organization?.id}
            getLeftIcon={getOrganizationLogo}
            onInfiniteScroll={fetchNextPage}
            onChange={form.handleChange}
          />
        </div>
      </div>
    </Section>
  );
};

const Schedule = ({ form }) => {
  return (
    <Section className="z-10 max-w-4xl">
      <div>
        <Text variant="h2">Schedule</Text>

        <RadioButtonGroup
          radioGroupName="schedule_type"
          options={[
            {
              value: 'start',
              label: 'Set start date to run your ad continuosly',
            },
          ]}
          value={form.values.schedule_type}
          onChange={(value) => form.setValue('schedule_type', value)}
        />

        {form.values.schedule_type === 'start' && (
          <div className="ml-9 mt-[-16px]">
            <div className="flex w-1/2">
              <DatePicker
                label="Start date"
                value={form.values.scheduled_start_date}
                onChange={(date) => form.setValue('scheduled_start_date', date)}
              />
            </div>
            <InputHelperText className="!text-red-500">{form.errors.scheduled_start_date}</InputHelperText>
          </div>
        )}

        <RadioButtonGroup
          radioGroupName="schedule_type"
          options={[
            {
              value: 'start_and_end',
              label: 'Set start date and end date to run your ad',
            },
          ]}
          value={form.values.schedule_type}
          onChange={(value) => form.setValue('schedule_type', value)}
        />

        {form.values.schedule_type === 'start_and_end' && (
          <div className="ml-9">
            <div className="mt-[-16px] flex items-center gap-4">
              <DatePicker
                label="Start date"
                value={form.values.scheduled_start_date}
                onChange={(date) => form.setValue('scheduled_start_date', date)}
              />
              <Text className="mt-2">-</Text>
              <DatePicker
                label="End date"
                value={form.values.scheduled_end_date}
                onChange={(date) => form.setValue('scheduled_end_date', date)}
              />
            </div>
            <InputHelperText className="!text-red-500">{form.errors.scheduled_end_date}</InputHelperText>
            <Text className="mt-1.5 text-[14px] text-[#3A3C40]">
              Ad delivery is based on your registered account timezone (UTC+00:00).
            </Text>
          </div>
        )}
      </div>
    </Section>
  );
};
