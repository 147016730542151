import api from '@pray/shared/utils/api';
import type { ObjectResponse } from '@pray/shared/utils/api/types/response';

import type { BookProcessStatus } from './types';

/* eslint-disable camelcase */
export type BookStatusResponse = {
  text_extraction_status: BookProcessStatus;
  text_extraction_updated_at: string;
  audiobook_generation_status: BookProcessStatus;
  audiobook_generation_updated_at: string;
};
/* eslint-enable camelcase */

type GetBookStatusProps = {
  artistId: string;
  bookId: string;
};

export const getBookStatus = async ({ artistId, bookId }: GetBookStatusProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/status`;
  const response = await api.executeGetRequest<ObjectResponse<BookStatusResponse>>(url);

  return response.data;
};
