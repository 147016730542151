import { matchPath } from 'react-router-dom';

import storage from '@pray/shared/utils/storage';

/**
 * Get the back route from the navigation history that matches one of the route patterns.
 *
 * @param {string[]} routePatterns - The possible origin patterns to match.
 * @param {string} defaultBackRoute - The default return.
 * @returns {string} - The back route or default path.
 */
export function getBackRoute(routePatterns = [], defaultBackRoute = null) {
  const navigationHistory = storage.getNavigationHistory();

  const backRoute = navigationHistory
    .reverse()
    .find((path) => routePatterns.some((pattern) => matchPath(pattern, path)));

  return backRoute || defaultBackRoute;
}

/**
 * Detecting if we are using Safari browser
 *
 * @returns {boolean} - true/false if safari browser is used
 */
export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
