import api from '@pray/shared/utils/api';
import type { CursorInfiniteQueryParams } from '@pray/shared/utils/api/types/fetch';

import { GetLeadListsResponse } from './types';

type GetLeadListsProps = {
  artistId: string;
  lastItemIdentifier?: string;
  search?: string;
  sortDirection?: 'asc' | 'desc';
};

export async function getLeadLists({
  artistId,
  lastItemIdentifier,
  search = '',
  sortDirection = 'asc',
}: GetLeadListsProps) {
  if (!artistId) throw new Error('Artist ID is required');

  const params: CursorInfiniteQueryParams = {};

  if (search) {
    params.search = search;
  }

  if (lastItemIdentifier) {
    params.last_item_identifier = lastItemIdentifier;
  }

  if (sortDirection) {
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/leads-lists`;
  const response = await api.executeGetRequest<GetLeadListsResponse>(url, params);

  return response.data;
}
