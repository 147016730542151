import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';

import { VOICE_MESSAGES } from '../constants';

function mapVoices(data) {
  return data?.voices?.map((voice) => ({
    id: voice.id,
    artistId: voice.artist_id,
    name: voice.name,
    isMain: voice.is_primary,
    audioUrl: voice.audio_url,
  }));
}

export default function useVoiceService(artistId) {
  const queryClient = useQueryClient();
  const toast = useToastMessage();

  const handleOperation = useCallback(
    async (operation, successMessage, errorMessage) => {
      try {
        const result = await operation();
        toast.show({ success: successMessage });
        return result;
      } catch (err) {
        toast.show({ error: errorMessage });
        throw err;
      }
    },
    [toast]
  );

  const deleteVoice = useCallback(
    async ({ artistId, voiceId }) => {
      return handleOperation(
        () =>
          studioService.voices.deleteVoice({
            artistId,
            voiceId,
          }),
        VOICE_MESSAGES.DELETE.SUCCESS,
        VOICE_MESSAGES.DELETE.ERROR
      );
    },
    [handleOperation]
  );

  const setMainVoice = useCallback(
    async ({ artistId, voiceId }) => {
      return handleOperation(
        () =>
          studioService.voices.updateVoice({
            artistId,
            voiceId,
            payload: {
              is_primary: true,
            },
          }),
        VOICE_MESSAGES.SET_MAIN.SUCCESS,
        VOICE_MESSAGES.SET_MAIN.ERROR
      );
    },
    [handleOperation]
  );

  const createVoice = useCallback(
    async ({ artistId, name, samplesUrls }) => {
      return handleOperation(
        () =>
          studioService.voices.createVoice({
            artistId,
            payload: {
              name,
              sample_urls: samplesUrls,
            },
          }),
        VOICE_MESSAGES.CREATE.SUCCESS,
        VOICE_MESSAGES.CREATE.ERROR
      );
    },
    [handleOperation]
  );

  const updateVoice = useCallback(
    async ({ artistId, voiceId, name }) => {
      return handleOperation(
        () =>
          studioService.voices.updateVoice({
            artistId,
            voiceId,
            payload: {
              name,
            },
          }),
        VOICE_MESSAGES.UPDATE.SUCCESS,
        VOICE_MESSAGES.UPDATE.ERROR
      );
    },
    [handleOperation]
  );

  const voicesQuery = useQuery({
    enabled: !!artistId,
    queryKey: queryKeys.artistVoices(artistId),
    refetchOnWindowFocus: true,
    queryFn: async () => {
      const response = await studioService.voices.getArtistVoices({ artistId });
      return mapVoices(response.data);
    },
  });

  const deleteVoiceMutation = useMutation({
    mutationFn: deleteVoice,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistVoices(artistId),
      });
    },
  });

  const setMainVoiceMutation = useMutation({
    mutationFn: setMainVoice,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistVoices(artistId),
      });
    },
  });

  const createVoiceMutation = useMutation({
    mutationFn: createVoice,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistVoices(artistId),
      });
    },
  });

  const updateVoiceMutation = useMutation({
    mutationFn: updateVoice,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistVoices(artistId),
      });
    },
  });

  return {
    deleteVoice: deleteVoiceMutation.mutateAsync,
    setMainVoice: setMainVoiceMutation.mutateAsync,
    createVoice: createVoiceMutation.mutateAsync,
    updateVoice: updateVoiceMutation.mutateAsync,
    voices: voicesQuery,
  };
}
