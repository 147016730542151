import { DateTime } from 'luxon';

import { TimeIndicator } from './TimeIndicator';

export default function EventCell({ date, time, getEvents, renderEvent, onMouseEnter = null, onMouseLeave = null }) {
  const dt = date.toFormat('yyyy-MM-dd');
  const hr = time.toFormat('HH');

  const events = (getEvents(dt, hr) || []).sort((a, b) => {
    return DateTime.fromISO(a.startDate).diff(DateTime.fromISO(b.startDate));
  });

  return (
    <td key={date.toISO() + time.toISO()} className="h-36" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className="relative flex size-full flex-col border-b border-r">
        {events.map((event, index) => {
          const startDate = DateTime.fromISO(event.startDate);
          const endDate = DateTime.fromISO(event.endDate);
          const minutes = endDate.diff(startDate, 'minutes').minutes || 20;
          const isCompact = minutes < 20;

          const isMultipleEvents = events.find((evt) => evt.startDate === startDate.toISO() && evt.id !== event.id);
          const divider = isMultipleEvents ? events.length : 1;
          const offset = isMultipleEvents ? index : 0;

          const top = `${(startDate.minute / 60) * 100}%`;
          const left = `${offset * Math.floor(100 / divider)}%`;
          const width = `${Math.floor(100 / divider)}%`;
          const height = `${(minutes / 60) * 100}%`;
          const zIndex = 10 + index;

          return (
            <div key={event.id} className="absolute overflow-hidden" style={{ top, left, width, height, zIndex }}>
              {renderEvent({
                event,
                date: startDate,
                isDisplayTime: events.length === 1,
                isCompact,
                isEventStart: true,
                isEventEnd: true,
              })}
            </div>
          );
        })}

        <TimeIndicator date={date} time={time} />
      </div>
    </td>
  );
}
