import { DateTime } from 'luxon';
import { useFormContext } from 'react-hook-form';

import { buttons, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button';
import DatePicker from '@pray/shared/components/ui/DatePicker';
import Popover from '@pray/shared/components/ui/Popover';
import RadioButtonGroup from '@pray/shared/components/ui/RadioButtonGroup/RadioButtonGroup';
import Select from '@pray/shared/components/ui/Select/Select';
import SelectionCard from '@pray/shared/components/ui/SelectionCard';
import Text from '@pray/shared/components/ui/Text';
import TextInput from '@pray/shared/components/ui/TextInput';
import { ErrorMessage, InfoIcon } from '@pray/shared/components/ui/TextInput/TextInput';
import TimePicker from '@pray/shared/components/ui/TimePicker';
import { formatDateUsingShorterMonthAndTime } from '@pray/shared/utils/datetime';

import Section from 'components/pages/StudioPage/components/Section/Section';

import {
  audience,
  draftCampaignName,
  emailStatus,
  nameMaxLength,
  subjectMaxLength,
  previewTextMaxLength,
  publishTypes,
} from '../constants';

export default function CreateEditEmailForm({ data, isDraft, isEmailStatusActive, onDesignEmail, onSendEmail }) {
  const form = useFormContext();
  const { errors } = form.formState;
  const { id, name, subjectText, previewText, status, rawHtml, scheduledAtDate, htmlCssUpdatedAt, publishType } = data;
  const canChangePublishType = id && !isDraft && publishType === publishTypes.automation;

  return (
    <form id="emailForm" onSubmit={form.handleSubmit(onSendEmail)} noValidate>
      <div className="mt-8 max-w-3xl space-y-8">
        <TextInput
          label="Email Name"
          placeholder="Enter campaign name"
          errorMessage={errors.name?.message.toString()}
          infoElement={
            <Popover description="Email name is not visible to recipients of this campaign. This is only visible to you so you can uniquely identify your campaign.">
              <InfoIcon />
            </Popover>
          }
          helperText={`${name.length} / ${nameMaxLength}`}
          {...form.register('name', {
            required: 'Missing email name',
            maxLength: { value: nameMaxLength, message: 'Email name is too long' },
          })}
        />

        <Section title="Send To" subtitle="Who are you sending this email to?">
          <Select
            label="Audience"
            placeholder="Select audience"
            items={[audience]}
            value={audience}
            getItemLabel={(item) => String(item)}
            getItemValue={(item) => String(item)}
            {...form.register('audience', {
              required: 'Missing audience',
            })}
          />
        </Section>

        <Section title="Subjects" subtitle="What's the subject line for this email?">
          <TextInput
            required
            label="Subject"
            placeholder="Enter subject"
            errorMessage={errors.subjectText?.message.toString()}
            helperText={`${subjectText.length} / ${subjectMaxLength}`}
            {...form.register('subjectText', {
              required: 'Missing email subject',
              maxLength: { value: subjectMaxLength, message: 'Email subject is too long' },
            })}
          />
          <TextInput
            label="Preview text"
            placeholder="Enter preview text"
            infoElement={
              <Popover description="Preview text appears in the inbox after the subject line.">
                <InfoIcon />
              </Popover>
            }
            errorMessage={errors.previewText?.message.toString()}
            helperText={`${previewText.length} / ${previewTextMaxLength}`}
            {...form.register('previewText', {
              maxLength: { value: previewTextMaxLength, message: 'Email preview text is too long' },
            })}
          />
        </Section>

        <Section title="Publish" subtitle="How do you want to send the email?">
          <div>
            {isEmailStatusActive && (
              <RadioButtonGroup
                radioGroupName="publishType"
                options={[
                  {
                    value: publishTypes.schedule,
                    label: 'Schedule',
                    disabled: canChangePublishType,
                    typography: typography.subhead_small,
                  },
                ]}
                value={publishType}
                onChange={(value) => {
                  form.setValue('publishType', value);
                  form.setValue('status', emailStatus.scheduled);
                }}
              />
            )}

            {publishType === publishTypes.schedule && isEmailStatusActive && (
              <div className="mb-3 flex flex-col items-stretch gap-3 lg:flex-row">
                <SelectionCard
                  type="radio"
                  value={emailStatus.scheduled}
                  className="w-full"
                  title="Schedule Time"
                  text="Schedule for a specific date and time in the future"
                  defaultChecked={!!scheduledAtDate}
                  {...form.register('status')}
                  onClick={(event) => form.setValue('status', event.target.value)}
                />
                <SelectionCard
                  type="radio"
                  value={emailStatus.now}
                  className="w-full"
                  title="Send Now"
                  text="Send your email immediately"
                  defaultChecked={!scheduledAtDate}
                  {...form.register('status')}
                  onClick={(event) => form.setValue('status', event.target.value)}
                />
              </div>
            )}

            {publishType === publishTypes.schedule && status === emailStatus.scheduled && isEmailStatusActive && (
              <div className="flex flex-row gap-3">
                <DatePicker
                  label="Publish Date"
                  value={data.scheduledAtDate}
                  minDate={DateTime.local().startOf('day').toJSDate()}
                  onChange={(date) => form.setValue('scheduledAtDate', date)}
                  errorMessage={errors.scheduledAtDate?.message.toString()}
                />
                <TimePicker
                  label="Time"
                  value={data.scheduledAtTime}
                  onChange={(time) => form.setValue('scheduledAtTime', time)}
                  errorMessage={errors.scheduledAtTime?.message.toString()}
                />
              </div>
            )}

            <RadioButtonGroup
              radioGroupName="publishType"
              options={[
                {
                  value: publishTypes.automation,
                  label: 'Automation',
                  description: 'This email will automatically send to users to sign up to Ministry Match.',
                  disabled: canChangePublishType,
                  typography: typography.subhead_small,
                },
              ]}
              value={publishType}
              onChange={(value) => {
                form.setValue('publishType', value);
                form.setValue('status', emailStatus.active);
              }}
            />
          </div>
        </Section>

        <Section title="Content" subtitle="Design the Content and the apperance of your email.">
          {!rawHtml && (
            <div className="space-y-4">
              {errors.rawHtml?.message && <ErrorMessage message={errors.rawHtml?.message.toString()} />}

              <Button variant={buttons.variant.primary} onClick={onDesignEmail}>
                Design Email
              </Button>
            </div>
          )}

          {!!rawHtml && (
            <div className="flex items-center justify-between rounded-xl border p-4 shadow-md">
              <div className="flex items-center gap-4">
                <div className="flex flex-col">
                  <Text className="font-medium">{name || draftCampaignName}</Text>
                  <Text variant={typography.body_lg} className="text-[#56585E]">
                    {formatDateUsingShorterMonthAndTime(htmlCssUpdatedAt)}
                  </Text>
                </div>
              </div>
              <Button variant={buttons.variant.secondary} onClick={onDesignEmail}>
                Edit Email
              </Button>
            </div>
          )}
        </Section>
      </div>
    </form>
  );
}
