import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';
import { RegenerationStatus } from '@pray/shared/services/studioService/courses/types';

type UseRegenerateEpisodeProps = {
  artistId: string;
  courseId: string;
};

type RegenerateEpisodeHookParams = {
  episodeId: string;
};

/**
 * Hook for regenerating a course episode.
 *
 * @param artistId - The ID of the artist who owns the course
 * @param courseId - The ID of the course containing the episode
 * @returns A mutation object for regenerating an episode
 */
export default function useRegenerateEpisode({ artistId, courseId }: UseRegenerateEpisodeProps) {
  const queryClient = useQueryClient();

  const regenerateEpisode = async ({ episodeId }: RegenerateEpisodeHookParams) => {
    if (!episodeId) throw new Error('Episode ID is required');

    const response = await studioService.courses.regenerateEpisode({
      episodeId,
    });

    return response.data;
  };

  const mutation = useMutation({
    mutationFn: regenerateEpisode,
    onSuccess: (data, { episodeId }) => {
      if (data.status !== RegenerationStatus.FAILED) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.artistCourseEpisodes(artistId, courseId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.artistCourseEpisode(artistId, courseId, episodeId),
        });
      }
    },
  });

  return mutation;
}
