import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';

import { ErrorIcon } from './assets/error-icon';
import EmptyState from '../../../components/EmptyState/EmptyState';

export function SocialClipsInvalidFormat() {
  return (
    <div className="mx-auto flex max-w-96 flex-col gap-6">
      <EmptyState
        icon={<ErrorIcon />}
        title="Video format not supported"
        subtitle="We only support mp4 videos, YouTube, Vimeo, Zoom, Rumble, Twitch, Facebook, LinkedIn, Twitter and StreamYard links."
      />
      <div className="flex justify-center">
        <Button disabled variant={buttons.variant.primary}>
          Generate Social Clips
        </Button>
      </div>
    </div>
  );
}
