import { useState, useCallback } from 'react';

import { useUpdateContentArticle } from 'hooks/contentArticle/useContentArticle';

import { useToastMessage } from '../../../../../components/StudioToastMessage';

export function useSaveArticle({ artist, content, locale, setArticle }) {
  const toast = useToastMessage();
  const [isSaving, setIsSaving] = useState(false);
  const { updateContentArticle } = useUpdateContentArticle();

  const saveArticle = useCallback(
    async (article) => {
      try {
        setIsSaving(true);
        await updateContentArticle({
          artistId: artist.id,
          contentId: content.id,
          locale,
          article,
        });

        setArticle(article);

        toast.show({ success: 'Article saved successfully' });
      } catch (error) {
        toast.show({ error: 'Failed to save article' });
      } finally {
        setIsSaving(false);
      }
    },
    [artist.id, content.id, locale, updateContentArticle, setArticle, toast]
  );

  return {
    saveArticle,
    isSaving,
  };
}
