import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Loading from '@pray/shared/components/Loading/Loading';
import Text from '@pray/shared/components/ui/Text';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import { AiFeather } from 'images/icons';

export default function SolomonAnalysingLabel() {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center gap-2">
        <AiFeather />
        <Text className="flex items-center gap-2 !font-medium" variant={typography.body_lg}>
          Analysing data...
          <Spinner color="black" size="small" />
        </Text>
      </div>

      <div className="ml-8 flex flex-col gap-2">
        <Loading isLight isLoading height={24} width={127} margin="0" />
        <Loading isLight isLoading height={24} width={407} margin="0" />
        <Loading isLight isLoading height={24} width={407} margin="0" />
        <Loading isLight isLoading height={24} width={241} margin="0" />
      </div>
    </div>
  );
}
