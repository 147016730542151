import api from '@pray/shared/utils/api';

export async function retriggerTranslations({ artistId, targetLocaleCodes, sourceContentIds, sourceContentSeriesIds }) {
  if (!artistId) {
    throw new Error('Artist ID is required');
  }

  if (
    (!sourceContentIds || sourceContentIds.length === 0) &&
    (!sourceContentSeriesIds || sourceContentSeriesIds.length === 0)
  ) {
    throw new Error('Either sourceContentIds or sourceContentSeriesIds must be provided and not empty');
  }

  const url = `/web/studio/artists/${artistId}/translations/retrigger`;
  const response = await api.executePostRequest(url, {
    target_locale_codes: targetLocaleCodes,
    source_content_ids: sourceContentIds,
    source_content_series_ids: sourceContentSeriesIds,
  });

  return response?.data;
}
