import { buttons } from '@pray/shared/components/foundations';

export const statementDescriptorMinLength = 5;
export const statementDescriptorMaxLength = 22;

export const payoutIntervals = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const payoutIntervalOptions = [
  {
    label: 'Daily',
    value: payoutIntervals.daily,
  },
  {
    label: 'Weekly',
    value: payoutIntervals.weekly,
  },
  {
    label: 'Monthly',
    value: payoutIntervals.monthly,
  },
];

export const RevenueStatuses = {
  ENABLED: 'enabled',
  ENABLED_FIELDS_DUE: 'enabled_fields_due',
  ENABLED_PENDING_VERIFICATION: 'enabled_pending_verification',
  PAUSED_FIELDS_DUE: 'paused_fields_due',
  PAUSED_PENDING_VERIFICATION: 'paused_pending_verification',
  PAYOUTS_PAUSED_FIELDS_DUE: 'payouts_paused_fields_due',
  PAYOUTS_PAUSED_PENDING_VERIFICATION: 'payouts_paused_pending_verification',
  PAYMENTS_PAUSED_FIELDS_DUE: 'payments_paused_fields_due',
  PAYMENTS_PAUSED_PENDING_VERIFICATION: 'payments_paused_pending_verification',
};

export const colorSchemes = {
  blue: {
    text: '#0B0C0D',
    background: '#F1F3FE',
    border: '#387AF0',
  },
  red: {
    text: '#441803',
    background: '#FEF0F0',
    border: '#FA6061',
  },
  purple: {
    text: '#2C0665',
    background: '#F4F2FE',
    border: '#7C32E6',
  },
};

export const buttonConfigs = {
  review: {
    title: 'Review Account',
    variant: buttons.variant.tertiary,
  },
  update: {
    title: 'Update Account',
    variant: buttons.variant.primary,
  },
};

export const revenueStatusConfig = {
  [RevenueStatuses.ENABLED]: {
    button: buttonConfigs.review,
  },
  [RevenueStatuses.ENABLED_FIELDS_DUE]: {
    label: {
      title: 'Updated information is requested',
      description: 'Payments and deposits may pause soon if your account is not updated.',
    },
    button: buttonConfigs.update,
    color: colorSchemes.blue,
  },
  [RevenueStatuses.ENABLED_PENDING_VERIFICATION]: {
    label: {
      title: 'Your updated information is being verified',
      description: 'Updates to your account are being verified to ensure payments and deposits remain enabled.',
    },
    button: buttonConfigs.review,
    color: colorSchemes.purple,
  },
  [RevenueStatuses.PAUSED_FIELDS_DUE]: {
    label: {
      title: 'Updated information is required',
      description: 'To enable payments and deposits, please update your account.',
    },
    button: buttonConfigs.update,
    color: colorSchemes.red,
  },
  [RevenueStatuses.PAUSED_PENDING_VERIFICATION]: {
    label: {
      title: 'Your updated information is being verified',
      description: 'Updates to your account are being verified to enable payments and deposits.',
    },
    button: buttonConfigs.review,
    color: colorSchemes.purple,
  },
  [RevenueStatuses.PAYOUTS_PAUSED_FIELDS_DUE]: {
    label: {
      title: 'Updated information is required',
      description: 'To enable deposits, please update your account.',
    },
    button: buttonConfigs.update,
    color: colorSchemes.red,
  },
  [RevenueStatuses.PAYOUTS_PAUSED_PENDING_VERIFICATION]: {
    label: {
      title: 'Your updated information is being verified',
      description: 'Updates to your account are being verified to enable deposits.',
    },
    button: buttonConfigs.review,
    color: colorSchemes.purple,
  },
  [RevenueStatuses.PAYMENTS_PAUSED_FIELDS_DUE]: {
    label: {
      title: 'Updated information is required',
      description: 'To enable payments, please update your account.',
    },
    button: buttonConfigs.update,
    color: colorSchemes.red,
  },
  [RevenueStatuses.PAYMENTS_PAUSED_PENDING_VERIFICATION]: {
    label: {
      title: 'Your updated information is being verified',
      description: 'Updates to your account are being verified to enable payments.',
    },
    button: buttonConfigs.review,
    color: colorSchemes.purple,
  },
};

export const getRevenueStatusConfig = (status) => {
  return revenueStatusConfig[status] || null;
};

export const isPaymentsPaused = (status) => {
  return [
    RevenueStatuses.PAUSED_FIELDS_DUE,
    RevenueStatuses.PAUSED_PENDING_VERIFICATION,
    RevenueStatuses.PAYMENTS_PAUSED_FIELDS_DUE,
    RevenueStatuses.PAYMENTS_PAUSED_PENDING_VERIFICATION,
  ].includes(status);
};

export const isPayoutsPaused = (status) => {
  return [
    RevenueStatuses.PAUSED_FIELDS_DUE,
    RevenueStatuses.PAUSED_PENDING_VERIFICATION,
    RevenueStatuses.PAYOUTS_PAUSED_FIELDS_DUE,
    RevenueStatuses.PAYOUTS_PAUSED_PENDING_VERIFICATION,
  ].includes(status);
};
