/**
 * Orientation options for the Stepper component
 */
export enum StepperOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

/**
 * Status options for a step
 */
export enum StepStatus {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  INACTIVE = 'inactive',
}

/**
 * Props for the Stepper component
 */
export type StepperProps = {
  /** Array of step names/labels */
  steps: string[];
  /** The currently active step */
  activeStep: string;
  /** Array of completed step names */
  completedSteps?: string[];
  /** Additional CSS classes */
  className?: string;
  /** Child components to render below the stepper */
  children?: React.ReactNode;
  /** Orientation of the stepper - horizontal or vertical */
  orientation?: StepperOrientation;
};
