import React, { useState } from 'react';

import { buttons, colors } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Bookmark } from '@pray/shared/components/ui/Icons/Bookmark';
import { Bookmarked } from '@pray/shared/components/ui/Icons/Bookmarked';
import { ContentList } from '@pray/shared/components/ui/Icons/ContentList';
import { CreatorProfile } from '@pray/shared/components/ui/Icons/CreatorProfile';
import { Options } from '@pray/shared/components/ui/Icons/Options';
import { Verified } from '@pray/shared/components/ui/Icons/Verified';
import Text from '@pray/shared/components/ui/Text/Text';
import BulletList from '@pray/shared/icons/BulletList';
import MaximizeIcon from '@pray/shared/icons/MaximizeIcon';
import ShareIcon from '@pray/shared/icons/shareIcon';

import ChapterSelector from '../ChapterSelector/ChapterSelector';
import Volume from '../Volume/Volume';

import styles from './Actions.module.scss';

const Actions = ({
  isMinimized = false,
  audio = null,
  player = null,
  currentContent = null,
  controlOptions,
  isContentSaved = false,
  onExtraActionsButtonClick = () => null,
  onReactionButtonClick = null,
  onShareButtonClick = null,
  onMaximizeButtonClick = () => null,
  onNavigate = () => null,
  onClose = () => null,
  onChapterSelect = () => null,
}) => {
  const [isShowChapterSelector, setIsShowChapterSelector] = useState(false);
  const actionStyles = [styles.actions];
  if (isMinimized) actionStyles.push(styles.minimized);
  const actionClasses = actionStyles.filter((item) => item).join(' ');

  const SaveIcon = isContentSaved ? Bookmarked : Bookmark;

  return (
    <div className={actionClasses}>
      {isMinimized && <Volume maxVolume={100} currentVolume={audio.currentVolume} onChange={player.volume} />}

      {currentContent.chapters?.length > 0 && controlOptions.isShowChapterSelector && (
        <div className="relative z-[1000] mx-2">
          <IconButton
            className={styles.actionButton}
            data-tapeventname="Toggle Chapters"
            onClick={() => setIsShowChapterSelector(!isShowChapterSelector)}
          >
            <BulletList />
          </IconButton>

          {isShowChapterSelector && (
            <ChapterSelector
              chapters={currentContent.chapters}
              currentChapter={currentContent.id}
              onChapterSelect={onChapterSelect}
            />
          )}
        </div>
      )}

      {!isMinimized && controlOptions.isShowSubscribe && (
        <div className="sm-hide xl-show">
          <Button className={styles.subscribeButton} size={buttons.size.large} to="/subscribe/" onClick={onClose}>
            <Text>Subscribe</Text>
            <Verified fill={colors.$pray_featured} />
          </Button>
        </div>
      )}

      {controlOptions.isShowSave && (
        <IconButton data-tapeventname="Save" className={styles.actionButton} onClick={onReactionButtonClick}>
          <SaveIcon className={styles.actionIcon} />
        </IconButton>
      )}

      {!isMinimized && (
        <>
          {controlOptions.isShowViewAllEpisodes && (
            <div className="sm-hide xl-show">
              <IconButton
                data-tapeventname="View all Episodes"
                className={styles.actionButton}
                to={`/series/${currentContent.content_series_handle}`}
                onClick={onNavigate}
              >
                <ContentList className={styles.actionIcon} />
              </IconButton>
            </div>
          )}

          {controlOptions.isShowViewProfile && (
            <div className="sm-hide xl-show">
              <IconButton
                data-tapeventname="View Creator Profile"
                className={styles.actionButton}
                to={`/artists/${currentContent.artist_id}`}
                onClick={onNavigate}
              >
                <CreatorProfile className={styles.actionIcon} />
              </IconButton>
            </div>
          )}
        </>
      )}

      {controlOptions.isShowShare && (
        <IconButton data-tapeventname="Share" className={styles.actionButton} onClick={onShareButtonClick}>
          <ShareIcon className={styles.actionIcon} />
        </IconButton>
      )}

      {isMinimized && controlOptions.isShowExtraActions && (
        <IconButton data-tapeventname="Options" className={styles.actionButton} onClick={onExtraActionsButtonClick}>
          <Options className={styles.actionIcon} />
        </IconButton>
      )}

      {isMinimized && controlOptions.isShowMaximize && (
        <IconButton data-tapeventname="Maximize" className={styles.actionButton} onClick={onMaximizeButtonClick}>
          <MaximizeIcon className={styles.actionIcon} />
        </IconButton>
      )}
    </div>
  );
};

export default Actions;
