import { useEffect, useState } from 'react';

import studioService from '@pray/shared/services/studioService';

const MAX_REFETCH_RETIES = 10; // up to 10 retries
const REFETCH_INTERVAL = 7 * 1000; // every 7 seconds

export default function useAiSuggestion({ artistId = '', contentId = '', socialClipId = '', property = '' }) {
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [refetchCount, setRefetchCount] = useState(0);

  async function fetchSuggestion() {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchAiSuggestion();

      if (!response.data.is_processing) {
        setText(response.data.suggestion);
        setIsLoading(false);
        setRefetchCount(0);
        return;
      }

      if (refetchCount >= MAX_REFETCH_RETIES) {
        throw Error('Failed to generate new suggestion');
      }

      setRefetchCount((count) => count + 1);
    } catch (error) {
      setText('');
      setError(error.message);
      setIsLoading(false);
      setRefetchCount(0);
    }
  }

  async function fetchAiSuggestion() {
    if (socialClipId) {
      return studioService.socialClips.getAiSuggestions({ artistId, contentId, socialClipId, property });
    }

    return studioService.content.getArtistContentSuggestions({ artistId, contentId, property });
  }

  useEffect(() => {
    if (!refetchCount) return undefined;

    const timeout = setTimeout(fetchSuggestion, REFETCH_INTERVAL);

    return () => clearTimeout(timeout);
  }, [refetchCount]);

  function resetSuggestion() {
    setText('');
    setError(null);
    setIsLoading(false);
    setRefetchCount(0);
  }

  return {
    text,
    isLoading,
    error,
    fetchSuggestion,
    resetSuggestion,
  };
}
