import React from 'react';

import Loading from '@pray/shared/components/Loading/Loading';
import { Table, Thead, Tr, Th } from '@pray/shared/components/ui/Table';

export function TableSkeleton({ type = 'subscribers', rows = 5 }) {
  const getColumns = () => {
    switch (type) {
      case 'organizations':
        return [
          { width: '25%', size: 120 },
          { width: '15%', size: 72 },
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
        ];
      case 'transactions':
        return [
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
          { width: '20%', size: 90 },
        ];
      default: // subscribers
        return [
          { width: '3%', size: 20 },
          { width: '15%', size: 90 },
          { width: '15%', size: 90 },
          { width: '12%', size: 72 },
          { width: '10%', size: 60 },
          { width: '15%', size: 72 },
          { width: '15%', size: 72 },
          { width: '15%', size: 72 },
        ];
    }
  };

  const columns = getColumns();

  return (
    <Table key="table-skeleton" sortable={false} rowCount={rows} disabled>
      <Thead>
        <Tr className="sticky top-0 bg-white">
          {columns.map((col, i) => (
            <Th key={`header-${i}`} field="col" width={col.width} className="p-1">
              <Loading height={14} isLight width={col.size} borderRadius="2px" />
            </Th>
          ))}
        </Tr>
        {[...Array(rows)].map((_, rowIndex) => (
          <Tr key={`row-${rowIndex}`}>
            {columns.map((col, colIndex) => (
              <Th key={`cell-${rowIndex}-${colIndex}`} field="col" width={col.width} className="p-1">
                <Loading height={14} isLight width={col.size} borderRadius="2px" />
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
    </Table>
  );
}
