import React from 'react';

export function Logo(props) {
  return (
    <svg
      width="73"
      height="18"
      viewBox="0 0 73 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.9226 1.73201C10.7266 0.66187 9.02489 0.0944713 7.00168 0.0944713H0.00396729V17.9984H3.19638V12.2741H6.64869C8.62777 12.2741 10.4068 11.7225 11.6595 10.7185C13.0256 9.6232 13.7488 8.03928 13.7488 6.12123C13.7488 4.31981 13.117 2.80214 11.9226 1.73201ZM10.5045 6.19688C10.5045 8.1165 9.02963 9.3569 6.74802 9.3569H3.19476V3.03848H6.74802C9.13678 3.03848 10.506 4.19057 10.506 6.19688H10.5045Z"
        fill="white"
      />
      <path
        d="M28.8787 11.2371C31.5732 10.4632 33.0559 8.53107 33.0559 5.79033C33.0559 2.27732 30.5269 0.0944713 26.4568 0.0944713H18.5484V17.9984H21.7424V11.7918H25.5508L29.8715 17.9794L29.884 17.9984H33.6578L33.6043 17.9243L28.8787 11.2371ZM21.7424 3.04163H26.2048C28.5305 3.04163 29.8115 4.08188 29.8115 5.97156C29.8115 7.76667 28.406 8.92664 26.23 8.92664H21.7424V3.04163Z"
        fill="white"
      />
      <path
        d="M47.6942 0.0283965L47.6831 0H44.7539L44.7413 0.0283965L36.9967 17.8377L36.9683 17.9039H40.2395L40.2522 17.8755L42.05 13.636H50.3099L52.0841 17.8755L52.0951 17.9039H55.4687L55.4387 17.8377L47.6942 0.0283965ZM49.1391 10.7881H43.2224L46.1673 3.88178L49.1391 10.7881Z"
        fill="white"
      />
      <path
        d="M69.0702 0.0961792L69.0576 0.118244L64.0799 7.94174L59.1558 0.118244L59.1417 0.0961792H55.3553L62.438 10.9441V18.0001H65.673V10.8685L72.7085 0.168677L72.7557 0.0961792H69.0702Z"
        fill="white"
      />
    </svg>
  );
}
