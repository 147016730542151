import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const DEFAULT_MARKDOWN_COMPONENTS = {
  h1: ({ children }) => <h1 className="mb-4 bg-inherit text-2xl font-bold">{children}</h1>,
  h2: ({ children }) => <h2 className="mb-3 bg-inherit text-xl font-bold">{children}</h2>,
  h3: ({ children }) => <h3 className="mb-2 bg-inherit text-lg font-bold">{children}</h3>,
  h4: ({ children }) => <h4 className="mb-2 bg-inherit text-base font-bold">{children}</h4>,
  h5: ({ children }) => <h5 className="mb-1 bg-inherit text-sm font-bold">{children}</h5>,
  ul: ({ children }) => <ul className="mb-4 list-disc bg-inherit pl-5">{children}</ul>,
  li: ({ children }) => <li className="mb-1 bg-inherit">{children}</li>,
  blockquote: ({ children }) => (
    <blockquote className="my-4 border-l-4 border-gray-300 bg-inherit pl-4 italic">{children}</blockquote>
  ),
};

export default function Markdown({ children, components = DEFAULT_MARKDOWN_COMPONENTS, ...props }) {
  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} components={components} {...props}>
      {children}
    </ReactMarkdown>
  );
}
