import React, { useEffect, useState } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Text from '@pray/shared/components/ui/Text/Text';
import { PRAY_EXTERNAL_LINKS } from '@pray/shared/constants';
import { isOnBrowser } from '@pray/shared/utils';
import { isCookieBannerDismissed, updateCookieBannerAcceptance } from '@pray/shared/utils/gdpr';
import initialize from '@pray/shared/utils/initialize';

import styles from './CookieBanner.module.scss';

/**
 * List of routes where the cookie banner should not be shown
 */
const pagesToNotShowCookieBanner = ['/donate', '/lead', '/onboarding'];

function shouldShowCookieBanner() {
  return isOnBrowser() && !pagesToNotShowCookieBanner.includes(window.location.pathname);
}

const CookieBanner = () => {
  const [isCookieBannerShown, setIsCookieBannerShown] = useState(false);

  useEffect(() => {
    if (shouldShowCookieBanner()) {
      const _isCookieBannerShown = !isCookieBannerDismissed();
      setIsCookieBannerShown(_isCookieBannerShown);
    }
  }, []);

  function onAcceptClick() {
    setIsCookieBannerShown(false);

    updateCookieBannerAcceptance(true);
    initialize.initializeAfterCookieBannerAcceptance();
  }

  function onRejectClick() {
    setIsCookieBannerShown(false);

    updateCookieBannerAcceptance(false);
    initialize.initializeAfterCookieBannerAcceptance();
  }

  if (!isCookieBannerShown) return null;

  return (
    <div className={styles.cookieBanner}>
      <div className={styles.content}>
        <Text variant={typography.h2} className={styles.title}>
          We care about your privacy
        </Text>
        <Text variant={typography.body_small}>
          We use cookies to ensure you get the best experience on our website. If you “Accept All”, we may collect, use,
          and share your personal information as described in our{' '}
          <a className={styles.link} href={PRAY_EXTERNAL_LINKS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </Text>
        <div className={styles.buttons}>
          <Button variant={buttons.variant.primary} color={colors.pray_featured} onClick={onAcceptClick}>
            ACCEPT ALL
          </Button>
          <Button variant={buttons.variant.secondary} onClick={onRejectClick}>
            REJECT ALL
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
