import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import { Search } from '@pray/shared/components/ui/Icons/Search';
import { Table, Tbody, Td, Th, Thead, Tr } from '@pray/shared/components/ui/Table';
import Pagination from '@pray/shared/components/ui/Table/Pagination';
import TextInput from '@pray/shared/components/ui/TextInput';
import useDebounce from '@pray/shared/hooks/useDebounce';
import usePagination from '@pray/shared/hooks/usePagination';
import studioService from '@pray/shared/services/studioService';
import { formatNumber } from '@pray/shared/utils';

import { useToastMessage } from 'components/pages/StudioPage/components/StudioToastMessage';
import useAppRoutes from 'hooks/useAppRoutes';
import { Pencil, Tag } from 'images/icons';

import plusIcon from '../../../assets/plus-icon.svg';
import ActionButton from '../../../components/ActionButton/ActionButton';
import EmptyState from '../../../components/EmptyState/EmptyState';
import TabPage from '../../../components/TabPage/TabPage';
import AddEditTagModal from '../AddEditTagModal/AddEditTagModal';

import styles from './TagList.module.scss';

export default function TagList() {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const [isShowTags, setIsShowTags] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort') ?? 'name';
  const order = searchParams.get('order') ?? 'asc';
  const tagSortOrder = `${sort} ${order}`;

  const [search, setSearch] = useState(searchParams.get('search') ?? '');
  const debouncedSearch = useDebounce(search);

  const setTagSortOrder = (value) => {
    const [sort, order] = value.split(' ');
    searchParams.set('sort', sort);
    searchParams.set('order', order);
    setSearchParams(searchParams);
  };

  const {
    isFirstPage,
    isLastPage,
    page,
    data,
    fetchData: fetchTagData,
    refetchData: refetchTagData,
    fetchPreviousPage,
    fetchNextPage,
  } = usePagination(async (params = {}) => {
    const [sortColumn, sortDirection] = tagSortOrder.split(' ');
    const response = await studioService.tag.getAllTags({
      search: debouncedSearch,
      sortColumn,
      sortDirection,
      ...params,
    });

    setIsShowTags(true);

    return {
      data: response.data,
      nextItemId: response.next_item,
    };
  });

  useEffect(() => {
    fetchTagData(false, { offset: 0 });
    // update search param in the URL
    if (debouncedSearch) {
      searchParams.set('search', debouncedSearch);
    } else {
      searchParams.delete('search');
    }
    setSearchParams(searchParams);
  }, [debouncedSearch]);

  useEffect(() => {
    if (data.length) refetchTagData();
  }, [tagSortOrder]);

  const renderTopRightButtons = () => {
    return (
      <ActionButton
        text="Add Tag"
        icon={<img src={plusIcon} alt="Add Tag" />}
        onClick={() => setIsTagModalOpen(true)}
      />
    );
  };

  return (
    <TabPage title="Tags" topRightButtons={renderTopRightButtons()}>
      <div className="mb-8 mt-3 w-96">
        <TextInput
          type="search"
          leftIcon={<Search />}
          placeholder="Search tags"
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      </div>

      {(() => {
        if (isShowTags) {
          if (data.length === 0) {
            return <EmptyState icon={<Tag />} title="No tags available" subtitle="Your tags will show up here" />;
          }

          return (
            <TagTable
              data={data}
              page={page}
              sortBy={tagSortOrder}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              onPreviousPageButtonClick={fetchPreviousPage}
              onNextPageButtonClick={fetchNextPage}
              onSortChange={setTagSortOrder}
              onSelectionChange={() => null}
              onItemRowClick={({ id }) => navigate(appRoutes.tagDetails(id), { replace: true })}
              onEditButtonClick={(tag) => {
                setIsTagModalOpen(true);
                setSelectedTag(tag);
              }}
            />
          );
        }

        return null;
      })()}

      <AddEditTagModal
        isOpen={isTagModalOpen}
        data={selectedTag}
        onClose={() => {
          setIsTagModalOpen(false);
          setSelectedTag(null);
        }}
        onSuccess={async () => {
          await refetchTagData();
          setIsTagModalOpen(false);
          setSelectedTag(null);
          toast.show({
            success: `Tag was ${!selectedTag?.id ? 'created' : 'updated'} successfully`,
          });
        }}
        onError={(error) => {
          toast.show({ error });
        }}
      />
    </TabPage>
  );
}

const TagTable = ({
  data,
  page,
  sortBy,
  isFirstPage,
  isLastPage,
  onPreviousPageButtonClick,
  onNextPageButtonClick,
  onSortChange,
  onSelectionChange,
  onItemRowClick,
  onEditButtonClick,
}) => {
  const getRowActions = (rowIndex) => {
    const item = data[rowIndex];
    return [
      <IconButton
        key={`edit-${item.id}`}
        className={styles.actionButton}
        icon={Pencil}
        tooltip="Edit"
        onClick={() => onEditButtonClick(item)}
      />,
    ];
  };

  return (
    <>
      <Table
        sortable
        sortBy={sortBy}
        rowCount={data.length}
        rowActions={getRowActions}
        onSortChange={onSortChange}
        onSelectionChange={onSelectionChange}
      >
        <Thead>
          <Tr>
            <Th field="name">Name</Th>
            <Th field="description">Description</Th>
            <Th field="taggings_count">Items</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((tag) => (
            <Tr key={tag.id}>
              <Td className="max-w-md cursor-pointer" onClick={() => onItemRowClick(tag)}>
                {tag.name}
              </Td>
              <Td className="cursor-pointer" onClick={() => onItemRowClick(tag)}>
                {tag.description}
              </Td>
              <Td className="cursor-pointer" onClick={() => onItemRowClick(tag)}>
                {tag.taggings_count ? formatNumber(tag.taggings_count) : '-'}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Pagination
        page={page}
        isPreviousEnabled={!isFirstPage}
        isNextEnabled={!isLastPage}
        onPreviousClick={onPreviousPageButtonClick}
        onNextClick={onNextPageButtonClick}
      />
    </>
  );
};
