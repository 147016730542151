import { LOCAL_STORAGE as SHARED_LOCAL_STORAGE } from '@pray/shared/constants';

export * from '@pray/shared/constants';

export const DAILY_TYPE = {
  AUDIO: 'audio',
  VIDEO: 'video',
};

export const LOCAL_STORAGE = {
  ...SHARED_LOCAL_STORAGE,
  SELECTED_ARTIST_ID: 'selectedArtistId',
};

export const AUDIO_PLAYER = {
  controlOptions: {
    isShowSkipBackward: false,
    isShowSkipForward: false,
    isShowSave: false,
    isShowShare: false,
    isShowSubscribe: false,
    isShowViewProfile: false,
    isShowViewAllEpisodes: false,
    isShowExtraActions: false,
    isShowMaximize: false,
    isShowClose: true,
  },
};

export const AUDIO_BOOK_PLAYER = {
  controlOptions: {
    isShowSkipBackward: true,
    isShowSkipForward: true,
    isShowChapterSelector: true,
    isShowSave: false,
    isShowShare: false,
    isShowSubscribe: false,
    isShowViewProfile: false,
    isShowViewAllEpisodes: false,
    isShowExtraActions: false,
    isShowMaximize: false,
    isShowClose: true,
  },
};

export const PRAY_ARTIST_NAME = 'Pray';

export const CALENDAR_ROW_HEIGHT = 56;

export const ALL_DAY_DAILY_SERIES_IDS = [
  '00ffd9fc-f678-11ed-9c18-126778781e7f',
  '03610b88-f678-11ed-9c18-126778781e7f',
  '05cd72e6-f678-11ed-9c18-126778781e7f',
  'bd98d603-ed04-4318-85f5-a0b3bfc8a61f', // Jesus Podcast
  'cc76ec0f-798c-11ee-8ae3-124e61249109', // Heartbeat of Faith
  '385fedbc-24b1-11ee-af08-02488e88fb17', // Kids Bible in a Year
  '7854ebb8-773e-11ee-8ae3-124e61249109', // Meditative Prayers
];

export const SENDBIRD = {
  CUSTOM_TYPES: {
    LEADER_BROADCAST_MESSAGE: 'leader_broadcast_message',
    LEADER_FOLLOWER_1_TO_1_CHANNEL: 'leader_follower_1_to_1_channel',
  },
};

export const ANALYTIC_REPORTS = {
  AUDIENCE: 'https://lookerstudio.google.com/embed/reporting/e5114be9-1423-4d20-bc65-1b893c7db8b3/page/6BZ9C',
  LISTEN: 'https://lookerstudio.google.com/embed/reporting/228f7f65-403d-476a-b7e9-5ebb34bccf46/page/6BZ9C',
  WATCH: 'https://lookerstudio.google.com/embed/reporting/6fe6d631-12f1-4f84-97cf-3cc86e0df639/page/6BZ9C',
};

export const CONTENT_SERIES_HANDLER = {
  RADIO: 'pray-radio',
  TV: 'praytv-content-series',
};

export const STUDIO_MESSAGE_SOURCE = '@pray/studio';

export const CUSTOM_ADMIN_STYLE = 'custom-admin-background';

export const VIEW_EVENT_NAMES = {
  ANNOUNCEMENTS: {
    CAMPAIGNS: 'Announcement Campaigns',
    CREATION: 'Announcement Creation',
    EDIT: 'Edit Announcement',
    OVERVIEW: 'Announcements Overview',
    ANALYTICS: 'Announcements Analytics',
  },
  EMAILS: {
    CAMPAIGNS: 'Email Campaigns',
    CREATION: 'Email Creation',
    EDIT: 'Edit Email',
    MANAGE_TEMPLATES: 'Manage Email Templates',
    SELECT_TEMPLATE: 'Select Email Template',
    DESIGN_EMAIL: 'Design Email',
    PREVIEW: 'Preview Email',
    OVERVIEW: 'Email Overview',
    ANALYTICS: 'Email Analytics',
  },
  DONATIONS: {
    SUMMARY: 'Revenue Summary',
    FUNDS: 'Revenue Funds',
    FUNDS_CREATION: 'Funds Creation',
    FUNDS_EDIT: 'Edit Funds',
    BANK_TRANSFERS: 'Revenue Bank Transfers',
    BANK_TRANSFER_DETAILS: 'Revenue Bank Transfer Details',
    STATEMENTS: 'Revenue Statements',
    SETTINGS: 'Revenue Settings',
  },
  ANALYTICS: {
    AUDIENCE: 'Audience Analytics',
    LISTEN: 'Listen Analytics',
    WATCH: 'Watch Analytics',
    PERIODIC_TABLE: 'Periodic Table',
    PERIODIC_TABLE_SEGMENT_OVERVIEW: 'Periodic Table Segment Overview',
  },
  SERIES: {
    LIST: 'Series List',
    SERIES: 'Series',
    CREATION: 'Series Creation',
    DETAILS: 'Series Details',
    DISTRIBUTION: 'Series Distribution',
    RSS_SERIES: 'RSS Series',
    RSS_SERIES_CREATION: 'RSS Series Creation',
    RSS_SERIES_DISTRIBUTION: 'RSS Series Distribution',
  },
  CONTENT: {
    EPISODES: 'Episodes',
    DETAILS: 'Content Details',
    DETAILS_SUMMARY: 'Content Details Summary',
    DETAILS_TRANSCRIPT: 'Content Details Transcript',
    DETAILS_ANNOUNCEMENT: 'Content Details Announcement',
    DETAILS_EMAIL: 'Content Details Email',
    TRANSCRIPT_EDIT: 'Edit Transcript',
  },
  SOCIAL_CLIPS: {
    LIST: 'Social Clips',
    EMPTY_STATE: 'Social Clips Empty State',
    PUBLISH: 'Publish Social Clips',
  },
  CONTACT_LISTS: {
    LISTS: 'Contact List',
    DETAILS: 'Contact List Details',
    UPLOAD_FLOW_CSV: 'Contact List Upload Flow CSV',
    UPLOAD_FLOW_MAPPING: 'Contact List Upload Flow Mapping',
    UPLOAD_FLOW_IMPORT_CONTACTS: 'Contact List Upload Flow Import Contacts',
  },
  MINISTRY_MATCH: {
    CAMPAIGNS: 'Ministry Match Campaigns',
    CAMPAIGNS_CREATION: 'New Ministry Match Campaign',
    CAMPAIGNS_EDIT: 'Edit Ministry Match Campaign',
    ORGANIZATIONS: 'Ministry Match Organizations',
    ORGANIZATIONS_CREATION: 'New Ministry Match Organization',
    ORGANIZATIONS_EDIT: 'Edit Ministry Match Organization',
  },
  TESTIMONIALS: 'Testimonials',
  CALENDAR: 'Calendar',
};

export const EMAIL_CAMPAIGNS = {
  REPLACEMENTS: {
    PREVIEW_TEXT: 'PREVIEW_TEXT',
  },
};

export const EXTERNAL_REDIRECTS = [
  { path: '/kenforeman', to: 'https://pray.com/pastorkenforeman' }, // Pastor Ken Foreman's page
];
