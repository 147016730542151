import api from '@pray/shared/utils/api';

export async function removeTeamMembers({ artistId, teamMemberIds = [] }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!teamMemberIds?.length) throw Error('Team Members IDs are required');

  const data = {
    ids: teamMemberIds,
  };

  const url = `/web/studio/artists/${artistId}/team-members`;
  const response = await api.executeDeleteRequest(url, data);

  return response.data.data;
}
