import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { typography } from '@pray/shared/components/foundations';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import Text from '@pray/shared/components/ui/Text/Text';

import { Trash } from 'images/icons';

import userAvatar from './assets/user-avatar.svg';

import styles from './TestimonialCard.module.scss';

const TESTIMONIAL_CHARACTER_LIMIT = 370;

const getSentimentClass = (sentiment) => {
  if (/positive/i.test(sentiment)) {
    return styles.positiveTestimonialBadge;
  }

  if (/negative/i.test(sentiment)) {
    return styles.negativeTestimonialBadge;
  }

  return styles.neutralTestimonialBadge;
};

const getTestimonialText = (testimonialBody, textLimit, shouldTruncateText) => {
  if (!testimonialBody) {
    return '';
  }

  if (shouldTruncateText) {
    return `${testimonialBody.substring(0, textLimit)}...`;
  }

  return testimonialBody;
};

export default function TestimonialCard({
  userName = '',
  userProfileImage = '',
  sentiment,
  testimonialTitle,
  testimonialBody,
  testimonialDate,
  onDelete,
}) {
  const sentimentClass = getSentimentClass(sentiment);

  const [shouldShowReadMoreButton, toggleReadMoreButton] = useState(
    testimonialBody.length > TESTIMONIAL_CHARACTER_LIMIT
  );

  return (
    <div className={styles.testimonialCard}>
      <div className={styles.header}>
        <div className={styles.infoBlock}>
          <img
            className={styles.avatar}
            src={userProfileImage || userAvatar}
            alt={`${userName}`}
            onError={(event) => {
              event.target.src = userAvatar;
            }}
          />
          <div className={styles.info}>
            <div className={styles.title}>
              <Text variant={typography.headline_medium}>{userName || 'Anonymous'}</Text>
            </div>
            <div className={styles.subtitle}>
              <div className={styles.date}>
                <Text variant={typography.subhead_small} weight="500">
                  {DateTime.fromISO(testimonialDate).toLocaleString(DateTime.DATE_MED)}
                </Text>
              </div>
              {sentiment && (
                <div className={styles.sentiment}>
                  <Text variant={typography.subhead_small}>Sentiment:&nbsp;</Text>
                  <Text className={`${styles.testimonialBadge} ${sentimentClass}`} variant={typography.subhead_medium}>
                    {sentiment}
                  </Text>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.actions}>
          <IconButton icon={Trash} className="hover:bg-gray-100" tooltip="Delete testimony" onClick={onDelete} />
        </div>
      </div>
      <div className={styles.testimonial}>
        {!!testimonialTitle && (
          <div className={styles.title}>
            <Text variant={typography.headline_medium}>{testimonialTitle}</Text>
          </div>
        )}
        <div className={styles.body}>
          <Text variant={typography.body_small}>
            {getTestimonialText(testimonialBody, TESTIMONIAL_CHARACTER_LIMIT, shouldShowReadMoreButton)}
            {
              // If the testimonial body is longer than the character limit, show the read more button
              shouldShowReadMoreButton && (
                <button type="button" className={styles.readMoreButton} onClick={() => toggleReadMoreButton(false)}>
                  &nbsp;
                  <Text className={styles.readMoreButtonText} variant={typography.body_small}>
                    Read More
                  </Text>
                </button>
              )
            }
          </Text>
        </div>
      </div>
    </div>
  );
}
