import React, { useCallback, useMemo } from 'react';

import { buttons, colors, typography } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import Button from '@pray/shared/components/ui/Button';
import Text from '@pray/shared/components/ui/Text';

import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';

import { VoiceSteps } from './constants';
import useVoiceSettings from './hooks/useVoiceSettings';
import VoiceDialogs from './VoiceComponents/Dialogs/VoiceDialogs';
import EmptyVoiceState from './VoiceComponents/VoiceTable/EmptyVoiceState';
import VoicesSkeletonTable from './VoiceComponents/VoiceTable/VoicesSkeletonTable';
import VoicesTable from './VoiceComponents/VoiceTable/VoicesTable';

export default function VoiceSettingsPage() {
  const voiceSettings = useVoiceSettings();
  const { step, handleCreateVoiceClick } = voiceSettings;

  const voicesTableProps = useMemo(
    () => ({
      voices: voiceSettings.voices,
      isPlayingId: voiceSettings.isPlayingId,
      handleEditClick: voiceSettings.handleEditClick,
      handleDeleteClick: voiceSettings.handleDeleteClick,
      handleSetAsMainVoiceClick: voiceSettings.handleSetAsMainVoiceClick,
      handlePlayClick: voiceSettings.handlePlayClick,
      handlePauseClick: voiceSettings.handlePauseClick,
    }),
    [voiceSettings]
  );

  const voiceDialogProps = useCallback(
    () => ({
      selectedVoiceName: voiceSettings.selectedVoiceName,
      uploadedSamples: voiceSettings.uploadedSamples,
      isEditDialogOpen: voiceSettings.isEditDialogOpen,
      isDeleteDialogOpen: voiceSettings.isDeleteDialogOpen,
      isSetAsMainVoiceDialogOpen: voiceSettings.isSetAsMainVoiceDialogOpen,
      isVoiceUploadDialogOpen: voiceSettings.isVoiceUploadDialogOpen,
      isPlayingId: voiceSettings.isPlayingId,
      isLoading: voiceSettings.isLoading,
      onEditCancel: voiceSettings.handleEditCancel,
      onEditConfirm: voiceSettings.handleEditConfirm,
      onDeleteCancel: voiceSettings.handleDeleteCancel,
      onDeleteConfirm: voiceSettings.handleDeleteConfirm,
      onSetAsMainVoiceCancel: voiceSettings.handleSetAsMainVoiceCancel,
      onSetAsMainVoiceConfirm: voiceSettings.handleSetAsMainVoiceConfirm,
      onCreateVoice: voiceSettings.handleCreateVoice,
      onUploadCancel: voiceSettings.handleUploadCancel,
      onAcceptedFiles: voiceSettings.handleAcceptedFiles,
      onRejectedFiles: voiceSettings.handleRejectedFiles,
      onPlayClick: voiceSettings.handlePlayClick,
      onPauseClick: voiceSettings.handlePauseClick,
      onDeleteSample: voiceSettings.handleDeleteSample,
    }),
    [voiceSettings]
  );

  const renderTopRightButtons = useCallback(() => {
    if (step === VoiceSteps.LOADING || step === VoiceSteps.EMPTY) return null;
    return (
      <Button variant={buttons.variant.primary} onClick={handleCreateVoiceClick}>
        Create new voice
      </Button>
    );
  }, [step, handleCreateVoiceClick]);

  return (
    <div data-viewname="PRAY Studio - Settings - Voices">
      <Metadata title="PRAY Studio for Leaders - Voices settings" />

      <TabPage title="Voices" topRightButtons={renderTopRightButtons()}>
        <Text variant={typography.body_lg} color={colors.text_tertiary}>
          Create and manage your cloned voices
        </Text>

        {step === VoiceSteps.LOADING && <VoicesSkeletonTable />}

        {step === VoiceSteps.EMPTY && <EmptyVoiceState onClick={handleCreateVoiceClick} />}

        {step === VoiceSteps.VOICES && <VoicesTable {...voicesTableProps} />}

        <VoiceDialogs {...voiceDialogProps()} />
      </TabPage>
    </div>
  );
}
