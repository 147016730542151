import React from 'react';

import { colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export default function FilterSection({ title, isColumn = false, children }) {
  return (
    <div className="flex flex-col gap-3">
      <Text className="text-sm font-bold" color={colors.text_secondary}>
        {title}
      </Text>
      <div className={`flex flex-wrap ${isColumn ? 'flex-col gap-4' : 'gap-2'}`}>{children}</div>
    </div>
  );
}
