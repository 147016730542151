import { useQuery } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function usePayoutById({ artistId, payoutId }) {
  async function fetchPayout() {
    const response = await studioService.donations.getPayoutById({ artistId, payoutId });
    return studioService.donations.payoutsMapper(response.data);
  }

  const query = useQuery({
    enabled: !!artistId && !!payoutId,
    queryKey: queryKeys.artistPayoutById(artistId, payoutId),
    queryFn: fetchPayout,
  });

  return query;
}
