import { useCallback } from 'react';

export function useDownloadStudyGuide() {
  const handleDownload = useCallback((studyGuide) => {
    try {
      const blob = new Blob([studyGuide], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'study-guide.txt';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to download study guide:', error);
    }
  }, []);

  return {
    downloadStudyGuide: handleDownload,
  };
}
