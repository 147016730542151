import React from 'react';

import Text from '@pray/shared/components/ui/Text';

import { typography } from '../../foundations';

import arrow from './assets/arrow.svg';

export default function Popover({ title = '', description = '', footer = null, children = null }) {
  if (!title && !description) return children;

  return (
    <div className="group relative cursor-default">
      <img src={arrow} alt="arrow" className="absolute left-[22px] top-1/2 hidden -translate-y-1/2 group-hover:flex" />
      <div className="absolute top-1/2 z-10 hidden w-[275px] -translate-y-1/2 translate-x-8 flex-col gap-1 rounded-lg bg-[#18191C] p-4 group-hover:flex">
        {title && (
          <Text variant={typography.subhead_medium} className="font-bold text-white">
            {title}
          </Text>
        )}
        {description && (
          <Text variant={typography.subhead_small} className="!text-[#DEDFE3]">
            {description}
          </Text>
        )}
        {footer}
      </div>
      {children}
    </div>
  );
}
