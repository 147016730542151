import React, { useCallback, useEffect, useRef } from 'react';

import { colors } from '../../foundations';
import Button from '../Button/Button';
import { Check, Search } from '../Icons/Icons';
import InputField from '../InputField/InputField';
import Text from '../Text/Text';

import styles from './Dropdown.module.scss';

export default function Dropdown({
  items = [],
  isLoading = false,
  getItemId = null,
  getImage = null,
  getTitle = null,
  getIsSelected = null,
  isRenderBackdrop = true,
  isRenderSearchField = true,
  isAutoHeight = false,
  fallbackImage = '',
  searchTerm = '',
  searchPlaceholder = 'Search',
  noItemsMessage = 'No items found',
  infiniteScrollThreshold = 1.0,
  onInfiniteScroll = null,
  onChange = null,
  onKeyUp = null,
  onSearchChange = null,
}) {
  const itemsRef = useRef(null);
  const height = items.length > 4 ? '360px' : '';

  const handleScroll = useCallback(() => {
    const { scrollHeight, scrollTop, offsetHeight } = itemsRef.current;
    if (searchTerm || scrollTop === 0) return;

    const scrollPercentage = (scrollTop + offsetHeight) / scrollHeight;
    if (scrollPercentage >= infiniteScrollThreshold) onInfiniteScroll?.(scrollPercentage);
  }, [searchTerm]);

  useEffect(() => {
    if (onInfiniteScroll) {
      itemsRef.current.addEventListener('scroll', handleScroll);
      return () => itemsRef.current?.removeEventListener('scroll', handleScroll);
    }
  }, [searchTerm]);

  const handleImageLoadError = (event) => {
    if (event.target.src !== fallbackImage) event.target.src = fallbackImage;
  };

  const renderItem = (item) => {
    const id = getItemId?.(item);
    const image = getImage?.(item) ?? fallbackImage;
    const title = getTitle?.(item);
    const isSelected = getIsSelected?.(item);

    return (
      <Button key={id} className={styles.item} onClick={() => onChange?.(item)}>
        {!!image && <img alt={title} className={styles.image} src={image} onError={handleImageLoadError} />}
        {!!title && <Text className={styles.title}>{title}</Text>}
        {isSelected && <Check />}
      </Button>
    );
  };

  const renderEmptyMessage = () => (
    <div className="text-center">
      <Text color={colors.text_tertiary}>{noItemsMessage}</Text>
    </div>
  );

  const itemsStyle = {
    height,
  };

  if (isAutoHeight) {
    itemsStyle.height = 'auto';
    itemsStyle.maxHeight = height;
  }

  return (
    <>
      {isRenderBackdrop && <div role="none" className={styles.backdrop} onClick={() => onChange?.()} />}
      <div className={styles.container}>
        <div className={`${styles.dropdown} flex flex-col gap-2`}>
          {isRenderSearchField && (
            <InputField
              autoFocus
              placeholder={searchPlaceholder}
              leftIcon={<Search />}
              loading={isLoading}
              renderStatusIndicator={isLoading}
              value={searchTerm}
              onChange={onSearchChange}
              onKeyUp={onKeyUp}
            />
          )}
          <div ref={itemsRef} className={styles.items} style={itemsStyle}>
            {items.map(renderItem)}
            {!items.length && renderEmptyMessage()}
          </div>
        </div>
      </div>
    </>
  );
}
