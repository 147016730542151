import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from 'components/layouts/MainLayout';

import LeadList from './LeadList/LeadList';
import LeadListDetails from './LeadListDetails/LeadListDetails';
import UploadLeadsFlow from './UploadFlow/UploadLeadsFlow';

export default function LeadListsTab() {
  return (
    <MainLayout data-viewname="PRAY Studio - Leads Lists Tab">
      <Routes>
        <Route index element={<LeadList />} />
        <Route path=":listId/details" element={<LeadListDetails />} />
        <Route path="upload" element={<UploadLeadsFlow />} />
      </Routes>
    </MainLayout>
  );
}
