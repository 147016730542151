import contentService from '../../../services/contentService';
import { useVideoModal } from '../../VideoModal/VideoModal';
import { useAudioPlayer } from '../FullscreenContentPlayer/FullscreenContentPlayer';

export const useContentPlayer = () => {
  const audioPlayer = useAudioPlayer();
  const videoModal = useVideoModal();

  const playContent = async (contentId, options = {}) => {
    if (!contentId) return;

    const content = await contentService.fetchContentData({ id: contentId, locale: options?.locale });

    if (!content) return;

    if (content.video_url) {
      audioPlayer.closeAudioPlayer();

      videoModal.playVideo(content);
    }

    if (content.audio_url) {
      videoModal.onClose();

      audioPlayer.playContent({
        ...options,
        contentId: content.id,
        contentItems: [content],
        isFetchContentData: false,
      });
    }
  };

  const closePlayer = () => {
    audioPlayer.closeAudioPlayer();
    videoModal.onClose();
  };

  return {
    playContent,
    closePlayer,
  };
};
