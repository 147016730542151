import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@pray/shared/hooks/useAuth';

import IndexPage from 'components/pages/IndexPage/IndexPage';
import { useStudioContext } from 'context/StudioContext';
import useAppRoutes from 'hooks/useAppRoutes';

export default function Index() {
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { selectedArtist } = useStudioContext();

  const isUserRegistered = !!user?.is_registered;
  const isUserPermissionsResolved = !!user?.is_permissions_resolved;
  const isUserResolved = isUserRegistered && isUserPermissionsResolved;
  const isArtistResolved = selectedArtist?.isResolved;

  useEffect(() => {
    if (isUserResolved && isArtistResolved) {
      navigate(appRoutes.initial(), { replace: true });
    }
  }, [isUserResolved, isArtistResolved]);

  // if user is registered return null
  if (isUserRegistered) {
    return null;
  }

  // if user is not registered show index page
  return <IndexPage />;
}
