import AR from './ar.svg';
import DE from './de.svg';
import EN from './en.svg';
import ES from './es.svg';
import FR from './fr.svg';
import HI from './hi.svg';
import IT from './it.svg';
import JA from './ja.svg';
import KO from './ko.svg';
import PT from './pt.svg';
import SW from './sw.svg';
import TL from './tl.svg';
import ZH from './zh.svg';

const Flags = {
  AR,
  DE,
  EN,
  ES,
  FR,
  HI,
  IT,
  JA,
  KO,
  PT,
  SW,
  TL,
  ZH,
};

export default Flags;
