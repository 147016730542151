import api from '@pray/shared/utils/api';
import { PaginatedListResponse } from '@pray/shared/utils/api/types/response';

import { ArtistContentResponse } from './types';

/* eslint-disable camelcase */
type GetArtistContentParams = {
  media_type?: string;
  is_added_to_series?: boolean;
  content_series_id?: string;
  include_content_from_rss?: boolean;
  search?: string;
  offset?: number;
  limit?: number;
  sort_column?: string;
  sort_direction?: string;
};
/* eslint-enable camelcase */

type GetArtistContentResponse = PaginatedListResponse<ArtistContentResponse>;

export const getArtistContent = async ({
  artistId = null,
  mediaType = null,
  isAddedToSeries = null,
  contentSeriesId = null,
  includeContentFromRSS = false,
  search = '',
  offset = 0,
  limit = 0,
  sortColumn = '',
  sortDirection = 'asc',
  options = {},
} = {}) => {
  if (!artistId) throw Error('ID is required');

  const params: GetArtistContentParams = {};

  if (mediaType !== null) {
    params.media_type = mediaType;
  }

  if (isAddedToSeries !== null) {
    params.is_added_to_series = isAddedToSeries;
  }

  if (includeContentFromRSS) {
    params.include_content_from_rss = includeContentFromRSS;
  }

  if (contentSeriesId) {
    params.content_series_id = contentSeriesId;
  }

  if (search) {
    params.search = search;
  } else if (offset) {
    params.offset = offset;
  }

  if (limit) params.limit = limit;

  if (sortColumn) {
    params.sort_column = sortColumn;
    params.sort_direction = sortDirection;
  }

  const url = `/web/studio/artists/${artistId}/content`;
  const response = await api.executeGetRequest<GetArtistContentResponse>(url, params, options);

  return response.data;
};
