import React from 'react';

import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import JumpBackIcon from '@pray/shared/icons/JumpBackIcon';
import JumpForwardIcon from '@pray/shared/icons/JumpForwardIcon';
import PauseIcon from '@pray/shared/icons/PauseIcon';
import PlayIcon from '@pray/shared/icons/PlayIconEmpty';
import SkipBackIcon from '@pray/shared/icons/SkipBackIcon';
import SkipForwardIcon from '@pray/shared/icons/SkipForwardIcon';

import styles from './Controls.module.css';

const Controls = (props) => {
  const {
    isPlaying,
    currentContent = {},
    controlOptions,
    onBackButtonClick,
    onPlayPauseButtonClick,
    onForwardButtonClick,
    onSkipBackButtonClick,
    onSkipForwardButtonClick,
    isMinimized = false,
  } = props;

  return (
    <div className={[styles.controls, isMinimized ? styles.controlsMinimized : ''].join(' ')}>
      <IconButton data-tapeventname="Rewind" className={styles.iconWrapper} onClick={onBackButtonClick}>
        <JumpBackIcon />
      </IconButton>

      {controlOptions.isShowSkipBackward && (
        <IconButton
          data-tapeventname="Skip Backward"
          className={styles.iconWrapper}
          onClick={onSkipBackButtonClick}
          title={currentContent.previousContentId}
        >
          <SkipBackIcon />
        </IconButton>
      )}

      <IconButton
        className={styles.playPauseButton}
        data-tapeventname={isPlaying ? 'Pause' : 'Play'}
        onClick={onPlayPauseButtonClick}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </IconButton>

      {controlOptions.isShowSkipForward && (
        <IconButton
          data-tapeventname="Skip Forward"
          disabled={!currentContent.nextContentId}
          className={styles.iconWrapper}
          onClick={onSkipForwardButtonClick}
          title={currentContent.nextContentId}
        >
          <SkipForwardIcon />
        </IconButton>
      )}

      <IconButton data-tapeventname="Fast Forward" className={styles.iconWrapper} onClick={onForwardButtonClick}>
        <JumpForwardIcon />
      </IconButton>
    </div>
  );
};

export default Controls;
