import React from 'react';

import Text from '@pray/shared/components/ui/Text';

import DeleteContentModal from 'components/pages/StudioPage/components/DeleteContentModal/DeleteContentModal';

export default function DeleteTemplateModal({ onCancel, onDelete }) {
  return (
    <DeleteContentModal
      isOpen
      title="Confirm Deletion"
      message={
        <>
          <Text>Are you sure you want to delete this email template?</Text>
          <Text>This action cannot be undone.</Text>
        </>
      }
      onDelete={onDelete}
      onClose={onCancel}
    />
  );
}
