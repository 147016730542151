import { useMutation } from '@tanstack/react-query';

import studioService from '@pray/shared/services/studioService';

export function useUpdateSocialClip() {
  async function updateSocialClip({ artistId, contentId, socialClipId, title, description, platforms, publishedAt }) {
    await studioService.socialClips.updateSocialClip({
      artistId,
      contentId,
      socialClipId,
      title,
      description,
      platforms,
      publishedAt,
    });
  }

  const { mutateAsync, ...mutation } = useMutation({
    mutationFn: updateSocialClip,
  });

  return {
    ...mutation,
    updateSocialClip: mutateAsync,
  };
}
