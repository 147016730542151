import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

type SaveBookVersionParams = {
  artistId: string;
  bookId: string;
};

export function useSaveBookVersion() {
  const queryClient = useQueryClient();

  async function saveBookVersion({ artistId, bookId }: SaveBookVersionParams): Promise<void> {
    await studioService.books.saveBookVersion({
      artistId,
      bookId,
    });
  }

  function onSuccess(_, variables) {
    queryClient.invalidateQueries({
      queryKey: queryKeys.artistBookAllChaptersVersions(variables.artistId, variables.bookId),
    });
    queryClient.invalidateQueries({
      queryKey: queryKeys.artistBookChapters(variables.artistId, variables.bookId),
    });
  }

  const { mutateAsync, isPending } = useMutation({
    mutationFn: saveBookVersion,
    onSuccess,
  });

  return { saveBookVersion: mutateAsync, isPending };
}
