import { useMutation, useQueryClient } from '@tanstack/react-query';

import queryKeys from '@pray/shared/queryKeys';
import studioService from '@pray/shared/services/studioService';

export default function useDeleteAnnouncement() {
  const queryClient = useQueryClient();

  async function deleteAnnouncement({ artistId = undefined, announcementId = undefined }) {
    return studioService.announcements.deleteAnnouncement({ artistId, announcementId });
  }

  const mutation = useMutation({
    mutationFn: deleteAnnouncement,
    onSuccess: (_, { artistId }) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.artistAnnouncements(artistId),
      });
    },
  });

  const { mutateAsync, ...rest } = mutation;

  return {
    deleteAnnouncement: mutateAsync,
    ...rest,
  };
}
