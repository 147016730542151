import { LOCAL_STORAGE, SESSION_STORAGE } from '@pray/shared/constants';
import api from '@pray/shared/utils/api';
import logger from '@pray/shared/utils/logger';
import storage from '@pray/shared/utils/storage';

export async function authenticate() {
  try {
    const url = `/web/studio/authenticate`;
    const response = await api.executePostRequest(url, {});
    const { tokens, permissions } = response.data.data;
    const user = _storeAuthUser(tokens);

    return { user, permissions };
  } catch (err) {
    logger.error('failed to authenticate', err);
    return {
      user: null,
      permissions: [],
    };
  }
}

function _storeAuthUser(tokens) {
  const sessionToken = tokens.find((x) => x.type === 'session')?.token;
  const refreshtoken = tokens.find((x) => x.type === 'refresh')?.token;
  const user = _decodeSessionTokenAndGetUser(sessionToken);

  if (!user) {
    logger.debug('invalid user in session token', tokens);
    return null;
  }

  user.is_logged_in = !!refreshtoken;

  storage.upsertToStorage(LOCAL_STORAGE.USER, user, false);
  sessionStorage.setItem(SESSION_STORAGE.SESSION_TOKEN, sessionToken);

  return user;
}

function _decodeSessionTokenAndGetUser(sessionToken) {
  try {
    const base64Url = sessionToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    const decodedToken = JSON.parse(jsonPayload);
    return decodedToken.user;
  } catch (error) {
    logger.debug('error in _decodeSessionTokenAndGetUser', error);
    return null;
  }
}
