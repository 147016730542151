import api from '@pray/shared/utils/api';

export async function updateRssFeedEpisode({ artistId, rssFeedId, episodeId, isPublished }) {
  if (!artistId) throw Error('Artist ID is required');
  if (!rssFeedId) throw Error('RSS Feed ID is required');
  if (!episodeId) throw Error('Episode ID is required');
  if (isPublished === undefined) throw Error('Published status is required');

  const data = {
    is_published: isPublished,
  };

  const url = `/web/studio/artists/${artistId}/rss-feeds/${rssFeedId}/episodes/${episodeId}`;
  const response = await api.executePutRequest(url, data);

  return response.data.data;
}
