import api from '@pray/shared/utils/api';

type DeleteChapterProps = {
  artistId: string;
  bookId: string;
  chapterId: string;
};

export const deleteChapter = async ({ artistId, bookId, chapterId }: DeleteChapterProps) => {
  if (!artistId) throw Error('Artist ID is required');
  if (!bookId) throw Error('Book ID is required');
  if (!chapterId) throw Error('Chapter ID is required');

  const url = `/web/studio/artists/${artistId}/books/${bookId}/chapters/${chapterId}`;

  await api.executeDeleteRequest(url);
};
