import React from 'react';

import { typography, colors } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text';

export function EmailAnalyticsItem({ icon, title, value = '0', description = '', subtitle = '', variation = 0 }) {
  return (
    <div className="flex h-[168px] flex-col rounded-xl border border-[#DEDFE3] p-4">
      <div className="flex items-end gap-4">
        {icon}
        <Text variant={typography.h2}>{title}</Text>
      </div>
      <div className="flex items-end gap-1 pl-1 pt-4">
        <Text variant={typography.heading_5xl}>{value}</Text>
        <Text className="pb-1" variant={typography.body_lg} color={colors.text_secondary}>
          {description}
        </Text>
      </div>
      {variation > 0 && (
        <div className="flex items-end gap-1 pl-1 pt-2">
          <Text variant={typography.button_medium} color={colors.text_tertiary}>
            {subtitle}
          </Text>
          <Text variant={typography.button_medium} color={colors.success}>
            {variation}%
          </Text>
        </div>
      )}
    </div>
  );
}
