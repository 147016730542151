import api from '@pray/shared/utils/api';

export async function getGivingSummary({ artistId = '', startDate = '', endDate = '' }) {
  if (!artistId) throw new Error('Artist ID is required');
  if (!startDate) throw new Error('Start Date is required');
  if (!endDate) throw new Error('End Date is required');

  const params = {
    start_date: startDate,
    end_date: endDate,
  };

  const url = `/web/studio/artists/${artistId}/giving-summary`;
  const response = await api.executeGetRequest(url, params);

  return response.data;
}
