import React from 'react';

import { typography } from '@pray/shared/components/foundations';
import Text from '@pray/shared/components/ui/Text/Text';
import ToggleSwitch from '@pray/shared/components/ui/ToggleSwitch/ToggleSwitch';

import { CrossedClose } from 'images/icons';

import styles from './LanguageSettings.module.scss';

export const LanguageSetting = ({ language, isEnabled, onToggle, onRemove }) => {
  return (
    <div className={styles.languageSetting}>
      <Text variant={typography.headline_medium}>{language}</Text>

      <div className={styles.settingsActionGroup}>
        <ToggleSwitch checked={isEnabled} onChange={onToggle} />

        <button type="button" className={styles.removeBtn} onClick={onRemove}>
          <CrossedClose />
        </button>
      </div>
    </div>
  );
};
