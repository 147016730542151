import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';

import { typography } from '@pray/shared/components/foundations';
import IconButton from '@pray/shared/components/ui/IconButton/IconButton';
import Text from '@pray/shared/components/ui/Text';

import { Close } from 'images/icons';

export default function DateDetailsModal({ date, events = [], anchorEl, renderEvent, onClose }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      const { top, left } = anchorEl.getBoundingClientRect();
      const { width, height } = modalRef.current.getBoundingClientRect();

      // Horizontal positioning
      const modalLeft = left - width;
      modalRef.current.style.left = `${modalLeft}px`;

      // Vertical positioning
      let modalTop = top - height / 2;

      if (modalTop + height > window.innerHeight - 200) {
        modalTop = window.innerHeight - height - 200;
      }

      modalRef.current.style.top = `${modalTop}px`;
    }
  }, [anchorEl]);

  return (
    <>
      <div ref={modalRef} className="absolute z-[110] w-60 -translate-x-1/2 rounded-md border bg-white shadow-xl">
        <div className="flex flex-row">
          <div className="flex flex-1 flex-col items-center justify-center px-3 py-2">
            <Text className="font-medium text-[#00000099]" variant={typography.headline_small}>
              {date.toFormat('cccc')}
            </Text>
            <Text variant={typography.h2}>{date.get('day')}</Text>
          </div>
          <div className="absolute right-0">
            <IconButton className="scale-75" icon={Close} onClick={onClose} />
          </div>
        </div>
        <div className="flex max-h-[640px] flex-col gap-1 overflow-y-auto px-3 pb-3">
          {events.map((event) => {
            return renderEvent({
              event,
              date: DateTime.fromISO(event.startDate),
              isDisplayTime: !!event.endDate,
              isEventStart: true,
              isEventEnd: true,
            });
          })}
        </div>
      </div>
      <div className="fixed inset-0 z-[109]" onClick={onClose} role="none" />
    </>
  );
}
