import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useForm from '@pray/shared/hooks/useForm';
import studioService from '@pray/shared/services/studioService';
import logger from '@pray/shared/utils/logger';

import { useStudioContext } from 'context/StudioContext';
import useGetDailySeriesDetails from 'hooks/dailySeries/useGetDailySeriesDetails';
import useSaveDailySeries from 'hooks/dailySeries/useSaveDailySeries';
import useAppRoutes from 'hooks/useAppRoutes';

import DailySeriesEditForm from './DailySeriesEditForm';
import DailySeriesFormDropdown from './DailySeriesFormDropdown';
import DailySeriesLoadingState from './DailySeriesLoadingState';
import DailySeriesPreview from './DailySeriesPreview';
import DeleteDailySeriesModal from './DeleteDailySeriesModal';
import ActionButton from '../../../components/ActionButton/ActionButton';
import { useToastMessage } from '../../../components/StudioToastMessage';
import TabPage from '../../../components/TabPage/TabPage';

const initialValues = {
  id: '',
  title: '',
  description: '',
  image_url: '',
  handle: '',
};

export default function DailySeriesForm() {
  const appRoutes = useAppRoutes();
  const toast = useToastMessage();
  const { id: dailySeriesId } = useParams();
  const navigate = useNavigate();
  const { selectedArtist } = useStudioContext();
  const artistId = selectedArtist?.id;

  // Form handling state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const form = useForm(initialValues);

  // Fetch daily series details
  const { data: dailySeriesData, isLoading, isRefetching } = useGetDailySeriesDetails(artistId, dailySeriesId);
  const { saveDailySeries, isLoading: isSaving } = useSaveDailySeries(artistId, dailySeriesId);

  useEffect(() => {
    if (!dailySeriesData) return;

    form.setValues({
      ...form.values,
      id: dailySeriesData.id || '',
      title: dailySeriesData.title || '',
      description: dailySeriesData.description || '',
      image_url: dailySeriesData.image_url || '',
      handle: dailySeriesData.handle || '',
      image_file: null, // store the image file in the form state
    });
  }, [dailySeriesData]);

  const handleSaveSeries = async () => {
    try {
      await saveDailySeries({
        title: form.values.title,
        description: form.values.description,
        handle: form.values.handle,
        imageUrl: form.values.image_url,
        imageFile: form.values.image_file,
      });

      toast.show({ success: 'Series updated successfully' });
    } catch (err) {
      logger.error(err);

      if (err.cause?.image_url) {
        form.setError('image_url', err.cause.image_url);
      } else {
        toast.show({ error: 'Series handle already exists' });
      }
    }
  };

  const handleDeleteSeries = async () => {
    try {
      await studioService.dailySeries.deleteDailySeries({ artistId, dailySeriesId });

      toast.show({ success: 'Series deleted successfully' });

      navigate(appRoutes.artistDailiesPlaylists());
    } catch (err) {
      logger.error(err);
    }

    setIsDeleteModalOpen(false);
  };

  const renderTopRightButtons = () => {
    if (isLoading || !dailySeriesData?.id) return null;
    return (
      <div>
        <div className="hidden md:block">
          <div className="flex flex-1 space-x-3">
            <ActionButton
              isLoading={isSaving}
              disabled={isSaving}
              text={isSaving ? 'Saving' : 'Save'}
              onClick={handleSaveSeries}
            />
            <DailySeriesFormDropdown isDisabled={isSaving} onDelete={() => setIsDeleteModalOpen(true)} />
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <DailySeriesLoadingState />;

  return (
    <TabPage title="Details" topRightButtons={renderTopRightButtons()}>
      <div className="flex flex-col gap-10 md:flex-row">
        <DailySeriesEditForm form={form} disabled />
        <DailySeriesPreview form={form} dailySeries={dailySeriesData} isLoading={isRefetching} />
      </div>

      <DeleteDailySeriesModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteSeries}
      />
    </TabPage>
  );
}
