import React from 'react';

import { buttons } from '@pray/shared/components/foundations';
import Button from '@pray/shared/components/ui/Button/Button';
import Spinner from '@pray/shared/components/v1/Spinner/Spinner';

import styles from './ActionButton.module.scss';

export default function ActionButton({ isLoading = false, text = '', icon = null, className = '', ...props }) {
  const classes = [styles.button, className] //
    .filter(Boolean)
    .join(' ');

  return (
    <Button className={classes} variant={buttons.variant.primary} disabled={isLoading} {...props}>
      {isLoading && <Spinner color="white" />}
      {icon}
      {text}
    </Button>
  );
}
