import { colors, typography } from '@pray/shared/components/foundations';
import Metadata from '@pray/shared/components/Metadata';
import Text from '@pray/shared/components/ui/Text';

import TabPage from 'components/pages/StudioPage/components/TabPage/TabPage';

import SocialAccountsTable from './SocialAccountsTable';
import { useSocialAccountsSettingsPage } from './useSocialAccountsSettingsPage';

export default function SocialAccountsSettingsPage() {
  const { socialAccounts, isLoading, handleConnect, handleDisconnect } = useSocialAccountsSettingsPage();

  return (
    <div data-viewname="PRAY Studio - Settings - Social Accounts">
      <Metadata title="PRAY Studio for Leaders - Social Accounts Settings" />

      <TabPage title="Social Accounts">
        <Text variant={typography.body_lg} color={colors.text_tertiary}>
          Link your profiles to track your social media performance with Pray Studio.
        </Text>

        <SocialAccountsTable
          data={socialAccounts}
          isLoading={isLoading}
          onConnect={handleConnect}
          onDisconnect={handleDisconnect}
        />
      </TabPage>
    </div>
  );
}
